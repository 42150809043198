import React from 'react';
import styled from '@cthings.co/styled-components';
import { CustomModal } from '../../../../components/customModal/CustomModal';
import { Text, TextType } from '@bit/first-scope.text';
import { InfoButton } from '../../../../components/infoButton/InfoButton';
import { ReactComponent as HelpIcon } from '../../../../assets/help.svg';
import { CommonButton } from '../../../../components/commonButton/CommonButton';
import { ReactComponent as InfoImage } from './assets/info.svg';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 12px 0 32px 0;
`;

const WrapperChecks = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: ${colorFetch('gray6')};
  border: 1px solid;
  border-radius: 8px;
  border-color: ${colorFetch('gray5')};
`;

const StyledInfoImage = styled(InfoImage)`
  margin-right: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.semiTablet} {
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column-reverse;
  }
`;

const StyledCancelButton = styled(CommonButton)`
  margin: 0 16px 0 0;
  font-size: 14px;
  ${media.semiTablet} {
    width: 100%;
    margin: 0;
  }
`;

const StyledNextButton = styled(CommonButton)`
  font-size: 14px;
  ${media.semiTablet} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const GpsLocationRequestStart = ({
  isOpenModal,
  language,
  count_of_check,
  onCancel,
  onStart,
  link,
}: {
  isOpenModal: boolean;
  language: any;
  count_of_check: number;
  onCancel: () => void;
  onStart: () => void;
  link: string;
}) => {
  const theme = useTheme();
  const { black1, gray1, pureWhite } = theme.colors;

  return (
    <CustomModal isOpen={isOpenModal}>
      <TitleWrapper>
        <Text type={TextType.TEXT_14_BLACK} fontSize={'18px'} weight={'600'} color={black1}>
          {language.gpsLocationCheckTitle}
        </Text>
        <InfoButton onClick={() => {}} tooltipText={language.gpsCheckTooltip} customIcon={<HelpIcon />} link={link} />
      </TitleWrapper>
      <Content>
        <WrapperChecks>
          <StyledInfoImage />
          <Text type={TextType.TEXT_14_BLACK} weight={'500'} color={gray1}>
            {language.gpsDetailsCheckPerformedText + count_of_check}
          </Text>
        </WrapperChecks>
        <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
          {language.gpsLocationCheckText}
        </Text>
      </Content>
      <ButtonRow>
        <StyledCancelButton onClick={onCancel} width={'116px'} color={black1} backgroundColor={pureWhite}>
          {language.cancelButton}
        </StyledCancelButton>
        <StyledNextButton onClick={onStart} width={'116px'}>
          {language.startButton}
        </StyledNextButton>
      </ButtonRow>
    </CustomModal>
  );
};
