import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '../../../../components/commonButton/CommonButton';
import { Text } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { AddUsersView } from './addUsersView/AddUsersView';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { pushAction } from '../../../../app/state/organisations';
import { CustomModal } from '../customModal/CustomModal';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { CloseButton } from '../closeButton/CloseButton';

const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black')};
` as any; // @TODO fix type

const StepNumber = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
` as any; // @TODO fix type

const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  white-space: break-spaces;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  ${media.semiTablet} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

type ButtonProps = {
  withMargin?: boolean;
};

// @TODO fix media types
const Button = styled(CommonButton)<ButtonProps>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: ${({ withMargin }) => (withMargin ? '0 16px 0 0' : '0')};
  ${media.semiTablet} {
    width: 100%;
    margin: ${({ withMargin }: any) => (withMargin ? '10px 0 0 0' : '0')};
    height: 40px;
  }
` as any; // @TODO fix type

export const Block = styled.div`
  display: flex;
  align-items: center;
`;

export enum ModifyUsersStage {
  CREATION = 'CREATION',
  SUMMARY = 'SUMMARY',
}

interface AddUsersViewProps {
  isOpen: boolean;
  users?: { id: string; name: string; email: string; companyName: string; role: string; isDisabled: boolean }[];
  type: ModifyUsersStage;
}

export const CreateMoreUsers: FC<AddUsersViewProps> = ({ isOpen, type }) => {
  const theme = useTheme();
  const { primary, black1, pureWhite } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  const dispatch = useDispatch();

  const handleNext = () => {
    if (type === ModifyUsersStage.CREATION) {
      dispatch(pushAction('next'));
    } else {
      dispatch(pushAction('back'));
    }
  };

  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  const nextButtonText = languageStrings.additionalDeviceNextButton;
  const backButtonText = languageStrings.backButton;
  const saveButtonText = languageStrings.saveButton;

  const buttons = [
    {
      label: backButtonText,
      onClick: handleBack,
      width: '116px',
      height: '32px',
      backgroundColor: pureWhite,
      backgroundColorHover: primary,
      borderColorHover: primary,
      color: black1,
      withMargin: true,
      hidden: type === ModifyUsersStage.SUMMARY,
    },
    {
      label: type === ModifyUsersStage.CREATION ? nextButtonText : saveButtonText,
      onClick: handleNext,
      width: '116px',
      height: '32px',
      backgroundColor: primary,
      backgroundColorHover: primary,
      borderColorHover: primary,
      color: pureWhite,
    },
  ];

  return (
    <CustomModal isOpen={isOpen}>
      <HeadWrapper>
        <Title>{languageStrings.createMoreUsers}</Title>
        <Block>
          <StepNumber>2/3</StepNumber>
          <CloseButton />
        </Block>
      </HeadWrapper>
      <Description>{languageStrings.multipleUsersCanBeAdded}</Description>
      <AddUsersView languageStrings={languageStrings} />
      <ButtonsWrapper>
        {buttons.map((item) => {
          const {
            label,
            onClick,
            width,
            height,
            backgroundColor,
            backgroundColorHover,
            color,
            borderColorHover,
            withMargin,
            hidden,
          } = item;

          return !hidden ? (
            <Button
              onClick={onClick}
              width={width}
              height={height}
              backgroundColor={backgroundColor}
              backgroundColorHover={backgroundColorHover}
              color={color}
              borderColorHover={borderColorHover}
              withMargin={withMargin}
            >
              {label}
            </Button>
          ) : null;
        })}
      </ButtonsWrapper>
    </CustomModal>
  );
};
