import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as ArrowIcon } from '../../../../../../../../../../../assets/arrowViewer.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
`;
const Arrow = styled(ArrowIcon)`
  cursor: pointer;
  &.left {
    transform: rotate(180deg);
  }
  & > path {
    transition: all 0.3s linear;
  }

  &:hover {
    & > path {
      stroke: ${colorFetch('gray4')};
      transition: all 0.3s linear;
    }
  }
`;

export const Arrows = ({ nextPhoto, previousPhoto }: { nextPhoto: () => void; previousPhoto: () => void }) => {
  const theme = useTheme();

  return (
    <Wrapper>
      <Arrow className={'left'} theme={theme} onClick={previousPhoto} />
      <Arrow theme={theme} onClick={nextPhoto} />
    </Wrapper>
  );
};
