import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { DevicesStepType } from '../AddDevicesManually';
import { DeviceSelectionModeValue } from '../types';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type ButtonWrapperProps = {
  type: DevicesStepType;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: ${({ type }) => (type === DevicesStepType.ADD ? '296px' : '340px')};
  height: 40px;
  display: flex;
  cursor: pointer;
  & > div:first-child {
    border-radius: 8px 0 0 8px;
    &.selected {
      border-right: 1px solid;
      border-right-color: ${colorFetch('primary')};
    }
  }
  & > div:last-child {
    border-radius: 0 8px 8px 0;
    &.selected {
      border-left: 1px solid;
      border-left-color: ${colorFetch('primary')};
    }
  }
  &:focus {
    outline: none;
  }
`;

type OptionProps = {
  type: DevicesStepType;
};

const Option = styled.div<OptionProps>`
  width: ${({ type }) => (type === DevicesStepType.ADD ? '146px' : '170px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};

  ${media.phone} {
    font-size: 12px;
  }

  &.selected {
    border-color: ${colorFetch('primary')};
  }
`;

const StyledText = styled(Text)`
  word-break: break-all;
`;

export const Switcher = ({
  values,
  value,
  type,
  onChange,
}: {
  values: DeviceSelectionModeValue[];
  value: DeviceSelectionModeValue;
  onChange: (props: DeviceSelectionModeValue) => void;
  type: DevicesStepType;

  isEditInProcess?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { primary, gray2 } = theme.colors;

  const handleClick = (val: DeviceSelectionModeValue) => {
    onChange(val);
  };

  return (
    <ButtonWrapper type={type}>
      {values.map((option: DeviceSelectionModeValue) => (
        <Option
          type={type}
          className={option.id === value.id ? 'selected' : ''}
          onClick={() => {
            handleClick(option);
          }}
          theme={theme}
        >
          <StyledText
            color={option.id === value.id ? primary : gray2}
            type={TextType.TEXT_14_GRAY}
            weight={'500'}
            lineHeight={'24px'}
          >
            {option.name}
          </StyledText>
        </Option>
      ))}
    </ButtonWrapper>
  );
};
