import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../app/state/user';
import { CommonButton, ButtonIconType } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { ManageTableType } from '../../ManageLayout';
import { useTheme } from '@cthings.co/styled-components';

type StyledButtonProps = {
  editInProcess?: boolean;
};

const StyledButton = styled(CommonButton)<StyledButtonProps>`
  justify-content: center;
  opacity: ${({ editInProcess }) => (editInProcess ? '0.5' : '1')};
  cursor: ${({ editInProcess }) => (editInProcess ? 'auto' : 'pointer')};
  font-size: 14px;
  ${media.tablet} {
    display: none;
  }
  & > svg {
    margin-right: 4px;
  }
`;

type AddButtonType = {
  handleAdd?: () => void;
  type: ManageTableType;
};

export const AddButton: FC<AddButtonType> = ({ handleAdd, type }) => {
  const { colors } = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <StyledButton
      width={'126px'}
      iconType={ButtonIconType.ADD}
      onClick={handleAdd}
      colors={{
        backgroundHover: colors.secondary2,
      }}
    >
      {languageStrings.manageAddButton}
    </StyledButton>
  );
};
