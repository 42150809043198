import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emptyAccess, selectAccess, selectLanguage, setAccess } from '../app/state/user';
import { API_URL } from '../consts';
import { View } from '../routes';
import { HttpService } from '../utils/http';
import { useCustomHistory } from '../utils/react-router-dom-abstraction';

export const useUserAccess = () => {
  const dispatch = useDispatch();
  const customHistory = useCustomHistory();

  const language = useSelector(selectLanguage);
  const globalAccess = useSelector(selectAccess);
  const [data, setData] = useState(globalAccess);

  //@TODO Alex test UseEffect()
  useEffect(() => {
    const url = `${API_URL}/v2/users/sectionsaccess`;

    (async () => {
      try {
        const responseData = (await HttpService.get(url)).data.payload;
        const { billing, dashboard, insight, management, problems, settings } = responseData;
        const { client, user, devices } = management;
        const permission =
          dashboard || insight || problems || settings || client.view || user.view || devices.view || !!billing;
        const isBillingDetailsNotFilled = !billing?.details_filled;
        dispatch(setAccess({ ...responseData, permission, isBillingDetailsNotFilled }));
        setData({ ...responseData, permission, isBillingDetailsNotFilled });
        !permission && customHistory.push(View.ERROR_403);
      } catch (e) {
        dispatch(setAccess({ ...emptyAccess, permission: false }));
        setData({ ...emptyAccess, permission: false });
      }
    })();
  }, [dispatch, language]);
  //@NOTE don't use effect here since this hook is the only place allowed to edit section access
  /*useEffect(() => {
    setData(globalAccess);
  }, [globalAccess]);*/

  return data;
};
