import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { DeviceLocationItem } from './components/DeviceLocationItem';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';
import { View } from '../../routes/routeInterfaces';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';

const Wrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.tablet} {
    width: auto;
    flex-direction: column;
  }
  ${media.phone} {
    flex-direction: column;
  }
`;

export interface DeviceLocationProps {
  data: any;
  deviceId: string;
}

export const DeviceInfo: FC<DeviceLocationProps> = ({ data, deviceId }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage);
  const [media] = useStyleContext();

  const customHistory = useCustomHistory();

  const { deviceName, longitude, latitude, location } = data;

  return (
    <Wrapper>
      <DeviceLocationItem
        deviceTitle={languageStrings.deviceLabel + ' ID:'}
        deviceName={deviceName}
        longitude={longitude}
        latitude={latitude}
        location={location}
        media={media}
        onClick={() => {
          const to = View.USER_MANAGE_DEVICES_DETAILS;
          customHistory.push(to, {
            pathParams: { id: deviceId },
          });
        }}
      />
    </Wrapper>
  );
};
