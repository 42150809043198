import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { NavLink } from '../../utils/react-router-dom-abstraction';
import { Text } from '@bit/first-scope.text';
//images
import { ReactComponent as LogoMpwik } from '../../assets/mpwik-logo.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  z-index: var(--navigation-layer);
  top: 0;
  left: 0;
  bottom: 0;
  width: 55px;
  height: 100%;
  padding: 30px 15px 30px 0;
  background-color: ${colorFetch('white')};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow3};
  border-radius: ${({ theme }) => `0 ${theme.borderRadius.primary} ${theme.borderRadius.primary} 0`};
  transition: width 0.3s ease;
  &.open {
    width: 180px;
  }
  ${media.tablet} {
    padding: 0 20px;
    right: 0;
    width: 100%;
    height: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border-bottom: 1px solid;
    border-color: ${colorFetch('gray3')};
  }
  ${media.phone} {
    justify-content: center;
  }
`;

export const IconArrowContainer = styled.div`
  padding-left: 20px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  &.open {
    justify-content: space-between;
  }
  ${media.tablet} {
    display: none;
  }
`;

type LogoContainerProps = {
  height: string;
};

export const LogoContainer = styled.div<LogoContainerProps>`
  height: ${({ height }) => height};
  width: 0;
  overflow: hidden;

  transition: 0.3s;
  &.open {
    flex: 1;
  }
`;

export const StyledLogo = styled.img`
  transition: none;
  width: 80px;
  height: 30px;
`;

export const StyledLogoMpwik = styled(LogoMpwik)`
  transition: none;
  width: 80px;
  height: 24px;
  & > rect {
    width: 100%;
    height: 100%;
  }
`;

export const ArrowContainer = styled.div`
  transition: all 0.3s ease;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  &.open {
    display: block;
    flex: 0;
    transform: rotate(-180deg);
    ${media.tablet} {
      flex: none;
      display: flex;
    }
  }
  ${media.tablet} {
    flex: none;
    display: flex;
  }
`;

export const BodyNavigation = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  ${media.tablet} {
    flex-direction: row;
  }
  ${media.phone} {
    width: 100%;
    justify-content: center;
  }
`;

export const MoreWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
`;

export const MoreButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-decoration: none;
  ${media.tablet} {
    flex-direction: column;
  }
`;

export const StyledMoreText = styled(Text)`
  margin-left: 10px;
  text-decoration: none;
  ${media.tablet} {
    margin-left: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    &:hover {
      color: ${colorFetch('gray1')};
    }
  }
`;

export const StyledText = styled(Text)`
  margin-left: 10px;
  text-decoration: none;
  &:hover {
    color: ${colorFetch('gray1')};
  }
`;

export const MoreModal = styled.div`
  box-sizing: border-box;
  width: 140px;
  height: 0;
  padding: 12px 15px;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-100%, calc(-100% - 20px));
  background-color: ${colorFetch('white')};
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  overflow: visible hidden;
  transition: all 0.3s ease;
  &.open {
    height: max-content;
    opacity: 1;
    pointer-events: auto;
  }
`;

export const StyledMoreNavlink = styled(NavLink)`
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  &.active {
    font-weight: 600;
    & > svg,
    & > svg > path {
      stroke: ${colorFetch('primary')};
    }
    & > span {
      color: ${colorFetch('primary')};
    }
  }
  & > svg,
  & > svg > path {
    stroke: ${colorFetch('black')};
  }
`;

export const LogoutButton = styled.button`
  box-sizing: border-box;
  border: none;
  outline: transparent;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  & svg,
  & svg path {
    stroke: ${colorFetch('black1')};
  }
`;

export const StyledLogoutText = styled(Text)`
  margin-left: 10px;
`;
