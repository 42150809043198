import React from 'react';

export const boldString = (text: string, boldStringColor: string) => {
  const matches = text.matchAll(/\n([\s\S]*?)\n/g);
  const boldStrings = Array.from(matches, (x) => x[1]);
  const normalStrings = text.split(/\n([\s\S]*?)\n/).map((item: string) => item);
  const { length: boldLength } = boldStrings;
  const result = boldStrings.map((elem, i) => (
    <>
      {normalStrings[2 * i]}
      <b style={{ color: boldStringColor }}>{elem}</b>
      {i === boldLength - 1 && normalStrings[2 * i + 2]}
    </>
  ));

  return result;
};
