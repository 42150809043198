import React, { useState, useEffect } from 'react';
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '../text/Text';
const Wrapper = styled.div `
  width: max-content;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  padding: 0 8px;
  cursor: pointer;
  & > span {
    font-weight: 700;
    line-height: 15px;
    color: ${(props) => props.color};
  }
`;
export var BadgesType;
(function (BadgesType) {
    BadgesType["MISC_TEAL"] = "MISC_TEAL";
    BadgesType["MISC_PURPLE"] = "MISC_PURPLE";
    BadgesType["SUCCESS"] = "SUCCESS";
    BadgesType["WARNING"] = "WARNING";
    BadgesType["DANGER"] = "DANGER";
    BadgesType["PRIMARY"] = "PRIMARY";
    BadgesType["REMINDER"] = "REMINDER";
})(BadgesType || (BadgesType = {}));
export const Badges = ({ type, content }) => {
    const theme = useTheme();
    const [background, setBackground] = useState('');
    const [fontColor, setFontColor] = useState('');
    const { teal2, teal3, purple2, purple3, green7, green8, orange4, orange5, red5, red2, light, blue3, yellow1, yellow2, } = theme.colors;
    useEffect(() => {
        switch (type) {
            case BadgesType.MISC_TEAL:
                setBackground(teal2);
                setFontColor(teal3);
                break;
            case BadgesType.MISC_PURPLE:
                setBackground(purple2);
                setFontColor(purple3);
                break;
            case BadgesType.SUCCESS:
                setBackground(green7);
                setFontColor(green8);
                break;
            case BadgesType.WARNING:
                setBackground(orange4);
                setFontColor(orange5);
                break;
            case BadgesType.DANGER:
                setBackground(red5);
                setFontColor(red2);
                break;
            case BadgesType.PRIMARY:
                setBackground(light);
                setFontColor(blue3);
                break;
            case BadgesType.REMINDER:
                setBackground(yellow1);
                setFontColor(yellow2);
                break;
            default:
                setBackground('');
                setFontColor('');
        }
    }, [
        type,
        teal2,
        teal3,
        purple2,
        purple3,
        green7,
        green8,
        orange4,
        orange5,
        red5,
        red2,
        light,
        blue3,
        yellow1,
        yellow2,
    ]);
    return (React.createElement(Wrapper, { type: type, backgroundColor: background, color: fontColor, theme: theme },
        React.createElement(Text, { type: TextType.TEXT_10_WHITE }, content)));
};
Badges.defaultProps = {};
