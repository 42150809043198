import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const usersTableSlice = createSlice({
  name: 'usersTable',
  initialState,
  reducers: reducers,
});

export const {
  setUsersTable,
  setField,
  updateUserLocal,
  deleteUser,
  deleteCheckedUsers,
  addUser,
  addUserMobile,
  increaseTotalItems,
  toggleIsChecked,
  toggleIsCheckedAll,
  toggleIsEdit,
  toggleIsEditInProcessUser,
  setDefaultIsEditProcessUser,
} = usersTableSlice.actions;

export default usersTableSlice.reducer;
