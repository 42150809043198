import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { handleTypedInFromKeyboard } from '../../../../api/provisioning';
import { selectLanguageStrings } from '../../../../app/state/user';
import { ReactComponent as NidCthings } from '../../../../assets/nidCthings.svg';
import { ReactComponent as SUPLogo } from '../../../../assets/SUPLogo.svg';
import withAuth from '../../../../features/authentication/withAuth';
import { useNotifierFunctions } from '../../../../features/notifier2';
import { View } from '../../../../routes/routeInterfaces';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useCustomHistory } from '../../../../utils/react-router-dom-abstraction';
import { OneNumberInput } from './components/oneNumberInput/OneNumberInput';
import { isNid } from '../../../../consts';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
  background-color: ${colorFetch('gray5')};
  ${media.phone} {
    flex-direction: column;
  }
`;

type WrapperTitleProps = {
  isNid?: boolean;
};

const WrapperTitle = styled.div<WrapperTitleProps>`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: ${({ isNid }) => (isNid ? '140px' : '40px')};
`;

const WrapperText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 20px;
  color: gray1;
`;

const WrapperDescription = styled.div`
  display: grid;
  grid-template-areas: 'a b c d e f g h j k';
  margin-top: 35px;
  ${media.phone} {
    grid-template-areas:
      'a b c d e'
      'f g h j k';
    row-gap: 20px;
  }
`;

const NextButton = styled(CommonButton)`
  width: 240px;
  height: 40px;
  margin-top: 40px;
  ${media.phone} {
    width: calc(100% - 32px);
  }
`;

const ProvisioningAdditionalDevicePlain = () => {
  const languageStrings = useSelector(selectLanguageStrings);
  const initialInputsState = ['', '', '', '', '', '', '', '', '', ''];
  const [numbers, setNumbers] = useState(initialInputsState);
  const [selectedNumberIndex, setSelectedNumberIndex] = useState(-1);
  const theme = useTheme();
  const { secondary2, secondary3, primary, pureWhite } = theme.colors;
  const { addNotification } = useNotifierFunctions();
  const [isInProgressCallApi, setIsInProgressCallApi] = useState(false);

  const history = useCustomHistory();

  const isDisabledNextButton = numbers.includes('');
  const secret = numbers.join('');

  const unFocusAll = () => {
    setSelectedNumberIndex(-1);
  };

  const handleChange = (e: any, index: number, lastIndex: number) => {
    const valueInputEvent = e?.currentTarget?.value;
    const valueInput = valueInputEvent.replace(/[^\d]/g, '');
    const splitArray = valueInput.split('');
    const valueArray = splitArray.length ? splitArray : [''];
    const valueLength = valueArray.length;
    const finalLength = valueLength + index > lastIndex ? lastIndex + 1 : valueLength + index;

    setNumbers((prev) => [
      ...prev.slice(0, index),
      ...valueArray.slice(0, finalLength - index),
      ...prev.slice(finalLength),
    ]);

    if (valueArray[0] !== '') {
      finalLength - 1 === lastIndex ? unFocusAll() : setSelectedNumberIndex(finalLength);
    }
  };

  const redirectToProvidePage = (secret: any, transportId: any, hasAccount: boolean, depth: number, limits: any) => {
    const to = View.PROVISIONING_DEVICE;
    to &&
      history.push(to, {
        state: { secret, transportId, hasAccount, depth, limits },
      });
  };

  const handleNextButton = () => {
    setIsInProgressCallApi(true);
    handleTypedInFromKeyboard(secret, addNotification, languageStrings, redirectToProvidePage, setIsInProgressCallApi);
  };

  const handleKeyUp = (e: any, index: number, isLast: boolean) => {
    if (e.keyCode === 13) {
      isLast ? unFocusAll() : setSelectedNumberIndex(index + 1);
    }
  };

  const handleFocus = useCallback(
    (index: number) => {
      selectedNumberIndex !== index && setSelectedNumberIndex(index);
    },
    [selectedNumberIndex],
  );

  const onClickOutsideHandler = useCallback(() => {
    unFocusAll();
  }, []);

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  return (
    <Wrapper>
      {isNid ? <NidCthings /> : <SUPLogo />}
      <WrapperTitle isNid={isNid}>{languageStrings.additionalDeviceAddDevice}</WrapperTitle>
      <WrapperDescription>
        {numbers.map((value, index) => (
          <OneNumberInput
            value={value}
            key={index}
            showDash={numbers.length % 2 === 0 && numbers.length / 2 === index + 1}
            onChange={(e: any) => handleChange(e, index, numbers.length - 1)}
            onFocus={() => handleFocus(index)}
            onKeyUp={(e: any) => handleKeyUp(e, index, numbers.length - 1 === index)}
            focused={selectedNumberIndex === index}
            inputType={'tel'}
          />
        ))}
      </WrapperDescription>
      <WrapperText>{languageStrings.additionalDeviceEnterCode}</WrapperText>
      <NextButton
        onClick={handleNextButton}
        colors={{
          main: pureWhite,
          mainHover: pureWhite,
          background: isDisabledNextButton ? secondary3 : primary,
          backgroundHover: isDisabledNextButton ? secondary3 : secondary2,
          border: isDisabledNextButton ? secondary3 : primary,
          borderHover: isDisabledNextButton ? secondary3 : primary,
        }}
        isLoadingInProcess={isInProgressCallApi}
        disabled={isInProgressCallApi}
      >
        {languageStrings.additionalDeviceNextButton}
      </NextButton>
    </Wrapper>
  );
};

export const ProvisioningAdditionalDevice = withAuth(ProvisioningAdditionalDevicePlain);
