import { API_URL } from '../consts';
import { ErrorEventType } from '../types/error';
import { HttpService } from '../utils/http';
import { useApi } from '../features/apiBuilder/useApiBuilder';

export const handleTypedInFromKeyboard = (
  secret: any,
  handleAddNotification?: Function,
  languageStrings?: any,
  redirectToProvidePage?: any,
  setIsInProgressCallApi?: any,
) => {
  const url = `${API_URL}/v2/devices/qrcode/keyboard?secret=${secret}`;

  (async () => {
    try {
      let responseData = (await HttpService.get(url)).data;
      redirectToProvidePage(
        responseData?.secret,
        responseData?.transport_id,
        responseData?.has_account,
        responseData?.depth,
        responseData?.limit,
      );
    } catch (e) {
      const error = e as ErrorEventType;
      handleAddNotification &&
        handleAddNotification({
          message: error?.response?.data?.detail,
          type: 'error',
        });
    }
    setIsInProgressCallApi && setIsInProgressCallApi(false);
  })();
};

export const uploadImage = (
  files: any[],
  handleAddNotification: any,
  languageStrings: any,
  secretCode?: string | null,
  transportId?: string,
) => {
  const urlUploadImage = `${API_URL}/v2/devices/${transportId}/images?secret=${secretCode}`;
  const bodyFormData = new FormData();

  for (let i = 0; i < files.length; i++) {
    bodyFormData.append('files', files[i]);
  }
  (async () => {
    try {
      if (files.length > 0) {
        HttpService.post(urlUploadImage, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
    } catch (e) {
      handleAddNotification({
        message: languageStrings.anUnexpectedErrorHasOccurred,
        type: 'error',
      });
    }
  })();
};

export const assignDeviceToClient = (
  secret: string,
  transport_id: string,
  handleAddNotification: Function,
  data: any,
  languageStrings?: any,
  redirectToInsight?: any,
  setIsDeployedInProgress?: any,
) => {
  const url = `${API_URL}/v2/devices/qrcode/assign?transport_id=${transport_id}&secret=${secret}`;

  (async () => {
    try {
      let { payload } = (await HttpService.post(url, data)).data;
      const deviceId = payload.id;
      const type = payload.type_id;

      setIsDeployedInProgress(false);
      handleAddNotification({
        message: languageStrings.success,
        type: 'success',
      });
      redirectToInsight(type, deviceId);
    } catch (e) {
      handleAddNotification({
        message: languageStrings.anUnexpectedErrorHasOccurred,
        type: 'error',
      });
      setIsDeployedInProgress(false);
    }
  })();
};

export const useBillingPlan = (device: string, secret: string) => {
  const url = `${API_URL}/v2/billing-plan/provisioning/${device}?secret=${secret}`;
  const keywords = [''];
  const [data] = useApi({
    keywords,
    url,
    transformResponse: (data) => data.payload,
  });

  return [data];
};

export const useGetProvisioningCustomers = (secret: string) => {
  const [localCustomers] = useApi({
    defaultValue: null,
    url: `${API_URL}/v2/clients/provisioning?secret=${secret}`,
    keywords: [],
    transformResponse: (data) => data.payload.map((item: any) => ({ id: item.id, name: item.name, isSelected: false })),
  });

  return [localCustomers as { id: string; name: string }[]];
};
