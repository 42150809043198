import { Text, TextType } from '@bit/first-scope.text';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { DeviceControl } from './components/deviceControl/DeviceControl';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 40px !important;

  ${media.tablet} {
    padding: 0 16px;
  }
`;

type MapWrapperProps = {
  height: number | null;
};

const MapWrapper = styled.div<MapWrapperProps>`
  height: ${({ height }) => (height ? `calc(${height + 'px'} - 146px)` : `calc(100vh - 146px)`)};
`;

export const Insights = () => {
  const [boundingRect, setBoundingRect] = useState(null);

  const mapRef = useCallback((node: any) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const [selectedPos, setSelectedPos] = useState(null);

  const locationTimerId = useRef(null as any);

  useEffect(() => {
    return () => {
      locationTimerId.current && clearTimeout(locationTimerId.current);
    };
  }, []);

  const languageStrings = useSelector(selectLanguageStrings);

  const height = use100vh();

  return (
    <Wrapper>
      <StyledText type={TextType.TITLE_PRIMARY}>{languageStrings.insightTitle}</StyledText>
      <MapWrapper ref={mapRef} height={height}>
        {boundingRect && <DeviceControl boundingRect={boundingRect} selectedPos={selectedPos} />}
      </MapWrapper>
    </Wrapper>
  );
};
