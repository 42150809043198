import { useDispatch, useSelector } from 'react-redux';
import { selectDevices, setDevices } from '../../app/state/insight';
import { selectLanguage } from '../../app/state/user';
import { API_URL } from '../../consts';
import { LsValueType } from '../../enums/LsValueType';
import { useApi } from '../../features/apiBuilder/useApiBuilder';
import { ApiCallType } from '../../utils/http';
import ss from '../../utils/ss';
import { urlEncodeArray } from '../../utils/urlEncode';
import { ParameterType } from '../../app/state/devicesTable/types';

export const useDevices = (
  dimensions: any,
  position: any = { center: [0, 0], radius: -1 },
  keywords: string[],
  lang: any,
) => {
  const value = useSelector(selectDevices);

  const dispatch = useDispatch();

  const { center, radius } = position;

  const triggers = [center[1], center[0], radius, lang];

  // Check if radius actually arrived, -1 corresponds to initial radius value
  const searchParams =
    radius !== -1 ? `&filter_lat=${center[1]}&filter_lng=${center[0]}&filter_radius_kms=${radius}` : '';

  const url = `${API_URL}/v2/devices/datalist/geopivoted?map_pwidth=${Math.floor(
    dimensions.width,
  )}&map_pheight=${Math.floor(dimensions.height)}${searchParams}&lang=${lang}`;

  const condition = dimensions && !!dimensions.width && !!dimensions.height;

  const [localData] = useApi({
    value,
    keywords,
    url,
    triggers,
    condition,
    transformResponse: (response) => response.payload,
    handleSuccess: (data) => dispatch(setDevices(data)),
  });

  return [localData];
};

const deviceParametersKeywords = ['DEVICE_PARAMETERS'];

export const useDeviceParameters = (id: string) => {
  const url = `${API_URL}/v2/devices/${id}/params`;
  const language = useSelector(selectLanguage);
  const condition = !!id;

  const [localData] = useApi({
    url,
    keywords: deviceParametersKeywords,
    callType: ApiCallType.GET,
    condition,
    triggers: [language],
    transformResponse: (response) => response.payload,
  });

  return localData || [];
};

const deviceAdditionalInfoKeywords = ['DEVICE_ADDITIONAL_INFO'];

export const useGetAdditionalInfoForExport = () => {
  //@TODO Alex discuss it with Bartek. We need to get this strings from backend side
  const permittedArr = ['connectivity', 'deployment', 'params', 'network'];
  const url = `${API_URL}/v2/devices/export`;

  const [data] = useApi({
    keywords: deviceAdditionalInfoKeywords,
    url,
    defaultValue: [] as ParameterType[],
    transformResponse: (data) => {
      return Object.values(data).map((item: unknown, index: number) => ({
        id: permittedArr[index],
        name: `${item}`,
      }));
    },
  });

  return [data as { id: string; name: string }[]];
};

export type DeviceExportParams = {
  client_ids: string[];
  output_attributes: string[];
};

export const getExportDeviceDetailsUrl = (selectedParams: DeviceExportParams, lang: string) => {
  const encodedClientIds = urlEncodeArray(selectedParams.client_ids);
  const encodedOutputAttributes = urlEncodeArray(selectedParams.output_attributes);
  return `${API_URL}/v2/devices/export/generate?access_token=${ss.get(
    LsValueType.token,
  )}&client_ids=${encodedClientIds}&output_attributes=${encodedOutputAttributes}&lang=${lang}`;
};
