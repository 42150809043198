import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { deployDevice, useRealTimeInsights } from '../../../../../../api/realTimeInsight';
import { resetRealTimeData } from '../../../../../../app/state/insight';
import { selectIsAccess, selectLanguageStrings } from '../../../../../../app/state/user';
import { CardType } from '../../../../../../components/dashboardCard/DashboardCard';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { useLoaderFunctions } from '../../../../../../features/placeholderComponent/loader.context';
import { useMediaType, useWindowSize } from '@cthings.co/styles-utils';
import { DeviceConfig } from '../common/deviceConfig/DeviceConfig';
import { NFCModal } from './components/nFCModal/NFCModal';
import { selectNfcFlag, setNfcFlag } from '../../../../../../app/state/app';
import { PATHS } from '../../../../../../routes/paths';
import { getRequiredDateFormat } from '../../../../../../utils/date/date-format';
import { handleRegisterNewEvent } from '../../../../../../api/devices/historyInsight';
import { DeployBlock } from '../common/deployBlock/DeployBlock';
import { Loader } from '../../../../../../components/placeholders/Loader';
import { InsightsHeader } from '../common/insightsHeader/InsightsHeader';
import { DeviceTitle } from '../common/deviceTitle/DeviceTitle';
import { MaintananceTableBlock } from './components/maintananceTableBlock/MaintananceTableBlock';
import { Cards } from './components/cards/Cards';
import { Content, Wrapper } from '../common/styledComponents/StyledComponents';
import { EventTableBlock } from '../common/eventTableBlock/EventTableBlock';
import { StatusOfCard } from '../../../../../../types/statusOfCards';

interface HydrantDetailsProps {
  pathParams: { id: string; type: string };
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  infoCardData?: any;
}

export const HydrantDetails: FC<HydrantDetailsProps> = ({ infoCardData, pathParams, accessData }) => {
  const id = pathParams.id || '';
  const type = pathParams.type || '';
  const [isLoading, setIsLoading] = useState(false);
  const [deploymentStatus, setDeploymentStatus] = useState<number>();
  const { desktop } = useMediaType();
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = ['HydrantDetailsMap', 'HydrantDetailsCard'];

  const data = useRealTimeInsights(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );

  const languageStrings = useSelector(selectLanguageStrings);
  const nfcFlag = useSelector(selectNfcFlag);

  const dispatch = useDispatch();
  const isAccess = useSelector(selectIsAccess);
  const isInsigthDetailsView = accessData?.view;

  const { addNotification } = useNotifierFunctions();

  const [nfcMessage, setNfcMessage] = useState('');

  const history = useHistory();

  const { address, transport_id: idDevice, deployment_status } = data.info;
  const { rt } = data.data;
  const isDeployed = deployment_status === 2;

  const { internal_humidity, internal_pressure, internal_temp, temp } = rt || {};

  const statusOfValue = (tile_color: number) => {
    return tile_color === 0
      ? StatusOfCard.NORMAL
      : tile_color === 1
      ? StatusOfCard.WARNING
      : tile_color === 2
      ? StatusOfCard.CRITICAL
      : StatusOfCard.NORMAL;
  };

  const cardsArr = [
    {
      title: internal_temp ? internal_temp.tile_name : languageStrings.outletTemperature,
      count:
        internal_temp && !isNaN(internal_temp.measurement)
          ? `${internal_temp.measurement} ${internal_temp.unit}`
          : 'N/A',
      type: CardType.OUTLET_TEMPERATURE,
      statusOfValue: statusOfValue(internal_temp ? internal_temp.tile_color : 0),
    },
    {
      title: internal_humidity ? internal_humidity.tile_name : languageStrings.internalHumidityTitle,
      count:
        internal_humidity && !isNaN(internal_humidity.measurement)
          ? `${internal_humidity.measurement} ${internal_humidity.unit}`
          : 'N/A',
      type: CardType.INTERNAL_HUMIDITY,
      statusOfValue: statusOfValue(internal_humidity ? internal_humidity.tile_color : 0),
    },
    {
      title: internal_pressure ? internal_pressure.tile_name : languageStrings.outletPressure,
      count:
        internal_pressure && !isNaN(internal_pressure.measurement)
          ? `${internal_pressure.measurement} ${internal_pressure.unit}`
          : 'N/A',
      type: CardType.OUTLET_PRESSURE,
      statusOfValue: statusOfValue(internal_pressure ? internal_pressure.tile_color : 0),
    },
    {
      title: temp ? temp.tile_name : languageStrings.internalTemperature,
      count: temp && !isNaN(temp.measurement) ? `${temp.measurement} ${temp.unit}` : 'N/A',
      type: CardType.INTERNAL_TEMPERATURE,
      statusOfValue: statusOfValue(temp ? temp.tile_color : 0),
    },
  ];

  const placeholderCard1Style = desktop ? { margin: '0', width: '100%' } : { margin: '0' };

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({ message: languageStrings.copiedMessage, type: 'info' });
  };

  const onDeployPress = () => {
    setIsLoading(true);
    deployDevice(id, languageStrings, addNotification, setDeploymentStatus, setIsLoading);
  };

  useEffect(() => {
    return () => {
      dispatch(resetRealTimeData());
    };
  }, []);

  useEffect(() => {
    if (isAccess && !isInsigthDetailsView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isInsigthDetailsView]);

  useEffect(() => {
    if (nfcFlag && idDevice && id) {
      const currentDate = new Date();
      const nfcData = {
        event_id: 5,
        is_problem: false,
        device_id: id,
        transport_id: idDevice,
        address,
        ts: getRequiredDateFormat(currentDate, 'YYYY-MM-DDTHH:mm:ss'),
      };
      handleRegisterNewEvent(nfcData, setNfcMessage);
    }
  }, [nfcFlag, idDevice, id]);

  useEffect(() => {
    setDeploymentStatus(deployment_status);
  }, [deployment_status]);

  return (
    <Wrapper>
      <InsightsHeader languageStrings={languageStrings} address={address} type={type} id={id} isDeployed={isDeployed} />
      <Content>
        <DeviceTitle
          languageStrings={languageStrings}
          address={address}
          deviceId={idDevice}
          handleCopyText={handleCopyText}
        />
        {deploymentStatus !== undefined ? (
          isDeployed ? (
            <>
              <Cards
                cards={cardsArr}
                languageStrings={languageStrings}
                placeholderCard1Style={placeholderCard1Style}
                infoCardData={infoCardData}
              />
              <EventTableBlock languageStrings={languageStrings} pathParams={pathParams} />
              <MaintananceTableBlock
                languageStrings={languageStrings}
                pathParams={pathParams}
                accessData={accessData}
              />
              <DeviceConfig id={id} photos={data.info.images} />
            </>
          ) : (
            <>
              <DeployBlock
                id={id}
                languageStrings={languageStrings}
                isLoading={isLoading}
                deploymentStatus={deploymentStatus}
                photos={data.info.images}
                onDeployPress={onDeployPress}
                infoCardData={infoCardData}
              />
            </>
          )
        ) : (
          <Loader />
        )}
      </Content>

      <NFCModal
        isOpenModal={nfcFlag && !!nfcMessage}
        handleCloseModal={() => {
          dispatch(setNfcFlag(false));
        }}
        languageStrings={languageStrings}
        message={nfcMessage}
      />
    </Wrapper>
  );
};
