import React from 'react';

export const CoverIcon = ({ primaryColor, secondaryColor }: { primaryColor: string; secondaryColor: string }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32.001" r="24.25" stroke={primaryColor} stroke-width="3.5" />
      <circle cx="32" cy="32.001" r="16.25" stroke={secondaryColor} stroke-width="3.5" />
    </svg>
  );
};
