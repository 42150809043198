import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as CardIcon } from './assets/SUP.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 310px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colorFetch('white')};
  box-shadow: 0px 2px 8px rgba(96, 97, 112, 0.06);
  border-radius: 8px;
  padding: 14px 16px 10px 20px;
  box-sizing: border-box;
  ${media.tablet} {
    width: 100%;
  }
`;

const Title = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1')};
` as any; // @TODO fix type

const MainInfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MainInfo = styled(Text)`
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: ${colorFetch('black1')};
` as any; // @TODO fix type

interface CardManningPairDeviceProps {
  data: any;
  languageStrings: any;
}

export const CardManningPairDevice: React.FC<CardManningPairDeviceProps> = ({ data, languageStrings }) => {
  return (
    <Wrapper>
      <Title>{languageStrings.flowLabel}</Title>
      <MainInfoBlock>
        <MainInfo>
          {data?.data?.rt?.water_flow
            ? data.data.rt.water_flow.measurement + ' ' + data.data.rt.water_flow.unit
            : 'N/A'}
        </MainInfo>
        <CardIcon />
      </MainInfoBlock>
    </Wrapper>
  );
};
