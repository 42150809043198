import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { RoutesData } from '../../../routes/types';

export const requestPush = (state: WritableDraft<RoutesData>, action: PayloadAction<any>) => {
  const key = state.nextKey + 1;
  state.nextKey = key;
  state.list = [{ route: action.payload, key }, ...state.list];
};
export const removeRequest = (state: WritableDraft<RoutesData>, action: PayloadAction<number>) => {
  state.list = state.list.filter((route) => route.key !== action.payload);
};

export const reducers = {
  requestPush,
  removeRequest,
};
