import React, { FC, ReactNode } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  width?: string;
  backgroundColor?: string;
  padding?: string;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ width }) => width || 'auto'};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : colorFetch('pureWhite')({ theme })};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow1};
  border-radius: ${({ theme }) => theme.borderRadius.additional16};
  position: relative;
  padding: ${({ padding }) => padding || '24px 14px'};
  // @TODO fix media types
  ${media.phone} {
    overflow-y: auto;
    border-radius: ${({ theme }: any) => {
      const { additional16 } = theme.borderRadius;
      return `${additional16} ${additional16} 0 0`;
    }};
    padding: 24px 16px 30px 16px;
  }
`;

export interface ModalContainerProps {
  children: ReactNode;
  backgroundColor?: string;
  padding?: string;
  width?: string;
  className?: string;
}

export const ModalContainer: FC<ModalContainerProps> = ({ children, backgroundColor, padding, width, ...props }) => {
  const theme = useTheme();

  return (
    <Wrapper width={width} backgroundColor={backgroundColor} theme={theme} {...props} padding={padding}>
      {children}
    </Wrapper>
  );
};

ModalContainer.defaultProps = {
  width: '480px',
};
