import { FieldsType } from '../../../../utils/validation';

export enum InputType {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
}

export type RoleProps = {
  name?: string;
  id?: string;
};

export type UserValuesProps = {
  company?: string;
  user?: { name?: string; id?: string };
  role: RoleProps[];
};

export type InputFieldName = 'name' | 'email' | 'role';

export type InputStructure = {
  fieldName: InputFieldName | 'company';
  fieldType: InputType;
  label: string;
  placeholder: string;
  validationRule?: FieldsType;
  isDisabled: boolean;
};
