import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as LocationIcon } from '../../../../../../../assets/location-secondary.svg';
import { CopyButton } from '../../../../../../../components/copyButton/CopyButton';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Title = styled.div``;

const TitleDevice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;
  ${media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const GeoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextDeviceID = styled(Text)`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: ${colorFetch('gray2')};
` as any; // @TODO fix type

const TextDevice = styled(Text)`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colorFetch('gray1')};
` as any; // @TODO fix type

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  &:hover {
    & > span {
      color: ${colorFetch('gray2')};
    }
    & > svg > path {
      stroke: ${colorFetch('gray3')};
    }
  }
  ${media.phone} {
    margin-left: 0px;
  }
`;

export const DeviceTitle = ({
  languageStrings,
  address,
  deviceId,
  handleCopyText,
}: {
  languageStrings: any;
  address: any;
  deviceId: string;
  handleCopyText: (deviceId: string) => void;
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;

  return (
    <>
      <TitleRow>
        <Title>
          <Text type={TextType.TITLE_PRIMARY}>{address.line1 ? address.line1 : 'N/A'}</Text>{' '}
          <Text type={TextType.TITLE_SECONDARY}>{`, ${address.city ? address.city : 'N/A'}`}</Text>
        </Title>
        <GeoWrapper>
          <LocationIcon />
          <Text type={TextType.HELPER_TEXT_1} color={gray2}>
            {address ? `${address.geotag.lat}, ${address.geotag.lng}` : 'N/A'}
          </Text>
        </GeoWrapper>
      </TitleRow>
      <TitleDevice>
        <TextDeviceID>{languageStrings.deviceIdText}</TextDeviceID>
        <CopyWrapper onClick={() => handleCopyText(deviceId)}>
          <TextDevice>{deviceId}</TextDevice>
          {deviceId && <CopyButton />}
        </CopyWrapper>
      </TitleDevice>
    </>
  );
};
