import React, { FC, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { ManholeInfo } from '../manholeInfo/ManholeInfo';
import { ManholeModal } from '../manholeModal/ManholeModal';
import { GroundWaterImage } from './components/GroundWaterImage';
import { HydrantImage } from './components/HydrantImage';
import { ManholeImage } from './components/ManholeImage';
import { ManholeWithWavesImage } from './components/ManholeWithWavesImage';
import { FlowImage } from './components/FlowImage';
import { DeviceType } from '../../enums/DeviceType';
import { WaterFlowInfoModal } from '../manholeInfo/components/waterFlowInfoModal/WaterFlowInfoModal';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { SmartAlarmImage } from './components/SmartAlarmImage';

export enum ManholesType {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
  NONE = 'NONE',
}

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border: none;
  position: relative;
  padding: 0;
  transition: all 0.3s ease;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  & > svg .opacityFirstLayer {
    opacity: 0;
    transition: opacity 0.2s linear;
    transition-delay: 120ms;
  }
  & > svg .opacitySecondLayer {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  &:hover > svg .opacityFirstLayer,
  &:hover > svg .opacitySecondLayer {
    opacity: 0.2;
  }
  &.open > svg .opacityFirstLayer,
  &.open > svg .opacitySecondLayer {
    opacity: 0.2;
  }
`;

const StyledManholeInfo = styled(ManholeInfo)`
  height: initial;
  left: -22px;
  top: 56px;
  z-index: var(--modal-first-layer);
  position: absolute;
  ${media.phone} {
    left: -89px;
  }
`;

const StyledMultiInfo = styled(ManholeModal)`
  left: -22px;
  top: 56px;
  z-index: var(--modal-first-layer);
  position: absolute;
  ${media.phone} {
    left: -89px;
  }
`;

type ClusterProps = {
  size: number;
  fontSize: string;
};

const Cluster = styled.div<ClusterProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colorFetch('white')};
  font-family: 'Poppins';
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
  line-height: 25px;
  border-radius: 50%;
  cursor: pointer;
  /* transform: translate(0, -2px); */
  position: relative;
  transition: all 0.3s ease;
  border: 3px solid;
  background-color: ${colorFetch('primary')};
  border-color: ${colorFetch('secondary3')};
  &:hover {
    background-color: ${colorFetch('secondary2')};
    border-color: ${colorFetch('primary')};
  }
  /* &:hover::after,
  &.open:after {
    content: '';
    position: absolute;
    width: ${({ size }: any) => `${size + 7}px`};
    height: ${({ size }: any) => `${size + 7}px`};
    background-color: ${({ activeColor }: any) => activeColor};
    z-index: -1;
    border-radius: 50%;
    transition: all 0.3s ease;
  } */
`;

export interface ManholeButtonProps {
  type: ManholesType;
  staticDisplay?: boolean;
  values?: any[];
  devices?: any[];
  deviceType?: string;
  history?: any;
  className?: string;
  languageStrings?: any;
  isReq?: boolean;
  match?: any;
}

export const ManholeButton: FC<ManholeButtonProps> = ({
  type,
  staticDisplay,
  values = [],
  devices = [],
  deviceType = '',
  history,
  languageStrings,
  isReq = false,
  ...props
}) => {
  const theme = useTheme();

  const isMultipuleDevices = values.length > 1;

  const isInfoOpen = !staticDisplay && isReq && values && !isMultipuleDevices;
  const isMultiInfoOpen = !staticDisplay && isReq && values && isMultipuleDevices;

  const clusterSize = 50;
  const clusterStringLength = `${values.length}`.length;
  const fontSize = 18 - (clusterStringLength - 3) + 'px';

  const getTypeDevice = (typeDevice: string, colorType: ManholesType) => {
    const typesDevice: any = {
      smart_manhole: (() => <ManholeImage colorType={colorType} />)(),
      smart_alarm: (() => <SmartAlarmImage colorType={colorType} />)(),
      smart_cap: (() => <HydrantImage colorType={colorType} />)(),
      water_ground_meter: (() => <GroundWaterImage colorType={colorType} />)(),
      hydrant: (() => <HydrantImage colorType={colorType} />)(),
      manhole_flow: (() => <ManholeWithWavesImage colorType={colorType} />)(),
      water_flow: (() => <FlowImage colorType={colorType} />)(),
      cluster: (() => (
        <Cluster theme={theme} fontSize={fontSize} size={clusterSize} className={!staticDisplay && isReq ? 'open' : ''}>
          {values.length}
        </Cluster>
      ))(),
    };
    return typesDevice[typeDevice];
  };

  const [flowArr, setFlowArr] = useState<any>([]);

  useEffect(() => {
    isMultipuleDevices
      ? values.forEach((el: any) => {
          const isWaterFlowInfo = values.some((item: any) => item.waterFlowInfo);
          setFlowArr((arr: any) => (el.waterFlowInfo ? [...arr, el.waterFlowInfo] : isWaterFlowInfo ? [...arr] : null));
        })
      : setFlowArr((arr: any) => (values[0].waterFlowInfo ? [values[0].waterFlowInfo] : null));
  }, [values]);

  return (
    <Wrapper className={!staticDisplay && isReq ? 'open' : ''} {...props}>
      <Button className={!staticDisplay && isReq ? 'open' : ''}>
        {!staticDisplay ? getTypeDevice(values[0].iconType, type) : getTypeDevice(deviceType, type)}
      </Button>
      {isInfoOpen &&
        (values[0].iconType === DeviceType.WATER_FLOW ? (
          <WaterFlowInfoModal
            isOpen={isReq}
            isClicked={!staticDisplay && isReq}
            flowInfoData={flowArr}
            history={history}
            languageStrings={languageStrings}
            {...(values as any)[0]}
          />
        ) : (
          <StyledManholeInfo
            isOpen={isReq}
            isClicked={!staticDisplay && isReq}
            flowInfoData={flowArr}
            history={history}
            languageStrings={languageStrings}
            {...(values as any)[0]}
          />
        ))}
      {isMultiInfoOpen && (
        <StyledMultiInfo
          isOpen={isReq}
          isClicked={!staticDisplay && isReq}
          manholes={values}
          flowInfoData={flowArr}
          history={history}
          languageStrings={languageStrings}
        />
      )}
    </Wrapper>
  );
};

ManholeButton.defaultProps = {
  type: ManholesType.NONE,
  values: [
    {
      address: 'Brest, Belarus',
      coordinates: '5551.4444 7895.5854',
      temperature: '54',
      waterLevel: '5',
      date: '21.01.2020',
      time: '9:54',
    },
  ],
};
