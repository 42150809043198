import organisationsReducer from './slice';

export * from './consts';
export * from './data';
export * from './selectors';
export * from './slice';
export * from './types';
export * from './utils';

export default organisationsReducer;
