var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../styles/media';
import { CloseButton } from '../closeButton/CloseButton';
import { LocationImage } from './components/LocationImage';
export const ModalSearch = styled.div `
  position: absolute;
  z-index: 9999999999;
  bottom: -8px;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows.additionalShadow4};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  min-height: 100px;
  max-height: 270px;
  padding: 20px 8px 20px 20px;
  box-sizing: border-box;
`;
const Button = styled.button `
  width: ${(props) => (props.isOpen ? '205px' : '43px')};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: primary;
  outline: none;
  border: none;
  border-radius: ${(props) => `0 ${props.theme.borderRadius.primary} ${props.theme.borderRadius.primary} 0`};
  cursor: pointer;
  padding: 6px 8px 6px 7px;
  transition: all 0.3s ease;
  & > svg {
    width: 28px;
    height: 28px;
  }
  &:hover {
    background-color: secondary2;
  }
  & > svg > path {
    transition: all 0.3s ease;
    stroke: ${(props) => props.theme.colors.white};
  }
  &:hover > svg > path {
    transition: fill 0.3s ease;
    fill: ${(props) => props.theme.colors.secondary2};
  }
  ${media.tablet `
  width: 43px;
  & > svg > path {
    fill: ${(props) => (props.isOpen ? props.theme.colors.white : props.theme.colors.primary)};
  }
  & > svg > path:nth-child(2n) {
    fill: ${(props) => props.theme.colors.primary};
  }
  `}
`;
const LocationWrapper = styled.div `
  width: 164px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 5px;
  ${media.tablet `
    display: none;
  `}
`;
const InputLocation = styled.input `
  color: white;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: 100%;
  outline: transparent;
  border: none;
`;
const ModalLocation = styled(ModalSearch) `
  padding: 12px;
`;
const ContainerForText = styled.div ``;
const ContainerRows = styled.div `
  max-height: 230px;
  box-sizing: border-box;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 18px;
  /* полоса прокрутки (скроллбар) */
  /* &::-webkit-scrollbar {
    width: 5px;
    background-color: secondaryBlue3;
    opacity: 0.6;
  } */
  /* ползунок скроллбара */
  /* &::-webkit-scrollbar-thumb {
    background-color: lighter;
    border-radius: 2px;
  } */
`;
const LocationRow = styled.p `
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
`;
export const LocationButton = (_a) => {
    var { onOpen, onClose, isOpen, location, count, handleLocationChange, locations, setSelectedPos } = _a, props = __rest(_a, ["onOpen", "onClose", "isOpen", "location", "count", "handleLocationChange", "locations", "setSelectedPos"]);
    const [openModal, setOpenModal] = useState(true);
    const theme = useTheme();
    const { secondary3, white } = theme.colors;
    const modalRef = useRef(null);
    const buttonRef = useRef(null);
    const handleClick = () => {
        setOpenModal(true);
    };
    const onClickOutsideHandler = useCallback((event) => {
        if (openModal &&
            locations.length > 0 &&
            !((buttonRef && buttonRef.current && buttonRef.current.contains(event.target)) ||
                (modalRef && modalRef.current && modalRef.current.contains(event.target)))) {
            setOpenModal(false);
        }
    }, [openModal, locations.length]);
    useEffect(() => {
        window.addEventListener('click', onClickOutsideHandler);
        return () => {
            window.removeEventListener('click', onClickOutsideHandler);
        };
    }, [onClickOutsideHandler]);
    const handleLocationClick = (location) => {
        setSelectedPos(location);
        setOpenModal(false);
    };
    const handleClose = () => {
        setSelectedPos(null);
        onClose();
    };
    return (React.createElement(Button, Object.assign({ onClick: () => {
            onOpen();
        }, theme: theme, isOpen: isOpen }, props),
        React.createElement(LocationImage, null),
        isOpen ? (React.createElement(React.Fragment, null,
            React.createElement(LocationWrapper, null,
                React.createElement(ContainerForText, { ref: buttonRef, onClick: handleClick },
                    React.createElement(InputLocation, { value: location, onChange: handleLocationChange, autoFocus: true })),
                React.createElement(CloseButton, { color: white, hoverColor: secondary3, onClick: handleClose })),
            openModal && locations.length > 0 && (React.createElement(ModalLocation, { ref: modalRef, theme: theme },
                React.createElement(ContainerRows, null, locations.map((location) => (React.createElement(LocationRow, { onClick: () => handleLocationClick(location) }, location.name)))))))) : null));
};
LocationButton.defaultProps = {
    location: 'Brest',
};
