import React from 'react';

export const HumidityIcon = ({ primaryColor, secondaryColor }: { primaryColor: string; secondaryColor: string }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_13421_8260)">
        <path
          d="M22.8951 15.2544L26.4861 20.0886C27.1938 21.0326 27.6811 21.7876 27.8774 22.824C28.0737 23.8604 27.9733 24.9348 27.5889 25.9112C27.2045 26.8876 26.5533 27.7222 25.7178 28.3094C24.8823 28.8966 23.9 29.21 22.8951 29.21C21.8901 29.21 20.9078 28.8966 20.0723 28.3094C19.2368 27.7222 18.5856 26.8876 18.2012 25.9112C17.8168 24.9348 17.7164 23.8604 17.9127 22.824C18.109 21.7876 18.8882 20.7033 19.304 20.0886L22.8951 15.2544Z"
          stroke={primaryColor}
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.10483 11.1372L12.6959 15.9714C13.4035 16.9154 13.8909 17.6704 14.0872 18.7068C14.2835 19.7432 14.1831 20.8176 13.7987 21.794C13.4142 22.7704 12.7631 23.605 11.9276 24.1922C11.0921 24.7794 10.1097 25.0928 9.10483 25.0928C8.09991 25.0928 7.11757 24.7794 6.28206 24.1922C5.44655 23.605 4.79541 22.7704 4.411 21.794C4.02659 20.8176 3.92618 19.7432 4.12247 18.7068C4.31876 17.6704 5.09801 16.5862 5.51375 15.9714L9.10483 11.1372Z"
          stroke={primaryColor}
          // stroke="#33894E"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.2907 2.7896L19.9575 6.37964C20.483 7.08066 20.8449 7.64131 20.9907 8.41099C21.1365 9.18067 21.0619 9.97853 20.7764 10.7036C20.491 11.4287 20.0074 12.0485 19.3869 12.4846C18.7665 12.9206 18.0369 13.1534 17.2907 13.1534C16.5444 13.1534 15.8149 12.9206 15.1944 12.4846C14.5739 12.0485 14.0904 11.4287 13.8049 10.7036C13.5194 9.97853 13.4449 9.18067 13.5906 8.41099C13.7364 7.64131 14.3151 6.83615 14.6238 6.37964L17.2907 2.7896Z"
          stroke={secondaryColor}
          // stroke="#88C99D"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13421_8260">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
