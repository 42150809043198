import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { routes } from './routes';
import * as serviceWorker from './serviceWorker';

import './styles/global.css';

import { themeNID, useTheme } from '@cthings.co/styled-components';
import { EngineProviders } from '@cthings.co/engine-providers';
import { TooltipContextProvider } from './components/tooltip/context';
import { NotifierProvider } from './features/notifier2';
import { LoaderContextProvider } from './features/placeholderComponent/loader.context';
import appleTouchIcon from './nid/apple-touch-icon.png';
import favicon16 from './nid/favicon-16x16.png';
import favicon32 from './nid/favicon-32x32.png';
import maskIcon from './nid/safari-pinned-tab.svg';
//@ts-ignore
import manifest from './nid/site.webmanifest';

import appleTouchIconMpwik from './mpwik/apple-touch-icon.png';
import favicon16Mpwik from './mpwik/favicon-16x16.png';
import favicon32Mpwik from './mpwik/favicon-32x32.png';
import maskIconMpwik from './mpwik/safari-pinned-tab.svg';

import { isMpwik } from './consts';
//@ts-ignore
import manifestMpwik from './mpwik/site.webmanifest';

import { QueryClient, QueryClientProvider } from 'react-query';
import { QueryClient as QueryClientFDS, QueryClientProvider as QueryClientProviderFDS } from '@cthings.co/engine-query';
import { renderRoutes } from './utils/renderRoutes';
import { NotFoundHandler } from './features/notFoundHandler/NotFoundHandler';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';

const queryClientOuter = new QueryClient();
const queryClientInner = new QueryClientFDS();
const NidMeta = () => {
  return (
    <>
      {!isMpwik ? (
        <Helmet>
          <link href="https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css" rel="stylesheet" />
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="mask-icon" href={maskIcon} color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#00aba9" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="description" content="NID platform utilities" />
          <link rel="manifest" href={manifest} />
          <title>Smart Utilities Platform</title>
        </Helmet>
      ) : (
        <Helmet>
          <link href="https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css" rel="stylesheet" />
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIconMpwik} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32Mpwik} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16Mpwik} />
          <link rel="mask-icon" href={maskIconMpwik} color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#00aba9" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="description" content="NID platform utilities" />
          <link rel="manifest" href={manifestMpwik} />
          <title>MPWiK Wrocław</title>
        </Helmet>
      )}
    </>
  );
};

// @TODO to be removed when @bit is removed
const InnerStyleProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <QueryClientProvider client={queryClientOuter}>
    <QueryClientProviderFDS client={queryClientInner}>
      <EngineProviders theme={themeNID}>
        <InnerStyleProvider>
          <TooltipContextProvider>
            <LoaderContextProvider>
              <Provider store={store}>
                <NotifierProvider>
                  <NotFoundHandler>{renderRoutes(routes as any, 0)}</NotFoundHandler>
                  <NidMeta />
                </NotifierProvider>
              </Provider>
            </LoaderContextProvider>
          </TooltipContextProvider>
        </InnerStyleProvider>
      </EngineProviders>
    </QueryClientProviderFDS>
  </QueryClientProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
