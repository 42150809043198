import React from 'react';
import styled from '@cthings.co/styled-components';
import { Textarea } from '../../../textarea/Textarea';
import { InputModal } from '../../../inputModal/InputModal';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTextarea = styled(Textarea)`
  width: calc(100% - 18px);
`;

const StyledInput = styled(InputModal)`
  margin: 0 0 12px 0;
  &.last {
    margin: 0;
  }

  ${media.tablet} {
    width: 100%;
  } ;
` as any; // @TODO fix type

const StyledText = styled(Text)`
  word-break: break-all;
`;

export interface TextfieldOptionsType {
  title?: string;
  textareaRef?: any;
  isDisabled?: boolean;
  isError?: boolean;
  placeholder?: string;
  validatingIsStarted?: boolean;
  textOfErrorMessage?: string;
  rows?: number;
}

export const Textfield = ({
  value,
  handleChangeValue,
  options,
  isEditInProcess,
  languageStrings,
  className,
  ...props
}: {
  value: string;
  handleChangeValue: (value: string) => void;
  options: TextfieldOptionsType;
  isEditInProcess?: boolean;
  languageStrings?: any;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const { title, textareaRef, isDisabled, isError, placeholder, validatingIsStarted, rows } = options;
  const countOfRows = value && Math.round(value.length / 33) + 1 < 11 ? Math.round(value.length / 33) + 1 : 10;

  return (
    <Wrapper className={className} {...props}>
      {isEditInProcess ? (
        title ? (
          <StyledInput
            name={title}
            value={value}
            disabled={isDisabled}
            placeholder={placeholder}
            onChange={(e: any) => {
              handleChangeValue(e.currentTarget.value);
            }}
            rows={rows ? rows : title ? 0 : countOfRows}
            startedValidation={validatingIsStarted}
            isError={isError}
            languageStrings={languageStrings}
            theme={theme}
          />
        ) : (
          <StyledTextarea
            textValue={value}
            changeValue={handleChangeValue}
            textareaRef={textareaRef}
            isDisabled={!isEditInProcess}
            isError={isError}
            placeholder={placeholder}
            className={className}
          />
        )
      ) : (
        <StyledText type={TextType.TEXT_14_GRAY} color={gray1}>
          {value ? value : 'N/A'}
        </StyledText>
      )}
    </Wrapper>
  );
};
