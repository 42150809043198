import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { getRequiredDateFormat } from '../../../../../../../../../utils/date/date-format';
import { TableCustomComponentState } from '../../../../../../../../../features/universalTable/types';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  inModal?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ inModal }) => (inModal ? '100%' : 'max-content')};
  display: flex;
  flex-direction: ${({ inModal }) => (inModal ? 'row' : 'column')};
  align-items: flex-start;
  padding: ${({ inModal }) => (inModal ? '8px 12px' : '0')};
`;

export const Date = ({ value, state }: { value: string; state: TableCustomComponentState }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        value ? (
          <Wrapper>
            <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'500'}>
              {getRequiredDateFormat(value, 'DD.MM.YYYY')}
            </Text>
            <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
              {getRequiredDateFormat(value, 'HH:mm')}
            </Text>
          </Wrapper>
        ) : (
          <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
            {'N/A'}
          </Text>
        )
      ) : (
        <Wrapper inModal>
          <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
            {value ? `${getRequiredDateFormat(value, 'DD.MM.YYYY')}, ${getRequiredDateFormat(value, 'HH:mm')}` : 'N/A'}
          </Text>
        </Wrapper>
      )}
    </>
  );
};
