import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../app/state/user';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ReactComponent as MaxDepthImage } from './assets/max_depth.svg';
import { media } from '@cthings.co/styles-utils';

const StyledModal = styled(Modal)`
  width: 100%;
  z-index: var(--modal-first-layer);
  & > div {
    width: 100%;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  align-items: flex-start;
  width: 100%;
  max-width: 566px;
  box-sizing: border-box;
  padding: 25px;
  ${media.semiTablet} {
    border-radius: 12px 12px 0 0;
    height: 100%;
    align-items: flex-end;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black1')};
  margin-bottom: 13px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 104px 1fr;
  grid-column-gap: 20px;
`;

const TextAndInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  margin-bottom: 16px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`;

const Button = styled(CommonButton)`
  max-width: 116px;
`;

interface MaxDepthModalProps {
  isOpen: boolean;
  handleClose: any;
}

export const MaxDepthModal: FC<MaxDepthModalProps> = ({ isOpen, handleClose }) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const buttons = [
    {
      value: languageStrings.gotIt,
      onClick: handleClose,
      backgroundColor: colorFetch('pureWhite'),
      borderColor: colorFetch('primary'),
      color: colorFetch('pureBlack'),
    },
  ];

  return (
    <StyledModal isOpenModal={isOpen}>
      <StyledModalContainer>
        <Title type={TextType.TEXT_14_GRAY}>{languageStrings.provisioningSetDepthModalTitle}</Title>
        <ContentWrapper>
          <MaxDepthImage />
          <TextAndInput>
            <StyledText type={TextType.TEXT_14_GRAY}>{languageStrings.deploySetDepthModalText}</StyledText>
            <div style={{ flex: 1 }}></div>
            <Buttons>
              {buttons.map((item: any, index: number) => {
                const { value, onClick, backgroundColor, borderColor, color } = item;
                return (
                  <Button
                    onClick={onClick}
                    colors={{
                      main: color,
                      background: backgroundColor,
                      border: borderColor,
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
            </Buttons>
          </TextAndInput>
        </ContentWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
