import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { uniqueId } from 'underscore';
import {
  ClientsTableState,
  COMPANY_CREATION_STEP,
  CreationDevice,
  CreationUser,
  DeviceSelectionMode,
  Organisation,
  PlansetOfDevice,
  PlansetOfTypeOfDevice,
} from './types';
import { EMPTY_USER, USER_PREFIX } from './consts';
import { initialState } from './data';
import { getFormattedActions } from './utils';

const startCompanyCreation = (state: WritableDraft<ClientsTableState>) => {
  state.treeHistory = [COMPANY_CREATION_STEP.ADD_COMPANY];
};

const startDeviceEditTool = (state: WritableDraft<ClientsTableState>) => {
  state.isDeviceMode = true;
  state.treeHistory = [COMPANY_CREATION_STEP.ADD_DEVICES];
};

const endCompanyCreation = (state: WritableDraft<ClientsTableState>) => {
  return {
    ...initialState,
    parentOrganisationsList: state.parentOrganisationsList || [],
    finaliseTrigger: state.finaliseTrigger + 1,
  };
};

const pushLocation = (state: WritableDraft<ClientsTableState>, action: PayloadAction<COMPANY_CREATION_STEP>) => {
  state.treeHistory = [...state.treeHistory, action.payload];
};

const pushAction = (state: WritableDraft<ClientsTableState>, action: PayloadAction<string>) => {
  let { treeHistory } = state;

  if (action.payload === 'back') {
    state.treeHistory = treeHistory.slice(0, treeHistory.length - 1);
    return;
  }

  const actions = getFormattedActions(treeHistory, !!state.isDeviceMode);
  if (actions[action.payload]) {
    treeHistory = [...treeHistory, actions[action.payload] as COMPANY_CREATION_STEP];
  }

  state.treeHistory = treeHistory;
};

const updateParentOrganisation = (
  state: WritableDraft<ClientsTableState>,
  action: PayloadAction<Partial<Organisation>>,
) => {
  state.parentOrganisation = { ...state.parentOrganisation, ...action.payload };
};

const addUsers = (state: WritableDraft<ClientsTableState>, action: PayloadAction<CreationUser[]>) => {
  const newUsers = action.payload.map((newUser) => ({ ...newUser, id: uniqueId(USER_PREFIX) }));
  state.users = [...state.users, ...newUsers];
};

const deleteUser = (state: WritableDraft<ClientsTableState>, action: PayloadAction<string>) => {
  const index = state.users.findIndex(({ id }) => id === action.payload);
  if (index !== -1) {
    state.users = [...state.users.slice(0, index), ...state.users.slice(index + 1)];
  }
};

const setSelectedField = (state: WritableDraft<ClientsTableState>, action: PayloadAction<boolean>) => {
  state.isSelectedField = action.payload;
};

const setParentsList = (state: WritableDraft<ClientsTableState>, action: PayloadAction<boolean>) => {
  state.parentOrganisationsList = action.payload;
};

const setProfilesList = (state: WritableDraft<ClientsTableState>, action: PayloadAction<boolean>) => {
  state.profilesList = action.payload;
};

const updateUser = (
  state: WritableDraft<ClientsTableState>,
  action: PayloadAction<Partial<CreationUser> & { id: string }>,
) => {
  const { payload: rawUser } = action;
  const index = state.users.findIndex(({ id }) => id === rawUser.id);
  if (index !== -1) {
    const updatedUser = { ...state.users[index], ...rawUser };
    state.users = [...state.users.slice(0, index), updatedUser, ...state.users.slice(index + 1)];
  }
};

const editUser = (state: WritableDraft<ClientsTableState>, action: PayloadAction<Partial<CreationUser>>) => {
  const { payload: rawUser } = action;
  state.userInEdit = { ...state.userInEdit, ...rawUser };
};

const setEditUser = (state: WritableDraft<ClientsTableState>, action: PayloadAction<string>) => {
  const userInEdit = state.users.find(({ id }) => id === action.payload);
  if (userInEdit) {
    state.userInEdit = userInEdit;
  }
};

const clearEditUser = (state: WritableDraft<ClientsTableState>) => {
  state.userInEdit = EMPTY_USER;
};

const saveEditUser = (state: WritableDraft<ClientsTableState>) => {
  const rawUser = state.userInEdit;
  const index = state.users.findIndex(({ id }) => id === rawUser.id);
  if (index !== -1) {
    const updatedUser = { ...state.users[index], ...rawUser };
    state.users = [...state.users.slice(0, index), updatedUser, ...state.users.slice(index + 1)];
    state.userInEdit = EMPTY_USER;
  }
};

const createUser = (state: WritableDraft<ClientsTableState>) => {
  const newUser = { ...state.userInEdit, id: uniqueId(USER_PREFIX) };
  state.users = [...state.users, newUser];
  state.userInEdit = EMPTY_USER;
};

const setFromDevice = (state: WritableDraft<ClientsTableState>, action: PayloadAction<CreationDevice>) => {
  state.fromDevice = action.payload;
};

const setToDevice = (state: WritableDraft<ClientsTableState>, action: PayloadAction<CreationDevice>) => {
  state.toDevice = action.payload;
};

const setDeviceSelectionMode = (
  state: WritableDraft<ClientsTableState>,
  action: PayloadAction<DeviceSelectionMode>,
) => {
  state.deviceSelectionMode = action.payload;
};

const loadDevices = (state: WritableDraft<ClientsTableState>, action: PayloadAction<CreationDevice[]>) => {
  const isUploadDevicesUnselectRequired = !!state.fromDevice && !!state.toDevice;
  if (isUploadDevicesUnselectRequired) {
    state.uploadedDevices = state.uploadedDevices.map((device) => ({ ...device, isSelected: false }));
  }
  state.devices = action.payload;
};

const uploadDevices = (
  state: WritableDraft<ClientsTableState>,
  action: PayloadAction<{ successfullDevices: CreationDevice[]; failedCount: number }>,
) => {
  const devices = action.payload.successfullDevices;
  const newDevices = devices.map((newDevice) => ({
    ...newDevice,
    isSelected: true,
    name: newDevice.transport_id,
  }));

  state.uploadedDevices = newDevices;
  state.failedDevicesCount = action.payload.failedCount;
};

const handleDeviceSelection = (state: WritableDraft<ClientsTableState>, action: PayloadAction<string>) => {
  const index = state.devices.findIndex(({ id }) => id === action.payload);
  if (index !== -1) {
    const { isSelected } = state.devices[index];
    const updatedDevice = { ...state.devices[index], isSelected: !isSelected };
    state.devices = [...state.devices.slice(0, index), updatedDevice, ...state.devices.slice(index + 1)];
  }

  const uploadedIndex = state.uploadedDevices.findIndex(({ id }) => id === action.payload);
  if (uploadedIndex !== -1) {
    const { isSelected } = state.uploadedDevices[uploadedIndex];
    const updatedDevice = { ...state.uploadedDevices[uploadedIndex], isSelected: !isSelected };
    state.uploadedDevices = [
      ...state.uploadedDevices.slice(0, uploadedIndex),
      updatedDevice,
      ...state.uploadedDevices.slice(uploadedIndex + 1),
    ];
  }

  state.fromDevice = null;
  state.toDevice = null;
};

const setPlansetOfDevice = (state: WritableDraft<ClientsTableState>, action: PayloadAction<PlansetOfDevice>) => {
  state.devices = state.devices.map((el: any) =>
    el.id === action.payload.id && el.isSelected
      ? { ...el, plansets_info: { ...el.plansets_info, device_planset: action.payload.item } }
      : el,
  );

  state.uploadedDevices = state.uploadedDevices.map((el: any) =>
    el.id === action.payload.id && el.isSelected
      ? { ...el, plansets_info: { ...el.plansets_info, device_planset: action.payload.item } }
      : el,
  );
};

const setPlansetForAllDevicesOfThisType = (
  state: WritableDraft<ClientsTableState>,
  action: PayloadAction<PlansetOfTypeOfDevice>,
) => {
  state.devices = state.devices.map((el: any) =>
    el.type === action.payload.type && el.isSelected
      ? { ...el, plansets_info: { ...el.plansets_info, device_planset: action.payload.item } }
      : el,
  );

  state.uploadedDevices = state.uploadedDevices.map((el: any) =>
    el.type === action.payload.type && el.isSelected
      ? { ...el, plansets_info: { ...el.plansets_info, device_planset: action.payload.item } }
      : el,
  );
};

export const reducers = {
  startCompanyCreation,
  endCompanyCreation,
  pushLocation,
  pushAction,
  updateParentOrganisation,
  addUsers,
  updateUser,
  deleteUser,
  editUser,
  setEditUser,
  clearEditUser,
  saveEditUser,
  createUser,
  loadDevices,
  setFromDevice,
  setToDevice,
  setDeviceSelectionMode,
  uploadDevices,
  setSelectedField,
  handleDeviceSelection,
  setParentsList,
  setProfilesList,
  startDeviceEditTool,
  setPlansetOfDevice,
  setPlansetForAllDevicesOfThisType,
};
