import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { EventTable } from '../tableControl/EventTable';

const TempTableTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 20px 0;
`;

export const EventTableBlock = ({
  languageStrings,
  pathParams,
  isInDevices,
}: {
  languageStrings: any;
  pathParams: any;
  isInDevices?: boolean;
}) => {
  return (
    <>
      <TempTableTitle>
        <Text type={TextType.SEMITITLE} margin={'0 4px 0 0'}>
          {`${languageStrings.insightDetailsEventTableTitle}`}
        </Text>
      </TempTableTitle>
      <EventTable pathParams={pathParams} isInDevices={isInDevices} />
    </>
  );
};
