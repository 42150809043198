import React, { useState } from 'react';
import { ReferenceArea } from 'recharts';
import moment from 'moment';
import { uniqueId } from 'underscore';
import { useNotifierFunctions } from '../../../../features/notifier2';
import { CustomCalibrationTooltip } from '../customCalibrationTooltip';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';

const CustomReferenceAreaComponent = ({
  theme,
  index,
  tooltipId,
  props,
}: {
  theme: any;
  index: number;
  tooltipId: number | null;
  props: any;
}) => {
  return (
    <g>
      <svg id={`${index}`} x={props.x} y={props.y} width={props.width} height={props.height}>
        <line stroke="#F77F00" strokeDasharray="4" strokeWidth={2} x1={0} y1={0} x2={0} y2={props.height} />
        <rect fill={theme.colors.orange1} fillOpacity={0.07} width={props.width} height={props.height} />
        <line
          stroke="#F77F00"
          strokeDasharray="4"
          strokeWidth={2}
          x1={props.width}
          y1={0}
          x2={props.width}
          y2={props.height}
        />
      </svg>
      {tooltipId !== null && tooltipId === index && <use href={`${tooltipId}`} xlinkHref={`${tooltipId}`} />}
    </g>
  );
};

const CustomLabel = ({
  index,
  viewBox: { x, y, width, height },
  theme,
  area,
  widthOfGraph,
  // setTooltipId,
  tooltipId,
  ...props
}: {
  index: number;
  viewBox: { x: number; y: number; width: number; height: number };
  theme: any;
  area: any;
  widthOfGraph: any;
  // setTooltipId?: (id: number) => void;
  tooltipId: number | null;
}) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const { addNotification } = useNotifierFunctions();

  const languageStrings = useSelector(selectLanguageStrings);

  const tooltipOffset = x + width / 2 + 93 > widthOfGraph ? x + width / 2 + 114 - widthOfGraph : 0;
  const tooltipArrowOffset = tooltipOffset > 83 ? 83 : tooltipOffset;

  const onIconClick = () => {
    setIsTooltipActive(!isTooltipActive);
    addNotification({
      message: languageStrings.calibrationTooltipText,
      type: 'warning',
    });
  };
  const onIconMouseEnter = () => {
    // setTooltipId && setTooltipId(index);
    setIsTooltipActive(true);
  };

  const onIconMouseLeave = () => {
    setIsTooltipActive(false);
  };

  return (
    <>
      {isTooltipActive && (
        <foreignObject
          id={`${index}`}
          x={x + width / 2 - 93 - tooltipOffset}
          y={y - 2}
          overflow={'visible'}
          width={186}
          height={74}
        >
          <CustomCalibrationTooltip
            isInfo
            isArrowShown
            tooltipArrowOffset={tooltipArrowOffset}
            tooltipData={{ active: true }}
            text={{
              name: languageStrings.calibraion,
              info: languageStrings.calibrationTooltipText,
            }}
            theme={theme}
          />
        </foreignObject>
      )}

      <g id={`${index}`}>
        <svg
          x={x + width / 2 - 10.5}
          y={y - 26.5}
          width="21"
          height="20"
          // viewBox={viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5007 1.66634C5.89828 1.66634 2.16732 5.3973 2.16732 9.99967C2.16732 14.602 5.89828 18.333 10.5007 18.333C15.103 18.333 18.834 14.602 18.834 9.99967C18.834 5.3973 15.103 1.66634 10.5007 1.66634Z"
            stroke="#F77F00"
            strokeWidth="1.1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 6.66667L10.5 10"
            stroke="#F77F00"
            strokeWidth="1.4"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 13.333L10.4917 13.333"
            stroke="#F77F00"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </g>
      <foreignObject
        x={x + width / 2 - 12}
        y={y - 27.5}
        width={24}
        height={22}
        onClick={onIconClick}
        onMouseEnter={onIconMouseEnter}
        onMouseLeave={onIconMouseLeave}
        style={{ cursor: 'pointer' }}
      />
      {tooltipId !== null && tooltipId === index && <use href={`${tooltipId}`} xlinkHref={`${tooltipId}`} />}
    </>
  );
};

interface CustomReferenceAreaProps {
  theme: any;
  data?: any;
  calibrationData?: any;
  widthOfGraph: any;
  // setTooltipId?: (id: number) => void;
  // tooltipId: number | null;
}

export const CustomReferenceArea = ({
  theme,
  data,
  calibrationData,
  widthOfGraph,
}: // setTooltipId,
// tooltipId,
CustomReferenceAreaProps) => {
  const firsGraphDate = data[0].x;
  const lastGraphDate = data[data.length - 1].x;

  const calibrationAreaMap = (calibrationData || []).map((area: any, index: number) => {
    const preparedX1 =
      // area.x2
      moment
        .utc(
          area.isFirstCalibrationBeforeFirstGraphDate
            ? firsGraphDate
            : data.reduce(
                (result: any, currentPoint: any) => {
                  return Math.pow(moment(area.x1).diff(moment(currentPoint.x)), 2) < result.closest
                    ? {
                        point: currentPoint.x,
                        closest: Math.pow(moment(area.x1).diff(moment(currentPoint.x)), 2),
                      }
                    : result;
                },
                { closest: Math.pow(moment(moment(area.x1)).diff(firsGraphDate), 2), point: firsGraphDate },
              ).point,
        )
        .format();
    const preparedX2 =
      area.x2 === null
        ? lastGraphDate
        : moment
            .utc(
              area.isSecondCalibrationAfterLastGraphDate
                ? lastGraphDate
                : data.reduce(
                    (result: any, currentPoint: any) => {
                      return Math.pow(moment(area.x2).diff(moment(currentPoint.x)), 2) < result.closest
                        ? {
                            point: currentPoint.x,
                            closest: Math.pow(moment(area.x2).diff(moment(currentPoint.x)), 2),
                          }
                        : result;
                    },
                    { closest: Math.pow(moment(moment(area.x2)).diff(lastGraphDate), 2), point: lastGraphDate },
                  ).point,
            )
            .format();
    // area.x2;

    const firstGraphSecondCalibrationInterval = moment(preparedX2).diff(moment(firsGraphDate));
    const firstCalibrationLastGraphInterval = moment(lastGraphDate).diff(moment(preparedX1));
    const firstGraphFirstCalibrationInterval = moment(preparedX1).diff(moment(firsGraphDate));
    const lastGraphSecondCalibrationInterval = moment(lastGraphDate).diff(moment(preparedX2));

    const isCalibrationIntervalInGraph =
      firstGraphSecondCalibrationInterval >= 0 && firstCalibrationLastGraphInterval >= 0;

    const isFirstCalibrationBeforeFirstGraphDate =
      firstGraphSecondCalibrationInterval >= 0 &&
      firstCalibrationLastGraphInterval >= 0 &&
      firstGraphFirstCalibrationInterval < 0;

    const isSecondCalibrationAfterLastGraphDate =
      firstGraphSecondCalibrationInterval >= 0 &&
      firstCalibrationLastGraphInterval >= 0 &&
      lastGraphSecondCalibrationInterval < 0;

    return {
      ...area,
      id: index,
      x1: preparedX1,
      x2: preparedX2,
      isCalibrationIntervalInGraph,
      isFirstCalibrationBeforeFirstGraphDate,
      isSecondCalibrationAfterLastGraphDate,
    };
  });

  return (
    calibrationData &&
    calibrationAreaMap.map((area: any, index: number) => {
      return (
        area.isCalibrationIntervalInGraph && (
          <ReferenceArea
            key={uniqueId('calibration_area_')}
            // ref={}
            // isFront={false}
            shape={(props: any) => {
              return <CustomReferenceAreaComponent theme={theme} index={index} tooltipId={area.id} props={props} />;
            }}
            label={({ viewBox }: any) => {
              return (
                <CustomLabel
                  // setTooltipId={setTooltipId}
                  // tooltipId={tooltipId}
                  tooltipId={area.id}
                  index={index}
                  viewBox={viewBox}
                  theme={theme}
                  area={area}
                  widthOfGraph={widthOfGraph}
                />
              );
            }}
            x1={new Date(area.x1).getTime()}
            x2={new Date(area.x2).getTime()}
            strokeDasharray="4"
            stroke={theme.colors.orange1}
            fill={theme.colors.orange1}
            fillOpacity={0.07}
          />
        )
      );
    })
  );
};
