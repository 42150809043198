import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { ReactComponent as RestartIcon } from '../../../../assets/Restart.svg';
import { useStyleContext } from '@cthings.co/styles-utils';
import { DeleteModal } from './components/DeleteModal';
import { DropdownModal } from './components/DropdownModal';
import { SelectDevices } from './components/SelectDevices';
import { Switcher } from './components/Switcher';
import {
  BackCancelButton,
  ButtonsRow,
  NextSaveButton,
  RestartButton,
  SelectedButton,
  StyledSelect,
  StyledText1,
  StyledText2,
  StyledText3,
  StyledText4,
  StyledText5,
  SwitchersRow,
  TitleRow,
  StyledCloseIcon,
  Block,
} from './styled';
import {
  handleDeviceSelection,
  pushAction,
  selectCreationDevices,
  selectDeviceSelectionMode,
  selectSelectedCount,
  selectSelectedField,
  setDeviceSelectionMode,
  setSelectedField,
  selectIsDeviceMode,
  endCompanyCreation,
  selectSelectedDevices,
} from '../../../../app/state/organisations';
import { CustomModal } from '../customModal/CustomModal';
import { DeviceSelectionModeValue, ParameterListType } from './types';
import { useDeviceSelectionModes } from './utils';
import { useTheme } from '@cthings.co/styled-components';
import { CloseButton } from '../closeButton/CloseButton';
import { boldString } from '../../../../utils/boldString';
import { useNotifierFunctions } from '../../../notifier2';

export enum DevicesStepType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

interface AddDevicesManuallyProps {
  isOpen: boolean;
  isLoading: boolean;
  type: DevicesStepType;
  toggleTrigger: () => void;
}

export const AddDevicesManually: FC<AddDevicesManuallyProps> = ({ isOpen, isLoading, type, toggleTrigger }) => {
  const theme = useTheme();
  const { primary } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);
  const [media] = useStyleContext();
  const isSelected = useSelector(selectSelectedField);
  const selectedDevices: any = useSelector(selectSelectedDevices);
  // const isDeviceMode = useSelector(selectIsDeviceMode);
  const individualType = { name: 'Individual, ', id: 'individual' };
  const [openClearModal, setOpenClearModal] = useState(false);
  const [isDropdownModal, setIsDropdownModal] = useState(false);

  const dispatch = useDispatch();

  const { addNotification } = useNotifierFunctions();

  const [showDevicesWIthoutPlanset, setShowDevicesWIthoutPlanset] = useState<boolean>(false);

  const handleNext = () => {
    if (!selectedDevicesCount || switcherValue?.id === 'selected') {
      if (!selectedDevices.filter((el: any) => !el.plansets_info.device_planset?.id).length) {
        setShowDevicesWIthoutPlanset(false);
        dispatch(pushAction('summary'));
        updateDeviceSelectionMode(individualType as any);
        dispatch(setSelectedField(false));
      } else {
        setShowDevicesWIthoutPlanset(true);
        addNotification({
          message: languageStrings.toProceedEveryDevice,
          type: 'error',
        });
      }
      return;
    }

    if (!isSelected) {
      handleSwitherButtonChange(getSelectionModeValue('selected'));
      return;
    }
  };

  const handleBack = () => {
    dispatch(pushAction('back'));
    updateDeviceSelectionMode(individualType as any);
    dispatch(setSelectedField(false));
  };

  const devices = useSelector(selectCreationDevices);
  const deviceSelectionMode = useSelector(selectDeviceSelectionMode);

  const updateDeviceSelectionMode = (value: DeviceSelectionModeValue) => {
    dispatch(setDeviceSelectionMode(value.id));
  };

  const [{ desktopSelectionModes, mobileSelectionModes }, { getSelectionModeValue }] = useDeviceSelectionModes(
    languageStrings,
  );

  const switcherValue = getSelectionModeValue(deviceSelectionMode);

  const checkDevices = (item: ParameterListType) => {
    dispatch(handleDeviceSelection(item.id as string));
  };

  const handleRefreshClick = () => {
    toggleTrigger();
  };

  const handleClearDevicesButtonClick = () => {
    setOpenClearModal(false);
  };

  const handleSwitherButtonChange = (value: DeviceSelectionModeValue) => {
    updateDeviceSelectionMode(value);
  };

  useEffect(() => {
    if (isSelected) {
      const selected = { name: 'Selected, ', id: 'selected' };
      updateDeviceSelectionMode(selected as any);
    }
  }, [isSelected]);

  const selectedDevicesCount = useSelector(selectSelectedCount);

  return (
    <CustomModal isOpen={isOpen}>
      <TitleRow>
        <StyledText1>
          {type === DevicesStepType.ADD ? languageStrings.addDevicesManuallyTitle : languageStrings.navigationDevices}
        </StyledText1>
        <Block>
          {type === DevicesStepType.ADD && <StyledText2>3/3</StyledText2>}
          <CloseButton />
        </Block>
      </TitleRow>
      <StyledText3>{boldString(languageStrings.choosePlansetForEachDevices, primary)}</StyledText3>
      <SwitchersRow>
        {media.mediaType.phone ? (
          <StyledSelect
            height={'40px'}
            value={switcherValue}
            values={mobileSelectionModes}
            onChange={(value) => console.log(value)}
            placeholder={languageStrings.manageUsersAddUserModalInputsClientPlaceholder}
            customModal={
              <DropdownModal
                setIsOpenModal={(value: any) => setIsDropdownModal(value)}
                value={switcherValue}
                values={mobileSelectionModes}
                onChange={updateDeviceSelectionMode}
                languageStrings={languageStrings}
                devices={devices}
              />
            }
            triggerCloseModal={!isDropdownModal}
            languageStrings={languageStrings}
          />
        ) : (
          <>
            <Switcher
              type={type}
              value={switcherValue}
              values={desktopSelectionModes}
              onChange={handleSwitherButtonChange}
            />
            <SelectedButton
              type={type}
              isClearDevices={switcherValue.id === 'selected'}
              onClick={() => {
                handleSwitherButtonChange(getSelectionModeValue('selected'));
              }}
            >
              <StyledText5 isClearDevices={switcherValue.id === 'selected'}>
                {languageStrings.selectedTitle}: {selectedDevicesCount}
              </StyledText5>
            </SelectedButton>
          </>
        )}
        {type === DevicesStepType.ADD && (
          <RestartButton onClick={handleRefreshClick}>
            <RestartIcon />
          </RestartButton>
        )}
      </SwitchersRow>
      <SelectDevices
        devices={devices}
        isLoading={isLoading}
        handleDeviceSelect={checkDevices}
        onClearDevices={() => setOpenClearModal(true)}
        isIndividual={switcherValue?.id === 'individual'}
        isRange={switcherValue?.id === 'range'}
        isSelected={switcherValue?.id === 'selected'}
        isClearDevices={false}
        showDevicesWIthoutPlanset={showDevicesWIthoutPlanset}
      />
      <ButtonsRow>
        {type === DevicesStepType.ADD && (
          <BackCancelButton type={type} onClick={handleBack}>
            {languageStrings.backButton}
          </BackCancelButton>
        )}
        <NextSaveButton onClick={type === DevicesStepType.ADD ? handleNext : handleBack}>
          {type === DevicesStepType.ADD ? languageStrings.paginationButtonNext : languageStrings.saveButton}
        </NextSaveButton>
      </ButtonsRow>
      <DeleteModal
        theme={theme}
        media={media}
        isOpenModal={openClearModal}
        languageStrings={languageStrings}
        setModalClose={() => setOpenClearModal(false)}
        onDelete={handleClearDevicesButtonClick}
      />
    </CustomModal>
  );
};
