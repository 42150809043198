import { TableInputType } from '../../components/universalTableInput/types';

export const SECtoMMSS = (secs: string) => {
  const minutes = Math.floor(+secs / 60);
  const seconds = +secs % 60;

  return [minutes, seconds].map((val) => (val < 10 ? `0${val}` : val)).join('');
};

export const MINtoHHMM = (mins: string) => {
  const hours = Math.floor(+mins / 60);
  const minutes = +mins % 60;
  return [hours, minutes].map((val) => (val < 10 ? `0${val}` : val));
};

export const HOURStoDDHH = (value: string) => {
  const days = value ? Math.floor(+value / 24) : 0;
  const hours = value ? +value % 24 : 0;

  return [days, hours].map((val, i) => `${val}`);
};

export const DDHHtoHOURS = (days: string, hours: string) => {
  return +days * 24 + +hours + '';
};

export const convertTimeDataForInputs = (data: any, type: TableInputType) => {
  const days = ~~(data / 86400);
  const hours = (value: number) => ~~(value / 3600);
  const minutes = (value: number) => value / 60;

  const obj: any = {
    [TableInputType.TIME_PICKER]: (() =>
      (hours(data) + '').padStart(2, '0') + (minutes(data % 3600) + '').padStart(2, '0'))(),
    [TableInputType.TIME_PICKER_FIELDS]: (() => [days, hours(data % 86400)])(),
    [TableInputType.TIME_PICKER_FIELDS_HHMM]: (() => [hours(data), minutes(data % 3600)])(),
    [TableInputType.TIME_PICKER_UNIT_HHMM]: (() =>
      (hours(data) + '').padStart(2, '0') + (minutes(data % 3600) + '').padStart(2, '0'))(),
    [TableInputType.TIME_PICKER_UNIT_MMSS]: (() =>
      (minutes(data) + '').padStart(2, '0') + ((data % 60) + '').padStart(2, '0'))(),
    [TableInputType.TIME_RANGE]: (() => [
      (hours(data[0]) + '').padStart(2, '0') + (minutes(data[0] % 3600) + '').padStart(2, '0'),
      (hours(data[1]) + '').padStart(2, '0') + (minutes(data[1] % 3600) + '').padStart(2, '0'),
    ])(),
  };

  return obj[type];
};

export const convertTimeDataForBackend = (data: any, type: TableInputType) => {
  const obj: any = {
    [TableInputType.TIME_PICKER]: (() => +data.slice(0, 2) * 3600 + +data.slice(-2) * 60)(),
    [TableInputType.TIME_PICKER_FIELDS]: (() => +data[0] * 86400 + +data[1] * 3600)(),
    [TableInputType.TIME_PICKER_FIELDS_HHMM]: (() => +data[0] * 3600 + +data[1] * 60)(),
    [TableInputType.TIME_PICKER_UNIT_HHMM]: (() => +data.slice(0, 2) * 3600 + +data.slice(-2) * 60)(),
    [TableInputType.TIME_PICKER_UNIT_MMSS]: (() => +data.slice(0, 2) * 60 + +data.slice(-2))(),
    [TableInputType.TIME_RANGE]: (() => [
      +data[0].slice(0, 2) * 3600 + +data[0].slice(-2) * 60,
      +data[1].slice(0, 2) * 3600 + +data[1].slice(-2) * 60,
    ])(),
  };

  return obj[type];
};
