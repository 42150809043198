import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { withMobileDraggableDeviceContainer } from '../../../draggableContainerHOC/withMobileDraggableDeviceContainer';
import { InputModal } from '../../../inputModal/InputModal';
import { CommonButton } from '@cthings.co/buttons';
import { ReactComponent as Instruction } from '../../../../assets/Instruction.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow2};
  border-radius: 24px 24px 0 0;
  background-color: ${colorFetch('white')};
  padding: 20px 16px;
  z-index: var(--modal-first-layer);
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  ${media.phone} {
    grid-template-columns: 1fr;
    height: 478px;
    overflow-y: auto;
  }
  ${media.phoneXS} {
    height: 406px;
  }
`;

const WrapperInput = styled(InputModal)`
  padding: 0px;
  margin: 0px 0px 10px 0px;
`;

const InstructionButton = styled(CommonButton)`
  width: 100%;
  height: 40px;
  padding: 0 34px;
  margin: 18px 0 10px 0;
  & > svg {
    margin-right: 5px;
    & > path {
      transition: 0.3s ease;
    }
  }
  &:hover > svg > path {
    stroke: ${colorFetch('white')};
  }
`;

const ButtonsWrapper = styled.div`
  padding-top: 20px;
`;

const StyledButton = styled(CommonButton)`
  height: 40px;
  width: 100%;

  &:first-child {
    margin-bottom: 10px;
  }
`;

const DraggableTableDevicePlain = ({
  inputArr,
  handleSetLocalAddress,
  validatingIsStarted,
  setValidatingIsStarted,
  isAllFieldsValid,
  isInstructions,
  setIsInstructionModal,
  isGps,
  isNotMainLocation,
  handleCancel,
  handleSaveAsMainLocation,
}: {
  inputArr: {
    name: any;
    fieldName: string;
    value: any;
    disabled: boolean;
    placeholder?: undefined;
  }[];
  className?: string;
  handleSetLocalAddress: (field: string, value: any, path: string[], callback?: Function) => void;
  isAllFieldsValid?: boolean;
  validatingIsStarted?: boolean;
  isInstructions?: boolean;
  setIsInstructionModal?: any;
  setValidatingIsStarted?: (val: boolean) => void;
  isGps?: boolean;
  isNotMainLocation?: boolean;
  handleCancel?: any;
  handleSaveAsMainLocation?: any;
}) => {
  const theme = useTheme();
  const { primary, pureWhite, black1 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  const onChangeHandle = (fieldName: string, e: any, path: string[], callback: any) => {
    setValidatingIsStarted && setValidatingIsStarted(true);
    handleSetLocalAddress(fieldName, e.target.value, path, callback);
    if (isAllFieldsValid) {
      setValidatingIsStarted && setValidatingIsStarted(false);
    }
  };

  const handleOpenInstructionModal = () => {
    setIsInstructionModal(true);
  };

  return (
    <Wrapper>
      <Container>
        {isInstructions && (
          <InstructionButton
            onClick={handleOpenInstructionModal}
            colors={{
              main: primary,
              background: pureWhite,
              borderHover: primary,
            }}
          >
            <Instruction />
            {languageStrings.provisioningInstructions}
          </InstructionButton>
        )}
        {inputArr.map((item: any, index: number) => {
          const { name, fieldName, value, placeholder, disabled, path, callback, isError } = item;
          return (
            <WrapperInput
              width={'100%'}
              className={index === 1 ? 'disabled' : ''}
              key={index}
              name={name}
              value={value}
              onChange={(e: any) => onChangeHandle(fieldName, e, path, callback)}
              disabled={disabled}
              placeholder={placeholder}
              startedValidation={validatingIsStarted}
              isError={isError}
            />
          );
        })}
      </Container>
      {isGps && (
        <ButtonsWrapper>
          <>
            {isNotMainLocation ? (
              <StyledButton onClick={handleCancel}>{languageStrings.closeButtonValue}</StyledButton>
            ) : (
              <>
                <StyledButton onClick={handleSaveAsMainLocation}>
                  {languageStrings.gpsDetailsSaveMainLocation}
                </StyledButton>
                <StyledButton
                  colors={{
                    main: black1,
                    background: pureWhite,
                  }}
                  onClick={handleCancel}
                >
                  {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
                </StyledButton>
              </>
            )}
          </>
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
};

export const DraggableTableDevice = withMobileDraggableDeviceContainer(true, true)(DraggableTableDevicePlain);
