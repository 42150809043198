import { LanguageStrings } from '../../languages/types';

export const basicMeasurements = (languageStrings: LanguageStrings) => {
  return {
    title: `${languageStrings.basicLabel}:`,
    subtitle: languageStrings.waterMeasurementsLabel,
    content: [
      {
        title: `${languageStrings.measurementsFromDevice}:`,
        list: [languageStrings.distanceToWater, languageStrings.temperatureLowCase],
        icon: 'green',
      },
      {
        title: `${languageStrings.minMeasurmentIntervals}:`,
        subtitle: '2 min',
        icon: 'green',
      },
      {
        title: `${languageStrings.minTransmissionIntervals}:`,
        subtitle: `1 ${languageStrings.hourLabel}`,
        icon: 'green',
      },
      {
        title: languageStrings.incidentsNotifications,
        icon: 'green',
      },
      {
        title: languageStrings.GPSLocationText,
        icon: 'green',
      },
      {
        title: `${languageStrings.overflowBasedOn}:`,
        list: [
          languageStrings.gravitationalCircularCulvert,
          languageStrings.freeFlowCircularCulvert,
          languageStrings.vWeirLabel,
        ],
        icon: 'red',
      },
      {
        title: `${languageStrings.flowMeasurementBasedOn}:`,
        list: [languageStrings.manningsPair],
        icon: 'red',
      },
    ],
  };
};

export const basic = (languageStrings: LanguageStrings) => {
  return {
    title: languageStrings.basicLabel,
    subtitle: null,
    content: [
      {
        title: `${languageStrings.measurementsFromDevice}:`,
        list: [languageStrings.distanceToWater, languageStrings.temperatureLowCase],
        icon: 'green',
      },
      {
        title: `${languageStrings.minMeasurmentIntervals}:`,
        subtitle: '2 min',
        icon: 'green',
      },
      {
        title: `${languageStrings.minTransmissionIntervals}:`,
        subtitle: `1 ${languageStrings.hourLabel}`,
        icon: 'green',
      },
      {
        title: languageStrings.incidentsNotifications,
        icon: 'green',
      },
      {
        title: languageStrings.GPSLocationText,
        icon: 'green',
      },
    ],
  };
};

export const advanced = (languageStrings: LanguageStrings) => {
  return {
    title: `${languageStrings.advancedLabel}:`,
    subtitle: languageStrings.overflowLabel,
    content: [
      {
        title: `${languageStrings.measurementsFromDevice}:`,
        list: [languageStrings.distanceToWater, languageStrings.temperatureLowCase],
        icon: 'green',
      },
      {
        title: `${languageStrings.minMeasurmentIntervals}:`,
        subtitle: '2 min',
        icon: 'green',
      },
      {
        title: `${languageStrings.minTransmissionIntervals}:`,
        subtitle: `1 ${languageStrings.hourLabel}`,
        icon: 'green',
      },
      {
        title: languageStrings.incidentsNotifications,
        icon: 'green',
      },
      {
        title: languageStrings.GPSLocationText,
        icon: 'green',
      },
      {
        title: `${languageStrings.overflowBasedOn}:`,
        list: [
          languageStrings.gravitationalCircularCulvert,
          languageStrings.freeFlowCircularCulvert,
          languageStrings.vWeirLabel,
        ],
        icon: 'green',
      },
      {
        title: `${languageStrings.flowMeasurementBasedOn}:`,
        list: [languageStrings.manningsPair],
        icon: 'red',
      },
    ],
  };
};

export const premium = (languageStrings: LanguageStrings) => {
  return {
    title: `${languageStrings.premiumLabel}:`,
    subtitle: languageStrings.flowMeasurementsLabel,
    content: [
      {
        title: `${languageStrings.measurementsFromDevice}:`,
        list: [languageStrings.distanceToWater, languageStrings.temperatureLowCase],
        icon: 'green',
      },
      {
        title: `${languageStrings.minMeasurmentIntervals}:`,
        subtitle: '2 min',
        icon: 'green',
      },
      {
        title: `${languageStrings.minTransmissionIntervals}:`,
        subtitle: `1 ${languageStrings.hourLabel}`,
        icon: 'green',
      },
      {
        title: languageStrings.incidentsNotifications,
        icon: 'green',
      },
      {
        title: languageStrings.GPSLocationText,
        icon: 'green',
      },
      {
        title: `${languageStrings.overflowBasedOn}:`,
        list: [
          languageStrings.gravitationalCircularCulvert,
          languageStrings.freeFlowCircularCulvert,
          languageStrings.vWeirLabel,
        ],
        icon: 'green',
      },
      {
        title: `${languageStrings.flowMeasurementBasedOn}:`,
        list: [languageStrings.manningsPair],
        icon: 'green',
      },
    ],
  };
};

export const premiumSmartCap = (languageStrings: LanguageStrings) => {
  return {
    title: `${languageStrings.premiumLabel}:`,
    subtitle: languageStrings.flowMeasurementsLabel,
    content: [
      {
        title: languageStrings.standingWaterDetection,
        icon: 'green',
      },
      {
        title: languageStrings.submergeInWaterDetection,
        icon: 'green',
      },
      {
        title: languageStrings.tamperDetection,
        icon: 'green',
      },
      {
        title: languageStrings.temperatureMeasurements,
        icon: 'green',
      },
      {
        title: languageStrings.humidityMeasurements,
        icon: 'green',
      },
      {
        title: `${languageStrings.minMeasurmentIntervals}:`,
        subtitle: '2 min',
        icon: 'green',
      },
      {
        title: `${languageStrings.minTransmissionIntervals}:`,
        subtitle: `1 ${languageStrings.hourLabel}`,
        icon: 'green',
      },
    ],
  };
};
