import React from 'react';
import { ManholesType } from '../ManholeButton';
import { useTheme } from '@cthings.co/styled-components';

export const ManholeWithWavesImage = ({ colorType }: { colorType: ManholesType }) => {
  const theme = useTheme();
  const { primary, secondary1, red0, red2, orange2, orange0 } = theme.colors;
  const setColorTheme = (colorType: ManholesType) => {
    const colors: any = {
      [ManholesType.GREEN]: { fill: primary, hoverColor: primary, bordersColor: secondary1 },
      [ManholesType.RED]: { fill: red2, hoverColor: red2, bordersColor: red0 },
      [ManholesType.YELLOW]: { fill: orange2, hoverColor: orange2, bordersColor: orange0 },
      [ManholesType.NONE]: { fill: '', hoverColor: '', bordersColor: '' },
    };
    return colors[colorType];
  };

  return (
    <svg width="60" height="60" viewBox="-10 -10 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        className={'opacityFirstLayer'}
        opacity="0.2"
        cx="20"
        cy="20"
        r="22.5"
        stroke={setColorTheme(colorType).hoverColor}
        stroke-width="11"
      />
      <circle
        className={'opacitySecondLayer'}
        opacity="0.2"
        cx="20"
        cy="20"
        r="18.5"
        stroke={setColorTheme(colorType).hoverColor}
        stroke-width="11"
      />
      <circle
        cx="19.9955"
        cy="19.9955"
        r="19.6592"
        fill={setColorTheme(colorType).fill}
        stroke={setColorTheme(colorType).bordersColor}
        stroke-width="0.672575"
      />
      <path
        d="M19.9999 3.10547C10.6842 3.10547 3.10535 10.6844 3.10535 20.0001C3.10535 29.3159 10.6843 36.8947 19.9999 36.8947C29.3155 36.8947 36.8945 29.3158 36.8945 20.0001C36.8946 10.6844 29.3157 3.10547 19.9999 3.10547ZM19.9999 35.3562C11.5325 35.3562 4.64388 28.4674 4.64388 20.0001C4.64388 11.5328 11.5326 4.64401 19.9999 4.64401C28.4673 4.64401 35.3559 11.5328 35.3559 20.0001C35.3561 28.4674 28.4673 35.3562 19.9999 35.3562Z"
        fill={setColorTheme(colorType).bordersColor}
      />
      <path
        d="M36.1435 31.7916C38.5667 28.483 40 24.406 40 20.0001C40 15.5939 38.5666 11.5168 36.1432 8.20809L31.7916 3.8565C28.4829 1.4332 24.406 0 20 0C15.5939 0 11.517 1.4333 8.2084 3.8565L3.8567 8.20799C1.4333 11.5168 0 15.5939 0 20.0001C0 24.4061 1.4332 28.483 3.8565 31.7916L8.20799 36.1432C11.5167 38.5666 15.5938 40 20 40C24.4061 40 28.4832 38.5666 31.7919 36.1432L36.1435 31.7916ZM1.53854 20.0001C1.53854 9.82038 9.82027 1.53854 20 1.53854C30.1797 1.53854 38.4615 9.82038 38.4615 20.0001C38.4615 30.1797 30.1797 38.4615 20 38.4615C9.82027 38.4615 1.53854 30.1797 1.53854 20.0001Z"
        fill={setColorTheme(colorType).bordersColor}
      />
      <path
        d="M10 14C10.6 14.5 11.2 15 12.5 15C15 15 15 13 17.5 13C20.1 13 19.9 15 22.5 15C25 15 25 13 27.5 13C28.8 13 29.4 13.5 30 14M10 20C10.6 20.5 11.2 21 12.5 21C15 21 15 19 17.5 19C20.1 19 19.9 21 22.5 21C25 21 25 19 27.5 19C28.8 19 29.4 19.5 30 20M10 26C10.6 26.5 11.2 27 12.5 27C15 27 15 25 17.5 25C20.1 25 19.9 27 22.5 27C25 27 25 25 27.5 25C28.8 25 29.4 25.5 30 26"
        stroke="#023010"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
