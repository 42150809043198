import { EMPTY_ADDRESS } from '../../../components/selectAddress/data';
import { DEFAULT_USER, EMPTY_USER } from './consts';
import { ClientsTableState } from './types';

export const initialState: ClientsTableState = {
  isEditInProcess: false,
  treeHistory: [],
  users: [DEFAULT_USER],
  userInEdit: EMPTY_USER,
  devices: [],
  failedDevicesCount: 0,
  uploadedDevices: [],
  fromDevice: null,
  toDevice: null,
  deviceSelectionMode: 'individual',
  parentOrganisation: {
    name: '',
    phone: '',
    parentOrganisation: { name: '', id: '' },
    profile: { name: '', id: '' },
    email: '',
    address: EMPTY_ADDRESS,
  },
  parentOrganisationsList: null,
  profilesList: [],
  isSelectedField: false,
  isDeviceMode: false,
  finaliseTrigger: 0,
};
