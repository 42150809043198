import React, { Dispatch, FC, SetStateAction, useState, useEffect } from 'react';
import {
  ItemWrapper,
  StyledNavLink,
  StyledText,
  TextWrapperManage,
  StyledManageNavlink,
  ManageMobileWrapper,
} from './styled';
import { TextType } from '@bit/first-scope.text';
import { getAccess } from '../../../routes';
//images
import { ReactComponent as ManageImage } from '../../../assets/Manage.svg';
import { useStyleContext } from '@cthings.co/styles-utils';
import { SecondaryMenuItem } from './mobileNavigation/MobileNavigation';
import { MenuItemType } from '../MenuItem';
import { ItemImage } from './ItemImages';
import { useTheme } from '@cthings.co/styled-components';
import { ArrowButton, ArrowDirectionType, ArrowSizeType } from '@cthings.co/buttons';
import { getTextWidth } from '../../../utils/textWidth';
import { NavigationDropdown } from './navigationDropdown/NavigationDropdown';
import { LsValueType } from '../../../enums/LsValueType';
import ss from '../../../utils/ss';

export interface ManageItemTypes {
  manageRef: any;
  isActive: boolean;
  to: any;
  type: MenuItemType;
  childRoutes: any;
  isOpen: boolean;
  value?: string;
  setOpenManageList: any;
  openManageList: boolean;
  secondaryMenuItems?: SecondaryMenuItem[];
  setSecondaryMenuItems?: Dispatch<SetStateAction<SecondaryMenuItem[]>> | undefined;
}

export const ManageItem: FC<ManageItemTypes> = ({
  manageRef,
  isActive,
  to,
  childRoutes,
  type,
  isOpen,
  value,
  setOpenManageList,
  openManageList,
  secondaryMenuItems,
  setSecondaryMenuItems,
}) => {
  const [isManageArrowOpen, setIsManageArrowOpen] = useState(false);

  const [
    {
      mediaType: { tablet },
    },
  ] = useStyleContext();

  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const theme = useTheme();

  const { additional4 } = theme.borderRadius;
  const { primary } = theme.colors;
  const route = window.location.href;
  const childRoutesLabels = childRoutes.map((item: any) => {
    return item.name;
  });

  const longestLabel = childRoutesLabels.sort((a: string, b: string) => b.length - a.length)[0];

  const textWidth = getTextWidth(longestLabel, { font: '15px Poppins' });

  const onManageClickHandler = (e: any) => {
    e.preventDefault();
    setOpenManageList((el: boolean) => !el);
    setIsManageArrowOpen && setIsManageArrowOpen(true);
    setSecondaryMenuItems && setSecondaryMenuItems(childRoutes);
  };

  useEffect(() => {
    secondaryMenuItems && !secondaryMenuItems.length && setIsManageArrowOpen(false);
  }, [secondaryMenuItems]);

  return (
    <ItemWrapper ref={manageRef}>
      <StyledNavLink
        to={to}
        access={getAccess(to)}
        onClick={(e: any) => onManageClickHandler(e)}
        borderRadius={`0 ${additional4} ${additional4} 0`}
        className={isActive ? 'active' : ''}
      >
        <ItemImage type={type} />
        <TextWrapperManage className={isOpen ? 'open' : ''}>
          {tablet ? (
            <>
              <ManageMobileWrapper>
                <ManageImage />
                <StyledText
                  type={TextType.MENU_ITEM_TEXT_PRIMARY}
                  color={theme.colors.black1}
                  hoverColor={theme.colors.primary}
                >
                  {value}
                </StyledText>
              </ManageMobileWrapper>
              <ArrowButton
                isReveresed={isManageArrowOpen}
                direction={ArrowDirectionType.RIGHT}
                size={ArrowSizeType.SMALL}
              />
            </>
          ) : (
            <>
              <StyledText
                type={TextType.MENU_ITEM_TEXT_PRIMARY}
                color={theme.colors.black1}
                hoverColor={theme.colors.primary}
              >
                {value}
              </StyledText>
              <ArrowButton
                isReveresed={openManageList}
                direction={ArrowDirectionType.RIGHT}
                size={ArrowSizeType.SMALL}
              />
            </>
          )}
        </TextWrapperManage>
      </StyledNavLink>
      {childRoutes && childRoutes.length ? (
        <NavigationDropdown className={openManageList ? 'open' : ''}>
          {childRoutes.map((item: any, index: number) => {
            const { name, to } = item;
            const checkedTo = `${to.split('/')[0]}/${to.split('/')[1]}/${to.split('/')[2]}`;
            return (
              <StyledManageNavlink
                key={index}
                to={`${to}?access_token=${token}&expires=${exp}`}
                access={getAccess(to)}
                className={route.includes(checkedTo) ? 'active' : ''}
                hoverColor={primary}
              >
                {name}
              </StyledManageNavlink>
            );
          })}
        </NavigationDropdown>
      ) : (
        <></>
      )}
    </ItemWrapper>
  );
};
