import axios, { AxiosResponse } from 'axios';
import { requestPush } from '../app/state/routes';
import { store } from '../app/store';
import { LsValueType } from '../enums/LsValueType';
import oauthRedirect from '../features/authentication/oauthRedirect';
import { LoaderOptions } from '../features/placeholderComponent/types';
import { View } from '../routes';
import ss from './ss';

export enum ApiCallType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export class HttpService {
  static source = axios.CancelToken.source();
  static token = ss.get(LsValueType.token);
  static locale = ss.get(LsValueType.locale);

  static getLanguage() {
    return ss.get(LsValueType.locale);
  }

  static setLanguage(locale: string) {
    ss.set(LsValueType.locale, locale);
    HttpService.locale = locale;
  }

  static removeLanguage() {
    ss.remove(LsValueType.locale);
    HttpService.locale = null;
  }

  static getToken() {
    return ss.get(LsValueType.token);
  }

  static setToken(token: string) {
    ss.set(LsValueType.token, token);
    HttpService.token = token;
  }

  static removeToken() {
    ss.remove(LsValueType.token);
    HttpService.token = null;
  }

  static get(url: string, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'get',
      ...options,
    });
  }

  static post(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'post',
      data,
      ...options,
    });
  }

  static put(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'put',
      data,
      ...options,
    });
  }
  static patch(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'patch',
      data,
      ...options,
    });
  }

  static delete(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'delete',
      data,
      ...options,
    });
  }

  static makeRequest(config: any) {
    let location: any;
    let setPlaceholder: any;

    if (config.location) {
      location = config.location;
      delete config.location;
    }

    if (config.setPlaceholder) {
      setPlaceholder = config.setPlaceholder;
      delete config.setPlaceholder;
      setPlaceholder(false);
    }

    const cancelToken = this.source.token;
    const token = HttpService.getToken();
    const locale = HttpService.getLanguage();

    const localeOptions = locale ? { 'Accept-Language': locale } : {};

    if (token) {
      config.headers = Object.assign(config.headers || {}, {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        /*'x-api-key': 'n02wIwHelV3Xd9H9YSSwL6kuoJ0ZQnFy7Y3yuh69',*/
        ...localeOptions,
      });
    }

    return axios
      .request({ ...config, cancelToken })
      .then((res) => {
        setPlaceholder && setPlaceholder(true);
        return res;
      })
      .catch((er) => {
        switch (er.message.slice(-3)) {
          case '401':
            if (ss.get(LsValueType.token) && Math.floor(Date.now() / 1000) < ss.get(LsValueType.exp)) {
              store.dispatch(requestPush(View.ERROR_401));
            } else {
              ss.remove(LsValueType.token);

              oauthRedirect();
            }
            break;
          case '403':
            store.dispatch(requestPush(View.ERROR_403));
            break;
          case '404':
            store.dispatch(requestPush(View.ERROR_404));
            break;
          default:
            break;
        }
        setPlaceholder && setPlaceholder(true);
        throw er;
      });
  }
}

const httpRequestMatch = {
  [ApiCallType.GET]: { call: HttpService.get, withoutBody: true },
  [ApiCallType.POST]: { call: HttpService.post },
  [ApiCallType.PATCH]: { call: HttpService.patch },
  [ApiCallType.PUT]: { call: HttpService.put },
  [ApiCallType.DELETE]: { call: HttpService.delete },
};

export interface HttpRequest {
  call: (url: string, body?: Object, options?: Object) => Promise<AxiosResponse<any>>;
  withoutBody?: boolean;
}

export const getHttpRequest = (callType: ApiCallType): HttpRequest => httpRequestMatch[callType];

export interface HttpServiceGenerationOptions {
  url: string;
  body?: Object;
  loaderOptions: LoaderOptions;
  callType: ApiCallType;
}

export const getHttpService = ({ callType, url, body = {}, loaderOptions }: HttpServiceGenerationOptions) => {
  const httpService = getHttpRequest(callType);
  const { call: httpServiceCall, withoutBody } = httpService;
  const optionsWithBody = [url, body, loaderOptions] as const;
  const optionsWithoutBody = [url, loaderOptions] as const;
  return withoutBody ? () => httpServiceCall(...optionsWithoutBody) : () => httpServiceCall(...optionsWithBody);
};
