import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTooltip } from '../../components/tooltip';
import { MenuItem, MenuItemType } from './MenuItem';
import { isMpwik } from '../../consts';
import {
  Wrapper,
  IconArrowContainer,
  LogoContainer,
  StyledLogoMpwik,
  StyledLogo,
  ArrowContainer,
  BodyNavigation,
} from './styled';
import { useStyleContext } from '@cthings.co/styles-utils';
import SUPLogo from '../../assets/SmartUtilities.svg';
import { MobileNavigation } from './components/mobileNavigation/MobileNavigation';
import { useSelector } from 'react-redux';
import { selectAccess } from '../../app/state/user';
import { useTheme } from '@cthings.co/styled-components';
import { ArrowButton, ArrowSizeType, ArrowDirectionType } from '@cthings.co/buttons';

export interface NavigationProps {
  onLogout: any;
  userStatus: string;
  className?: string;
  menuItemArray: any[];
  menuMobileItemArray: any[];
  withLanguages?: boolean;
}

export const Navigation: FC<NavigationProps> = ({
  onLogout,
  userStatus,
  menuItemArray,
  menuMobileItemArray,
  withLanguages,
  ...props
}) => {
  const theme = useTheme();
  const [media] = useStyleContext();

  const [isOpen, setIsOpen] = useState(false);

  const access = useSelector(selectAccess);

  const nav: any = useRef();
  const [isHidden, setIsHidden] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (!isHidden && window.scrollY > scrollY) {
        setIsHidden(true);
        setScrollY(window.scrollY);
      } else if (isHidden && window.scrollY < scrollY) {
        setIsHidden(false);
        setScrollY(window.scrollY);
      } else if (window.scrollY < 0) {
        setIsHidden(false);
      } else {
        setScrollY(window.scrollY);
      }
    };

    const addEventScroll = () => {
      if (nav) {
        window.addEventListener('scroll', handleScroll);
      }
      window.addEventListener('touchmove', handleScroll);
    };

    const removeEventListener = () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };

    media.mediaType.phone ? addEventScroll() : removeEventListener();
    return () => {
      removeEventListener();
    };
  }, [isHidden, scrollY]);

  const outsideHandler = useCallback(
    (e: any) => {
      isOpen && !nav.current.contains(e.target) && setIsOpen(false);
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const [tooltipIds] = useTooltip(menuItemArray.length);

  const billingAccess = new Set(Object.values<any>(access.billing ?? {}).map((value) => value.view));

  return (
    <Wrapper
      ref={nav}
      theme={theme}
      className={`${isOpen ? 'open' : ''} ${media.mediaType.phone && isHidden && 'hidden'}`}
    >
      <IconArrowContainer className={isOpen ? 'open' : ''}>
        <LogoContainer className={isOpen ? 'open' : ''} height={isMpwik ? '24px' : '30px'}>
          {isMpwik ? <StyledLogoMpwik /> : <StyledLogo src={SUPLogo} alt={'SUP logo'} />}
        </LogoContainer>
        <ArrowContainer className={isOpen ? 'open' : ''}>
          <ArrowButton
            size={ArrowSizeType.SMALL}
            direction={ArrowDirectionType.RIGHT}
            onClick={() => setIsOpen(!isOpen)}
          />
        </ArrowContainer>
      </IconArrowContainer>
      <BodyNavigation>
        {media.mediaType.tablet ? (
          <MobileNavigation
            menuMobileItemArray={menuItemArray}
            onLogout={onLogout}
            billingAccess={billingAccess}
            withLanguages
          />
        ) : (
          <>
            {menuItemArray.map((item, index) => {
              const { value, route, type, disabled, hidden, childRoutes } = item;

              return (
                !(
                  (type === MenuItemType.BILLING && billingAccess.size === 1 && billingAccess.has(false)) ||
                  (type === MenuItemType.BILLING && !billingAccess.size)
                ) && (
                  <MenuItem
                    key={index}
                    type={type}
                    value={value}
                    to={route}
                    isOpen={isOpen}
                    disabled={disabled}
                    hidden={hidden}
                    tooltipId={tooltipIds[index]}
                    childRoutes={childRoutes ? childRoutes : null}
                  />
                )
              );
            })}
          </>
        )}
      </BodyNavigation>
      {!media.mediaType.tablet && <div style={{ flex: 1 }}></div>}
      {!media.mediaType.tablet && withLanguages && <MenuItem type={MenuItemType.LANGUAGES} isOpen={isOpen} />}
      {!media.mediaType.tablet && (
        <MenuItem type={MenuItemType.LOGOUT} isOpen={isOpen} userName={userStatus} onLogout={onLogout} />
      )}
    </Wrapper>
  );
};
