import { RefObject, useEffect, useState } from 'react';
import { debounce } from './throttling';

export const useSlicedScroll = (ref: RefObject<HTMLElement> | null) => {
  const [pages, setPages] = useState(1);

  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      debounce(() => setPages((current) => current + 1), 700)();
    }
  });

  useEffect(() => {
    if (ref?.current && observer) {
      observer?.observe && ref.current?.lastElementChild && observer?.observe(ref.current?.lastElementChild);
    }
  }, [ref?.current]);

  return [pages];
};
