import React from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import { ReactComponent as HelpIcon } from '../../../../../../../../../../../../../assets/help.svg';
import { CustomModal } from '../../../../../../../../../../../../../components/customModal/CustomModal';
import { InfoButton } from '../../../../../../../../../../../../../components/infoButton/InfoButton';
import { CommonButton } from '../../../../../../../../../../../../../components/commonButton/CommonButton';
import { useTheme } from '@cthings.co/styled-components';

const StyledText = styled(Text)`
  white-space: pre-line;
`;
interface CommonModalProps {
  data: any;
  isOpenModal: boolean;
  isCalibration?: boolean;
}

export const CommonModal: React.FC<CommonModalProps> = ({ data, isOpenModal, isCalibration }) => {
  const theme = useTheme();
  const { black1, gray1, orange2 } = theme.colors;

  return (
    <CustomModal isOpen={isOpenModal}>
      <div style={data.title.styles}>
        <Text
          type={TextType.TEXT_14_BLACK}
          color={isCalibration ? orange2 : black1}
          fontSize={'18px'}
          weight={'600'}
          {...data.title}
        >
          {data.title.value}
        </Text>
        {data.title.tooltipText && (
          <InfoButton
            customIcon={<HelpIcon />}
            link={data.title.tooltipLink}
            onClick={data.title.tooltipOnClick}
            tooltipText={data.title.tooltipText}
          />
        )}
      </div>
      <div style={data.content.styles}>
        {data.content.items.map((item: any) => {
          return (
            <div style={item.styles}>
              {item.values.map((value: any) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <StyledText type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'} {...value.style}>
                      {value.text}
                    </StyledText>
                    {value.tooltipText && (
                      <InfoButton
                        link={value.tooltipLink}
                        onClick={value.tooltipOnClick}
                        tooltipText={value.tooltipText}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div style={data.buttons.styles}>
        {data.buttons.values.map((item: any) => {
          return <CommonButton {...item}>{item.text}</CommonButton>;
        })}
      </div>
    </CustomModal>
  );
};
