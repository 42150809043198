import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { updateDynamicParams } from '../../../../../../../api/realTimeInsight';
import { selectDeviceInfo } from '../../../../../../../app/state/insight';
import { PlaceholderType } from '../../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { withLoader } from '../../../../../../../features/placeholderComponent/loaderFunctions';
import { NoDataPlaceholder } from '../../../../../../../features/universalTable/components/noDataPlaceholder/NoDataPlaceholder';
import { RowItemType } from '../../../../../../../features/universalTable/types';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useBlockBodyScroll } from '../../../../../../../utils/useBlockBodyScroll';
import { FieldsType, isValidField } from '../../../../../../../utils/validation';
import { TableBlockWrapper } from '../common/Common';
import { DeviceConfigurationRow } from './components/deviceConfigurationRow/DeviceConfigurationRow';
import { MobileEditModal } from './components/mobileEditModal/MobileEditModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colorFetch('white')};
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
`;

const TableWrapper = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(Wrapper);

const NoData = styled(NoDataPlaceholder)`
  border-radius: 0 0 8px 8px;
`;

export enum DeviceConfigurationRowType {
  HEADER_ROW = 'HEADER_ROW',
  PRIMARY_ROW = 'PRIMARY_ROW',
}

export interface DeviceConfigurationTableProps {
  values: any[];
  changeValueTextarea: (
    key: string,
    val: string | any[],
    handleRevert?: () => void,
    setValueByAnswer?: (value: any) => void,
  ) => void;
  deviceName: string;
  setDeviceName: Function;
  className?: string;
  isAccessDeviceConfiguration?: boolean;
  languageStrings?: any;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}

export const DeviceConfigurationTable: FC<DeviceConfigurationTableProps> = ({
  values,
  changeValueTextarea,
  isAccessDeviceConfiguration,
  languageStrings,
  accessData,
  deviceName,
  setDeviceName,
  ...props
}) => {
  const [localValues, setLocalValues] = useState(values);
  const [savedData, setSavedData] = useState<any>([]);
  const [isOpenMobileEditModal, setIsEditMobileEditModal] = useState(false);
  const [validatingIsStarted, setIsStartedValidating] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const theme = useTheme();
  const deviceId = useSelector(selectDeviceInfo).id;
  const { pureWhite, primary, secondary2, black1 } = theme.colors;
  const { addNotification } = useNotifierFunctions();
  useBlockBodyScroll(isOpenMobileEditModal);

  const setField = (
    parameter_id: string,
    index: number,
    val: any,
    handleRevert?: () => void,
    setValueByAnswer?: (value: any, index: number) => void,
  ) => {
    const preparedValues = localValues.map((item: any, i: number) =>
      i === index ? { ...item, value: { data: val } } : item,
    );
    if (localValues[index].value.data !== val) {
      setLocalValues(preparedValues);
      changeValueTextarea(
        parameter_id,
        val,
        () => {
          setLocalValues(savedData);
          handleRevert && handleRevert();
        },
        (value: any) => {
          setValueByAnswer && setValueByAnswer(value, index);
        },
      );
    }
  };
  const handleRevertData = () => {
    setLocalValues(savedData);
  };
  const handleOpenMobileEditModal = () => {
    setIsEditMobileEditModal(true);
    setSavedData(localValues);
  };
  const handleCloseMobileEditModal = () => {
    setLocalValues(savedData);
    setIsStartedValidating(false);
    setIsEditMobileEditModal(false);
    setDeviceName(savedData[0].value.data);
  };
  const handleSaveData = () => {
    setIsStartedValidating(true);
    if (isAllFieldsValid) {
      const preparedValues = localValues.map((item: any) => ({ id: item.id, value: item.value.data }));
      updateDynamicParams(deviceId, preparedValues, addNotification, handleRevertData, languageStrings);
      setIsStartedValidating(false);
      setIsEditMobileEditModal(false);
      setSavedData([]);
    }
  };
  const globalSetSavedData = () => {
    setSavedData(localValues);
  };
  const setValueByAnswer = (value: any, index: number) => {
    const preparedValues = localValues.map((item: any, i: number) => (i === index ? value : item));
    setLocalValues(preparedValues);
  };

  const inputsList = localValues.map((item: any, index: number) => {
    const { requested_value, metadata, value, request_status, id } = item;
    const { name, label_locale_key, tooltip_locale_key, input_type, limit, step_size, unit, symbol } = metadata;
    return {
      id,
      value: value,
      description: label_locale_key,
      fieldName: name,
      label: symbol,
      requested_value: requested_value,
      type: RowItemType.INPUT,
      inputType: input_type,
      placeholder: languageStrings.enterInfoPlaceholder,
      isError: index === 0 ? !isValidField(deviceName, FieldsType.TEXT) : false,
      tooltip: tooltip_locale_key,
      validatingIsStarted,
      requestStatus: request_status,
      options: {
        ...limit,
        step: step_size,
        unit,
        checkboxLabel: index === 5 ? languageStrings.depthUnknown : '',
      },
      handleChangeValue: (val: any, index: number) => {
        const prepareArr = (v: any) =>
          localValues.map((item: any, i: number) => (i === index ? { ...item, value: { data: v } } : item));
        if (index === 0) {
          setDeviceName(val);
        }
        setLocalValues(prepareArr(val));
      },
    };
  });

  const isAllFieldsValid = inputsList.length === inputsList.filter((item: any) => !item.isError).length;

  const buttonsList = [
    {
      value: languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsSave,
      onClick: handleSaveData,
      color: pureWhite,
      colorHover: pureWhite,
      borderColor: primary,
      borderColorHover: secondary2,
      backgroundColor: primary,
      backgroundColorHover: secondary2,
    },
    {
      value: languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel,
      onClick: handleCloseMobileEditModal,
      color: black1,
      colorHover: pureWhite,
      borderColor: primary,
      borderColorHover: primary,
      backgroundColor: pureWhite,
      backgroundColorHover: primary,
    },
  ];

  const placeholder = {
    keyword: 'InsightDetailsDeviceConfigurationTable',
    placeholderOptions: { countRows: 4, withoutPagination: false },
    conditionalStyle: { overflow: 'hidden' },
  };

  useEffect(() => {
    setLocalValues(values);
  }, [values]);

  return (
    <TableBlockWrapper>
      <Text type={TextType.SEMITITLE} margin={'0 0 10px 0'}>
        {languageStrings.configuration}
      </Text>
      <TableWrapper {...props} placeholderProps={placeholder}>
        <DeviceConfigurationRow
          type={DeviceConfigurationRowType.HEADER_ROW}
          handleEditInHeaderOnClick={handleOpenMobileEditModal}
          accessData={accessData}
          languageStrings={languageStrings}
        />
        {inputsList.length > 0 ? (
          inputsList.map((val: any, index: number) => {
            const { id, value, fieldName, label, tooltip, inputType, isError, description } = val;
            return (
              <DeviceConfigurationRow
                type={DeviceConfigurationRowType.PRIMARY_ROW}
                isEdit={selectedIndex === index}
                setSelectedIndex={setSelectedIndex}
                data={val}
                inputType={inputType}
                parameter={label}
                key={fieldName}
                value={value}
                description={description}
                changeValue={(data: any, handleRevert?: () => void) => {
                  setField(id, index, data, handleRevert, setValueByAnswer);
                  if (index === 0) {
                    setDeviceName(data);
                  }
                }}
                infoTooltip={tooltip}
                isLastRow={index === inputsList.length - 1}
                isAccessDeviceConfiguration={isAccessDeviceConfiguration}
                accessData={accessData}
                index={index}
                isFieldValid={!isError}
                setDeviceName={setDeviceName}
                globalSetSavedData={globalSetSavedData}
                languageStrings={languageStrings}
              />
            );
          })
        ) : (
          <NoData />
        )}
        <MobileEditModal
          isOpenModal={isOpenMobileEditModal}
          handleCloseModal={handleCloseMobileEditModal}
          inputsList={inputsList}
          buttonsList={buttonsList}
          languageStrings={languageStrings}
        />
      </TableWrapper>
    </TableBlockWrapper>
  );
};
