import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  gridTemplate?: string;
  isFirst?: boolean;
  isLast?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: grid;
  height: max-content;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate || '1.25fr 1.5fr'};
  grid-gap: 24px;
  padding: 11px 22px 10px 22px;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  border-top: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray4')};
  border-radius: ${({ isLast }) => (isLast ? '0 0 8px 8px' : 'none')};
  ${media.phone} {
    height: max-content;
    padding: 13px 16px;
  }
`;

const WrapperText = styled(Text)`
  color: ${colorFetch('gray1')};
`;

type StyledTextRSRPProps = {
  value: number;
};

const StyledTextRSRP = styled(Text)<StyledTextRSRPProps>`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
  color: ${({ value, theme }) =>
    value
      ? value >= -84
        ? colorFetch('green2')({ theme })
        : value <= -85 && value >= -102
        ? colorFetch('green2')({ theme })
        : value <= -103 && value >= -111
        ? colorFetch('orange2')
        : value <= -111 && colorFetch('red2')({ theme })
      : colorFetch('gray1')({ theme })};
` as any; // @TODO fix type

type StyledTextRSRQProps = {
  value: number;
};

const StyledTextRSRQ = styled(Text)<StyledTextRSRQProps>`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
  color: ${({ value, theme }) =>
    value
      ? value > -5
        ? colorFetch('green2')({ theme })
        : value <= -5 && value >= -9
        ? colorFetch('green2')({ theme })
        : value < -9 && value >= -12
        ? colorFetch('orange2')
        : value < -12 && colorFetch('red2')({ theme })
      : colorFetch('gray1')({ theme })};
` as any; // @TODO fix type

type StyledTextSINRProps = {
  value: number;
};

const StyledTextSINR = styled(Text)<StyledTextSINRProps>`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
  color: ${({ value, theme }) =>
    value
      ? value > 12.5
        ? colorFetch('green2')({ theme })
        : value >= 10 && value <= 12.5
        ? colorFetch('green2')({ theme })
        : value >= 7 && value < 10
        ? colorFetch('orange2')({ theme })
        : value < 7 && colorFetch('red2')({ theme })
      : value === 0
      ? colorFetch('red2')({ theme })
      : colorFetch('gray1')({ theme })};
` as any; // @TODO fix type

type StyledTextRSSIProps = {
  value: number;
};

const StyledTextRSSI = styled(Text)<StyledTextRSSIProps>`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 500;
  color: ${({ value, theme }) =>
    value
      ? value > -65
        ? colorFetch('green2')({ theme })
        : value <= -65 && value >= -75
        ? colorFetch('green2')({ theme })
        : value < -75 && value >= -85
        ? colorFetch('orange2')
        : value < -85 && colorFetch('red2')({ theme })
      : colorFetch('gray1')({ theme })};
` as any; // @TODO fix type

export enum SignalStrengthType {
  RSRP = 'RSRP',
  RSRQ = 'RSRQ',
  SINR = 'SINR',
  RSSI = 'RSSI',
}

export const TableRow = ({
  property,
  value,
  isFirst,
  isLast,
  gridTemplate,
  isColor,
  type,
  ...props
}: {
  property: string;
  value: number;
  isFirst?: boolean;
  isLast?: boolean;
  isColor?: boolean;
  gridTemplate?: string;
  className?: string;
  type: any;
}) => {
  const theme = useTheme();

  return (
    <Wrapper isFirst={isFirst} isLast={isLast} gridTemplate={gridTemplate} {...props}>
      <WrapperText type={TextType.TEXT_14_BLACK} weight={'400'}>
        {property}
      </WrapperText>
      {isColor ? (
        <>
          {type === SignalStrengthType.RSSI && (
            <StyledTextRSSI value={value} theme={theme}>
              {value ? `${value} dBm` : 'N/A'}
            </StyledTextRSSI>
          )}
          {type === SignalStrengthType.RSRP && (
            <StyledTextRSRP value={value} theme={theme}>
              {value ? `${value} dBm` : 'N/A'}
            </StyledTextRSRP>
          )}
          {type === SignalStrengthType.SINR && (
            <StyledTextSINR value={value} theme={theme}>
              {value || value === 0 ? `${value} dB` : 'N/A'}
            </StyledTextSINR>
          )}
          {type === SignalStrengthType.RSRQ && (
            <StyledTextRSRQ value={value} theme={theme}>
              {value ? `${value} dB` : 'N/A'}
            </StyledTextRSRQ>
          )}
        </>
      ) : (
        <WrapperText type={TextType.TEXT_14_BLACK}>{value || 'N/A'}</WrapperText>
      )}
    </Wrapper>
  );
};
