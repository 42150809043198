import React, { FC, ReactNode, useState, ChangeEvent } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { TextareaModal } from '../textareaModal/TextareaModal';
import { ReactComponent as Info } from '../../assets/infoButton.svg';
import { CommonButton } from '@cthings.co/buttons';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type ExtraWrapperProps = {
  width?: string;
  margin?: string;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  width: ${({ width }) => (width ? width : '100%')};
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => (margin ? margin : '0 0 14px 0')};
`;

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  position: relative;
`;

type InputElementProps = {
  width?: string;
  inputHeight?: string;
  isError?: boolean;
  isFocused: boolean;
  image: boolean;
  primaryBorderRadius: string;
};

const InputElement = styled.input<InputElementProps>`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ inputHeight }) => inputHeight || '40px'};
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  color: ${colorFetch('gray1')};
  &::placeholder {
    font-family: 'Poppins', sans-serif;
    -webkit-safari-color: ${colorFetch('gray2')};
    color: ${colorFetch('gray2')};
  }
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid
    ${({ disabled, isError, isFocused, theme }) =>
      disabled
        ? colorFetch('gray5')({ theme })
        : isError
        ? colorFetch('red2')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : colorFetch('gray3')({ theme })};

  border-radius: ${({ primaryBorderRadius }) => primaryBorderRadius};
  padding: ${({ image }) => (image ? '8px 36px 8px 38px' : '8px 12px')};
  outline: none;
  transition: all 0.3s linear;

  &.disabled {
    background-color: ${colorFetch('gray5')} !important;
    -webkit-safari-color: ${colorFetch('gray1')};
    color: ${colorFetch('gray1')} !important;
    -webkit-text-fill-color: ${colorFetch('gray1')};
    &::placeholder {
      -webkit-safari-color: ${colorFetch('gray1')};
      color: ${colorFetch('gray1')};
    }
  }

  opacity: 1; /* required on iOS */
`;

type ImageWrapperProps = {
  inputHeight?: string;
  imageColor?: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  height: ${({ inputHeight }) => inputHeight || '40px'};
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  & > svg > path {
    stroke: ${({ imageColor, theme }) => imageColor || colorFetch('primary')({ theme })};
  }
`;

type ButtonWrapperProps = {
  buttonRightPosition?: string;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  height: 100%;
  width: max-content;
  display: flex;
  align-items: center;
  position: absolute;
  right: ${({ buttonRightPosition }) => buttonRightPosition || '15px'};
`;

const AdditionalImage = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

type TooltipWrapperProps = {
  name?: string;
};

const TooltipWrapper = styled.div<TooltipWrapperProps>`
  display: flex;
  margin: ${({ name }) => (name ? '0 0 4px 6px' : '0')};
`;

const TooltipBlock = styled.div`
  position: absolute;
  background: #28293d;
  bottom: calc(100% + 10px);
  min-width: 200px;
  width: 100%;
  text-align: center;
  z-index: var(--modal-first-layer);
  line-height: 15px;
  padding: 6px;
  border-radius: 12px;
  left: 50%;
  -webkit-transform: translate(-51%, 0);
  -ms-transform: translate(-51%, 0);
  transform: translate(-51%, 0);
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden;
  opacity: 0;
  &::after {
    position: absolute;
    content: '';
    border-bottom: 15px solid #28293d;
    border-left: 15px solid transparent;
    bottom: -6px;
    transform: rotate(45deg);
    left: calc(50% - 5px);
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  height: max-content;
  margin-left: 4px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:hover ${TooltipBlock} {
    visibility: visible;
    opacity: 1;
  }
`;

const InfoIcon = styled(Info)`
  & > path {
    stroke: ${colorFetch('primary')};
  }
  &:hover > path {
    transition: all 0.3s linear;
    stroke: ${colorFetch('secondary2')};
  }
`;

const TooltipText = styled(Text)`
  color: ${colorFetch('white')};
  font-size: 10px;
  line-height: 15px;
`;

const StyledCommonButton = styled(CommonButton)`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  padding: 0 10px;
`;

export interface InputProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  placeholder: string;
  onClick?: any;
  onBlur?: any;
  setIsError?: any;
  type?: string;
  image?: any;
  additionalImage?: any;
  additionalImageOnClick?: any;
  disabled?: boolean;
  width?: string;
  inputHeight?: string;
  rows?: number;
  startedValidation?: boolean;
  isError?: boolean;
  fromAttach?: boolean;
  className?: string;
  isTooltip?: boolean;
  tooltipText?: string;
  imageColor?: string;
  margin?: string;
  withButton?: boolean;
  onButtonClick?: any;
  buttonText?: string;
  customButton?: any;
  buttonRightPosition?: string;
  tooltip?: ReactNode;
}

export const InputModal: FC<InputProps> = ({
  value,
  onChange,
  onClick,
  onBlur,
  setIsError,
  name,
  placeholder,
  image,
  type,
  width,
  inputHeight,
  additionalImage,
  additionalImageOnClick,
  rows,
  disabled,
  isError,
  startedValidation,
  fromAttach,
  isTooltip,
  tooltipText,
  imageColor,
  margin,
  withButton,
  onButtonClick,
  buttonText,
  customButton,
  buttonRightPosition,
  tooltip,
  ...props
}) => {
  const theme = useTheme();
  const { pureWhite, gray2, gray3 } = theme.colors;
  const [isFocused, setIsFocused] = useState(false);
  const { gray1, black1, red2, primary } = theme.colors;
  const { primary: primaryBorderRadius } = theme.borderRadius;
  const isShowMessage = fromAttach ? !!isError : startedValidation && !!isError && !disabled;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <ExtraWrapper {...props} width={width} margin={margin}>
      <TooltipWrapper name={name}>
        <Text type={TextType.TEXT_12_BLACK} color={gray1}>
          <div style={{ display: 'flex' }}>
            {name} {tooltip}
          </div>
        </Text>
        {tooltipText && (
          <InfoWrapper>
            <InfoIcon theme={theme} />
            <TooltipBlock>
              <TooltipText type={TextType.TEXT_12_BLACK}>{tooltipText || ''}</TooltipText>
            </TooltipBlock>
          </InfoWrapper>
        )}
      </TooltipWrapper>
      <Wrapper>
        {withButton && (
          <>
            {customButton ? (
              <ButtonWrapper buttonRightPosition={buttonRightPosition} onClick={onButtonClick}>
                {customButton}
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <StyledCommonButton
                  height={'26px'}
                  width={'max-content'}
                  colors={{
                    main: gray2,
                    mainHover: pureWhite,
                    background: pureWhite,
                    backgroundHover: gray2,
                    border: gray3,
                    borderHover: gray2,
                  }}
                  onClick={onButtonClick}
                >
                  {buttonText}
                </StyledCommonButton>
              </ButtonWrapper>
            )}
          </>
        )}
        {image ? (
          <ImageWrapper inputHeight={inputHeight} imageColor={imageColor}>
            {image}
          </ImageWrapper>
        ) : null}
        {rows && rows > 1 ? (
          <TextareaModal
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
              onBlur && onBlur();
            }}
            isFocused={isFocused}
            isError={startedValidation && isError}
            disabled={disabled}
            isImage={image}
            rows={rows}
          />
        ) : (
          <InputElement
            type={type ? type : 'text'}
            onClick={onClick}
            value={value ?? 'N/A'}
            width={width}
            placeholder={placeholder}
            className={disabled ? 'disabled' : ''}
            theme={theme}
            disabled={disabled}
            color={black1}
            isFocused={isFocused}
            isError={fromAttach ? isError : startedValidation && isError}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
              onBlur && onBlur();
            }}
            onChange={onChange}
            image={image}
            inputHeight={inputHeight}
            primaryBorderRadius={primaryBorderRadius}
          />
        )}

        {additionalImage ? (
          <AdditionalImage color={gray1} theme={theme} onClick={additionalImageOnClick}>
            {additionalImage}
          </AdditionalImage>
        ) : null}
      </Wrapper>
      {isShowMessage && (
        <Text type={TextType.TEXT_12_GRAY} color={red2} margin={'4px 0 0 12px'}>
          {`${name} ${languageStrings.validationMessage}`}
        </Text>
      )}
    </ExtraWrapper>
  );
};
