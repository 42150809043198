import React, { Dispatch, FC, SetStateAction } from 'react';
import { ItemWrapper, StyledNavLink, TextWrapper, StyledText, ItemLang } from './styled';
import { TextType } from '@bit/first-scope.text';
import Tooltip from '../../../components/tooltip';
import { getAccess } from '../../../routes';
import { ItemImage } from './ItemImages';
import { MenuItemType } from '../MenuItem';
import { useStyleContext } from '@cthings.co/styles-utils';
import { SecondaryMenuItem } from './mobileNavigation/MobileNavigation';
import { useTheme } from '@cthings.co/styled-components';

export interface ItemTypes {
  disabled?: boolean;
  tooltipId?: string;
  to: any;
  type: MenuItemType;
  isOpen: boolean;
  isActive?: boolean;
  value?: string;
  handleCloseFirstMenu?: () => void | undefined;
  setSecondaryMenuItems?: Dispatch<SetStateAction<SecondaryMenuItem[]>> | undefined;
  index?: number;
  languages?: any;
  handleSetLanguage?: (language: string) => void | undefined;
  languageIndex?: number;
}

export const Item: FC<ItemTypes> = ({
  disabled,
  tooltipId,
  to,
  type,
  isOpen,
  isActive,
  value,
  handleCloseFirstMenu,
  setSecondaryMenuItems,
  index,
  languages,
  handleSetLanguage,
  languageIndex,
}) => {
  const theme = useTheme();
  const { additional4 } = theme.borderRadius;

  const [
    {
      mediaType: { tablet },
    },
  ] = useStyleContext();

  const route = window.location.href;
  const checkedTo = type === MenuItemType.REPORTS ? `${to.split('/')[0]}/${to.split('/')[1]}/${to.split('/')[2]}` : to;

  const itemIndex = index ? index : 0;

  const onClickHandler = (e: any) => {
    disabled && e.preventDefault();
    tablet && handleCloseFirstMenu && handleCloseFirstMenu();
    tablet && setSecondaryMenuItems && setSecondaryMenuItems([]);
  };

  const onLanguageItemClickHandler = () => {
    handleSetLanguage && languages && handleSetLanguage(languages[itemIndex].id);
    setSecondaryMenuItems && setSecondaryMenuItems([]);
    handleCloseFirstMenu && handleCloseFirstMenu();
  };

  return (
    <ItemWrapper>
      <Tooltip id={disabled ? tooltipId || '' : ''} text={'coming soon'}>
        {to.length > 0 ? (
          <StyledNavLink
            theme={theme}
            to={to}
            access={getAccess(to)}
            onClick={(e: any) => onClickHandler(e)}
            borderRadius={`0 ${additional4} ${additional4} 0`}
            className={route.includes(checkedTo) || isActive ? 'active' : ''}
          >
            {type !== MenuItemType.SECOND_MENU && <ItemImage type={type} />}
            <TextWrapper className={isOpen ? 'open' : ''}>
              <StyledText
                type={TextType.MENU_ITEM_TEXT_PRIMARY}
                color={theme.colors.black1}
                hoverColor={theme.colors.primary}
              >
                {value}
              </StyledText>
            </TextWrapper>
          </StyledNavLink>
        ) : (
          <ItemLang
            key={index}
            className={index === languageIndex ? 'selected' : ''}
            onClick={onLanguageItemClickHandler}
          >
            {value}
          </ItemLang>
        )}
      </Tooltip>
    </ItemWrapper>
  );
};
