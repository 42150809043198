import { UserRoleId } from '../../../types/userRole';
import { uniqueId } from 'underscore';
import { COMPANY_CREATION_STEP, UserModificationMode } from './types';

export const USER_PREFIX = 'user_';

export const DEFAULT_USER = { id: uniqueId(USER_PREFIX), name: '', role: 1 as UserRoleId, email: '' };

export const EMPTY_USER = { id: uniqueId(USER_PREFIX), name: '', role: 0 as UserRoleId, email: '' };

export const USER_MODIFICATION_MODE_MAPPING = [UserModificationMode.EDIT, UserModificationMode.ADD] as const;

export const ACTION_MAPPING = {
  [COMPANY_CREATION_STEP.ADD_COMPANY]: ['fullOperational', 'companyOnly'],
  [COMPANY_CREATION_STEP.FULL_OPERATIONAL_EXPLANATIONS]: ['getStarted', 'back'],
  [COMPANY_CREATION_STEP.ADD_NEW_COMPANY]: ['next', 'back'],
  [COMPANY_CREATION_STEP.CREATE_FIRST_USER]: ['next', 'back'],
  [COMPANY_CREATION_STEP.CREATE_MORE_USERS]: ['next', 'back'],
  [COMPANY_CREATION_STEP.ADD_DEVICES]: ['upload', 'addManually', 'summary', 'back'],
  [COMPANY_CREATION_STEP.UPLOAD_DEVICES]: ['addManually', 'back'],
  [COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY]: ['summary', 'back'],
  [COMPANY_CREATION_STEP.SUMMARY]: ['editUsers', 'editDevices', 'back'],
  [COMPANY_CREATION_STEP.SUMMARY_USERS]: ['back'],
  [COMPANY_CREATION_STEP.SUMMARY_DEVICES]: ['back'],
};

export const DEVICE_ACTION_MAPPING = {
  [COMPANY_CREATION_STEP.ADD_DEVICES]: ['upload', 'addManually', 'back'],
  [COMPANY_CREATION_STEP.UPLOAD_DEVICES]: ['addManually', 'back'],
  [COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY]: ['summary', 'back'],
  [COMPANY_CREATION_STEP.SUMMARY]: ['editDevices', 'back'],
  [COMPANY_CREATION_STEP.SUMMARY_DEVICES]: ['back'],
};
