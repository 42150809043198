import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Select } from '../../../../components/universalSelect/Select';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '../../../../components/commonButton/CommonButton';
import { DevicesStepType } from './AddDevicesManually';
import { ReactComponent as CloseIcon } from '../../../../assets/Close.svg';
import { ThemeType } from '@cthings.co/styled-components';

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px 0;
  ${media.phone} {
    margin: 0 0 16px 0;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

type StyledTextType = typeof Text & { theme: ThemeType };

export const StyledText1 = styled(Text).attrs(({ theme: { colors: { black1 } }, ...rest }: StyledTextType) => ({
  ...rest,
  type: TextType.TEXT_14_BLACK,
  fontSize: '18px',
  color: black1,
  weight: '600',
}))``;

export const StyledText2 = styled(Text).attrs(({ theme: { colors: { gray2 } }, ...rest }: StyledTextType) => ({
  ...rest,
  type: TextType.TEXT_14_BLACK,
  fontSize: '18px',
  color: gray2,
}))``;

export const StyledText3 = styled(Text).attrs(({ theme: { colors: { gray1 } }, ...rest }: StyledTextType) => ({
  ...rest,
  type: TextType.TEXT_14_BLACK,
  color: gray1,
  weight: '400',
}))``;

export const StyledText4 = styled.span.attrs(({ theme: { colors: { primary } }, ...rest }: StyledTextType) => ({
  ...rest,
  color: primary,
}))`
  color: ${({ color }) => color};
  font-weight: 500;
`;

type StyledTextType5 = typeof Text & { theme: ThemeType; isClearDevices: boolean };

export const StyledText5 = styled(Text).attrs(
  ({
    theme: {
      colors: { primary, gray2 },
    },
    isClearDevices,
    ...rest
  }: StyledTextType5) => ({
    ...rest,
    lineHeight: '16px',
    type: TextType.TEXT_14_BLACK,
    color: isClearDevices ? primary : gray2,
  }),
)`` as any; // @TODO fix type

export const SwitchersRow = styled.div`
  display: flex;
  margin: 24px 0 12px 0;

  ${media.phone} {
    margin: 34px 0 16px 0;
  }
`;

export const StyledSecondaryModal = styled(Modal)`
  align-items: flex-end;
`;

export const StyledSecondaryModalContainer = styled(ModalContainer)`
  padding: 24px 16px;
  align-items: center;
  border-radius: 12px 12px 0px 0px;

  ${media.phone} {
    width: 416px;
  }

  ${media.phoneS} {
    width: 376px;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  ${media.phone} {
    flex-direction: column-reverse;
    gap: 0;
  }
`;

export const StyledSelect = styled(Select)`
  width: auto;
  flex: 1;
` as typeof Select;

type StyledButton = typeof CommonButton & { theme: ThemeType; type: DevicesStepType; isClearDevices: boolean };

export const SelectedButton = styled(CommonButton).attrs(
  ({
    theme: {
      colors: { primary, gray3, pureWhite },
    },
    type,
    isClearDevices,
    ...rest
  }: StyledButton) => ({
    ...rest,
    width: type === DevicesStepType.ADD ? '144px' : '156px',
    height: '40px',
    borderColor: isClearDevices ? primary : gray3,
    backgroundColorHover: pureWhite,
    backgroundColor: pureWhite,
    margin: type === DevicesStepType.ADD ? '0 20px 0 20px' : '0 0px 0 20px',
  }),
)`` as any; // @TODO fix type

export const RestartButton = styled(CommonButton).attrs(
  ({
    theme: {
      colors: { gray3, pureWhite },
    },
    type,
    ...rest
  }: StyledButton) => ({
    ...rest,
    width: '40px',
    height: '40px',
    borderColor: gray3,
    backgroundColorHover: pureWhite,
    backgroundColor: pureWhite,
  }),
)`
  margin: 0;

  ${media.phone} {
    margin: 0 0 0 10px;
  }
`;

export const StyledCommonButton = styled(CommonButton)`
  width: 116px;
  height: 32px;

  ${media.phone} {
    height: 40px;
    width: 100%;
  }
`;

export const BackCancelButton = styled(StyledCommonButton).attrs(
  ({
    theme: {
      colors: { black1, pureWhite },
    },
    ...rest
  }: StyledButton) => ({
    ...rest,
    backgroundColor: pureWhite,
    color: black1,
    fontSize: '14px',
  }),
)`
  margin: ${({ type }: { type: DevicesStepType }) => (type === DevicesStepType.ADD ? '0' : '0 16px 0 0')};

  ${media.phone} {
    margin: 10px 0 0 0;
  }
`;

export const SkipButton = styled(StyledCommonButton).attrs(
  ({
    theme: {
      colors: { black1, pureWhite },
    },
    ...rest
  }: StyledButton) => ({
    ...rest,
    backgroundColor: pureWhite,
    color: black1,
    fontSize: '14px',
  }),
)`
  margin: 0 16px 0 16px;

  ${media.phone} {
    margin: 10px 0 0 0;
  }
`;

export const NextSaveButton = styled(StyledCommonButton).attrs(({ ...rest }: StyledButton) => ({
  ...rest,
  fontSize: '14px',
}))``;

export const Block = styled.div`
  display: flex;
  align-items: center;
`;
