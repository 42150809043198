import { Language } from './types';

export const emptyAccess = {
  isAccess: false,
  permission: false,
  billing: {
    plans: { edit: false, view: false, delete: false },
    invoices: { edit: false, view: false, delete: false },
    details: { edit: false, view: false, delete: false },
    vat_invalid: false,
    unpaid_invoice: false,
  },
  dashboard: false,
  insight: false,
  management: {
    client: {
      edit: false,
      view: false,
      delete: false,
    },
    user: { edit: false, view: false, delete: false },
    devices: { edit: false, view: false, delete: false },
  },
  problems: false,
  settings: false,
  role_name: '',
  role: 0,
};

export const languageSet = [
  { name: 'English', id: 'en_gb', shortName: 'EN' },
  { name: 'Dansk', id: 'da_dk', shortName: 'DA' },
  { name: 'Svenska', id: 'sv_se', shortName: 'SV' },
  { name: 'Polski', id: 'pl_pl', shortName: 'PL' },
  { name: 'Deutsch', id: 'de_de', shortName: 'DE' },
] as Language[];

export const danNID = 'platform.nid.dk';
export const swedNID = 'platform.nidnet.se';
export const polNID = 'mpwik.intelliventory.ai';
export const germNID = '';

export const defaultLang: Language = window.location.href.includes(danNID)
  ? { name: 'Dansk', id: 'da_dk', shortName: 'DA' }
  : window.location.href.includes(swedNID)
  ? { name: 'Svenska', id: 'sv_se', shortName: 'SV' }
  : window.location.href.includes(polNID)
  ? { name: 'Polski', id: 'pl_pl', shortName: 'PL' }
  : {
      name: 'English',
      id: 'en_gb',
      shortName: 'EN',
    };
