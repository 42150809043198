export const da_dk = {
  accessDownloadEvents: 'Ønsker du at downloade en excel-fil med hændelsesdata for denne enhed for den valgte periode?',
  accessDownloadTemperature:
    'Ønsker du at downloade en excel-fil med temperaturdata for denne enhed for den valgte periode?',
  accessDownloadWaterLevel:
    'Ønsker du at downloade en excel-fil med vandstandsdata for denne enhed for den valgte tidsperiode?',
  accessToThePlatformBlocked: 'Din adgang til platformen er blokeret, fordi du har ubetalte fakturaer.',
  actionButtonValue: 'Handling',
  add: 'Tilføje',
  addCommentButton: 'Tilføj kommentar',
  addCompany: 'Tilføj firma',
  addDeviceModalClarifyingDesc:
    'Slutbrugeren skal stadig levere og implementere enhederne for at kunne indsamle og overvåge dataene.',
  addDeviceModalDesc:
    'Ved at tilføje enheder til en virksomhedskonto vil alle brugere, der er tildelt dette firma, kunne se og spore ydeevnen for disse enheder. Brugere med administratortilladelser vil også være i stand til at konfigurere deres indstillinger og tilføje nye enheder til kontoen.',
  addDeviceModalWaysDesc: 'Der er to måder, du kan tildele enhederne til en virksomhedskonto',
  addDevicesManuallyTitle: 'Tilføj enhed(er) manuelt',
  addEventText: 'Tilføj begivenhed',
  addIncidentTitle: 'Tilføj hændelse',
  additionalDeviceAddDevice: 'Tilføjelse af en ny enhed',
  additionalDeviceEnterCode: 'Indtast 10-cifret kode fra instruktionen',
  additionalDeviceNextButton: 'Næste',
  additionalInfoLabel: 'Yderligere information',
  additionalInfoPlaceholder: 'Vælg yderligere info',
  addManuallyAddDeviceModal: 'Tilføj Manuelt',
  addManuallyDescAddDeviceModal:
    'indtast serienumrene på de enheder, du vil tildele. Det kan være et antal individuelle enheder eller et interval inklusive en større batch',
  addManuallyTooltipAddDeviceModal:
    'Anbefales til at tilføje op til 10 enheder på en gang eller et interval fra en produktionsbatch.',
  addNew: 'Tilføj ny',
  addNewCompany: 'Tilføj nyt firma',
  addNewCompanyDesc:
    'Company ist eine übergeordnete Instanz für Benutzer und Geräte und fungiert als deren Aggregator. Alle Benutzer und Geräte müssen einem zugewiesen werden company.In um alle Plattformfunktionen nutzen zu können, ist eine Benutzererstellung erforderlich.',
  addNewCompanyDetails: 'Angiv nærmere oplysninger om det nye selskab.',
  addNewCompanyModalSecondStepDescription:
    'Bruger er en instans, der gør det muligt at overvåge ydeevnen af enhederne på platformen og administrere deres indstillinger.',
  addNewCompanyModalSecondStepHintText:
    'Den første bruger, der tildeles en virksomhed, skal have en administratorkonto for at tilføje flere brugere og administrere indstillinger for enheder.',
  addNewCompanyModalSecondStepTitle: 'Opret første bruger på virksomhedens konto',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText1: 'kan redigere indstillingerne for kontoen og enhederne.',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText2:
    'er begrænset til skrivebeskyttet visning, har ikke tilladelse til at redigere indstillingerne.',
  addNewCompanyModalSecondStepTypesOfAccountsText: 'Der er to typer konti for brugere:',
  addOrganisationLabel: 'Tilføj ny organisation',
  addresInstructionDesc1: 'Indtast adresse eller placering i søgefeltet i øverste venstre hjørne af kortet.',
  addresInstructionDesc2:
    'Når du ser den generelle placering, skal du zoome ind på kortet og trække det til det nøjagtige sted for bedre præcision.',
  addresInstructionDesc3: 'Klik på Bekræft for at gemme dit valg og forlade menuen.',
  addresInstructionDesc4:
    'Brug tekstfelterne nederst i vinduet til at indtaste den præcise adresse på enhedens placering.',
  addresInstructionDesc5: 'Klik på Bekræft for at gemme adressen og forlade menuen.',
  addresInstructionDesc6: 'Bemærk: kortet viser ikke placeringen i denne metode.',
  addresInstructionTitle1: 'Metode 1: Kortsøgning',
  addresInstructionTitle2: 'Metode 2: Adresseindtastning',
  addressSeaLevelLabel: 'Højde over havets overflade (m)',
  addressSeaLevelPlaceholder: 'Indtast havniveau',
  addUser: '+ Tilføj bruger',
  advancedLabel: 'Avanceret',
  alertMessageError: 'Error appears when you did wrong or there is system fault',
  alertMessageNeutral: 'Neutral info displayed here',
  alertMessagePositive: 'Successful message to those who did good',
  alertMessageWarning: 'Warning text here so you can be more careful',
  allButton: 'Alle',
  analyticsItem: 'Analytics',
  anUnexpectedErrorHasOccurred: 'En uventet fejl er opstået. Prøv igen',
  applyButton: 'Ansøge',
  applyButton1: 'Ansøger',
  applyButton2: 'Anvendt',
  areYouSure: 'Er du sikker?',
  average: 'gns.',
  averagingMethodLabel: 'Gennemsnitsmetode',
  backButton: 'Tilbage',
  backHome: 'Tilbage til startside',
  basedOnManningsEquation: 'Baseret på Mannings ligning, ',
  basicLabel: 'Grundlæggende',
  battery: 'Batteri',
  batteryLevel: 'Batteriniveau',
  batteryStatusBad: 'Lav',
  batteryStatusGood: 'Høj',
  batteryStatusWarn: 'Medium',
  billing: 'Fakturering',
  billingAddressPlaceholder: 'Vælg faktureringsadresse',
  billingDetails: 'Faktureringsdetaljer',
  billingDetailsNotFilledDesc: 'Kontakt din administrator, hvis problemet fortsætter.',
  calibraion: 'Kalibrering',
  calibraionInPropgress: 'Kalibrering i gang',
  calibraionLabel: 'Kalibreringsdetaljer',
  calibraionTooltip: 'Lær mere om kalibrering',
  calibration: 'Kalibrering',
  calibrationDate: 'Kalibreringsdato',
  calibrationDesc:
    'Kalibrering udføres altid efter installationen af enheden. Det er nødvendigt at genkalibrere enheden, hvis du har flyttet enheden siden sidste kalibrering, eller kalibreringen blev udført forkert. Før du starter kalibreringsprocessen, skal du sikre dig, at enheden er på den tilsigtede placering, og at den forbliver uforstyrret i kalibreringsperioden - op til 48 timer.',
  calibrationDescInProgress:
    'Kalibreringsprocessen er startet. Det kan tage op til 48 timer. Husk, at vandstandsmålingerne i denne periode kan være upålidelige. Sørg for, at enheden forbliver uforstyrret i denne periode.',
  calibrationInfoTooltip:
    'Under kalibreringsprocessen scanner enheden indersiden af mandehullet for at detektere alle de statiske elementer, for at tage hensyn til dem, når de udfører målinger og sikre korrekt dataindsamling. Det udføres altid efter implementering',
  calibrationPeriodTootlipText: 'Dataene fra kalibreringsperioden\n kan være upålidelige',
  calibrationText: 'Kalibrering',
  calibrationTooltipText: 'Dataene fra kalibreringsperioden kan være upræcise',
  cancelButton: 'Afbestille',
  chooseAddressPlaceholder: 'Vælg adresse',
  chooseDateTitle: 'Vælg dato',
  chooseDeviceNamePlaceholder: 'Vælg navn på enhed',
  chooseOrganisation: 'Vælg organisation',
  chooseOrganisationEmail: 'Organisationens e-mail',
  chooseOrganisationName: 'Organisationens navn',
  chooseOrganisationNamePlaceholder: 'Indtast navn',
  chooseOrganisationPhone: 'Organisationens telefon',
  chooseOrganisationPhonePlaceholder: 'Indtast telefon',
  chooseParentOrgFirst: 'Vælg overordnet org. først',
  choosePaymentMethodPlaceholder: 'Vælge betalingsmetode',
  choosePlansetForEachDevices:
    '\nVælg plansæt\n for hver enhed, du tildele. Det vil bestemme, hvilke planer, enhedsforsyningsprocessen vil tilbyde som muligheder.',
  chooseSeverityPlaceholder: 'Vælg sværhedsgrad',
  chooseTypeOfInvoices: 'Vælge typer af fakturaer',
  chooseTypePlaceholder: 'Vælg type',
  choosingPlanset: 'Vælge plan-sæt',
  clearButton: 'Ryd enheder',
  clearButtonMobile: 'Ryd',
  clickOn: 'Klik på ',
  closeButtonValue: 'Tæt',
  closedText: 'Lukket',
  commentsPlaceholder: 'Ingen kommentarer endnu',
  commentTableDayToday: 'I dag, ',
  commentTableDayYesterday: 'I går, ',
  commentTableEdit: 'Redigere Besked',
  commentTableEditLabel: 'Redigeret: ',
  commentTableEditPlaceholder: 'Redigere besked...',
  commentTablePlaceholder: 'Skriv en kommentar...',
  commonButtonMore: 'Mere',
  commonButtonRevoke: 'Fortryd',
  commonButtonSend: 'Send',
  commonButtonUpload: 'Upload',
  companyAddress: 'Virksomhedens adresse',
  companyAddressPlaceholder: 'Vælg virksomhedens adresse',
  companyCreationEmailAddress: 'Din e-mailadresse',
  companyName: 'Virksomhedens navn',
  companyNameLabel: 'Firmanavn',
  companyNamePlaceholder: 'Indtast virksomhedens navn',
  companyNamePlaceholderr: 'Firmanavn',
  companyOnly: 'Kun selskab',
  companyOnlyCardInfo:
    'Gå efter det, hvis du kun ønsker at tilføje et firma i øjeblikket. For at gøre virksomhedens konto operationel skal du manuelt tilføje en admin-bruger og tildele enheder til denne comapny på et senere tidspunkt.',
  companyPhone: 'Virksomhedens telefon',
  companyPhonePlaceholder: 'Indtast virksomhedens telefon',
  companyPhoneTooltip:
    'Virksomhedens generelle telefonnummer.Hvis virksomheden ikke har en, skal du udfylde den med et telefonnummer til den første bruger, der vil blive tildelt dette firma.',
  completedProvisioningButton: 'Gå til platformen',
  completedProvisioningContent:
    'Du har fuldført forsyning af enheden. For at begynde at administrere enheden skal du logge ind på Smart Utilities-platformen. Oprettelse af en ny organisation kan tage op ',
  completedProvisioningContent2: 'til 10 minutter',
  completedProvisioningContent3: 'Log venligst ind på platformen efter dette tidspunkt.',
  completedProvisioningLabel: 'Forsyning er afsluttet',
  conditionalCheckerModalCheckboxLabel: "Don't Show this message again",
  conditionalCheckerModalCommonButton: ' Forstået',
  conditionalCheckerModalTitleFirstPart:
    'We will run maintenance. All system will be updated automatically in the next',
  conditionalCheckerModalTitleSecondPart: 'hours.',
  configuration: 'Konfiguration',
  contactSupport: 'Kontakt support',
  continue: 'Fortsæt',
  continueStyled: '“Fortsæt“',
  copiedMessage: 'Kopieret til udklipsholder',
  coverTitle: 'Dække',
  createAccount: 'Tilmeld dig',
  createButton: 'Skab',
  createMoreUsers: 'Opret flere brugere',
  creationDate: 'Oprettelsesdato',
  currentNetworkConnectionTechnology: 'Forbindelses Teknologi',
  currentNetworkLastUpdated: 'Sidst opdateret',
  currentNetworkPreferedConectionTechnology: 'Foretrukken forbindelse Teknologi',
  dashBoardCardCitiesTitle: 'Lokationer',
  dashBoardCardDevicesTitle: 'Total antal enheder',
  dashBoardCardLocationsTitle: 'Total antal lokationer',
  dashboardCardsTitleDevices: 'Total antal enheder',
  dashboardCardsTitleLocations: 'Total antal lokationer',
  dashboardCardsTitleNoProblems: 'Ingen problemer detekteret',
  dashboardCardsTitleProblems: 'Problemer fundet',
  dashboardCardsTitleReport: 'Rapporter et problem',
  dashboardCardsTitleTemperature: 'Gennemsnits temperatur',
  dashboardCardsTitleWarnings: 'Advarsler',
  dashboardTitle: 'Instrumentbræt',
  dataPeriodLabel: 'Dataperiode',
  dataProcessingDescription: 'Behandling af store mængder data. Vent venligst',
  dataProcessingTitle: 'Data behandling',
  days: 'dage',
  daysUnit: 'dage',
  deleteDevicesModal: 'Listen over valgte enheder vil blive ryddet.',
  deleteModalInfoText: 'Listen over valgte enheder vil blive ryddet.',
  deletingComment: 'Denne kommentar vil blive slettet.',
  deletPairModal: 'Mandehullsparret slettes permanent.',
  deliveryAddress: 'Leveringsadresse',
  deploy: 'Indsætte',
  deployed: 'Anvendt',
  deploying: 'Udruller',
  deploymentDate: 'Dato for udstationering',
  deploymentDetailsModalTitle: 'Installationsdetaljer',
  deploymentIsInProgress: 'Enhedsimplementering er i gang. Kom tilbage senere!',
  deploymentIsInProgressTextFirst:
    'Deployment is in progress, it can take up to 48 hours. Make sure the device stays undisturbed during this time.',
  deploymentIsInProgressTextSecond:
    'In the meantime, we recommend that you \n set other parameters \n of the device, such as transmission and measurement intervals or alarm configurations. If you want to set it from the device details page later, close this window.',
  deploymentIsInProgressTitle: 'Deployment is in progress',
  deploySetDepthModalText:
    'Pålidelige målinger kræver en præcis maksimal dybde, målt fra radaren til den dybeste punkt i tanken.',
  deploySetDepthModalTitle: 'Indstil den maksimale dybde',
  depth: 'Dybden',
  depthNotice:
    'Brøndens dybde er afgørende for korrekte målinger. Angiv venligst dybden så hurtigt som muligt, ellers er målingen muligvis ikke korrekt.',
  depthUnknown: 'Dybden er ukendt',
  descriptionLabel: 'Beskrivelse',
  descriptionPlaceholder: 'Indtast beskrivelse',
  details: 'Detaljer',
  deviceDeploymentIsInProgress: 'Enhedsimplementering er i gang',
  deviceDetailsGoToInsightDetails: 'Gå til placering',
  deviceID: 'Enheds ID',
  deviceIdText: 'Enheds ID: ',
  deviceInfoLabelProperty: 'Ejendom',
  deviceIsntDeployed: 'Enheden er ikke installeret',
  deviceLabel: 'Enhed',
  deviceName: 'Enhedsnavn',
  directLink: 'direkte link',
  dismissableModalCommonButton: 'Learn More',
  dismissableModalTitle: 'This is an example of a simplified view that can be dismissed. Action isn’t required.',
  distanceToWater: 'afstand til vand/vandstand',
  distanceToWaterTooltip: 'Distance til vand',
  documentsManual: 'Brugervejledning',
  documentsOneMore: 'Endnu en dok',
  documentsReleaseNote1_1: 'Udgivelsesnote 1.1',
  documentsReleaseNote2_0: 'Udgivelsesnote 2.0',
  documentsReleaseNote2_4: 'Udgivelsesnote 2.4',
  documentsReleaseNote2_5: 'Udgivelsesnote 2.5',
  documentsReleaseNote2_6: 'Udgivelsesnote 2.6',
  documentsSpecification: 'Smart RADAR-specifikation',
  download: 'Hent',
  doYouWantToApply: 'Vil du anvende dette sæt af planer på alle enheder af denne type eller kun denne ene?',
  dueOn: 'Forfalder',
  editDeliveryAddressTitle: 'Rediger leveringsadresse',
  editInvoiceTitle: 'Rediger fakturaoplysninger',
  editMessageTitle: 'Rediger besked',
  emailAddress: 'E-mail adresse',
  emailAddressPlaceholder: 'Indtast e-mail-adresse',
  emailAddressTooltip:
    'Virksomhedens generelle e-mail-adresse.Hvis virksomheden ikke har en, skal du udfylde den med en e-mail-adresse til den første bruger, der vil blive tildelt dette firma.',
  emailNotificationsError: 'En uventet fejl er opstået. Prøv igen',
  emailNotificationsOff: 'E-mailmeddelelser er deaktiveret for denne enhed',
  emailNotificationsOn: 'E-mail-meddelelser er aktiveret for denne enhed',
  emphasizedHeaderTextModalDescription: 'You won’t be able to undo this action.',
  emphasizedHeaderTextModalTitle: 'Er du sikker?',
  endUserGuide: 'Slutbrugervejledning',
  enterInfoPlaceholder: 'Indtast info',
  errorMessageForSelectAddress: 'Felterne "linje 1" og "by" skal udfyldes.',
  errorPage401Description: 'Adgang nægtes på grund af ugyldige legitimationsoplysninger.',
  errorPage401Title: 'Uberettiget',
  errorPage403Description:
    'Beklager, du har ikke tilladelse til at se denne mappe. Du kan vende tilbage til hovedsiden, hvis du har adgangsrettigheder.',
  errorPage403Title: 'Forbudt',
  errorPage404Description: 'Vi kan ikke finde den side, du leder efter',
  errorPage404Title: 'Siden blev ikke fundet',
  errorPage500Description: 'Vi har et problem. Prøv en af disse muligheder for at få dig tilbage på sporet',
  errorPage500Title: 'Noget gik galt',
  errorPageMainPage: 'Forside',
  errorPageReload: 'Genindlæs',
  existingItem: 'Dette element er i brug og kan ikke slettes lige nu.',
  export: 'Eksport',
  exportButton: 'Eksport',
  exportExcelFileTitle: 'Eksporter Excel-fil',
  failureReasonsModalHeader: 'Fejlårsager',
  failureReasonsModalInfo:
    'Disse er almindelige problemer, der opstår under upload, og som kan forårsage fejl i uploaden. Tjek din fil for følgende problemer, og upload den derefter igen:',
  failureReasonsModalReasonFirst: 'Matchende problem:',
  failureReasonsModalReasonFourth: 'Filstørrelsen er for stor:',
  failureReasonsModalReasonSecond: 'Formatproblem:',
  failureReasonsModalReasonThird: 'Enheden er ikke tilgængelig:',
  failureReasonsModalSuggestionFirst:
    'dataene i filen svarer ikke til kravene. Download venligst prøvefilen og match kolonnerne der er angivet.',
  failureReasonsModalSuggestionFourth:
    'for at reducere filstørrelsen kan du slette unødvendige dataværdier, kolonner og rækker.',
  failureReasonsModalSuggestionSecond:
    'de data, der er indført i filen, kan ikke behandles. Kontroller venligst, om enhedsnumrene matcher følgende format: SMC12345678900.',
  failureReasonsModalSuggestionThird:
    'serienummeret på en enhed er ikke tilgængeligt, fordi det er tildelt et andet firma. Hvis du vil tildele det til et nyt firma, skal du først gentildele det tilbage til dig.',
  filtersButton: 'Filtre',
  filtersNotificationsError: 'Der er opstået en uventet fejl. Genindlæs venligst siden igen',
  filtersSelectError: 'Fejl: ingen data',
  finalizeButton: 'Afslut',
  firstDeviceAddress: 'Enheds #1 adresse',
  floatingModalCommonButtonDiscard: 'Discard',
  floatingModalTitle: 'Save changes?',
  flowInfo: 'Strøm info',
  flowLabel: 'Flyde',
  flowMeasurementBasedOn: 'Flowmåling baseret på',
  flowMeasurementsLabel: 'flowmålinger',
  flowUnit: 'Flow enhed',
  freeFlowCircularCulvert: 'cirkulær kulvert med frit afløb',
  fullAdditionalDescription:
    'Supportgebyret er en månedlig abonnementsomkostning, der dækker personlig opstart, dedikeret kundesupport og løbende softwareopdateringer. Det opkræves kun for de første 5 leverede enheder, uanset deres plan.',
  fullAddress: 'Fuld adresse',
  fullNameOfOrganisation: 'Fulde navn (eller organisationens navn)',
  fullNameOfOrganisationPlaceholder: 'Indtast det fulde navn (eller organisationens navn)',
  fullyOperationalAccount: 'Fuldt operationel konto',
  fullyOperationalAccountCardInfo:
    'Hurtig og nem måde at oprette en fuldt operationel konto til en ny virksomhed. Ved at følge en 3-trins guide vil du ende op med en konto klar til brug af din klient.',
  generateButton: 'Generer',
  generateReportModalDay: 'I dag',
  generateReportModalMonth: 'Denne måned',
  generateReportModalParameterCustomerPlaceholder: 'Vælg kunde(r).',
  generateReportModalParameterLocationPlaceholder: 'Vælg placering(er)',
  generateReportModalParameterLocationPlaceholderDisabled: 'Vælg kunde(r) først',
  generateReportModalParameterRecipients: 'Modtagere',
  generateReportModalParameterRecipientsPlaceholder: 'Vælg modtager(e)',
  generateReportModalPeriod: 'Periode',
  generateReportModalPeriodBorderFrom: 'Fra:',
  generateReportModalPeriodBorderTo: 'Til:',
  generateReportModalShowMessage: 'Du bør vælge',
  generateReportModalWeek: 'Denne uge',
  generateReports: 'Generer rapport',
  getProfilesError: 'Valgt overordnet org. ikke har profilerne, vælg venligst en anden overordnet organisation',
  getStartedButton: 'Kom Igang',
  goBack: 'Gå tilbage',
  gotIt: 'Forstået',
  goToBillingDetails: 'Gå til fakturaoplysninger',
  goToInvoices: 'Gå til fakturaer',
  goToMain: 'Gå til hovedmenu',
  gpsCheck1Row:
    'GPS-placeringstjek gør det muligt at kontrollere den aktuelle placering af enheden og gemme den på platformen. Denne proces dræner batteriet dybt, hvilket forkorter enhedens levetid. ',
  gpsCheck2Row:
    'Denne proces dræner batteriet dybt, hvilket forkorter enhedens levetid. Derfor \nanbefaler vi at bruge GPS-koordinatkontrolfunktionen op til 2 gange\n, kun hvis: ',
  gpsCheck3Row:
    '- du installerede, implementerede og kalibrerede enheden i den tilsigtede tank for første gang og ønsker at gemme GPS-koordinaterne som enhedens placering,',
  gpsCheck4Row: '- du kan ikke lokalisere enheden på grund af en uautoriseret afhentningshændelse.',
  gpsCheck5Row: '\nEr du sikker på, at du vil fortsætte med GPS-positionskontrollen?\n',
  gpsCheckTooltip: 'Lær mere om GPS-placering',
  gpsDetailsAddressTitle: 'Koordinater modtaget fra GPS',
  gpsDetailsAreYouSureText: 'Hver GPS -kontrol dræner batteriet dybt.Er du sikker på, at du vil fortsætte?',
  gpsDetailsAreYouSureTitle: 'Er du sikker?',
  gpsDetailsCheckPerformedText: 'Manuel GPS -placeringskontrol udført: ',
  gpsDetailsManualChecksPerformed: 'Manuelle kontroller udført',
  gpsDetailsRequestCheck: 'Anmod om GPS-tjek',
  gpsDetailsSaveAsMainLocText: 'Er du sikker på, at du vil ændre den aktuelle placering af enheden til denne?',
  gpsDetailsSaveAsMainLocTitle: 'Gem som hovedplacering',
  gpsDetailsSaveMainLocation: 'Gem som hovedplacering',
  gpsDetailsSaveMainLocationText: 'Er du sikker på, at du vil ændre enhedens aktuelle placering til denne?',
  gpsDetailsSaveMainLocationTitle: 'Gem som hovedplacering',
  gpsDetailsTitle: 'GPS-placeringsoplysninger',
  gpsLocationCheckText:
    'Du kan anmode om GPS -placeringskontrol, som udføres, når den næste datatransmissionscyklus opstår (kontroller transmissionsfrekvens i enhedsdetaljerne).Når koordinaterne er modtaget fra GPS, vil de være tilgængelige her.',
  gpsLocationCheckTitle: 'Anmodning om GPS placeringskontrol',
  gpsLocationProperty: 'GPS-placering',
  GPSLocationText: 'Kontrol af GPS-position',
  gpsRequestSuccess: 'GPS-placering blev opdateret',
  graphPlaceholderLabel: 'Vandstand',
  gravitationalCircularCulvert: 'Gravitationscirkelformet gennemløbsbro',
  groundMeter: 'Smart NIVEAU',
  groupFieldPlaceholder: 'Select customer',
  hightText: 'Højde',
  hourLabel: 'time',
  hours: 'timer',
  hoursUnit: 'timer',
  humidity: 'Fugtighed',
  humidityMeasurements: 'Fugtmålinger',
  ifItDoesntTryThis: 'Hvis det ikke gør det, så prøv dette',
  imagesLabel: 'Billeder (valgfrit)',
  incidentAddressPlaceholder: 'Valgt automatisk',
  incidentsNotifications: 'Meddelelser om hændelser',
  incidentsPlaceholder: 'Skriv noget...',
  includedLablel: 'Inkluderet',
  incorrectTypeOfFile: 'Forkert filtype. Vælg billedformat (.jpg, .png, .heic osv.)',
  individualText:
    'søg efter en enkelt enhed. Du kan tildele de søgte og følgende enheder ved at markere afkrydsningsfelterne ud for dem.',
  individualTitle: 'Individuel',
  info: 'Info',
  infoButtonTooltip: 'some info',
  informationModalHeader: 'Oprettelse af en konto for et nyt firma',
  informationModalInfo:
    'Opret hurtigt en ny konto til din kunde. Bare følg 3 nemme trin for at oprette en fuldt operationel konto:',
  informationModalListOfTextFirst: 'Tilføj nyt firma;',
  informationModalListOfTextSecond: 'Opret bruger(e) på virksomhedens konto;',
  informationModalListOfTextThird: 'Tildel enhed(er) til virksomhedens konto.',
  inisghtDetailsCommentsPlaceholder: 'Indtast noget…',
  inisghtDetailsHistorySelectLast: 'Sidst',
  inisghtDetailsHistorySelectPeriodsDays: 'dage',
  inisghtDetailsHistorySelectPeriodsMonths: 'måneder',
  inisghtDetailsHistorySelectPeriodsWeek: 'uge',
  inisghtDetailsHistorySelectPeriodsYear: 'år',
  insightDetailsCommentsTitle: 'Bemærkninger',
  insightDetailsCurrentNetworkTitle: 'Nuværende netværk',
  insightDetailsDeviceConfigAddressButtonsConfirm: 'Bekræft',
  insightDetailsDeviceConfigAddressChooseLocation: 'vælg lokation på kortet',
  insightDetailsDeviceConfigAddressInputsCity: 'By',
  insightDetailsDeviceConfigAddressInputsCountry: 'Land',
  insightDetailsDeviceConfigAddressInputsLatitudeName: 'Breddegrad',
  insightDetailsDeviceConfigAddressInputsLatitudePlaceholder: 'Indtast Latitude',
  insightDetailsDeviceConfigAddressInputsLine1: 'Linje 1',
  insightDetailsDeviceConfigAddressInputsLine2: 'Linje 2',
  insightDetailsDeviceConfigAddressInputsLongitudePlaceholder: 'Indtast længdegrad',
  insightDetailsDeviceConfigAddressInputsLongotudeName: 'Længde',
  insightDetailsDeviceConfigAddressInputsRegion: 'Region',
  insightDetailsDeviceConfigAddressInputsZip: 'Postnummer',
  insightDetailsDeviceConfigAddressSearchBarPlaceholder: 'Indtast placering',
  insightDetailsDeviceConfigAddressTitle: 'Enheds adresse',
  insightDetailsDeviceConfigHeaderEdit: 'Rediger',
  insightDetailsDeviceConfigHeaderParam: 'Parameter',
  insightDetailsDeviceConfigHeaderValue: 'Værdi',
  insightDetailsDeviceConfigRowsName: 'Enheds navn',
  insightDetailsDeviceConfigRowsTransmissionFreq: 'Overførselsfrekvens',
  insightDetailsDeviceConfigStatusChangesStagesApplied: 'Anvendt',
  insightDetailsDeviceConfigStatusChangesStagesFailed: 'Mislykkedes',
  insightDetailsDeviceConfigStatusChangesStagesRequested: 'Ønsket',
  insightDetailsDeviceConfigTitle: 'Opsætning af enhed',
  insightDetailsDeviceConfigurationEditInputsAddressPlaceholder: 'Indtast adresse',
  insightDetailsDeviceConfigurationEditInputsAddressTitle: 'Adresse',
  insightDetailsDeviceConfigurationEditInputsButtonsCancel: 'Annuller',
  insightDetailsDeviceConfigurationEditInputsButtonsSave: 'Gemme',
  insightDetailsDeviceConfigurationEditInputsNamePlaceholder: 'Indtast navn',
  insightDetailsDeviceConfigurationEditInputsNameTitle: 'Navn',
  insightDetailsDeviceConfigurationEditInputsTransmissionFreqPlaceholder: 'Indtast frekvens for overførsel',
  insightDetailsDeviceConfigurationEditInputsTypeTitle: 'Type',
  insightDetailsDeviceId: 'Enheds ID',
  insightDetailsDeviceInfoTitle: 'Enhedsinfo',
  insightDetailsEvent: 'Begivenhed',
  insightDetailsEventData: 'Dato',
  insightDetailsEventTableHeaderEvent: 'Hændelse',
  insightDetailsEventTableHeaderTime: 'Tid',
  insightDetailsEventTableSelectAll: 'Alle',
  insightDetailsEventTableTitle: 'Hændelsesoversigt',
  insightDetailsEventTime: 'Tid',
  insightDetailsGeneralReportsButton: 'Generelle rapporter',
  insightDetailsGoToDeviceButton: 'Gå til enheden',
  insightDetailsGoToFlowButton: 'Gå til flyde',
  insightDetailsHistoryTitle: 'Historie',
  insightDetailsNetworkEvent: 'Netværksbegivenhedstabel',
  insightDetailsRadioModulesLabel: 'RADIOMODUL',
  insightDetailsRadioModulesTitle: 'Radiomoduler',
  insightDetailsRealTimeCardsTemperature: 'Temperatur',
  insightDetailsRealTimeCardsWater: 'Afstand til vand',
  insightDetailsRealTimeTitle: 'Real tid',
  insightDetailsStatusChangesButton: 'Forstået',
  insightDetailsStatusChangesStagesCurrent: 'Nuværende:',
  insightDetailsStatusChangesTitlesDeviceName: 'Enheds navn',
  insightDetailsStatusChangesTitlesTransmissionFreq: 'Enhedens frekvens for overførsel',
  insightDetalisEventDateAndTime: 'Dato & Tid',
  insightMobilePlaceholder: 'Vælg en lokation',
  insightPlaceholder: 'Søg efter noget',
  insightSearchPlaceholder: 'Hvad søger du?',
  insightSearchResultsNoResults: 'ingen resultater',
  insightSearchResultsResult: 'resultat',
  insightSearchResultsResults: 'resultater',
  insightTitle: 'Indsigt i realtid',
  insightToggleList: 'Vis som liste',
  insightToggleMap: 'Vis på kort',
  internalHumidityTitle: 'Indvendig fugtighed',
  internalTemperature: 'Intern temperatur',
  invoiceDetails: 'Fakturaoplysninger',
  invoiceID: 'Faktura ID',
  invoices: 'Fakturaer',
  labelReportsTableLocation: 'Beliggenhed',
  lastEventLabel: 'Sidste begivenhed',
  lastSeenOnline: 'Sidst set online',
  lastSeenTitle: 'Sidst set',
  levels: 'Nivauer',
  linkAccount: 'link konto',
  linkPaymentMethod: 'Link betalingsmetode',
  locationPopupCityPlaceholder: 'Indtast by',
  locationPopupCountryPlaceholder: 'Indtast land',
  locationPopupDescription: 'vælg lokation på kortet',
  locationPopupLine1Placeholder: 'Indtast linje 1',
  locationPopupRegionPlaceholder: 'Indtast region',
  locationPopupTitleDevices: 'Enheds adresse',
  locationPopupZipPlaceholder: 'Indtast postnummer',
  maintenanceTitle: 'Vedligeholdelse',
  manageAddButton: 'Tilføj',
  manageClientEditAddressSearchBarPlaceholderAddress: 'Indtast adresse….',
  manageClientEditAddressTitle: 'Kunde adresse',
  manageClientsEditClientInputsNameTitle: 'Kunde navn',
  manageClientsEditClientTitle: 'Rediger',
  manageClientsMultipleDeleteModalButtonsNo: 'Nej, afbryd',
  manageClientsMultipleDeleteModalButtonsYes: 'Ja, gør det',
  manageClientsMultipleDeleteModalDescription: 'Disse kunder vil blive slettet permanent:',
  manageClientsMultipleDeleteModalTitle: 'Er du sikker?',
  manageClientsSingleDeleteModalDescription: 'Kunden vil blive slettet permanent:',
  manageClientsTableHeaderDeleteButton: 'Slet',
  manageClientsTableHeaderDeleteSelected: 'Valgt:',
  manageClientsTableHeaderItemsAddress: 'Adresse',
  manageClientsTableHeaderItemsClient: 'Organisation',
  manageClientsTableHeaderItemsEmail: 'Email',
  manageClientsTableHeaderItemsName: 'Navn',
  manageClientsTableHeaderItemsPhone: 'Telefonnummer',
  manageClientsTableHeaderItemsPlace: 'Lokation',
  manageClientsTableRowPlaceholdersAddress: 'Indtast adresse',
  manageClientsTableRowPlaceholdersEmail: 'Indtast email',
  manageClientsTableRowPlaceholdersPhone: 'Indtast telefon',
  manageDeleteSemiTitle: 'Disse enheder slettes permanent:',
  manageDevicesChooseClientPlaceholder: 'Vælg kunde',
  manageDevicesEditDevicesTitle: 'Rediger enhed',
  manageDevicesNoCLients: 'Ingen kunder eksisterer',
  manageDevicesPlace: 'Indtast sted',
  manageIncidentsAddButton: 'Tilføj',
  manageUsersAddUserModalInputsClientPlaceholder: 'Vælg',
  manageUsersAddUserModalInputsRoleTitle: 'Rolle',
  manageUsersEditUserModalTitle: 'Rediger bruger',
  manageUsersMultipleDeleteModalDescription: 'Disse brugere vil blive slettet permanent:',
  manageUsersSelectAll: 'Alle ',
  manageUsersSingleDeleteModalDescription: 'Brugeren vil blive slettet permanent:',
  manholeModalName: 'Brønd',
  manholes: 'mandehuller',
  manningsPair: 'Mannings par',
  manufacturingDate: 'Fremstillingsdato',
  meaningLabel: 'Betydningen',
  measurementDeviceDetailsTableName: 'Navn',
  measurementItem: 'Flowmåling',
  measurementsFromDevice: 'Målinger fra enheden',
  measurementTitle: 'Flowmåling',
  messageAboutSuccessfullyDeleted: 'Succesfuldt slettet',
  minMeasurmentIntervals: 'Mindste måleintervaller',
  mins: 'min',
  minsUnit: 'min',
  minTransmissionIntervals: 'Mindste transmissionsintervaller',
  mobileEditPopupChooseClient: 'Vælg organisation',
  mobileEditPopupChooseRole: 'Vælg brugertype',
  mobileModalEditAddClientTitle: 'Opret ny kunde',
  mobileModalEditAddUserTitle: 'Tilføj ny bruger',
  modalSystemSelectPlaceholder: 'Data er tomme',
  modalTitleAdd: 'Tilføje',
  modalTitleView: 'Udsigt',
  monthlyLabel: 'Monatlich',
  moreDetailsButton: 'Flere detaljer',
  multipleUsersCanBeAdded:
    'Der kan tilføjes flere brugere i en virksomhed.\nVed at tilføje flere brugere giver du dem adgang til platformen for at spore enhedernes ydeevne.',
  nameSurnamePlaceholder: 'Indtast navn og efternavn',
  navigationClients: 'Organisationer',
  navigationDevices: 'Enheder',
  navigationDocuments: 'Dokumenter',
  navigationIncidents: 'Hændelser',
  navigationInsight: 'Indsigt',
  navigationLogout: 'Log Ud',
  navigationManage: 'Administrer',
  navigationNotifications: 'Notifikationer',
  navigationNotificationsTimeAgo: 'siden',
  navigationNotificationsTimeHr: 'T',
  navigationNotificationsTimeMin: 'min.',
  navigationReports: 'Rapporter',
  navigationSettings: 'Indstillinger',
  navigationUsers: 'Brugere',
  nextButton: 'Næste',
  noButton: 'Ingen',
  noDataPlaceholderText: 'Ingen data',
  noReportsPlaceholder: 'Ingen rapporter endnu',
  notDeployed: 'Ikke indsat',
  noTestInformation: 'Ingen tilgængelige QA-oplysninger for denne enhed',
  noteText: 'Bemærk',
  notice: 'Varsel',
  notOkTitle: 'Ikke OK',
  oops: 'Ups!',
  openText: 'Åben',
  optionsDialogueModalDescription: 'Do you want to proceed?',
  optionsDialogueModalTitle: 'Items will be moved to secret folder.',
  organisationAddress: 'Organisationens adresse',
  organisationAddressLabel: 'Organisationens adresse',
  organisationCreationSuccess: 'Organisationen blev oprettet',
  organisationDetailsTitleInfoTable: 'Organisationsoplysninger',
  organisationLabel: 'Organisation',
  organisationsName: 'Organisationens navn',
  organisationsNamePlaceholder: 'Indtast organisationens navn',
  outletPressure: 'Udløbstryk',
  outletTemperature: 'Udgangstemperatur',
  outsidePressure: 'Udefrakommende pres',
  outsideTemperature: 'Udetemperatur',
  overdue: 'Ikke betalt til tiden',
  overflowBasedOn: 'Overløb baseret på',
  overflowLabel: 'overløb',
  pageNotFound: 'Side ikke fundet.',
  paginationButtonNext: 'Næste',
  paginationButtonPrevious: 'Tilbage',
  paginationGo: 'Gå',
  paginationGoTo: 'Gå til',
  paid: 'Betalt',
  pairConfiguration: 'Par konfiguration',
  pairId: 'Par-id',
  pairInfo: 'Paroplysninger',
  pariedManholeButton: 'Gå til par',
  pariedManholegraphLabel: 'Vandgennemstrømning',
  pariedManholeModalName: 'Parrede mandehuller',
  passed: 'Bestået',
  paymentDetails: 'Betalingsoplysninger',
  paymentMethodHasBeenSuccessfullySaved: 'Betalingsmetoden er blevet gemt',
  pending: 'Verserende',
  perDevice: 'per enhed',
  perMonth: 'om måneden',
  personnelLabel: 'Personale',
  personnelPlaceholder: 'Indtast personale',
  perYear: 'om året',
  phisicalId: 'Fysisk ID',
  photosTitle: 'Fotos',
  pickSubscriptionPlan: 'Vælg en abonnementsplan',
  pickSubscriptionPlanSub1:
    'Ved at vælge et abonnement abonnerer du på en række funktioner på Smart Utilities-platformen.',
  pickSubscriptionPlanSub2: 'Du skal vælge en plan for hver enhed, du stiller til rådighed.',
  pickUpEventText: 'Afhentningsarrangement',
  placeholderNoData: 'Ingen data',
  plans: 'Planer',
  plansetWasAppliedToAllDevices: 'Planset was applied to all devices of this type',
  plansetWasAppliedToDevices: 'Planset was applied to the device',
  pleaseFillOutBillingInformation:
    'Udfyld venligst faktureringsoplysninger for at få fuld adgang til platformen. Når du har udfyldt den, skal du opdatere siden.',
  pointIntervalLabel: 'Punktinterval',
  premiumLabel: 'Premium',
  pressToLearnMoreTooltip: 'Tryk for at lære mere',
  privacyPolicy: 'Fortrolighedspolitik',
  problemsButtonDetails: 'Detaljer',
  problemsButtonHighTemperature: 'Høj Temperatur',
  problemsDate: 'Dato',
  problemsOf: 'af',
  problemsPlace: 'Sted',
  problemsProblem: 'Problem',
  problemsShowing: 'Viser',
  profileLabel: 'Profil',
  profilePlaceholder: 'Vælg profil',
  provisioned: 'Hensættelse',
  provisionedOnLabel: 'Forsynet den',
  provisioning: 'Start enhed',
  provisioningAddingDevice: 'Tilføjelse af en ny enhed',
  provisioningAddress: 'Adresse',
  provisioningChooseCustomer: 'Vælg organisation',
  provisioningContent:
    ' og følg stien. Når du har bekræftet din konto, vend tilbage til denne side for at logge ind og fortsætte med klargøringsprocessen.',
  provisioningContent2: ' for at fortsætte med klargøring. Du bliver muligvis bedt om at logge ind på din konto.',
  provisioningContent3:
    'Indtast 10-cifret kode fra instruktionen, hvis du er på skrivebordet. Hvis du har scannet en QR-kode, går du direkte til næste trin.',
  provisioningContent4:
    'Vælg en organisation blandt de tilgængelige muligheder (hvis du tidligere er blevet føjet til platformen), eller ',
  provisioningContent5:
    ' (hvis du lige har tilmeldt dig). Den enhed, du klargør, vil blive tildelt denne organisation.',
  provisioningCreateOrg: 'opret en ny organisation',
  provisioningCustomer: 'Organisation',
  provisioningDate: 'Forsynet dato',
  provisioningDeployNow: 'Implementer nu (du kan altid implementere det senere)',
  provisioningDescribe:
    'Ved at klargøre en enhed vil du tildele den til din organisation på Smart Utilities-platformen. Processen er forskellig, om du allerede har en konto på vores platform eller ej.',
  provisioningDetails: 'orsynetdetaljer Information about provided',
  provisioningDeviceInstalmentLocationOptional: 'Placering af enhedens afbetaling (valgfrit)',
  provisioningErrorAssign: 'Fejl ved tildelingen',
  provisioningFinish: 'Afslut',
  provisioningInOrderForDevice:
    'For at enheden skal være operationel og udføre målinger, skal den installeres. Det anbefales at installere enheden efter installationen. Bemærk: implementeringen kan tage op til 24 timer under gode netværksforhold (mere i dårlige)',
  provisioningInstructions: 'Instruktioner',
  provisioningRow: 'Hvis du ikke har en konto endnu:',
  provisioningRow2: 'Hvis du allerede har en konto på platformen:',
  provisioningSetDepthModalTitle: 'Maksimal dybde',
  provisioningSuccessfullyAssign: 'Enheden blev implementeret',
  provisioningTooltipOrganisations:
    'Organisationen er en overordnet instans for brugere og enheder, den fungerer som deres aggregator. Hvis du er privatkunde, kan du angive dine egne detaljer.',
  pumpingStationAndTank: 'pumpestation og tank',
  qualityControl: 'Kvalitetskontrol',
  quickstartGuide: 'Hurtig start guide',
  radioModulesFirmwareRevision: 'Firmware revision',
  radioModulesManufacturer: 'Fabrikant',
  radioModulesTypeModel: 'Type/Model',
  rangeText:
    'se efter en række enheder ved at indtaste det første og sidste serienummer fra en produktionsbatch, som du vil tildele. Alle enheder fra dette område vil blive kontrolleret automatisk, du kan fjerne markeringen af dem, du vil udelukke.',
  rangeTitle: 'Rækkevidde',
  reload: 'Genindlæs.',
  reportProblemsModalDescription: 'Indtast en beskrivelse af problemet',
  requestedValue: 'Ønsket værdiændring til',
  resolveButton: 'Beslutte',
  resolvedButton: 'Løst',
  resolvedSuccessfullyMessage: 'Løst med succes',
  resolveIncidentTitle: 'Løs hændelsen',
  roleAdmin: 'Admin',
  roleLabel: 'Rolle',
  roleUser: 'Bruger',
  sameAsBillingInfo: 'Samme som faktureringsoplysninger',
  sampleButton: 'Prøve',
  saveButton: 'Gem',
  scanCuccessful: 'Scanningen lykkedes',
  searchBarPlaceholder: 'Søg…',
  secondDeviceAddress: 'Enheds #2 adresse',
  selectButtonValue: 'Välj',
  selectDevicePlaceholder: 'Vælg enhed',
  selectedDatesLabel: 'Udvalgte datoer',
  selectedTitle: 'Valgt',
  selectPeriodLastDays: 'Seneste 30 dage',
  selectPeriodLastMonths: 'Seneste 6 måneder',
  selectPeriodLastWeek: 'Seneste uge',
  selectPeriodLastYear: 'Senest år',
  selectValuePlaceholder: 'Vælg værdi',
  setParameters: 'Set parameters',
  severityHigh: 'Høj',
  severityLabel: 'Alvorlighed',
  severityLow: 'Lav',
  severityMedium: 'Medium',
  sewageLevelTitle: 'Spildevands niveau (%)',
  shortAdditionalDescription: 'gælder kun for \nde første 5 enheder\n til denne pris pr. enhed pr. måned',
  skipButton: 'Springe',
  skipButtonAddDeviceModal: 'Springe',
  smartHydrant: 'Smart HYDRANT',
  smartRadar: 'Smart RADAR',
  smartRadarAlarm: 'Smart ALARM',
  somethingWentWrong: 'Noget gik galt',
  sorryWeCouldNotFindThePage: 'Beklager, vi kunne ikke finde siden!',
  sourceLabel: 'Kilde',
  sourceTitle: 'Kilde',
  specialInputPlaceholder: 'Alt hvad du ønsker kan være her',
  specialInputTitle: 'Særligt input',
  standingWater: 'Stillestående vand',
  standingWaterDetection: 'Registrering af stillestående vand',
  startButton: 'Start',
  startDateLabel: 'Start dato',
  startLabel: 'Start',
  startTimeLabel: 'Starttidspunkt',
  startTimePlaceholder: 'Indtast starttidspunkt',
  statusLabel: 'Status',
  statusPlaceholder: 'Indtast status',
  subcribeButton: 'Abonnere',
  submergeInWater: 'Vandnedsænkning',
  submergeInWaterDetection: 'Detektering af nedsænkning i vand',
  submit: 'Indsend',
  subscribeTooltip: 'Afmeld e-mail-notifikationer',
  subscriptionContent1:
    'Hvis din organisation har valgt en betalingsmetode og -politik på SUP-platformen, anvendes de på denne enhed.',
  subscriptionContent2:
    'Hvis ikke, skal du angive dem, når du logger ind på platformen. Først derefter vil du få adgang til funktionerne.',
  subscriptionPlan: 'Abonnementsplan',
  success: 'Succes',
  successfullSavingMessage: 'Gemt med succes',
  successMessageUploadingPhoto: 'Billedet er uploadet!',
  successSaveMessage: 'Gemt med succes',
  summaryAddressInput: 'Virksomhedens adresse',
  summaryAddressPlaceholder: 'Indtast virksomhedens adresse',
  summaryCompanyInput: 'Virksomhedens navn',
  summaryCompanyPlaceholder: 'Indtast virksomhedens navn',
  summaryCompanyRowTitle: 'Selskab',
  summaryDevicesRowTitle: 'Enheder',
  summaryEmailInput: 'Email adresse',
  summaryEmailPlaceholder: 'Indtast virksomhedens e-mail',
  summaryPhoneInput: 'Virksomhedens telefon',
  summaryPhonePlaceholder: 'Indtast virksomhedens telefon',
  summarySubtitle:
    'Tjek venligst, om alle oplysningerne er korrekte, og afslut derefter ved at gemme det nye firma med tildelte bruger(e) og enhed(er).',
  summaryTitle: 'Resumé',
  summaryUsersRowTitle: 'Bruger(e)',
  symbolLabel: 'Symbol',
  tableInputErrorMessage: 'Tjek venligst værdien. Det er ikke gyldigt',
  tableLableDatePlaceholder: 'Indtast dato',
  tamperDetection: 'Sabotagedetektering',
  taxesLabel: 'ekskl. gældende afgifter',
  temperatureLowCase: 'temperatur',
  temperatureMeasurements: 'Temperaturmålinger',
  temperatureTooltip: 'Temperatur',
  termsOfService: 'Servicevilkår',
  textFieldEmphasizedHeaderTextModalDescription:
    'Info text informing the user about what are the consequences of the action.',
  textFieldEmphasizedHeaderTextModalInputName: 'Enter name of device to confirm action',
  textFieldEmphasizedHeaderTextModalTitle: 'Revoke device rights?',
  theDownloadWillStartShortly: 'Downloaden starter snart',
  thePhotoWillBeDeleted: 'Billedet slettes permanent.',
  thisDeviceButton: 'Denne enhed',
  timePlaceholder: 'Indtast tid (tt:mm)',
  tooltipAddDevicesManually:
    'Denne enhed er tildelt et andet firma. Hvis du vil tildele den til en ny, skal du først gentildele den til dig, trykke på opdateringsknappen og derefter tildele den til det nye firma.',
  toProceedEveryDevice: 'For at fortsætte skal der vælges et plansæt for hver enhed',
  total: 'Total',
  totalNumberOfDevices: 'Samlet antal enheder',
  unexpectedErrorHasOccurred: 'En uventet fejl er opstået. Prøv igen',
  unsubcribeButton: 'Opsige abonnement',
  unsubscribeTooltip:
    'Abonner på at modtage e-mail-meddelelser, når enheden registrerer unormale værdier (f.eks. Kritisk temperaturgrænse er nået)',
  uploadDevicesInputTextFirstPart: 'Vælg fil',
  uploadDevicesInputTextSecondPart: 'eller træk den hertil (.csv eller .xlsx)',
  uploadDevicesInputTextSecondPartMobile: '(.csv eller .xlsx)',
  uploadDevicesModalFailedUploads: 'Mislykkede uploads:',
  uploadDevicesModalSuccessfullyUploadedDevices: 'Succesfuldt uploadede enheder:',
  uploadDevicesModalTryAgain: 'Prøv igen',
  uploadDevicesModalUploadingFile: 'Uploader fil...',
  uploadDevicesTitle: 'Upload enheder',
  uploadFileAddDeviceModal: 'Upload fil',
  uploadFileDescAddDeviceModal:
    'Importer en CSV-fil med alle serienumrene på de enheder, du vil tildele til dette firma',
  uploadFilesModalDescriptionFirstPart: 'Choose file',
  uploadFilesModalDescriptionSecondPart: 'or drag it here',
  uploadFilesModalListTitle: 'Uploaded files',
  uploadFilesModalTitle: 'Upload firmware',
  uploadFileTooltipAddDeviceModal:
    'Anbefales til at tilføje mere end 10 enheder på en gang fra forskellige produktionsbatcher.',
  userCompanyLabel: 'Selskab',
  userDeleteInfo: 'Brugeren slettes permanent.',
  userEmailLabel: 'Email adresse',
  userEmailPlaceholder: 'Indtast email-adresse',
  userNameLabel: 'Brugerens navn',
  usersAddedByLabel: 'Tilføjet af',
  usersDateOfRegistrationLabel: 'Dato for registrering',
  usersFirstLoginLabel: 'Første login',
  usersName: 'Brugerens navn',
  usersParentCustomerLabel: 'Moderorganisation',
  usersParentOrganisationPlacholder: 'Vælg overordnet organisation',
  validationFillFieldsRequest: 'Udfyld venligst alle felterne korrekt',
  validationMessage: ' ser ikke rigtigt ud',
  valueWillBeUpdated: 'Værdien vil blive opdateret, næste gang enheden sender',
  VATnumber: 'Momsnummer',
  vatNumberErrorText: 'Det momsnummer, du har angivet, er ugyldigt i VIES-systemet. Gennemgå og opdater venligst.',
  VATnumberPlaceholder: 'Indtast momsnummer',
  view: 'Udsigt',
  vWeirLabel: 'V-væger',
  waterLevelTooltip: 'Vandstand',
  waterMeasurementsLabel: 'vandmålinger',
  weAreWorkingOnTheFixing: 'Vi arbejder på at løse problemet. Prøv igen.',
  weCantFindThePage: 'Vi kan ikke finde den side, du leder efter.',
  wereHavingAnIssue: 'Vi oplever et problem. Prøv venligst en af disse muligheder for at komme tilbage på sporet',
  wereSorry: `Vi beklager, men noget gik galt, da vi forsøgte at indlæse denne side. Prøv igen`,
  wereSorry2: `Vi beklager, men det ser ud til, at vi ikke kan finde den side, du har ledt efter`,
  whatAreYouLookingFor: 'Hvad kigger du efter?',
  wrongFileFormat: 'Forkert filformat. Brug venligst .csv eller .xlsx',
  yearlyLabel: 'Jährlich',
  yesButton: 'Ja',
};
