import React, { useEffect, useRef } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const SmallInput = styled.input`
  height: 57px;
  width: 40px;
  margin: 0 4px 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  background-color: ${colorFetch('white')};
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 30px;
  color: ${colorFetch('gray1')};
  outline: none;
  padding: 0 10px 0 10px;
  transition: all 0.3s linear;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &:focus {
    border-color: ${colorFetch('primary')};
  }
  opacity: 1; /* required on iOS */
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */
` as any; // @TODO fix type

const WrapperInput = styled.div`
  position: relative;
  &.showDash {
    margin-right: 10px;
    ::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -9px;
      transform: translate(0, -50%);
      width: 8px;
      height: 2px;
      background-color: ${colorFetch('gray2')};
    }
    ${media.phone} {
      margin-right: 0;
      ::after {
        content: none;
      }
    }
  }
`;

export const OneNumberInput = ({
  value,
  onChange,
  onBlur,
  onFocus,
  focused,
  onKeyUp,
  showDash,
  inputType,
}: {
  value: string;
  onChange?: Function;
  onBlur?: Function;
  onFocus?: Function;
  onKeyUp?: Function;
  focused: boolean;
  showDash: boolean;
  className?: string;
  inputType?: string;
}) => {
  const inputRef = useRef<any | HTMLParagraphElement>(null);

  useEffect(() => {
    if (focused) {
      inputRef?.current?.focus();
    } else if (document.activeElement === inputRef.current) {
      inputRef?.current?.blur();
    }
  }, [focused]);

  return (
    <WrapperInput
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={showDash ? 'showDash' : ''}
    >
      <SmallInput
        ref={inputRef}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        type={inputType ?? 'text'}
      />
    </WrapperInput>
  );
};
