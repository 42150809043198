import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { setPlansetForAllDevicesOfThisType, setPlansetOfDevice } from '../../../../../app/state/organisations';
import { selectLanguageStrings } from '../../../../../app/state/user';
import { ReactComponent as InfoIcon } from '../../../../../assets/Info2.svg';
import { CheckBox } from '../../../../../components/checkbox-v2/Checkbox';
import Tooltip, { TooltipPlace, useTooltip } from '../../../../../components/tooltip';
import { UniversalTableSelect } from '../../../../../components/universalTableSelect/UniversalTableSelect';
import { useStyleContext } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useSlicedScroll } from '../../../../../utils/useSlicedScroll';
import { useNotifierFunctions } from '../../../../notifier2';
import { ChoosingPlanset } from '../../choosingPlanset/ChoosingPlanset';
import { StyledSecondaryModal, StyledSecondaryModalContainer } from '../styled';
import { TooltipMobileModal } from './TooltipMobileModal';

type ParameterListProps = {
  heightOfList: number;
};

const ParameterList = styled.ul<ParameterListProps>`
  width: 100%;
  max-height: 312px;
  margin: 0;
  padding: 0;
  overflow-y: ${({ heightOfList }) => (heightOfList > 220 ? 'scroll' : 'auto')};
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

type ParameterItemProps = {
  isLast: boolean;
  isSelectedTab: boolean;
};

const ParameterItem = styled.li<ParameterItemProps>`
  display: grid;
  grid-template-columns: ${({ isSelectedTab }) => (isSelectedTab ? 'repeat(3, 1fr)' : 'repeat(3, 1fr) 18px')};
  grid-row-gap: 2px;
  grid-column-gap: 10px;
  margin-bottom: ${({ isLast }) => (isLast ? '0' : '13px')};
  /* cursor: pointer; */
  &:hover > span {
    color: ${colorFetch('primary') as any};
  }
  & > div {
    display: flex;
    align-items: center;
  }
  &:first-child {
    margin-top: 10px;
  }

  ${media.semiTablet} {
    grid-template-columns: ${({ isSelectedTab }: any) =>
      isSelectedTab ? 'repeat(2, 1fr)' : 'repeat(2, 1fr) 0px 18px'};
  }
`;

const DeviceNameWrapper = styled.div`
  flex: 1;
  overflow-x: hidden;

  ${media.semiTablet} {
    & > span {
      font-weight: 500;
    }
  }

  ${media.phone} {
    width: 180px;
  }
`;

type DeviceSecondaryNameWrapperProps = {
  isSelectedTab: boolean;
};

const DeviceSecondaryNameWrapper = styled.div<DeviceSecondaryNameWrapperProps>`
  flex: 1;
  overflow-x: hidden;

  ${media.semiTablet} {
    margin-left: ${({ isSelectedTab }: any) => isSelectedTab && '30px'};
    grid-area: ${({ isSelectedTab }: any) => (isSelectedTab ? '2/1/2/1' : '1/2/1/2')};

    & > span {
      font-size: ${({ isSelectedTab }: any) => isSelectedTab && '12px'};
      line-height: ${({ isSelectedTab }: any) => isSelectedTab && '18px'};
    }
  }

  ${media.phone} {
    width: 160px;
  }
`;

const PlansetWrapper = styled.div`
  flex: 1;
`;

// interface PlansetSelectProps {
//   widthOfModal: number;
// }

// const PlansetSelect = styled(Select)<PlansetSelectProps>`
//   height: 20px;
//   & .selectedItem {
//     padding: 0;
//     height: 20px;
//     border: none;
//   }

//   & .modalOfSelect {
//     width: ${({ widthOfModal }) => (widthOfModal > 172 ? '172px' : `${widthOfModal}px`)};
//     /* & div > div {
//       overflow-x: hidden;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//     } */
//   }
// `;

const PlansetSelect = styled(UniversalTableSelect)`` as any; // TODO fix type

const NameText = styled(Text)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: 20px;
`;

const TypeText = styled(Text)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: 20px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-right: 8px;
`;

const StyledInfoIcon = styled(InfoIcon)``;

const CheckboxWrapper = styled.div`
  width: max-content;
  display: flex;
  margin-right: 10px;
`;

const CheckboxSemiwrapper = styled.div`
  width: max-content;
  box-sizing: border-box;
  padding: 2px;
`;

interface ParameterItemsProps {
  values: any;
  devices: any;
  listRef: any;
  isSelectedTab: boolean;
  heightOfList: number;
  openModal: boolean;
  handleSelect: (props: any) => void;
  setModalOpen: (props: any) => void;
  showDevicesWIthoutPlanset: boolean;
}

export const ParameterItems: FC<ParameterItemsProps> = ({
  values,
  devices,
  listRef,
  isSelectedTab,
  heightOfList,
  openModal,
  handleSelect,
  setModalOpen,
  showDevicesWIthoutPlanset,
}) => {
  const theme = useTheme();
  const { primary, gray3, gray1 } = theme.colors;

  const dispatch = useDispatch();

  const { addNotification } = useNotifierFunctions();

  const [
    {
      mediaType: { phone },
    },
  ] = useStyleContext();

  const languageStrings = useSelector(selectLanguageStrings);

  const [tooltipIds] = useTooltip();

  const clearEvents = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const [pages] = useSlicedScroll(listRef);

  const [isChoosingPlansetModalOpen, setIsChoosingPlansetModalOpen] = useState<any>(null);

  const handleCallbackNotification = (deviceType: 'all' | 'one') => {
    return addNotification({
      message:
        deviceType === 'all'
          ? languageStrings.plansetWasAppliedToAllDevices
          : languageStrings.plansetWasAppliedToDevices,
      type: 'success',
    });
  };

  const changePlansetForOneDevice = () => {
    const { item, id } = isChoosingPlansetModalOpen;

    dispatch(setPlansetOfDevice({ item, id }));
    handleCallbackNotification('one');
    setIsChoosingPlansetModalOpen(null);
  };

  const changePlansetForAllTypesDevices = () => {
    const { item, type } = isChoosingPlansetModalOpen;

    dispatch(setPlansetForAllDevicesOfThisType({ item, type }));
    handleCallbackNotification('all');
    setIsChoosingPlansetModalOpen(null);
  };

  const [tooltipId] = useTooltip(values.length);

  return (
    <>
      <ParameterList ref={listRef} heightOfList={heightOfList}>
        {values.length &&
          values.slice(0, 20 * pages).map((item: any, index: number) => {
            const { id, name, fake_type, isSelected, available_status, plansets_info, type } = item;

            return (
              <ParameterItem
                key={index}
                isLast={index === devices.length - 1}
                onClick={() => {
                  // available_status && !openModal && handleSelect(item);
                }}
                isSelectedTab={isSelectedTab}
              >
                <DeviceNameWrapper>
                  <CheckboxWrapper>
                    <CheckboxSemiwrapper>
                      <CheckBox
                        value={isSelected}
                        disabled={!available_status}
                        onChange={(e: any) => {
                          clearEvents(e);
                          available_status && !openModal && handleSelect(item);
                        }}
                      />
                    </CheckboxSemiwrapper>
                  </CheckboxWrapper>
                  <StyledTooltip id={tooltipId[index]} text={name} place={TooltipPlace.TOP}>
                    <NameText
                      type={TextType.TEXT_14_GRAY}
                      color={isSelected && !!available_status ? primary : !available_status ? gray3 : gray1}
                    >
                      {name}
                    </NameText>
                  </StyledTooltip>
                </DeviceNameWrapper>
                <DeviceSecondaryNameWrapper isSelectedTab={isSelectedTab}>
                  {fake_type && (
                    <TypeText
                      type={TextType.TEXT_14_GRAY}
                      color={isSelected ? primary : !available_status ? gray3 : gray1}
                      weight={'400'}
                      lineHeight={'20px'}
                    >
                      {fake_type}
                    </TypeText>
                  )}
                </DeviceSecondaryNameWrapper>
                <PlansetWrapper>
                  {isSelectedTab && !!plansets_info.available_plansets.length && (
                    <PlansetSelect
                      value={plansets_info.device_planset}
                      list={plansets_info.available_plansets}
                      onChange={(item: any) => setIsChoosingPlansetModalOpen({ item, id, type: type })}
                      placeholder={languageStrings.choosingPlanset}
                      withError={!plansets_info.device_planset?.id && showDevicesWIthoutPlanset}
                      parentRef={listRef}
                      hackOverflow
                      withoutBorder
                    />
                  )}
                </PlansetWrapper>
                {!available_status ? (
                  <StyledTooltip
                    width={'298px'}
                    id={tooltipIds[0]}
                    text={languageStrings.tooltipAddDevicesManually}
                    disabled={phone}
                  >
                    <StyledInfoIcon onClick={() => phone && setModalOpen(true)} />
                  </StyledTooltip>
                ) : (
                  <div style={{ width: '16px' }}></div>
                )}
                <StyledSecondaryModal isOpenModal={openModal}>
                  <StyledSecondaryModalContainer>
                    <TooltipMobileModal value={name} setModalClose={setModalOpen} languageStrings={languageStrings} />
                  </StyledSecondaryModalContainer>
                </StyledSecondaryModal>
              </ParameterItem>
            );
          })}
      </ParameterList>
      <ChoosingPlanset
        isOpenModal={!!isChoosingPlansetModalOpen}
        onThisDeviceButtonClick={changePlansetForOneDevice}
        onAllButtonClick={changePlansetForAllTypesDevices}
        onCloseButtonClick={() => setIsChoosingPlansetModalOpen(null)}
      />
    </>
  );
};
