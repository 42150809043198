import { useTheme } from '@cthings.co/styled-components';
import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { LsValueType } from '../../../../../../../../../enums/LsValueType';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import ss from '../../../../../../../../../utils/ss';
import { useBlockBodyScroll } from '../../../../../../../../../utils/useBlockBodyScroll';
import { DeleteIcon } from '../deleteIcon/DeleteIcon';
import { PhotoViewer } from '../photoViewer/PhotoViewer';

const ImageWrapper = styled.div`
  width: 134px;
  height: 134px;
  display: flex;
  position: relative;
  margin: 0 10px 10px 0;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  &:hover {
    & > div:last-child {
      transition: all 0.3s linear;
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
`;

const HoveredBackground = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  cursor: pointer;
`;

const DeleteButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  bottom: 6px;
  background-color: ${colorFetch('white')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 0;
  border: none;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover > svg > path {
    stroke: ${colorFetch('gray1')};
  }
`;

export const PhotoItem = ({
  index,
  deletePhoto,
  photosList,
}: {
  index: number;
  deletePhoto: (e: any, imageId: string) => void;
  photosList: any[];
}) => {
  const theme = useTheme();
  const [isOpenPhotoViewer, setIsOpenPhotoViewer] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(index);
  useBlockBodyScroll(isOpenPhotoViewer);
  const { black1, gray1 } = theme.colors;

  const nextPhoto = () => {
    if (selectedIndex === photosList.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };
  const previousPhoto = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(photosList.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 37) {
      previousPhoto();
    } else if (e.keyCode === 39) {
      nextPhoto();
    } else if (e.keyCode === 27) {
      setIsOpenPhotoViewer(false);
    }
  };

  useEffect(() => {
    if (isOpenPhotoViewer) {
      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [isOpenPhotoViewer, selectedIndex]);

  return (
    <ImageWrapper theme={theme}>
      <Image src={`${photosList[index].link}?access_token=${ss.get(LsValueType.token)}`} />
      <HoveredBackground
        onClick={() => {
          setIsOpenPhotoViewer(true);
          setSelectedIndex(index);
        }}
      >
        <DeleteButton
          theme={theme}
          onClick={(e: any) => {
            deletePhoto(e, photosList[index].id);
          }}
        >
          <DeleteIcon color={black1} hoverColor={gray1} />
        </DeleteButton>
      </HoveredBackground>
      <PhotoViewer
        isOpen={isOpenPhotoViewer}
        closeViewer={() => {
          setIsOpenPhotoViewer(false);
        }}
        nextPhoto={nextPhoto}
        previousPhoto={previousPhoto}
        photosList={photosList}
        selectedIndex={selectedIndex}
      />
    </ImageWrapper>
  );
};
