import React, { FC, useEffect, useRef, useState, ReactNode } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as Arrow } from '../select/assets/arrow.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const SelectBlock = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type SelectItemProps = {
  disabled?: boolean;
  selectIcon: boolean;
  isFocused?: boolean;
  isError?: boolean;
};

const SelectItem = styled.div<SelectItemProps>`
  width: 100%;
  height: 45px;
  padding: 11px 22px 10px;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray4')};
  display: flex;
  outline: none;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  & > svg {
    width: ${({ selectIcon }) => (selectIcon ? '26px' : 'max-content')};
    height: ${({ selectIcon }) => (selectIcon ? '26px' : 'max-content')};
    & > path {
      stroke: ${({ disabled, isFocused, isError, theme }) =>
        disabled
          ? colorFetch('gray5')({ theme })
          : isFocused
          ? colorFetch('primary')({ theme })
          : isError
          ? colorFetch('red2')({ theme })
          : colorFetch('gray2')({ theme })};
    }
  }
  ${media.phone} {
    padding: 13px 16px;
  }
`;

type ArrowButtonProps = {
  isOpen: boolean;
};

const ArrowButton = styled.button<ArrowButtonProps>`
  border: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  & svg {
    transition: all 0.3s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : '')};
  }
  & svg > path {
    fill: ${colorFetch('gray2')};
  }
`;

const WrapperText = styled(Text)`
  transition: all 0.1s;
  font-weight: 600;
  font-weight: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? '600' : '500')};
  color: ${({ theme }: any) => theme.colors.gray1};
  font-family: 'Poppins', sans-serif;
`;

interface valueType {
  id: any;
  name: string;
}

export interface ComponentProps {
  valueTitle: string;
  value: valueType;
  values: any[];
  disabled?: boolean;
  isOpen: boolean;
  onChange: (id: any) => any;
  className?: any;
  label?: any;
  selectIcon?: () => ReactNode;
  onClick?: () => void;
}

export const SelectDevice: FC<ComponentProps> = ({
  isOpen,
  valueTitle,
  value,
  values,
  disabled = false,
  onChange,
  label,
  selectIcon,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<number>(values?.findIndex((item: any) => item.id === value.id));

  useEffect(() => {
    setSelectedIndex(values?.findIndex((item: any) => item.id === value.id));
  }, [value, values]);

  const buttonRef = useRef<any>(null);
  return (
    <SelectBlock {...props}>
      <SelectItem theme={theme} onClick={onChange} selectIcon={!!selectIcon}>
        <WrapperText isOpen={isOpen} type={TextType.TEXT_14_BLACK}>
          {valueTitle}
        </WrapperText>
        {!disabled &&
          (selectIcon ? (
            selectIcon()
          ) : (
            <ArrowButton isOpen={isOpen} ref={buttonRef}>
              <Arrow />
            </ArrowButton>
          ))}
      </SelectItem>
    </SelectBlock>
  );
};
