import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../app/state/user';
import { View } from '../../../../../../../routes/routeInterfaces';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useCustomHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { DeviceType } from './components/DeviceType';
import { Info } from './components/Info';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${media.desktop} {
    justify-content: space-between;
    align-items: flex-start;
  }

  ${media.semiTablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
`;

const InfoBlock = styled.div`
  display: flex;

  ${media.desktop} {
    gap: 10px;
    flex-direction: column;
  }

  ${media.phone} {
    gap: 15px;
  }
`;

export const Line = styled.div`
  height: 16px;
  border-left: 1px solid;
  border-color: ${colorFetch('gray3')};
  margin: 0 14px;
`;

export interface DevicesSubtitleProps {
  data: any;
}

export const DevicesSubtitle: React.FC<DevicesSubtitleProps> = ({ data }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();
  const { gray1_5 } = theme.colors;
  const [
    {
      mediaType: { desktop },
    },
  ] = useStyleContext();

  const customHistory = useCustomHistory();

  return (
    <Wrapper>
      <>
        <DeviceType borderColor={gray1_5} type={languageStrings.pariedManholeModalName} />
        {!desktop && <Line />}
      </>
      <InfoBlock>
        <Info
          deviceTitle={languageStrings.deviceLabel + ' #1 ID:'}
          deviceName={data.devices[0].transport_id}
          onClick={() => {
            const to = View.USER_MANAGE_DEVICES_DETAILS;
            customHistory.push(to, {
              pathParams: { id: data.devices[0].id, offset: 0 },
            });
          }}
        />
        {!desktop && <Line />}
        <Info
          deviceTitle={languageStrings.deviceLabel + ' #2 ID:'}
          deviceName={data.devices[1].transport_id}
          onClick={() => {
            const to = View.USER_MANAGE_DEVICES_DETAILS;
            customHistory.push(to, {
              pathParams: { id: data.devices[1].id, offset: 0 },
            });
          }}
        />
      </InfoBlock>
    </Wrapper>
  );
};
