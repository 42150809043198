import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { useBlockBodyScroll } from '../../../../../../utils/useBlockBodyScroll';
import { TableInput, TableInputType } from '../../../../../../components/tableInput/TableInput';
import { DownloadLink } from '../../../../../../components/donwloadLink/DonwloadLink';
import { getExportDeviceDetailsUrl, useGetAdditionalInfoForExport } from '../../../../../../api/devices/devices';
import { useGetCustomers } from '../../../../../../api/reports';
import { selectLanguage } from '../../../../../../app/state/user';
import { useSelector } from 'react-redux';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 564px !important;
  height: max-content;
  padding: 25px 24px !important;
  display: flex;
  align-items: flex-start !important;
  ${media.phone} {
    width: 100% !important;
    position: fixed !important;
    padding: 24px 16px 18px !important;
    bottom: 0 !important;
    border-radius: 16px 16px 0 0 !important;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledDownloadLink = styled(DownloadLink)`
  margin-left: 16px;
` as any; // @TODO any

type ScrolledWrapperProps = {
  isOpenParameterPopup: boolean;
  isScrollable: boolean;
};

// @TODO fix media types
const ScrolledWrapper = styled.div<ScrolledWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 626px;
  min-height: ${({ isOpenParameterPopup }) => (isOpenParameterPopup ? '366px' : '156px')};

  border-radius: 8px;
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'auto' : 'visible')};
  padding-right: 4px;
  transition: min-height 0.3s linear;
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  ${media.semiTablet} {
    height: 100%;
    max-height: 454px;
    border-radius: ${({ theme }: any) => theme.borderRadius.primary};
  }
`;

export const ExportModal = ({
  closeModal,
  isOpenModal,
  languageStrings,
}: {
  closeModal: () => void;
  isOpenModal: boolean;
  languageStrings?: any;
}) => {
  const [isScrollableModal, setIsScrollableModal] = useState(true);
  const theme = useTheme();
  const { black1, pureWhite } = theme.colors;
  const [customers] = useGetCustomers();
  const [additionalParams] = useGetAdditionalInfoForExport();
  const language = useSelector(selectLanguage);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);
  const [selectedAdditionalParamIds, setSelectedAdditionalParamIds] = useState<string[]>([]);

  const [isValidatingStarted, setIsValidatingStarted] = useState(false);
  const [isCustomerListOpen, setIsCustomerListOpen] = useState(false);
  const [isAdditionalParamListOpen, setIsAdditionalParamListOpen] = useState(false);

  useBlockBodyScroll(isOpenModal);
  const wrapperRef = useRef<any>(null);

  const isOpenParameterPopup = isCustomerListOpen || isAdditionalParamListOpen;

  const noCustomerSelected = selectedCustomerIds.length === 0;

  const checkCustomer = (id: string) => {
    setSelectedCustomerIds((selectedIds) => {
      if (selectedIds.includes(id)) {
        const index = selectedIds.indexOf(id);
        return [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1, selectedIds.length)];
      } else {
        return Array.from(new Set([...selectedIds, id]));
      }
    });
  };

  const checkAdditionalParam = (id: string) => {
    setSelectedAdditionalParamIds((selectedIds) => {
      if (selectedIds.includes(id)) {
        const index = selectedIds.indexOf(id);
        return [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1, selectedIds.length)];
      } else {
        return Array.from(new Set([...selectedIds, id]));
      }
    });
  };

  const selectAllCustomers = () => {
    setSelectedCustomerIds(
      customers.length === selectedCustomerIds.length ? [] : customers.map((customer) => customer.id),
    );
  };

  const selectAllAdditionalParams = () => {
    setSelectedAdditionalParamIds(
      additionalParams.length === selectedAdditionalParamIds.length
        ? []
        : additionalParams.map((additionalParam) => additionalParam.id),
    );
  };

  const handleRemoveSelectedCustomer = (id: string) => {
    setSelectedCustomerIds((selectedIds) => {
      const index = selectedIds.indexOf(id);

      return [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1, selectedIds.length)];
    });
  };

  const handleRemoveSelectedAdditionalParam = (id: string) => {
    setSelectedAdditionalParamIds((selectedIds) => {
      const index = selectedIds.indexOf(id);

      return [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1, selectedIds.length)];
    });
  };

  const handleCloseModal = useCallback(() => {
    setIsValidatingStarted(false);
    setSelectedCustomerIds([]);
    setSelectedAdditionalParamIds([]);
    closeModal();
  }, [closeModal]);

  const handleDownload = useCallback(
    (e: SyntheticEvent<HTMLLinkElement>) => {
      setIsValidatingStarted(true);
      if (noCustomerSelected) {
        e.preventDefault();
      } else {
        handleCloseModal();
        setIsValidatingStarted(false);
      }
    },
    [noCustomerSelected, handleCloseModal],
  );

  const preparedSelectedParams = {
    client_ids: selectedCustomerIds,
    output_attributes: selectedAdditionalParamIds,
  };

  const statefulCustomers = customers.map((customer) => ({
    ...customer,
    isSelected: selectedCustomerIds.findIndex((selectedId) => selectedId === customer.id) !== -1,
  }));

  const statefulAdditionalParams = additionalParams.map((additionalParam) => ({
    ...additionalParam,
    isSelected: selectedAdditionalParamIds.findIndex((selectedId) => selectedId === additionalParam.id) !== -1,
  }));

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Text
          type={TextType.SEMITITLE}
          fontSize={'18px'}
          weight={'600'}
          lineHeight={'24px'}
          color={black1}
          margin={'0 0 16px 0'}
        >
          {languageStrings.exportExcelFileTitle}
        </Text>
        <ScrolledWrapper
          ref={wrapperRef}
          theme={theme}
          isScrollable={isScrollableModal}
          isOpenParameterPopup={isOpenParameterPopup}
        >
          <TableInput
            type={TableInputType.DROPDOWN_MULTI_CHOICE}
            value={statefulCustomers.filter((customer) => customer.isSelected)}
            handleChangeValue={() => {}}
            valuesList={statefulCustomers}
            options={{
              placeholder: languageStrings.generateReportModalParameterCustomerPlaceholder,
              title: languageStrings.navigationClients,
              isValidatingStarted,
              isError: noCustomerSelected && isValidatingStarted,
              isOpenSelectList: isCustomerListOpen,
              isSelectAll: customers.length === selectedCustomerIds.length,
              popUpScrollTo: 80,
              popUpScrollToWithCustomPeriod: 124,
              wrapperRef,
            }}
            isEditInProcess
            additionalFunctions={{
              handleCheckedItem: (id: string) => {
                checkCustomer(id);
              },
              handleRemoveSelectedItem: ({ id }: { id: string }) => {
                handleRemoveSelectedCustomer(id);
              },
              handleSelectAll: () => {
                selectAllCustomers();
              },
              setIsOpenSelectList: (value: any) => {
                setIsAdditionalParamListOpen(false);
                setIsCustomerListOpen(!!value);
              },
              setIsScrollableModal,
              handleTransformName: (name: any) => `${name}`,
            }}
          />
          <TableInput
            type={TableInputType.DROPDOWN_MULTI_CHOICE}
            value={statefulAdditionalParams.filter((additionalParam) => additionalParam.isSelected)}
            handleChangeValue={() => {}}
            valuesList={statefulAdditionalParams}
            options={{
              placeholder: languageStrings.additionalInfoPlaceholder,
              title: languageStrings.additionalInfoLabel,
              isValidatingStarted,
              isError: false,
              isOpenSelectList: isAdditionalParamListOpen,
              isSelectAll: additionalParams.length === selectedAdditionalParamIds.length,
              popUpScrollTo: 244,
              popUpScrollToWithCustomPeriod: 288,
              wrapperRef,
            }}
            isEditInProcess
            additionalFunctions={{
              handleCheckedItem: (id: string) => {
                checkAdditionalParam(id);
              },
              handleRemoveSelectedItem: ({ id }: { id: string }) => {
                handleRemoveSelectedAdditionalParam(id);
              },
              handleSelectAll: () => {
                selectAllAdditionalParams();
              },
              setIsOpenSelectList: (value: any) => {
                setIsCustomerListOpen(false);
                setIsAdditionalParamListOpen(!!value);
              },
              setIsScrollableModal,
              handleTransformName: (name: any) => `${name}`,
            }}
          />
        </ScrolledWrapper>
        <ButtonsWrapper>
          <CommonButton
            onClick={handleCloseModal}
            width={'116px'}
            height={'32px'}
            colors={{
              main: black1,
              background: pureWhite,
            }}
          >
            {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
          </CommonButton>
          <StyledDownloadLink
            onClick={handleDownload}
            downloadUrl={getExportDeviceDetailsUrl(preparedSelectedParams, language.id)}
            width={'116px'}
            height={'32px'}
            disabled={noCustomerSelected}
          >
            {languageStrings.download}
          </StyledDownloadLink>
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
