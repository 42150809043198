import styled from '@cthings.co/styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteDeviceComment,
  deletePhoto,
  editDeviceComment,
  setDeviceComment,
  uploadPhoto,
  useDeviceComments,
} from '../../../../../../../api/realTimeInsight';
import { selectComments } from '../../../../../../../app/state/insight';
import { selectLanguageStrings } from '../../../../../../../app/state/user';
import { Comments } from '../../../../../../../components/comments/Comments';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { media } from '@cthings.co/styles-utils';
import { Photos } from '../photos/Photos';

const DeviceWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 52px;
  & > div:first-child {
    margin-right: 20px;
  }

  ${media.tablet} {
    flex-direction: column;
  }

  ${media.phone} {
    margin-top: 0;
  }
`;

const StyledComments = styled(Comments)`
  margin-bottom: 20px;
`;

export const DeviceConfig = ({ id, photos }: { id: string; photos?: any[] }) => {
  const commentsGet = useDeviceComments(id);

  const commentsData = useSelector(selectComments);
  const [comments, setComments] = useState(commentsData || []);
  const [valueComment, setValueComment] = useState('');
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    setComments(commentsData);
  }, [commentsData]);

  useEffect(() => {
    setComments(commentsGet);
  }, [commentsGet]);

  const handleCommentChange = useCallback((e: any) => {
    const val = e.target.value;
    setValueComment(val);
  }, []);

  const handleCommentEdit = (id_comment: number, edit_comment: string) => {
    editDeviceComment(id, edit_comment, id_comment, addNotification, languageStrings);
  };

  const handleCommentDelete = (id_comment: number) => {
    deleteDeviceComment(id, id_comment, addNotification, languageStrings);
  };

  const handleCommentSubmit = () => {
    setDeviceComment(id, valueComment, addNotification, languageStrings);
    setValueComment('');
  };
  const addPhoto = (
    data: string,
    setPhotoList: React.Dispatch<any>,
    setIsLoadingInProcess: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    uploadPhoto(id, data, addNotification, setPhotoList, setIsLoadingInProcess, languageStrings);
  };
  const handleDeletePhoto = (imageId: string) => {
    deletePhoto(id, imageId, addNotification, languageStrings);
  };

  return (
    <DeviceWrapper>
      <StyledComments
        languageStrings={languageStrings}
        commentsList={comments}
        value={valueComment}
        onChange={handleCommentChange}
        onEdit={handleCommentEdit}
        onDelete={handleCommentDelete}
        onSubmit={handleCommentSubmit}
      />
      <Photos languageStrings={languageStrings} photos={photos} addPhoto={addPhoto} deletePhoto={handleDeletePhoto} />
    </DeviceWrapper>
  );
};
