import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetAvailableFiltersForGraph } from '../../../../../api/devices/historyInsight';
import { selectAccess, selectLanguageStrings } from '../../../../../app/state/user';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { ManholeDetails } from './manholeDetails/ManholeDetails';
import { useParams } from '@cthings.co/router-dom';

interface InsightsDetailsProps {}

export const MeasurementInsights: FC<InsightsDetailsProps> = ({ ...props }: any) => {
  const accessData = useSelector(selectAccess).management.devices;
  const languageStrings = useSelector(selectLanguageStrings);
  const params = useParams();
  const id = params.id || '';
  const pathParams = { id };

  const { addNotification } = useNotifierFunctions();

  const [defaultFilters, setDefaultFilters] = useState({
    periods: {
      default_value: { name: '', id: '' },
      items_list: [{ name: '', id: '' }],
    },
    intervals: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
    averaging_methods: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
    ts_from: 0,
    ts_to: 0,
  });

  const handleSuccess = (data: any) => {
    setDefaultFilters(data.available_filters);
  };

  const handleError = () => {
    addNotification({
      message: languageStrings.filtersNotificationsError,
      type: 'error',
    });
  };

  useGetAvailableFiltersForGraph({ id, handleSuccess, handleError, isMeasurement: true });

  return <ManholeDetails pathParams={pathParams} accessData={accessData} defaultFilters={defaultFilters} />;
};
