import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguageIdentity } from '../../api/language';
import { selectAccess, selectLanguage, selectLanguages, setLanguage } from '../../app/state/user';
import { isSafari } from '../../consts';
import { LsValueType } from '../../enums/LsValueType';
import { PATHS, View, getAccess } from '../../routes';
import ss from '../../utils/ss';
import { Item } from './components/Item';
import { ItemInsightMap } from './components/ItemInsightMap';
import { LanguageItem } from './components/LanguageItem';
import { LogoutItem } from './components/LogoutItem';
import { ManageItem } from './components/ManageItem';
import { SecondaryMenuItem } from './components/mobileNavigation/MobileNavigation';

export enum MenuItemType {
  DASHBOARD = 'DASHBOARD',
  INSIGHT = 'INSIGHT',
  INCIDENTS = 'INCIDENTS',
  MANAGE = 'MANAGE',
  SETTINGS = 'SETTINGS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  REPORTS = 'REPORTS',
  DOCUMENTS = 'DOCUMENTS',
  LANGUAGES = 'LANGUAGES',
  LOGOUT = 'LOGOUT',
  SECOND_MENU = 'SECOND_MENU',
  ANALYTICS = 'ANALYTICS',
  BILLING = 'BILLING',
}

export interface MenuItemProps {
  type: MenuItemType;
  to?: any;
  value?: string;
  isOpen: boolean;
  disabled?: boolean;
  hidden?: boolean;
  tooltipId?: string;
  userName?: string;
  onLogout?: any;
  childRoutes?: any;
  handleCloseFirstMenu?: () => void;
  secondaryMenuItems?: SecondaryMenuItem[];
  setSecondaryMenuItems?: Dispatch<SetStateAction<SecondaryMenuItem[]>>;
  index?: number;
}

export const MenuItem: FC<MenuItemProps> = ({
  type,
  to,
  value,
  isOpen,
  disabled,
  hidden,
  tooltipId,
  userName,
  onLogout,
  childRoutes,
  handleCloseFirstMenu,
  secondaryMenuItems,
  setSecondaryMenuItems,
  index,
  ...props
}) => {
  const dispatch = useDispatch();
  const languages = useSelector(selectLanguages);
  const language = useSelector(selectLanguage);
  const userAccess = useSelector(selectAccess);

  const route = window.location.href;

  const languageIndex = languages.findIndex((lan: any) => lan.id === language.id);

  const [openManageList, setOpenManageList] = useState(false);
  const [openLangList, setOpenLangList] = useState(false);
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const languagePickerRef = useRef<any>(null);
  const manageRef = useRef<any>(null);

  const languagePickerOutsideHandler = useCallback(
    (e: any) => {
      if (openLangList && !languagePickerRef.current.contains(e.target)) {
        setOpenLangList(false);
      }
    },
    [openLangList],
  );
  const handleSetLanguage = (language: string) => {
    ss.set(LsValueType.locale, language);
    setLanguageIdentity(language);
    if (isSafari) {
      window.location.reload();
    } else {
      dispatch(setLanguage(language));
    }
    setOpenLangList(false);
  };

  const manageOutsideHandler = useCallback(
    (e: any) => {
      if (openManageList && !manageRef.current.contains(e.target)) {
        setOpenManageList(false);
      }
    },
    [openManageList],
  );

  useEffect(() => {
    window.addEventListener('click', languagePickerOutsideHandler);
    return () => {
      window.removeEventListener('click', languagePickerOutsideHandler);
    };
  }, [languagePickerOutsideHandler]);

  useEffect(() => {
    window.addEventListener('click', manageOutsideHandler);
    window.addEventListener('touchstart', manageOutsideHandler);
    return () => {
      window.removeEventListener('click', manageOutsideHandler);
      window.removeEventListener('touchstart', manageOutsideHandler);
    };
  }, [manageOutsideHandler]);

  // function for getting value of access current page
  const getAccessForPage = (keys: string[]) =>
    keys.reduce(
      (acc: any, currentValue: string) => (acc?.hasOwnProperty(currentValue) ? acc[currentValue] : null),
      userAccess,
    );

  switch (type) {
    case MenuItemType.DASHBOARD:
      return (
        getAccessForPage(getAccess(View.USER_DASHBOARD)) && (
          <Item
            disabled={disabled}
            tooltipId={tooltipId}
            to={`${to}?access_token=${token}&expires=${exp}`}
            type={type}
            isOpen={isOpen}
            value={value}
            handleCloseFirstMenu={handleCloseFirstMenu}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );
    case MenuItemType.INSIGHT:
      //check active insightMap route
      const isActiveInsightRoute = route.includes('insightsmap');
      return (
        getAccessForPage(getAccess(View.USER_INSIGHTS)) && (
          <ItemInsightMap
            disabled={disabled}
            hidden={hidden}
            tooltipId={tooltipId}
            to={`${to}?access_token=${token}&expires=${exp}`}
            type={type}
            isOpen={isOpen}
            value={value}
            isActiveInsightRoute={isActiveInsightRoute}
            handleCloseFirstMenu={handleCloseFirstMenu}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );
    case MenuItemType.INCIDENTS:
      const isActiveIncidentsRoute = route.includes('incident');
      return (
        getAccessForPage(getAccess(View.USER_INCIDENTS)) && (
          <Item
            disabled={disabled}
            tooltipId={tooltipId}
            to={`${to}?access_token=${token}&expires=${exp}`}
            type={type}
            isOpen={isOpen}
            isActive={isActiveIncidentsRoute}
            value={value}
            handleCloseFirstMenu={handleCloseFirstMenu}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );
    // case MenuItemType.SETTINGS:
    case MenuItemType.REPORTS:
      return (
        getAccessForPage(getAccess(View.USER_REPORTS)) && (
          <Item
            disabled={disabled}
            tooltipId={tooltipId}
            to={`${to}?access_token=${token}&expires=${exp}`}
            type={type}
            isOpen={isOpen}
            value={value}
            handleCloseFirstMenu={handleCloseFirstMenu}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );
    case MenuItemType.DOCUMENTS:
      return (
        getAccessForPage(getAccess(View.USER_DOCUMENTS)) && (
          <Item
            disabled={disabled}
            tooltipId={tooltipId}
            to={`${to}?access_token=${token}&expires=${exp}`}
            type={type}
            isOpen={isOpen}
            value={value}
            handleCloseFirstMenu={handleCloseFirstMenu}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );
    case MenuItemType.MANAGE:
      const isActive = route.includes(PATHS.MANAGE);
      return (
        (getAccessForPage(getAccess(View.MANAGE_ORGANISATIONS)) ||
          getAccessForPage(getAccess(View.MANAGE_DEVICES)) ||
          getAccessForPage(getAccess(View.MANAGE_USERS))) && (
          <ManageItem
            type={type}
            manageRef={manageRef}
            isActive={isActive}
            to={to}
            childRoutes={childRoutes}
            isOpen={isOpen}
            value={value}
            setOpenManageList={setOpenManageList}
            openManageList={openManageList}
            secondaryMenuItems={secondaryMenuItems}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );
    case MenuItemType.ANALYTICS:
      const isActiveAnalytics = route.includes(PATHS.MANAGE_ANALYTICS);
      return (
        getAccessForPage(getAccess(View.MANAGE_MEASUREMENT)) && (
          <ManageItem
            type={type}
            manageRef={manageRef}
            isActive={isActiveAnalytics}
            to={to}
            childRoutes={childRoutes}
            isOpen={isOpen}
            value={value}
            setOpenManageList={setOpenManageList}
            openManageList={openManageList}
            secondaryMenuItems={secondaryMenuItems}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );
    case MenuItemType.BILLING:
      const isActiveBilling = route.includes(PATHS.BILLING);
      return (
        (getAccessForPage(getAccess(View.BILLING_DETAILS)) ||
          getAccessForPage(getAccess(View.BILLING_INVOICES)) ||
          getAccessForPage(getAccess(View.BILLING_PLANS))) && (
          <ManageItem
            type={type}
            manageRef={manageRef}
            isActive={isActiveBilling}
            to={to}
            childRoutes={childRoutes}
            isOpen={isOpen}
            value={value}
            setOpenManageList={setOpenManageList}
            openManageList={openManageList}
            secondaryMenuItems={secondaryMenuItems}
            setSecondaryMenuItems={setSecondaryMenuItems}
          />
        )
      );

    case MenuItemType.NOTIFICATIONS:
      return <></>;
    case MenuItemType.LANGUAGES:
      return (
        <LanguageItem
          languagePickerRef={languagePickerRef}
          languages={languages}
          languageIndex={languageIndex}
          isOpen={isOpen}
          openLangList={openLangList}
          handleSetLanguage={handleSetLanguage}
          setOpenLangList={setOpenLangList}
          secondaryMenuItems={secondaryMenuItems}
          setSecondaryMenuItems={setSecondaryMenuItems}
        />
      );
    case MenuItemType.LOGOUT:
      return <LogoutItem userName={userName} onLogout={onLogout} isOpen={isOpen} />;
    case MenuItemType.SECOND_MENU:
      return (
        <Item
          to={to}
          type={type}
          isOpen={isOpen}
          value={value}
          handleCloseFirstMenu={handleCloseFirstMenu}
          setSecondaryMenuItems={setSecondaryMenuItems}
          index={index}
          languages={languages}
          handleSetLanguage={handleSetLanguage}
          languageIndex={languageIndex}
        />
      );
    default:
      return null;
  }
};
