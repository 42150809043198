import React, { FC, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import {
  BackButton,
  Block,
  ButtonContainer,
  ButtonWrapper,
  Description,
  Details,
  DetailsWrapper,
  HeadingBlock,
  HintIcon,
  HintText,
  HintWrapper,
  InputsContainer,
  InputsWrapper,
  NextButton,
  StepValue,
  StyledInput,
  Title,
  TypesOfAccountsText,
  TypesOfAccountsWrapper,
} from './styled';
import { InputFieldName } from './types';
import {
  pushAction,
  selectOrganisationUsers,
  selectParentOrganisation,
  updateUser,
} from '../../../../app/state/organisations';
import { getAccountTypes, getInputStructure } from './utils';
import { useUserRole } from '../../../../utils/userRole';
import { CustomModal } from '../customModal/CustomModal';
import { checkFieldValidity } from '../../../../utils/validation';
import { useNotifierFunctions } from '../../../notifier2';
import { useTheme } from '@cthings.co/styled-components';
import { CloseButton } from '../closeButton/CloseButton';

interface AddNewCompanyModalSecondStepProps {
  isOpen: boolean;
  data?: any;
}

export const AddNewCompanyModalSecondStep: FC<AddNewCompanyModalSecondStepProps> = ({ isOpen, data }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const languageStrings = useSelector(selectLanguageStrings);

  const { black1, gray1, gray2, gray5, gray6, pureWhite, primary } = theme.colors;

  const { addNotification } = useNotifierFunctions();
  const [startedValidation, setStartedValidation] = useState(false);
  const [firstUser] = useSelector(selectOrganisationUsers);
  const { name: companyName } = useSelector(selectParentOrganisation);
  const { name, email, role } = firstUser;

  const [, { getUserByRole }] = useUserRole();

  const { name: roleName } = getUserByRole(role);

  const accountTypes = getAccountTypes(languageStrings);

  const inputStructure = getInputStructure(languageStrings);

  const inputValues = {
    name,
    email,
    company: companyName,
    role: roleName,
  };

  const isFormValid = inputStructure.every(({ fieldName, validationRule, isDisabled }) =>
    checkFieldValidity({ value: inputValues[fieldName], validationRule, isDisabled }),
  );

  const handleInputOnChange = (e: FormEvent<HTMLInputElement>, fieldName: InputFieldName) => {
    dispatch(updateUser({ ...firstUser, [fieldName]: e.currentTarget.value }));
  };

  const handleNext = () => {
    setStartedValidation(true);
    if (isFormValid) {
      dispatch(pushAction('next'));
    } else {
      addNotification({
        message: 'Please, correctly fill in all the fields',
        type: 'error',
      });
    }
  };

  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  const hintText = languageStrings.addNewCompanyModalSecondStepHintText;
  const typesOfAccountsText = languageStrings.addNewCompanyModalSecondStepTypesOfAccountsText;
  const titleText = languageStrings.addNewCompanyModalSecondStepTitle;

  return (
    <CustomModal isOpen={isOpen}>
      <HeadingBlock>
        <Title color={black1}>{titleText}</Title>
        <Block>
          <StepValue color={gray2}>2/3</StepValue>
          <CloseButton />
        </Block>
      </HeadingBlock>
      <Description color={gray1}>{languageStrings.addNewCompanyModalSecondStepDescription}</Description>
      <TypesOfAccountsWrapper>
        <TypesOfAccountsText color={gray1}>{typesOfAccountsText}</TypesOfAccountsText>
        {accountTypes.map((el: any) => (
          <DetailsWrapper>
            <Details margin={'0 4px 0 0'} weight={'400'} color={gray1}>
              {'-'}
            </Details>
            <Details margin={'0 4px 0 0'} weight={'500'} color={primary}>
              {el.coloredText}
            </Details>
            <Details weight={'400'} color={gray1}>
              {el.remainingText}
            </Details>
          </DetailsWrapper>
        ))}
      </TypesOfAccountsWrapper>
      <HintWrapper backgroundColor={gray6} borderColor={gray5}>
        <HintIcon />
        <HintText color={gray1}>{hintText}</HintText>
      </HintWrapper>
      <InputsWrapper>
        {inputStructure.map((el) => {
          const { label, fieldName, placeholder, validationRule, isDisabled } = el;
          const validationOptions = {
            isError: !checkFieldValidity({ value: inputValues[fieldName], validationRule, isDisabled }),
            startedValidation,
          };

          return (
            <InputsContainer>
              <StyledInput
                width={'100%'}
                value={inputValues[fieldName]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputOnChange(e, fieldName)}
                name={label}
                placeholder={placeholder}
                disabled={isDisabled}
                {...validationOptions}
              />
            </InputsContainer>
          );
        })}
      </InputsWrapper>
      <ButtonWrapper>
        <ButtonContainer>
          <BackButton
            colors={{
              main: black1,
              background: pureWhite,
            }}
            onClick={handleBack}
          >
            {languageStrings.backButton}
          </BackButton>
          <NextButton onClick={handleNext}>{languageStrings.nextButton}</NextButton>
        </ButtonContainer>
      </ButtonWrapper>
    </CustomModal>
  );
};
