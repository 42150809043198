import React from 'react';
import { useDispatch } from 'react-redux';
import { EditButton } from '../editButton/EditButton';
import { setIsOpenInvoiceDetailsModal } from '../../../../../../app/state/billingDetailsSlice';
import { ValueView } from '../valueViewComponent/ValueView';
import { isObject } from 'underscore';

export const header = {
  EDIT: () => {
    const dispatch = useDispatch();
    const onClickHandle = () => {
      dispatch(setIsOpenInvoiceDetailsModal(true));
    };

    return <EditButton onClick={onClickHandle} />;
  },
};

export const component = {
  ACTUAL_VALUE: (value: any, state: any, setValue?: any, ...rest: any) => {
    const { tooltip_text, vat_validation_status } = value || {};

    const textValue = (isObject(value.actual_value) ? value.actual_value?.line1 : value.actual_value) || 'N/A';

    return <ValueView value={textValue} vat_status={vat_validation_status} tooltipText={tooltip_text} />;
  },
};
