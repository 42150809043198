import styled from '@cthings.co/styled-components';
import React, { FC } from 'react';
import { Text, TextType } from '@bit/first-scope.text';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { InfoButton } from '../../../../../../../../../components/infoButton/InfoButton';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const TitleBlock = styled(Text)`
  color: ${colorFetch('gray1')} !important;
`;

interface PropertyWithTooltipProps {
  data: { name: string; tooltip_property: string };
}

export const PropertyWithTooltip: FC<PropertyWithTooltipProps> = (data) => {
  return (
    <Wrapper>
      <TitleBlock type={TextType.TEXT_14_GRAY}>{data?.data.name}</TitleBlock>
      <InfoButton onClick={() => {}} tooltipText={data?.data.tooltip_property} isInfoIcon />
    </Wrapper>
  );
};
