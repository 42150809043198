import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { Input } from '@bit/first-scope.input';
import { CheckBox } from '@bit/first-scope.controls';
import { CommonButton } from '@cthings.co/buttons';
import { ReactComponent as EmailImage } from '../../assets/Email.svg';
import { ReactComponent as PasswordImage } from '../../assets/Password.svg';
import { ReactComponent as HidedPasswordImage } from '../../assets/HidedPassword.svg';
import { ReactComponent as LogoImage } from '../../assets/NID logo.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const ExtraWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet} {
    padding: 113px 0px 201px 0px;
  }
`;

const LogoImageWrapper = styled.div`
  display: flex;

  position: absolute;

  top: 50px;
  left: 50px;

  ${media.phoneS} {
    left: 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 0px 0px 38px 0px;

  ${media.phoneS} {
    justify-content: flex-start;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0px 0px 23px 0px;
`;

const ForgotPassword = styled.span`
  color: ${colorFetch('gray2')};
  font-weight: normal;
  font-size: 12px;

  line-height: 18px;

  cursor: pointer;

  transition: color 0.3s ease;

  &:hover {
    color: ${colorFetch('gray1')};
  }
`;

const MemberWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 17px 0px 0px 0px;
`;

const MemberText = styled.span`
  color: ${colorFetch('gray2')};

  font-weight: normal;
  font-size: 12px;

  line-height: 18px;
  .join {
    color: ${colorFetch('black1')};

    font-weight: 500;

    cursor: pointer;
  }
`;

const Login = () => {
  const [inputValue, setInputValue] = useState('');
  const inputsArr = [
    { name: 'Email', placeholder: 'Email', image: EmailImage, type: 'text' },
    {
      name: 'Password',
      placeholder: 'Password',
      image: PasswordImage,
      additionalImage: HidedPasswordImage,
      type: 'password',
    },
  ];

  return (
    <ExtraWrapper>
      <LogoImageWrapper>
        <LogoImage />
      </LogoImageWrapper>

      <Wrapper>
        <TitleWrapper>
          <Text type={TextType.TITLE_H1}>Welcome Back</Text>
        </TitleWrapper>

        <FormWrapper>
          {inputsArr.map((item, index) => {
            return (
              <Input
                key={index}
                value={inputValue}
                onChange={setInputValue}
                type={item.type}
                name={item.name}
                image={item.image}
                additionalImage={item.additionalImage}
                placeholder={item.placeholder}
              />
            );
          })}
        </FormWrapper>
        <CheckboxWrapper>
          <CheckBox label="Remember me" handleToggle={() => {}} />
          <ForgotPassword onClick={() => {}}>Forgot Password?</ForgotPassword>
        </CheckboxWrapper>
        <CommonButton width="100%" height="40px" onClick={() => {}}>
          Sign In
        </CommonButton>
        <MemberWrapper>
          <MemberText>
            Not a member?{' '}
            <MemberText className="join" onClick={() => {}}>
              Join now
            </MemberText>
          </MemberText>
        </MemberWrapper>
      </Wrapper>
    </ExtraWrapper>
  );
};

export default Login;
