import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as CloseIconRaw } from '../../../../../assets/Close.svg';
import { CommonButton } from '../../../../../components/commonButton/CommonButton';
import { Text, TextType } from '@bit/first-scope.text';
import { ThemeType } from '@cthings.co/styled-components';

const CONTAINER_WIDTH = '384px';

export const StyledModal = styled(Modal)`
  z-index: 1000500;

  ${media.phone} {
    align-items: flex-end;
    & > div {
      width: 100%;
    }
  }
`;

export const StyledModalContainer = styled(ModalContainer).attrs((props: typeof ModalContainer) => ({
  ...props,
  width: CONTAINER_WIDTH,
}))`
  align-items: unset;
  padding: 24px;

  ${media.phone} {
    width: 100%;
    padding: 24px 16px 26px;
    border-radius: 12px 12px 0px 0px;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.phone} {
    flex-direction: column-reverse;
  }
`;

export const CloseIcon = styled(CloseIconRaw)`
  display: none;

  ${media.phone} {
    display: block;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCommonButton = styled(CommonButton)`
  height: 32px;
  width: 116px;

  ${media.phone} {
    height: 40px;
    width: 100%;
  }
`;

type ButtonType = typeof CommonButton & { theme: ThemeType };

export const AffirmativeButton = styled(StyledCommonButton).attrs(
  ({
    theme: {
      colors: { red1 },
    },
    ...rest
  }: ButtonType) => ({
    ...rest,
    fontSize: '14px',
    borderColor: red1,
    backgroundColor: red1,
    backgroundColorHover: red1,
  }),
)`
  margin: 0;

  ${media.phone} {
    margin: 0 0 10px 0;
  }
`;

export const NegationButton = styled(StyledCommonButton).attrs(
  ({
    theme: {
      colors: { black1, primary, pureWhite },
    },
    ...rest
  }: ButtonType) => ({
    ...rest,
    fontSize: '14px',
    color: black1,
    borderColor: primary,
    backgroundColor: pureWhite,
  }),
)`
  margin: 0 16px 0 0;

  ${media.phone} {
    margin: 0;
  }
`;

type StyledTextType = typeof Text & { theme: ThemeType };

export const DeleteText = styled(Text).attrs(({ theme: { colors: { gray1 } }, ...rest }: StyledTextType) => ({
  ...rest,
  type: TextType.TEXT_14_GRAY,
  weight: '400',
  color: gray1,
}))`
  margin: 14px 0 32px 0;

  ${media.phone} {
    margin: 24px 0 24px 0;
  }
`;
