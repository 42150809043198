import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as Graphic } from '../assets/graphic.svg';

const StyledGraph = styled(Graphic)`
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export interface ComponentProps {
  // type: PlaceholderType;
}

export const PlaceholderGraph: FC<ComponentProps> = () => {
  return <StyledGraph width="100%" height="100%" preserveAspectRatio={'none'} />;
};
