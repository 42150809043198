import { AppState } from './types';

export const initialState: AppState = {
  isAuthenticated: false,
  loaderList: [],
  initialFlag: false,
  nfcFlag: false,
  managmentSearchValue: '',
  newOrganisation: {
    name: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      region: '',
      country: '',
      zip: '',
      geotag: { lat: '0', lng: '0' },
    },
  },
  secret: '',
  transport_id: '',
};
