import { HttpService } from '../utils/http';
import { API_URL } from '../consts';
import { useEffect, useState } from 'react';
import { useApi } from '../features/apiBuilder/useApiBuilder';

const BILLING_KEYWORD = 'BILLING_KEYWORD';

export const useBillingData = () => {
  const [data, setData] = useApi({
    defaultValue: null,
    url: `${API_URL}/v2/billing/`,
    keywords: [BILLING_KEYWORD],
    transformResponse: (data) => data.payload,
  });

  const setPaymentMethodStatus = (value: boolean) => {
    setData((current: any) => ({ ...current, show_link_payment_method_button: value }));
  };

  return [data, { setPaymentMethodStatus }] as const;
};

export const useGenerateStripeAccountLink = (isAccountLinkingActive: boolean) => {
  const [stripeLink, setStripeLink] = useState('');

  useEffect(() => {
    const url = `${API_URL}/v2/billing/onboard`;

    isAccountLinkingActive &&
      (async () => {
        try {
          const responseData = (await HttpService.get(url)).data;
          setStripeLink(responseData.url);
        } catch (e) {
          console.log(e);
        }
      })();
  }, [isAccountLinkingActive]);

  return stripeLink;
};

export const usePaymentMethodKeys = (isPaymentLinkingActive: boolean) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    const url = `${API_URL}/v2/billing/payment_method`;

    isPaymentLinkingActive &&
      (async () => {
        try {
          const responseData = (await HttpService.get(url)).data.payload;

          setData(responseData);
        } catch (e) {
          console.log(e);
        }
      })();
  }, [isPaymentLinkingActive]);

  return data;
};

export const verifyPaymentMethod = (callback: () => void) => {
  const url = `${API_URL}/v2/billing/verify_payment_methods`;

  (async () => {
    try {
      await HttpService.get(url);
      callback();
    } catch (e) {
      console.log(e);
    }
  })();
};
