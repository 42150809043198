import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { CustomModal } from '../../../../../../../features/companyCreationWizard/components/customModal/CustomModal';
import { CommonButton } from '@cthings.co/buttons';
import { verifyPaymentMethod } from '../../../../../../../api/billing';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const StyledCustomModal = styled(CustomModal)`
  & > div > div {
    padding: 25px 22px;
  }
`;

const Wrapper = styled.div`
  overflow-x: hidden;
  padding: 0 3px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black')};
  margin-bottom: 18px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 24px;
  ${media.semiTablet} {
    flex-direction: column-reverse;
  }
`;

const StyledCommonButton = styled(CommonButton)`
  height: 32px;
  width: 116px;
  ${media.semiTablet} {
    width: 100%;
  }
`;

const SubmitButton = styled(StyledCommonButton)`
  margin: 0 0 0 16px;
  ${media.semiTablet} {
    margin: 0 0 10px;
  }
  & > div > div {
    ${media.tablet} {
      margin-right: 8px;
    }
  }
`;

interface SetupFormProps {
  isOpen: boolean;
  closeModal: () => void;
  setPaymentMethodStatus: (value: boolean) => void;
}

export const FormModal: React.FC<SetupFormProps> = ({ isOpen, closeModal, setPaymentMethodStatus }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);
  const { black1, pureWhite } = useTheme().colors;
  const [processingStatus, setProcessingStatus] = useState(false);

  const handleSubmit = async () => {
    if (stripe && elements) {
      setProcessingStatus(true);

      const { error } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        addNotification({
          message: error.message,
          type: 'error',
        });
        setProcessingStatus(false);
      } else {
        verifyPaymentMethod(() => {
          setPaymentMethodStatus(false);
          closeModal();
          setProcessingStatus(false);
          addNotification({
            message: languageStrings.paymentMethodHasBeenSuccessfullySaved,
            type: 'success',
          });
        });
      }
    }
  };

  return (
    <StyledCustomModal isOpen={isOpen}>
      <Wrapper>
        <Title>{languageStrings.linkPaymentMethod}</Title>
        <PaymentElement />
        <ButtonsWrapper>
          <StyledCommonButton
            colors={{
              main: black1,
              background: pureWhite,
            }}
            disabled={processingStatus}
            onClick={closeModal}
          >
            {languageStrings.cancelButton}
          </StyledCommonButton>
          <SubmitButton
            isLoadingInProcess={processingStatus}
            disabled={!stripe || !elements || processingStatus}
            onClick={handleSubmit}
          >
            {languageStrings.submit}
          </SubmitButton>
        </ButtonsWrapper>
      </Wrapper>
    </StyledCustomModal>
  );
};
