import React, { FC } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { ItemsList } from './components/ItemsList';
import { useNotifierFunctions } from '../../../../features/notifier2';
import { finaliseDevicesAdding } from '../../../../api/organisation';
import { ButtonRow, EditButton, SubtitleRow, CancelButton, SaveButton, StyledCloseIcon, TitleRow } from './styled';
import { CustomModal } from '../../../../features/companyCreationWizard/components/customModal/CustomModal';
import { pushAction, selectSelectedDevices, endCompanyCreation } from '../../../../app/state/organisations';
import { useParams } from '@cthings.co/router-dom';
import { useTheme } from '@cthings.co/styled-components';

interface SummaryProps {
  isOpen: boolean;
}

export const SummaryModal: FC<SummaryProps> = ({ isOpen }) => {
  const devices = useSelector(selectSelectedDevices);
  const params = useParams();
  const theme = useTheme();
  const { black1, gray2, gray1 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  const { addNotification } = useNotifierFunctions();

  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  const successText = languageStrings.successSaveMessage;
  const somethingWentWrongtext = languageStrings.somethingWentWrong;

  const handleCallbackNotification = (isSuccess: boolean, messageText?: string) => {
    return addNotification({
      message: messageText || (isSuccess ? successText : somethingWentWrongtext),
      type: isSuccess ? 'success' : 'error',
    });
  };

  const handleFinalise = () => {
    const devicesPrep = devices.map(({ type, transport_id, plansets_info, id }) => ({
      type,
      transport_id,
      id,
      planset_id: plansets_info.device_planset.id,
    }));
    finaliseDevicesAdding({ client: params.id, devices: devicesPrep }, handleCallbackNotification);
  };

  const handleEditDevices = () => {
    dispatch(pushAction('editDevices'));
  };

  return (
    <CustomModal isOpen={isOpen}>
      <TitleRow>
        <Text type={TextType.TEXT_14_BLACK} color={black1} fontSize={'18px'} weight={'600'}>
          {languageStrings.summaryTitle}
        </Text>
        <StyledCloseIcon onClick={() => dispatch(endCompanyCreation())} />
      </TitleRow>
      <SubtitleRow>
        <Text type={TextType.TEXT_14_BLACK} color={gray1}>
          {languageStrings.summaryDevicesRowTitle}: {devices.length}
        </Text>
        <EditButton type={TextType.TEXT_14_BLACK} color={gray2} onClick={handleEditDevices}>
          {languageStrings.insightDetailsDeviceConfigHeaderEdit}
        </EditButton>
      </SubtitleRow>
      <ItemsList parameterList={devices} languageStrings={languageStrings} />
      <ButtonRow>
        <CancelButton onClick={handleBack}>{languageStrings.backButton}</CancelButton>
        <SaveButton onClick={handleFinalise}>{languageStrings.finalizeButton}</SaveButton>
      </ButtonRow>
    </CustomModal>
  );
};
