import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from '@cthings.co/router-dom';
import { useDevices } from '../../../../../api/devices/devices';
import { DeviceListMap, DeviceListMapType } from '../../../../../components/deviceListMap/DeviceListMap';
import { MAPBOX_TOKEN } from '../../../../../consts';
import { selectLanguage } from '../../../../../app/state/user';
import { isEmpty } from 'underscore';
import { injectPathParams, useHistory } from '@cthings.co/utils';
import { PATHS } from '../../../../../routes/paths';

export const DeviceControl = ({ boundingRect, selectedPos }: { boundingRect: any; selectedPos: any }) => {
  const keywordList = ['InsightMap'];
  const history = useHistory();
  const params = useParams();
  const { state }: any = useLocation();
  const [zoom, setZoom] = useState(0);
  const [centre, setCentre] = useState<any>(
    params?.lat && params?.lon
      ? { lat: parseFloat(params?.lat), lon: parseFloat(params?.lon) }
      : { lat: 52.25603, lon: 20.98765 },
  );
  const [isInfoManholeId, setisInfoManholeId] = useState('');

  const lang = useSelector(selectLanguage);

  const [devices] = useDevices(boundingRect, selectedPos || { center: [0, 0], radius: -1 }, keywordList, lang.id);

  const deviceList = devices.geopivoted;
  const centreLat = devices.centre_coord.lat;
  const centreLon = devices.centre_coord.lng;
  const localZoom = devices?.zoom_level_mapbox;

  useEffect(() => {
    if (isEmpty(state)) {
      if (centreLat && centreLon) {
        setCentre({ lat: parseFloat(centreLat), lon: parseFloat(centreLon) });
        const to = injectPathParams(PATHS.USER_INSIGHTS, {
          lat: parseFloat(centreLat),
          lon: parseFloat(centreLon),
          zoom: localZoom || zoom,
        });
        history.replace(to);
        setZoom(localZoom || zoom);
      } else {
        setCentre({ lat: parseFloat(centre?.lat), lon: parseFloat(centre?.lon) });
        setZoom(parseFloat(params?.zoom || '0'));
      }
    }
  }, [centreLat, centreLon]);

  useEffect(() => {
    if (state && !isEmpty(state)) {
      const { address, id } = state;
      setZoom(state?.zoom);
      setCentre({ lat: parseFloat(address?.geotag.lat), lon: parseFloat(address?.geotag.lng) });
      setisInfoManholeId(id);
    }
  }, [state]);

  const handleSetCentre = (centre: any) => {
    setCentre({ lat: parseFloat(centre.lat), lon: parseFloat(centre.lng) });
  };

  return (
    <DeviceListMap
      placeholderProps={{
        keyword: 'InsightMap',
        style: { width: '100%', height: '100%' },
        conditionalStyle: { overflow: 'hidden', borderRadius: '8px' },
      }}
      type={DeviceListMapType.INSIGHT}
      zoom={zoom !== -1 ? zoom : devices.zoom_level_mapbox > 0 ? devices.zoom_level_mapbox - 1 : 0}
      latitude={centre.lat}
      longitude={centre.lon}
      centerCoords={centre}
      setCentre={handleSetCentre}
      devices={deviceList ? deviceList : []}
      mapToken={MAPBOX_TOKEN}
      dataLocationCluster={state?.address}
      isInfoManholeId={isInfoManholeId}
      setisInfoManholeId={setisInfoManholeId}
    />
  );
};
