import { useEffect, useState } from 'react';

import { API_URL } from '../consts';
import { generateApi, useApi } from '../features/apiBuilder/useApiBuilder';
import { ErrorEventType } from '../types/error';
import { ApiCallType, HttpService } from '../utils/http';
import { useLoaderFunctions } from '../features/placeholderComponent/loader.context';
import { isNull } from 'underscore';

type IncidentParameters = {
  incident_types: any[];
  incident_types_list: { name: string; id: string }[];
  severities: any[];
  devices: any[];
};

const incidentTypesKeywords = ['INCIDENT_TYPES'];

export const useGetIncidentParameters = () => {
  const url = `${API_URL}/v2/incidents/add/listall/`;

  const [data, setData] = useApi({
    url,
    defaultValue: { incident_types: [], incident_types_list: [], severities: [], devices: [] } as IncidentParameters,
    keywords: incidentTypesKeywords,
    transformResponse: (response) => ({
      ...response.payload,
      incident_types_list: response.payload.incident_types.map((item: any) => ({ name: item.name, id: item.id })),
    }),
  });

  return [data, setData];
};

export const useGetIncident = (id: string, setIsLoadingInProcess: any) => {
  const [localData, setLocalData] = useState<any>([]);

  useEffect(() => {
    const url = `${API_URL}/v2/incidents/${id}`;
    if (id?.length > 0) {
      setIsLoadingInProcess(true);
      (async () => {
        try {
          const responseData = (await HttpService.get(url)).data.payload;
          setLocalData(responseData);
          setIsLoadingInProcess(false);
        } catch (e) {
          setIsLoadingInProcess(false);
        }
      })();
    }
  }, [id]);

  return localData;
};

export const addCommentToIncident = (
  id: string,
  comment: { ts: string; text: string; user_id: string },
  addNotification: (notification?: { message: string; type: string }) => void,
  revertData: () => void,
  languageStrings?: any,
) => {
  const url = `${API_URL}/v2/incidents/${id}/comment`;

  (async () => {
    try {
      await HttpService.patch(url, { comment });
      addNotification({
        message: languageStrings.successfullSavingMessage,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      addNotification({
        message: `${error?.response?.data?.detail}`,
        type: 'error',
      });
      revertData();
    }
  })();
};

export const addIncident = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  toggleUpdaiting: any,
  addNotification: (notification?: { message: string; type: string }) => void,
  userId: string,
  setIsSaveButtonDisabled: (status: boolean) => void,
  languageStrings?: any,
) => {
  setIsSaveButtonDisabled(true);
  const url = `${API_URL}/v2/incidents`;
  const firstPart = {
    device_id: data.device.id,
    incident_type_id: data.incident_type.id,
    severity: [{ ts: data.ts_start, text: data.severity.id, user_id: userId }],
    ts_start: data.ts_start,
  };
  const preparedData =
    data.comments.length > 0
      ? { ...firstPart, comments: [{ ts: data.ts_start, text: data.comments, user_id: userId }] }
      : firstPart;

  (async () => {
    try {
      let responseData = (await HttpService.post(url, preparedData)).data;
      updateLocalItem(responseData.payload.id, responseData.payload);
      addNotification({
        message: languageStrings.successfullSavingMessage,
        type: 'success',
      });
      setIsSaveButtonDisabled(false);
    } catch (e) {
      const error = e as ErrorEventType;
      addNotification({
        message: `${error?.response?.data?.detail}`,
        type: 'error',
      });
      setIsSaveButtonDisabled(false);
    }
    toggleUpdaiting(false);
  })();
};

export const resolveIncident = (
  id: string,
  comment: { text: string } | null,
  setLocalIncident: Function,
  addNotification: (notification?: { message: string; type: string }) => void,
  languageStrings?: any,
) => {
  const url = `${API_URL}/v2/incidents/${id}/resolve`;

  (async () => {
    try {
      let responseData = (await HttpService.patch(url, { id, comment })).data;
      setLocalIncident(responseData.payload);
      addNotification({
        message: languageStrings.resolvedSuccessfullyMessage,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      addNotification({
        message: `${error?.response?.data?.detail}`,
        type: 'error',
      });
    }
  })();
};

export const createIncidentTypesFunctions = () => {
  const createIncidentType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { name: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const url = `${API_URL}/v2/incident_types/`;
    return generateApi({ url, callType: ApiCallType.POST })({ handleSuccess, handleError, body });
  };

  const updateIncidentType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { id: string; name: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const { id, ...remainingBody } = body;
    const url = `${API_URL}/v2/incident_types/${id}`;
    return generateApi({ url: url, callType: ApiCallType.PATCH })({ handleSuccess, handleError, body: remainingBody });
  };

  const deleteIncidentType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { id: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const { id } = body;
    const url = `${API_URL}/v2/incident_types/${id}`;
    return generateApi({ url, callType: ApiCallType.DELETE })({ handleSuccess, handleError });
  };

  return { createIncidentType, updateIncidentType, deleteIncidentType };
};

const incidentOffsetKeywords = ['INCIDENT_OFFSET'];

export const useIncidentsOffset = ({
  id,
  itemsPerPage,
  keyword,
  offset: tableOffset,
}: {
  id: string;
  itemsPerPage: number;
  keyword: string;
  offset: string;
}) => {
  if (itemsPerPage === 0) {
    throw new Error('Zero incidents per page are not allowed');
  }

  const [isOffsetLoaded, setIsOffsetLoaded] = useState(!id);

  const { appendKeywordList } = useLoaderFunctions();

  const url = `${API_URL}/v2/incidents/${id}/offset`;

  const [{ offset }] = useApi({
    url,
    condition: !!id && !isOffsetLoaded,
    defaultValue: { offset: null },
    keywords: incidentOffsetKeywords,
    transformResponse: (data) => data.payload,
    handleSuccess: () => {
      setIsOffsetLoaded(true);
    },
  });

  useEffect(() => {
    appendKeywordList([keyword], true);
  }, []);

  const flooredOffset = !isNull(offset) ? `${Math.floor(offset / itemsPerPage) * itemsPerPage}` : null;

  return { offset: tableOffset || flooredOffset || '0', isOffsetReady: !id || flooredOffset };
};
