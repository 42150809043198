import React from 'react';
import { AdminMain } from '../containers/admin';
import {
  BillingDetails,
  Dashboard,
  DeviceDetails,
  Devices,
  Documents,
  Download,
  FlowMeasurement,
  Incidents,
  Insights,
  InsightsDetails,
  Invoices,
  MeasurementDetails,
  MeasurementInsights,
  OrganisationDetails,
  Organisations,
  ProvisioningAdditionalDevice,
  ProvisioningCompleted,
  ProvisioningDevice,
  ProvisioningLobby,
  ProvisioningSubscription,
  Reports,
  Users,
} from '../containers/common';
import { ErrorPage, ErrorPageVersion } from '../containers/errorPage/ErrorPage';
import { TestContainer } from '../containers/testContainer/TestContainer';
import { UserMain } from '../containers/user';
import { Admin, Errors, ManagementMobile, Provisioning, User } from '../layouts';
import { random404 } from '../utils/errors/random404';
import UserSwitcher from './UserSwitcher';
import { PATHS } from './paths';

export const routes = [
  {
    path: PATHS.USER,
    render: ({ ...props }: any) => <User {...props} />,
    routes: [
      {
        path: PATHS.USER_TEST,
        component: <TestContainer />,
      },
      {
        path: PATHS.USER_MAIN,
        component: <UserMain />,
      },
      {
        path: PATHS.USER_INSIGHTS_DETAILS,
        component: <InsightsDetails />,
      },
      {
        path: PATHS.USER_INSIGHTS,
        component: <Insights />,
      },
      {
        path: PATHS.USER_INCIDENTS,
        component: <Incidents />,
      },
      {
        path: PATHS.USER_REPORTS,
        component: <Reports />,
      },
      {
        path: PATHS.USER_DOCUMENTS,
        component: <Documents />,
      },
      {
        path: PATHS.DOWNLOAD,
        component: <Download />,
      },
      {
        path: PATHS.USER_MANAGE_DEVICES_DETAILS,
        component: <DeviceDetails />,
      },
      { path: PATHS.USER_DASHBOARD, component: <Dashboard /> },
      { path: PATHS.USER_MANAGE_CLIENTS, component: <Organisations /> },
      { path: PATHS.USER_MANAGE_DEVICES, component: <Devices /> },
      { path: PATHS.USER_MANAGE_USERS, component: <Users /> },
    ],
  },
  {
    path: PATHS.MANAGE_ANALYTICS,
    render: ({ ...props }: any) => <ManagementMobile {...props} />,
    routes: [
      { path: PATHS.USER_PAIR_DEVICE_INSIGHTS, component: <MeasurementInsights /> },
      {
        path: PATHS.USER_PAIR_DEVICE_DETAILS,
        component: <MeasurementDetails />,
      },
      {
        path: PATHS.USER_MEASUREMENT_DEVICE_INSIGHTS,
        component: <MeasurementInsights />,
      },
      {
        path: PATHS.USER_MEASUREMENT_DEVICE_DETAILS,
        component: <MeasurementDetails />,
      },
      { path: PATHS.MANAGE_MEASUREMENT, component: <FlowMeasurement /> },
    ],
  },
  {
    path: PATHS.MANAGE,
    render: ({ ...props }: any) => <ManagementMobile {...props} />,
    routes: [
      { path: PATHS.MANAGE_ORGANISATIONS, component: <Organisations /> },
      {
        path: PATHS.MANAGE_ORGANISATIONS_DETAILS,
        component: <OrganisationDetails />,
      },
      { path: PATHS.MANAGE_DEVICES, component: <Devices /> },
      { path: PATHS.MANAGE_USERS, component: <Users /> },
    ],
  },
  {
    path: PATHS.BILLING,
    render: ({ ...props }: any) => <User {...props} />,
    routes: [
      // { path: PATHS.BILLING_PLANS, component: <Plans /> },
      { path: PATHS.BILLING_INVOICES, component: <Invoices /> },
      { path: PATHS.BILLING_DETAILS, component: <BillingDetails /> },
    ],
  },
  {
    path: PATHS.ADMIN,
    render: ({ ...props }: any) => <Admin {...props} />,
    routes: [
      {
        path: PATHS.ADMIN_MAIN,
        component: <AdminMain />,
      },
    ],
  },
  {
    path: PATHS.PROVISIONING_LOBBY,
    component: <ProvisioningLobby />,
  },
  {
    path: PATHS.PROVISIONING,
    render: ({ ...props }: any) => <Provisioning {...props} />,
    routes: [
      {
        path: PATHS.PROVISIONING_ADDITIONAL_DEVICE,
        component: <ProvisioningAdditionalDevice />,
      },
      { path: PATHS.PROVISIONING_DEVICE, component: <ProvisioningDevice /> },
      { path: PATHS.PROVISIONING_SUBSCRIPTION, component: <ProvisioningSubscription /> },
      { path: PATHS.PROVISIONING_COMPLETED, component: <ProvisioningCompleted /> },
    ],
  },
  {
    path: PATHS.DOWNLOAD,
    component: <Download />,
  },
  {
    path: PATHS.ERROR,
    render: ({ ...props }: any) => <Errors {...props} />,
    routes: [
      {
        path: PATHS.ERROR_403,
        component: <ErrorPage version={ErrorPageVersion.FIFTH_VERSION_403} />,
      },
      { path: PATHS.ERROR_500, component: <ErrorPage version={ErrorPageVersion.FIFTH_VERSION_500} /> },
      { path: PATHS.ERROR_404, component: <ErrorPage version={random404()} /> },
      { path: PATHS.ERROR_401, component: <ErrorPage version={ErrorPageVersion.FIFTH_VERSION_401} /> },
    ],
  },
  {
    path: PATHS.REDIRECT,
    component: <UserSwitcher />,
  },
];
