import { useEffect, useState } from 'react';
import { useObjectPos } from '../../../api/objectPosition';
import { Address } from '../../../types/address';
import { useBlockBodyScroll } from '../../../utils/useBlockBodyScroll';
import { CentreState } from '../components/locationPopup/types';
import { EMPTY_ADDRESS } from '../data';

type LocalChangesProps = {
  address: Address;
  localAddress: Address;
  externalState: boolean;
  saveToggle: boolean;
  isInUniversalTable: boolean;
};

export const useLocalChanges = ({
  localAddress,
  address,
  externalState,
  saveToggle,
  isInUniversalTable,
}: LocalChangesProps) => {
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [search, setSearch] = useState('');
  const [localSearch, setLocalSearch] = useState('');
  const [selectedPos, setSelectedPos] = useState<any>(null);
  const [savedAddress, setSavedAddress] = useState<Address>(address);
  const [validatingIsStarted, setValidatingIsStarted] = useState(false);

  const { geotag } = localAddress;
  const centreLat = selectedPos ? selectedPos.center[1] : (geotag && geotag.lat) || null;
  const centreLon = selectedPos ? selectedPos.center[0] : (geotag && geotag.lng) || null;

  const [searchCentre, setSearchCentre] = useState<CentreState>({
    lat: parseFloat(centreLat),
    lon: parseFloat(centreLon),
  });

  const isOpenPopupGlobal = externalState ? externalState : isOpenPopup;
  const isAddressEmpty = address.line1.length === 0 && address.city.length === 0;

  const [position] = useObjectPos(search);

  useBlockBodyScroll(isOpenPopupGlobal);

  useEffect(() => {
    setSearch('');
    setSelectedPos(null);
    setSearchCentre({ lat: parseFloat(geotag.lat), lon: parseFloat(geotag.lng) });
    setLocalSearch('');
    setSavedAddress(isAddressEmpty ? EMPTY_ADDRESS : localAddress);
  }, [saveToggle]);

  useEffect(() => {
    setSearch('');
    setSelectedPos(null);
    setSearchCentre({ lat: parseFloat(geotag.lat), lon: parseFloat(geotag.lng) });
    setLocalSearch('');
    !isInUniversalTable && setSavedAddress(isAddressEmpty ? EMPTY_ADDRESS : localAddress);
  }, [confirmToggle]);

  return [
    {
      confirmToggle,
      isOpenPopup,
      localSearch,
      selectedPos,
      validatingIsStarted,
      searchCentre,
      isAddressEmpty,
      centreLat,
      centreLon,
      savedAddress,
      isOpenPopupGlobal,
      position,
    },
    {
      setConfirmToggle,
      setIsOpenPopup,
      setSearch,
      setLocalSearch,
      setSelectedPos,
      setValidatingIsStarted,
      setSearchCentre,
      setSavedAddress,
    },
  ] as const;
};
