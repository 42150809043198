import { SimpleIcon, SimpleIconType, SizeType } from '@cthings.co/base';

import styled, { useTheme } from '@cthings.co/styled-components';
import Tooltip, { TooltipPlace } from '../../../tooltip';

import { FC } from 'react';
import { ThresholdData, TooltipWrapperProps } from './types';
import { thresholdColorMapping } from './utils';
import { isUndefined } from 'underscore';

const TooltipWrapper = styled.div<TooltipWrapperProps>`
  top: ${({ top }) => `${top}px`};
  right: ${({ right }) => `${right}px`};

  position: absolute;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledSimpleIcon = styled(SimpleIcon)`
  pointer-events: auto;
`;

export type ThreshlodIconTooltipMappingType = {
  thresholdData: ThresholdData[];
  areaSize: { height: number; width: number };
  limits: { x: { min?: number; max?: number }; y: { min?: number; max?: number } };
  tooltipIds: string[];
};

export const ThreshlodIconTooltipMapping: FC<ThreshlodIconTooltipMappingType> = ({
  thresholdData,
  areaSize,
  limits,
  tooltipIds,
}) => {
  const theme = useTheme();
  const { red2 } = theme.colors;

  const defaultStyle = {
    stroke: red2,
    strokeDasharray: '3 3',
    opacity: 0.6,
    strokeWidth: 1,
  };

  return (
    <>
      {thresholdData.map(({ threshold, color, style, tooltipText }, index) => {
        const { stroke } = style || thresholdColorMapping({ color, theme }) || defaultStyle;

        // thresholdOffsetFromMaxLimit - offset from max limit to threshold
        const thresholdOffsetFromMaxLimit = isUndefined(limits.y.max) ? 0 : limits.y.max - threshold;
        // minLimitOffsetFromThreshold - offset from threshold to min limit
        const minLimitOffsetFromThreshold = isUndefined(limits.y.min) ? 0 : threshold - limits.y.min;

        // definedLimitArea - calculated graph area defined by limits
        const definedLimitArea =
          isUndefined(limits.y.max) || isUndefined(limits.y.min) ? 0 : limits.y.max - limits.y.min;

        // definedArea - graph area height interpreted by limits (min and max limits, where graph will be shown)
        const definedArea = areaSize.height / definedLimitArea;

        // heightOffset - definedArea multiplied by offset plus 52px for top offset
        const heightOffset = definedArea * thresholdOffsetFromMaxLimit + 52;

        const isOnTheGraph = thresholdOffsetFromMaxLimit >= 0 && minLimitOffsetFromThreshold >= 0;

        return (
          tooltipText &&
          isOnTheGraph && (
            <TooltipWrapper top={heightOffset} right={28}>
              <Tooltip place={TooltipPlace.TOP} id={tooltipIds[index]} text={tooltipText}>
                <StyledSimpleIcon iconSize={SizeType.SMALL} colors={{ main: stroke }} iconType={SimpleIconType.INFO} />
              </Tooltip>
            </TooltipWrapper>
          )
        );
      })}
    </>
  );
};
