import { LanguageStrings } from '../../../../languages/types';
import { InputStructure } from './types';
import { FieldsType } from '../../../../utils/validation';

export const getInputStructure = (languageStrings: LanguageStrings) =>
  [
    {
      label: languageStrings.companyName,
      placeholder: languageStrings.companyNamePlaceholder,
      fieldName: 'name',
    },
    {
      label: languageStrings.companyPhone,
      placeholder: languageStrings.companyPhonePlaceholder,
      fieldName: 'phone',
      tooltip: languageStrings.companyPhoneTooltip,
      validationRule: FieldsType.NUMBER,
    },
    {
      label: languageStrings.usersParentCustomerLabel,
      placeholder: languageStrings.usersParentOrganisationPlacholder,
      fieldName: 'parentOrganisation',
      validationRule: FieldsType.SELECT,
    },
    {
      label: languageStrings.profileLabel,
      placeholder: languageStrings.profilePlaceholder,
      fieldName: 'profile',
      validationRule: FieldsType.SELECT,
    },
    {
      label: languageStrings.companyCreationEmailAddress,
      placeholder: languageStrings.emailAddressPlaceholder,
      fieldName: 'email',
      tooltip: languageStrings.emailAddressTooltip,
      validationRule: FieldsType.EMAIL,
      flex: '2',
    },
    {
      label: languageStrings.companyAddress,
      placeholder: languageStrings.companyAddressPlaceholder,
      fieldName: 'address',
      validationRule: FieldsType.ADDRESS,
      flex: '2',
    },
  ] as InputStructure[];
