import React from 'react';

export const OutletImage = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.36121 13.625H11.5468M11.5468 4.89644H3.36121M10.7392 4.89644C10.7392 3.28934 9.5004 2.14062 7.454 2.14062C5.40761 2.14062 4.19614 3.30025 4.19614 4.89644M10.7392 4.89644H4.19614M10.7392 4.89644C10.7392 8.9483 10.7392 13.625 10.7392 13.625V4.89644ZM4.19614 4.89644V13.625M4.19614 7.64134H2.57812V10.8774H4.19341L4.19614 7.64134ZM10.7392 10.8774H12.3544V7.64134H10.7392V10.8774Z"
        stroke="#555770"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
