import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { useContentForErrorPages } from '../useContentForErrorPages';

export enum TypeOfErrorFirst {
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
}

const Wrapper = styled.div`
  display: flex;
  ${media.semiTablet} {
    flex-direction: column;
    padding: 0 15px;

    & > svg {
      width: 100%;
    }
  }
`;

type ContainerProps = {
  type: TypeOfErrorFirst;
};

const Container = styled.div<ContainerProps>`
  width: 340px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: ${({ type }) => (type === TypeOfErrorFirst.ERROR_500 ? '44px' : '90px')};
  right: ${({ type }) => (type === TypeOfErrorFirst.ERROR_500 ? '18px' : '38px')};
  & > .title {
    font-size: 49px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 16px;
  }

  & > span {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  ${media.semiTablet} {
    right: 0px;
    top: 40px;
  }
`;

const ButtonWrapper = styled.div`
  width: 325px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const StyledButton = styled(CommonButton)`
  min-width: 157px;
  width: auto;
`;

export const FirstVersionErrorPage = ({ type }: { type: TypeOfErrorFirst }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const { firstVersionErrorPage500, firstVersionErrorPage404 } = useContentForErrorPages();

  const getErrorPageValue = (type: TypeOfErrorFirst) => {
    const value = {
      [TypeOfErrorFirst.ERROR_500]: firstVersionErrorPage500(),
      [TypeOfErrorFirst.ERROR_404]: firstVersionErrorPage404(),
    };
    return value[type];
  };

  const selectedValue: any = getErrorPageValue(type);

  return (
    <Wrapper>
      {selectedValue.image}
      <Container type={type}>
        <Text className="title" type={TextType.TITLE_H1}>
          {selectedValue.title}
        </Text>
        <Text type={TextType.SEMITITLE} color={black1}>
          {selectedValue.description}
        </Text>
        <ButtonWrapper>
          {selectedValue.buttons.map((item: any, index: number) => {
            const { onClick, backgroundColor, color, value } = item;
            return (
              <StyledButton
                key={index}
                onClick={onClick}
                height={'40px'}
                colors={{
                  main: color,
                  background: backgroundColor,
                }}
              >
                {value}
              </StyledButton>
            );
          })}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};
