import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { useSelector } from 'react-redux';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import { selectLanguageStrings } from '../../app/state/user';
import { InfoCardModal } from './components/InfoCardModal';
import { Modal } from '../modal/Modal';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  min-width: 280px;
  max-width: 280px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('orange2')};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow2};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  overflow: hidden;
  padding: 14px 20px;
  ${media.desktop} {
    order: -2;
    max-width: 100%;
  }
  ${media.phone} {
    margin: 16px 0 0 0;
  }
`;

const StyledModal = styled(Modal)`
  ${media.semiTablet} {
    align-items: flex-end;
    & > div {
      width: 100%;
      align-items: flex-end;
    }
  }
`;

const Title = styled.span`
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: ${colorFetch('orange2')};
  text-align: left;
`;

const HeadingBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MoreButton = styled.span`
  position: relative;
  top: 5px;
  height: max-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: right;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1_5')};
  cursor: pointer;
  &:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    content: '';
    height: 0.8px;
    width: 100%;
    background-color: ${colorFetch('gray1_5')};
  }
`;

const MainInfo = styled.span`
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1')};
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
`;

const StyledModalContainer = styled(ModalContainer)`
  border-radius: 12px;
  padding: 24px;
  ${media.semiTablet} {
    padding: 24px 15px;
  }
  ${media.phone} {
    border-radius: 12px 12px 0px 0px;
  }
`;

export interface InfoCardProps {
  title: string;
  data: any;
}

export const InfoCard: React.FC<InfoCardProps> = ({ title, data, ...props }) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();

  const openModal = () => {
    setShowMoreInfo(true);
  };

  const closeModal = () => {
    setShowMoreInfo(false);
  };

  useBlockBodyScroll(showMoreInfo);

  return (
    <Wrapper theme={theme}>
      <HeadingBlock>
        <Title>{title ?? 'N/A'}</Title>
        <MoreButton onClick={openModal}>{languageStrings.commonButtonMore}</MoreButton>
      </HeadingBlock>
      <MainInfo>{data[0].desc}</MainInfo>
      <StyledModal isOpenModal={showMoreInfo}>
        <StyledModalContainer>
          <InfoCardModal title={title} data={data} showMoreInfo={showMoreInfo} closeModal={closeModal} />
        </StyledModalContainer>
      </StyledModal>
    </Wrapper>
  );
};
