import { ButtonIconType, CommonButton } from '@cthings.co/buttons';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectDeviceInfo } from '../../../../../../../app/state/insight';
import { ManagementAccess, selectLanguageStrings } from '../../../../../../../app/state/user';
import { ReactComponent as BellOffIcon } from '../../../../../../../assets/Bell-off.svg';
import { ReactComponent as BellIcon } from '../../../../../../../assets/Bell.svg';
import { View, getPath } from '../../../../../../../routes';
import { media } from '@cthings.co/styles-utils';
import { useMediaType } from '@cthings.co/styles-utils';
import { useCustomHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { LocationButton } from './components/locationButton/LocationButton';
import { NameOfDevice } from './components/nameOfDevice/NameOfDevice';
import { SubscribeButton } from './components/subscribeButton/SubscribeButton';
import { injectPathParams } from '@cthings.co/utils';
import { stringifyUrl } from 'query-string';

const Wrapper = styled.div``;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > span {
    display: none;
    ${media.tablet} {
      display: block;
      font-size: 20px;
    }
  }
  ${media.tablet} {
    justify-content: space-between;
  }
  ${media.phone} {
    margin-bottom: 25px;
  }
`;

export const TitleBlock = ({
  id,
  deviceName,
  params,
  accessData,
  isLoading,
  isShowSubscribeButton,
  isSubscribed = true,
  setDeviceName,
  onSubscribeBtnPress,
  changeTitleTextarea,
}: {
  id: string;
  deviceName: string;
  params: any;
  accessData: ManagementAccess;
  isLoading: boolean;
  isShowSubscribeButton?: boolean;
  isSubscribed: boolean;
  setDeviceName: (value: string) => void;
  onSubscribeBtnPress: () => void;
  changeTitleTextarea: (parameterId: string, value: string) => void;
}) => {
  const customHistory = useCustomHistory();
  const deviceInfoData = useSelector(selectDeviceInfo);
  const { type } = deviceInfoData;

  const languageStrings = useSelector(selectLanguageStrings);
  const { desktop, phone } = useMediaType();

  const subscribeBtnIcon = isLoading ? null : isSubscribed ? <BellOffIcon /> : <BellIcon />;

  const subscribeBtnText = desktop
    ? ''
    : isSubscribed
    ? languageStrings.unsubcribeButton
    : languageStrings.subcribeButton;

  const subscribeBtnText2 = isSubscribed ? languageStrings.unsubcribeButton : languageStrings.subcribeButton;

  const locationBtnText = desktop ? '' : languageStrings.deviceDetailsGoToInsightDetails;

  const handleOnChangeField = (value: string) => {
    changeTitleTextarea(params ? (params[0] as any).id : '', value);
    setDeviceName(value);
  };

  const to = View.USER_INSIGHTS_DETAILS;

  const href = stringifyUrl({
    url: injectPathParams(getPath(to), { id, type, offsetEvents: 0, offsetMaintenance: 0 }),
  });

  return (
    <Wrapper>
      <Container>
        <CommonButton
          width="32px"
          iconType={ButtonIconType.ARROW_LEFT}
          iconWithoutText
          onClick={() => customHistory.goBack()}
        />
        <NameOfDevice name={deviceName} handleOnChangeField={handleOnChangeField} accessData={accessData} />
        {!!type && (
          <>
            {isShowSubscribeButton && !phone && (
              <SubscribeButton
                onClick={onSubscribeBtnPress}
                isSubscribed={isSubscribed}
                isLoading={isLoading}
                margin={'0 0 0 40px'}
              >
                {subscribeBtnIcon}
                {subscribeBtnText}
              </SubscribeButton>
            )}
            <LocationButton href={href}>{locationBtnText}</LocationButton>
          </>
        )}
      </Container>
      {isShowSubscribeButton && phone && (
        <SubscribeButton onClick={onSubscribeBtnPress} isSubscribed={isSubscribed} isLoading={isLoading}>
          {subscribeBtnIcon}
          {subscribeBtnText2}
        </SubscribeButton>
      )}
    </Wrapper>
  );
};
