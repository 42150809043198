import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { today } from './consts';

export const initialState: any = {
  realTimeData: {
    info: {
      address: {
        line1: '',
        line2: '',
        city: '',
        region: '',
        country: '',
        zip: '',
        geotag: { lat: '0', lng: '0' },
      },
      name: '',
    },
    data: {
      rt: {
        temp: { measurement: '', unit: '' },
        water_level: { measurement: '', unit: '' },
      },
      config: [],
    },
  },
  comments: [],
  history: {
    water_level: {
      sensor: '',
      data: [],
      unit: '',
      axis_limits: { x: null, y: null },
      filters: {
        periods: {
          default_value: { name: '', id: '' },
          items_list: [{ name: '', id: '' }],
        },
        intervals: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
        averaging_methods: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
      },
      ts_from: getRequiredDateFormat(new Date(today.setDate(today.getDate() - 1)), 'YYYY-MM-DDTHH:mm:ssZ'),
      ts_to: getRequiredDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ssZ'),
      isLoadingInProcess: false,
    },
    temperature: {
      sensor: '',
      data: [],
      unit: '',
      axis_limits: { x: null, y: null },
      filters: {
        periods: {
          default_value: { name: '', id: '' },
          items_list: [{ name: '', id: '' }],
        },
        intervals: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
        averaging_methods: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
      },
      ts_from: '',
      ts_to: '',
      isLoadingInProcess: false,
    },
  },
  devices: {
    geopivoted: [],
    centre_coord: {
      lat: '',
      lng: '',
    },
    zoom_level_mapbox: -1,
  },
  events: {
    list: [],
    pagination: {
      total_items: 0,
      total_pages: 1,
    },
  },
  events_graph: {
    data: [],
    axis_labels: {
      x: 'Date',
      y: 'Time',
      title: 'Events over time',
    },
    axis_limits: {
      x: null,
      y: {
        min: 0,
        max: 0,
      },
    },
    filters: {
      periods: {
        default_value: { id: '', key: null, tooltip: '' },
        items_list: [{ name: '', id: '' }],
      },
      intervals: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
    },
    ts: {
      ts_from: null,
      ts_to: null,
    },
    isLoadingInProcess: false,
  },
};
