import { API_URL } from '../consts';
import { LsValueType } from '../enums/LsValueType';
import ss from '../utils/ss';

export const getDocumentsDownloadUrl = (filename: string) => {
  return `${API_URL}/v2/static/docs/${filename}?access_token=${ss.get(LsValueType.token)}`;
};

// is needed for organisation Export button
export const getOrganisationsDownloadUrl = (filename: string) => {
  //@TODO its fake example url, needs to be changed
  return `${API_URL}/v2/static/docs/${filename}?access_token=${ss.get(LsValueType.token)}`;
};
