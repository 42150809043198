import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';
import { useContentForErrorPages } from '../useContentForErrorPages';
import { media } from '@cthings.co/styles-utils';

export enum TypeOfError {
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
  ERROR_403 = 'ERROR_403',
  ERROR_401 = 'ERROR_401',
}

const Wrapper = styled.div`
  width: 472px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    margin: 38px 0 18px 0;
  }

  & > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 40px;
  }

  ${media.semiTablet} {
    width: 330px;
    padding: 0 15px;

    & > svg {
      width: 100%;
    }
  }
`;

type ButtonWrapperProps = {
  type: TypeOfError;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: 378px;
  display: flex;
  justify-content: ${({ type }) =>
    type === TypeOfError.ERROR_404 || type === TypeOfError.ERROR_401 ? 'center' : 'space-between'};
`;

const StyledButton = styled(CommonButton)`
  min-width: 181px;
  width: auto;
`;

export const FifthVersionErrorPage = ({ type }: { type: TypeOfError }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const {
    fifthVersionErrorPage500,
    fifthVersionErrorPage404,
    fifthVersionErrorPage403,
    fifthVersionErrorPage401,
  } = useContentForErrorPages();

  const getErrorPageValue = (type: TypeOfError) => {
    const value = {
      [TypeOfError.ERROR_500]: fifthVersionErrorPage500(),
      [TypeOfError.ERROR_404]: fifthVersionErrorPage404(),
      [TypeOfError.ERROR_403]: fifthVersionErrorPage403(),
      [TypeOfError.ERROR_401]: fifthVersionErrorPage401(),
    };
    return value[type];
  };

  const selectedValue: any = getErrorPageValue(type);

  return (
    <Wrapper>
      {selectedValue.image}

      <Text type={TextType.TITLE_H1} className="title" color={black1}>
        {selectedValue.title}
      </Text>
      <Text type={TextType.SEMITITLE} color={black1}>
        {selectedValue.description}
      </Text>
      <ButtonWrapper type={type}>
        {selectedValue.buttons.map((item: any, index: number) => {
          const { onClick, backgroundColor, backgroundColorHover, color, value } = item;
          return (
            <StyledButton
              key={index}
              onClick={onClick}
              height={'40px'}
              colors={{
                main: color,
                background: backgroundColor,
                backgroundHover: backgroundColorHover,
              }}
            >
              {value}
            </StyledButton>
          );
        })}
      </ButtonWrapper>
    </Wrapper>
  );
};
