/**
 * @description
 * This is one of the common modal components - select.
 * For more advanced requirements - create custom modal components.
 *
 */

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchInputSelect } from '../searchInputSelect/SearchInputSelect';
import { media } from '@cthings.co/styles-utils';
import { usePrevious } from '../../utils/usePrevious';
import { useMediaType } from '@cthings.co/styles-utils';
import { SmallLoader } from '../../features/smallLoader/SmallLoader';
import { CommonButton } from '@cthings.co/buttons';
//images
import { ReactComponent as SearchPlaceholder } from '../../assets/SearchPlaceholder.svg';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Delete } from './assets/delete.svg';
import { ReactComponent as Edit } from './assets/edit.svg';
import { ReactComponent as Accept } from './assets/accept.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { getTextWidth } from '../../utils/textWidth';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { InfoButton } from '../infoButton/InfoButton';

type SelectBlockProps = {
  fieldWidth?: string;
  position?: string;
  margin?: string;
};

const SelectBlock = styled.div<SelectBlockProps>`
  width: ${({ fieldWidth }) => fieldWidth || '100%'};
  box-sizing: border-box;
  position: ${({ position }) => (position ? position : 'relative')};
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '0'};
`;

const LabelContainer = styled.span`
  margin: 0 0 4px 6px;
  display: block;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

type SelectItemProps = {
  paddingInput?: string;
  disabled: boolean;
  width?: string;
  height?: string;
  isFocused: boolean;
  isError?: boolean;
  isPendingRequest?: boolean;
};

const SelectItem = styled.div<SelectItemProps>`
  padding: ${({ paddingInput, disabled }) =>
    paddingInput ? paddingInput : disabled ? '8px 12px' : '6px 6px 6px 12px'};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ disabled, theme }) => (disabled ? colorFetch('gray5')({ theme }) : 'transparent')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  border: 1px solid
    ${({ disabled, isFocused, isError, theme }) =>
      disabled
        ? colorFetch('gray5')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : isError
        ? colorFetch('red2')({ theme })
        : colorFetch('gray3')({ theme })};
  &.borderless {
    border: 0;
  }
  display: flex;
  outline: none;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ isPendingRequest }) => (isPendingRequest ? 'auto' : 'pointer')};
  transition: all 0.3s linear;
`;

type TextItemProps = {
  color: string;
};

const TextItem = styled.span<TextItemProps>`
  display: block;
  font-size: 14px;
  line-height: 22px;
  font-family: Poppins, sans-serif;
  color: ${({ color }) => color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  &.stageType {
    ${media.megaMonitor} {
      width: 118px;
    }
    ${media.tablet} {
      width: 100%;
    }
  }

  opacity: 1; /* required on iOS */
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */

  & > span.tooltip {
    visibility: hidden;
    color: ${colorFetch('white')};
    background-color: ${colorFetch('black')};
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px;

    /* Position the tooltip */
    left: 4px;
    bottom: 40px;
    position: absolute;
    z-index: 1;
  }

  &:hover {
    > span.tooltip {
      visibility: visible;
    }
  }
`;

type ArrowButtonProps = {
  isPendingRequest?: boolean;
  isOpen: boolean;
};

const ArrowButton = styled.button<ArrowButtonProps>`
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${({ isPendingRequest }) => (isPendingRequest ? '0' : '1')};
  transition: all 0.3s linear;
  & svg {
    transition: all 0.3s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : '')};
  }
`;

type SelectModalProps = {
  width: string;
  height: string;
  left?: string;
  top?: string;
  bottom?: string;
  tooltipText?: string;
};

const SelectModal = styled.div<SelectModalProps>`
  width: ${({ width }) => width || '100%'};
  max-height: ${({ height }) => height};
  left: ${({ left }) => left || '0'};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  position: absolute;
  z-index: 20;
  top: ${({ top, tooltipText }) => (tooltipText ? '75px' : top || '62px')};
  bottom: ${({ bottom }) => bottom || 'unset'};
`;

const SelectModalContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  &.borderless {
    width: calc(100% - 8px);
  }
`;

const ScrolledWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  padding: 3px 3px 3px 0;
`;

type ScrolledListProps = {
  height?: string;
};

const ScrolledList = styled.ul<ScrolledListProps>`
  width: 100%;
  max-height: ${({ height }) => height || '108px'};
  background-color: ${colorFetch('white')};
  box-sizing: border-box;
  list-style: none;
  margin: 2px 2px 2px 0;
  overflow: auto;
  padding: 0px 7px 0px 0;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

const SelectModalItem = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
  color: ${colorFetch('black')};
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s linear;
  & > span {
    flex: 1;
    /* word-break: break-all; */
    hyphens: auto;
  }
  &.selected > span {
    color: ${colorFetch('primary')};
  }
  &.create {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > span {
      margin: 8px 0 8px;
      flex: 1;
      /* word-wrap: break-word; */
      hyphens: auto;
      /* -moz-hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto; */
    }
    &:hover > span {
      color: ${colorFetch('black')};
      font-weight: normal;
      cursor: default;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 4px;
      left: 12px;
      height: 1px;
      background-color: ${colorFetch('gray4')};
      opacity: 1;
      transition: opacity 0.3s linear;
    }
    &.single {
      margin: 0;
      & > span {
        margin-top: 0;
      }
      &::after {
        opacity: 0;
      }
    }
  }
  &:hover > span {
    color: ${colorFetch('primary')};
  }
  &:hover .buttons .edit,
  &:hover .buttons .delete {
    opacity: 1;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const EditInput = styled.input`
  margin-right: 8px;
  padding: 0 6px;
  width: 100%;
  height: 100%;
  border: none;
  padding: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  color: ${colorFetch('black')};
  border: 1px solid;
  border-color: ${colorFetch('primary')};
  border-radius: ${({ theme }) => theme.borderRadius.additional6};
`;

const Buttons = styled.div`
  width: 45px;
  display: flex;
  & .button {
    transition: all 0.3s linear;
    & path {
      stroke: ${colorFetch('gray2')};
    }
    &:hover path {
      transition: all 0.3s linear;
      stroke: ${colorFetch('gray3')};
    }
  }
  & .edit,
  & .delete {
    opacity: 0;
  }
  ${media.tablet} {
    & .edit,
    & .delete {
      opacity: 1;
    }
  }
`;

const StyledAccept = styled(Accept)`
  margin-right: 5px;
`;

const StyledEdit = styled(Edit)`
  margin-right: 5px;
`;

const StyledButton = styled(CommonButton)`
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colorFetch('primary')};
  background-color: ${colorFetch('white')};
` as any; // @TODO fix type

const Message = styled(Text)`
  width: max-content;
`;

const CustomPlaceholder = styled.div`
  height: 246px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > span {
    font-size: 14px;
    line-height: 24px;
  }
`;

const StyledText = styled(Text)`
  & > span.tooltip {
    visibility: hidden;
    color: ${colorFetch('white')};
    background-color: ${colorFetch('black')};
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px;

    /* Position the tooltip */
    left: 4px;
    bottom: -36px;
    position: absolute;
  }

  &:hover {
    > span.tooltip {
      visibility: visible;
    }
  }
`;

interface ValueType {
  id: any;
  name: string;
}

export interface ComponentProps {
  value: ValueType;
  values: any[];
  disabled?: boolean;
  onChange: (value: ValueType) => any;
  className?: any;
  label?: any;
  width?: any;
  placeholder?: string;
  positionLeft?: any;
  positionBottom?: string;
  paddingInput?: any;
  positionTop?: any;
  handleClick?: any;
  borderLess?: boolean;
  openingListener?: (isOpen: boolean) => void;
  stageType?: boolean;
  banAutoFocus?: boolean;
  startedValidation?: boolean;
  isError?: boolean;
  fromAttach?: boolean;
  isPendingRequest?: boolean;
  updateItem?: Function;
  deleteItem?: Function;
  createItem?: Function;
  triggerColumnStructure?: () => void;
  languageStrings: any;
  isSearch?: boolean;
  height?: string;
  listHeight?: string;
  fieldWidth?: string;
  triggerCloseModal?: boolean;
  customModal?: any;
  margin?: string;
  withEnterButtonPlaceholder?: boolean;
  tooltipText?: string;
}

export const Select: FC<ComponentProps> = ({
  value,
  values,
  disabled = false,
  onChange,
  label,
  width,
  placeholder,
  positionLeft,
  positionBottom,
  paddingInput,
  positionTop,
  handleClick,
  borderLess,
  openingListener,
  stageType,
  banAutoFocus,
  startedValidation,
  isError,
  fromAttach,
  isPendingRequest,
  updateItem,
  deleteItem,
  createItem,
  triggerColumnStructure,
  languageStrings,
  className,
  isSearch = true,
  height,
  listHeight,
  fieldWidth,
  margin,
  triggerCloseModal,
  customModal,
  withEnterButtonPlaceholder,
  tooltipText,
  ...props
}) => {
  const theme = useTheme();
  const mediaType = useMediaType();
  const withCreating = !!updateItem && !!deleteItem && !!createItem;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [valueInEdit, setValueInEdit] = useState('');
  const prevIsOpen = usePrevious(isOpen);
  const [selectedID, setSelectedID] = useState<string>('');
  const [mouseOverValue, setMouseOverValue] = useState<string>('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const isShowMessage = fromAttach ? !!isError : startedValidation && !!isError && !disabled;
  const { gray1, gray2, red2, gray3, primary, black1 } = theme.colors;
  const heightOfPopup = values ? `${values.length * 34}px` : 'max-content';

  const buttonRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  const outsideHandler = useCallback(
    (e: any) => {
      if (
        isOpen &&
        !dropdownRef.current?.contains(e.target) &&
        !buttonRef.current?.contains(e.target) &&
        !customModal
      ) {
        setIsOpen(false);
        setIsEditing(null);
      }
    },
    [isOpen],
  );

  const textRef: any = useRef();
  const containerRef: any = useRef();
  const filterParameterList = (flag: string, items: any) => {
    return (
      items &&
      items.filter((item: any) => {
        return item?.name?.length > 0 && item?.name?.toLowerCase()?.indexOf(flag.toLowerCase()) !== -1;
      })
    );
  };

  const handleChange = (index: any, value: ValueType) => {
    setIsOpen(false);
    setSelectedID(value.id);
    onChange(value);
  };

  const handleInutOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  const handleCreate = (name: string) => {
    handleClearInput();

    createItem && createItem({ body: { name }, callback: onChange });
  };

  const handleUpdate = (name: string, id: string) => {
    updateItem && updateItem({ body: { name, id }, callback: triggerColumnStructure });
  };

  const handleDelete = (id: string) => {
    deleteItem && deleteItem({ body: { id } });
  };

  const onEnterClickHandler = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onChange({ name: event.target.value, id: '' });
        setIsOpen(false);
      }
    },
    [searchInputValue],
  );

  useEffect(() => {
    const input = document.getElementById('searchInput');
    input && input.addEventListener('keypress', onEnterClickHandler);
    return () => {
      input && input.removeEventListener('keypress', onEnterClickHandler);
    };
  }, [onEnterClickHandler]);

  useEffect(() => {
    if (stageType) {
      onChange(value);
    }
  }, []);

  useEffect(() => {
    customModal && triggerCloseModal && setIsOpen(false);
  }, [triggerCloseModal]);

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const mouseOverHandler = useCallback((e: any) => {
    setMouseOverValue(e.target.innerText);
  }, []);

  useEffect(() => {
    window.addEventListener('mouseover', mouseOverHandler);
    return () => {
      window.removeEventListener('mouseover', mouseOverHandler);
    };
  }, [mouseOverHandler]);

  useEffect(() => {
    isOpen && isOpen !== prevIsOpen && handleClick && handleClick();
  }, [isOpen]);

  useEffect(() => {
    setSelectedID(
      filterParameterList(searchInputValue, values)?.filter((item: any) => value && item.id === value.id)[0]?.id,
    );
  }, [value, values]);

  useEffect(() => {
    openingListener && openingListener(isOpen);
  }, [isOpen]);

  const selectValue =
    typeof value === 'string' ? value : values?.filter((item: any) => selectedID === item.id)[0]?.name;

  const isTooltip = useMemo(() => {
    const width = containerRef?.current?.getBoundingClientRect().width;
    const textWidth = getTextWidth(selectValue, { textNode: textRef?.current });

    return width && width - 44 < textWidth;
  }, [selectValue]);

  const isTooltipModal = useMemo(() => {
    const width = containerRef?.current?.getBoundingClientRect().width;
    const textWidth = getTextWidth(mouseOverValue, { textNode: textRef?.current });

    return width && width - 44 < textWidth;
  }, [mouseOverValue]);

  return (
    <SelectBlock {...props} ref={containerRef} margin={margin} fieldWidth={fieldWidth} className={className}>
      {label && (
        <LabelContainer>
          <Text type={TextType.TEXT_12_BLACK} color={gray1}>
            {label}
          </Text>
          {tooltipText && (
            <InfoButton width={'22px'} height={'22px'} onClick={() => {}} tooltipText={tooltipText} isInfoIcon />
          )}
        </LabelContainer>
      )}
      <SelectItem
        ref={dropdownRef}
        disabled={disabled}
        isError={isShowMessage}
        className={!!borderLess ? 'borderless' : ''}
        height={height}
        width={width}
        isFocused={isOpen}
        theme={theme}
        onClick={
          isPendingRequest
            ? () => {}
            : () => {
                setIsOpen(!isOpen);
                !isOpen && handleClearInput();
              }
        }
        paddingInput={paddingInput}
        isPendingRequest={isPendingRequest}
      >
        <TextItem
          color={disabled ? gray1 : selectValue ? gray1 : gray2}
          className={stageType ? 'stageType' : ''}
          ref={textRef}
        >
          {selectValue ? selectValue : placeholder}
          {isTooltip && <span className="tooltip">{selectValue}</span>}
        </TextItem>
        {!disabled &&
          (isPendingRequest ? (
            <SmallLoader style={{ width: '24px', height: '24px' }} />
          ) : (
            <ArrowButton isOpen={isOpen} ref={buttonRef}>
              <Arrow />
            </ArrowButton>
          ))}
      </SelectItem>
      {isOpen && (
        <>
          {customModal ? (
            customModal
          ) : (
            <SelectModal
              theme={theme}
              width={width}
              height={values.length > 5 ? heightOfPopup : 'max-content'}
              left={positionLeft}
              top={positionTop}
              bottom={positionBottom}
              tooltipText={tooltipText}
            >
              <SelectModalContainer theme={theme} className={!!borderLess ? 'borderless' : ''}>
                {isSearch && (
                  <SearchInputSelect
                    id={'searchInput'}
                    value={searchInputValue}
                    handleOnChange={handleInutOnChange}
                    handleClearInput={handleClearInput}
                    setIsEditing={setIsEditing}
                    isFocused={isOpen && !mediaType.tablet}
                    banAutoFocus={banAutoFocus}
                  />
                )}
                <ScrolledWrapper>
                  <ScrolledList theme={theme} height={listHeight}>
                    {(!withCreating && filterParameterList(searchInputValue, values).length) || withCreating ? (
                      <>
                        {filterParameterList(searchInputValue, values).map((el: any, i: number) => (
                          <SelectModalItem
                            key={el.id}
                            className={selectedID === el.id ? 'selected' : ''}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              isEditing !== el.id && handleChange(i, el);
                              setIsEditing(null);
                            }}
                          >
                            {withCreating && isEditing === el.id ? (
                              <EditInput
                                theme={theme}
                                value={valueInEdit}
                                onClick={(e: any) => e.stopPropagation()}
                                onChange={(e: any) => setValueInEdit(e.currentTarget.value)}
                                autoFocus
                              />
                            ) : (
                              <StyledText type={TextType.TEXT_14_BLACK} color={black1} weight={'400'}>
                                {el.name}
                                {isTooltipModal && <span className="tooltip">{el.name}</span>}
                              </StyledText>
                            )}
                            {withCreating && (
                              <Buttons className={`buttons`}>
                                {isEditing === el.id ? (
                                  <>
                                    <StyledAccept
                                      className={'button'}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        handleUpdate(valueInEdit, el?.id);
                                        setIsEditing(null);
                                      }}
                                    />
                                    <Close
                                      className={'button'}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        setIsEditing(null);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <StyledEdit
                                      className={'button edit'}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        setIsEditing(el?.id);
                                        setValueInEdit(el?.name);
                                      }}
                                    />
                                    {!(selectedID === el.id) && (
                                      <Delete
                                        className={'button delete'}
                                        onClick={(e: any) => {
                                          handleDelete(el?.id);
                                          e.stopPropagation();
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Buttons>
                            )}
                          </SelectModalItem>
                        ))}
                        {withCreating &&
                          searchInputValue &&
                          values.findIndex((e: any) => e.name === searchInputValue) === -1 && (
                            <SelectModalItem
                              className={`create ${
                                filterParameterList(searchInputValue, values).length === 0 && 'single'
                              }`}
                            >
                              <Text type={TextType.TEXT_14_BLACK} color={black1} weight={'400'}>
                                {searchInputValue}
                              </Text>
                              <StyledButton
                                borderColor={primary}
                                borderColorHover={primary}
                                onClick={() => handleCreate(searchInputValue)}
                                isBlocked={false}
                              >
                                {languageStrings.createButton}
                              </StyledButton>
                            </SelectModalItem>
                          )}
                      </>
                    ) : withEnterButtonPlaceholder ? (
                      <CustomPlaceholder>
                        <SearchPlaceholder />
                        <Text type={TextType.TEXT_14_BLACK} color={gray2} weight={'400'}>
                          No results found
                        </Text>
                        <Text type={TextType.TEXT_14_BLACK} color={gray2}>
                          Press enter to save the value
                        </Text>
                      </CustomPlaceholder>
                    ) : (
                      <PlaceholderWrapper>
                        <Text type={TextType.TEXT_12_BLACK} color={gray2} weight={'400'} margin={'0 0 0 10px'}>
                          {languageStrings.modalSystemSelectPlaceholder}
                        </Text>
                      </PlaceholderWrapper>
                    )}
                  </ScrolledList>
                </ScrolledWrapper>
              </SelectModalContainer>
            </SelectModal>
          )}
        </>
      )}
      {isShowMessage && (
        <Message type={TextType.TEXT_12_GRAY} color={red2} margin={'4px 0 0 12px'}>
          {`${label} ${languageStrings.validationMessage}`}
        </Message>
      )}
    </SelectBlock>
  );
};
