import React, { FC, useState } from 'react';
import styled, { ThemeProvider } from '@cthings.co/styled-components';
import { motion } from 'framer-motion';
import Check from '../../assets/check.svg';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Container = styled.div`
  width: auto;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

type ButtonType = {
  borderColor: string;
  editInProcess?: boolean;
};

const Button = styled(motion.div)<ButtonType>`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ borderColor, theme }) => (borderColor ? borderColor : colorFetch('primary')({ theme }))};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${({ editInProcess }) => (editInProcess ? 'auto' : 'pointer')};
`;

const InnerCircle = styled(motion.div)`
  width: auto;
  height: auto;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  box-shadow: none;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
`;

type LabelContainerProps = {
  color?: string;
  fontSize?: string;
  labelWhiteSpace?: string;
};

const LabelContainer = styled(motion.div)<LabelContainerProps>`
  width: auto;
  height: auto;

  display: flex;

  color: ${({ color, theme }) => (color ? color : colorFetch('black1')({ theme }))};

  font-family: 'Poppins';
  font-weight: normal;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};

  line-height: 18px;

  margin: 0px 0px 0px 6px;

  cursor: pointer;
  user-select: none;
  white-space: ${({ labelWhiteSpace }) => labelWhiteSpace};
`;
export interface CheckBoxProps {
  toggle?: boolean;
  disabled?: boolean;
  label: string;
  handleToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;

  backgroundColor?: string;
  backgroundColorHover?: string;
  color?: string;
  fontSize?: string;
  className?: string;
  editInProcess?: boolean;
  labelWhiteSpace?: string;
}

export const CheckBox: FC<CheckBoxProps> = ({
  toggle,
  label,
  handleToggle,
  disabled = false,
  color,
  fontSize,
  backgroundColor,
  backgroundColorHover,
  editInProcess,
  labelWhiteSpace,
  ...props
}) => {
  const theme = useTheme();
  const [disabledState] = useState(disabled);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleToggle(e);
  };

  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    disabled ? e.preventDefault() : handleClick(e);
  };

  const onLabelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    disabled ? e.preventDefault() : handleClick(e);
  };

  return (
    <ThemeProvider theme={theme} {...props}>
      <Container>
        <Button
          // @TODO fix onButtonClick type
          onClick={onButtonClick as any}
          animate={{
            backgroundColor: toggle
              ? backgroundColorHover
                ? backgroundColorHover
                : theme.colors.primary
              : backgroundColor
              ? backgroundColor
              : theme.colors.pureWhite,
            opacity: disabledState ? 0.3 : 1,
          }}
          transition={{ duration: 0.1, ease: 'linear' }}
          borderColor={theme.colors.primary}
          theme={theme}
          editInProcess={editInProcess}
        >
          <InnerCircle
            animate={{
              opacity: toggle ? 1 : 0,
            }}
            theme={theme}
          >
            <img src={Check} alt="c" />
          </InnerCircle>
        </Button>
        <LabelContainer
          // @TODO fix onLabelClick type
          onClick={onLabelClick as any}
          animate={{
            opacity: disabledState ? 0.3 : 1,
          }}
          theme={theme}
          color={color}
          fontSize={fontSize}
          labelWhiteSpace={labelWhiteSpace}
        >
          {label}
        </LabelContainer>
      </Container>
    </ThemeProvider>
  );
};

function handleToggle() {}

CheckBox.defaultProps = {
  toggle: false,
  label: 'Label',
  disabled: false,
  handleToggle: handleToggle,
};
