import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Container = styled.div`
  width: 100%;
`;

type WrapperProps = {
  height?: string;
  isEditingInProcess: boolean;
  isError?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  height: ${({ height }) => height};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border: 1px solid;
  border-color: ${({ isEditingInProcess, isError, theme }) =>
    isEditingInProcess
      ? colorFetch('primary')({ theme })
      : isError
      ? colorFetch('red2')({ theme })
      : colorFetch('gray3')({ theme })};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  background-color: ${colorFetch('white')};
  color: ${colorFetch('gray1')};
  transition: all 0.3s linear;
`;

const TextField = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  background-color: inherit;
  color: inherit;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  padding: 0 6px 0 6px;
  margin: 6px;
  outline: none;
  resize: none;
  border: none;
  &::-webkit-scrollbar {
    width: 3px;
    height: 100%;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
`;

export const Textarea = ({
  value,
  placeholder,
  onChange,
  isEdit,
  isError,
  height,
  fieldName,
  ...props
}: {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  isEdit?: boolean;
  isError?: boolean;
  height?: string;
  fieldName?: string;
  className?: string;
}) => {
  const [isEditingInProcess, setIsEditingInProcess] = useState(false);
  const theme = useTheme();
  const { red2 } = theme.colors;

  return (
    <Container {...props}>
      <Wrapper theme={theme} isEditingInProcess={isEditingInProcess} isError={isError} height={height}>
        <TextField
          value={value}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          onFocus={() => {
            setIsEditingInProcess(true);
          }}
          onBlur={() => {
            setIsEditingInProcess(false);
          }}
          placeholder={placeholder}
        />
      </Wrapper>
      {isError && (
        <Text type={TextType.TEXT_12_BLACK} margin={'4px 0 0 12px'} color={red2} weight={'400'}>
          {fieldName ? `${fieldName} doesn't look right` : `Description doesn't look right`}
        </Text>
      )}
    </Container>
  );
};
