import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectCustomer, setParameters, toggleIsUploadingInProcess } from '../app/state/generateReportModal';
import { selectLanguage } from '../app/state/user';
import { API_URL } from '../consts';
import { TypeOfParameter } from '../containers/common/reports/components/generateReportModal/GenerateReportModal';
import { useApi } from '../features/apiBuilder/useApiBuilder';
import { HttpService } from '../utils/http';

export const useReportsList = (setIsEmpty: any) => {
  useEffect(() => {
    const url = `${API_URL}/v2/reports/`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        setIsEmpty(payload.length === 0);
      } catch (e) {
        //
      }
    })();
  }, []);
};

export const generateReport = (
  data: {
    ts_to: string;
    ts_from: string;
    locations: any[];
    customer_ids: any[];
    recipients_ids: any[];
    devices?: any[];
  },
  addNotification: any,
  languageStrings: any,
  handleResponse?: any,
) => {
  const url = `${API_URL}/v2/reports/generate`;
  (async () => {
    try {
      let responseData = await HttpService.post(url, data);
      handleResponse && handleResponse(true);
      addNotification({
        message: responseData.data.message,
        type: 'success',
      });
    } catch (e) {
      handleResponse && handleResponse(false);
      /* @ts-ignore */
      const message = e?.response?.data?.detail;
      addNotification({
        message: message || 'Something went wrong',
        type: 'error',
      });
      //
    }
  })();
};

const customersKeywords = ['GET_CUSTOMERS'];

export const useGetCustomers = () => {
  const dispatch = useDispatch();
  const type = TypeOfParameter.CUSTOMER;

  const [localCustomers] = useApi({
    defaultValue: [],
    url: `${API_URL}/v2/clients/?limit=0&offset=0`,
    keywords: customersKeywords,
    transformResponse: (data) => data.payload.map((item: any) => ({ id: item.id, name: item.name, isSelected: false })),
    handleSuccess: (data) => dispatch(setParameters({ type, data })),
  });

  return [localCustomers as { id: string; name: string }[]];
};

export const useGetLocations = () => {
  const [localLocations, setLocalLocations] = useState();
  const language = useSelector(selectLanguage);
  const customers = useSelector(SelectCustomer).selected_parameters;

  const countOfCustomers = customers.length;
  const customersQueryText = customers
    .map((item: any, index: number, arr: any[]) => `customers=${item}${index !== arr.length - 1 ? '&' : ''}`)
    .join('');
  const dispatch = useDispatch();
  const type = TypeOfParameter.LOCATION;
  useEffect(() => {
    if (countOfCustomers > 0) {
      const url = `${API_URL}/v2/reports/listlocations${countOfCustomers > 0 ? `?${customersQueryText}` : ''}`;
      dispatch(toggleIsUploadingInProcess({ type, value: true }));
      (async () => {
        try {
          let responseData = (await HttpService.get(url)).data;
          const { payload } = responseData;
          const result = payload
            .map((item: any) => {
              const { device_id, city, country, geotag, line1, line2, region, zip } = item;
              return { id: device_id, name: { city, country, geotag, line1, line2, region, zip }, isSelected: false };
            })
            .filter(
              (item: any, index: number, array: any) =>
                array.findIndex(
                  (i: any) => i.name.geotag.lat === item.name.geotag.lat || i.name.geotag.lng === item.name.geotag.lng,
                ) === index,
            );
          setLocalLocations(result);
          dispatch(setParameters({ type, result }));
          dispatch(toggleIsUploadingInProcess({ type, value: false }));
        } catch (e) {
          dispatch(toggleIsUploadingInProcess({ type, value: false }));
        }
      })();
    }
  }, [language, countOfCustomers]);
  return localLocations;
};

export const useGetRecipients = () => {
  const [localRecipients, setLocalRecipients] = useState();
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const type = TypeOfParameter.RECIPIENTS;
  useEffect(() => {
    const url = `${API_URL}/v2/users/?limit=0&offset=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({
          id: item.id,
          name: item.name,
          secondary_name: item.email,
          isSelected: false,
        }));
        setLocalRecipients(result);
        dispatch(setParameters({ type, result }));
      } catch (e) {
        //
      }
    })();
  }, [language]);
  return localRecipients;
};
