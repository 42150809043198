import { ReactNode, useEffect, useState } from 'react';
import { useTooltipContext } from './context';
// @ts-ignore
import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
import { Tooltip } from './types';

// use this hook to define tooltip ids
export const useTooltip = (amount?: number) => {
  const [, setTooltip] = useTooltipContext();
  const [uuidArray, setUuidArray] = useState([]);

  const createId = (index: number): string => {
    const timestampPart = uuidv1();
    const randomPart = uuidv4();
    // custom uuid logic compines static text with random uuid with
    // current index included and also adds the timestamp uuid in the end
    const combinedUuid = 'tooltip' + randomPart.slice(0, randomPart.length - 1) + index + timestampPart;
    return combinedUuid;
  };

  const createIndexArray = (limit: number): number[] => {
    let i = 0;
    let indexArray: number[] = [];
    for (i; i < limit; i++) {
      indexArray = [...indexArray, i];
    }
    return indexArray;
  };

  useEffect(() => {
    const requiredAmount = amount || 1;
    const ids = createIndexArray(requiredAmount).map((index) => {
      return createId(index);
    });
    setUuidArray(ids as any);
  }, []);

  const showTooltip = (id: string) => {
    setTooltip((value) => ({ ...value, openRequests: [...value.openRequests, { id }] }));
  };

  const hideTooltip = (id: string) => {
    setTooltip((value) => ({ ...value, closeRequests: [...value.closeRequests, { id }] }));
  };

  return [uuidArray, showTooltip, hideTooltip] as const;
};

export const useTooltipRequestMonitor = (id: string) => {
  const [tooltip] = useTooltipContext();
  const { openRequests, closeRequests } = tooltip;

  const requestedToOpen = openRequests.findIndex((request) => request.id === id) !== -1;
  const requestedToClose = closeRequests.findIndex((request) => request.id === id) !== -1;

  return [requestedToOpen, requestedToClose];
};
