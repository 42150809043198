import React from 'react';

export const TemperatureImage = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.625 10.1475V2.40625C9.625 1.95041 9.44392 1.51324 9.12159 1.19091C8.79926 0.868582 8.36209 0.6875 7.90625 0.6875C7.45041 0.6875 7.01324 0.868582 6.69091 1.19091C6.36858 1.51324 6.1875 1.95041 6.1875 2.40625V10.1475C5.63563 10.5162 5.21697 11.0527 4.99336 11.6776C4.76975 12.3025 4.75305 12.9828 4.94571 13.6179C5.13837 14.2531 5.53019 14.8094 6.06329 15.2048C6.5964 15.6002 7.24253 15.8136 7.90625 15.8136C8.56997 15.8136 9.2161 15.6002 9.74921 15.2048C10.2823 14.8094 10.6741 14.2531 10.8668 13.6179C11.0595 12.9828 11.0427 12.3025 10.8191 11.6776C10.5955 11.0527 10.1769 10.5162 9.625 10.1475Z"
        stroke="#F44450"
        stroke-width="0.916667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <clipPath id="clip0">
          <rect width="16.5" height="16.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
