import React from 'react';
import { useDispatch } from 'react-redux';
import { EditButton } from '../editButton/EditButton';
import { setIsOpenDeliveryAddressModal } from '../../../../../../app/state/billingDetailsSlice';
import { ValueView } from '../valueViewComponent/ValueView';

export const header = {
  EDIT: () => {
    const dispatch = useDispatch();
    const onClickHandle = () => {
      dispatch(setIsOpenDeliveryAddressModal(true));
    };

    return <EditButton onClick={onClickHandle} />;
  },
};
export const component = {
  ACTUAL_VALUE: (value: any, state: any, setValue?: any, ...rest: any) => {
    return (
      <ValueView
        value={
          (value.actual_value?.line1 || value.actual_value?.line1 === ''
            ? value.actual_value?.line1
            : value.actual_value) || 'N/A'
        }
      />
    );
  },
};
