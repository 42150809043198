// UTILS OF COMPONENT FOR CHECKING ACCESS FOR THE PAGES
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
import { stringifyUrl } from 'query-string';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAccess } from '../../app/state/user';
import { PATHS, View, getAccess } from '../../routes';
import { useCurrentView } from '../../routes/currentView';
import { useHistory } from '../../utils/react-router-dom-abstraction';

export const useRedirectToPageWithAccess = () => {
  // history RRD v6+
  const history = useHistory();

  // VEIW of current page
  const { currentView } = useCurrentView();
  const userAccess = useSelector(selectAccess);

  // exceptions for redirecting to other page instead error 403
  const exceptions: Partial<typeof View> = { [View.USER_DASHBOARD]: PATHS.BILLING_DETAILS };

  // function for getting value of access current page
  const getAccessForPage = (keys: string[]) =>
    keys.reduce(
      (acc: any, currentValue: string) => (acc.hasOwnProperty(currentValue) ? acc[currentValue] : null),
      userAccess,
    );

  // block of code which redirecting to the error 403 if user dont have access to the current page (or redirecting to the exceptions)
  useEffect(() => {
    if (currentView && userAccess.isAccess && getAccess(currentView).length) {
      !getAccessForPage(getAccess(currentView)) &&
        (!!Object.keys(exceptions).find((key: string) => key === currentView)
          ? history.push(stringifyUrl({ url: exceptions[currentView] || PATHS.ERROR_403 }))
          : history.push(stringifyUrl({ url: PATHS.ERROR_403 })));
    }
  }, [currentView, userAccess.isAccess]);
};
