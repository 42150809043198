import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';
import { ReactComponent as Download } from '../../../../assets/downloadIcon.svg';
import { ExportModal } from './components/ExportModal';
import { getExportEventsUrl, getExportHistoryUrl } from '../../../../api/devices/historyInsight';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { GraphType } from '../../types';
import { useParams } from '@cthings.co/router-dom';

type DownloadButtonProps = {
  isApplyAvailable: boolean | undefined;
};

const DownloadButton = styled(CommonButton)<DownloadButtonProps>`
  margin: 16px 0 0 15px;
  pointer-events: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
  user-select: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
  cursor: ${({ isApplyAvailable }) => (isApplyAvailable ? 'pointer' : 'auto')};
  &:hover > svg > path {
    stroke: ${colorFetch('white')};
  }

  & > svg > path {
    stroke: ${({ colors: { main } = {} }) => main};
  }

  // @TODO fix media type
  ${media.desktop} {
    margin: 0;
    &:hover > svg > path {
      stroke: ${({ colors: { main } = {} }) => main};
    }
  }
`;

interface FiltersDefaultValueType {
  id: string;
  key: string;
  tooltip: string;
}

interface ItemListType {
  id: string;
  key: string;
  tooltip: string;
}

interface DataType {
  data: any[];
  device_id: string;
  id?: string;
  sensor?: any;
  color?: string;
  invertAxis?: boolean;
}

export const Export = ({
  isExportButtonActive,
  applyingStatus,
  type,
  data,
  filters,
  ts_from,
  ts_to,
}: {
  isExportButtonActive: boolean | undefined;
  applyingStatus: number;
  type: GraphType;
  data: DataType;
  filters: {
    averaging_methods: {
      default_value: FiltersDefaultValueType;
      items_list: ItemListType[];
    };
    intervals: {
      default_value: FiltersDefaultValueType;
      items_list: ItemListType[];
    };
    periods: {
      default_value: FiltersDefaultValueType;
      items_list: ItemListType[];
    };
  };
  ts_from: Date | string;
  ts_to: Date | string;
}) => {
  const theme = useTheme();
  const { pureWhite, gray3, primary, secondary2 } = theme.colors;

  const params = useParams();
  const { id } = params;

  const language = useSelector(selectLanguage);

  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [intermediateFilterState, setIntermediateFilterState] = useState(filters);

  useEffect(() => {
    if (applyingStatus === 1) {
      setIntermediateFilterState(filters);
    }
  }, [filters, applyingStatus]);

  const handleDownload = () => {
    if (type === GraphType.EVENTS) {
      getExportEventsUrl(
        id || '',
        {
          filter_interval: 'GRAPH_FILTER_INTERVAL_1D',
          filter_data_period: intermediateFilterState?.periods?.default_value?.key ?? null,
          ts_from: getRequiredDateFormat(ts_from, 'YYYY-MM-DDTHH:mm:ssZ'),
          ts_to: getRequiredDateFormat(ts_to, 'YYYY-MM-DDTHH:mm:ssZ'),
        },
        language.id,
        setIsOpenExportModal,
      );
    } else {
      getExportHistoryUrl(
        id || '',
        data.sensor,
        {
          filter_interval: intermediateFilterState.intervals.default_value.key,
          filter_averaging_method: intermediateFilterState?.averaging_methods?.default_value?.key,
          filter_data_period: intermediateFilterState.periods.default_value.key,
          ts_from: getRequiredDateFormat(ts_from, 'YYYY-MM-DDTHH:mm:ssZ'),
          ts_to: getRequiredDateFormat(ts_to, 'YYYY-MM-DDTHH:mm:ssZ'),
        },
        language.id,
        setIsOpenExportModal,
      );
    }
  };

  return (
    <>
      <DownloadButton
        onClick={() => {
          setIsOpenExportModal(true);
        }}
        colors={{
          main: isExportButtonActive ? primary : gray3,
          background: pureWhite,
          backgroundHover: isExportButtonActive ? secondary2 : gray3,
          border: isExportButtonActive ? primary : gray3,
          borderHover: secondary2,
        }}
        width={'32px'}
        height={'32px'}
        isApplyAvailable={isExportButtonActive}
      >
        <Download />
      </DownloadButton>
      <ExportModal
        type={type}
        isOpenModal={isOpenExportModal}
        setIsOpenModal={setIsOpenExportModal}
        onClick={handleDownload}
      />
    </>
  );
};
