import React from 'react';

export const WaterLevelImage = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.25003 1.9292L10.2569 4.93607C10.8516 5.53034 11.2566 6.28761 11.4208 7.1121C11.585 7.9366 11.501 8.79128 11.1794 9.56802C10.8579 10.3448 10.3131 11.0087 9.61415 11.4758C8.91518 11.9429 8.09338 12.1923 7.25269 12.1923C6.412 12.1923 5.59019 11.9429 4.89122 11.4758C4.19226 11.0087 3.64752 10.3448 3.32593 9.56802C3.00434 8.79128 2.92034 7.9366 3.08455 7.1121C3.24877 6.28761 3.65382 5.53034 4.24847 4.93607L7.25003 1.9292Z"
        stroke="#555770"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
