import React from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { useMediaType } from '@cthings.co/styles-utils';
import { media } from '@cthings.co/styles-utils';

const StyledCommonButton = styled(CommonButton)`
  padding: 0 20px;
  ${media.tablet} {
    padding: 0 10px;
  }
`;

interface AddButtonProps {
  onClick: () => void;
}

export const AddButton: React.FC<AddButtonProps> = ({ onClick }) => {
  const theme = useTheme();
  const { primary, pureWhite } = theme.colors;
  const { tablet } = useMediaType();

  const languageStrings = useSelector(selectLanguageStrings);

  const text = !tablet ? `+ ${languageStrings.add}` : '+';

  return (
    <StyledCommonButton
      theme={theme}
      height={'32px'}
      width={'fit-content'}
      colors={{ main: primary, background: pureWhite }}
      onClick={onClick}
    >
      {text}
    </StyledCommonButton>
  );
};
