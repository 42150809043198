import React, { useCallback, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useStyleContext } from '@cthings.co/styles-utils';
import { useRef } from 'react';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type ContainerProps = {
  withoutNav?: boolean;
  onTouch: any; // @TODO remove after checking implementation
};

const Container = styled.div<ContainerProps>`
  transition: all 0.2s ease-in-out;
  position: fixed;
  z-index: var(--modal-first-layer);
  bottom: ${({ withoutNav }) => (withoutNav ? '0' : '62px')};
  left: 0;
  right: 0;
  height: max-content;
  background-color: transparent;
  border-radius: ${({ theme }) => `${theme.borderRadius.additional24} ${theme.borderRadius.additional24} 0 0`};
  transform: translateY(calc(100% - 130px));
  & > div > div:first-child {
    border-radius: ${({ theme }) => `${theme.borderRadius.additional24} ${theme.borderRadius.additional24} 0 0`};
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 50%;
    transform: translate(-30%, -30%);
    width: 30px;
    height: 4px;
    background-color: ${colorFetch('black')};
    opacity: 0.2;
    border-radius: 2px;
  }
  &.open {
    transform: translateY(0);
  }
`;

const SwipeableBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  &.open {
    bottom: initial;
    height: 50px;
  }
`;
const Background = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1;
`;

export const withMobileDraggableContainer = (isDraggable: boolean, withoutNav?: boolean) => {
  const enhance = (Component: any) => ({ ...props }) => {
    const [style] = useStyleContext();
    const { mediaType } = style;
    const theme = useTheme();
    const [mobileTable, setMobileTable] = useState(false);

    const modalRef = useRef<HTMLDivElement>(null);

    let x1: number, y1: number;

    const onTouchStartHandler = (event: any) => {
      const firstTouch = event.touches[0];
      x1 = firstTouch.clientX;
      y1 = firstTouch.clientY;
    };

    const onTouchMoveHandler = (event: any) => {
      if (!x1 || !y1) return;
      let x2: number = event.touches[0].clientX;
      let y2: number = event.touches[0].clientY;
      let xDiff = x2 - x1;
      let yDiff = y2 - y1;

      if (Math.abs(xDiff) < Math.abs(yDiff)) {
        yDiff < 0 ? setMobileTable(true) : setMobileTable(false);
      }
    };

    const onClickOutsideHandler = useCallback(
      (event: any) => {
        if (mobileTable && modalRef.current && !(modalRef as any).current.contains(event.target)) {
          setMobileTable(false);
        }
      },
      [mobileTable],
    );

    useEffect(() => {
      window.addEventListener('click', onClickOutsideHandler);
      return () => {
        window.removeEventListener('click', onClickOutsideHandler);
      };
    }, [onClickOutsideHandler]);

    useBlockBodyScroll(mobileTable);

    return (
      <>
        {mediaType.tablet ? (
          <>
            <Container
              ref={modalRef}
              className={mobileTable ? 'open' : ''}
              withoutNav={withoutNav}
              theme={theme}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              // @TODO check if this function works because onTouch doesn't exist for div
              onTouch={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Component {...props} />
              <SwipeableBlock
                className={mobileTable ? 'open' : ''}
                onTouchStart={onTouchStartHandler}
                onTouchMove={onTouchMoveHandler}
              />
            </Container>
            {mobileTable && <Background />}
          </>
        ) : (
          <Component {...props} />
        )}
      </>
    );
  };
  return enhance;
};
