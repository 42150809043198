import React from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { InputModal } from '../../../../inputModal/InputModal';
import { HOURStoDDHH, MINtoHHMM, convertTimeDataForInputs } from '../../../../../utils/date/functions';
import { InputWrapper } from '../../inputWrapper/InputWrapper';
import { selectLanguageStrings } from '../../../../../app/state/user';
import { useTheme } from '@cthings.co/styled-components';
import { TableInputType } from '../../../../universalTableInput/types';

const Wrapper = styled(InputWrapper)`
  align-items: flex-start;
  flex-direction: row;
  ${media.phone} {
    flex-direction: column;
  }
`;

const PeriodsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 20px;
  ${media.phone} {
    grid-template-columns: 1fr 1fr;
  }
`;

const DaysWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${media.phone} {
    margin-right: 0;
    width: 100%;
  }
`;

const HoursWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${media.phone} {
    width: 100%;
  }
`;

type StyledInputProps = {
  width?: string;
};

const StyledInput = styled(InputModal)<StyledInputProps>`
  width: auto;
  margin: 0 6px 0 0;
  text-align: center;
  ${media.phone} {
    width: 100%;
    margin: 0;
  }
  & div:nth-child(2) {
    min-width: 40px;
    height: 32px;
    margin: 0;
    width: ${({ width }) => width} !important;
    ${media.phone} {
      min-width: 100%;
      height: 40px;
    }
  }
  & div > input {
    height: inherit;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    ${media.phone} {
      text-align: left;
    }
  }
` as any; // @TODO fix type

const StyledText = styled(Text)`
  word-break: break-all;
`;

const StyledTextUnit = styled(Text)`
  ${media.phone} {
    position: absolute;
    right: 20px;
  }
`;

export const TimePickerFields = ({
  value,
  onChange,
  isEditInProcess,
  options,
  ...props
}: {
  value: any;
  onChange: Function;
  options: any;
  isEditInProcess?: boolean;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const { unit, title, max, min, isHHMM } = options;
  const languageStrings = useSelector(selectLanguageStrings);
  const preparedUnit = unit && unit.slice(1, -1).split(', ');
  const translatedUnits = (unit: string) => {
    return unit === preparedUnit[0]
      ? languageStrings.daysUnit
      : unit === preparedUnit[1]
      ? languageStrings.hoursUnit
      : unit === preparedUnit[2]
      ? languageStrings.minsUnit
      : '';
  };

  const handleOnChange = (e: any, index: number) => {
    const currentValue = +e.currentTarget.value;
    const preparedValue = value.map((item: number, i: number) => (i === index ? currentValue : 0));
    onChange(preparedValue);
  };

  const onBlur = (index: number) => {
    if (index === 0) {
      if (value[0] >= max[0]) {
        onChange(max);
      } else if (value[0] === 0 && value[1] === 0) {
        onChange(min);
      }
    } else if (index === 1) {
      if (value[index] < min[index] && value[index - 1] === 0) {
        const preparedValue = value.map((item: number, i: number) => (index === i ? min[index] : 0));
        onChange(preparedValue);
      } else if (value[index] > 23) {
        const prepDay = +HOURStoDDHH(`${value[1]}`)[0] + value[0];
        const prepHour = +HOURStoDDHH(`${value[1]}`)[1];
        const preparedTime = [prepDay <= max[0] ? prepDay : max[0], prepDay < max[0] ? prepHour : max[1]];
        const preparedValue = value.map((item: number, i: number) => {
          return index - 1 === i
            ? +preparedTime[0] <= max[0]
              ? +preparedTime[0]
              : max[0]
            : index === i
            ? +preparedTime[1]
            : 0;
        });
        onChange(preparedValue);
      } else if (value[0] === max[0] && !isHHMM) {
        onChange(max);
      } else if (isHHMM && (value[index] > max[index] || (value[index] === max[index] && value[index + 1] > 0))) {
        onChange(max);
      } else if (isHHMM && value[index] === 0 && value[index + 1] === 0) {
        onChange(min);
      } else {
        const preparedValue = value.map((item: number, i: number) => {
          return index === i ? value[index] : 0;
        });
        onChange(preparedValue);
      }
    } else if (index === 2) {
      if (value[index - 1] === max[index - 1] && value[index] > 0) {
        onChange(max);
      } else if (value[index] > 59) {
        const preparedMins = +MINtoHHMM(`${value[index]}`)[1];
        const preparedHours = +MINtoHHMM(`${value[index]}`)[0];
        const preparedTime = [
          preparedHours <= max[1] ? preparedHours : max[1],
          preparedMins < 60 && preparedHours < max[1] ? preparedMins : max[2],
        ];
        const preparedValue = value.map((item: number, i: number) =>
          index - 1 === i ? preparedTime[0] : index === i ? preparedTime[1] : 0,
        );
        onChange(preparedValue);
      } else if (value[index] === 0 && value[index - 1] === 0) {
        onChange(min);
      }
    }
  };
  const findIndex = (text: string) => {
    return preparedUnit.findIndex((item: any) => item === text);
  };

  const styledInput = (value: string, text: string) => (
    <>
      <StyledInput
        key={text}
        value={value}
        onBlur={() => onBlur(findIndex(text))}
        onClick={(e: any) => e.target.select()}
        onKeyDown={(e: any) => {
          if (+e.keyCode === 13) {
            e.target.blur();
          }
        }}
        onChange={(e: any) =>
          !isNaN(+e.currentTarget.value) && e.currentTarget.value.length <= 5 && handleOnChange(e, findIndex(text))
        }
        width={`${`${value}`.length >= 2 ? 20 * `${value}`.length : 32 * `${value}`.length}px `}
      />
      <StyledTextUnit color={gray2} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
        {text}
      </StyledTextUnit>
    </>
  );

  const data = convertTimeDataForInputs(
    value,
    isHHMM ? TableInputType.TIME_PICKER_FIELDS_HHMM : TableInputType.TIME_PICKER_FIELDS,
  );

  return (
    <Wrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      {isEditInProcess ? (
        <PeriodsWrapper>
          <DaysWrapper>
            {styledInput(isHHMM ? value[1] : value[0], isHHMM ? preparedUnit[1] : preparedUnit[0])}
          </DaysWrapper>
          <HoursWrapper>
            {styledInput(isHHMM ? value[2] : value[1], isHHMM ? preparedUnit[2] : preparedUnit[1])}
          </HoursWrapper>
        </PeriodsWrapper>
      ) : (
        <StyledText color={gray1} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
          {data[0]} {isHHMM ? translatedUnits(preparedUnit[1]) : translatedUnits(preparedUnit[0])}, {data[1]}{' '}
          {isHHMM ? translatedUnits(preparedUnit[2]) : translatedUnits(preparedUnit[1])}
        </StyledText>
      )}
    </Wrapper>
  );
};
