import React, { useCallback, useEffect } from 'react';

import ss from '../../utils/ss';
import oauthRedirect, { clearNfc, oauthClear } from './oauthRedirect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation /*, useLocation*/, useNavigate, useParams } from '@cthings.co/router-dom';
import {
  setIsAuthenticated,
  selectIsAuthenticated,
  setNfcFlag,
  selectNfcFlag,
  setProvisioningData,
  selectProvisioningData,
} from '../../app/state/app';
import { LoadingScreen } from '../loadingScreen/LoadingScreen';
import { LsValueType } from '../../enums/LsValueType';
import { store } from '../../app/store';
import { useHistory } from '../../utils/react-router-dom-abstraction';
//import { selectUserSettingsCompleted } from '../../app/state/user';

const danNID = 'platform.nid.dk';
const swedNID = 'platform.nidnet.se';
const polNID = 'mpwik.intelliventory.ai';

const defaultLang = window.location.href.includes(danNID)
  ? { name: 'Dansk', id: 'da_dk', shortName: 'DA' }
  : window.location.href.includes(swedNID)
  ? { name: 'Svenska', id: 'sv_se', shortName: 'SV' }
  : window.location.href.includes(polNID)
  ? { name: 'Polski', id: 'pl_pl', shortName: 'PL' }
  : {
      name: 'English',
      id: 'en_gb',
      shortName: 'EN',
    };

const withAuth = (Component: any) => {
  const AuthComponent = (props: any) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const nfcFlag = useSelector(selectNfcFlag);
    const provisioningData = useSelector(selectProvisioningData);
    const { secret: existingSecret, transport_id: existingTransportId } = provisioningData;
    const history = useHistory();
    const location = useLocation();
    const navigate = useNavigate();

    const processParams = useCallback(
      (params: any) => {
        const nfc = params.get('nfc');
        const secret = params.get('secret');
        const transport_id = params.get('transport_id');
        const nfcCondition = !nfcFlag && nfc;
        const provisioningCondition = !existingSecret && !!secret && !existingTransportId && !!transport_id;
        if (nfcCondition) {
          store.dispatch(setNfcFlag(true));
        }
        if (provisioningCondition) {
          store.dispatch(setProvisioningData({ secret, transport_id }));
        }
        if (nfcCondition || provisioningCondition) {
          clearNfc(history, location.pathname);
        }
      },
      [nfcFlag, history, location.pathname],
    );

    //const status = useSelector(selectUserSettingsCompleted);
    //const currentPath = useLocation().pathname;
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);

      if (!isAuthenticated && !(ss.get(LsValueType.token) && Math.floor(Date.now() / 1000) < ss.get(LsValueType.exp))) {
        const token = urlParams.get('access_token');
        const exp = urlParams.get('expires');
        const lang = urlParams.get('lang');

        if (exp) {
          ss.set(LsValueType.exp, exp);
        }
        if (!ss.get(LsValueType.locale)) {
          ss.set(LsValueType.locale, defaultLang.id);
        } else if (lang) {
          ss.set(LsValueType.locale, lang);
        }

        if (token) {
          ss.set(LsValueType.token, token);
          oauthClear();
        }

        if ((token || ss.get(LsValueType.token)) && Math.floor(Date.now() / 1000) < (exp || ss.get(LsValueType.exp))) {
          processParams(urlParams);
          dispatch(setIsAuthenticated(true));
        } else {
          oauthRedirect();
        }
      } else {
        processParams(urlParams);
        dispatch(setIsAuthenticated(true));

        const params = new URLSearchParams(location.search);
        const keysToRemove = ['access_token', 'expires'];

        const paramsModified = keysToRemove.some((key) => {
          if (params.has(key)) {
            params.delete(key);
            return true;
          }
          return false;
        });

        if (paramsModified) {
          navigate({ search: params.toString() }, { replace: true });
        }
      }
    }, [dispatch, isAuthenticated, nfcFlag, location]);

    if (!isAuthenticated) {
      return <LoadingScreen />;
    }

    return <Component {...props} />;
  };

  return AuthComponent;
};

export default withAuth;
