import styled from '@cthings.co/styled-components';
import React, { FC } from 'react';
import { Address } from './components/address/Address';
import { Email } from './components/email/Email';
import { NumberOfDevices } from './components/numberOfDevices/NumberOfDevices';
import { Phone } from './components/phone/Phone';
import { SelectField } from './components/select/Select';
import { Textfield } from './components/textfield/Textfield';
import { TableInputType } from './types';
import { TimePickerFields } from './components/timePickers/timePickerFields/TimePickerFields';
import { TimePickerFieldsHHMM } from './components/timePickers/timePickerFieldsHHMM/TimePickerFieldsHHMM';
import { SliderFreeInputWithUnit } from './components/sliders/sliderWithInputAndUnit/SliderFreeInputWithUnit';
import { SliderRangeFreeInputWithUnit } from './components/sliders/rangeWithInputAndUnit/SliderRangeFreeInputWithUnit';

const Wrapper = styled.div`
  width: 100%;
  min-height: 32px;
  height: 100%;
  display: flex;
  align-items: center;
`;

interface UniversalTableInputProps {
  value: {
    type: TableInputType;
    actual_value: any;
    requested_value: any;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  valuesList?: any[];
  isEditInProcess?: boolean;
  isError?: boolean;
  placeholder?: string;
  saveToggle?: boolean;
  options?: Partial<{ [key in TableInputType]: any }>;
  handleCancel?: Function;
  handleConfirm?: Function;
  highlightValue?: string | any;
}

export const UniversalTableInput: FC<UniversalTableInputProps> = ({
  value,
  state,
  setField,
  valuesList,
  isEditInProcess,
  isError,
  placeholder,
  saveToggle,
  options,
  handleCancel,
  handleConfirm,
  highlightValue,
}) => {
  const renderInput = (type: TableInputType) => {
    const data: any = {
      [TableInputType.TEXTFIELD]: (() => (
        <Textfield
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
          placeholder={placeholder}
        />
      ))(),
      [TableInputType.PHONE]: (() => (
        <Phone
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
          placeholder={placeholder}
        />
      ))(),
      [TableInputType.EMAIL]: (() => (
        <Email
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
          placeholder={placeholder}
        />
      ))(),
      [TableInputType.ADDRESS]: (() => (
        <Address
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
          placeholder={placeholder}
          saveToggle={saveToggle}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      ))(),
      [TableInputType.NUMBER_OF_DEVICES]: (() => (
        <NumberOfDevices
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
        />
      ))(),
      [TableInputType.TEXTFIELD_NUMBER]: (() => (
        <Textfield
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
          placeholder={placeholder}
          isNumber
        />
      ))(),
      [TableInputType.SELECT]: (() => (
        <SelectField
          value={value}
          valuesList={valuesList}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          options={{ ...((options || {})[type] || {}), isError, placeholder }}
        />
      ))(),
      [TableInputType.SLIDER_FREE_INPUT_WITH_UNIT]: (() => (
        <SliderFreeInputWithUnit
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          highlightValue={highlightValue}
        />
      ))(),
      [TableInputType.SLIDER_RANGE_FREE_INPUT_WITH_UNIT]: (() => (
        <SliderRangeFreeInputWithUnit
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          highlightValue={highlightValue}
        />
      ))(),
      [TableInputType.TIME_PICKER_FIELDS_HHMM]: (() => (
        <TimePickerFieldsHHMM
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
          highlightValue={highlightValue}
        />
      ))(),
      [TableInputType.TIME_PICKER_FIELDS]: (() => (
        <TimePickerFields
          value={value}
          state={state}
          setField={setField}
          isEditInProcess={isEditInProcess}
          isError={isError}
          highlightValue={highlightValue}
        />
      ))(),
    };

    return data[type];
  };

  return <Wrapper>{renderInput(value.type)}</Wrapper>;
};
