import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type CircleType = {
  height?: string;
  width?: string;
};

const Circle = styled.div<CircleType>`
  height: ${({ height }) => height || '130px'};
  width: ${({ width }) => width || '130px'};
  box-sizing: border-box;
  background-color: ${colorFetch('gray5')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SecondCircle = styled.div`
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  box-sizing: border-box;
  background-color: ${colorFetch('gray4')};
  border-radius: 50%;
`;

export interface ComponentProps {
  width?: string;
  height?: string;
}

export const PlaceholderCircle: FC<ComponentProps> = ({ width, height }) => {
  return (
    <Circle width={width} height={height}>
      <SecondCircle />
    </Circle>
  );
};
