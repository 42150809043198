import React from 'react';
import styled from '@cthings.co/styled-components';
import { ArrowButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';

const Wrappper = styled.div`
  width: 100%;
`;

export const TestContainer = () => {
  const theme = useTheme();

  // @NOTE place to test your components, do commit it to git
  return (
    <Wrappper>
      <ArrowButton onClick={() => {}} />
      {/* <SimpleIcon theme={theme} iconType={SimpleIconType.ARROW_UP} /> */}
    </Wrappper>
  );
};
