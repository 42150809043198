import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CloseButton } from '@bit/first-scope.close-button';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  title?: string;
  isFieldScrollable?: boolean;
  isLastSelectedCheap?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: max-content;
  height: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${colorFetch('gray5')};
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  margin: ${({ title, isFieldScrollable, isLastSelectedCheap }) =>
    title ? '6px 6px 0 0' : isFieldScrollable && isLastSelectedCheap ? '2px 6px 2px 0' : '2px 6px 4px 0'};
`;

export const Cheap = ({
  value,
  closeOnClick,
  isFieldGrowed,
  isFieldScrollable,
  isLastSelectedCheap,
  title,
  isEditInProcess,
}: {
  value: string;
  closeOnClick: () => void;
  isFieldGrowed?: boolean;
  isFieldScrollable?: boolean;
  isLastSelectedCheap?: boolean;
  title?: string;
  isEditInProcess?: boolean;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;

  return (
    <Wrapper
      theme={theme}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      isFieldScrollable={isFieldScrollable}
      isLastSelectedCheap={isLastSelectedCheap}
      title={title}
    >
      <Text type={TextType.TEXT_12_GRAY} color={gray1} margin={'0 6px 0 0'} weight={'500'}>
        {value}
      </Text>
      {title && <CloseButton onClick={closeOnClick} color={gray2} width={'12px'} height={'12px'} strokeWidth={'2px'} />}
      {!title && isEditInProcess && (
        <CloseButton onClick={closeOnClick} color={gray2} width={'12px'} height={'12px'} strokeWidth={'2px'} />
      )}
    </Wrapper>
  );
};
