import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../app/state/user';
import Tooltip, { useTooltip } from '../../../../components/tooltip';
import { TableInputType } from '../../types';
import { RequestBlock } from '../requestedBlock/RequestedBlock';

const Wrapper = styled.div`
  height: max-content;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  flex: 1;
  word-break: break-all;
  gap: 5px;
  cursor: pointer;

  &.wrapper {
    display: inline-flex;
  }
`;

const StyledTooltip = styled(Tooltip)`
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  max-width: 215px !important;
  width: max-content !important;
`;

interface NumberOfDevicesProps {
  value: {
    type: TableInputType;
    actual_value: string | any;
    requested_value: string | null;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  isEditInProcess?: boolean;
  isError?: boolean;
}

export const NumberOfDevices: FC<NumberOfDevicesProps> = ({ value, state, setField, isEditInProcess }) => {
  const theme = useTheme();
  const { gray1, gray2, green2, orange2 } = theme.colors;

  const ref = useRef<any>();

  const { type, actual_value, requested_value, with_requested, is_editable } = value;

  const { deployed, provisioned, total } = actual_value;

  const languageStrings = useSelector(selectLanguageStrings);
  const deployedTooltipText = languageStrings.deployed;
  const provisionedTooltipText = languageStrings.provisioned;
  const totalTooltipText = languageStrings.totalNumberOfDevices;

  const [tooltipIds] = useTooltip(3);

  return (
    <Wrapper>
      <StyledText type={TextType.TEXT_14_GRAY} color={gray2} className={'wrapper'}>
        <StyledTooltip id={tooltipIds[0]} text={deployedTooltipText}>
          <StyledText type={TextType.TEXT_14_GRAY} color={green2}>
            {deployed.number}
          </StyledText>
        </StyledTooltip>{' '}
        /{' '}
        <StyledTooltip id={tooltipIds[1]} text={provisionedTooltipText}>
          <StyledText type={TextType.TEXT_14_GRAY} color={orange2}>
            {provisioned.number}
          </StyledText>
        </StyledTooltip>{' '}
        /{' '}
        <StyledTooltip id={tooltipIds[2]} text={totalTooltipText}>
          <StyledText type={TextType.TEXT_14_GRAY} color={gray1}>
            {total.number}
          </StyledText>
        </StyledTooltip>
      </StyledText>
      {with_requested && <RequestBlock requested_value={requested_value} />}
    </Wrapper>
  );
};
