import React, { ReactNode, useEffect, useState } from 'react';
import { Placeholder } from '../../components/placeholders/Placeholders';
import { PlaceholderType } from '../../components/placeholders/typePlaceholders/placeholdersType';
import { PlaceholderContextProvider, usePlaceholderContext } from './placeholder.context';
import styled from '@cthings.co/styled-components';
import { use100vh } from 'react-div-100vh';

export const withPlaceholderComponent = (typePlaceholder: PlaceholderType, isLoading?: string[]) => (
  Component: any,
) => ({ ...props }) => {
  return (
    <PlaceholderContextProvider>
      <PlaceholderComponent typePlaceholder={typePlaceholder}>
        <Component {...props} />
      </PlaceholderComponent>
    </PlaceholderContextProvider>
  );
};

type ExtraWrapperProps = {
  height: number | null;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  width: 100%;
  min-height: ${({ height }) => height + 'px' || '100vh'};
  overflow: hidden;
  position: relative;
`;

type ChildrenWrapperProps = {
  height: number | null;
};

const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  width: 100%;
  height: ${({ height }) => height + 'px' || '100vh'};
  &.hidden {
    overflow: hidden;
    position: absolute;
    z-index: var(--hidden-layer);
    visibility: hidden;
    opacity: 0;
  }
`;

export const PlaceholderComponent = ({
  children,
  typePlaceholder,
  options,
}: {
  children: ReactNode;
  typePlaceholder: PlaceholderType;
  options?: any;
}) => {
  const [placeholder] = usePlaceholderContext();

  const height = use100vh();

  return (
    <ExtraWrapper height={height}>
      <ChildrenWrapper height={height} className={!placeholder.skeleton ? 'hidden' : ''}>
        {children}
      </ChildrenWrapper>
      {!placeholder.skeleton && <Placeholder type={typePlaceholder} options={options} />}
    </ExtraWrapper>
  );
};

export const withPlaceholderComponentReduced = (typePlaceholder: PlaceholderType, options?: any) => (
  Component: any,
) => ({ ...props }) => {
  return (
    <PlaceholderComponent typePlaceholder={typePlaceholder} options={options}>
      <Component {...props} />
    </PlaceholderComponent>
  );
};

export const withPlaceholderProvider = (Component: any) => ({ ...props }) => {
  return (
    <PlaceholderContextProvider>
      <Component {...props} />
    </PlaceholderContextProvider>
  );
};

export const usePlaceholderOptions = (setPlaceholder: any, trigger: any[]) => {
  const [isInit, setIsInit] = useState(false);
  const handleLoader = (flag: boolean) => {
    setPlaceholder((value: any) => ({ ...value, loader: flag }));
  };
  const handleSkeleton = (flag: boolean) => {
    setPlaceholder((value: any) => ({ ...value, skeleton: flag }));
  };
  useEffect(() => {
    !isInit && setIsInit(true);
  }, [...trigger]);
  return !isInit ? { setPlaceholder: handleSkeleton } : { setPlaceholder: handleLoader };
};
