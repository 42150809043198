import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { InputModal } from '../../../../inputModal/InputModal';
import { MINtoHHMM } from '../../../../../utils/date/functions';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1 1;
  align-items: center;
  position: relative;
`;

type StyledInputProps = {
  width?: string;
};

const StyledInput = styled(InputModal)<StyledInputProps>`
  width: ${({ width }) => width || '65px'};
  margin: 0 6px 0 0;
  & > div > span {
    margin: 0 0 4px 8px;
  }
  ${media.phone} {
    width: 100%;
  }
  & div {
    margin: 0;
  }
  & div > input {
    height: 32px;
    padding: 4px 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;

    ${media.phone} {
      text-align: left;
      height: 40px;
    }
  }
` as any; // @TODO fix type

const StyledText = styled(Text)`
  word-break: break-all;
`;

export const TimePicker = ({
  value,
  onChange,
  options,
  isEditInProcess,
  className,
}: {
  value: string;
  onChange: Function;
  options: { title?: string; width?: string; isDisabled?: boolean; onBlur?: Function };
  isEditInProcess?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const [oldValue, setOldValue] = useState('0000');

  const [time, setTime] = useState('0000');
  const [isEdit, setIsEdit] = useState(false);

  const { title, width, isDisabled, onBlur } = options;

  useEffect(() => {
    setTime(value ?? getRequiredDateFormat(new Date(), 'HHmm'));
  }, [value]);

  const handleOnChange = (e: any) => {
    if (e.currentTarget.value.length === 5 && e.currentTarget.value.slice(-2) >= 60) return;
    !isNaN(+e.currentTarget.value) && e.currentTarget.value.length <= 5 && onChange(e.currentTarget.value);
  };

  const handleOnBlur = () => {
    switch (time.length) {
      case 0:
        setTime(oldValue);
        onBlur && onBlur(oldValue);
        break;
      case 1:
        setTime(`0${time}00`);
        onBlur && onBlur(`0${time}00`);
        break;
      case 2:
        if (+time < 24) {
          setTime(`${time}00`);
          onBlur && onBlur(`${time}00`);
        }
        if (+time === 24) {
          setTime(`0000`);
          onBlur && onBlur(`0000`);
        }
        if (+time > 24) {
          setTime(`0${time[0]}0${time[1]}`);
          onBlur && onBlur(`0${time[0]}0${time[1]}`);
        }
        break;
      case 3:
        if (+time.slice(-2) < 60) {
          setTime(`0${time}`);
          onBlur && onBlur(`0${time}`);
        } else {
          setTime(
            !isNaN(
              +`${
                (+time.slice(0, 1) + +MINtoHHMM(time.slice(-2)).slice(0, 2) + '').length === 1
                  ? `0${
                      +time.slice(0, 1) +
                      +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                      '' +
                      +MINtoHHMM(time.slice(-2)).slice(-2)
                    }`
                  : +time.slice(0, 1) +
                    +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                    '' +
                    +MINtoHHMM(time.slice(-2)).slice(-2)
              }`,
            )
              ? `${
                  (+time.slice(0, 1) + +MINtoHHMM(time.slice(-2)).slice(0, 2) + '').length === 1
                    ? `0${
                        +time.slice(0, 1) +
                        +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                        '' +
                        +MINtoHHMM(time.slice(-2)).slice(-2)
                      }`
                    : +time.slice(0, 1) +
                      +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                      '' +
                      +MINtoHHMM(time.slice(-2)).slice(-2)
                }`
              : oldValue,
          );

          onBlur &&
            onBlur(
              !isNaN(
                +`${
                  (+time.slice(0, 1) + +MINtoHHMM(time.slice(-2)).slice(0, 2) + '').length === 1
                    ? `0${
                        +time.slice(0, 1) +
                        +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                        '' +
                        +MINtoHHMM(time.slice(-2)).slice(-2)
                      }`
                    : +time.slice(0, 1) +
                      +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                      '' +
                      +MINtoHHMM(time.slice(-2)).slice(-2)
                }`,
              )
                ? `${
                    (+time.slice(0, 1) + +MINtoHHMM(time.slice(-2)).slice(0, 2) + '').length === 1
                      ? `0${
                          +time.slice(0, 1) +
                          +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                          '' +
                          +MINtoHHMM(time.slice(-2)).slice(-2)
                        }`
                      : +time.slice(0, 1) +
                        +MINtoHHMM(time.slice(-2)).slice(0, 2) +
                        '' +
                        +MINtoHHMM(time.slice(-2)).slice(-2)
                  }`
                : oldValue,
            );
        }
        break;
      case 4:
        if (+time.slice(0, 2) >= 24) {
          setTime(oldValue);
          onBlur && onBlur(oldValue);
        }
        if (+time.slice(0, 2) < 24 && +time.slice(-2) < 60) {
          setTime(`${time}`);
          onBlur && onBlur(`${time}`);
        }
        if (+time.slice(0, 2) < 24 && +time.slice(-2) >= 60) {
          setTime(
            !isNaN(
              +`${
                (((+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24) + '').length === 1
                  ? `0${(+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24}`
                  : (+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24
              }${MINtoHHMM(time.slice(-2)).slice(-2)}`,
            )
              ? `${
                  (((+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24) + '').length === 1
                    ? `0${(+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24}`
                    : (+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24
                }${MINtoHHMM(time.slice(-2)).slice(-2)}`
              : oldValue,
          );
          onBlur &&
            onBlur(
              !isNaN(
                +`${
                  (((+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24) + '').length === 1
                    ? `0${(+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24}`
                    : (+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24
                }${MINtoHHMM(time.slice(-2)).slice(-2)}`,
              )
                ? `${
                    (((+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24) + '').length === 1
                      ? `0${(+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24}`
                      : (+time.slice(0, 2) + +MINtoHHMM(time.slice(-2)).slice(0, 2)) % 24
                  }${MINtoHHMM(time.slice(-2)).slice(-2)}`
                : oldValue,
            );
        }
        break;
      default:
        setTime(oldValue);
        onBlur && onBlur(oldValue);
    }
    setIsEdit(false);
  };

  return (
    <Wrapper className={className}>
      {isEditInProcess ? (
        <>
          <StyledInput
            value={isEdit ? time : isDisabled && !value ? 'N/A' : time.slice(0, time.length - 2) + ':' + time.slice(-2)}
            name={title}
            onFocus={() => {
              setOldValue(time);
              setIsEdit(true);
            }}
            onBlur={handleOnBlur}
            onClick={(e: any) => e.target.select()}
            onKeyDown={(e: any) => {
              if (+e.keyCode === 13) {
                e.target.blur();
              }
            }}
            width={width}
            onChange={handleOnChange}
            disabled={isDisabled}
          />
        </>
      ) : (
        <>
          <StyledText color={gray1} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
            {time ? time.slice(0, time.length - 2) + ':' + time.slice(-2) : 'N/A'}
          </StyledText>
        </>
      )}
    </Wrapper>
  );
};
