import { stringifyUrl } from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleRegisterNewEvent } from '../../../../../../api/devices/historyInsight';
import { useRealTimeInsights } from '../../../../../../api/realTimeInsight';
import { selectNfcFlag, setNfcFlag } from '../../../../../../app/state/app';
import { resetRealTimeData } from '../../../../../../app/state/insight';
import { selectIsAccess, selectLanguageStrings } from '../../../../../../app/state/user';
import { Loader } from '../../../../../../components/placeholders/Loader';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { useLoaderFunctions } from '../../../../../../features/placeholderComponent/loader.context';
import { View } from '../../../../../../routes';
import { PATHS, getPath, injectPathParams } from '../../../../../../routes/paths';
import { StatusOfCard } from '../../../../../../types/statusOfCards';
import { getRequiredDateFormat } from '../../../../../../utils/date/date-format';
import { useHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { DeployBlock } from '../common/deployBlock/DeployBlock';
import { SetDepthModal } from '../common/deployModals/setDepth/SetDepthModal';
import { SetOtherParametersModal } from '../common/deployModals/setOtherParameters/SetOtherParametersModal';
import { DeviceConfig } from '../common/deviceConfig/DeviceConfig';
import { DeviceTitle } from '../common/deviceTitle/DeviceTitle';
import { EventTableBlock } from '../common/eventTableBlock/EventTableBlock';
import { InsightsHeader } from '../common/insightsHeader/InsightsHeader';
import { Content, Wrapper } from '../common/styledComponents/StyledComponents';
import { NFCModal } from '../hydrantDetails/components/nFCModal/NFCModal';
import { Cards } from './components/cards/Cards';
import { History } from './components/history/History';
import { DefaultFilters } from '../InsightsDetails';

interface ManholeDetailsProps {
  pathParams: { id: string; type: string };
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  infoCardData?: any;
  defaultFilters: DefaultFilters;
}

export const ManholeDetails: FC<ManholeDetailsProps> = ({ pathParams, accessData, infoCardData, defaultFilters }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const id = pathParams.id || '';
  const type = pathParams.type || '';
  const isInsigthDetailsView = accessData?.view;

  const keywordList = [
    'ManholeDetailsMap',
    'ManholeDetailsProblemsButton',
    'ManholeDetailsCard',
    'ManholeDetailsGraphWaterLevel',
    'ManholeDetailsGraphTemperature',
    'ManholeDetailsEventsGraph',
    'ManholeDetailsDeviceConfigurationTable',
    'setDepthModalLoader',
  ];

  const languageStrings = useSelector(selectLanguageStrings);
  const isAccess = useSelector(selectIsAccess);
  const nfcFlag = useSelector(selectNfcFlag);

  const { addNotification } = useNotifierFunctions();
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();

  // Main data
  const data = useRealTimeInsights(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );

  // variabels from data
  const { address, transport_id: idDevice, deployment_status, icon_type } = data.info;
  const isDeployed = deployment_status === 2;

  const [deploymentStatus, setDeploymentStatus] = useState<number>();

  const [nfcMessage, setNfcMessage] = useState('');

  const { rt } = data.data;
  const { water_level, temp } = rt || {};

  const handleCopyText = async (copiedText: string) => {
    await navigator?.clipboard?.writeText(copiedText);
    addNotification({ message: languageStrings.copiedMessage, type: 'info' });
  };

  const onDeployPress = () => {
    setIsOpenSetDepthModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(resetRealTimeData());
    };
  }, []);

  useEffect(() => {
    if (isAccess && !isInsigthDetailsView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isInsigthDetailsView]);

  useEffect(() => {
    if (nfcFlag && idDevice && id) {
      const currentDate = new Date();
      const nfcData = {
        event_id: 5,
        is_problem: false,
        device_id: id,
        transport_id: idDevice,
        address,
        ts: getRequiredDateFormat(currentDate, 'YYYY-MM-DDTHH:mm:ss'),
      };
      handleRegisterNewEvent(nfcData, setNfcMessage);
    }
  }, [nfcFlag, idDevice, id]);

  useEffect(() => {
    setDeploymentStatus(deployment_status);
  }, [deployment_status]);

  const statusOfValue = (status: number) => {
    const data: any = {
      0: StatusOfCard.NORMAL,
      1: StatusOfCard.WARNING,
      2: StatusOfCard.CRITICAL,
    };

    return data[status] || StatusOfCard.NORMAL;
  };

  const [isOpenSetDepthModal, setIsOpenSetDepthModal] = useState(false);
  const [isOpenSetOtherParametersModal, setIsOpenSetOtherParametersModal] = useState(false);

  const handleSetParameters = () => {
    history.push(
      stringifyUrl({
        url: injectPathParams(getPath(View.USER_MANAGE_DEVICES_DETAILS), { id: id, offset: 0 }),
      }),
    );
  };

  return (
    <Wrapper>
      <InsightsHeader
        languageStrings={languageStrings}
        address={address}
        type={icon_type || type}
        id={id}
        isDeployed={isDeployed}
        // openReportModal={() => setIsOpenModal(true)}
      />
      <Content>
        <DeviceTitle
          languageStrings={languageStrings}
          address={address}
          deviceId={idDevice}
          handleCopyText={handleCopyText}
        />
        {deploymentStatus !== undefined ? (
          isDeployed ? (
            <>
              <Cards
                waterLevel={water_level}
                temp={temp}
                statusOfWater={statusOfValue(water_level.tile_color)}
                statusOfTemperature={statusOfValue(temp.tile_color)}
                infoCardData={infoCardData}
              />
              <History defaultFilters={defaultFilters} />
              <EventTableBlock languageStrings={languageStrings} pathParams={pathParams} isInDevices />
              <DeviceConfig id={id} photos={data.info.images} />
            </>
          ) : (
            <DeployBlock
              id={id}
              languageStrings={languageStrings}
              isLoading={false}
              deploymentStatus={deploymentStatus}
              onDeployPress={onDeployPress}
              photos={data.info.images}
              infoCardData={infoCardData}
            />
          )
        ) : (
          <Loader />
        )}
      </Content>
      {/* Temporarily disabling */}
      {/* <GenerateReportModal
              location={location}
              customer={customer}
              recipients={recipient}
              isOpenModal={isOpenModal}
              closeModal={closeModal}
              generateOnClick={handleGenerateReport}
              languageStrings={languageStrings}
              locationId={id}
            /> */}
      <NFCModal
        isOpenModal={nfcFlag && !!nfcMessage}
        handleCloseModal={() => {
          dispatch(setNfcFlag(false));
        }}
        languageStrings={languageStrings}
        message={nfcMessage}
      />
      <SetDepthModal
        isOpen={isOpenSetDepthModal}
        handleClose={() => {
          setIsOpenSetDepthModal(false);
        }}
        setIsOpenSetOtherParametersModal={setIsOpenSetOtherParametersModal}
      />
      <SetOtherParametersModal
        isOpen={isOpenSetOtherParametersModal}
        handleClose={() => setIsOpenSetOtherParametersModal(false)}
        handleSetParameters={handleSetParameters}
      />
    </Wrapper>
  );
};
