import React, { FC, useEffect } from 'react';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';
import { ReactComponent as LocationIcon } from '../../assets/location.svg';
import { useTableContext } from '../../features/universalTable/context';
import { TableCustomComponentState } from '../../features/universalTable/types';
import { Select } from '../select/Select';
import { AddressPopupDescription } from './components/addressPopupDescription/AddressPopupDescription';
import { LocationPopup } from './components/locationPopup/LocationPopup';
import { MobileEditAddress } from './components/mobileEditAddress/MobileEditAddress';
import { MAPBOX_ZOOM } from './data';
import { StyledModal, StyledModalContainer, StyledText } from './styled';
import { SelectAddressProps } from './types';
import { useAddressStructure } from './utils/useAddressStructure';
import { useDynamicStyles } from './utils/useDynamicStyles';
import { useHandlers } from './utils/useHandlers';
import { useLocalChanges } from './utils/useLocalChanges';
import { useTheme } from '@cthings.co/styled-components';
import { useStyleContext } from '@cthings.co/styles-utils';

export const SelectAddress: FC<SelectAddressProps> = ({
  address,
  titleOfPopup,
  setValue,
  state,
  saveToggle,
  isInUniversalTable = true,
  handleUpdateDevice,
  setExternalState,
  externalState,
  isShowSelect,
  isDescriptionModal,
  isInstructions,
  label,
  isWhiteBackground,
  className,
  isModalTriggered,
  onCancel,
  onConfirm,
  handleSaveAsMainLocation,
  isNotMainLocation = false,
  isGps = false,
}) => {
  const theme = useTheme();

  const languageStrings = useSelector(selectLanguageStrings);
  const [
    {
      mediaType: { tablet },
    },
  ] = useStyleContext();

  const [
    { inputs, altitude, localAddress, areAllFieldsValid },
    { setAltitude, setLocalAddress },
  ] = useAddressStructure({ address, isGps });

  const [
    {
      localSearch,
      selectedPos,
      validatingIsStarted,
      searchCentre,
      isAddressEmpty,
      centreLat,
      centreLon,
      savedAddress,
      isOpenPopupGlobal,
      position,
    },
    {
      setConfirmToggle,
      setIsOpenPopup,
      setSearch,
      setLocalSearch,
      setSelectedPos,
      setValidatingIsStarted,
      setSearchCentre,
      setSavedAddress,
    },
  ] = useLocalChanges({
    localAddress,
    address,
    externalState: !!externalState,
    saveToggle,
    isInUniversalTable,
  });

  const value = {
    id: 'value',
    name: address ? `${address.city}${address.city && address.line1 && `, `}${address.line1}` : '',
  };

  const selectValues = [
    {
      id: 'value',
      name: address.city
        ? `${address.city}${address && address.city && address.line1 && `, `}${address.line1}`
        : languageStrings.selectValuePlaceholder,
    },
  ];

  const [{ isEditInProcess, isAddInProcess }] = useTableContext();
  const disabled = !isEditInProcess && !isAddInProcess;

  const {
    handleOnClickOpen,
    handleLocationChange,
    handleSelectedPos,
    handleCancel,
    handleConfirm,
    handleSetLocalAddress,
    locationTimerId,
    handleLatChange,
    handleLonChange,
  } = useHandlers({
    setIsOpenPopup,
    setSavedAddress,
    setConfirmToggle,
    setSearch,
    setLocalSearch,
    setSearchCentre,
    setSelectedPos,
    setValidatingIsStarted,
    setValue,
    setLocalAddress,
    setExternalState,
    setAltitude,
    handleUpdateDevice,
    savedAddress,
    localAddress,
    isAddressEmpty,
    areAllFieldsValid,
  });

  const { modalStyles, descriptionModalContainerStyles, modalContainerStyles } = useDynamicStyles();

  useEffect(() => {
    if (!!isModalTriggered) {
      handleOnClickOpen();
    }
  }, [isModalTriggered]);

  return (
    <>
      {isInUniversalTable &&
        (state === TableCustomComponentState.VIEW ? (
          <StyledText type={TextType.TEXT_14_GRAY} color={theme.colors.gray1} weight={'400'}>
            {address.city.length > 0 && address.line1.length > 0 ? (
              <>
                {address.city}
                {address.line1?.length && `, ${address.line1}`}
                {address.line2?.length && `, ${address.line2}`}
              </>
            ) : (
              'N/A'
            )}
          </StyledText>
        ) : (
          <Select
            value={value}
            disabled={isShowSelect ? false : disabled}
            values={selectValues}
            onChange={() => {}}
            onClick={handleOnClickOpen}
            selectIcon={() => <LocationIcon />}
            languageStrings={languageStrings}
            label={label}
            isWhiteBackground={isWhiteBackground}
            className={className}
          />
        ))}
      {!tablet && (
        <StyledModal isOpenModal={isOpenPopupGlobal} {...modalStyles}>
          {isDescriptionModal && (
            <StyledModalContainer width={'226px'} {...descriptionModalContainerStyles}>
              <AddressPopupDescription languageStrings={languageStrings} />
            </StyledModalContainer>
          )}
          <ModalContainer {...modalContainerStyles}>
            <LocationPopup
              address={address}
              title={titleOfPopup}
              inputArr={inputs}
              position={position}
              centreLat={centreLat}
              centreLon={centreLon}
              zoom={MAPBOX_ZOOM}
              localSearch={localSearch}
              selectedPos={selectedPos}
              locationTimerId={locationTimerId}
              searchCentre={searchCentre}
              setLocalSearch={setLocalSearch}
              setLocalAddress={setLocalAddress}
              handleSetAddressFieldValue={setValue}
              handleSelectedPos={handleSelectedPos}
              handleLocationChange={handleLocationChange}
              handleCancel={(...props) => {
                handleCancel(...props);
                onCancel && onCancel();
              }}
              handleSetLocalAddress={handleSetLocalAddress}
              handleConfirm={(...props) => {
                onConfirm && onConfirm();
                handleConfirm(...props);
              }}
              languageStrings={languageStrings}
              altitude={altitude}
              validatingIsStarted={validatingIsStarted}
              handleSaveAsMainLocation={handleSaveAsMainLocation}
              isNotMainLocation={isNotMainLocation}
              isGps={isGps}
              handleLatChange={handleLatChange}
              handleLonChange={handleLonChange}
            />
          </ModalContainer>
        </StyledModal>
      )}
      {tablet && isOpenPopupGlobal && (
        <MobileEditAddress
          address={address}
          inputArr={inputs}
          position={position}
          centreLat={centreLat}
          centreLon={centreLon}
          zoom={MAPBOX_ZOOM}
          localSearch={localSearch}
          selectedPos={selectedPos}
          locationTimerId={locationTimerId}
          searchCentre={searchCentre}
          setLocalSearch={setLocalSearch}
          setLocalAddress={setLocalAddress}
          handleSetAddressFieldValue={setValue}
          handleSelectedPos={handleSelectedPos}
          handleLocationChange={handleLocationChange}
          handleCancel={(...props) => {
            handleCancel(...props);
            onCancel && onCancel();
          }}
          handleSetLocalAddress={handleSetLocalAddress}
          handleConfirm={(...props) => {
            onConfirm && onConfirm();
            handleConfirm(...props);
          }}
          languageStrings={languageStrings}
          altitude={altitude}
          isAllFieldsValid={areAllFieldsValid}
          validatingIsStarted={validatingIsStarted}
          setValidatingIsStarted={setValidatingIsStarted}
          isInstructions={isInstructions}
          handleSaveAsMainLocation={handleSaveAsMainLocation}
          isNotMainLocation={isNotMainLocation}
          isGps={isGps}
        />
      )}
    </>
  );
};
