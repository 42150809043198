import { createIncidentTypesFunctions } from '../../../api/incidents';
import { SelectValue } from '../../../features/universalTable/types';
import { Dispatch } from 'react';

export const useIncidentTypes = (setTypes: Dispatch<any>) => {
  const { createIncidentType, updateIncidentType, deleteIncidentType } = createIncidentTypesFunctions();

  const handleCreateIncidentType = ({
    body,
    callback,
  }: {
    body: { name: string };
    callback?: (data: SelectValue) => void;
  }) => {
    createIncidentType({
      body,
      handleSuccess: (data) => {
        const { name, id } = data.payload;
        setTypes((values: any) => {
          return {
            ...values,
            incident_types: [...values.incident_types, { name, id }],
            // incident_types_list: [...values.incident_types_list, { name, id }],
            // incident_types: [...values.incident_types_list, { name, id }],
          };
        });
        callback && callback(data);
      },
    });
  };

  const handleUpdateIncidentType = ({
    body,
    callback,
  }: {
    body: SelectValue;
    callback?: (data: SelectValue) => void;
  }) => {
    updateIncidentType({
      body,
      handleSuccess: (data) => {
        const { name, id } = data.payload;

        setTypes((values: any) => {
          const index = values.incident_types.findIndex((item: SelectValue) => item.id === id);
          let newVal = [...values.incident_types];
          if (index !== -1) {
            const itemToChange = values.incident_types[index];
            const newItem = { name, id: itemToChange.id };
            newVal = [...values.incident_types.slice(0, index), newItem, ...values.incident_types.slice(index + 1)];
          }
          return { ...values, incident_types: newVal };
        });
        callback && callback(data);
      },
    });
  };

  const handleDeleteIncidentType = ({
    body,
    handleError,
    callback,
  }: {
    body: { id: string };
    handleError?: (data: any) => void;
    callback?: () => void;
  }) => {
    deleteIncidentType({
      body,
      handleError: handleError,
      handleSuccess: () => {
        const { id } = body;

        setTypes((values: any) => {
          const index = values.incident_types.findIndex((item: SelectValue) => item.id === id);
          let newVal = [...values.incident_types];
          if (index !== -1) {
            newVal = [...values.incident_types.slice(0, index), ...values.incident_types.slice(index + 1)];
          }
          return { ...values, incident_types: newVal };
        });
        callback && callback();
      },
    });
  };

  return { handleCreateIncidentType, handleUpdateIncidentType, handleDeleteIncidentType };
};
