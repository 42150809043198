import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { Address } from '../../../types/address';
import { setValInObj } from '../../../utils/objectDeconstruct';
import { CentreState } from '../components/locationPopup/types';
import { isNaN } from 'underscore';

type HandlersProps = {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  setSavedAddress: Dispatch<SetStateAction<Address>>;
  setConfirmToggle: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
  setLocalSearch: Dispatch<SetStateAction<string>>;
  setSearchCentre: Dispatch<SetStateAction<CentreState>>;
  setSelectedPos: Dispatch<SetStateAction<any>>;
  setValidatingIsStarted: Dispatch<SetStateAction<boolean>>;
  setValue: Dispatch<SetStateAction<Address>>;
  setLocalAddress: Dispatch<SetStateAction<Address>>;
  setAltitude: Dispatch<SetStateAction<string | null>>;
  handleUpdateDevice?: () => void;
  setExternalState?: Function;
  savedAddress: Address;
  localAddress: Address;
  isAddressEmpty: boolean;
  areAllFieldsValid: boolean;
};
export const useHandlers = ({
  setIsOpenPopup,
  setSavedAddress,
  setConfirmToggle,
  setSearch,
  setLocalSearch,
  setSearchCentre,
  setSelectedPos,
  setValidatingIsStarted,
  setValue,
  setLocalAddress,
  setExternalState,
  setAltitude,
  handleUpdateDevice,
  savedAddress,
  localAddress,
  isAddressEmpty,
  areAllFieldsValid,
}: HandlersProps) => {
  const locationTimerId = useRef(null as any);
  const latTimerId = useRef(null as any);
  const lonTimerId = useRef(null as any);

  const handleOnClickOpen = () => {
    setIsOpenPopup(true);
    !isAddressEmpty && setSavedAddress(localAddress);
  };
  const handleOnClickClose = () => {
    setConfirmToggle((val) => !val);
    setIsOpenPopup(false);
  };
  const handleLocationChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      locationTimerId.current && clearTimeout(locationTimerId.current);
      locationTimerId.current = setTimeout(() => {
        setSearch(value);
      }, 500);
      setLocalSearch(value);
    },
    [locationTimerId],
  );

  const handleSelectedPos = useCallback(
    (selectedPosition: any) => {
      setSearchCentre({
        lat: parseFloat(selectedPosition.center[1]),
        lon: parseFloat(selectedPosition.center[0]),
      });
      setSelectedPos(selectedPosition);
    },
    [setSearchCentre, setSelectedPos],
  );

  const handleCancel = () => {
    const { geotag: savedGeotag } = savedAddress;

    setValidatingIsStarted(false);

    setSearch('');
    setSelectedPos(null);
    setSearchCentre({ lat: parseFloat(savedGeotag.lat), lon: parseFloat(savedGeotag.lng) });
    setLocalSearch('');
    savedAddress && setValue(savedAddress);
    setLocalAddress(savedAddress);
    setAltitude(savedGeotag.alt);
    setIsOpenPopup(false);
    setExternalState && setExternalState(false);
  };

  const handleConfirm = () => {
    setValidatingIsStarted(true);
    if (areAllFieldsValid) {
      handleOnClickClose();
      setExternalState && setExternalState(false);
      handleUpdateDevice && handleUpdateDevice();
      setValidatingIsStarted(false);
    }
  };

  const handleSetLocalAddress = useCallback(
    (field: string, value: any, path: string[] = [], callback?: Function) => {
      let newVal = value;
      if (path.length > 0) {
        const currentVal = (localAddress as any)[field];
        newVal = setValInObj(path, currentVal, value);
      }

      callback && callback(value);

      setValue({ ...localAddress, [field]: newVal });

      setLocalAddress((el) => ({ ...el, [field]: newVal }));
    },
    [localAddress, setValue],
  );

  const handleLatChange = useCallback((lat: string) => {
    setValidatingIsStarted(true);

    latTimerId.current && clearTimeout(latTimerId.current);

    const iaValidLatitude = lat && !isNaN(+lat) && +lat <= 90 && +lat >= -90;

    if (iaValidLatitude) {
      latTimerId.current = setTimeout(() => {
        setSearchCentre((prev) => ({ ...prev, lat: parseFloat(lat) }));
        setSelectedPos((prev: any) => ({ ...prev, center: [prev?.center[0], parseFloat(lat)] }));
      }, 500);
    }
  }, []);

  const handleLonChange = useCallback((lon: string) => {
    setValidatingIsStarted(true);

    lonTimerId.current && clearTimeout(lonTimerId.current);

    const isValidLongitude = lon && !isNaN(+lon) && +lon <= 180 && +lon >= -180;

    if (isValidLongitude) {
      lonTimerId.current = setTimeout(() => {
        setSearchCentre((prev) => ({ ...prev, lon: parseFloat(lon) }));
        setSelectedPos((prev: any) => ({ ...prev, center: [parseFloat(lon), prev?.center[1]] }));
      }, 500);
    }
  }, []);

  return {
    handleOnClickOpen,
    handleLocationChange,
    handleSelectedPos,
    handleCancel,
    handleConfirm,
    handleSetLocalAddress,
    locationTimerId,
    handleLatChange,
    handleLonChange,
  };
};
