import { Text, TextType } from '@bit/first-scope.text';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAccess, selectLanguage, selectLanguageStrings } from '../../../../app/state/user';
import { RowItemType } from '../../../../features/universalTable/types';
import { UniversalTable, withTableContextProvider, useTableFunctions } from '@cthings.co/universal-table';
import { useMobileManagementSearch } from '../../../../layouts/managementMobile/useMobileManagement';

import { View } from '../../../../routes/routeInterfaces';
import { ExtraWrapper, StyledLink, StyledTitle, TitleWrapper, Wrapper } from './styles';
import { useTheme } from '@cthings.co/styled-components';
import { useStyleContext } from '@cthings.co/styles-utils';
import { getRequiredDateFormat, HttpService } from '@cthings.co/utils';
import { StatusTextType } from './types';
import { API_URL } from '../../../../consts';
import { constructPath } from '../../../../routes/paths';
import { useQueryParams } from '../../../../utils/useQueryParams';
import { useParams } from '@cthings.co/router-dom';

const InvoicesPlain = () => {
  const theme = useTheme();
  const { blue2, green2, gray1, primary, red2 } = theme.colors;

  const { resetTable, getSearchValue, setSearchValue } = useTableFunctions();

  const languageStrings = useSelector(selectLanguageStrings);
  const { shortName } = useSelector(selectLanguage);

  const keyword = 'InvoicesTable';
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  // @TODO add possibility to use path params by the table
  const pathParams = useParams();

  const accessData = useSelector(selectAccess).management.client;
  const { actual: searchValue } = getSearchValue();

  // const { ultramonitor, monitor, tablet, phone } = useMediaType();
  const [
    {
      mediaType: { ultramonitor, monitor, tablet, phone },
    },
  ] = useStyleContext();

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useMobileManagementSearch(setSearchValue);

  const switchStatusText = (name: string, status: StatusTextType) => {
    switch (status) {
      case StatusTextType.PAID:
        return (
          <Text color={green2} weight={'400'} type={TextType.TEXT_14_BLACK}>
            {name || languageStrings.paid}
          </Text>
        );
      case StatusTextType.PENDING:
        return (
          <Text color={blue2} weight={'400'} type={TextType.TEXT_14_BLACK}>
            {name || languageStrings.pending}
          </Text>
        );
      case StatusTextType.OVERDUE:
        return (
          <Text color={red2} weight={'400'} type={TextType.TEXT_14_BLACK}>
            {name || languageStrings.overdue}
          </Text>
        );
    }
  };

  return (
    <>
      <ExtraWrapper>
        <TitleWrapper>
          <StyledTitle type={TextType.TITLE_H1}>{languageStrings.invoices}</StyledTitle>
        </TitleWrapper>
        <Wrapper>
          <UniversalTable
            HttpService={HttpService}
            theme={theme}
            language={shortName}
            placeholderProps={{
              keyword,
              placeholderOptions: { borderRadiusTable: !tablet },
            }}
            mainApiUrl={`${API_URL}/v2/billing/invoices`}
            apiTriggers={[shortName]}
            keyword={keyword}
            offset={+offset}
            offsetKey={offsetKey}
            inlineActionSet={[{ preset: 'VIEW' } as any]}
            /** Make search enabled as soon as backend implements it */
            isSearchEnabled={false}
            sortingData={sortingData}
            sortingKeys={sortingKeys}
            pathParams={pathParams}
            queryParams={queryParams}
            tablePath={View.BILLING_INVOICES}
            accessData={accessData}
            getPath={constructPath}
            customRowClick={(data: any) => {
              data?.stripe_url && window.open(data.stripe_url, '_blank');
            }}
            presetMapping={{
              component: {
                TOTAL_VALUE: (value: any, state: any, setValue?: any, ...rest: any) => {
                  return (
                    <Text color={gray1} weight={'400'} type={TextType.TEXT_14_GRAY}>
                      {value.currency + value.sum}
                    </Text>
                  );
                },
                DUE_DATE_VALUE: (value: any, state: any, setValue?: any, ...rest: any) => {
                  const date = getRequiredDateFormat(value, 'DD.MM.YYYY');
                  return (
                    <Text color={gray1} weight={'500'} type={TextType.TEXT_14_GRAY}>
                      {date}
                    </Text>
                  );
                },
                STATUS_TEXT: (value: { name: string; id: StatusTextType }) => {
                  const { name, id } = value || {};
                  return switchStatusText(name, id);
                },
              },
              inline: {
                VIEW: (data: any) => {
                  return data.stripe_url ? (
                    <StyledLink target="_blank" rel="noopener noreferrer" theme={theme} href={data.stripe_url}>
                      <Text color={primary} weight={'400'} type={TextType.TEXT_14_BLACK}>
                        {languageStrings.view}
                      </Text>
                    </StyledLink>
                  ) : null;
                },
              },
              textValue: {
                DUE_ON: (value: any) => getRequiredDateFormat(value, 'DD.MM.YYYY'),
              },
            }}
            columnStructureTriggers={[ultramonitor, monitor, tablet]}
            columnStructure={[
              {
                fieldName: 'invoice_number',
                placeholder: languageStrings.invoiceID,
                label: languageStrings.invoiceID,
                defaultValue: '',
                width: tablet ? '1fr' : monitor ? '1fr' : '1fr',
              },
              {
                fieldName: 'description',
                placeholder: languageStrings.descriptionLabel,
                label: languageStrings.descriptionLabel,
                width: tablet ? '1fr' : monitor ? '1fr' : ultramonitor ? '1fr' : '1fr',
                defaultValue: '',
              },
              {
                fieldName: 'amount',
                placeholder: languageStrings.total,
                label: languageStrings.total,
                defaultValue: '',
                width: tablet ? '1fr' : '1fr',
                type: RowItemType.CUSTOM,
                component: { preset: 'TOTAL_VALUE' },
              },
              {
                fieldName: 'due_date',
                placeholder: languageStrings.dueOn,
                label: languageStrings.dueOn,
                defaultValue: '',
                width: tablet ? '1fr' : '1fr',
                getTextDisplay: { preset: 'DUE_ON', data: { format: 'DD.MM.YYYY' } } as any,
              },
              {
                fieldName: 'status',
                placeholder: languageStrings.statusLabel,
                label: languageStrings.statusLabel,
                width: tablet ? '1fr' : '1fr',
                defaultValue: '',
                customComponentHasSelectView: true,
                type: RowItemType.CUSTOM,
                component: { preset: 'STATUS_TEXT' },
              },
            ]}
          />
        </Wrapper>
      </ExtraWrapper>
    </>
  );
};

export const Invoices = withTableContextProvider(InvoicesPlain);
