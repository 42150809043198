/**
 * @description
 * This is one of the core Table components that represents
 * the table heading, including labels and customiszable action section
 *
 */

import React, { FC, ReactNode } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { CheckBox } from '@bit/first-scope.controls';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { useTableContext, useTableFunctions } from '../../context';
import { SortingArrows } from '../../../../components/sortingArrows/SortingArrows';
import { View } from '../../../../routes/routeInterfaces';
import { useStyleContext } from '@cthings.co/styles-utils';
import { SORT_TYPE } from '../../types';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  withCheckBox: boolean;
  withHeaderInMobile?: boolean;
};

// @TODO fix media types
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 56px;
  display: flex;
  position: relative;
  background-color: ${colorFetch('white')};
  box-sizing: border-box;
  padding: ${({ withCheckBox }) => (withCheckBox ? '12px 24px' : '12px 24px 12px 12px')};
  border-radius: 8px 8px 0 0;
  border-bottom: 2px solid;
  border-color: ${colorFetch('gray4')};
  user-select: none;

  ${media.tablet} {
    display: ${({ withHeaderInMobile }: any) => (withHeaderInMobile ? 'flex' : 'none')};
    padding: 13px 16px 13px 4px;
  }
`;

type TitleBoxProps = {
  gridTemplate?: string;
};

const TitleBox = styled.div<TitleBoxProps>`
  width: 100%;
  display: grid;
  gap: 0px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  align-items: center;
`;

type TitleWrapperProps = {
  isSortable: boolean;
};

const TitleWrapper = styled.div<TitleWrapperProps>`
  display: flex;
  align-items: center;
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'auto')};
  & > span {
    font-weight: 500;
  }
  &:hover > div > svg > path {
    stroke: ${colorFetch('gray1')};
    fill: ${colorFetch('gray1')};
  }
`;

const GridItem = styled.div`
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  &.field {
    padding: 0 12px;
  }
  &.select {
    margin-right: 0 12px;
    ${media.tablet} {
      padding: 0px;
    }
  }
`;

type ActionWrapperProps = {
  withHeaderInMobile?: boolean;
};

const ActionWrapper = styled.div<ActionWrapperProps>`
  display: flex;
  align-items: center;

  justify-content: flex-end;
  ${media.tablet} {
    display: ${({ withHeaderInMobile }: any) => (withHeaderInMobile ? 'flex' : 'none')};
  }
`;

const StyledSortingArrow = styled(SortingArrows)`
  margin-left: 6px;
`;

export interface TableHeaderProps {
  titleActionComponent?: ReactNode;

  allowDelete: boolean;
  allowSelect: boolean;
  selectComponent?: (selectedList: any[]) => ReactNode;
  withHeaderInMobile?: boolean;
  withHeaderGridTemplate?: string;
  tablePath?: typeof View[keyof typeof View];
  languageStrings?: any;
}

export const Head: FC<TableHeaderProps> = ({
  titleActionComponent,
  allowDelete,
  allowSelect,
  tablePath,
  selectComponent,
  withHeaderInMobile,
  withHeaderGridTemplate,
  languageStrings,
}) => {
  const {
    toggleIsCheckedAll,
    toggleDelete,
    getSelectedItems,
    getGridStructure,
    getHeaderData,
    setSortingData,
    getSortingData,
  } = useTableFunctions();
  const theme = useTheme();
  const { red1, red2, primary, black1 } = theme.colors;

  const [media] = useStyleContext();
  const { mediaType } = media;

  const [{ isSelectAll }] = useTableContext();

  const markedArr = getSelectedItems();
  const countOfMarkedElements = markedArr && markedArr.length;

  const { headGridTemplate } = getGridStructure(-1, mediaType);
  const headerData = getHeaderData(mediaType);
  const headerDataFiltered = mediaType.narrowMonitor && headerData.length > 3 ? headerData.slice(0, 3) : headerData;

  const sortData = getSortingData();

  const selectAllCheckbox = () => {
    toggleIsCheckedAll();
  };

  const handleDelete = () => {
    toggleDelete();
  };

  return (
    <Wrapper withHeaderInMobile={withHeaderInMobile} withCheckBox={allowDelete || allowSelect}>
      <TitleBox gridTemplate={withHeaderInMobile ? withHeaderGridTemplate : headGridTemplate}>
        {(allowDelete || allowSelect) && (
          <GridItem className={'select'}>
            <CheckBox label="" toggle={isSelectAll} handleToggle={selectAllCheckbox} />
          </GridItem>
        )}
        {headerDataFiltered.map((item: any, index: number) => {
          const { sortable, sort_field, label, field_name } = item;

          return (
            <GridItem key={index} className={'field'}>
              <TitleWrapper
                onClick={
                  sortable
                    ? () => {
                        const { sort_type, sort_field: old_sort_field } = sortData;

                        return setSortingData({
                          sort_field: sort_field,
                          sort_type:
                            old_sort_field !== sort_field
                              ? SORT_TYPE.DESC
                              : sort_type === SORT_TYPE.NONE
                              ? SORT_TYPE.DESC
                              : sort_type === SORT_TYPE.DESC
                              ? SORT_TYPE.ASC
                              : sort_type === SORT_TYPE.ASC
                              ? SORT_TYPE.NONE
                              : SORT_TYPE.DESC,
                        });
                      }
                    : () => {}
                }
                isSortable={sortable}
              >
                <Text type={TextType.TITLE_H4} color={black1}>
                  {field_name ?? label}
                </Text>
                {sortable && <StyledSortingArrow sortData={sortData} fieldName={sort_field} />}
              </TitleWrapper>
            </GridItem>
          );
        })}
        <GridItem className={'actionItem'}>
          <ActionWrapper withHeaderInMobile={withHeaderInMobile}>
            {countOfMarkedElements > 0 ? (
              <>
                <Text
                  type={TextType.TEXT_14_RED}
                  color={tablePath === View.USER_REPORTS ? primary : red2}
                  margin={'0 10px 0 0'}
                >
                  {languageStrings.manageClientsTableHeaderDeleteSelected}
                  {countOfMarkedElements}
                </Text>
                {allowDelete && (
                  <CommonButton
                    width="94px"
                    height="32px"
                    colors={{
                      background: red2,
                      backgroundHover: red1,
                      border: red2,
                      borderHover: red1,
                    }}
                    onClick={handleDelete}
                  >
                    {languageStrings.manageClientsTableHeaderDeleteButton}
                  </CommonButton>
                )}
                {allowSelect && selectComponent ? selectComponent(markedArr) : null}
              </>
            ) : !!titleActionComponent ? (
              titleActionComponent
            ) : null}
          </ActionWrapper>
        </GridItem>
      </TitleBox>
    </Wrapper>
  );
};
