import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  & > span {
    user-select: none;
  }
`;

export const Counter = ({ currentIndex, generalCount }: { currentIndex: number; generalCount: number }) => {
  const theme = useTheme();

  const { gray3 } = theme.colors;

  return (
    <Wrapper>
      <Text type={TextType.TEXT_14_GRAY} color={gray3} lineHeight={'20px'}>
        {`${currentIndex} / ${generalCount}`}
      </Text>
    </Wrapper>
  );
};
