import React from 'react';

export const LocationIcon = ({ primaryColor, secondaryColor }: { primaryColor: string; secondaryColor: string }) => {
  return (
    <svg width="46" height="54" viewBox="0 0 46 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.25 22.5C43.25 38.25 23 51.75 23 51.75C23 51.75 2.75 38.25 2.75 22.5C2.75 17.1294 4.88348 11.9787 8.68109 8.18109C12.4787 4.38348 17.6294 2.25 23 2.25C28.3706 2.25 33.5213 4.38348 37.3189 8.18109C41.1165 11.9787 43.25 17.1294 43.25 22.5Z"
        stroke={primaryColor}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 29.25C26.7279 29.25 29.75 26.2279 29.75 22.5C29.75 18.7721 26.7279 15.75 23 15.75C19.2721 15.75 16.25 18.7721 16.25 22.5C16.25 26.2279 19.2721 29.25 23 29.25Z"
        stroke={secondaryColor}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="0.4"
      />
    </svg>
  );
};
