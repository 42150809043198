import { UsersTableState } from './types';

export const initialState: UsersTableState = {
  list: [],
  isSelectAll: false,
  isEditInProcess: false,
  pagination: {
    total_items: 0,
    total_pages: 1,
  },
};
