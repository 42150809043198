import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '../../../../../../../components/commonButton/CommonButton';
import { ReactComponent as CloseIcon } from '../../../../../../../assets/Close.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { MobileSearchField } from './MobileSearchField';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const StyledModal = styled(Modal)`
  ${media.phone} {
    align-items: flex-end;
    & > div {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  display: flex;
  flex-direction: column;
  align-items: unset;
  padding: 24px;
  ${media.phone} {
    width: 100%;
    padding: 24px 16px 30px 16px;
    border-radius: 12px 12px 0px 0px;
  }
`;

const ButtonRow = styled.div`
  border-top: 1px solid;
  border-color: ${colorFetch('gray4')};
`;

const SearchFieldRow = styled.div`
  margin: 24px 0 0 0;
`;

const StyledCloseIcon = styled(CloseIcon)``;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MobileSelectModal = ({
  value,
  values,
  languageStrings,
  setIsOpenModal,
  onChange,
}: {
  value?: any;
  values?: any;
  languageStrings?: any;
  setIsOpenModal: (props: any) => void;
  onChange: (props: any) => void;
}) => {
  const theme = useTheme();
  const { primary, pureWhite, black1 } = theme.colors;
  const [openModal, setModalOpen] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const [filteredList, setFilteredList] = useState([]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChange = (value: any) => {
    onChange(value);
    setModalOpen(false);
  };

  useEffect(() => {
    setIsOpenModal(openModal);
  }, [openModal]);

  return (
    <StyledModal isOpenModal={openModal}>
      <StyledModalContainer>
        <TitleRow>
          <Text type={TextType.TEXT_14_BLACK} color={black1} fontSize={'18px'} weight={'600'} lineHeight={'24px'}>
            Choose or enter material’s roughtness
          </Text>
          <StyledCloseIcon onClick={handleModalClose} />
        </TitleRow>
        <SearchFieldRow>
          <MobileSearchField
            value={value}
            setFilteredList={(value: any) => setFilteredList(value)}
            setSearchValue={(value: any) => setSearchValue(value)}
            values={values}
            onChange={handleChange}
          />
        </SearchFieldRow>
        <ButtonRow>
          {!filteredList.length && (
            <CommonButton
              width={'100%'}
              height={'40px'}
              fontSize={'14px'}
              margin={'16px 0 0 0'}
              onClick={() => handleChange({ name: searchValue, id: '' })}
            >
              Save
            </CommonButton>
          )}
          <CommonButton
            width={'100%'}
            height={'40px'}
            borderColor={primary}
            backgroundColorHover={pureWhite}
            backgroundColor={pureWhite}
            color={primary}
            fontSize={'14px'}
            margin={'16px 0 0 0'}
            onClick={handleModalClose}
          >
            Cancel
          </CommonButton>
        </ButtonRow>
      </StyledModalContainer>
    </StyledModal>
  );
};
