import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { TableCustomComponentState } from '../../../../../../../../../../features/universalTable/types';
import { useTableContext, useTableFunctions } from '../../../../../../../../../../features/universalTable/context';
import { InputModal } from '../../../../../../../../../../components/inputModal/InputModal';
import moment from 'moment';
import { useTheme } from '@cthings.co/styled-components';

const Input = styled(InputModal)`
  margin: 0;
  & > div:first-child {
    display: none;
  }
` as any; // @TODO fix type

export const Time = ({
  globalValue,
  name,
  state,
  languageStrings,
}: {
  globalValue: string;
  name: string;
  state?: TableCustomComponentState;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const { setField, getModalItems } = useTableFunctions();
  const [{ openIndex, isEditInProcess, isAddInProcess, isViewInProcess }] = useTableContext();
  const timeData = getModalItems()[1] ? getModalItems()[1] : {};
  const { value, fieldName, isError, placeholder, validatingIsStarted } = timeData;
  const [timeValue, setTimeValue] = useState('');

  const handleOnChangeValue = (t: string) => {
    setTimeValue(t);
  };
  const handleOnBlurValue = () => {
    const preparedDate = moment(value).toISOString();
    const date =
      preparedDate && preparedDate.length > 0
        ? `${preparedDate.slice(0, 11)}${timeValue}:00${preparedDate.slice(19)}`
        : '';
    timeValue.length === 5 && setField(openIndex, date, fieldName);
  };

  useEffect(() => {
    if (timeValue.length === 4 && timeValue[2] !== ':') {
      setTimeValue(`${timeValue.slice(0, 2)}:${timeValue.slice(2)}`);
    }
  }, [timeValue]);

  useEffect(() => {
    const time = value && value.length > 0 ? moment.utc(value).format('HH:mm') : '';
    if (isEditInProcess || isViewInProcess) {
      setTimeValue(time);
    }
  }, [isEditInProcess, isAddInProcess, value, isViewInProcess]);

  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
          {globalValue || 'N/A'}
        </Text>
      ) : (
        <Input
          value={timeValue}
          onChange={(e: any) => {
            handleOnChangeValue(e.target.value);
          }}
          onBlur={handleOnBlurValue}
          placeholder={placeholder}
          name={name}
          isError={isError && (isEditInProcess || isAddInProcess)}
          startedValidation={validatingIsStarted}
          disabled={!(isEditInProcess || isAddInProcess)}
          languageStrings={languageStrings}
        />
      )}
    </>
  );
};
