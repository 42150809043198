import React from 'react';
import { FC } from 'react';
import {
  StyledText,
  TextWrapper,
  StyledInfoText,
  OrderedList,
  InfoListText,
  BackButton,
  ButtonsWrapper,
  GetStartedButton,
  HeadWrapper,
} from './styled';
import { TextType } from '@bit/first-scope.text';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { pushAction } from '../../../../app/state/organisations';
import { CustomModal } from '../customModal/CustomModal';
import { useTheme } from '@cthings.co/styled-components';
import { CloseButton } from '../closeButton/CloseButton';

export interface InformationModalProps {
  isOpen: boolean;
}

export const InformationModal: FC<InformationModalProps> = ({ isOpen }) => {
  const theme = useTheme();
  const { black1, gray1, pureWhite, primary } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  const listOfText = [
    languageStrings.informationModalListOfTextFirst,
    languageStrings.informationModalListOfTextSecond,
    languageStrings.informationModalListOfTextThird,
  ];

  const dispatch = useDispatch();

  const handleGetStarted = () => {
    dispatch(pushAction('getStarted'));
  };

  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  return (
    <CustomModal isOpen={isOpen}>
      <TextWrapper>
        <HeadWrapper>
          <StyledText type={TextType.TEXT_12_BLACK} color={black1}>
            {languageStrings.informationModalHeader}
          </StyledText>
          <CloseButton />
        </HeadWrapper>
        <StyledInfoText type={TextType.TEXT_12_BLACK} color={gray1}>
          {languageStrings.informationModalInfo}
          <OrderedList>
            {listOfText.map((value: any, index: number) => (
              <InfoListText key={index}>{value}</InfoListText>
            ))}
          </OrderedList>
        </StyledInfoText>
      </TextWrapper>
      <ButtonsWrapper>
        <BackButton onClick={handleBack} colors={{ main: black1, background: pureWhite, backgroundHover: primary }}>
          {languageStrings.backButton}
        </BackButton>
        <GetStartedButton onClick={handleGetStarted}>{languageStrings.getStartedButton}</GetStartedButton>
      </ButtonsWrapper>
    </CustomModal>
  );
};
