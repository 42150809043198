import React from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover > svg > path {
    stroke: ${colorFetch('gray1')};
  }
`;

export const ProblemsInfoButton = ({ onClick }: { onClick: any }) => {
  const theme = useTheme();
  return (
    <Wrapper onClick={onClick} theme={theme}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5832 11.9779V16.7445C15.5832 17.1659 15.4158 17.5701 15.1178 17.868C14.8198 18.166 14.4157 18.3334 13.9943 18.3334H5.25539C4.83399 18.3334 4.42985 18.166 4.13188 17.868C3.8339 17.5701 3.6665 17.1659 3.6665 16.7445V8.00564C3.6665 7.58424 3.8339 7.1801 4.13188 6.88212C4.42985 6.58415 4.83399 6.41675 5.25539 6.41675H10.0221"
          stroke="#8F90A6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M13.75 3.66675H18.3333V8.25008" stroke="#8F90A6" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.1665 12.8334L18.3332 3.66675" stroke="#8F90A6" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </Wrapper>
  );
};
