import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import settingsReducer from './state/settings';
import userReducer from './state/user';
import appReducer from './state/app';
import insightReducer from './state/insight';
import dashboardReducer from './state/dashboard';
import organisationsReducer from './state/organisations';
import usersTableReducer from './state/usersTable';
import devicesTableReducer from './state/devicesTable';
import routesReducer from './state/routes';
import editAddressModalMobileReducer from './state/editAddressModalMobile';
import generateReportModalReducer from './state/generateReportModal';
import incidentsReducer from './state/incidents';
import billingDetailsReducer from './state/billingDetailsSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    settings: settingsReducer,
    user: userReducer,
    insight: insightReducer,
    dashboard: dashboardReducer,
    clientsTable: organisationsReducer,
    usersTable: usersTableReducer,
    devicesTable: devicesTableReducer,
    editAddressModalMobile: editAddressModalMobileReducer,
    routes: routesReducer,
    generateReportModal: generateReportModalReducer,
    incidents: incidentsReducer,
    billingDetails: billingDetailsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
