import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { selectLanguageStrings } from '../../app/state/user';
import { useSelector } from 'react-redux';
import { InfoButton } from '../infoButton/InfoButton';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const Container = styled.div`
  display: flex;
  background-color: ${colorFetch('pureWhite')};
  height: 38px;
  border: 1px solid;
  border-color: ${colorFetch('primary')};
  border-radius: 6px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const AddOrganisationButton = ({ value, onClick }: { value: string; onClick: () => void }) => {
  const theme = useTheme();
  const { black1, gray1 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <WrapperLabel>
        <Text type={TextType.TEXT_12_BLACK} lineHeight={'16px'} margin={'0 0 0 6px'} color={gray1}>
          {languageStrings.navigationClients}
        </Text>
        <InfoButton onClick={() => {}} tooltipText={languageStrings.provisioningTooltipOrganisations} isInfoIcon />
      </WrapperLabel>
      <Container onClick={onClick}>
        <Text type={TextType.TEXT_14_BLACK} lineHeight={'16px'} color={black1}>
          {value || languageStrings.addNew}
        </Text>
      </Container>
    </Wrapper>
  );
};
