import React, { FC } from 'react';
import { Handle, Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from '@cthings.co/styled-components';
import { TableInputType } from '../../TableInput';
import AutosizeInput from 'react-input-autosize';
import { media } from '@cthings.co/styles-utils';
import { InputWrapper } from '../inputWrapper/InputWrapper';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  ${media.phone} {
    height: 40px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 100%;
  background-color: ${colorFetch('gray6')};
  border-right: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 8px 0 0 8px;
  &.right {
    border-radius: 0 8px 8px 0;
    border-right: 0;
    border-left: 1px solid;
    border-color: ${colorFetch('gray3')};
  }
`;

const StyledInput = styled(AutosizeInput)`
  background-color: ${colorFetch('gray6')};
  border-radius: 8px 0 0 8px;
  & > input {
    outline: transparent;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${colorFetch('gray1')};
    text-align: center;
  }
`;

// @TODO fix any
const StyledRange = styled(Range as any)`
  width: calc(100% - 142px);
  margin: 0 14px;
`;

type StyledHandleProps = {
  withInput?: boolean;
  pureWhite: string;
  primary: string;
  boxShadow?: string;
};

// @TODO fix any
const StyledHandle = styled(Handle as any)<StyledHandleProps>`
  width: ${({ withInput }) => (withInput ? '14px' : '44px')};
  height: ${({ withInput }) => (withInput ? '14px' : '24px')};
  background-color: ${({ withInput, pureWhite, primary }) => (withInput ? pureWhite : primary)};
  border: ${({ withInput }) => (withInput ? '2px solid' : '0')};
  border-color: ${({ withInput, primary }) => withInput && primary};
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  transform: ${({ withInput }) => (withInput ? 'translate(-50%, -5px)' : 'translate(-50%, -10px)')} !important;
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: ${({ withInput }) => (withInput ? '50%' : '3px')};
  &:hover {
    border: ${({ withInput }) => !withInput && 0};
    border-color: ${({ withInput, primary }) => withInput && primary};
  }
  &:focus,
  &:active {
    border-color: ${({ withInput, primary }) => withInput && primary} !important;
    box-shadow: ${({ boxShadow }) => boxShadow} !important;
  }
`;

const TextUnit = styled.span`
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

const TextWrapper = styled.span`
  word-break: break-all;
  width: 100%;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

export interface SLiderRangeOptionsType {
  min?: number;
  max?: number;
  count?: number;
  pushable?: number;
  unit?: string;
  step?: number;
  title?: string;
}

interface RangeInputProps {
  type: TableInputType;
  values: number[];
  handleChangeValue: (value: number[]) => void;
  options: SLiderRangeOptionsType;
  isEditInProcess: boolean | undefined;
}

export const RangeInput: FC<RangeInputProps> = ({
  type,
  values,
  handleChangeValue,
  options,
  isEditInProcess = false,
  ...props
}) => {
  const theme = useTheme();
  const { primary, secondary3, pureWhite } = theme.colors;
  const { additionalShadow6 } = theme.shadows;
  const { min = 0, max = 100, count = 1, pushable = 0, title } = options;

  const setRange = (type: TableInputType) => {
    const rangeWithInputs = (unit: string = '') => {
      return (
        <InputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
          <Container>
            <Box>
              <StyledInput
                value={values[0]}
                onChange={(e: any) => {
                  !isNaN(+e.target.value) &&
                    e.target.value.length <= 4 &&
                    handleChangeValue([e.target.value, values[1]]);
                }}
                onBlur={() => {
                  values[0] <= min
                    ? handleChangeValue([min, values[1]])
                    : values[0] >= values[1] - pushable && handleChangeValue([values[1] - pushable, values[1]]);
                }}
                width={`${values[0]}`.length * 10}
              />
              {unit && <TextUnit>{' ' + unit}</TextUnit>}
            </Box>
            <StyledRange
              value={values}
              min={options.min}
              max={options.max}
              count={options.count}
              pushable={options.pushable}
              onChange={(values: number[]) => {
                values[0] >= min && values[1] <= max && handleChangeValue(values);
              }}
              trackStyle={[{ backgroundColor: primary }]}
              railStyle={{ backgroundColor: secondary3 }}
              handle={({ index, ...props }: any) => (
                <StyledHandle
                  primary={primary}
                  pureWhite={pureWhite}
                  boxShadow={additionalShadow6}
                  withInput
                  {...props}
                />
              )}
              step={options.step}
            />
            <Box className="right">
              <StyledInput
                value={values[1]}
                onChange={(e: any) => {
                  !isNaN(+e.target.value) &&
                    e.target.value.length <= 3 &&
                    handleChangeValue([values[0], e.target.value]);
                }}
                onBlur={() => {
                  values[1] <= values[0] + pushable
                    ? handleChangeValue([values[0], values[0] + pushable])
                    : values[1] >= max && handleChangeValue([values[0], max]);
                }}
              />
              {unit && <TextUnit>{' ' + unit}</TextUnit>}
            </Box>
          </Container>
        </InputWrapper>
      );
    };

    const data: any = {
      [TableInputType.SLIDER_RANGE]: (() => (
        <InputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
          <Container>
            <StyledRange
              value={values}
              count={options.count}
              pushable={options.pushable}
              onChange={(values: number[]) => {
                handleChangeValue(values);
              }}
              trackStyle={[{ backgroundColor: primary }]}
              railStyle={{ backgroundColor: secondary3 }}
              handle={({ index, ...props }: any) => (
                <StyledHandle primary={primary} pureWhite={pureWhite} {...props}>
                  {values[index]}
                </StyledHandle>
              )}
            />
          </Container>
        </InputWrapper>
      ))(),
      [TableInputType.SLIDER_RANGE_FREE_INPUT]: rangeWithInputs(),
      [TableInputType.SLIDER_RANGE_FREE_INPUT_WITH_UNIT]: rangeWithInputs(options.unit),
    };
    return data[type];
  };
  return (
    <>{isEditInProcess ? setRange(type) : <TextWrapper>{`${values[0]} - ${values[1]} ${options.unit}`}</TextWrapper>}</>
  );
};
