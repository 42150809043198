import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as Icon } from '../../assets/dropdown_arrow.svg';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

type FieldNameProps = {
  value?: boolean;
};

const FieldName = styled(Text)<FieldNameProps>`
  visibility: ${({ value }) => (value ? 'visible' : 'hidden')};
`;

type WrapperProps = {
  isOpenDropdownList: boolean;
  isDisabled?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 204px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ isDisabled }) => (isDisabled ? 'none' : '1px solid')};
  border-color: ${({ isOpenDropdownList }) => (isOpenDropdownList ? colorFetch('primary') : colorFetch('gray3'))};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  background-color: ${({ isDisabled }) => colorFetch(isDisabled ? 'gray5' : 'white')};
  padding: 4px 12px;
  position: relative;
  transition: all 0.3s linear;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

type ArrowProps = {
  isOpenDropdownList: boolean;
  isDisabled?: boolean;
};

const Arrow = styled(Icon)<ArrowProps>`
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'block')};
  transform: ${({ isOpenDropdownList }) => (isOpenDropdownList ? 'rotate(0)' : 'rotate(180deg)')};
  transition: all 0.3s linear;
`;

type ListWrapperProps = {
  isOpenDropdownList: boolean;
  marginList: number | string;
};

const ListWrapper = styled.div<ListWrapperProps>`
  width: 100%;
  height: auto;
  max-height: 205px;
  box-sizing: border-box;
  display: ${({ isOpenDropdownList }) => (isOpenDropdownList ? 'flex' : 'none')};
  flex-direction: column;
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  background-color: ${colorFetch('white')};
  padding: 12px;
  position: absolute;
  top: ${({ marginList }) => `${marginList}px`};
  right: 0;
  z-index: 1;
`;

const Item = styled(Text)`
  cursor: pointer;
  &:hover {
    color: ${colorFetch('primary')};
    font-weight: 500;
  }
` as any; // @TODO fix type

export const Dropdown = ({
  value,
  valuesList,
  fieldName,
  onChange,
  placeholder,
  languageStrings,
  isDisabled,
  ...props
}: {
  valuesList: { name: string; id: string; onClick: () => void }[];
  fieldName: string;
  onChange: (value: { name: string; id: string }, index: number) => void;
  value?: { name: string; id: string };
  placeholder?: string;
  languageStrings?: any;
  isDisabled?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1, gray2, black1, primary } = theme.colors;
  const [isOpenDropdownList, setIsOpenDropdownList] = useState(false);

  const dropdownRef = useRef<any>(null);
  const marginList = dropdownRef && dropdownRef.current ? dropdownRef.current.clientHeight + 7 : 0;

  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpenDropdownList && !(dropdownRef && dropdownRef.current && dropdownRef.current.contains(e.target))) {
        setIsOpenDropdownList(false);
      }
    },
    [isOpenDropdownList],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  return (
    <Container {...props}>
      <FieldName type={TextType.TEXT_12_BLACK} color={gray1} margin={'0 0 2px 8px'} value={!!value}>
        {fieldName}
      </FieldName>
      <Wrapper
        isOpenDropdownList={isOpenDropdownList}
        onClick={() => {
          setIsOpenDropdownList(!isOpenDropdownList);
        }}
        ref={dropdownRef}
        isDisabled={isDisabled}
      >
        <Text type={TextType.TEXT_14_BLACK} color={value ? gray1 : gray2} weight={'400'}>
          {value ? value?.name : placeholder ? placeholder : languageStrings.selectValuePlaceholder}
        </Text>
        <Arrow isOpenDropdownList={isOpenDropdownList} isDisabled={isDisabled} />
        <ListWrapper marginList={marginList} isOpenDropdownList={isOpenDropdownList}>
          {valuesList.map((item: { name: string; id: string; onClick: () => void }, index: number) => {
            const { name, id, onClick } = item;
            return (
              <Item
                type={TextType.TEXT_14_BLACK}
                color={value?.id === id ? primary : black1}
                weight={value?.id === id ? '500' : '400'}
                lineHeight={'21px'}
                margin={index === valuesList.length - 1 ? '0' : '0 0 8px 0'}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onChange(item, index);
                  setIsOpenDropdownList(false);
                  onClick && onClick();
                }}
              >
                {name}
              </Item>
            );
          })}
        </ListWrapper>
      </Wrapper>
    </Container>
  );
};
