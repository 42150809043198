// COMPONENT FOR CHECKING ACCESS FOR THE PAGES
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
import React, { FC, ReactNode } from 'react';
import { useRedirectToPageWithAccess } from './utils';

interface AccessHandlerProps {
  children: ReactNode;
}

export const AccessHandler: FC<AccessHandlerProps> = ({ children }) => {
  // CHECK THE LOGIC INSIDE
  useRedirectToPageWithAccess();

  return <>{children}</>;
};
