import React, { FC } from 'react';
import { PlaceholderCustomCard } from './components/customCard/CustomCard';
import { PlaceholderCustomTable } from './components/customTablePlaceholder/CustomPlaceholderTable';
import { PlaceholderAddButton } from './components/PlaceholderAddButton';
import { PlaceholderCampusMap } from './components/PlaceholderCampusMap';
import { PlaceholderGradientMap } from './components/PlaceholderGradientMap';
import { PlaceholderGraph } from './components/PlaceholderGraph';
import { PlaceholderMap } from './components/PlaceholderMap';
import { PlaceholderSearch } from './components/PlaceholderSearch';
import { PlaceholderType } from './typePlaceholders/placeholdersType';

interface optionsType {
  countRows?: number;
  withoutPagination?: boolean;
  borderRadiusTable?: boolean;
  isLargeCard?: boolean;
}
export interface PlaceholderProps {
  type?: PlaceholderType;
  options?: optionsType;
  keyword?: any;
}

export const Placeholder: FC<PlaceholderProps> = ({ type, options, keyword }) => {
  switch (type) {
    /*Valmont*/
    case PlaceholderType.CAMPUS_MAP_INSIGHT:
      return <PlaceholderCampusMap />;
    /********/
    case PlaceholderType.CUSTOM_TABLE:
      return <PlaceholderCustomTable {...options} />;
    case PlaceholderType.CARD:
      return <PlaceholderCustomCard {...options} />;
    case PlaceholderType.GRAPH:
      return <PlaceholderGraph />;
    case PlaceholderType.SEARCH:
      return <PlaceholderSearch />;
    case PlaceholderType.BUTTON:
      return <PlaceholderAddButton />;
    case PlaceholderType.MAP:
      return <PlaceholderMap />;
    case PlaceholderType.GRADIENT_MAP:
      return <PlaceholderGradientMap />;
    default:
      return <div />;
  }
};
