/**
 * @description
 * This is a section that contains the row action items.
 * It supports default 'details' action as well as
 * custom per feature actions
 *
 */

import React, { FC, useEffect, useState } from 'react';
import { InlineActionType, PreparedActionConfig } from '../../types';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { DetailsButton } from '../../../../components/detailsButton/DetailsButton';
import { ArrowButton, ArrowButtonType } from '../../../../components/arrowButton/ArrowButton';
import { CustomComponent } from './components/customComponent/CustomComponent';
import ss from '../../../../utils/ss';
import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { LsValueType } from '../../../../enums/LsValueType';

const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.wide > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  & > .locationIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  & > .rightIcon {
    margin-left: 14px;
  }

  & > div > div > svg > path {
    stroke: ${colorFetch('gray2')};
  }
`;

const StyledDetailsButton = styled(DetailsButton)`
  ${media.tablet} {
    display: none;
  }
`;

const StyledArrowButton = styled(ArrowButton)`
  display: none;
  & > svg > path {
    stroke: ${colorFetch('gray2')};
  }
  ${media.tablet} {
    margin-left: 12px;
    display: flex;
  }
`;

interface ActionSectionProps {
  inlineActionSet: PreparedActionConfig[];
  onDetailsClick: any;
  data: any;
  languageStrings?: any;
  isHrefDetailsClick?: boolean;
}

export const ActionSection: FC<ActionSectionProps> = ({
  inlineActionSet,
  onDetailsClick,
  data,
  languageStrings,
  isHrefDetailsClick,
}) => {
  const [href, setHref] = useState();
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);
  const path = `${href}?access_token=${token}&expires=${exp}`;

  const handleClick = () => {
    !href && onDetailsClick();
  };

  useEffect(() => {
    if (isHrefDetailsClick) {
      const href = onDetailsClick();
      setHref(href);
    }
  }, [isHrefDetailsClick, data]);

  return (
    <EditWrapper
      className={inlineActionSet.length === 1 && inlineActionSet[0].type === InlineActionType.CUSTOM ? 'wide' : ''}
    >
      {inlineActionSet.map((item, index) => {
        return item.type === InlineActionType.DETAILS ? (
          <>
            <StyledDetailsButton key={index} href={path} onClick={handleClick}>
              {languageStrings.problemsButtonDetails}
            </StyledDetailsButton>
            <StyledArrowButton type={ArrowButtonType.RIGHT} to={path} onClick={handleClick} />
          </>
        ) : !!item.component ? (
          <CustomComponent key={index} data={data} item={item} />
        ) : null;
      })}
    </EditWrapper>
  );
};
