import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { ActionPopup } from './components/actionPopup/ActionPopup';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useBlockBodyScroll } from '../../../../utils/useBlockBodyScroll';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledText = styled(Text)`
  width: fit-content;
  text-decoration-line: underline;
  cursor: pointer;
  &:hover {
    color: ${colorFetch('blue3')};
  }
` as any; // @TODO fix type

const PopupWrapper = styled.div`
  min-height: 164px;
  box-sizing: border-box;
  background-color: ${colorFetch('gray6')};
  padding: 12px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  ${media.phone} {
    width: 100%;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  padding: 26px 24px 24px 24px;
  // @TODO fix media types
  ${media.phone} {
    width: 100%;
    max-width: 100%;
    padding: 16px 16px 30px 16px;
    position: fixed;
    bottom: 0;
    border-radius: ${({ theme }: any) => `${theme.borderRadius.additional16} ${theme.borderRadius.additional16} 0 0`};
  }
`;

export interface SpecialInputOptions {
  fieldName: string;
  content?: any;
  onAction?: () => void;
}

export const SpecialInput = ({ value, options }: { value: string; options: SpecialInputOptions }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();
  const { blue2, gray2 } = theme.colors;
  const [openModal, setOpenModal] = useState(false);

  const { fieldName, content, onAction } = options;

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleActionModal = () => {
    onAction && onAction();
    setOpenModal(false);
  };
  useBlockBodyScroll(openModal);

  return (
    <Wrapper>
      <StyledText
        onClick={handleOpenModal}
        color={blue2}
        type={TextType.TEXT_14_GRAY}
        weight={'500'}
        lineHeight={'24px'}
      >
        {value}
      </StyledText>
      <StyledModal isOpenModal={openModal}>
        <StyledModalContainer>
          <ActionPopup
            title={fieldName}
            content={
              <>
                {content ? (
                  <PopupWrapper>{content}</PopupWrapper>
                ) : (
                  <PopupWrapper>
                    <Text color={gray2} type={TextType.TEXT_14_GRAY} weight={'normal'} lineHeight={'24px'}>
                      {languageStrings.specialInputPlaceholder}
                    </Text>
                  </PopupWrapper>
                )}
              </>
            }
            handleOnClose={handleCloseModal}
            handleOnAction={handleActionModal}
          />
        </StyledModalContainer>
      </StyledModal>
    </Wrapper>
  );
};
