import React, { FC } from 'react';
import { TextType } from '@bit/first-scope.text';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../app/state/user';
import { CustomModal } from '../customModal/CustomModal';
import { getUploadWays } from './utils';
import {
  HeadingBlock,
  HeadingContainer,
  Title,
  StepNumber,
  Info,
  ClarifyingBlock,
  StyledClarifyingIcon,
  Ways,
  Tooltip,
  ButtonBlock,
  Button,
  ButtonRow,
  Block,
} from './styled';
import { ButtonType } from './types';
import { pushAction, selectActions } from '../../../../app/state/organisations';
import { useTheme } from '@cthings.co/styled-components';
import { CloseButton } from '../closeButton/CloseButton';

interface AddDeviceModalProps {
  isOpen: boolean;
}

export const AddDeviceModal: FC<AddDeviceModalProps> = ({ isOpen }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage);
  const actions = useSelector(selectActions);
  const theme = useTheme();
  const { black1, gray1, gray2, gray5, gray6, primary, pureWhite } = theme.colors;

  const dispatch = useDispatch();

  const handleManual = () => {
    dispatch(pushAction('addManually'));
  };

  const handleUpload = () => {
    dispatch(pushAction('upload'));
  };

  const handleSkip = () => {
    dispatch(pushAction('summary'));
  };

  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  const uploadWays = getUploadWays(languageStrings);

  const buttons = [
    { type: ButtonType.BACK_BUTTON },
    { type: ButtonType.SKIP_BUTTON },
    { type: ButtonType.ADD_MANUALY },
    { type: ButtonType.UPLOAD_FILE },
  ];

  const filteredButtons = buttons.filter((button) => actions.summary || button.type !== ButtonType.SKIP_BUTTON);

  const buttonValues = {
    [ButtonType.BACK_BUTTON]: { name: languageStrings.backButton },
    [ButtonType.SKIP_BUTTON]: { name: languageStrings.skipButtonAddDeviceModal },
    [ButtonType.ADD_MANUALY]: { name: languageStrings.addManuallyAddDeviceModal },
    [ButtonType.UPLOAD_FILE]: { name: languageStrings.uploadFileAddDeviceModal },
  };

  const buttonWidthHandler = (type: ButtonType) => {
    return (language.shortName === 'DE' || language.shortName === 'SV') &&
      type !== ButtonType.BACK_BUTTON &&
      type !== ButtonType.SKIP_BUTTON
      ? '160px'
      : '116px';
  };

  const buttonClick = (type: ButtonType) => {
    const onClick = {
      [ButtonType.BACK_BUTTON]: handleBack,
      [ButtonType.SKIP_BUTTON]: handleSkip,
      [ButtonType.ADD_MANUALY]: handleManual,
      [ButtonType.UPLOAD_FILE]: handleUpload,
    };

    return onClick[type];
  };

  const buttonColorHandler = (colorType: 'background' | 'textColor', buttonType: ButtonType) => {
    return colorType === 'background' &&
      (buttonType === ButtonType.BACK_BUTTON || buttonType === ButtonType.SKIP_BUTTON)
      ? pureWhite
      : colorType === 'textColor' && (buttonType === ButtonType.BACK_BUTTON || buttonType === ButtonType.SKIP_BUTTON)
      ? black1
      : '';
  };

  const infoText = languageStrings.addDeviceModalDesc;
  const clarifyingInfoText = languageStrings.addDeviceModalClarifyingDesc;
  const uploadWaysDescriptionText = languageStrings.addDeviceModalWaysDesc;

  return (
    <CustomModal isOpen={isOpen}>
      <HeadingBlock>
        <HeadingContainer>
          <Title>{languageStrings.addDevicesLabel}</Title>
          <Block>
            {actions.summary && <StepNumber>3/3</StepNumber>}
            <CloseButton />
          </Block>
        </HeadingContainer>
      </HeadingBlock>
      <Info>{infoText}</Info>
      <ClarifyingBlock>
        <StyledClarifyingIcon />
        <Info margin={'0px 0 0 10px'}>{clarifyingInfoText}</Info>
      </ClarifyingBlock>
      <Info>{uploadWaysDescriptionText}</Info>
      {uploadWays.map(({ name, info, tooltip }, index: number) => {
        return (
          <Info type={TextType.TEXT_14_BLACK}>
            <Info>{index + 1 + '. '}</Info>
            <Ways> {name + ': '}</Ways>
            <Info>
              {info} <Tooltip tooltipText={tooltip} isInfoIcon />
            </Info>
          </Info>
        );
      })}
      <ButtonBlock>
        {actions.summary ? (
          filteredButtons.map(({ type }) => {
            return (
              <Button
                width={buttonWidthHandler(type)}
                colors={{
                  main: buttonColorHandler('textColor', type),
                  background: buttonColorHandler('background', type),
                }}
                onClick={buttonClick(type)}
                type={type}
              >
                {buttonValues[type].name}
              </Button>
            );
          })
        ) : (
          <>
            <Button
              width={buttonWidthHandler(ButtonType.BACK_BUTTON)}
              colors={{
                main: buttonColorHandler('textColor', ButtonType.BACK_BUTTON),
                background: buttonColorHandler('background', ButtonType.BACK_BUTTON),
              }}
              onClick={buttonClick(ButtonType.BACK_BUTTON)}
              type={ButtonType.BACK_BUTTON}
            >
              {buttonValues[ButtonType.BACK_BUTTON].name}
            </Button>
            <ButtonRow>
              <Button
                width={buttonWidthHandler(ButtonType.ADD_MANUALY)}
                colors={{
                  main: buttonColorHandler('textColor', ButtonType.ADD_MANUALY),
                  background: buttonColorHandler('background', ButtonType.ADD_MANUALY),
                }}
                onClick={buttonClick(ButtonType.ADD_MANUALY)}
                type={ButtonType.ADD_MANUALY}
              >
                {buttonValues[ButtonType.ADD_MANUALY].name}
              </Button>
              <Button
                width={buttonWidthHandler(ButtonType.UPLOAD_FILE)}
                colors={{
                  main: buttonColorHandler('textColor', ButtonType.UPLOAD_FILE),
                  background: buttonColorHandler('background', ButtonType.UPLOAD_FILE),
                }}
                onClick={buttonClick(ButtonType.UPLOAD_FILE)}
                type={ButtonType.UPLOAD_FILE}
              >
                {buttonValues[ButtonType.UPLOAD_FILE].name}
              </Button>
            </ButtonRow>
          </>
        )}
      </ButtonBlock>
    </CustomModal>
  );
};
