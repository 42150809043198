import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: reducers,
});

export const { setDashboardData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
