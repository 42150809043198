import { ErrorPageVersion } from '../../containers/errorPage/ErrorPage';

export const random404 = () => {
  switch (Math.round(Math.random() * 5)) {
    case 1:
      return ErrorPageVersion.FIRST_VERSION_404;
    case 2:
      return ErrorPageVersion.SECOND_VERSION_404;
    case 3:
      return ErrorPageVersion.THIRD_VERSION_404;
    case 4:
      return ErrorPageVersion.FOURTH_VERSION_404;
    case 5:
      return ErrorPageVersion.FIFTH_VERSION_404;
    default:
      return ErrorPageVersion.FIRST_VERSION_404;
  }
};
