import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  minHeight?: number | null;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => (minHeight ? minHeight + 'px' : '100vh')};
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

type BodyProps = {
  withoutPadding?: boolean;
};

// @TODO fix media types
export const Body = styled.div<BodyProps>`
  display: flex;
  flex: 1;
  margin: 0 0 0 55px;
  padding: ${({ withoutPadding }) => !withoutPadding && '40px'};
  background-color: ${colorFetch('gray5')};
  overflow-y: hidden;

  ${media.tablet} {
    margin: 50px 0 0 0;
    padding: ${({ withoutPadding }: any) => !withoutPadding && '32px 0 0'};
  }

  ${media.phone} {
    padding: ${({ withoutPadding }: any) => !withoutPadding && '24px 0 0'};
  }
`;
