import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { CheckBox } from '@bit/first-scope.controls';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchInput } from '../../../../../../containers/common/reports/components/searchInput/SearchInput';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  title?: string;
  fieldHeight: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow3};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  position: absolute;
  top: ${({ title, fieldHeight }) => (title ? `${fieldHeight + 22}px` : `${fieldHeight + 6}px`)};
  left: 0;
  z-index: 1;
  cursor: auto;
`;

const ParameterListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 6px 4px 6px 10px;
  max-height: 190px;
`;

type ParameterListProps = {
  heightOfList: number;
};

const ParameterList = styled.ul<ParameterListProps>`
  width: 100%;
  max-height: 210px;
  margin: 0;
  padding: 0;
  overflow-y: ${({ heightOfList }) => (heightOfList > 200 ? 'scroll' : 'auto')};
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

type ParameterItemProps = {
  isLast?: boolean;
  isActive?: boolean;
};

const ParameterItem = styled.li<ParameterItemProps>`
  display: flex;
  margin-bottom: ${({ isLast }) => (isLast ? '0' : '13px')};
  cursor: pointer;
  & > div > span {
    color: ${({ isActive, theme }) => (isActive ? colorFetch('primary')({ theme }) : colorFetch('gray1')({ theme }))};
  }
  &:hover > div > span {
    color: ${colorFetch('primary')};
  }
`;

const CheckboxWrapper = styled.div`
  width: max-content;
  display: flex;
  ${media.phone} {
    align-items: center;
  }
`;

const CheckboxSemiwrapper = styled.div`
  width: max-content;
  box-sizing: border-box;
  padding: 2px;
  margin-right: 10px;
`;

type NameWrapperProps = {
  isSecondNameExists: boolean;
};

const NameWrapper = styled.div<NameWrapperProps>`
  display: ${({ isSecondNameExists }) => (isSecondNameExists ? 'grid' : 'flex')};
  grid-template-columns: 180px 1fr;
  grid-gap: 10px;
  ${media.phone} {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
`;

const StyledText = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  word-break: break-all;
`;

export const SelectListPopup = ({
  popupRef,
  parameterList,
  handleCheckedItem,
  handleSelectAll,
  fieldHeight,
  languageStrings,
  isSelectAll,
  isOpenSelectList,
  wrapperRef,
  selectedIndexOfPeriod,
  scrollTo,
  scrollToWithCustomPeriod,
  handleTransformName,
  title,
}: {
  popupRef: React.MutableRefObject<any>;
  parameterList: any[];
  handleCheckedItem: (id: string) => void;
  handleSelectAll: () => void;
  fieldHeight: number;
  wrapperRef: any;
  selectedIndexOfPeriod: number;
  scrollTo: number;
  scrollToWithCustomPeriod: number;
  isSelectAll?: boolean;
  isOpenSelectList?: boolean;
  handleTransformName?: (name: any) => string;
  languageStrings?: any;
  title?: string;
}) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const { gray1, gray2, pureWhite, primary } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [heightOfList, setHeightOfList] = useState(0);

  const handleInutOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };
  const handleClearInput = () => {
    setSearchInputValue('');
  };
  const filterParameterList = (flag: string) => {
    return parameterList.filter((item: any) => {
      if (handleTransformName) {
        let condition;
        const values = handleTransformName(item.name).split(',');
        for (let i = 0; i < values.length; i++) {
          if (values[i - 1]) {
            break;
          }
          condition = values[i].trim().toLowerCase().indexOf(flag.toLowerCase()) !== -1;
        }
        return condition;
      } else {
        return item.name.toLowerCase().indexOf(flag.toLowerCase()) !== -1;
      }
    });
  };
  const clearEvents = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleSelect = useCallback(
    (id: string) => {
      handleCheckedItem(id);
    },
    [handleCheckedItem],
  );

  useEffect(() => {
    listRef && listRef.current && setHeightOfList(listRef.current.clientHeight);
  }, []);

  useEffect(() => {
    const scrollRef = wrapperRef && wrapperRef.current;
    if (isOpenSelectList && scrollRef) {
      scrollRef.scrollTo({
        top: selectedIndexOfPeriod === 3 ? scrollToWithCustomPeriod : scrollTo,
        behavior: 'smooth',
      });
    }
  }, [isOpenSelectList, searchInputValue.length]);

  return (
    <Wrapper
      theme={theme}
      fieldHeight={fieldHeight}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      title={title}
    >
      <SearchInput
        value={searchInputValue}
        handleOnChange={handleInutOnChange}
        handleClearInput={handleClearInput}
        languageStrings={languageStrings}
      />
      <ParameterListWrapper ref={popupRef}>
        {parameterList.length > 0 ? (
          <ParameterList ref={listRef} heightOfList={heightOfList}>
            <ParameterItem
              onClick={() => {
                handleSelectAll();
              }}
            >
              <CheckboxSemiwrapper>
                <CheckBox
                  label={''}
                  color={gray2}
                  backgroundColor={pureWhite}
                  backgroundColorHover={primary}
                  toggle={isSelectAll}
                  disabled={false}
                  handleToggle={() => {}}
                />
              </CheckboxSemiwrapper>
              <Text type={TextType.TEXT_14_GRAY} color={isSelectAll ? primary : gray1}>
                {languageStrings.insightDetailsEventTableSelectAll}
              </Text>
            </ParameterItem>
            {filterParameterList(searchInputValue).map((item: any, index: number) => {
              const { name, secondary_name, isSelected, id } = item;
              return (
                <ParameterItem
                  key={index}
                  isLast={index === parameterList.length - 1}
                  isActive={isSelected}
                  onClick={() => {
                    handleSelect(id);
                  }}
                >
                  <CheckboxWrapper onTouchStart={clearEvents} onTouchEnd={clearEvents}>
                    <CheckboxSemiwrapper onClick={() => {}}>
                      <CheckBox
                        label={''}
                        color={gray2}
                        backgroundColor={pureWhite}
                        backgroundColorHover={primary}
                        toggle={isSelected}
                        disabled={false}
                        handleToggle={(e: any) => {
                          clearEvents(e);
                          handleSelect(id);
                        }}
                      />
                    </CheckboxSemiwrapper>
                  </CheckboxWrapper>
                  <NameWrapper isSecondNameExists={!!secondary_name}>
                    <StyledText type={TextType.TEXT_14_GRAY}>
                      {handleTransformName ? handleTransformName(name) : name}
                    </StyledText>
                    {secondary_name && <StyledText type={TextType.TEXT_14_GRAY}>{secondary_name}</StyledText>}
                  </NameWrapper>
                </ParameterItem>
              );
            })}
          </ParameterList>
        ) : (
          <>
            <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
              {languageStrings.placeholderNoData}
            </Text>
          </>
        )}
      </ParameterListWrapper>
    </Wrapper>
  );
};
