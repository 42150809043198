import { useTheme } from '@cthings.co/styled-components';
import { RowItemType, UniversalTable, useTableFunctions, withTableContextProvider } from '@cthings.co/universal-table';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import { selectIsOpenDeliveryAddressModal } from '../../../../../../app/state/billingDetailsSlice';
import { selectAccess, selectLanguage, selectLanguageStrings } from '../../../../../../app/state/user/selectors';
import { API_URL } from '../../../../../../consts';
import { View } from '../../../../../../routes/routeInterfaces';
import { useQueryParams } from '../../../../../../utils/useQueryParams';
import { DeliveryDetailsModal } from '../deliveryDetailsModal/DeliveryDetailsModal';
import { ExtraWrapper, StyledTitle, TitleWrapper, Wrapper } from './styled';
import { component, header } from './utils';
import { HttpService } from '../../../../../../utils/http';

const DeliveryAddressPlain = () => {
  const theme = useTheme();

  const { resetTable, getRowEssentials } = useTableFunctions();

  const languageStrings = useSelector(selectLanguageStrings);
  const { shortName } = useSelector(selectLanguage);

  const keyword = 'DeliveryAddressTable';
  const offsetKey = 'offset';

  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pathParams = useParams();

  const accessData = useSelector(selectAccess).billing.details;

  const isOpenDeliveryAddressModal = useSelector(selectIsOpenDeliveryAddressModal);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <>
      <ExtraWrapper>
        <TitleWrapper>
          <StyledTitle>{languageStrings.deliveryAddress}</StyledTitle>
        </TitleWrapper>
        <Wrapper>
          <UniversalTable
            HttpService={HttpService}
            theme={theme}
            language={shortName}
            placeholderProps={{
              keyword,
              placeholderOptions: { countRows: 6 },
              conditionalStyle: { height: '100%', overflow: 'hidden', borderRadius: '8px' },
            }}
            withHeaderGridTemplate={'1.5fr 1.5fr 1fr'}
            withHeaderInMobile={true}
            mainApiUrl={`${API_URL}/v2/billing/delivery_address`}
            apiTriggers={[shortName]}
            updateItems={() => {}}
            keyword={keyword}
            offset={+offset}
            offsetKey={offsetKey}
            actionSet={[]}
            pathParams={pathParams}
            queryParams={queryParams}
            sortingData={sortingData}
            sortingKeys={sortingKeys}
            tablePath={View.BILLING_DETAILS}
            accessData={accessData}
            isSearchEnabled={false}
            isPaginationEnabled={false}
            titleActionComponent={{ preset: 'EDIT' } as any}
            customRowClick={() => {}}
            presetMapping={{
              header: header,
              component: component,
            }}
            columnStructure={[
              {
                fieldName: 'property',
                placeholder: languageStrings.deviceInfoLabelProperty,
                label: languageStrings.deviceInfoLabelProperty,
                excludedFromAdd: true,
                excludedFromModalView: true,
                defaultValue: '',
                width: '2fr',
                shownInHeader: true,
              },
              {
                fieldName: 'value',
                placeholder: languageStrings.insightDetailsDeviceConfigHeaderValue,
                label: languageStrings.insightDetailsDeviceConfigHeaderValue,
                excludedFromAdd: true,
                excludedFromModalView: true,
                type: RowItemType.CUSTOM,
                component: { preset: 'ACTUAL_VALUE' },
                width: '2fr',
                defaultValue: '',
                shownInHeader: true,
              },
            ]}
          />
        </Wrapper>
        {isOpenDeliveryAddressModal && <DeliveryDetailsModal getRowEssentials={getRowEssentials} />}
      </ExtraWrapper>
    </>
  );
};

export const DeliveryAddressTable = withTableContextProvider(DeliveryAddressPlain);
