import React from 'react';
import { Edit } from './styled';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user/selectors';
import { useTheme } from '@cthings.co/styled-components';

export const EditButton = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();
  const { primary, secondary2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Edit
      colors={{
        main: primary,
        mainHover: secondary2,
        background: 'transparent',
        backgroundHover: 'transparent',
        border: 'transparent',
        borderHover: 'transparent',
      }}
      onClick={onClick}
    >
      {languageStrings.insightDetailsDeviceConfigHeaderEdit}
    </Edit>
  );
};
