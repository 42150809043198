import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { InfoIcon } from '../../../../../qualityModal/components/infoIcon/InfoIcon';

export type DeploymentDetailsInfoType = { fieldName: string; tooltipText: string; value: string };

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
` as any; // @TODO fix type

const StyledModalContainer = styled(ModalContainer)`
  width: 527px !important;
  height: max-content;
  padding: 25px 24px !important;
  display: flex;
  align-items: flex-start !important;
  ${media.phone} {
    width: 100% !important;
    position: fixed !important;
    padding: 24px 16px 18px !important;
    bottom: 0 !important;
    border-radius: 16px 16px 0 0 !important;
  } ;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const InfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(CommonButton)`
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
  }
`;

export const DeploymentDetailsModal = ({
  infoArr,
  closeModal,
  isOpenModal,
}: {
  infoArr: DeploymentDetailsInfoType[];
  closeModal: () => void;
  isOpenModal?: boolean;
}) => {
  const theme = useTheme();
  const { black1, gray1, gray2 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Text type={TextType.SEMITITLE} color={black1} fontSize={'18px'} weight={'700'} margin={'0 0 6px 0'}>
          {languageStrings.deploymentDetailsModalTitle}
        </Text>
        <InfoWrapper>
          {infoArr.map((item: DeploymentDetailsInfoType, index: number) => {
            const { fieldName, tooltipText, value } = item;
            return (
              <InfoRow key={index}>
                <TooltipWrapper>
                  <Text
                    type={TextType.TEXT_14_BLACK}
                    color={gray1}
                    fontSize={'14px'}
                    weight={'500'}
                    margin={'0 5px 0 0'}
                  >
                    {fieldName ? fieldName : 'N/A'}
                  </Text>
                  <InfoIcon tooltip={tooltipText} />
                </TooltipWrapper>
                <Text type={TextType.TEXT_14_BLACK} color={value ? gray1 : gray2} fontSize={'14px'} weight={'400'}>
                  {value ? value : 'N/A'}
                </Text>
              </InfoRow>
            );
          })}
        </InfoWrapper>
        <ButtonWrapper>
          <StyledButton width={'104px'} height={'32px'} onClick={closeModal}>
            {languageStrings.closeButtonValue}
          </StyledButton>
        </ButtonWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
