import React, { Dispatch, FC, SetStateAction } from 'react';
import { ItemWrapper, StyledNavLink, TextWrapper, StyledText } from './styled';
import { TextType } from '@bit/first-scope.text';
import Tooltip from '../../../components/tooltip';
import { getAccess } from '../../../routes';
import { ItemImage } from './ItemImages';
import { MenuItemType } from '../MenuItem';
import { useStyleContext } from '@cthings.co/styles-utils';
import { SecondaryMenuItem } from './mobileNavigation/MobileNavigation';
import { useTheme } from '@cthings.co/styled-components';

export interface ItemTypes {
  disabled?: boolean;
  hidden?: boolean;
  tooltipId?: string;
  to: any;
  type: MenuItemType;
  isOpen: boolean;
  isActiveInsightRoute: boolean;
  value?: string;
  handleCloseFirstMenu?: () => void | undefined;
  setSecondaryMenuItems?: Dispatch<SetStateAction<SecondaryMenuItem[]>> | undefined;
}

export const ItemInsightMap: FC<ItemTypes> = ({
  disabled,
  hidden,
  tooltipId,
  to,
  type,
  isOpen,
  value,
  isActiveInsightRoute,
  handleCloseFirstMenu,
  setSecondaryMenuItems,
}) => {
  const theme = useTheme();
  const { additional4 } = theme.borderRadius;

  const [
    {
      mediaType: { tablet },
    },
  ] = useStyleContext();

  const onClickHandler = (e: any) => {
    disabled && e.preventDefault();
    tablet && handleCloseFirstMenu && handleCloseFirstMenu();
    tablet && setSecondaryMenuItems && setSecondaryMenuItems([]);
  };

  return (
    <ItemWrapper className={hidden ? 'hidden' : ''}>
      <Tooltip id={disabled ? tooltipId || '' : ''} text={'coming soon'}>
        <StyledNavLink
          to={to}
          access={getAccess(to)}
          onClick={(e: any) => onClickHandler(e)}
          borderRadius={`0 ${additional4} ${additional4} 0`}
          className={isActiveInsightRoute ? 'active' : ''}
        >
          <ItemImage type={type} />
          <TextWrapper className={isOpen ? 'open' : ''}>
            <StyledText
              type={TextType.MENU_ITEM_TEXT_PRIMARY}
              color={theme.colors.black1}
              hoverColor={theme.colors.primary}
            >
              {value}
            </StyledText>
          </TextWrapper>
        </StyledNavLink>
      </Tooltip>
    </ItemWrapper>
  );
};
