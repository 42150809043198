import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../app/state/user';
import { MAPBOX_API, MAPBOX_TOKEN } from '../consts';
// import { getGeoDistance } from '../utils/geoDistance';
import { HttpService } from '../utils/http';
import { usePrevious } from '../utils/usePrevious';

export const useObjectPos = (search: string) => {
  const [position, setPosition] = useState([]);
  useEffect(() => {
    const url = `${MAPBOX_API}/mapbox.places/${search}.json?access_token=${MAPBOX_TOKEN}`;
    if (search) {
      (async () => {
        try {
          let responseData = (await HttpService.get(url)).data;
          const positionList = responseData.features.map((position: any) => {
            //const bbox = position.bbox;
            const radius = /*bbox ? (1 / 2) * getGeoDistance(bbox[0], bbox[1], bbox[2], bbox[3]) :*/ 200;
            const { center, place_name } = position;

            return {
              radius,
              center,
              name: place_name,
            };
          });
          setPosition(positionList);
        } catch (e) {
          //
        }
      })();
    } else {
      setPosition([]);
    }
  }, [search]);

  return [position];
};

const getAddressField = (field: string) => {
  switch (field) {
    case 'postcode':
      return 'zip';
    case 'region':
      return 'region';
    case 'country':
      return 'country';
    case 'place':
      return 'city';
    default:
      return 'country';
  }
};

export const useAddress = (lat: number, lng: number, alt?: number | null) => {
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    zip: '',
    city: '',
    region: '',
    country: '',
    geotag: {
      lat,
      lng,
      alt,
    },
  });
  const prevLat = usePrevious(lat);
  const prevLng = usePrevious(lng);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter((val) => val + 1);
    const url = `${MAPBOX_API}/mapbox.places/${`${lng},${lat}`}.json?access_token=${MAPBOX_TOKEN}&types=address`;
    // url for height above sea level
    //const urlHeightSeaLevel = `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${lng},${lat}.json?&access_token=${MAPBOX_TOKEN}`;

    if (lat && lng && (prevLat !== lat || prevLng !== lng)) {
      (async () => {
        try {
          let responseData = (await HttpService.get(url)).data;
          // request for height above sea level
          //let dataHeightSeaLevel = (await HttpService.get(urlHeightSeaLevel)).data;
          const { features } = responseData;
          //const { features: levels } = dataHeightSeaLevel;
          //const heightSeaLevel = levels && levels[levels.length - 1]?.properties?.ele;

          const { context, center, place_name } = features[0] || {};

          const line1 = place_name ? place_name.split(',')[0].trim() : '';

          const finalAddressList = (context || []).reduce(
            (accumulator: any, cont: any) => {
              const field = cont.id.match(/[^0-9.]+(?=.)/)[0];
              return { ...accumulator, [getAddressField(field)]: cont.text };
            },
            { line1, line2: '', region: '', country: '', city: '', zip: '' },
          );

          const geoTaggedAddress = {
            ...finalAddressList,
            geotag: { lat /*: center[1]*/, lng /*: center[0]*/, alt },
          };

          setAddress({ ...geoTaggedAddress });
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [lat, lng]);

  const isInitialLoad = counter < 2;

  return [address, isInitialLoad] as const;
};
