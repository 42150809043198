export const EMPTY_ADDRESS = {
  city: '',
  country: '',
  geotag: { lat: '52.24424681018519', lng: '21.005766087471937', alt: '' },
  line1: '',
  line2: '',
  region: '',
  zip: '',
};

export const DEFAULT_ADDRESS = {
  city: 'Warsaw',
  country: 'Poland',
  geotag: { lat: '52.24424681018519', lng: '21.005766087471937', alt: '' },
  line1: 'Bielańska 5',
  line2: '',
  region: 'Masovian Voivodeship',
  zip: '00-086',
};

export const MAPBOX_ZOOM = 15;
