import { HighBattery } from './assets/HighBattery';
import { LowBattery } from './assets/LowBattery';
import { MediumBattery } from './assets/MediumBattery';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

export enum BatteryLvlType {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const BatteryLvl = ({ type }: { type: BatteryLvlType }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const setBatteryLvl = (type: BatteryLvlType) => {
    const battery = {
      [BatteryLvlType.LOW]: <LowBattery />,
      [BatteryLvlType.MEDIUM]: <MediumBattery />,
      [BatteryLvlType.HIGH]: <HighBattery />,
    };
    return battery[type];
  };

  return (
    <>
      {type ? (
        setBatteryLvl(type)
      ) : (
        <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
          {'N/A'}
        </Text>
      )}
    </>
  );
};
