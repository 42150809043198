import React from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;

  width: 0;
  padding: 15px 12px;
  top: 50%;
  right: 0;

  transition: all 0.3s ease;
  transform: translate(calc(100% + 18px), -50%);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  background-color: white;
  border: 1px solid;
  border-color: ${({ theme }: any) => theme.colors.gray5};
  border-radius: ${({ theme }: any) => theme.borderRadius.additional6};
  box-shadow: 0px 6px 100px rgba(7, 4, 85, 0.01), 0px 4px 100px rgba(7, 4, 112, 0.02),
    0px 8px 36px rgba(7, 4, 102, 0.03), 0px 6px 16px rgba(5, 3, 88, 0.02);

  &.open {
    min-width: 136px;
    width: auto;
    opacity: 1;
    pointer-events: auto;
  }
  ${media.tablet} {
    display: none;
  }
`;

export interface NavigationDropdownTypes {
  children: any;
  className: string;
}

export const NavigationDropdown = ({ children, className }: NavigationDropdownTypes) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};
