export const sv_se = {
  accessDownloadEvents:
    'Vill du ladda ner en Excel-fil med händelsedata för den här enheten för den valda tidsperioden?',
  accessDownloadTemperature:
    'Vill du ladda ner en Excel-fil med temperaturdata för den här enheten för den valda tidsperioden?',
  accessDownloadWaterLevel:
    'Vill du ladda ner en excel-fil med vattenståndsdata för den här enheten för den valda tidsperioden?',
  accessToThePlatformBlocked: 'Din åtkomst till plattformen har blockerats eftersom du har obetalda fakturor.',
  actionButtonValue: 'Handling',
  add: 'Lägg till',
  addCommentButton: 'Lägg till kommentar',
  addCompany: 'Lägg till företag',
  addDeviceModalClarifyingDesc:
    'Slutanvändaren kommer fortfarande att behöva tillhandahålla och distribuera enheterna för att kunna samla in och övervaka data.',
  addDeviceModalDesc:
    'Genom att lägga till enheter till ett företagskonto kommer alla användare som tilldelats detta företag att kunna se och spåra prestanda för dessa enheter. Användare med administratörsbehörigheter kommer också att kunna konfigurera sina inställningar och lägga till nya enheter i kontot.',
  addDeviceModalWaysDesc: 'Det finns två sätt att tilldela enheterna till ett företagskonto',
  addDevicesManuallyTitle: 'Lägg till enhet(er) manuellt',
  addEventText: 'Lägg till händelse',
  addIncidentTitle: 'Lägg till incident',
  additionalDeviceAddDevice: 'Lägger till en ny enhet',
  additionalDeviceEnterCode: 'Ange 10-siffrig kod från instruktionen',
  additionalDeviceNextButton: 'Nästa',
  additionalInfoLabel: 'Ytterligare info',
  additionalInfoPlaceholder: 'Välj ytterligare information',
  addManuallyAddDeviceModal: 'Lägg Till Manuellt',
  addManuallyDescAddDeviceModal:
    'skriv in serienumren på de enheter du vill tilldela. Det kan vara ett antal enskilda enheter eller ett intervall inklusive en större sats',
  addManuallyTooltipAddDeviceModal:
    'Rekommenderas för att lägga till upp till 10 enheter samtidigt eller ett intervall från en produktionsbatch.',
  addNew: 'Lägg till ny',
  addNewCompany: 'Lägg till nytt företag',
  addNewCompanyDesc:
    'Företaget är en överordnad instans för användare och enheter och det fungerar som deras aggregator. Alla användare och enheter måste tilldelas en company.In för att använda alla plattformsfunktioner kommer användarskapandet att behövas.',
  addNewCompanyDetails: 'Vänligen ange detaljerna för det nya företaget.',
  addNewCompanyModalSecondStepDescription:
    'Användare är en instans som gör det möjligt att övervaka prestanda för enheterna på plattformen och hantera deras inställningar.',
  addNewCompanyModalSecondStepHintText:
    'Den första användaren som tilldelas ett företag måste ha ett administratörskonto för att kunna lägga till fler användare och hantera inställningar för enheter.',
  addNewCompanyModalSecondStepTitle: 'Skapa första användare på företagets konto',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText1: 'kan redigera inställningarna för kontot och enheterna.',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText2:
    'är begränsad till skrivskyddad visning, har inte tillåtelse att redigera inställningarna.',
  addNewCompanyModalSecondStepTypesOfAccountsText: 'Det finns två typer av konton för användare:',
  addOrganisationLabel: 'Lägg till ny organisation',
  addresInstructionDesc1: 'Ange adress eller plats i sökfältet i det övre vänstra hörnet av kartan.',
  addresInstructionDesc2:
    'När du ser den allmänna platsen zoomar du in på kartan och drar den till den exakta platsen för bättre precision.',
  addresInstructionDesc3: 'Klicka på Bekräfta för att spara ditt val och avsluta menyn.',
  addresInstructionDesc4:
    'Använd textfälten längst ner i fönstret för att ange den exakta adressen till enhetens plats.',
  addresInstructionDesc5: 'Klicka på Bekräfta för att spara adressen och stänga menyn.',
  addresInstructionDesc6: 'Observera: kartan visar inte platsen med den här metoden.',
  addresInstructionTitle1: 'Metod 1: Kartsökning',
  addresInstructionTitle2: 'Metod 2: Adressinmatning',
  addressSeaLevelLabel: 'Höjd över havet (m)',
  addressSeaLevelPlaceholder: 'Ange havsnivån',
  addUser: '+ Lägg till användare',
  advancedLabel: 'Avancerat',
  alertMessageError: 'Error appears when you did wrong or there is system fault',
  alertMessageNeutral: 'Neutral info displayed here',
  alertMessagePositive: 'Successful message to those who did good',
  alertMessageWarning: 'Warning text here so you can be more careful',
  allButton: 'Alla',
  analyticsItem: 'Analytics',
  anUnexpectedErrorHasOccurred: 'Ett oväntat fel har uppstått. Var god försök igen',
  applyButton: 'Tillämpa',
  applyButton1: 'Ansöker',
  applyButton2: 'Applicerad',
  areYouSure: 'Är du säker?',
  average: 'snitt',
  averagingMethodLabel: 'Genomsnittsmetod',
  backButton: 'Tillbaka',
  backHome: 'Tillbaka till startsidan',
  basedOnManningsEquation: 'Baserat på Mannings ekvation, ',
  basicLabel: 'Grundläggande',
  battery: 'Batteri',
  batteryLevel: 'Batterinivå',
  batteryStatusBad: 'Låg',
  batteryStatusGood: 'Hög',
  batteryStatusWarn: 'Medium',
  billing: 'Fakturering',
  billingAddressPlaceholder: 'Välj faktureringsadress',
  billingDetails: 'Faktureringsuppgifter',
  billingDetailsNotFilledDesc: 'Kontakta din administratör om problemet kvarstår.',
  calibraion: 'Kalibrering',
  calibraionInPropgress: 'Kalibrering pågår',
  calibraionLabel: 'Kalibreringsdetaljer',
  calibraionTooltip: 'Läs mer om kalibrering',
  calibration: 'Kalibrering',
  calibrationDate: 'Kalibreringsdatum',
  calibrationDesc:
    'Kalibrering utförs alltid efter installationen av enheten. Det är nödvändigt att omkalibrera enheten om du flyttade enheten sedan den senaste kalibreringen, eller om kalibreringen utfördes felaktigt. Innan du startar kalibreringsprocessen, se till att enheten är på avsedd plats och att den förblir ostörd under kalibreringsperioden - upp till 48 timmar.',
  calibrationDescInProgress:
    'Kalibreringsprocessen har börjat. Det kan ta upp till 48 timmar. Tänk på att under denna tid kan vattennivåmätningarna vara opålitliga. Se till att enheten förblir ostörd under denna period.',
  calibrationInfoTooltip:
    'Under kalibreringsprocessen skannar enheten insidan av brunnen för att upptäcka alla statiska element, för att ta hänsyn till dem när de utför mätningar och säkerställa korrekt datainsamling. Det utförs alltid efter driftsättning',
  calibrationPeriodTootlipText: 'Uppgifterna från kalibreringsperioden\n kan vara otillförlitliga',
  calibrationText: 'Kalibrering',
  calibrationTooltipText: 'Data från kalibreringsperioden kan vara oprecisa',
  cancelButton: 'Annullera',
  chooseAddressPlaceholder: 'Välj adress',
  chooseDateTitle: 'Välj datum',
  chooseDeviceNamePlaceholder: 'Välj namn på enheten',
  chooseOrganisation: 'Välj organisation',
  chooseOrganisationEmail: 'Organisationens e-post',
  chooseOrganisationName: 'Organisationens namn',
  chooseOrganisationNamePlaceholder: 'Ange namn',
  chooseOrganisationPhone: 'Organisationens telefon',
  chooseOrganisationPhonePlaceholder: 'Ange telefon',
  chooseParentOrgFirst: 'Välj överordnad org. först',
  choosePaymentMethodPlaceholder: 'Välja betalningsmetod',
  choosePlansetForEachDevices:
    '\nVälj plansamling\n för varje enhet du tilldelar. Det kommer att avgöra vilka planer enhetstillhandahållsprocessen kommer att erbjuda som alternativ.',
  chooseSeverityPlaceholder: 'Välj svårighetsgrad',
  chooseTypeOfInvoices: 'Välja typer av fakturor',
  chooseTypePlaceholder: 'Välj typ',
  choosingPlanset: 'Välja plansamling',
  clearButton: 'Rensa enheter',
  clearButtonMobile: 'Rensa',
  clickOn: 'Klicka på ',
  closeButtonValue: 'Stänga',
  closedText: 'Stängd',
  commentsPlaceholder: 'Inga kommentarer än',
  commentTableDayToday: 'I dag, ',
  commentTableDayYesterday: 'I går, ',
  commentTableEdit: 'Redigera Meddelande',
  commentTableEditLabel: 'Redigerade: ',
  commentTableEditPlaceholder: 'Redigera meddelande...',
  commentTablePlaceholder: 'Skriv en kommentar...',
  commonButtonMore: 'Mer',
  commonButtonRevoke: 'Återkalla',
  commonButtonSend: 'Skicka',
  commonButtonUpload: 'Upload',
  companyAddress: 'Företagets adress',
  companyAddressPlaceholder: 'Välj företagets adress',
  companyCreationEmailAddress: 'Din e-postadress',
  companyName: 'Företagets namn',
  companyNameLabel: 'Företagsnamn',
  companyNamePlaceholder: 'Ange företagets namn',
  companyNamePlaceholderr: 'Företagsnamn',
  companyOnly: 'Endast företag',
  companyOnlyCardInfo:
    'Gå till det om du bara vill lägga till ett företag just nu. För att göra företagets konto operativt måste du manuellt lägga till en administratörsanvändare och tilldela enheter till denna comapny i ett senare skede.',
  companyPhone: 'Företagets telefon',
  companyPhonePlaceholder: 'Ange företagets telefon',
  companyPhoneTooltip:
    'Företagets allmänna telefonnummer.Om företaget inte har en, Fyll i det med ett telefonnummer till den första användaren som kommer att tilldelas detta företag.',
  completedProvisioningButton: 'Gå till plattformen',
  completedProvisioningContent:
    'Du har slutfört provisioneringen av enheten. För att börja hantera enheten måste du logga in på Smart Utilities-plattformen. Skapandet av en ny organisation kan ta upp ',
  completedProvisioningContent2: 'till 10 minuter',
  completedProvisioningContent3: 'Vänligen logga in på plattformen efter denna tid.',
  completedProvisioningLabel: 'Provisioneringen är klar',
  conditionalCheckerModalCheckboxLabel: "Don't Show this message again",
  conditionalCheckerModalCommonButton: ' Jag förstår',
  conditionalCheckerModalTitleFirstPart:
    'We will run maintenance. All system will be updated automatically in the next',
  conditionalCheckerModalTitleSecondPart: 'hours.',
  configuration: 'Konfiguration',
  contactSupport: 'Kontakta support',
  continue: 'Fortsätta',
  continueStyled: '“Fortsätta“',
  copiedMessage: 'Kopierade till urklipp',
  coverTitle: 'Omslag',
  createAccount: 'Registrera dig',
  createButton: 'Skapa',
  createMoreUsers: 'Skapa fler användare',
  creationDate: 'Skapelsedagen',
  currentNetworkConnectionTechnology: 'Anslutningsteknik',
  currentNetworkLastUpdated: 'Senast uppdaterad',
  currentNetworkPreferedConectionTechnology: 'Föredragen anslutningsteknik',
  dashBoardCardCitiesTitle: 'Platser',
  dashBoardCardDevicesTitle: 'Totalt antal enheter',
  dashBoardCardLocationsTitle: 'Totalt antal platser',
  dashboardCardsTitleDevices: 'Totalt antal enheter',
  dashboardCardsTitleLocations: 'Totalt antal positioner',
  dashboardCardsTitleNoProblems: 'Inga Problem Upptäckta',
  dashboardCardsTitleProblems: 'Problem Upptäckt',
  dashboardCardsTitleReport: 'Rapportera problem',
  dashboardCardsTitleTemperature: 'Medeltemperatur',
  dashboardCardsTitleWarnings: 'Varningar',
  dashboardTitle: 'Överblick',
  dataPeriodLabel: 'Dataperiod',
  dataProcessingDescription: 'Bearbetar stora mängder data. Vänta',
  dataProcessingTitle: 'Data behandling',
  days: 'dagar',
  daysUnit: 'dagar',
  deleteDevicesModal: 'Listan över valda enheter rensas.',
  deleteModalInfoText: 'Listan över valda enheter rensas.',
  deletingComment: 'Denna kommentar kommer att raderas.',
  deletPairModal: 'Manhålsparet kommer att raderas permanent.',
  deliveryAddress: 'Leveransadress',
  deploy: 'Distribuera',
  deployed: 'Användas',
  deploying: 'Utplacering',
  deploymentDate: 'Datum för installation',
  deploymentDetailsModalTitle: 'Distributionsdetaljer',
  deploymentIsInProgress: 'Enhetsdistribution pågår. Återkom senare!',
  deploymentIsInProgressTextFirst:
    'Deployment is in progress, it can take up to 48 hours. Make sure the device stays undisturbed during this time.',
  deploymentIsInProgressTextSecond:
    'In the meantime, we recommend that you \n set other parameters \n of the device, such as transmission and measurement intervals or alarm configurations. If you want to set it from the device details page later, close this window.',
  deploymentIsInProgressTitle: 'Deployment is in progress',
  deploySetDepthModalText:
    'Tillförlitliga mätningar kräver ett noggrant maximalt djup, mätt från radarn till den djupaste punkten i tanken.',
  deploySetDepthModalTitle: 'Ange maximalt djup',
  depth: 'Djupet',
  depthNotice:
    'Brunnens djup är avgörande för korrekta mätningar. Ange djupet så snart som möjligt, annars kan måttet inte vara korrekt.',
  depthUnknown: 'Djupet är okänt',
  descriptionLabel: 'Beskrivning',
  descriptionPlaceholder: 'Ange beskrivning',
  details: 'Detaljer',
  deviceDeploymentIsInProgress: 'Enhetsdistribution pågår',
  deviceDetailsGoToInsightDetails: 'Gå till platsen',
  deviceID: 'Enhets ID',
  deviceIdText: 'Enhets ID: ',
  deviceInfoLabelProperty: 'Fast egendom',
  deviceIsntDeployed: 'Enheten är inte utplacerad',
  deviceLabel: 'Enhet',
  deviceName: 'Enhetens namn',
  directLink: 'direktlänk',
  dismissableModalCommonButton: 'Learn More',
  dismissableModalTitle: 'This is an example of a simplified view that can be dismissed. Action isn’t required.',
  distanceToWater: 'avstånd till vatten/vattennivå',
  distanceToWaterTooltip: 'Avstånd till vatten',
  documentsManual: 'Manuell',
  documentsOneMore: 'Ytterligare en dok',
  documentsReleaseNote1_1: 'Släpp anteckning 1.1',
  documentsReleaseNote2_0: 'Släpp anteckning 2.0',
  documentsReleaseNote2_4: 'Släpp anteckning 2.4',
  documentsReleaseNote2_5: 'Släpp anteckning 2.5',
  documentsReleaseNote2_6: 'Släpp anteckning 2.6',
  documentsSpecification: 'Smart RADAR-specifikation',
  download: 'Ladda ner',
  doYouWantToApply: 'Vill du tillämpa denna plansamling på alla enheter av denna typ eller bara denna?',
  dueOn: 'Betalas på',
  editDeliveryAddressTitle: 'Redigera leveransadress',
  editInvoiceTitle: 'Redigera fakturauppgifter',
  editMessageTitle: 'Redigera meddelande',
  emailAddress: 'E-postadress',
  emailAddressPlaceholder: 'Ange e-postadress',
  emailAddressTooltip:
    'Företagets allmänna e-postadress.Om företaget inte har en, Fyll i den med en e-postadress till den första användaren som kommer att tilldelas detta företag.',
  emailNotificationsError: 'Ett oväntat fel har uppstått. Var god försök igen',
  emailNotificationsOff: 'E-postaviseringar har inaktiverats för den här enheten',
  emailNotificationsOn: 'E-postaviseringar är aktiverade för den här enheten',
  emphasizedHeaderTextModalDescription: 'You won’t be able to undo this action.',
  emphasizedHeaderTextModalTitle: 'Är du säker?',
  endUserGuide: 'Slutanvändarhandbok',
  enterInfoPlaceholder: 'Ange info',
  errorMessageForSelectAddress: 'Fälten "rad 1" och "stad" måste fyllas i.',
  errorPage401Description: 'Åtkomst nekas på grund av ogiltiga referenser.',
  errorPage401Title: 'Obehörig',
  errorPage403Description:
    'Du har tyvärr inte behörighet att visa den här katalogen. Du kan gå tillbaka till huvudsidan om du har behörighet.',
  errorPage403Title: 'Förbjuden',
  errorPage404Description: 'Vi hittar inte den sida du letar efter',
  errorPage404Title: 'Sidan hittas inte',
  errorPage500Description:
    'Vi har ett problem. Försök med ett av dessa alternativ för att få dig tillbaka på rätt spår',
  errorPage500Title: 'Något gick fel',
  errorPageMainPage: 'Huvudsida',
  errorPageReload: 'Ladda om',
  existingItem: 'Det här objektet används och kan inte raderas just nu.',
  export: 'Exportera',
  exportButton: 'Exportera',
  exportExcelFileTitle: 'Exportera Excel-fil',
  failureReasonsModalHeader: 'Orsaker till misslyckanden',
  failureReasonsModalInfo:
    'Det här är vanliga problem som inträffar under uppladdningen som kan orsaka att uppladdningen misslyckas, kontrollera din fil för följande problem och ladda sedan upp den igen:',
  failureReasonsModalReasonFirst: 'Matchningsproblem:',
  failureReasonsModalReasonFourth: 'Filstorleken är för stor:',
  failureReasonsModalReasonSecond: 'Formatproblem:',
  failureReasonsModalReasonThird: 'Enheten är inte tillgänglig:',
  failureReasonsModalSuggestionFirst:
    'uppgifterna i filen stämmer inte överens med kraven. Ladda ner exempelfilen och matcha kolumnerna som anges där.',
  failureReasonsModalSuggestionFourth:
    'för att minska filstorleken kan du ta bort onödiga datavärden, kolumner och rader.',
  failureReasonsModalSuggestionSecond:
    'de uppgifter som införts i filen kan inte behandlas. Kontrollera om enhetsnumren matchar följande format: SMC12345678900.',
  failureReasonsModalSuggestionThird:
    'serienumret för en enhet är inte tillgängligt eftersom det är tilldelat till ett annat företag. Om du vill tilldela det till ett nytt företag måste du först återtilldela det till dig.',
  filtersButton: 'Filters',
  filtersNotificationsError: 'Ett oväntat fel har inträffat. Vänligen ladda om sidan.',
  filtersSelectError: 'Fel: inga uppgifter',
  finalizeButton: 'Avsluta',
  firstDeviceAddress: 'Adress för enhet #1',
  floatingModalCommonButtonDiscard: 'Discard',
  floatingModalTitle: 'Save changes?',
  flowInfo: 'Flödesinformation',
  flowLabel: 'Flöde',
  flowMeasurementBasedOn: 'Flödesmätning baserad på',
  flowMeasurementsLabel: 'flödesmätningar',
  flowUnit: 'Flödesenhet',
  freeFlowCircularCulvert: 'Runtgående cirkulär kulvert med fritt flöde',
  fullAdditionalDescription:
    'Supportavgiften är en månatlig prenumerationskostnad som täcker personlig igångsättning, dedikerad kundsupport och löpande programuppdateringar. Den debiteras endast för de första 5 tillhandahållna enheterna, oavsett deras plan.',
  fullAddress: 'Fullständig adress',
  fullNameOfOrganisation: 'Fullständigt namn (eller organisationens namn)',
  fullNameOfOrganisationPlaceholder: 'Ange fullständigt namn (eller organisationens namn)',
  fullyOperationalAccount: 'Fuldt operationel konto',
  fullyOperationalAccountCardInfo:
    'Snabbt och enkelt sätt att skapa ett fullt fungerande konto för ett nytt företag. Genom att följa en 3-stegs guide kommer du att sluta med ett konto redo att användas av din klient.',
  generateButton: 'Generera',
  generateReportModalDay: 'I dag',
  generateReportModalMonth: 'Den här månaden',
  generateReportModalParameterCustomerPlaceholder: 'Välj kund(er).',
  generateReportModalParameterLocationPlaceholder: 'Välj plats(er)',
  generateReportModalParameterLocationPlaceholderDisabled: 'Välj kund(er) först',
  generateReportModalParameterRecipients: 'Mottagare',
  generateReportModalParameterRecipientsPlaceholder: 'Välj mottagare',
  generateReportModalPeriod: 'Period',
  generateReportModalPeriodBorderFrom: 'Från:',
  generateReportModalPeriodBorderTo: 'Till:',
  generateReportModalShowMessage: 'Du bør vælge',
  generateReportModalWeek: 'Denna vecka',
  generateReports: 'Generera rapport',
  getProfilesError: 'Vald överordnad org. inte har profilerna, välj en annan överordnad organisation',
  getStartedButton: 'Komma Igång',
  goBack: 'Gå tillbaka',
  gotIt: 'Jag fattar',
  goToBillingDetails: 'Gå till fakturadetaljer',
  goToInvoices: 'Gå till fakturor',
  goToMain: 'Gå till huvudmeny',
  gpsCheck1Row:
    'GPS-placeringstjek gör det möjligt att kontrollera den aktuella placeringen av en och gemme den på plattformen. Den här processen är dänare batteri dybt, vilket förkortar enhedens livslängd. ',
  gpsCheck2Row:
    'Denna process dræner batteriet dybt, vilket förkortar enhedens livslängd. Därför \nrekommenderar vi att använda GPS-koordinatkontrollfunktionen till 2 gånger\n, bara om: ',
  gpsCheck3Row:
    '- du installerar, implementerade och kalibrerade enheden i den tillsigtede tanken för första gänget och vill ge dig GPS-koordinater som enhedens placering,',
  gpsCheck4Row: '- du kan inte lokalisera enheden på grund av en uautoriserad afhentningshændelse.',
  gpsCheck5Row: '\nÄr du säker på, när du vill fortsätta med GPS-positionskontrollen?\n',
  gpsCheckTooltip: 'Lär dig mer om GPS-platsen',
  gpsDetailsAddressTitle: 'Koordinater tar emot från GPS',
  gpsDetailsAreYouSureText: 'Varje GPS-kontroll tappar batteriet djupt. Är du säker på att du vill fortsätta?',
  gpsDetailsAreYouSureTitle: 'Är du säker?',
  gpsDetailsCheckPerformedText: 'Manuella GPS-platskontroller utförda: ',
  gpsDetailsManualChecksPerformed: 'Manuella kontroller utförda',
  gpsDetailsRequestCheck: 'Anmod om GPS-tjek',
  gpsDetailsSaveAsMainLocText: 'Är du säker på att du vill ändra den aktuella platsen för enheten till den här?',
  gpsDetailsSaveAsMainLocTitle: 'Spara som huvudplats',
  gpsDetailsSaveMainLocation: 'Pärla som huvudplacering',
  gpsDetailsSaveMainLocationText: 'Är du säker på att du vill ändra en aktuell placering till denna?',
  gpsDetailsSaveMainLocationTitle: 'Pärla som huvudplacering',
  gpsDetailsTitle: 'GPS-placeringsinformation',
  gpsLocationCheckText:
    'Du kan begära GPS-platskontrollen, som kommer att utföras när nästa dataöverföringscykel inträffar (kontrollera överföringsfrekvensen i enhetsinformationen). När koordinaterna har tagits emot från GPS kommer de att finnas tillgängliga här.',
  gpsLocationCheckTitle: 'Anmodning om GPS-placeringskontroll',
  gpsLocationProperty: 'GPS-position',
  GPSLocationText: 'Kontroll av GPS-position',
  gpsRequestSuccess: 'GPS-platsen uppdaterades',
  graphPlaceholderLabel: 'Vattennivå',
  gravitationalCircularCulvert: 'Gravitationell cirkulär kulvert',
  groundMeter: 'Smart NIVÅ',
  groupFieldPlaceholder: 'Select customer',
  hightText: 'Höjd',
  hourLabel: 'timme',
  hours: 'timmar',
  hoursUnit: 'timmar',
  humidity: 'Fuktighet',
  humidityMeasurements: 'Fuktighetsmätningar',
  ifItDoesntTryThis: 'Om det inte gör det, prova detta',
  imagesLabel: 'Bilder (valfritt)',
  incidentAddressPlaceholder: 'Väljs automatiskt',
  incidentsNotifications: 'Anmälningar om incidenter',
  incidentsPlaceholder: 'Skriv något...',
  includedLablel: 'Ingår',
  incorrectTypeOfFile: 'Fel typ av fil. Välj bildformat (.jpg, .png, .heic etc.)',
  individualText:
    'söka efter en enda enhet. Du kan tilldela de sökta och följande enheterna genom att markera rutorna bredvid dem.',
  individualTitle: 'Enskild',
  info: 'Info',
  infoButtonTooltip: 'some info',
  informationModalHeader: 'Skapa ett konto för ett nytt företag',
  informationModalInfo:
    'Opret hurtigt en ny konto til din kunde. Bare følg 3 nemme trin for at oprette en fuldt operationel konto:',
  informationModalListOfTextFirst: 'Lägg till nytt företag;',
  informationModalListOfTextSecond: 'Skapa användare inom företagets konto;',
  informationModalListOfTextThird: 'Tilldela enhet(er) till företagets konto.',
  inisghtDetailsCommentsPlaceholder: 'Skriv något…',
  inisghtDetailsHistorySelectLast: 'Senaste',
  inisghtDetailsHistorySelectPeriodsDays: 'dagar',
  inisghtDetailsHistorySelectPeriodsMonths: 'månader',
  inisghtDetailsHistorySelectPeriodsWeek: 'vecka',
  inisghtDetailsHistorySelectPeriodsYear: 'år',
  insightDetailsCommentsTitle: 'Kommentarer',
  insightDetailsCurrentNetworkTitle: 'Nuvarande nätverk',
  insightDetailsDeviceConfigAddressButtonsConfirm: 'Bekräfta',
  insightDetailsDeviceConfigAddressChooseLocation: 'Välj position på kartan',
  insightDetailsDeviceConfigAddressInputsCity: 'Stad',
  insightDetailsDeviceConfigAddressInputsCountry: 'Land',
  insightDetailsDeviceConfigAddressInputsLatitudeName: 'Latitud',
  insightDetailsDeviceConfigAddressInputsLatitudePlaceholder: 'Ange Latitude',
  insightDetailsDeviceConfigAddressInputsLine1: 'Rad 1',
  insightDetailsDeviceConfigAddressInputsLine2: 'Rad 2',
  insightDetailsDeviceConfigAddressInputsLongitudePlaceholder: 'Ange longitud',
  insightDetailsDeviceConfigAddressInputsLongotudeName: 'Longitud',
  insightDetailsDeviceConfigAddressInputsRegion: 'Område',
  insightDetailsDeviceConfigAddressInputsZip: 'Postnummer',
  insightDetailsDeviceConfigAddressSearchBarPlaceholder: 'Ange position…',
  insightDetailsDeviceConfigAddressTitle: 'Radera adress',
  insightDetailsDeviceConfigHeaderEdit: 'Redigera',
  insightDetailsDeviceConfigHeaderParam: 'Parameter',
  insightDetailsDeviceConfigHeaderValue: 'Värde',
  insightDetailsDeviceConfigRowsName: 'Enhet Namn',
  insightDetailsDeviceConfigRowsTransmissionFreq: 'Överföringsfrekvens',
  insightDetailsDeviceConfigStatusChangesStagesApplied: 'Misslyckades',
  insightDetailsDeviceConfigStatusChangesStagesFailed: 'Failed',
  insightDetailsDeviceConfigStatusChangesStagesRequested: 'Efterfrågad',
  insightDetailsDeviceConfigTitle: 'Radera Konfiguration',
  insightDetailsDeviceConfigurationEditInputsAddressPlaceholder: 'Ange Adress',
  insightDetailsDeviceConfigurationEditInputsAddressTitle: 'Adress',
  insightDetailsDeviceConfigurationEditInputsButtonsCancel: 'Avbryt',
  insightDetailsDeviceConfigurationEditInputsButtonsSave: 'Spara',
  insightDetailsDeviceConfigurationEditInputsNamePlaceholder: 'Ange namn',
  insightDetailsDeviceConfigurationEditInputsNameTitle: 'Namn',
  insightDetailsDeviceConfigurationEditInputsTransmissionFreqPlaceholder: 'Ange överföringsfrekvens',
  insightDetailsDeviceConfigurationEditInputsTypeTitle: 'Typ',
  insightDetailsDeviceId: 'Enhet ID',
  insightDetailsDeviceInfoTitle: 'Enhetsinformation',
  insightDetailsEvent: 'Händelse',
  insightDetailsEventData: 'Datum',
  insightDetailsEventTableHeaderEvent: 'Händelse',
  insightDetailsEventTableHeaderTime: 'Tid',
  insightDetailsEventTableSelectAll: 'Alla',
  insightDetailsEventTableTitle: 'Händelsetabell',
  insightDetailsEventTime: 'Tid',
  insightDetailsGeneralReportsButton: 'Generere en rapport',
  insightDetailsGoToDeviceButton: 'Gå till enheten',
  insightDetailsGoToFlowButton: 'Gå till flödet',
  insightDetailsHistoryTitle: 'Historik',
  insightDetailsNetworkEvent: 'Network Event Table',
  insightDetailsRadioModulesLabel: 'RADIOMODUL',
  insightDetailsRadioModulesTitle: 'Radiomoduler',
  insightDetailsRealTimeCardsTemperature: 'Temperatur',
  insightDetailsRealTimeCardsWater: 'Avstånd till vatten',
  insightDetailsRealTimeTitle: 'Realtid',
  insightDetailsStatusChangesButton: 'Jag förstår',
  insightDetailsStatusChangesStagesCurrent: 'Aktuell:',
  insightDetailsStatusChangesTitlesDeviceName: 'Enhet namn',
  insightDetailsStatusChangesTitlesTransmissionFreq: 'Enhet överföringsfrekvens',
  insightDetalisEventDateAndTime: 'Datum & Tid',
  insightMobilePlaceholder: 'Välj plats..',
  insightPlaceholder: 'Sök efter något',
  insightSearchPlaceholder: 'Vad letar du efter?',
  insightSearchResultsNoResults: 'inga resultat',
  insightSearchResultsResult: 'resultat',
  insightSearchResultsResults: 'resultat',
  insightTitle: 'Överblick i realtid',
  insightToggleList: 'Listvy',
  insightToggleMap: 'Kartvy',
  internalHumidityTitle: 'Inre fuktighet',
  internalTemperature: 'Intern temperatur',
  invoiceDetails: 'Fakturainformation',
  invoiceID: 'Faktura-ID',
  invoices: 'Fakturor',
  labelReportsTableLocation: 'Plats',
  lastEventLabel: 'Senaste händelsen',
  lastSeenOnline: 'Senast online',
  lastSeenTitle: 'Senast sedd',
  levels: 'Nivåer',
  linkAccount: 'länka konto',
  linkPaymentMethod: 'Länka betalningsmetod',
  locationPopupCityPlaceholder: 'Ange Stad',
  locationPopupCountryPlaceholder: 'Ange Land',
  locationPopupDescription: 'välj position på kartan',
  locationPopupLine1Placeholder: 'Ange rad 1',
  locationPopupRegionPlaceholder: 'Ange Redion',
  locationPopupTitleDevices: 'Radera adress',
  locationPopupZipPlaceholder: 'Ange Postnummer',
  maintenanceTitle: 'Underhåll',
  manageAddButton: 'Lägg till',
  manageClientEditAddressSearchBarPlaceholderAddress: 'Ange Adress…',
  manageClientEditAddressTitle: 'Kund adress',
  manageClientsEditClientInputsNameTitle: 'Kund namn',
  manageClientsEditClientTitle: 'Redigera kund',
  manageClientsMultipleDeleteModalButtonsNo: 'Nej, Avbryt',
  manageClientsMultipleDeleteModalButtonsYes: 'Ja, gör det',
  manageClientsMultipleDeleteModalDescription: 'Dessa kunder kommer raderas permanent:',
  manageClientsMultipleDeleteModalTitle: 'Är du säker?',
  manageClientsSingleDeleteModalDescription: 'Denna kund kommer raderas permanent:',
  manageClientsTableHeaderDeleteButton: 'Radera',
  manageClientsTableHeaderDeleteSelected: 'Vald:',
  manageClientsTableHeaderItemsAddress: 'Adress',
  manageClientsTableHeaderItemsClient: 'Organisation',
  manageClientsTableHeaderItemsEmail: 'Email',
  manageClientsTableHeaderItemsName: 'Namn',
  manageClientsTableHeaderItemsPhone: 'Telefonnummer',
  manageClientsTableHeaderItemsPlace: 'Plats',
  manageClientsTableRowPlaceholdersAddress: 'Ange adress',
  manageClientsTableRowPlaceholdersEmail: 'Ange email',
  manageClientsTableRowPlaceholdersPhone: 'Ange telefonnummer',
  manageDeleteSemiTitle: 'Dessa enheter raderas permanent:',
  manageDevicesChooseClientPlaceholder: 'Välj kund',
  manageDevicesEditDevicesTitle: 'Redigera enhet',
  manageDevicesNoCLients: 'Kund existerar ej',
  manageDevicesPlace: 'Ange plats',
  manageIncidentsAddButton: 'Lägg till',
  manageUsersAddUserModalInputsClientPlaceholder: 'Välj',
  manageUsersAddUserModalInputsRoleTitle: 'Roll',
  manageUsersEditUserModalTitle: 'Redigera användare',
  manageUsersMultipleDeleteModalDescription: 'Dessa användare kommer raderas permanent:',
  manageUsersSelectAll: 'Alla kunder',
  manageUsersSingleDeleteModalDescription: 'Denna användare kommer raderas permanent:',
  manholeModalName: 'Brunn',
  manholes: 'manhål',
  manningsPair: 'Mannings par',
  manufacturingDate: 'Tillverkningsdatum',
  meaningLabel: 'Menande',
  measurementDeviceDetailsTableName: 'Namn',
  measurementItem: 'Flödesmätning',
  measurementsFromDevice: 'Mätningar från anordningen',
  measurementTitle: 'Flödesmätning',
  messageAboutSuccessfullyDeleted: 'Framgångsrikt raderad',
  minMeasurmentIntervals: 'Minsta mätintervall',
  mins: 'min',
  minsUnit: 'min',
  minTransmissionIntervals: 'Minsta överföringsintervall',
  mobileEditPopupChooseClient: 'Välj organisation',
  mobileEditPopupChooseRole: 'Välj Roll',
  mobileModalEditAddClientTitle: 'Lägg till ny kund',
  mobileModalEditAddUserTitle: 'Lägg till  användare',
  modalSystemSelectPlaceholder: 'Data är tom',
  modalTitleAdd: 'Lägg till',
  modalTitleView: 'Utsikt',
  monthlyLabel: 'Månadsvis',
  moreDetailsButton: 'Fler detaljer',
  multipleUsersCanBeAdded:
    'Flera användare kan läggas till inom ett företag.\nGenom att lägga till fler användare ger du dem tillgång till plattformen för att spåra enheternas prestanda.',
  nameSurnamePlaceholder: 'Ange namn och efternamn',
  navigationClients: 'Organisationer',
  navigationDevices: 'Enheter',
  navigationDocuments: 'Dokument',
  navigationIncidents: 'Incidenter',
  navigationInsight: 'Inblick',
  navigationLogout: 'Logga Ut',
  navigationManage: 'Hantera',
  navigationNotifications: 'Notifikationer ',
  navigationNotificationsTimeAgo: 'sedan',
  navigationNotificationsTimeHr: 'hr',
  navigationNotificationsTimeMin: 'min',
  navigationReports: 'Rapporter',
  navigationSettings: 'Inställningar',
  navigationUsers: 'Användare',
  nextButton: 'Nästa',
  noButton: 'Nej',
  noDataPlaceholderText: 'Inga data',
  noReportsPlaceholder: 'Inga rapporter än',
  notDeployed: 'Inte utplacerad',
  noTestInformation: 'Ingen QA-information tillgänglig för den här enheten',
  noteText: 'Notera',
  notice: 'Lägga märke till',
  notOkTitle: 'Inte ok',
  oops: 'Oooops!',
  openText: 'Öppen',
  optionsDialogueModalDescription: 'Do you want to proceed?',
  optionsDialogueModalTitle: 'Items will be moved to secret folder.',
  organisationAddress: 'Organisationens adress',
  organisationAddressLabel: 'Organisationens adress',
  organisationCreationSuccess: 'Organisationen har skapats framgångsrikt',
  organisationDetailsTitleInfoTable: 'Organisationsinformation',
  organisationLabel: 'Organisation',
  organisationsName: 'Organisations namn',
  organisationsNamePlaceholder: 'Ange organisationens namn',
  outletPressure: 'Utloppstryck',
  outletTemperature: 'Utloppstemperatur',
  outsidePressure: 'Tryck utifrån',
  outsideTemperature: 'Utetemperatur',
  overdue: 'Gått över tiden',
  overflowBasedOn: 'Överströmning baserad på',
  overflowLabel: 'överflöd',
  pageNotFound: 'Sidan kunde inte hittas.',
  paginationButtonNext: 'Nästa',
  paginationButtonPrevious: 'Tillbaka',
  paginationGo: 'Gå',
  paginationGoTo: 'Gå till',
  paid: 'Betalt',
  pairConfiguration: 'Parkonfiguration',
  pairId: 'Par-ID',
  pairInfo: 'Parinformation',
  pariedManholeButton: 'Gå till par',
  pariedManholegraphLabel: 'Vattenflöde',
  pariedManholeModalName: 'Parade brunnar',
  passed: 'Passerade',
  paymentDetails: 'Betalningsinformation',
  paymentMethodHasBeenSuccessfullySaved: 'Betalningsmetoden har sparats',
  pending: 'I väntan på',
  perDevice: 'per anordning',
  perMonth: 'per månad',
  personnelLabel: 'Personal',
  personnelPlaceholder: 'Ange personal',
  perYear: 'per år',
  phisicalId: 'Fysiskt ID',
  photosTitle: 'Foton',
  pickSubscriptionPlan: 'Välj en prenumerationsplan',
  pickSubscriptionPlanSub1:
    'Genom att välja en plan prenumererar du på en uppsättning funktioner på Smart Utilities-plattformen.',
  pickSubscriptionPlanSub2: 'Du måste välja en plan för varje enhet som du tillhandahåller.',
  pickUpEventText: 'Hämtning',
  placeholderNoData: 'Inga data',
  plans: 'Planer',
  plansetWasAppliedToAllDevices: 'Planset was applied to all devices of this type',
  plansetWasAppliedToDevices: 'Planset was applied to the device',
  pleaseFillOutBillingInformation:
    'Vänligen fyll i faktureringsinformation för att få full åtkomst till plattformen. När du har fyllt i den uppdaterar du sidan.',
  pointIntervalLabel: 'Punktintervall',
  premiumLabel: 'Premium',
  pressToLearnMoreTooltip: 'Tryck för att lära dig mer',
  privacyPolicy: 'Integritetspolicy',
  problemsButtonDetails: 'Detaljer',
  problemsButtonHighTemperature: 'Hög temperatur ',
  problemsDate: 'Datum',
  problemsOf: 'av',
  problemsPlace: 'Plats',
  problemsProblem: 'Problem',
  problemsShowing: 'Visar',
  profileLabel: 'Profil',
  profilePlaceholder: 'Välj profil',
  provisioned: 'Avsatt',
  provisionedOnLabel: 'Tillhandahålls den',
  provisioning: 'Tillhandahålls',
  provisioningAddingDevice: 'Lägger till en ny enhet',
  provisioningAddress: 'Adress',
  provisioningChooseOrganisation: 'Välj en organisation',
  provisioningContent:
    ' och följ vägen. När du har verifierat ditt konto kommer du tillbaka till den här sidan för att logga in och fortsätta med administrationsprocessen.',
  provisioningContent2: ' för att fortsätta med provisioneringen. Du kan bli ombedd att logga in på ditt konto.',
  provisioningContent3:
    'Ange 10-siffrig kod från instruktionen om du är på skrivbordet. Om du skannade en QR-kod går du direkt till nästa steg.',
  provisioningContent4: ' från de tillgängliga alternativen (om du tidigare lagts till på plattformen) eller ',
  provisioningContent5:
    ' (om du precis har registrerat dig). Enheten du tillhandahåller kommer att tilldelas den här organisationen.',
  provisioningCreateOrg: 'skapa en ny organisation',
  provisioningDate: 'Tillhandahållande datum',
  provisioningDeployNow: 'Distribuera nu (du kan alltid distribuera det senare)',
  provisioningDescribe:
    'Genom att tillhandahålla en enhet tilldelar du den till din organisation på Smart Utilities-plattformen. Processen skiljer sig om du redan har ett konto på vår plattform eller inte.',
  provisioningDetails: 'Tillhandahållande detaljer',
  provisioningDeviceInstalmentLocationOptional: 'Plats för enhetens avbetalning (valfritt)',
  provisioningErrorAssign: 'Error the assign',
  provisioningFinish: 'Avsluta',
  provisioningInOrderForDevice:
    'För att enheten ska fungera och utföra mätningar måste den vara utplacerad. Det rekommenderas att installera enheten efter installationen. Obs: distributionen kan ta upp till 24 timmar under bra nätverksförhållanden (mer i dåliga förhållanden)',
  provisioningInstructions: 'Instruktioner',
  provisioningOrganisation: 'Organisation',
  provisioningRow: 'Om du inte har ett konto ännu:',
  provisioningRow2: 'Om du redan har ett konto på plattformen:',
  provisioningSetDepthModalTitle: 'Maximalt djup',
  provisioningSuccessfullyAssign: 'Enheten har implementerats',
  provisioningTooltipOrganisations:
    'Organisationen är en överordnad instans för användare och enheter, den fungerar som deras aggregator. Om du är privatkund kan du presentera dina egna uppgifter.',
  pumpingStationAndTank: 'pumpstation och tank',
  qualityControl: 'Kvalitetskontroll',
  quickstartGuide: 'Snabbstartsguide',
  radioModulesFirmwareRevision: 'Firmware revision',
  radioModulesManufacturer: 'Tillverkare',
  radioModulesTypeModel: 'Typ/Modell',
  rangeText:
    'leta efter en rad enheter genom att skriva in det första och sista serienumret från en produktionsbatch som du vill tilldela. Alla enheter från det här intervallet kommer att kontrolleras automatiskt, du kan avmarkera de du vill utesluta.',
  rangeTitle: 'Räckvidd',
  reload: 'Ladda om',
  reportProblemsModalDescription: 'Ange beskrivning av problemet',
  requestedValue: 'Begärt värdeändring till',
  resolveButton: 'Lösa',
  resolvedButton: 'Löst',
  resolvedSuccessfullyMessage: 'Löst framgångsrikt',
  resolveIncidentTitle: 'Lös incidenten',
  roleAdmin: 'Administratör',
  roleLabel: 'Roll',
  roleUser: 'Användare',
  sameAsBillingInfo: 'Samma som faktureringsinformation',
  sampleButton: 'Prov',
  saveButton: 'Spara',
  scanCuccessful: 'Genomsökningen lyckades',
  searchBarPlaceholder: 'Sök..',
  secondDeviceAddress: 'Adress för enhet #2',
  selectButtonValue: 'Vælg',
  selectDevicePlaceholder: 'Välj enhet',
  selectedDatesLabel: 'Valda datum',
  selectedTitle: 'Vald',
  selectPeriodLastDays: 'Senaste 30 dagar',
  selectPeriodLastMonths: 'Senaste 6 månader',
  selectPeriodLastWeek: 'Senaste veckan',
  selectPeriodLastYear: 'Senaste året',
  selectValuePlaceholder: 'Välj värde',
  setParameters: 'Set parameters',
  severityHigh: 'Hög',
  severityLabel: 'Stränghet',
  severityLow: 'Låg',
  severityMedium: 'Medium',
  sewageLevelTitle: 'Avloppsnivå (%)',
  shortAdditionalDescription: 'gäller endast för \nde första 5 enheterna\n till detta pris per enhet per månad',
  skipButton: 'Hoppa',
  skipButtonAddDeviceModal: 'Hoppa',
  smartHydrant: 'Smart HYDRANT',
  smartRadar: 'Smart RADAR',
  smartRadarAlarm: 'Smart ALARM',
  somethingWentWrong: 'Något gick fel',
  sorryWeCouldNotFindThePage: 'Tyvärr, vi kunde inte hitta sidan!',
  sourceLabel: 'Källa',
  sourceTitle: 'Källa',
  specialInputPlaceholder: 'Allt du vill kan finnas här',
  specialInputTitle: 'Särskild input',
  standingWater: 'Stående vatten',
  standingWaterDetection: 'Detektering av stående vatten',
  startButton: 'Start',
  startDateLabel: 'Start datum',
  startLabel: 'Start',
  startTimeLabel: 'Starttid',
  startTimePlaceholder: 'Ange starttid',
  statusLabel: 'Status',
  statusPlaceholder: 'Ange status',
  subcribeButton: 'Prenumerera',
  submergeInWater: 'Vattennedsänkning',
  submergeInWaterDetection: 'Dykning i vattendetektering',
  submit: 'Skicka in',
  subscribeTooltip: 'Avsluta prenumerationen på e-postmeddelanden',
  subscriptionContent1:
    'Om din organisation har valt en betalningsmetod och en policy på SUP-plattformen kommer de att tillämpas på den här enheten.',
  subscriptionContent2:
    'Om så inte är fallet måste du ange dem när du loggar in på plattformen. Först då får du tillgång till funktionerna.',
  subscriptionPlan: 'Prenumerationsplan',
  success: 'Framgång',
  successfullSavingMessage: 'Sparades framgångsrikt',
  successMessageUploadingPhoto: 'Bilden är uppladdad!',
  successSaveMessage: 'Sparad framgångsrikt',
  summaryAddressInput: 'Företagets adress',
  summaryAddressPlaceholder: 'Ange företagets adress',
  summaryCompanyInput: 'Företagsnamn',
  summaryCompanyPlaceholder: 'Ange företagets namn',
  summaryCompanyRowTitle: 'Företag',
  summaryDevicesRowTitle: 'Enhet(er)',
  summaryEmailInput: 'E-postadress',
  summaryEmailPlaceholder: 'Ange företagets e-postadress',
  summaryPhoneInput: 'Företagets telefon',
  summaryPhonePlaceholder: 'Ange företagets telefon',
  summarySubtitle:
    'Kontrollera om all information är korrekt och avsluta sedan genom att spara det nya företaget med tilldelade användare och enhet(er).',
  summaryTitle: 'Sammanfattning',
  summaryUsersRowTitle: 'Användare',
  symbolLabel: 'Symbol',
  tableInputErrorMessage: 'Kontrollera värdet. Det är inte giltigt',
  tableLableDatePlaceholder: 'Ange datum',
  tamperDetection: 'Sabotagedetektering',
  taxesLabel: 'exkl. tillämpliga skatter',
  temperatureLowCase: 'temperatur',
  temperatureMeasurements: 'Temperaturmätningar',
  temperatureTooltip: 'Temperatur',
  termsOfService: 'Användarvillkor',
  textFieldEmphasizedHeaderTextModalDescription:
    'Info text informing the user about what are the consequences of the action.',
  textFieldEmphasizedHeaderTextModalInputName: 'Enter name of device to confirm action',
  textFieldEmphasizedHeaderTextModalTitle: 'Revoke device rights?',
  theDownloadWillStartShortly: 'Nedladdningen startar inom kort',
  thePhotoWillBeDeleted: 'Fotot kommer att raderas permanent.',
  thisDeviceButton: 'Den här enheten',
  timePlaceholder: 'Ange tid (tt:mm)',
  tooltipAddDevicesManually:
    'Den här enheten är tilldelad till ett annat företag. Om du vill tilldela det till ett nytt måste du först återtilldela det till dig, trycka på uppdateringsknappen och sedan tilldela det nya företaget.',
  toProceedEveryDevice: 'För att fortsätta måste en plansamling väljas för varje enhet',
  total: 'Total',
  totalNumberOfDevices: 'Totalt antal enheter',
  unexpectedErrorHasOccurred: 'Ett oväntat fel har uppstått. Var god försök igen',
  unsubcribeButton: 'Säga upp',
  unsubscribeTooltip:
    'Prenumerera för att få e-postmeddelanden när enheten upptäcker onormala värden (t.ex. Kritisk temperaturtröskel är uppfylld)',
  uploadDevicesInputTextFirstPart: 'Välj FIL',
  uploadDevicesInputTextSecondPart: 'eller dra den hit (.csv eller .xlsx)',
  uploadDevicesInputTextSecondPartMobile: '(.csv eller .xlsx)',
  uploadDevicesModalFailedUploads: 'Misslyckade uppladdningar:',
  uploadDevicesModalSuccessfullyUploadedDevices: 'Uppladdade enheter:',
  uploadDevicesModalTryAgain: 'Försök igen',
  uploadDevicesModalUploadingFile: 'Laddar upp fil...',
  uploadDevicesTitle: 'Ladda upp enheter',
  uploadFileAddDeviceModal: 'Ladda upp fil',
  uploadFileDescAddDeviceModal:
    'importera en CSV / XLSX-fil med alla serienummer på de enheter du vill tilldela detta företag',
  uploadFilesModalDescriptionFirstPart: 'Choose file',
  uploadFilesModalDescriptionSecondPart: 'or drag it here',
  uploadFilesModalListTitle: 'Uploaded files',
  uploadFilesModalTitle: 'Upload firmware',
  uploadFileTooltipAddDeviceModal:
    'Rekommenderas för att lägga till mer än 10 enheter samtidigt från olika produktionssatser.',
  userCompanyLabel: 'Företag',
  userDeleteInfo: 'Användaren kommer att raderas permanent.',
  userEmailLabel: 'E-postadress',
  userEmailPlaceholder: 'Skriv in epostadress',
  userNameLabel: 'Användarnamn',
  usersAddedByLabel: 'Tillagd av',
  usersDateOfRegistrationLabel: 'Registreringsdatum',
  usersFirstLoginLabel: 'Första inloggningen',
  usersName: 'Användarens namn',
  usersParentCustomerLabel: 'Moderorganisation',
  usersParentOrganisationPlacholder: 'Välj överordnad organisation',
  validationFillFieldsRequest: 'Vänligen fyll i alla fält korrekt',
  validationMessage: ' ser inte rätt ut',
  valueWillBeUpdated: 'Värdet kommer att uppdateras nästa gång enheten sänder',
  VATnumber: 'Momsregistreringsnummer',
  vatNumberErrorText: 'Det momsnummer du har angett är ogiltigt i VIES-systemet. Vänligen granska och uppdatera.',
  VATnumberPlaceholder: 'Ange momsregistreringsnummer',
  view: 'Se',
  vWeirLabel: 'V-kärr',
  waterLevelTooltip: 'Vattennivå',
  waterMeasurementsLabel: 'vattenmätningar',
  weAreWorkingOnTheFixing: 'Vi jobbar på att åtgärda problemet. Var god försök igen',
  weCantFindThePage: 'Vi kan inte hitta den sida du letar efter',
  wereHavingAnIssue: 'Vi har ett problem, vänligen försök en av dessa alternativ för att komma tillbaka på rätt spår',
  wereSorry: `Vi är ledsna, men något gick fel när vi försökte ladda den här sidan. Försök igen`,
  wereSorry2: `Vi är ledsna, men det verkar som om vi inte kan hitta den sida du har letat efter`,
  whatAreYouLookingFor: 'Vad letar du efter?',
  wrongFileFormat: 'Fel filformat. Använd .csv eller .xlsx',
  yearlyLabel: 'Årligen',
  yesButton: 'Ja',
};
