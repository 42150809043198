import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { Access, UserSettings, UserState } from './types';
import { UserType } from '../../../enums/UserType';

export const setUser = (state: WritableDraft<UserState>, action: PayloadAction<UserState>) => {
  state.id = action.payload.id;
  state.idpId = action.payload.idpId;
  state.userType = action.payload.userType;
  state.firstName = action.payload.firstName;
  state.lastName = action.payload.lastName;
  state.userSettingsCompleted = action.payload.userSettingsCompleted;
  state.userSettings = action.payload.userSettings;
};
export const setUserType = (state: WritableDraft<UserState>, action: PayloadAction<UserType>) => {
  state.userType = action.payload;
};

export const setAccess = (state: WritableDraft<UserState>, action: PayloadAction<Access>) => {
  state.access = { ...action.payload, isAccess: true };
};

export const updateUserSettings = (state: WritableDraft<UserState>, action: PayloadAction<UserSettings>) => {
  state.userSettings = { ...state.userSettings, ...action.payload };
};

export const setLanguage = (state: WritableDraft<UserState>, action: PayloadAction<string>) => {
  const index = state.languages.findIndex((language: any) => language.id === action.payload);
  state.language = index !== -1 ? state.languages[index] : state.language;
};

export const setUserExportModalState = (state: WritableDraft<UserState>, action: PayloadAction<boolean>) => {
  state.isExportModalOpen = action.payload;
};

export const reducers = {
  setUser,
  setUserType,
  updateUserSettings,
  setLanguage,
  setAccess,
  setUserExportModalState,
};
