import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as ArrowImage } from '../../assets/Arrow.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { NavLink } from '../../utils/react-router-dom-abstraction';
import { To, useNavigate } from '@cthings.co/router-dom';

export enum ArrowButtonType {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
type ButtonType = {
  type: ArrowButtonType;
};

const Button = styled.div<ButtonType>`
  width: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: ${({ type }) => (type === ArrowButtonType.LEFT ? '' : 'rotate(180deg)')};
  & > svg > path {
    stroke: ${colorFetch('primary')};
    transition: stroke 0.3s ease;
  }
  &:hover > svg > path {
    stroke: ${colorFetch('secondary3')};
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
`;

export interface ArrowButtonProps {
  type: ArrowButtonType;
  onClick: any;
  to?: string;
  className?: string;
}

export const ArrowButton: FC<ArrowButtonProps> = ({ type, to, onClick, ...props }) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e.button === 0) {
      e.preventDefault();
      if (to) {
        navigate(to);
      } else {
        onClick();
      }
    }
  };

  return (
    <StyledLink access={[]} to={to as To} onClick={handleClick}>
      <Button type={type} {...props}>
        <ArrowImage />
      </Button>
    </StyledLink>
  );
};

ArrowButton.defaultProps = {
  type: ArrowButtonType.LEFT,
};
