/**
 * @description
 * Button for subscribe and unnsubscribe on current device.
 * With tooltip when user hover mouse on button.
 *
 */

import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../../app/state/user';
import Tooltip, { TooltipPlace, useTooltip } from '../../../../../../../../../components/tooltip';
import { media } from '@cthings.co/styles-utils';
import { useWindowSize } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  margin?: string;
};

const Wrapper = styled.div<WrapperProps>`
  margin: ${({ margin }) => margin};
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 350px !important;
  width: max-content !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px;
  padding: 10px 21px;
`;

// @TODO fix media types
export const StyledButton = styled(CommonButton)`
  height: 32px;
  width: max-content;
  padding: 0 34px;
  & > div > div {
    margin: 0 4px 0 0;
  }
  & > svg {
    width: 16px;
    margin-right: 4px;
    & > g > path {
      transition: 0.3s ease;
    }
  }
  &:hover > svg > g > path {
    stroke: ${colorFetch('white')};
  }

  ${media.desktop} {
    width: 70px;
    padding: 0;
    & > svg {
      margin: 0;
    }
    & > div > div {
      margin-right: 0;
    }
    &:hover > svg > g > path {
      stroke: ${({ color }: any) => color};
    }
  }

  ${media.phone} {
    height: 40px;
    width: 100%;
    & > svg {
      margin-right: 4px;
    }
    & > div > div {
      margin-right: 4px;
    }
  }
` as any; // @TODO fix type

export const SubscribeButton = ({
  children,
  isSubscribed,
  isLoading,
  margin,
  onClick,
}: {
  children: ReactNode;
  isSubscribed: boolean | undefined;
  isLoading: boolean;
  margin?: string;
  onClick: (deviceId: string, isSubscribed: boolean) => void;
}) => {
  const theme = useTheme();
  const { pureWhite, primary, secondary2 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipIds] = useTooltip(1);
  const screenWidth = useWindowSize()[0];

  const tooltipText = isSubscribed ? languageStrings.subscribeTooltip : languageStrings.unsubscribeTooltip;

  const onHover = () => {
    setIsHovered(true);
  };
  const onBlur = () => {
    setIsHovered(false);
  };

  return (
    <Wrapper margin={margin}>
      <StyledTooltip
        id={isHovered ? tooltipIds[0] : ''}
        place={TooltipPlace.BOTTOM}
        text={tooltipText}
        disabled={isLoading || screenWidth < 1024}
      >
        <StyledButton
          colors={{
            main: isSubscribed ? primary : pureWhite,
            background: isSubscribed ? pureWhite : primary,
            backgroundHover: isSubscribed ? primary : secondary2,
          }}
          isLoadingInProcess={isLoading}
          disabled={isLoading}
          onClick={onClick}
          onMouseEnter={onHover}
          onMouseLeave={onBlur}
        >
          {children}
        </StyledButton>
      </StyledTooltip>
    </Wrapper>
  );
};
