import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectComments,
  selectRealTimeData,
  setCommentsData,
  setDeviceInfo,
  setRealTimeData,
} from '../app/state/insight';
import { LsValueType } from './../enums/LsValueType';

import { selectLanguage } from '../app/state/user';
import { store } from '../app/store';
import { API_URL } from '../consts';
import { generateApi, useApi, useApiMutation } from '../features/apiBuilder/useApiBuilder';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { ErrorEventType } from '../types/error';
import { ApiCallType, HttpService } from '../utils/http';
import ss from '../utils/ss';

// export const useRealTimeInsights = (id: string, keywords: any) => {
//   const language = useSelector(selectLanguage);

//   const dispatch = useDispatch();

//   const LsConfig = ss.get(LsValueType.realTimeConfig) || [];

//   const url = `${API_URL}/v2/devices/${id}/insight`;

//   const [data] = useApi({
//     url,
//     defaultValue: {},
//     keywords: keywords,
//     triggers: [language, dispatch, id],
//     transformResponse: (response) => ({
//       ...response.payload,
//       config: response.payload.data.config.map((el: any) => {
//         const lsIndex = LsConfig.findIndex((lsEl: any) => lsEl.key === el.key);
//         if (lsIndex !== -1) {
//           return { ...el, current: el.val, val: LsConfig[lsIndex].val };
//         } else {
//           return { ...el, current: el.val };
//         }
//       }),
//     }),
//     handleSuccess: (response) => dispatch(setRealTimeData(response)),
//   });
//   return { data };
// };

export const useRealTimeInsights = (id: string, setPlaceholder?: any, stopPlaceholder?: any) => {
  const realTimeData = useSelector(selectRealTimeData);
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const [data, setData] = useState(realTimeData);

  const LsConfig = ss.get(LsValueType.realTimeConfig) || [];

  const trigger = [language, dispatch, id] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);

  useEffect(() => {
    const url = `${API_URL}/v2/devices/${id}/insight`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
        responseData.data.config = responseData.data.config.map((el: any) => {
          const lsIndex = LsConfig.findIndex((lsEl: any) => lsEl.key === el.key);
          if (lsIndex !== -1) {
            return { ...el, current: el.val, val: LsConfig[lsIndex].val };
          } else {
            return { ...el, current: el.val };
          }
        });
        dispatch(setRealTimeData(responseData));
        setData(responseData);
      } catch (e) {
        //
      }
    })();
  }, [...trigger]);

  useEffect(() => {
    setData(realTimeData);
  }, [realTimeData]);
  return data;
};

export const useDeviceComments = (id: string) => {
  const comments = useSelector(selectComments);
  const dispatch = useDispatch();
  const [data, setData] = useState(comments);
  useEffect(() => {
    const url = `${API_URL}/v2/devices/${id}/comment`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        setData(responseData);
        dispatch(setCommentsData(responseData));
      } catch (e) {
        //
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    setData(comments);
  }, [comments]);

  return data;
};

export const setDeviceComment = (
  id: string,
  comment: string,
  handleAddNotification: Function,
  languageStrings?: any,
) => {
  const url = `${API_URL}/v2/devices/${id}/comment`;
  (async () => {
    try {
      let responseData = (await HttpService.post(url, { text: comment })).data;
      store.dispatch(setCommentsData(responseData));
      handleAddNotification({
        message: languageStrings.successfullSavingMessage,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
    }
  })();
};

export const editDeviceComment = (
  id: string,
  comment: string,
  id_comment: number,
  handleAddNotification: Function,
  languageStrings?: any,
) => {
  const url = `${API_URL}/v2/devices/${id}/comment/${id_comment}`;
  (async () => {
    try {
      let responseData = (await HttpService.patch(url, { text: comment })).data;
      store.dispatch(setCommentsData(responseData));
      handleAddNotification({
        message: languageStrings.successfullSavingMessage,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
    }
  })();
};

export const deleteDeviceComment = (
  id: string,
  id_comment: number,
  handleAddNotification: Function,
  languageStrings?: any,
) => {
  const url = `${API_URL}/v2/devices/${id}/comment/${id_comment}`;
  (async () => {
    try {
      let responseData = (await HttpService.delete(url, {})).data;
      store.dispatch(setCommentsData(responseData));
      handleAddNotification({
        message: languageStrings.messageAboutSuccessfullyDeleted,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
    }
  })();
};

export const updateDeviceRow = (
  id: string,
  val: string | any[],
  addNotification: any,
  languageStrings?: any,
  handleRevert?: () => void,
  setValueByAnswer?: (value: any) => void,
) => {
  const url = `${API_URL}/v2/devices/${id}/config_params/device_name`;

  (async () => {
    try {
      let responseData = (await HttpService.patch(url, { value: val })).data.payload;
      setValueByAnswer && setValueByAnswer(responseData[0]);
      addNotification({
        message: languageStrings.successfullSavingMessage,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      const message = error?.response?.data?.detail;
      addNotification({
        message: message || 'Something went wrong',
        type: 'error',
      });
      handleRevert && handleRevert();
    }
  })();
};

export const updateDynamicParams = (
  id: string,
  val: any[],
  addNotification: any,
  handleRevertData: () => void,
  languageStrings?: any,
) => {
  const url = `${API_URL}/v2/devices/${id}/params`;
  (async () => {
    try {
      await HttpService.patch(url, { parameters: val });
      addNotification({
        message: languageStrings.successfullSavingMessage,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      const message = error?.response?.data?.detail;
      addNotification({
        message: message || 'Something went wrong',
        type: 'error',
      });
      handleRevertData();
    }
  })();
};

export const updateDevice = (id: string, group_id: string, name: string, ip: string, location: any) => {
  const data = { group_id, name, ip, location };
  const url = `${API_URL}/v1/devices/${id}`;
  (async () => {
    try {
      await HttpService.patch(url, data);
    } catch (e) {
      //
    }
  })();
};

export const useGetDefaultDepth = ({ keywords, id }: { keywords: string[]; id: string }) => {
  const url = `${API_URL}/v2/devices/${id}/depth`;

  const [data] = useApi({
    keywords,
    defaultValue: { depth: 6.5, limit: { min: 0, max: 20 } },
    url,
    transformResponse: (data) => data.payload,
  });

  return [data];
};

export const deployDeviceWithDepth = ({
  id,
  body,
  handleSuccess,
  handleError,
}: {
  id: string;
  body: { depth: number };
  handleSuccess: any;
  handleError: any;
}) => {
  const url = `${API_URL}/v2/devices/${id}/deploy`;

  return generateApi({ url, callType: ApiCallType.POST })({ handleSuccess, handleError, body });
};

export const deployDevice = (
  deviceId: string,
  languageStrings: any,
  addNotification: Function,
  setDeploymentStatus: (status: number) => void,
  setIsLoading: (value: boolean) => void,
) => {
  const url = `${API_URL}/v2/devices/${deviceId}/deploy`;
  (async () => {
    try {
      let responseData = await (await HttpService.post(url, {})).data.payload;
      setDeploymentStatus(responseData.deployment_status);
      addNotification({
        message: languageStrings.deviceDeploymentIsInProgress,
        type: 'success',
      });
    } catch (e) {
      addNotification({
        message: languageStrings.unexpectedErrorHasOccurred,
        type: 'error',
      });
    }
    setIsLoading(false);
  })();
};

export const uploadPhoto = (
  id: string,
  data: string,
  addNotification: any,
  setPhotoList: React.Dispatch<any>,
  setIsLoadingInProcess: React.Dispatch<React.SetStateAction<boolean>>,
  languageStrings?: any,
) => {
  const url = `${API_URL}/v2/devices/${id}/images/insight`;
  const bodyFormData = new FormData();
  bodyFormData.append('files', data);
  (async () => {
    try {
      let responseData = (
        await HttpService.post(url, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      ).data;
      const message = 'The photo is uploaded!';
      addNotification({
        message: languageStrings.successMessageUploadingPhoto || message,
        type: 'success',
      });
      setPhotoList(responseData.payload.images);
    } catch (e) {
      const error = e as ErrorEventType;
      const message = error?.response?.data?.detail;
      addNotification({
        message: message || 'Something went wrong',
        type: 'error',
      });
    }
    setIsLoadingInProcess(false);
  })();
};

export const deletePhoto = (id: string, image_id: string, addNotification: any, languageStrings?: any) => {
  const url = `${API_URL}/v2/devices/${id}/file/${image_id}`;
  (async () => {
    try {
      await HttpService.delete(url, {});
      addNotification({
        message: languageStrings.messageAboutSuccessfullyDeleted,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      addNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
    }
  })();
};

export const useDeviceAlertsData = (deviceId: string, keywords: string[] = []) => {
  const url = `${API_URL}/v2/devices/${deviceId}/insight/alerts`;

  const [data] = useApi({
    keywords,
    url,
    transformResponse: (data) => data.payload,
  });

  return [data];
};

export const useRequestGpsCheck = (
  deviceId: string,
  addNotification: any,
  languageStrings: any,
  closeModal: () => void,
  keywords: string[] = [],
) => {
  const url = `${API_URL}/v2/devices/${deviceId}/gps_check`;

  const dispatch = useDispatch();

  const [mutate] = useApiMutation({
    keywords,
    url,
    callType: ApiCallType.POST,
    transformResponse: (data) => data.payload,
    handleSuccess: (data: any) => {
      dispatch(setDeviceInfo({ data: data.info }));
      addNotification({
        message: languageStrings.gpsRequestSuccess,
        type: 'success',
      });
      closeModal();
    },
    handleError: (e) => addNotification({ message: `${(e as ErrorEventType)?.response?.data?.detail}`, type: 'error' }),
  });

  return [mutate];
};

// export const useSaveMainLocationGps = (
//   deviceId: string,
//   addNotification: any,
//   languageStrings: any,
//   closeModal: () => void,
//   keywords: string[] = [],
// ) => {
//   const url = `${API_URL}/v2/devices/${deviceId}`;

//   const [mutate] = useApiMutation({
//     keywords,
//     url,
//     callType: ApiCallType.PATCH,
//     transformResponse: (data) => data.payload,
//     handleSuccess: () => {
//       addNotification({
//         message: languageStrings.successfullSavingMessage,
//         type: 'success',
//       });
//       closeModal();
//     },
//     handleError: (e) => addNotification({ message: `${(e as ErrorEventType)?.response?.data?.detail}`, type: 'error' }),
//   });

//   return [mutate];
// };

export const startCalibration = ({
  id,
  handleSuccess,
  handleError,
}: {
  id: string;
  handleSuccess: () => void;
  handleError: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/devices/${id}/calibrate`;
  (async () => {
    try {
      let responseData = await HttpService.post(url, {});
      const data = responseData.data.payload;
      store.dispatch(setRealTimeData(data));
      handleSuccess();
    } catch (e) {
      handleError(e);
    }
  })();
};
