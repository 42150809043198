import React, { SyntheticEvent } from 'react';
import { useTheme } from '@cthings.co/styled-components';
import { Input, StyledCheckbox, Wrapper } from './styles';

const getClassNames = (disabled: boolean, checked: boolean) => {
  const rawClassNames = [disabled ? 'disabled' : '', checked ? 'checked' : ''] as const;
  const classNames = rawClassNames.reduce(
    (className, currentClassName) => (currentClassName ? [...className, currentClassName] : [...className]),
    [] as string[],
  );
  return classNames.join(' ');
};

export interface CheckBoxProps {
  disabled?: boolean;
  value: boolean;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ disabled, value, onChange }) => {
  const theme = useTheme();
  const className = getClassNames(!!disabled, value);

  // @TODO fix value and onChange types

  return (
    <Wrapper>
      <Input disabled={disabled} className={className} type="checkbox" theme={theme} value={value as any} />
      <StyledCheckbox theme={theme} className={className} onClick={onChange as any} />
    </Wrapper>
  );
};
