import React from 'react';
import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { selectLanguageStrings } from '../../../../../../../../../../../app/state/user';
import { useSelector } from 'react-redux';
import { SubscriptionCardTypes } from '../../../../../../../../../../../components/subscriptionCard';
import {
  advanced,
  basic,
  basicMeasurements,
  premium,
  premiumSmartCap,
} from '../../../../../../../../../../../components/subscriptionCard/data';
import { ReactComponent as LightGreenIcon } from '../../../../../../../../../../../assets/LightGreenTick.svg';
import { ReactComponent as RedIcon } from '../../../../../../../../../../../assets/RedTick.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { DeviceType } from '../../../../../../../../devices/Devices';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
` as any; // @TODO fix type

const StyledModalContainer = styled(ModalContainer)`
  width: 385px !important;
  height: max-content !important;
  padding: 25px 24px !important;
  display: flex;
  align-items: flex-start !important;

  ${media.phone} {
    width: 100% !important;
    position: fixed !important;
    padding: 24px 16px 18px !important;
    bottom: 0 !important;
    border-radius: 16px 16px 0 0 !important;
  } ;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  &.price {
    margin-bottom: 18px;
  }
`;

type RowProps = {
  isIncluded?: boolean;
};

const Row = styled.div<RowProps>`
  display: flex;

  &.title {
    justify-content: space-between;
  }
  &.sub-title {
    margin-bottom: 10px;
  }
  &.price {
    align-items: center;
    justify-content: ${({ isIncluded }) => (isIncluded ? 'center' : 'space-between')};
  }
  &.content {
    margin-bottom: 8px;
    &:first-child {
      margin-top: 18px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Icon = styled.div`
  margin-right: 8px;
`;

type StyledTextType = {
  isGreenIcon: boolean;
};

const StyledText = styled(Text)<StyledTextType>`
  color: ${({ isGreenIcon, theme }) =>
    !isGreenIcon ? colorFetch('gray1_5')({ theme }) : colorFetch('gray1')({ theme })};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  &.price {
    color: black;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin-right: 10px;
  }
  &.price-subtitle {
    color: ${colorFetch('gray2')};
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  &.content-subtitle {
    font-weight: 500;
  }
` as any;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
`;

type ItemListType = {
  isGreenIcon: boolean;
};

const ItemList = styled.li<ItemListType>`
  color: ${({ isGreenIcon, theme }) =>
    !isGreenIcon ? colorFetch('gray1_5')({ theme }) : colorFetch('gray1')({ theme })};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:before {
    content: '• ';
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const StyledButton = styled(CommonButton)`
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
  }
`;

export const SubscriptionModal = ({
  deviceType,
  data,
  closeModal,
  isOpenModal,
}: {
  deviceType: DeviceType;
  data: any;
  closeModal: () => void;
  isOpenModal?: boolean;
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const localData = (type: SubscriptionCardTypes) => {
    switch (type) {
      case SubscriptionCardTypes.PLAN_SMART_ALARM_BASIC:
        return basic(languageStrings);
      case SubscriptionCardTypes.PLAN_SMART_RADAR_BASIC:
        return basicMeasurements(languageStrings);
      case SubscriptionCardTypes.PLAN_SMART_RADAR_ADVANCED:
        return advanced(languageStrings);
      case deviceType === DeviceType.SMART_CAP && SubscriptionCardTypes.PLAN_SMART_RADAR_PREMIUM:
        return premiumSmartCap(languageStrings);
      case SubscriptionCardTypes.PLAN_SMART_RADAR_PREMIUM || SubscriptionCardTypes.PLAN_SMART_RADAR_INCLUDED:
        return premium(languageStrings);
    }
  };

  const selectedData = localData(data?.frontend_plan_id);

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Text type={TextType.SEMITITLE} color={black1} fontSize={'18px'} weight={'700'} margin={'0 0 6px 0'}>
          {data?.name}
        </Text>
        <Column>
          {(selectedData?.content || []).map((item: any) => {
            const { title, subtitle, list, icon } = item;
            const isGreenIcon = icon === 'green';

            return (
              <Row className="content">
                <Icon>{isGreenIcon ? <LightGreenIcon /> : <RedIcon />}</Icon>
                <Column>
                  <StyledText isGreenIcon={isGreenIcon}>{title}</StyledText>
                  {subtitle && (
                    <StyledText isGreenIcon={isGreenIcon} className="content-subtitle">
                      {subtitle}
                    </StyledText>
                  )}
                  {list && (
                    <StyledList>
                      {list.map((item: any) => {
                        return <ItemList isGreenIcon={isGreenIcon}>{item}</ItemList>;
                      })}
                    </StyledList>
                  )}
                </Column>
              </Row>
            );
          })}
        </Column>
        <ButtonWrapper>
          <StyledButton width={'116px'} height={'32px'} onClick={closeModal}>
            {languageStrings.closeButtonValue}
          </StyledButton>
        </ButtonWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
