import React, { useEffect, useState } from 'react';
import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { FormModal } from './formModal/FormModal';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../../../app/state/user';
import { LanguageId } from '../../../../../../languages/types';
import { useTheme } from '@cthings.co/styled-components';

enum LanguageIds {
  ENGLISH = 'en_gb',
  DEUTSCH = 'de_de',
  DANSK = 'da_dk',
  SVENSKA = 'sv_se',
  POLSKI = 'pl_pl',
}

export const setLanguage = (languageName: LanguageId) => {
  switch (languageName) {
    case LanguageIds.ENGLISH:
      return 'en';
    case LanguageIds.DEUTSCH:
      return 'de';
    case LanguageIds.DANSK:
      return 'da';
    case LanguageIds.SVENSKA:
      return 'sv';
    case LanguageIds.POLSKI:
      return 'pl';
    default:
      return 'en';
  }
};

interface PaymentKeysType {
  client_secret: string;
  publishable_key: string;
}

interface PaymentMethodModalProps {
  isOpen: boolean;
  paymentKeys: PaymentKeysType;
  closeModal: () => void;
  setPaymentMethodStatus: (value: boolean) => void;
}

export const PaymentMethodModal: React.FC<PaymentMethodModalProps> = ({
  isOpen,
  paymentKeys,
  closeModal,
  setPaymentMethodStatus,
}) => {
  const language = useSelector(selectLanguage);
  const theme = useTheme();
  const { gray1, gray2, primary, red2 } = theme.colors;

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  useEffect(() => {
    const stripePromise = loadStripe(paymentKeys.publishable_key);
    setStripePromise(stripePromise);
  }, []);

  const options = {
    clientSecret: paymentKeys.client_secret,
    locale: setLanguage(language.id) as StripeElementLocale,
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap',
      },
    ],
    appearance: {
      variables: {
        fontFamily: 'Poppins',
        fontSizeBase: '14px',
        fontSizeSm: '12px',
        fontSize3Xs: '12px',
        spacingGridColumn: '30px',
        colorPrimary: primary,
        colorText: gray1,
        colorDanger: red2,
        colorTextSecondary: gray2,
        colorTextPlaceholder: gray2,
        borderRadius: '6px',
      },
      rules: {
        '.Input': {
          transition: 'all 0.3s ease',
        },
        '.Label': {
          marginLeft: '8px',
        },
      },
    },
  };

  return stripePromise ? (
    <Elements stripe={stripePromise} options={options}>
      <FormModal isOpen={isOpen} closeModal={closeModal} setPaymentMethodStatus={setPaymentMethodStatus} />
    </Elements>
  ) : null;
};
