import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { InputWrapper } from '../inputWrapper/InputWrapper';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type ButtonWrapperprops = {
  width?: string;
  height?: string;
  title?: string;
};

const ButtonWrapper = styled.div<ButtonWrapperprops>`
  width: calc(100% - 18px);
  max-width: ${({ width }) => width || '240px'};
  height: ${({ height, title }) => (height ? height : title ? '40px' : '32px')};
  display: flex;
  cursor: pointer;
  & > div:last-child {
    border-radius: 0 8px 8px 0;
    border-left: none;
  }
  & > div:first-child {
    border-radius: 8px 0 0 8px;
    border-right: none;
  }
  &:focus {
    outline: none;
  }
  ${media.phone} {
    width: 100%;
    max-width: 100%;
  }
`;

type OptionType = {
  disabled: boolean;
};

const Option = styled.div<OptionType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorFetch('gray6')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  transition: all 0.3s linear;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  ${media.phone} {
    font-size: 12px;
  }
  &.selected {
    background-color: ${colorFetch('primary')};
    border-color: ${colorFetch('primary')};
  }
`;

const StyledText = styled(Text)`
  word-break: break-all;
  color: ${colorFetch('gray1')};

  &.selected {
    color: ${colorFetch('white')};
  }
`;

export interface BooleanSwitcherOptions {
  width?: string;
  height?: string;
  title?: string;
}

export const BooleanSwitcher = ({
  values,
  value,
  onChange,
  options,
  isEditInProcess,
  ...props
}: {
  values: any[];
  value: any;
  onChange: Function;
  options: BooleanSwitcherOptions;
  isEditInProcess?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const optionLength = `${100 / values.length}%`;
  const handleClick = (val: string | boolean) => {
    onChange(val);
  };

  const { width, height, title } = options;

  return (
    <InputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      {isEditInProcess ? (
        <ButtonWrapper width={width} height={height} title={title}>
          {values.map((option: any, index: number) => (
            <Option
              key={index}
              className={option.value === value ? 'selected' : ''}
              onClick={() => handleClick(option.value)}
              style={{ width: optionLength }}
              disabled={option.disabled}
            >
              <StyledText
                className={option.value === value ? 'selected' : ''}
                type={TextType.TEXT_14_GRAY}
                weight={'500'}
                lineHeight={'24px'}
              >
                {option.name}
              </StyledText>
            </Option>
          ))}
        </ButtonWrapper>
      ) : (
        <>
          {values.map((option: any) => (
            <StyledText color={gray1} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
              {option.value === value ? option.name : ''}
            </StyledText>
          ))}
        </>
      )}
    </InputWrapper>
  );
};
