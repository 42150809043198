import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { EventTable } from '../tableControl/EventTable';

const Wrapper = styled.div``;

const TempTableTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const EventTableBlock = ({
  languageStrings,
  pathParams,
  isInDevices,
}: {
  languageStrings: any;
  pathParams: any;
  isInDevices?: boolean;
}) => {
  return (
    <Wrapper>
      <TempTableTitle>
        <Text type={TextType.SEMITITLE}>{`${languageStrings.insightDetailsEventTableTitle}`}</Text>
      </TempTableTitle>
      <EventTable pathParams={pathParams} isInDevices={isInDevices} />
    </Wrapper>
  );
};
