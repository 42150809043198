import { Text } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguage, selectLanguageStrings } from '../../../../../../../../app/state/user';
import { CopyButton } from '../../../../../../../../components/copyButton/CopyButton';
import { useNotifierFunctions } from '../../../../../../../../features/notifier2';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: auto;
  box-sizing: border-box;
`;

const Title = styled(Text)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${colorFetch('gray1_5')};
  line-height: 24px;

  ${media.phone} {
    margin-bottom: 10px;
    font-weight: 400;
  }
` as any; // @TODO fix type

const CopyBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
`;

const DeviceId = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('blue2')};
  color: ${colorFetch('blue2')};
  margin-right: 4px;
  line-height: 16px;
  cursor: pointer;
` as any; // @TODO fix type

const StyledCopyButton = styled(CopyButton)`
  cursor: pointer;
  margin-left: 3px;
  &:hover {
    & > span {
      color: ${colorFetch('gray2')};
    }
    & > svg > path {
      stroke: ${colorFetch('gray3')};
    }
  }
` as any; // @TODO fix type

const Location = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${colorFetch('gray1_5')};
  margin-bottom: 4px;
` as any; // @TODO fix type

const LongLat = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${colorFetch('gray1_5')};
` as any; // @TODO fix type

export interface DeviceLocationItemProps {
  deviceTitle: string;
  deviceName: string;
  longitude: string;
  latitude: string;
  location: string;
  media: any;
  onClick?: () => void;
}

export const DeviceLocationItem: React.FC<DeviceLocationItemProps> = ({
  deviceTitle,
  deviceName,
  longitude,
  latitude,
  location,
  media,
  onClick,
}: DeviceLocationItemProps) => {
  const theme = useTheme();
  const { blue2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage);
  const { addNotification } = useNotifierFunctions();

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({
      message: languageStrings.copiedMessage,
      type: 'info',
    });
  };

  return (
    <Wrapper>
      <Title>
        {deviceTitle}
        <CopyBlock>
          <DeviceId onClick={onClick} blue2={blue2}>
            {deviceName}
          </DeviceId>
          <StyledCopyButton onClick={() => handleCopyText(deviceName)} />
        </CopyBlock>
      </Title>
      {!media.mediaType.desktop && (
        <>
          <Location>{location}</Location>
          <LongLat>{latitude + ', ' + longitude}</LongLat>
        </>
      )}
    </Wrapper>
  );
};
