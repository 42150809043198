import { RootState } from '../../../app/store';

export const selectIsAuthenticated = (state: RootState) => {
  return state.app.isAuthenticated;
};

export const selectInitialFlag = (state: RootState) => {
  return state.app.initialFlag;
};

export const selectNfcFlag = (state: RootState) => {
  return state.app.nfcFlag;
};

export const selectManagmentSearchValue = (state: RootState) => {
  return state.app.managmentSearchValue;
};

export const selectProvisioningData = (state: RootState) => {
  const { secret, transport_id } = state.app;
  return { secret, transport_id };
};
