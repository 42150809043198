import React, { FC, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { TableInput, TableInputType } from '../../../../../../../../components/tableInput/TableInput';
import { CommonButton } from '@cthings.co/buttons';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useParams } from '@cthings.co/router-dom';
import { deployDeviceWithDepth, useGetDefaultDepth } from '../../../../../../../../api/realTimeInsight';
import { useNotifierFunctions } from '../../../../../../../../features/notifier2';
import { ReactComponent as MaxDepthImage } from './assets/max_depth.svg';

const StyledModal = styled(Modal)`
  width: 100%;
  z-index: var(--modal-first-layer);
  & > div {
    width: 100%;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  align-items: flex-start !important;
  width: 100% !important;
  max-width: 566px !important;
  box-sizing: border-box;
  padding: 25px !important;
  ${media.semiTablet} {
    border-radius: 12px 12px 0 0 !important;
    height: 100% !important;
    align-items: flex-end !important;
  }
`;

const Title = styled(Text)`
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: ${colorFetch('black1')} !important;
  margin-bottom: 13px !important;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 104px 1fr;
  grid-column-gap: 20px;
`;

const TextAndInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(Text)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: ${colorFetch('gray1')} !important;
  margin-bottom: 16px !important;
`;

const SetDepthInput = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const Label = styled(Text)`
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.2px !important;
  color: ${colorFetch('gray2')} !important;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`;

const Button = styled(CommonButton)`
  max-width: 116px;
`;

interface SetDepthModalProps {
  isOpen: boolean;
  handleClose: any;
  setIsOpenSetOtherParametersModal: any;
}

export const SetDepthModal: FC<SetDepthModalProps> = ({ isOpen, handleClose, setIsOpenSetOtherParametersModal }) => {
  const params = useParams();

  const id = params.id || '';

  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const [depthValue, setDepthValue] = useState(6.5);

  const [data] = useGetDefaultDepth({ keywords: ['setDepthModalLoader'], id });

  useEffect(() => {
    setDepthValue(data.depth || data.limit.min);
  }, [data]);

  const handleSave = () => {
    const handleSuccess = () => {
      handleClose();
      setIsOpenSetOtherParametersModal(true);

      addNotification({
        message: languageStrings.deviceDeploymentIsInProgress,
        type: 'success',
      });
    };

    const handleError = (e: any) => {
      addNotification({
        message: e?.response?.data?.detail || languageStrings.unexpectedErrorHasOccurred,
        type: 'error',
      });
    };

    deployDeviceWithDepth({ id, body: { depth: depthValue }, handleSuccess, handleError });
  };

  const buttons = [
    {
      value: languageStrings.saveButton,
      onClick: handleSave,
      backgroundColor: colorFetch('primary'),
      borderColor: colorFetch('primary'),
      color: colorFetch('pureWhite'),
    },
    {
      value: languageStrings.closeButtonValue,
      onClick: handleClose,
      backgroundColor: colorFetch('pureWhite'),
      borderColor: colorFetch('primary'),
      color: colorFetch('pureBlack'),
    },
  ];

  return (
    <StyledModal isOpenModal={isOpen}>
      <StyledModalContainer>
        <Title type={TextType.TEXT_14_GRAY}>{languageStrings.deploySetDepthModalTitle}</Title>
        <ContentWrapper>
          <MaxDepthImage />
          <TextAndInput>
            <StyledText type={TextType.TEXT_14_GRAY}>{languageStrings.deploySetDepthModalText}</StyledText>
            <SetDepthInput>
              <Label type={TextType.TEXT_14_GRAY}>{languageStrings.depth}</Label>
              <TableInput
                type={TableInputType.SLIDER_FREE_INPUT_WITH_UNIT}
                value={depthValue}
                handleChangeValue={(value: number) => {
                  setDepthValue(value);
                }}
                options={{ unit: 'm', step: 0.01, min: data.limit.min, max: data.limit.max }}
                isEditInProcess={true}
              />
            </SetDepthInput>
            <div style={{ flex: 1 }}></div>
            <Buttons>
              {buttons.map((item: any, index: number) => {
                const { value, onClick, backgroundColor, borderColor, color } = item;
                return (
                  <Button
                    className={index === 0 ? 'first' : ''}
                    onClick={onClick}
                    colors={{
                      main: color,
                      background: backgroundColor,
                      border: borderColor,
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
            </Buttons>
          </TextAndInput>
        </ContentWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
