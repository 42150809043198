import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/user';
import { Address } from '../../../types/address';
import { FieldsType, isValidField } from '../../../utils/validation';
import { DEFAULT_ADDRESS } from '../data';

const condForNumber = /^-?\d{1,4}(?:[.]\d+)?$/;
const condForZip = /^(\d+[\s-]|[^\s])\d+$/;
const lettersOnly = /^[^\s][^0-9]+$/;
const latRegEx = /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/;
const longRegEx = /^-?(180(\.0+)?|((1[0-7][0-9])|([1-9]?[0-9]))(\.\d+)?)$/;

type InputStructureProps = {
  address: Address;
  isGps?: boolean;
};

export const useAddressStructure = ({ address, isGps }: InputStructureProps) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const [localAddress, setLocalAddress] = useState<Address>(address.city ? address : DEFAULT_ADDRESS);

  const { line1, line2, region, zip, city, country, geotag } = localAddress;

  const [altitude, setAltitude] = useState(geotag.alt);

  const inputs = [
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsLine1,
      fieldName: 'line1',
      value: line1 || '',
      placeholder: languageStrings.locationPopupLine1Placeholder,
      disabled: isGps || false,
      isError: line1 ? !isValidField(line1, FieldsType.TEXT) : true,
    },
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsLine2,
      fieldName: 'line2',
      value: line2 || '',
      disabled: isGps || true,
      isError: !isValidField(line2, FieldsType.TEXT),
    },
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsZip,
      fieldName: 'zip',
      value: zip || '',
      placeholder: languageStrings.locationPopupZipPlaceholder,
      disabled: isGps || false,
      isError: zip ? !isValidField(zip, FieldsType.CUSTOM, condForZip) : false,
    },
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsCity,
      fieldName: 'city',
      value: city || '',
      placeholder: languageStrings.locationPopupCityPlaceholder,
      disabled: isGps || false,
      isError: city ? !isValidField(city, FieldsType.CUSTOM, lettersOnly) : true,
    },
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsRegion,
      fieldName: 'region',
      value: region || '',
      placeholder: languageStrings.locationPopupRegionPlaceholder,
      disabled: isGps || false,
      isError: region ? !isValidField(region, FieldsType.CUSTOM, lettersOnly) : false,
    },
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsCountry,
      fieldName: 'country',
      value: country || '',
      placeholder: languageStrings.locationPopupCountryPlaceholder,
      disabled: isGps || false,
      isError: country ? !isValidField(country, FieldsType.CUSTOM, lettersOnly) : false,
    },
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsLatitudeName,
      fieldName: 'geotag',
      path: ['lat'],
      value: geotag ? geotag.lat : '',
      placeholder: languageStrings.insightDetailsDeviceConfigAddressInputsLatitudePlaceholder,
      disabled: isGps || false,
      isError: geotag.lat ? !isValidField(geotag.lat, FieldsType.CUSTOM, latRegEx) : true,
    },
    {
      name: languageStrings.insightDetailsDeviceConfigAddressInputsLongotudeName,
      fieldName: 'geotag',
      path: ['lng'],
      value: geotag ? geotag.lng : '',
      placeholder: languageStrings.insightDetailsDeviceConfigAddressInputsLongitudePlaceholder,
      disabled: isGps || false,
      isError: geotag.lng ? !isValidField(geotag.lng, FieldsType.CUSTOM, longRegEx) : true,
    },
    {
      name: languageStrings.addressSeaLevelLabel,
      fieldName: 'geotag',
      path: ['alt'],
      value: altitude || '',
      callback: setAltitude,
      placeholder: languageStrings.addressSeaLevelPlaceholder,
      disabled: isGps || false,
      isError: geotag.alt ? !isValidField(geotag.alt, FieldsType.CUSTOM, condForNumber) : false,
    },
  ];

  const areAllFieldsValid = inputs.length - 1 === inputs.filter((item: any) => !item.isError).length;

  return [
    { inputs, altitude, localAddress, areAllFieldsValid },
    {
      setAltitude,
      setLocalAddress: (val: any) => {
        setLocalAddress(val);
      },
    },
  ] as const;
};
