import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { Text } from '@bit/first-scope.text';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const StyledText = styled(Text)`
  display: inline;
  word-wrap: break-word;
  & > span {
    color: ${colorFetch('gray1')};
    width: 100%;
    &.normal {
      font-weight: normal;
    }
  }
`;

type StyledModalProps = {
  isTransformed: boolean;
};

export const StyledModal = styled(Modal)<StyledModalProps>`
  z-index: var(--modal-second-layer);
  & > div {
    justify-content: normal;
    align-items: normal;
    width: ${({ isTransformed }) => (isTransformed ? '100%' : 'auto')};
    justify-content: ${({ isTransformed }) => (isTransformed ? 'center' : 'initial')};
  }
  ${media.tablet} {
    display: none;
  }
`;

type StyledModalContainerProps = {
  isHidden: boolean;
};

export const StyledModalContainer = styled(ModalContainer)<StyledModalContainerProps>`
  height: auto;
  margin-right: 5px;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
`;
