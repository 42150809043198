import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as Search } from '../assets/search_wide.svg';

const StyledSearch = styled(Search)`
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export interface ComponentProps {
  // type: PlaceholderType;
}

export const PlaceholderSearch: FC<ComponentProps> = () => {
  return <StyledSearch width="100%" height="100%" preserveAspectRatio={'none'} />;
};
