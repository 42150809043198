import { CommonButton } from '@cthings.co/buttons';
import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderIconDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > svg {
    display: none;
  }
  ${media.phone} {
    & > svg {
      display: block;
    }
  }
`;

export const StyledText = styled(Text)`
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
`;

export const StyledInfoText = styled(Text)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  margin-top: 13px !important;
  ${media.phone} {
    margin-top: 24px !important;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 33px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  ${media.phone} {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
`;

export const Button = styled(CommonButton)`
  max-width: 116px;
  width: 100%;

  ${media.phone} {
    max-width: 100%;
    height: 40px;
  }
`;
