import React from 'react';
import { ManholesType } from '../ManholeButton';
import { useTheme } from '@cthings.co/styled-components';

export const FlowImage = ({ colorType }: { colorType: ManholesType }) => {
  const theme = useTheme();
  const { primary, secondary1, secondary2, red0, red2, orange2, orange0 } = theme.colors;
  const setColorTheme = (colorType: ManholesType) => {
    const colors: any = {
      [ManholesType.GREEN]: { fill: primary, hoverColor: secondary2, bordersColor: secondary1 },
      [ManholesType.RED]: { fill: red2, hoverColor: red2, bordersColor: red0 },
      [ManholesType.YELLOW]: { fill: orange2, hoverColor: orange2, bordersColor: orange0 },
      [ManholesType.NONE]: { fill: '', hoverColor: '', bordersColor: '' },
    };
    return colors[colorType];
  };

  return (
    <svg width="60" height="60" viewBox="-10 -10 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="-0.00146484"
        y="-0.0380859"
        width="60"
        height="60"
        fill="black"
      >
        <rect fill="white" x="-0.00146484" y="-0.0380859" width="60" height="60" />
      </mask>
      <path
        transform="translate(-7 -2)"
        className={'opacityFirstLayer'}
        fill={setColorTheme(colorType).hoverColor}
        opacity="0.2"
        mask="url(#path-1-outside-1)"
        d="M47.24 37.37C47.24 37.9223 46.7923 38.37 46.24 38.37H9.59998C9.04769 38.37 8.59998 37.9223 8.59998 37.37V10.14C8.59998 9.29 9.48998 8.93 10.07 9.55C11.14 10.71 12.5 12 14.1 12C18.85 12 19.1 8 23.85 8C28.6 8 28.41 11.8 33.35 11.8C38.29 11.8 38.1 8 42.85 8C44.7981 8 45.9226 8.59057 46.8672 9.30591C47.1068 9.48732 47.24 9.77436 47.24 10.0749V37.37Z"
        stroke={setColorTheme(colorType).hoverColor}
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <mask
        id="path-3-outside-2"
        maskUnits="userSpaceOnUse"
        x="-0.00146484"
        y="-0.0380859"
        width="53"
        height="44"
        fill="black"
      >
        <rect fill="white" x="-0.00146484" y="-0.0380859" width="53" height="44" />
      </mask>
      <path
        transform="translate(-7 -3)"
        className={'opacitySecondLayer'}
        fill={setColorTheme(colorType).hoverColor}
        opacity="0.2"
        mask="url(#path-3-outside-2)"
        d="M47.24 37.37C47.24 37.9223 46.7923 38.37 46.24 38.37H9.59998C9.04769 38.37 8.59998 37.9223 8.59998 37.37V10.14C8.59998 9.29 9.48998 8.93 10.07 9.55C11.14 10.71 12.5 12 14.1 12C18.85 12 19.1 8 23.85 8C28.6 8 28.41 11.8 33.35 11.8C38.29 11.8 38.1 8 42.85 8C44.7981 8 45.9226 8.59057 46.8672 9.30591C47.1068 9.48732 47.24 9.77436 47.24 10.0749V37.37Z"
        stroke={setColorTheme(colorType).hoverColor}
        stroke-width="9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0177 5.61726C13.7949 5.2442 14.721 5 15.9374 5C17.1982 5 18.1367 5.24329 18.9106 5.61774C19.672 5.98615 20.2593 6.47564 20.8121 6.93633L20.8238 6.94608C21.3866 7.41507 21.9154 7.85378 22.594 8.18215C23.2656 8.5071 24.0988 8.73006 25.2626 8.73006C26.3775 8.73006 27.1998 8.508 27.8796 8.18168C28.5669 7.85177 29.1204 7.41072 29.7067 6.94166L29.7184 6.9323C30.2942 6.4716 30.9034 5.98426 31.668 5.61726C32.4452 5.2442 33.3713 5 34.5877 5C35.8485 5 36.787 5.24329 37.5609 5.61774C38.3223 5.98615 38.9096 6.47564 39.4624 6.93633L39.4742 6.94608C39.5539 7.01252 39.6 7.11094 39.6 7.21472V18.638C39.6 18.8312 39.4434 18.9877 39.2503 18.9877H1.94967C1.75654 18.9877 1.59998 18.8312 1.59998 18.638V6.21472C1.59998 6.07904 1.67847 5.9556 1.80134 5.89805C1.92422 5.84049 2.0693 5.85922 2.17354 5.94608C2.73633 6.41507 3.26506 7.85378 3.9437 8.18215C4.61525 8.5071 5.44851 8.73006 6.61225 8.73006C7.72714 8.73006 8.54948 8.508 9.22933 8.18168C9.91663 7.85177 10.47 7.41072 11.0564 6.94166L11.0681 6.93229C11.6439 6.47159 12.2531 5.98425 13.0177 5.61726Z"
        fill="#88C99D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0177 14.0098C13.7949 13.6368 14.721 13.3926 15.9374 13.3926C17.1982 13.3926 18.1367 13.6359 18.9106 14.0103C19.672 14.3787 20.2593 14.8682 20.8121 15.3289L20.8238 15.3387C21.3866 15.8077 21.9154 16.2464 22.594 16.5747C23.2656 16.8997 24.0988 17.1226 25.2626 17.1226C26.3775 17.1226 27.1998 16.9006 27.8796 16.5743C28.5669 16.2444 29.1204 15.8033 29.7067 15.3342L29.7184 15.3249C30.2942 14.8642 30.9034 14.3768 31.668 14.0098C32.4452 13.6368 33.3713 13.3926 34.5877 13.3926C35.8485 13.3926 36.787 13.6359 37.5609 14.0103C38.3223 14.3787 38.9096 14.8682 39.4624 15.3289L39.4742 15.3387C39.5539 15.4051 39.6 15.5035 39.6 15.6073V27.0306C39.6 27.2237 39.4434 27.3803 39.2503 27.3803H1.94967C1.75654 27.3803 1.59998 27.2237 1.59998 27.0306V15.6073C1.59998 15.4716 1.67847 15.3482 1.80134 15.2906C1.92422 15.2331 2.0693 15.2518 2.17354 15.3387C2.73633 15.8077 3.26506 16.2464 3.9437 16.5747C4.61525 16.8997 5.44851 17.1226 6.61225 17.1226C7.72714 17.1226 8.54948 16.9006 9.22933 16.5743C9.91663 16.2444 10.47 15.8033 11.0564 15.3342L11.0681 15.3249C11.6439 14.8642 12.2531 14.3768 13.0177 14.0098Z"
        fill="#33894E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0177 22.0528C13.7949 21.6797 14.721 21.4355 15.9374 21.4355C17.1982 21.4355 18.1367 21.6788 18.9106 22.0533C19.672 22.4217 20.2593 22.9112 20.8121 23.3719L20.8238 23.3816C21.3866 23.8506 21.9154 24.2893 22.594 24.6177C23.2656 24.9426 24.0988 25.1656 25.2626 25.1656C26.3775 25.1656 27.1998 24.9436 27.8796 24.6172C28.5669 24.2873 29.1204 23.8463 29.7067 23.3772L29.7184 23.3678C30.2942 22.9071 30.9034 22.4198 31.668 22.0528C32.4452 21.6797 33.3713 21.4355 34.5877 21.4355C35.8485 21.4355 36.787 21.6788 37.5609 22.0533C38.3223 22.4217 38.9096 22.9112 39.4624 23.3719L39.4742 23.3816C39.5539 23.4481 39.6 23.5465 39.6 23.6503V35.0736C39.6 35.2667 39.4434 35.4233 39.2503 35.4233H1.94967C1.75654 35.4233 1.59998 35.2667 1.59998 35.0736V23.6503C1.59998 23.5146 1.67847 23.3911 1.80134 23.3336C1.92422 23.276 2.0693 23.2948 2.17354 23.3816C2.73633 23.8506 3.26506 24.2893 3.9437 24.6177C4.61525 24.9426 5.44851 25.1656 6.61225 25.1656C7.72714 25.1656 8.54948 24.9436 9.22933 24.6172C9.91663 24.2873 10.47 23.8463 11.0564 23.3772L11.0681 23.3678C11.6439 22.9071 12.2531 22.4198 13.0177 22.0528Z"
        fill="#136B2F"
      />
      <path
        d="M40.24 34.37C40.24 34.9223 39.7923 35.37 39.24 35.37H2.59998C2.04769 35.37 1.59998 34.9223 1.59998 34.37V7.14C1.59998 6.29 2.48998 5.93 3.06998 6.55C4.13998 7.71 5.49998 9 7.09998 9C11.85 9 12.1 5 16.85 5C21.6 5 21.41 8.8 26.35 8.8C31.29 8.8 31.1 5 35.85 5C37.7981 5 38.9226 5.59057 39.8672 6.30591C40.1068 6.48732 40.24 6.77436 40.24 7.07485V34.37Z"
        stroke="#023010"
        stroke-width="1.42"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.59998 14.425C2.73998 15.375 4.62998 16.8 7.09998 16.8C11.85 16.8 11.1 13 15.85 13C20.79 13 20.41 16.8 25.35 16.8C30.1 16.8 30.1 13 34.85 13C37.32 13 38.46 13.95 39.6 14.9"
        stroke="#023010"
        stroke-width="1.425"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.59998 22.425C2.73998 23.375 4.62998 24.8 7.09998 24.8C11.85 24.8 11.1 21 15.85 21C20.79 21 20.41 24.8 25.35 24.8C30.1 24.8 30.1 21 34.85 21C37.32 21 38.46 21.95 39.6 22.9"
        stroke="#023010"
        stroke-width="1.425"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
