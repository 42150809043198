import React, { FC } from 'react';
import { useTheme } from '@cthings.co/styled-components';
import styled from '@cthings.co/styled-components';
import { ReactComponent as AddIcon } from '../../assets/Add.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type ButtonType = {
  width: string;
  height: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  colorHover?: string;
  backgroundColorHover?: string;
  borderColorHover?: string;
};

const Button = styled.button<ButtonType>`
  max-width: ${({ width }) => width};
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : colorFetch('pureWhite')({ theme })};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  border: 1px solid;
  border-color: ${({ borderColor, theme }) => (borderColor ? borderColor : colorFetch('primary')({ theme }))};
  transition: all 0.3s linear;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  & > span {
    color: ${({ color, theme }) => (color ? color : colorFetch('primary')({ theme }))};
  }
  & > svg > path {
    stroke: ${({ color, theme }) => (color ? color : colorFetch('primary')({ theme }))};
  }
  &:hover {
    background-color: ${({ backgroundColorHover, theme }) =>
      backgroundColorHover ? backgroundColorHover : colorFetch('primary')({ theme })};

    border-color: ${({ borderColorHover, theme }) =>
      borderColorHover ? borderColorHover : colorFetch('primary')({ theme })};
    & > span {
      color: ${({ colorHover, theme }) => (colorHover ? colorHover : colorFetch('pureWhite')({ theme }))};
    }
    & > svg > path {
      stroke: ${({ colorHover, theme }) => (colorHover ? colorHover : colorFetch('pureWhite')({ theme }))};
    }
  }
`;

const Title = styled.span`
  max-width: calc(100% - 14px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2px;
`;

export interface AddButtonProps {
  onClick: (...props: any[]) => void;
  title: string;
  width: string;
  height: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  colorHover?: string;
  backgroundColorHover?: string;
  borderColorHover?: string;
  className?: string;
}

export const AddButton: FC<AddButtonProps> = ({
  onClick,
  title,
  width,
  height,
  color,
  backgroundColor,
  borderColor,
  colorHover,
  backgroundColorHover,
  borderColorHover,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      width={width}
      height={height}
      theme={theme}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      colorHover={colorHover}
      backgroundColorHover={backgroundColorHover}
      borderColorHover={borderColorHover}
      {...props}
    >
      <AddIcon />
      <Title>{title}</Title>
    </Button>
  );
};
