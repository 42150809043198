import React from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import styled, { css } from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { useSelector } from 'react-redux';
import { CloseWhiteRoundButton } from '../../../../../closeWhiteRoundButton/CloseWhiteRoundButton';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0 0 20px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${media.phone} {
    flex-direction: column-reverse;
  }
`;

type StyledButtonProps = {
  isBlocked: boolean;
};

// @TODO fix media types
const StyledButton = css<StyledButtonProps>`
  font-size: 14px;
  line-height: 16px;
  ${media.tablet} {
    width: 100%;
    height: 40px;
    pointer-events: ${({ isBlocked }: any) => isBlocked && 'none'};
    line-height: 24px;
  }
  ${media.phone} {
    width: 100%;
  }
`;

const CloseButton = styled(CommonButton)`
  ${StyledButton};
  margin-right: 16px;
  ${media.phone} {
    margin: 10px 0 0 0;
  }
` as any; // @TODO fix type

const ActionButton = styled(CommonButton)`
  ${StyledButton};
` as any; // @TODO fix type

const StyledTitle = styled(Text)`
  font-size: 16px;
  ${media.phone} {
    font-size: 18px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  ${media.phone} {
    margin-bottom: 22px;
  }
`;

const StyledCloseWhiteRoundButton = styled(CloseWhiteRoundButton)`
  display: none;
  ${media.phone} {
    display: flex;
    position: relative;
    top: 0;
    right: 0;
  }
`;

export const ActionPopup = ({
  title,
  content,
  handleOnClose,
  handleOnAction,
  actionName,
  ...props
}: {
  title: string;
  content: any;
  handleOnClose: (value: React.SetStateAction<boolean>) => void;
  handleOnAction: (() => void) | undefined;
  actionName?: string;
  className?: string;
}) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();
  const { secondary2, primary, black1, pureWhite } = theme.colors;

  return (
    <Wrapper {...props}>
      <TitleWrapper>
        <StyledTitle weight="bold" color={black1} type={TextType.TEXT_14_BLACK}>
          {title}
        </StyledTitle>
        {/* @TODO fix any */}
        <StyledCloseWhiteRoundButton onClick={handleOnClose as any} />
      </TitleWrapper>
      <Content>
        <Text lineHeight="24px" color={black1} type={TextType.TEXT_14_BLACK} weight="normal">
          {content}
        </Text>
      </Content>
      <ButtonWrapper>
        <CloseButton width="104px" color={black1} backgroundColor={pureWhite} onClick={handleOnClose}>
          {languageStrings.closeButtonValue}
        </CloseButton>
        <ActionButton
          width="104px"
          backgroundColor={primary}
          backgroundColorHover={secondary2}
          borderColor={primary}
          borderColorHover={primary}
          onClick={handleOnAction}
        >
          {actionName || languageStrings.actionButtonValue}
        </ActionButton>
      </ButtonWrapper>
    </Wrapper>
  );
};
