import React, { FC, ReactNode, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0px 0px 14px 0px;
`;

type WrapperProps = {
  width?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => (width ? width : '315px')};
  height: 40px;

  position: relative;

  margin-top: 2px;
`;

type InputElementProps = {
  disabled?: boolean;
  borderColor?: string;
  isFocused: boolean;
  isError: boolean;
  image?: any;
};

const InputElement = styled.input<InputElementProps>`
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;

  background-color: ${({ disabled, theme }) =>
    disabled ? colorFetch('gray5')({ theme }) : colorFetch('pureWhite')({ theme })};

  color: ${colorFetch('black1')};
  ::placeholder {
    color: ${({ disabled, theme }) => (disabled ? colorFetch('gray5')({ theme }) : colorFetch('gray2')({ theme }))};
  }

  font-weight: normal;
  font-size: 14px;

  line-height: 24px;

  border: 1px solid
    ${({ borderColor, disabled, isFocused, isError, theme }) =>
      borderColor
        ? borderColor
        : disabled
        ? colorFetch('gray5')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : isError
        ? colorFetch('red2')({ theme })
        : colorFetch('gray2')({ theme })};

  border-radius: ${({ theme }) => theme.borderRadius.primary};

  padding: ${({ image }) => (image ? '8px 36px 8px 38px' : '8px 36px 8px 12px')};

  outline: none;
`;

type ImageWrapperProps = {
  imageColor?: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  & > svg > path {
    stroke: ${({ imageColor, theme }) => imageColor || colorFetch('primary')({ theme })};
  }
`;

const AdditionalImage = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  cursor: pointer;
`;

export interface InputProps {
  value: string;
  onChange: any;
  name?: string | ReactNode;
  placeholder: string;
  type?: string;
  image?: any;
  additionalImage?: any;
  additionalImageOnClick?: any;
  disabled?: boolean;
  width?: string;
  className?: string;
  borderColor?: string;
  imageColor?: string;
}

export const Input: FC<InputProps> = ({
  value,
  onChange,
  name,
  placeholder,
  image,
  type,
  width,
  additionalImage,
  additionalImageOnClick,
  disabled,
  borderColor,
  imageColor,
  ...props
}) => {
  const theme = useTheme();

  const [isFocused, setIsFocused] = useState(false);
  const { gray1 } = theme.colors;

  return (
    <ExtraWrapper {...props}>
      <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0px 0px 2px 6px">
        {name}
      </Text>
      <Wrapper width={width}>
        {image ? <ImageWrapper imageColor={imageColor}>{image}</ImageWrapper> : null}
        <InputElement
          type={type ? type : 'text'}
          value={value}
          placeholder={placeholder}
          borderColor={borderColor}
          theme={theme}
          isFocused={isFocused}
          isError={false}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onChange={onChange}
          image={image}
          disabled={disabled}
        />
        {additionalImage ? (
          <AdditionalImage color={gray1} theme={theme} onClick={additionalImageOnClick}>
            {additionalImage}
          </AdditionalImage>
        ) : null}
      </Wrapper>
    </ExtraWrapper>
  );
};
