import { DeviceSelectionMode } from '../../../../app/state/organisations';
import { LanguageStrings } from '../../../../languages/types';
import { DeviceSelectionModeValue } from './types';

const exclusionsFromDesktopSwitcher = ['selected'] as DeviceSelectionMode[];
const exclusionsFromMobileSwitcher = [] as DeviceSelectionMode[];

export const useDeviceSelectionModes = (languageStrings: LanguageStrings) => {
  const deviceSelectionModes: DeviceSelectionModeValue[] = [
    { name: languageStrings.individualTitle, id: 'individual' },
    { name: languageStrings.rangeTitle, id: 'range' },
    { name: languageStrings.selectedTitle, id: 'selected' },
  ];

  const desktopSelectionModes = deviceSelectionModes.filter((mode) => !exclusionsFromDesktopSwitcher.includes(mode.id));
  const mobileSelectionModes = deviceSelectionModes.filter((mode) => !exclusionsFromMobileSwitcher.includes(mode.id));

  const getSelectionModeValue = (id: DeviceSelectionMode) =>
    deviceSelectionModes.find((mode) => mode.id === id) as DeviceSelectionModeValue;

  const functions = { getSelectionModeValue };

  return [{ desktopSelectionModes, mobileSelectionModes, deviceSelectionModes }, functions] as const;
};
