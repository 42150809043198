import { CommonButton } from '@cthings.co/buttons';
import { Text } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from '@cthings.co/router-dom';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../app/state/user';
import { PATHS } from '../../../../routes/paths';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useHistory } from '../../../../utils/react-router-dom-abstraction';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: ${colorFetch('gray5')};
  justify-content: center;

  ${media.phone} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.desktop} {
    margin: 0 40px 44px 40px;
  }

  ${media.phone} {
    margin: 0;
  }
`;

const Title = styled(Text)`
  font-family: 'Bronova';
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: ${colorFetch('black')};
` as any; // @TODO fix type

const SubTitle = styled(Text)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
  margin-bottom: 40px;
` as any; // @TODO fix type

type BlockProps = {
  additionalShadow11: string;
};

const Block = styled.div<BlockProps>`
  max-width: 564px;
  padding: 24px;
  margin-top: 40px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${colorFetch('white')};
  box-shadow: ${({ additionalShadow11 }) => additionalShadow11};
  border-radius: 12px;

  ${media.phone} {
    padding: 16px;
    margin: 0;
  }
`;

const BlockTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black')};
  margin-bottom: 12px;
` as any; // @TODO fix type

const BlockSubTitle = styled(Text)`
  margin-top: 12px !important;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  margin-bottom: 24px;

  mark {
    font-weight: 600;
    background: none;
    color: ${colorFetch('gray1')};
  }
` as any; // @TODO fix type

const FinishButton = styled(CommonButton)`
  width: 314px;
  height: 40px;
  margin-top: 40px;

  & > svg,
  & > div {
    margin-right: 5px;
    & > path {
      transition: 0.3s ease;
    }
  }
  ${media.phone} {
    width: 100%;
  }
`;

interface ProvisioningCompletedProps {}

export const ProvisioningCompleted: FC<ProvisioningCompletedProps> = () => {
  const theme = useTheme();
  const { secondary2, primary, pureWhite } = theme.colors;
  const { additionalShadow11 } = theme.shadows;
  const location = useLocation();
  const history = useHistory();
  const { subtitle } = location?.state;
  const languageStrings = useSelector(selectLanguageStrings);

  const redirectToDashboard = () => {
    const to = PATHS.USER_DASHBOARD;
    history.push(to);
  };

  return (
    <Wrapper>
      <Content>
        <Title>SMART UTILITIES</Title>
        <SubTitle>{subtitle}</SubTitle>
        <Block additionalShadow11={additionalShadow11}>
          <BlockTitle>{languageStrings.completedProvisioningLabel}</BlockTitle>
          <BlockSubTitle>
            {languageStrings.completedProvisioningContent}
            <mark>{languageStrings.completedProvisioningContent2}</mark>.{' '}
            {languageStrings.completedProvisioningContent3}
          </BlockSubTitle>
          <FinishButton
            onClick={redirectToDashboard}
            colors={{
              main: pureWhite,
              mainHover: pureWhite,
              background: primary,
              backgroundHover: secondary2,
              border: primary,
              borderHover: secondary2,
            }}
          >
            {languageStrings.completedProvisioningButton}
          </FinishButton>
        </Block>
      </Content>
    </Wrapper>
  );
};
