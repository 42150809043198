import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { LocationImage } from './components/LocationImage';
import { media } from '@cthings.co/styles-utils';
import { FlowInfo } from '../manholeInfo/components/flowInfo/FlowInfo';
import { DeviceType } from '../../enums/DeviceType';
import { WaterLevelImage } from '../manholeInfo/components/WaterLevelImage';
import { DistanceToWaterImage } from '../manholeInfo/components/DistanceToWaterImage';
import { TemperatureImage } from '../manholeInfo/components/TemperatureImage';
import {
  StyledTooltip,
  DeviceData,
  DeviceDataWrapper,
} from '../manholeInfo/components/waterFlowInfoModal/WaterFlowInfoModal';
import { useTooltip, TooltipPlace } from '../tooltip/Tooltip';
import { useSlicedScroll } from '../../utils/useSlicedScroll';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  isOpen: boolean;
  isClicked: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 244px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  background-color: ${colorFetch('pureWhite')};
  visibility: ${({ isOpen, isClicked }) => (isOpen || isClicked ? 'visible' : 'hidden')};
  opacity: ${({ isOpen, isClicked }) => (isOpen || isClicked ? '1' : '0')};
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  transition: all 0.3s ease;
  position: absolute;
  top: 58px;
  left: -22px;
  box-shadow: 0 2px 8px rgba(40, 41, 61, 0.04), 0 16px 24px rgba(96, 97, 112, 0.16);
  &:before {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -8px;
    left: 40px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
    border-radius: 0 0 0 0.25em;
    ${media.phone} {
      left: 108px;
    }
  }
`;

const ScrolledWrapper = styled.div`
  height: 250px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 0.6;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
`;

const WrapperManholes = styled.div`
  padding: 0 20px;
`;

const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 14px;
  padding: 12px 20px 0 20px;
  & > span {
    margin: 0 0 0 22px;
  }
`;

const DevicesSeparator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray4')};
  background-color: ${colorFetch('gray6')};
  padding: 7px 0;
  & > span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }
`;

type TextWrapperProps = {
  margin?: string;
};

const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  margin: ${({ margin }) => margin};
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2px 0 -3px;
  & > svg > path {
    stroke: ${colorFetch('black1')};
  }
`;

type InfoWrapperProps = {
  isFirst: boolean;
};

const InfoWrapper = styled.div<InfoWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray4')};
  padding: 15px 0;
  & > span {
    word-break: break-word;
  }
`;

const DownWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > button {
    font-size: 12px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0;
`;

const StyledDeviceDataWrapper = styled(DeviceDataWrapper)`
  margin: 6px 0 6px 0;
`;

const StyledCommonButton = styled(CommonButton)``;

export interface ManholeModalProps {
  isClicked: boolean;
  isOpen: boolean;
  address?: string;
  devicesInfo?: boolean;
  coordinates?: string;
  manholes: any[];
  flowInfoData: any[];
  history?: any;
  className?: string;
  languageStrings?: any;
}

export const ManholeModal: FC<ManholeModalProps> = ({
  isClicked,
  isOpen,
  address,
  devicesInfo = true,
  coordinates,
  manholes,
  flowInfoData,
  history,
  languageStrings,
  ...props
}) => {
  const theme = useTheme();
  const { black1, gray2, gray1 } = theme.colors;

  const listRef = useRef<any>(null);

  const [tooltipId] = useTooltip(3);

  const [node, setNode] = useState<any>(null);
  const wrapperRef = useCallback((currentNode: any) => {
    if (currentNode !== null) {
      setNode(currentNode);
    }
  }, []);

  const handleClick = useCallback(
    (to: string) => {
      to && history && history.push(to);
    },
    [history],
  );

  useEffect(() => {
    if (node) {
      const pseudoFunc = (e: any) => {
        e.stopPropagation();
      };
      node.addEventListener('wheel', pseudoFunc, { passive: false });
      return () => node.removeEventListener('wheel', pseudoFunc);
    }
  }, [node]);

  const [pages] = useSlicedScroll(listRef);

  return (
    <Wrapper
      theme={theme}
      isOpen={isOpen}
      isClicked={isClicked}
      ref={wrapperRef}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      {...props}
    >
      <WrapperAddress>
        <TextWrapper>
          <ImageWrapper theme={theme}>
            <LocationImage />
          </ImageWrapper>
          <Text type={TextType.TITLE_H4} color={black1}>
            {manholes[0].address}
          </Text>
        </TextWrapper>
        <Text type={TextType.TEXT_12_GRAY} color={gray2}>
          {manholes[0].coordinates}
        </Text>
      </WrapperAddress>
      <ScrolledWrapper ref={listRef}>
        {devicesInfo && (
          <DevicesSeparator>
            <Text type={TextType.TEXT_12_GRAY} color={gray1}>
              {languageStrings.navigationDevices}
            </Text>
          </DevicesSeparator>
        )}
        <WrapperManholes theme={theme}>
          {manholes
            .filter((el: any) => el.multipleDevicesType !== DeviceType.WATER_FLOW)
            .slice(0, 10 * pages)
            .map((item: any, index: number) => {
              const {
                date,
                time,
                to,
                name,
                deployment_status,
                deviceType,
                waterLevel,
                distanceToWater,
                temperature,
              } = item;

              const deviceData = [
                {
                  icon: <WaterLevelImage />,
                  value: waterLevel?.measurement || 'N/A',
                  tooltipText: waterLevel?.tooltipText,
                  margin: '0 6px 0 0',
                },
                {
                  icon: <DistanceToWaterImage />,
                  value: distanceToWater?.measurement || 'N/A',
                  tooltipText: distanceToWater?.tooltipText,
                  margin: '0 6px 0 0',
                },
                {
                  icon: <TemperatureImage />,
                  value: temperature?.measurement || 'N/A',
                  tooltipText: temperature?.tooltipText,
                },
              ];

              return (
                <InfoWrapper theme={theme} key={index} isFirst={index === 0}>
                  <Text type={TextType.TEXT_14_BLACK} color={black1}>
                    {name || 'N/A'}
                  </Text>
                  {deviceType !== DeviceType.HYDRANT && (
                    <StyledDeviceDataWrapper>
                      {deviceData.map((item: any, index: number) => {
                        return (
                          <StyledTooltip
                            key={index}
                            id={tooltipId[index]}
                            text={item.tooltipText}
                            place={TooltipPlace.TOP}
                          >
                            <DeviceData color={gray1} textColor={black1} margin={item.margin ?? '0'}>
                              {item.icon}
                              <Text type={TextType.TEXT_12_BLACK} color={black1}>
                                {item.value}
                              </Text>
                            </DeviceData>
                          </StyledTooltip>
                        );
                      })}
                    </StyledDeviceDataWrapper>
                  )}
                  <DownWrapper>
                    <DateWrapper>
                      {deployment_status === 2 ? (
                        <>
                          <TextWrapper margin="0 6px 0 0">
                            <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                              {date || 'N/A'}
                            </Text>
                          </TextWrapper>
                          <TextWrapper margin="0 0 0 12px">
                            <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                              {time || 'N/A'}
                            </Text>
                          </TextWrapper>
                        </>
                      ) : (
                        <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                          {deployment_status === 1
                            ? languageStrings.deploying
                            : deployment_status === 0
                            ? languageStrings.notDeployed
                            : 'N/A'}
                        </Text>
                      )}
                    </DateWrapper>
                    <StyledCommonButton onClick={() => handleClick(to)} height="25px" width="100%" noFocus>
                      {languageStrings.commonButtonMore}
                    </StyledCommonButton>
                  </DownWrapper>
                </InfoWrapper>
              );
            })}
        </WrapperManholes>
        {flowInfoData && (
          <FlowInfo
            flowData={flowInfoData}
            withFlowSeparator
            history={history}
            withFlow
            languageStrings={languageStrings}
          />
        )}
      </ScrolledWrapper>
    </Wrapper>
  );
};

ManholeModal.defaultProps = {
  isOpen: true,
  address: 'Seedorffs Vænge 16-18',
  coordinates: '55.683773, 12.523383',
  manholes: [
    { name: 'Manhole 1', date: '22.11.2020', time: '17:25' },
    { name: 'Manhole 2', date: '21.11.2020', time: '14:03' },
    { name: 'Manhole 3', date: '21.11.2020', time: '18:52' },
    { name: 'Manhole 4', date: '20.11.2020', time: '17:25' },
  ],
};
