import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: reducers,
});

export const {
  addNotification,
  removeNotification,
  openModal,
  closeModal,
  setScrolled,
  setRedirect,
} = settingsSlice.actions;

export default settingsSlice.reducer;
