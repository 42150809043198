import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { InsightDataType } from '../../../enums/InsightDataType';
import { initialState } from './data';

export const setRealTimeData = (state: any, action: PayloadAction<any>) => {
  state.realTimeData = action.payload;
};
export const resetRealTimeData = (state: any) => {
  state.realTimeData = initialState.realTimeData;
  state.comments = initialState.comments;
  state.history = initialState.history;
  state.devices = initialState.devices;
  state.events = initialState.events;
  state.events_graph = initialState.events_graph;
};
export const updateRealTimeAddress = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.info.address = { ...state.realTimeData.info.address, ...action.payload };
};

export const resetRealTimeAddress = (state: any) => {
  state.realTimeData = initialState.realTimeData;
};

export const setCommentsData = (state: any, action: PayloadAction<any>) => {
  state.comments = action.payload;
};

export const editRealTimeConfig = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.data.config = state.realTimeData.data.config.map((e: any) => {
    if (e.key === action.payload.key) {
      return { ...e, current: e.val, val: action.payload.val };
    } else {
      return e;
    }
  });
};
export const setHistory = (state: any, action: PayloadAction<any>) => {
  if (action.payload.type === InsightDataType.TEMPERATURE) {
    state.history.temperature = action.payload.data;
  } else if (action.payload.type === InsightDataType.TEMPERATURE) {
    state.history.water_level = action.payload.data;
  }
};

export const setDevices = (state: any, action: PayloadAction<any>) => {
  state.devices = action.payload;
};

export const setEvents = (state: any, action: PayloadAction<any>) => {
  state.events = action.payload;
};

export const setEventsGraph = (state: any, action: PayloadAction<any>) => {
  state.events_graph = action.payload.data;
};

export const setDeviceInfo = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.info = action.payload.data;
};

export const setDeviceInfoCustomer = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.info = {
    ...state.realTimeData.info,
    client_name: action.payload.name,
    client_id: action.payload.id,
  };
};

export const setDeviceInfoAddress = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.info.address = action.payload.value;
};

export const setDeviceInfoName = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.info.name = action.payload.name;
  state.realTimeData.data.config[0].current = action.payload.name;
};

export const setDeviceConfigData = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.data.config = action.payload.data;
};

export const setDeviceConfigItem = (state: any, action: PayloadAction<any>) => {
  state.realTimeData.data.config[action.payload.index].current = action.payload.value;
  state.realTimeData.data.config[action.payload.index].val = action.payload.value;
};

export const setDefaultGraphFilters = (state: any, action: PayloadAction<any>) => {
  state.history.water_level.filters = action.payload.filters;
  state.history.temperature.filters = action.payload.filters;
};

export const setDefaultEventsGraphFilters = (state: any, action: PayloadAction<any>) => {
  state.events_graph.filters = action.payload.filters;
};

export const setWaterLevelFilter = (state: any, action: PayloadAction<any>) => {
  state.history.water_level.filters[`${action.payload.filterName}`].default_value = action.payload.value;
};

export const setTemperatureFilter = (state: any, action: PayloadAction<any>) => {
  state.history.temperature.filters[`${action.payload.filterName}`].default_value = action.payload.value;
};

export const setEventsGraphFilter = (state: any, action: PayloadAction<any>) => {
  state.events_graph.filters[`${action.payload.filterName}`].default_value = action.payload.value;
};

export const setIsLoadingGraphsInProcess = (state: any, action: PayloadAction<any>) => {
  state.history[`${action.payload.graphName}`].isLoadingInProcess = action.payload.value;
};

export const setIsLoadingEventsGraphsInProcess = (state: any, action: PayloadAction<any>) => {
  state.events_graph.isLoadingInProcess = action.payload.value;
};

export const setTsFromGraph = (state: any, action: PayloadAction<any>) => {
  state.history[`${action.payload.graphName}`].ts_from = action.payload.value;
};

export const setTsToGraph = (state: any, action: PayloadAction<any>) => {
  state.history[`${action.payload.graphName}`].ts_to = action.payload.value;
};

export const setTsEventsGraph = (state: any, action: PayloadAction<any>) => {
  state.events_graph.ts.ts_from = action.payload.value.ts_from;
  state.events_graph.ts.ts_to = action.payload.value.ts_to;
};

export const revertFilters = (state: any, action: PayloadAction<any>) => {
  state.history[`${action.payload.graphName}`].filters = action.payload.value;
};

export const revertFiltersFromEventsGraph = (state: any, action: PayloadAction<any>) => {
  state.events_graph.filters = action.payload.value;
};

export const reducers = {
  setRealTimeData,
  resetRealTimeData,
  setCommentsData,
  editRealTimeConfig,
  setDevices,
  setEvents,
  setHistory,
  setEventsGraph,
  updateRealTimeAddress,
  resetRealTimeAddress,
  setDeviceInfo,
  setDeviceInfoCustomer,
  setDeviceInfoAddress,
  setDeviceInfoName,
  setDeviceConfigItem,
  setDeviceConfigData,
  setDefaultGraphFilters,
  setDefaultEventsGraphFilters,
  setWaterLevelFilter,
  setTemperatureFilter,
  setEventsGraphFilter,
  setIsLoadingGraphsInProcess,
  setIsLoadingEventsGraphsInProcess,
  setTsFromGraph,
  setTsToGraph,
  setTsEventsGraph,
  revertFilters,
  revertFiltersFromEventsGraph,
};
