import { useTheme } from '@cthings.co/styled-components';
import { RowItemType, UniversalTable, useTableFunctions, withTableContextProvider } from '@cthings.co/universal-table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import {
  updatePaymentDetails,
  useGetInvoiceTypes,
  useGetPaymentsMethods,
} from '../../../../../../api/manage/manageBilling';
import { startDeviceEditTool } from '../../../../../../app/state/organisations';
import { selectAccess, selectLanguage, selectLanguageStrings } from '../../../../../../app/state/user/selectors';
import { EditAction, TableInputType, UniversalTableInput } from '../../../../../../components/universalTableInput';
import { API_URL } from '../../../../../../consts';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { View } from '../../../../../../routes/routeInterfaces';
import { useQueryParams } from '../../../../../../utils/useQueryParams';
import { ExtraWrapper, StyledTitle, TitleWrapper, Wrapper } from './styled';
import { HttpService } from '../../../../../../utils/http';

export enum PaymentMethodType {
  PAYMENT_METHOD = 'payment_method',
  INVOICE_TYPE = 'invoice_type',
}

const PaymentDetailsPlain = () => {
  const theme = useTheme();

  const { resetTable, updateLocalItem, setOpenIndex } = useTableFunctions();
  const dispatch = useDispatch();

  const [rowInEdit, setRowInEdit] = useState('');
  const [savedData, setSavedData] = useState('');

  const languageStrings = useSelector(selectLanguageStrings);
  const { shortName } = useSelector(selectLanguage);

  const keyword = 'PaymentDetailsTable';
  const offsetKey = 'offset';

  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pathParams = useParams();

  const accessData = useSelector(selectAccess).billing.details;

  const [paymentMethodsList] = useGetPaymentsMethods();
  const [invoiceTypesList] = useGetInvoiceTypes();

  const { addNotification } = useNotifierFunctions();

  const handleRevert = (savedData: any, index: number) => updateLocalItem(index, savedData);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  const handleConfirm = (data: any, savedData: any, index?: number) => {
    updatePaymentDetails({
      field_id: data.id,
      body: { value: data?.value?.actual_value?.id },

      handleError: (data: any) => {
        addNotification({
          message: data.response.data.detail ?? languageStrings.somethingWentWrong,
          type: 'error',
        });

        handleRevert(savedData, index ?? -1);

        setSavedData('');
        setRowInEdit('');
        setOpenIndex(-1);
      },

      handleSuccess: (data: any) => {
        addNotification({
          message: languageStrings.successSaveMessage,
          type: 'success',
        });

        setSavedData('');
        setRowInEdit('');
        setOpenIndex(-1);
      },
    });
  };

  const handleAdd = () => dispatch(startDeviceEditTool());

  const handleStart = (data: any, index: number) => {
    setSavedData(data);
    setRowInEdit(data.id);
    setOpenIndex(index);
  };

  const handleCancel = (savedData: any, index?: number) => {
    setRowInEdit('');
    setOpenIndex(-1);
    handleRevert(savedData, index ?? -1);
  };

  return (
    <>
      <ExtraWrapper>
        <TitleWrapper>
          <StyledTitle>{languageStrings.paymentDetails}</StyledTitle>
        </TitleWrapper>
        <Wrapper>
          <UniversalTable
            HttpService={HttpService}
            theme={theme}
            language={shortName}
            placeholderProps={{
              keyword,
              placeholderOptions: { countRows: 3 },
              conditionalStyle: { height: '100%', overflow: 'hidden', borderRadius: '8px' },
            }}
            mainApiUrl={`${API_URL}/v2/billing/payment_details`}
            apiTriggers={[shortName]}
            keyword={keyword}
            offset={+offset}
            offsetKey={offsetKey}
            actionSet={[]}
            inlineActionSet={[{ preset: 'EDIT_ACTION' } as any]}
            pathParams={pathParams}
            queryParams={queryParams}
            sortingData={sortingData}
            sortingKeys={sortingKeys}
            tablePath={View.BILLING_DETAILS}
            accessData={accessData}
            isSearchEnabled={false}
            isPaginationEnabled={false}
            customRowClick={() => {}}
            presetMapping={{
              component: {
                EDITABLE_INPUT: (
                  value: any,
                  state: any,
                  setValue?: any,
                  saveToggle?: boolean,
                  id?: string,
                  index?: number,
                ) => {
                  return (
                    <UniversalTableInput
                      value={value}
                      state={state}
                      setField={setValue}
                      valuesList={id === PaymentMethodType.PAYMENT_METHOD ? paymentMethodsList : invoiceTypesList}
                      isEditInProcess={rowInEdit === id}
                      options={{
                        [TableInputType.SELECT]: {
                          hackOverflow: true,
                          placeholder:
                            id === PaymentMethodType.PAYMENT_METHOD
                              ? languageStrings.choosePaymentMethodPlaceholder
                              : languageStrings.chooseTypeOfInvoices,
                        },
                      }}
                    />
                  );
                },
              },
              inline: {
                EDIT_ACTION: (data: any, index: number) => {
                  const isEditInProcess = rowInEdit === data.id;

                  return (
                    <EditAction
                      data={data}
                      index={index}
                      isEditInProcess={isEditInProcess}
                      handleStart={handleStart}
                      handleConfirm={handleConfirm}
                      handleCancel={handleCancel}
                      handleAdd={handleAdd}
                      savedData={savedData}
                    />
                  );
                },
              },
            }}
            columnStructureTriggers={[rowInEdit]}
            columnStructure={[
              {
                fieldName: 'property',
                placeholder: languageStrings.deviceInfoLabelProperty,
                label: languageStrings.deviceInfoLabelProperty,
                defaultValue: '',
                width: '2fr',
              },
              {
                fieldName: 'value',
                placeholder: languageStrings.insightDetailsDeviceConfigHeaderValue,
                label: languageStrings.insightDetailsDeviceConfigHeaderValue,
                type: RowItemType.CUSTOM,
                component: { preset: 'EDITABLE_INPUT' },
                width: '2fr',
                defaultValue: '',
                selectItems: paymentMethodsList,
              },
            ]}
          />
        </Wrapper>
      </ExtraWrapper>
    </>
  );
};

export const PaymentDetailsTable = withTableContextProvider(PaymentDetailsPlain);
