import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { COMPANY_CREATION_STEP, selectParentOrganisation } from '../../app/state/organisations';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import {
  AddCompany,
  AddDevicesManually,
  DevicesStepType,
  AddNewCompanyModalFirstStep,
  AddNewCompanyModalSecondStep,
  Summary,
  UploadDevicesModal,
  AddDeviceModal,
} from './components';
import { CreateMoreUsers, ModifyUsersStage } from './components/createMoreUsers/CreateMoreUsers';
import { InformationModal } from './components/informationModal/InformationModal';
import { useOrganisationCreationDevices } from '../../api/organisation';
import { useTrigger } from '../../utils/useTrigger';
import { selectCurrentLocation } from '../../app/state/organisations';

interface CompanyCreationWizardProps {}

export const CompanyCreationWizard: FC<CompanyCreationWizardProps> = () => {
  const currentLocation = useSelector(selectCurrentLocation);
  const [trigger, toggleTrigger] = useTrigger();
  const { profile } = useSelector(selectParentOrganisation);
  const [triggers] = useState<any>([trigger]);
  const { isLoading } = useOrganisationCreationDevices({ triggers, profile_id: profile.id, isFullScopeUrl: true });

  useBlockBodyScroll(!!currentLocation);

  if (!currentLocation) {
    return null;
  }

  const createMoreUsersMapping = [COMPANY_CREATION_STEP.CREATE_MORE_USERS, COMPANY_CREATION_STEP.SUMMARY_USERS];
  const addDevicesMapping = [COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY, COMPANY_CREATION_STEP.SUMMARY_DEVICES];
  console.log({ currentLocation });
  return (
    <>
      <AddCompany isOpen={currentLocation === COMPANY_CREATION_STEP.ADD_COMPANY} />
      <InformationModal isOpen={currentLocation === COMPANY_CREATION_STEP.FULL_OPERATIONAL_EXPLANATIONS} />
      <AddNewCompanyModalFirstStep isOpen={currentLocation === COMPANY_CREATION_STEP.ADD_NEW_COMPANY} />
      <AddNewCompanyModalSecondStep isOpen={currentLocation === COMPANY_CREATION_STEP.CREATE_FIRST_USER} />
      <CreateMoreUsers
        isOpen={createMoreUsersMapping.includes(currentLocation)}
        type={
          currentLocation === COMPANY_CREATION_STEP.CREATE_MORE_USERS
            ? ModifyUsersStage.CREATION
            : ModifyUsersStage.SUMMARY
        }
      />
      <AddDeviceModal isOpen={currentLocation === COMPANY_CREATION_STEP.ADD_DEVICES} />
      <UploadDevicesModal isOpen={currentLocation === COMPANY_CREATION_STEP.UPLOAD_DEVICES} />
      <AddDevicesManually
        isOpen={addDevicesMapping.includes(currentLocation)}
        isLoading={isLoading}
        toggleTrigger={toggleTrigger}
        type={
          currentLocation === COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY ? DevicesStepType.ADD : DevicesStepType.EDIT
        }
      />
      <Summary isOpen={currentLocation === COMPANY_CREATION_STEP.SUMMARY} />
    </>
  );
};
