import React, { FC, useState } from 'react';
import { useBillingData, useGenerateStripeAccountLink, usePaymentMethodKeys } from '../../../../api/billing';
import { DeliveryAddressTable } from './components/deliveryAddressTable/DeliveryAddressTable';
import { InvoiceDetailsTable } from './components/invoiceDetailsTable/InvoiceDetailsTable';
import { PaymentDetailsTable } from './components/paymentDetailsTable/PaymentDetailsTable';
import { PaymentMethodModal } from './components/paymentMethodModal/PaymentMethodModal';
import { TitleBlock } from './components/titleBlock/TitleBlock';
import { ExtraWrapper, Wrapper } from './styled';

export const BillingDetails: FC = () => {
  const [billingData, { setPaymentMethodStatus }] = useBillingData();
  const isAccountLinkingActive = billingData && billingData.show_onboarding_button;
  const isPaymentLinkingActive = billingData && billingData.show_link_payment_method_button;

  const stripeLink = useGenerateStripeAccountLink(isAccountLinkingActive);
  const paymentKeys = usePaymentMethodKeys(isPaymentLinkingActive);
  const [isPaymentModalShown, setIsPaymentModalShown] = useState(false);

  const linkAccount = () => {
    window.location.href = stripeLink;
  };

  const linkPaymentMethod = () => {
    setIsPaymentModalShown(true);
  };

  return (
    <ExtraWrapper>
      <TitleBlock
        isLinkAccountShown={!!stripeLink}
        isLinkPaymentShown={!!paymentKeys && isPaymentLinkingActive}
        linkAccount={linkAccount}
        linkPaymentMethod={linkPaymentMethod}
      />
      <Wrapper>
        <InvoiceDetailsTable />
        <DeliveryAddressTable />
        <PaymentDetailsTable />
      </Wrapper>

      {paymentKeys && (
        <PaymentMethodModal
          isOpen={isPaymentModalShown}
          paymentKeys={paymentKeys}
          closeModal={() => setIsPaymentModalShown(false)}
          setPaymentMethodStatus={setPaymentMethodStatus}
        />
      )}
    </ExtraWrapper>
  );
};
