import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Info } from './components/Info';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';
import { View } from '../../routes/routeInterfaces';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.phone} {
    flex-direction: column;
  }
`;
export const Line = styled.div`
  height: 16px;
  border-left: 1px solid;
  border-color: ${colorFetch('gray3')};
  margin: 0 14px;
`;

export interface DevicesSubtitleProps {
  data: any;
}

export const DeviceBlock: React.FC<DevicesSubtitleProps> = ({ data }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const customHistory = useCustomHistory();

  return (
    <Wrapper>
      <Info
        deviceTitle={languageStrings.deviceLabel + ' ID:'}
        deviceName={data.devices[0].transport_id}
        onClick={() => {
          const to = View.USER_MANAGE_DEVICES_DETAILS;
          customHistory.push(to, {
            pathParams: { id: data.devices[0].id },
          });
        }}
      />
    </Wrapper>
  );
};
