import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as DownloadCloud } from '../../../assets/download_cloud 1.svg';
import ss from '../../../utils/ss';
import { LsValueType } from '../../../enums/LsValueType';
import { API_URL } from '../../../consts';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/user';
import withAuth from '../../../features/authentication/withAuth';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useParams } from '@cthings.co/router-dom';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  ${media.phone} {
    flex-direction: column;
  }
`;

const WrapperDownloadCloud = styled(DownloadCloud)`
  path {
    stroke: ${colorFetch('primary')};
  }
`;

const WrapperTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  margin-top: 2px;
`;

const WrapperText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 2px;
`;

const DownloadLink = styled.a`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  margin: 1px 0 0 5px;
  color: ${colorFetch('primary')};
  transition: all 0.3s linear;
  cursor: pointer;
  border-bottom: 1px solid;
  border-color: ${colorFetch('primary')};
  text-decoration: none;
  &:hover {
    color: ${colorFetch('primary')};
  }
`;

const WrapperDescription = styled.div`
  width: 325px;
  display: flex;
  justify-content: center;
`;

const WrapperDownloadLink = styled(DownloadLink)`
  display: none;
`;

const Download = () => {
  const theme = useTheme();
  const { black1 } = theme.colors;
  // const id = props?.match?.params?.id || '';

  const languageStrings = useSelector(selectLanguageStrings);

  const params = useParams();
  const { id } = params;

  const [avoidDoubleDownloading, setAvoidDoubleDownloading] = useState(false);

  const simulateClick = (e: any) => {
    setAvoidDoubleDownloading(true);
    !avoidDoubleDownloading && e && e.click();
  };

  return (
    <Wrapper>
      <WrapperDownloadCloud theme={theme} />
      <WrapperTitle className="title">{languageStrings.theDownloadWillStartShortly}</WrapperTitle>
      <WrapperDescription>
        <WrapperText color={black1}>{languageStrings.ifItDoesntTryThis}</WrapperText>
        <DownloadLink
          href={`${API_URL}/v2/reports/multiplereportsdownload/?file_ids=${id || ''}&access_token=${ss.get(
            LsValueType.token,
          )}`}
        >
          {languageStrings.directLink}
        </DownloadLink>
      </WrapperDescription>
      <WrapperDownloadLink
        ref={simulateClick}
        href={`${API_URL}/v2/reports/multiplereportsdownload/?file_ids=${id || ''}&access_token=${ss.get(
          LsValueType.token,
        )}`}
        target="_blank"
        rel={'noopener noreferer'}
      />
    </Wrapper>
  );
};

export default withAuth(React.memo(Download));
