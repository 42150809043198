import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { InputModal } from '../../../../../../../components/inputModal/InputModal';
import { media } from '@cthings.co/styles-utils';
import { FieldsType, isValidField } from '../../../../../../../utils/validation';

const StyledModal = styled(Modal)`
  width: 100%;

  & > div {
    width: 100%;
  }

  ${media.semiTablet} {
    align-items: flex-end !important;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  align-items: center !important;
  padding: 24px !important;
  width: 100% !important;
  max-width: 564px !important;
  ${media.semiTablet} {
    border-radius: 12px 12px 0 0 !important;
    height: 100% !important;
    align-items: flex-end !important;
  }
`;

const StyledText = styled(Text)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px !important;
`;

const StyledInput = styled(InputModal)`
  width: 100%;
  margin: 0 0 20px 0;
  & > div {
    & > div > input {
      padding: 8px 12px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  ${media.semiTablet} {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledButton = styled(CommonButton)`
  width: 116px;
  height: 32px;

  ${media.semiTablet} {
    width: 100%;
    height: 40px;
  }
`;

export enum EditModalType {
  EDIT_NAME = 'EDIT_NAME',
  EDIT_GROUP = 'EDIT_GROUP',
}

export interface EditModalProps {
  type: EditModalType;
  data: any;
  isOpenModal: boolean;
  handleOnChangeField: any;
  onClose: () => void;
  onSave: () => void;
  languageStrings?: any;
  validatingIsStarted?: boolean;
}

export const EditModal: FC<EditModalProps> = ({
  type,
  data,
  isOpenModal,
  handleOnChangeField,
  onClose,
  onSave,
  languageStrings,
  validatingIsStarted,
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <StyledText type={TextType.SEMITITLE} color={black1}>
          {type === EditModalType.EDIT_NAME
            ? languageStrings.insightDetailsStatusChangesTitlesDeviceName
            : languageStrings.insightDetailsDeviceInfoTitle}
        </StyledText>
        <StyledInput
          name={'Name'}
          value={data}
          placeholder={languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChangeField(e.target.value);
          }}
          disabled={false}
          startedValidation={validatingIsStarted}
          isError={!isValidField(data, FieldsType.TEXT)}
        />
        <ButtonsWrapper>
          <StyledButton onClick={onSave}>{languageStrings.saveButton}</StyledButton>
          <StyledButton colors={{ main: black1, background: 'transparent' }} onClick={onClose}>
            {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
          </StyledButton>
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
