import React, { useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/user';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as CloseIcon } from '../../../assets/x.svg';
import { useMediaType } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const HeadingBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.2px;
  color: ${colorFetch('orange2')};
  text-align: left;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainInfoTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: ${colorFetch('gray1')};
`;

const Timestamp = styled(Text)``;

const MainInfo = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1')};
  margin-top: 10px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 15px;
`;

const CloseButton = styled(CommonButton)`
  min-width: 116px;
  font-weight: 500;
  color: ${colorFetch('pureWhite')};
  background-color: ${colorFetch('primary')};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.additional6};
  cursor: pointer;
  padding: 8px 38px;
  ${media.semiTablet} {
    width: 100%;
  }
`;

const ScrollBlock = styled.div`
  display: flex;
  max-height: 450px;
  flex-direction: column;
  overflow: scroll;
  padding: 0;
  margin-top: 25px;
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  &:first-child {
    margin: 0;
  }
`;

const CloseLine = styled.div`
  position: absolute;
  bottom: 62px;
  left: -14px;
  width: 106%;
  height: 1px;
  background-color: ${colorFetch('gray4')};
  &.top {
    width: 106%;
    top: 42px;
    left: -14px;
  }
`;

export interface InfoCardModalProps {
  data: any;
  showMoreInfo: boolean;
  closeModal: any;
  title: string;
}

export const InfoCardModal: React.FC<InfoCardModalProps> = ({ showMoreInfo, closeModal, data, title, ...props }) => {
  const theme = useTheme();
  const [scroll, setScroll] = useState(0);
  const [height, setHeight] = useState(0);
  const scrollRef: any = useRef();
  const languageStrings = useSelector(selectLanguageStrings);
  const { semiTablet, phone } = useMediaType();

  const handleScroll = () => {
    setScroll(scrollRef.current.scrollTop);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
      setHeight(scrollRef.current.clientHeight);
    }
  }, [showMoreInfo]);

  return (
    <Wrapper>
      <HeadingBlock>
        <Title>{title ? title : ''}</Title>
        {phone && <CloseIcon onClick={closeModal} />}
      </HeadingBlock>
      {showMoreInfo && (
        <>
          <ScrollBlock ref={scrollRef}>
            {data.map((el: any, index: number) => {
              return (
                <InfoBlock key={index}>
                  <SubTitleWrapper>
                    <MainInfoTitle>{el.name}</MainInfoTitle>
                    <Timestamp type={TextType.TEXT_14_GRAY}>
                      {getRequiredDateFormat(el.timestamp, 'DD.MM.YYYY')}
                    </Timestamp>
                  </SubTitleWrapper>
                  <MainInfo>{el.desc}</MainInfo>
                </InfoBlock>
              );
            })}
          </ScrollBlock>
          <CloseButtonWrapper>
            <CloseButton
              width={languageStrings.closeButtonValue.length * 18 + 'px' || '116px'}
              theme={theme}
              onClick={closeModal}
            >
              {languageStrings.closeButtonValue}
            </CloseButton>
          </CloseButtonWrapper>
        </>
      )}
      {height >= 434 && !semiTablet && (
        <>
          {scroll !== 0 && <CloseLine className={'top'} />}
          <CloseLine />
        </>
      )}
    </Wrapper>
  );
};
