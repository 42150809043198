import React, { useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { getRequiredDateFormat } from '../../../../../../../utils/date/date-format';
import { NoteButton } from '../noteButton/NoteButton';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
  width: calc(100% - 26px);
  height: auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 78px;
  grid-gap: 20px;
  background-color: ${colorFetch('gray6')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  margin-bottom: 22px;
  padding: 8px 14px;
  &.last {
    margin: 0;
  }
  &.resolved {
    background-color: ${colorFetch('green2')};
  }
  ${media.phone} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
  }
`;

const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

type CircleProps = {
  borderColor: string;
};

const Circle = styled.div<CircleProps>`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 3px solid;
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 50%;
`;

type LineProps = {
  height: string;
};

const Line = styled.div<LineProps>`
  width: 2px;
  height: ${({ height }) => height};
  background-color: ${colorFetch('gray3')};
  position: absolute;
  &.down {
    top: 16px;
  }
  &.up {
    bottom: 16px;
  }
  &.first {
    display: none;
  }
  &.last {
    display: none;
  }
`;

const Source = styled(Text)`
  ${media.phone} {
    text-align: end;
  }
`;

const Description = styled(Text)`
  &.mobile {
    display: none;
  }
  ${media.phone} {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
    }
  }
`;

export const EventItem = ({
  data,
  index,
  lastIndex,
  isTopBlured,
  languageStrings,
  ...props
}: {
  data: {
    desc: string;
    ts: string;
    source: string;
    status: number;
    note: { ts: string; desc: string; text: string } | null;
  };
  index: number;
  lastIndex: number;
  isTopBlured?: boolean;
  languageStrings?: any;
  className?: string;
}) => {
  const theme = useTheme();
  const { orange2, blue2, red2, gray2, green2, gray1, pureWhite } = theme.colors;

  const [height, setHeight] = useState(0);
  const fieldRef = useRef<any>(null);

  const generateBorderColor = (status: number) => {
    switch (status) {
      case 1:
        return blue2;
      case 2:
        return orange2;
      case 3:
        return red2;
      case 4:
        return green2;
      default:
        return gray2;
    }
  };

  const { desc, ts, source, note, status } = data;

  useEffect(() => {
    setHeight(fieldRef.current.clientHeight);
  });

  return (
    <Wrapper {...props}>
      <CircleWrapper>
        <Line height={`${24}px`} className={index === 0 ? 'first up' : 'up'} />
        <Circle borderColor={generateBorderColor(status)} />
        <Line height={`${height - 16}px`} className={index === lastIndex ? 'last down' : 'down'} />
      </CircleWrapper>
      <FieldWrapper ref={fieldRef} className={status === 4 ? 'resolved' : ''}>
        <Text type={TextType.TEXT_14_GRAY} color={status === 4 ? pureWhite : gray1} weight={'400'}>
          {getRequiredDateFormat(ts, 'DD.MM.yyyy, HH:mm')}
        </Text>
        <Description
          className={'desktop'}
          type={TextType.TEXT_14_GRAY}
          color={status === 4 ? pureWhite : gray1}
          weight={'500'}
        >
          {desc}
        </Description>
        <Source type={TextType.TEXT_14_GRAY} color={status === 4 ? pureWhite : gray1} weight={'400'}>
          {source}
        </Source>
        <Description
          className={'mobile'}
          type={TextType.TEXT_14_GRAY}
          color={status === 4 ? pureWhite : gray1}
          weight={'500'}
        >
          {desc}
        </Description>
        {note && (
          <NoteButton
            data={{ ...note, desc }}
            status={status}
            isTopBlured={isTopBlured}
            languageStrings={languageStrings}
          />
        )}
      </FieldWrapper>
    </Wrapper>
  );
};
