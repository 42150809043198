import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { Textarea } from '../../../textarea/Textarea';
import { FieldsType, isValidField } from '../../../../utils/validation';
import { InputWrapper } from '../inputWrapper/InputWrapper';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type DateFieldProps = {
  title?: string;
  isFocused: boolean;
};

const DateField = styled.div<DateFieldProps>`
  width: ${({ title }) => (title ? '100%' : 'calc(100% - 18px)')};
  max-width: ${({ title }) => (title ? '100%' : '190px')};
  height: ${({ title }) => (title ? '40px' : '32px')};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${({ isFocused, theme }) =>
    isFocused ? colorFetch('primary')({ theme }) : colorFetch('gray3')({ theme })};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 4px 12px;
  transition: all 0.3s linear;
`;

type DateItemProps = {
  title?: string;
};

const DateItem = styled.div<DateItemProps>`
  width: ${({ title }) => (title ? '33%' : 'max-content')};
  display: flex;
  justify-content: ${({ title }) => (title ? 'center' : 'flex-start')};
  align-items: center;
  &.center {
    justify-content: space-between;
  }
`;

const StyledTextarea = styled(Textarea)`
  width: 30px;
  margin: 0;
  text-align: center;
  &.last {
    width: 60px;
  }
  &.placeholder {
    -webkit-text-fill-color: ${colorFetch('gray2')};
    font-weight: 500;
  }
` as any; // @TODO fix type

export interface DatePickerOptions {
  title?: string;
  validatingIsStarted?: boolean;
  isFocusable?: boolean;
  needToUpdate?: boolean;
}
export interface DatePickerFunctions {
  setNeedToUpdate?: Function;
  handleOnBlurItem?: Function;
}

export const DatePicker = ({
  globalValue,
  handleChangeValue,
  options,
  isEditInProcess,
  functions,
  ...props
}: {
  globalValue: any;
  handleChangeValue: Function;
  options: DatePickerOptions;
  isEditInProcess?: boolean;
  functions: DatePickerFunctions;
  setNeedToUpdate?: Function;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1, gray3 } = theme.colors;
  const globalDay = getRequiredDateFormat(globalValue, 'DD');
  const globalMonth = getRequiredDateFormat(globalValue, 'MM');
  const globalYear = getRequiredDateFormat(globalValue, 'YYYY');
  const [localDay, setLocalDay] = useState(globalDay);
  const [localMonth, setLocalMonth] = useState(globalMonth);
  const [localYear, setLocalYear] = useState(globalYear);
  const [isFocused, setIsFocused] = useState(false);

  const { title, validatingIsStarted, isFocusable, needToUpdate } = options;
  const { handleOnBlurItem, setNeedToUpdate } = functions;

  const daysInMonth = new Date(+localYear, +localMonth, 0).getDate();
  const currentDate = new Date();
  const handleOnKeyDown = (
    e: KeyboardEvent,
    value: string,
    setValue: Function,
    maxValue: number,
    minValue: number,
    incBorderValue?: number,
    decBorderValue?: number,
  ) => {
    if (e.key === 'ArrowUp' && +value < maxValue) {
      setValue(`${incBorderValue && +value < incBorderValue ? '0' : ''}${+value + 1}`);
    }
    if (e.key === 'ArrowDown' && +value > minValue) {
      setValue(`${decBorderValue && +value < decBorderValue ? '0' : ''}${+value - 1}`);
    }
  };
  const handleOnBlur = (
    value: string,
    formatOfValue: string,
    setValue: Function,
    handleChangeValue: Function,
    maxValue: number,
    minValue: number,
    borderValue?: number,
  ) => {
    const preparedValue = !value
      ? `${getRequiredDateFormat(currentDate, formatOfValue)}`
      : value && +value < minValue
      ? `${minValue > 0 && minValue < 10 ? `0${minValue}` : minValue}`
      : value && +value > maxValue
      ? `${maxValue}`
      : borderValue && value && +value < borderValue && value[0] !== '0'
      ? `0${value}`
      : value;
    setValue(preparedValue);
    handleChangeValue(preparedValue);
  };

  const valuesArr = [
    {
      value: localDay,
      handleChangeValue: (day: string) => {
        setLocalDay(day);
      },
      handleKeyOnClick: (e: KeyboardEvent) => {
        handleOnKeyDown(e, localDay, setLocalDay, daysInMonth, 1, 9, 11);
      },
      handleOnBlur: () => {
        handleOnBlur(
          localDay,
          'DD',
          setLocalDay,
          (value: string) => {
            if (globalValue) {
              handleChangeValue(new Date(globalValue.setDate(value)));
            }
          },
          daysInMonth,
          1,
          10,
        );
      },
      placeholder: 'DD',
      maxLength: 2,
    },
    {
      value: localMonth,
      handleChangeValue: (month: string) => {
        setLocalMonth(month);
      },
      handleKeyOnClick: (e: KeyboardEvent) => {
        handleOnKeyDown(e, localMonth, setLocalMonth, 12, 1, 9, 11);
      },
      handleOnBlur: () => {
        handleOnBlur(
          localMonth,
          'MM',
          setLocalMonth,
          (value: string) => {
            if (globalValue) {
              const preparedValue = +value >= 10 ? +value : +value[1];
              handleChangeValue(new Date(globalValue.setMonth(preparedValue - 1)));
            }
          },
          12,
          1,
          10,
        );
      },
      placeholder: 'MM',
      maxLength: 2,
    },
    {
      value: localYear,
      handleChangeValue: (year: string) => {
        setLocalYear(year);
      },
      handleKeyOnClick: (e: KeyboardEvent) => {
        handleOnKeyDown(e, localYear, setLocalYear, 2100, 1900);
      },
      handleOnBlur: () => {
        handleOnBlur(
          localYear,
          'YYYY',
          setLocalYear,
          (value: string) => {
            if (globalValue) {
              handleChangeValue(new Date(globalValue.setFullYear(value)));
            }
          },
          2100,
          1900,
        );
      },
      placeholder: 'YYYY',
      maxLength: 4,
    },
  ];

  useEffect(() => {
    if (+localDay > daysInMonth) {
      handleChangeValue(new Date(globalValue.setDate(daysInMonth)));
      setLocalDay(`${daysInMonth}`);
    }
  }, [localDay, daysInMonth, globalValue]);

  useEffect(() => {
    if (needToUpdate && setNeedToUpdate) {
      setLocalDay(getRequiredDateFormat(globalValue, 'DD'));
      setLocalMonth(getRequiredDateFormat(globalValue, 'MM'));
      setLocalYear(getRequiredDateFormat(globalValue, 'YYYY'));
      setNeedToUpdate(false);
    }
  }, [globalValue, needToUpdate, setNeedToUpdate]);

  return (
    <InputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      {isEditInProcess ? (
        <DateField title={title} isFocused={isFocused} theme={theme}>
          {valuesArr.map((item: any, index: number) => {
            const { value, handleChangeValue, placeholder, maxLength, handleKeyOnClick, handleOnBlur } = item;

            return (
              <DateItem title={title} className={index === 1 && title ? 'center' : ''}>
                {index === 1 && (
                  <Text type={TextType.TEXT_14_GRAY} color={gray3} margin={'0 6px'}>
                    /
                  </Text>
                )}
                <StyledTextarea
                  className={`${index === 2 ? 'last' : 'first'} ${value.length === 0 ? 'placeholder' : ''} `}
                  textValue={value}
                  changeValue={handleChangeValue}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  onKeyDown={(e: KeyboardEvent) => {
                    handleKeyOnClick(e);
                  }}
                  isError={validatingIsStarted && !isValidField(value, FieldsType.NUMBER)}
                  onFocus={() => {
                    isFocusable && setIsFocused(true);
                  }}
                  onBlur={() => {
                    handleOnBlur();
                    handleOnBlurItem && handleOnBlurItem();
                    isFocusable && setIsFocused(false);
                  }}
                  theme={theme}
                />
                {index === 1 && (
                  <Text type={TextType.TEXT_14_GRAY} color={gray3} margin={'0 6px'}>
                    /
                  </Text>
                )}
              </DateItem>
            );
          })}
        </DateField>
      ) : (
        <Text type={TextType.TEXT_14_GRAY} color={gray1}>
          {getRequiredDateFormat(globalValue, 'DD.MM.YYYY')}
        </Text>
      )}
    </InputWrapper>
  );
};
