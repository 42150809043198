import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as CloseIcon } from '../../../../assets/Close.svg';
import { ThemeType } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const StyledCommonButton = styled(CommonButton)`
  height: 32px;
  max-width: 116px;

  ${media.phone} {
    height: 40px;
    width: 100%;
    max-width: none;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

type ButtonType = typeof CommonButton & { theme: ThemeType };

export const SaveButton = styled(StyledCommonButton).attrs((props: ButtonType) => ({
  ...props,
  fontSize: '14px',
}))`
  margin: 0;

  ${media.phone} {
    margin-bottom: 10px;
  }
`;

export const CancelButton = styled(StyledCommonButton).attrs(
  ({
    theme: {
      colors: { primary: primaryColor, pureWhite, black1 },
    },
    ...rest
  }: ButtonType) => ({
    ...rest,
    fontSize: '14px',
    backgroundColor: pureWhite,
    borderColor: primaryColor,
    color: black1,
  }),
)`
  margin: 0 16px 0 0;

  ${media.phone} {
    margin: 0;
  }
`;

export const EditButton = styled(Text)`
  cursor: pointer;
  text-decoration-line: underline;
` as any; // @TODO fix type

export const SubtitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  border-color: ${colorFetch('primary')};
  margin: 24px 0 18px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  ${media.phone} {
    flex-direction: column-reverse;
  }
`;
