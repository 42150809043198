import React from 'react';
import styled from '@cthings.co/styled-components';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Modal } from '@bit/first-scope.modal';
import { Text, TextType } from '@bit/first-scope.text/dist/components/text/Text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { Check } from './components/check/Check';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  display: none;
  ${media.semiTablet} {
    display: flex;
    align-items: flex-end;
    & > div {
      width: 100%;
    }
  }
`;
const StyledContainer = styled(ModalContainer)`
  ${media.semiTablet} {
    width: 100%;
    height: 100%;
    align-items: center;
    border-radius: 24px 24px 0 0;
    padding: 46px 16px 40px 16px;
    & > span {
      font-size: 18px;
    }
  }
`;

export const NFCModal = ({
  isOpenModal,
  handleCloseModal,
  message,
  languageStrings,
}: {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  message?: string;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { black1, pureWhite } = theme.colors;
  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledContainer>
        <Check />
        <Text type={TextType.TEXT_14_BLACK} color={black1} margin={'20px 0 38px 0'}>
          {message ? message : languageStrings.scanCuccessful}
        </Text>
        <CommonButton
          onClick={handleCloseModal}
          width={'100%'}
          height={'40px'}
          colors={{
            main: black1,
            background: pureWhite,
          }}
        >
          {languageStrings.closeButtonValue}
        </CommonButton>
      </StyledContainer>
    </StyledModal>
  );
};
