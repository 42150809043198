import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { ReactComponent as ClarifyingIcon } from './assets/Info.svg';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { InfoButton } from '../../../../components/infoButton/InfoButton';
import { ReactComponent as CloseIcon } from '../../../../assets/Close.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const HeadingBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.semiTablet} {
    align-items: center;
  }
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black')};
` as any; // @TODO fix type

export const StepNumber = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: ${colorFetch('gray2')};
  margin-left: 5px;
` as any; // @TODO fix type

type InfoProps = {
  margin?: string;
};

export const Info = styled(Text)<InfoProps>`
  width: 100%;
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  margin: ${({ margin }) => margin || '15px 0 0 '}!important;
` as any; // @TODO fix type

export const ClarifyingBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colorFetch('gray6')};
  border: 1px solid;
  border-color: ${colorFetch('gray5')};
  border-radius: 6px;
  box-sizing: border-box;
  padding: 12px;
  margin-top: 15px;
`;

export const StyledClarifyingIcon = styled(ClarifyingIcon)`
  min-width: 20px;
  min-height: 20px;
`;

export const Ways = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('primary')};
  margin: 0 !important;
`;

export const Tooltip = styled(InfoButton)`
  display: inline;
  margin-left: 0;
  top: 3px;
  & > div {
    display: inline;
  }
  & > div > svg {
    position: relative;
    top: 5px;
  }
` as any; // @TODO fix type

export const ButtonBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  gap: 10px;
  ${media.semiTablet} {
    flex-direction: column-reverse;
    margin-top: 15px;
    gap: 0;
  }
`;

export const Button = styled(CommonButton)`
  width: ${({ width }) => width || '116px'};
  background-color: ${({ colors: { background } = {} }) => background};
  color: ${({ colors: { main } = {} }) => main};
  white-space: nowrap;
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
    margin-top: 10px;
  }
` as any; // @TODO fix type

export const ButtonRow = styled.div`
  display: flex;
  gap: 16px;

  ${media.semiTablet} {
    width: 100%;
    flex-direction: column-reverse;
    gap: 0;
  }
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
`;
