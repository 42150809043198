import React, { FC, ReactNode, useCallback } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '../text/Text';
import { Icons } from './components/icons/Icons';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { StatusOfCard } from '../../types/statusOfCards';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export enum CardType {
  TEMPERATURE = 'TEMPERATURE',
  INTERNAL_HUMIDITY_BOOLEAN = 'INTERNAL_HUMIDITY_BOOLEAN',
  COVER = 'COVER',
  OUTLET_TEMPERATURE = 'OUTLET_TEMPERATURE',
  INTERNAL_HUMIDITY = 'INTERNAL_HUMIDITY',
  OUTLET_PRESSURE = 'OUTLET_PRESSURE',
  OUTSIDE_TEMPERATURE = 'OUTSIDE_TEMPERATURE',
  INTERNAL_TEMPERATURE = 'INTERNAL_TEMPERATURE',
  OUTSIDE_PRESSURE = 'OUTSIDE_PRESSURE',
  DEVICES = 'DEVICES',
  LOCATIONS = 'LOCATIONS',
  CITIES = 'CITIES',
  OUTLET = 'OUTLET',
  WATER_LEVEL = 'WATER_LEVEL',
  HUMIDITY = 'HUMIDITY',
}

type ExtraWrapperProps = {
  type: CardType;
  margin?: string;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  width: 280px;
  height: ${({ type }) => (type === CardType.CITIES ? '224px' : '120px')};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow2};
  background-color: ${colorFetch('white')};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.additional16};
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ type }) => (type === CardType.CITIES ? '20px 24px 30px 24px' : '20px 24px')};

  ${media.semiTablet} {
    width: 100%;
  }
`;

type ButtonProps = {
  type: CardType;
  color: string;
  titleColor: string;
};

const Button = styled.div<ButtonProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span {
    color: ${({ type, color, titleColor }) => (type === CardType.CITIES ? color : titleColor)};
    font-size: ${({ type }) => (type === CardType.CITIES ? '14px' : '12px')};
    font-weight: ${({ type }) => (type === CardType.CITIES ? '700' : '500')};
    line-height: ${({ type }) => (type === CardType.CITIES ? '24px' : '16px')};
  }
`;

type WrapperProps = {
  type: CardType;
};

const Wrapper = styled.div<WrapperProps>`
  height: ${({ type }) => (type === CardType.CITIES ? 'max-content' : '64px')};
  display: ${({ type }) => (type === CardType.CITIES ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: flex-end;
`;

const TextWrapper = styled.span`
  max-width: 168px;
  width: 100%;
  display: flex;
  line-height: 52px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Count = styled(Text)`
  transition: none;
  font-size: 36px;
`;

const TitleText = styled(Text)`
  &.title {
    margin-left: 25px;
  }
`;

export interface DashboardCardProps {
  type: CardType;
  title?: string;
  margin?: string;
  titleClassName?: string;
  count?: string | number;
  statusOfValue?: StatusOfCard;
  children?: ReactNode;
  languageStrings?: any;
  className?: string;
}

const DashboardCardPlain: FC<DashboardCardProps> = ({
  type,
  count,
  title,
  margin,
  children,
  statusOfValue,
  languageStrings,
  titleClassName,
  ...props
}) => {
  const theme = useTheme();
  const { black1, gray2, primary, secondary4, green4, red2, orange2 } = theme.colors;
  const getTitle = useCallback(() => {
    if (title) return title;
    switch (type) {
      case CardType.DEVICES:
        return languageStrings.dashBoardCardDevicesTitle;
      case CardType.TEMPERATURE:
        return languageStrings.insightDetailsRealTimeCardsTemperature;
      case CardType.CITIES:
        return languageStrings.dashBoardCardCitiesTitle;
      case CardType.LOCATIONS:
        return languageStrings.dashBoardCardLocationsTitle;
      case CardType.WATER_LEVEL:
        return languageStrings.insightDetailsRealTimeCardsWater;
      case CardType.HUMIDITY:
        return languageStrings.humidity;
    }
  }, [type, title, languageStrings]);

  return (
    <ExtraWrapper margin={margin} type={type} {...props} theme={theme}>
      <Button type={type} color={black1} titleColor={gray2}>
        <TitleText className={titleClassName} type={TextType.TITLE_H5} color={gray2}>
          {getTitle()}
        </TitleText>
        <Wrapper type={type}>
          <TextWrapper>
            <Count
              type={TextType.PROBLEMS_BUTTON_TEXT}
              color={
                statusOfValue
                  ? statusOfValue === StatusOfCard.NORMAL
                    ? black1
                    : statusOfValue === StatusOfCard.WARNING
                    ? orange2
                    : red2
                  : black1
              }
              lineHeight={'52px'}
            >
              {count}
            </Count>
          </TextWrapper>
          <Icons
            type={type}
            primaryColor={
              statusOfValue
                ? statusOfValue === StatusOfCard.NORMAL
                  ? primary
                  : statusOfValue === StatusOfCard.WARNING
                  ? orange2
                  : red2
                : primary
            }
            secondaryColor={
              statusOfValue
                ? statusOfValue === StatusOfCard.NORMAL
                  ? secondary4
                  : statusOfValue === StatusOfCard.WARNING
                  ? orange2
                  : red2
                : secondary4
            }
          />
          {type === CardType.CITIES && <> {children} </>}
        </Wrapper>
      </Button>
    </ExtraWrapper>
  );
};

export const DashboardCard = withLoader(undefined, PlaceholderType.CARD)(DashboardCardPlain);
