import React, { FC } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/user';
import {
  StyledModal,
  StyledModalContainer,
  ButtonsRow,
  CloseIcon,
  HeaderSection,
  NegationButton,
  AffirmativeButton,
  DeleteText,
} from './styled';
import { useTheme } from '@cthings.co/styled-components';

interface DeleteModalProps {
  isOpenModal: boolean;
  onDelete?: () => void;
  setModalClose: () => void;
}

export const DeleteModal: FC<DeleteModalProps> = ({ isOpenModal, onDelete, setModalClose }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const handleCancelClick = () => {
    setModalClose();
  };

  const handleDelete = () => {
    onDelete && onDelete();
    setModalClose();
  };

  const deleteText = languageStrings.userDeleteInfo;
  const negationButtonText = languageStrings.noButton;
  const affirmativeButtonText = languageStrings.yesButton;
  const headerText = languageStrings.emphasizedHeaderTextModalTitle;

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <HeaderSection>
          <Text type={TextType.TEXT_14_BLACK} fontSize={'18px'} weight={'600'} color={black1}>
            {headerText}
          </Text>
          <CloseIcon onClick={handleCancelClick} />
        </HeaderSection>
        <DeleteText>{deleteText}</DeleteText>
        <ButtonsRow>
          <NegationButton onClick={handleCancelClick}>{negationButtonText}</NegationButton>
          <AffirmativeButton onClick={handleDelete}>{affirmativeButtonText}</AffirmativeButton>
        </ButtonsRow>
      </StyledModalContainer>
    </StyledModal>
  );
};
