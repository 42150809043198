var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from '@xstyled/styled-components';
import { Modal } from '../modal/Modal';
import { SearchInput } from '../searchInput/SearchInput';
import { Text, TextType } from '../text/Text';
import { SearchImage } from './components/SearchImage';
const Wrapper = styled.div `
  width: 343px;
  min-width: 343px;
  box-sizing: border-box;
  background-color: white;
  border-radius: ${(props) => props.theme.borderRadius.additional14};
  box-shadow: ${(props) => props.theme.shadows.additionalShadow3};
  padding: 8px;
`;
const SearchWrapper = styled.div `
  min-height: 104px;
  box-sizing: border-box;
  padding: 20px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > svg > path {
    stroke: ${(props) => props.colorOfSpan};
  }
`;
const LocationRow = styled.p `
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
`;
export const SearchBarModal = (_a) => {
    var { isOpenModal, setIsOpenModal, location, handleLocationChange, locations, setSelectedPos, languageStrings } = _a, props = __rest(_a, ["isOpenModal", "setIsOpenModal", "location", "handleLocationChange", "locations", "setSelectedPos", "languageStrings"]);
    const theme = useTheme();
    const { gray2 } = theme.colors;
    const handleLocationClick = (location) => {
        setSelectedPos(location);
        setIsOpenModal(false);
    };
    const handleClose = () => {
        setSelectedPos(null);
        setIsOpenModal(false);
    };
    return isOpenModal ? (React.createElement(Modal, Object.assign({ isOpenModal: isOpenModal }, props),
        React.createElement(Wrapper, { theme: theme },
            React.createElement(SearchInput, { action: handleClose, value: location || '', onChange: handleLocationChange }),
            React.createElement(SearchWrapper, { colorOfSpan: gray2 }, locations.length > 0 ? (React.createElement(React.Fragment, null, locations.map((location) => (React.createElement(LocationRow, { onClick: () => handleLocationClick(location) }, location.name))))) : (React.createElement(React.Fragment, null,
                React.createElement(SearchImage, null),
                React.createElement(Text, { type: TextType.TEXT_14_GRAY, color: gray2 }, languageStrings && languageStrings.insightPlaceholder
                    ? languageStrings.insightPlaceholder
                    : 'Search for something...'))))))) : null;
};
SearchBarModal.defaultProps = {
    isOpenModal: false,
};
