import React from 'react';

export const WavesImage = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33325 4.00065C1.73325 4.33398 2.13325 4.66732 2.99992 4.66732C4.66659 4.66732 4.66659 3.33398 6.33325 3.33398C8.06658 3.33398 7.93325 4.66732 9.66658 4.66732C11.3333 4.66732 11.3333 3.33398 12.9999 3.33398C13.8666 3.33398 14.2666 3.66732 14.6666 4.00065M1.33325 8.00065C1.73325 8.33398 2.13325 8.66732 2.99992 8.66732C4.66659 8.66732 4.66659 7.33398 6.33325 7.33398C8.06658 7.33398 7.93325 8.66732 9.66658 8.66732C11.3333 8.66732 11.3333 7.33398 12.9999 7.33398C13.8666 7.33398 14.2666 7.66732 14.6666 8.00065M1.33325 12.0007C1.73325 12.334 2.13325 12.6673 2.99992 12.6673C4.66659 12.6673 4.66659 11.334 6.33325 11.334C8.06658 11.334 7.93325 12.6673 9.66658 12.6673C11.3333 12.6673 11.3333 11.334 12.9999 11.334C13.8666 11.334 14.2666 11.6673 14.6666 12.0007"
        stroke="#555770"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
