import { API_URL } from '../consts';
import { useApi } from '../features/apiBuilder/useApiBuilder';

const defaultData = { total_devices: 0, total_locations: 0, locations_piechart: [], problems_count: 0 };

export const useDashboardData = (keywords: string[]) => {
  const url = `${API_URL}/v2/devices/aggregated/insight/dashboard`;

  const [data] = useApi({
    keywords,
    defaultValue: defaultData,
    url,
    transformResponse: (data) => data.payload,
  });

  return [data];
};
