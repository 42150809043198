import { Text } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import Tooltip, { useTooltip } from '../../../../../../components/tooltip';
import { TableCustomComponentState } from '../../../../../../features/universalTable/types';

const StyledText = styled(Text)`
  display: inline-flex;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${({ color }) => color};
  gap: 5px;

  & > div > span {
    flex: 1;
    word-break: break-all;
    cursor: pointer;
  }

  &.modal {
    padding: 8px 12px;
  }
` as any; // @TODO fix type

type DeployedProps = {
  color?: string;
};

const Deployed = styled.span<DeployedProps>`
  cursor: pointer;
  color: ${({ color }) => color};
`;

type ProvisionedProps = {
  color?: string;
};

const Provisioned = styled.span<ProvisionedProps>`
  cursor: pointer;
  color: ${({ color }) => color};
`;

type TotalProps = {
  color?: string;
};

const Total = styled.span<TotalProps>`
  cursor: pointer;
  font-weight: 700;
  color: ${({ color }) => color};
`;

const StyledTooltip = styled(Tooltip)`
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  max-width: 215px !important;
  width: max-content !important;
`;

interface typeValue {
  deployed: { number: number; color: string };
  provisioned: { number: number; color: string };
  total: { number: number; color: string };
}
interface NumberOfDevicesProps {
  value: typeValue;
  state?: TableCustomComponentState;
  setValue?: (value: typeValue) => void;
}

export const NumberOfDevices: FC<NumberOfDevicesProps> = ({ value, state }) => {
  const theme = useTheme();
  const { gray1, gray3, green2, orange2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);
  const deployedTooltipText = languageStrings.deployed;
  const provisionedTooltipText = languageStrings.provisioned;
  const totalTooltipText = languageStrings.totalNumberOfDevices;

  const [tooltipIds] = useTooltip(3);

  return (
    <>
      {/* {state === TableCustomComponentState.VIEW ? (
        <Text>
          <Deployed>{value?.deployed?.number}</Deployed> / <Provisioned>{value?.provisioned?.number}</Provisioned> /{' '}
          <Total>{value?.total?.number}</Total>
        </Text>
      ) : state === TableCustomComponentState.VIEW_MODAL ? (
        <Text className={'modal'}>
          <Deployed>{value?.deployed?.number}</Deployed> / <Provisioned>{value?.provisioned?.number}</Provisioned> /{' '}
          <Total>{value?.total?.number}</Total>
        </Text>
      ) : (
        <Text>
          <Deployed>{value?.deployed?.number}</Deployed> / <Provisioned>{value?.provisioned?.number}</Provisioned> /{' '}
          <Total>{value?.total?.number}</Total>
        </Text>
      )} */}
      <StyledText color={gray3} className={state === TableCustomComponentState.VIEW_MODAL ? 'modal' : ''}>
        <StyledTooltip id={tooltipIds[0]} text={deployedTooltipText}>
          <Deployed color={green2}>{value?.deployed?.number}</Deployed>
        </StyledTooltip>
        /
        <StyledTooltip id={tooltipIds[1]} text={provisionedTooltipText}>
          <Provisioned color={orange2}>{value?.provisioned?.number}</Provisioned>
        </StyledTooltip>
        /
        <StyledTooltip id={tooltipIds[2]} text={totalTooltipText}>
          <Total color={gray1}>{value?.total?.number}</Total>
        </StyledTooltip>
      </StyledText>
    </>
  );
};
