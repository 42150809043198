import styled from '@cthings.co/styled-components';
import { SearchBar } from '@bit/first-scope.search-bar';
import { CommonButton } from '@cthings.co/buttons';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 15px 0 20px 0;
`;

export const StyledSearchBar = styled(SearchBar)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: var(--modal-first-layer);
  width: 400px;
  & > div > div {
    width: 400px;
    & > div > svg > path {
      stroke: ${colorFetch('primary')};
    }
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 22px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;
export const StyledButton = styled(CommonButton)`
  margin-right: 16px;
`;
