import { Text, TextType } from '@bit/first-scope.text';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { useTheme } from '@cthings.co/styled-components';
import { useHistory } from '../../../../utils/react-router-dom-abstraction';
import { selectLanguage, selectLanguageStrings } from '../../../../app/state/user';
import { CommonButton } from '../../../../components/commonButton/CommonButton';
import { InfoButton } from '../../../../components/infoButton/InfoButton';
import { LanguageSelector } from '../../../../components/languageSelector/LanguageSelector';
import { signupRedirect } from '../../../../features/authentication/oauthRedirect';
import { colorFetchFDS, media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { PATHS } from '../../../../routes/paths';
import ss from '../../../../utils/ss';
import { LsValueType } from '../../../../enums/LsValueType';
import { useQueryParams } from '../../../../utils/useQueryParams';
import { stringifyUrl } from 'query-string';
import { themeIdentity } from '../../../../styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${colorFetch('gray5')};
  ${media.phone} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.phone} {
    margin-top: 42px;
  }
`;

const StyledLanguageSelector = styled(LanguageSelector)`
  top: 40px;
  right: 40px;
  position: absolute;

  ${media.phone} {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    position: initial;
  }
`;

const Title = styled(Text)`
  font-family: 'Bronova';
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 34px;
  color: ${colorFetch('black')};

  ${media.phone} {
    margin: 30px 0 36px 0;
  }
` as any; // @TODO fix type

const ContainerTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
` as any; // @TODO fix type

const Container = styled.div`
  width: 514px;
  margin-top: 40px;
  padding: 24px;

  display: flex;
  flex-direction: column;

  background: ${colorFetch('white')};
  border-radius: 12px;

  ${media.phone} {
    width: auto;
  }
`;

const ModalTitleRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;

  ${media.phone} {
    margin-bottom: 24px;
  }
`;

const SimpleText = styled(Text)``;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledList = styled.ol`
  margin: 0;
`;

const StyledElement = styled.li`
  color: ${colorFetchFDS('gray1')};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const SubTitle = styled(Text)`
  color: ${colorFetch('gray1')};

  ${media.phone} {
    margin-top: 24px;
  }

  &.bottomSubtitle {
    margin: 0;
  }
`;
type StyledButtonProps = {
  borderColorHover: string;
};

const StyledButton = styled(CommonButton)<StyledButtonProps>`
  width: 116px;
  margin: 32px 0 0 0;
  font-size: 14px;

  &:hover {
    border-color: ${({ borderColorHover }) => borderColorHover};
  }

  ${media.phone} {
    width: 100%;
    height: 40px;
    margin-top: 24px;
    font-size: 14px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
`;

type StyledSignupButtonProps = {
  backgroundHoverColor: string;
  borderColor: string;
  borderColorHover: string;
};

const StyledSignupButton = styled.a<StyledSignupButtonProps>`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 158px;
  height: 30px;
  color: ${colorFetch('black')};
  background-color: ${colorFetch('white')};
  margin: 16px 0 12px 0;
  border: 1px solid;
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 8px;
  text-decoration: none;
  justify-content: center;
  transition: all 0.3s ease;

  ${media.phone} {
    width: 100%;
    height: 40px;
    margin: 24px 0 24px 0;
  }

  &:hover {
    background-color: ${({ backgroundHoverColor }) => backgroundHoverColor};
    border-color: ${({ borderColorHover }) => borderColorHover};
    color: ${colorFetch('white')};
  }
`;

export const ProvisioningLobby = () => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);
  const lang = useSelector(selectLanguage);
  const history = useHistory();
  const queryParams = useQueryParams();

  const handleContinueClick = () => {
    const url = queryParams?.secret ? PATHS.PROVISIONING_DEVICE : PATHS.PROVISIONING_ADDITIONAL_DEVICE;
    history.push(stringifyUrl({ url, query: queryParams }));
  };

  const handleSignUpClick = () => {
    ss.remove(LsValueType.token);
    ss.remove(LsValueType.exp);

    return signupRedirect(lang.id);
  };

  return (
    <Wrapper>
      <StyledLanguageSelector />
      <Content>
        <Title>SMART UTILITIES</Title>
        <Container>
          <ModalTitleRow>
            <ContainerTitle>{languageStrings.provisioning}</ContainerTitle>
            <InfoButton onClick={() => {}} tooltipText={languageStrings.pressToLearnMoreTooltip} />
          </ModalTitleRow>
          <SimpleText color={gray1} weight="400" type={TextType.TEXT_14_GRAY}>
            {languageStrings.provisioningDescribe}
          </SimpleText>
          <SubTitle color={gray1} weight="500" margin="14px 0 0" type={TextType.TEXT_14_GRAY}>
            {languageStrings.provisioningRow}
          </SubTitle>
          <TextWrapper>
            <SimpleText color={themeIdentity.colors.primary} type={TextType.TEXT_14_GRAY}>
              {languageStrings.createAccount}
            </SimpleText>
            <SimpleText color={gray1} weight="400" type={TextType.TEXT_14_GRAY}>
              {languageStrings.provisioningContent}
            </SimpleText>
          </TextWrapper>
          <ButtonRow>
            <StyledSignupButton
              borderColor={themeIdentity.colors.primary}
              borderColorHover={themeIdentity.colors.secondary2}
              backgroundHoverColor={themeIdentity.colors.secondary2}
              href={handleSignUpClick()}
              target="_blank"
              rel="noreferrer"
              className="cerateButton"
            >
              {languageStrings.createAccount}
            </StyledSignupButton>
          </ButtonRow>
          <SubTitle color={gray1} className="bottomSubtitle" type={TextType.TEXT_14_GRAY}>
            {languageStrings.provisioningRow2}
          </SubTitle>
          <StyledList>
            <StyledElement>
              {languageStrings.clickOn}
              <Text color={themeIdentity.colors.primary} type={TextType.TEXT_14_GRAY}>
                {languageStrings.continueStyled}
              </Text>
              {languageStrings.provisioningContent2}
            </StyledElement>
            <StyledElement>{languageStrings.provisioningContent3}</StyledElement>
            <StyledElement>
              <Text color={gray1} type={TextType.TEXT_14_GRAY}>
                {languageStrings.provisioningChooseOrganisation}
              </Text>
              {languageStrings.provisioningContent4}
              <Text color={gray1} type={TextType.TEXT_14_GRAY}>
                {languageStrings.provisioningCreateOrg}
              </Text>
              {languageStrings.provisioningContent5}
            </StyledElement>
          </StyledList>
          <ButtonRow>
            <StyledButton
              backgroundColor={themeIdentity.colors.primary}
              backgroundColorHover={themeIdentity.colors.secondary2}
              borderColor={themeIdentity.colors.primary}
              borderColorHover={themeIdentity.colors.secondary2}
              onClick={handleContinueClick}
            >
              {languageStrings.continue}
            </StyledButton>
          </ButtonRow>
        </Container>
      </Content>
    </Wrapper>
  );
};
