import React from 'react';
import { TableCustomComponentState } from '../../../../../../../../../features/universalTable/types';
import { EventView } from './components/eventView/EventView';
import { EventViewModal } from './components/eventViewModal/EventViewModal';

export const EventField = ({ value, state }: { value: string; state?: TableCustomComponentState }) => {
  return (
    <>{state === TableCustomComponentState.VIEW ? <EventView value={value} /> : <EventViewModal value={value} />}</>
  );
};
