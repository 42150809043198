var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { createPortal } from 'react-dom';
import styled from '@xstyled/styled-components';
import { media } from '../../styles/media';
const ExtraWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 1000000;
  background-color: rgb(0, 0, 0, 0.6);
`;
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${media.ultramonitor `
    max-width: 1920px;
`};
`;
export const Modal = (_a) => {
    var { children, isOpenModal } = _a, props = __rest(_a, ["children", "isOpenModal"]);
    if (isOpenModal) {
        return createPortal(React.createElement(ExtraWrapper, Object.assign({}, props),
            React.createElement(Wrapper, null, children)), document.getElementById('modal') || document.body);
    }
    return null;
};
