import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const devicesTableSlice = createSlice({
  name: 'devicesTable',
  initialState,
  reducers: reducers,
});

export const { setExportModalState } = devicesTableSlice.actions;

export default devicesTableSlice.reducer;
