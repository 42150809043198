import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import {
  useAvailableFiltersForEventsGraph,
  useGetAvailableFiltersForGraph,
} from '../../../../../api/devices/historyInsight';
import { selectAccess, selectLanguageStrings } from '../../../../../app/state/user';
import { DeviceType } from '../../../../../enums/DeviceType';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { GroundWaterDetails } from './groundWaterDetails';
import { HydrantDetails } from './hydrantDetails/HydrantDetails';
import { ManholeDetails } from './manholeDetails/ManholeDetails';
import { useDeviceAlertsData } from '../../../../../api/realTimeInsight';
import { SmartCapDetails } from './smartCapDetails/SmartCapDetails';

interface InsightsDetailsProps {}

export type DefaultValue = {
  id: string;
  key: string;
  tooltip: string;
};

export type FilterItems = {
  default_value: DefaultValue;
  items_list: DefaultValue[];
};

export type GraphFilters = {
  averaging_methods: FilterItems;
  intervals: FilterItems;
  periods: FilterItems;
  ts_from: string;
  ts_to: string;
  isLoaded: boolean;
};
export type EventsGraphFilters = {
  periods: FilterItems;
  ts_from: string;
  ts_to: string;
  isLoaded: boolean;
};

export type DefaultFilters = {
  waterLevel: GraphFilters;
  temperature: GraphFilters;
  events: EventsGraphFilters;
};

export type FilterFieldNames = 'periods' | 'intervals' | 'averaging_methods';

const defaultValue: DefaultValue = { key: '', id: '', tooltip: '' };
const defaultFilter: GraphFilters = {
  periods: {
    default_value: defaultValue,
    items_list: [defaultValue],
  },
  intervals: {
    default_value: defaultValue,
    items_list: [defaultValue],
  },
  averaging_methods: {
    default_value: defaultValue,
    items_list: [defaultValue],
  },
  ts_from: '',
  ts_to: '',
  isLoaded: false,
};

export const InsightsDetails: FC<InsightsDetailsProps> = ({ ...props }: any) => {
  const params = useParams();
  const type = params.type as DeviceType;
  const id = params.id || '';
  const pathParams = { type, id };

  const accessData = useSelector(selectAccess).management.devices;
  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const [defaultFilters, setDefaultFilters] = useState<DefaultFilters>({
    waterLevel: defaultFilter,
    temperature: defaultFilter,
    events: defaultFilter,
  });

  const handleSuccess = (data: any) => {
    setDefaultFilters((prev) => ({
      ...prev,
      waterLevel: {
        ...prev.waterLevel,
        ...data.available_filters.waterLevel,
        isLoaded: true,
      },
      temperature: {
        ...prev.temperature,
        ...data.available_filters.temperature,
        isLoaded: true,
      },
      events: {
        ...prev.events,
        ...data.available_filters.events,
        isLoaded: true,
      },
    }));
  };

  const handleError = () => {
    addNotification({
      message: languageStrings.filtersNotificationsError,
      type: 'error',
    });
  };

  useGetAvailableFiltersForGraph({ id, handleSuccess, handleError });

  const [alertsData] = useDeviceAlertsData(id);

  const getPage = (type: DeviceType) => {
    const data: any = {
      [DeviceType.SMART_MANHOLE]: (() => (
        <ManholeDetails
          pathParams={pathParams}
          accessData={accessData}
          infoCardData={alertsData}
          defaultFilters={defaultFilters}
        />
      ))(),
      [DeviceType.SMART_CAP]: (() => (
        <SmartCapDetails
          defaultFilters={defaultFilters.events}
          pathParams={pathParams}
          accessData={accessData}
          infoCardData={alertsData}
        />
      ))(),
      [DeviceType.GROUND_WATER]: (() => (
        <GroundWaterDetails
          pathParams={pathParams}
          accessData={accessData}
          infoCardData={alertsData}
          defaultFilters={defaultFilters}
        />
      ))(),
      [DeviceType.HYDRANT]: (() => (
        <HydrantDetails pathParams={pathParams} accessData={accessData} infoCardData={alertsData} />
      ))(),
    };

    return data[type];
  };

  return getPage(type);
};
