import { CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ReactComponent as LocationImage } from '../../../../../../../../../../../../../assets/location.svg';
import { selectLanguageStrings } from '../../../../../../../../../../../../../app/state/user';
import { RowItemType } from '../../../../../../../../../../../../../features/universalTable/types';

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  margin: 0 0 12px 0;
  ${media.tablet} {
    width: 100%;
  }
`;

const InnerBlock = styled.div`
  width: 100%;
  border: 1px solid ${colorFetch('gray5')};
  background-color: ${colorFetch('gray5')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 7px 12px 7px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;

  &.location {
    width: 100%;
    border: 1px solid ${colorFetch('gray3')};
    background-color: ${colorFetch('pureWhite')};
    padding: 7px 32px 7px 12px;
  }
`;

const LocationIcon = styled(LocationImage)`
  position: absolute;
  right: 12px;
  & > path {
    stroke: ${colorFetch('gray2')};
  }
`;

const StyledButton = styled(CommonButton)`
  font-size: 13px;
  line-height: 17px;
`;

export const CustomMobileRow = ({ value, setIsOpenAddressPopup }: { value: any; setIsOpenAddressPopup?: any }) => {
  const theme = useTheme();
  const { gray1, gray1_5, gray3, pureWhite } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const isLocationRow = value.type === RowItemType.ADDRESS;

  return (
    <Block>
      <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 4px 6px">
        {value.label}
      </Text>
      <InnerBlock
        onClick={isLocationRow ? () => setIsOpenAddressPopup(true) : () => {}}
        className={isLocationRow ? 'location' : ''}
      >
        <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
          {value.value}
        </Text>
        {isLocationRow ? (
          <LocationIcon />
        ) : (
          <StyledButton
            onClick={value.additionalActionFunction}
            width={'148px'}
            height={'26px'}
            colors={{
              main: gray1_5,
              mainHover: gray1_5,
              background: pureWhite,
              backgroundHover: pureWhite,
              border: gray3,
              borderHover: gray3,
            }}
          >
            {value.withQualityControl ? languageStrings.qualityControl : languageStrings.details}
          </StyledButton>
        )}
      </InnerBlock>
    </Block>
  );
};
