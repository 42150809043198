import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

import { GraphRow } from '../../../common/styledComponents/StyledComponents';
import { EventsGraph } from './components/eventsGraph/EventsGraph';
import { TemperatureGraph } from './components/temperatureGraph/TemperatureGraph';
import { WaterLevelGraph } from './components/waterLevelGraph/WaterLevelGraph';
import { DefaultFilters } from '../../../InsightsDetails';

const StyledGraphRow = styled(GraphRow)`
  margin-top: 25px;
  gap: 40px;
  ${media.tablet} {
    margin-top: 45px;
  }
`;

export const History = ({ defaultFilters }: { defaultFilters: DefaultFilters }) => {
  return (
    <StyledGraphRow>
      <WaterLevelGraph defaultFilters={defaultFilters.waterLevel} />
      <TemperatureGraph defaultFilters={defaultFilters.temperature} />
      <EventsGraph defaultFilters={defaultFilters.events} />
    </StyledGraphRow>
  );
};
