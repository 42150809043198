import { useTheme } from '@cthings.co/styled-components';
import {
  ActionType,
  RowItemType,
  UniversalTable,
  useTableFunctions,
  withTableContextProvider,
} from '@cthings.co/universal-table';
import { HttpService, injectPathParams } from '@cthings.co/utils';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClientGlobal, updateClient } from '../../../../api/manage/manageClients';
import { selectFinaliseTrigger, startCompanyCreation } from '../../../../app/state/organisations';
import { selectAccess, selectIsAccess, selectLanguage, selectLanguageStrings } from '../../../../app/state/user';
import { DetailsButton } from '../../../../components/detailsButton/DetailsButton';
import { ManageLayout, ManageTableType } from '../../../../components/manageLayout/ManageLayout';
import { API_URL } from '../../../../consts';
import { CompanyCreationWizard } from '../../../../features/companyCreationWizard';
import { useMobileManagementSearch } from '../../../../layouts/managementMobile/useMobileManagement';
import { constructPathFds, getPath, PATHS } from '../../../../routes/paths';
import { View } from '../../../../routes/routeInterfaces';
import { useMediaType } from '@cthings.co/styles-utils';
import { useCustomHistory } from '../../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../../utils/useQueryParams';
import { AddButton } from './components/addButton/AddButton';
import { NumberOfDevices } from './components/numberOfDevices/NumberOfDevices';
import { useParams } from '@cthings.co/router-dom';
import { stringifyUrl } from 'query-string';
import ss from '../../../../utils/ss';
import { LsValueType } from '../../../../enums/LsValueType';

const OrganisationPlain = () => {
  const theme = useTheme();

  const { monitor2k, ultramonitor, wideMonitor, monitor, narrowMonitor, desktop, tablet } = useMediaType();
  const accessObj = useSelector(selectAccess);

  const customHistory = useCustomHistory();
  const dispatch = useDispatch();

  const { resetTable, setSearchValue } = useTableFunctions();

  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage);

  const keyword = 'OrganisationsTable';
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const pathParams = useParams();

  const handleAdd = () => dispatch(startCompanyCreation());

  const accessData = useSelector(selectAccess).management.client;
  const isAccess = useSelector(selectIsAccess);
  const { search_value = '' } = queryParams || {};

  useEffect(() => {
    if (isAccess && !accessData.view) {
      customHistory.push(PATHS.ERROR_401);
    }
  }, [isAccess, accessData]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useMobileManagementSearch(setSearchValue);

  const finaliseTrigger = useSelector(selectFinaliseTrigger);

  const handleDetailsClick = (id: string) => {
    customHistory.push(View.MANAGE_ORGANISATIONS_DETAILS, {
      pathParams: { id, offset: 0 },
    });
  };

  return (
    <ManageLayout
      type={ManageTableType.CLIENTS_TABLE}
      title={languageStrings.navigationClients}
      handleAdd={handleAdd}
      accessData={accessData}
      searchValue={search_value}
      setSearchValue={setSearchValue}
      withSmartSearch={!tablet}
      withHistoryReplace
    >
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !tablet },
        }}
        getPath={constructPathFds}
        HttpService={HttpService}
        language={language.shortName}
        theme={theme}
        mainApiUrl={`${API_URL}/v2/clients/`}
        apiTriggers={[finaliseTrigger, language]}
        keyword={keyword}
        offset={+offset}
        offsetKey={offsetKey}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        pathParams={pathParams}
        queryParams={queryParams}
        tablePath={View.MANAGE_ORGANISATIONS}
        updateItems={updateClient}
        deleteItems={(id: string[], deleteUser: any, deleteCheckedUsers: any, handleAddNotification: Function) => {
          deleteClientGlobal(id, deleteUser, deleteCheckedUsers, handleAddNotification, languageStrings);
        }}
        allowDelete={accessData?.delete}
        actionSet={[{ type: ActionType.EDIT }]}
        // actionSectionGridWidth={'100px'}
        inlineActionSet={[]}
        accessData={accessData}
        columnStructureTriggers={[
          monitor2k,
          ultramonitor,
          wideMonitor,
          monitor,
          narrowMonitor,
          desktop,
          tablet,
          accessObj,
        ]}
        disableSupportRow={tablet}
        customRowClick={(data: any) => handleDetailsClick(data.id)}
        presetMapping={{
          component: {
            NUMBER_OF_DEVICES: (value: any, state: any, setValue?: any, ...rest: any) => {
              return <NumberOfDevices value={value} state={state} />;
            },
          },
          inline: {
            DETAILS: (value: any) => {
              const to = View.MANAGE_ORGANISATIONS_DETAILS;

              const href = stringifyUrl({
                url: injectPathParams(getPath(to), { id: value.id, offset: 0 }),
              });

              return <DetailsButton href={`${href}?access_token=${token}&expires=${exp}`} />;
            },
          },
          supportRow: {
            EXPORT: () => /*<ExportButton onClick={() => {}} />*/ null,
            ADD: () => <AddButton onClick={handleAdd} />,
          },
          textValue: {
            COUNTRY: ({ country }: { country: string }) => country,
            USERS: (value: number) => value,
          },
        }}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
            label: languageStrings.insightDetailsDeviceConfigurationEditInputsNameTitle,
            defaultValue: '',
            width: 'minmax(120px, 1.5fr)',
            type: RowItemType.INPUT,
          },
          {
            fieldName: 'profile_name',
            fullFieldName: 'profile_name',
            placeholder: '',
            label: '',
            defaultValue: '',
            width: 'minmax(120px, 1fr)',
            type: RowItemType.INPUT,
          },
          {
            fieldName: 'number_of_devices',
            placeholder: '',
            label: '',
            width: 'minmax(160px, 1fr)',
            defaultValue: {
              ready_to_ship: '0',
              in_process: '0',
              total: '0',
            },
            type: RowItemType.CUSTOM,
            component: { preset: 'NUMBER_OF_DEVICES' },
          },
          {
            fieldName: 'number_of_users',
            fullFieldName: 'number_of_users',
            placeholder: '',
            label: '',
            defaultValue: '',
            excludedFromView: desktop,
            width: '1fr',
            type: RowItemType.INPUT_NUMBER,
            getTextDisplay: { preset: 'USERS' },
          },
          {
            fieldName: 'parent_name',
            fullFieldName: 'parent_name',
            placeholder: '',
            label: '',
            defaultValue: '',
            excludedFromView: desktop,
            width: '1fr',
            type: RowItemType.INPUT,
          },
          {
            fieldName: 'address',
            fullFieldName: 'address.country',
            placeholder: '',
            label: '',
            defaultValue: '',
            excludedFromView: narrowMonitor,
            width: 'minmax(120px, 1fr)',
            type: RowItemType.INPUT,
            getTextDisplay: { preset: 'COUNTRY' },
          },
          {
            fieldName: 'address',
            placeholder: '',
            label: '',
            excludedFromView: wideMonitor,
            width: 'minmax(120px, 1fr)',
            defaultValue: {
              city: '',
              line1: '',
              zip: '',
              country: '',
              line2: '',
              geotag: { lat: '0', lng: '0' },
              region: '',
            },
            getTextDisplay: { preset: 'ADDRESS' },
          },
          {
            fieldName: 'email',
            placeholder: languageStrings.manageClientsTableRowPlaceholdersEmail,
            label: languageStrings.manageClientsTableHeaderItemsEmail,
            excludedFromView: ultramonitor,
            width: tablet ? '1fr' : monitor ? '2fr' : ultramonitor ? '2.5fr' : '2fr',
            defaultValue: '',
            type: RowItemType.INPUT_EMAIL,
          },
          {
            fieldName: 'phone',
            placeholder: languageStrings.manageClientsTableRowPlaceholdersPhone,
            label: languageStrings.manageClientsTableHeaderItemsPhone,
            defaultValue: '',
            excludedFromView: monitor2k,
            width: 'minmax(210px, 1fr)',
            type: RowItemType.INPUT_PHONE_NUMBER,
          },
        ]}
      />
      <CompanyCreationWizard />
    </ManageLayout>
  );
};

export const Organisations = withTableContextProvider(OrganisationPlain);
