import { Text, TextType } from '@bit/first-scope.text';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { useGetClients } from '../../../../../../../api/manage/manageClients';
import { updateDevices } from '../../../../../../../api/manage/manageDevices';
import { setDeviceInfoCustomer } from '../../../../../../../app/state/insight';
import { PlaceholderType } from '../../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { withLoader } from '../../../../../../../features/placeholderComponent/loaderFunctions';
import { RowItemType } from '../../../../../../../features/universalTable/types';
import { media } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../../../utils/date/date-format';
import { isEmptyObject } from '../../../../../../../utils/isEmptyObject';
import { TableBlockWrapper } from '../common/Common';
import {
  DeploymentDetailsInfoType,
  DeploymentDetailsModal,
} from './components/infoContentTable/components/deploymentDetailsModal/DeploymentDetailsModal';
import { InfoContentTable } from './components/infoContentTable/InfoContentTable';

const Wrapper = styled.div`
  min-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.desktop} {
    width: initial;
    margin-bottom: 0;
  }
`;

const TableWrapper = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(Wrapper);

export const DeviceInfo = ({
  data,
  deploymentDetailsinfoArr,
  isOpenAddressPopup,
  setIsOpenAddressPopup,
  accessData,
  languageStrings,
  handleQualityModal,
}: {
  data: any;
  deploymentDetailsinfoArr: DeploymentDetailsInfoType[];
  setIsOpenAddressPopup: (value: boolean) => void;
  isOpenAddressPopup: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  handleQualityModal: () => void;
  languageStrings: any;
}) => {
  const dispatch = useDispatch();
  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [savedCustomer, setSavedCustomer] = useState({ name: '', id: '' });
  const [isOpenDeploymentDetailsModal, setIsOpenDeploymentDetailsModal] = useState(false);
  const clientsList = useGetClients();

  const { addNotification } = useNotifierFunctions();

  const openDeploymentDetailsModal = () => {
    setIsOpenDeploymentDetailsModal(true);
  };

  const closeDeploymentDetailsModal = () => {
    setIsOpenDeploymentDetailsModal(false);
  };

  const handleCallbackNotification = (isSuccess: boolean, messageText?: string) => {
    const successMessage = isSuccess && languageStrings.successfullSavingMessage;
    const errorMessage = messageText ?? languageStrings.somethingWentWrong;
    addNotification({
      message: successMessage || errorMessage,
      type: isSuccess ? 'success' : 'error',
    });
  };

  const { id, devices, set_id_tooltip, client_name, created_at, set_id, name } = data;

  const arrNode = !isEmptyObject(data)
    ? [
        {
          property: languageStrings.measurementDeviceDetailsTableName,
          value: name || 'N/A',
          type: RowItemType.INPUT,
          disabled: true,
          infoTooltip: set_id_tooltip,
        },
        {
          property: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
          value: set_id || 'N/A',
          type: RowItemType.INPUT,
          disabled: true,
        },
        {
          property: languageStrings.summaryCompanyRowTitle,
          value: client_name ?? 'N/A',
          type: RowItemType.INPUT,
          disabled: true,
        },
        {
          property: languageStrings.locationPopupTitleDevices,
          value: devices[0]?.address.line1 ? devices[0]?.address.line1 + ', ' + devices[0]?.address.city : 'N/A',
          type: RowItemType.INPUT,
          disabled: true,
        },
        {
          property: languageStrings.creationDate,
          value: created_at ? getRequiredDateFormat(created_at, 'DD.MM.YYYY') : 'N/A',
          type: RowItemType.INPUT,
          disabled: true,
        },
      ]
    : [];

  const placeholder = {
    keyword: 'InsightDetailsDeviceInfoTable',
    style: { width: '100%', boxSizing: 'border-box' },
    placeholderOptions: { countRows: 4, withoutPagination: true },
  };

  const handleUpdateData = () => {
    updateDevices(id, data, handleCallbackNotification, languageStrings);
  };
  const handleRevertCustomer = () => {
    const { name, id } = savedCustomer;
    dispatch(setDeviceInfoCustomer({ name, id }));
  };

  return (
    <TableBlockWrapper>
      <Text type={TextType.SEMITITLE} margin={'0 0 10px 0'}>
        {languageStrings.info}
      </Text>
      <TableWrapper placeholderProps={placeholder}>
        <InfoContentTable
          arr={arrNode}
          handleUpdateData={handleUpdateData}
          handleRevertData={handleRevertCustomer}
          languageStrings={languageStrings}
          accessData={accessData}
        />
      </TableWrapper>
      {isOpenDeploymentDetailsModal && (
        <DeploymentDetailsModal
          isOpenModal={isOpenDeploymentDetailsModal}
          closeModal={closeDeploymentDetailsModal}
          infoArr={deploymentDetailsinfoArr}
        />
      )}
    </TableBlockWrapper>
  );
};
