import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const insightSlice = createSlice({
  name: 'insight',
  initialState,
  reducers: reducers,
});

export const {
  setRealTimeData,
  resetRealTimeData,
  setCommentsData,
  editRealTimeConfig,
  setDevices,
  setEvents,
  setHistory,
  setEventsGraph,
  updateRealTimeAddress,
  resetRealTimeAddress,
  setDeviceInfo,
  setDeviceInfoCustomer,
  setDeviceInfoAddress,
  setDeviceInfoName,
  setDeviceConfigItem,
  setDeviceConfigData,
  setDefaultGraphFilters,
  setDefaultEventsGraphFilters,
  setWaterLevelFilter,
  setTemperatureFilter,
  setEventsGraphFilter,
  setIsLoadingGraphsInProcess,
  setIsLoadingEventsGraphsInProcess,
  setTsFromGraph,
  setTsEventsGraph,
  setTsToGraph,
  revertFilters,
  revertFiltersFromEventsGraph,
} = insightSlice.actions;

export default insightSlice.reducer;
