import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: reducers,
});

export const {
  setUser,
  setUserType,
  updateUserSettings,
  setLanguage,
  setAccess,
  setUserExportModalState,
} = userSlice.actions;

export default userSlice.reducer;
