import { ThemeType } from '@cthings.co/styled-components';

import { ReferenceLine } from 'recharts';
import { ThresholdData } from './types';
import { thresholdColorMapping } from './utils';

export type ThreshlodLinesMappingType = {
  thresholdData: ThresholdData[];
  theme: ThemeType;
};

export const threshlodLinesMapping = ({ thresholdData, theme }: ThreshlodLinesMappingType) => {
  const { red2 } = theme.colors;

  const defaultStyle = {
    stroke: red2,
    strokeDasharray: '3 3',
    opacity: 0.6,
    strokeWidth: 1,
  };

  return thresholdData.map(({ threshold, style, color }, index) => {
    const prepStyle = { ...defaultStyle, ...thresholdColorMapping({ color, theme }), ...style };

    return <ReferenceLine key={index} y={threshold} style={prepStyle} />;
  });
};
