import React from 'react';
import { TableCustomComponentState } from '../../../../../../../../../features/universalTable/types';
import { EventView } from './components/eventView/EventView';
import { EventViewModal } from './components/eventViewModal/EventViewModal';

export const EventField = ({
  value,
  state,
  isInDevices,
}: {
  value: string;
  state?: TableCustomComponentState;
  isInDevices?: boolean;
}) => {
  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <EventView value={value} isInDevices={isInDevices} />
      ) : (
        <EventViewModal value={value} />
      )}
    </>
  );
};
