import React, { ChangeEvent } from 'react';
import styled from '@cthings.co/styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type TextareaProps = {
  disabled?: boolean;
  isFocused?: boolean;
  isError?: boolean;
  image?: any;
};

const Textarea = styled(TextareaAutosize)<TextareaProps>`
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;

  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  color: ${colorFetch('gray1')};
  ::placeholder {
    font-family: 'Poppins', sans-serif;
    color: ${colorFetch('gray2')};
  }
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid
    ${({ disabled, isFocused, isError, theme }) =>
      disabled
        ? colorFetch('gray5')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : isError
        ? colorFetch('red2')({ theme })
        : colorFetch('gray3')({ theme })};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: ${({ image }) => (image ? '8px 36px 8px 38px' : '8px 36px 8px 12px')};
  outline: none;
  resize: none;

  &.disabled {
    background-color: ${colorFetch('gray5')};
    &::placeholder {
      color: ${colorFetch('gray5')};
    }
  }
  -webkit-text-fill-color: ${colorFetch('gray2')};
  opacity: 1; /* required on iOS */
  ${media.tablet} {
    -webkit-appearance: caret;
    -moz-appearance: caret; /* mobile firefox too! */
    -webkit-text-fill-color: ${(props: any) => props.theme.colors.gray1};
  }
` as any; // @TODO fix type

export const TextareaModal = ({
  value,
  placeholder,
  onChange,
  onClick,
  onFocus,
  onBlur,
  isFocused,
  isError,
  disabled,
  isImage,
  className,
  rows,
}: {
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  onFocus: () => void;
  onBlur: () => void;
  isFocused?: boolean;
  isError?: boolean;
  disabled?: boolean;
  isImage?: boolean;
  className?: string;
  rows?: number;
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  return (
    <Textarea
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      isFocused={isFocused}
      isError={isError}
      disabled={disabled}
      isImage={isImage}
      className={disabled ? 'disabled' : className ? className : ''}
      theme={theme}
      color={black1}
      maxRows={rows}
    />
  );
};
