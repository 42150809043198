import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { MaintenanceTable } from '../maintenanceTable/MaintenanceTable';

const TempTableTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 20px 0;
`;

export const MaintananceTableBlock = ({
  languageStrings,
  pathParams,
  accessData,
}: {
  languageStrings: any;
  pathParams: any;
  accessData: any;
}) => {
  return (
    <>
      <TempTableTitle>
        <Text type={TextType.SEMITITLE} margin={'0 4px 0 0'}>
          {languageStrings.maintenanceTitle}
        </Text>
      </TempTableTitle>
      <MaintenanceTable pathParams={pathParams} accessData={accessData} />
    </>
  );
};
