export enum FileNameType {
  SPECIFICATION = 'nid_sm_spec',
  RELEASE_NOTE_1_1 = 'nid_release_note',
  RELEASE_NOTE_2_0 = 'nid_release_note_2_0',
  RELEASE_NOTE_2_4 = 'nid_release_note_2_4',
  RELEASE_NOTE_2_5 = 'nid_release_note_2_5',
  RELEASE_NOTE_2_6 = 'nid_release_note_2_6',
  DEVICE_PROVISIONING = 'nid_quickstart',
  END_USER_GUIDE = 'end_user_guide',
  TERMS_OF_SERVICE = 'terms_of_service',
  PRIVACY_POLICY = 'privacy_policy',
}
