import { Text, TextType } from '@bit/first-scope.text';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../app/state/user';
import { PlaceholderType } from '../../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { withLoader } from '../../../../../../../features/placeholderComponent/loaderFunctions';
import { NoDataPlaceholder } from '../../../../../../../features/universalTable/components/noDataPlaceholder/NoDataPlaceholder';
import { useLiteralNumbering } from '../../../../../../../utils/useLiteralNumbering';
import { TableBlockWrapper } from '../common/Common';
import { InfoContentTable } from './components/infoContentTable/InfoContentTable';

const Wrapper = styled.div`
  width: 100%;
`;

const TableWrapper = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(Wrapper);

const NoData = styled(NoDataPlaceholder)`
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: 0 0 1px rgb(40 41 61 / 4%), 0 2px 4px rgb(96 97 112 / 16%);
`;

const makeStructureRadioModules = (initialData: any, literalNumbering: any, languageStrings?: any) => {
  return initialData.map((item: any, i: any) => ({
    module: `${languageStrings.insightDetailsRadioModulesLabel} ${literalNumbering[i]}`,
    propertysRadioModule: [
      { property: languageStrings.radioModulesManufacturer, value: item?.manufacturer },
      { property: languageStrings.radioModulesTypeModel, value: item?.model },
      {
        property: languageStrings.radioModulesFirmwareRevision,
        value: item?.fm_version,
      },
      { property: 'IMEI', value: item?.imei },
      { property: 'ICCID', value: item?.iccid },
      { property: 'IMSI', value: item?.imsi },
    ],
    isShow: i === 0,
  }));
};

const placeholder = {
  keyword: 'InsightDetailsDeviceConfigurationTable',
  placeholderOptions: { withoutPagination: false },
  conditionalStyle: { overflow: 'hidden', borderRadius: '8px' },
};

export const RadioModules = ({ data = [] }: { data: any }) => {
  const [dataRadioModule, setDataRadioModule] = useState(data);
  const literalNumbering = useLiteralNumbering(1000);
  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    setDataRadioModule(data);
  }, [data]);

  const newStructureRadioModule = makeStructureRadioModules(dataRadioModule, literalNumbering, languageStrings);

  return (
    <TableBlockWrapper>
      <Text type={TextType.SEMITITLE} margin={'0 0 12px 0'}>
        {languageStrings.insightDetailsRadioModulesTitle}
      </Text>
      <TableWrapper placeholderProps={placeholder}>
        {data.length > 0 ? <InfoContentTable newStructureRadioModule={newStructureRadioModule} /> : <NoData />}
      </TableWrapper>
    </TableBlockWrapper>
  );
};
