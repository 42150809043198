import { Modal } from '@bit/first-scope.modal';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as CloseIcon } from '../../../../../../../../../assets/x.svg';
import { LsValueType } from '../../../../../../../../../enums/LsValueType';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import ss from '../../../../../../../../../utils/ss';
import { Arrows } from './components/arrows/Arrows';
import { Counter } from './components/counter/Counter';

const StyledModal = styled(Modal)`
  background-color: rgb(0, 0, 0, 0.9);
  & > div {
    width: 100vw;
    position: static;
  }
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  & > path {
    transition: all 0.3s linear;
  }

  &:hover {
    & > path {
      stroke: ${colorFetch('gray4')};
      transition: all 0.3s linear;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;

  object-fit: contain;
  user-select: none;
  max-width: calc(100% - 110px);
`;

export const PhotoViewer = ({
  isOpen,
  closeViewer,
  nextPhoto,
  previousPhoto,
  photosList,
  selectedIndex,
}: {
  isOpen: boolean;
  closeViewer: () => void;
  nextPhoto: () => void;
  previousPhoto: () => void;
  photosList: any[];
  selectedIndex: number;
}) => {
  const theme = useTheme();

  return (
    <StyledModal isOpenModal={isOpen}>
      <Counter currentIndex={selectedIndex + 1} generalCount={photosList.length} />
      <Arrows nextPhoto={nextPhoto} previousPhoto={previousPhoto} />
      <CloseButton onClick={closeViewer} theme={theme} />
      <ImageWrapper>
        <Image src={`${photosList[selectedIndex].link}?access_token=${ss.get(LsValueType.token)}`} />
      </ImageWrapper>
    </StyledModal>
  );
};
