import React from 'react';
import { UserType } from '../enums/UserType';
import { useSelector } from 'react-redux';
import { selectUserType } from '../app/state/user';
import { LoadingScreen } from '../features/loadingScreen/LoadingScreen';
import { useHistory } from '../utils/react-router-dom-abstraction';

const withUserType = (type: UserType) => {
  return (Component: any) => {
    function WrapperMain(props: any) {
      const globalUserType = useSelector(selectUserType);
      const history = useHistory();
      if (type === globalUserType) {
        return <Component {...props} />;
      } else {
        if (globalUserType !== UserType.NONE) history.push('/');
        return <LoadingScreen />;
      }
    }
    return WrapperMain;
  };
};

export default withUserType;
