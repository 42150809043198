import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styled from '@cthings.co/styled-components';
import { EditCommentType } from '../Comments';
import { useStyleContext } from '@cthings.co/styles-utils';
import { ReactComponent as EditImage } from '../../../assets/edit.svg';
import { ReactComponent as MenuIcon } from '../assets/menu.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/user';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as DeleteIcon } from '../../../assets/Delete_button.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  overflow: visible;
  position: relative;
  padding: 12px 0;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
  &.last {
    border: none;
    padding-bottom: 0;
  }
`;
const CommentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
const InformationBlock = styled.div``;
const Author = styled(Text)`
  padding-right: 10px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 1px;
    height: 70%;
    background-color: ${colorFetch('gray2')};
  }
`;
const CommentDate = styled(Text)`
  padding: 0 10px;
  position: relative;
  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 1px;
      height: 70%;
      background-color: ${colorFetch('gray2')};
    }
  }
`;
const Update = styled(Text)`
  padding-left: 10px;
`;
const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;
const MenuButton = styled.button`
  width: 15px;
  height: 15px;
  padding: 0;
  margin-top: 4px;
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
`;

type MenuProps = {
  modal: boolean;
};

const Menu = styled(MenuIcon)<MenuProps>`
  & > path {
    transition: all 0.3s linear;
    fill: ${({ modal }) => (modal ? colorFetch('black1') : colorFetch('gray2'))};
  }
`;
const Modal = styled.div`
  background-color: ${colorFetch('white')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow4};
  position: absolute;
  top: calc(100% + 4px);
  right: 50%;
  z-index: 5;
  transform: translateX(6px);
  width: 128px;
`;
const EditButton = styled.button`
  background-color: transparent;
  border: none;
  outline: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray4')};
  padding: 5px 10px;
  & > svg > path {
    stroke: ${colorFetch('black1')};
  }
`;
const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 10px;
  & > svg > path {
    stroke: ${colorFetch('red2')};
  }
`;

type StyledEditImageProps = {
  color?: string;
  colorHover?: string;
};

const StyledEditImage = styled(EditImage)<StyledEditImageProps>`
  cursor: pointer;
  margin-right: 16px;
  & > path {
    stroke: ${({ color, theme }) => (color ? color : colorFetch('gray3')({ theme }))};
    transition: stroke 0.3s ease;
  }
  &:hover > path {
    stroke: ${({ colorHover, theme }) => (colorHover ? colorHover : colorFetch('gray2')({ theme }))};
  }
`;

type StyledDeleteIconProps = {
  color?: string;
  colorHover?: string;
};

const StyledDeleteIcon = styled(DeleteIcon)<StyledDeleteIconProps>`
  cursor: pointer;
  & > path {
    stroke: ${({ color, theme }) => (color ? color : colorFetch('gray3')({ theme }))};
    transition: stroke 0.3s ease;
  }
  &:hover > path {
    stroke: ${({ colorHover, theme }) => (colorHover ? colorHover : colorFetch('gray2')({ theme }))};
  }
`;

export interface CommentItemProps {
  id: number;
  textComment: string;
  dateCom: string;
  author: string;
  updateDate: string;
  onEdit?: any;
  onDelete?: any;
  isEdit: boolean;
  edit_comment: EditCommentType;
  permissions: any;
  setEdit_Comment: (el: EditCommentType) => void;
  className?: string;
}

export const CommentItem: FC<CommentItemProps> = ({
  id,
  textComment,
  dateCom,
  author,
  updateDate,
  onEdit,
  isEdit,
  onDelete,
  edit_comment,
  permissions,
  setEdit_Comment,
  ...props
}) => {
  const theme = useTheme();
  const { black1, primary, gray1, gray2, red2 } = theme.colors;
  const [media] = useStyleContext();
  const [modal, setModal] = useState(false);
  const buttonRef = useRef<any>(null);
  const modalRef = useRef<any>(null);
  const [date, setDate] = useState<string>('');
  const [edited, setEdited] = useState<string>('');
  const languageStrings = useSelector(selectLanguageStrings);

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (
        modal &&
        !(
          (buttonRef && buttonRef.current && buttonRef.current.contains(event.target)) ||
          (modalRef.current && modalRef.current.contains(event.target))
        )
      ) {
        setModal(false);
      }
    },
    [modal],
  );
  const handleEditComment = () => {
    !isEdit && onEdit(true);
    setEdit_Comment({
      valueComment: textComment,
      editComment: textComment,
      id: id,
    });
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  useEffect(() => {
    const today = new Date();
    const todayDate = moment(`${today}`).format('D.MM.yyyy');

    const formatDate = moment(`${dateCom}`).format('D.MM.yyyy, HH:mm');
    const formatDateDay = moment(`${dateCom}`).format('D.MM.yyyy');
    const formatDateTime = moment(`${dateCom}`).format('HH:mm');

    const previousTodayDate = moment(`${today}`).subtract(1, 'days').format('D.MM.yyyy');

    const formatUpdateDate = moment(`${updateDate}`).format('D.MM.yyyy, HH:mm');
    const formatUpdateDateDay = moment(`${updateDate}`).format('D.MM.yyyy');
    const formatUpdateDateTime = moment(`${updateDate}`).format('HH:mm');

    if (todayDate === formatDateDay) {
      setDate(languageStrings.commentTableDayToday + formatDateTime);
    } else {
      setDate(formatDate);
    }

    if (todayDate === formatUpdateDateDay) {
      setEdited(languageStrings.commentTableDayToday + formatUpdateDateTime);
    } else {
      setEdited(formatUpdateDate);
    }

    if (previousTodayDate === formatDateDay) {
      setDate(languageStrings.commentTableDayYesterday + formatDateTime);
    }
    if (previousTodayDate === formatUpdateDateDay) {
      setEdited(languageStrings.commentTableDayYesterday + formatUpdateDateTime);
    }
  });

  return (
    <Content theme={theme} {...props}>
      <CommentBlock>
        <Text type={TextType.TEXT_14_BLACK} color={edit_comment.id === id ? primary : gray1} weight="normal">
          {textComment}
        </Text>
        <InformationBlock>
          <Author type={TextType.TEXT_12_BLACK} color={edit_comment.id === id ? primary : gray2} lineHeight="24px">
            {author}
          </Author>
          <CommentDate
            type={TextType.TEXT_12_BLACK}
            color={edit_comment.id === id ? primary : gray2}
            lineHeight="24px"
            weight="400"
          >
            {date}
          </CommentDate>
          {date !== edited && (
            <Update
              type={TextType.TEXT_12_BLACK}
              color={edit_comment.id === id ? primary : gray2}
              lineHeight="24px"
              weight="400"
            >
              {languageStrings.commentTableEditLabel} {edited}
            </Update>
          )}
        </InformationBlock>
      </CommentBlock>
      {media.mediaType.tablet ? (
        <>
          {(permissions.edit || permissions.delete) && (
            <MenuButton>
              <Menu ref={buttonRef} onClick={() => setModal((el) => !el)} modal={modal} />
              {modal && (
                <Modal ref={modalRef} theme={theme}>
                  {permissions.edit && (
                    <EditButton
                      onClick={() => {
                        handleEditComment();
                        setModal(false);
                      }}
                      theme={theme}
                    >
                      <EditImage />
                      <Text type={TextType.TEXT_14_BLACK} color={black1} margin={'0 0 0 4px'}>
                        {languageStrings.insightDetailsDeviceConfigHeaderEdit}
                      </Text>
                    </EditButton>
                  )}
                  {permissions.delete && (
                    <DeleteButton
                      onClick={() => {
                        onDelete(id);
                        setModal(false);
                      }}
                      theme={theme}
                    >
                      <DeleteIcon />
                      <Text type={TextType.TEXT_14_BLACK} color={red2} margin={'0 0 0 4px'}>
                        {languageStrings.manageClientsTableHeaderDeleteButton}
                      </Text>
                    </DeleteButton>
                  )}
                </Modal>
              )}
            </MenuButton>
          )}
        </>
      ) : (
        <ButtonsBlock>
          {permissions.edit && edit_comment.id !== id && <StyledEditImage onClick={handleEditComment} theme={theme} />}
          {permissions.delete && <StyledDeleteIcon onClick={() => onDelete(id)} theme={theme} />}
        </ButtonsBlock>
      )}
    </Content>
  );
};

CommentItem.defaultProps = {};
