import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ReactComponent as Buildings } from '../../../assets/Buildings.svg';
import { ReactComponent as Device } from '../../../assets/Device.svg';
import { ReactComponent as User } from '../../../assets/User.svg';
import { AddCompanyCardType } from '../../../types';

type WrapperProps = {
  type: AddCompanyCardType;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ type }) => (type === AddCompanyCardType.COMPANY_CARD ? '' : '95px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Plus = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${colorFetch('secondary3')};
`;

export interface AddCompanyCardProps {
  type: AddCompanyCardType;
}

export const AddCompanyIcons: FC<AddCompanyCardProps> = ({ type, ...props }) => {
  return (
    <Wrapper type={type}>
      {type === AddCompanyCardType.FULLY_OPERATIONAL_CARD ? (
        <>
          <Buildings />
          <Plus>+</Plus>
          <User />
          <Plus>+</Plus>
          <Device />
        </>
      ) : (
        <Buildings />
      )}
    </Wrapper>
  );
};
