import { en_gb } from './locales/en_gb';
import { pl_pl } from './locales/pl_pl';
import { de_de } from './locales/de_de';
import { sv_se } from './locales/sv_se';
import { da_dk } from './locales/da_dk';

export const lang = {
  en_gb,
  pl_pl,
  de_de,
  sv_se,
  da_dk,
};
