import { RootState } from '../../../app/store';
import { EMPTY_USER, USER_MODIFICATION_MODE_MAPPING } from './consts';
import { getFormattedActions } from './utils';

const getUniqueDevices = (devices: any[]) => {
  return devices.reduce((acc: any[], el: any) => {
    const index = acc.findIndex((accEl: any) => accEl.id === el.id);
    return index !== -1 ? [...acc.slice(0, index), el, ...acc.slice(index + 1)] : [...acc, el];
  }, []);
};

export const selectCompanyCreationHistory = (state: RootState) => {
  const { treeHistory } = state.clientsTable;

  return treeHistory;
};

export const selectActions = (state: RootState) => {
  const { treeHistory } = state.clientsTable;
  return getFormattedActions(treeHistory, !!state.clientsTable.isDeviceMode);
};

export const selectCurrentLocation = (state: RootState) => {
  const { treeHistory } = state.clientsTable;

  return treeHistory[treeHistory.length - 1];
};

export const selectParentOrganisation = (state: RootState) => {
  const { parentOrganisation } = state.clientsTable;

  return parentOrganisation;
};

export const selectProfiles = (state: RootState) => {
  const { profilesList } = state.clientsTable;

  return profilesList;
};

export const selectOrganisationUsers = (state: RootState) => {
  const { users } = state.clientsTable;

  return users;
};

export const selectIsDeviceMode = (state: RootState) => {
  const { isDeviceMode } = state.clientsTable;

  return isDeviceMode;
};

export const selectOrganisationSelectedDevices = (state: RootState) => {
  const { devices } = state.clientsTable;

  return devices.filter((device) => device.isSelected);
};

export const selectUserInEdit = (state: RootState) => {
  const { userInEdit } = state.clientsTable;

  return userInEdit;
};

export const selectUserModificationMode = (state: RootState) => {
  const { userInEdit } = state.clientsTable;

  // @NOTE '+' operator will convert true to index of 1, and false to index of 0
  return USER_MODIFICATION_MODE_MAPPING[+(userInEdit.id === EMPTY_USER.id)];
};

export const selectSelectedCount = (state: RootState) => {
  const { devices, uploadedDevices } = state.clientsTable;

  return getUniqueDevices([...devices, ...uploadedDevices]).filter((device) => device.isSelected).length;
};

export const selectSelectedField = (state: RootState) => {
  return state.clientsTable.isSelectedField;
};

export const selectSelectedDevices = (state: RootState) => {
  const { devices, uploadedDevices } = state.clientsTable;

  return getUniqueDevices([...devices, ...uploadedDevices]).filter((device) => device.isSelected);
};

export const selectParentOrganisationsList = (state: RootState) => {
  return state.clientsTable.parentOrganisationsList;
};

export const selectAllDevices = (state: RootState) => {
  const { devices, uploadedDevices } = state.clientsTable;

  return getUniqueDevices([...devices, ...uploadedDevices]);
};

export const selectFetchedDevices = (state: RootState) => {
  const { devices } = state.clientsTable;

  return devices;
};

export const selectCreationDevices = (state: RootState) => {
  const { devices, uploadedDevices, deviceSelectionMode } = state.clientsTable;

  const isSelectedMode = deviceSelectionMode === 'selected';

  return getUniqueDevices([...devices, ...uploadedDevices]).filter((device) => !isSelectedMode || device.isSelected);
};

export const selectFromTo = (state: RootState) => {
  const { fromDevice, toDevice } = state.clientsTable;

  return {
    fromDevice,
    toDevice,
    query: fromDevice && toDevice ? { tid_from: fromDevice?.transport_id, tid_to: toDevice?.transport_id } : null,
  };
};

export const selectDeviceSelectionMode = (state: RootState) => {
  const { deviceSelectionMode } = state.clientsTable;

  return deviceSelectionMode;
};

export const selectUploadedDevicesCounts = (state: RootState) => {
  const { uploadedDevices, failedDevicesCount } = state.clientsTable;

  return { successfullDevices: uploadedDevices.length, failedDevices: failedDevicesCount };
};

export const selectFinaliseTrigger = (state: RootState) => {
  const { finaliseTrigger } = state.clientsTable;

  return { finaliseTrigger };
};
