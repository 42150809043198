import React, { SyntheticEvent, useState } from 'react';
import { FC } from 'react';
import {
  TitleText,
  HeaderStepNumberWrapper,
  SampleButton,
  ButtonWrapper,
  StepNumber,
  BackButton,
  SkipButton,
  NextButton,
  StyledUpladText,
  Label,
  UploadInput,
  SampleIcon,
  UpladTextWrapper,
  InfoButton,
  TryAgainText,
  SampleButtonWrapper,
  StyledCloseIcon,
  Block,
} from './styled';
import { TextType } from '@bit/first-scope.text';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../app/state/user';
// @ts-ignore
//import { setTimeout as setCustomTimeout } from 'timers-promises';
import { useNotifierFunctions } from '../../../notifier2';
import {
  pushAction,
  selectUploadedDevicesCounts,
  setSelectedField,
  selectIsDeviceMode,
  selectParentOrganisation,
} from '../../../../app/state/organisations';
import { CustomModal } from '../customModal/CustomModal';
import {
  getDownloadSampleExcelFileUrl,
  uploadDeviceExcel,
  uploadSpecificDeviceExcel,
} from '../../../../api/organisation';
import { FailureReasonsModal } from '../failureReasonsModal/FailureReasonsModal';
import { useParams } from '@cthings.co/router-dom';
import { useTheme } from '@cthings.co/styled-components';
import { CloseButton } from '../closeButton/CloseButton';

interface UploadStateType {
  selectedFile: File;
  isUploadingFile: boolean;
  isFilePicked: boolean;
  isIncorrectTypeOfFile: boolean;
  isError: boolean;
}

export interface UploadDevicesModalProps {
  isOpen: boolean;
}

export const UploadDevicesModal: FC<UploadDevicesModalProps> = ({ isOpen }) => {
  const { id: client_id } = useParams();
  const { profile } = useSelector(selectParentOrganisation);
  const [isOpenFailureReasonsModal, setIsOpenFailureReasonsModal] = useState(false);
  const isDeviceMode = useSelector(selectIsDeviceMode);
  const stepNumber = '3/3';
  const allowedFileFormats = ['csv', 'xlsx', 'xls'];
  const acceptTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
  ];

  const language = useSelector(selectLanguage);
  const downloadSampleExcelFileUrl = getDownloadSampleExcelFileUrl(language.id);

  const onInfoButtonClick = () => {
    setIsOpenFailureReasonsModal(true);
  };

  const onGotItButtonClick = () => {
    setIsOpenFailureReasonsModal(false);
  };

  // backend test alternative
  /*const handleUpload = async (file: File) => {
    return await setCustomTimeout(300, {
      successfullDevices: 1,
      failedDevices: 10,
    });
  };*/

  const dispatch = useDispatch();

  const theme = useTheme();
  const { black1, green2, gray1, gray2, pureWhite, primary, secondary2, red1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const { addNotification } = useNotifierFunctions();

  const { successfullDevices, failedDevices } = useSelector(selectUploadedDevicesCounts);

  const [uploadState, setUploadState] = useState<UploadStateType>({
    selectedFile: new File([''], ''),
    isUploadingFile: false,
    isFilePicked: false,
    isIncorrectTypeOfFile: false,
    isError: false,
  });

  const { isUploadingFile, isFilePicked, isIncorrectTypeOfFile, isError } = uploadState;

  const handleDragOver = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFile = ({ e, selectedFile }: { e: SyntheticEvent; selectedFile: File }) => {
    e.preventDefault();
    e.stopPropagation();

    setUploadState((uploadState) => ({
      ...uploadState,
      isUploadingFile: true,
    }));
    const splittedFileName = selectedFile.name.split('.');
    const formatOfFile = splittedFileName[splittedFileName.length - 1];

    const fileIsChosen = !!(selectedFile && selectedFile.name && selectedFile.name.length > 0);
    if (fileIsChosen) {
      if (allowedFileFormats.includes(formatOfFile)) {
        setUploadState((uploadState) => ({
          ...uploadState,
          selectedFile: selectedFile,
          isIncorrectTypeOfFile: false,
          isFilePicked: true,
          isUploadingFile: true,
        }));
        handleSubmitFiles(selectedFile);
      } else {
        setUploadState((uploadState) => ({
          ...uploadState,
          selectedFile: new File([''], ''),
          isIncorrectTypeOfFile: true,
          isFilePicked: false,
          isUploadingFile: false,
        }));
        addNotification({
          message: languageStrings.wrongFileFormat,
          type: 'error',
        });
      }
    } else {
      addNotification({
        message: languageStrings.unexpectedErrorHasOccurred,
        type: 'error',
      });
      setUploadState((uploadState) => ({ ...uploadState, isUploadingFile: false }));
    }
  };

  const handleChangeUploadInput = (e: any) => {
    const selectedFile = e.target.files[0];
    handleFile({ e, selectedFile });
  };

  const handleDrop = (e: any) => {
    const selectedFile = e.dataTransfer.files[0];

    handleFile({ e, selectedFile });
  };

  const handleSubmitFiles = async (selectedFile: any) => {
    client_id
      ? uploadSpecificDeviceExcel(client_id, selectedFile, () =>
          setUploadState((uploadState) => ({
            ...uploadState,
            isUploadingFile: false,
          })),
        )
      : uploadDeviceExcel(
          selectedFile,
          () =>
            setUploadState((uploadState) => ({
              ...uploadState,
              isUploadingFile: false,
            })),
          profile.id,
        );
  };

  const handleNext = () => {
    dispatch(pushAction('addManually'));
    dispatch(setSelectedField(true));
  };
  const handleSkip = () => {
    dispatch(pushAction('addManually'));
  };
  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  const onBackClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (!isUploadingFile && !isFilePicked) {
    } else {
      setUploadState((uploadState) => ({
        ...uploadState,
        selectedFile: new File([''], ''),
        isFilePicked: false,
        isIncorrectTypeOfFile: false,
        isUploadingFile: false,
      }));
    }
    handleBack();
  };

  const onTryAgainClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadState((uploadState) => ({
      ...uploadState,
      selectedFile: new File([''], ''),
      isFilePicked: false,
      isIncorrectTypeOfFile: false,
      isUploadingFile: false,
      successfullDevices: 0,
      failedDevices: 0,
    }));
  };

  return (
    <>
      <CustomModal isOpen={isOpen}>
        <HeaderStepNumberWrapper>
          <TitleText type={TextType.TEXT_12_BLACK} color={black1}>
            {languageStrings.uploadDevicesTitle}
          </TitleText>
          <Block>
            <StepNumber type={TextType.TEXT_12_BLACK} color={gray2}>
              {stepNumber}
            </StepNumber>
            <CloseButton />
          </Block>
        </HeaderStepNumberWrapper>
        <Label
          for={'files'}
          isFilePicked={isFilePicked}
          theme={theme}
          isUploadingFile={isUploadingFile}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          isIncorrectTypeOfFile={isIncorrectTypeOfFile}
          isError={isError}
        >
          {!isUploadingFile && isFilePicked ? (
            <>
              {successfullDevices >= 1 && (
                <UpladTextWrapper>
                  <StyledUpladText
                    color={gray1}
                    theme={theme}
                    type={TextType.TEXT_14_BLACK}
                    weight={'500'}
                    margin={'0 4px 0 0'}
                  >
                    {languageStrings.uploadDevicesModalSuccessfullyUploadedDevices}
                  </StyledUpladText>
                  <StyledUpladText color={green2} theme={theme} type={TextType.TEXT_14_BLACK} weight={'500'}>
                    {successfullDevices}
                  </StyledUpladText>
                </UpladTextWrapper>
              )}
              {failedDevices === 0 && (
                <TryAgainText
                  onClick={onTryAgainClick}
                  color={gray2}
                  underline={'underline'}
                  theme={theme}
                  type={TextType.TEXT_14_BLACK}
                  weight={'400'}
                >
                  {languageStrings.uploadDevicesModalTryAgain}
                </TryAgainText>
              )}
              {failedDevices >= 1 && (
                <>
                  <UpladTextWrapper margin={'2px'}>
                    <StyledUpladText
                      color={gray1}
                      theme={theme}
                      type={TextType.TEXT_14_BLACK}
                      weight={'500'}
                      margin={'0 4px 0 0'}
                    >
                      {languageStrings.uploadDevicesModalFailedUploads}
                    </StyledUpladText>
                    <StyledUpladText color={red1} theme={theme} type={TextType.TEXT_14_BLACK} weight={'500'}>
                      {failedDevices}
                    </StyledUpladText>
                    <InfoButton onClick={onInfoButtonClick} $hoverColor={gray2} />
                  </UpladTextWrapper>
                  <TryAgainText
                    onClick={onTryAgainClick}
                    color={gray2}
                    theme={theme}
                    type={TextType.TEXT_14_BLACK}
                    weight={'400'}
                  >
                    {languageStrings.uploadDevicesModalTryAgain}
                  </TryAgainText>
                </>
              )}
            </>
          ) : isUploadingFile ? (
            <StyledUpladText color={green2} theme={theme} type={TextType.TEXT_14_BLACK} weight={'500'}>
              {languageStrings.uploadDevicesModalUploadingFile}
            </StyledUpladText>
          ) : (
            <UpladTextWrapper>
              <StyledUpladText
                color={primary}
                theme={theme}
                type={TextType.TEXT_14_BLACK}
                weight={'500'}
                margin={'0 4px 0 0'}
              >
                {languageStrings.uploadDevicesInputTextFirstPart}
              </StyledUpladText>
              <StyledUpladText className={'desktopText'} theme={theme} type={TextType.TEXT_14_BLACK} weight={'400'}>
                {languageStrings.uploadDevicesInputTextSecondPart}
              </StyledUpladText>
              <StyledUpladText className={'mobileText'} theme={theme} type={TextType.TEXT_14_BLACK} weight={'400'}>
                {languageStrings.uploadDevicesInputTextSecondPartMobile}
              </StyledUpladText>
            </UpladTextWrapper>
          )}
        </Label>
        {!isUploadingFile && !isFilePicked && (
          <UploadInput
            theme={theme}
            type={'file'}
            // onChange={(e: any) => handleChangeUploadInput(e)}
            onChange={handleChangeUploadInput}
            accept={acceptTypes.toString()}
            id={'files'}
          />
        )}
        <ButtonWrapper>
          <SampleButtonWrapper href={downloadSampleExcelFileUrl} target={'_blank'} rel={'noopener noreferer'}>
            <SampleButton
              colors={{
                main: black1,
                background: pureWhite,
                backgroundHover: primary,
                borderHover: primary,
              }}
            >
              <SampleIcon />
              {languageStrings.sampleButton}
            </SampleButton>
          </SampleButtonWrapper>
          <BackButton
            onClick={onBackClick}
            colors={{
              main: black1,
              background: pureWhite,
              backgroundHover: primary,
              borderHover: primary,
            }}
          >
            {languageStrings.backButton}
          </BackButton>
          <SkipButton
            onClick={handleSkip}
            colors={{
              main: black1,
              background: pureWhite,
              backgroundHover: primary,
              borderHover: primary,
            }}
            isDisabled={isUploadingFile}
          >
            {languageStrings.skipButton}
          </SkipButton>
          <NextButton
            onClick={handleNext}
            colors={{
              main: pureWhite,
              background: primary,
              backgroundHover: secondary2,
              borderHover: primary,
            }}
            isDisabled={!isFilePicked || isIncorrectTypeOfFile}
          >
            {languageStrings.nextButton}
          </NextButton>
        </ButtonWrapper>
      </CustomModal>
      <FailureReasonsModal isOpenModal={isOpenFailureReasonsModal} onGotItButtonClick={onGotItButtonClick} />
    </>
  );
};
