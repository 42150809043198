import React from 'react';

export const LocationImage = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.625 8.75C15.625 13.125 10 16.875 10 16.875C10 16.875 4.375 13.125 4.375 8.75C4.375 7.25816 4.96763 5.82742 6.02252 4.77252C7.07742 3.71763 8.50816 3.125 10 3.125C11.4918 3.125 12.9226 3.71763 13.9775 4.77252C15.0324 5.82742 15.625 7.25816 15.625 8.75Z"
        stroke="#555770"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 10.625C11.0355 10.625 11.875 9.78553 11.875 8.75C11.875 7.71447 11.0355 6.875 10 6.875C8.96447 6.875 8.125 7.71447 8.125 8.75C8.125 9.78553 8.96447 10.625 10 10.625Z"
        stroke="#555770"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
