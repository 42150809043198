import { Text, TextType } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import React, { useState } from 'react';
import { CommonButton } from '@cthings.co/buttons';
import AutosizeInput from 'react-input-autosize';
import { media } from '@cthings.co/styles-utils';
import { TableInputType } from '../../TableInput';
import { InputWrapper } from '../inputWrapper/InputWrapper';
import { CheckBox } from '../../../controls';
import { useEffect } from 'react';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const StyledInputWrapper = styled(InputWrapper)`
  flex-direction: row;
  align-items: center;
  ${media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ExtraWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  box-sizing: border-box;
  ${media.tablet} {
    display: grid;
    grid-template-columns: 1.25fr 2fr 1.25fr;
  }
  ${media.phone} {
    height: 40px;
    width: 100%;
    grid-template-columns: 80px 1fr 80px;
  }
`;

const StepperWrapper = styled.div`
  height: 100%;
  min-width: 84px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${colorFetch('gray3')};
  ${media.tablet} {
    min-width: auto;
    padding: 0 8px;
    display: flex;
    justify-content: center;
  }
`;

type StyledInputProps = {
  color: string;
};

const StyledInput = styled(AutosizeInput)<StyledInputProps>`
  ${media.phone} {
    width: auto;
    text-align: center;
  }
  & > input {
    border-color: transparent;
    background-color: transparent;
    outline: transparent;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: ${({ color }) => color};
    position: relative;
    transition: color 0.3s ease;
  }
  & > input:focus {
    outline: transparent;
  }
`;

const StyledButtton = styled(CommonButton)`
  width: 48px;
  height: 100%;
  background-color: ${colorFetch('gray6')};
  border-color: transparent;
  color: ${colorFetch('gray1')};
  &:hover {
    background-color: ${colorFetch('gray4')};
    border: transparent;
    color: ${colorFetch('gray1')};
  }
  &.increment {
    border-radius: 8px 0 0 8px;
  }
  &.decrement {
    border-radius: 0 8px 8px 0;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  ${media.tablet} {
    width: 100%;
  }
`;

const StyledText = styled(Text)`
  word-break: break-all;
`;

const CheckBoxWrapper = styled.div`
  margin: 0 12px 0;
  ${media.phone} {
    margin: 10px 0 0;
  }
`;

export interface StepperOptionsType {
  step?: number;
  min?: number;
  max?: number;
  unit?: string;
  title?: string;
}

export const Stepper = ({
  type,
  value,
  handleChangeValue,
  isEditInProcess,
  options,
  ...props
}: {
  type: TableInputType;
  value: any;
  handleChangeValue: Function;
  isEditInProcess?: boolean;
  options: StepperOptionsType;
}) => {
  const theme = useTheme();
  const { gray1, gray3 } = theme.colors;
  const { step = 1, min = 0, max = 1000000, unit, title } = options;
  const symbAfterDot = +`${step}`[0] === 0 ? `${step}`.slice(2).length : 0;
  const [checkbox, setCheckbox] = useState(!isNaN(value) ? false : true);

  const onClickDecrementHandle = () => {
    if (value > min) {
      handleChangeValue(+(value - step).toFixed(symbAfterDot));
    } else {
      handleChangeValue(min);
    }
  };
  const onClickIncrementHandle = () => {
    if (value < max && value >= min) {
      handleChangeValue(+(value + step).toFixed(symbAfterDot));
    } else if (value < min) {
      handleChangeValue(min);
    } else {
      handleChangeValue(max);
    }
  };
  const handleOnChange = (e: any) => {
    const currentValue = e.currentTarget.value;
    !isNaN(+currentValue) && `${currentValue}`.length < 6 && handleChangeValue(+currentValue);
  };
  const handleOnBlur = () => {
    if (value < min) {
      handleChangeValue(min);
    }
    if (value > max) {
      handleChangeValue(max);
    }
  };
  const onCheckboxPress = () => {
    setCheckbox((current) => !current);
    checkbox ? handleChangeValue(min) : handleChangeValue(null);
  };

  useEffect(() => {
    !isNaN(value) && setCheckbox(false);
    value === null && setCheckbox(true);
    value === 'N/A' && setCheckbox(true);
  }, [value, isEditInProcess]);

  return (
    <StyledInputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      {isEditInProcess ? (
        <>
          <ExtraWrapper>
            <StyledButtton
              className={checkbox ? 'increment disabled' : value === min ? 'increment disabled' : 'increment'}
              onClick={onClickDecrementHandle}
            >
              -
            </StyledButtton>
            <StepperWrapper>
              <StyledInput
                value={value === null ? 'N/A' : value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                disabled={checkbox}
                color={checkbox ? gray3 : gray1}
              />
              {type === TableInputType.STEPPER_WITH_UNIT && (
                <Text type={TextType.TEXT_14_GRAY} color={checkbox ? gray3 : gray1} fontSize={'14px'}>
                  {value !== null && unit}
                </Text>
              )}
            </StepperWrapper>
            <StyledButtton
              className={checkbox ? 'decrement disabled' : value === max ? 'decrement disabled' : 'decrement'}
              onClick={onClickIncrementHandle}
            >
              +
            </StyledButtton>
          </ExtraWrapper>
          <CheckBoxWrapper>
            <CheckBox
              color={gray1}
              fontSize={'14px'}
              toggle={checkbox}
              label={'Depth is unknown'}
              handleToggle={onCheckboxPress}
            />
          </CheckBoxWrapper>
        </>
      ) : (
        <StyledText type={TextType.TEXT_14_GRAY} color={gray1}>
          {`${value} ${value === 'N/A' ? '' : unit}`}
        </StyledText>
      )}
    </StyledInputWrapper>
  );
};
