import React, { useRef } from 'react';
import styled from '@cthings.co/styled-components';
import { InputWrapper } from '../inputWrapper/InputWrapper';
import { media } from '@cthings.co/styles-utils';
import { DatePickerWithIcon, DatePickerWithIconOptions } from '../datePickerWithIcon/DatePickerWithIcon';
import { Text, TextType } from '@bit/first-scope.text';
import { useWindowSize } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  title: any; // @TODO fix type
  screenWidth: number;
  wrapperWidth?: number;
  isEditInProcess?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ title }) => (title ? '100%' : 'max-content')};
  display: flex;
  flex-direction: ${({ screenWidth, wrapperWidth, isEditInProcess }) =>
    screenWidth > 480 ? (wrapperWidth && wrapperWidth < 308 && isEditInProcess ? 'column' : 'row') : 'row'};
  align-items: center;

  ${media.phone} {
    width: ${({ isEditInProcess }: any) => (isEditInProcess ? '100%' : 'max-content')};
    flex-direction: ${({ isEditInProcess }: any) => (isEditInProcess ? 'row' : 'column')};
    margin: 0;
  }

  ${media.phoneXS} {
    width: 100%;
    flex-direction: column;
  }
`;

type StyledDatePickerWithIconProps = {
  title: any; // @TODO fix type
  isEndDate: boolean;
  wrapperWidth?: number;
  isEditInProcess?: boolean;
  bottom: any; // @TODO fix type
};

const StyledDatePickerWithIcon = styled(DatePickerWithIcon)<StyledDatePickerWithIconProps>`
  width: ${({ title }) => (title ? '100%' : 'max-content')};
  margin: 0;
  & > span {
    display: ${({ isEditInProcess }) => (isEditInProcess ? 'none' : 'flex')};
  }

  & > div > div > div > div > div {
    right: ${({ isEndDate, wrapperWidth }) => (isEndDate && !(wrapperWidth && wrapperWidth < 308) ? '190px' : '0')};
    bottom: ${({ bottom }) => bottom || '0'};
  }
` as any; // @TODO fix type

const Line = styled(Text)``;

export interface DateRangeWithIconOptions {
  title?: string;
  startOptions: DatePickerWithIconOptions;
  endOptions: DatePickerWithIconOptions;
}

export const DateRangeWithIcon = ({
  value,
  handleChangeValue,
  isEditInProcess,
  options,
  ...props
}: {
  value: any;
  handleChangeValue: any;
  options: DateRangeWithIconOptions | any;
  title?: string;
  isEditInProcess?: boolean;

  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperWidth = wrapperRef?.current?.clientWidth;
  const screenWidth = useWindowSize()[0];

  const { startDate, endDate } = value;
  const { setStartDate, setEndDate } = handleChangeValue;
  const { title, startOptions, endOptions } = options;

  return (
    <InputWrapper title={title} wrapperRef={wrapperRef} isEditInProcess={isEditInProcess} {...props}>
      <Wrapper title={title} wrapperWidth={wrapperWidth} isEditInProcess={isEditInProcess} screenWidth={screenWidth}>
        <StyledDatePickerWithIcon
          globalValue={startDate}
          handleChangeValue={setStartDate}
          isEditInProcess={isEditInProcess}
          options={{ ...startOptions, title }}
          bottom={options.bottom}
        />
        <Line type={TextType.TEXT_14_BLACK} color={gray1} margin={'0 4px'}>
          {'-'}
        </Line>
        <StyledDatePickerWithIcon
          globalValue={endDate}
          handleChangeValue={setEndDate}
          isEditInProcess={isEditInProcess}
          options={{ ...endOptions, title }}
          wrapperWidth={wrapperWidth}
          bottom={options.bottom}
          isEndDate
        />
      </Wrapper>
    </InputWrapper>
  );
};
