import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { TableCustomComponentState } from '../../../../../../../../../features/universalTable/types';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  inModal?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ inModal }) => (inModal ? '100%' : 'max-content')};
  display: flex;
  flex-wrap: ${({ inModal }) => (inModal ? 'nowrap' : 'wrap')};
  padding: ${({ inModal }) => (inModal ? '8px 12px' : '0')};
`;

export const Place = ({ value, state }: { value: any; state: TableCustomComponentState }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        value ? (
          <Wrapper>
            <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'500'}>
              {`${value.city}, `}
            </Text>
            <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
              {`${value.line1}`}
            </Text>
          </Wrapper>
        ) : (
          <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
            {'N/A'}
          </Text>
        )
      ) : (
        <Wrapper inModal>
          <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
            {value ? `${value.city}, ${value.line1}` : 'N/A'}
          </Text>
        </Wrapper>
      )}
    </>
  );
};
