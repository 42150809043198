import React from 'react';

export const InternalHumidity = ({
  primaryColor,
  secondaryColor,
}: {
  primaryColor: string;
  secondaryColor: string;
}) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.9997 7.1748L47.093 22.2681C50.0779 25.2511 52.1111 29.0523 52.9354 33.191C53.7597 37.3296 53.338 41.6197 51.7238 45.5187C50.1095 49.4177 47.3752 52.7503 43.8666 55.0951C40.3581 57.4398 36.233 58.6914 32.013 58.6914C27.7931 58.6914 23.668 57.4398 20.1594 55.0951C16.6509 52.7503 13.9165 49.4177 12.3023 45.5187C10.688 41.6197 10.2663 37.3296 11.0906 33.191C11.9149 29.0523 13.9481 25.2511 16.933 22.2681L31.9997 7.1748Z"
        stroke={secondaryColor}
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.875 29.126L24.125 44.876"
        stroke={primaryColor}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.3125 34.376C28.0039 34.376 29.375 33.0048 29.375 31.3135C29.375 29.6221 28.0039 28.251 26.3125 28.251C24.6211 28.251 23.25 29.6221 23.25 31.3135C23.25 33.0048 24.6211 34.376 26.3125 34.376Z"
        stroke={primaryColor}
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M37.6875 45.751C39.3789 45.751 40.75 44.3798 40.75 42.6885C40.75 40.9971 39.3789 39.626 37.6875 39.626C35.9961 39.626 34.625 40.9971 34.625 42.6885C34.625 44.3798 35.9961 45.751 37.6875 45.751Z"
        stroke={primaryColor}
        stroke-width="3"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
