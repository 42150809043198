import { useDispatch, useSelector } from 'react-redux';

import { stringifyUrl } from 'query-string';
import { useEffect, useState } from 'react';
import {
  CreationDevice,
  endCompanyCreation,
  loadDevices,
  selectFetchedDevices,
  selectFromTo,
  uploadDevices,
} from '../app/state/organisations';
import { store } from '../app/store';
import { API_URL } from '../consts';
import { LsValueType } from '../enums/LsValueType';
import { useApi } from '../features/apiBuilder/useApiBuilder';
import { ErrorAddCustomerEventType, ErrorEventType } from '../types/error';
import { HttpService } from '../utils/http';
import ss from '../utils/ss';

const ORGANISATION_KEYWORDS = ['OrganisationCreation'];

export const useOrganisationCreationDevices = ({
  triggers,
  client_id,
  profile_id,
  isFullScopeUrl,
}: {
  triggers: any[];
  client_id?: string;
  profile_id?: string;
  isFullScopeUrl?: boolean;
}) => {
  const fullScopeUrl = `${API_URL}/v2/devices/company_wizard`;
  const specificScopeUrl = `${API_URL}/v2/devices/reassign`;

  const rawUrl = !isFullScopeUrl ? specificScopeUrl : fullScopeUrl;

  const value = useSelector(selectFetchedDevices);
  const { query } = useSelector(selectFromTo);
  const { tid_from, tid_to } = query || {};

  const specificScopeQuery = !isFullScopeUrl ? { new_client_id: client_id } : { new_client_profile: profile_id };

  const queryOptions = query ? { query: { ...query, ...specificScopeQuery } } : { query: { ...specificScopeQuery } };

  const [url, setUrl] = useState(stringifyUrl({ url: rawUrl, ...queryOptions }));

  const dispatch = useDispatch();

  const condition = isFullScopeUrl ? !!profile_id : !!client_id;
  const [localCondition, setLocalCondition] = useState(condition);

  const urlTriggers = [...triggers, profile_id, client_id];
  const apiTriggers = [...triggers, localCondition];

  useEffect(() => {
    if (tid_from && tid_to) {
      setUrl(stringifyUrl({ url: rawUrl, ...queryOptions }));
    }
  }, [tid_from, tid_to]);

  useEffect(() => {
    setUrl(stringifyUrl({ url: rawUrl, ...queryOptions }));
    setLocalCondition(condition);
  }, [...urlTriggers]);

  const [data, , isLoading] = useApi({
    value,
    keywords: ORGANISATION_KEYWORDS,
    triggers: apiTriggers,
    url,
    condition: localCondition,
    transformResponse: (response) =>
      response.payload.map(
        ({
          is_selected,
          transport_id,
          ...restOfDevice
        }: Omit<CreationDevice, 'isSelected' | 'name'> & { is_selected: boolean; transport_id: string }) => ({
          ...restOfDevice,
          transport_id,
          name: transport_id,
          isSelected: is_selected,
        }),
      ),
    handleSuccess: (data) => dispatch(loadDevices(data as CreationDevice[])),
  });

  return { data, isLoading };
};

export const getDownloadSampleExcelFileUrl = (lang: string) => {
  return stringifyUrl({
    url: `${API_URL}/v2/devices/sample_excel`,
    query: { access_token: ss.get(LsValueType.token), lang },
  });
};

export const uploadDeviceExcel = (file: any, callback: () => void, profile_id: string) => {
  const url = `${API_URL}/v2/devices/process_excel?new_client_profile=${profile_id}`;
  const bodyFormData = new FormData();

  bodyFormData.append('file', file);

  (async () => {
    try {
      let {
        payload: {
          devices_success: { devices: successfullDevices },
          devices_failed: { number: failedCount },
        },
      } = (
        await HttpService.post(url, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      ).data;
      store.dispatch(uploadDevices({ successfullDevices, failedCount }));
      callback && callback();
    } catch (e) {
      callback && callback();
    }
  })();
};

export const uploadSpecificDeviceExcel = (client_id: string, file: any, callback: () => void) => {
  const url = stringifyUrl({
    url: `${API_URL}/v2/devices/process_excel_reassign`,
    query: { new_client_id: client_id },
  });
  const bodyFormData = new FormData();

  bodyFormData.append('file', file);

  (async () => {
    try {
      let {
        payload: {
          devices_success: { devices: successfullDevices },
          devices_failed: { number: failedCount },
        },
      } = (
        await HttpService.post(url, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      ).data;
      store.dispatch(uploadDevices({ successfullDevices, failedCount }));
      callback && callback();
    } catch (e) {
      callback && callback();
    }
  })();
};

export const finaliseCreation = (
  { client, users, devices }: { client: any; users: any; devices: any },
  handleNotification: (isSuccess: boolean, message?: string) => void,
) => {
  const url = `${API_URL}/v2/clients/company_wizard`;
  (async () => {
    try {
      await HttpService.post(url, { client, users, devices });
      handleNotification(true);
      store.dispatch(endCompanyCreation());
    } catch (e) {
      const errorAddCustomer = e as ErrorAddCustomerEventType;
      const errorEvent = e as ErrorEventType;
      const message = errorAddCustomer?.response?.data?.detail[0]?.msg ?? errorEvent?.response?.data?.detail;
      handleNotification(false, message);
    }
  })();
};

export const finaliseSimpleCreation = (
  {
    name,
    email,
    phone,
    address,
    parent,
    profile,
  }: { name: string; email: string; phone: string; address: any; parent: string; profile: string },
  handleNotification: (isSuccess: boolean, message?: string) => void,
) => {
  const url = `${API_URL}/v2/clients/`;
  (async () => {
    try {
      await HttpService.post(url, { name, email, phone, address, parent, profile });
      handleNotification(true);
      store.dispatch(endCompanyCreation());
    } catch (e) {
      const errorAddCustomer = e as ErrorAddCustomerEventType;
      const errorEvent = e as ErrorEventType;
      const message = errorAddCustomer?.response?.data?.detail[0]?.msg ?? errorEvent?.response?.data?.detail;
      handleNotification(false, message);
    }
  })();
};

export const finaliseDevicesAdding = (
  { client, devices }: { client: any; devices: any },
  handleNotification: (isSuccess: boolean, message?: string) => void,
) => {
  const url = `${API_URL}/v2/devices/reassign`;
  (async () => {
    try {
      await HttpService.post(url, { new_client_id: client, devices });
      handleNotification(true);
      store.dispatch(endCompanyCreation());
    } catch (e) {
      const error = e as ErrorEventType;
      const message = error?.response?.data?.detail;
      handleNotification(false, message);
    }
  })();
};
