import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { DeviceLocationItem } from './components/DeviceLocationItem';
import { ReactComponent as Arrow } from './assets/rightArrow.svg';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';
import { useCustomHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../../../routes/routeInterfaces';

const Wrapper = styled.div`
  width: auto;
  display: grid;
  grid-template-columns: 1fr 30px 1fr;
  gap: 10px;
  align-items: center;

  ${media.desktop} {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 0;
  }
`;

export interface DeviceLocationProps {
  data: any;
  deviceIds: any;
}

export const DeviceLocation: FC<DeviceLocationProps> = ({ data, deviceIds }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage);
  const [media] = useStyleContext();

  const customHistory = useCustomHistory();

  const { deviceName_1, deviceName_2, longitude_1, longitude_2, latitude_1, latitude_2, location_1, location_2 } = data;

  // const width = language.shortName === 'PL' ? '710px' : '632px';

  return (
    <Wrapper>
      <DeviceLocationItem
        deviceTitle={languageStrings.deviceLabel + ' #1 ID:'}
        deviceName={deviceName_1}
        longitude={longitude_1}
        latitude={latitude_1}
        location={location_1}
        media={media}
        onClick={() => {
          const to = View.USER_MANAGE_DEVICES_DETAILS;
          customHistory.push(to, {
            pathParams: { id: deviceIds[0].id, offset: 0 },
          });
        }}
      />
      {!media.mediaType.desktop && <Arrow />}
      <DeviceLocationItem
        deviceTitle={languageStrings.deviceLabel + ' #2 ID:'}
        deviceName={deviceName_2}
        longitude={longitude_2}
        latitude={latitude_2}
        location={location_2}
        media={media}
        onClick={() => {
          const to = View.USER_MANAGE_DEVICES_DETAILS;
          customHistory.push(to, {
            pathParams: { id: deviceIds[1].id, offset: 0 },
          });
        }}
      />
    </Wrapper>
  );
};
