import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../app/state/user';
import { UserRoleId, UserRoleMapping } from '../types/userRole';

export const useUserRole = () => {
  const languageStrings = useSelector(selectLanguageStrings);
  const admin = languageStrings.roleAdmin;
  const user = languageStrings.roleUser;

  // @NOTE order of mapping is important, the position reflects the id
  const mapping: UserRoleMapping = [user, admin];

  const getUserByRole = (role: UserRoleId) => ({ name: mapping[role], id: role });
  const roleList = mapping.map((name, id) => ({ name, id: id as UserRoleId })).reverse();

  const functions = { getUserByRole };

  return [{ roleList }, functions] as const;
};
