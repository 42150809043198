export const en_gb = {
  accessDownloadEvents:
    'Do you want to download an excel file with events data of this device for the selected time period?',
  accessDownloadTemperature:
    'Do you want to download an excel file with temperature data of this device for the selected time period?',
  accessDownloadWaterLevel:
    'Do you want to download an excel file with water level data of this device for the selected time period?',
  accessToThePlatformBlocked: 'Your access to the platform was blocked because you have unpaid invoices.',
  actionButtonValue: 'Action',
  add: 'Add',
  addCommentButton: 'Add comment',
  addCompany: 'Add organisation',
  addDeviceModalClarifyingDesc:
    'The end user will still need to provision and deploy the devices to be able to gather and monitor the data.',
  addDeviceModalDesc:
    'By adding devices to an organisation account all the users assigned to this organisation will be able to see and track the performance of these devices. Users with the admin permissions will also be able to configurate their settings and add new devices to the account.',
  addDeviceModalWaysDesc: 'There are two ways you can assign the devices to an organisation:',
  addDevicesLabel: 'Add device(s)',
  addDevicesManuallyTitle: 'Add device(s) manually',
  addEventText: 'Add Event',
  addIncidentTitle: 'Add incident',
  additionalDeviceAddDevice: 'Adding a new device',
  additionalDeviceEnterCode: 'Enter 10-digit code from the instruction',
  additionalDeviceNextButton: 'Next',
  additionalInfoLabel: 'Additional info',
  additionalInfoPlaceholder: 'Choose additional info',
  addManuallyAddDeviceModal: 'Add Manually',
  addManuallyDescAddDeviceModal:
    'type in the serial numbers of the devices you want to assign. It can be a number of individual device or a range including a bigger batch',
  addManuallyTooltipAddDeviceModal:
    'Recommended for adding up to 10 devices at once or a range from one production batch.',
  addNew: 'Add new',
  addNewCompany: 'Add new organisation',
  addNewCompanyDesc:
    'Organisation is a parent instance to users and devices, it acts as their aggregator. All users and devices need to be assigned to an organisation. In order to use any platform functionalities, user creation will be needed.',
  addNewCompanyDetails: 'Please provide the details of the new organisation.',
  addNewCompanyModalSecondStepDescription:
    'User is an instance that allows to monitor performance of the devices on the platform and manage their settings.',
  addNewCompanyModalSecondStepHintText:
    'First user assigned to an organisation has to have an admin account in order to add more users and manage settings of devices.',
  addNewCompanyModalSecondStepTitle: "Create first user within the organisation's account",
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText1: 'can edit the settings of the account and the devices.',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText2:
    'is restricted to read-only view, isn’t permited to edit the settings.',
  addNewCompanyModalSecondStepTypesOfAccountsText: 'There are two types of accounts for users:',
  addOrganisationLabel: 'Add new organisation',
  addresInstructionDesc1: 'Enter address or location in the Search bar on the top-left corner of the map.',
  addresInstructionDesc2:
    'Once you see the general location, zoom in on the map and drag it to the exact place for better precision.',
  addresInstructionDesc3: 'Click on Confirm to save your selection and exit the menu.',
  addresInstructionDesc4:
    'Use the text fields at the bottom of the window to enter the precise address of the device location.',
  addresInstructionDesc5: 'Click on Confirm to save the address and exit the menu.',
  addresInstructionDesc6: 'Notice: the map will not show the location in this method.',
  addresInstructionTitle1: 'Method 1: Map search',
  addresInstructionTitle2: 'Method 2: Address input',
  addressSeaLevelLabel: 'Height above sea level (m)',
  addressSeaLevelPlaceholder: 'Enter Sea Level',
  addUser: '+ Add user',
  advancedLabel: 'Advanced',
  alertMessageError: 'Error appears when you did wrong or there is system fault',
  alertMessageNeutral: 'Neutral info displayed here',
  alertMessagePositive: 'Successful message to those who did good',
  alertMessageWarning: 'Warning text here so you can be more careful',
  allButton: 'All',
  analyticsItem: 'Analytics',
  anUnexpectedErrorHasOccurred: 'An unexpected error has occurred. Please, try again',
  applyButton: 'Apply',
  applyButton1: 'Applying',
  applyButton2: 'Applied',
  areYouSure: 'Are you sure?',
  average: 'avg.',
  averagingMethodLabel: 'Averaging Method',
  backButton: 'Back',
  backHome: 'Back Home',
  basedOnManningsEquation: "Based on manning's equation, ",
  basicLabel: 'Basic',
  battery: 'Battery',
  batteryLevel: 'Battery level',
  batteryStatusBad: 'Low',
  batteryStatusGood: 'High',
  batteryStatusWarn: 'Medium',
  billing: 'Billing',
  billingAddressPlaceholder: 'Choose billing address',
  billingDetails: 'Billing details',
  billingDetailsNotFilledDesc: 'If the problem persists, please contact your administrator.',
  calibraion: 'Calibration',
  calibraionInPropgress: 'Calibration in progress',
  calibraionLabel: 'Calibration details',
  calibraionTooltip: 'Learn more about calibration',
  calibration: 'Calibration',
  calibrationDate: 'Calibration date',
  calibrationDesc:
    'Calibration is always performed after the deployment of the device. It is necessary to recalibrate the device if you relocated the device since the last calibration, or the calibration was performed incorrectly. \n Before starting the calibration process, make sure that the device is in the intended location, and that it will stay undisturbed for the calibration period - up to 48h.',
  calibrationDescInProgress:
    'The process of calibration has started. It can take up to 48 hours. Have in mind that during this time the water level measurements may be unreliable. Please make sure that the device will stay undisturbed during this period.',
  calibrationInfoTooltip:
    'In the process of calibration, the device scans the inside of the manhole to detect all the static elements, to take them into account when conducting measurements and ensure proper data collection. It is always performed after deployment',
  calibrationPeriodTootlipText: 'The data from calibration\n period may be unreliable',
  calibrationText: 'Calibration',
  calibrationTooltipText: 'The data from calibration period may be imprecise',
  cancelButton: 'Cancel',
  chooseAddressPlaceholder: 'Choose address',
  chooseDateTitle: 'Choose date',
  chooseDeviceNamePlaceholder: 'Choose name of device',
  chooseOrganisation: 'Choose organisation',
  chooseOrganisationEmail: 'Organisation’s email',
  chooseOrganisationName: 'Organisation’s name',
  chooseOrganisationNamePlaceholder: 'Enter name',
  chooseOrganisationPhone: 'Organisation’s phone',
  chooseOrganisationPhonePlaceholder: 'Enter phone',
  chooseParentOrgFirst: 'Choose parent org. first',
  choosePaymentMethodPlaceholder: 'Choose payment method',
  choosePlansetForEachDevices:
    '\nChoose planset\n for each device you are assigning. It will determine which plans the device provisioning process will offer as options.',
  chooseSeverityPlaceholder: 'Choose severity',
  chooseTypeOfInvoices: 'Choose type of invoices',
  chooseTypePlaceholder: 'Choose type',
  choosingPlanset: 'Choosing planset',
  clearButton: 'Clear devices',
  clearButtonMobile: 'Clear',
  clickOn: 'Click on ',
  closeButtonValue: 'Close',
  closedText: 'Closed',
  commentsPlaceholder: 'No comments yet',
  commentTableDayToday: 'Today, ',
  commentTableDayYesterday: 'Yesterday, ',
  commentTableEdit: 'Edit Message',
  commentTableEditLabel: 'Edited: ',
  commentTableEditPlaceholder: 'Edit a message...',
  commentTablePlaceholder: 'Write a comment...',
  commonButtonMore: 'More',
  commonButtonRevoke: 'Revoke',
  commonButtonSend: 'Send',
  commonButtonUpload: 'Upload',
  companyAddress: "Organisation's address",
  companyAddressPlaceholder: "Choose organisation's address",
  companyCreationEmailAddress: 'Email address',
  companyName: "Organisation's name",
  companyNameLabel: "Organisation's name",
  companyNamePlaceholder: "Enter organisation's name",
  companyNamePlaceholderr: 'Organisation name',
  companyOnly: 'Organisation only',
  companyOnlyCardInfo:
    "Go for it if you wish to add only an organisation at the moment. In order to make the organisation's account operational, you will need to manually add an admin user and assign devices to this organisation at a later stage.",
  companyPhone: "Organisation's phone",
  companyPhonePlaceholder: "Enter organisation's phone",
  companyPhoneTooltip:
    'Organisation general phone number. If organisation doesn’t have one, fill it out with a phone number of the first user that will be assigned to this organisation.',
  completedProvisioningButton: 'Go to the platform',
  completedProvisioningContent:
    'You have completed the provisioning flow of the device. To start managing the device you will need to log into the Smart Utilities Platform. Creation of a new organisation can take ',
  completedProvisioningContent2: 'up to 10 minutes',
  completedProvisioningContent3: 'Please login in to the Platform after this time.',
  completedProvisioningLabel: 'Provisioning is completed',
  conditionalCheckerModalCheckboxLabel: "Don't Show this message again",
  conditionalCheckerModalCommonButton: ' Ok, Got It',
  conditionalCheckerModalTitleFirstPart:
    'We will run maintenance. All system will be updated automatically in the next',
  conditionalCheckerModalTitleSecondPart: 'hours.',
  configuration: 'Configuration',
  contactSupport: 'contact support',
  continue: 'Continue',
  continueStyled: '“Continue“',
  copiedMessage: 'Copied to clipboard',
  coverTitle: 'Cover',
  createAccount: 'Create an account',
  createButton: 'Create',
  createMoreUsers: 'Create more users',
  creationDate: 'Creation date',
  currentNetworkConnectionTechnology: 'Connection Technology',
  currentNetworkLastUpdated: 'Last updated',
  currentNetworkPreferedConectionTechnology: 'Preferred connection Technology',
  dashBoardCardCitiesTitle: 'Locations',
  dashBoardCardDevicesTitle: 'Total Devices',
  dashBoardCardLocationsTitle: 'Total Locations',
  dashboardCardsTitleDevices: 'Total Devices',
  dashboardCardsTitleLocations: 'Total Locations',
  dashboardCardsTitleNoProblems: 'No Problems Detected',
  dashboardCardsTitleProblems: 'Problems Detected',
  dashboardCardsTitleReport: 'Report a problem',
  dashboardCardsTitleTemperature: 'Average Temperature',
  dashboardCardsTitleWarnings: 'Warnings',
  dashboardTitle: 'Dashboard',
  dataPeriodLabel: 'Data Period',
  dataProcessingDescription: 'Processing large amount of data. Please wait',
  dataProcessingTitle: 'Data processing',
  days: 'days',
  daysUnit: 'days',
  deleteDevicesModal: 'The list of selected devices will be cleared.',
  deleteModalInfoText: 'The list of selected devices will be cleared.',
  deletingComment: 'This comment will be deleted.',
  deletPairModal: 'The pair of manholes will be permanently deleted.',
  deliveryAddress: 'Delivery address',
  deploy: 'Deploy',
  deployed: 'Deployed',
  deploying: 'Deploying',
  deploymentDate: 'Deployment date',
  deploymentDetailsModalTitle: 'Deployment details',
  deploymentIsInProgress: 'Device deployment is in progress. Check back later!',
  deploymentIsInProgressTextFirst:
    'Deployment is in progress, it can take up to 48 hours. Make sure the device stays undisturbed during this time.',
  deploymentIsInProgressTextSecond:
    'In the meantime, we recommend that you \n set other parameters \n of the device, such as transmission and measurement intervals or alarm configurations. If you want to set it from the device details page later, close this window.',
  deploymentIsInProgressTitle: 'Deployment is in progress',
  deploySetDepthModalText:
    'Reliable measurements require an accurate maximum depth, measured from the radar to the deepest point of the tank.',
  deploySetDepthModalTitle: 'Set the maximum depth',
  depth: 'Depth',
  depthNotice:
    'Depth of the well is crucial for proper measurements. Please provide the depth as soon as possible, otherwise, the measurement may not be correct.',
  depthUnknown: 'Depth is unknown',
  descriptionLabel: 'Description',
  descriptionPlaceholder: 'Enter description',
  details: 'Details',
  deviceDeploymentIsInProgress: 'Device deployment is in progress',
  deviceDetailsGoToInsightDetails: 'Go to location',
  deviceID: 'Device ID',
  deviceIdText: 'Device ID: ',
  deviceInfoLabelProperty: 'Property',
  deviceIsntDeployed: 'Device isn’t deployed',
  deviceLabel: 'Device',
  deviceName: 'Device name',
  directLink: 'direct link',
  dismissableModalCommonButton: 'Learn More',
  dismissableModalTitle: 'This is an example of a simplified view that can be dismissed. Action isn’t required.',
  distanceToWater: 'distance to water/water level',
  distanceToWaterTooltip: 'Distance to water',
  documentsManual: 'Manual',
  documentsOneMore: 'One more doc',
  documentsReleaseNote1_1: 'Release Note 1.1',
  documentsReleaseNote2_0: 'Release Note 2.0',
  documentsReleaseNote2_4: 'Release Note 2.4',
  documentsReleaseNote2_5: 'Release Note 2.5',
  documentsReleaseNote2_6: 'Release Note 2.6',
  documentsSpecification: 'Smart RADAR Specification',
  download: 'Download',
  doYouWantToApply: 'Do you want to apply this planset to all devices of this type or just this one?',
  dueOn: 'Due on',
  editDeliveryAddressTitle: 'Edit delivery address',
  editInvoiceTitle: 'Edit invoice details',
  editMessageTitle: 'Edit Message',
  emailAddress: 'Email address',
  emailAddressPlaceholder: 'Enter email address',
  emailAddressTooltip:
    'Organisation general email address. If organisation doesn’t have one, fill it out with an email address of the first user that will be assigned to this organisation.',
  emailNotificationsError: 'An unexpected error has occurred. Please, try again',
  emailNotificationsOff: 'Email notifications disabled for this device',
  emailNotificationsOn: 'Email notifications are enabled for this device',
  emphasizedHeaderTextModalDescription: 'You won’t be able to undo this action.',
  emphasizedHeaderTextModalTitle: 'Are you sure?',
  endUserGuide: 'End User Guide',
  enterInfoPlaceholder: 'Enter info',
  errorMessageForSelectAddress: 'Fields “line 1” and “city” need to be filled in.',
  errorPage401Description: 'Access is denied due to invalid credentials.',
  errorPage401Title: 'Unauthorised',
  errorPage403Description:
    'Sorry, you don’t have permission to view this directory. You can return to main page if you have the access rights.',
  errorPage403Title: 'Forbidden',
  errorPage404Description: 'We can’t find the page that you’re looking for',
  errorPage404Title: 'Page not found',
  errorPage500Description: 'We’re having an issue, please try one of these option to get you back on track',
  errorPage500Title: 'Something went wrong',
  errorPageMainPage: 'Main Page',
  errorPageReload: 'Reload',
  existingItem: 'This item is in use and can’t be deleted right now.',
  export: 'Export',
  exportButton: 'Export',
  exportExcelFileTitle: 'Export Excel file',
  failureReasonsModalHeader: 'Failure reasons',
  failureReasonsModalInfo:
    'These are common issues that happen during upload that could cause the failure of the upload, check your file for the following issues and then upload it again:',
  failureReasonsModalReasonFirst: 'Matching issue:',
  failureReasonsModalReasonFourth: 'The file size is too large:',
  failureReasonsModalReasonSecond: 'Format issue:',
  failureReasonsModalReasonThird: 'Device unavailable:',
  failureReasonsModalSuggestionFirst:
    'the data in the file does not match the requirements. Please download the sample file and match the columns stated there.',
  failureReasonsModalSuggestionFourth:
    'to reduce the file size, you can delete unnecessary data values, columns, and rows.',
  failureReasonsModalSuggestionSecond:
    'the data introduced in the file cannot be processed. Please check if the device numbers match the following format: SMC12345678900.',
  failureReasonsModalSuggestionThird:
    'serial number of a device is not available because it is assigned to a different company. If you want to assign it to a new company, first you will need to reassign it back to you.',
  filtersButton: 'Filters',
  filtersNotificationsError: 'An unexpected error has occurred. Please, reload the page',
  filtersSelectError: 'Error: no data',
  finalizeButton: 'Finalise',
  firstDeviceAddress: 'Device #1 address',
  floatingModalCommonButtonDiscard: 'Discard',
  floatingModalTitle: 'Save changes?',
  flowInfo: 'Flow info',
  flowLabel: 'Flow',
  flowMeasurementBasedOn: 'Flow measurement based on',
  flowMeasurementsLabel: 'flow measurements',
  flowUnit: 'Flow unit',
  freeFlowCircularCulvert: 'Free-flow circular culvert',
  fullAdditionalDescription:
    'The support fee is a monthly subscription cost covering personalized onboarding, dedicated customer support, and ongoing software updates. It is charged only for the first 5 provisioned devices, regardless of their plan.',
  fullAddress: 'Full address',
  fullNameOfOrganisation: 'Full name (or organisation’s name)',
  fullNameOfOrganisationPlaceholder: 'Enter full name (or organisation’s name)',
  fullyOperationalAccount: 'Fully operational account',
  fullyOperationalAccountCardInfo:
    'Quick and easy way to create a fully operational account for a new organisation. By following a 3-step wizard you will end up with an account ready for use by your client.',
  generateButton: 'Generate',
  generateReportModalDay: 'Today',
  generateReportModalMonth: 'This Month',
  generateReportModalParameterCustomerPlaceholder: 'Choose organisation(s)',
  generateReportModalParameterLocationPlaceholder: 'Choose location(s)',
  generateReportModalParameterLocationPlaceholderDisabled: 'Choose organisation(s) first',
  generateReportModalParameterRecipients: 'Recipients',
  generateReportModalParameterRecipientsPlaceholder: 'Choose recipient(s)',
  generateReportModalPeriod: 'Period',
  generateReportModalPeriodBorderFrom: 'From:',
  generateReportModalPeriodBorderTo: 'To:',
  generateReportModalShowMessage: 'You should choose',
  generateReportModalWeek: 'This Week',
  generateReports: 'Generate report',
  getProfilesError: 'Selected parent org. does not have the profiles, please select another parent org.',
  getStartedButton: 'Get Started',
  goBack: 'Go Back',
  gotIt: 'Got it',
  goToBillingDetails: 'Go to billing details',
  goToInvoices: 'Go to invoices',
  goToMain: 'Go to Main',
  gpsCheck1Row:
    'GPS location check allows checking the current location of the device and saving it in the platform. This process drains the battery deeply, shortening the lifetime of the device. ',
  gpsCheck2Row:
    'This process drains the battery deeply, shortening the lifetime of the device. Therefore, \nwe recommend using the GPS coordinates check functionality up to 2 times\n, only if: ',
  gpsCheck3Row:
    '- you installed, deployed, and calibrated the device in the intended tank for the first time and want to save the GPS coordinates as the location of the device,',
  gpsCheck4Row: "- you can't localise the device because of an unauthorized pick-up event.",
  gpsCheck5Row: '\nAre you sure you want to proceed with the GPS location check?\n',
  gpsCheckTooltip: 'Learn more about GPS location',
  gpsDetailsAddressTitle: 'Coordinates received from GPS',
  gpsDetailsAreYouSureText: 'Every GPS check drains the baterry deeply. Are you sure you want to proceed?',
  gpsDetailsAreYouSureTitle: 'Are you sure?',
  gpsDetailsCheckPerformedText: 'Manual GPS location checks performed: ',
  gpsDetailsManualChecksPerformed: 'Manual checks performed',
  gpsDetailsRequestCheck: 'Request GPS check',
  gpsDetailsSaveAsMainLocText: 'Are you sure you want to change the current location of the device to this one?',
  gpsDetailsSaveAsMainLocTitle: 'Save as main location',
  gpsDetailsSaveMainLocation: 'Save as main location',
  gpsDetailsSaveMainLocationText: 'Are you sure you want to change the current location of the device to this one?',
  gpsDetailsSaveMainLocationTitle: 'Save as main location',
  gpsDetailsTitle: 'GPS location details',
  gpsLocationCheckText:
    'You can request the GPS location check, which will be performed when the next data transmission cycle occurs (check transmission frequency in the device details). Once the coordinates are received from GPS they will be available here.',
  gpsLocationCheckTitle: 'GPS location check request',
  gpsLocationProperty: 'GPS location',
  GPSLocationText: 'GPS location check',
  gpsRequestSuccess: 'GPS location was updated',
  graphPlaceholderLabel: 'Water level',
  gravitationalCircularCulvert: 'Gravitational circular culvert',
  groundMeter: 'Smart LEVEL',
  groupFieldPlaceholder: 'Select organisation',
  hightText: 'Hight',
  hourLabel: 'hour',
  hours: 'hours',
  hoursUnit: 'hours',
  humidity: 'Humidity',
  humidityMeasurements: 'Humidity measurements',
  ifItDoesntTryThis: "If it doesn't, try this",
  imagesLabel: 'Images (optional)',
  incidentAddressPlaceholder: 'Selected automatically',
  incidentsNotifications: 'Incidents notifications',
  incidentsPlaceholder: 'Type something...',
  includedLablel: 'Included:',
  incorrectTypeOfFile: 'Incorrect type of file. Choose image format (.jpg, .png, .heic etc.)',
  individualText:
    'search for a single device. You can assign the searched and following devices by checking the boxes next to them.',
  individualTitle: 'Individual',
  info: 'Info',
  infoButtonTooltip: 'some info',
  informationModalHeader: 'Setting up an account for a new organisation',
  informationModalInfo:
    'Quickly create a new account for your client. Just follow 3 easy steps to set up a fully operational account:',
  informationModalListOfTextFirst: 'Add new organisation;',
  informationModalListOfTextSecond: "Create user(s) within organisation's account;",
  informationModalListOfTextThird: 'Assign device(s) to the organisation’s account.',
  inisghtDetailsHistorySelectLast: 'Last',
  inisghtDetailsHistorySelectPeriodsDays: 'days',
  inisghtDetailsHistorySelectPeriodsMonths: 'months',
  inisghtDetailsHistorySelectPeriodsWeek: 'week',
  inisghtDetailsHistorySelectPeriodsYear: 'year',
  insightDetailsCommentsPlaceholder: 'Type something...',
  insightDetailsCommentsTitle: 'Comments',
  insightDetailsCurrentNetworkTitle: 'Current Network',
  insightDetailsDeviceConfigAddressButtonsConfirm: 'Confirm',
  insightDetailsDeviceConfigAddressChooseLocation: 'choose location on the map',
  insightDetailsDeviceConfigAddressInputsCity: 'City',
  insightDetailsDeviceConfigAddressInputsCountry: 'Country',
  insightDetailsDeviceConfigAddressInputsLatitudeName: 'Latitude',
  insightDetailsDeviceConfigAddressInputsLatitudePlaceholder: 'Enter Latitude',
  insightDetailsDeviceConfigAddressInputsLine1: 'Line 1',
  insightDetailsDeviceConfigAddressInputsLine2: 'Line 2',
  insightDetailsDeviceConfigAddressInputsLongitudePlaceholder: 'Enter Longitude',
  insightDetailsDeviceConfigAddressInputsLongotudeName: 'Longitude',
  insightDetailsDeviceConfigAddressInputsRegion: 'Region',
  insightDetailsDeviceConfigAddressInputsZip: 'ZIP',
  insightDetailsDeviceConfigAddressSearchBarPlaceholder: 'Enter location…',
  insightDetailsDeviceConfigAddressTitle: 'Device address',
  insightDetailsDeviceConfigHeaderEdit: 'Edit',
  insightDetailsDeviceConfigHeaderParam: 'Parameter',
  insightDetailsDeviceConfigHeaderValue: 'Value',
  insightDetailsDeviceConfigRowsName: 'Device Name',
  insightDetailsDeviceConfigRowsTransmissionFreq: 'Transmission Frequency',
  insightDetailsDeviceConfigStatusChangesStagesApplied: 'Applied',
  insightDetailsDeviceConfigStatusChangesStagesFailed: 'Failed',
  insightDetailsDeviceConfigStatusChangesStagesRequested: 'Requested',
  insightDetailsDeviceConfigTitle: 'Device Configuration',
  insightDetailsDeviceConfigurationEditInputsAddressPlaceholder: 'Enter Address',
  insightDetailsDeviceConfigurationEditInputsAddressTitle: 'Address',
  insightDetailsDeviceConfigurationEditInputsButtonsCancel: 'Cancel',
  insightDetailsDeviceConfigurationEditInputsButtonsSave: 'Save',
  insightDetailsDeviceConfigurationEditInputsNamePlaceholder: 'Enter name',
  insightDetailsDeviceConfigurationEditInputsNameTitle: 'Name',
  insightDetailsDeviceConfigurationEditInputsTransmissionFreqPlaceholder: 'Enter transmission frequency',
  insightDetailsDeviceConfigurationEditInputsTypeTitle: 'Type',
  insightDetailsDeviceId: 'Device ID',
  insightDetailsDeviceInfoTitle: 'Device Info',
  insightDetailsEvent: 'Event',
  insightDetailsEventData: 'Date',
  insightDetailsEventTableHeaderEvent: 'Event',
  insightDetailsEventTableHeaderTime: 'Time',
  insightDetailsEventTableSelectAll: 'All',
  insightDetailsEventTableTitle: 'Event Table',
  insightDetailsEventTime: 'Time',
  insightDetailsGeneralReportsButton: 'Generate Report',
  insightDetailsGoToDeviceButton: 'Go to device',
  insightDetailsGoToFlowButton: 'Go to flow',
  insightDetailsHistoryTitle: 'History',
  insightDetailsNetworkEvent: 'Network Event Table',
  insightDetailsRadioModulesLabel: 'RADIO MODULE',
  insightDetailsRadioModulesTitle: 'Radio Modules',
  insightDetailsRealTimeCardsTemperature: 'Temperature',
  insightDetailsRealTimeCardsWater: 'Distance to water',
  insightDetailsRealTimeTitle: 'Real time',
  insightDetailsStatusChangesButton: 'Got it',
  insightDetailsStatusChangesStagesCurrent: 'Current: ',
  insightDetailsStatusChangesTitlesDeviceName: 'Device name',
  insightDetailsStatusChangesTitlesTransmissionFreq: 'Device transmission frequency',
  insightDetalisEventDateAndTime: 'Date & Time',
  insightMobilePlaceholder: 'Choose a place…',
  insightPlaceholder: 'Search for something',
  insightSearchPlaceholder: 'What are you looking for?',
  insightSearchResultsNoResults: 'no results',
  insightSearchResultsResult: 'result',
  insightSearchResultsResults: 'results',
  insightTitle: 'Real Time Insight',
  insightToggleList: 'List view',
  insightToggleMap: 'Map view',
  internalHumidityTitle: 'Internal humidity',
  internalTemperature: 'Internal temperature',
  invoiceDetails: 'Invoice details',
  invoiceID: 'Invoice ID',
  invoices: 'Invoices',
  labelReportsTableLocation: 'Location',
  lastEventLabel: 'Last event',
  lastSeenOnline: 'Last seen online',
  lastSeenTitle: 'Last seen',
  levels: 'Levels',
  linkAccount: 'Link account',
  linkPaymentMethod: 'Link payment method',
  locationPopupCityPlaceholder: 'Enter City',
  locationPopupCountryPlaceholder: 'Enter Country',
  locationPopupDescription: 'choose location on the map',
  locationPopupLine1Placeholder: 'Enter Line 1',
  locationPopupRegionPlaceholder: 'Enter Region',
  locationPopupTitleDevices: 'Device address',
  locationPopupZipPlaceholder: 'Enter Zip',
  maintenanceTitle: 'Maintenance',
  manageAddButton: 'Add ',
  manageClientEditAddressSearchBarPlaceholderAddress: 'Enter address…',
  manageClientEditAddressTitle: 'Organisation address',
  manageClientsEditClientInputsNameTitle: 'Organisation name',
  manageClientsEditClientTitle: 'Edit organisation',
  manageClientsMultipleDeleteModalButtonsNo: 'No, Cancel',
  manageClientsMultipleDeleteModalButtonsYes: 'Yes, Do It',
  manageClientsMultipleDeleteModalDescription: 'These organisations will be permanently deleted: ',
  manageClientsMultipleDeleteModalTitle: 'Are you sure?',
  manageClientsSingleDeleteModalDescription: 'This organisation will be permanently deleted: ',
  manageClientsTableHeaderDeleteButton: 'Delete',
  manageClientsTableHeaderDeleteSelected: 'Selected: ',
  manageClientsTableHeaderItemsAddress: 'Address',
  manageClientsTableHeaderItemsClient: 'Organisation',
  manageClientsTableHeaderItemsEmail: 'Email',
  manageClientsTableHeaderItemsName: 'Name',
  manageClientsTableHeaderItemsPhone: 'Phone number',
  manageClientsTableHeaderItemsPlace: 'Place',
  manageClientsTableRowPlaceholdersAddress: 'Enter address',
  manageClientsTableRowPlaceholdersEmail: 'Enter email',
  manageClientsTableRowPlaceholdersPhone: 'Enter phone',
  manageDeleteSemiTitle: 'This entities will be permanently deleted:',
  manageDevicesChooseClientPlaceholder: 'Choose organisation',
  manageDevicesEditDevicesTitle: 'Edit device',
  manageDevicesNoCLients: 'No s exist',
  manageDevicesPlace: 'Enter place',
  manageIncidentsAddButton: 'Add',
  manageUsersAddUserModalInputsClientPlaceholder: 'Choose',
  manageUsersAddUserModalInputsRoleTitle: 'Role',
  manageUsersEditUserModalTitle: 'Edit user',
  manageUsersMultipleDeleteModalDescription: 'These users will be permanently deleted: ',
  manageUsersSelectAll: 'All organisations',
  manageUsersSingleDeleteModalDescription: 'This user will be permanently deleted: ',
  manholeModalName: 'Manhole',
  manholes: 'manholes',
  manningsPair: 'Manning’s pair',
  manufacturingDate: 'Manufacturing date',
  meaningLabel: 'Meaning',
  measurementDeviceDetailsTableName: 'Name',
  measurementItem: 'Flow measurement',
  measurementsFromDevice: 'Measurements from the device',
  measurementTitle: 'Flow measurement pairs',
  messageAboutSuccessfullyDeleted: 'Successfully deleted',
  minMeasurmentIntervals: 'Minimum measurement intervals',
  mins: 'mins',
  minsUnit: 'mins',
  minTransmissionIntervals: 'Minimum transmission intervals',
  mobileEditPopupChooseClient: 'Choose organisation',
  mobileEditPopupChooseRole: 'Choose role',
  mobileModalEditAddClientTitle: 'Add new organisation',
  mobileModalEditAddUserTitle: 'Add new user',
  modalSystemSelectPlaceholder: 'Data is empty',
  modalTitleAdd: 'Add',
  modalTitleView: 'View',
  monthlyLabel: 'Monthly',
  moreDetailsButton: 'More details',
  multipleUsersCanBeAdded:
    'Multiple users can be added within one organisation.\nBy adding more users you grant them access to the platform to track the devices’ performance.',
  nameSurnamePlaceholder: 'Enter name & surname',
  navigationClients: 'Organisations',
  navigationDevices: 'Devices',
  navigationDocuments: 'Documents',
  navigationIncidents: 'Incidents',
  navigationInsight: 'Insight',
  navigationLogout: 'Log out',
  navigationManage: 'Manage',
  navigationNotifications: 'Notifications',
  navigationNotificationsTimeAgo: 'ago',
  navigationNotificationsTimeHr: 'hr',
  navigationNotificationsTimeMin: 'min',
  navigationReports: 'Reports',
  navigationSettings: 'Settings',
  navigationUsers: 'Users',
  nextButton: 'Next',
  noButton: 'No',
  noDataPlaceholderText: 'No data',
  noReportsPlaceholder: 'No reports yet',
  notDeployed: 'Not deployed',
  noTestInformation: 'No QA information available for this device',
  noteText: 'Note',
  notice: 'Notice',
  notOkTitle: 'Not ok',
  oops: 'Oooops!',
  openText: 'Open',
  optionsDialogueModalDescription: 'Do you want to proceed?',
  optionsDialogueModalTitle: 'Items will be moved to secret folder.',
  organisationAddress: "Organisation's address",
  organisationAddressLabel: 'Organisation’s address',
  organisationCreationSuccess: 'Organisation created successfully',
  organisationDetailsTitleInfoTable: 'Organisation Info',
  organisationLabel: 'Organisation',
  organisationsName: 'Organisation’s name',
  organisationsNamePlaceholder: 'Enter organisation’s name',
  outletPressure: 'Outlet pressure',
  outletTemperature: 'Outlet temperature',
  outsidePressure: 'Outside pressure',
  outsideTemperature: 'Outside temperature',
  overdue: 'Overdue',
  overflowBasedOn: 'Overflow based on',
  overflowLabel: 'overflow',
  pageNotFound: 'Page not found.',
  paginationButtonNext: 'Next',
  paginationButtonPrevious: 'Prev',
  paginationGo: 'Go',
  paginationGoTo: 'Go to',
  paid: 'Paid',
  pairConfiguration: 'Pair Configuration',
  pairId: 'Pair ID',
  pairInfo: 'Pair info',
  pariedManholeButton: 'Go to pair',
  pariedManholegraphLabel: 'Water flow',
  pariedManholeModalName: 'Paired manholes',
  passed: 'Passed',
  paymentDetails: 'Payment details',
  paymentMethodHasBeenSuccessfullySaved: 'Payment method has been successfully saved',
  pending: 'Pending',
  perDevice: 'per device',
  perMonth: 'per month',
  personnelLabel: 'Personnel',
  personnelPlaceholder: 'Enter personnel',
  perYear: 'per year',
  phisicalId: 'Physical ID',
  photosTitle: 'Photos',
  pickSubscriptionPlan: 'Pick a subscription plan',
  pickSubscriptionPlanSub1: 'By picking a plan you subscribe to set of features on the Smart Utilities Platform.',
  pickSubscriptionPlanSub2: 'You need to pick a plan for every device you provision.',
  pickUpEventText: 'Pick-up event',
  placeholderNoData: 'No data',
  plans: 'Plans',
  plansetWasAppliedToAllDevices: 'Planset was applied to all devices of this type',
  plansetWasAppliedToDevices: 'Planset was applied to the device',
  pleaseFillOutBillingInformation:
    'Please fill out billing information to have full access to the platform. After filling it, refresh the page.',
  pointIntervalLabel: 'Point Interval',
  premiumLabel: 'Premium',
  pressToLearnMoreTooltip: 'Press to learn more',
  privacyPolicy: 'Privacy Policy',
  problemsButtonDetails: 'Details',
  problemsButtonHighTemperature: 'High temperature',
  problemsDate: 'Date',
  problemsOf: 'of',
  problemsPlace: 'Place',
  problemsProblem: 'Problem',
  problemsShowing: 'Showing',
  profileLabel: 'Profile',
  profilePlaceholder: 'Choose profile',
  provisioned: 'Provisioned',
  provisionedOnLabel: 'Provisioned on',
  provisioning: 'Provisioning',
  provisioningAddingDevice: 'Adding a new device',
  provisioningAddress: 'Address',
  provisioningChooseCustomer: 'Choose organisation',
  provisioningChooseOrganisation: 'Choose an organisation',
  provisioningContent:
    ' and follow the path. After verifying your account come back to this page to sign in and continue with the provisioning process.',
  provisioningContent2: '  to proceed with provisioning. You may be prompted to login to your account.',
  provisioningContent3:
    'Enter 10-digit code from the instruction if you’re on the desktop. If you scanned a QR code you will go directly to the next step.',
  provisioningContent4: ' from the available options (if you were previously added to the platform) or ',
  provisioningContent5:
    ' (if you just signed up). The device you are provisioning will be assigned to this organisation.',
  provisioningCreateOrg: 'create a new organisation',
  provisioningCustomer: 'Organisation',
  provisioningDate: 'Provisioning date',
  provisioningDeployNow: 'Deploy now (You can always deploy it later)',
  provisioningDescribe:
    'By provisioning a device you will assign it to your organisation on the Smart Utilities Platform. The process differs if you already have an account on our platform or not.',
  provisioningDetails: 'Provisioning details',
  provisioningDeviceInstalmentLocationOptional: 'Device instalment location (optional)',
  provisioningErrorAssign: 'Error the assign',
  provisioningFinish: 'Finish',
  provisioningInOrderForDevice:
    'In order for device to be operational and perform measurements it has to be deployed. It is advised to deploy the device after installation. Note: the deployment may take up to 24 hours in good network conditions (more in bad)',
  provisioningInstructions: 'Instructions',
  provisioningOrganisation: 'Organisation',
  provisioningRow: 'If you don’t have an account yet:',
  provisioningRow2: 'If you already have an account on the Platform:',
  provisioningSetDepthModalTitle: 'Maximum depth',
  provisioningSuccessfullyAssign: 'Device deployed successfully',
  provisioningTooltipOrganisations:
    'Organisation is a parent instance to users and devices, it acts as their aggregator. If you are a private customer you can introduce your own details.',
  pumpingStationAndTank: 'pumping station and tank',
  qualityControl: 'Quality control',
  quickstartGuide: 'Quickstart guide',
  radioModulesFirmwareRevision: 'Firmware revision',
  radioModulesManufacturer: 'Manufacturer',
  radioModulesTypeModel: 'Type/Model',
  rangeText:
    'look for a range of devices by typing in the first and last serial number from a production batch that you want to assign. All the devices from this range will be checked automatically, you can uncheck the ones you want to exclude.',
  rangeTitle: 'Range',
  reload: 'Reload',
  reportProblemsModalDescription: 'Enter description of a problem',
  requestedValue: 'Requested value change to',
  resolveButton: 'Resolve',
  resolvedButton: 'Resolved',
  resolvedSuccessfullyMessage: 'Resolved successfully',
  resolveIncidentTitle: 'Resolve incident',
  roleAdmin: 'Admin',
  roleLabel: 'Role',
  roleUser: 'User',
  sameAsBillingInfo: 'Same as billing information',
  sampleButton: 'Sample',
  saveButton: 'Save',
  scanCuccessful: 'Scan successful',
  searchBarPlaceholder: 'Search...',
  secondDeviceAddress: 'Device #2 address',
  selectButtonValue: 'Select',
  selectDevicePlaceholder: 'Choose device',
  selectedDatesLabel: 'Selected dates',
  selectedTitle: 'Selected',
  selectPeriodLastDays: 'Last 30 days',
  selectPeriodLastMonths: 'Last 6 months',
  selectPeriodLastWeek: 'Last week',
  selectPeriodLastYear: 'Last year',
  selectValuePlaceholder: 'Choose value',
  setParameters: 'Set parameters',
  severityHigh: 'High',
  severityLabel: 'Severity',
  severityLow: 'Low',
  severityMedium: 'Medium',
  sewageLevelTitle: 'Sewage level (%)',
  shortAdditionalDescription: 'applicable only for \nthe first 5 devices\n at this price per device per month',
  skipButton: 'Skip',
  skipButtonAddDeviceModal: 'Skip',
  smartHydrant: 'Smart HYDRANT',
  smartRadar: 'Smart RADAR',
  smartRadarAlarm: 'Smart ALARM',
  somethingWentWrong: 'Something went wrong',
  sorryWeCouldNotFindThePage: 'Sorry, we could not find the page!',
  sourceLabel: 'Source',
  sourceTitle: 'Source',
  specialInputPlaceholder: 'Anything you want can be here',
  specialInputTitle: 'Special input',
  standingWater: 'Standing water',
  standingWaterDetection: 'Standing water detection',
  startButton: 'Start',
  startDateLabel: 'Start Date',
  startLabel: 'Start',
  startTimeLabel: 'Start Time',
  startTimePlaceholder: 'Enter start time',
  statusLabel: 'Status',
  statusPlaceholder: 'Enter status',
  subcribeButton: 'Subscribe',
  submergeInWater: 'Submerge in water',
  submergeInWaterDetection: 'Submerge in water detection',
  submit: 'Submit',
  subscribeTooltip: 'Unsubscribe from email notifications',
  subscriptionContent1:
    'If your organisation has a payment method and policy chosen on the SUP platform, they will be applied to this device.',
  subscriptionContent2:
    'If not, you will be required to provide them when logging into the platform. Only then you will have access to the features.',
  subscriptionPlan: 'Subscription plan',
  success: 'Success',
  successfullSavingMessage: 'Saved successfully',
  successMessageUploadingPhoto: 'The photo is uploaded!',
  successSaveMessage: 'Saved successfully',
  summaryAddressInput: 'Organisation address',
  summaryAddressPlaceholder: 'Enter organisation address',
  summaryCompanyInput: 'Organisation name',
  summaryCompanyPlaceholder: 'Enter organisation name',
  summaryCompanyRowTitle: 'Organisation',
  summaryDevicesRowTitle: 'Device(s)',
  summaryEmailInput: 'Email address',
  summaryEmailPlaceholder: 'Enter organisation email',
  summaryPhoneInput: 'Organisation phone',
  summaryPhonePlaceholder: 'Enter organisation phone',
  summarySubtitle:
    'Please check if all of the information is correct, then finalize by saving the new organisation with assigned user(s) and device(s).',
  summaryTitle: 'Summary',
  summaryUsersRowTitle: 'User(s)',
  symbolLabel: 'Symbol',
  tableInputErrorMessage: "Please check value. It's not valid",
  tableLableDatePlaceholder: 'Enter date',
  tamperDetection: 'Tamper detection',
  taxesLabel: 'excl. applicable taxes',
  temperatureLowCase: 'temperature',
  temperatureMeasurements: 'Temperature measurements',
  temperatureTooltip: 'Temperature',
  termsOfService: 'Terms of Service',
  textFieldEmphasizedHeaderTextModalDescription:
    'Info text informing the user about what are the consequences of the action.',
  textFieldEmphasizedHeaderTextModalInputName: 'Enter name of device to confirm action',
  textFieldEmphasizedHeaderTextModalTitle: 'Revoke device rights?',
  theDownloadWillStartShortly: 'The download will start shortly',
  thePhotoWillBeDeleted: 'The photo will be permanently deleted.',
  thisDeviceButton: 'This device',
  timePlaceholder: 'Enter time (hh:mm)',
  tooltipAddDevicesManually:
    'This device is assigned to a different organisation. If you want to assign it to a new one, first you will need to reassign it back to you, press refresh button and then assign to the new organisation.',
  toProceedEveryDevice: 'To proceed every device needs to have a planset selected',
  total: 'Total',
  totalNumberOfDevices: 'Total number of devices',
  unexpectedErrorHasOccurred: 'An unexpected error has occurred. Please, try again',
  unsubcribeButton: 'Unsubscribe',
  unsubscribeTooltip:
    'Subscribe to receive email notification when device detects abnormal values (e.g. Crit­ic­al tem­per­at­ure threshold is met)',
  uploadDevicesInputTextFirstPart: 'Choose file',
  uploadDevicesInputTextSecondPart: 'or drag it here (.csv or .xlsx)',
  uploadDevicesInputTextSecondPartMobile: '(.csv or .xlsx)',
  uploadDevicesModalFailedUploads: 'Failed uploads:',
  uploadDevicesModalSuccessfullyUploadedDevices: 'Successfully uploaded devices:',
  uploadDevicesModalTryAgain: 'Try again',
  uploadDevicesModalUploadingFile: 'Uploading file...',
  uploadDevicesTitle: 'Upload devices',
  uploadFileAddDeviceModal: 'Upload file',
  uploadFileDescAddDeviceModal:
    'import a CSV/XLSX file with all the serial numbers of the devices you want to assign to this organisation',
  uploadFilesModalDescriptionFirstPart: 'Choose file',
  uploadFilesModalDescriptionSecondPart: 'or drag it here',
  uploadFilesModalListTitle: 'Uploaded files',
  uploadFilesModalTitle: 'Upload firmware',
  uploadFileTooltipAddDeviceModal:
    'Recommended for adding more than 10 devices at once from different production batches.',
  userCompanyLabel: "Organisation's name",
  userDeleteInfo: 'The user will be permanently deleted.',
  userEmailLabel: 'Email address',
  userEmailPlaceholder: 'Enter email address',
  userNameLabel: 'User’s name',
  usersAddedByLabel: 'Added by',
  usersDateOfRegistrationLabel: 'Date of registration',
  usersFirstLoginLabel: 'First login',
  usersName: 'User’s name',
  usersParentCustomerLabel: 'Parent organisation',
  usersParentOrganisationPlacholder: 'Choose parent organisation',
  validationFillFieldsRequest: 'Please, correctly fill in all the fields',
  validationMessage: " doesn't look right",
  valueWillBeUpdated: 'Value will be updated the next time the device transmits',
  VATnumber: 'VAT number (optional)',
  vatNumberErrorText: 'VAT number you provided is invalid in VIES system. Please review and update.',
  VATnumberPlaceholder: 'Enter VAT number',
  view: 'View',
  vWeirLabel: 'V-weir',
  waterLevelTooltip: 'Water level',
  waterMeasurementsLabel: 'water measurements',
  weAreWorkingOnTheFixing: 'We are working on fixing the problem. Please try again.',
  weCantFindThePage: 'We can’t find the page that you’re looking for',
  wereHavingAnIssue: 'We’re having an issue, please try one of these option to get you back on track',
  wereSorry: `We’re sorry, however something went wrong when trying to load this page. Please try again`,
  wereSorry2: `We’re sorry but it appears that we can’t find the page you’ve been looking for`,
  whatAreYouLookingFor: 'What are you looking for?',
  wrongFileFormat: 'Wrong file format. Please use .csv or .xlsx',
  yearlyLabel: 'Yearly',
  yesButton: 'Yes',
};
