import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleRegisterNewEvent } from '../../../../../../api/devices/historyInsight';
import { deployDevice, useRealTimeInsights } from '../../../../../../api/realTimeInsight';
import { selectNfcFlag, setNfcFlag } from '../../../../../../app/state/app';
import { resetRealTimeData } from '../../../../../../app/state/insight';
import { selectIsAccess, selectLanguageStrings } from '../../../../../../app/state/user';
import { Loader } from '../../../../../../components/placeholders/Loader';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { useLoaderFunctions } from '../../../../../../features/placeholderComponent/loader.context';
import { PATHS } from '../../../../../../routes/paths';
import { useMediaType } from '@cthings.co/styles-utils';
import { StatusOfCard } from '../../../../../../types/statusOfCards';
import { getRequiredDateFormat } from '../../../../../../utils/date/date-format';
import { useHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { DeployBlock } from '../common/deployBlock/DeployBlock';
import { DeviceConfig } from '../common/deviceConfig/DeviceConfig';
import { DeviceTitle } from '../common/deviceTitle/DeviceTitle';
import { EventTableBlock } from '../common/eventTableBlock/EventTableBlock';
import { InsightsHeader } from '../common/insightsHeader/InsightsHeader';
import { Content, Wrapper } from '../common/styledComponents/StyledComponents';
import { NFCModal } from '../hydrantDetails/components/nFCModal/NFCModal';
import { Cards } from './components/cards/Cards';
import { History } from './components/history/History';

interface GroundWaterDetailsProps {
  pathParams: { id: string; type: string };
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  infoCardData?: any;
  defaultFilters: any;
}

export const GroundWaterDetails: FC<GroundWaterDetailsProps> = ({
  infoCardData,
  pathParams,
  accessData,
  defaultFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { desktop } = useMediaType();

  const id = pathParams.id || '';
  const type = pathParams.type || '';
  const isInsigthDetailsView = accessData?.view;

  const keywordList = [
    'GroundWaterDetailsMap',
    'GroundWaterDetailsProblemsButton',
    'GroundWaterDetailsCard',
    'GroundWaterDetailsGraph',
    'GroundWaterDetailsDeviceConfigurationTable',
  ];

  const languageStrings = useSelector(selectLanguageStrings);
  const isAccess = useSelector(selectIsAccess);
  const nfcFlag = useSelector(selectNfcFlag);

  const { addNotification } = useNotifierFunctions();
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();

  const data = useRealTimeInsights(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );

  const { address, transport_id: idDevice, deployment_status } = data.info;
  const isDeployed = deployment_status === 2;

  const [deploymentStatus, setDeploymentStatus] = useState<number>();

  const [nfcMessage, setNfcMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const { rt } = data.data;
  const { ground_water_level } = rt;

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({ message: languageStrings.copiedMessage, type: 'info' });
  };

  const onDeployPress = () => {
    setIsLoading(true);
    deployDevice(id, languageStrings, addNotification, setDeploymentStatus, setIsLoading);
  };

  useEffect(() => {
    return () => {
      dispatch(resetRealTimeData());
    };
  }, []);

  useEffect(() => {
    if (isAccess && !isInsigthDetailsView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isInsigthDetailsView]);

  useEffect(() => {
    if (nfcFlag && idDevice && id) {
      const currentDate = new Date();
      const nfcData = {
        event_id: 5,
        is_problem: false,
        device_id: id,
        transport_id: idDevice,
        address,
        ts: getRequiredDateFormat(currentDate, 'YYYY-MM-DDTHH:mm:ss'),
      };
      handleRegisterNewEvent(nfcData, setNfcMessage);
    }
  }, [nfcFlag, idDevice, id]);

  useEffect(() => {
    setDeploymentStatus(deployment_status);
  }, [deployment_status]);

  const statusOfValue = (status: number) => {
    const data: any = {
      0: StatusOfCard.NORMAL,
      1: StatusOfCard.WARNING,
      2: StatusOfCard.CRITICAL,
    };

    return data[status] || StatusOfCard.NORMAL;
  };

  const placeholderCard1Style = desktop ? { margin: '0', width: '100%' } : { margin: '0' };

  return (
    <Wrapper>
      <InsightsHeader languageStrings={languageStrings} address={address} type={type} id={id} isDeployed={isDeployed} />
      <Content>
        <DeviceTitle
          languageStrings={languageStrings}
          address={address}
          deviceId={idDevice}
          handleCopyText={handleCopyText}
        />
        {deploymentStatus !== undefined ? (
          isDeployed ? (
            <>
              <Cards
                languageStrings={languageStrings}
                placeholderCard1Style={placeholderCard1Style}
                groundWaterLevel={ground_water_level}
                statusOfWater={statusOfValue(ground_water_level.tile_color)}
                infoCardData={infoCardData}
              />
              <History defaultFilters={defaultFilters} />
              <EventTableBlock languageStrings={languageStrings} pathParams={pathParams} isInDevices />
              <DeviceConfig id={id} photos={data.info.images} />
            </>
          ) : (
            <DeployBlock
              id={id}
              languageStrings={languageStrings}
              isLoading={isLoading}
              deploymentStatus={deploymentStatus}
              photos={data.info.images}
              onDeployPress={onDeployPress}
              infoCardData={infoCardData}
            />
          )
        ) : (
          <Loader />
        )}
      </Content>
      <NFCModal
        isOpenModal={nfcFlag && !!nfcMessage}
        handleCloseModal={() => {
          dispatch(setNfcFlag(false));
        }}
        languageStrings={languageStrings}
        message={nfcMessage}
      />
    </Wrapper>
  );
};
