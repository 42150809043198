import React, { FC, useState, useEffect } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { useDispatch, useSelector } from 'react-redux';
import { CommonButton } from '@cthings.co/buttons';
import { getRequiredDateFormat } from '../../utils/date/date-format';
import { InfoButton } from '../../components/infoButton/InfoButton';
import { ReactComponent as HelpIcon } from '../../assets/help.svg';
import { TableCustomComponentState } from '../universalTable/types';
import { SaveAsMainLocation } from './components/saveAsMainLocation/SaveAsMainLocation';
import { AreYouSure } from './components/areYouSure/AreYouSure';
import { useNotifierFunctions } from '../notifier2';
import { GpsLocationRequestStart } from './components/gpsLocationRequestStart/GpsLocationRequestStart';
import { GpsLocationRequestInfo } from './components/gpsLocationRequestInfo/GpsLocationRequestInfo';
import { Address } from '../../types/address';
import { SelectAddress } from '../../components/selectAddress/SelectAddress';
import { selectLanguageStrings } from '../../app/state/user';
import { useRequestGpsCheck } from '../../api/realTimeInsight';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ModalContainer } from '../../components/newModals/ModalContainer';
import { Modal } from '../../components/newModals/Modal';
import { updateDeviceDetails } from '../../api/manage/manageDevices';
import { setDeviceInfo } from '../../app/state/insight';

export enum TypeFileds {
  TEXT = 'TEXT',
  DATE = 'DATE',
  ADDRESS = 'ADDRESS',
}

const StyledModal = styled(Modal)`
  z-index: var(--modal-second-layer) !important;
  & > div {
    width: 100%;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  max-width: 648px;
  width: 100%;
  align-items: flex-start;
  padding: 24px;

  ${media.phone} {
    width: 100%;
    position: fixed;
    padding: 24px 16px 18px;
    bottom: 0;
    border-radius: 16px 16px 0 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black')};
`;

const ManualChecks = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ManualCheckText = styled.span`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

const CountOfChecks = styled.span`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  &.red {
    color: ${colorFetch('red2')};
  }
`;

const Line = styled.div`
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background-color: ${colorFetch('gray4')};
`;

const BlockInfo = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoLabel = styled.span`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

const InfoValue = styled.span`
  text-align: end;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  &.address {
    cursor: pointer;
    color: ${colorFetch('blue2')};
    text-decoration-line: underline;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.85fr 116px;
  grid-gap: 16px;
  ${media.semiTablet} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

type buttonProps = {
  disabled: boolean;
};

const Button = styled(CommonButton)<buttonProps>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  &:disabled {
    border-color: ${colorFetch('gray3')};
    background-color: ${colorFetch('gray3')};
    color: ${colorFetch('white')};
  }
`;

interface GpsChecksType {
  is_main_location: boolean;
  user_info: { label: string; value: string | null };
  request_info: { label: string; value: string | null };
  performed_info: { label: string; value: string | null };
  location_info: {
    label: string;
    value: {
      line1: string;
      line2: string;
      zip: string;
      city: string;
      region: string;
      country: string;
      geotag: { lat: string; lng: string; alt?: string | null };
    } | null;
  };
}

interface GpsLocationDetailsProps {
  deviceId: string;
  isOpenModal: boolean;
  closeModal: () => void;
  data: {
    gps_checks: GpsChecksType[];
    gps_location_tooltip: string;
    gps_requested_count: number;
    is_requested: boolean;
    knowledgebase_url: string;
    save_location_disabled: boolean;
  };
}

export const GpsLocationDetails: FC<GpsLocationDetailsProps> = ({ deviceId, isOpenModal, closeModal, data = {} }) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const dispatch = useDispatch();

  const { addNotification } = useNotifierFunctions();

  // state
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [isOpenGpsCheckInfoModal, setIsOpenGpsCheckInfoModal] = useState(false);
  const [isOpenGpsCheckStartModal, setIsOpenGpsCheckStartModal] = useState(false);
  const [isOpenSaveAsMainLocationModal, setIsOpenSaveAsMainLocationModal] = useState(false);
  const [isOpenAreYouSureModal, setIsOpenAreYouSureModal] = useState(false);
  const [isGPSLocationCheckRequest, setIsGPSLocationCheckRequest] = useState(false);

  const {
    gps_checks = [],
    gps_requested_count = 0,
    is_requested,
    knowledgebase_url = '',
    save_location_disabled = false,
  } = data;

  const [gpsLocations, setGpsLocations] = useState<any[]>([]);

  const [isNotMainLocation, setIsNotMainLocation] = useState(false);

  const [address, setAddress] = useState<Address>({
    line1: '',
    line2: '',
    region: '',
    zip: '',
    city: '',
    country: '',
    geotag: { lat: '', lng: '', alt: '' },
  });

  const [mainLocationBody, setMainLocationBody] = useState<any>({
    line1: '',
    line2: '',
    region: '',
    zip: '',
    city: '',
    country: '',
    geotag: { lat: '', lng: '', alt: '' },
  });

  useEffect(() => {}, [mainLocationBody]);

  useEffect(() => {
    if (gps_checks.length) {
      const arrData = gps_checks.reduce((acc: any[], el: GpsChecksType, index: number) => {
        return [
          ...acc,
          [
            { type: TypeFileds.TEXT, value: el.user_info },
            { type: TypeFileds.DATE, value: el.request_info },
            { type: TypeFileds.DATE, value: el.performed_info },
            { type: TypeFileds.ADDRESS, value: { ...el.location_info, is_main_location: el.is_main_location } },
          ],
        ];
      }, []);

      setGpsLocations(arrData);
    }
  }, [gps_checks]);

  const handleCloseModal = () => {
    closeModal();
  };

  const handleRequestCheck = () => {
    setIsOpenGpsCheckInfoModal(true);
  };

  const handleSaveAsMainLocation = () => {
    isOpenAddressModal && setIsOpenAddressModal(false);

    !isOpenAddressModal && setMainLocationBody(gps_checks[gps_checks.length - 1].location_info.value);

    setIsOpenSaveAsMainLocationModal(true);
  };

  const buttons = [
    {
      value: languageStrings.gpsDetailsSaveMainLocation,
      onClick: handleSaveAsMainLocation,
      width: '100%',
      height: '32px',
      backgroundColor: colorFetch('white'),
      backgroundColorHover: colorFetch('primary'),
      borderColorHover: colorFetch('primary'),
      color: colorFetch('black'),
      disabled: save_location_disabled,
    },
    {
      value: languageStrings.gpsDetailsRequestCheck,
      onClick: handleRequestCheck,
      width: '100%',
      height: '32px',
      backgroundColor: colorFetch('white'),
      backgroundColorHover: colorFetch('primary'),
      borderColorHover: colorFetch('primary'),
      color: colorFetch('black'),
      disabled: is_requested,
    },
    {
      value: languageStrings.closeButtonValue,
      onClick: handleCloseModal,
      width: '100%',
      height: '32px',
      backgroundColor: colorFetch('primary'),
      backgroundColorHover: colorFetch('primary'),
      borderColorHover: colorFetch('primary'),
      color: colorFetch('white'),
    },
  ];

  const handleLocation = (data: any, index: number) => {
    setIsNotMainLocation(gpsLocations.length === 2 && index === 0 ? true : data.is_main_location);
    setAddress(data.value);
    setIsOpenAddressModal(true);
  };

  const handleSaveMainLocation = () => {
    updateDeviceDetails({
      deviceId,
      body: { ...data, address: mainLocationBody },
      handleSuccess: (data: any) => {
        dispatch(setDeviceInfo({ data: data.info }));
        addNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
        closeModal();
      },
      handleError: (data: any) => addNotification({ message: `${data?.response?.data?.detail}`, type: 'error' }),
    });
    setIsOpenSaveAsMainLocationModal(false);
  };

  const handleCancelSaveMainLocation = () => {
    setMainLocationBody({
      line1: '',
      line2: '',
      region: '',
      zip: '',
      city: '',
      country: '',
      geotag: { lat: '', lng: '', alt: '' },
    });
    setIsOpenSaveAsMainLocationModal(false);
  };

  const [mutateRequestGpsCheck] = useRequestGpsCheck(deviceId, addNotification, languageStrings, closeModal);

  const handleStartGpsRequest = () => {
    mutateRequestGpsCheck({});
    setIsOpenAreYouSureModal(false);
  };

  const handleCancelGpsRequest = () => {
    setIsOpenAreYouSureModal(false);
  };

  useEffect(() => {
    isOpenAddressModal && setMainLocationBody(address);
  }, [isOpenAddressModal]);

  const renderInfo = (type: TypeFileds, value: any, index: number) => {
    const dataList = {
      [TypeFileds.TEXT]: (() => (
        <InfoRow>
          <InfoLabel>{value?.label ?? 'N/A'}</InfoLabel>
          <InfoValue>{value?.value ?? 'N/A'}</InfoValue>
        </InfoRow>
      ))(),
      [TypeFileds.DATE]: (() => (
        <InfoRow>
          <InfoLabel>{value?.label ?? 'N/A'}</InfoLabel>
          <InfoValue>{value?.ts ? getRequiredDateFormat(value?.ts, 'DD.MM.YYYY') : 'N/A'}</InfoValue>
        </InfoRow>
      ))(),
      [TypeFileds.ADDRESS]: (() => (
        <InfoRow>
          <InfoLabel>{value?.label ?? 'N/A'}</InfoLabel>
          <InfoValue className={`${value?.value && 'address'}`} onClick={() => handleLocation(value, index)}>
            {value?.value ? `${value?.value?.city}, ${value?.value?.line1 ? value?.value?.line1 : ''}` : 'N/A'}
          </InfoValue>
        </InfoRow>
      ))(),
    };

    return dataList[type];
  };

  return (
    <>
      <StyledModal isOpenModal={isOpenModal}>
        <StyledModalContainer>
          <TitleWrapper>
            <Title>{languageStrings.gpsDetailsTitle}</Title>
            <InfoButton
              onClick={() => {}}
              tooltipText={languageStrings.gpsCheckTooltip}
              customIcon={<HelpIcon />}
              link={knowledgebase_url}
            />
          </TitleWrapper>
          <ManualChecks>
            <ManualCheckText>{languageStrings.gpsDetailsManualChecksPerformed}</ManualCheckText>
            <CountOfChecks>{gps_requested_count}</CountOfChecks>
          </ManualChecks>
          <BlockInfo>
            {gpsLocations.map((el: any, index: number) => (
              <>
                <Line />
                {el.map((elem: any) => renderInfo(elem.type, elem.value, index))}
              </>
            ))}
          </BlockInfo>
          <Buttons>
            {buttons.map((item: any) => {
              const {
                value,
                onClick,
                width,
                height,
                backgroundColor,
                backgroundColorHover,
                color,
                borderColorHover,
                disabled,
              } = item;

              return (
                <Button
                  onClick={onClick}
                  width={width}
                  height={height}
                  colors={{
                    main: color,
                    background: backgroundColor,
                    backgroundHover: backgroundColorHover,
                    borderHover: borderColorHover,
                  }}
                  disabled={!!disabled}
                >
                  {value}
                </Button>
              );
            })}
          </Buttons>
          {address.line1 && address.city && (
            <SelectAddress
              state={TableCustomComponentState.VIEW_MODAL}
              address={address}
              titleOfPopup={languageStrings.gpsDetailsAddressTitle}
              setValue={() => {}}
              saveToggle={false}
              handleUpdateDevice={() => {}}
              setExternalState={(isOpen: boolean) => {
                if (!isOpen) {
                  setMainLocationBody({
                    line1: '',
                    line2: '',
                    region: '',
                    zip: '',
                    city: '',
                    country: '',
                    geotag: { lat: '', lng: '', alt: '' },
                  });
                  setAddress({
                    line1: '',
                    line2: '',
                    region: '',
                    zip: '',
                    city: '',
                    country: '',
                    geotag: { lat: '', lng: '', alt: '' },
                  });
                }
                setIsOpenAddressModal(isOpen);
              }}
              externalState={isOpenAddressModal}
              isInUniversalTable={false}
              handleSaveAsMainLocation={handleSaveAsMainLocation}
              isNotMainLocation={isNotMainLocation}
              isGps
            />
          )}
        </StyledModalContainer>
      </StyledModal>

      <GpsLocationRequestInfo
        isOpenModal={isOpenGpsCheckInfoModal}
        language={languageStrings}
        onCancel={() => setIsOpenGpsCheckInfoModal(false)}
        onNext={() => {
          setIsOpenGpsCheckInfoModal(false);
          setIsOpenGpsCheckStartModal(true);
        }}
        link={knowledgebase_url}
      />
      <GpsLocationRequestStart
        isOpenModal={isOpenGpsCheckStartModal}
        language={languageStrings}
        count_of_check={gps_requested_count}
        onCancel={() => setIsOpenGpsCheckStartModal(false)}
        onStart={() => {
          setIsOpenGpsCheckStartModal(false);
          setIsOpenAreYouSureModal(true);
          setIsGPSLocationCheckRequest(true);
        }}
        link={knowledgebase_url}
      />
      <SaveAsMainLocation
        isOpenModal={isOpenSaveAsMainLocationModal}
        language={languageStrings}
        onCancel={handleCancelSaveMainLocation}
        onSave={handleSaveMainLocation}
      />
      <AreYouSure
        isOpenModal={isOpenAreYouSureModal}
        language={languageStrings}
        onCancel={handleCancelGpsRequest}
        onStart={handleStartGpsRequest}
        isGPSLocationCheckRequest={isGPSLocationCheckRequest}
      />
    </>
  );
};
