import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { useBlockBodyScroll } from '../../../../../../utils/useBlockBodyScroll';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 384px;
  height: max-content;
  padding: 25px 24px;
  display: flex;
  align-items: flex-start;
  ${media.phone} {
    width: 100%;
    position: fixed;
    padding: 24px 16px 18px;
    bottom: 0;
    border-radius: 16px 16px 0 0;
  } ;
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('black')};
  margin-bottom: 11px;
` as any; // @TODO fix type

const StyledCommonButton = styled(CommonButton)`
  width: 104px;
  align-self: flex-end;
  margin-top: 41px;

  ${media.desktop} {
    height: 40px;
  }

  ${media.phone} {
    width: 100%;
  }
`;

export const NoticeModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();
  const { black1, gray1, pureWhite } = theme.colors;

  useBlockBodyScroll(isOpen);

  return (
    <StyledModal isOpenModal={isOpen}>
      <StyledModalContainer>
        <Title>{languageStrings.notice}</Title>
        <Text type={TextType.TEXT_14_GRAY} weight={'400'} color={gray1}>
          {languageStrings.depthNotice}
        </Text>
        <StyledCommonButton
          colors={{
            main: black1,
            background: pureWhite,
          }}
          onClick={closeModal}
        >
          {languageStrings.gotIt}
        </StyledCommonButton>
      </StyledModalContainer>
    </StyledModal>
  );
};
