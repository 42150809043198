import { useEffect, useState } from 'react';

const alphabet = [...Array(26)].map((val, i) => String.fromCharCode(i + 65));
const { length: alphabetLength } = alphabet;

const getComplexityLength = (complexity: number) => {
  return Math.pow(alphabetLength, complexity);
};

const getLiteral = (index: number, complexity: number) => {
  let literal = '';
  let complexityIndex = index - getComplexityLength(complexity - 1) + 1;

  for (let i = 0; i < complexity; i++) {
    const divider = getComplexityLength(complexity - i - 1);
    const newIndex = Math.floor(complexityIndex / divider);
    const newLetter = alphabet[newIndex];
    literal = literal + newLetter;
    complexityIndex = complexityIndex % divider;
  }

  return literal;
};

const processLength = (length: number) => {
  let complexity = 1;
  let literals: any[] = [];
  let reached = false;
  let index = 0;
  while (!reached) {
    if (index === length - 1) {
      reached = true;
    }
    if (index < getComplexityLength(complexity) - 1) {
      const literal = getLiteral(index, complexity);
      literals = [...literals, literal];
      index = index + 1;
    } else {
      complexity = complexity + 1;
    }
  }

  return literals;
};

export const useLiteralNumbering = (length: number) => {
  const [literalNumbering, setLiteralNumbering] = useState(processLength(length));

  useEffect(() => {
    setLiteralNumbering(processLength(length));
  }, [length]);

  return literalNumbering;
};
