import React, { useRef } from 'react';
// @ts-ignore
import { Marker } from 'react-map-gl';
import styled from '@cthings.co/styled-components';

const MarkerWrapper = styled.div`
  & > div:first-child {
    transform: translate(-28px, -28px);
  }
`;

export const CustomMarker = ({ children, setCentre, zoom, handleClick, isReq, latitude, longitude, ...props }: any) => {
  const markerRef = useRef(null);

  const handleLocalClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleClick && handleClick();
    const calcMisplacedLat = (zoom: number, latitude: number) => {
      const y =
        (512 / (2 * Math.PI)) *
          Math.pow(2, zoom) *
          (Math.PI - Math.log(Math.tan((Math.PI / 4) * (1 + latitude / 90)))) +
        160;
      const newLat =
        ((Math.atan(Math.exp(Math.PI - ((y / 512) * 2 * Math.PI) / Math.pow(2, zoom))) / Math.PI) * 4 - 1) * 90;

      return newLat;
    };

    setCentre &&
      props.latitude &&
      props.longitude &&
      setCentre({ lat: calcMisplacedLat(zoom, props.latitude), lng: props.longitude });
  };

  return (
    <MarkerWrapper onClick={handleLocalClick} ref={markerRef}>
      <Marker {...props} latitude={latitude} longitude={longitude} style={{ zIndex: isReq ? 2 : 1 }}>
        {children}
      </Marker>
    </MarkerWrapper>
  );
};
