import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { Textarea } from './components/textarea/Textarea';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 528px;
  padding: 25px 24px;
  display: flex;
  flex-direction: column;
  ${media.phone} {
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: 16px 16px 0 0;
  }
`;

const Title = styled(Text)`
  align-self: flex-start;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  ${media.phone} {
    width: 100%;
    flex-direction: column;
    & > button {
      width: 100%;
      height: 40px;
    }
  }
`;

const CancelButton = styled(CommonButton)`
  width: 104px;
  background-color: ${colorFetch('white')};
  color: ${colorFetch('black')};
  margin-right: 16px;
  ${media.phone} {
    margin: 0 0 10px 0;
  }
`;
const AddButton = styled(CommonButton)``;

export const CommentModal = ({
  isOpenModal,
  closeModal,
  addComment,
  languageStrings,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  addComment: (comment: string) => void;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { primary, black1 } = theme.colors;
  const [value, setValue] = useState('');
  const [validatingIsStarted, setValidatingIsStarted] = useState(false);

  const handleCancelOnClick = () => {
    closeModal();
    setValidatingIsStarted(false);
  };
  const handleAddOnClick = () => {
    if (value.length > 0) {
      addComment(value);
      closeModal();
      setValidatingIsStarted(false);
    } else {
      setValidatingIsStarted(true);
    }
  };

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Title type={TextType.SEMITITLE} fontSize={'18px'} weight={'700'} margin={'0 0 18px 0'} color={black1}>
          {languageStrings.addCommentButton}
        </Title>
        <Textarea
          value={value}
          onChange={setValue}
          placeholder={languageStrings.incidentsPlaceholder}
          height={'130px'}
          fieldName={'Comment'}
          isError={validatingIsStarted && value.length === 0}
        />
        <ButtonsContainer>
          <CancelButton
            onClick={handleCancelOnClick}
            colors={{
              backgroundHover: primary,
            }}
          >
            {languageStrings.cancelButton}
          </CancelButton>
          <AddButton onClick={handleAddOnClick} width={'104px'}>
            {languageStrings.manageIncidentsAddButton}
          </AddButton>
        </ButtonsContainer>
      </StyledModalContainer>
    </StyledModal>
  );
};
