import styled from '@cthings.co/styled-components';
import { ReactComponent as CopyIcon } from '../../assets/CopySimple.svg';
import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  isEditName?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  margin-left: 6px;
  cursor: ${({ isEditName }) => (isEditName ? 'auto' : 'pointer')};
  &:hover {
    & svg > path {
      transition: all 0.3s linear;
      stroke: ${colorFetch('gray3')};
    }
  }
`;

const Icon = styled(CopyIcon)`
  & > path {
    transition: all 0.3s linear;
  }
`;

export const CopyButton = ({
  onClick,
  isEditName,
  ...props
}: {
  onClick?: () => void;
  isEditName?: boolean;
  className?: string;
}) => {
  return (
    <Wrapper onClick={onClick} isEditName={isEditName} {...props}>
      <Icon />
    </Wrapper>
  );
};
