import React, { FC, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import {
  pushAction,
  selectActions,
  selectParentOrganisation,
  updateParentOrganisation,
  selectParentOrganisationsList,
  selectProfiles,
} from '../../../../app/state/organisations';
import { TableCustomComponentState } from '../../../universalTable/types';
import { Address } from '../../../../types/address';
import { InputFieldName } from './types';
import {
  BackButton,
  ButtonContainer,
  ButtonWrapper,
  Description,
  Details,
  HeadingBlock,
  InputsContainer,
  InputsWrapper,
  NextButton,
  StepValue,
  Title,
  Tooltip,
  StyledSelect,
  Block,
} from './styled';
import { getTextWidth } from '../../../../utils/textWidth';
import { getInputStructure } from './utils';
import { AddressInput } from '../../../../components/addressInput/AddressInput';
import { InputModal } from '../../../../components/inputModal/InputModal';
import { checkFieldValidity } from '../../../../utils/validation';
import { useNotifierFunctions } from '../../../notifier2';
import { CustomModal } from '../customModal/CustomModal';
import { finaliseSimpleCreation } from '../../../../api/organisation';
import { getProfiles, useGetClientsParents } from '../../../../api/manage/manageClients';
import { debounce } from '../../../../utils/throttling';
import { useStyleContext } from '@cthings.co/styles-utils';
import { CloseButton } from '../closeButton/CloseButton';

export interface AddNewCompanyModalFirstStepProps {
  isOpen: boolean;
  data?: any;
  className?: string;
}

export const AddNewCompanyModalFirstStep: FC<AddNewCompanyModalFirstStepProps> = ({ isOpen, data, ...props }) => {
  const dispatch = useDispatch();
  const parentOrganisationsList = useSelector(selectParentOrganisationsList);
  useGetClientsParents();
  const [{ mediaType }] = useStyleContext();
  const { phone: phoneStyle } = mediaType;
  const profilesList = useSelector(selectProfiles);
  const languageStrings = useSelector(selectLanguageStrings);

  const { name, phone, email, address, parentOrganisation, profile } = useSelector(selectParentOrganisation);

  const [startedValidation, setStartedValidation] = useState(false);

  const actions = useSelector(selectActions);
  const inputStructure = getInputStructure(languageStrings);

  const inputValues = {
    name,
    phone,
    parentOrganisation,
    profile,
    email,
    address: `${address.city ? address.city + ', ' : ''}${address.line1}`,
  };

  const isFormValid = inputStructure.every(({ fieldName, validationRule }) =>
    checkFieldValidity({
      value:
        fieldName === 'parentOrganisation' || fieldName === 'profile'
          ? inputValues[fieldName].name
          : inputValues[fieldName],
      validationRule,
    }),
  );

  const { addNotification } = useNotifierFunctions();

  //@TODO add type here
  const handleInputOnChange = (e: any, fieldName: InputFieldName) => {
    const isParentOrgField = fieldName === 'parentOrganisation';
    const isProfileField = fieldName === 'profile';
    if (isParentOrgField || isProfileField) {
      dispatch(updateParentOrganisation({ [fieldName]: e }));
      if (isParentOrgField) {
        dispatch(updateParentOrganisation({ profile: { name: '', id: '' } }));
        getProfiles(e.id, dispatch, addNotification, languageStrings);
      }
    } else {
      dispatch(updateParentOrganisation({ [fieldName]: e.currentTarget.value }));
    }
  };

  const orgCreationSuccessText = languageStrings.organisationCreationSuccess;
  const validationRequestText = languageStrings.validationFillFieldsRequest;
  const somethingWentWrongtext = languageStrings.somethingWentWrong;

  const handleValidationNotification = () => {
    addNotification({
      message: validationRequestText,
      type: 'error',
    });
  };

  const handleCallbackNotification = (isSuccess: boolean, messageText?: string) =>
    addNotification({
      message: messageText || (isSuccess ? orgCreationSuccessText : somethingWentWrongtext),
      type: isSuccess ? 'success' : 'error',
    });

  const handleNext = debounce(() => {
    setStartedValidation(true);
    if (actions.next) {
      if (isFormValid) {
        dispatch(pushAction('next'));
      } else {
        handleValidationNotification();
      }
    } else {
      if (isFormValid) {
        finaliseSimpleCreation(
          { name, email, phone, address, parent: parentOrganisation.id, profile: profile.id },
          handleCallbackNotification,
        );
      } else {
        handleValidationNotification();
      }
    }
  }, 700);

  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  const setAddress = (address: Address) => {
    dispatch(updateParentOrganisation({ address }));
  };

  return (
    <CustomModal isOpen={isOpen} {...props}>
      <HeadingBlock>
        <Title>{languageStrings.addNewCompany}</Title>
        <Block>
          {actions.next && <StepValue>1/3</StepValue>}
          <CloseButton />
        </Block>
      </HeadingBlock>
      <Description>{languageStrings.addNewCompanyDesc}</Description>
      <Details>{languageStrings.addNewCompanyDetails}</Details>
      <InputsWrapper>
        {inputStructure.map((el) => {
          const isLocationSelect = el.fieldName === 'address';
          const isParentOrganisationSelect = el.fieldName === 'parentOrganisation';
          const isProfileSelect = el.fieldName === 'profile';
          const { fieldName, label, placeholder, validationRule, tooltip } = el;
          const validationOptions = {
            isError: !checkFieldValidity({
              value:
                isProfileSelect || isParentOrganisationSelect ? inputValues[fieldName].name : inputValues[fieldName],
              validationRule,
            }),
            startedValidation,
          };

          return (
            <InputsContainer>
              {isLocationSelect ? (
                <AddressInput
                  value={inputValues[fieldName]}
                  title={label}
                  placeholder={placeholder}
                  address={address}
                  setAddress={setAddress}
                  addressState={TableCustomComponentState.EDIT}
                  {...validationOptions}
                />
              ) : isParentOrganisationSelect || isProfileSelect ? (
                <StyledSelect
                  width={phoneStyle ? '100%' : '248px'}
                  value={inputValues[fieldName]}
                  values={isParentOrganisationSelect ? parentOrganisationsList : profilesList}
                  label={label}
                  placeholder={
                    isProfileSelect && !parentOrganisation.name.length
                      ? languageStrings.chooseParentOrgFirst
                      : placeholder
                  }
                  disabled={isProfileSelect && !parentOrganisation.name.length}
                  //@TODO add type
                  onChange={(e: any) => handleInputOnChange(e, el.fieldName)}
                  languageStrings={languageStrings}
                  isPendingRequest={isProfileSelect ? !profilesList.length : !parentOrganisationsList}
                  {...validationOptions}
                />
              ) : (
                <InputModal
                  width={'100%'}
                  value={inputValues[fieldName]}
                  onChange={(e: FormEvent<HTMLInputElement>) => handleInputOnChange(e, el.fieldName)}
                  name={label}
                  placeholder={placeholder}
                  {...validationOptions}
                />
              )}
              {tooltip && (
                <Tooltip
                  leftOffset={getTextWidth(label, { font: '12px Poppins, sans-serif' })}
                  onClick={() => {}}
                  tooltipText={tooltip}
                  isInfoIcon
                />
              )}
            </InputsContainer>
          );
        })}
      </InputsWrapper>
      <ButtonWrapper>
        <ButtonContainer>
          <BackButton onClick={handleBack}>{languageStrings.backButton}</BackButton>
          <NextButton onClick={handleNext}>
            {actions.next ? languageStrings.nextButton : languageStrings.saveButton}
          </NextButton>
        </ButtonContainer>
      </ButtonWrapper>
    </CustomModal>
  );
};
