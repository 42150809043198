import styled from '@cthings.co/styled-components';
import { Graph } from '../../../../../../../components/graph/Graph';
import { media } from '@cthings.co/styles-utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  padding: 10px 46px 46px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${media.tablet} {
    padding: 10px 16px 45px 16px;
  }
`;

export const GraphRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${media.phone} {
    flex-direction: column;
  }
`;

export const StyledGraph = styled(Graph)`
  padding: 0;
  width: 100%;
  ${media.tablet} {
    width: 100%;
  }
` as any;
