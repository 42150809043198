import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { Select } from '../../../../../../components/universalSelect/Select';
import { useGetProvisioningCustomers } from '../../../../../../api/provisioning';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user';

const CustomerSelect = styled(Select)`
  width: 100%;
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
    background-color: ${colorFetch('white')};
  }
` as any; // @TODO fix type

interface OrganisationSelectProps {
  localSecret: any;
  setProvisioningData: (props: any) => void;
}

export const OrganisationSelect: FC<OrganisationSelectProps> = ({ setProvisioningData, localSecret }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const [customer] = useGetProvisioningCustomers(localSecret);

  const handleCustomerChange = (value: any) => setProvisioningData((prev: any) => ({ ...prev, client_id: value?.id }));

  return (
    <CustomerSelect
      values={customer || []}
      placeholder={languageStrings.chooseOrganisation}
      fieldName={''}
      label={languageStrings.manageClientsTableHeaderItemsClient}
      onChange={handleCustomerChange}
      className={'static'}
      isSearch={false}
      languageStrings={languageStrings}
      tooltipText={languageStrings.provisioningTooltipOrganisations}
    />
  );
};
