import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasurementDeviceInsights } from '../../../../../../api/devices/measurementInsights';
import { resetRealTimeData } from '../../../../../../app/state/insight';
import { selectIsAccess, selectLanguageStrings } from '../../../../../../app/state/user';
import { Loader } from '../../../../../../components/placeholders/Loader';
import { useLoaderFunctions } from '../../../../../../features/placeholderComponent/loader.context';
import { PATHS } from '../../../../../../routes/paths';
import { useHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { DeviceTitle } from '../common/deviceTitle/DeviceTitle';
import { EventTableBlock } from '../common/eventTableBlock/EventTableBlock';
import { Header } from '../common/header/Header';
import { Content, Wrapper } from '../common/styledComponents/StyledComponents';
import { CardManningPairDevice } from './components/cardManningPairDevice/CardManningPairDevice';
import { History } from './components/history/History';

interface ManholeDetailsProps {
  pathParams: { id: string };
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  defaultFilters: any;
}

export const ManholeDetails: FC<ManholeDetailsProps> = ({ pathParams, accessData, defaultFilters }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const id = pathParams.id || '';

  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();

  const keywordList = ['ManholesDetailsMap', 'ManholesDetailsCard', 'ManholesDetailsGraph'];

  const data = useMeasurementDeviceInsights(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  ) as any;

  const languageStrings = useSelector(selectLanguageStrings);

  const isAccess = useSelector(selectIsAccess);

  const isInsigthDetailsView = accessData?.view;

  useEffect(() => {
    return () => {
      dispatch(resetRealTimeData());
    };
  }, []);

  useEffect(() => {
    if (isAccess && !isInsigthDetailsView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isInsigthDetailsView]);

  return (
    <Wrapper>
      <Header data={data} languageStrings={languageStrings} />
      <Content>
        <DeviceTitle languageStrings={languageStrings} data={data} />
        {data ? (
          <>
            <CardManningPairDevice data={data} languageStrings={languageStrings} />
            <History defaultFilters={defaultFilters} />
            <EventTableBlock languageStrings={languageStrings} pathParams={pathParams} isInDevices />
          </>
        ) : (
          <Loader />
        )}
      </Content>
    </Wrapper>
  );
};
