import React, { FC, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { Select } from '../../../../../../components/universalSelect/Select';
import { InfoButton } from '../../../../../../components/infoButton/InfoButton';
import ManholesImage from '../../../../../../assets/TwoManholes.png';
import ManholesImageMobile from '../../../../../../assets/TwoManholesMobile.png';
import { ReactComponent as ArrowIconMobile } from '../../../../../../assets/VectorArrow.svg';
import { ReactComponent as ArrowIconDesktop } from '../../../../../../assets/VectorArrowDesktop.svg';
import { CommonButton } from '../../../../../../components/commonButton/CommonButton';
import { useBlockBodyScroll } from '../../../../../../utils/useBlockBodyScroll';
import { useStyleContext } from '@cthings.co/styles-utils';
import { Input } from '../../../../../../components/input/Input';
import { TooltipPlace } from '../../../../../../components/tooltip/Tooltip';
import { FieldsType, isValidField } from '../../../../../../utils/validation';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { useSelector } from 'react-redux';
import { useTheme } from '@cthings.co/styled-components';

export const StyledModal = styled(Modal)`
  ${media.phone} {
    align-items: flex-end;
    & > div {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  display: flex;
  flex-direction: column;
  align-items: unset;
  padding: 24px;
  ${media.phone} {
    width: 100%;
    padding: 24px 16px;
    border-radius: 12px 12px 0px 0px;
  }
`;

const SelectsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 30px 0;

  ${media.phone} {
    margin: 16px 0 16px 0;
  }
`;

const StyledInput = styled(Input)`
  margin: 0 0 12px 0 !important;
`;

const ArrowIconRow = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.phone} {
    flex-direction: column;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  ${media.phone} {
    flex-direction: column-reverse;
  }
`;

export interface ChooseDevicesProps {
  values: any;
  isOpenModal: boolean;
  onCancel: () => void;
  onNext?: (props: any) => void;
}

export const ChooseDevices: FC<ChooseDevicesProps> = ({ values, onCancel, onNext, isOpenModal, ...props }) => {
  const theme = useTheme();
  const { black1, gray1, pureWhite, gray3 } = theme.colors;
  const [media] = useStyleContext();
  const [isFieldsValid, setIsFieldsValid] = useState(false);
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);
  useBlockBodyScroll(isOpenModal);

  const [data, setData] = useState<any>({
    name: '',
    device_1: '',
    device_2: '',
  });

  const handleChange = (value: any, fieldName: string) => {
    setData((val: any) => ({
      ...val,
      [fieldName]: value,
    }));
  };

  const handleNextButton = () => {
    if (isFieldsValid) {
      onNext && onNext(data);
    } else {
      addNotification({
        message: 'Please fill in all fields',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    const isValid = Object.values(data).every((item: any) => {
      return item && item.name !== '';
    });
    isValid && setIsFieldsValid(true);
  }, [data]);

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer width={'564px'}>
        <Text type={TextType.TEXT_14_BLACK} color={black1} weight={'600'} fontSize={'18px'}>
          Pair up two manholes
        </Text>
        <SelectsBlock>
          <StyledInput
            width={'100%'}
            name={'Name the new pair of manholes'}
            placeholder={'Enter name'}
            borderColor={gray3}
            value={data.name}
            onChange={(e: any) => handleChange(e.target.value, 'name')}
          />
          <div style={{ display: 'flex' }}>
            <Text margin={'0 0 4px 6px'} type={TextType.TEXT_12_BLACK} color={gray1} lineHeight={'16px'}>
              Choose two devices from manholes that are connected
            </Text>
            {!media.mediaType.phone && (
              <InfoButton
                onClick={() => {}}
                tooltipPlace={TooltipPlace.RIGHT}
                tooltipText={'Height above sea level of device #1 has to be greater than of device #2'}
                margin={'0 0 4px 4px'}
              />
            )}
          </div>
          <SelectsRow>
            <Select
              placeholder={'Choose device #1'}
              fieldWidth={media.mediaType.phone ? '100%' : '248px'}
              value={data.device_1}
              values={values}
              margin={media.mediaType.phone ? '0 0 8px 0' : '0'}
              positionTop={'44px'}
              height={'40px'}
              onChange={(e: any) => handleChange(e, 'device_1')}
              languageStrings={languageStrings}
            />
            {media.mediaType.phone ? (
              <ArrowIconRow>
                <ArrowIconMobile />
              </ArrowIconRow>
            ) : (
              <ArrowIconDesktop />
            )}
            <Select
              placeholder={'Choose device #2'}
              fieldWidth={media.mediaType.phone ? '100%' : '248px'}
              value={data.device_2}
              values={values}
              margin={media.mediaType.phone ? '8px 0 0 0' : '0'}
              positionTop={'44px'}
              height={'40px'}
              onChange={(e: any) => handleChange(e, 'device_2')}
              languageStrings={languageStrings}
            />
          </SelectsRow>
        </SelectsBlock>
        <img src={media.mediaType.phone ? ManholesImageMobile : ManholesImage} alt={'Manholes'} />
        <ButtonsRow>
          <CommonButton
            backgroundColor={pureWhite}
            color={black1}
            height={media.mediaType.phone ? '40px' : '32px'}
            margin={media.mediaType.phone ? '0' : '0 16px 0 0'}
            width={media.mediaType.phone ? '100%' : '116px'}
            onClick={onCancel}
          >
            Cancel
          </CommonButton>
          <CommonButton
            height={media.mediaType.phone ? '40px' : '32px'}
            margin={media.mediaType.phone ? '0 0 10px 0' : '0'}
            width={media.mediaType.phone ? '100%' : '116px'}
            onClick={handleNextButton}
          >
            Next
          </CommonButton>
        </ButtonsRow>
      </StyledModalContainer>
    </StyledModal>
  );
};
