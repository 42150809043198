import { Text } from '@bit/first-scope.text';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../app/state/user';
import { CopyButton } from '../../../../../../../../components/copyButton/CopyButton';
import { useNotifierFunctions } from '../../../../../../../../features/notifier2';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  max-width: auto;
  box-sizing: border-box;
`;

const Title = styled(Text)`
  display: flex;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${colorFetch('gray1_5')};
` as any; // @TODO fix type

const CopyBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
`;

const DeviceId = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('blue2')};
  color: ${colorFetch('blue2')};
  margin-right: 4px;
  cursor: pointer;
` as any; // @TODO fix type

const StyledCopyButton = styled(CopyButton)`
  cursor: pointer;
  margin-left: 3px;
  &:hover {
    & > span {
      color: ${colorFetch('gray2')};
    }
    & > svg > path {
      stroke: ${colorFetch('gray3')};
    }
  }
` as any; // @TODO fix type

export interface InfoProps {
  deviceTitle: string;
  deviceName: string;
  onClick?: () => void;
  className?: string;
}

export const Info: React.FC<InfoProps> = ({ deviceTitle, deviceName, onClick, className }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({
      message: languageStrings.copiedMessage,
      type: 'info',
    });
  };

  return (
    <Wrapper>
      <Title className={className}>
        {deviceTitle}
        <CopyBlock>
          <DeviceId onClick={onClick}>{deviceName}</DeviceId>
          <StyledCopyButton onClick={() => handleCopyText(deviceName)} />
        </CopyBlock>
      </Title>
    </Wrapper>
  );
};
