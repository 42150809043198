import { Text, TextType } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import React, { FC, useState, useCallback, useEffect } from 'react';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { throttle } from '../../utils/throttling';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type SelectProps = {
  borderRadius: string;
  withoutBorder: boolean;
};

const Select = styled.div<SelectProps>`
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px 30px 4px 12px;
  border: ${({ withoutBorder }) => !withoutBorder && '1px solid'};
  border-color: ${colorFetch('gray3')};
  border-radius: ${({ borderRadius }) => borderRadius};
  transition: all 0.3s;
  &.open {
    border-color: ${colorFetch('primary')};
  }
`;

type ValueProps = {
  withError?: boolean;
};

const Value = styled(Text)<ValueProps>`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ withError }) => withError && colorFetch('red2')};
  &.list {
    overflow: visible;
    white-space: pre-line;
    text-overflow: clip;
    padding: 4px 8px;
    transition: all 0.3s;
    &:hover {
      color: ${colorFetch('primary')};
    }
  }
  &.active {
    color: ${colorFetch('primary')};
  }
` as any; // @TODO fix type

const StyledArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: all 0.3s;
  &.open {
    transform: translateY(-50%) rotate(-180deg);
  }
`;

type ListProps = {
  borderRadius: string;
  height: string;
  top: string;
  left: string;
  maxWidth: string;
  withoutBorder: boolean;
};

const List = styled.div<ListProps>`
  z-index: 1;
  box-sizing: border-box;
  height: 0;
  max-height: 180px;
  padding: 4px;
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${({ borderRadius }) => borderRadius};
  position: absolute;
  transform: translateY(100%);
  bottom: -10px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s;
  pointer-events: none;
  &.open {
    height: ${({ height }) => height};
    opacity: 1;
    pointer-events: auto;
  }
  &.hackOverflow {
    position: fixed;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    max-width: ${({ maxWidth }) => maxWidth};
    width: ${({ withoutBorder }) => withoutBorder && '100%'};
    transform: none;
    bottom: initial;
    right: initial;
    transition: all 0.05s;
  }
`;

const ScrollContainer = styled.div`
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

interface UniversalTableSelectProps {
  value: { id: string; name: string } | null;
  list: { id: string; name: string }[];
  onChange: Function;
  placeholder?: string;
  withError?: boolean;
  parentRef?: any;
  hackOverflow?: boolean;
  withoutBorder?: boolean;
}

export const UniversalTableSelect: FC<UniversalTableSelectProps> = ({
  value,
  list,
  onChange,
  placeholder,
  withError,
  parentRef,
  hackOverflow = false,
  withoutBorder = false,
  ...props
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const { primary } = theme.borderRadius;

  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [node, setNode] = useState<HTMLElement | null>(null);
  const [top, setTop] = useState(0);

  useEffect(() => {
    if (node) {
      const currentTop = node.getBoundingClientRect().top;

      setTop(currentTop);
    }
  }, [isOpenList]);

  const selectRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      setNode(node);
    }
  }, []);

  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpenList && !node?.contains(e.target)) {
        setIsOpenList(false);
      }
    },
    [isOpenList],
  );

  useEffect(() => {
    parentRef && parentRef?.current
      ? parentRef?.current.addEventListener('click', outsideHandler)
      : window.addEventListener('click', outsideHandler);
    return () => {
      parentRef && parentRef?.current
        ? parentRef?.current.removeEventListener('click', outsideHandler)
        : window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler, parentRef?.current]);

  const setTopOffset = useCallback(
    throttle((e: Event) => {
      if (node) {
        const currentTop = node.getBoundingClientRect().top;

        setTop(currentTop);
      }
    }, 20),
    [node],
  );

  useEffect(() => {
    if (node && isOpenList) {
      parentRef && parentRef?.current
        ? parentRef.current.addEventListener('scroll', setTopOffset)
        : window.addEventListener('scroll', setTopOffset);
    }
    return () => {
      if (node && isOpenList) {
        parentRef && parentRef?.current
          ? parentRef.current.removeEventListener('scroll', setTopOffset)
          : window.removeEventListener('scroll', setTopOffset);
      }
    };
  }, [setTopOffset, isOpenList, parentRef?.current]);

  useEffect(() => {
    if (parentRef?.current) {
      top < parentRef.current.getBoundingClientRect().top - 30 && setIsOpenList(false);

      top > parentRef.current.getBoundingClientRect().top + parentRef.current.getBoundingClientRect().height - 30 &&
        setIsOpenList(false);
    }
  }, [parentRef?.current, top]);

  return (
    <Select
      className={isOpenList ? 'open' : ''}
      ref={selectRef}
      onClick={() => setIsOpenList((value: boolean) => !value)}
      borderRadius={primary}
      withoutBorder={withoutBorder}
      {...props}
    >
      <Value type={TextType.TEXT_14_GRAY} color={value?.name ? gray1 : gray2} withError={withError}>
        {value?.name || placeholder || 'N/A'}
      </Value>
      <StyledArrow className={isOpenList ? 'open' : ''} />
      <List
        className={`${isOpenList && 'open'} ${hackOverflow && 'hackOverflow'}`}
        onClick={(e: any) => e.stopPropagation()}
        top={top + (node?.getBoundingClientRect().height ?? 0) + 10 + 'px'}
        left={node?.getBoundingClientRect().left + 'px'}
        height={list.length * 32 + 10 + 'px'}
        maxWidth={
          withoutBorder
            ? (node?.getBoundingClientRect().width ?? 0) + 'px'
            : (node?.getBoundingClientRect().width ?? 0) + 54 + 'px'
        }
        borderRadius={primary}
        withoutBorder={withoutBorder}
      >
        <ScrollContainer>
          {list.map((item: { id: string; name: string }, index: number) => (
            <Value
              className={`${item?.id === value?.id && 'active'} list`}
              onClick={() => {
                setIsOpenList(false);
                onChange(item);
              }}
              type={TextType.TEXT_14_GRAY}
              color={gray1}
            >
              {item?.name ?? 'N/A'}
            </Value>
          ))}
        </ScrollContainer>
      </List>
    </Select>
  );
};
