import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { GenerateReportModalState } from './types';
import { TypeOfParameter } from '../../../containers/common/reports/components/generateReportModal/GenerateReportModal';

export const setParameters = (state: WritableDraft<GenerateReportModalState>, action: PayloadAction<any>) => {
  if (action.payload.type === TypeOfParameter.CUSTOMER) {
    state.customers.parameters = action.payload.data;
  } else if (action.payload.type === TypeOfParameter.LOCATION) {
    state.locations.parameters = action.payload.result;
  } else if (action.payload.type === TypeOfParameter.RECIPIENTS) {
    state.recipients.parameters = action.payload.result;
  }
};
export const clearLocation = (state: WritableDraft<GenerateReportModalState>, action: PayloadAction<any>) => {
  state.locations.parameters = action.payload.locations;
  state.locations.selected_parameters = [];
  state.locations.isSelectAll = false;
};
export const clearCustomer = (state: WritableDraft<GenerateReportModalState>, action: PayloadAction<any>) => {
  state.customers.parameters = action.payload.customers;
  state.customers.selected_parameters = [];
  state.customers.isSelectAll = false;
};
export const clearRecipients = (state: WritableDraft<GenerateReportModalState>, action: PayloadAction<any>) => {
  state.recipients.parameters = action.payload.recipients;
  state.recipients.selected_parameters = [];
  state.recipients.isSelectAll = false;
};
export const clearPeriod = (state: WritableDraft<GenerateReportModalState>) => {
  state.ts_from = '';
  state.ts_to = '';
};
export const toggleIsSelectedParameter = (
  state: WritableDraft<GenerateReportModalState>,
  action: PayloadAction<any>,
) => {
  if (action.payload.type === TypeOfParameter.CUSTOMER) {
    const itemIndex = state.customers.parameters.findIndex((el: any) => el.id === action.payload.id);
    state.customers.parameters[itemIndex].isSelected = !state.customers.parameters[itemIndex].isSelected;
    state.customers.selected_parameters = state.customers.parameters
      .filter((parameter) => parameter.isSelected === true)
      .map((item: any) => item.id);
    if (
      state.customers.parameters.filter((item: any) => item.isSelected === true).length <
      state.customers.parameters.length
    ) {
      state.customers.isSelectAll = false;
    } else if (
      state.customers.parameters.filter((item: any) => item.isSelected === true).length ===
      state.customers.parameters.length
    ) {
      state.customers.isSelectAll = true;
    }
  } else if (action.payload.type === TypeOfParameter.LOCATION) {
    const itemIndex = state.locations.parameters.findIndex((el: any) => el.id === action.payload.id);
    if (itemIndex !== -1) {
      state.locations.parameters[itemIndex].isSelected = !state.locations.parameters[itemIndex].isSelected;
      state.locations.selected_parameters = state.locations.parameters.filter(
        (parameter: any) => parameter.isSelected === true,
      );
    }

    if (
      state.locations.parameters.filter((item: any) => item.isSelected === true).length <
      state.locations.parameters.length
    ) {
      state.locations.isSelectAll = false;
    } else if (
      state.locations.parameters.filter((item: any) => item.isSelected === true).length ===
      state.locations.parameters.length
    ) {
      state.locations.isSelectAll = true;
    }
  } else if (action.payload.type === TypeOfParameter.RECIPIENTS) {
    const itemIndex = state.recipients.parameters.findIndex((el: any) => el.id === action.payload.id);
    state.recipients.parameters[itemIndex].isSelected = !state.recipients.parameters[itemIndex].isSelected;
    state.recipients.selected_parameters = state.recipients.parameters
      .filter((parameter) => parameter.isSelected === true)
      .map((item: any) => item.id);
    if (
      state.recipients.parameters.filter((item: any) => item.isSelected === true).length <
      state.recipients.parameters.length
    ) {
      state.recipients.isSelectAll = false;
    } else if (
      state.recipients.parameters.filter((item: any) => item.isSelected === true).length ===
      state.recipients.parameters.length
    ) {
      state.recipients.isSelectAll = true;
    }
  } else {
    console.warn('Please add type of parameter!');
  }
};
export const toggleIsSelectedAllParameter = (
  state: WritableDraft<GenerateReportModalState>,
  action: PayloadAction<any>,
) => {
  if (action.payload.type === TypeOfParameter.CUSTOMER) {
    state.customers.parameters.forEach((item: any, index: number) => {
      const { isSelected } = item;
      if (!isSelected && !state.customers.isSelectAll) {
        state.customers.parameters[index].isSelected = true;
      } else if (isSelected && state.customers.isSelectAll) {
        state.customers.parameters[index].isSelected = false;
      }
    });
    state.customers.isSelectAll = !state.customers.isSelectAll;
    state.customers.selected_parameters = state.customers.parameters
      .filter((parameter) => parameter.isSelected === true)
      .map((item: any) => item.id);
  } else if (action.payload.type === TypeOfParameter.LOCATION) {
    state.locations.parameters.forEach((item: any, index: number) => {
      const { isSelected } = item;
      if (!isSelected && !state.locations.isSelectAll) {
        state.locations.parameters[index].isSelected = true;
      } else if (isSelected && state.locations.isSelectAll) {
        state.locations.parameters[index].isSelected = false;
      }
    });
    state.locations.isSelectAll = !state.locations.isSelectAll;
    state.locations.selected_parameters = state.locations.parameters.filter(
      (parameter) => parameter.isSelected === true,
    );
  } else if (action.payload.type === TypeOfParameter.RECIPIENTS) {
    state.recipients.parameters.forEach((item: any, index: number) => {
      const { isSelected } = item;
      if (!isSelected && !state.recipients.isSelectAll) {
        state.recipients.parameters[index].isSelected = true;
      } else if (isSelected && state.recipients.isSelectAll) {
        state.recipients.parameters[index].isSelected = false;
      }
    });
    state.recipients.isSelectAll = !state.recipients.isSelectAll;
    state.recipients.selected_parameters = state.recipients.parameters
      .filter((parameter) => parameter.isSelected === true)
      .map((item: any) => item.id);
  } else {
    console.warn('Please add type of parameter!');
  }
};
export const toggleIsOpenPopup = (state: WritableDraft<GenerateReportModalState>, action: PayloadAction<any>) => {
  if (action.payload.type === TypeOfParameter.CUSTOMER) {
    state.customers.isOpenPopup = action.payload.value;
    state.locations.isOpenPopup = action.payload.value === true && false;
    state.recipients.isOpenPopup = action.payload.value === true && false;
  } else if (action.payload.type === TypeOfParameter.LOCATION) {
    state.locations.isOpenPopup = action.payload.value;
    state.customers.isOpenPopup = action.payload.value === true && false;
    state.recipients.isOpenPopup = action.payload.value === true && false;
  } else if (action.payload.type === TypeOfParameter.RECIPIENTS) {
    state.recipients.isOpenPopup = action.payload.value;
    state.customers.isOpenPopup = action.payload.value === true && false;
    state.locations.isOpenPopup = action.payload.value === true && false;
  } else {
    console.warn('Please add type of parameter!');
  }
};
export const setDates = (state: WritableDraft<GenerateReportModalState>, action: PayloadAction<any>) => {
  state.ts_from = action.payload.startDate;
  state.ts_to = action.payload.endDate;
};
export const toggleIsOpenMobileDateModal = (
  state: WritableDraft<GenerateReportModalState>,
  action: PayloadAction<any>,
) => {
  state.isOpenMobileDateModal = action.payload.isOpenMobileDateModal;
};
export const toggleIsError = (state: WritableDraft<GenerateReportModalState>, action: PayloadAction<any>) => {
  if (action.payload.type === TypeOfParameter.CUSTOMER) {
    state.customers.isError = action.payload.value;
  } else if (action.payload.type === TypeOfParameter.LOCATION) {
    state.locations.isError = action.payload.value;
  } else if (action.payload.type === TypeOfParameter.RECIPIENTS) {
    state.recipients.isError = action.payload.value;
  } else {
    console.warn('Please add type of parameter!');
  }
};
export const toggleIsValidatingStarted = (
  state: WritableDraft<GenerateReportModalState>,
  action: PayloadAction<any>,
) => {
  state.validatingIsStarted = action.payload.value;
};
export const toggleIsUploadingInProcess = (
  state: WritableDraft<GenerateReportModalState>,
  action: PayloadAction<any>,
) => {
  if (action.payload.type === TypeOfParameter.CUSTOMER) {
    state.customers.isUploadingInProcess = action.payload.value;
  } else if (action.payload.type === TypeOfParameter.LOCATION) {
    state.locations.isUploadingInProcess = action.payload.value;
  } else if (action.payload.type === TypeOfParameter.RECIPIENTS) {
    state.recipients.isUploadingInProcess = action.payload.value;
  } else {
    console.warn('Please add type of parameter!');
  }
};

export const reducers = {
  setParameters,
  clearLocation,
  clearCustomer,
  clearRecipients,
  clearPeriod,
  toggleIsSelectedParameter,
  toggleIsSelectedAllParameter,
  toggleIsOpenPopup,
  setDates,
  toggleIsOpenMobileDateModal,
  toggleIsError,
  toggleIsValidatingStarted,
  toggleIsUploadingInProcess,
};
