import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: ${colorFetch('gray5')};
  padding: 8px 12px;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  cursor: auto;
`;

export const AddressField = ({ languageStrings }: { languageStrings?: any }) => {
  const theme = useTheme();

  return (
    <Wrapper theme={theme}>
      <Text type={TextType.TEXT_14_GRAY} weight={'400'}>
        {languageStrings.incidentAddressPlaceholder}
      </Text>
    </Wrapper>
  );
};
