import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const ExtraWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

export const StyledTitle = styled.h3`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colorFetch('gray1_5')};
  margin: 0;
`;
