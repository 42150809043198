import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchInput } from '../../../../../../../containers/common/reports/components/searchInput/SearchInput';
import { ReactComponent as SearchPlaceholder } from '../../../../../../../assets/SearchPlaceholder.svg';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  max-height: 342px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
`;

const CustomPlaceholder = styled.div`
  height: 246px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled(SearchInput)`
  border-bottom: none;
  height: 32px;
`;

const StyledText = styled(Text)`` as any; // @TODO fix type

export const MobileSearchField = ({
  value,
  values,
  languageStrings,
  setFilteredList,
  setSearchValue,
  onChange,
}: {
  value?: any;
  values?: any;
  languageStrings?: any;
  setFilteredList: (props: any) => void;
  setSearchValue: (props: any) => void;
  onChange: (props: any) => void;
}) => {
  const theme = useTheme();
  const { primary, gray1, gray2 } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');

  const filterParameterList = (flag: string, items: any) => {
    return (
      items &&
      items.filter((item: any) => {
        return item?.name?.length > 0 && item?.name?.toLowerCase()?.indexOf(flag.toLowerCase()) !== -1;
      })
    );
  };

  useEffect(() => {
    setFilteredList(filterParameterList(searchInputValue, values));
    setSearchValue(searchInputValue);
  }, [searchInputValue]);

  const handleInutOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const handleChange = (value: any) => {
    onChange(value);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  return (
    <Wrapper>
      <InputWrapper>
        <StyledInput
          value={searchInputValue}
          // @TODO fix type
          handleOnChange={handleInutOnChange as any}
          handleClearInput={handleClearInput}
          languageStrings={languageStrings}
        />
      </InputWrapper>
      {filterParameterList(searchInputValue, values).length ? (
        <>
          {filterParameterList(searchInputValue, values).map((el: any) => (
            <StyledText
              onClick={() => handleChange(el)}
              type={TextType.TEXT_14_BLACK}
              color={value === el ? primary : gray1}
              margin={'20px 0 20px 0'}
            >
              {el.name}
            </StyledText>
          ))}
        </>
      ) : (
        <CustomPlaceholder>
          <SearchPlaceholder />
          <Text type={TextType.TEXT_14_BLACK} color={gray2} weight={'400'}>
            No results found
          </Text>
          <Text type={TextType.TEXT_14_BLACK} color={gray2}>
            Save the value
          </Text>
        </CustomPlaceholder>
      )}
    </Wrapper>
  );
};
