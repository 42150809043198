import React from 'react';

export const Check = () => {
  return (
    <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M99 50.001C99 76.7868 77.2858 98.501 50.5 98.501C23.7142 98.501 2 76.7868 2 50.001C2 23.2152 23.7142 1.50098 50.5 1.50098C77.2858 1.50098 99 23.2152 99 50.001Z"
        fill="#33894E"
        stroke="#ADD0B8"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.4341 35.9219L43.2751 66.0809L29.5664 52.3723"
        stroke="white"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
