import styled from '@cthings.co/styled-components';

type ValueProp = {
  vatColor?: string;
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Value = styled.span<ValueProp>`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ vatColor }) => vatColor};
`;
