import { useEffect, useState } from 'react';
// @ts-ignore
import { v1 as uuidv1, v4 as uuidv4 } from 'uuid';

export const createId = (keyword: string, index: number, isShort?: boolean): string => {
  const timestampPart = uuidv1();
  const randomPart = uuidv4();
  const combinedUuid = !isShort ? keyword + index + randomPart + timestampPart : keyword + randomPart;
  return combinedUuid;
};

export const useUuids = (amount: number, keyword: string) => {
  const [uuids, setUuids] = useState([]);
  const createIndexArray = (limit: number): number[] => {
    let i = 0;
    let indexArray: number[] = [];
    for (i; i < limit; i++) {
      indexArray = [...indexArray, i];
    }
    return indexArray;
  };

  useEffect(() => {
    const requiredAmount = amount || 1;
    const ids = createIndexArray(requiredAmount).map((index) => {
      return createId(keyword, index);
    });
    setUuids(ids as any);
  }, []);
  return [uuids] as const;
};
