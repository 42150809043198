import React, { FC } from 'react';
import { useTheme } from '@cthings.co/styled-components';
import { SubscriptionCardTypes } from './types';
import { selectLanguageStrings } from '../../app/state/user';
import { useSelector } from 'react-redux';
//styles
import { Wrapper, StyledText, Row, Column, Circle, UnderLine, StyledList, ItemList, Icon } from './styled';
//images
import { ReactComponent as GreenIcon } from '../../assets/GreenTick.svg';
import { ReactComponent as LightGreenIcon } from '../../assets/LightGreenTick.svg';
import { ReactComponent as RedIcon } from '../../assets/RedTick.svg';
//data
import { basicMeasurements, basic, advanced, premium } from './data';

export interface SubscriptionCardProps {
  selectedId?: string;
  data: any;
  isPerMonth?: boolean;
  onChange: (props: any) => void;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({ data, selectedId, onChange, isPerMonth }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();

  const { frontend_plan_id: type, currency, price, id } = data;
  const { additionalShadow10 } = theme.shadows;
  const isSelected = selectedId === id;
  const isIncluded = price === 'Included';

  const localData = (type: SubscriptionCardTypes) => {
    switch (type) {
      case SubscriptionCardTypes.PLAN_SMART_ALARM_BASIC:
        return basic(languageStrings);
      case SubscriptionCardTypes.PLAN_SMART_RADAR_BASIC:
        return basicMeasurements(languageStrings);
      case SubscriptionCardTypes.PLAN_SMART_RADAR_ADVANCED:
        return advanced(languageStrings);
      case SubscriptionCardTypes.PLAN_SMART_RADAR_PREMIUM || SubscriptionCardTypes.PLAN_SMART_RADAR_INCLUDED:
        return premium(languageStrings);
    }
  };

  const selectedData = localData(type);

  const handleClick = () => {
    onChange(id);
  };

  return (
    <Wrapper onClick={handleClick} additionalShadow10={additionalShadow10} isSelected={isSelected}>
      <Row className="title">
        <StyledText className="title">{selectedData?.title}</StyledText>
        {isSelected ? <GreenIcon /> : <Circle />}
      </Row>
      {selectedData?.subtitle && (
        <Row className="sub-title">
          <StyledText className="sub-title">{selectedData?.subtitle}</StyledText>
        </Row>
      )}
      <Column className="price">
        <Row isIncluded={isIncluded} className="price">
          <Row>
            <StyledText isIncluded={isIncluded} className="price">
              {isIncluded ? languageStrings.includedLablel : price}
            </StyledText>
            <StyledText className="price">{!isIncluded && currency}</StyledText>
          </Row>
          {!isIncluded && (
            <Column>
              <StyledText className="price-subtitle">{languageStrings.perDevice}</StyledText>
              <StyledText className="price-subtitle">
                {isPerMonth ? languageStrings.perMonth : languageStrings.perYear}
              </StyledText>
            </Column>
          )}
        </Row>
        {!isIncluded && <StyledText className="price-subtitle">{languageStrings.taxesLabel}</StyledText>}
      </Column>
      <UnderLine />
      <Column>
        {selectedData?.content.map((item: any) => {
          const { title, subtitle, list, icon } = item;
          const isGreenIcon = icon === 'green';

          return (
            <Row className="content">
              <Icon>{isGreenIcon ? <LightGreenIcon /> : <RedIcon />}</Icon>
              <Column>
                <StyledText>{title}</StyledText>
                {subtitle && <StyledText className="content-subtitle">{subtitle}</StyledText>}
                {list && (
                  <StyledList>
                    {list.map((item: any) => {
                      return <ItemList>{item}</ItemList>;
                    })}
                  </StyledList>
                )}
              </Column>
            </Row>
          );
        })}
      </Column>
    </Wrapper>
  );
};
