import React, { useEffect } from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from '../../../../../../../../utils/react-router-dom-abstraction';
import { deleteMaintanenceGlobal, updateMaintenanceEvents } from '../../../../../../../../api/hydrant';
import { selectIsAccess, selectLanguageStrings } from '../../../../../../../../app/state/user';
import { AddButton } from '../../../../../../../../components/addButton/AddButton';
import { API_URL } from '../../../../../../../../consts';
import { useTableFunctions, withTableContextProvider } from '../../../../../../../../features/universalTable/context';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../../../../../../features/universalTable/types';
import { UniversalTable } from '../../../../../../../../features/universalTable/UniversalTable';
import { PATHS } from '../../../../../../../../routes/paths';
import { View } from '../../../../../../../../routes/routeInterfaces';
import { useMediaType, useWindowSize } from '@cthings.co/styles-utils';
import { DateField } from './components/dateField/DateField';
import { StatusComponent } from './components/statusComponent/StatusComponent';
import { Time } from './components/time/Time';
import { media } from '@cthings.co/styles-utils';
import moment from 'moment';
import { useQueryParams } from '../../../../../../../../utils/useQueryParams';

const StyledAddButton = styled(AddButton)`
  ${media.tablet} {
    & > span {
      display: none;
    }
  }
`;

const MaintenanceTableContainer = ({
  pathParams,
  accessData,
}: {
  pathParams: any;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}) => {
  const deviceId = pathParams.id;
  const { startAddProcess, resetTable, toggleEdit, toggleAdd } = useTableFunctions();
  const history = useHistory();
  const isAccess = useSelector(selectIsAccess);
  const isUserAccessManagementView = accessData?.view;

  const languageStrings = useSelector(selectLanguageStrings);
  const mediaType = useMediaType();
  const keyword = 'MaintenanceTable';
  const offsetKey = 'offsetMaintenance';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const width = useWindowSize()[0];
  const conditionForTime = /^(\d{4})-(\d\d)-(\d\dT)(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:00?.\w{0,7}$/;

  useEffect(() => {
    if (isAccess && !isUserAccessManagementView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isUserAccessManagementView]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  const handleUpdateItems = (
    id: string,
    data: any,
    index: number,
    updateLocalItem: any,
    toggleUpdaiting: any,
    handleAddNotification: Function,
    setIsSaveButtonDisabled: any,
    languageStrings?: any,
  ) => {
    updateMaintenanceEvents(
      id,
      deviceId,
      data,
      index,
      updateLocalItem,
      toggleUpdaiting,
      toggleAdd,
      toggleEdit,
      handleAddNotification,
      setIsSaveButtonDisabled,
      languageStrings,
    );
  };
  const handleDeleteItem = (
    id: string[],
    deleteMaintanence: any,
    deleteCheckedMaintanences: any,
    handleAddNotification: Function,
    languageStrings?: any,
  ) => {
    deleteMaintanenceGlobal(
      id,
      deleteMaintanence,
      deleteCheckedMaintanences,
      deviceId,
      handleAddNotification,
      languageStrings,
    );
  };

  const { phoneS, tablet, narrowMonitor } = useMediaType();

  return (
    <UniversalTable
      placeholderProps={{
        keyword,
        style: { height: 'max-content' },
        conditionalStyle: { overflow: 'hidden' },
        placeholderOptions: { countRows: 6 },
      }}
      keyword={keyword}
      offset={+offset}
      offsetKey={offsetKey}
      mainApiUrl={`${API_URL}/v2/maintenance/${deviceId}/`}
      pathParams={pathParams}
      queryParams={queryParams}
      sortingData={sortingData}
      sortingKeys={sortingKeys}
      tablePath={View.USER_INSIGHTS_DETAILS}
      allowDelete={accessData?.delete && width > 769}
      actionSet={[{ type: ActionType.EDIT }]}
      inlineActionSet={[{ type: InlineActionType.DETAILS }]}
      updateItems={handleUpdateItems}
      deleteItems={handleDeleteItem}
      fieldNameOfDeletedItems={'description'}
      pageSize={10}
      withHeaderInMobile={true}
      withHeaderGridTemplate={
        phoneS
          ? '80px 1fr 32px'
          : tablet
          ? '1fr 4fr 32px'
          : narrowMonitor
          ? '24px 1fr 1fr 1fr 182px'
          : '24px 1fr 1fr 1fr 1fr 3fr 182px'
      }
      titleActionComponent={
        <>
          {accessData?.edit && (
            <StyledAddButton
              onClick={() => {
                startAddProcess();
              }}
              title={mediaType.tablet ? languageStrings.modalTitleAdd : languageStrings.modalTitleAdd}
              width={mediaType.tablet ? '32px' : '120px'}
              height="32px"
            />
          )}
        </>
      }
      accessData={accessData}
      columnStructureTriggers={[phoneS, tablet, narrowMonitor]}
      columnStructure={[
        {
          fieldName: 'ts',
          placeholder: languageStrings.tableLableDatePlaceholder,
          label: languageStrings.insightDetailsEventData,
          defaultValue: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
          type: RowItemType.CUSTOM,
          shownInHeader: tablet,
          component: (value: any, state?: TableCustomComponentState) => (
            <DateField globalValue={value} state={state} languageStrings={languageStrings} />
          ),
        },
        {
          fieldName: 'ts',
          placeholder: languageStrings.timePlaceholder,
          label: languageStrings.insightDetailsEventTime,
          defaultValue: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
          type: RowItemType.CUSTOM,
          conditionForValidationInCustom: conditionForTime,
          component: (value: any, state?: TableCustomComponentState) => (
            <Time
              globalValue={moment.utc(value).format('HH:mm')}
              state={state}
              name={languageStrings.insightDetailsEventTime}
              languageStrings={languageStrings}
            />
          ),
        },
        {
          fieldName: 'personnel',
          placeholder: languageStrings.personnelPlaceholder,
          label: languageStrings.personnelLabel,
          defaultValue: '',
          // excludedFromView: tablet,
        },
        {
          fieldName: 'state',
          placeholder: languageStrings.statusPlaceholder,
          label: languageStrings.statusLabel,
          defaultValue: '',
          type: RowItemType.CUSTOM,
          // excludedFromView: tablet,
          component: (value: any, state?: TableCustomComponentState) => (
            <StatusComponent globalValue={value} state={state} languageStrings={languageStrings} />
          ),
        },
        {
          fieldName: 'description',
          placeholder: languageStrings.descriptionPlaceholder,
          label: languageStrings.descriptionLabel,
          defaultValue: '',
          modalColumns: 2,
          shownInHeader: tablet,
        },
      ]}
    />
  );
};

export const MaintenanceTable = withTableContextProvider(MaintenanceTableContainer);
