import React, { useEffect } from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/user';
import { useTableFunctions, withTableContextProvider } from '../../../../../../../features/universalTable/context';
import { withMobileDraggableContainer } from '../../../../../../../components/draggableContainerHOC/withMobileDraggableContainer';
import { UniversalTable } from '../../../../../../../features/universalTable/UniversalTable';
import { media, mediaType } from '../../../../../../../styles/media';
import { API_URL } from '../../../../../../../consts';
import { View } from '../../../../../../../routes/routeInterfaces';
import { InlineActionType, RowItemType } from '../../../../../../../features/universalTable/types';
import { Date } from './components/date/Date';
import { Place } from './components/place/Place';
import { Problem } from './components/problem/Problem';
import { ProblemsInfoButton } from '../../../../../../../components/DraggableTable/components/ProblemsInfoButton';
import { useCustomHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { useWindowSize } from '@cthings.co/styles-utils';
import { useParams } from '@cthings.co/router-dom';
import { useQueryParams } from '../../../../../../../utils/useQueryParams';
import { useTheme } from '@cthings.co/styled-components';

// @TODO fix media types
const Wrapper = styled.div`
  width: fit-content;
  height: max-content;
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow2};
  border-radius: ${({ theme }) => theme.borderRadius.primary};

  ${media.desktop} {
    width: 100%;
  }
  ${media.tablet} {
    border-radius: ${({ theme }: any) => `${theme.borderRadius.additional24} ${theme.borderRadius.additional24} 0 0`};
    height: 100%;
    & > div > div > div:first-child {
      border-radius: 24px 24px 0 0;
    }
    & > div > div > div:last-child {
      border-radius: 0;
    }
  }
`;

const DraggableTablePlain = ({
  pageSize,
  className,

  boundingRect,
  selectedDevice,
  setSelectedDevice,
  setViewport,
}: {
  pageSize: number;
  boundingRect: any;
  selectedDevice: string;
  setSelectedDevice: any;
  setViewport: any;
  className?: string;
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  const { resetTable } = useTableFunctions();
  const keyword = 'ProblemsTable';
  const offsetKey = 'offsetProblems';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};

  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pathParams = useParams();

  const history = useCustomHistory();
  const screenWidth = useWindowSize()[0];
  const mapPwidth = boundingRect ? Math.floor(boundingRect.width) : 1;
  const mapPheight = boundingRect ? Math.floor(boundingRect.height) : 1;
  const selectedDeviceForRequest = selectedDevice ? `&device_id=${selectedDevice}` : '';

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper className={className} theme={theme}>
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet },
        }}
        mainApiUrl={`${API_URL}/v2/devices/aggregated/insight/problems?&map_pwidth=${mapPwidth}&map_pheight=${mapPheight}${selectedDeviceForRequest}&`}
        keyword={keyword}
        offset={+offset}
        offsetKey={offsetKey}
        tablePath={View.USER_PROBLEMS}
        indexOfPayloadItem={'0'}
        pathParams={pathParams}
        queryParams={queryParams}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        withHeaderInMobile={true}
        withHeaderGridTemplate={screenWidth > 768 ? '1fr 2fr 3fr 22px' : '1fr 2fr 22px'}
        updateItems={() => {}}
        deleteItems={() => {}}
        actionSet={[]}
        isScrollableRows={screenWidth < 769}
        pageSize={pageSize}
        withoutQuestionMarkBeforeLimit
        handleRowOnClick={(value: any) => {
          const { device_id } = value;
          setSelectedDevice(device_id);

          setTimeout(() => {
            setViewport((currentViewport: any) => ({
              ...currentViewport,
              zoom: currentViewport.zoom - 0.00001,
            }));
          }, 3000);
        }}
        inlineActionSet={[
          {
            type: InlineActionType.CUSTOM,
            component: <ProblemsInfoButton onClick={() => {}} />,
            callback: (value: any) => {
              const { device_id, device_type } = value;
              const to = View.USER_INSIGHTS_DETAILS;
              to &&
                history.push(to, {
                  pathParams: { id: device_id, type: device_type, offsetEvents: 0, offsetMaintenance: 0 },
                });
            },
          },
        ]}
        columnStructure={[
          {
            fieldName: 'ts',
            label: languageStrings.problemsDate,
            placeholder: '',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any, state: any) => {
              return <Date value={value} state={state} />;
            },
            shownInHeader: true,
          },
          {
            fieldName: 'address',
            label: languageStrings.problemsPlace,
            placeholder: '',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any, state: any) => {
              return <Place value={value} state={state} />;
            },
            shownInHeader: true,
          },
          {
            fieldName: 'event',
            label: languageStrings.problemsProblem,
            placeholder: '',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any, state: any) => {
              return <Problem value={value} state={state} />;
            },
          },
        ]}
      />
    </Wrapper>
  );
};
const DraggableTableWithLoader = withMobileDraggableContainer(true)(DraggableTablePlain);
export const DraggableTable = withTableContextProvider(DraggableTableWithLoader);
