import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as CpuSmall } from '../../../../../../../assets/cpuSmall.svg';
import { ReactComponent as ArrowImage } from '../../../../../../../assets/mini_arrow.svg';
import { ReportsModal } from '../../../../../../../components/generateReports/ReportsModal';
import { Map } from '../../../../../../../components/map/Map';
import { View } from '../../../../../../../routes/routeInterfaces';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { NavLink, useCustomHistory, useHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { stringifyUrl } from 'query-string';
import { injectPathParams } from '@cthings.co/utils';
import { getPath } from '../../../../../../../routes';
import ss from '../../../../../../../utils/ss';
import { LsValueType } from '../../../../../../../enums/LsValueType';

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 45px;
  left: 45px;
  ${media.tablet} {
    top: 16px;
    left: 16px;
  }
`;

const BlockForButtons = styled.div`
  position: absolute;
  top: 45px;
  right: 45px;
  display: flex;
  ${media.tablet} {
    top: 16px;
    right: 16px;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
`;

const StyledCommonButton = styled(CommonButton)`
  width: max-content;
  padding: 0 15px;
  margin-right: 10px;
  &:hover > div > svg > path {
    stroke: ${colorFetch('white')};
    transition: stroke 0.3s ease;
  }

  ${media.tablet} {
    width: 32px;
    & > div > div {
      display: none;
    }
  }

  ${media.desktop} {
    &:hover > div > svg > path {
      stroke: ${({ color }: any) => color};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GenerateButton = styled(StyledCommonButton)`
  margin-left: 10px;
`;

const ButtonText = styled.div`
  margin-left: 10px;
`;

export const InsightsHeader = ({
  languageStrings,
  address,
  type,
  id,
  isDeployed,
  openReportModal,
  isShowRecipientsBlock,
}: {
  languageStrings: any;
  address: any;
  type: string;
  id: string;
  isDeployed: boolean;
  isShowRecipientsBlock?: boolean;
  openReportModal?: () => void;
}) => {
  const history = useHistory();
  const customHistory = useCustomHistory();
  const theme = useTheme();
  const { primary, pureWhite } = theme.colors;
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const onBackButtonClick = () => {
    history.goBack();
  };

  const to = View.USER_MANAGE_DEVICES_DETAILS;

  const href = stringifyUrl({
    url: injectPathParams(getPath(to), { id, offset: 0 }),
  });

  return (
    <>
      <Map
        placeholderProps={{
          keyword: 'ManholeDetailsMap',
        }}
        zoom={13}
        isGradiented
        latitude={parseFloat(address.geotag.lat)}
        longitude={parseFloat(address.geotag.lng)}
        deviceType={type}
        staticDisplay
      />
      <BackButtonWrapper>
        <StyledCommonButton onClick={onBackButtonClick}>
          <ButtonContainer>
            <ArrowImage />
            <ButtonText>{languageStrings.backButton}</ButtonText>
          </ButtonContainer>
        </StyledCommonButton>
      </BackButtonWrapper>

      <BlockForButtons>
        <StyledLink access={[]} to={`${href}?access_token=${token}&expires=${exp}`}>
          <StyledCommonButton
            colors={{
              main: primary,
              background: pureWhite,
            }}
          >
            <ButtonContainer>
              <CpuSmall />
              <ButtonText>{languageStrings.insightDetailsGoToDeviceButton}</ButtonText>
            </ButtonContainer>
          </StyledCommonButton>
        </StyledLink>
        <ReportsModal deviceId={id} isShowRecipientsBlock={isShowRecipientsBlock} />
      </BlockForButtons>
    </>
  );
};
