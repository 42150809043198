import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';

export const setDate = (state: WritableDraft<any>, action: PayloadAction<any>) => {
  state.date = action.payload.value;
};

export const reducers = {
  setDate,
};
