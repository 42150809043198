import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: reducers,
});

export const { setDate } = incidentsSlice.actions;

export default incidentsSlice.reducer;
