import React, { useMemo } from 'react';
import styled from '@cthings.co/styled-components';
import { Dropdown } from '../../../dropdown/Dropdown';
import { CommonButton } from '@cthings.co/buttons';
import { DateRangeWithIcon } from '../../../tableInput/components/dateRangeWithIcon/DateRangeWithIcon';
import { media } from '@cthings.co/styles-utils';
import { useWindowSize } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { GraphType } from '../../types';
import { Export } from '../exportModal/Export';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import moment from 'moment';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 20px 0;
  align-self: flex-start;
  ${media.desktop} {
    flex-wrap: wrap;
  }
  ${media.desktop} {
    display: none;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledDropdown = styled(Dropdown)`
  & > div {
    margin-right: 14px;
  }
  &.disabled {
    & > div {
      background-color: ${colorFetch('gray5')};
      border-color: ${colorFetch('gray5')};
      pointer-events: none;
      & > svg {
        opacity: 0;
      }
    }
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: auto;
`;

type ApplyButtonProps = {
  isApplyAvailable: boolean | undefined;
};

const ApplyButton = styled(CommonButton)<ApplyButtonProps>`
  margin-top: auto;
  pointer-events: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
  user-select: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
  cursor: ${({ isApplyAvailable }) => (isApplyAvailable ? 'pointer' : 'auto')};
`;

type DateRangeProps = {
  isDateAvailable: boolean;
  colors: string;
};

const DateRange = styled(DateRangeWithIcon)<DateRangeProps>`
  justify-content: flex-end;
  pointer-events: ${({ isDateAvailable }) => (isDateAvailable ? 'auto' : 'none')};
  & > div {
    flex-direction: row;
    & > div > div {
      width: 200px;
      & > div > div > div {
        & > div > svg > path {
          stroke: ${({ colors }) => colors};
        }
        & > span {
          color: ${({ colors }) => colors};
          font-weight: 400 !important;
        }
      }
    }
  }
`;

export const formatDateToString = (date: Date) => {
  return getRequiredDateFormat(date, 'YYYY-MM-DDTHH:mm:ssZ');
};

export const getStartDate = (date: Date) => {
  return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
};

export const getEndDate = (date: Date) => {
  return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
};

export const formatStringToDate = (str: string) => {
  return new Date(str);
};

export const EventsHeader = ({
  data,
  filters,
  savedFilters,
  setCurrentFilters,
  applyOnClick,
  setFilters,
  applyingStatus,
  isApplyButtonActive,
}: {
  applyOnClick: () => void;
  filters: any;
  savedFilters: any;
  setCurrentFilters: any;
  setFilters: (filterName: string, value: { tooltip: string; id: string; key: string }) => void;
  applyingStatus: number;
  data: { data: any[]; device_id: string; ts_from: Date; ts_to: Date };
  isApplyButtonActive?: boolean;
}) => {
  const theme = useTheme();
  const { pureWhite, gray1, gray3, primary, secondary2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const period = !!filters?.periods?.default_value ? filters?.periods?.default_value : filters?.periods?.items_list[3];
  const periodListRedux = filters?.periods?.items_list;

  const findKey = (arr: any[], id: string) => arr.find((item: any) => item.id === id).key;

  const isApplyButtonAvailable = isApplyButtonActive && !!period;

  const defaultDate = formatDateToString(new Date());
  const currentTsFromDate = filters?.ts_from || defaultDate;
  const currentTsToDate = filters?.ts_to || defaultDate;

  const applyButtonText =
    applyingStatus === 0
      ? languageStrings.applyButton
      : applyingStatus === 1
      ? languageStrings.applyButton1
      : languageStrings.applyButton2;

  const periodOnChangeArr = useMemo(
    () => [
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setDate(new Date().getDate() - 7))),
          ts_to: getEndDate(new Date()),
        }));
      },
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
          ts_to: getEndDate(new Date()),
        }));
      },
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setMonth(new Date().getMonth() - 6))),
          ts_to: getEndDate(new Date()),
        }));
      },
    ],
    [setCurrentFilters],
  );

  const periodList = periodListRedux.map((item: any, index: number, arr: any[]) => {
    return index !== arr.length - 1
      ? { name: item.tooltip, id: item.id, key: item.key, onClick: periodOnChangeArr[index] }
      : { name: item.tooltip, id: item.id, key: item.key };
  });

  const handleChangeValue = (value: Date, type: 'start' | 'end') => {
    const startDate = type === 'start' ? getStartDate(value) : filters?.ts_from;
    const endDate = type === 'end' ? getEndDate(value) : filters?.ts_to;

    setFilters('periods', { tooltip: periodList[3].name, id: periodList[3].id, key: periodList[3].key });
    setCurrentFilters((filters: any) => ({ ...filters, ts_from: startDate, ts_to: endDate }));

    if (new Date(endDate) <= new Date(startDate)) {
      const newStartDate = getStartDate(new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 7)));
      setCurrentFilters((filters: any) => {
        return { ...filters, ts_from: newStartDate, ts_to: endDate };
      });
    }
  };

  return (
    <Wrapper>
      <DropdownWrapper>
        <SettingsWrapper>
          <StyledDropdown
            className={applyingStatus === 1 ? 'disabled' : ''}
            value={{
              name: period?.tooltip ? period?.tooltip : languageStrings.filtersSelectError,
              id: period?.id,
            }}
            valuesList={periodList}
            fieldName={languageStrings.dataPeriodLabel}
            onChange={(value: { name: string; id: string }) => {
              const key: string = findKey(periodList, value.id);
              setFilters('periods', { tooltip: value.name, id: value.id, key });
            }}
            placeholder={languageStrings.dataPeriodLabel}
            isDisabled={!periodList[0].id}
            languageStrings={languageStrings}
          />
          <DateRange
            value={{
              startDate: new Date(currentTsFromDate),
              endDate: new Date(currentTsToDate),
            }}
            handleChangeValue={{
              setStartDate: (value: any) => handleChangeValue(value, 'start'),
              setEndDate: (value: any) => handleChangeValue(value, 'end'),
            }}
            options={{
              startOptions: {
                maxValue: new Date(
                  new Date(formatStringToDate(currentTsToDate)).setDate(
                    formatStringToDate(currentTsToDate).getDate() - 7,
                  ),
                ),
                isDisabled: applyingStatus === 1 || !filters?.ts_from,
              },
              endOptions: {
                maxValue: new Date(),
                minValue: null,
                isDisabled: applyingStatus === 1 || !filters?.ts_to,
              },
            }}
            isEditInProcess
            isDateAvailable={!!period}
            colors={!!period ? gray1 : gray3}
          />
        </SettingsWrapper>
      </DropdownWrapper>
      <ButtonsWrapper>
        <ApplyButton
          onClick={applyOnClick}
          colors={{
            main: pureWhite,
            background: isApplyButtonAvailable ? primary : gray3,
            backgroundHover: isApplyButtonAvailable ? secondary2 : gray3,
            border: isApplyButtonAvailable ? primary : gray3,
            borderHover: isApplyButtonAvailable ? secondary2 : gray3,
          }}
          width={'180px'}
          height={'32px'}
          isApplyAvailable={isApplyButtonAvailable}
        >
          {applyButtonText}
        </ApplyButton>
        <Export
          isExportButtonActive={data?.data?.length > 1}
          applyingStatus={applyingStatus}
          type={GraphType.EVENTS}
          data={data}
          filters={filters}
          ts_from={savedFilters.ts_from}
          ts_to={savedFilters.ts_to}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};
