import { useLocation, matchRoutes } from '@cthings.co/router-dom';
import { View } from './routeInterfaces';
import { useState, useEffect } from 'react';
import { VIEW_PATH_PAIRS } from './paths';

export const useCurrentView = () => {
  const [currentView, setCurrentView] = useState<typeof View[keyof typeof View]>(View.REDIRECT);
  const { pathname } = useLocation();

  useEffect(() => {
    const index = VIEW_PATH_PAIRS.findIndex(({ path }) => {
      const routes = matchRoutes([{ path, exact: true, strict: false }], pathname);

      return !!routes?.length && !!routes[0].pathname;
    });
    index !== -1 ? setCurrentView(VIEW_PATH_PAIRS[index].view) : setCurrentView(View.ERROR_404);
  }, [pathname]);

  return {
    currentView,
  };
};
