import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import { stringifyUrl } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import styled from '@cthings.co/styled-components';
import { addIncident, useGetIncidentParameters, useIncidentsOffset } from '../../../../../api/incidents';
import { useLoggedUserInfo } from '../../../../../api/manage/manageUsers';
import { setDate } from '../../../../../app/state/incidents';
import { selectAccess, selectLanguage } from '../../../../../app/state/user';
import { DateField } from '../../../../../components/dateField/DateField';
import { API_URL } from '../../../../../consts';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { UniversalTable } from '../../../../../features/universalTable/UniversalTable';
import { useTableContext } from '../../../../../features/universalTable/context';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../../../features/universalTable/types';
import { getPath, injectPathParams } from '../../../../../routes/paths';
import { View } from '../../../../../routes/routeInterfaces';
import { useMediaType } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { useHistory } from '../../../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../../../utils/useQueryParams';
import { useIncidentTypes } from '../../useIncidentTypes';
import { AddressField } from '../addressField/AddressField';
import { CommentsField } from '../commentsField/CommentsField';
import { DetailsButton } from '../detailsButton/DetailsButton';
import { DeviceItem } from '../deviceItem/DeviceItem';
import { ResolveButton } from '../resolveButton/ResolveButton';
import { Severity } from '../severity/Severity';
import { TimeField } from '../timeField/TimeField';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Table = ({
  data,
  incident_id,
  customRowClick,
  openModal,
  isLoadingInProcess,
  languageStrings,
  setIsDataLoaded,
  isOpenResolveModal,
}: {
  data: any;
  incident_id: string;
  customRowClick: (value: any) => void;
  setLocalIncident: React.Dispatch<any>;
  openModal: () => void;
  isLoadingInProcess: boolean;
  languageStrings?: any;
  withHeaderGridTemplate?: string;
  withHeaderInMobile?: boolean;
  setIsDataLoaded?: (props: boolean) => void;
  isOpenResolveModal?: boolean;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const { addNotification } = useNotifierFunctions();

  const [{ isAddInProcess, isUpdaitingInProcess }] = useTableContext();

  const accessData = useSelector(selectAccess).management.client;

  const keyword = 'IncidentsTable';
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };

  const queryParams = useQueryParams() || {};
  const pathParams = useParams();

  const pageSize = 12;

  const { offset, isOffsetReady } = useIncidentsOffset({
    id: incident_id,
    itemsPerPage: pageSize,
    keyword,
    offset: queryParams[offsetKey],
  });

  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const userInfo = useLoggedUserInfo();
  const [types, setTypes] = useGetIncidentParameters();

  const devicesList = types ? types.devices.map((item: any) => ({ name: item.name, id: item.id })) : [];
  const dispatch = useDispatch();
  const [resolvedList, setResolvedList] = useState<string[]>([]);

  const history = useHistory();

  const toIncidents = View.USER_INCIDENTS;
  const lang = useSelector(selectLanguage).shortName;
  const { handleCreateIncidentType, handleUpdateIncidentType, handleDeleteIncidentType } = useIncidentTypes(setTypes);

  const openDetailslModal = (id: string) => {
    history.push(
      stringifyUrl({
        url: injectPathParams(getPath(toIncidents), pathParams),
        query: { ...queryParams, incident_id: id },
      }),
    );
  };

  const closeDetailsModal = () => {
    const { incident_id: _, ...restQueries } = queryParams || {};
    history.push(
      stringifyUrl({
        url: injectPathParams(getPath(toIncidents), pathParams),
        query: restQueries,
      }),
    );
  };

  const openResolveModalHandle = (id: string) => {
    openModal();
    history.push(
      stringifyUrl({
        url: injectPathParams(getPath(toIncidents), pathParams),
        query: { ...queryParams, incident_id: id },
      }),
    );
  };

  useEffect(() => {
    if (!isAddInProcess) {
      dispatch(setDate({ value: getRequiredDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ssZ') }));
    }
  }, [isAddInProcess]);

  const { tablet, desktop, narrowMonitor, monitor, wideMonitor } = useMediaType();

  const actionSectionWidth = lang === 'DE' ? '260px' : '220px';

  const columnStructureTriggers = [tablet, desktop, narrowMonitor, monitor, wideMonitor];

  return (
    <UniversalTable
      placeholderProps={{
        keyword,
        placeholderOptions: { borderRadiusTable: !tablet, countRows: 10 },
      }}
      mainApiUrl={isOffsetReady ? `${API_URL}/v2/incidents/` : undefined}
      setIsDataLoaded={setIsDataLoaded}
      keyword={keyword}
      pageSize={pageSize}
      offset={+offset}
      offsetKey={offsetKey}
      sortingData={sortingData}
      sortingKeys={sortingKeys}
      pathParams={pathParams}
      tablePath={View.USER_INCIDENTS}
      withHeaderInMobile={!tablet}
      withHeaderGridTemplate={
        !narrowMonitor ? '1fr 1fr 0.75fr 0.75fr 0.75fr 0.5fr 220px' : tablet ? '1fr 1fr' : '1fr 1fr 0.75fr 220px'
      }
      actionSectionGridWidth={actionSectionWidth}
      updateItems={(
        id: string,
        data: any,
        index: number,
        updateLocalItem: any,
        toggleUpdaiting: any,
        addNotification: (notification?: { message: string; type: string }) => void,
        setIsSaveButtonDisabled: (status: boolean) => void,
        languageStrings?: any,
      ) => {
        addIncident(
          id,
          data,
          index,
          updateLocalItem,
          toggleUpdaiting,
          addNotification,
          userInfo.id,
          setIsSaveButtonDisabled,
          languageStrings,
        );
      }}
      actionSet={[{ type: ActionType.EDIT }]}
      customRowClick={customRowClick}
      columnStructureTriggers={[types, ...columnStructureTriggers]}
      apiTriggers={[isUpdaitingInProcess]}
      inlineActionSet={
        !tablet
          ? [
              {
                type: InlineActionType.CUSTOM,
                component: (value: any) => {
                  const isOpen = data._id === value.id;
                  if (isOpen && (data.resolved || value.resolved) && !resolvedList.includes(data._id || value.id)) {
                    setResolvedList([...resolvedList, data._id]);
                  }

                  return (
                    <DetailsButton
                      device_name={value.device.transport_id}
                      deviceId={value?.device_id}
                      deviceType={value?.device?.type}
                      address={
                        value && value.device
                          ? `${value.device?.address?.city}, ${value.device?.address?.line1}`
                          : 'N/A'
                      }
                      incidentData={data}
                      isLoadingInProcess={isLoadingInProcess}
                      userInfo={userInfo}
                      isOpenModal={!isOpenResolveModal && incident_id === value.id}
                      closeDetailsModal={closeDetailsModal}
                      lang={lang}
                      languageStrings={languageStrings}
                    />
                  );
                },
                callback: (value: any) => {
                  const { id } = value;
                  openDetailslModal(id);
                },
              },
              {
                type: InlineActionType.CUSTOM,
                component: (value: any) => {
                  const isOpen = data?.id === value?.id;

                  if (isOpen && (data?.resolved || value.resolved) && !resolvedList.includes(data?.id || value?.id)) {
                    setResolvedList([...resolvedList, data?.id]);
                  }
                  const ts =
                    value.resolved || resolvedList.includes(value.id)
                      ? getRequiredDateFormat(value?.resolved?.ts, 'DD.MM.YYYY, HH:mm')
                      : isOpen && data?.resolved
                      ? getRequiredDateFormat(data?.resolved?.ts, 'DD.MM.YYYY, HH:mm')
                      : null;
                  return (
                    <ResolveButton
                      date={ts}
                      openModal={() => openResolveModalHandle(value.id)}
                      languageStrings={languageStrings}
                    />
                  );
                },
              },
            ]
          : [{ type: InlineActionType.DETAILS }]
      }
      accessData={accessData}
      columnStructure={[
        {
          fieldName: 'incident_type',
          placeholder: languageStrings.chooseTypePlaceholder,
          label: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
          type: RowItemType.JOINED_SELECT,
          defaultValue: { name: '', id: '' },
          selectItems: types.incident_types,
          // @TODO manage with createItem, updateItem, deleteItem callback during RENG integration
          createItem: handleCreateIncidentType,
          updateItem: handleUpdateIncidentType,
          deleteItem: (props: any) =>
            handleDeleteIncidentType({
              ...props,
              handleError: () =>
                addNotification({
                  message: languageStrings.existingItem,
                  type: 'error',
                }),
            }),
        },
        {
          fieldName: 'device',
          placeholder: languageStrings.selectDevicePlaceholder,
          label: languageStrings.deviceLabel,
          defaultValue: { name: '', id: '' },
          type: RowItemType.JOINED_SELECT,
          excludedFromView: true,
          selectItems: devicesList,
        },
        {
          fieldName: 'device',
          placeholder: '',
          label: languageStrings.deviceLabel,
          defaultValue: '',
          type: RowItemType.CUSTOM,
          excludedFromAdd: true,
          width: '1fr',
          component: (value: any) => {
            const { transport_id, address, _id } = value;
            return (
              <DeviceItem
                deviceId={_id}
                address={address ? `${address.city}, ${address.line1}` : 'N/A'}
                name={transport_id}
              />
            );
          },
        },
        {
          fieldName: 'device',
          placeholder: '',
          label: languageStrings.insightDetailsDeviceConfigurationEditInputsAddressTitle,
          defaultValue: '',
          type: RowItemType.CUSTOM,
          excludedFromView: true,
          modalColumns: 2,
          component: () => {
            return <AddressField languageStrings={languageStrings} />;
          },
        },
        // {
        //   fieldName: 'source',
        //   placeholder: '',
        //   type: RowItemType.JOINED_SELECT,
        //   label: languageStrings.sourceLabel || 'Source',
        //   defaultValue: '',
        //   excludedFromAdd: true,
        //   excludedFromView: lang === 'DE' ? windowWidth < 1760 : windowWidth < 1410,
        //   selectItems: [],
        // },
        {
          fieldName: 'source',
          placeholder: '',
          type: RowItemType.INPUT,
          label: languageStrings.sourceLabel,
          defaultValue: '',
          excludedFromAdd: true,
          width: '0.75fr',
        },
        {
          fieldName: 'ts_start',
          placeholder: '',
          type: RowItemType.CUSTOM,
          label: languageStrings.startLabel,
          excludedFromAdd: true,
          width: '0.75fr',
          defaultValue: '',
          component: (value, state, setField, saveToggle) => {
            return (
              <DateField
                value={value}
                setField={setField}
                state={state}
                languageStrings={languageStrings}
                saveToggle={saveToggle}
              />
            );
          },
        },
        {
          fieldName: 'ts_start',
          placeholder: '',
          type: RowItemType.CUSTOM,
          label: languageStrings.startDateLabel,
          defaultValue: '',
          excludedFromView: true,
          component: (value, state, setField, saveToggle) => {
            return (
              <DateField
                value={value}
                setField={setField}
                state={state}
                isAddInProcess={isAddInProcess}
                languageStrings={languageStrings}
                saveToggle={saveToggle}
              />
            );
          },
        },
        {
          fieldName: 'ts_start',
          placeholder: '',
          type: RowItemType.CUSTOM,
          label: languageStrings.startTimeLabel,
          defaultValue: '',
          excludedFromView: true,
          component: (value, state, setField) => {
            return <TimeField setField={setField} languageStrings={languageStrings} />;
          },
        },
        {
          fieldName: 'updated_at',
          placeholder: '',
          type: RowItemType.CUSTOM,
          label: languageStrings.lastEventLabel,
          defaultValue: '',
          width: '0.75fr',
          excludedFromAdd: true,
          component: (value: any, state) => {
            return (
              <>
                {state === TableCustomComponentState.VIEW ? (
                  <ItemWrapper>
                    <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
                      {getRequiredDateFormat(value, 'DD.MM.YYYY')}
                    </Text>
                    <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
                      {getRequiredDateFormat(value, 'HH:mm')}
                    </Text>
                  </ItemWrapper>
                ) : null}
              </>
            );
          },
        },
        {
          fieldName: 'severity',
          placeholder: '',
          type: RowItemType.CUSTOM,
          label: languageStrings.severityLabel,
          defaultValue: '',
          excludedFromAdd: true,
          width: '0.5fr',
          component: (value: any, state) => {
            return (
              <Severity
                status={value && value[0] ? value[0].text : 0}
                isResolved={false}
                state={state}
                languageStrings={languageStrings}
              />
            );
          },
        },
        {
          fieldName: 'severity',
          placeholder: languageStrings.chooseSeverityPlaceholder,
          type: RowItemType.JOINED_SELECT,
          label: languageStrings.severityLabel,
          defaultValue: { name: '', id: '' },
          excludedFromView: true,
          selectItems: types.severities.map((item: any) => ({ name: item.name, id: item.severity_status })),
        },
        {
          fieldName: 'comments',
          placeholder: '',
          type: RowItemType.CUSTOM,
          label: languageStrings.insightDetailsCommentsTitle,
          defaultValue: '',
          modalColumns: 2,
          excludedFromView: true,
          excludedFromModalView: true,
          component: (value: any, state, setField) => {
            return <CommentsField setField={setField} languageStrings={languageStrings} />;
          },
        },
      ]}
    />
  );
};
