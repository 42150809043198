import { ButtonIconType, CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../app/state/user';
import { NotificationsButton } from '../../../components/notificationsButton/NotificationsButton';
import { PATHS } from '../../../routes';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useHistory } from '../../../utils/react-router-dom-abstraction';
import { TableControl } from './components/tableControl/TableControl';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 46px;
  ${media.tablet} {
    padding: 0px;
    & > div > div > div:first-child {
      border-radius: 0;
      border: 0;
    }
  }
`;

const UpPartWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 54px;
  ${media.tablet} {
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 34px 16px 16px 16px;
    border-bottom: 1px solid;
    border-color: ${colorFetch('gray3')};
    margin-bottom: 0;
    & > span {
      margin: 0;
      font-size: 20px;
    }
  }
`;

export const ProblemsDetected = () => {
  const history = useHistory();
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <UpPartWrapper>
        <CommonButton
          onClick={() => {
            history.push(PATHS.USER_DASHBOARD);
          }}
          height="32px"
          width="32px"
          iconType={ButtonIconType.ARROW_LEFT}
          iconWithoutText
        />
        <Text type={TextType.TITLE_H1} margin={'0 0 0 14px'}>
          {languageStrings.dashboardCardsTitleProblems}
        </Text>
        <NotificationsButton onClick={() => {}} countOfNotifications={0} />
      </UpPartWrapper>
      <TableControl />
    </Wrapper>
  );
};
