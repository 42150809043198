import React, { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--modal-first-layer);
  background-color: rgb(0, 0, 0, 0.6);
  ${media.semiTablet} {
    align-items: flex-end;
  }
`;

export interface ModalProps {
  children: any;
  isOpenModal: boolean;
  bodyElem?: any;
}
export const Modal: FC<ModalProps> = ({ children, isOpenModal, bodyElem, ...props }) => {
  useEffect(() => {
    if (bodyElem) {
      bodyElem.style.overflow = isOpenModal ? 'hidden' : 'overlay';
    }
  }, [isOpenModal, bodyElem]);

  if (isOpenModal) {
    return createPortal(<Wrapper {...props}>{children}</Wrapper>, document.getElementById('modal') || document.body);
  }
  return null;
};
