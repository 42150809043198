/**
 * Custom axis tick for the Axis component from "Recharts" library
 */

import React from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export enum YAxisTickType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

const Tick = styled.div`
  height: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray2')};
`;

type LineProps = {
  width: number;
};

const Line = styled.div<LineProps>`
  height: 1px;
  width: ${({ width }) => `${width}px`};
  background-color: ${colorFetch('gray5')};
`;

export type PayloadType = {
  value: number;
};

export type TickValue = {
  y: number;
  payload: PayloadType;
  index: number;
};

export const CustomYAxisTick = ({
  type = YAxisTickType.DEFAULT,
  value,
  lineWidth,
  setTickText,
  theme,
}: {
  type?: YAxisTickType;
  value: TickValue;
  lineWidth: number;
  setTickText?: (value: TickValue) => string | number;
  theme: any;
}) => {
  const { y, payload } = value;

  const tickText = type === YAxisTickType.DEFAULT ? payload.value : setTickText && setTickText(value);
  // @TODO Alex use some cleaner solution for rounding
  return (
    <foreignObject x={20} y={y - 14.5} width={'100%'} height={20}>
      <Tick theme={theme}>{tickText && `${tickText}`.slice(0, 6)}</Tick>
      <Line width={lineWidth} theme={theme} />
    </foreignObject>
  );
};

// export const CustomХAxisTick = ({
//   type = YAxisTickType.DEFAULT,
//   value,
//   lineWidth,
//   setTickText,
//   theme,
// }: {
//   type?: YAxisTickType;
//   value: TickValue;
//   lineWidth: number;
//   setTickText?: (value: TickValue) => string | number;
//   theme: any;
// }) => {
//   const { y, payload } = value;

//   const tickText = type === YAxisTickType.DEFAULT ? payload.value : setTickText && setTickText(value);
//   // @TODO Alex use some cleaner solution for rounding
//   return (
//     <foreignObject x={20} y={y - 14.5} width={'100%'} height={20}>
//       <Tick theme={theme}>{tickText && `${tickText}`.slice(0, 6)}</Tick>
//       <Line width={lineWidth} theme={theme} />
//     </foreignObject>
//   );
// };
