/**
 * Function with Line of average value for the Rechart library graph
 */
import React from 'react';

export const setMidValueLine = (midLineOffsetY: number, lineWidth: number, theme: any) => {
  return (
    <line
      x1={20}
      y1={midLineOffsetY}
      x2={lineWidth + 20}
      y2={midLineOffsetY}
      style={{
        stroke: theme.colors.green1,
        strokeDasharray: '4 5',
        opacity: 0.6,
        strokeWidth: 1,
      }}
    />
  );
};
