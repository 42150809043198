/**
 * Function with svg linear gradient for the Rechart library graph
 */

import React from 'react';

export const setGradientArea = (id: string, stopColor: string) => {
  return (
    <defs>
      <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor={stopColor} stopOpacity={0.8} />
        <stop offset="95%" stopColor={stopColor} stopOpacity={0.0} />
      </linearGradient>
    </defs>
  );
};
