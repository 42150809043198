import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { InputWrapper } from '../inputWrapper/InputWrapper';
import { Text, TextType } from '@bit/first-scope.text';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { Calendar, CalendarType } from '@bit/first-scope.calendar';
import { ChooseDateMobileModal } from '../../../../containers/common/reports/components/chooseDateMobileModal/ChooseDateMobileModal';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useWindowSize } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

// @TODO fix types
type StyledCalendarProps = {
  title: any;
  disabled: boolean;
  startDate: Date;
};

const StyledCalendar = styled(Calendar)<StyledCalendarProps>`
  width: ${({ title }) => (title ? '100%' : '140px')};
  .react-datepicker__input-container > div {
    height: ${({ title }) => (title ? '40px' : '32px')};
    box-shadow: none;
    z-index: 0;
    background-color: ${({ disabled, theme }) =>
      disabled ? colorFetch('gray5')({ theme }) : colorFetch('pureWhite')({ theme })};
    border: ${({ disabled }) => (disabled ? 'none' : '1px solid')};
    border-color: ${colorFetch('gray3')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    & > div:first-child {
      top: ${({ title }) => (title ? '-1px' : '0')};
    }
    & > span {
      font-weight: ${({ disabled }) => (disabled ? '400' : '500')};
    }
    & > div:last-child {
      display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
    }
    & > div > svg {
      & > path {
        stroke: ${colorFetch('gray2')};
      }
    }
    & > div:nth-child(3n) {
      right: 12px;
    }
    & > span {
      color: ${colorFetch('gray1')};
      display: ${({ disabled, startDate }) => (disabled && !startDate ? 'none' : 'flex')};
    }
  }
  .react-datepicker-wrapper {
    display: flex;
  }
` as any; // @TODO fix type

const PlaceholderText = styled(Text)`
  display: none;
  position: absolute;
  top: 26px;
  left: 32px;
  font-weight: 400;
  &.visible {
    display: flex;
  }
  &.visible.fixTop {
    top: 8px;
  }
`;

export interface DatePickerWithIconOptions {
  title?: string;
  maxValue?: number;
  minValue?: number;
  isDisabled?: boolean;
  handleSaveMobile?: any;
  onCloseMobile?: Function;
}

export const DatePickerWithIcon = ({
  globalValue,
  handleChangeValue,
  options,
  isEditInProcess,
  ...props
}: {
  globalValue: Date;
  handleChangeValue: Function;
  options: any;
  isEditInProcess?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const { maxValue, minValue, handleSaveMobile, title, isDisabled, onCloseMobile } = options;
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(false);
  const windowWidth = useWindowSize()[0];

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <InputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      {isEditInProcess ? (
        <StyledCalendar
          type={CalendarType.DATE_SELECTION}
          dateFormat={'DD.MM.YYYY'}
          startDate={globalValue}
          setStartDate={handleChangeValue}
          maxDate={maxValue}
          minDate={minValue}
          title={title}
          disabled={isDisabled}
          onClick={
            title && windowWidth < 769
              ? () => {
                  setIsOpenMobileModal(true);
                }
              : () => {}
          }
        />
      ) : (
        <Text type={TextType.TEXT_14_GRAY} color={gray1}>
          {getRequiredDateFormat(globalValue, 'DD.MM.YYYY')}
        </Text>
      )}
      <PlaceholderText
        type={TextType.TEXT_14_GRAY}
        color={gray1}
        className={isDisabled && !globalValue ? 'visible fixTop' : ''}
      >
        {'N/A'}
      </PlaceholderText>
      <ChooseDateMobileModal
        isOpenModal={isOpenMobileModal}
        closeModal={() => {
          setIsOpenMobileModal(false);
          onCloseMobile && onCloseMobile();
        }}
        title={''}
        languageStrings={languageStrings}
        date={globalValue}
        setDate={handleChangeValue}
        maxDate={maxValue}
        minDate={minValue}
        handleSave={() => {
          setIsOpenMobileModal(false);
          handleSaveMobile && handleSaveMobile();
        }}
      />
    </InputWrapper>
  );
};
