import React from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { CustomModal } from '../../../../components/customModal/CustomModal';
import { useTheme } from '@cthings.co/styled-components';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.semiTablet} {
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column-reverse;
  }
`;

const StyledCancelButton = styled(CommonButton)`
  margin: 0 16px 0 0;
  ${media.semiTablet} {
    width: 100%;
    margin: 0;
  }
`;

const StyledNextButton = styled(CommonButton)`
  ${media.semiTablet} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const AreYouSure = ({
  isOpenModal,
  language,
  onCancel,
  onStart,
  isGPSLocationCheckRequest = false,
}: {
  isOpenModal: boolean;
  language?: any;
  onCancel: () => void;
  onStart: () => void;
  isGPSLocationCheckRequest?: boolean;
}) => {
  const theme = useTheme();
  const { black1, gray1, pureWhite } = theme.colors;

  return (
    <CustomModal isOpen={isOpenModal}>
      <TitleWrapper>
        <Text type={TextType.TEXT_14_BLACK} fontSize={'18px'} weight={'600'} color={black1}>
          {language.gpsDetailsAreYouSureTitle}
        </Text>
      </TitleWrapper>
      <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1} margin={'24px 0'}>
        {language.gpsDetailsAreYouSureText}
      </Text>
      <ButtonRow>
        <StyledCancelButton
          onClick={onCancel}
          width={'116px'}
          colors={{
            main: black1,
            background: pureWhite,
          }}
        >
          {language.cancelButton}
        </StyledCancelButton>
        <StyledNextButton onClick={onStart} width={'116px'}>
          {isGPSLocationCheckRequest ? language.startButton : language.saveButton}
        </StyledNextButton>
      </ButtonRow>
    </CustomModal>
  );
};
