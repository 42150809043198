import React from 'react';
import { GraphRow } from '../../../common/styledComponents/StyledComponents';
import { WaterLevelGraph } from '../../../manholeDetails/components/history/components/waterLevelGraph/WaterLevelGraph';

export const History = ({ defaultFilters }: { defaultFilters: any }) => {
  return (
    <>
      <GraphRow>
        <WaterLevelGraph defaultFilters={defaultFilters} />
      </GraphRow>
    </>
  );
};
