import React, { FC, FormEvent } from 'react';
import {
  ButtonContainer,
  ButtonWrapper,
  CancelButton,
  InputsContainer,
  InputsWrapper,
  SaveButton,
  StyledModal,
  StyledModalContainer,
  StyledTitle,
  Wrapper,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user/selectors';
import { setIsOpenInvoiceDetailsModal } from '../../../../../../app/state/billingDetailsSlice';
import { TextType } from '@bit/first-scope.text';
import { useInputStructure } from './utils';
import { InputModal } from '../../../../../../components/inputModal/InputModal';
import { RowItemType, TableCustomComponentState } from '../../../../../../features/universalTable/types';
import { AddressInput } from '../../../../../../components/addressInput/AddressInput';
import { updateInvoiceDetails } from '../../../../../../api/manage/manageBilling';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { useTheme } from '@cthings.co/styled-components';
import { uniqueId } from 'underscore';

interface InvoiceDetailsModalPropsType {
  getRowEssentials: any;
  apiTrigger: (val: string) => void;
}

export const InvoiceDetailsModal: FC<InvoiceDetailsModalPropsType> = ({ getRowEssentials, apiTrigger }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const dispatch = useDispatch();

  const [
    {
      inputStructure,
      isFormValid,
      state: { orgName, vat, address: addressState },
      isLoaded,
    },
    { setStartedValidation, processRowData, handleRevert },
  ] = useInputStructure({ getRowEssentials });

  const onClickCancelButton = () => {
    handleRevert();
    dispatch(setIsOpenInvoiceDetailsModal(false));
  };

  const onClickSaveButton = () => {
    setStartedValidation(true);
    if (isFormValid) {
      updateInvoiceDetails({
        body: {
          organisation_name: orgName,
          vat_number: vat,
          address: addressState,
        },
        handleError: (data: any) => {
          addNotification({
            message: data?.response?.data?.detail ?? languageStrings.somethingWentWrong,
            type: 'error',
          });
        },

        handleSuccess: (data: any) => {
          apiTrigger(uniqueId());
          addNotification({
            message: languageStrings.successSaveMessage,
            type: 'success',
          });
        },
      });
      dispatch(setIsOpenInvoiceDetailsModal(false));
    }
  };

  return (
    <StyledModal isOpenModal={true}>
      <StyledModalContainer>
        <Wrapper>
          <StyledTitle type={TextType.TEXT_14_BLACK} color={black1}>
            {languageStrings.editInvoiceTitle}
          </StyledTitle>
          <InputsWrapper>
            {inputStructure.map((el: any, index) => {
              const { type, value, label, placeholder } = el;

              const [{ readableAddress, validationOptions }, { handleOnChange }] = processRowData(el);

              return (
                <InputsContainer key={index}>
                  {type === RowItemType.ADDRESS ? (
                    isLoaded && (
                      <AddressInput
                        title={label}
                        value={readableAddress}
                        placeholder={languageStrings.chooseAddressPlaceholder}
                        isDisabled={false}
                        addressState={TableCustomComponentState.EDIT}
                        address={addressState}
                        setAddress={handleOnChange}
                        isDescriptionModal={true}
                        {...validationOptions}
                      />
                    )
                  ) : (
                    <InputModal
                      name={label}
                      placeholder={placeholder}
                      value={value}
                      onClick={() => {}}
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        handleOnChange(e.currentTarget.value.trim());
                      }}
                      width={'100%'}
                      {...validationOptions}
                    />
                  )}
                </InputsContainer>
              );
            })}
          </InputsWrapper>
          <ButtonWrapper>
            <ButtonContainer>
              <CancelButton onClick={onClickCancelButton}>{languageStrings.cancelButton}</CancelButton>
              <SaveButton onClick={onClickSaveButton}>{languageStrings.saveButton}</SaveButton>
            </ButtonContainer>
          </ButtonWrapper>
        </Wrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
