import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as NotificationsImage } from '../../assets/Notifications.svg';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  display?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: ${({ display }) => display || 'none'};

  position: relative;

  & > svg {
    width: 33px;
    height: 33px;
  }

  & > svg > path {
    stroke: ${colorFetch('black1')};
  }

  ${media.tablet} {
    display: flex;
  }
`;

const Marker = styled.div`
  display: flex;

  background-color: ${colorFetch('red2')};

  border-radius: ${colorFetch('primary')};

  position: absolute;
  bottom: 16px;
  left: 16px;

  padding: 1px 3px 0 3px;

  & > span {
    user-select: none;
  }
`;

export interface NotificationsButtonProps {
  countOfNotifications: number;
  onClick: () => void;

  display?: string;
}

export const NotificationsButton: FC<NotificationsButtonProps> = ({
  countOfNotifications,
  onClick,

  display,
}) => {
  const theme = useTheme();

  return (
    <Wrapper theme={theme} onClick={onClick} display={display}>
      <NotificationsImage />

      {countOfNotifications > 0 ? (
        <Marker theme={theme}>
          <Text type={TextType.TEXT_10_WHITE} color={theme.colors.pureWhite}>
            {countOfNotifications}
          </Text>
        </Marker>
      ) : null}
    </Wrapper>
  );
};

NotificationsButton.defaultProps = {
  onClick: () => {},
  display: 'none',
};
