import { useCallback, useRef, useState } from 'react';

export const useLongTap = (
  action: (e: any) => void,
  onClick: (e: any) => void,
  duration: number,
  condition: boolean,
) => {
  const timerId = useRef(null as any);
  const [reached, setReached] = useState(false);

  const onTouchStart = useCallback(
    (e: any) => {
      setReached(false);
      const handleAction = (e: any) => {
        action && action(e);
      };
      timerId.current && clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        if (condition) {
          setReached(true);
          handleAction(e);
        }
      }, duration);
    },
    [timerId, duration, action, condition],
  );
  const onTouchEnd = useCallback(
    (e: any) => {
      timerId.current && clearTimeout(timerId.current);
      !reached && onClick(e);
    },
    [timerId, reached, onClick],
  );

  const onTouchMove = useCallback(
    (e: any) => {
      !reached && setReached(true);
    },
    [reached],
  );

  const clearTouch = useCallback(
    (e: any) => {
      !reached && setReached(true);
    },
    [reached],
  );

  return [onTouchStart, onTouchEnd, clearTouch] as const;
};
