import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useTheme } from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

type LegendWrapperProps = {
  borderRadius: string;
};

const LegendWrapper = styled.div<LegendWrapperProps>`
  display: flex;
  gap: 120px;
  padding: 13px 20px 13px 40px;
  margin-top: 5px;
  box-shadow: 0px 2px 8px rgba(96, 97, 112, 0.06);
  background-color: ${colorFetch('pureWhite')};
  border-radius: ${({ borderRadius }) => borderRadius};

  ${media.tablet} {
    gap: 50px;
  }

  ${media.semiTablet} {
    gap: 20px;
    flex-direction: column;
  }
`;

type StyledTextProps = {
  pickUpColor?: string;
  locationColor?: string;
  calibrationColor?: string;
  gpsLocationColor?: string;
  standingWaterColor?: string;
  submergeInWaterColor?: string;
};

const StyledText = styled(Text)<StyledTextProps>`
  position: relative;
  font-weight: 500 !important;
  &:before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 4px;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.pickUp:before {
    background: ${({ pickUpColor }) => pickUpColor};
  }
  &.location:before {
    background: ${({ locationColor }) => locationColor};
  }
  &.calibration:before {
    background: ${({ calibrationColor }) => calibrationColor};
  }
  &.gpsLocation:before {
    background: ${({ gpsLocationColor }) => gpsLocationColor};
  }
  &.standingWater:before {
    background: ${({ standingWaterColor }) => standingWaterColor};
  }
  &.submergeInWater:before {
    background: ${({ submergeInWaterColor }) => submergeInWaterColor};
  }
`;

export const LegendsOfEvents = ({ isSmartCapDevice }: { isSmartCapDevice?: boolean }) => {
  const theme = useTheme();
  const { primary: primaryBorder } = theme.borderRadius;
  const { primary, gray1, orange2, blue2, chartPink3, chartCyan4 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <>
      {isSmartCapDevice ? (
        <LegendWrapper borderRadius={primaryBorder}>
          <StyledText type={TextType.TEXT_12_GRAY} color={gray1} className={'pickUp'} pickUpColor={primary}>
            {languageStrings.pickUpEventText}
          </StyledText>
          <StyledText type={TextType.TEXT_12_GRAY} color={gray1} className={'gpsLocation'} gpsLocationColor={blue2}>
            {languageStrings.GPSLocationText}
          </StyledText>
          <StyledText
            type={TextType.TEXT_12_GRAY}
            color={gray1}
            className={'standingWater'}
            standingWaterColor={chartPink3}
          >
            {languageStrings.standingWater}
          </StyledText>
          <StyledText
            type={TextType.TEXT_12_GRAY}
            color={gray1}
            className={'submergeInWater'}
            submergeInWaterColor={chartCyan4}
          >
            {languageStrings.submergeInWater}
          </StyledText>
        </LegendWrapper>
      ) : (
        <LegendWrapper borderRadius={primaryBorder}>
          <StyledText type={TextType.TEXT_12_GRAY} color={gray1} className={'pickUp'} pickUpColor={primary}>
            {languageStrings.pickUpEventText}
          </StyledText>
          <StyledText type={TextType.TEXT_12_GRAY} color={gray1} className={'location'} locationColor={blue2}>
            {languageStrings.GPSLocationText}
          </StyledText>
          <StyledText type={TextType.TEXT_12_GRAY} color={gray1} className={'calibration'} calibrationColor={orange2}>
            {languageStrings.calibrationText}
          </StyledText>
        </LegendWrapper>
      )}
    </>
  );
};
