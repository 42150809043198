import React from 'react';
import { ManholesType } from '../ManholeButton';
import { useTheme } from '@cthings.co/styled-components';

export const SmartAlarmImage = ({ colorType }: { colorType: ManholesType }) => {
  const theme = useTheme();
  const { primary, secondary1, secondary4, red0, red2, orange2, orange0 } = theme.colors;

  const setColorTheme = (colorType: ManholesType) => {
    const colors: any = {
      [ManholesType.GREEN]: { fill: primary, secondFill: secondary4, hoverColor: primary, bordersColor: secondary1 },
      [ManholesType.RED]: { fill: red2, secondFill: red2, hoverColor: red2, bordersColor: red0 },
      [ManholesType.YELLOW]: { fill: orange2, secondFill: orange2, hoverColor: orange2, bordersColor: orange0 },
      [ManholesType.NONE]: { fill: '', secondFill: '', hoverColor: '', bordersColor: '' },
    };
    return colors[colorType];
  };

  return (
    <svg width="54" height="50" viewBox="0 0 54 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        className={'opacityFirstLayer'}
        opacity="0.2"
        x="8"
        y="8"
        width="38"
        height="33.5294"
        rx="1.80952"
        fill="#D8F0E0"
        stroke={setColorTheme(colorType).hoverColor}
        stroke-width="16"
      />
      <rect
        className={'opacitySecondLayer'}
        opacity="0.2"
        x="8"
        y="8"
        width="38"
        height="33.5294"
        rx="1.80952"
        fill={setColorTheme(colorType).secondFill}
        stroke={setColorTheme(colorType).hoverColor}
        stroke-width="9"
      />
      <rect
        x="8"
        y="8"
        width="38"
        height="33.5294"
        rx="1.80952"
        fill={setColorTheme(colorType).secondFill}
        stroke={setColorTheme(colorType).secondFill}
        stroke-width="1.7"
      />
      <path
        d="M8 22.8088L14.4265 25.3235L21.9706 21.1323L25.0441 21.9706L28.1176 23.9264L31.75 25.0441L40.4118 21.4117L45.4412 23.0882V41.5294H8V22.8088Z"
        fill={setColorTheme(colorType).fill}
      />
      <path
        d="M8.27954 22.5293C9.36254 23.4318 11.9963 25.0216 14.3428 25.0216C18.8553 25.0216 18.1428 21.4116 22.6553 21.4116C27.3483 21.4116 26.9873 25.0216 31.6803 25.0216C36.1928 25.0216 36.1928 21.4116 40.7053 21.4116C43.0518 21.4116 44.9171 22.7444 46.0001 23.6469"
        stroke={setColorTheme(colorType).bordersColor}
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="8"
        y="8"
        width="38"
        height="33.5294"
        rx="1.80952"
        stroke={setColorTheme(colorType).bordersColor}
        stroke-width="1.7"
      />
      <path
        d="M8 9.80952C8 8.81015 8.81015 8 9.80952 8H44.1905C45.1898 8 46 8.81015 46 9.80952V11.619H8V9.80952Z"
        fill={setColorTheme(colorType).fill}
        stroke={setColorTheme(colorType).bordersColor}
        stroke-width="1.7"
      />
    </svg>
  );
};
