import { FC, useState, useEffect, ChangeEvent } from 'react';
import Slider, { Handle } from 'rc-slider';
import AutosizeInput from 'react-input-autosize';
import 'rc-slider/assets/index.css';
import styled from '@cthings.co/styled-components';
import { TableInputType } from '../../TableInput';
import { media } from '@cthings.co/styles-utils';
import { InputWrapper } from '../inputWrapper/InputWrapper';
import { CheckBox } from '../../../controls';
import { NoticeModal } from './components/NoticeModal/NoticeModal';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const StyledInputWrapper = styled(InputWrapper)`
  flex-direction: row;
  align-items: center;
  ${media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin-right: 20px;
  ${media.phone} {
    margin-right: 0;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  ${media.phone} {
    height: 40px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 100%;
  background-color: ${colorFetch('gray6')};
  border-right: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 8px 0 0 8px;
`;

type StyledInputProps = {
  disabled: boolean;
};

const StyledInput = styled(AutosizeInput)<StyledInputProps>`
  display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')} !important;
  background-color: ${colorFetch('gray6')};
  border-radius: 8px 0 0 8px;
  & > input {
    outline: transparent;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${colorFetch('gray1')};
  }
`;

// @TODO fix any
const StyledSlider = styled(Slider as any)`
  width: calc(100% - 71px);
  margin: 0 14px;
  background: none;
`;

type StyledHandleProps = {
  withInput?: boolean;
  pureWhite: string;
  primary: string;
  boxShadow: string;
};

const StyledHandle = styled(Handle as any)<StyledHandleProps>`
  width: ${({ withInput }) => (withInput ? '14px' : '44px')};
  height: ${({ withInput }) => (withInput ? '14px' : '24px')};
  background-color: ${({ withInput, pureWhite, primary }) => (withInput ? pureWhite : primary)};
  border: ${({ withInput }) => (withInput ? '2px solid' : '0')};
  border-color: ${({ withInput, primary }) => withInput && primary};
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  transform: ${({ withInput }) => (withInput ? 'translate(-50%, -5px)' : 'translate(-50%, -10px)')} !important;
  box-shadow: ${({ boxShadow }) => boxShadow} !important;
  border-radius: ${({ withInput }) => (withInput ? '50%' : '3px')};
  &:hover {
    border: ${({ withInput }) => !withInput && 0};
    border-color: ${({ withInput, primary }) => withInput && primary};
  }
  &:focus,
  &:active {
    border-color: ${({ withInput, primary }) => withInput && primary};
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

type TextUnitProps = {
  disabled: boolean;
};

const TextUnit = styled.span<TextUnitProps>`
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ disabled, theme }) => (disabled ? colorFetch('gray3')({ theme }) : colorFetch('gray1')({ theme }))};
`;

const TextWrapper = styled.span`
  word-break: break-all;
  width: 100%;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

const CheckBoxWrapper = styled.div`
  margin: 0 0 0 14px;

  ${media.phone} {
    margin: 10px 0 0;
  }
`;

export interface SLiderInputOptionsType {
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
  title?: string;
  checkboxLabel?: string;
}
interface SliderInputProps {
  type: TableInputType;
  value: any;
  handleChangeValue: (value: number | string | null) => void;
  options: SLiderInputOptionsType;
  isEditInProcess: boolean | undefined;
}

export const SliderInput: FC<SliderInputProps> = ({
  type,
  value,
  handleChangeValue,
  options,
  isEditInProcess = false,
  ...props
}) => {
  const theme = useTheme();
  const { gray1, gray4, primary, secondary3, pureWhite } = theme.colors;
  const { additionalShadow6 } = theme.shadows;
  const { min = 0, max = 100, step = 1, title, checkboxLabel } = options;

  const noValue = value === 'N/A' || value === null;
  const withNullCheckbox = type === TableInputType.SLIDER_FREE_INPUT_WITH_UNIT_AND_CHECKBOX;
  const [checkbox, setCheckbox] = useState(withNullCheckbox && noValue ? true : false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const regexForInput = /^\d{0,3}([.,])?\d{0,2}$/gm;

  const onInputChange = (value: string) => {
    const inputValue = value.replace(',', '.');

    if (regexForInput.test(inputValue) && inputValue.length < 6) {
      handleChangeValue(inputValue);
    }
  };

  const onInputBlur = () => {
    +value <= min
      ? handleChangeValue(min)
      : +value >= max
      ? handleChangeValue(max)
      : isNaN(value)
      ? handleChangeValue(min)
      : handleChangeValue(+value);
  };

  const onCheckboxPress = () => {
    !checkbox && setIsModalOpen(true);
    setCheckbox((current) => !current);
    checkbox ? handleChangeValue(min) : handleChangeValue(null);
  };

  useEffect(() => {
    if (withNullCheckbox) {
      setCheckbox(noValue ? true : false);
    }
  }, [value, type, isEditInProcess]);

  const setSlider = (type: TableInputType) => {
    const sliderWithInput = (unit: string = '', withCheckbox?: boolean) => {
      return (
        <StyledInputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
          <Container>
            <Box>
              <StyledInput
                inputMode="decimal"
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onInputChange(e.target.value);
                }}
                onBlur={onInputBlur}
                disabled={checkbox}
              />
              {unit && <TextUnit disabled={checkbox}>{checkbox ? 'N/A' : ' ' + unit}</TextUnit>}
            </Box>
            <StyledSlider
              disabled={checkbox}
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={(val: number) => handleChangeValue(val)}
              trackStyle={{ backgroundColor: primary }}
              railStyle={{ backgroundColor: checkbox ? gray4 : secondary3 }}
              handle={(props: any) => (
                <StyledHandle
                  primary={primary}
                  pureWhite={pureWhite}
                  boxShadow={additionalShadow6}
                  withInput
                  {...props}
                />
              )}
            />
          </Container>
          {withCheckbox && (
            <CheckBoxWrapper>
              <CheckBox
                color={gray1}
                fontSize={'14px'}
                toggle={checkbox}
                label={checkboxLabel ? checkboxLabel : ''}
                labelWhiteSpace={'nowrap'}
                handleToggle={onCheckboxPress}
              />
            </CheckBoxWrapper>
          )}
          <NoticeModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
        </StyledInputWrapper>
      );
    };

    const data: any = {
      [TableInputType.SLIDER]: (() => (
        <Wrapper>
          <Container>
            <StyledSlider
              value={value}
              min={min}
              max={max}
              step={step}
              onChange={(val: number) => handleChangeValue(val)}
              trackStyle={{ backgroundColor: primary }}
              railStyle={{ backgroundColor: secondary3 }}
              handle={(props: any) => (
                <StyledHandle boxShadow={additionalShadow6} primary={primary} pureWhite={pureWhite} {...props}>
                  {value}
                </StyledHandle>
              )}
            />
          </Container>
        </Wrapper>
      ))(),
      [TableInputType.SLIDER_FREE_INPUT]: sliderWithInput(),
      [TableInputType.SLIDER_FREE_INPUT_WITH_UNIT]: sliderWithInput(options.unit),
      [TableInputType.SLIDER_FREE_INPUT_WITH_UNIT_AND_CHECKBOX]: sliderWithInput(options.unit, true),
    };
    return data[type];
  };

  return (
    <>
      {isEditInProcess ? (
        setSlider(type)
      ) : (
        <TextWrapper>{`${value} ${withNullCheckbox && noValue ? '' : options.unit}`}</TextWrapper>
      )}
    </>
  );
};
