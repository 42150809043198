import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as NoDataImage } from '../../assets/No_Data.svg';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const MainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 18px 24px 0;
`;

const NoDataBackground = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100px;
  box-sizing: border-box;
  background-color: ${colorFetch('gray6')};
  border-radius: ${({ theme }) => theme.borderRadius.additional6};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    font-weight: 400;
  }
`;

export const NoDataPlaceholder = ({ languageStrings }: { languageStrings?: any }) => {
  const theme = useTheme();

  return (
    <MainWrapper>
      <NoDataBackground theme={theme}>
        <NoDataImage />
        <Text type={TextType.TEXT_14_GRAY}>{languageStrings.placeholderNoData}</Text>
      </NoDataBackground>
    </MainWrapper>
  );
};
