import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { AddedImage } from './components/addedImage/AddedImage';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as AddIcon } from '../../assets/plus_icon.svg';
import { Loader } from '../placeholders/Loader';
import { useNotifierFunctions } from '../../features/notifier2';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`;
export const InputAddImage = styled.input`
  display: none;
`;
const WrapperForImages = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const LabelAddImage = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: 1px solid #c7c9d9;
  box-sizing: border-box;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.3s linear;
  margin: 0 15px 15px 0;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    background-color: ${colorFetch('gray4')};
    & > svg > path {
      stroke: ${colorFetch('gray1')};
    }
  }
`;

export const AddingAnImage = ({
  languageStrings,
  imagesList,
  setImagesList,
}: {
  languageStrings?: any;
  imagesList: string[];
  setImagesList: any;
}) => {
  const [isLoadingInProcess, setIsLoadingInProcess] = useState(false);
  const [localImages, setLocalImages] = useState<any>([]);

  const theme = useTheme();
  const { gray1 } = theme.colors;
  const { addNotification } = useNotifierFunctions();
  const fileReader = new FileReader();

  const deleteImage = (index: number) => {
    setImagesList(imagesList.filter((el: any, i: number) => i !== index));
    setLocalImages(localImages.filter((el: any, i: number) => i !== index));
  };

  const resetInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = '';
  };

  const addImage = (e: any) => {
    if (e?.target?.files.length > 0) {
      setIsLoadingInProcess(true);
      if (e?.target?.files[0]?.type.includes('image')) {
        setImagesList([...imagesList, e?.target?.files[0]]);
      }
    }

    fileReader.onload = function () {
      if ((fileReader.result as string).length > 0) {
        if ((fileReader.result as string).includes('image')) {
          setLocalImages([...localImages, fileReader.result as string]);
        } else {
          addNotification({
            message: languageStrings.incorrectTypeOfFile,
            type: 'error',
          });
        }
        setIsLoadingInProcess(false);
      }
    };

    e.target.files[0] && fileReader.readAsDataURL(e.target.files[0]);
    resetInputValue(e);
  };

  return (
    <Wrapper>
      <Text type={TextType.TEXT_12_GRAY} color={gray1} margin={'0 0 6px 0'} weight={'500'}>
        {languageStrings.imagesLabel}
      </Text>
      <InputAddImage onChange={addImage} type="file" id="addPictureInput" accept=".heic, image/*" />
      <WrapperForImages>
        {localImages.map((item: any, index: number) => {
          return (
            <AddedImage
              deleteImage={() => {
                deleteImage(index);
              }}
              element={item}
            />
          );
        })}
        <LabelAddImage htmlFor="addPictureInput">{isLoadingInProcess ? <Loader /> : <AddIcon />}</LabelAddImage>
      </WrapperForImages>
    </Wrapper>
  );
};
