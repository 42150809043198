import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { NewOrganisation, AppState } from './types';
import ss from '../../../utils/ss';
import { LsValueType } from '../../../enums/LsValueType';

export const setIsAuthenticated = (state: WritableDraft<AppState>, action: PayloadAction<boolean>) => {
  state.isAuthenticated = action.payload;
};

export const setInitialFlag = (state: WritableDraft<AppState>, action: PayloadAction<boolean>) => {
  state.initialFlag = action.payload;
};

export const setNfcFlag = (state: WritableDraft<AppState>, action: PayloadAction<boolean>) => {
  state.nfcFlag = action.payload;
};

export const setProvisioningData = (
  state: WritableDraft<AppState>,
  action: PayloadAction<{ secret: string; transport_id: string }>,
) => {
  state.secret = action.payload.secret;
  state.transport_id = action.payload.transport_id;
};

export const setNewOrgProvisioningData = (state: WritableDraft<AppState>, action: PayloadAction<NewOrganisation>) => {
  state.newOrganisation = { ...state.newOrganisation, ...action.payload };
  ss.set(LsValueType.provisioning_data_new_organisation, state.newOrganisation);
};

export const clearProvisioningData = (state: WritableDraft<AppState>) => {
  state.secret = '';
  state.transport_id = '';
};

export const setManagmentSearchValue = (state: WritableDraft<AppState>, action: PayloadAction<string>) => {
  state.managmentSearchValue = action.payload;
};

export const reducers = {
  setIsAuthenticated,
  setNfcFlag,
  setInitialFlag,
  setManagmentSearchValue,
  setProvisioningData,
  setNewOrgProvisioningData,
  clearProvisioningData,
};
