import { RowItemType } from '../../../../../../features/universalTable/types';
import { FieldsType } from '../../../../../../utils/validation';

export const inputStructureData = [
  {
    id: 'organisation_name',
    type: RowItemType.INPUT,
    fieldName: 'orgName',
    languageStringsKeys: {
      label: 'organisationsName',
      placeholder: 'organisationsNamePlaceholder',
    },
    isRequired: true,
    validationRule: FieldsType.TEXT,
  },
  {
    id: 'vat_number',
    type: RowItemType.INPUT,
    fieldName: 'vat',
    languageStringsKeys: {
      label: 'VATnumber',
      placeholder: 'VATnumberPlaceholder',
    },
    isRequired: false,
    validationRule: FieldsType.TEXT,
  },
  {
    ids: ['country', 'region', 'city', 'address', 'postcode'],
    type: RowItemType.ADDRESS,
    fieldName: 'address',
    languageStringsKeys: {
      label: 'fullAddress',
      placeholder: 'billingAddressPlaceholder',
    },
    isRequired: true,
    validationRule: FieldsType.CUSTOM,
  },
] as const;
