import { useState } from 'react';

export const useTrigger = () => {
  const [trigger, setTrigger] = useState(false);

  const toggleTrigger = () => {
    setTrigger((val) => !val);
  };

  return [trigger, toggleTrigger] as const;
};
