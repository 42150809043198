import { Dispatch, useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export const useMapResizeControl = (setViewport: Dispatch<any>) => {
  const ref = useRef<HTMLDivElement>(null);

  const sizes = useResizeObserver({ ref });

  const [idTimeOut, setIdTimeOut] = useState<any>(null);

  useEffect(() => {
    clearTimeout(idTimeOut);
    const newTimeOut: any = setTimeout(() => {
      sizes.height && sizes.width && setViewport((el: any) => ({ ...el, width: sizes.width, height: sizes.height }));
    }, 50);
    setIdTimeOut(newTimeOut);
  }, [sizes.width, sizes.height]);

  return ref;
};
