import React, { ReactNode } from 'react';
import { CardType } from '../../DashboardCard';
import { CoverIcon } from './components/cover/Cover';
import { CPUIcon } from './components/cPUIcon/CPUIcon';
import { DropletIcon } from './components/dropletIcon/DropletIcon';
import { LocationIcon } from './components/locationIcon/LocationIcon';
import { OutletIcon } from './components/outlet/Outlet';
import { ThermometerIcon } from './components/thermometerIcon/ThermometerIcon';
import { InternalHumidity } from './components/internalHumidity/InternalHumidity';
import { OutletPlessure } from './components/outletPlessure/OutletPlessure';
import { HumidityIcon } from './components/humidity/Humidity';

export const Icons = ({
  type,
  primaryColor,
  secondaryColor,
  icon,
}: {
  type: CardType;
  primaryColor: string;
  secondaryColor: string;
  icon?: ReactNode;
}) => {
  const getIcon = (type: CardType) => {
    if (icon) return icon;
    switch (type) {
      case CardType.DEVICES:
        return <CPUIcon primaryColor={primaryColor} />;
      case CardType.WATER_LEVEL:
        return <DropletIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      case CardType.TEMPERATURE:
      case CardType.OUTLET_TEMPERATURE:
      case CardType.OUTSIDE_TEMPERATURE:
      case CardType.INTERNAL_TEMPERATURE:
        return <ThermometerIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      case CardType.LOCATIONS:
        return <LocationIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      case CardType.COVER:
        return <CoverIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      case CardType.OUTLET:
        return <OutletIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      case CardType.INTERNAL_HUMIDITY:
      case CardType.INTERNAL_HUMIDITY_BOOLEAN:
        return <InternalHumidity primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      case CardType.OUTLET_PRESSURE:
      case CardType.OUTSIDE_PRESSURE:
        return <OutletPlessure primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      case CardType.HUMIDITY:
        return <HumidityIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />;
      default:
        return <div />;
    }
  };

  return <>{getIcon(type)}</>;
};
