import { RootState } from '../../../app/store';

export const SelectLocation = (state: RootState) => {
  return state.generateReportModal.locations;
};

export const SelectCustomer = (state: RootState) => {
  return state.generateReportModal.customers;
};

export const SelectRecipients = (state: RootState) => {
  return state.generateReportModal.recipients;
};

export const SelectStartDate = (state: RootState) => {
  return state.generateReportModal.ts_from;
};

export const SelectEndDate = (state: RootState) => {
  return state.generateReportModal.ts_to;
};

export const SelectSelectedParameters = (state: RootState) => {
  const locationArr = state.generateReportModal.locations.selected_parameters.map((item: any) => {
    const { id, isSelected, ...newObj } = item;
    return newObj.name;
  });

  return {
    ts_to: state.generateReportModal.ts_to,
    ts_from: state.generateReportModal.ts_from,
    locations: locationArr,
    customer_ids: state.generateReportModal.customers.selected_parameters,
    recipients_ids: state.generateReportModal.recipients.selected_parameters,
  };
};

export const SelectIsOpenMobileDateModal = (state: RootState) => {
  return state.generateReportModal.isOpenMobileDateModal;
};

export const SelectIsValidatingStarted = (state: RootState) => {
  return state.generateReportModal.validatingIsStarted;
};

export const SelectIsOpenParameterPopup = (state: RootState) => {
  return (
    state.generateReportModal.customers.isOpenPopup ||
    state.generateReportModal.locations.isOpenPopup ||
    state.generateReportModal.recipients.isOpenPopup
  );
};
