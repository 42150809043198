import React from 'react';
import { Background } from './background/Background';
import { FifthVersionErrorPage, TypeOfError } from './fifthVersionErrorPage/FifthVersionErrorPage';
import { FirstVersionErrorPage, TypeOfErrorFirst } from './firstVersionErrorPage/FirstVersionErrorPage';
import { FourthVersionErrorPage, TypeOfErrorFourth } from './fourthVersionErrorPage/FourthVersionErrorPage';
import { SecondVersionErrorPage, TypeOfErrorSecond } from './secondVersionErrorPage/SecondVersionErrorPage';
import { ThirdVersionErrorPage, TypeOfErrorThird } from './thirdVersionErrorPage/ThirdVersionErrorPage';

export enum ErrorPageVersion {
  FIRST_VERSION_500 = 'FIRST_VERSION_500',
  FIRST_VERSION_404 = 'FIRST_VERSION_404',
  SECOND_VERSION_500 = 'SECOND_VERSION_500',
  SECOND_VERSION_404 = 'SECOND_VERSION_404',
  THIRD_VERSION_500 = 'THIRD_VERSION_500',
  THIRD_VERSION_404 = 'THIRD_VERSION_404',
  FOURTH_VERSION_500 = 'FOURTH_VERSION_500',
  FOURTH_VERSION_404 = 'FOURTH_VERSION_404',
  FIFTH_VERSION_500 = 'FIFTH_VERSION_500',
  FIFTH_VERSION_404 = 'FIFTH_VERSION_404',
  FIFTH_VERSION_403 = 'FIFTH_VERSION_403',
  FIFTH_VERSION_401 = 'FIFTH_VERSION_401',
}

export const ErrorPage = ({ version }: { version: ErrorPageVersion }) => {
  const errorsPages = (type: ErrorPageVersion) => {
    const errors = {
      [ErrorPageVersion.FIFTH_VERSION_401]: (() => <FifthVersionErrorPage type={TypeOfError.ERROR_401} />)(),
      [ErrorPageVersion.FIFTH_VERSION_403]: (() => <FifthVersionErrorPage type={TypeOfError.ERROR_403} />)(),
      [ErrorPageVersion.FIRST_VERSION_404]: (() => <FirstVersionErrorPage type={TypeOfErrorFirst.ERROR_404} />)(),
      [ErrorPageVersion.SECOND_VERSION_404]: (() => <SecondVersionErrorPage type={TypeOfErrorSecond.ERROR_404} />)(),
      [ErrorPageVersion.THIRD_VERSION_404]: (() => <ThirdVersionErrorPage type={TypeOfErrorThird.ERROR_404} />)(),
      [ErrorPageVersion.FOURTH_VERSION_404]: (() => <FourthVersionErrorPage type={TypeOfErrorFourth.ERROR_404} />)(),
      [ErrorPageVersion.FIFTH_VERSION_404]: (() => <FifthVersionErrorPage type={TypeOfError.ERROR_404} />)(),
      [ErrorPageVersion.FIRST_VERSION_500]: (() => <FirstVersionErrorPage type={TypeOfErrorFirst.ERROR_500} />)(),
      [ErrorPageVersion.SECOND_VERSION_500]: (() => <SecondVersionErrorPage type={TypeOfErrorSecond.ERROR_500} />)(),
      [ErrorPageVersion.THIRD_VERSION_500]: (() => <ThirdVersionErrorPage type={TypeOfErrorThird.ERROR_500} />)(),
      [ErrorPageVersion.FOURTH_VERSION_500]: (() => <FourthVersionErrorPage type={TypeOfErrorFourth.ERROR_500} />)(),
      [ErrorPageVersion.FIFTH_VERSION_500]: (() => <FifthVersionErrorPage type={TypeOfError.ERROR_500} />)(),
    };
    return errors[type];
  };

  return <Background version={version}>{errorsPages(version)}</Background>;
};
