import { CloseButton } from '@bit/first-scope.close-button';
import { CommonButton } from '@cthings.co/buttons';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as AgreeIcon } from '../../../../assets/agree_button.svg';
import { ReactComponent as Pencil } from '../../../../assets/icons/pencil.svg';
import { ReactComponent as PlusCircle } from '../../../../assets/icons/PlusCircle.svg';
import { TableInputType } from '../../types';

const Wrapper = styled.div``;

const AcceptAndCloseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const AgreeIconWrapper = styled.div`
  display: flex;
`;

const StyledPencil = styled(Pencil)`
  cursor: pointer;
`;

const StyledPlusCircle = styled(PlusCircle)`
  cursor: pointer;
`;

interface EditActionProps {
  data: any;
  savedData: any;
  index: number;
  isEditInProcess: boolean;
  handleConfirm: (data: any, savedData: any, index: number) => void;
  handleCancel: (savedData: any, index: number) => void;
  handleStart: (data: any, index: number) => void;
  handleAdd: any;
}

export const EditAction: FC<EditActionProps> = ({
  data,
  savedData,
  index,
  isEditInProcess,
  handleConfirm,
  handleCancel,
  handleStart,
  handleAdd,
}) => {
  const [wrapperNode, setWrapperNode] = useState<HTMLElement | null>(null);
  const [startNode, setStartNode] = useState<HTMLElement | null>(null);
  const [confirmNode, setConfirmNode] = useState<HTMLElement | null>(null);
  const [cancelNode, setCancelNode] = useState<HTMLElement | null>(null);
  const [addNode, setAddNode] = useState<HTMLElement | null>(null);

  const wrapperRef = useCallback((currentNode: any) => {
    if (currentNode !== null) {
      setWrapperNode(currentNode);
    }
  }, []);

  const startRef = useCallback((currentNode: any) => {
    if (currentNode !== null) {
      setStartNode(currentNode);
    }
  }, []);

  const confirmRef = useCallback((currentNode: any) => {
    if (currentNode !== null) {
      setConfirmNode(currentNode);
    }
  }, []);

  const cancelRef = useCallback((currentNode: any) => {
    if (currentNode !== null) {
      setCancelNode(currentNode);
    }
  }, []);

  const addRef = useCallback((currentNode: any) => {
    if (currentNode !== null) {
      setAddNode(currentNode);
    }
  }, []);

  useEffect(() => {
    if (startNode) {
      const clickFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        handleStart(data, index);
      };
      startNode.addEventListener('touchstart', clickFunc, { passive: false });

      startNode.addEventListener('click', clickFunc);
      return () => {
        startNode.removeEventListener('touchstart', clickFunc);

        startNode.removeEventListener('click', clickFunc);
      };
    }
  }, [startNode, index, data]);

  useEffect(() => {
    if (confirmNode) {
      const clickFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        handleConfirm(data, savedData, index);
      };
      confirmNode.addEventListener('touchstart', clickFunc, { passive: false });

      confirmNode.addEventListener('click', clickFunc);
      return () => {
        confirmNode.removeEventListener('touchstart', clickFunc);

        confirmNode.removeEventListener('click', clickFunc);
      };
    }
  }, [confirmNode, index, data, savedData]);

  useEffect(() => {
    if (cancelNode) {
      const clickFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        handleCancel(savedData, index);
      };
      cancelNode.addEventListener('touchstart', clickFunc, { passive: false });

      cancelNode.addEventListener('click', clickFunc);
      return () => {
        cancelNode.removeEventListener('touchstart', clickFunc);

        cancelNode.removeEventListener('click', clickFunc);
      };
    }
  }, [cancelNode, index, savedData]);

  useEffect(() => {
    if (addNode) {
      const clickFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        handleAdd();
      };
      addNode.addEventListener('touchstart', clickFunc, { passive: false });

      addNode.addEventListener('click', clickFunc);
      return () => {
        addNode.removeEventListener('touchstart', clickFunc);

        addNode.removeEventListener('click', clickFunc);
      };
    }
  }, [addNode]);

  useEffect(() => {
    if (wrapperNode) {
      const pseudoFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
      };

      wrapperNode.addEventListener('touchend', pseudoFunc, { passive: false });

      return () => {
        wrapperNode.removeEventListener('touchend', pseudoFunc);
      };
    }
  }, [wrapperNode]);

  return (
    <Wrapper
      ref={wrapperRef}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {data.value.type === TableInputType.NUMBER_OF_DEVICES ? (
        <StyledPlusCircle ref={addRef} />
      ) : data.value.is_editable ? (
        data.value.type !== TableInputType.ADDRESS && isEditInProcess ? (
          <AcceptAndCloseWrapper>
            <div ref={confirmRef}>
              <CommonButton onClick={() => {}} width={'32px'} height={'32px'}>
                <AgreeIconWrapper>
                  <AgreeIcon />
                </AgreeIconWrapper>
              </CommonButton>
            </div>
            <div ref={cancelRef}>
              <CloseButton onClick={() => {}} />
            </div>
          </AcceptAndCloseWrapper>
        ) : (
          <StyledPencil ref={startRef} />
        )
      ) : null}
    </Wrapper>
  );
};
