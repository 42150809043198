import React, { useEffect, useState } from 'react';
import { TextType } from '@bit/first-scope.text';
import { Info, YAxisData, XAxisData, TooltipWrapper, Arrow } from './styles';
import moment from 'moment';
import { TypeGraph } from '../../../../enums/GraphType';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';

interface CustomCalibrationTooltipProps {
  data?: any;
  tooltipData?: any;
  calibrationData?: any;
  limits?: any;
  theme: any;
  unit?: string;
  isInfo?: boolean;
  id?: string;
  isArrowShown?: boolean;
  tooltipArrowOffset?: number;
  text?: { name?: string; date?: string; info?: string };
}

export const CustomCalibrationTooltip = ({
  theme,
  text = { info: 'The data from calibration period may be unreliable' },
  isArrowShown,
  tooltipArrowOffset,
  isInfo,
  unit,
  data,
  tooltipData,
  calibrationData,
  id,
  limits,
}: CustomCalibrationTooltipProps) => {
  const { active } = tooltipData;
  const { name, date, info } = text;
  const payload = active && tooltipData.payload && tooltipData.payload[0].payload;
  const [isCalibrationArea, setIsCalibrationArea] = useState(false);

  useEffect(() => {
    if (payload) {
      const lastGraphDate = data[data.length - 1].x;

      const map = (calibrationData || []).map((area: any) => {
        const preparedX2 = area.x2 === null ? lastGraphDate : area.x2;

        // const intervals = moment(preparedX2).diff(moment(area.x1));
        const intervals = moment(preparedX2).diff(moment(payload.x));
        const dateToCheckIntervals = moment(payload.x).diff(moment(area.x1));
        // const condition = dateToCheckIntervals >= 0 && dateToCheckIntervals <= intervals;
        const condition = dateToCheckIntervals >= 0 && intervals >= 0;

        return condition;
      });

      if (map.includes(true)) {
        setIsCalibrationArea(true);
      } else {
        setIsCalibrationArea(false);
      }
    }
  }, [payload && payload.x, calibrationData]);

  return active ? (
    <>
      {isArrowShown && <Arrow tooltipArrowOffset={tooltipArrowOffset} />}
      <TooltipWrapper theme={theme}>
        <YAxisData type={TextType.TEXT_14_WHITE}>
          {name ||
            (payload && id === TypeGraph.WATERLEVEL ? (
              <>{`${Math.round((!!data.invertAxis ? limits.y.min - payload.y : payload.y) * 100) / 100} ${
                unit ?? 'm'
              }`}</>
            ) : (
              <>{`${Math.round(payload.y * 100) / 100}°C`}</>
            ))}
        </YAxisData>
        {payload && (
          <XAxisData type={TextType.TEXT_14_WHITE}>{getRequiredDateFormat(payload.x, 'DD.MM.YYYY HH:mm')}</XAxisData>
        )}
        {(isInfo || isCalibrationArea) && (
          <Info type={TextType.TEXT_12_GRAY} color={theme.colors.orange2}>
            {info}
          </Info>
        )}
      </TooltipWrapper>
    </>
  ) : null;
};
