import { hexToRGBA } from '../../utils/hexToRgb';
import { TextType } from '../../enums/TextType';

export const getParams = (type: TextType, theme: any, color?: string) => {
  const params = {
    [TextType.CHIPS_WHITE]: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '14px',
      color: theme.colors.pureWhite,
    },
    [TextType.CHIPS_BLACK]: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '14px',
      color: hexToRGBA(theme.colors.pureBlack, 0.6),
    },
    [TextType.TEXT_14_BLACK]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      color: hexToRGBA(theme.colors.pureBlack, 0.6),
    },
    [TextType.TEXT_14_WHITE]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.colors.pureWhite,
    },
    [TextType.TEXT_14_GRAY]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.colors.gray2,
    },
    [TextType.TEXT_14_RED]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.colors.red1,
    },
    [TextType.TEXT_14_GREEN]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.colors.primary,
    },
    [TextType.PROBLEMS_BUTTON_TEXT]: {
      fontWeight: '700',
      fontSize: '40px',
      lineHeight: '40px',
      color: theme.colors.pureWhite,
    },
    [TextType.HELPER_TEXT_1]: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.colors.gray1,
    },
    [TextType.HELPER_TEXT_2]: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.colors.gray2,
    },
    [TextType.MENU_ITEM_TEXT_PRIMARY]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.colors.black1,
    },
    [TextType.MENU_ITEM_TEXT_ADDITIONAL]: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '16px',
      color: theme.colors.gray1,
    },
    [TextType.TEXT_12_BLACK]: {
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.colors.black1,
    },
    [TextType.TEXT_12_GRAY]: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.colors.gray2,
    },
    [TextType.TEXT_12_GREEN]: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.colors.primary,
    },
    [TextType.TEXT_10_GRAY]: {
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '14px',
      color: theme.colors.gray1,
    },
    [TextType.TEXT_10_WHITE]: {
      fontWeight: '600',
      fontSize: '10px',
      lineHeight: '14px',
      color: theme.colors.pureWhite,
    },
    [TextType.TITLE_PRIMARY]: {
      fontWeight: '700',
      fontSize: '30px',
      lineHeight: '24px',
      color: theme.colors.black1,
    },
    [TextType.TITLE_SECONDARY]: {
      fontWeight: '500',
      fontSize: '30px',
      lineHeight: '24px',
      color: theme.colors.gray2,
    },
    [TextType.TITLE_ADDITIONAL]: {
      fontWeight: '400',
      fontSize: '30px',
      lineHeight: '24px',
      color: theme.colors.gray2,
    },
    [TextType.SEMITITLE]: {
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      color: theme.colors.gray2,
    },
    [TextType.TITLE_H1]: {
      fontWeight: 'bold',
      fontSize: '30px',
      lineHeight: '24px',
      color: theme.colors.black1,
    },
    [TextType.TITLE_H1MOBILE]: {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '24px',
      color: theme.colors.black1,
    },
    [TextType.TITLE_H3]: {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '22px',
      color: theme.colors.gray2,
    },
    [TextType.TITLE_H3MOBILE]: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '22px',
      color: theme.colors.gray2,
    },
    [TextType.TITLE_H4]: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.colors.black1,
    },
    [TextType.TITLE_H4MOBILE]: {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.colors.black1,
    },
    [TextType.TITLE_H5]: {
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.colors.gray2,
    },
    [TextType.TEXT_21_black]: {
      fontWeight: '500',
      fontSize: '21px',
      lineHeight: '16px',
      color: theme.colors.black1,
    },
  };
  const values = params[type] || {};
  const result = color ? { ...values, color } : values;
  return result;
};
