import React, { FC } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '../../../../../../../../../components/commonButton/CommonButton';
import { useStyleContext } from '@cthings.co/styles-utils';
import { selectLanguageStrings } from '../../../../../../../../../app/state/user/selectors';
import { useSelector } from 'react-redux';

export const StyledModal = styled(Modal)`
  ${media.phone} {
    align-items: flex-end;
    & > div {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  align-items: unset;
  padding: 24px;

  ${media.phone} {
    width: 100%;
    padding: 24px 16px;
    border-radius: 12px 12px 0px 0px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.phone} {
    flex-direction: column-reverse;
  }
`;

interface DeleteModalProps {
  isOpenModal: boolean;
  onDelete: () => void;
  setModalClose: (props: boolean) => void;
}

export const DeletePhotoModal: FC<DeleteModalProps> = ({ isOpenModal, onDelete, setModalClose }) => {
  const [media] = useStyleContext();
  const { phone } = media.mediaType;

  const theme = useTheme();
  const { black1, pureWhite, primary, red1, gray1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const handleCancelClick = () => {
    setModalClose(false);
  };

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer width={'384px'}>
        <Text type={TextType.TEXT_14_BLACK} fontSize={'18px'} weight={'600'} color={black1}>
          {languageStrings.emphasizedHeaderTextModalTitle}
        </Text>
        <Text
          type={TextType.TEXT_14_GRAY}
          weight={'400'}
          color={gray1}
          margin={phone ? '24px 0 24px 0' : '14px 0 32px 0'}
        >
          {languageStrings.thePhotoWillBeDeleted}
        </Text>
        <ButtonsRow>
          <CommonButton
            onClick={handleCancelClick}
            width={phone ? '100%' : '116px'}
            margin={phone ? '0' : '0 16px 0 0'}
            color={black1}
            borderColor={primary}
            backgroundColor={pureWhite}
            height={phone ? '40px' : '32px'}
            fontSize={'14px'}
          >
            {languageStrings.noButton}
          </CommonButton>
          <CommonButton
            onClick={onDelete}
            width={phone ? '100%' : '116px'}
            margin={phone ? '0 0 10px 0' : '0'}
            borderColor={red1}
            backgroundColor={red1}
            backgroundColorHover={red1}
            height={phone ? '40px' : '32px'}
            fontSize={'14px'}
          >
            {languageStrings.yesButton}
          </CommonButton>
        </ButtonsRow>
      </StyledModalContainer>
    </StyledModal>
  );
};
