import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { DashboardState } from './types';

export const setDashboardData = (state: WritableDraft<DashboardState>, action: PayloadAction<any>) => {
  state.data = action.payload;
};

export const reducers = {
  setDashboardData,
};
