import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../../../utils/date/date-format';
import { InfoContentTable } from './components/infoContentTable/InfoContentTable';
import { isEmptyObject } from '../../../../../../../utils/isEmptyObject';
import { withLoader } from '../../../../../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { TableBlockWrapper } from '../common/Common';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/user';

const Wrapper = styled.div`
  width: 100%;
  ${media.desktop} {
    min-width: 288px;
  }
`;

const TableWrapper = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(Wrapper);

export enum SignalStrengthType {
  RSRP = 'RSRP',
  RSRQ = 'RSRQ',
  SINR = 'SINR',
  RSSI = 'RSSI',
}

export const CurrentNetworkTable = ({ data = [] }: { data: any }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const { lac_tac, ci, conn_tech_name, conn_tech_pref, conn_tech_pref_name, rsrp, rsrq, rssi, sinr, ts } = data;

  const arrNode = !isEmptyObject(data)
    ? [
        {
          property: languageStrings.currentNetworkLastUpdated,
          value: getRequiredDateFormat(ts, 'DD.MM.YYYY, HH:mm'),
          isColor: false,
          type: false,
        },
        {
          property: languageStrings.currentNetworkConnectionTechnology,
          value: conn_tech_name,
          isColor: false,
          type: false,
        },
        {
          property: languageStrings.currentNetworkPreferedConectionTechnology,
          value: conn_tech_pref_name,
          isColor: false,
          type: false,
        },
        {
          property: 'Tracking Area Code (TAC)',
          value: lac_tac,
          isColor: false,
          type: false,
        },
        { property: 'Cell ID (CI)', value: ci, isColor: false, type: false },
        { property: 'T3412 Periodic TAU', value: conn_tech_pref, isColor: false, type: false },
        {
          property: 'Received Signal Strength Indicator (RSSI)',
          value: rssi,
          isColor: true,
          type: SignalStrengthType.RSSI,
        },
        {
          property: 'Reference Signal Received Power (RSRP)',
          value: rsrp,
          isColor: true,
          type: SignalStrengthType.RSRP,
        },
        {
          property: 'Signal-To-Noise ratio (SINR)',
          value: sinr,
          isColor: true,
          type: SignalStrengthType.SINR,
        },
        {
          property: 'Reference Signal Received Quality (RSRQ)',
          value: rsrq,
          isColor: true,
          type: SignalStrengthType.RSRQ,
        },
      ]
    : [];

  const placeholder = {
    keyword: 'InsightDetailsDeviceConfigurationTable',
    placeholderOptions: { withoutPagination: false },
    conditionalStyle: { overflow: 'hidden', borderRadius: '8px' },
  };

  return (
    <TableBlockWrapper>
      <Text type={TextType.SEMITITLE} margin={'0 0 20px 0'}>
        {languageStrings.insightDetailsCurrentNetworkTitle}
      </Text>
      <TableWrapper placeholderProps={placeholder}>
        <InfoContentTable
          arr={arrNode}
          placeholderProps={{
            keyword: 'OrderInfoTable',
            style: { width: '100%', boxSizing: 'border-box', borderRadius: '8px' },
            placeholderOptions: { countRows: 4, withoutPagination: false },
          }}
        />
      </TableWrapper>
    </TableBlockWrapper>
  );
};
