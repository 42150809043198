import React, { FC } from 'react';
import { WrapperLogout, UserNameWrapper, TextWrapper, Line, LogoutButton, StyledLogoutText } from './styled';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/user';
//images
import { ReactComponent as LogOutImage } from '../../../assets/Logout.svg';
import { useTheme } from '@cthings.co/styled-components';

export interface LogoutItemTypes {
  userName: string | undefined;
  onLogout: any;
  isOpen: boolean;
}

export const LogoutItem: FC<LogoutItemTypes> = ({ userName, onLogout, isOpen }) => {
  const theme = useTheme();
  const { black1, gray2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <WrapperLogout>
      <UserNameWrapper className={isOpen ? 'open' : ''}>
        <Text type={TextType.TEXT_12_BLACK} fontSize={'14px'} color={gray2}>
          {userName}
        </Text>
      </UserNameWrapper>
      <Line className={isOpen ? 'open' : ''} />
      <LogoutButton onClick={onLogout}>
        <LogOutImage />
        <TextWrapper className={isOpen ? 'open' : ''}>
          <StyledLogoutText type={TextType.TEXT_12_BLACK} color={black1}>
            {languageStrings.navigationLogout}
          </StyledLogoutText>
        </TextWrapper>
      </LogoutButton>
    </WrapperLogout>
  );
};
