import React from 'react';
import styled from '@cthings.co/styled-components';
import { CardType, DashboardCard } from '../../../../../../../../components/dashboardCard/DashboardCard';
import { InfoCard } from '../../../../../../../../components/infoCard/InfoCard';
import { media } from '@cthings.co/styles-utils';
import { StatusOfCard } from '../../../../../../../../types/statusOfCards';

const GlobalRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  ${media.tablet} {
    flex-direction: row;
  }
`;

const CardRow = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

const StyledCard = styled(DashboardCard)`
  ${media.desktop} {
    width: 100%;
    margin: 0;
  }
`;

const Space = styled.div`
  flex: 1;
  ${media.desktop} {
    order: -1;
  }
  ${media.phone} {
    display: none;
  }
`;

export const Cards = ({
  languageStrings,
  placeholderCard1Style,
  groundWaterLevel,
  statusOfWater,
  infoCardData,
}: {
  languageStrings: any;
  placeholderCard1Style: any;
  groundWaterLevel: any;
  statusOfWater: StatusOfCard;
  infoCardData?: any;
}) => {
  const { heading, issues } = infoCardData ? infoCardData : { heading: '', issues: '' };

  return (
    <GlobalRow>
      <CardRow>
        <StyledCard
          placeholderProps={{
            keyword: 'GroundWaterDetailsCard',
            style: placeholderCard1Style,
          }}
          languageStrings={languageStrings}
          title={groundWaterLevel ? groundWaterLevel.tile_name : 'N/A'}
          type={CardType.WATER_LEVEL}
          count={
            groundWaterLevel && !isNaN(groundWaterLevel.measurement)
              ? `${groundWaterLevel.measurement} ${groundWaterLevel.unit}`
              : 'N/A'
          }
          statusOfValue={statusOfWater}
        />
        {infoCardData && (
          <>
            <Space />
            <InfoCard title={heading} data={issues} />
          </>
        )}
      </CardRow>
    </GlobalRow>
  );
};
