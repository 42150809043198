import { ACTION_MAPPING, DEVICE_ACTION_MAPPING } from './consts';
import { COMPANY_CREATION_STEP, getActions, DEVICE_TOOL_STEP } from './types';

export const getFormattedActions = (
  treeHistory: COMPANY_CREATION_STEP[] | DEVICE_TOOL_STEP[],
  isDeviceMode?: boolean,
) => {
  const actions = getActions(treeHistory, isDeviceMode);
  const currentItem = treeHistory[treeHistory.length - 1];
  const names = isDeviceMode ? DEVICE_ACTION_MAPPING[currentItem as DEVICE_TOOL_STEP] : ACTION_MAPPING[currentItem];
  const listOfActions = (names || []).map((name) => ({ name, to: actions[name] }));
  return listOfActions.reduce(
    (actions, item) => ({ ...actions, [item.name]: item.to }),
    {} as { [K in string]: COMPANY_CREATION_STEP | undefined },
  );
};
