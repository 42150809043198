import { Text, TextType } from '@bit/first-scope.text';
import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';
import { UniversalTableSelect } from '../../../universalTableSelect/UniversalTableSelect';
import { TableInputType } from '../../types';
import { RequestBlock } from '../requestedBlock/RequestedBlock';

const Wrapper = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  flex: 1;
  word-break: break-all;
`;

const Select = styled(UniversalTableSelect)`` as any; // TODO fix type

interface SelectProps {
  value: {
    type: TableInputType;
    actual_value: { id: string; name: string } | null;
    requested_value: { id: string; name: string } | null;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  valuesList: any;
  isEditInProcess?: boolean;
  options?: { hackOverflow?: boolean; isError?: boolean; placeholder?: string; isNumber?: boolean };
}

export const SelectField: FC<SelectProps> = ({ value, setField, valuesList, isEditInProcess, options }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const { actual_value, requested_value, with_requested, is_editable } = value;

  const setSelectValue = (actual_value: string) => setField({ ...value, actual_value });

  return (
    <Wrapper>
      {is_editable && isEditInProcess ? (
        <Select
          value={actual_value}
          list={valuesList}
          onChange={setSelectValue}
          hackOverflow={!!options?.hackOverflow}
        />
      ) : (
        <StyledText type={TextType.TEXT_14_GRAY} color={gray1}>
          {actual_value?.name ? actual_value?.name : 'N/A'}
        </StyledText>
      )}
      {with_requested && <RequestBlock requested_value={requested_value?.name ?? null} />}
    </Wrapper>
  );
};
