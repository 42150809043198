// UTILS OF COMPONENT FOR CHECKING AVAILABILITY FOR THE PAGES
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
//****************************
import { stringifyUrl } from 'query-string';
import { useEffect } from 'react';
import { useLocation } from '@cthings.co/router-dom';
import { View, getPath } from '../../routes';
import { useCurrentView } from '../../routes/currentView';
import { useHistory } from '../../utils/react-router-dom-abstraction';

export const useRedirectTo404Error = () => {
  // history RRD v6+
  const history = useHistory();
  const { pathname } = useLocation();

  // VEIW of current page
  const { currentView } = useCurrentView();

  // block of code which redirecting to the error 404 if user trying go to wrong URL
  useEffect(() => {
    if (currentView === View.ERROR_404 && pathname !== getPath(View.ERROR_404)) {
      //history.replace(stringifyUrl({ url: getPath(View.ERROR_404) }));
    }
  }, [currentView]);
};
