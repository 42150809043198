import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';
import { useContentForErrorPages } from '../useContentForErrorPages';
import { media } from '@cthings.co/styles-utils';

export enum TypeOfErrorSecond {
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
}

const Wrapper = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.semiTablet} {
    padding: 0 15px;

    & > svg {
      width: 100%;
    }
  }
`;

type ContainerProps = {
  type: TypeOfErrorSecond;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: ${({ type }) => (type === TypeOfErrorSecond.ERROR_500 ? '75px' : '60px')};
  & > .title {
    font-size: 36px;
    line-height: 54px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  & > span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
`;

type ButtonWrapperProps = {
  type: TypeOfErrorSecond;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: 325px;
  display: flex;
  justify-content: ${({ type }) => (type === TypeOfErrorSecond.ERROR_500 ? 'space-between' : 'center')};
  margin-top: 34px;
`;

type StyledButtonType = {
  minWidth: string;
};

const StyledButton = styled(CommonButton)<StyledButtonType>`
  min-width: ${({ minWidth }) => minWidth};
  width: auto;
`;

export const SecondVersionErrorPage = ({ type }: { type: TypeOfErrorSecond }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const { secondVersionErrorPage500, secondVersionErrorPage404 } = useContentForErrorPages();

  const getErrorPageValue = (type: TypeOfErrorSecond) => {
    const value = {
      [TypeOfErrorSecond.ERROR_500]: secondVersionErrorPage500(),
      [TypeOfErrorSecond.ERROR_404]: secondVersionErrorPage404(),
    };
    return value[type];
  };

  const selectedValue: any = getErrorPageValue(type);

  return (
    <Wrapper>
      {selectedValue.image}
      <Container type={type}>
        <Text type={TextType.TITLE_H1} className="title">
          {selectedValue.title}
        </Text>
        <Text type={TextType.SEMITITLE} color={black1}>
          {selectedValue.description}
        </Text>
        <ButtonWrapper type={type}>
          {selectedValue.buttons.map((item: any, index: number) => {
            const { onClick, backgroundColor, backgroundColorHover, color, value, width, height } = item;
            return (
              <StyledButton
                key={index}
                onClick={onClick}
                minWidth={width}
                height={height}
                colors={{
                  main: color,
                  background: backgroundColor,
                  backgroundHover: backgroundColorHover,
                }}
              >
                {value}
              </StyledButton>
            );
          })}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};
