import React from 'react';
import { use100vh } from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { useUserAccess } from '../../api/user';
import { selectDevices } from '../../app/state/insight';
import { selectAccess, selectLanguageStrings } from '../../app/state/user';
import { MAPBOX_FLAG } from '../../consts';
import { LsValueType } from '../../enums/LsValueType';
import { UserType } from '../../enums/UserType';
import { AccessHandler } from '../../features/accessHandler/AccessHandler';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import withAuth from '../../features/authentication/withAuth';
import { MenuItemType } from '../../features/navigation/MenuItem';
import { Navigation } from '../../features/navigation/Navigation';
import { View } from '../../routes';
import { PATHS, injectPathParams } from '../../routes/paths';
import { useViewMatch } from '../../routes/utils';
import withUserType from '../../routes/withUserType';
import { renderRoutes } from '../../utils/renderRoutes';
import ss from '../../utils/ss';
import { Body, Wrapper } from '../elements/wrappers';
import { NoBillingDetailsBanner } from '../../features/noBillingDetailsBanner/NoBillingDetailsBanner';
import { InvalidVATBanner } from '../../features/invalidVATBanner/InvalidVATBanner';

const User = (props: any) => {
  useUserAccess();
  const globalAccess = useSelector(selectAccess);

  const { role_name } = globalAccess;
  const { role } = globalAccess;
  const isNotUser = role !== 0;

  const isVATInvalid = globalAccess.billing.vat_invalid;
  const isInvoiceUnpaid = globalAccess.billing.unpaid_invoice;

  const devices = useSelector(selectDevices);
  const { zoom_level_mapbox, centre_coord } = devices;

  const handleLogout = (e: any) => {
    e.preventDefault();
    ss.remove(LsValueType.token);
    oauthRedirect();
  };

  const languageStrings = useSelector(selectLanguageStrings);

  const menuItemArray = [
    { type: MenuItemType.DASHBOARD, route: PATHS.USER_DASHBOARD, value: languageStrings.dashboardTitle },
    {
      type: MenuItemType.INSIGHT,
      route: injectPathParams(PATHS.USER_INSIGHTS, {
        lat: centre_coord.lat || 54.5,
        lon: centre_coord.lng || 17.54,
        zoom: zoom_level_mapbox || 5,
      }),
      value: languageStrings.navigationInsight,
      hidden: !MAPBOX_FLAG,
    },
    {
      type: MenuItemType.INCIDENTS,
      route: PATHS.USER_INCIDENTS,
      value: languageStrings.navigationIncidents,
    },
    {
      type: MenuItemType.MANAGE,
      route: View.MANAGE,
      value: languageStrings.navigationManage,
      childRoutes: [
        {
          name: languageStrings.navigationClients,
          to: PATHS.MANAGE_ORGANISATIONS,
        },
        {
          name: languageStrings.navigationDevices,
          to: PATHS.MANAGE_DEVICES,
        },
        {
          name: languageStrings.navigationUsers,
          to: PATHS.MANAGE_USERS,
        },
      ],
    },
    {
      type: MenuItemType.REPORTS,
      route: PATHS.USER_REPORTS,
      value: languageStrings.navigationReports,
    },
    {
      type: MenuItemType.ANALYTICS,
      route: View.MANAGE_ANALYTICS,
      value: languageStrings.analyticsItem,
      childRoutes: [
        {
          name: languageStrings.measurementItem,
          to: PATHS.MANAGE_MEASUREMENT,
        },
      ],
    },
    {
      type: MenuItemType.BILLING,
      route: View.BILLING,
      value: languageStrings.billing,
      childRoutes: [
        // {
        //   name: languageStrings.plans,
        //   to: PATHS.BILLING_PLANS,
        // },
        {
          name: languageStrings.invoices,
          to: PATHS.BILLING_INVOICES,
        },
        {
          name: languageStrings.details,
          to: PATHS.BILLING_DETAILS,
        },
      ],
    },
    {
      type: MenuItemType.DOCUMENTS,
      route: PATHS.USER_DOCUMENTS,
      value: languageStrings.navigationDocuments,
    },
  ];

  const menuMobileItemArray = [
    { type: MenuItemType.DASHBOARD, route: PATHS.USER_DASHBOARD, value: languageStrings.dashboardTitle },
    {
      type: MenuItemType.INSIGHT,
      route: injectPathParams(PATHS.USER_INSIGHTS, {
        lat: centre_coord.lat || 54.5,
        lon: centre_coord.lng || 17.54,
        zoom: zoom_level_mapbox || 5,
      }),
      value: languageStrings.navigationInsight,
      hidden: !MAPBOX_FLAG,
    },
    {
      type: MenuItemType.MANAGE,
      route: View.MANAGE,
      value: languageStrings.navigationManage,
      childRoutes: [
        {
          name: languageStrings.navigationClients,
          to: PATHS.MANAGE_ORGANISATIONS,
        },
        {
          name: languageStrings.navigationDevices,
          to: PATHS.MANAGE_DEVICES,
        },
        {
          name: languageStrings.navigationUsers,
          to: PATHS.MANAGE_USERS,
        },
      ],
    },
    {
      type: MenuItemType.REPORTS,
      route: PATHS.USER_REPORTS,
      value: languageStrings.navigationReports,
    },
    {
      type: MenuItemType.ANALYTICS,
      route: View.MANAGE_ANALYTICS,
      value: languageStrings.analyticsItem,
      childRoutes: [
        {
          name: languageStrings.measurementItem,
          to: PATHS.MANAGE_MEASUREMENT,
        },
      ],
    },
    {
      type: MenuItemType.BILLING,
      route: View.BILLING,
      value: languageStrings.billing,
      childRoutes: [
        // {
        //   name: languageStrings.plans,
        //   to: PATHS.BILLING_PLANS,
        // },
        {
          name: languageStrings.invoices,
          to: PATHS.BILLING_INVOICES,
        },
        {
          name: languageStrings.details,
          to: PATHS.BILLING_DETAILS,
        },
      ],
    },
  ];

  const minHeight = use100vh();

  const matchPath = useViewMatch();

  const withoutPadding = matchPath((View as any).USER_INSIGHTS_DETAILS);

  const isShowFillOutBillingInformationBanner = !isInvoiceUnpaid && globalAccess.isAccess && !globalAccess.dashboard;
  const isShowInvalidVATBanner = !isInvoiceUnpaid && isNotUser && isVATInvalid;

  return (
    <AccessHandler>
      <Wrapper minHeight={minHeight}>
        <Navigation
          menuItemArray={menuItemArray}
          menuMobileItemArray={menuMobileItemArray}
          onLogout={handleLogout}
          userStatus={role_name ? role_name : 'User'}
          withLanguages
        />
        <Body withoutPadding={!!withoutPadding}>{renderRoutes(props.routes, props.parentPathLength)}</Body>
        {isInvoiceUnpaid && (
          <NoBillingDetailsBanner
            isBlockingAccess={isInvoiceUnpaid}
            hideButton={!!matchPath((View as any).BILLING_INVOICES)}
          />
        )}
        {isShowFillOutBillingInformationBanner && (
          <NoBillingDetailsBanner hideButton={!!matchPath((View as any).BILLING_DETAILS)} />
        )}
        {isShowInvalidVATBanner && <InvalidVATBanner hideButton={!!matchPath((View as any).BILLING_DETAILS)} />}
      </Wrapper>
    </AccessHandler>
  );
};

export default withAuth(withUserType(UserType.USER)(User));
