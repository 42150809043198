import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useContentForErrorPages } from '../useContentForErrorPages';
import { media } from '@cthings.co/styles-utils';

export enum TypeOfErrorThird {
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;

  & > span {
    font-weight: 400;
    font-size: 35px;
    line-height: 52px;
    color: ${colorFetch('black')};
  }
  & > .large {
    font-weight: 600;
    font-size: 55px;
    line-height: 82px;
  }

  ${media.semiTablet} {
    padding: 0 15px;
  }
`;

const TextWrapper = styled.div`
  width: 616px;
  & > .small {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  ${media.semiTablet} {
    width: 100%;
  }
`;

type ButtonWrapperProps = {
  type: TypeOfErrorThird;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: 325px;
  display: flex;
  justify-content: ${({ type }) => (type === TypeOfErrorThird.ERROR_500 ? 'space-between' : 'flex-start')};
  margin-top: 18px;
`;

const SupportText = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${colorFetch('blue3')};
  cursor: pointer;
`;

const StyledButton = styled(CommonButton)`
  min-width: 157px;
  width: auto;
`;

export const ThirdVersionErrorPage = ({ type }: { type: TypeOfErrorThird }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const { thirdVersionErrorPage500, thirdVersionErrorPage404 } = useContentForErrorPages();

  const getErrorPageValue = (type: TypeOfErrorThird) => {
    const value = {
      [TypeOfErrorThird.ERROR_500]: thirdVersionErrorPage500(),
      [TypeOfErrorThird.ERROR_404]: thirdVersionErrorPage404(),
    };
    return value[type];
  };

  const selectedValue: any = getErrorPageValue(type);

  return (
    <Wrapper>
      <Text type={TextType.TITLE_H1}>{selectedValue.title}</Text>
      <Text className="large" type={TextType.SEMITITLE}>
        {selectedValue.description}
      </Text>
      <TextWrapper>
        <Text className="small" type={TextType.SEMITITLE} color={black1}>
          {selectedValue.secondDescription}
          {selectedValue.supportText && (
            <SupportText onClick={selectedValue.supportText.onClick}>{selectedValue.supportText.value}</SupportText>
          )}
        </Text>
      </TextWrapper>
      <ButtonWrapper type={type}>
        {selectedValue.buttons.map((item: any, index: number) => {
          const { onClick, color, backgroundColor, backgroundColorHover, value } = item;
          return (
            <StyledButton
              key={index}
              height="40px"
              colors={{
                main: color,
                background: backgroundColor,
                backgroundHover: backgroundColorHover,
              }}
              onClick={onClick}
            >
              {value}
            </StyledButton>
          );
        })}
      </ButtonWrapper>
    </Wrapper>
  );
};
