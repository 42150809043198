import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { MINtoHHMM, convertTimeDataForBackend, convertTimeDataForInputs } from '../../../../../utils/date/functions';
import { getTextWidth } from '../../../../../utils/textWidth';
import { TableInputType } from '../../../types';
import { RequestBlock } from '../../requestedBlock/RequestedBlock';

const Wrapper = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.phone} {
    flex-direction: column;
  }
`;

const PeriodsWrapper = styled.div`
  width: 100%;
  /* display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 10px; */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  ${media.phone} {
    /* grid-template-columns: 1fr 1fr; */
    /* display: flex; */
    flex-direction: column;
  }
`;

const MinutesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  gap: 5px;
  ${media.phone} {
    width: 100%;
  }
`;

const HoursWrapper = styled(MinutesWrapper)`
  ${media.phone} {
    margin-right: 0;
  }
`;

type StyledInputProps = {
  width: number;
  borderRadius: string;
};

const StyledInput = styled.input<StyledInputProps>`
  height: 32px;
  min-width: 20px;
  width: ${({ width }) => `${width}px`};
  padding: 5px 10px;
  border: none;
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid ${colorFetch('gray3')};
  border-radius: ${({ borderRadius }) => borderRadius};
  text-align: center;
  transition: border-color 0.3s ease-in-out;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};

  &:focus {
    outline: none !important;
    border-color: ${colorFetch('primary')};
  }
`;

const StyledText = styled(Text)`
  word-break: break-all;
`;

const StyledTextUnit = styled(Text)`
  /* margin-left: 5px;
  ${media.phone} {
      position: absolute;
      right: 20px;
  } */
`;

export const TimePickerFieldsHHMM = ({
  value,
  state,
  setField,
  isEditInProcess,
  isError,
  highlightValue,
}: {
  value: {
    type: TableInputType;
    actual_value: number;
    requested_value: number;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  isEditInProcess?: boolean;
  isError?: boolean;
  highlightValue?: string | any;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const { primary } = theme.borderRadius;

  const languageStrings = useSelector(selectLanguageStrings);

  const data = value.actual_value ? convertTimeDataForInputs(value.actual_value, value.type) : [0, 0];
  const requestedData = value.requested_value && convertTimeDataForInputs(value.requested_value, value.type);

  const regexForInputs = /^\d{0,4}?$/gm;

  const [hours, setHours] = useState<any>(`${data[0]}`);
  const [minutes, setMinutes] = useState<any>(`${data[1]}`);

  useEffect(() => {
    if (data && !isEditInProcess) {
      data[0] !== +hours && setHours(`${data[0]}`);
      data[1] !== +minutes && setMinutes(`${data[1]}`);
    }
  }, [data]);

  const handleOnChange = (e: any, type: string) => {
    setField({ ...value, actual_value: convertTimeDataForBackend([hours, minutes], value.type) });
    if (regexForInputs.test(e.target.value)) {
      if (type === languageStrings.hours) {
        hours.length <= 4 && setHours(+e.target.value + '');
      }

      if (type === languageStrings.mins) {
        minutes.length <= 4 && setMinutes(+e.target.value + '');
      }
    }
  };

  const onBlur = (type: string) => {
    if (type === languageStrings.mins) {
      switch (minutes.length) {
        case 1:
          break;
        case 2:
        case 3:
        case 4:
          if (+minutes >= 60) {
            setHours((el: string) => `${+el + +MINtoHHMM(minutes)[0]}`);
            setMinutes(`${+MINtoHHMM(minutes)[1]}`);
          }
          break;
        default:
          setMinutes('0');
      }
    }

    setField({ ...value, actual_value: convertTimeDataForBackend([hours, minutes], value.type) });
  };

  const styledInput = (value: string, text: string) => (
    <>
      <StyledInput
        key={text}
        value={value}
        onBlur={() => {
          onBlur(text);
        }}
        onClick={(e: any) => e.target.select()}
        onKeyDown={(e: any) => {
          if (+e.keyCode === 13) {
            e.target.blur();
          }
        }}
        onChange={(e: any) => handleOnChange(e, text)}
        width={getTextWidth(value)}
        borderRadius={primary}
      />
      <StyledTextUnit color={gray2} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
        {text}
      </StyledTextUnit>
    </>
  );

  return (
    <Wrapper>
      {isEditInProcess ? (
        <PeriodsWrapper>
          <HoursWrapper>{styledInput(hours, languageStrings.hours)}</HoursWrapper>
          <MinutesWrapper>{styledInput(minutes, languageStrings.mins)}</MinutesWrapper>
        </PeriodsWrapper>
      ) : (
        <StyledText color={highlightValue || gray1} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
          {hours} {languageStrings.hours}, {minutes} {languageStrings.mins}
        </StyledText>
      )}
      {!isEditInProcess && value.with_requested && (
        <RequestBlock
          requested_value={`${requestedData[0]} ${languageStrings.hours} ${requestedData[1]} ${languageStrings.mins}`}
        />
      )}
    </Wrapper>
  );
};
