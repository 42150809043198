import React from 'react';

export const DropletIcon = ({ primaryColor, secondaryColor }: { primaryColor: string; secondaryColor: string }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.6829 7.28711L46.6272 22.2315C49.5827 25.185 51.5958 28.9487 52.412 33.0465C53.2281 37.1443 52.8106 41.3921 51.2123 45.2526C49.614 49.1131 46.9066 52.4129 43.4327 54.7345C39.9588 57.0561 35.8743 58.2953 31.6961 58.2953C27.5178 58.2953 23.4333 57.0561 19.9594 54.7345C16.4855 52.4129 13.7781 49.1131 12.1798 45.2526C10.5815 41.3921 10.164 37.1443 10.9801 33.0465C11.7963 28.9487 13.8094 25.185 16.7649 22.2315L31.6829 7.28711Z"
        stroke={primaryColor}
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
