import React, { FC } from 'react';
import withAuth from '../../features/authentication/withAuth';
import { renderRoutes } from '../../utils/renderRoutes';
import styled from '@cthings.co/styled-components';
import { LanguageSelector } from '../../components/languageSelector/LanguageSelector';
import { media } from '@cthings.co/styles-utils';

const StyledLanguageSelector = styled(LanguageSelector)`
  top: 40px;
  right: 40px;
  position: absolute;

  ${media.phone} {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    position: initial;
  }
`;

interface ProvisioningProps {}

const ProvisioningPlain: FC<ProvisioningProps> = (props: any) => {
  return (
    <>
      <StyledLanguageSelector />
      {renderRoutes(props.routes, props.parentPathLength)}
    </>
  );
};

export const Provisioning = withAuth(ProvisioningPlain);
