import React, { FC, ReactNode } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { NavLink } from '../../utils/react-router-dom-abstraction';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: transparent;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 5px 10px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colorFetch('gray2')};
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.2px;
  user-select: none;
  transition: all 0.3s linear;
  &:hover {
    background-color: ${colorFetch('gray2')};
    color: ${colorFetch('white')};
    border-color: ${colorFetch('gray2')};
  }
  ${media.tablet} {
    display: none;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
`;

export interface DeleteButtonProps {
  onClick?: any;
  className?: string;
  children?: ReactNode;
  href?: string;
}

export const DetailsButton: FC<DeleteButtonProps> = ({ href, onClick, children, ...props }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const to = !href?.includes('undefined') && href;

  return to ? (
    <StyledLink access={[]} to={to as string}>
      <Button onClick={onClick} {...props}>
        {children || languageStrings.problemsButtonDetails}
      </Button>
    </StyledLink>
  ) : (
    <Button onClick={onClick} {...props}>
      {children || languageStrings.problemsButtonDetails}
    </Button>
  );
};
