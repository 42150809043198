import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as CpuSmall } from '../../../../../../../assets/cpuSmall.svg';
import { ReactComponent as ArrowImage } from '../../../../../../../assets/mini_arrow.svg';
import { Map } from '../../../../../../../components/map/Map';
import { View } from '../../../../../../../routes/routeInterfaces';
import { media } from '@cthings.co/styles-utils';
import ss from '../../../../../../../utils/ss';
import { LsValueType } from '../../../../../../../enums/LsValueType';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { NavLink, useHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { MeasurementDevices } from '../../../types';
import { stringifyUrl } from 'query-string';
import { injectPathParams } from '@cthings.co/utils';
import { getPath } from '../../../../../../../routes';

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 45px;
  left: 45px;
  ${media.tablet} {
    top: 16px;
    left: 16px;
  }
`;

const BlockForButtons = styled.div`
  position: absolute;
  top: 45px;
  right: 45px;
  display: flex;
  ${media.tablet} {
    top: 16px;
    right: 16px;
  }
`;

const StyledCommonButton = styled(CommonButton)`
  width: max-content;
  padding: 0 15px;
  &:hover > div > svg > path {
    stroke: ${colorFetch('white')};
    transition: stroke 0.3s ease;
  }

  ${media.tablet} {
    width: 32px;
    & > div > div {
      display: none;
    }
  }

  ${media.desktop} {
    &:hover > div > svg > path {
      stroke: ${({ color }: any) => color};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.div`
  margin-left: 10px !important;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
`;

export const Header = ({ languageStrings, data }: { data?: any; languageStrings: any }) => {
  const history = useHistory();
  const theme = useTheme();
  const { primary, pureWhite } = theme.colors;
  const isManningsPair = data?.type === MeasurementDevices.MANNINGS_PAIR;
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const lat_1 = parseFloat(data?.devices[0]?.address?.geotag?.lat);
  const lng_1 = parseFloat(data?.devices[0]?.address?.geotag?.lng);
  const lat_2 = parseFloat(data?.devices[1]?.address?.geotag?.lat);
  const lng_2 = parseFloat(data?.devices[1]?.address?.geotag?.lng);

  const coordinate_1 = (lat_1 + lat_2) / 2;
  const coordinate_2 = (lng_1 + lng_2) / 2;

  const geotagData = [
    { lat: lat_1, lng: lng_1 },
    { lat: lat_2, lng: lng_2 },
  ];

  const onBackButtonClick = () => {
    history.goBack();
  };

  const to = View.USER_MEASUREMENT_DEVICE_DETAILS;

  const href = stringifyUrl({
    url: injectPathParams(getPath(to), { id: data?.id, offset: 0 }),
  });

  return (
    <>
      <Map
        placeholderProps={{
          keyword: 'ManholesDetailsMap',
        }}
        zoom={12}
        data={isManningsPair && geotagData}
        isGradiented
        latitude={isManningsPair ? coordinate_1 : parseFloat(data?.location?.geotag?.lat)}
        longitude={isManningsPair ? coordinate_2 : parseFloat(data?.location?.geotag?.lng)}
        deviceType={'smart_manhole'}
        staticDisplay
      />
      <BackButtonWrapper>
        <StyledCommonButton onClick={onBackButtonClick}>
          <ButtonContainer>
            <ArrowImage />
            <ButtonText>{languageStrings.backButton}</ButtonText>
          </ButtonContainer>
        </StyledCommonButton>
      </BackButtonWrapper>
      <StyledLink access={[]} to={`${href}?access_token=${token}&expires=${exp}`}>
        <BlockForButtons>
          <StyledCommonButton
            colors={{
              main: primary,
              background: pureWhite,
            }}
          >
            <ButtonContainer>
              <CpuSmall />
              <ButtonText>{languageStrings.insightDetailsGoToFlowButton}</ButtonText>
            </ButtonContainer>
          </StyledCommonButton>
        </BlockForButtons>
      </StyledLink>
    </>
  );
};
