import {
  themeNID,
  themeIdentity as themeIdentityFDS,
  mpwikTheme as mpwikThemeFDS,
} from '@cthings.co/styled-components';

const pureWhite = '#ffffff';

export const themeIdentity = {
  ...themeIdentityFDS,
  colors: {
    ...themeIdentityFDS.colors,
    white: pureWhite,
    black: '#28293D',
  },
} as const;

export const theme = {
  ...themeNID,
  colors: {
    ...themeNID.colors,
    white: pureWhite,
    black: '#28293D',
  },
} as const;

export const mpwikTheme = {
  ...mpwikThemeFDS,
  colors: {
    ...mpwikThemeFDS.colors,
    white: pureWhite,
    black: '#28293D',
  },
} as const;
