import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { TableInput, TableInputType } from '../../../../../components/tableInput/TableInput';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { FieldsType, isValidField } from '../../../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { selectDate, setDate } from '../../../../../app/state/incidents';

const StyledTableInput = styled(TableInput)`
  & > div {
    margin: 0;
  }
  & > div > div:first-child {
    display: none;
  }
  & > div > div > input {
    height: 40px;
    text-align: start;
  }
`;

export const TimeField = ({ setField, languageStrings }: { setField: Function; languageStrings?: any }) => {
  const date = useSelector(selectDate);
  const [time, setTime] = useState(getRequiredDateFormat(date, 'HHmm'));
  const dispatch = useDispatch();

  const onChange = (value: string) => {
    setTime(value);
  };

  return (
    <StyledTableInput
      value={time}
      type={TableInputType.TIME_PICKER}
      options={{
        width: '100%',
        textOfErrorMessage: '',
        title: languageStrings.startTimeLabel,
        placeholder: 'Enter start time',
        isError: isValidField(time, FieldsType.TEXT),
        onBlur: (val?: string) => {
          const preparedTime = val ? `${val[0]}${val[1]}:${val[2]}${val[3]}` : `${time.slice(0, 2)}:${time.slice(2)}`;
          const preparedDate = `${date.slice(0, 11)}${preparedTime}${date.slice(16)}`;
          setField(preparedDate);
          if (preparedDate.length === 25) {
            dispatch(setDate({ value: preparedDate }));
          }
        },
      }}
      handleChangeValue={onChange}
      isEditInProcess
    />
  );
};
