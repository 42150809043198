/**
 * @description
 * Button for subscribe and unnsubscribe on current device.
 * With tooltip when user hover mouse on button.
 *
 */

import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as BellIcon } from '../../../../../../assets/Subscribe.svg';
import { ReactComponent as BellOffIcon } from '../../../../../../assets/Unsubscribe.svg';
import Tooltip, { useTooltip } from '../../../../../../components/tooltip';
import { useWindowSize } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { Loader } from '../loader/Loader';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    transition: 0.3s ease;
  }
  &:hover > svg > path {
    stroke: ${colorFetch('gray3')};
  }
  &.alone {
    margin-right: 12px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  width: max-content !important;
  box-sizing: border-box !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px;
  padding: 6px 10px;
`;

export interface SubscribeButtonProps {
  subscribedDevices: string[] | undefined;
  deviceData: any;
  languageStrings?: any;
  subscribeError: boolean;
  onSubscribeBtnPress: (deviceId: string, isSubscribed: boolean) => void;
  setSubscribeError: (value: boolean) => void;
  className?: string;
}

export const SubscribeButton: FC<SubscribeButtonProps> = ({
  subscribedDevices,
  deviceData,
  languageStrings,
  subscribeError,
  onSubscribeBtnPress,
  setSubscribeError,
  ...props
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipIds] = useTooltip(1);
  const [isLoading, setIsLoading] = useState(false);
  const deviceId = deviceData.id;
  const isSubscribed = subscribedDevices?.includes(deviceId);

  const windowWidth = useWindowSize()[0];

  const onHover = () => {
    setIsHovered(true);
  };
  const onBlur = () => {
    setIsHovered(false);
  };
  const onClick = () => {
    if (!isLoading) {
      setIsLoading(true);
      onSubscribeBtnPress(deviceId, !!isSubscribed);
    }
  };

  useEffect(() => {
    subscribedDevices && setIsLoading(false);
  }, [subscribedDevices]);

  useEffect(() => {
    if (subscribeError) {
      setIsLoading(false);
      setSubscribeError(false);
    }
  }, [subscribeError]);

  return (
    <IconWrapper onClick={onClick} onTouchStart={onClick} onMouseEnter={onHover} onMouseLeave={onBlur} {...props}>
      <StyledTooltip
        id={isHovered ? tooltipIds[0] : ''}
        text={isSubscribed ? languageStrings.unsubcribeButton : languageStrings.subcribeButton}
        disabled={windowWidth < 1024}
      >
        {isLoading ? <Loader color={gray2} /> : isSubscribed ? <BellOffIcon /> : <BellIcon />}
      </StyledTooltip>
    </IconWrapper>
  );
};
