import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Placeholder, PlaceholderContextProviderType } from './types';

const DEFAULT_PLACEHOLDER = { skeleton: false, loader: false } as Placeholder;

const PlaceholderContext = createContext([DEFAULT_PLACEHOLDER, null as any] as PlaceholderContextProviderType);

const usePlaceholderContextCreator = (): PlaceholderContextProviderType => {
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER);
  return [placeholder, setPlaceholder];
};

export const PlaceholderContextProvider = ({ children }: { children: ReactNode }) => {
  const provider = usePlaceholderContextCreator();
  return <PlaceholderContext.Provider value={provider}>{children}</PlaceholderContext.Provider>;
};

export const usePlaceholderContext = (): PlaceholderContextProviderType => {
  const service = useContext(PlaceholderContext);

  if (!service) {
    throw new Error('Placeholder Context is unavailable');
  }

  return service;
};

export const withPlaceholderContext = (Component: any) => {
  return function WithPlaceholderContext(props: any) {
    const service = usePlaceholderContext();

    return <Component {...props} placeholderContext={service} />;
  };
};
