import { useTheme } from '@cthings.co/styled-components';
import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { InfoButton } from '../../../infoButton/InfoButton';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  ${media.phone} {
    display: none;
  }
`;

interface RequestBlockProps {
  requested_value: string | null;
}

export const RequestBlock: FC<RequestBlockProps> = ({ requested_value }) => {
  const theme = useTheme();

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <InfoButton
        isInfoIcon
        onClick={() => {}}
        color={theme.colors.gray3}
        colorHover={theme.colors.primary}
        tooltipText={
          `${languageStrings.requestedValue} ` + requested_value + `\n ${languageStrings.valueWillBeUpdated}` || 'N/A'
        }
      />
    </Wrapper>
  );
};
