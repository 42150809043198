import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const editAddressModalMobileSlice = createSlice({
  name: 'editAddressModalMobile',
  initialState,
  reducers: reducers,
});

export const { toggleIsOpenModal } = editAddressModalMobileSlice.actions;

export default editAddressModalMobileSlice.reducer;
