import React, { useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const ItemsWrapper = styled.div`
  width: 100%;
  max-height: 260px;
  box-sizing: border-box;
  background-color: ${colorFetch('gray5')};
  border-radius: 6px;
  cursor: auto;

  ${media.phone} {
    margin: 16px 0 24px 0;
  }
`;

const ParameterListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px;
`;

type ParameterListProps = {
  heightOfList: number;
};

const ParameterList = styled.ul<ParameterListProps>`
  width: 100%;
  max-height: 210px;
  margin: 0;
  padding: 0;
  overflow-y: ${({ heightOfList }) => (heightOfList > 220 ? 'scroll' : 'auto')};
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

type ParameterItemProps = {
  isLast: boolean;
};

const ParameterItem = styled.li<ParameterItemProps>`
  gap: 10px;
  display: flex;
  margin-bottom: ${({ isLast }) => (isLast ? '0' : '12px')};
  cursor: pointer;
  &:hover > span {
    color: ${colorFetch('primary')};
  }
  & > div {
    display: flex;
    align-items: center;
  }
`;

const DeviceSecondaryNameWrapper = styled.div`
  flex: 1;
  /* width: 236px; */
  /* ${media.phone} {
    width: 124px;
  } */
`;

const UserNameWrapper = styled.div`
  width: 46px;
  ${media.phone} {
    width: 124px;
  }
`;

const DeviceNameWrapper = styled.div`
  display: flex;
  width: 210px;

  ${media.phone} {
    width: 180px;
  }
`;

export interface ValueProps {
  name?: string;
  secondary_name?: string;
  user_name?: string;
  id?: string | number;
}

export const ItemsList = ({
  parameterList,
  languageStrings,
  title,
}: {
  parameterList: ValueProps[];
  languageStrings?: any;
  title?: string;
}) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const { gray1, gray2 } = theme.colors;
  const [heightOfList, setHeightOfList] = useState(0);

  useEffect(() => {
    listRef && listRef.current && setHeightOfList(listRef.current.clientHeight);
  }, []);

  return (
    <>
      <ItemsWrapper
        theme={theme}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        title={title}
      >
        <ParameterListWrapper>
          {parameterList.length ? (
            <ParameterList ref={listRef} heightOfList={heightOfList}>
              {parameterList.map((item: any, index: number) => {
                const { name, secondary_name, user_name, email, fake_type, plansets_info } = item;

                const { device_planset }: any = plansets_info || {};

                return (
                  <ParameterItem key={index} isLast={index === parameterList.length - 1}>
                    <DeviceNameWrapper>
                      <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'} lineHeight={'20px'}>
                        {name}
                      </Text>
                    </DeviceNameWrapper>
                    {fake_type && (
                      <DeviceSecondaryNameWrapper>
                        <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'} lineHeight={'20px'}>
                          {fake_type}
                        </Text>
                      </DeviceSecondaryNameWrapper>
                    )}
                    {device_planset && device_planset?.name && (
                      <DeviceSecondaryNameWrapper>
                        <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'} lineHeight={'20px'}>
                          {device_planset.name}
                        </Text>
                      </DeviceSecondaryNameWrapper>
                    )}
                    {email && (
                      <DeviceSecondaryNameWrapper>
                        <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'} lineHeight={'20px'}>
                          {email}
                        </Text>
                      </DeviceSecondaryNameWrapper>
                    )}
                    {secondary_name && (
                      <DeviceSecondaryNameWrapper>
                        <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'} lineHeight={'20px'}>
                          {secondary_name}
                        </Text>
                      </DeviceSecondaryNameWrapper>
                    )}
                    {user_name && (
                      <UserNameWrapper>
                        <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'} lineHeight={'20px'}>
                          {user_name}
                        </Text>
                      </UserNameWrapper>
                    )}
                  </ParameterItem>
                );
              })}
            </ParameterList>
          ) : (
            <>
              <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
                {languageStrings.placeholderNoData}
              </Text>
            </>
          )}
        </ParameterListWrapper>
      </ItemsWrapper>
    </>
  );
};
