import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as Download } from '../../../../../assets/download.svg';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type LinkProps = {
  hoverColor?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  borderColor?: string;
  borderColorHover?: string;
};

const Link = styled.a<LinkProps>`
  width: 98px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('pureWhite')({ theme })};
  border: 1px solid;
  border-color: ${({ borderColor, theme }) => borderColor || colorFetch('gray3')({ theme })};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  cursor: pointer;
  transition: all 0.3s linear;
  text-decoration: none;
  &:hover {
    background-color: ${({ backgroundColorHover, theme }) => backgroundColorHover || colorFetch('gray2')({ theme })};
    border-color: ${({ borderColorHover, theme }) => borderColorHover || colorFetch('gray2')({ theme })};
    & > span {
      color: ${({ hoverColor, theme }) => hoverColor || colorFetch('pureWhite')({ theme })};
    }
  }
  ${media.tablet} {
    display: none;
  }
`;

const Wrapper = styled.a`
  display: none;
  ${media.tablet} {
    display: flex;
  }
`;

const DownloadIcon = styled(Download)`
  width: 22px;
  height: 22px;
  & > path {
    stroke: ${colorFetch('primary')};
    transition: all 0.3s linear;
  }
  &:hover > path {
    opacity: 0.5;
  }
`;

export const DownloadButton = ({
  value,
  link,
  color,
  hoverColor,
  backgroundColor,
  backgroundColorHover,
  borderColor,
  borderColorHover,
}: {
  value: string;
  link?: string;
  color?: string;
  hoverColor?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  borderColor?: string;
  borderColorHover?: string;
}) => {
  const theme = useTheme();
  const { gray2, pureWhite } = theme.colors;

  return (
    <>
      <Link
        href={link}
        rel={'noopener noreferer'}
        backgroundColor={backgroundColor}
        backgroundColorHover={backgroundColorHover}
        borderColor={borderColor}
        borderColorHover={borderColorHover}
        hoverColor={hoverColor ? hoverColor : pureWhite}
        theme={theme}
      >
        <Text type={TextType.TEXT_12_GRAY} color={color ? color : gray2} weight={'500'}>
          {value}
        </Text>
      </Link>
      <Wrapper href={link} target="_blank" rel={'noopener noreferer'}>
        <DownloadIcon theme={theme} />
      </Wrapper>
    </>
  );
};
