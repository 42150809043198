import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectDeviceInfo, setDeviceInfo } from '../../../../../../../../../app/state/insight';
import { FieldsType, isValidField } from '../../../../../../../../../utils/validation';
import { DetailsModal } from './components/detailsModal/DetailsModal';
import { TableHeader } from './components/tableHeader/TableHeader';
import { TableRow } from './components/tableRow/TableRow';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
`;

export interface ComponentProps {
  arr: any;
  handleUpdateData: () => void;
  handleRevertData: () => void;
  languageStrings?: any;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}

export const InfoContentTable: FC<ComponentProps> = ({
  arr,
  handleUpdateData,
  handleRevertData,
  languageStrings,
  accessData,
}) => {
  const theme = useTheme();
  const { primary, pureWhite, black1, secondary2 } = theme.colors;
  const [isEditInProcess, setIsEditInProcess] = useState(false);
  const [savedData, setSavedData] = useState({});
  const dispatch = useDispatch();
  const dataInfo = useSelector(selectDeviceInfo);

  const headerLabels = [
    { name: languageStrings.deviceInfoLabelProperty, isSorted: false },
    {
      name: languageStrings.insightDetailsDeviceConfigHeaderValue,
      isSorted: false,
    },
  ];

  const gridTemplate = {
    desktop: '1.7fr 2fr',
    tablet: '1.7fr 2fr',
    phone: '1fr 1fr 30px',
    phoneXS: '1.25fr 1fr 30px',
  };

  const inputsList = arr.map((item: any) => {
    const {
      property,
      value,
      list,
      placeholder,
      fieldName,
      handleChangeValue,
      type,
      disabled,
      additionalActionLable,
      additionalActionFunction,
    } = item;

    return {
      defaultValue: { name: '', id: '' },
      fieldName: fieldName,
      value,
      type,
      isError: value && !isValidField(value.name, FieldsType.SELECT),
      label: property,
      placeholder,
      selectItems: list,
      onChange: (value: any) => {
        handleChangeValue(value.name, value.id);
      },
      disabled,
      additionalActionLable,
      additionalActionFunction,
    };
  });

  const buttonsList = [
    {
      value: languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsSave,
      onClick: () => {
        handleUpdateData();
        setIsEditInProcess(false);
        document.body.style.overflow = 'visible';
      },
      color: pureWhite,
      colorHover: pureWhite,
      borderColor: primary,
      borderColorHover: secondary2,
      backgroundColor: primary,
      backgroundColorHover: secondary2,
    },
    {
      value: languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel,
      onClick: () => {
        dispatch(setDeviceInfo({ data: savedData }));
        setIsEditInProcess(false);
        document.body.style.overflow = 'visible';
      },
      color: black1,
      colorHover: pureWhite,
      borderColor: primary,
      borderColorHover: primary,
      backgroundColor: pureWhite,
      backgroundColorHover: primary,
    },
  ];

  return (
    <Wrapper>
      <TableWrapper theme={theme}>
        <TableHeader
          labels={headerLabels}
          gridTemplateDesktop={gridTemplate.desktop}
          gridTemplateTablet={gridTemplate.tablet}
          gridTemplatePhone={gridTemplate.phone}
          gridTemplatePhoneXS={gridTemplate.phoneXS}
          handleEditOnClick={() => {
            setIsEditInProcess(true);
            setSavedData(dataInfo);
            document.body.style.overflow = 'hidden';
          }}
          languageStrings={languageStrings}
          isEditable={accessData?.edit}
        />
        {arr &&
          arr.map((item: any, index: number) => {
            const {
              value,
              property,
              list,
              handleChangeValue,
              placeholder,
              fieldName,
              isEdit,
              setIsEdit,
              phisicalValue,
              additionalActionLable,
              additionalActionFunction,
              infoTooltip,
            } = item;

            return (
              <TableRow
                key={index}
                property={property}
                value={value}
                values={list}
                selectPlaceholder={placeholder}
                selectFieldName={fieldName}
                isFirst={index === 0}
                isLast={index === arr.length - 1}
                handleSaveOnClick={handleUpdateData}
                handleCloseOnClick={() => handleRevertData()}
                handleChangeValue={handleChangeValue}
                isEditable={handleChangeValue}
                gridTemplateDesktop={gridTemplate.desktop}
                gridTemplateTablet={gridTemplate.tablet}
                gridTemplatePhone={gridTemplate.phone}
                gridTemplatePhoneXS={gridTemplate.phoneXS}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                phisicalValue={phisicalValue}
                languageStrings={languageStrings}
                accessData={accessData}
                additionalActionLable={additionalActionLable}
                additionalActionFunction={additionalActionFunction}
                tooltip={infoTooltip}
              />
            );
          })}
      </TableWrapper>
      <DetailsModal
        title={languageStrings.insightDetailsDeviceConfigHeaderEdit}
        handleCloseModal={() => {
          setIsEditInProcess(false);
          document.body.style.overflow = 'visible';
        }}
        inputsList={inputsList}
        buttonsList={buttonsList}
        isOpenModal={isEditInProcess}
        languageStrings={languageStrings}
      />
    </Wrapper>
  );
};
