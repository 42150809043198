import { useTheme } from '@cthings.co/styled-components';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../../app/state/user';
import { PlaceholderType } from '../../../../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { withLoader } from '../../../../../../../../../features/placeholderComponent/loaderFunctions';
import { NoDataPlaceholder } from '../../../../../../../../../features/universalTable/components/noDataPlaceholder/NoDataPlaceholder';
import { TableHeader } from '../../../deviceInfo/components/infoContentTable/components/tableHeader/TableHeader';
import { TableRow } from '../tableRow/TableRow';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
`;

const WrapperItems = styled.div``;

const NoData = styled(NoDataPlaceholder)`
  border-radius: 0 0 8px 8px;
`;

export interface HeaderLabelsProps {
  name: string;
  isSorted: boolean;
}

export interface ComponentProps {
  arr: any;
}

const ContentTable: FC<ComponentProps> = ({ arr }) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  const headerLabels = [
    { name: languageStrings.deviceInfoLabelProperty, isSorted: false },
    {
      name: languageStrings.insightDetailsDeviceConfigHeaderValue,
      isSorted: false,
    },
  ];

  return (
    <TableWrapper theme={theme}>
      <TableHeader
        labels={headerLabels}
        gridTemplateDesktop={'1.25fr 1.5fr'}
        gridTemplateTablet={'1.25fr 1.5fr'}
        gridTemplatePhone={'1.25fr 1.5fr'}
      />
      <WrapperItems>
        {arr.length > 0 ? (
          arr.map((item: any, index: number) => {
            const { value, property, isColor, type } = item;
            return (
              <TableRow
                key={index}
                property={property}
                value={value}
                isFirst={index === 0}
                isLast={index === arr.length - 1}
                isColor={isColor}
                type={type}
              />
            );
          })
        ) : (
          <NoData />
        )}
      </WrapperItems>
    </TableWrapper>
  );
};

export const InfoContentTable = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(ContentTable);
