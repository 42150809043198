import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 26px;
`;

const StyledButton = styled(CommonButton)`
  width: 100px;
  height: 35px;
  padding: 9px 11px;
  margin: 0 0 0 26px;
  font-size: 12px;
`;

const ResolveText = styled(Text)`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${colorFetch('green2')};
` as any; // @TODO fix type

const Date = styled(Text)`
  width: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${colorFetch('gray2')};
  text-align: center;
` as any; // @TODO fix type

export const ResolveButton = ({
  date,
  openModal,
  openResolveModal,
  languageStrings,
}: {
  date: string | null;
  openModal?: () => void;
  openResolveModal?: () => void;
  languageStrings?: any;
}) => {
  const [localDate, setLocalDate] = useState(date);

  useEffect(() => {
    setLocalDate(date);
  }, [date]);

  const handleResolveOnClick = () => {
    openModal && openModal();
  };

  return localDate ? (
    <Wrapper>
      <ResolveText>{languageStrings.resolvedButton}</ResolveText>
      <Date>{localDate}</Date>
    </Wrapper>
  ) : (
    <StyledButton onClick={openResolveModal || handleResolveOnClick}>{languageStrings.resolveButton}</StyledButton>
  );
};
