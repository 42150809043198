export const HighBattery = () => {
  return (
    <svg width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 2.5L5.5 13.5L3.21795 13.5C2.82144 13.5 2.5 13.1786 2.5 12.7821L2.5 3.21795C2.5 2.82144 2.82144 2.5 3.21795 2.5L5.5 2.5Z"
        fill="#00AC5C"
      />
      <rect x="9.5" y="2.5" width="11" height="3" transform="rotate(90 9.5 2.5)" fill="#00AC5C" />
      <rect x="13.5" y="2.5" width="11" height="3" transform="rotate(90 13.5 2.5)" fill="#00AC5C" />
      <rect x="17.5" y="2.5" width="11" height="3" transform="rotate(90 17.5 2.5)" fill="#00AC5C" />
      <rect x="21.5" y="2.5" width="11" height="3" transform="rotate(90 21.5 2.5)" fill="#00AC5C" />
      <path
        d="M24.7821 2.5C25.1786 2.5 25.5 2.82144 25.5 3.21795L25.5 12.7821C25.5 13.1786 25.1786 13.5 24.7821 13.5L22.5 13.5L22.5 2.5L24.7821 2.5Z"
        fill="#00AC5C"
      />
      <rect x="27.5" y="0.5" width="15" height="27" rx="2.87179" transform="rotate(90 27.5 0.5)" stroke="#555770" />
      <rect x="29.5" y="5.5" width="5" height="2" rx="1" transform="rotate(90 29.5 5.5)" fill="#555770" />
    </svg>
  );
};
