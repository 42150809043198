import React, { useMemo } from 'react';
import styled from '@cthings.co/styled-components';
import { Dropdown } from '../../../dropdown/Dropdown';
import { CommonButton } from '@cthings.co/buttons';
import { DateRangeWithIcon } from '../../../tableInput/components/dateRangeWithIcon/DateRangeWithIcon';
import { useWindowSize, zIndexFetchFDS, zIndexFetchGeneric } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { media } from '@cthings.co/styles-utils';
import { useSelector } from 'react-redux';
import { Export } from '../exportModal/Export';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { GraphType } from '../../types';
import { selectLanguageStrings } from '../../../../app/state/user';
import moment from 'moment';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 20px 0px 20px;
  align-self: flex-start;
  ${media.desktop} {
    flex-wrap: wrap;
  }
  ${media.desktop} {
    display: none;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledDropdown = styled(Dropdown)`
  & > div {
    margin-right: 14px;
  }
  &.disabled {
    & > div {
      background-color: ${colorFetch('gray5')};
      border-color: ${colorFetch('gray5')};
      pointer-events: none;
      & > svg {
        opacity: 0;
      }
    }
  }
`;

type SettingsWrapperProps = {
  isMarginNeeded?: boolean;
};

const SettingsWrapper = styled.div<SettingsWrapperProps>`
  display: flex;
  margin: ${({ isMarginNeeded }) => (isMarginNeeded ? '16px 0 0 0' : '0')};
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

type ApplyButtonProps = {
  isApplyAvailable: boolean | undefined;
};

const ApplyButton = styled(CommonButton)<ApplyButtonProps>`
  margin-top: 16px;
  pointer-events: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
  user-select: ${({ isApplyAvailable }) => (isApplyAvailable ? 'auto' : 'none')};
  cursor: ${({ isApplyAvailable }) => (isApplyAvailable ? 'pointer' : 'auto')};
`;

type DateRangeProps = {
  isDateAvailable: boolean;
};

const DateRange = styled(DateRangeWithIcon)<DateRangeProps>`
  justify-content: flex-end;
  pointer-events: ${({ isDateAvailable }) => (isDateAvailable ? 'auto' : 'none')};
  & > div {
    flex-direction: row;
    & > div > div {
      width: 204px;
      & > div > div > div {
        & > div > svg > path {
          stroke: ${({ isDateAvailable, theme }) =>
            isDateAvailable ? colorFetch('gray1')({ theme }) : colorFetch('gray3')({ theme })};
        }
        & > span {
          color: ${({ isDateAvailable, theme }) =>
            isDateAvailable ? colorFetch('gray1')({ theme }) : colorFetch('gray3')({ theme })};
          font-weight: 400 !important;
        }
      }
    }
  }
`;

export const formatDateToString = (date: Date) => {
  return getRequiredDateFormat(date, 'YYYY-MM-DDTHH:mm:ssZ');
};

export const getStartDate = (date: Date) => {
  return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
};

export const getEndDate = (date: Date) => {
  return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
};

export const formatStringToDate = (str: string) => {
  return new Date(str);
};
interface DataType {
  data: any[];
  device_id: string;
  header: string;
  id: string;
  sensor: any;
  color: string;
  invertAxis?: boolean;
  ts_from?: string;
  ts_to?: string;
}

export const Header = ({
  applyOnClick,
  data,
  filters,
  setFilters,
  setCurrentFilters,
  graphType,
  applyingStatus,
  isApplyButtonActive,
  hideExportButton = false,
}: {
  applyOnClick: () => void;
  data: DataType;
  filters: any;
  setFilters: (filterName: string, value: { tooltip: string; id: string; key: string }) => void;
  setCurrentFilters?: any;
  graphType: GraphType;
  applyingStatus: number;
  isApplyButtonActive?: boolean;
  hideExportButton?: boolean;
}) => {
  const theme = useTheme();
  const { pureWhite, gray3, primary, secondary2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const windowWidth = useWindowSize()[0];

  const period = filters.periods.default_value;
  const interval = filters.intervals.default_value;
  const method = filters.averaging_methods.default_value;
  const periodListRedux = filters.periods.items_list;
  const intervalList = filters.intervals.items_list;
  const methodList = filters.averaging_methods.items_list;

  const prepareArr = (arr: any[]) => arr.map((item: any) => ({ name: item.tooltip, id: item.id, key: item.key }));
  const findKey = (arr: any[], id: string) => arr.find((item: any) => item.id === id).key;

  const isApplyButtonAvailable = isApplyButtonActive && !!(period && interval && method);
  const applyButtonText =
    applyingStatus === 0
      ? languageStrings.applyButton
      : applyingStatus === 1
      ? languageStrings.applyButton1
      : languageStrings.applyButton2;

  const defaultDate = formatDateToString(new Date());
  const currentTsFromDate = filters?.ts_from || defaultDate;
  const currentTsToDate = filters?.ts_to || defaultDate;

  const periodOnChangeArr = useMemo(
    () => [
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setDate(new Date().getDate() - 1))),
          ts_to: getEndDate(new Date()),
        }));
      },
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setDate(new Date().getDate() - 7))),
          ts_to: getEndDate(new Date()),
        }));
      },
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
          ts_to: getEndDate(new Date()),
        }));
      },
      () => {
        setCurrentFilters((filters: any) => ({
          ...filters,
          ts_from: getStartDate(new Date(new Date().setMonth(new Date().getMonth() - 6))),
          ts_to: getEndDate(new Date()),
        }));
      },
    ],
    [setCurrentFilters],
  );

  const periodList = periodListRedux.map((item: any, index: number, arr: any[]) =>
    index < arr.length - 1
      ? { name: item.tooltip, id: item.id, key: item.key, onClick: periodOnChangeArr[index] }
      : { name: item.tooltip, id: item.id, key: item.key },
  );

  const dropdownsList = [
    {
      value: interval,
      valueList: prepareArr(intervalList),
      fieldName: languageStrings.pointIntervalLabel,
      onChange: (value: { tooltip: string; id: string; key: string }) => {
        setFilters('intervals', value);
      },
    },
    {
      value: method,
      valueList: prepareArr(methodList),
      fieldName: languageStrings.averagingMethodLabel,
      onChange: (value: { tooltip: string; id: string; key: string }) => {
        setFilters('averaging_methods', value);
      },
    },
  ];

  const handleChangeValue = (value: Date, type: 'start' | 'end') => {
    const startDate = type === 'start' ? getStartDate(value) : filters?.ts_from;
    const endDate = type === 'end' ? getEndDate(value) : filters?.ts_to;

    setFilters('periods', { tooltip: periodList[4].name, id: periodList[4].id, key: periodList[4].key });
    setCurrentFilters((filters: any) => ({ ...filters, ts_from: startDate, ts_to: endDate }));

    if (new Date(endDate) <= new Date(startDate)) {
      const newStartDate = getStartDate(new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 1)));
      setCurrentFilters((filters: any) => {
        return { ...filters, ts_from: newStartDate, ts_to: endDate };
      });
    }
  };

  return (
    <Wrapper>
      <DropdownWrapper>
        <SettingsWrapper>
          {dropdownsList.map((item: any, index: number) => {
            const { value, valueList, fieldName, onChange } = item;
            const { id, tooltip, key } = value;
            return (
              <StyledDropdown
                key={index}
                className={applyingStatus === 1 ? 'disabled' : ''}
                value={{ name: tooltip ? tooltip : languageStrings.filtersSelectError, id /*, key*/ }}
                valuesList={valueList}
                fieldName={fieldName}
                onChange={(value: { name: string; id: string }) => {
                  const key: string = findKey(valueList, value.id);
                  onChange({ tooltip: value.name, id: value.id, key });
                }}
                placeholder={fieldName}
                languageStrings={languageStrings}
                isDisabled={!valueList[0].id}
              />
            );
          })}
        </SettingsWrapper>
        <SettingsWrapper isMarginNeeded={windowWidth < 1487}>
          <StyledDropdown
            className={applyingStatus === 1 ? 'disabled' : ''}
            value={{
              name: period.tooltip ? period.tooltip : languageStrings.filtersSelectError,
              id: period.id,
              /*key: period.key,*/
            }}
            valuesList={periodList}
            fieldName={languageStrings.dataPeriodLabel}
            onChange={(value: { name: string; id: string }) => {
              const key: string = findKey(periodList, value.id);

              setFilters('periods', { tooltip: value.name, id: value.id, key });
            }}
            placeholder={languageStrings.dataPeriodLabel}
            languageStrings={languageStrings}
            isDisabled={!periodList[0].id}
          />
          <DateRange
            value={{ startDate: new Date(currentTsFromDate), endDate: new Date(currentTsToDate) }}
            handleChangeValue={{
              setStartDate: (value: any) => handleChangeValue(value, 'start'),
              setEndDate: (value: any) => handleChangeValue(value, 'end'),
            }}
            options={{
              startOptions: {
                maxValue: new Date(
                  new Date(formatStringToDate(currentTsToDate)).setDate(
                    formatStringToDate(currentTsToDate).getDate() - 1,
                  ),
                ),
                isDisabled: applyingStatus === 1 || !filters?.ts_from,
              },
              endOptions: {
                maxValue: new Date(),
                isDisabled: applyingStatus === 1 || !filters?.ts_to,
              },
            }}
            isEditInProcess
            isDateAvailable={!!period}
          />
        </SettingsWrapper>
      </DropdownWrapper>
      <ButtonsWrapper>
        <ApplyButton
          onClick={applyOnClick}
          colors={{
            main: pureWhite,
            background: isApplyButtonAvailable ? primary : gray3,
            backgroundHover: isApplyButtonAvailable ? secondary2 : gray3,
            border: isApplyButtonAvailable ? primary : gray3,
            borderHover: isApplyButtonAvailable ? secondary2 : gray3,
          }}
          width={'180px'}
          height={'32px'}
          isApplyAvailable={isApplyButtonAvailable}
        >
          {applyButtonText}
        </ApplyButton>
        {!hideExportButton && (
          <Export
            isExportButtonActive={data.data.length > 1}
            applyingStatus={applyingStatus}
            type={graphType}
            data={data}
            filters={filters}
            ts_from={currentTsFromDate}
            ts_to={currentTsToDate}
          />
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};
