var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from '@xstyled/styled-components';
import { CloseImage } from './components/CloseImage';
const Button = styled.div `
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin: ${(props) => props.margin};
  & > svg {
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};

    stroke-width: ${(props) => (props.strokeWidth ? props.strokeWidth : 'auto')};
  }
  & > svg > path {
    stroke: ${(props) => (props.color ? props.color : props.theme.colors.gray1)};
    transition: stroke 0.3s ease;
  }
  &:hover > svg > path {
    stroke: ${(props) => (props.hoverColor ? props.hoverColor : props.theme.colors.gray3)};
  }
`;
export const CloseButton = (_a) => {
    var { width, height, strokeWidth, color, hoverColor, onClick, margin } = _a, props = __rest(_a, ["width", "height", "strokeWidth", "color", "hoverColor", "onClick", "margin"]);
    const theme = useTheme();
    return (React.createElement(Button, Object.assign({ width: width, height: height, strokeWidth: strokeWidth, color: color, hoverColor: hoverColor, onClick: onClick, margin: margin, theme: theme }, props),
        React.createElement(CloseImage, null)));
};
