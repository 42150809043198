import { RowItemType } from '../../../../../../features/universalTable/types';
import { FieldsType } from '../../../../../../utils/validation';

export const inputStructureData = [
  {
    id: 'name',
    type: RowItemType.INPUT,
    fieldName: 'name',
    languageStringsKeys: {
      label: 'fullNameOfOrganisation',
      placeholder: 'fullNameOfOrganisationPlaceholder',
    },
    isRequired: true,
    validationRule: FieldsType.TEXT,
  },
  {
    ids: ['country', 'region', 'city', 'address', 'postcode'],
    type: RowItemType.ADDRESS,
    fieldName: 'address',
    languageStringsKeys: {
      label: 'fullAddress',
      placeholder: 'billingAddressPlaceholder',
    },
    isRequired: true,
    validationRule: FieldsType.CUSTOM,
  },
] as const;
