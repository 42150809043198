import { ButtonIconType, CommonButton } from '@cthings.co/buttons';
import styled from '@cthings.co/styled-components';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from '@cthings.co/router-dom';
import { setExportModalState } from '../../app/state/devicesTable';
import { selectLanguageStrings, setUserExportModalState } from '../../app/state/user';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';
import { ExportButton } from '../../components/exportButton/ExportButton';
import { InputModal } from '../../components/inputModal/InputModal';
import { ManageTableType } from '../../components/manageLayout/ManageLayout';
import { Calculation, ChooseDevices } from '../../containers/common/analytics/flowMeasurement/components';
import { PATHS } from '../../routes/paths';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type MobileWrapperProps = {
  isMeasurmentTable: boolean;
};

const MobileWrapper = styled.div<MobileWrapperProps>`
  display: none;
  ${media.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${colorFetch('gray5')};
    /* padding: ${({ isMeasurmentTable }: any) =>
      isMeasurmentTable ? '82px 16px 12px 16px' : '82px 16px 12px 16px'}; */
    box-sizing: border-box;
    border-bottom: 1px solid;
    border-color: ${colorFetch('gray3')};
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 82px 16px 12px;
  }
  ${media.phone} {
    padding: 74px 16px 12px;
  }
`;

type NavigationWrapperProps = {
  type: ManageTableType;
};

const NavigationWrapper = styled.div<NavigationWrapperProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ type }) => (type === ManageTableType.DEVICES_TABLE ? '1fr' : '1fr 32px')};
  grid-gap: 20px;
  margin-bottom: 12px;
`;

const AddWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex: 0;
`;

const ItemsWrapper = styled.div`
  width: max-content;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 20px;
  align-items: center;

  ${media.phone} {
    width: 100%;
    /* grid-template-columns: 1fr 1fr 1fr; */
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const NavItem = styled(NavLink)`
  width: max-content;
  color: ${colorFetch('gray2')};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  &.active {
    color: ${colorFetch('black')};
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
`;

const Button = styled(CommonButton)`
  & > div > svg {
    margin: 0;
  }
`;

const Input = styled(InputModal)`
  & > div > input {
    border: none;
  }
  flex: 1;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const ManageTitle = ({
  type,
  handleAddOnClick,
  searchValue,
  setSearchValue,
  accessData,
  ...props
}: {
  type: ManageTableType;
  handleAddOnClick: () => void;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  accessData?: boolean;
  className?: string;
}) => {
  const dispatch = useDispatch();
  const languageStrings = useSelector(selectLanguageStrings);
  const navItems = [
    {
      name: languageStrings.navigationClients,
      to: PATHS.MANAGE_ORGANISATIONS,
    },
    {
      name: languageStrings.navigationDevices,
      to: PATHS.MANAGE_DEVICES,
    },
    {
      name: languageStrings.navigationUsers,
      to: PATHS.MANAGE_USERS,
    },
  ];
  const itemsWrapperRef = useRef<any>();
  //const location = useLocation();
  //const currentRoute = location.pathname;
  const isDevicesTable = type === ManageTableType.DEVICES_TABLE;
  const isUsersTable = type === ManageTableType.USERS_TABLE;
  const isOrganisationsTable = type === ManageTableType.CLIENTS_TABLE;
  const isMeasurmentTable = type === ManageTableType.MEASUREMENT_TABLE;
  const route = window.location.href;
  const [isOpenChooseDevicesModal, setIsOpenChooseDevicesModal] = useState(false);
  const [isOpenCalculationModal, setIsOpenCalculationModal] = useState(false);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue && setSearchValue(e.currentTarget.value);
  };

  const openExportModal = (isUserDomain: boolean) =>
    isUserDomain ? dispatch(setUserExportModalState(true)) : dispatch(setExportModalState(true));

  const handleChooseDevicesSave = (data: any) => {
    setIsOpenChooseDevicesModal(false);
    setIsOpenCalculationModal(true);
  };

  const handleCalculationSave = (data: any) => {
    setIsOpenCalculationModal(false);
  };

  return (
    <MobileWrapper isMeasurmentTable={isMeasurmentTable} {...props}>
      <NavigationWrapper type={type}>
        <ItemsWrapper ref={itemsWrapperRef}>
          {navItems.map((item: any, index: number) => {
            const { name, to } = item;
            const currentSection = `${to.split('/')[2]}`;
            const isActive = route.includes(currentSection);
            return (
              <NavItem key={index} to={to} className={isActive ? 'active' : ''}>
                {name}
              </NavItem>
            );
          })}
        </ItemsWrapper>
      </NavigationWrapper>

      <ActionWrapper>
        {searchValue !== undefined ? (
          <Input
            inputHeight={'32px'}
            margin={'0'}
            value={searchValue}
            onChange={onInputChange}
            placeholder={languageStrings.whatAreYouLookingFor}
            image={<SearchIcon />}
          />
        ) : (
          <div style={{ height: '32px' }} />
        )}
        {(isDevicesTable || isUsersTable) /*|| isOrganisationsTable*/ && (
          <ExportButton onClick={() => openExportModal(isUsersTable)} languageStrings={languageStrings} />
        )}
        {!isDevicesTable && accessData && (
          <AddWrapper>
            <Button
              width="32px"
              iconType={ButtonIconType.ADD}
              onClick={isMeasurmentTable ? () => setIsOpenChooseDevicesModal(true) : handleAddOnClick}
            />
          </AddWrapper>
        )}
      </ActionWrapper>
      <ChooseDevices
        values={[{ name: 'test', id: 'test' }]}
        onCancel={() => setIsOpenChooseDevicesModal(false)}
        onNext={handleChooseDevicesSave}
        isOpenModal={isOpenChooseDevicesModal}
      />
      <Calculation
        values={[
          { name: 'test', id: 'test' },
          { name: 'test2', id: 'test2' },
          { name: 'test3', id: 'test3' },
          { name: 'test4', id: 'test4' },
        ]}
        onCancel={() => setIsOpenCalculationModal(false)}
        onSave={handleCalculationSave}
        isOpenModal={isOpenCalculationModal}
      />
    </MobileWrapper>
  );
};
