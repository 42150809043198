import { RootState } from '../../../app/store';
import { LanguageStrings } from '../../../languages/types';
import { lang } from '../../../languages/lang';

export const selectUser = (state: RootState) => {
  return state.user;
};
export const selectUserType = (state: RootState) => {
  return state.user.userType;
};

export const selectUserSettingsCompleted = (state: RootState) => {
  return state.user.userSettingsCompleted;
};

export const selectAccess = (state: RootState) => {
  return state.user.access;
};

export const selectLanguageStrings = (state: RootState) => {
  return lang[state.user.language.id] as LanguageStrings;
};

export const selectLanguages = (state: RootState) => {
  return state.user.languages;
};

export const selectLanguage = (state: RootState) => {
  return state.user.language;
};

export const selectСlientAccessManagement = (state: RootState) => {
  return { ...state.user.access.management.client };
};

export const selectUserAccessManagement = (state: RootState) => {
  return { ...state.user.access.management.user };
};

export const selectIsAccess = (state: RootState) => {
  return state.user.access.isAccess;
};

export const selectUserExportModalIsOpen = (state: RootState) => {
  return state.user.isExportModalOpen;
};
