import React from 'react';

import { useUserAccess } from '../../api/user';
import { Wrapper } from '../elements/wrappers';
import { renderRoutes } from '../../utils/renderRoutes';
import withAuth from '../../features/authentication/withAuth';

const Errors = (props: any) => {
  useUserAccess();
  return <Wrapper>{renderRoutes(props.routes, props.parentPathLength)}</Wrapper>;
};

export default withAuth(Errors);
