import { CommonButton } from '@cthings.co/buttons';
import { CheckBox } from '@bit/first-scope.controls';
import { useTheme } from '@cthings.co/styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@cthings.co/router-dom';
import styled from '@cthings.co/styled-components';
import { handleTypedInFromKeyboard, uploadImage } from '../../../../api/provisioning';
import { selectProvisioningData, setNewOrgProvisioningData } from '../../../../app/state/app';
import { selectDeviceInfo, setDeviceInfoAddress } from '../../../../app/state/insight';
import { selectLanguageStrings } from '../../../../app/state/user';
import { ReactComponent as Info } from '../../../../assets/Info.svg';
import { ReactComponent as NidCthings } from '../../../../assets/nidCthings.svg';
import { ReactComponent as SUPLogo } from '../../../../assets/SUPLogo.svg';
import { AddOrganisationButton } from '../../../../components/addOrganisationButton/AddOrganisationButton';
import { AddingAnImage } from '../../../../components/addingAnImage/addingAnImage';
import { InputModal as Input } from '../../../../components/inputModal/InputModal';
import { SelectAddress } from '../../../../components/selectAddress/SelectAddress';
import Tooltip, { TooltipPlace, useTooltip } from '../../../../components/tooltip/Tooltip';
import { LsValueType } from '../../../../enums/LsValueType';
import withAuth from '../../../../features/authentication/withAuth';
import { useNotifierFunctions } from '../../../../features/notifier2';
import { TableCustomComponentState } from '../../../../features/universalTable/types';
import { View } from '../../../../routes';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useCustomHistory } from '../../../../utils/react-router-dom-abstraction';
import ss from '../../../../utils/ss';
import { AddOrganisationModal } from './components/addOrganisationModal/AddOrganisationModal';
import { OrganisationSelect } from './components/customerSelect/CustomerSelect';
import { Depth } from './components/depth/Depth';
import { MaxDepthModal } from './components/depth/MaxDepthModal';
import { isNid } from '../../../../consts';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: ${colorFetch('gray5')};

  ${media.phone} {
    margin-top: 42px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

type WrapperTitleProps = {
  isNid?: boolean;
};

const WrapperTitle = styled.div<WrapperTitleProps>`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: ${({ isNid }) => (isNid ? '62px 0 30px 0' : '40px 0 30px 0')};
`;

const WrapperDescription = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;
  flex-direction: column;

  ${media.phone} {
    width: calc(100% - 32px);
    margin: 5px 16px 0 16px;
  }
`;

const NextButton = styled(CommonButton)`
  width: 100%;
  height: 40px;
  margin: 40px 0 0 0;
  & > svg,
  & > div {
    margin-right: 5px;
    & > path {
      transition: 0.3s ease;
    }
  }
  ${media.phone} {
    width: 100%;
    margin: 48px 0 40px 0;
  }
`;

const WrapperSelectAddress = styled(SelectAddress)`
  margin-bottom: 12px;
` as any; // @TODO fix type

const InputCheckBox = styled(Input)`
  margin-top: 12px;
  & > div:nth-child(2) > textarea {
    border-color: ${colorFetch('gray3')};
    background-color: ${colorFetch('white')} !important;
    padding: 8px 36px 8px 40px;
  }
` as any; // @TODO fix type

const WrapperCheckBox = styled.div`
  padding: 5px;
  position: relative;
`;

const initialProvisioningData = {
  client_id: '',
  is_deployed: false,
};

const ProvisioningDevice = () => {
  const theme = useTheme();
  const { secondary2, primary, pureWhite, secondary3 } = theme.colors;
  const dispatch = useDispatch();
  const location = useLocation();
  const { addNotification } = useNotifierFunctions();
  const [tooltipIds] = useTooltip();
  const history = useCustomHistory();
  const languageStrings = useSelector(selectLanguageStrings);

  const secretFromParams = location?.state?.state?.secret;
  const transportIdFromParams = location?.state?.state?.transportId;
  const isHasAccountFromParams = location?.state?.state?.hasAccount;
  const depthParams = location?.state?.state?.depth;
  const depthLimitsParams = location?.state?.state?.limits;

  const lsSecret = ss.get(LsValueType.secret);
  const lsTransportId = ss.get(LsValueType.transport_id);
  const newOrganisationData = ss.get(LsValueType.provisioning_data_new_organisation);

  const [isHasAccount, setIsHasAccount] = useState<any>(isHasAccountFromParams);
  const address = useSelector(selectDeviceInfo).address;
  const provisionindData = useSelector(selectProvisioningData);
  const { secret, transport_id } = provisionindData;
  const transpId = transportIdFromParams || transport_id || lsTransportId;
  const [transportId, setTransportId] = useState(transpId || lsTransportId);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState(false);
  const localSecret = secretFromParams || secret || lsSecret;

  const [imagesList, setImagesList] = useState<string[]>([]);
  const [provisioningData, setProvisioningData] = useState(initialProvisioningData);
  const [depth, setDepth] = useState<number>(depthParams);
  const [depthLimits, setDepthLimits] = useState(depthLimitsParams);

  const isCompletedField = (provisioningData?.client_id && transportId) || (newOrganisationData && transportId);

  const handleNextButton = () => {
    const data = isHasAccount
      ? { ...provisioningData }
      : { ...provisioningData, client_id: null, client: { ...newOrganisationData } };

    uploadImage(imagesList, addNotification, languageStrings, localSecret, transportId);

    const to = View.PROVISIONING_SUBSCRIPTION;
    to &&
      history.push(to, {
        state: {
          localSecret,
          transportId,
          data,
          languageStrings,
          device: transportId,
          isUserHasAccount: isHasAccount,
          depth,
        },
      });
  };

  const handleSetAddressValue = (value: any) => {
    const alt = value?.geotag?.alt ? `${value?.geotag?.alt}` : '';
    const lat = value?.geotag?.lat ? `${value?.geotag?.lat}` : '';
    const lng = value?.geotag?.lng ? `${value?.geotag?.lng}` : '';

    setProvisioningData((prev: any) => ({
      ...prev,
      address: {
        ...value,
        geotag: { ...value?.geotag, lat, lng, alt },
      },
    }));
    dispatch(setDeviceInfoAddress({ value }));
  };

  const handleInputId = (e: any) => setTransportId(e.currentTarget.value);

  const handleCheckBox = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setProvisioningData((prev: any) => ({ ...prev, is_deployed: !prev?.is_deployed }));
  };

  const handleSetNewOrganisation = (items: any) => {
    dispatch(setNewOrgProvisioningData(items));
    setIsOpenModal(false);
  };

  const handleUserHasAccount = (_secret: any, _transportId: any, hasAccount: boolean, depth: number, limits: any) => {
    setDepthLimits(limits);
    setDepth(depth);
    setIsHasAccount(hasAccount);
  };

  useEffect(() => {
    if (localSecret) {
      handleTypedInFromKeyboard(
        localSecret,
        () => {},
        languageStrings,
        handleUserHasAccount,
        () => {},
      );
    }
  }, [localSecret]);

  useEffect(() => {
    if (localSecret) {
      ss.set(LsValueType.secret, localSecret);
    }
  }, [localSecret]);

  useEffect(() => {
    if (transportId) {
      ss.set(LsValueType.transport_id, transportId);
    }
  }, [transportId]);

  const [maxDepthModal, setMaxDepthModal] = useState(false);

  return (
    <Wrapper>
      <Content>
        {isNid ? <NidCthings /> : <SUPLogo />}
        <WrapperTitle isNid={isNid}>{languageStrings.provisioningAddingDevice}</WrapperTitle>
        <WrapperDescription>
          <Input
            width={'100%'}
            name={'ID'}
            value={transportId || ''}
            onChange={handleInputId}
            disabled={false}
            placeholder={'ID'}
          />
          {isHasAccount ? (
            <OrganisationSelect localSecret={localSecret} setProvisioningData={setProvisioningData} />
          ) : (
            <AddOrganisationButton value={newOrganisationData?.name} onClick={() => setIsOpenModal(true)} />
          )}
          <WrapperSelectAddress
            state={TableCustomComponentState.EDIT}
            address={address}
            titleOfPopup={languageStrings.provisioningAddress}
            setValue={handleSetAddressValue}
            saveToggle={false}
            setExternalState={(isOpen: boolean) => {
              setIsOpenAddressPopup(isOpen);
            }}
            externalState={isOpenAddressPopup}
            label={languageStrings.provisioningDeviceInstalmentLocationOptional}
            isDescriptionModal={true}
            isInstructions={true}
            isFromUniT={true}
            isShowSelect={true}
            isWhiteBackground={true}
            className={'position'}
          />
          <AddingAnImage imagesList={imagesList} setImagesList={setImagesList} languageStrings={languageStrings} />
          <InputCheckBox
            name={''}
            rows={2}
            value={languageStrings.provisioningDeployNow}
            disabled={true}
            placeholder={''}
            onClick={handleCheckBox}
            image={
              <WrapperCheckBox onClick={handleCheckBox}>
                <CheckBox label="" handleToggle={() => {}} toggle={provisioningData?.is_deployed} />
              </WrapperCheckBox>
            }
            additionalImage={
              <Tooltip
                id={tooltipIds[0]}
                text={languageStrings.provisioningInOrderForDevice}
                place={TooltipPlace.TOP}
                width={'275px'}
              >
                <Info />
              </Tooltip>
            }
          />
          {depthLimits && provisioningData?.is_deployed && (
            <Depth
              value={depth ?? 0}
              maxDepth={depthLimits.max}
              minDepth={depthLimits.min}
              setValue={setDepth}
              onClick={() => setMaxDepthModal(true)}
            />
          )}
          <MaxDepthModal isOpen={maxDepthModal} handleClose={() => setMaxDepthModal(false)} />
          <NextButton
            onClick={handleNextButton}
            colors={{
              main: pureWhite,
              mainHover: pureWhite,
              background: !isCompletedField ? secondary3 : primary,
              backgroundHover: !isCompletedField ? secondary3 : secondary2,
              border: !isCompletedField ? secondary3 : primary,
              borderHover: !isCompletedField ? secondary3 : primary,
            }}
            disabled={!isCompletedField}
          >
            {languageStrings.additionalDeviceNextButton}
          </NextButton>
        </WrapperDescription>
        <AddOrganisationModal
          isOpen={isOpenModal}
          onCancelClick={() => setIsOpenModal(false)}
          setValues={handleSetNewOrganisation}
          values={newOrganisationData}
          isData={!newOrganisationData?.name}
        />
      </Content>
    </Wrapper>
  );
};

export default withAuth(ProvisioningDevice);
