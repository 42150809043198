import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTableFunctions, withTableContextProvider } from '../../../features/universalTable/context';
import {
  generateReport,
  useGetCustomers,
  useGetLocations,
  useGetRecipients,
  useReportsList,
} from '../../../api/reports';
import { selectAccess, selectIsAccess, selectLanguage, selectLanguageStrings } from '../../../app/state/user';
import {
  clearCustomer,
  clearLocation,
  clearPeriod,
  clearRecipients,
  SelectCustomer,
  SelectLocation,
  SelectRecipients,
  SelectSelectedParameters,
  toggleIsError,
  toggleIsValidatingStarted,
} from '../../../app/state/generateReportModal';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { ReactComponent as AddFileIcon } from '../../../assets/add_file_icon.svg';
import { InlineActionType, RowItemType, TableCustomComponentState } from '../../../features/universalTable/types';
import { DownloadButton } from './components/downloadButton/DownloadButton';
import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { GenerateReportModal, TypeOfParameter } from './components/generateReportModal/GenerateReportModal';
import { useMediaType } from '@cthings.co/styles-utils';
import { API_URL } from '../../../consts';
import { View } from '../../../routes/routeInterfaces';
import ss from '../../../utils/ss';
import { LsValueType } from '../../../enums/LsValueType';
import { useNotifierFunctions } from '../../../features/notifier2';
import { ReactComponent as ReportsIcon } from '../../../assets/reports_icon.svg';
import { PATHS } from '../../../routes/paths';
import { useHistory } from '../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../utils/useQueryParams';
import { useParams } from '@cthings.co/router-dom';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const ExtraWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &.placeholder {
    height: calc(100% - 121px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  ${media.desktop} {
    max-width: 1024px;
  }
  ${media.tablet} {
    margin-top: 44px;
  }
`;

const TitleWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  ${media.tablet} {
    position: fixed;
    z-index: 1;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 0;
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: ${colorFetch('gray3')};
      left: 50%;
      bottom: -12px;
      transform: translateX(-50%);
    }
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      background-color: ${colorFetch('gray5')};
      left: 0;
      right: 0;
      bottom: -12px;
      top: -32px;
    }
  }
  ${media.phone} {
    &:before {
      top: -24px;
    }
  }
`;

const StyledTitle = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;

const StyledCommonButton = styled(CommonButton)`
  & > svg {
    margin-right: 6px;
  }
  ${media.tablet} {
    width: 32px;
    height: 32px;
    padding: 0 5px;
    & > span {
      display: none;
    }
    & > svg {
      margin: 0;
    }
  }
`;

const ButtonText = styled(Text)`
  ${media.tablet} {
    display: none;
  }
`;

type TextWrapperProps = {
  state?: TableCustomComponentState;
};

const TextWrapper = styled.div<TextWrapperProps>`
  height: 40px;
  display: flex;
  align-items: center;
  padding: ${({ state }) => (state === TableCustomComponentState.VIEW_MODAL ? '8px 12px' : '0')};
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledText = styled(Text)`
  width: 150px;
`;

type LinkProps = {
  color: string;
  backgroundColor: string;
  borderRadius: string;
  backgroundColorHover: string;
};

const Link = styled.a<LinkProps>`
  display: flex;
  text-decoration: none;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 7px 10px;
  max-width: 75px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${({ backgroundColorHover }) => backgroundColorHover};
  }
  & > span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 110px;
  ${media.phone} {
    margin: 0;
  }
`;

const PlacegolderIcon = styled(ReportsIcon)`
  margin-right: 14px;
  & > path {
    stroke: ${colorFetch('gray3')};
  }
`;

const ReportsPlain = () => {
  const theme = useTheme();
  const { gray1, primary, secondary2, gray2, pureWhite } = theme.colors;
  const { primary: primaryBorderRadius } = theme.borderRadius;

  const { resetTable } = useTableFunctions();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoadingInProcess, setIsLoadingInProcess] = useState(false);
  const [reportTrigger, setReportTrigger] = useState(false);

  const dispatch = useDispatch();
  const languageStrings = useSelector(selectLanguageStrings);
  const mediaType = useMediaType();

  const keyword = 'ReportsTable';
  const offsetKey = 'offsetReports';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pathParams = useParams();
  const history = useHistory();
  const isAccess = useSelector(selectIsAccess);
  //TODO @IGOR when sectionaccess will done from backend need to change it to reports besides devices
  const accessData = useSelector(selectAccess).management.devices;
  const isReportsAccessManagementView = accessData.view;

  useGetCustomers();
  useGetLocations();
  useGetRecipients();

  const location = useSelector(SelectLocation);
  const customer = useSelector(SelectCustomer);
  const recipient = useSelector(SelectRecipients);
  const reportData = useSelector(SelectSelectedParameters);

  const isNotEmptyParameters =
    location.selected_parameters.length && customer.selected_parameters.length && recipient.selected_parameters.length;

  const language = useSelector(selectLanguage);
  const { addNotification } = useNotifierFunctions();

  useReportsList(setIsEmpty);

  const clearModalData = () => {
    dispatch(
      clearLocation({
        locations: location.parameters && location.parameters.map((item: any) => ({ ...item, isSelected: false })),
      }),
    );
    dispatch(
      clearCustomer({
        customers: customer.parameters && customer.parameters.map((item: any) => ({ ...item, isSelected: false })),
      }),
    );
    dispatch(
      clearRecipients({
        recipients: recipient.parameters && recipient.parameters.map((item: any) => ({ ...item, isSelected: false })),
      }),
    );
    dispatch(clearPeriod());
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    dispatch(toggleIsValidatingStarted({ value: false }));
    clearModalData();
  };

  const handleGenerateReport = () => {
    dispatch(toggleIsValidatingStarted({ value: true }));
    if (isNotEmptyParameters) {
      setIsLoadingInProcess(true);
      generateReport(reportData, addNotification, languageStrings, handleResponse);
      setIsOpenModal(false);
      clearModalData();
      dispatch(toggleIsValidatingStarted({ value: false }));
    }
  };

  const handleResponse = (status: boolean) => {
    setIsLoadingInProcess(false);
    status && setReportTrigger((val) => !val);
  };

  const handleToggleIsError = (selectedItems: any[], type: TypeOfParameter) => {
    selectedItems.length === 0
      ? dispatch(toggleIsError({ type, value: true }))
      : dispatch(toggleIsError({ type, value: false }));
  };

  useEffect(() => {
    handleToggleIsError(customer.selected_parameters, TypeOfParameter.CUSTOMER);
    handleToggleIsError(recipient.selected_parameters, TypeOfParameter.RECIPIENTS);
    handleToggleIsError(location.selected_parameters, TypeOfParameter.LOCATION);
  }, [customer, recipient, location]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useEffect(() => {
    if (isAccess && !isReportsAccessManagementView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isReportsAccessManagementView]);

  return (
    <ExtraWrapper>
      <TitleWrapper>
        <StyledTitle type={TextType.TITLE_H1}>{languageStrings.navigationReports}</StyledTitle>
        {
          <StyledCommonButton
            onClick={openModal}
            width={language.shortName === 'EN' ? '180px' : '190px'}
            isLoadingInProcess={isLoadingInProcess && !isOpenModal}
          >
            {!isLoadingInProcess && <AddFileIcon />}
            <ButtonText type={TextType.TEXT_14_WHITE}>{languageStrings.generateReports}</ButtonText>
          </StyledCommonButton>
        }
      </TitleWrapper>
      <Wrapper className={isEmpty ? 'placeholder' : ''}>
        {!isEmpty ? (
          <UniversalTable
            placeholderProps={{
              keyword,
              placeholderOptions: { borderRadiusTable: !mediaType.tablet },
            }}
            mainApiUrl={`${API_URL}/v2/reports/`}
            keyword={keyword}
            offset={+offset}
            offsetKey={offsetKey}
            sortingData={sortingData}
            sortingKeys={sortingKeys}
            pathParams={pathParams}
            queryParams={queryParams}
            tablePath={View.USER_REPORTS}
            allowSelect
            selectComponent={(value: any) => {
              const ids = value.map((item: any) => item.file_id);
              let href = `${API_URL}/v2/reports/multiplereportsdownload/?`;
              ids.forEach((item: any, index: number) => {
                if (index !== ids.length - 1) {
                  href += `file_ids=${item}&`;
                } else {
                  href += `file_ids=${item}&access_token=${ss.get(LsValueType.token)}`;
                }
              });
              return (
                <Link
                  href={href}
                  rel={'noopener noreferer'}
                  backgroundColor={primary}
                  backgroundColorHover={secondary2}
                  color={pureWhite}
                  borderRadius={primaryBorderRadius}
                >
                  <Text type={TextType.TEXT_14_WHITE}>{languageStrings.download}</Text>
                </Link>
              );
            }}
            inlineActionSet={[
              {
                type: InlineActionType.CUSTOM,
                component: <DownloadButton link={'#'} value={languageStrings.download} />,
                callback: (data: any) => {
                  window.open(
                    `${API_URL}/v2/reports/multiplereportsdownload/?file_ids=${data.file_id}&access_token=${ss.get(
                      LsValueType.token,
                    )}&lang=${language.id}`,
                    '_blank',
                  );
                },
              },
            ]}
            columnStructure={[
              {
                fieldName: 'dog',
                placeholder: languageStrings.tableLableDatePlaceholder,
                label: languageStrings.problemsDate,
                defaultValue: '',
                type: RowItemType.CUSTOM,
                component: (value: any, state) => {
                  return (
                    <TextWrapper state={state}>
                      <Text type={TextType.TEXT_14_GRAY} color={theme.colors.gray1} weight={'400'}>
                        {getRequiredDateFormat(value, 'DD.MM.Y') || 'N/A'}
                      </Text>
                    </TextWrapper>
                  );
                },
              },
              // {
              //   fieldName: 'locations',
              //   placeholder: '',
              //   label: languageStrings.labelReportsTableLocation || 'Location',
              //   defaultValue: { city: '', line1: '' },
              //   type: RowItemType.CUSTOM,
              //   component: (value: any, state) => {
              //     return (
              //       <TextWrapper state={state}>
              //         <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
              //           {value
              //             .map((item: any, index: number) => {
              //               const isLast = index === value.length - 1;
              //               const address = item.city ? `${item.city}, ${item.line1}` : item.city;
              //               const string = isLast ? `${address}` : `${address}; `;
              //               return string;
              //             })
              //             .join('') || 'N/A'}
              //         </StyledText>
              //       </TextWrapper>
              //     );
              //   },
              // },
              {
                fieldName: 'customers',
                placeholder: '',
                label: languageStrings.manageClientsTableHeaderItemsClient,
                defaultValue: [],
                width: '1fr',
                type: RowItemType.CUSTOM,
                component: (value: any, state) => {
                  return (
                    <TextWrapper state={state}>
                      <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                        {value.length > 0
                          ? value.map((item: any, index: number) => {
                              const isLast = index === value.length - 1;
                              const string = isLast ? `${item.name}` : `${item.name}; `;
                              return string;
                            })
                          : 'N/A'}
                      </StyledText>
                    </TextWrapper>
                  );
                },
              },
              {
                fieldName: 'period',
                placeholder: '',
                label: languageStrings.generateReportModalPeriod,
                defaultValue: { ts_from: '', ts_to: '' },
                type: RowItemType.CUSTOM,
                component: (value: any, state) => (
                  <TextWrapper state={state}>
                    <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                      {`${getRequiredDateFormat(value.ts_from, 'D MMM yyyy')} - ${getRequiredDateFormat(
                        value.ts_to,
                        'D MMM yyyy',
                      )}` || 'N/A'}
                    </Text>
                  </TextWrapper>
                ),
              },
            ]}
            apiTriggers={[reportTrigger]}
          />
        ) : (
          <PlaceholderWrapper>
            <PlacegolderIcon />
            <Text type={TextType.TEXT_20_MAP} weight={'400'} color={gray2}>
              {languageStrings.noReportsPlaceholder}
            </Text>
          </PlaceholderWrapper>
        )}
        <GenerateReportModal
          location={location}
          customer={customer}
          recipients={recipient}
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          generateOnClick={handleGenerateReport}
          languageStrings={languageStrings}
        />
      </Wrapper>
    </ExtraWrapper>
  );
};

export const Reports = withTableContextProvider(ReportsPlain);
