import { useTheme } from '@cthings.co/styled-components';
import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '../../../../../components/commonButton/CommonButton';
import { StyledSecondaryModal, StyledSecondaryModalContainer } from '../styled';
import { DeviceSelectionModeValue } from '../types';

const StyledButton = styled(CommonButton)`
  width: 342px;
  height: 40px;
  font-size: 14px;

  margin: 0 0 16px 0;
`;

const BackButton = styled(StyledButton)`
  margin: 8px 0 0 0;
`;

export const DropdownModal = ({
  value,
  values,
  devices,
  languageStrings,
  onChange,
  setIsOpenModal,
}: {
  value: DeviceSelectionModeValue;
  values: DeviceSelectionModeValue[];
  devices?: any;
  languageStrings: any;
  onChange: (props: any) => void;
  setIsOpenModal: (props: any) => void;
}) => {
  const theme = useTheme();
  const { primary, gray3, pureWhite, black1, gray1 } = theme.colors;
  const [openModal, setModalOpen] = useState(true);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChange = (val: any) => {
    onChange(val);
    setModalOpen(false);
  };

  useEffect(() => {
    setIsOpenModal(openModal);
  }, [openModal]);

  const backText = languageStrings.insightDetailsBack;

  return (
    <StyledSecondaryModal isOpenModal={openModal}>
      <StyledSecondaryModalContainer>
        {values.map((currentVal) => (
          <StyledButton
            borderColor={value.id === currentVal.id ? primary : gray3}
            backgroundColorHover={pureWhite}
            backgroundColor={pureWhite}
            color={value.id === currentVal.id ? primary : gray1}
            onClick={() => handleChange(currentVal)}
          >
            {currentVal.name}
          </StyledButton>
        ))}

        <BackButton backgroundColor={pureWhite} color={black1} onClick={handleModalClose}>
          {backText}
        </BackButton>
      </StyledSecondaryModalContainer>
    </StyledSecondaryModal>
  );
};
