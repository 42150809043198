import React, { FC, useState, useCallback } from 'react';
import { TextType } from '@bit/first-scope.text';
import { DeleteModal } from './components/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useNotifierFunctions } from '../../../notifier2';
import { checkFieldValidity } from '../../../../utils/validation';
import {
  selectUserInEdit,
  selectParentOrganisation,
  editUser,
  saveEditUser,
  createUser,
  clearEditUser,
  selectUserModificationMode,
  deleteUser,
} from '../../../../app/state/organisations';
import { InputType } from './types';
import {
  TitleRow,
  InputsContainer,
  ButtonsRow,
  StyledInput,
  StyledSelect,
  ButtonSection,
  DeleteButton,
  CancelButton,
  SaveButton,
  CloseIcon,
  StyledText,
  StyledCustomModal,
} from './styled';
import { getInputStructure } from './utils';
import { useUserRole } from '../../../../utils/userRole';
import { UserModificationMode } from '../../../../app/state/organisations';

interface AddUserProps {
  onClose: () => void;
}

export const AddUser: FC<AddUserProps> = ({ onClose }) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [startedValidation, setStartedValidation] = useState(false);

  const userModificationMode = useSelector(selectUserModificationMode);
  const isInEditMode = userModificationMode === UserModificationMode.EDIT;

  const dispatch = useDispatch();

  const languageStrings = useSelector(selectLanguageStrings);
  const userInEdit = useSelector(selectUserInEdit);
  const parentOrganisation = useSelector(selectParentOrganisation);
  const { name, email, role, id } = userInEdit;

  const [{ roleList }, { getUserByRole }] = useUserRole();
  const roleValue = getUserByRole(role);

  const inputValues = {
    name,
    email,
    company: parentOrganisation.name,
    role: roleValue,
  };

  const inputStructure = getInputStructure(languageStrings);

  const isFormValid = inputStructure.every(({ isDisabled, fieldName, validationRule }) =>
    checkFieldValidity({ value: inputValues[fieldName], validationRule, isDisabled }),
  );

  const { addNotification } = useNotifierFunctions();

  const handleBack = () => {
    onClose();
    dispatch(clearEditUser());
  };

  const handleChange = (value: string | { id: string; name: string }, fieldName: string) => {
    dispatch(editUser({ [fieldName]: value }));
  };

  const handleDelete = () => {
    dispatch(deleteUser(id));
    onClose();
  };

  const handleSaveUser = useCallback(() => {
    setStartedValidation(true);
    if (isFormValid) {
      onClose();
      if (isInEditMode) {
        dispatch(saveEditUser());
      } else {
        dispatch(createUser());
      }
    } else {
      addNotification({
        message: 'Please, correctly fill in all the fields',
        type: 'error',
      });
    }
  }, [isFormValid, dispatch, addNotification, onClose, isInEditMode]);

  const cancelText = languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel;
  const saveText = languageStrings.saveButton;
  const deleteText = languageStrings.manageClientsTableHeaderDeleteButton;
  const editText = languageStrings.insightDetailsDeviceConfigHeaderEdit;
  const addText = languageStrings.modalTitleAdd;

  return (
    <>
      <StyledCustomModal isOpen={true}>
        <TitleRow>
          <StyledText type={TextType.TEXT_14_BLACK}>{isInEditMode ? editText : addText}</StyledText>
          <CloseIcon onClick={handleBack} />
        </TitleRow>
        <InputsContainer>
          {inputStructure.map((input) => {
            const { fieldName, fieldType, label, placeholder, validationRule, isDisabled } = input;
            const validationOptions = {
              isError: !checkFieldValidity({ value: inputValues[fieldName], validationRule, isDisabled }),
              startedValidation,
            };

            return fieldType === InputType.INPUT ? (
              <StyledInput
                value={inputValues[fieldName] as string}
                onChange={(e) => handleChange(e.currentTarget.value, fieldName)}
                placeholder={placeholder}
                name={label}
                disabled={!!isDisabled}
                {...validationOptions}
              />
            ) : (
              <StyledSelect
                value={inputValues[fieldName] as typeof roleValue}
                values={roleList}
                onChange={(value) => handleChange(value.id, fieldName)}
                label={label}
                placeholder={placeholder}
                languageStrings={languageStrings}
              />
            );
          })}
        </InputsContainer>
        <ButtonsRow justifyContent={isInEditMode ? 'space-between' : 'flex-end'}>
          {isInEditMode && <DeleteButton onClick={() => setIsOpenDeleteModal(true)}>{deleteText}</DeleteButton>}
          <ButtonSection>
            <CancelButton onClick={handleBack}>{cancelText}</CancelButton>
            <SaveButton onClick={handleSaveUser}>{saveText}</SaveButton>
          </ButtonSection>
        </ButtonsRow>
      </StyledCustomModal>

      <DeleteModal
        isOpenModal={isOpenDeleteModal}
        setModalClose={() => setIsOpenDeleteModal(false)}
        onDelete={handleDelete}
      />
    </>
  );
};
