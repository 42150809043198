import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserType } from '../app/state/user';
import { UserType } from '../enums/UserType';
import withAuth from '../features/authentication/withAuth';
import { LoadingScreen } from '../features/loadingScreen/LoadingScreen';
import { useHistory } from '../utils/react-router-dom-abstraction';
import { PATHS } from './paths';

const UserSwitcher = () => {
  const history = useHistory();
  const type = useSelector(selectUserType);
  useEffect(() => {
    if (type !== UserType.NONE) {
      switch (type) {
        case UserType.USER:
          history.push(PATHS.USER_DASHBOARD);
          break;
        case UserType.ADMIN:
          history.push(PATHS.ADMIN_MAIN);
          break;
        default:
          history.push(PATHS.USER_MAIN);
      }
    }
  }, [type]);

  return <LoadingScreen />;
};

export default withAuth(UserSwitcher);
