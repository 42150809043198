import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as DeleteIcon } from '../../../../assets/deleteIcon.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const ImageWrapper = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  position: relative;
  margin: 0 15px 15px 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
`;

const DeleteButton = styled.button`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: ${colorFetch('pureWhite')};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.round};
  padding: 0;
  border: none;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover > svg > path {
    stroke: ${colorFetch('gray1')};
  }
`;

type AddedImageType = {
  element: any;
  deleteImage: () => void;
};
export const AddedImage = ({ element, deleteImage }: AddedImageType) => {
  const theme = useTheme();

  return (
    <ImageWrapper>
      <Image src={element} />
      <DeleteButton theme={theme} onClick={deleteImage}>
        <DeleteIcon />
      </DeleteButton>
    </ImageWrapper>
  );
};
