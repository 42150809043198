import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage } from '../../app/state/user';

import { API_URL, NEW_ID } from '../../consts';
import { ErrorAddCustomerEventType, ErrorEventType } from '../../types/error';
import { HttpService } from '../../utils/http';
import { setParentsList, setProfilesList } from '../../app/state/organisations';
import { useApi, generateApi } from '../../features/apiBuilder/useApiBuilder';

export const useGetClients = () => {
  const [localClients, setLocalClients] = useState([]);
  const language = useSelector(selectLanguage);
  useEffect(() => {
    const url = `${API_URL}/v2/clients/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id }));
        setLocalClients(result);
      } catch (e) {
        //
      }
    })();
  }, [language]);
  return localClients;
};

export const updateClient = (
  id: string,
  data: { name: string; email: string; phone: string; address: any },
  index: number,
  updateLocalItem: any,
  toggleUpdaiting: any,
  handleAddNotification: Function,
  setIsSaveButtonDisabled: (status: boolean) => void,
  languageStrings?: any,
) => {
  if (id !== NEW_ID) {
    setIsSaveButtonDisabled(true);
    const url = `${API_URL}/v2/clients/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
        setIsSaveButtonDisabled(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
        setIsSaveButtonDisabled(false);
      }
      toggleUpdaiting(false);
    })();
  } else {
    const url = `${API_URL}/v2/clients`;
    setIsSaveButtonDisabled(true);
    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
        setIsSaveButtonDisabled(false);
      } catch (e) {
        const error = e as ErrorAddCustomerEventType;
        handleAddNotification({ message: `${error?.response?.data?.detail[0]?.msg}`, type: 'error' });
        setIsSaveButtonDisabled(false);
      }
      toggleUpdaiting(false);
    })();
  }
};

export const deleteClientGlobal = (
  id: string[],
  deleteUser: any,
  deleteCheckedUsers: any,
  handleAddNotification: Function,
  languageStrings?: any,
) => {
  const data = { client_ids: id };
  const url = `${API_URL}/v2/clients/delete`;
  const deleteOneClient = () => deleteUser(id[0]);
  (async () => {
    try {
      await HttpService.post(url, data);
      if (id.length === 1) {
        deleteOneClient();
      } else {
        deleteCheckedUsers();
      }
      handleAddNotification({
        message: languageStrings.messageAboutSuccessfullyDeleted,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleAddNotification({
        message:
          error && error?.response?.data?.detail
            ? `${error?.response?.data?.detail}`
            : languageStrings.errorPage500Title,
        type: 'error',
      });
      console.warn(e);
    }
  })();
};

export const useGetClientsParents = () => {
  const dispatch = useDispatch();
  const url = `${API_URL}/v2/clients/parents/dropdown?limit=0`;

  const [data] = useApi({
    keywords: ['OrganisationCreation'],
    url,
    transformResponse: (data) => dispatch(setParentsList(data.parents)),
  });

  return [data];
};

export const getProfiles = (id: string, dispatch: any, handleAddNotification: Function, languageStrings?: any) => {
  const url = `${API_URL}/v2/clients/profiles/dropdown?parent_id=${id}&limit=0`;
  return generateApi({
    url,
    transformResponse: (data) => {
      dispatch(setProfilesList(data.profiles));
      data.profiles.length === 0 &&
        handleAddNotification({
          message: languageStrings.getProfilesError,
          type: 'error',
        });
    },
  })();
};
