import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { Textarea } from '../../../../components/textarea/Textarea';
import { TableInputType } from '../../types';
import { RequestBlock } from '../requestedBlock/RequestedBlock';

const Wrapper = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTextarea = styled(Textarea)`
  flex: 1;
  width: 100%;
` as any; // @TODO fix type

const StyledText = styled(Text)`
  flex: 1;
  word-break: break-all;
`;

interface PhoneProps {
  value: {
    type: TableInputType;
    actual_value: string | null;
    requested_value: string | null;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  isEditInProcess?: boolean;
  isError?: boolean;
  placeholder?: string;
}

export const Phone: FC<PhoneProps> = ({ value, state, setField, isEditInProcess, isError, placeholder }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const { actual_value, requested_value, with_requested, is_editable } = value;

  const validateReg = /^[\d\+]{0,1}[\d\(\)\ -]{0,14}[\d]{0,1}$/;

  const setPhoneValue = (actual_value: string) => setField({ ...value, actual_value });

  return (
    <Wrapper>
      {is_editable && isEditInProcess ? (
        <StyledTextarea
          textValue={actual_value}
          changeValue={(value: string) => {
            validateReg.test(value) && setPhoneValue(value);
          }}
          isError={isError}
          placeholder={placeholder}
          autoFocus
        />
      ) : (
        <StyledText type={TextType.TEXT_14_GRAY} color={gray1}>
          {actual_value ?? 'N/A'}
        </StyledText>
      )}
      {with_requested && <RequestBlock requested_value={requested_value} />}
    </Wrapper>
  );
};
