export const pl_pl = {
  accessDownloadEvents: 'Czy chcesz pobrać plik excel z danymi o zdarzeniach tego urządzenia za wybrany okres czasu?',
  accessDownloadTemperature:
    'Czy chcesz pobrać plik excel z danymi o temperaturze tego urządzenia za wybrany okres czasu?',
  accessDownloadWaterLevel:
    'Czy chcesz pobrać plik excel z danymi o poziomie wody w tym urządzeniu za wybrany okres czasu?',
  accessToThePlatformBlocked: 'Dostęp do platformy został zablokowany z powodu nieuregulowanych faktur.',
  actionButtonValue: 'Akcja',
  add: 'Dodać',
  addCommentButton: 'Dodaj komentarz',
  addCompany: 'Dodaj organizację',
  addDeviceModalClarifyingDesc:
    'Użytkownik końcowy nadal będzie musiał zainstalować i wdrożyć urządzenia, aby zbierać i monitorować pomiary.',
  addDeviceModalDesc:
    'Dodając urządzenia do konta organizacji, wszyscy użytkownicy przypisani do tej organizacji będą mogli zobaczyć i śledzić wydajność tych urządzeń. Użytkownicy z uprawnieniami administratora będą mogli również konfigurować ustawienia i dodawać nowe urządzenia do konta.',
  addDeviceModalWaysDesc: 'Istnieją dwa sposoby przypisania urządzeń do organizacji',
  addDevicesLabel: 'Dodaj urządzenia',
  addDevicesManuallyTitle: 'Dodaj urządzenie(a) ręcznie',
  addEventText: 'Dodaj wydarzenie',
  addIncidentTitle: 'Dodaj incydent',
  additionalDeviceAddDevice: 'Dodawanie nowego urządzenia',
  additionalDeviceEnterCode: 'Wpisz 10-cyfrowy kod z instrukcji',
  additionalDeviceNextButton: 'Następny',
  additionalInfoLabel: 'Dodatkowe informacje',
  additionalInfoPlaceholder: 'Wybierz dodatkowe informacje',
  addManuallyAddDeviceModal: 'Dodaj Ręcznie',
  addManuallyDescAddDeviceModal:
    'wpisz numery seryjne urządzeń, które chcesz przypisać. Może to być wiele pojedynczych urządzeń lub zakres obejmujący większą partię',
  addManuallyTooltipAddDeviceModal:
    'Zalecany do dodawania do 10 urządzeń lub do dodawania wielu urządzeń z tej samej parti produkcyjnej.',
  addNew: 'Dodaj nowy',
  addNewCompany: 'Dodaj nową organizację ',
  addNewCompanyDesc:
    'Organizacja jest nadrzędną instancją dla użytkowników i urządzeń i działa jako ich agregator. Wszyscy użytkownicy i urządzenia muszą być przepisane do organizacji. Aby móc korzystac z funkcjonalności platformy, konieczne będzie stworzenie użytkownika.',
  addNewCompanyDetails: 'Podaj szczegóły dotyczące organizacji.',
  addNewCompanyModalSecondStepDescription:
    'Użytkownik to instancja umożliwiająca monitorowanie pomiarów urządzeń na platformie oraz zarządzanie ich ustawieniami.',
  addNewCompanyModalSecondStepHintText:
    'Pierwszy użytkownik przypisany do organizacji musi mieć konto administratora, aby móc dodawać kolejnych użytkowników i zarządzać ustawieniami urządzeń.',
  addNewCompanyModalSecondStepTitle: 'Utwórz pierwszego użytkownika w tej organizacji',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText1: 'może edytować ustawienia konta i urządzeń.',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText2:
    'posiada widok tylko do odczytu, nie może edytować ustawień.',
  addNewCompanyModalSecondStepTypesOfAccountsText: 'Isnieją dwa rodzaje kont użytkowników:',
  addOrganisationLabel: 'Dodaj nową organizację',
  addresInstructionDesc1: 'Wpisz adres lub lokalizację w pasku wyszukiwania w lewym górnym rogu mapy.',
  addresInstructionDesc2:
    'Gdy zobaczysz ogólną lokalizację, powiększ mapę i przeciągnij ją w dokładne miejsce, aby uzyskać większą precyzję.',
  addresInstructionDesc3: 'Kliknij Potwierdź, aby zapisać swój wybór i wyjść z menu.',
  addresInstructionDesc4: 'Użyj pól tekstowych na dole okna, aby wprowadzić dokładny adres lokalizacji urządzenia.',
  addresInstructionDesc5: 'Kliknij Potwierdź, aby zapisać adres i wyjść z menu.',
  addresInstructionDesc6: 'Uwaga: mapa nie pokaże lokalizacji w tej metodzie.',
  addresInstructionTitle1: 'Metoda 1: Wyszukiwanie na mapie',
  addresInstructionTitle2: 'Metoda 2: Wprowadzanie adresu',
  addressSeaLevelLabel: 'Wysokość nad poziomem morza (m)',
  addressSeaLevelPlaceholder: 'Wprowadź wartość',
  addUser: '+ Dodaj użytkownika',
  advancedLabel: 'Zaawansowany',
  alertMessageError: 'Error appears when you did wrong or there is system fault',
  alertMessageNeutral: 'Neutral info displayed here',
  alertMessagePositive: 'Successful message to those who did good',
  alertMessageWarning: 'Warning text here so you can be more careful',
  allButton: 'Wszystkie',
  analyticsItem: 'Analityka',
  anUnexpectedErrorHasOccurred: 'Wystąpił nieoczekiwany błąd. Proszę spróbuj ponownie',
  applyButton: 'Zastosuj',
  applyButton1: 'Aplikowanie',
  applyButton2: 'Zastosowany',
  areYouSure: 'Jesteś pewny?',
  average: 'śr.',
  averagingMethodLabel: 'Metoda uśredniania',
  backButton: 'Cofnij',
  backHome: 'Powrót do strony głównej',
  basedOnManningsEquation: 'Na podstawie równania Manninga, ',
  basicLabel: 'Podstawowy',
  battery: 'Bateria',
  batteryLevel: 'Poziom baterii',
  batteryStatusBad: 'Niski',
  batteryStatusGood: 'Wysoki',
  batteryStatusWarn: 'Średni',
  billing: 'Dane do faktury',
  billingAddressPlaceholder: 'Wybierz adres rozliczeniowy',
  billingDetails: 'Szczegóły płatności',
  billingDetailsNotFilledDesc: 'Jeśli problem będzie się powtarzał, skontaktuj się z administratorem.',
  calibraion: 'Kalibracja',
  calibraionInPropgress: 'Trwa kalibracja',
  calibraionLabel: 'Szczegóły kalibracji',
  calibraionTooltip: 'Dowiedz się więcej o kalibracji',
  calibration: 'Kalibracja',
  calibrationDate: 'Data kalibracji',
  calibrationDesc:
    'Kalibracja jest zawsze wykonywana po uruchomieniu urządzenia. Ponowna kalibracja jest konieczna, jeśli urządzenie zostało przeniesione od czasu ostatniej kalibracji lub kalibracja została przeprowadzona nieprawidłowo. Przed przystąpieniem do procesu kalibracji należy upewnić się, że urządzenie znajduje się w wyznaczonym miejscu i pozostanie nienaruszone przez okres kalibracji - do 48h.',
  calibrationDescInProgress:
    'Rozpoczął się proces kalibracji. Może to potrwać do 48 godzin. Należy pamiętać, że w tym czasie pomiary poziomu wody mogą być niewiarygodne. Upewnij się, że urządzenie pozostanie nienaruszone w tym okresie.',
  calibrationInfoTooltip:
    'W procesie kalibracji urządzenie skanuje wnętrze studzienki w celu wykrycia wszystkich elementów statycznych, uwzględnienia ich w pomiarach i prawidłowego zebrania danych. Jest wykonywany zawsze po wdrożeniu',
  calibrationPeriodTootlipText: 'Dane z okresu kalibracji\n mogą być niewiarygodne',
  calibrationText: 'Kalibracja',
  calibrationTooltipText: 'Dane z okresu kalibracji mogą być nieprecyzyjne',
  cancelButton: 'Anuluj',
  chooseAddressPlaceholder: 'Wybierz adres',
  chooseDateTitle: 'Wybierz datę',
  chooseDeviceNamePlaceholder: 'Wybierz nazwę urządzenia',
  chooseOrganisation: 'Wybierz organizację',
  chooseOrganisationEmail: 'E-mail organizacji',
  chooseOrganisationName: 'Nazwa organizacji',
  chooseOrganisationNamePlaceholder: 'Wprowadź nazwę',
  chooseOrganisationPhone: 'Telefon organizacji',
  chooseOrganisationPhonePlaceholder: 'Wprowadź numer telefonu',
  chooseParentOrgFirst: 'Wybierz org. macierz.',
  choosePaymentMethodPlaceholder: 'Wybierz metoda płatności',
  choosePlansetForEachDevices:
    '\nWybierz zestaw planów\n dla każdego urządzenia. To ustali, jakie plany będą dostępne podczas procesu instalowania urządzenia.',
  chooseSeverityPlaceholder: 'Wybierz stopień ważności',
  chooseTypeOfInvoices: 'Wybierz rodzaje faktur',
  chooseTypePlaceholder: 'Wybierz typ',
  choosingPlanset: 'Wybieranie zestawu planów',
  clearButton: 'Wyczyść urządzenia',
  clearButtonMobile: 'Wyczyść',
  clickOn: 'Kliknij ',
  closeButtonValue: 'Zamknij',
  closedText: 'Zamknięty',
  commentsPlaceholder: 'Brak komentarzy',
  commentTableDayToday: 'Dziś, ',
  commentTableDayYesterday: 'Wczoraj, ',
  commentTableEdit: 'Edytuj Wiadomość',
  commentTableEditLabel: 'Edytowane: ',
  commentTableEditPlaceholder: 'Edytuj wiadomość ...',
  commentTablePlaceholder: 'Napisz komentarz...',
  commonButtonMore: 'Więcej',
  commonButtonRevoke: 'Cofni',
  commonButtonSend: 'Wyśłi',
  commonButtonUpload: 'Upload',
  companyAddress: 'Adres organizacji',
  companyAddressPlaceholder: 'Wpisz adres organizacji',
  companyCreationEmailAddress: 'Adres e-mail',
  companyName: 'Nazwa organizacji',
  companyNameLabel: 'Nazwa organizacji',
  companyNamePlaceholder: 'Wpisz nazwę organizacji',
  companyNamePlaceholderr: 'Nazwa firmy',
  companyOnly: 'Tylko organizacja',
  companyOnlyCardInfo:
    'Jeżeli teraz chcesz utworzyć tylko organizację, wybierz tę opcję. Aby konto było w pełni operacyjne, na późniejszym etapie należy ręcznie dodać użytkownika, który będzie Administratorem, oraz przypisać urządzenia.',
  companyPhone: 'Telefon organizacji',
  companyPhonePlaceholder: 'Wpisz telefon organizacji',
  companyPhoneTooltip:
    'Ogólny numer telefonu organizacji. Jeżeli taki nie istnieje, podaj numer pierwszego użytkownika, który zostanie przypisany do tej organizacji.',
  completedProvisioningButton: 'Przejdź do platformy',
  completedProvisioningContent:
    'Procecs instalowania urządzenia zakończył się. Aby rozpocząć zarządzanie urządzeniem, musisz zalogować się do platformy Smart Utilities. Utworzenie nowej organizacji może zająć ',
  completedProvisioningContent2: 'do 10 minut',
  completedProvisioningContent3: 'Po tym czasie prosimy o zalogowanie się do Platformy.',
  completedProvisioningLabel: 'Ukończono instalowanie',
  conditionalCheckerModalCheckboxLabel: "Don't Show this message again",
  conditionalCheckerModalCommonButton: ' Ok, rozumiem',
  conditionalCheckerModalTitleFirstPart:
    'We will run maintenance. All system will be updated automatically in the next',
  conditionalCheckerModalTitleSecondPart: 'hours.',
  configuration: 'Konfiguracja',
  contactSupport: 'Skontaktuj się z pomocą techniczną',
  continue: 'Dalej',
  continueStyled: '“Dalej“',
  copiedMessage: 'Skopiowane do schowka',
  coverTitle: 'Okładka',
  createAccount: 'Zarejestruj się',
  createButton: 'Utwórz',
  createMoreUsers: 'Utwórz więcej użytkowników',
  creationDate: 'Data utworzenia',
  currentNetworkConnectionTechnology: 'Technologia połączenia',
  currentNetworkLastUpdated: 'Ostatnia aktualizacja',
  currentNetworkPreferedConectionTechnology: 'Preferowana technologia połączenia',
  dashBoardCardCitiesTitle: 'Lokalizacje',
  dashBoardCardDevicesTitle: 'Całkowita liczba urządzeń',
  dashBoardCardLocationsTitle: 'Łączna liczba lokalizacji',
  dashboardCardsTitleDevices: 'Razem urządzeń',
  dashboardCardsTitleLocations: 'Lokalizacje',
  dashboardCardsTitleNoProblems: 'Nie wykryto problemów',
  dashboardCardsTitleProblems: 'Wykryte problemy',
  dashboardCardsTitleReport: 'Zgłoś problem',
  dashboardCardsTitleTemperature: 'Średnia temperatura',
  dashboardCardsTitleWarnings: 'Ostrzeżenia',
  dashboardTitle: 'Panel',
  dataPeriodLabel: 'Okres danych',
  dataProcessingDescription: 'Przetwarzanie dużej ilości danych. Proszę czekać',
  dataProcessingTitle: 'Przetwarzanie danych',
  days: 'dni',
  daysUnit: 'dni',
  deleteDevicesModal: 'Lista wybranych urządzeń zostanie wyczyszczona.',
  deleteModalInfoText: 'Lista wybranych urządzeń zostanie wyczyszczona.',
  deletingComment: 'Ten komentarz zostanie usunięty.',
  deletPairModal: 'Para włazów zostanie trwale usunięta.',
  deliveryAddress: 'Adres dostawy',
  deploy: 'Wdróż',
  deployed: 'Wdrożone',
  deploying: 'Wdrażany',
  deploymentDate: 'Data wdrożenia',
  deploymentDetailsModalTitle: 'Szczegóły wdrożenia',
  deploymentIsInProgress: 'Trwa wdrażanie urządzenia. Sprawdź później!',
  deploymentIsInProgressTextFirst:
    'Deployment is in progress, it can take up to 48 hours. Make sure the device stays undisturbed during this time.',
  deploymentIsInProgressTextSecond:
    'In the meantime, we recommend that you \n set other parameters \n of the device, such as transmission and measurement intervals or alarm configurations. If you want to set it from the device details page later, close this window.',
  deploymentIsInProgressTitle: 'Deployment is in progress',
  deploySetDepthModalText:
    'Wiarygodne pomiary wymagają podania dokładnej maksymalnej głębokości, mierzonej od radaru do najgłębszego punktu zbiornika.',
  deploySetDepthModalTitle: 'Ustaw maksymalną głębokość',
  depth: 'Głębokość',
  depthNotice:
    'Głębokość studni jest kluczowa dla prawidłowego pomiaru. Podaj głębokość jak najszybciej, w przeciwnym razie pomiar może nie być prawidłowy.',
  depthUnknown: 'Głębokość jest nieznana',
  descriptionLabel: 'Opis',
  descriptionPlaceholder: 'Wprowadź opis',
  details: 'Detale',
  deviceDeploymentIsInProgress: 'Trwa wdrażanie urządzenia',
  deviceDetailsGoToInsightDetails: 'Przejdź do lokalizacji',
  deviceID: 'ID Urządzenia',
  deviceIdText: 'ID Urządzenia: ',
  deviceName: 'Nazwa urządzenia',
  deviceInfoLabelProperty: 'Własność',
  deviceIsntDeployed: 'Urządzenie nie jest wdrożone',
  deviceLabel: 'Urządzenie',
  directLink: 'link bezpośredni',
  dismissableModalCommonButton: 'Learn More',
  dismissableModalTitle: 'This is an example of a simplified view that can be dismissed. Action isn’t required.',
  distanceToWater: 'odległość do wody/poziom wody',
  distanceToWaterTooltip: 'Odległość od wody',
  documentsManual: 'Instrukcja obslugi',
  documentsOneMore: 'One more doc',
  documentsReleaseNote1_1: 'Release Note 1.1',
  documentsReleaseNote2_0: 'Release Note 2.0',
  documentsReleaseNote2_4: 'Release Note 2.4',
  documentsReleaseNote2_5: 'Release Note 2.5',
  documentsReleaseNote2_6: 'Release Note 2.6',
  documentsSpecification: 'Specyfikacja inteligentnego radaru',
  download: 'Pobierz',
  doYouWantToApply:
    'Czy chcesz zastosować ten zestaw planów do wszystkich urządzeń tego typu, czy tylko do tego jednego?',
  dueOn: 'Termin zapłaty',
  editDeliveryAddressTitle: 'Edytuj adres dostawy',
  editInvoiceTitle: 'Edytuj szczegóły faktury',
  editMessageTitle: 'Edytuj wiadomość',
  emailAddress: 'Adres e-mailowy',
  emailAddressPlaceholder: 'Wpisz adres e-mail',
  emailAddressTooltip:
    'Ogólny adres e-mail organizacji. Jeżeli taki nie istnieje, podaj e-mail pierwszego użytkownika, który zostanie przypisany do tej organizacji.',
  emailNotificationsError: 'Wystąpił nieoczekiwany błąd. Proszę spróbuj ponownie',
  emailNotificationsOff: 'Powiadomienia e-mail są wyłączone na tym urządzeniu',
  emailNotificationsOn: 'Powiadomienia e-mail są włączone na tym urządzeniu',
  emphasizedHeaderTextModalDescription: 'You won’t be able to undo this action.',
  emphasizedHeaderTextModalTitle: 'Jesteś pewny?',
  endUserGuide: 'Podręcznik użytkownika końcowego',
  enterInfoPlaceholder: 'Wprowadź informacje',
  errorMessageForSelectAddress: 'Pola „linia 1” i „miasto” muszą być wypełnione.',
  errorPage401Description: 'Odmowa dostępu z powodu nieprawidłowych poświadczeń.',
  errorPage401Title: 'Nieautoryzowany',
  errorPage403Description:
    'Przepraszamy, nie masz uprawnień do przeglądania tego katalogu. Jeśli masz prawa dostępu, możesz wrócić do strony głównej.',
  errorPage403Title: 'Zakazany',
  errorPage404Description: 'Nie możemy znaleźć strony, której szukasz',
  errorPage404Title: 'Nie znaleziono strony',
  errorPage500Description: 'Mamy problem. Wypróbuj jedną z tych opcji, aby wrócić na właściwe tory',
  errorPage500Title: 'Coś poszło nie tak',
  errorPageMainPage: 'Strona główna',
  errorPageReload: 'Odśwież',
  existingItem: 'Ten element jest w użyciu i nie można go teraz usunąć.',
  export: 'Eksport',
  exportButton: 'Eksport',
  exportExcelFileTitle: 'Eksportuj plik Excel',
  failureReasonsModalHeader: 'Przyczyny niepowodzenia',
  failureReasonsModalInfo:
    'Oto typowe problemy występujące podczas przesyłania, które mogą spowodować niepowodzenie przesyłania. Sprawdź plik pod kątem następujących problemów, a następnie prześlij go ponownie:',
  failureReasonsModalReasonFirst: 'Dopasowany problem:',
  failureReasonsModalReasonFourth: 'Rozmiar pliku jest za duży:',
  failureReasonsModalReasonSecond: 'Problem z formatem:',
  failureReasonsModalReasonThird: 'Urządzenie niedostępne:',
  failureReasonsModalSuggestionFirst:
    'dane w pliku nie spełniają wymagań. Pobierz przykładowy plik i dopasuj podane tam kolumny.',
  failureReasonsModalSuggestionFourth:
    'aby zmniejszyć rozmiar pliku, możesz usunąć niepotrzebne wartości danych, kolumny i wiersze.',
  failureReasonsModalSuggestionSecond:
    'dane wprowadzone w pliku nie mogą być przetwarzane. Sprawdź, czy numery urządzeń są zgodne z następującym formatem: SMC12345678900.',
  failureReasonsModalSuggestionThird:
    'numer seryjny urządzenia nie jest dostępny, ponieważ jest przypisany do innej firmy. Jeśli chcesz przypisać go do nowej firmy, najpierw musisz go ponownie przypisać.',
  filtersButton: 'Filtry',
  filtersNotificationsError: 'Wystąpił nieoczekiwany błąd. Proszę przeładować stronę.',
  filtersSelectError: 'Błąd: brak danych',
  finalizeButton: 'Zakończ',
  firstDeviceAddress: 'Adres urządzenia nr 1',
  floatingModalCommonButtonDiscard: 'Discard',
  floatingModalTitle: 'Save changes?',
  flowInfo: 'Informacje o przepływie',
  flowLabel: 'Przepływ',
  flowMeasurementBasedOn: 'Pomiar przepływu na podstawie',
  flowMeasurementsLabel: 'pomiary przepływu',
  flowUnit: 'Jednostka przepływu',
  freeFlowCircularCulvert: 'Przepust kołowy o swobodnym przepływie',
  fullAdditionalDescription:
    'Opłata za wsparcie jest miesięcznym kosztem subskrypcji, obejmującym spersonalizowane wdrożenie, dedykowane wsparcie dla klientów oraz regularne aktualizacje oprogramowania. Płatność ta jest pobierana tylko za pierwsze 5 urządzeń, niezależnie od ich planu.',
  fullAddress: 'Pełny adres',
  fullNameOfOrganisation: 'Pełna nazwa (lub nazwa organizacji)',
  fullNameOfOrganisationPlaceholder: 'Wpisz pełną nazwę (lub nazwę organizacji)',
  fullyOperationalAccount: 'W pełni operacyjne konto',
  fullyOperationalAccountCardInfo:
    'Szybki i łatwy sposób na utworzenie w pełni funkcjonalnego konta dla nowej organizacji. Przechodząc przez 3-stopniowy kreator, utworzysz konto gotowe do użycia przez klienta.',
  generateButton: 'Generuj',
  generateReportModalDay: 'Dzień',
  generateReportModalMonth: 'Ten miesiąc',
  generateReportModalParameterCustomerPlaceholder: 'Wybierz klienta(ów)',
  generateReportModalParameterLocationPlaceholder: 'Wybierz lokalizacje',
  generateReportModalParameterLocationPlaceholderDisabled: 'Najpierw wybierz klienta(ów)',
  generateReportModalParameterRecipients: 'Odbiorcy',
  generateReportModalParameterRecipientsPlaceholder: 'Wybierz odbiorcę(ów)',
  generateReportModalPeriod: 'Okres',
  generateReportModalPeriodBorderFrom: 'Z:',
  generateReportModalPeriodBorderTo: 'Do:',
  generateReportModalShowMessage: 'Powinieneś wybrać',
  generateReportModalWeek: 'W tym tygodniu',
  generateReports: 'Generuj raport',
  getProfilesError: 'Wybrana organizacja nadrzędna. nie ma profili, wybierz inną organizację nadrzędną',
  getStartedButton: 'Rozpocznij',
  goBack: 'Wróć',
  gotIt: 'Rozumiem',
  goToBillingDetails: 'Przejdź do płatności',
  goToInvoices: 'Przejdź do faktur',
  goToMain: 'Przejdź do strony głównej',
  gpsCheck1Row:
    'Sprawdzenie lokalizacji GPS pozwala na weryfikację aktualnej pozycji urządzenia i zapisanie jej na platformie. Ten proces zużywa baterię, co skraca jej żywotność.',
  gpsCheck2Row:
    'Z tego powodu \nzalecamy używać funkcji sprawdzania współrzędnych GPS nie więcej niż 2 razy\n, tylko w przypadkach gdy',
  gpsCheck3Row:
    '- instalujesz, uruchamiasz i kalibrujesz urządzenie w miejscu, gdzie ma być umieszczone na stałe i chcesz podać jego współrzędne GPS jako lokalizację,',
  gpsCheck4Row: '- nie możesz zlokalizować urządzenia z powodu nieupoważnionego przemieszczenia.',
  gpsCheck5Row: '\nCzy na pewno chcesz kontynuować sprawdzanie pozycji GPS?\n',
  gpsCheckTooltip: 'Dowiedz się więcej o lokalizacji GPS',
  gpsDetailsAddressTitle: 'Współrzędne odebrane z GPS',
  gpsDetailsAreYouSureText: 'Każde kontrola GPS głęboko spuszcza baterię. Na pewno chcesz kontynuować?',
  gpsDetailsAreYouSureTitle: 'Jesteś pewny?',
  gpsDetailsCheckPerformedText: 'Wykonane kontrole lokalizacji GPS: ',
  gpsDetailsManualChecksPerformed: 'Kontrole ręczne wykonane',
  gpsDetailsRequestCheck: 'Żądanie sprawdzenia GPS',
  gpsDetailsSaveAsMainLocText: 'Czy na pewno chcesz zmienić bieżącą lokalizację urządzenia na ten?',
  gpsDetailsSaveAsMainLocTitle: 'Zapisz jako główną lokalizację',
  gpsDetailsSaveMainLocation: 'Zapisz jako główną lokalizację',
  gpsDetailsSaveMainLocationText: 'Czy na pewno chcesz zmienić bieżącą lokalizację na tę?',
  gpsDetailsSaveMainLocationTitle: 'Zapisz jako główną lokalizację',
  gpsDetailsTitle: 'Szczegóły lokalizacji GPS',
  gpsLocationCheckText:
    'Możesz poprosić o kontrolę lokalizacji GPS, która zostanie wykonana, gdy nastąpi następny cykl transmisji danych (sprawdź częstotliwość transmisji w szczegółach urządzenia). Po otrzymaniu współrzędnych od GPS będą dostępne tutaj.',
  gpsLocationCheckTitle: 'Żądanie sprawdzenia lokalizacji GPS',
  gpsLocationProperty: 'Lokalizacja GPS',
  GPSLocationText: 'Sprawdzanie lokalizacji GPS',
  gpsRequestSuccess: 'Lokalizacja GPS została zaktualizowana',
  graphPlaceholderLabel: 'Poziom wody',
  gravitationalCircularCulvert: 'Grawitacyjny przepust kołowy',
  groundMeter: 'Inteligentny POZIOM',
  groupFieldPlaceholder: 'Select customer',
  hightText: 'Wysokość',
  hourLabel: 'godzina',
  hours: 'godziny',
  hoursUnit: 'godziny',
  humidity: 'Wilgotność',
  humidityMeasurements: 'Pomiar wilgotności',
  ifItDoesntTryThis: 'Jeśli nie, spróbuj tego',
  imagesLabel: 'Obrazy (opcjonalnie)',
  incidentAddressPlaceholder: 'Wybrane automatycznie',
  incidentsNotifications: 'Powiadomienia o incydentach',
  incidentsPlaceholder: 'Napisz coś...',
  includedLablel: 'Zawiera',
  incorrectTypeOfFile: 'Nieprawidłowy typ pliku. Wybierz format obrazu (.jpg, .png, .heic itp.)',
  individualText: 'Możesz dodać wyszukiwane urządzenie oraz te następujące po nim, zaznaczając pola obok nich.',
  individualTitle: 'Pojedyńczo',
  info: 'Info',
  infoButtonTooltip: 'some info',
  informationModalHeader: 'Zakładanie konta dla nowej organizacji',
  informationModalInfo:
    'Szybko stwórz konto dla swojego klienta. Przejdź przez 3-stopniowy proces, aby stworzyć w pełni operacyjne konto:',
  informationModalListOfTextFirst: 'Dodaj nową organizację;',
  informationModalListOfTextSecond: 'Utwórz użytkownika(ów) w tej organizacji;',
  informationModalListOfTextThird: 'Przypisz urządzenia do konta organizacji.',
  inisghtDetailsHistorySelectLast: 'Ostatni',
  inisghtDetailsHistorySelectPeriodsDays: 'dni',
  inisghtDetailsHistorySelectPeriodsMonths: 'miesięcy',
  inisghtDetailsHistorySelectPeriodsWeek: 'tydzień',
  inisghtDetailsHistorySelectPeriodsYear: 'rok',
  insightDetailsCommentsPlaceholder: 'Wpisz coś...',
  insightDetailsCommentsTitle: 'Komentarze',
  insightDetailsCurrentNetworkTitle: 'Bieżąca sieć',
  insightDetailsDeviceConfigAddressButtonsConfirm: 'Potwierdź',
  insightDetailsDeviceConfigAddressChooseLocation: 'wybierz lokalizację na mapie',
  insightDetailsDeviceConfigAddressInputsCity: 'Miasto',
  insightDetailsDeviceConfigAddressInputsCountry: 'Kraj',
  insightDetailsDeviceConfigAddressInputsLatitudeName: 'Szerokość',
  insightDetailsDeviceConfigAddressInputsLatitudePlaceholder: 'Wpisz szerokość',
  insightDetailsDeviceConfigAddressInputsLine1: 'Linia 1',
  insightDetailsDeviceConfigAddressInputsLine2: 'Linia 2',
  insightDetailsDeviceConfigAddressInputsLongitudePlaceholder: 'Wpisz długość',
  insightDetailsDeviceConfigAddressInputsLongotudeName: 'Długość',
  insightDetailsDeviceConfigAddressInputsRegion: 'Region',
  insightDetailsDeviceConfigAddressInputsZip: 'Kod pocztowy',
  insightDetailsDeviceConfigAddressSearchBarPlaceholder: 'Wpisz lokalizację…',
  insightDetailsDeviceConfigAddressTitle: 'Adres urządzenia',
  insightDetailsDeviceConfigHeaderEdit: 'Edytuj',
  insightDetailsDeviceConfigHeaderParam: 'Parametr',
  insightDetailsDeviceConfigHeaderValue: 'Wartość',
  insightDetailsDeviceConfigRowsName: 'Nazwa urządzenia',
  insightDetailsDeviceConfigRowsTransmissionFreq: 'Częstotliwość transmisji',
  insightDetailsDeviceConfigStatusChangesStagesApplied: 'Stosowany',
  insightDetailsDeviceConfigStatusChangesStagesFailed: 'Przegrany',
  insightDetailsDeviceConfigStatusChangesStagesRequested: 'Żądany',
  insightDetailsDeviceConfigTitle: 'Konfiguracja urzadzenia',
  insightDetailsDeviceConfigurationEditInputsAddressPlaceholder: 'Podaj adres',
  insightDetailsDeviceConfigurationEditInputsAddressTitle: 'Adres',
  insightDetailsDeviceConfigurationEditInputsButtonsCancel: 'Anuluj',
  insightDetailsDeviceConfigurationEditInputsButtonsSave: 'Zapish',
  insightDetailsDeviceConfigurationEditInputsNamePlaceholder: 'Wprowadź imię',
  insightDetailsDeviceConfigurationEditInputsNameTitle: 'Nazwa',
  insightDetailsDeviceConfigurationEditInputsTransmissionFreqPlaceholder: 'Wprowadź częstotliwość transmisji',
  insightDetailsDeviceConfigurationEditInputsTypeTitle: 'Rodzaj',
  insightDetailsDeviceId: 'ID urzadzenia',
  insightDetailsDeviceInfoTitle: 'Informacje o urządzeniu',
  insightDetailsEvent: 'Zdarzenie',
  insightDetailsEventData: 'Data',
  insightDetailsEventTableHeaderEvent: 'Zdarzenie',
  insightDetailsEventTableHeaderTime: 'Czas',
  insightDetailsEventTableSelectAll: 'Wszystko',
  insightDetailsEventTableTitle: 'Tabela zdarzeń',
  insightDetailsEventTime: 'Czas',
  insightDetailsGeneralReportsButton: 'Raporty ogólne',
  insightDetailsGoToDeviceButton: 'Przejdź do urządzenia',
  insightDetailsGoToFlowButton: 'Przejdż do przepływu',
  insightDetailsHistoryTitle: 'Historia',
  insightDetailsNetworkEvent: 'Tabela zdarzeń sieciowych',
  insightDetailsRadioModulesLabel: 'MODUŁ RADIOWY',
  insightDetailsRadioModulesTitle: 'Moduły radiowe',
  insightDetailsRealTimeCardsTemperature: 'Temperatura',
  insightDetailsRealTimeCardsWater: 'Poziom wody',
  insightDetailsRealTimeTitle: 'Czas rzeczywisty',
  insightDetailsStatusChangesButton: 'Rozumiem',
  insightDetailsStatusChangesStagesCurrent: 'Bieżący: ',
  insightDetailsStatusChangesTitlesDeviceName: 'Nazwa urządzenia',
  insightDetailsStatusChangesTitlesTransmissionFreq: 'Częstotliwość transmisji urządzenia',
  insightDetalisEventDateAndTime: 'Data & Czas',
  insightMobilePlaceholder: 'Wybierz miejsce…',
  insightPlaceholder: 'Szukaj',
  insightSearchPlaceholder: 'Czego szukasz?',
  insightSearchResultsNoResults: 'brak wyników',
  insightSearchResultsResult: 'wynik',
  insightSearchResultsResults: 'wyniki',
  insightTitle: 'Podgląd w czasie rzeczywistym',
  insightToggleList: 'Widok mapy',
  insightToggleMap: 'Widok mapy',
  internalHumidityTitle: 'Wilgotność wewnętrzna',
  internalTemperature: 'Temperatura wewnętrzna',
  invoiceDetails: 'Dane faktury',
  invoiceID: 'Identyfikator faktury',
  invoices: 'Faktury',
  kontynuuj: 'Kontynuuj',
  labelReportsTableLocation: 'Lokalizacja',
  lastEventLabel: 'Ostatnie zdarzenie',
  lastSeenOnline: 'Ostatnio widziany online',
  lastSeenTitle: 'Ostatnio widziany',
  levels: 'Poziomy',
  linkAccount: 'Połącz konto',
  linkPaymentMethod: 'Połącz metodę płatności',
  locationPopupCityPlaceholder: 'Wpisz miasto',
  locationPopupCountryPlaceholder: 'Wpisz kraj',
  locationPopupDescription: 'wybierz lokalizację na mapie',
  locationPopupLine1Placeholder: 'Wpisz wiersz 1',
  locationPopupRegionPlaceholder: 'Wprowadź region',
  locationPopupTitleDevices: 'Adres urządzenia',
  locationPopupZipPlaceholder: 'Wpisz kod pocztowy',
  maintenanceTitle: 'Utrzymanie',
  manageAddButton: 'Dodaj',
  manageClientEditAddressSearchBarPlaceholderAddress: 'Podaj adres…',
  manageClientEditAddressTitle: 'Adres klienta',
  manageClientsEditClientInputsNameTitle: 'Nazwa klienta',
  manageClientsEditClientTitle: 'Edytuj klienta',
  manageClientsMultipleDeleteModalButtonsNo: 'Nie, anuluj',
  manageClientsMultipleDeleteModalButtonsYes: 'Tak. zrób to',
  manageClientsMultipleDeleteModalDescription: 'Klienci, którzy zostaną trwale usunięci: ',
  manageClientsMultipleDeleteModalTitle: 'Jesteś pewny?',
  manageClientsSingleDeleteModalDescription: 'Ten klient zostanie trwale usunięty:',
  manageClientsTableHeaderDeleteButton: 'Usuń',
  manageClientsTableHeaderDeleteSelected: 'Wybrany: ',
  manageClientsTableHeaderItemsAddress: 'Adres',
  manageClientsTableHeaderItemsClient: 'Organizacja',
  manageClientsTableHeaderItemsEmail: 'Email',
  manageClientsTableHeaderItemsName: 'Imie',
  manageClientsTableHeaderItemsPhone: 'Numer telefonu',
  manageClientsTableHeaderItemsPlace: 'Miejsce',
  manageClientsTableRowPlaceholdersAddress: 'Podaj adres',
  manageClientsTableRowPlaceholdersEmail: 'Wprowadź email',
  manageClientsTableRowPlaceholdersPhone: 'Wpisz telefon',
  manageDeleteSemiTitle: 'Podmioty, które zostaną trwale usunięte: ',
  manageDevicesChooseClientPlaceholder: 'Wybierz klienta',
  manageDevicesEditDevicesTitle: 'Edytuj urządzenie',
  manageDevicesNoCLients: 'Brak klientów',
  manageDevicesPlace: 'Wpisz miejsce',
  manageIncidentsAddButton: 'Dodaj',
  manageUsersAddUserModalInputsClientPlaceholder: 'Wybierz',
  manageUsersAddUserModalInputsRoleTitle: 'Rola',
  manageUsersEditUserModalTitle: 'Edytuj użytkownika',
  manageUsersMultipleDeleteModalDescription: 'Użytkownicy, którzy zostaną trwale usunięci: ',
  manageUsersSelectAll: 'Wszyscy klienci',
  manageUsersSingleDeleteModalDescription: 'Ten użytkownik zostanie trwale usunięty:',
  manholeModalName: 'Właz',
  manholes: 'włazy',
  manningsPair: 'Para Manninga',
  manufacturingDate: 'Data produkcji',
  meaningLabel: 'Znaczenie',
  measurementDeviceDetailsTableName: 'Nazwa',
  measurementItem: 'Pomiar przepływu',
  measurementsFromDevice: 'Pomiary z urządzenia',
  measurementTitle: 'Pomiar przepływu',
  messageAboutSuccessfullyDeleted: 'Pomyślnie usunięto',
  minMeasurmentIntervals: 'Minimalne odstępy między pomiarami',
  mins: 'minut',
  minsUnit: 'minut',
  minTransmissionIntervals: 'Minimalne odstępy między transmisjami',
  mobileEditPopupChooseClient: 'Wybierz organizacja',
  mobileEditPopupChooseRole: 'Wybierz rolę',
  mobileModalEditAddClientTitle: 'Dodaj nowego klienta',
  mobileModalEditAddUserTitle: 'Dodaj nowego użytkownika',
  modalSystemSelectPlaceholder: 'Dane są puste',
  modalTitleAdd: 'Dodaj',
  modalTitleView: 'Szczegóły',
  monthlyLabel: 'Miesięcznie',
  moreDetailsButton: 'Więcej szczegółów',
  multipleUsersCanBeAdded:
    'W ramach jednej organizacji można dodać wielu użytkowników.\nDodając kolejnych użytkowników, dajesz im dostęp do platformy, aby mogli monitorować pomiary urządzeń.',
  nameSurnamePlaceholder: 'Wpisz imię i nazwisko',
  navigationClients: 'Organizacje',
  navigationDevices: 'Urządzenia',
  navigationDocuments: 'Dokumenty',
  navigationIncidents: 'Zdarzenia',
  navigationInsight: 'Podgląd',
  navigationLogout: 'Wyloguj',
  navigationManage: 'Zarządzanie',
  navigationNotifications: 'Powiadomienia',
  navigationNotificationsTimeAgo: 'temu',
  navigationNotificationsTimeHr: 'godz',
  navigationNotificationsTimeMin: 'min',
  navigationReports: 'Raporty',
  navigationSettings: 'Ustawienia',
  navigationUsers: 'Użytkownicy',
  nextButton: 'Następny',
  noButton: 'Nie',
  noDataPlaceholderText: 'Brak danych',
  noReportsPlaceholder: 'Brak raportów',
  notDeployed: 'Nie wdrożono',
  noTestInformation: 'Brak informacji o kontroli jakości dla tego urządzenia',
  noteText: 'Nota',
  notice: 'Ogłoszenie',
  notOkTitle: 'Nie ok',
  oops: 'Ups!',
  openText: 'Otwarty',
  optionsDialogueModalDescription: 'Do you want to proceed?',
  optionsDialogueModalTitle: 'Items will be moved to secret folder.',
  organisationAddress: 'Adres organizacji',
  organisationAddressLabel: 'Adres organizacji',
  organisationCreationSuccess: 'Utworzono organizację',
  organisationDetailsTitleInfoTable: 'Informacje o organizacji',
  organisationLabel: 'Organizacja',
  organisationsName: 'Nazwa organizacji',
  organisationsNamePlaceholder: 'Wpisz nazwę organizacji',
  outletPressure: 'Ciśnienie wylotowe',
  outletTemperature: 'Temperatura na wylocie',
  outsidePressure: 'Ciśnienie zewnętrzne',
  outsideTemperature: 'Temperatura na zewnątrz',
  overdue: 'Po terminie',
  overflowBasedOn: 'Przelew oparty na',
  overflowLabel: 'przelewy',
  pageNotFound: 'Strona nie znaleziona.',
  paginationButtonNext: 'Następny',
  paginationButtonPrevious: 'Poprzedni',
  paginationGo: 'Idź',
  paginationGoTo: 'Idź do',
  paid: 'Opłacona',
  pairConfiguration: 'Konfiguracja parowania',
  pairId: 'ID pary',
  pairInfo: 'Informacje o parowaniu',
  pariedManholeButton: 'Przejdź do pary',
  pariedManholegraphLabel: 'Przepływ wody',
  pariedManholeModalName: 'Sparowane studzienki',
  passed: 'Przeszedł',
  paymentDetails: 'Szczegóły płatności',
  paymentMethodHasBeenSuccessfullySaved: 'Metoda płatności została pomyślnie zapisana',
  pending: 'Oczekująca',
  perDevice: 'za urządzenie',
  perMonth: 'miesięcznie',
  personnelLabel: 'Personel',
  personnelPlaceholder: 'Wprowadź personel',
  perYear: 'rocznie',
  phisicalId: 'Fizyczny ID',
  photosTitle: 'Zdjęcia',
  pickSubscriptionPlan: 'Wybierz plan subskrypcji',
  pickSubscriptionPlanSub1: 'Wybierając plan, subskrybujesz zestaw funkcji na Platformie Smart Utilities.',
  pickSubscriptionPlanSub2: 'Musisz wybrać plan dla każdego urządzenia, które instalujesz.',
  pickUpEventText: 'Przemieszczenie urządzenia',
  placeholderNoData: 'Brak danych',
  plans: 'Plany',
  plansetWasAppliedToAllDevices: 'Planset was applied to all devices of this type',
  plansetWasAppliedToDevices: 'Planset was applied to the device',
  pleaseFillOutBillingInformation:
    'Wypełnij dane do faktury, aby uzyskać pełny dostęp do platformy. Po wypełnieniu odśwież stronę.',
  pointIntervalLabel: 'Odstęp punktów',
  premiumLabel: 'Premium',
  pressToLearnMoreTooltip: 'Kliknij by dowiedzieć się więcej',
  privacyPolicy: 'Polityka prywatności',
  problemsButtonDetails: 'Szczegóły',
  problemsButtonHighTemperature: 'Wysoka temperatura',
  problemsDate: 'Data',
  problemsOf: 'z',
  problemsPlace: 'Miejsce',
  problemsProblem: 'Problem',
  problemsShowing: 'Wyświetlono',
  profileLabel: 'Profil',
  profilePlaceholder: 'Wybierz profil',
  provisioned: 'Udostępnione',
  provisionedOnLabel: 'Udostępnione w dniu',
  provisioning: 'Instalacja',
  provisioningAddingDevice: 'Dodawanie nowego urządzenia',
  provisioningAddress: 'Adres',
  provisioningChooseOrganisation: 'Wybierz organizację',
  provisioningContent:
    ' i podążaj ścieżką. Po zweryfikowaniu konta wróć do tej strony, aby się zalogować i kontynuować proces instalacji.',
  provisioningContent2: ' aby kontynuować instalację. Możesz zostać poproszony o zalogowanie się na swoje konto.',
  provisioningContent3:
    'Wpisz 10-cyfrowy kod z instrukcji, jeśli jesteś na komputerze. Jeśli zeskanowałeś kod QR, przejdziesz bezpośrednio do następnego kroku.',
  provisioningContent4: ' z dostępnych opcji (jeśli byłeś wcześniej dodany do platformy) lub ',
  provisioningContent5:
    ' (jeśli dopiero się zarejestrowałeś). Urządzenie, które instalujesz, zostanie przypisane do tej organizacji.',
  provisioningCreateOrg: 'utwórz nową organizację',
  provisioningDate: 'Data instalacji',
  provisioningDeployNow: 'Wdróż teraz (zawsze możesz wdrożyć później)',
  provisioningDescribe:
    'Instalując urządzenie, przypiszesz je do swojej organizacji na platformie Smart Utilities. Proces różni się, w zależności czy masz już konto na naszej platformie, czy nie.',
  provisioningDetails: 'Szczegóły instalacji',
  provisioningDeviceInstalmentLocationOptional: 'Lokalizacja instalacji urządzenia (opcjonalnie)',
  provisioningErrorAssign: 'Błąd przypisania',
  provisioningFinish: 'Sfinalizuj',
  provisioningInOrderForDevice:
    'Aby urządzenie było sprawne i wykonywało pomiary, należy je wdrożyć. Zaleca się wdrożenie urządzenia po instalacji. Uwaga: wdrożenie może potrwać do 24 godzin w dobrych warunkach sieciowych (więcej w złych)',
  provisioningInstructions: 'Instrukcje',
  provisioningOrganisation: 'Organizacja',
  provisioningRow: 'Jeśli jeszcze nie masz konta:',
  provisioningRow2: 'Jeśli masz już konto na Platformie:',
  provisioningSetDepthModalTitle: 'Maksymalna głębokość',
  provisioningSuccessfullyAssign: 'Urządzenie zostało wdrożone pomyślnie',
  provisioningTooltipOrganisations:
    'Organizacja jest instancją nadrzędną dla użytkowników i urządzeń, pełni rolę ich agregatora. Jeśli jesteś klientem indywidualnym, możesz wprowadzić własne dane.',
  pumpingStationAndTank: 'przepompownia i zbiornik',
  qualityControl: 'Kontrola jakości',
  quickstartGuide: 'Skrócona instrukcja obsługi',
  radioModulesFirmwareRevision: 'Wersja oprogramowania układowego',
  radioModulesManufacturer: 'Producent',
  radioModulesTypeModel: 'Typ/Model',
  rangeText:
    'wyszukaj gamę urządzeń, wpisując pierwszy i ostatni numer seryjny z parti produkcyjnej, którą chcesz przypisać. Wszystkie urządzenia z tego zakresu zostaną zaznaczone automatycznie. Te które chcesz wykluczyć możesz odznaczyć.',
  rangeTitle: 'Zasięg',
  reload: 'Odśwież stronę',
  reportProblemsModalDescription: 'Opisz problem',
  requestedValue: 'Żądana zmiana wartości na',
  resolveButton: 'Rozwiąż',
  resolvedButton: 'Rozwiązane',
  resolvedSuccessfullyMessage: 'Rozwiązano pomyślnie',
  resolveIncidentTitle: 'Rozwiąż incydent',
  roleAdmin: 'Administrator',
  roleLabel: 'Rola',
  roleUser: 'Użytkownik',
  sameAsBillingInfo: 'Tak samo jak informacje rozliczeniowe',
  sampleButton: 'Próbka',
  saveButton: 'Zapisz',
  scanCuccessful: 'Skanowanie powiodło się',
  searchBarPlaceholder: 'Szukaj...',
  secondDeviceAddress: 'Adres urządzenia nr 2',
  selectButtonValue: 'Wybierz',
  selectDevicePlaceholder: 'Wybierz urządzenie',
  selectedDatesLabel: 'Wybrane daty',
  selectedTitle: 'Wybrane',
  selectPeriodLastDays: 'Ostatnie 30 dni',
  selectPeriodLastMonths: 'Ostatnie 6 miesięcy',
  selectPeriodLastWeek: 'Zeszły tydzień',
  selectPeriodLastYear: 'Ostatni rok',
  selectValuePlaceholder: 'Wybierz wartość',
  setParameters: 'Set parameters',
  severityHigh: 'Wysoki',
  severityLabel: 'Priorytet',
  severityLow: 'Niski',
  severityMedium: 'Średni',
  sewageLevelTitle: 'Poziom ścieków (%)',
  shortAdditionalDescription: 'dotyczy tylko \npierwszych 5 urządzeń\n w tej cenie za urządzenie miesięcznie',
  skipButton: 'Pomiń',
  skipButtonAddDeviceModal: 'Pomiń',
  smartHydrant: 'Inteligentny HYDRANT',
  smartRadar: 'Inteligentny RADAR',
  smartRadarAlarm: 'Inteligentny ALARM',
  somethingWentWrong: 'Coś poszło nie tak',
  sorryWeCouldNotFindThePage: 'Przepraszamy, nie mogliśmy znaleźć strony!',
  sourceLabel: 'Źródło',
  sourceTitle: 'Źródło',
  specialInputPlaceholder: 'Wszystko, co chcesz, może być tutaj',
  specialInputTitle: 'Specjalny input',
  standingWater: 'Stojąca woda',
  standingWaterDetection: 'Wykrywanie stojącej wody',
  startButton: 'Start',
  startDateLabel: 'Data rozpoczęcia',
  startLabel: 'Start',
  startTimeLabel: 'Czas rozpoczęcia',
  startTimePlaceholder: 'Wprowadź czas rozpoczęcia',
  statusLabel: 'Status',
  statusPlaceholder: 'Wprowadź status',
  subcribeButton: 'Subskrybuj',
  submergeInWater: 'Zanurzenie w wodzie',
  submergeInWaterDetection: 'Wykrywanie zanurzenia w wodzie',
  submit: 'Składać',
  subscribeTooltip: 'Zrezygnuj z otrzymywania powiadomień e-mail',
  subscriptionContent1:
    'Jeśli Twoja organizacja ma wybraną metodę i politykę płatności na platformie SUP, zostaną one zastosowane do tego urządzenia.',
  subscriptionContent2:
    'Jeśli nie, będziesz musiał je podać podczas logowania do platformy. Tylko wtedy będziesz miał dostęp do funkcji.',
  subscriptionPlan: 'Plan subskrypcji',
  success: 'Powodzenie',
  successfullSavingMessage: 'Zapisano pomyślnie',
  successMessageUploadingPhoto: 'Zdjęcie zostało przesłane!',
  successSaveMessage: 'Zapisano pomyślnie',
  summaryAddressInput: 'Adres firmy',
  summaryAddressPlaceholder: 'Wpisz adres firmy',
  summaryCompanyInput: 'Nazwa firmy',
  summaryCompanyPlaceholder: 'Wpisz nazwę firmy',
  summaryCompanyRowTitle: 'Organizacja',
  summaryDevicesRowTitle: 'Urządzenia',
  summaryEmailInput: 'Adres e-mail',
  summaryEmailPlaceholder: 'Wpisz adres e-mail firmy',
  summaryPhoneInput: 'Telefon firmowy',
  summaryPhonePlaceholder: 'Wpisz numer telefonu firmy',
  summarySubtitle:
    'Sprawdź, czy wszystkie informacje są poprawne, a następnie zakończ, zapisując nową organizację z przypisanymi użytkownikami i urządzeniami.',
  summaryTitle: 'Podsumowanie',
  summaryUsersRowTitle: 'Użytkownik(cy)',
  symbolLabel: 'Symbol',
  tableInputErrorMessage: 'Sprawdź wartość. To nie jest ważne',
  tableLableDatePlaceholder: 'Wpisz datę',
  tamperDetection: 'Wykrywanie manipulacji',
  taxesLabel: 'bez podatków',
  temperatureLowCase: 'temperatura',
  temperatureMeasurements: 'Pomiar temperatury',
  temperatureTooltip: 'Temperatura',
  termsOfService: 'Warunki usługi',
  textFieldEmphasizedHeaderTextModalDescription:
    'Info text informing the user about what are the consequences of the action.',
  textFieldEmphasizedHeaderTextModalInputName: 'Enter name of device to confirm action',
  textFieldEmphasizedHeaderTextModalTitle: 'Revoke device rights?',
  theDownloadWillStartShortly: 'Pobieranie rozpocznie się wkrótce',
  thePhotoWillBeDeleted: 'Zdjęcie zostanie trwale usunięte.',
  thisDeviceButton: 'To urządzenie',
  timePlaceholder: 'Wprowadź czas (gg:mm)',
  tooltipAddDevicesManually:
    'To urządzenie jest przypisane do innej organizacji. Jesli chcesz przypisać je do nowej organizacji, najpierw musisz przepisać go z powrotem do siebie, nacisnąć przycisk odświeżenia, a następnie przypisać do nowej organizacji.',
  toProceedEveryDevice: 'Aby kontynuować, każde urządzenie musi mieć wybrany zestaw planów',
  total: 'Razem',
  totalNumberOfDevices: 'Całkowita liczba urządzeń',
  unexpectedErrorHasOccurred: 'Wystąpił nieoczekiwany błąd. Proszę spróbuj ponownie',
  unsubcribeButton: 'Anuluj subskrybcje',
  unsubscribeTooltip:
    'Zapisz się, aby otrzymywać powiadomienia e-mail, gdy urządzenie wykryje nieprawidłowe wartości (np. osiągnięto krytyczny próg temperatury)',
  uploadDevicesInputTextFirstPart: 'Wybierz plik',
  uploadDevicesInputTextSecondPart: 'lub przeciągnij tutaj (.csv lub .xlsx)',
  uploadDevicesInputTextSecondPartMobile: '(.csv lub .xlsx)',
  uploadDevicesModalFailedUploads: 'Nieudane przesyłanie:',
  uploadDevicesModalSuccessfullyUploadedDevices: 'Pomyślnie przesłane urządzenia:',
  uploadDevicesModalTryAgain: 'Spróbuj ponownie',
  uploadDevicesModalUploadingFile: 'Przesyłanie pliku...',
  uploadDevicesTitle: 'Prześlij urządzenia',
  uploadFileAddDeviceModal: 'Prześlij plik',
  uploadFileDescAddDeviceModal:
    'Importuj plik CSV / XLSX ze wszystkimi numerami seryjnymi urządzeń, które chcesz przypisac do tej organizacji',
  uploadFilesModalDescriptionFirstPart: 'Choose file',
  uploadFilesModalDescriptionSecondPart: 'or drag it here',
  uploadFilesModalListTitle: 'Uploaded files',
  uploadFilesModalTitle: 'Upload firmware',
  uploadFileTooltipAddDeviceModal:
    'Zalecany do dodawania więcej niż 10 urządzeń jednocześnie z różnych parti produkcyjnych.',
  userCompanyLabel: 'Organizacja',
  userDeleteInfo: 'Użytkownik zostanie natychmiast usunięty.',
  userEmailLabel: 'Adres e-mail',
  userEmailPlaceholder: 'Wprowadź adres e-mail',
  userNameLabel: 'Nazwa użytkownika',
  usersAddedByLabel: 'Dodane przez',
  usersDateOfRegistrationLabel: 'Data rejestracji',
  usersFirstLoginLabel: 'Pierwsze logowanie',
  usersName: 'Nazwa użytkownika',
  usersParentCustomerLabel: 'Organizacja macierzysta',
  usersParentOrganisationPlacholder: 'Wybierz org. macierz.',
  validationFillFieldsRequest: 'Proszę poprawnie wypełnić wszystkie pola',
  validationMessage: ' nie wygląda dobrze',
  valueWillBeUpdated: 'Wartość zostanie zaktualizowana przy następnej transmisji z urządzenia',
  VATnumber: 'Numer NIP',
  vatNumberErrorText:
    'Podany numer VAT na platformie Smart Utilities jest błędny w systemie VIES. Sprawdź go i zaktualizuj.',
  VATnumberPlaceholder: 'Wprowadź numer VAT',
  view: 'Pogląd',
  vWeirLabel: 'V-weir',
  waterLevelTooltip: 'Poziom wody',
  waterMeasurementsLabel: 'pomiary wody',
  weAreWorkingOnTheFixing: 'Pracujemy nad rozwiązaniem problemu. Proszę spróbuj ponownie',
  weCantFindThePage: 'Nie możemy znaleźć szukanej strony',
  wereHavingAnIssue: 'Mamy problem, spróbuj jednej z tych opcji, aby wrócić na właściwą ścieżkę',
  wereSorry: `Przepraszamy, ale coś poszło nie tak podczas ładowania tej strony. Spróbuj ponownie`,
  wereSorry2: `Przepraszamy, ale wygląda na to, że nie możemy znaleźć strony, której szukałeś`,
  whatAreYouLookingFor: 'Czego szukasz?',
  wrongFileFormat: 'Nieprawidłowy format pliku. Użyj .csv lub .xlsx',
  yearlyLabel: 'Rocznie',
  yesButton: 'Tak',
};
