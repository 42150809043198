import { ss } from '@cthings.co/utils';

export default ss;

//import { UNIQUE_PREFIX } from '../consts';
//import { LsValueType } from '../enums/LsValueType';

/*const ss: SessionStorage = {
  set: <T = object>(key: string, value: T): void => {
    if (!sessionStorage) {
      return;
    }
    try {
      const serializedValue = JSON.stringify(value);
      sessionStorage.setItem(UNIQUE_PREFIX + key, serializedValue);
    } catch (error) {
      throw new Error('store serialization failed');
    }
  },
  get: <T = object>(key: string): T | undefined => {
    if (!sessionStorage) {
      return;
    }

    try {
      const serializedValue = sessionStorage.getItem(UNIQUE_PREFIX + key);
      if (serializedValue == null) {
        return;
      }
      return JSON.parse(serializedValue);
    } catch (error) {
      throw new Error('store deserialization failed');
    }
  },
  remove: (key: string) => {
    try {
      sessionStorage.removeItem(UNIQUE_PREFIX + key);
    } catch (err) {
      throw new Error("couldn't remove sessionStorage item");
    }
  },
};

export const appendLsArray = (lsKey: LsValueType, value: any, keyIndex: string, valIndex: string) => {
  let current = ss.get(lsKey);
  let index = null;

  if (current) {
    index = current.findIndex((element: any) => element[keyIndex] === value[keyIndex]);
    if (index !== -1) {
      current[index][valIndex] = value[valIndex];
    } else {
      current = [...current, value];
    }
  } else {
    current = [value];
  }

  ss.set(lsKey, current);
};

interface SessionStorage {
  set: Function;
  get: Function;
  remove: Function;
}*/
