import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { ChooseDateMobileModal } from '../chooseDateMobileModal/ChooseDateMobileModal';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 234px;
  height: 32px;
  box-sizing: border-box;
  display: none;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 0 12px;
  &.from {
    margin-right: 8px;
  }
  ${media.phone} {
    display: flex;
  }
`;

export enum DateButtonType {
  FROM = 'FROM',
  TO = 'TO',
}

export const DateButton = ({
  type,
  date,
  setDate,
  maxDate,
  languageStrings,
  previousDate,
  setPreviousDate,
}: {
  type: DateButtonType;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  maxDate: Date;
  previousDate: Date;
  setPreviousDate: React.Dispatch<React.SetStateAction<Date>>;
  languageStrings: any;
}) => {
  const theme = useTheme();
  const [isOpenModal, setIsOpenModal] = useState<boolean>();

  const openModal = () => {
    setIsOpenModal(true);
    setPreviousDate(date);
  };
  const closeModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenModal(false);
    setDate(previousDate);
  };
  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenModal(false);
  };

  return (
    <Wrapper theme={theme} className={type === DateButtonType.FROM ? 'from' : ''} onClick={openModal}>
      <Text type={TextType.TEXT_12_GRAY} margin={'0 12px 0 0'} weight={'500'}>
        {type === DateButtonType.FROM ? 'From:' : 'To:'}
      </Text>
      <Text type={TextType.TEXT_12_BLACK}>{getRequiredDateFormat(date, 'DD MMM yy')}</Text>
      <ChooseDateMobileModal
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        title={type === DateButtonType.FROM ? 'From' : 'To'}
        languageStrings={languageStrings}
        date={date}
        setDate={setDate}
        maxDate={maxDate}
        handleSave={handleSave}
      />
    </Wrapper>
  );
};
