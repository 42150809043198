import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { emptyModal, emptySearch } from './consts';
import { ModalOpen, NotificationCall, SettingsState } from './types';
import { ModalType } from '../../../enums/ModalType';

export const addNotification = (state: WritableDraft<SettingsState>, action: PayloadAction<NotificationCall>) => {
  const key = state.notifications.nextKey + 1;
  state.notifications = {
    nextKey: key,
    list: [{ ...action.payload, key }, ...state.notifications.list],
  };
};
export const removeNotification = (state: WritableDraft<SettingsState>, action: PayloadAction<number>) => {
  state.notifications.list = state.notifications.list.filter((notification) => notification.key !== action.payload);
};
export const openModal = (state: WritableDraft<SettingsState>, action: PayloadAction<ModalOpen>) => {
  document.body.classList.add('blocked');
  const search =
    action.payload.type === ModalType.SEARCH && action.payload.search ? action.payload.search : emptySearch;
  state.modal = {
    ...action.payload,
    status: true,
    windowOffset: window.pageYOffset,
    scrolled: true,
    search,
    redirect: false,
  };
};
export const closeModal = (state: WritableDraft<SettingsState>, action: PayloadAction<boolean | undefined>) => {
  document.body.classList.remove('blocked');

  state.modal = {
    ...emptyModal,
    windowOffset: state.modal.windowOffset,
    scrolled: false,
    type: state.modal.type,
    search: state.modal.search,
    redirect: !!action.payload,
  };
};
export const setScrolled = (state: WritableDraft<SettingsState>, action: PayloadAction<boolean>) => {
  state.modal.scrolled = action.payload;
  state.modal.type = ModalType.NONE;
  state.modal.search = emptySearch;
};
export const setRedirect = (state: WritableDraft<SettingsState>, action: PayloadAction<boolean>) => {
  state.modal.redirect = action.payload;
};

export const reducers = {
  addNotification,
  removeNotification,
  openModal,
  closeModal,
  setScrolled,
  setRedirect,
};
