import React from 'react';
import { useHistory } from '../../../utils/react-router-dom-abstraction';
import { PATHS } from '../../../routes';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';
import { CommonButton } from '../../commonButton/CommonButton';
import { Text, TextType } from '@bit/first-scope.text';
//images
import { ReactComponent as ArrowImage } from '../assets/mini_arrow.svg';
import { ReactComponent as LocationIcon } from '../assets/location-secondary.svg';
import { ReactComponent as SearchCampusMap } from '../assets/search_campus_map.svg';
import { ReactComponent as FloorCampusMap } from '../assets/floors_campus_map.svg';
import { ReactComponent as CampusMap } from '../assets/campus_map.svg';
import mobileArrowButton from '../assets/MobileArrowPrimary.png';
import { useSelector } from 'react-redux';
import { selectDevices } from '../../../app/state/insight';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Content = styled.div`
  flex: 1;
  padding: 45px;
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    padding: 30px 16px 70px;
  }
  ${media.phone} {
    padding: 30px 0 50px;
  }
`;

const TitleWithBackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  ${media.phone} {
    margin-bottom: 10px;
    padding: 0 16px;
  }
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg > path {
    stroke: ${colorFetch('white')};
  }
`;

const MobileArrow = styled.img`
  width: 100%;
  height: 100%;
`;

const TitleRow = styled.div`
  margin-left: 12px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${media.phone} {
    align-items: center;
  }
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  ${media.phone} {
    font-size: 20px;
  }
`;

const LastUpdatedBlock = styled.p`
  /* align-self: flex-end; */
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
  ${media.tablet} {
    display: none;
  }
`;

const Time = styled.span`
  font-weight: 500;
`;

const SearchbarWithLevelsSelectBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.phone} {
    width: 100%;
    flex-direction: column-reverse;
    padding: 0 16px;
  }
`;

const SearchbarWithLevelSelect = styled.div`
  display: flex;
  align-items: center;
  ${media.phone} {
    width: 100%;
    margin-top: 22px;
  }
`;

const SearchBarBlock = styled.div`
  /* width: 480px; */
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  overflow: hidden;
  & > svg {
    width: 100%;
    height: 100%;
  }
  ${media.desktop} {
    width: 340px;
  }
  ${media.phone} {
    width: 100%;
  }
`;

const LevelSelectBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 210px;
  height: 40px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  overflow: hidden;
  & > svg {
    width: 100%;
    height: 100%;
  }
  ${media.tablet} {
    display: none;
  }
`;

const GeoWrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.phone} {
    & > svg {
      display: none;
    }
  }
`;

const FloorContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 244px);
  margin-top: 40px;
  width: 100%;
  align-self: center;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  overflow: hidden;
  & > svg {
    width: 100%;
    height: 100%;
  }
  ${media.tablet} {
    margin-top: 20px;
  }
  ${media.phone} {
    margin-top: 30px;
    flex-direction: column;
    flex: 1;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${colorFetch('gray3')};
    }
  }
`;

export const PlaceholderCampusMap = () => {
  const theme = useTheme();
  const [style] = useStyleContext();
  const history = useHistory();
  const devices = useSelector(selectDevices);
  const { zoom_level_mapbox, centre_coord } = devices;

  return (
    <Wrapper>
      <Content>
        <TitleWithBackButton>
          <CommonButton
            onClick={() => {
              history.push(PATHS.USER_INSIGHTS, {
                lat: centre_coord.lat || 54.5,
                lon: centre_coord.lng || 17.54,
                zoom: zoom_level_mapbox || 5,
              });
            }}
            height="32px"
            width="32px"
            backgroundColor={style.mediaType.phone ? 'transparent' : theme.colors.primary}
            borderColor={style.mediaType.phone ? 'transparent' : theme.colors.primary}
          >
            <ButtonInnerContainer>
              {style.mediaType.phone ? <MobileArrow src={mobileArrowButton} alt="arrow" /> : <ArrowImage />}
            </ButtonInnerContainer>
          </CommonButton>
          <TitleRow>
            <Title>
              <Text type={!style.mediaType.phone ? TextType.TITLE_PRIMARY : TextType.TITLE_H6BOLD}>Assets</Text>{' '}
              <Text type={!style.mediaType.phone ? TextType.TITLE_SECONDARY : TextType.TITLE_H6MOBILE}>
                in Valmont HQ
              </Text>
            </Title>
          </TitleRow>
          <LastUpdatedBlock>
            Last updated time: <Time>N/A</Time>
          </LastUpdatedBlock>
        </TitleWithBackButton>
        <SearchbarWithLevelsSelectBlock>
          <SearchbarWithLevelSelect>
            <SearchBarBlock theme={theme}>
              <SearchCampusMap preserveAspectRatio="none" />
            </SearchBarBlock>
            <LevelSelectBlock theme={theme}>
              <FloorCampusMap preserveAspectRatio="none" />
            </LevelSelectBlock>
          </SearchbarWithLevelSelect>
          <GeoWrapper>
            <LocationIcon />
            <Text type={TextType.HELPER_TEXT_1} color={theme.colors.gray2}>
              Siedlce, mjr. Henryka Sucharskiego, 6
            </Text>
          </GeoWrapper>
        </SearchbarWithLevelsSelectBlock>
        <FloorContainer theme={theme}>
          <CampusMap preserveAspectRatio="xMidYMid slice" />
        </FloorContainer>
      </Content>
    </Wrapper>
  );
};
