import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectFromTo, setFromDevice, setToDevice } from '../../../../../app/state/organisations';
import { selectLanguageStrings } from '../../../../../app/state/user';
import { ReactComponent as DeleteIcon } from '../../../../../assets/Delete_button.svg';
import { CommonButton } from '../../../../../components/commonButton/CommonButton';
import { Loader } from '../../../../../components/placeholders/Loader';
import { Select } from '../../../../../components/universalSelect/Select';
import { SearchInput } from '../../../../../containers/common/reports/components/searchInput/SearchInput';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';
import { ParameterListType } from '../types';
import { ParameterItems } from './ParameterItems';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type ItemsWrapperProps = {
  alignItems: string;
};

const ItemsWrapper = styled.div<ItemsWrapperProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;
  height: 316px;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  margin: 10px 0 24px 0;
  cursor: auto;
  ${media.phone} {
    margin: 16px 0 24px 0;
  }
`;

type InputWrapperProps = {
  isForClearDevices?: boolean;
};

const InputWrapper = styled.div<InputWrapperProps>`
  width: ${({ isForClearDevices }) => (isForClearDevices ? '340px' : '100%')};
  height: 40px;
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  ${media.phone} {
    height: 32px;
  }
`;

const SelectsRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.phone} {
    flex-direction: column;
    gap: 16px 0;
  }
`;

const StyledInput = styled(SearchInput)`
  border-bottom: none;
  ${media.phone} {
    height: 32px;
  }
`;

const FromToSelect = styled(Select)`
  width: 248px;

  ${media.phone} {
    width: 100%;
  }
` as any; // @TODO fix type

const ClearButton = styled(CommonButton)`
  width: 156px;
  height: 40px;
  margin: 0 0 0 20px;

  ${media.phone} {
    height: 32px;
    margin: 0 0 0 10px;
  }
`;

const ClearText = styled(Text)`
  line-height: 16px;
  margin: 0 0 0 4px;
`;

type ParameterListProps = {
  isListEmpty: boolean;
};

const ParameterListWrapper = styled.div<ParameterListProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${({ isListEmpty }) => (!isListEmpty ? 'center' : 'initial')};
  box-sizing: border-box;
  padding: 1px 4px 1px 14px;
  position: relative;

  ${media.phone} {
    padding: 12px;
  }
`;

interface SelectDevicesProps {
  devices: ParameterListType[];
  handleDeviceSelect: (item: ParameterListType) => void;
  isLoading: boolean;
  title?: string;
  isRange?: boolean;
  isIndividual?: boolean;
  isSelected?: boolean;
  isClearDevices?: boolean;
  onClearDevices?: (value: ParameterListType) => void;
  showDevicesWIthoutPlanset: boolean;
}

export const SelectDevices: FC<SelectDevicesProps> = ({
  devices,
  handleDeviceSelect,
  title,
  isIndividual,
  isSelected,
  isRange,
  isLoading,
  isClearDevices,
  onClearDevices,
  showDevicesWIthoutPlanset,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const listRef = useRef<any>(null);
  const { gray2, pureWhite } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [heightOfList, setHeightOfList] = useState(0);

  const [
    {
      mediaType: { phone },
    },
  ] = useStyleContext();

  const [openModal, setModalOpen] = useState(false);
  const { fromDevice, toDevice } = useSelector(selectFromTo);
  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    isSelected && searchInputValue && setSearchInputValue('');
  }, [isSelected]);

  const handleInutOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  const handleSelect = useCallback(
    (item: ParameterListType) => {
      handleDeviceSelect(item);
    },
    [handleDeviceSelect],
  );

  useEffect(() => {
    listRef && listRef.current && setHeightOfList(listRef.current.clientHeight);
  }, []);

  const values =
    searchInputValue === ''
      ? devices
      : devices.filter((el) => el.name?.toLowerCase().includes(searchInputValue.toLowerCase()));

  return (
    <>
      {isIndividual && (
        <InputWrapper>
          <StyledInput
            value={searchInputValue}
            // @TODO fix type
            handleOnChange={handleInutOnChange as any}
            handleClearInput={handleClearInput}
            languageStrings={languageStrings}
          />
        </InputWrapper>
      )}
      {isRange && (
        <SelectsRow>
          <FromToSelect
            label={languageStrings.generateReportModalPeriodBorderFrom}
            placeholder={languageStrings.chooseDeviceNamePlaceholder}
            value={fromDevice}
            values={devices}
            onChange={(value: any) => {
              dispatch(setFromDevice(value));
            }}
            languageStrings={languageStrings}
          />
          <FromToSelect
            label={languageStrings.generateReportModalPeriodBorderTo}
            placeholder={languageStrings.chooseDeviceNamePlaceholder}
            value={toDevice}
            values={devices}
            onChange={(value: any) => {
              dispatch(setToDevice(value));
            }}
            languageStrings={languageStrings}
          />
        </SelectsRow>
      )}
      {isClearDevices && (
        <div style={{ display: 'flex' }}>
          <InputWrapper isForClearDevices>
            <StyledInput
              value={searchInputValue}
              // @TODO fix type
              handleOnChange={handleInutOnChange as any}
              handleClearInput={handleClearInput}
              languageStrings={languageStrings}
            />
          </InputWrapper>
          <ClearButton
            borderColor={'#c7c9d9'}
            backgroundColorHover={pureWhite}
            backgroundColor={pureWhite}
            onClick={onClearDevices}
          >
            <DeleteIcon />
            <ClearText type={TextType.TEXT_14_BLACK} color={gray2}>
              {phone ? languageStrings.clearButtonMobile : languageStrings.clearButton}
            </ClearText>
          </ClearButton>
        </div>
      )}
      <ItemsWrapper
        theme={theme}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        title={title}
        alignItems={!devices.length ? 'center' : ''}
      >
        <ParameterListWrapper isListEmpty={!!values.length}>
          {isLoading && <Loader />}
          {values.length ? (
            <ParameterItems
              values={values}
              devices={devices}
              isSelectedTab={isSelected || false}
              listRef={listRef}
              heightOfList={heightOfList}
              openModal={openModal}
              handleSelect={handleSelect}
              setModalOpen={setModalOpen}
              showDevicesWIthoutPlanset={showDevicesWIthoutPlanset}
            />
          ) : (
            <>
              <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
                {languageStrings.placeholderNoData}
              </Text>
            </>
          )}
        </ParameterListWrapper>
      </ItemsWrapper>
    </>
  );
};
