import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { InputModal } from '../../../../inputModal/InputModal';
import { MINtoHHMM } from '../../../../../utils/date/functions';
import { TableInputType } from '../../../TableInput';
import { InputWrapper } from '../../inputWrapper/InputWrapper';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled(InputWrapper)`
  flex-direction: row;
  align-items: center;
  ${media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

type StyledInputProps = {
  width?: string;
};

const StyledInput = styled(InputModal)<StyledInputProps>`
  width: auto;
  margin: 0 6px 0 0;
  text-align: center;
  ${media.phone} {
    width: 100%;
    margin: 0;
  }
  & div:nth-child(2) {
    min-width: 40px;
    width: ${({ width }) => width} !important;
    height: 32px;
    margin: 0;
    transition: all 0.3s linear;
    ${media.phone} {
      min-width: 100%;
      height: 40px;
    }
  }
  & div > input {
    height: inherit;
    padding: 4px 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    ${media.phone} {
      text-align: left;
    }
  }
` as any; // @TODO fix type

const StyledText = styled(Text)`
  word-break: break-all;
`;

const Unit = styled(Text)`
  ${media.phone} {
    display: none;
  }
`;

export const TimePickerUnit = ({
  value,
  onChange,
  unit,
  type,
  isEditInProcess,
  options,
  ...props
}: {
  value: number[];
  onChange: Function;
  unit: string | boolean;
  type: TableInputType;
  options: any;
  isEditInProcess?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const time =
    type === TableInputType.TIME_PICKER_UNIT_HHMM
      ? `${value[1]}:${value[2]}`
      : type === TableInputType.TIME_PICKER_UNIT_MMSS
      ? `${value[2]}`
      : '';

  const { title, min } = options;
  //TODO @IgorZd Logic for changing HH:MM
  const handleOnChange = (e: any, index: number) => {
    const currentValue = +e.currentTarget.value;
    const preparedValue = value.map((item: number, i: number) => (i === index ? currentValue : item));
    !isNaN(+currentValue) && `${currentValue}`.length <= 5 && onChange(preparedValue);
  };

  const onBlur = () => {
    if (type === TableInputType.TIME_PICKER_UNIT_MMSS) {
      if (+value[2] <= min[2]) {
        onChange(min);
      } else {
        const preparedValue = value.map((item: number, index: number) =>
          index === 2 ? +MINtoHHMM(`${value[2]}`)[1] : item,
        );
        onChange(preparedValue);
      }
    }
    // switch (value.length) {
    //   case 1:
    //   case 2:
    //     if (value.slice(0, value.length - 2).length === 3 && value.slice(0, 1) === '0') {
    //       onChange(value.substring(1, value.length - 2));
    //     }
    //     onChange(SECtoMMSS(value));
    //     break;
    //   case 3:
    //     if (+value.slice(-2) < 60) {
    //       onChange('0' + value);
    //     } else {
    //       onChange(SECtoMMSS(value));
    //     }
    //     break;
    //   default:
    //     if (+value.slice(-2) < 60) {
    //       if (value.slice(0, value.length).length === 5 && value.slice(0, 1) === '0') {
    //         onChange(value.slice(1, value.length));
    //       } else {
    //         onChange(value);
    //       }
    //     } else {
    //       onChange(SECtoMMSS(value));
    //     }
    // }
    // setIsEdit(false);
  };

  return (
    <Wrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      {isEditInProcess ? (
        <>
          <StyledInput
            value={time}
            onBlur={onBlur}
            onClick={(e: any) => e.target.select()}
            onKeyDown={(e: any) => {
              if (+e.keyCode === 13) {
                e.target.blur();
              }
            }}
            onChange={(e: any) => {
              handleOnChange(e, 2);
            }}
            width={`${22 * `${time}`.length}px`}
            isEditInProcess={isEditInProcess}
          />
          <Unit color={gray2} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
            {unit}
          </Unit>
        </>
      ) : (
        <>
          <StyledText color={gray1} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
            {time + ' ' + unit}
          </StyledText>
        </>
      )}
    </Wrapper>
  );
};
