import React, { FC } from 'react';
import { ExportButtonWrapper, ExportText, StyledDotsIcon } from './styled';
import { ReactComponent as UploadIcon } from '../../assets/Upload-icon.svg';
import { LanguageStrings } from '../../languages/types';

type ExportButtonProps = {
  languageStrings: LanguageStrings;
  onClick?: () => void;
  isDotsIcon?: boolean;
  link?: string;
};

export const ExportButton: FC<ExportButtonProps> = ({ onClick, languageStrings, isDotsIcon, link }) => {
  return (
    <ExportButtonWrapper href={link} target="_blank" onClick={onClick}>
      <UploadIcon />
      <ExportText>{languageStrings.exportButton}</ExportText>
      {isDotsIcon && <StyledDotsIcon />}
    </ExportButtonWrapper>
  );
};
