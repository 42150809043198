import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { InputModal } from '../../../../inputModal/InputModal';
import { MINtoHHMM } from '../../../../../utils/date/functions';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1 1;
  align-items: center;
  position: relative;
  margin-right: 22px;
  ${media.semiTablet} {
    flex-direction: column;
  }
  ${media.phone} {
    flex-direction: row;
    margin-right: 0;
  }
`;

const StyledInput = styled(InputModal)`
  width: 65px;
  margin: 0;
  ${media.semiTablet} {
    width: 100%;
  }
  & div {
    margin: 0;
  }
  & div > input {
    height: 32px;
    padding: 4px 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    ${media.semiTablet} {
      height: 40px;
    }
  }
` as any; // @TODO fix type

const Separator = styled.span`
  display: inline-block;
  margin: 0 5px;
`;

const StyledText = styled(Text)`
  word-break: break-all;
`;

export const TimePickerRange = ({
  value,
  onChange,
  isEditInProcess,
}: {
  value: string;
  onChange: Function;
  isEditInProcess?: boolean;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const [oldValue, setOldValue] = useState('0000');

  const [startTime, setStartTime] = useState('0000');
  const [isEditStart, setIsEditStart] = useState(false);

  const [endTime, setEndTime] = useState('0000');
  const [isEditEnd, setIsEditEnd] = useState(false);

  useEffect(() => {
    const now: any = new Date();
    const mockDate = [
      `${getRequiredDateFormat(now, 'HHmm')}`,
      `${getRequiredDateFormat(now.setHours(now.getHours() + 1), 'HHmm')}`,
    ];

    setStartTime(value[0] ?? mockDate[0]);
    setEndTime(value[1] ?? mockDate[1]);
  }, [value]);

  const onChangeTime = (e: any, type: string) => {
    type === 'start' && setStartTime(e.currentTarget.value);
    type === 'end' && setEndTime(e.currentTarget.value);
  };

  const validationTime = (val: string, setVal: (el: string) => void) => {
    switch (val.length) {
      case 0:
        setVal(oldValue);
        break;
      case 1:
        setVal(`0${val}00`);
        break;
      case 2:
        if (+val < 24) {
          setVal(`${val}00`);
        }
        if (+val === 24) {
          setVal(`0000`);
        }
        if (+val > 24) {
          setVal(`0${val[0]}0${val[1]}`);
        }
        break;
      case 3:
        if (+val.slice(-2) < 60) {
          setVal(`0${val}`);
        } else {
          setVal(
            `${
              (+val.slice(0, 1) + +MINtoHHMM(val.slice(-2)).slice(0, 2) + '').length === 1
                ? `0${
                    +val.slice(0, 1) + +MINtoHHMM(val.slice(-2)).slice(0, 2) + '' + +MINtoHHMM(val.slice(-2)).slice(-2)
                  }`
                : +val.slice(0, 1) + +MINtoHHMM(val.slice(-2)).slice(0, 2) + '' + +MINtoHHMM(val.slice(-2)).slice(-2)
            }`,
          );
        }
        break;
      case 4:
        if (+val.slice(0, 2) >= 24) {
          setVal(oldValue);
        }
        if (+val.slice(0, 2) < 24 && +val.slice(-2) < 60) {
          setVal(`${val}`);
        }
        if (+val.slice(0, 2) < 24 && +val.slice(-2) >= 60) {
          setVal(
            `${
              (((+val.slice(0, 2) + +MINtoHHMM(val.slice(-2)).slice(0, 2)) % 24) + '').length === 1
                ? `0${(+val.slice(0, 2) + +MINtoHHMM(val.slice(-2)).slice(0, 2)) % 24}`
                : (+val.slice(0, 2) + +MINtoHHMM(val.slice(-2)).slice(0, 2)) % 24
            }${MINtoHHMM(val.slice(-2)).slice(-2)}`,
          );
        }
        break;
      default:
        setVal(oldValue);
    }
  };

  const onBlur = (type: string) => {
    if (type === 'start') {
      validationTime(startTime, setStartTime);
      setIsEditStart(false);
    }
    if (type === 'end') {
      validationTime(endTime, setEndTime);
      setIsEditEnd(false);
    }
  };

  const styledInput = (value: string, type: string) => (
    <StyledInput
      value={
        (type === 'start' && isEditStart) || (type === 'end' && isEditEnd)
          ? value
          : value.slice(0, 2) + ':' + value.slice(-2)
      }
      onFocus={() => {
        setOldValue(value);
        if (type === 'start') {
          setIsEditStart(true);
        }
        if (type === 'end') {
          setIsEditEnd(true);
        }
      }}
      onBlur={() => onBlur(type)}
      onClick={(e: any) => e.target.select()}
      onKeyDown={(e: any) => +e.keyCode === 13 && e.target.blur()}
      onChange={(e: any) => {
        !isNaN(+e.currentTarget.value) && e.currentTarget.value.length <= 5 && onChangeTime(e, type);
      }}
    />
  );

  return (
    <Wrapper>
      {isEditInProcess ? (
        <>
          {styledInput(startTime, 'start')}
          <Separator> - </Separator>
          {styledInput(endTime, 'end')}
        </>
      ) : (
        <>
          <StyledText color={gray1} type={TextType.TEXT_14_GRAY} weight={'500'} lineHeight={'24px'}>
            {`${startTime.slice(0, 2) + ':' + startTime.slice(-2)} - ${endTime.slice(0, 2) + ':' + endTime.slice(-2)}`}
          </StyledText>
        </>
      )}
    </Wrapper>
  );
};
