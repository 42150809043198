import { PayloadAction } from '@reduxjs/toolkit';
import { DevicesTableState } from './types';
import { WritableDraft } from 'immer/dist/internal';

export const setExportModalState = (state: WritableDraft<DevicesTableState>, action: PayloadAction<boolean>) => {
  state.isExportModalOpen = action.payload;
};

export const reducers = {
  setExportModalState,
};
