import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { PlaceholderBlock } from './placeholderBlock';

const Row = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 25px;
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
  height: 80px;
  & > div:nth-child(1) {
    align-self: end;
  }
  & > div:nth-child(2) {
    align-self: end;
  }
  & > div:nth-child(3) {
    align-self: end;
  }
`;

export interface ComponentProps {}

export const TableRow: FC<ComponentProps> = () => {
  return (
    <Row>
      <PlaceholderBlock width={'140px'} />
      <PlaceholderBlock width={'170px'} />
      <PlaceholderBlock width={'600px'} />
      <PlaceholderBlock width={'60px'} />
      <PlaceholderBlock width={'210px'} />
      <PlaceholderBlock width={'410px'} />
    </Row>
  );
};
