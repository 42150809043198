import React from 'react';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Modal } from '@bit/first-scope.modal';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../../../utils/date/date-format';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import styled, { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 528px !important;
  align-items: flex-start !important;
  padding: 24px !important;
  ${media.phone} {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    border-radius: 16px 16px 0 0 !important;
  } ;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('gray5')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 8px 14px 32px 14px;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.phone} {
    & > button {
      width: 100%;
      height: 40px;
    }
  }
`;

export const NoteModal = ({
  isOpenModal,
  data,
  closeNoteModal,
  languageStrings,
}: {
  isOpenModal: boolean;
  data: { ts: string; desc: string; text: string };
  closeNoteModal: () => void;
  languageStrings?: any;
}) => {
  const theme = useTheme();

  const { ts, desc, text } = data;
  const { black1, gray1 } = theme.colors;

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Text type={TextType.SEMITITLE} fontSize={'18px'} weight={'700'} margin={'0 0 18px 0'} color={black1}>
          {`${getRequiredDateFormat(ts, 'DD.MM.yyyy, HH:mm')} - ${desc}`}
        </Text>
        <DescriptionWrapper>
          <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
            {text}
          </Text>
        </DescriptionWrapper>
        <ButtonWrapper>
          <CommonButton onClick={closeNoteModal} width={'104px'}>
            {languageStrings.closeButtonValue}
          </CommonButton>
        </ButtonWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
