import React from 'react';
import { FC } from 'react';
import {
  StyledText,
  TextWrapper,
  StyledInfoText,
  GotItButton,
  ButtonWrapper,
  OrderedList,
  InfoListText,
} from './styled';
import { TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { CustomModal } from '../customModal/CustomModal';
import { useTheme } from '@cthings.co/styled-components';

export interface FailureReasonsModalProps {
  isOpenModal: boolean;
  onGotItButtonClick: () => void;
  className?: string;
}

export const FailureReasonsModal: FC<FailureReasonsModalProps> = ({ isOpenModal = true, onGotItButtonClick }) => {
  const theme = useTheme();
  const { black1, gray1, pureWhite, primary } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  const headerText = languageStrings.failureReasonsModalHeader;
  const infoText = languageStrings.failureReasonsModalInfo;
  const listOfText = [
    {
      reason: languageStrings.failureReasonsModalReasonFirst,
      suggestions: languageStrings.failureReasonsModalSuggestionFirst,
    },
    {
      reason: languageStrings.failureReasonsModalReasonSecond,
      suggestions: languageStrings.failureReasonsModalSuggestionSecond,
    },
    {
      reason: languageStrings.failureReasonsModalReasonThird,
      suggestions: languageStrings.failureReasonsModalSuggestionThird,
    },
    {
      reason: languageStrings.failureReasonsModalReasonFourth,
      suggestions: languageStrings.failureReasonsModalSuggestionFourth,
    },
  ];

  return (
    <CustomModal isOpen={isOpenModal}>
      <TextWrapper>
        <StyledText type={TextType.TEXT_12_BLACK} color={black1}>
          {headerText}
        </StyledText>
        <StyledInfoText type={TextType.TEXT_12_BLACK} color={gray1}>
          {infoText}
        </StyledInfoText>
        <OrderedList>
          {listOfText.map((value: any, index: number) => (
            <InfoListText key={index}>
              {value.reason + ' '}
              <StyledInfoText type={TextType.TEXT_12_BLACK} color={gray1}>
                {value.suggestions}
              </StyledInfoText>
            </InfoListText>
          ))}
        </OrderedList>
      </TextWrapper>
      <ButtonWrapper>
        <GotItButton
          onClick={onGotItButtonClick}
          colors={{ main: black1, background: pureWhite, backgroundHover: primary, borderHover: primary }}
        >
          {languageStrings.gotIt}
        </GotItButton>
      </ButtonWrapper>
    </CustomModal>
  );
};
