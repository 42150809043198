import { useTheme } from '@cthings.co/styled-components';
import { stringifyUrl } from 'query-string';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccess, selectLanguageStrings } from '../../app/state/user';
import { ReactComponent as SecondErrorImage404 } from '../../assets/404_with_shadow.svg';
import { ReactComponent as FirstErrorImage404 } from '../../assets/PageNotFoundIllustration.svg';
import { ReactComponent as FifthError401Image } from '../../assets/error_401_manhole.svg';
import { ReactComponent as FifthError403Image } from '../../assets/error_403_manhole.svg';
import { ReactComponent as FifthError404Image } from '../../assets/error_404_manhole.svg';
import { ReactComponent as FirstErrorImage500 } from '../../assets/error_500_1.svg';
import { ReactComponent as SecondErrorImage500 } from '../../assets/error_500_2.svg';
import { ReactComponent as FifthError500Image } from '../../assets/error_500_5.svg';
import { LsValueType } from '../../enums/LsValueType';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import { PATHS } from '../../routes';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import ss from '../../utils/ss';

export const useContentForErrorPages = () => {
  const theme = useTheme();
  const { pureWhite, secondary2, black1, primary } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  const isAccess = useSelector(selectAccess).dashboard;

  const history = useHistory();

  const toMainOnClick = () => {
    history.push(stringifyUrl({ url: PATHS.REDIRECT }));
  };

  const reloadOnClick = () => {
    history.goBack();
  };

  const backOnClick = () => {
    history.goBack();
  };

  const logoutOnClick = (e: any) => {
    e.preventDefault();
    ss.remove(LsValueType.token);
    oauthRedirect();
  };

  const supportOnClick = () => {};

  const fifthVersionErrorPage500 = () => {
    return {
      image: <FifthError500Image />,
      title: languageStrings.errorPage500Title,
      description: languageStrings.errorPage500Description,
      buttons: [
        {
          onClick: reloadOnClick,
          value: languageStrings.errorPageReload,
        },
        {
          onClick: toMainOnClick,
          value: languageStrings.errorPageMainPage,
          backgroundColor: pureWhite,
          backgroundColorHover: secondary2,
          color: black1,
        },
      ],
    };
  };

  const fifthVersionErrorPage404 = () => {
    return {
      image: <FifthError404Image />,
      title: languageStrings.errorPage404Title,
      description: languageStrings.errorPage404Description,
      buttons: [
        {
          onClick: toMainOnClick,
          value: languageStrings.errorPageMainPage,
          backgroundColor: primary,
          backgroundColorHover: secondary2,
          color: pureWhite,
        },
      ],
    };
  };

  const fifthVersionErrorPage403 = () => {
    return {
      image: <FifthError403Image />,
      title: languageStrings.errorPage403Title,
      description: `${languageStrings.errorPage403Description} ${languageStrings.billingDetailsNotFilledDesc}`,
      buttons: [
        {
          onClick: logoutOnClick,
          value: languageStrings.navigationLogout,
        },
        {
          onClick: toMainOnClick,
          value: languageStrings.errorPageMainPage,
          backgroundColor: pureWhite,
          backgroundColorHover: secondary2,
          color: black1,
        },
      ],
    };
  };

  const fifthVersionErrorPage401 = () => {
    return {
      image: <FifthError401Image />,
      title: languageStrings.errorPage401Title,
      description: `${languageStrings.errorPage401Description} ${languageStrings.billingDetailsNotFilledDesc}`,
      buttons: [
        {
          onClick: toMainOnClick,
          value: languageStrings.errorPageMainPage,
          backgroundColor: primary,
          backgroundColorHover: secondary2,
          color: pureWhite,
        },
      ],
    };
  };

  const fourthVersionErrorPage500 = () => {
    return {
      buttons: [
        {
          onClick: backOnClick,
          value: languageStrings.goBack,
        },
        {
          onClick: toMainOnClick,
          value: languageStrings.goToMain,
          backgroundColor: pureWhite,
          backgroundColorHover: primary,
          color: black1,
        },
      ],
    };
  };

  const fourthVersionErrorPage404 = () => {
    return {
      buttons: [
        {
          onClick: backOnClick,
          value: languageStrings.goBack,
        },
      ],
    };
  };

  const thirdVersionErrorPage500 = () => {
    return {
      title: '500',
      description: `${languageStrings.somethingWentWrong}...`,
      secondDescription: languageStrings.wereSorry,
      supportText: {
        value: languageStrings.contactSupport,
        onClick: supportOnClick,
      },
      buttons: [
        {
          onClick: backOnClick,
          value: languageStrings.backButton,
        },
        {
          onClick: toMainOnClick,
          value: languageStrings.goToMain,
          backgroundColor: pureWhite,
          backgroundColorHover: primary,
          color: black1,
        },
      ],
    };
  };

  const thirdVersionErrorPage404 = () => {
    return {
      title: '404',
      description: languageStrings.pageNotFound,
      secondDescription: languageStrings.wereSorry2,
      buttons: [
        {
          onClick: toMainOnClick,
          value: languageStrings.goToMain,
          backgroundColor: primary,
          backgroundColorHover: primary,
          color: pureWhite,
        },
      ],
    };
  };

  const secondVersionErrorPage500 = () => {
    return {
      image: <SecondErrorImage500 />,
      title: languageStrings.somethingWentWrong,
      description: languageStrings.wereHavingAnIssue,
      buttons: [
        {
          onClick: reloadOnClick,
          value: languageStrings.reload,
        },
        {
          onClick: backOnClick,
          value: languageStrings.backHome,
          width: '157px',
          height: '40px',
          color: black1,
          backgroundColor: pureWhite,
          backgroundColorHover: primary,
        },
      ],
    };
  };

  const secondVersionErrorPage404 = () => {
    return {
      image: <SecondErrorImage404 />,
      title: languageStrings.pageNotFound,
      description: languageStrings.weCantFindThePage,
      buttons: [
        {
          onClick: backOnClick,
          value: languageStrings.backHome,
          width: '112px',
          height: '32px',
          color: black1,
          backgroundColor: pureWhite,
          backgroundColorHover: primary,
        },
      ],
    };
  };

  const firstVersionErrorPage500 = () => {
    return {
      image: <FirstErrorImage500 />,
      title: languageStrings.somethingWentWrong,
      description: languageStrings.weAreWorkingOnTheFixing,
      buttons: [
        {
          onClick: reloadOnClick,
          value: languageStrings.reload,
        },
        {
          onClick: backOnClick,
          value: languageStrings.goToMain,
          width: '112px',
          height: '32px',
          color: black1,
          backgroundColor: pureWhite,
        },
      ],
    };
  };

  const firstVersionErrorPage404 = () => {
    return {
      image: <FirstErrorImage404 />,
      title: languageStrings.oops,
      description: languageStrings.sorryWeCouldNotFindThePage,
      buttons: [
        {
          onClick: backOnClick,
          value: languageStrings.goToMain,
          width: '112px',
          height: '32px',
          color: pureWhite,
          backgroundColor: primary,
        },
      ],
    };
  };

  return {
    fifthVersionErrorPage500,
    fifthVersionErrorPage404,
    fifthVersionErrorPage403,
    fifthVersionErrorPage401,
    fourthVersionErrorPage500,
    fourthVersionErrorPage404,
    thirdVersionErrorPage500,
    thirdVersionErrorPage404,
    secondVersionErrorPage500,
    secondVersionErrorPage404,
    firstVersionErrorPage500,
    firstVersionErrorPage404,
  };
};
