import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import React, { FC, useState } from 'react';
import { InputModal } from '../../../../components/inputModal/InputModal';
import { Textarea } from '../../../../components/textarea/Textarea';
import { media } from '@cthings.co/styles-utils';
import { RequestBlock } from '../requestedBlock/RequestedBlock';
import { SelectAddress } from '../../../../components/selectAddress/SelectAddress';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { TableInputType } from '../../types';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  height: max-content;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTextarea = styled(Textarea)`
  flex: 1;
  width: calc(100% - 18px);
`;

const StyledInput = styled(InputModal)`
  margin: 0 0 12px 0;
  &.last {
    margin: 0;
  }

  ${media.tablet} {
    width: 100%;
  }
`;

const StyledText = styled(Text)`
  flex: 1;
  word-break: break-all;
`;

interface AddressProps {
  value: {
    type: TableInputType;
    actual_value: any;
    requested_value: any;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  isEditInProcess?: boolean;
  isError?: boolean;
  placeholder?: string;
  saveToggle?: boolean;
  handleCancel?: Function;
  handleConfirm?: Function;
}

export const Address: FC<AddressProps> = ({
  value,
  state,
  setField,
  isEditInProcess,
  saveToggle,
  isError,
  placeholder,
  handleCancel,
  handleConfirm,
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const { actual_value, requested_value, with_requested, is_editable } = value;

  const languageStrings = useSelector(selectLanguageStrings);

  const [externalState, setExternalState] = useState(isEditInProcess);

  return (
    <Wrapper>
      {is_editable && isEditInProcess && (
        <SelectAddress
          state={state}
          address={actual_value}
          titleOfPopup={languageStrings.organisationAddress}
          setValue={(actual_value: any) => setField({ ...value, actual_value })}
          saveToggle={!!saveToggle}
          externalState={externalState}
          setExternalState={setExternalState}
          isModalTriggered={isEditInProcess}
          isInUniversalTable={false}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
      <StyledText type={TextType.TEXT_14_GRAY} color={gray1}>
        {actual_value.city ? `${actual_value.city}${actual_value.line1 && ','} ${actual_value.line1}` : 'N/A'}
      </StyledText>
      {with_requested && (
        <RequestBlock
          requested_value={
            requested_value.city && requested_value.line1
              ? `${requested_value.city}${requested_value.line1 && ','} ${requested_value.line1}`
              : 'N/A'
          }
        />
      )}
    </Wrapper>
  );
};
