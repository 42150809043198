import React, { useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { ChoosePeriod } from '../choosePeriod/ChoosePeriod';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
// import { LocationState, ParameterState } from '../../../../../app/state/generateReportModalInterfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearLocation,
  LocationState,
  ParameterState,
  SelectIsOpenParameterPopup,
  SelectIsValidatingStarted,
  setDates,
  toggleIsOpenPopup,
  toggleIsSelectedAllParameter,
  toggleIsSelectedParameter,
} from '../../../../../app/state/generateReportModal';
import { CloseButtonRound } from '../closeButtonRound/CloseButtonRound';
import { useBlockBodyScroll } from '../../../../../utils/useBlockBodyScroll';
import { TableInput, TableInputType } from '../../../../../components/tableInput/TableInput';
import { useMediaType } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  ${media.semiTablet} {
    align-items: flex-end !important;
    & > div {
      width: 100% !important;
      height: calc(100% - 70px) !important;
      align-items: flex-end !important;
    }
  }
`;

const StyledContainer = styled(ModalContainer)`
  width: 564px !important;
  align-items: flex-start !important;
  padding: 24px !important;
  ${media.semiTablet} {
    width: 100% !important;
    border-radius: 24px 24px 0 0 !important;
    padding: 16px !important;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: none;
  }
  ${media.semiTablet} {
    & > div {
      display: flex;
    }
  }
`;

type ScrolledWrapperProps = {
  isOpenParameterPopup?: boolean;
  isScrollable: boolean;
  isAllFieldsIncluded: boolean;
};

// @TODO fix media types
const ScrolledWrapper = styled.div<ScrolledWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 520px;
  min-height: ${({ isOpenParameterPopup }) => (isOpenParameterPopup ? '300px' : 'auto')};
  border-radius: 8px;
  overflow-y: ${({ isScrollable }) => (isScrollable ? 'auto' : 'visible')};
  padding-right: 4px;
  transition: min-height 0.3s linear;
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  ${media.semiTablet} {
    height: 100%;
    min-height: ${({ isAllFieldsIncluded, isOpenParameterPopup }: any) =>
      isAllFieldsIncluded ? '338px' : isOpenParameterPopup ? '338px' : 'auto'};
    max-height: 454px;
    border-radius: ${({ theme }: any) => theme.borderRadius.primary};
  }
`;

const StyledText = styled(Text)`
  font-size: 18px !important;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.semiTablet} {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  width: max-content;
  margin-top: 10px;
  &:first-child {
    margin-right: 16px;
  }
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
    &:first-child {
      margin: 10px 0 0 0;
    }
  }
`;

const StyledButton = styled(CommonButton)`
  width: 104px;

  ${media.semiTablet} {
    width: 100%;
    height: 100%;
  }
`;

export enum TypeOfParameter {
  LOCATION = 'LOCATION',
  CUSTOMER = 'CUSTOMER',
  RECIPIENTS = 'RECIPIENTS',
}

export const GenerateReportModal = ({
  isOpenModal,
  closeModal,
  generateOnClick,
  location,
  customer,
  recipients,
  languageStrings,
  locationId,
  isShowCustomerAndLocationFields = true,
  isShowRecipientsBlock = true,
}: {
  isOpenModal?: boolean;
  closeModal: () => void;
  generateOnClick: () => void;
  location?: LocationState;
  customer?: ParameterState;
  recipients?: ParameterState;
  languageStrings: any;
  locationId?: string;
  isShowCustomerAndLocationFields?: boolean;
  isShowRecipientsBlock?: boolean;
}) => {
  const [selectedIndexOfPeriod, setSelectedIndexOfPeriod] = useState<number>(0);
  const [isScrollableModal, setIsScrollableModal] = useState(true);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pureWhite, black1 } = theme.colors;
  const isOpenParameterPopup = useSelector(SelectIsOpenParameterPopup);
  const isValidatingStarted = useSelector(SelectIsValidatingStarted);

  const mediaType = useMediaType();

  const parameterArr = (type: TypeOfParameter) => {
    return type === TypeOfParameter.CUSTOMER
      ? customer?.parameters
      : type === TypeOfParameter.LOCATION
      ? location?.parameters
      : type === TypeOfParameter.RECIPIENTS
      ? recipients?.parameters
      : [];
  };
  const filteredArr = (type: TypeOfParameter) => {
    const arr = parameterArr(type) as any[];
    return arr && arr.filter((item: any) => item.isSelected === true);
  };

  const handleCheckedItem = (type: TypeOfParameter, id: string) => {
    dispatch(toggleIsSelectedParameter({ type, id }));
  };
  const handleSelectAll = (type: TypeOfParameter) => {
    dispatch(toggleIsSelectedAllParameter({ type }));
  };
  const handleRemoveSelectedItem = (type: TypeOfParameter, item: any) => {
    const index = parameterArr(type)!.indexOf(item);
    const id = parameterArr(type)?.[index]?.id;

    dispatch(toggleIsSelectedParameter({ type, id }));
  };
  const handlePopupOpenClose = (type: TypeOfParameter, value?: boolean) => {
    dispatch(toggleIsOpenPopup({ type, value }));
  };
  const handleSetDates = (startDate: string, endDate: string) => {
    dispatch(setDates({ startDate, endDate }));
  };
  const handleCloseModal = () => {
    closeModal();
    setSelectedIndexOfPeriod(0);
    handlePopupOpenClose(TypeOfParameter.CUSTOMER, false);
    handlePopupOpenClose(TypeOfParameter.LOCATION, false);
    handlePopupOpenClose(TypeOfParameter.RECIPIENTS, false);
  };

  const handleGenerateReport = () => {
    generateOnClick();
    setSelectedIndexOfPeriod(0);
    handlePopupOpenClose(TypeOfParameter.CUSTOMER, false);
    handlePopupOpenClose(TypeOfParameter.LOCATION, false);
    isShowRecipientsBlock && handlePopupOpenClose(TypeOfParameter.RECIPIENTS, false);
  };

  const wrapperRef = useRef<any>(null);

  useEffect(() => {
    if (isOpenModal) {
      const type = TypeOfParameter.LOCATION;
      const id = locationId;
      locationId && dispatch(toggleIsSelectedParameter({ type, id }));
    }
  }, [isOpenModal]);
  useEffect(() => {
    if (customer?.selected_parameters.length === 0) {
      dispatch(clearLocation({ locations: location?.parameters.map((item: any) => ({ ...item, isSelected: false })) }));
    }
  }, [customer?.selected_parameters]);

  useBlockBodyScroll(!!isOpenModal);

  if (isOpenModal) {
    return (
      <StyledModal isOpenModal={isOpenModal}>
        <StyledContainer>
          <TitleWrapper>
            <StyledText type={TextType.TITLE_PRIMARY}>{languageStrings.generateReports}</StyledText>
            <CloseButtonRound closeOnClick={handleCloseModal} />
          </TitleWrapper>
          <ScrolledWrapper
            ref={wrapperRef}
            theme={theme}
            isScrollable={isScrollableModal}
            isOpenParameterPopup={isOpenParameterPopup}
            isAllFieldsIncluded={!!location && !!customer}
          >
            <ChoosePeriod
              languageStrings={languageStrings}
              handleSetDates={handleSetDates}
              selectedIndex={selectedIndexOfPeriod}
              setSelectedIndex={setSelectedIndexOfPeriod}
              setIsScrollableModal={setIsScrollableModal}
            />
            {isShowCustomerAndLocationFields && (
              <>
                <TableInput
                  type={TableInputType.DROPDOWN_MULTI_CHOICE}
                  value={filteredArr(TypeOfParameter.CUSTOMER)}
                  handleChangeValue={() => {}}
                  valuesList={customer?.parameters}
                  options={{
                    placeholder: languageStrings.generateReportModalParameterCustomerPlaceholder,
                    title: languageStrings.navigationClients,
                    isValidatingStarted,
                    isError: customer?.isError && isValidatingStarted,
                    isOpenSelectList: customer?.isOpenPopup,
                    isSelectAll: customer?.isSelectAll,
                    selectedIndexOfPeriod: selectedIndexOfPeriod,
                    popUpScrollTo: 75,
                    popUpScrollToWithCustomPeriod: 130,
                    wrapperRef,
                  }}
                  isEditInProcess
                  additionalFunctions={{
                    handleCheckedItem: (id: string) => {
                      handleCheckedItem(TypeOfParameter.CUSTOMER, id);
                    },
                    handleRemoveSelectedItem: (item: any) => {
                      handleRemoveSelectedItem(TypeOfParameter.CUSTOMER, item);
                    },
                    handleSelectAll: () => {
                      handleSelectAll(TypeOfParameter.CUSTOMER);
                    },
                    setIsOpenSelectList: (value?: boolean) => {
                      handlePopupOpenClose(TypeOfParameter.CUSTOMER, value);
                    },
                    setIsScrollableModal,
                    handleTransformName: (name: any) => `${name}`,
                  }}
                />
                <TableInput
                  type={TableInputType.DROPDOWN_MULTI_CHOICE}
                  value={filteredArr(TypeOfParameter.LOCATION)}
                  handleChangeValue={() => {}}
                  valuesList={location?.parameters}
                  options={{
                    placeholder: languageStrings.generateReportModalParameterLocationPlaceholder,
                    title: languageStrings.dashBoardCardCitiesTitle,
                    isValidatingStarted,
                    isError: location?.isError && isValidatingStarted,
                    isOpenSelectList: location?.isOpenPopup,
                    isSelectAll: location?.isSelectAll,
                    selectedIndexOfPeriod: selectedIndexOfPeriod,
                    popUpScrollTo: 244,
                    popUpScrollToWithCustomPeriod: 288,
                    wrapperRef,
                    isDisabled: customer && !(customer?.selected_parameters.length > 0),
                    disabledPlaceholder: languageStrings.generateReportModalParameterLocationPlaceholderDisabled,
                    paramsIsUploading: location?.isUploadingInProcess,
                  }}
                  isEditInProcess
                  additionalFunctions={{
                    handleCheckedItem: (id: string) => {
                      handleCheckedItem(TypeOfParameter.LOCATION, id);
                    },
                    handleRemoveSelectedItem: (item: any) => {
                      handleRemoveSelectedItem(TypeOfParameter.LOCATION, item);
                    },
                    handleSelectAll: () => {
                      handleSelectAll(TypeOfParameter.LOCATION);
                    },
                    setIsOpenSelectList: (value?: boolean) => {
                      customer?.parameters &&
                        customer?.parameters.length > 0 &&
                        handlePopupOpenClose(TypeOfParameter.LOCATION, value);
                    },
                    setIsScrollableModal,
                    handleTransformName: (name: any) =>
                      name ? `${name.city ? name.city + ', ' : ''}${name.line1 ? name.line1 : ''}` : '',
                  }}
                />
              </>
            )}
            {isShowRecipientsBlock && (
              <TableInput
                type={TableInputType.DROPDOWN_MULTI_CHOICE}
                value={filteredArr(TypeOfParameter.RECIPIENTS)}
                handleChangeValue={() => {}}
                valuesList={recipients?.parameters}
                options={{
                  placeholder: languageStrings.generateReportModalParameterRecipientsPlaceholder,
                  title: languageStrings.generateReportModalParameterRecipients,
                  isValidatingStarted,
                  isError: recipients?.isError && isValidatingStarted,
                  isOpenSelectList: recipients?.isOpenPopup,
                  isSelectAll: recipients?.isSelectAll,
                  selectedIndexOfPeriod: selectedIndexOfPeriod,
                  popUpScrollTo: location && customer ? 408 : mediaType.phone ? 35 : 75,
                  popUpScrollToWithCustomPeriod: location && customer ? 452 : mediaType.phone ? 80 : 130,
                  wrapperRef,
                }}
                isEditInProcess
                additionalFunctions={{
                  handleCheckedItem: (id: string) => {
                    handleCheckedItem(TypeOfParameter.RECIPIENTS, id);
                  },
                  handleRemoveSelectedItem: (item: any) => {
                    handleRemoveSelectedItem(TypeOfParameter.RECIPIENTS, item);
                  },
                  handleSelectAll: () => {
                    handleSelectAll(TypeOfParameter.RECIPIENTS);
                  },
                  setIsOpenSelectList: (value?: boolean) => {
                    handlePopupOpenClose(TypeOfParameter.RECIPIENTS, value);
                  },
                  setIsScrollableModal,
                  handleTransformName: (name: any) => `${name}`,
                }}
              />
            )}
          </ScrolledWrapper>
          <ButtonWrapper>
            <Wrapper>
              <StyledButton onClick={handleCloseModal} width={'104px'} colors={{ main: black1, background: pureWhite }}>
                {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
              </StyledButton>
            </Wrapper>
            <Wrapper>
              <StyledButton onClick={handleGenerateReport} width={'104px'}>
                {languageStrings.generateButton}
              </StyledButton>
            </Wrapper>
          </ButtonWrapper>
        </StyledContainer>
      </StyledModal>
    );
  } else {
    return null;
  }
};
