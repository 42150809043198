import { useSelector } from 'react-redux';
import { selectLanguage } from '../../app/state/user';
import { API_URL } from '../../consts';
import { useApi } from '../../features/apiBuilder/useApiBuilder';
import { ApiCallType } from '../../utils/http';

const deviceParametersKeywords = ['DEVICE_PARAMETERS'];

export const useMeasurementDeviceParameters = (id: string) => {
  const url = `${API_URL}/v2/logical-set/${id}/params`;
  const language = useSelector(selectLanguage);
  const condition = !!id;

  const [localData] = useApi({
    url,
    keywords: deviceParametersKeywords,
    callType: ApiCallType.GET,
    condition,
    triggers: [language.id],
    transformResponse: (response) => response.payload,
  });

  return localData || [];
};
