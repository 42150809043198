import { Text, TextType } from '@bit/first-scope.text';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { useGetClients } from '../../../../../../../api/manage/manageClients';
import { updateDeviceDetails } from '../../../../../../../api/manage/manageDevices';
import { setDeviceInfo, setDeviceInfoCustomer } from '../../../../../../../app/state/insight';
import { selectLanguageStrings } from '../../../../../../../app/state/user';
import { PlaceholderType } from '../../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { withLoader } from '../../../../../../../features/placeholderComponent/loaderFunctions';
import { RowItemType } from '../../../../../../../features/universalTable/types';
import { getRequiredDateFormat } from '../../../../../../../utils/date/date-format';
import { isEmptyObject } from '../../../../../../../utils/isEmptyObject';
import { TableBlockWrapper } from '../common/Common';
import { CalibrationDetailsModal } from './components/infoContentTable/components/calibrationDetailsModal/CalibrationDetailsModal';
import {
  DeploymentDetailsInfoType,
  DeploymentDetailsModal,
} from './components/infoContentTable/components/deploymentDetailsModal/DeploymentDetailsModal';
import { InfoContentTable } from './components/infoContentTable/InfoContentTable';
import { GpsLocationDetails } from '../../../../../../../features/gpsLocationDetails';
import { ProvisioningDateModal } from './components/infoContentTable/components/provisioningDateModal/ProvisioningDateModal';
import { SubscriptionModal } from './components/infoContentTable/components/subscriptionModal/SubscriptionModal';
import { DeviceType } from '../../../../devices/Devices';

const Wrapper = styled.div`
  min-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TableWrapper = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(Wrapper);

export const DeviceInfo = ({
  data,
  deploymentDetailsinfoArr,
  isOpenAddressPopup,
  setIsOpenAddressPopup,
  accessData,
  handleQualityModal,
}: {
  data: any;
  deploymentDetailsinfoArr: DeploymentDetailsInfoType[];
  setIsOpenAddressPopup: (value: boolean) => void;
  isOpenAddressPopup: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  handleQualityModal: () => void;
}) => {
  const dispatch = useDispatch();
  const languageStrings = useSelector(selectLanguageStrings);
  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [savedCustomer, setSavedCustomer] = useState({ name: '', id: '' });
  const [isOpenDeploymentDetailsModal, setIsOpenDeploymentDetailsModal] = useState(false);
  const [isOpenGpsLocationDetails, setIsOpenGpsLocationDetails] = useState(false);
  const [isOpenCalibrationDetailsModal, setIsOpenCalibrationDetailsModal] = useState(false);
  const [isOpenProvisioningDateModal, setIsOpenProvisioningDateModal] = useState(false);
  const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState(false);

  const clientsList = useGetClients();

  const { addNotification } = useNotifierFunctions();

  const openDeploymentDetailsModal = () => {
    setIsOpenDeploymentDetailsModal(true);
  };

  const openGpsLocationDetails = () => {
    setIsOpenGpsLocationDetails(true);
  };

  const openCalibrationDetailsModal = () => {
    setIsOpenCalibrationDetailsModal(true);
  };

  const openProvisioningDateModal = () => {
    setIsOpenProvisioningDateModal(true);
  };

  const openSubscriptionModal = () => {
    setIsOpenSubscriptionModal(true);
  };

  const closeDeploymentDetailsModal = () => {
    setIsOpenDeploymentDetailsModal(false);
  };

  const closeGpsLocationDetails = () => {
    setIsOpenGpsLocationDetails(false);
  };

  const closeProvisioningDateModal = () => {
    setIsOpenProvisioningDateModal(false);
  };

  const closeSubscriptionModal = () => {
    setIsOpenSubscriptionModal(false);
  };

  const handleRevertOrganisation = (isError: boolean) => {
    isError && dispatch(setDeviceInfoCustomer(savedCustomer));
  };

  const {
    id,
    transport_id,
    transport_id_tooltip,
    location_tooltip,
    human_readable_type,
    client_name,
    client_id,
    address,
    created_at,
    deployment_date,
    last_seen_date,
    first_deployment,
    battery_level,
    calibration_info,
    gps_info,
    first_provisioning,
    billing_plan,
    type,
  } = data;

  const { gps_checks } = gps_info || [];
  const gpsLocationAddress = gps_checks && gps_checks[gps_checks?.length - 1]?.location_info?.value;

  const arrNode = !isEmptyObject(data)
    ? [
        {
          id: 'phisicalId',
          type: RowItemType.INPUT,
          property: languageStrings.phisicalId,
          value: transport_id,
          disabled: false,
          infoTooltip: transport_id_tooltip,
        },
        {
          id: 'type',
          type: RowItemType.INPUT,
          property: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
          value: human_readable_type,
          disabled: false,
        },
        {
          id: 'organisation',
          type: RowItemType.SELECT, // @TODO replace with JOINED_SELECT during RENG integration
          property: languageStrings.manageClientsTableHeaderItemsClient,
          value: { name: client_name, id: client_id },
          list: clientsList,
          placeholder: languageStrings.groupFieldPlaceholder,
          fieldName: { name: 'client_name', id: 'client_id' },
          handleChangeValue: (name: string, id: string) => {
            dispatch(setDeviceInfoCustomer({ name, id }));
          },
          disabled: false,
          isEdit: accessData?.edit && isCustomerEdit,
          setIsEdit: (value: boolean) => {
            setSavedCustomer({ name: client_name, id: client_id });
            setIsCustomerEdit(value);
          },
        },
        {
          id: 'location',
          type: RowItemType.ADDRESS,
          property: languageStrings.labelReportsTableLocation,
          infoTooltip: location_tooltip,
          value: `${address?.city}, ${address?.line1 ? address?.line1 : ''}`,
          isEdit: accessData?.edit && isOpenAddressPopup,
          setIsEdit: (value: boolean) => {
            setIsOpenAddressPopup(value);
          },
          additionalActionFunction: (value: boolean) => {
            setIsOpenAddressPopup(value);
          },
          disabled: false,
        },
        {
          id: 'gpsLocation',
          type: RowItemType.CUSTOM,
          property: languageStrings.gpsLocationProperty,
          infoTooltip: gps_info?.gps_location_tooltip,
          value: gpsLocationAddress
            ? `${gpsLocationAddress?.city}, ${gpsLocationAddress?.line1 ? gpsLocationAddress?.line1 : ''}`
            : 'N/A',
          additionalActionLable: languageStrings ? languageStrings.problemsButtonDetails : 'Details',
          additionalActionFunction: openGpsLocationDetails,
        },

        {
          id: 'manufacturingDate',
          type: RowItemType.CUSTOM,
          property: languageStrings.manufacturingDate,
          value: created_at === null ? 'N/A' : getRequiredDateFormat(created_at, 'DD.MM.YYYY'),
          disabled: false,
          additionalActionLable: languageStrings.qualityControl,
          additionalActionFunction: () => {
            handleQualityModal();
          },
          withQualityControl: true,
        },
        {
          id: 'provisioningDate',
          type: RowItemType.CUSTOM,
          property: languageStrings.provisioningDate,
          value:
            first_provisioning?.ts_info?.value === null
              ? 'N/A'
              : getRequiredDateFormat(first_provisioning?.ts_info?.value, 'DD.MM.YYYY'),
          disabled: false,
          additionalActionLable: languageStrings.problemsButtonDetails,
          additionalActionFunction: openProvisioningDateModal,
        },
        {
          id: 'subscriptionPlan',
          type: RowItemType.CUSTOM,
          property: languageStrings.subscriptionPlan,
          value: billing_plan?.name || 'N/A',
          disabled: !billing_plan,
          additionalActionLable: languageStrings.problemsButtonDetails,
          additionalActionFunction: openSubscriptionModal,
        },
        {
          id: 'deploymentDate',
          type: RowItemType.CUSTOM,
          property: languageStrings.deploymentDate,
          value: !first_deployment?.success_ts_info?.value
            ? 'N/A'
            : getRequiredDateFormat(first_deployment?.success_ts_info?.value, 'DD.MM.YYYY'),
          disabled: false,
          additionalActionLable: languageStrings.problemsButtonDetails,
          additionalActionFunction: openDeploymentDetailsModal,
        },
        {
          id: 'calibrationDate',
          type: RowItemType.CUSTOM,
          property: languageStrings.calibrationDate,
          value: !calibration_info?.success_info?.value
            ? 'N/A'
            : getRequiredDateFormat(calibration_info?.success_info?.value, 'DD.MM.YYYY'),
          disabled: false,
          additionalActionLable: languageStrings.problemsButtonDetails,
          additionalActionFunction: openCalibrationDetailsModal,
          infoTooltip: languageStrings.calibrationInfoTooltip,
        },
        {
          id: 'lastSeenOnline',
          type: RowItemType.INPUT,
          property: languageStrings.lastSeenOnline,
          value: last_seen_date === null ? 'N/A' : getRequiredDateFormat(last_seen_date, 'DD.MM.YYYY'),
          disabled: false,
        },
        {
          id: 'batteryLevel',
          type: RowItemType.INPUT,
          property: languageStrings.batteryLevel,
          value: typeof battery_level === 'string' ? battery_level : 'N/A',
          disabled: false,
          phisicalValue: battery_level,
        },
      ]
    : [];

  const arrForSmartCapDevices = arrNode.filter((item: any) => item.id !== 'calibrationDate');

  const finalArr = type === DeviceType.SMART_CAP ? arrForSmartCapDevices : arrNode;

  const placeholder = {
    keyword: 'InsightDetailsDeviceConfigurationTable',
    style: { width: '100%', boxSizing: 'border-box' },
    placeholderOptions: { withoutPagination: true, countRows: 11 },
    conditionalStyle: { overflow: 'hidden', borderRadius: '8px' },
  };

  const handleUpdateData = () => {
    updateDeviceDetails({
      deviceId: id,
      body: data,
      handleSuccess: (data: any) => {
        dispatch(setDeviceInfo({ data: data.info }));
        addNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
      },
      handleError: (data: any) => {
        dispatch(setDeviceInfoCustomer(savedCustomer));
        return addNotification({ message: `${data?.response?.data?.detail}`, type: 'error' });
      },
    });
  };

  const handleRevertCustomer = () => {
    const { name, id } = savedCustomer;
    dispatch(setDeviceInfoCustomer({ name, id }));
  };

  return (
    <TableBlockWrapper>
      <Text type={TextType.SEMITITLE} margin={'0 0 12px 0'}>
        {languageStrings.insightDetailsDeviceInfoTitle}
      </Text>
      <TableWrapper placeholderProps={placeholder}>
        <InfoContentTable
          arr={finalArr}
          handleUpdateData={handleUpdateData}
          handleRevertData={handleRevertCustomer}
          languageStrings={languageStrings}
          accessData={accessData}
        />
      </TableWrapper>
      {isOpenDeploymentDetailsModal && (
        <DeploymentDetailsModal
          isOpenModal={isOpenDeploymentDetailsModal}
          closeModal={closeDeploymentDetailsModal}
          infoArr={deploymentDetailsinfoArr}
        />
      )}
      {isOpenGpsLocationDetails && (
        <GpsLocationDetails
          deviceId={id}
          isOpenModal={isOpenGpsLocationDetails}
          closeModal={closeGpsLocationDetails}
          data={gps_info}
        />
      )}
      {isOpenCalibrationDetailsModal && (
        <CalibrationDetailsModal
          isOpenModal={isOpenCalibrationDetailsModal}
          data={calibration_info}
          id={id}
          setCloseModal={() => setIsOpenCalibrationDetailsModal(false)}
        />
      )}
      {isOpenProvisioningDateModal && (
        <ProvisioningDateModal
          isOpenModal={isOpenProvisioningDateModal}
          closeModal={closeProvisioningDateModal}
          data={first_provisioning}
        />
      )}
      {isOpenSubscriptionModal && (
        <SubscriptionModal
          deviceType={type}
          isOpenModal={isOpenSubscriptionModal}
          closeModal={closeSubscriptionModal}
          data={billing_plan}
        />
      )}
    </TableBlockWrapper>
  );
};
