import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as ArrowImage } from '../../../../../../../../../../../assets/Shape.svg';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  background-color: ${colorFetch('white')};
  box-sizing: border-box;
  padding: 13px 22px;
  border-radius: 8px 8px 0 0;
  border-bottom: 2px solid;
  border-color: ${colorFetch('gray4')};
  ${media.phone} {
    padding: 13px 16px;
  } ;
`;

type TitleBoxProps = {
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
  gridTemplatePhoneXS?: string;
};

// @TODO fix media types
const TitleBox = styled.div<TitleBoxProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ gridTemplateDesktop }) => gridTemplateDesktop || '190px 280px 10fr'};
  grid-gap: 24px;

  ${media.tablet} {
    grid-template-columns: ${({ gridTemplateTablet }: any) => gridTemplateTablet || '130px 130px 10fr'};
  }

  ${media.phone} {
    grid-template-columns: ${({ gridTemplatePhone }: any) => gridTemplatePhone || '86px 60px 10fr'};
  }

  ${media.phoneXS} {
    grid-template-columns: ${({ gridTemplatePhoneXS }: any) => gridTemplatePhoneXS || '1.25fr 1.5fr'};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-weight: 500;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 4px;
  & > svg:nth-child(2n + 1) {
    transform: rotate(180deg);
    margin: 0 0 2px 0;
  }
  & > svg {
    cursor: pointer;
  }
  & > svg > path {
    fill: ${colorFetch('gray2')};
    transition: fill 0.3s ease;
  }
  & > svg:hover > path {
    fill: ${colorFetch('gray1')};
  }
`;

const EditWrapper = styled.div`
  width: 100%;
  display: none;
  justify-content: flex-end;
  align-items: center;
  ${media.phone} {
    display: flex;
  }
`;

export interface TableRowProps {
  labels: any;
  handleEditOnClick?: () => void;
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
  gridTemplatePhoneXS?: string;
  className?: string;
  languageStrings?: any;
  isEditable?: boolean;
}

export const TableHeader: FC<TableRowProps> = ({
  labels,
  handleEditOnClick,
  gridTemplateDesktop,
  gridTemplateTablet,
  gridTemplatePhone,
  gridTemplatePhoneXS,
  languageStrings,
  isEditable,
  ...props
}) => {
  const theme = useTheme();
  const { primary, secondary2 } = theme.colors;

  return (
    <Wrapper {...props}>
      <TitleBox
        gridTemplateDesktop={gridTemplateDesktop}
        gridTemplateTablet={gridTemplateTablet}
        gridTemplatePhone={gridTemplatePhone}
        gridTemplatePhoneXS={gridTemplatePhoneXS}
      >
        {labels.map((item: any, index: number) => {
          return (
            <TitleWrapper key={index}>
              <Text type={TextType.TITLE_H4} color={theme.colors.black1}>
                {item.name}
              </Text>
              {item.isSorted ? (
                <ArrowWrapper theme={theme}>
                  <ArrowImage />
                  <ArrowImage />
                </ArrowWrapper>
              ) : null}
            </TitleWrapper>
          );
        })}
        {isEditable && (
          <EditWrapper onClick={handleEditOnClick}>
            <Text type={TextType.TEXT_14_GRAY} color={primary} hoverColor={secondary2}>
              {languageStrings.insightDetailsDeviceConfigHeaderEdit}
            </Text>
          </EditWrapper>
        )}
      </TitleBox>
    </Wrapper>
  );
};
