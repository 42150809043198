import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { UsersTableState } from './types';
import { NEW_ID } from '../../../consts';

export const setUsersTable = (state: WritableDraft<UsersTableState>, action: PayloadAction<any>) => {
  state.list = action.payload.list;
  state.pagination = action.payload.pagination;
};

export const updateUserLocal = (state: WritableDraft<UsersTableState>, action: PayloadAction<any>) => {
  state.list[action.payload.index] = action.payload.value;
};

export const setField = (state: WritableDraft<UsersTableState>, action: PayloadAction<any>) => {
  state.list[action.payload.index][action.payload.fieldName] = action.payload.value;
};

export const deleteUser = (state: WritableDraft<UsersTableState>, action: PayloadAction<any>) => {
  const id = action.payload.id;
  state.list = state.list.filter((elem: any) => elem.id !== id);
  state.pagination.total_items -= 1;
};

export const deleteCheckedUsers = (state: WritableDraft<UsersTableState>) => {
  const oldListLength = state.list.length;
  state.list = state.list.filter((item: any) => !item.isChecked);
  const newListLength = state.list.length;
  const difference = oldListLength - newListLength;
  state.pagination.total_items -= difference;
};

export const addUser = (state: WritableDraft<UsersTableState>) => {
  state.list.unshift({
    id: NEW_ID,
    name: '',
    email: 'user@example.com',
    phone: '',
    client_id: '',
    role: 0,
    client_name: '',
    role_name: '',
    role_description: '',
    isChecked: false,
    isEdit: true,
  });
};

export const addUserMobile = (state: WritableDraft<UsersTableState>) => {
  state.list.unshift({
    id: NEW_ID,
    name: '',
    email: 'user@example.com',
    phone: '',
    client_id: '',
    role: 0,
    client_name: '',
    role_name: '',
    role_description: '',
    isChecked: false,
    isEdit: false,
  });
};

export const increaseTotalItems = (state: WritableDraft<UsersTableState>) => {
  state.pagination.total_items += 1;
};

export const toggleIsChecked = (state: WritableDraft<UsersTableState>, action: PayloadAction<any>) => {
  state.list[action.payload.index].isChecked = !state.list[action.payload.index].isChecked;
};

export const toggleIsCheckedAll = (state: WritableDraft<UsersTableState>) => {
  state.list.forEach((item: any, index: number) => {
    const { isChecked } = item;
    if (!isChecked && !state.isSelectAll) {
      state.list[index].isChecked = true;
    } else if (isChecked === true && state.isSelectAll === true) {
      state.list[index].isChecked = false;
    }
  });
  state.isSelectAll = !state.isSelectAll;
};

export const toggleIsEdit = (state: WritableDraft<UsersTableState>, action: PayloadAction<any>) => {
  state.list[action.payload.index].isEdit = !state.list[action.payload.index].isEdit;
};
export const toggleIsEditInProcessUser = (state: WritableDraft<UsersTableState>) => {
  state.isEditInProcess = !state.isEditInProcess;
};

export const setDefaultIsEditProcessUser = (state: WritableDraft<UsersTableState>) => {
  state.isEditInProcess = false;
};

export const reducers = {
  setUsersTable,
  setField,
  updateUserLocal,
  deleteUser,
  deleteCheckedUsers,
  addUser,
  addUserMobile,
  increaseTotalItems,
  toggleIsChecked,
  toggleIsCheckedAll,
  toggleIsEdit,
  toggleIsEditInProcessUser,
  setDefaultIsEditProcessUser,
};
