import { CardType } from '../../../../enums/CardType';
import { FileNameType } from '../../../../enums/FileNameType';

export const getDocumentData = (languageStrings: any) => {
  return [
    {
      title: languageStrings.documentsReleaseNote1_1,
      type: CardType.DOCUMENTS,
      filename: FileNameType.RELEASE_NOTE_1_1,
    },
    {
      title: languageStrings.documentsReleaseNote2_0,
      type: CardType.DOCUMENTS,
      filename: FileNameType.RELEASE_NOTE_2_0,
    },
    {
      title: languageStrings.documentsReleaseNote2_4,
      type: CardType.DOCUMENTS,
      filename: FileNameType.RELEASE_NOTE_2_4,
    },
    {
      title: languageStrings.documentsReleaseNote2_5,
      languageStrings: languageStrings,
      type: CardType.DOCUMENTS,
      filename: FileNameType.RELEASE_NOTE_2_5,
    },
    {
      title: languageStrings.documentsReleaseNote2_6,
      type: CardType.DOCUMENTS,
      filename: FileNameType.RELEASE_NOTE_2_6,
    },
    {
      title: languageStrings.quickstartGuide,
      type: CardType.DOCUMENTS,
      filename: FileNameType.DEVICE_PROVISIONING,
    },
    {
      title: languageStrings.endUserGuide,
      type: CardType.DOCUMENTS,
      filename: FileNameType.END_USER_GUIDE,
    },
    {
      title: languageStrings.documentsSpecification,
      type: CardType.DOCUMENTS,
      filename: FileNameType.SPECIFICATION,
    },
    {
      title: languageStrings.termsOfService,
      type: CardType.DOCUMENTS,
      filename: FileNameType.TERMS_OF_SERVICE,
    },
    {
      title: languageStrings.privacyPolicy,
      type: CardType.DOCUMENTS,
      filename: FileNameType.PRIVACY_POLICY,
    },
  ];
};
