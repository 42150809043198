// SHOULD BE DELETED DEPRECATED!!!!!!
//*
//*
//*
//*
//*
//*
//*
//*
//*
//*

import React from 'react';

import { useSelector } from 'react-redux';
import { useUserAccess } from '../../api/user';
import { selectAccess, selectLanguageStrings } from '../../app/state/user';
import { MAPBOX_FLAG } from '../../consts';
import { LsValueType } from '../../enums/LsValueType';
import { UserType } from '../../enums/UserType';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import withAuth from '../../features/authentication/withAuth';
import { MenuItemType } from '../../features/navigation/MenuItem';
import { Navigation } from '../../features/navigation/Navigation';
import { View } from '../../routes';
import { PATHS, injectPathParams } from '../../routes/paths';
import withUserType from '../../routes/withUserType';
import { renderRoutes } from '../../utils/renderRoutes';
import ss from '../../utils/ss';
import { useUserRole } from '../../utils/userRole';
import { Body, Wrapper } from '../elements/wrappers';

const Admin = (props: any) => {
  useUserAccess();
  const globalAccess = useSelector(selectAccess);
  const { role_name } = globalAccess;
  const [, { getUserByRole }] = useUserRole();

  // @NOTE role <1> corresponds to Admin, which is default here
  const defaultRoleName = getUserByRole(1).name;
  const handleLogout = (e: any) => {
    e.preventDefault();
    ss.remove(LsValueType.token);
    oauthRedirect();
  };

  const languageStrings = useSelector(selectLanguageStrings);

  const menuItemArray = [
    { type: MenuItemType.DASHBOARD, route: PATHS.USER_DASHBOARD, value: languageStrings.dashboardTitle },
    {
      type: MenuItemType.INSIGHT,
      route: PATHS.USER_INSIGHTS,
      value: languageStrings.navigationInsight,
      hidden: !MAPBOX_FLAG,
    },
    {
      type: MenuItemType.INCIDENTS,
      route: PATHS.USER_INCIDENTS,
      value: languageStrings.navigationIncidents,
    },
    {
      type: MenuItemType.MANAGE,
      route: View.MANAGE,
      value: languageStrings.navigationManage,
      childRoutes: [
        {
          name: languageStrings.navigationClients,
          to: PATHS.MANAGE_ORGANISATIONS,
        },
        {
          name: languageStrings.navigationDevices,
          to: PATHS.MANAGE_DEVICES,
        },
        {
          name: languageStrings.navigationUsers,
          to: PATHS.MANAGE_USERS,
        },
      ],
    },
    {
      type: MenuItemType.REPORTS,
      route: PATHS.USER_REPORTS,
      value: languageStrings.navigationReports,
    },
    {
      type: MenuItemType.DOCUMENTS,
      route: PATHS.USER_DOCUMENTS,
      value: languageStrings.navigationDocuments,
    },
    {
      type: MenuItemType.ANALYTICS,
      route: View.MANAGE_ANALYTICS,
      value: languageStrings.analyticsItem,
      childRoutes: [
        {
          name: languageStrings.measurementItem,
          to: PATHS.MANAGE_MEASUREMENT,
        },
      ],
    },
    {
      type: MenuItemType.BILLING,
      route: View.BILLING,
      value: languageStrings.billing,
      childRoutes: [
        // {
        //   name: languageStrings.plans,
        //   to: PATHS.BILLING_PLANS,
        // },
        {
          name: languageStrings.invoices,
          to: PATHS.BILLING_INVOICES,
        },
        {
          name: languageStrings.details,
          to: PATHS.BILLING_DETAILS,
        },
      ],
    },
    // {
    //   type: MenuItemType.SETTINGS,
    //   route: View.USER_SETTINGS,
    //   value: languageStrings.navigationSettings || 'Settings',
    //   disabled: true,
    // },
  ];

  const menuMobileItemArray = [
    { type: MenuItemType.DASHBOARD, route: PATHS.USER_DASHBOARD, value: languageStrings.dashboardTitle },
    {
      type: MenuItemType.INSIGHT,
      route: injectPathParams(PATHS.USER_INSIGHTS, { lat: 54.9, lon: 17.54, zoom: 4 }),
      value: languageStrings.navigationInsight,
      hidden: !MAPBOX_FLAG,
    },
    {
      type: MenuItemType.MANAGE,
      route: View.MANAGE,
      value: languageStrings.navigationManage,
      childRoutes: [
        {
          name: languageStrings.navigationClients,
          to: PATHS.MANAGE_ORGANISATIONS,
        },
        {
          name: languageStrings.navigationDevices,
          to: PATHS.MANAGE_DEVICES,
        },
        {
          name: languageStrings.navigationUsers,
          to: PATHS.MANAGE_USERS,
        },
      ],
    },
    {
      type: MenuItemType.REPORTS,
      route: PATHS.USER_REPORTS,
      value: languageStrings.navigationReports,
    },
    {
      type: MenuItemType.BILLING,
      route: View.BILLING,
      value: languageStrings.billing,
      childRoutes: [
        // {
        //   name: languageStrings.plans,
        //   to: PATHS.BILLING_PLANS,
        // },
        {
          name: languageStrings.invoices,
          to: PATHS.BILLING_INVOICES,
        },
        {
          name: languageStrings.details,
          to: PATHS.BILLING_DETAILS,
        },
      ],
    },
  ];

  return (
    <Wrapper>
      <Navigation
        menuItemArray={menuItemArray}
        menuMobileItemArray={menuMobileItemArray}
        onLogout={handleLogout}
        userStatus={role_name ? role_name : defaultRoleName}
        withLanguages
      />
      <Body>{renderRoutes(props.routes, props.parentPathLength)}</Body>
    </Wrapper>
  );
};

export default withAuth(withUserType(UserType.ADMIN)(Admin));
