import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { View } from '../../../../../routes/routeInterfaces';
import { useTheme } from '@cthings.co/styled-components';
import { stringifyUrl } from 'query-string';
import { injectPathParams } from '@cthings.co/utils';
import { getPath } from '../../../../../routes';
import { NavLink } from '../../../../../utils/react-router-dom-abstraction';
import ss from '../../../../../utils/ss';
import { LsValueType } from '../../../../../enums/LsValueType';

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(Text)`
  cursor: pointer;
` as any; // @TODO fix type

const StyledLink = styled(NavLink)`
  text-decoration: none;
`;

export const DeviceItem = ({ deviceId, name, address }: { deviceId: string; name: string; address?: string }) => {
  const theme = useTheme();
  const { blue2, gray1 } = theme.colors;
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const to = View.USER_MANAGE_DEVICES_DETAILS;

  const href = stringifyUrl({
    url: injectPathParams(getPath(to), { id: deviceId, offset: 0 }),
  });

  return (
    <StyledLink access={[]} to={`${href}?access_token=${token}&expires=${exp}`}>
      <Wrapper>
        <StyledText fontSize={'14px'} weight={'500'} lineHeight={'24px'} color={blue2} type={TextType.TEXT_14_BLACK}>
          {name}
        </StyledText>
        {address && (
          <Text fontSize={'14px'} weight={'400'} lineHeight={'24px'} type={TextType.TEXT_14_BLACK} color={gray1}>
            {address}
          </Text>
        )}
      </Wrapper>
    </StyledLink>
  );
};
