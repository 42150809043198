import React from 'react';

export const DistanceToWaterImage = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33325 13.6667C1.73325 14 2.13325 14.3333 2.99992 14.3333C4.66659 14.3333 4.66659 13 6.33325 13C8.06659 13 7.93325 14.3333 9.66658 14.3333C11.3333 14.3333 11.3333 13 12.9999 13C13.8666 13 14.2666 13.3333 14.6666 13.6667"
        stroke="#555770"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8 1L8 10" stroke="#555770" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M3.93335 6.59935L8.00002 10.666L12.0667 6.59935"
        stroke="#555770"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
