export const de_de = {
  accessDownloadEvents:
    'Möchten Sie eine Exceldatei mit den Ereignisdaten dieses Geräts für den ausgewählten Zeitraum herunterladen?',
  accessDownloadTemperature:
    'Möchten Sie eine Excel-Datei mit den Temperaturdaten dieses Geräts für den ausgewählten Zeitraum herunterladen?',
  accessDownloadWaterLevel:
    'Möchten Sie eine Excel-Datei mit den Wasserstandsdaten dieses Geräts für den ausgewählten Zeitraum herunterladen?',
  accessToThePlatformBlocked: 'Ihr Zugriff auf die Plattform wurde blockiert, da Sie unbezahlte Rechnungen haben.',
  actionButtonValue: 'Aktion',
  add: 'Hinzufügen',
  addCommentButton: 'Einen Kommentar hinzufügen',
  addCompany: 'Firma hinzufügen',
  addDeviceModalClarifyingDesc:
    'Der Endbenutzer muss die Geräte weiterhin bereitstellen und bereitstellen, um die Daten sammeln und überwachen zu können.',
  addDeviceModalDesc:
    'Durch das Hinzufügen von Geräten zu einem Unternehmenskonto können alle diesem Unternehmen zugewiesenen Benutzer die Leistung dieser Geräte sehen und verfolgen. Benutzer mit Administratorberechtigungen können auch ihre Einstellungen konfigurieren und dem Konto neue Geräte hinzufügen.',
  addDeviceModalWaysDesc: 'Es gibt zwei Möglichkeiten, die Geräte einem Firmenkonto zuzuweisen',
  addDevicesManuallyTitle: 'Gerät(e) manuell hinzufügen',
  addEventText: 'Ereignis hinzufügen',
  addIncidentTitle: 'Vorfall hinzufügen',
  additionalDeviceAddDevice: 'Hinzufügen eines neuen Geräts',
  additionalDeviceEnterCode: 'Geben Sie den 10-stelligen Code aus der Anleitung ein',
  additionalDeviceNextButton: 'Nächste',
  additionalInfoLabel: 'Zusätzliche info',
  additionalInfoPlaceholder: 'Wählen sie zusätzliche info',
  addManuallyAddDeviceModal: 'Manuell hinzufügen',
  addManuallyDescAddDeviceModal:
    'geben Sie die Seriennummern der Geräte ein, die Sie zuweisen möchten. Es kann sich um eine Anzahl einzelner Geräte oder um eine Reihe mit einer größeren Charge handeln',
  addManuallyTooltipAddDeviceModal:
    'Empfohlen für das gleichzeitige Hinzufügen von bis zu 10 Geräten oder einer Reihe aus einer Produktionscharge.',
  addNew: 'Neu hinzufügen',
  addNewCompany: 'Neue Firma hinzufügen',
  addNewCompanyDesc:
    'Company ist eine übergeordnete Instanz für Benutzer und Geräte und fungiert als deren Aggregator. Alle Benutzer und Geräte müssen einem zugewiesen werden company.In um alle Plattformfunktionen nutzen zu können, ist eine Benutzererstellung erforderlich.',
  addNewCompanyDetails: 'Bitte geben Sie die Details des neuen Unternehmens an.',
  addNewCompanyModalSecondStepDescription:
    'Der Benutzer ist eine Instanz, die es ermöglicht, die Leistung der Geräte auf der Plattform zu überwachen und ihre Einstellungen zu verwalten.',
  addNewCompanyModalSecondStepHintText:
    'Der erste einem Unternehmen zugewiesene Benutzer muss über ein Administratorkonto verfügen, um weitere Benutzer hinzufügen und Geräteeinstellungen verwalten zu können.',
  addNewCompanyModalSecondStepTitle: 'Erstellen Sie den ersten Benutzer im Konto des Unternehmens',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText1:
    'kann die Einstellungen des Kontos und der Geräte bearbeiten.',
  addNewCompanyModalSecondStepTypesOfAccountsRemainingText2:
    'auf schreibgeschützte Ansicht beschränkt ist, darf die Einstellungen nicht bearbeiten.',
  addNewCompanyModalSecondStepTypesOfAccountsText: 'Es gibt zwei Arten von Konten für Benutzer:',
  addOrganisationLabel: 'Neue Organisation hinzufügen',
  addresInstructionDesc1: 'Geben sie die Adresse oder den Standort in die Suchleiste oben links auf der Karte ein.',
  addresInstructionDesc2:
    'Sobald Sie den ungefähren Standort sehen, vergrößern Sie die Karte und ziehen Sie sie für eine bessere Genauigkeit an die genaue Stelle.',
  addresInstructionDesc3: 'Klicken Sie auf Bestätigen, um Ihre Auswahl zu speichern und das Menü zu verlassen.',
  addresInstructionDesc4:
    'Verwenden Sie die Textfelder am unteren Rand des Fensters, um die genaue Adresse des Gerätestandorts einzugeben.',
  addresInstructionDesc5: 'Klicken Sie auf Bestätigen, um die Adresse zu speichern und das Menü zu verlassen.',
  addresInstructionDesc6: 'Hinweis: Die Karte zeigt den Standort bei dieser Methode nicht an.',
  addresInstructionTitle1: 'Methode 1: Kartensuche',
  addresInstructionTitle2: 'Methode 2: Adresseingabe',
  addressSeaLevelLabel: 'Höhe über dem Meeresspiegel (m)',
  addressSeaLevelPlaceholder: 'Geben Sie den Meeresspiegel ein',
  addUser: '+ Benutzer hinzufügen',
  advancedLabel: 'Erweitert',
  alertMessageError: 'Fehler erscheint, wenn Sie etwas falsch gemacht haben oder ein Systemfehler vorliegt',
  alertMessageNeutral: 'Hier werden neutrale Informationen angezeigt',
  alertMessagePositive: 'Erfolgreiche Botschaft an die, die Gutes getan haben',
  alertMessageWarning: 'Warntext hier, damit Sie vorsichtiger sein können',
  allButton: 'Alle',
  analyticsItem: 'Analytik',
  anUnexpectedErrorHasOccurred: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut',
  applyButton: 'Sich bewerben',
  applyButton1: 'Bewirbt sich',
  applyButton2: 'Angewandt',
  areYouSure: 'Bist du dir sicher?',
  average: 'Schnitt',
  averagingMethodLabel: 'Mittelungsmethode',
  backButton: 'Zurück',
  backHome: 'Zurück zur Startseite',
  basedOnManningsEquation: 'Basierend auf der Manning-Gleichung, ',
  basicLabel: 'Basic',
  battery: 'Batterie',
  batteryLevel: 'Batteriestand',
  batteryStatusBad: 'Niedrig',
  batteryStatusGood: 'Hoch',
  batteryStatusWarn: 'Mittel',
  Bereitstellung: 'Bereitstellung',
  billing: 'Abrechnung',
  billingAddressPlaceholder: 'Rechnungsadresse wählen',
  billingDetails: 'Rechnungsdetails',
  billingDetailsNotFilledDesc: 'Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Administrator.',
  calibraion: 'Kalibrierung',
  calibraionInPropgress: 'Kalibrierung läuft',
  calibraionLabel: 'Kalibrierungsdetails',
  calibraionTooltip: 'Erfahren Sie mehr über die Kalibrierung',
  calibration: 'Kalibrierung',
  calibrationDate: 'Kalibrierdatum',
  calibrationDesc:
    'Die Kalibrierung wird immer nach dem Einsatz des Geräts durchgeführt. Eine Neukalibrierung des Geräts ist erforderlich, wenn Sie das Gerät seit der letzten Kalibrierung an einem anderen Ort aufgestellt haben oder die Kalibrierung falsch durchgeführt wurde. Bevor Sie mit dem Kalibriervorgang beginnen, vergewissern Sie sich, dass sich das Gerät am vorgesehenen Ort befindet und für die Kalibrierdauer – bis zu 48 h – ungestört bleibt.',
  calibrationDescInProgress:
    'Der Kalibrierungsprozess hat begonnen. Es kann bis zu 48 Stunden dauern. Denken Sie daran, dass während dieser Zeit die Wasserstandsmessungen unzuverlässig sein können. Bitte stellen Sie sicher, dass das Gerät während dieser Zeit ungestört bleibt.',
  calibrationInfoTooltip:
    'Bei der Kalibrierung scannt das Gerät das Innere des Schachts, um alle statischen Elemente zu erkennen, sie bei der Durchführung von Messungen zu berücksichtigen und eine ordnungsgemäße Datenerfassung sicherzustellen. Sie wird immer nach der Bereitstellung durchgeführt',
  calibrationPeriodTootlipText: 'Die Daten aus dem Kalibrierungszeitraum\n können unzuverlässig sein',
  calibrationText: 'Kalibrierung',
  calibrationTooltipText: 'Die Daten aus dem Kalibrierzeitraum können ungenau sein',
  cancelButton: 'Absagen',
  chooseAddressPlaceholder: 'Adresse wählen',
  chooseDateTitle: 'Datum wählen',
  chooseDeviceNamePlaceholder: 'Wählen Sie den Namen des Geräts',
  chooseOrganisation: 'Organisation wählen',
  chooseOrganisationEmail: 'E-Mail-Adresse der Organisation',
  chooseOrganisationName: 'Name der Organisation',
  chooseOrganisationNamePlaceholder: 'Namen eingeben',
  chooseOrganisationPhone: 'Telefon der Organisation',
  chooseOrganisationPhonePlaceholder: 'Telefon eingeben',
  chooseParentOrgFirst: 'Wählen über. Org. aus erste',
  choosePaymentMethodPlaceholder: 'Wählen zahlungsmethode',
  choosePlansetForEachDevices:
    '\nWählen Sie\n für jedes zugewiesene Gerät ein Plan-Set aus. Dies bestimmt, welche Pläne im Gerätebereitstellungsprozess als Optionen angeboten werden.',
  chooseSeverityPlaceholder: 'Wählen sie den schweregrad',
  chooseTypeOfInvoices: 'Wählen arten von Rechnungen',
  chooseTypePlaceholder: 'Typ wählen',
  choosingPlanset: 'Plan-Set auswählen',
  clearButton: 'Geräte löschen',
  clearButtonMobile: 'Geräte',
  clickOn: 'Klicken Sie auf ',
  closeButtonValue: 'Nah dran',
  closedText: 'Abgeschlossen',
  commentsPlaceholder: 'Noch keine Kommentare',
  commentTableDayToday: 'Heute, ',
  commentTableDayYesterday: 'Gestern, ',
  commentTableEdit: 'Nachricht bearbeiten',
  commentTableEditLabel: 'Bearbeitet: ',
  commentTableEditPlaceholder: 'Bearbeiten Sie eine Nachricht...',
  commentTablePlaceholder: 'Schreibe einen Kommentar...',
  commonButtonMore: 'Mehr',
  commonButtonRevoke: 'Widerrufen',
  commonButtonSend: 'Senden',
  commonButtonUpload: 'Hochladen',
  companyAddress: 'Anschrift des Unternehmens',
  companyAddressPlaceholder: 'Wählen Sie die Adresse des Unternehmens',
  companyCreationEmailAddress: 'Ihre E-Mail-Adresse',
  companyName: 'Firmenname',
  companyNameLabel: 'Name der Firma',
  companyNamePlaceholder: 'Geben Sie den Firmennamen ein',
  companyNamePlaceholderr: 'Name der Firma',
  companyOnly: 'Nur Unternehmen',
  companyOnlyCardInfo:
    'Entscheiden Sie sich dafür, wenn Sie im Moment nur eine Firma hinzufügen möchten. Um das Konto des Unternehmens betriebsbereit zu machen, müssen Sie zu einem späteren Zeitpunkt manuell einen Administratorbenutzer hinzufügen und diesem Unternehmen Geräte zuweisen.',
  companyPhone: 'Telefonnummer der Firma',
  companyPhonePlaceholder: 'Geben Sie das Telefon des Unternehmens ein',
  companyPhoneTooltip:
    'Der General des Unternehmens telefonnummer.Om wenn das Unternehmen keine hat, geben Sie eine Telefonnummer des ersten Benutzers ein, der diesem Unternehmen zugewiesen wird.',
  completedProvisioningButton: 'Gehen Sie zur Plattform',
  completedProvisioningContent:
    'Sie haben den Bereitstellungsablauf des Geräts abgeschlossen. Um mit der Verwaltung des Geräts zu beginnen, müssen Sie sich bei der Smart Utilities Platform anmelden. Die Erstellung einer neuen Organisation kann bis ',
  completedProvisioningContent2: 'zu 10 Minuten dauern',
  completedProvisioningContent3: 'Bitte melden Sie sich nach dieser Zeit auf der Plattform an.',
  completedProvisioningLabel: 'Die Bereitstellung ist abgeschlossen',
  conditionalCheckerModalCheckboxLabel: 'Diese Nachricht nicht mehr anzeigen',
  conditionalCheckerModalCommonButton: ' Ok, habe es',
  conditionalCheckerModalTitleFirstPart:
    'Wir führen Wartungsarbeiten durch. Alle Systeme werden im demnächst automatisch aktualisiert',
  conditionalCheckerModalTitleSecondPart: 'std.',
  configuration: 'Konfiguration',
  contactSupport: 'Support kontaktieren',
  continue: 'Weiter',
  continueStyled: '“Fortfahren“',
  copiedMessage: 'In die Zwischenablage kopiert',
  coverTitle: 'Abdeckung',
  createAccount: 'Melden Sie sich',
  createButton: 'Schaffen',
  createMoreUsers: 'Mehr Benutzer anlegen',
  creationDate: 'Erstellungsdatum',
  currentNetworkConnectionTechnology: 'Verbindungstechnik',
  currentNetworkLastUpdated: 'Zuletzt aktualisiert',
  currentNetworkPreferedConectionTechnology: 'Bevorzugte Verbindungstechnik',
  dashBoardCardCitiesTitle: 'Standorte',
  dashBoardCardDevicesTitle: 'Geräte insgesamt',
  dashBoardCardLocationsTitle: 'Standorte insgesamt',
  dashboardCardsTitleDevices: 'Geräte insgesamt',
  dashboardCardsTitleLocations: 'Standorte Insgesamt',
  dashboardCardsTitleNoProblems: 'Keine Probleme erkannt',
  dashboardCardsTitleProblems: 'Probleme erkannt',
  dashboardCardsTitleReport: 'Ein Problem melden',
  dashboardCardsTitleTemperature: 'Durchschnittstemperatur',
  dashboardCardsTitleWarnings: 'Warnungen',
  dashboardTitle: 'Armaturenbrett',
  dataPeriodLabel: 'Daten Zeitraum',
  dataProcessingDescription: 'Verarbeitung großer Datenmengen. Warten Sie mal',
  dataProcessingTitle: 'Datenverarbeitung',
  days: 'Tage',
  daysUnit: 'Tage',
  deleteDevicesModal: 'Die Liste der ausgewählten Geräte wird gelöscht.',
  deleteModalInfoText: 'Die Liste der ausgewählten Geräte wird gelöscht.',
  deletingComment: 'Dieser Kommentar wird gelöscht.',
  deletPairModal: 'Das Schachtpaar wird dauerhaft gelöscht.',
  deliveryAddress: 'Lieferadresse',
  deploy: 'Einsetzen',
  deployed: 'Eingesetzt',
  deploying: 'Bereitstellen',
  deploymentDate: 'Bereitstellungsdatum',
  deploymentDetailsModalTitle: 'Bereitstellungsdetails',
  deploymentIsInProgress: 'Die Gerätebereitstellung wird durchgeführt. Schauen Sie später noch einmal vorbei!',
  deploymentIsInProgressTextFirst:
    'Deployment is in progress, it can take up to 48 hours. Make sure the device stays undisturbed during this time.',
  deploymentIsInProgressTextSecond:
    'In the meantime, we recommend that you \n set other parameters \n of the device, such as transmission and measurement intervals or alarm configurations. If you want to set it from the device details page later, close this window.',
  deploymentIsInProgressTitle: 'Deployment is in progress',
  deploySetDepthModalText:
    'Zuverlässige Messungen erfordern eine genaue maximale Tiefe, gemessen vom Radar bis zum tiefsten Punkt des Tanks.',
  deploySetDepthModalTitle: 'Stelle die maximale Tiefe ein',
  depth: 'Festlegen',
  depthNotice:
    'Die Tiefe des Bohrlochs ist entscheidend für korrekte Messungen. Bitte geben Sie die Tiefe so schnell wie möglich an, da sonst die Messung möglicherweise nicht korrekt ist.',
  depthUnknown: 'Tiefe ist unbekannt',
  descriptionLabel: 'Beschreibung',
  descriptionPlaceholder: 'Beschreibung eingeben',
  details: 'Einzelheiten',
  deviceDeploymentIsInProgress: 'Die Gerätebereitstellung wird durchgeführt',
  deviceDetailsGoToInsightDetails: 'Gehen sie zum Standort',
  deviceID: 'Geräte ID',
  deviceIdText: 'Geräte ID: ',
  deviceInfoLabelProperty: 'Eigentum',
  deviceIsntDeployed: 'Gerät ist nicht bereitgestellt',
  deviceLabel: 'Gerät',
  deviceName: 'Gerätename',
  directLink: 'direkte Verbindung',
  dismissableModalCommonButton: 'Lern mehr',
  dismissableModalTitle:
    'Dies ist ein Beispiel für eine vereinfachte Ansicht, die verworfen werden kann. Es ist keine Aktion erforderlich.',
  distanceToWater: 'Abstand zum Wasser/Wasserstand',
  distanceToWaterTooltip: 'Entfernung zu Wasser',
  documentsManual: 'Handbuch',
  documentsOneMore: 'Noch ein Dokument',
  documentsReleaseNote1_1: 'Versionshinweis 1.1',
  documentsReleaseNote2_0: 'Versionshinweis 2.0',
  documentsReleaseNote2_4: 'Versionshinweis 2.4',
  documentsReleaseNote2_5: 'Versionshinweis 2.5',
  documentsReleaseNote2_6: 'Versionshinweis 2.6',
  documentsSpecification: 'Smart RADAR-Spezifikation',
  download: 'Herunterladen',
  doYouWantToApply: 'Möchtest du diesen Plan-Set auf alle Geräte dieses Typs anwenden oder nur auf dieses eine?',
  dueOn: 'Fällig am',
  editDeliveryAddressTitle: 'Lieferadresse bearbeiten',
  editInvoiceTitle: 'Rechnungsdetails bearbeiten',
  editMessageTitle: 'Nachricht Bearbeiten',
  emailAddress: 'E-Mail Adresse',
  emailAddressPlaceholder: 'E-Mail-Adresse eingeben',
  emailAddressTooltip:
    'Allgemeine E-Mail-Adresse des Unternehmens.Wenn das Unternehmen keine hat, füllen Sie es mit einer E-Mail-Adresse des ersten Benutzers aus, der diesem Unternehmen zugewiesen wird.',
  emailNotificationsError: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut',
  emailNotificationsOff: 'E-Mail-Benachrichtigungen für dieses Gerät deaktiviert',
  emailNotificationsOn: 'E-Mail-Benachrichtigungen sind für dieses Gerät aktiviert',
  emphasizedHeaderTextModalDescription: 'Sie können diese Aktion nicht rückgängig machen.',
  emphasizedHeaderTextModalTitle: 'Bist du dir sicher?',
  endUserGuide: 'Endbenutzerhandbuch',
  enterInfoPlaceholder: 'Geben Sie Informationen ein',
  errorMessageForSelectAddress: 'Die Felder „Linie 1“ und „Stadt“ müssen ausgefüllt werden.',
  errorPage401Description: 'Zugang wegen ungültiger Zugangsdaten verwährt.',
  errorPage401Title: 'Unbefugt',
  errorPage403Description:
    'Sie sind leider nicht berechtigt, dieses Verzeichnis anzuzeigen. Sie können zur Hauptseite zurückkehren, wenn Sie die Zugriffsrechte haben.',
  errorPage403Title: 'Verboten',
  errorPage404Description: 'Wir können die Seite, die Sie suchen, nicht finden',
  errorPage404Title: 'Seite nicht gefunden',
  errorPage500Description:
    'Wir haben ein Problem. Bitte versuchen Sie eine dieser Optionen, um wieder auf Kurs zu kommen',
  errorPage500Title: 'Etwas ist schief gelaufen',
  errorPageMainPage: 'Hauptseite',
  errorPageReload: 'Neu laden',
  existingItem: 'Dieses Element wird verwendet und kann derzeit nicht gelöscht werden.',
  export: 'Export',
  exportButton: 'Export',
  exportExcelFileTitle: 'Excel-Datei exportieren',
  failureReasonsModalHeader: 'Ausfallgründe',
  failureReasonsModalInfo:
    'Dies sind häufige Probleme, die während des Hochladens auftreten und zum Fehlschlagen des Hochladens führen können. Überprüfen Sie Ihre Datei auf die folgenden Probleme und laden Sie sie dann erneut hoch:',
  failureReasonsModalReasonFirst: 'Passendes Problem:',
  failureReasonsModalReasonFourth: 'Die Dateigröße ist zu groß:',
  failureReasonsModalReasonSecond: 'Formatproblem:',
  failureReasonsModalReasonThird: 'Gerät nicht verfügbar:',
  failureReasonsModalSuggestionFirst:
    'Die Daten in der Datei entsprechen nicht den Anforderungen. Bitte laden Sie die Beispieldatei herunter und gleichen Sie die dort angegebenen Spalten ab.',
  failureReasonsModalSuggestionFourth:
    'Um die Dateigröße zu reduzieren, können Sie unnötige Datenwerte, Spalten und Zeilen löschen.',
  failureReasonsModalSuggestionSecond:
    'die in die Datei eingegebenen Daten können nicht verarbeitet werden. Bitte überprüfen Sie, ob die Gerätenummern dem folgenden Format entsprechen: SMC12345678900.',
  failureReasonsModalSuggestionThird:
    'Seriennummer eines Geräts ist nicht verfügbar, da es einer anderen Firma zugeordnet ist. Wenn Sie es einem neuen Unternehmen zuweisen möchten, müssen Sie es zunächst wieder Ihnen zuweisen.',
  filtersButton: 'Filter',
  filtersNotificationsError: 'Ein unerwarteter Fehler ist aufgetreten. Bitte laden Sie die Seite neu.',
  filtersSelectError: 'Fehler: keine daten',
  finalizeButton: 'Abschließen',
  firstDeviceAddress: 'Adresse von Gerät Nr. 1',
  floatingModalCommonButtonDiscard: 'Verwerfen',
  floatingModalTitle: 'Änderungen speichern?',
  flowInfo: 'Fluss-Info',
  flowLabel: 'Fließen',
  flowMeasurementBasedOn: 'Durchflussmessung basierend auf',
  flowMeasurementsLabel: 'Durchflussmessungen',
  flowUnit: 'Durchflusseinheit',
  freeFlowCircularCulvert: 'Kreisdurchlass mit freiem Durchfluss',
  fullAdditionalDescription:
    'Die Support-Gebühr ist eine monatliche Abonnementkosten, die die persönliche Einführung, dedizierte Kundenbetreuung und fortlaufende Software-Updates abdeckt. Sie wird nur für die ersten 5 bereitgestellten Geräte erhoben, unabhängig von ihrem Plan.',
  fullAddress: 'Vollständige Adresse',
  fullNameOfOrganisation: 'Vollständiger Name (oder Name der Organisation)',
  fullNameOfOrganisationPlaceholder: 'Geben Sie den vollständigen Namen (oder den Namen der Organisation) ein',
  fullyOperationalAccount: 'Voll funktionsfähiges Konto',
  fullyOperationalAccountCardInfo:
    'Schnelle und einfache Möglichkeit, ein voll funktionsfähiges Konto für ein neues Unternehmen zu erstellen. Wenn Sie einem 3-stufigen Assistenten folgen, erhalten Sie ein Konto, das von Ihrem Kunden verwendet werden kann.',
  generateButton: 'Generieren',
  generateReportModalDay: 'Heute',
  generateReportModalMonth: 'Diesen Monat',
  generateReportModalParameterCustomerPlaceholder: 'Kunde(n) auswählen',
  generateReportModalParameterLocationPlaceholder: 'Ort(e) wählen',
  generateReportModalParameterLocationPlaceholderDisabled: 'Wählen Sie zuerst den/die Kunden aus',
  generateReportModalParameterRecipients: 'Empfänger',
  generateReportModalParameterRecipientsPlaceholder: 'Empfänger auswählen',
  generateReportModalPeriod: 'Zeitraum',
  generateReportModalPeriodBorderFrom: 'Aus:',
  generateReportModalPeriodBorderTo: 'Zu:',
  generateReportModalShowMessage: 'Sie sollten wählen',
  generateReportModalWeek: 'In dieser Woche',
  generateReports: 'Bericht generieren',
  getProfilesError:
    'Ausgewählte übergeordnete Org. nicht über die Profile verfügt, wählen Sie bitte eine andere übergeordnete Organisation aus',
  getStartedButton: 'Loslegen',
  goBack: 'Zurück',
  gotIt: 'Verstanden',
  goToBillingDetails: 'Zur Rechnungsdetails gehen',
  goToInvoices: 'Gehen Sie zu Rechnungen',
  goToMain: 'Zur Hauptseite gehen',
  gpsCheck1Row:
    'Die GPS-Standortprüfung ermöglicht es, den aktuellen Standort von jemandem zu überprüfen und auf der Plattform zu speichern. Dieser Vorgang ist batterieintensiver, was die Lebensdauer des Geräts verkürzt.',
  gpsCheck2Row:
    'Dieser Vorgang entlädt den Akku stark, was die Lebensdauer des Geräts verkürzt. Daher \nempfehlen wir, die GPS-Koordinatenprüffunktion nur zweimal zu verwenden\n, wenn: ',
  gpsCheck3Row:
    '- Sie haben das Gerät in dem vorgesehenen Tank für die erste Charge installiert, bereitgestellt und kalibriert und möchten Ihnen GPS-Koordinaten als Standort des Geräts mitteilen',
  gpsCheck4Row: '- Sie können das Gerät aufgrund eines nicht autorisierten Abholereignisses nicht finden.',
  gpsCheck5Row: '\nSind Sie sicher, wann Sie mit der GPS-Positionsprüfung fortfahren möchten?\n',
  gpsCheckTooltip: 'Meer informatie over GPS-locatie',
  gpsDetailsAddressTitle: 'Vom GPS empfangene Koordinaten',
  gpsDetailsAreYouSureText: 'Elke GPS controleert de batterij diep. Weet je zeker dat je verder wilt gaan?',
  gpsDetailsAreYouSureTitle: 'Weet je het zeker?',
  gpsDetailsCheckPerformedText: 'Handmatige GPS-locatiecontroles uitgevoerd: ',
  gpsDetailsManualChecksPerformed: 'Manuelle Prüfungen durchgeführt',
  gpsDetailsRequestCheck: 'GPS-Check-Anfrage',
  gpsDetailsSaveAsMainLocText: 'Weet u zeker dat u de huidige locatie van het apparaat wilt wijzigen in deze?',
  gpsDetailsSaveAsMainLocTitle: 'Bewaar als hoofdlocatie',
  gpsDetailsSaveMainLocation: 'Juwel als Hauptstandort',
  gpsDetailsSaveMainLocationText: 'Sind Sie sicher, dass Sie hier einen aktuellen Standort ändern möchten?',
  gpsDetailsSaveMainLocationTitle: 'Juwel als Hauptstandort',
  gpsDetailsTitle: 'GPS-Standortdetails',
  gpsLocationCheckText:
    'U kunt de GPS-locatiecontrole aanvragen, die wordt uitgevoerd wanneer de volgende gegevensoverdrachtcyclus optreedt (controleer de transmissiefrequentie in de apparaatdetails). Zodra de coördinaten zijn ontvangen van GPS, zullen ze hier beschikbaar zijn.',
  gpsLocationCheckTitle: 'GPS-Standortprüfungsanfrage',
  gpsLocationProperty: 'GPS-Standort',
  GPSLocationText: 'GPS-Standortprüfung',
  gpsRequestSuccess: 'GPS-locatie is bijgewerkt',
  graphPlaceholderLabel: 'Wasserpegel',
  gravitationalCircularCulvert: 'Schwerkraft-Runddüker',
  groundMeter: 'Smart LEVEL',
  groupFieldPlaceholder: 'Select customer',
  hightText: 'Höhe',
  hourLabel: 'Stunde',
  hours: 'Std.',
  hoursUnit: 'Std.',
  humidity: 'Feuchtigkeit',
  humidityMeasurements: 'Feuchtigkeitsmessungen',
  ifItDoesntTryThis: 'Wenn dies nicht der Fall ist, versuchen sie dies',
  imagesLabel: 'Bilder (optional)',
  incidentAddressPlaceholder: 'Automatisch ausgewählt',
  incidentsNotifications: 'Benachrichtigungen über Vorfälle',
  incidentsPlaceholder: 'Schreibe etwas...',
  includedLablel: 'Inbegriffen',
  incorrectTypeOfFile: 'Falscher Dateityp. Bildformat wählen (.jpg, .png, .heic etc.)',
  individualText:
    'suche nach einem einzelnen Gerät. Sie können die gesuchten und folgenden Geräte zuweisen, indem Sie die Kästchen daneben aktivieren.',
  individualTitle: 'Individuell',
  info: 'Infos',
  infoButtonTooltip: 'Einige Infos',
  informationModalHeader: 'Einrichten eines Kontos für ein neues Unternehmen',
  informationModalInfo:
    'Opret hurtigt en ny konto til din kunde. Bare følg 3 nemme trin for at oprette en fuldt operationel konto:',
  informationModalListOfTextFirst: 'Neues Unternehmen hinzufügen;',
  informationModalListOfTextSecond: 'Benutzer innerhalb des Unternehmenskontos erstellen;',
  informationModalListOfTextThird: 'Weisen Sie dem Konto des Unternehmens Geräte zu.',
  inisghtDetailsHistorySelectLast: 'Letzte',
  inisghtDetailsHistorySelectPeriodsDays: 'Tage',
  inisghtDetailsHistorySelectPeriodsMonths: 'Monate',
  inisghtDetailsHistorySelectPeriodsWeek: 'Woche',
  inisghtDetailsHistorySelectPeriodsYear: 'Jahr',
  insightDetailsBack: 'Zurück',
  insightDetailsCommentsPlaceholder: 'Schreibe etwas...',
  insightDetailsCommentsTitle: 'Kommentare',
  insightDetailsCurrentNetworkTitle: 'Aktuelles Netzwerk',
  insightDetailsDeviceConfigAddressButtonsConfirm: 'Bestätigen',
  insightDetailsDeviceConfigAddressChooseLocation: 'Wählen sie den Standort auf der Karte aus',
  insightDetailsDeviceConfigAddressInputsCity: 'Stadt',
  insightDetailsDeviceConfigAddressInputsCountry: 'Land',
  insightDetailsDeviceConfigAddressInputsLatitudeName: 'Breite',
  insightDetailsDeviceConfigAddressInputsLatitudePlaceholder: 'Breitengrad eingeben',
  insightDetailsDeviceConfigAddressInputsLine1: 'Linie 1',
  insightDetailsDeviceConfigAddressInputsLine2: 'Linie 2',
  insightDetailsDeviceConfigAddressInputsLongitudePlaceholder: 'Längengrad eingeben',
  insightDetailsDeviceConfigAddressInputsLongotudeName: 'Längengrad',
  insightDetailsDeviceConfigAddressInputsRegion: 'Region',
  insightDetailsDeviceConfigAddressInputsZip: 'ZIP',
  insightDetailsDeviceConfigAddressSearchBarPlaceholder: 'Ort eingeben…',
  insightDetailsDeviceConfigAddressTitle: 'Geräteadresse',
  insightDetailsDeviceConfigHeaderEdit: 'Bearbeiten',
  insightDetailsDeviceConfigHeaderParam: 'Parameter',
  insightDetailsDeviceConfigHeaderValue: 'Wert',
  insightDetailsDeviceConfigRowsName: 'Gerätename',
  insightDetailsDeviceConfigRowsTransmissionFreq: 'Übertragungsfrequenz',
  insightDetailsDeviceConfigStatusChangesStagesApplied: 'Angewandt',
  insightDetailsDeviceConfigStatusChangesStagesFailed: 'Gescheitert',
  insightDetailsDeviceConfigStatusChangesStagesRequested: 'Angefordert',
  insightDetailsDeviceConfigTitle: 'Geräte konfiguration',
  insightDetailsDeviceConfigurationEditInputsAddressPlaceholder: 'Adresse eingeben',
  insightDetailsDeviceConfigurationEditInputsAddressTitle: 'Adresse',
  insightDetailsDeviceConfigurationEditInputsButtonsCancel: 'Absagen',
  insightDetailsDeviceConfigurationEditInputsButtonsSave: 'Speichern',
  insightDetailsDeviceConfigurationEditInputsNamePlaceholder: 'Name eingeben',
  insightDetailsDeviceConfigurationEditInputsNameTitle: 'Name',
  insightDetailsDeviceConfigurationEditInputsTransmissionFreqPlaceholder: 'Geben Sie die Sendefrequenz ein',
  insightDetailsDeviceConfigurationEditInputsTypeTitle: 'Typ',
  insightDetailsDeviceId: 'Geräte ID',
  insightDetailsDeviceInfoTitle: 'Informationen zum Gerät',
  insightDetailsEvent: 'Vorfall',
  insightDetailsEventData: 'Datum',
  insightDetailsEventTableHeaderEvent: 'Vorfall',
  insightDetailsEventTableHeaderTime: 'Zeit',
  insightDetailsEventTableSelectAll: 'Alle',
  insightDetailsEventTableTitle: 'Ereignis Tabelle',
  insightDetailsEventTime: 'Zeit',
  insightDetailsGeneralReportsButton: 'Bericht generieren',
  insightDetailsGoToDeviceButton: 'Gehen sie zum Gerät',
  insightDetailsGoToFlowButton: 'Gehen sie zum fluss',
  insightDetailsHistoryTitle: 'Geschichte',
  insightDetailsNetworkEvent: 'Netzwerkereignis-Tabelle',
  insightDetailsRadioModulesLabel: 'RADIOMODULE',
  insightDetailsRadioModulesTitle: 'Funkmodule',
  insightDetailsRealTimeCardsTemperature: 'Temperatur',
  insightDetailsRealTimeCardsWater: 'Entfernung zum Wasser',
  insightDetailsRealTimeTitle: 'Echtzeit',
  insightDetailsStatusChangesButton: 'Ich hab es',
  insightDetailsStatusChangesStagesCurrent: 'Aktuell: ',
  insightDetailsStatusChangesTitlesDeviceName: 'Gerätename',
  insightDetailsStatusChangesTitlesTransmissionFreq: 'Übertragungshäufigkeit des Geräts',
  insightDetalisEventDateAndTime: 'Datum & Zeit',
  insightMobilePlaceholder: 'Wähle einen Ort…',
  insightPlaceholder: 'Suchen sie nach etwas',
  insightSearchPlaceholder: 'Wonach suchst du?',
  insightSearchResultsNoResults: 'Keine Ergebnisse',
  insightSearchResultsResult: 'Ergebnis',
  insightSearchResultsResults: 'Ergebnisse',
  insightTitle: 'Einblick in Echtzeit',
  insightToggleList: 'Listenansicht',
  insightToggleMap: 'Karten ansicht',
  internalHumidityTitle: 'Innere Feuchtigkeit',
  internalTemperature: 'Innentemperatur',
  invoiceDetails: 'Rechnungs-Details',
  invoiceID: 'Rechnungs-ID',
  invoices: 'Rechnungen',
  labelReportsTableLocation: 'Ort',
  lastEventLabel: 'Letzte Veranstaltung',
  lastSeenOnline: 'Zuletzt online gesehen',
  lastSeenTitle: 'Zuletzt gesehen',
  levels: 'Ebenen',
  linkAccount: 'Konto verbinden',
  linkPaymentMethod: 'Zahlungsmethode verknüpfen',
  locationPopupCityPlaceholder: 'Stadt betreten',
  locationPopupCountryPlaceholder: 'Land eingeben',
  locationPopupDescription: 'Standort auf der Karte auswählen',
  locationPopupLine1Placeholder: 'Geben sie Zeile 1 ein',
  locationPopupRegionPlaceholder: 'Region eingeben',
  locationPopupTitleDevices: 'Geräteadresse',
  locationPopupZipPlaceholder: 'Geben sie ZIP ein',
  maintenanceTitle: 'Wartung',
  manageAddButton: 'Hinzufügen',
  manageClientEditAddressSearchBarPlaceholderAddress: 'Adresse eingeben…',
  manageClientEditAddressTitle: 'Kundenadresse',
  manageClientsEditClientInputsNameTitle: 'Kundenname',
  manageClientsEditClientTitle: 'Kunde bearbeiten',
  manageClientsMultipleDeleteModalButtonsNo: 'Nein, abbrechen',
  manageClientsMultipleDeleteModalButtonsYes: 'Ja, mach es',
  manageClientsMultipleDeleteModalDescription: 'Diese Kunden werden dauerhaft gelöscht: ',
  manageClientsMultipleDeleteModalTitle: 'Bist du dir sicher?',
  manageClientsSingleDeleteModalDescription: 'Dieser Kunde wird dauerhaft gelöscht: ',
  manageClientsTableHeaderDeleteButton: 'Löschen',
  manageClientsTableHeaderDeleteSelected: 'Ausgewählt: ',
  manageClientsTableHeaderItemsAddress: 'Adresse',
  manageClientsTableHeaderItemsClient: 'Organisation',
  manageClientsTableHeaderItemsEmail: 'Email',
  manageClientsTableHeaderItemsName: 'Name',
  manageClientsTableHeaderItemsPhone: 'Telefonnummer',
  manageClientsTableHeaderItemsPlace: 'Ort',
  manageClientsTableRowPlaceholdersAddress: 'Adresse eingeben',
  manageClientsTableRowPlaceholdersEmail: 'Email eingeben',
  manageClientsTableRowPlaceholdersPhone: 'Geben Sie Telefon ein',
  manageDeleteSemiTitle: 'Diese Entitäten werden dauerhaft gelöscht:',
  manageDevicesChooseClientPlaceholder: 'Wählen sie Kunde aus',
  manageDevicesEditDevicesTitle: 'Gerät bearbeiten',
  manageDevicesNoCLients: 'Es sind keine Kunden vorhanden',
  manageDevicesPlace: 'Ort eingeben',
  manageIncidentsAddButton: 'Hinzufügen',
  manageUsersAddUserModalInputsClientPlaceholder: 'Wählen',
  manageUsersAddUserModalInputsRoleTitle: 'Rolle',
  manageUsersEditUserModalTitle: 'Benutzer bearbeiten',
  manageUsersMultipleDeleteModalDescription: 'Diese Nutzer werden endgültig gelöscht: ',
  manageUsersSelectAll: 'Alle Kunden',
  manageUsersSingleDeleteModalDescription: 'Dieser Nutzer wird endgültig gelöscht: ',
  manholeModalName: 'Mannloch',
  manholes: 'mannlöcher',
  manningsPair: "Manning's Paar",
  manufacturingDate: 'Herstellungsdatum',
  meaningLabel: 'Bedeutung',
  measurementDeviceDetailsTableName: 'Name',
  measurementItem: 'Durchflussmessung',
  measurementsFromDevice: 'Messungen des Geräts',
  measurementTitle: 'Durchflussmessung',
  messageAboutSuccessfullyDeleted: 'Erfolgreich gelöscht',
  minMeasurmentIntervals: 'Minimale Messintervalle',
  mins: 'Min',
  minsUnit: 'Min',
  minTransmissionIntervals: 'Mindestübertragungsintervalle',
  mobileEditPopupChooseClient: 'Kunde organisation',
  mobileEditPopupChooseRole: 'Rolle wählen',
  mobileModalEditAddClientTitle: 'Neuen Kunden hinzufügen',
  mobileModalEditAddUserTitle: 'Neuen Benutzer hinzufügen',
  modalSystemSelectPlaceholder: 'Daten sind leer',
  modalTitleAdd: 'Hinzufügen',
  modalTitleView: 'Aussicht',
  monthlyLabel: 'Monatlich',
  moreDetailsButton: 'Mehr Details',
  multipleUsersCanBeAdded:
    'Innerhalb eines Unternehmens können\nmehrere Benutzer hinzugefügt werden. Wenn Sie weitere Benutzer hinzufügen, gewähren Sie ihnen Zugang zur Plattform, um die Leistung der Geräte zu verfolgen.',
  nameSurnamePlaceholder: 'Geben Sie Vor- und Nachnamen ein',
  navigationClients: 'Organisationen',
  navigationDevices: 'Geräte',
  navigationDocuments: 'Unterlagen',
  navigationIncidents: 'Vorfälle',
  navigationInsight: 'Einblick',
  navigationLogout: 'Ausloggen',
  navigationManage: 'Verwalten',
  navigationNotifications: 'Benachrichtigungen',
  navigationNotificationsTimeAgo: 'vor',
  navigationNotificationsTimeHr: 'Std',
  navigationNotificationsTimeMin: 'min',
  navigationReports: 'Berichte',
  navigationSettings: 'Einstellungen',
  navigationUsers: 'Benutzer',
  nextButton: 'Nächste',
  noButton: 'Nein',
  noDataPlaceholderText: 'Keine Daten',
  noReportsPlaceholder: 'Noch keine berichte',
  notDeployed: 'Nicht bereitgestellt',
  noTestInformation: 'Für dieses Gerät sind keine QA-Informationen verfügbar',
  noteText: 'Notiz',
  notice: 'Notiz',
  notOkTitle: 'Nicht ok',
  oops: 'Ups!',
  openText: 'Offen',
  optionsDialogueModalDescription: 'Willst du fortfahren?',
  optionsDialogueModalTitle: 'Elemente werden in den geheimen Ordner verschoben.',
  organisationAddress: 'Anschrift der Organisation',
  organisationAddressLabel: 'Adresse der Organisation',
  organisationCreationSuccess: 'Organisation erfolgreich erstellt',
  organisationDetailsTitleInfoTable: 'Organisationsinfo',
  organisationLabel: 'Organisation',
  organisationsName: 'Name der Organisation',
  organisationsNamePlaceholder: 'Name der Organisation eingeben',
  outletPressure: 'Ausgangsdruck',
  outletTemperature: 'Austrittstemperatur',
  outsidePressure: 'Druck von außen',
  outsideTemperature: 'Außentemperatur',
  overdue: 'Überfällig',
  overflowBasedOn: 'Überlauf basiert auf',
  overflowLabel: 'Überlauf',
  pageNotFound: 'Seite nicht gefunden.',
  paginationButtonNext: 'Nächste',
  paginationButtonPrevious: 'Vorher',
  paginationGo: 'Gehen',
  paginationGoTo: 'Gehe zu',
  paid: 'Bezahlt',
  pairConfiguration: 'Pair-Konfiguration',
  pairId: 'Paar-ID',
  pairInfo: 'Paarinfo',
  pariedManholeButton: 'Gehen Sie zum Koppeln',
  pariedManholegraphLabel: 'Wasserfluss',
  pariedManholeModalName: 'Gepaarte Schächte',
  passed: 'Bestanden',
  paymentDetails: 'Zahlungsdetails',
  paymentMethodHasBeenSuccessfullySaved: 'Zahlungsmethode wurde erfolgreich gespeichert',
  pending: 'Ausstehend',
  perDevice: 'pro Jahr',
  perMonth: 'pro Monat',
  personnelLabel: 'Personal',
  personnelPlaceholder: 'Personal eingeben',
  perYear: 'pro Jahr',
  phisicalId: 'Physische ID',
  photosTitle: 'Fotos',
  pickSubscriptionPlan: 'Wählen Sie einen Abonnementplan',
  pickSubscriptionPlanSub1:
    'Mit der Wahl eines Plans abonnieren Sie eine Reihe von Funktionen auf der Smart Utilities Platform.',
  pickSubscriptionPlanSub2: 'Sie müssen für jedes Gerät, das Sie bereitstellen, einen Plan wählen.',
  pickUpEventText: 'Pick-up event',
  placeholderNoData: 'Keine Daten',
  plans: 'Pläne',
  plansetWasAppliedToAllDevices: 'Planset was applied to all devices of this type',
  plansetWasAppliedToDevices: 'Planset was applied to the device',
  pleaseFillOutBillingInformation:
    'Bitte füllen Sie die Rechnungsinformationen aus, um vollen Zugriff auf die Plattform zu erhalten. Aktualisieren Sie die Seite nach dem Ausfüllen.',
  pointIntervalLabel: 'Punkt Intervall',
  premiumLabel: 'Premium',
  pressToLearnMoreTooltip: 'Drücken Sie, um mehr zu erfahren',
  privacyPolicy: 'Datenschutzbestimmungen',
  problemsButtonDetails: 'Einzelheiten',
  problemsButtonHighTemperature: 'Hohe temperatur',
  problemsDate: 'Datum',
  problemsOf: 'von',
  problemsPlace: 'Ort',
  problemsProblem: 'Problem',
  problemsShowing: 'Anzeigen',
  profileLabel: 'Profil',
  profilePlaceholder: 'Profil wählen',
  provisioned: 'Bestimmung',
  provisionedOnLabel: 'Bereitgestellt am',
  provisioning: 'Bereitgestellt',
  provisioningAddingDevice: 'Hinzufügen eines neuen Geräts',
  provisioningAddress: 'Adresse',
  provisioningChooseOrganisation: 'Wählen Sie eine Organisation',
  provisioningContent:
    ' an und folgen Sie dem Pfad. Nachdem Sie Ihr Konto verifiziert haben, kehren Sie zu dieser Seite zurück, um sich anzumelden und mit dem Bereitstellungsprozess fortzufahren.',
  provisioningContent2:
    'um mit der Bereitstellung fortzufahren. Möglicherweise werden Sie aufgefordert, sich bei Ihrem Konto anzumelden.',
  provisioningContent3:
    'Geben Sie den 10-stelligen Code aus der Anleitung ein, wenn Sie sich auf dem Desktop befinden. Wenn Sie einen QR-Code gescannt haben, gelangen Sie direkt zum nächsten Schritt.',
  provisioningContent4: ' aus den verfügbaren Optionen aus (wenn Sie zuvor zur Plattform hinzugefügt wurden) oder ',
  provisioningContent5:
    ' (wenn Sie sich gerade angemeldet haben). Das Gerät, das Sie bereitstellen, wird dieser Organisation zugewiesen.',
  provisioningCreateOrg: 'erstellen Sie eine neue Organisation',
  provisioningDate: 'Bereitgestelltdatum',
  provisioningDeployNow: 'Jetzt bereitstellen (Sie können es später jederzeit bereitstellen)',
  provisioningDescribe:
    'Durch die Bereitstellung eines Geräts weisen Sie es Ihrer Organisation auf der Smart Utilities Platform zu. Der Prozess unterscheidet sich, ob Sie bereits ein Konto auf unserer Plattform haben oder nicht.',
  provisioningDetails: 'Bereitstellungsdetails',
  provisioningDeviceInstalmentLocationOptional: 'Speicherort der Geräteinstallation (optional)',
  provisioningErrorAssign: 'Fehler beim Zuweisen',
  provisioningFinish: 'Fertig',
  provisioningInOrderForDevice:
    'Damit das Gerät betriebsbereit ist und Messungen durchführt, muss es eingesetzt werden. Es wird empfohlen, das Gerät nach der Installation einzusetzen. Hinweis: Die Bereitstellung kann bei guten Netzwerkbedingungen bis zu 24 Stunden dauern (mehr bei schlechten)',
  provisioningInstructions: 'Anweisungen',
  provisioningOrganisation: 'Organisation',
  provisioningRow: 'Wenn Sie noch kein Konto haben:',
  provisioningRow2: 'Wenn Sie bereits ein Konto auf der Plattform haben:',
  provisioningSetDepthModalTitle: 'Maximale Tiefe',
  provisioningSuccessfullyAssign: 'Gerät erfolgreich bereitgestellt',
  provisioningTooltipOrganisations:
    'Die Organisation ist eine übergeordnete Instanz für Benutzer und Geräte, sie fungiert als deren Aggregator. Wenn Sie ein Privatkunde sind, können Sie Ihre eigenen Daten eingeben.',
  pumpingStationAndTank: 'Pumpstation und Tank',
  qualityControl: 'Qualitätskontrolle',
  quickstartGuide: 'Schnellstartanleitung',
  radioModulesFirmwareRevision: 'Firmware Revision',
  radioModulesManufacturer: 'Hersteller',
  radioModulesTypeModel: 'Typ/Modell',
  rangeText:
    'Suchen Sie nach einer Reihe von Geräten, indem Sie die erste und letzte Seriennummer aus einer Produktionscharge eingeben, die Sie zuordnen möchten. Alle Geräte aus diesem Bereich werden automatisch überprüft, Sie können diejenigen deaktivieren, die Sie ausschließen möchten.',
  rangeTitle: 'Bereich',
  reload: 'Aktualisieren Sie die Seite',
  reportProblemsModalDescription: 'Geben sie eine beschreibung eines problems ein',
  requestedValue: 'Angeforderte Wertänderung auf',
  resolveButton: 'Beschließen',
  resolvedButton: 'Beschlossen',
  resolvedSuccessfullyMessage: 'Erfolgreich gelöst',
  resolveIncidentTitle: 'Vorfall lösen',
  roleAdmin: 'Admin',
  roleLabel: 'Rolle',
  roleUser: 'Benutzer',
  sameAsBillingInfo: 'Wie Rechnungsinformationen',
  sampleButton: 'Probe',
  saveButton: 'Speichern',
  scanCuccessful: 'Scannen erfolgreich',
  searchBarPlaceholder: 'Suche...',
  secondDeviceAddress: 'Adresse von Gerät Nr. 2',
  selectButtonValue: 'Auswählen',
  selectDevicePlaceholder: 'Gerät wählen',
  selectedDatesLabel: 'Ausgewählte Termine',
  selectedTitle: 'Ausgewählt',
  selectPeriodLastDays: 'Letzte 30 Tage',
  selectPeriodLastMonths: 'Letzte 6 monate',
  selectPeriodLastWeek: 'Letzte Woche',
  selectPeriodLastYear: 'Vergangenes jahr',
  selectValuePlaceholder: 'Wert wählen',
  setParameters: 'Set parameters',
  severityHigh: 'Hoch',
  severityLabel: 'Schwere',
  severityLow: 'Niedrig',
  severityMedium: 'Medium',
  sewageLevelTitle: 'Abwasserpegel (%)',
  shortAdditionalDescription: 'gilt nur für die \nersten 5 Geräte\n zu diesem Preis pro Gerät pro Monat',
  skipButton: 'Überspringen',
  skipButtonAddDeviceModal: 'Skip',
  smartHydrant: 'Smarte HYDRANT',
  smartRadar: 'Intelligentes RADAR',
  smartRadarAlarm: 'Intelligentes ALARM',
  somethingWentWrong: 'Etwas ist schief gelaufen',
  sorryWeCouldNotFindThePage: 'Entschuldigung, wir konnten die Seite nicht finden!',
  sourceLabel: 'Quelle',
  sourceTitle: 'Quelle',
  specialInputPlaceholder: 'Alles, was sie wollen, kann hier sein',
  specialInputTitle: 'Spezielle Eingabe',
  standingWater: 'Stehendes Wasser',
  standingWaterDetection: 'Erkennung von stehendem Wasser',
  startButton: 'Anfang',
  startDateLabel: 'Anfangs Datum',
  startLabel: 'Anfang',
  startTimeLabel: 'Startzeit',
  startTimePlaceholder: 'Start zeit eingeben',
  statusLabel: 'Status',
  statusPlaceholder: 'Status eingeben',
  subcribeButton: 'Abonnieren',
  submergeInWater: 'Eintauchen in Wasser',
  submergeInWaterDetection: 'Erkennung des Eintauchens in Wasser',
  submit: 'Einreichen',
  subscribeTooltip: 'E-Mail-Benachrichtigungen abbestellen',
  subscriptionContent1:
    'Wenn Ihre Organisation eine Zahlungsmethode und -politik auf der SUP-Plattform ausgewählt hat, werden diese auf dieses Gerät angewendet.',
  subscriptionContent2:
    'Wenn nicht, müssen Sie diese bei der Anmeldung auf der Plattform angeben. Erst dann haben Sie Zugang zu den Funktionen.',
  subscriptionPlan: 'Abonnementplan',
  success: 'Erfolg',
  successfullSavingMessage: 'Erfolgreich gespeichert',
  successMessageUploadingPhoto: 'Das Foto wird hochgeladen!',
  successSaveMessage: 'Erfolgreich gespeichert',
  summaryAddressInput: 'Firmenadresse',
  summaryAddressPlaceholder: 'Geben Sie die Firmenadresse ein',
  summaryCompanyInput: 'Firmenname',
  summaryCompanyPlaceholder: 'Geben Sie den Firmennamen ein',
  summaryCompanyRowTitle: 'Gesellschaft',
  summaryDevicesRowTitle: 'Geräte',
  summaryEmailInput: 'E-Mail-Addresse',
  summaryEmailPlaceholder: 'Geben Sie die E-Mail-Adresse des Unternehmens ein',
  summaryPhoneInput: 'Firmentelefon',
  summaryPhonePlaceholder: 'Geben Sie die Telefonnummer des Unternehmens ein',
  summarySubtitle:
    'Bitte überprüfen Sie, ob alle Informationen korrekt sind, und schließen Sie dann ab, indem Sie das neue Unternehmen mit zugewiesenen Benutzern und Geräten speichern.',
  summaryTitle: 'Zusammenfassung',
  summaryUsersRowTitle: 'Benutzer',
  symbolLabel: 'Symbol',
  tableInputErrorMessage: 'Bitte überprüfen Sie den Wert. Es ist nicht gültig',
  tableLableDatePlaceholder: 'Datum eingeben',
  tamperDetection: 'Sabotageerkennung',
  taxesLabel: 'exkl. anwendbarer Steuern',
  temperatureLowCase: 'Temperatur',
  temperatureMeasurements: 'Temperaturmessungen',
  temperatureTooltip: 'Temperatur',
  termsOfService: 'Nutzungsbedingungen',
  textFieldEmphasizedHeaderTextModalDescription: 'Infotext, der den Benutzer über die Folgen der Aktion informiert.',
  textFieldEmphasizedHeaderTextModalInputName: 'Geben Sie den Namen des Geräts ein, um die Aktion zu bestätigen',
  textFieldEmphasizedHeaderTextModalTitle: 'Geräterechte entziehen?',
  theDownloadWillStartShortly: 'Der Download startet in Kürze',
  thePhotoWillBeDeleted: 'Das Foto wird dauerhaft gelöscht.',
  thisDeviceButton: 'Dieses Gerät',
  timePlaceholder: 'Uhrzeit eingeben (hh:mm)',
  tooltipAddDevicesManually:
    'Dieses Gerät ist einer anderen Firma zugeordnet. Wenn Sie es einem neuen Unternehmen zuweisen möchten, müssen Sie es zuerst wieder Ihnen zuweisen, auf die Schaltfläche „Aktualisieren“ klicken und es dann dem neuen Unternehmen zuweisen.',
  toProceedEveryDevice: 'Um fortzufahren, muss für jedes Gerät ein Plan-Set ausgewählt werden',
  total: 'Gesamt',
  totalNumberOfDevices: 'Gesamtzahl der Geräte',
  unexpectedErrorHasOccurred: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut',
  unsubcribeButton: 'Abbestellen',
  unsubscribeTooltip:
    'Melden Sie sich an, um eine E-Mail-Benachrichtigung zu erhalten, wenn das Gerät abnormale Werte erkennt (z. B. wenn der kritische Temperaturschwellenwert erreicht wird).',
  uploadDevicesInputTextFirstPart: 'Datei wählen',
  uploadDevicesInputTextSecondPart: 'oder hierher ziehen (.csv oder .xlsx)',
  uploadDevicesInputTextSecondPartMobile: '(.csv oder .xlsx)',
  uploadDevicesModalFailedUploads: 'Fehlgeschlagene Uploads:',
  uploadDevicesModalSuccessfullyUploadedDevices: 'Erfolgreich hochgeladene Geräte:',
  uploadDevicesModalTryAgain: 'Versuchen Sie es nochmal',
  uploadDevicesModalUploadingFile: 'Datei wird hochgeladen...',
  uploadDevicesTitle: 'Geräte hochladen',
  uploadFileAddDeviceModal: 'Datei hochladen',
  uploadFileDescAddDeviceModal:
    'importieren Sie eine CSV/XLSX-Datei mit allen Seriennummern der Geräte, die Sie dieser Firma zuweisen möchten',
  uploadFilesModalDescriptionFirstPart: 'Datei wählen',
  uploadFilesModalDescriptionSecondPart: 'oder hierher ziehen',
  uploadFilesModalListTitle: 'Hochgeladene Dateien',
  uploadFilesModalTitle: 'Firmware hochladen',
  uploadFileTooltipAddDeviceModal:
    'Empfohlen für das gleichzeitige Hinzufügen von mehr als 10 Geräten aus verschiedenen Produktionschargen.',
  userCompanyLabel: 'Gesellschaft',
  userDeleteInfo: 'Der Benutzer wird dauerhaft gelöscht.',
  userEmailLabel: 'E-Mail-Addresse',
  userEmailPlaceholder: 'E-Mail Adresse eingeben',
  userNameLabel: 'Benutzername',
  usersAddedByLabel: 'Hinzugefügt von',
  usersDateOfRegistrationLabel: 'Datum der Registrierung',
  usersFirstLoginLabel: 'Erste Anmeldung',
  usersName: 'Name des Benutzers',
  usersParentCustomerLabel: 'Mutterorganisation',
  usersParentOrganisationPlacholder: 'Übergeordnete Organisation',
  validationFillFieldsRequest: 'Please, correctly fill in all the fields',
  validationMessage: ' sieht nicht richtig aus',
  valueWillBeUpdated: 'Der Wert wird aktualisiert, wenn das Gerät das nächste Mal sendet',
  VATnumber: 'Umsatzsteuer-Identifikationsnummer',
  vatNumberErrorText:
    'Die von Ihnen angegebene Umsatzsteuer-Identifikationsnummer ist im VIES-System ungültig. Bitte überprüfen und aktualisieren Sie.',
  VATnumberPlaceholder: 'Umsatzsteuer-Identifikationsnummer eingeben',
  view: 'Sicht',
  vWeirLabel: 'V-Wehr',
  waterLevelTooltip: 'Wasserpegel',
  waterMeasurementsLabel: 'Wassermessungen',
  weAreWorkingOnTheFixing: 'Wir arbeiten daran, das Problem zu beheben. Bitte versuche es erneut',
  weCantFindThePage: 'Wir können die von Ihnen gesuchte Seite nicht finden',
  weiter: 'Weiter',
  wereHavingAnIssue: 'Wir haben ein Problem. Bitte versuchen Sie eine dieser Optionen, um wieder auf Kurs zu kommen',
  wereSorry: `Es tut uns leid, aber beim Laden dieser Seite ist etwas schiefgegangen. Bitte versuchen Sie es erneut`,
  wereSorry2: `Es tut uns leid, aber es scheint, dass wir die von Ihnen gesuchte Seite nicht finden können`,
  whatAreYouLookingFor: 'Wonach suchst du?',
  wrongFileFormat: 'Falsches Dateiformat. Bitte verwenden Sie .csv oder .xlsx',
  yearlyLabel: 'Jährlich',
  yesButton: 'Ja',
};
