import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const TooltipWrapper = styled.div`
  width: fit-content;
  max-width: 186px;
  height: fit-content;
  padding: 10px 14px;
  margin: 0;

  background-color: ${colorFetch('black1')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.additional4};

  outline: none;
`;

type ArrowType = {
  tooltipArrowOffset?: number;
};

export const Arrow = styled.div<ArrowType>`
  width: 0;
  height: 0;
  margin-bottom: -1px;
  margin-left: calc(50% - 8px + ${({ tooltipArrowOffset }) => tooltipArrowOffset + 'px'});

  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #28293d;

  z-index: -1;
`;

export const StyledText = styled(Text)`
  text-align: center;
`;

export const YAxisData = styled(StyledText)`
  font-weight: 600;
  line-height: 21px;
`;

export const XAxisData = styled(StyledText)`
  font-weight: 400;
  line-height: 21px;
`;

export const Info = styled(StyledText)`
  width: 158px;

  line-height: 18px;
`;
