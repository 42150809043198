import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDeviceRow } from '../../../../../api/realTimeInsight';
import styled from '@cthings.co/styled-components';
import {
  subscribeToDevice,
  unsubscribeToDevice,
  updateDeviceDetails,
  useCurrentNetwork,
  useDeviceQualityTests,
} from '../../../../../api/manage/manageDevices';
import { useRealTimeInsights } from '../../../../../api/realTimeInsight';
import { selectAccess, selectIsAccess, selectLanguageStrings } from '../../../../../app/state/user';
import { useLoaderFunctions } from '../../../../../features/placeholderComponent/loader.context';
import { DeviceInfo } from './components/deviceInfo/DeviceInfo';
import { RadioModules } from './components/radioModules/RadioModules';
import { NetworkEvent } from './components/networkEvent/NetworkEvent';
import { CurrentNetworkTable } from './components/currentNetwork/CurrentNetworkTable';
import {
  updateRealTimeAddress,
  resetRealTimeAddress,
  selectDeviceInfo,
  selectRealTimeData,
  setDeviceInfoName,
  selectAddress,
  setDeviceInfo,
} from '../../../../../app/state/insight';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { SelectAddress } from '../../../../../components/selectAddress/SelectAddress';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { PATHS } from '../../../../../routes/paths';
import { useLoggedUserInfo } from '../../../../../api/manage/manageUsers';
import { QualityModal } from './components/qualityModal/QualityModal';
import { TitleBlock } from './components/titleBlock/TitleBlock';
import { DeploymentDetailsInfoType } from './components/deviceInfo/components/infoContentTable/components/deploymentDetailsModal/DeploymentDetailsModal';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { useParams } from '@cthings.co/router-dom';
import { DeviceConfigurationUT } from './components/deviceConfigurationUT/DeviceConfigurationUT';
import { media } from '@cthings.co/styles-utils';

export enum DeviceDetailsType {
  TRACKER = 'TRACKER',
  NODE = 'NODE',
}

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 40px;
  &.noScroll {
    height: 100vh;
    overflow: hidden;
  }
  ${media.tablet} {
    padding: 0 16px 32px;
    gap: 30px;
  }
  ${media.phone} {
    padding: 0 16px 24px;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  &.custom {
    ${media.wideMonitorS} {
      grid-template-columns: 1fr;
    }
  }

  ${media.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const DeviceDetails = () => {
  const customHistory = useCustomHistory();
  const dispatch = useDispatch();
  const pathParams = useParams();
  const id = pathParams?.id || '';
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();

  const packagesKeyword = 'PackagesInfoTable';

  const remainingKeywords = [
    'InsightDetailsMap',
    'InsightDetailsProblemsButton',
    'InsightDetailsCard',
    'InsightDetailsGraph',
    'InsightDetailsDeviceConfigurationTable',
  ];

  const keywordList = [packagesKeyword, ...remainingKeywords];

  useRealTimeInsights(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );

  const data = useSelector(selectRealTimeData);

  const address = useSelector(selectAddress);

  const [selectAddressData, setSelectAddressData] = useState({
    line1: '',
    line2: '',
    city: '',
    region: '',
    country: '',
    zip: '',
    geotag: { lat: '0', lng: '0', alt: '0' },
  });

  useEffect(() => {
    address && setSelectAddressData(address);
  }, [address]);

  const dataCurrentNetwork: any = useCurrentNetwork(id);
  const languageStrings = useSelector(selectLanguageStrings);
  const configTimerId = useRef(null as any);
  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState(false);

  const deviceInfoData = useSelector(selectDeviceInfo);

  const { addNotification } = useNotifierFunctions();
  const accessData = useSelector(selectAccess).management.devices;
  const isDevicesAccessManagementView = accessData.view;
  const isAccess = useSelector(selectIsAccess);
  const idDevice = data?.info?.id;
  // const params = useDeviceParameters(idDevice);

  const [deviceName, setDeviceName] = useState('');
  const [userDeviceIds, setUserDeviceIds] = useState<string[]>();
  const [subscribeError, setSubscribeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useLoggedUserInfo();
  const isSubscribed = userDeviceIds && userDeviceIds.includes(id);
  const [isQualityModalOpen, setIsQualityModalOpen] = useState(false);
  const deviceTests: any = useDeviceQualityTests(id);
  const popupTitle = languageStrings.insightDetailsDeviceConfigurationEditInputsAddressTitle;

  const deploymentDetailsArr: DeploymentDetailsInfoType[] = [
    {
      fieldName: data?.info?.first_provisioning?.user_info.label,
      tooltipText: data?.info?.first_provisioning?.user_info.tooltip,
      value: data?.info?.first_provisioning?.user_info.value,
    },
    {
      fieldName: data?.info?.first_provisioning?.ts_info.label,
      tooltipText: data?.info?.first_provisioning?.ts_info.tooltip,
      value: data?.info?.first_provisioning?.ts_info.value
        ? getRequiredDateFormat(data?.info?.first_provisioning?.ts_info.value, 'DD.MM.YYYY')
        : null,
    },
    {
      fieldName: data?.info?.first_deployment?.user_info.label,
      tooltipText: data?.info?.first_deployment?.user_info.tooltip,
      value: data?.info?.first_deployment?.user_info.value,
    },
    {
      fieldName: data?.info?.first_deployment?.ts_info.label,
      tooltipText: data?.info?.first_deployment?.ts_info.tooltip,
      value: data?.info?.first_deployment?.ts_info.value
        ? getRequiredDateFormat(data?.info?.first_deployment?.ts_info.value, 'DD.MM.YYYY')
        : null,
    },
    {
      fieldName: data?.info?.first_deployment?.success_ts_info.label,
      tooltipText: data?.info?.first_deployment?.success_ts_info.tooltip,
      value: data?.info?.first_deployment?.success_ts_info.value
        ? getRequiredDateFormat(data?.info?.first_deployment?.success_ts_info.value, 'DD.MM.YYYY')
        : null,
    },
  ];

  const handleCallbackNotification = (isSuccess: boolean, messageText?: string) => {
    const successMessage = isSuccess && languageStrings.successfullSavingMessage;
    const errorMessage = messageText ?? languageStrings.somethingWentWrong;
    addNotification({
      message: successMessage || errorMessage,
      type: isSuccess ? 'success' : 'error',
    });
  };

  const resetAddress = () => {
    dispatch(resetRealTimeAddress());
  };

  const changeValueTextarea = useCallback(
    (parameter_id: string, val: string | any[], handleRevert?: () => void, setValueByAnswer?: (value: any) => void) => {
      configTimerId.current && clearTimeout(configTimerId.current);
      configTimerId.current = setTimeout(() => {
        updateDeviceRow(id, val, addNotification, languageStrings, handleRevert, setValueByAnswer);
      }, 1500);
    },
    [configTimerId],
  );

  const changeTitleTextarea = useCallback(
    (parameter_id: string, val: string) => {
      dispatch(setDeviceInfoName({ name: val }));
      configTimerId.current && clearTimeout(configTimerId.current);
      configTimerId.current = setTimeout(() => {
        updateDeviceRow(id, val, addNotification, languageStrings);
      }, 1500);
    },
    [configTimerId],
  );

  const setAddress = (value: any) => {
    setSelectAddressData(value);
  };

  const handleUpdateDevice = () => {
    dispatch(updateRealTimeAddress(selectAddressData));
    updateDeviceDetails({
      deviceId: idDevice,
      body: { ...deviceInfoData, address: selectAddressData },
      handleSuccess: (data: any) => {
        dispatch(setDeviceInfo({ data: data.info }));
        addNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
      },
      handleError: (data: any) => addNotification({ message: `${data?.response?.data?.detail}`, type: 'error' }),
    });
  };

  const onSubscribeBtnPress = () => {
    setIsLoading(true);
    isSubscribed
      ? unsubscribeToDevice(idDevice, languageStrings, addNotification, setUserDeviceIds, setSubscribeError)
      : subscribeToDevice(idDevice, languageStrings, addNotification, setUserDeviceIds, setSubscribeError);
  };

  const handleQualityModal = () => {
    setIsQualityModalOpen((value) => !value);
  };

  useEffect(() => {
    if (isAccess && !isDevicesAccessManagementView) {
      customHistory.push(PATHS.ERROR_401);
    }
  }, [isAccess, isDevicesAccessManagementView]);

  useEffect(() => {
    return () => {
      resetAddress();
    };
  }, []);

  useEffect(() => {
    if (data.info.name) {
      setDeviceName(data.info.name);
    }
  }, [data]);

  useEffect(() => {
    if (userInfo && userInfo.subscribed_devices) {
      setUserDeviceIds(userInfo.subscribed_devices);
    }
  }, [userInfo]);

  useEffect(() => {
    subscribeError && setSubscribeError(false);
    isLoading && setIsLoading(false);
  }, [userDeviceIds, subscribeError]);

  return (
    <Wrapper>
      <>
        <TitleBlock
          id={id}
          deviceName={deviceName}
          params={'params'}
          accessData={accessData}
          isLoading={isLoading}
          isShowSubscribeButton={!(data?.info?.type === 'smart_cap')}
          isSubscribed={!!isSubscribed}
          setDeviceName={setDeviceName}
          onSubscribeBtnPress={onSubscribeBtnPress}
          changeTitleTextarea={changeTitleTextarea}
        />
        <TableWrapper>
          <DeviceInfo
            data={deviceInfoData}
            isOpenAddressPopup={isOpenAddressPopup}
            setIsOpenAddressPopup={(value: boolean) => setIsOpenAddressPopup(value)}
            accessData={accessData}
            handleQualityModal={handleQualityModal}
            deploymentDetailsinfoArr={deploymentDetailsArr}
          />
          <RadioModules data={data?.data?.radio_module || []} />
        </TableWrapper>
        <TableWrapper className="custom">
          <DeviceConfigurationUT />
          <CurrentNetworkTable data={dataCurrentNetwork || {}} />
        </TableWrapper>
        <NetworkEvent keyWords={remainingKeywords} id={id} pathParams={pathParams} />
        {data.data.config.length && isOpenAddressPopup && (
          <SelectAddress
            state={TableCustomComponentState.EDIT}
            address={selectAddressData}
            titleOfPopup={popupTitle}
            setValue={setAddress}
            saveToggle={false}
            handleUpdateDevice={handleUpdateDevice}
            setExternalState={(isOpen: boolean) => setIsOpenAddressPopup(isOpen)}
            externalState={isOpenAddressPopup}
            isInUniversalTable={false}
          />
        )}
        {deviceTests && (
          <QualityModal
            isOpen={isQualityModalOpen}
            closeModal={handleQualityModal}
            languageStrings={languageStrings}
            deviceTests={deviceTests}
          />
        )}
      </>
    </Wrapper>
  );
};
