import React from 'react';
import styled from '@cthings.co/styled-components';
import { ErrorPageVersion } from '../ErrorPage';
import backgroundImage_500_Desktop from '../../../assets/error_500_4_1_background.svg';
import backgroundImage_500_Tablet from '../../../assets/error_500_4_2_background.svg';
import backgroundImage_500_Mobile from '../../../assets/error_500_4_3_background.svg';
import backgroundImage_404_Desktop from '../../../assets/404_number_background.svg';
import backgroundImageThirdVersion_500 from '../../../assets/error_500_3_background.svg';
import backgroundImageThirdVersion_404 from '../../../assets/404_background.svg';
import { media } from '@cthings.co/styles-utils';

type WrapperProps = {
  version: string;
};

// @TODO fix media types
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ version }) =>
    version === ErrorPageVersion.FOURTH_VERSION_500
      ? `url(${backgroundImage_500_Desktop})`
      : version === ErrorPageVersion.FOURTH_VERSION_404
      ? `url(${backgroundImage_404_Desktop})`
      : version === ErrorPageVersion.THIRD_VERSION_500
      ? `url(${backgroundImageThirdVersion_500})`
      : version === ErrorPageVersion.THIRD_VERSION_404
      ? `url(${backgroundImageThirdVersion_404})`
      : 'none'};
  background-repeat: ${({ version }) =>
    (version === ErrorPageVersion.FOURTH_VERSION_500 ||
      version === ErrorPageVersion.FOURTH_VERSION_404 ||
      version === ErrorPageVersion.THIRD_VERSION_500 ||
      version === ErrorPageVersion.THIRD_VERSION_404) &&
    'no-repeat'};
  background-position: ${({ version }) =>
    (version === ErrorPageVersion.FOURTH_VERSION_500 ||
      version === ErrorPageVersion.FOURTH_VERSION_404 ||
      version === ErrorPageVersion.THIRD_VERSION_500 ||
      version === ErrorPageVersion.THIRD_VERSION_404) &&
    'center'};

  ${media.tablet} {
    background-image: ${({ version }: any) =>
      version === ErrorPageVersion.FOURTH_VERSION_500
        ? `url(${backgroundImage_500_Tablet})`
        : version === ErrorPageVersion.FOURTH_VERSION_404
        ? `url(${backgroundImage_404_Desktop})`
        : version === ErrorPageVersion.THIRD_VERSION_500
        ? `url(${backgroundImageThirdVersion_500})`
        : version === ErrorPageVersion.THIRD_VERSION_404
        ? `url(${backgroundImageThirdVersion_404})`
        : 'none'};
  }

  ${media.phone} {
    background-image: ${({ version }: any) =>
      version === ErrorPageVersion.FOURTH_VERSION_500
        ? `url(${backgroundImage_500_Mobile})`
        : version === ErrorPageVersion.FOURTH_VERSION_404
        ? `url(${backgroundImage_404_Desktop})`
        : version === ErrorPageVersion.THIRD_VERSION_500
        ? `url(${backgroundImageThirdVersion_500})`
        : version === ErrorPageVersion.THIRD_VERSION_404
        ? `url(${backgroundImageThirdVersion_404})`
        : 'none'};
  }
`;

export const Background = ({ children, version, ...props }: { children: any; version: string; className?: string }) => {
  return (
    <Wrapper version={version} {...props}>
      {children}
    </Wrapper>
  );
};
