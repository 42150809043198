import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { CommonButton } from '@cthings.co/buttons';
import { Text } from '@bit/first-scope.text';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

type WrapperProps = {
  borderRadius: string;
  shadows: string;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: auto;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colorFetch('pureWhite')};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ shadows }) => shadows};
`;

// @NOTE needed for next revision of graphs, don't remove
export const LineWrapperRecharts = styled.div`
  /* min-height: 360px; */
  width: calc(100% - 12px);
  display: flex;
  padding: 0px 12px 24px 20px;
  overflow: hidden;
  position: relative;

  /** Use this setting to prevent crop of the line on extreme value */
  & > div > div > svg {
    overflow: visible;
  }
  ${media.tablet} {
    /* min-height: 254px; */
    width: 100%;
  }
`;

export const LineWrapper = styled.div`
  /* min-height: 360px; */
  width: 100%;
  display: flex;
  padding: 0 0 50px;
  overflow: hidden;
  position: relative;

  /** Use this setting to prevent crop of the line on extreme value */
  & > div > div > svg {
    overflow: visible;
  }
  ${media.tablet} {
    /* min-height: 254px; */
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px 0;
`;

export const Title = styled(Text)`
  font-size: 16px;
  ${media.tablet} {
    font-weight: 700;
  }
`;

// @TODO fix media types

export const FiltersButton = styled(CommonButton)`
  display: none;
  ${media.desktop} {
    display: flex;
    margin-right: 8px;
    & > svg {
      margin-right: 4px;
    }
    &:hover > svg > path {
      stroke: ${({ colors: { main } = {} }) => main};
    }
  }
  ${media.tablet} {
    &:hover > svg > path {
      stroke: ${({ colors: { main } = {} }) => main};
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;
