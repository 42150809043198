import React from 'react';

export const OutletPlessure = ({ primaryColor, secondaryColor }: { primaryColor: string; secondaryColor: string }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 32.001H13" stroke={secondaryColor} stroke-width="3.5" stroke-linecap="round" />
      <path d="M50.9902 32.001H55.9902" stroke={secondaryColor} stroke-width="3.5" stroke-linecap="round" />
      <path d="M32 7.99316L32 12.9932" stroke={secondaryColor} stroke-width="3.5" stroke-linecap="round" />
      <path d="M14.7051 15.6558L18.2406 19.1913" stroke={secondaryColor} stroke-width="3.5" stroke-linecap="round" />
      <path d="M14.0703 48.0918L17.6058 44.5563" stroke={secondaryColor} stroke-width="3.5" stroke-linecap="round" />
      <path d="M50.0762 16.1631L46.5406 19.6986" stroke={secondaryColor} stroke-width="3.5" stroke-linecap="round" />
      <path d="M50.0762 48.0918L46.5406 44.5563" stroke={secondaryColor} stroke-width="3.5" stroke-linecap="round" />
      <path d="M32.1284 32.0949L22.6191 23.6182" stroke={primaryColor} stroke-width="3.5" stroke-linecap="round" />
      <circle cx="32" cy="32.001" r="24.25" stroke={primaryColor} stroke-width="3.5" />
      <circle cx="32.0002" cy="32.0016" r="1.92399" stroke={primaryColor} stroke-width="3.5" />
    </svg>
  );
};
