import React, { FC, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { motion } from 'framer-motion';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Container = styled.div`
  width: auto;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled(motion.div)`
  width: 16px;
  height: 16px;
  border: 1px solid #33894e;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerCircle = styled(motion.div)`
  width: 8px;
  height: 8px;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  box-shadow: none;
  background-color: ${colorFetch('white')};
`;

const LabelContainer = styled(motion.div)`
  width: auto;
  height: auto;
  padding: 2px;
  display: flex;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
`;

export interface RadioButtonProps {
  toggle: boolean;
  disabled: boolean;
  label: string;
  handleToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export const RadioButton: FC<RadioButtonProps> = ({ toggle, label, handleToggle, disabled = false, ...props }) => {
  const theme = useTheme();

  const [toggled, setToggle] = useState(toggle);
  const [disabledState] = useState(disabled);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      e.preventDefault();
    } else {
      setToggle(!toggled);
      handleToggle(e);
    }
  };

  return (
    <Container>
      <Button
        // @TODO change handleClick type
        onClick={handleClick as any}
        animate={{
          backgroundColor: toggled ? theme.colors.primary : 'rgb(255, 255, 255)',
          opacity: disabledState ? 0.3 : 1,
        }}
        transition={{ duration: 0.1, ease: 'linear' }}
      >
        <InnerCircle
          style={{
            boxShadow: toggled ? '0px 1px 2px rgba(40, 41, 61, 0.4), 0px 2px 4px rgba(96, 97, 112, 0.2)' : 'none',
          }}
          animate={{
            opacity: toggled ? 1 : 0,
          }}
        ></InnerCircle>
      </Button>
      <LabelContainer
        animate={{
          opacity: disabledState ? 0.3 : 1,
        }}
      >
        {label}
      </LabelContainer>
    </Container>
  );
};

function handleToggle() {}

RadioButton.defaultProps = {
  toggle: false,
  label: 'Label',
  disabled: false,
  handleToggle: handleToggle,
};
