import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import { useGetClients } from '../../../../api/manage/manageClients';
import { deleteUserGlobal, updateUsers } from '../../../../api/manage/manageUsers';
import {
  selectAccess,
  selectIsAccess,
  selectLanguageStrings,
  selectUserAccessManagement,
  selectUserExportModalIsOpen,
  setUserExportModalState,
} from '../../../../app/state/user';
import { DateField } from '../../../../components/dateField/DateField';
import { ManageLayout, ManageTableType } from '../../../../components/manageLayout/ManageLayout';
import { API_URL } from '../../../../consts';
import {
  useTableContext,
  useTableFunctions,
  withTableContextProvider,
} from '../../../../features/universalTable/context';
import { ActionType, InlineActionType, RowItemType } from '../../../../features/universalTable/types';
import { UniversalTable } from '../../../../features/universalTable/UniversalTable';
import { useMobileManagementSearch } from '../../../../layouts/managementMobile/useMobileManagement';
import { PATHS } from '../../../../routes/paths';
import { View } from '../../../../routes/routeInterfaces';
import { useMediaType } from '@cthings.co/styles-utils';
import { useHistory } from '../../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../../utils/useQueryParams';
import { useUserRole } from '../../../../utils/userRole';
import { ExportModal } from './components/exportModal/ExportModal';

export const UsersPlain = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { startAddProcess, resetTable, setSearchValue } = useTableFunctions();
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserAccessManagementData = useSelector(selectUserAccessManagement);
  const isAccess = useSelector(selectIsAccess);
  const { view: isUserAccessManagementView } = isUserAccessManagementData;

  const languageStrings = useSelector(selectLanguageStrings);
  const mediaType = useMediaType();
  const keyword = 'UsersTable';
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pathParams = useParams();

  const addButtonInHeader = document.getElementById('add-button');
  if (addButtonInHeader) {
    addButtonInHeader.onclick = () => {
      startAddProcess();
    };
  }
  const [{ isAddInProcess }] = useTableContext();

  const clients = useGetClients();

  const [{ roleList }] = useUserRole();
  const accessData = useSelector(selectAccess).management.user;
  const { search_value = '' } = queryParams || {};

  useEffect(() => {
    if (isAccess && !isUserAccessManagementView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isUserAccessManagementView]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useMobileManagementSearch(setSearchValue);

  const openExportModal = () => {
    dispatch(setUserExportModalState(true));
  };

  const closeExportModal = () => {
    dispatch(setUserExportModalState(false));
  };

  const isOpenExportModal = useSelector(selectUserExportModalIsOpen);

  const isDataReady = isDataLoaded && !!clients.length && !!roleList.length;

  return (
    <ManageLayout
      type={ManageTableType.USERS_TABLE}
      title={languageStrings.navigationUsers}
      handleAdd={startAddProcess}
      accessData={accessData}
      searchValue={search_value}
      setSearchValue={setSearchValue}
      openExportModal={openExportModal}
      withSmartSearch
      isDataLoaded={isDataReady}
    >
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet },
        }}
        mainApiUrl={`${API_URL}/v2/users/`}
        keyword={keyword}
        offset={+offset}
        offsetKey={offsetKey}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        pathParams={pathParams}
        queryParams={queryParams}
        tablePath={View.MANAGE_USERS}
        updateItems={updateUsers}
        deleteItems={deleteUserGlobal}
        allowDelete={accessData?.delete}
        actionSet={[{ type: ActionType.EDIT }]}
        inlineActionSet={[{ type: InlineActionType.DETAILS }]}
        accessData={accessData}
        columnStructureTriggers={[clients]}
        setIsDataLoaded={setIsDataLoaded}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
            label: languageStrings.insightDetailsDeviceConfigurationEditInputsNameTitle,
            type: RowItemType.INPUT,
            defaultValue: '',
          },
          {
            fieldName: 'email',
            placeholder: languageStrings.manageClientsTableRowPlaceholdersEmail,
            label: languageStrings.manageClientsTableHeaderItemsEmail,
            type: RowItemType.INPUT_EMAIL,
            defaultValue: '',
          },
          {
            fieldName: { name: 'client_name', id: 'client_id' },
            type: RowItemType.SELECT, // @TODO replace with JOINED_SELECT during RENG integration
            placeholder: languageStrings.mobileEditPopupChooseClient,
            label: languageStrings.manageClientsTableHeaderItemsClient,
            defaultValue: clients &&
              (clients as any).length > 0 && { name: (clients[0] as any).name, id: (clients[0] as any).id },
            selectItems: clients,
          },
          {
            fieldName: { name: 'role_name', id: 'role' },
            type: RowItemType.SELECT, // @TODO replace with JOINED_SELECT during RENG integration
            placeholder: languageStrings.manageUsersAddUserModalInputsRoleTitle,
            label: languageStrings.manageUsersAddUserModalInputsRoleTitle,
            defaultValue: { name: roleList[0].name, id: roleList[0].id },
            selectItems: roleList,
          },
          {
            fieldName: 'registration_date',
            placeholder: '',
            label: languageStrings.usersDateOfRegistrationLabel,
            defaultValue: '',
            disabled: true,
            /*getTextDisplay: (value: any) => {
              return value ? getRequiredDateFormat(value, 'DD.MM.YYYY') : 'N/A';
            },*/
            type: RowItemType.CUSTOM,
            component: (value, state, setField, saveToggle) => {
              return (
                <DateField
                  value={value}
                  setField={setField}
                  state={state}
                  isAddInProcess={isAddInProcess}
                  languageStrings={languageStrings}
                  disabled={true}
                  withTime={false}
                  saveToggle={saveToggle}
                />
              );
            },
          },
          {
            fieldName: 'first_login_date',
            placeholder: '',
            label: languageStrings.usersFirstLoginLabel,
            defaultValue: '',
            disabled: true,
            /*getTextDisplay: (value: any) => {
              return value ? getRequiredDateFormat(value, 'DD.MM.YYYY') : 'N/A';
            },*/
            type: RowItemType.CUSTOM,
            component: (value, state, setField, saveToggle) => {
              return (
                <DateField
                  value={value}
                  setField={setField}
                  state={state}
                  isAddInProcess={isAddInProcess}
                  languageStrings={languageStrings}
                  disabled={true}
                  withTime={false}
                  saveToggle={saveToggle}
                />
              );
            },
            excludedFromView: true,
          },
          {
            fieldName: 'parent_user',
            placeholder: '',
            label: languageStrings.usersAddedByLabel,
            defaultValue: '',
            disabled: true,
            getTextDisplay: (value: any) => {
              return value ? value.name : 'N/A';
            },
            excludedFromView: true,
          },
        ]}
      />
      <ExportModal isOpenModal={isOpenExportModal} closeModal={closeExportModal} languageStrings={languageStrings} />
    </ManageLayout>
  );
};

export const Users = withTableContextProvider(UsersPlain);
