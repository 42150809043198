import React, { FC, useEffect, useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import 'rc-slider/assets/index.css';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';
import { TableInputType } from '../../../types';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { InputWrapper } from '../../common/inputWrapper/InputWrapper';
import { RequestBlock } from '../../requestedBlock/RequestedBlock';
import { Handle, Range } from 'rc-slider';
import { media } from '@cthings.co/styles-utils';
import { getTextWidth } from '../../../../../utils/textWidth';

const Wrapper = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledInputWrapper = styled(InputWrapper)`
  flex-direction: row;
  align-items: center;
  ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  ${media.tablet} {
    height: auto;
    flex-direction: column;
    gap: 10px;
    border: none;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 70px; */
  height: 100%;
  background-color: ${colorFetch('gray6')};
  border-right: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 8px 0 0 8px;
  padding: 0 5px;
  &.right {
    border-radius: 0 8px 8px 0;
    border-right: 0;
    border-left: 1px solid;
    border-color: ${colorFetch('gray3')};
  }
  ${media.tablet} {
    width: 100%;
    border: 1px solid ${colorFetch('gray3')}!important;
    border-radius: 8px;
    box-sizing: border-box;

    &.right {
      border-radius: 8px;
    }
  }
`;

type StyledInputProps = {
  disabled: boolean;
  width: number;
};

const StyledInput = styled(AutosizeInput)<StyledInputProps>`
  display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')} !important;
  background-color: ${colorFetch('gray6')};
  border-radius: 8px 0 0 8px;
  & > input {
    box-sizing: border-box;
    outline: transparent;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${colorFetch('gray1')};
    min-width: ${({ width }) => `${width}px`};
    text-align: right;
  }
`;

// @TODO fix any
const StyledRange = styled(Range as any)`
  width: calc(100% - 142px);
  margin: 0 14px;
  background: none;
  ${media.tablet} {
    display: none;
  }
`;

type StyledHandleProps = {
  withInput?: boolean;
  pureWhite: string;
  primary: string;
  boxShadow: string;
};

const StyledHandle = styled(Handle as any)<StyledHandleProps>`
  width: 14px;
  height: 14px;
  background-color: ${colorFetch('pureWhite')};
  border: 2px solid;
  border-color: ${colorFetch('primary')};
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  transform: translate(-50%, -5px) !important;
  box-shadow: ${({ boxShadow }) => boxShadow} !important;
  border-radius: 50%;
  &:hover {
    border-color: ${colorFetch('primary')};
  }
  &:focus,
  &:active {
    border-color: ${colorFetch('primary')};
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

const TextUnit = styled.span`
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

type TextWrapperType = {
  highlightValue: string | any;
};

const TextWrapper = styled.span<TextWrapperType>`
  word-break: break-all;
  width: 100%;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ highlightValue }) => highlightValue || colorFetch('gray1')};
`;

interface SliderRangeFreeInputWithUnitProps {
  value: {
    type: TableInputType;
    actual_value: { value: number | string; unit: string; limit: { min: number; max: number }; step: number } | null;
    requested_value: { value: number | string; unit: string; limit: { min: number; max: number }; step: number } | null;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  isEditInProcess: boolean | undefined;
  highlightValue?: string | any;
}

export const SliderRangeFreeInputWithUnit: FC<SliderRangeFreeInputWithUnitProps> = ({
  value,
  state,
  setField,
  isEditInProcess = false,
  highlightValue,
  ...props
}) => {
  const theme = useTheme();
  const { primary, secondary3, pureWhite } = theme.colors;
  const { additionalShadow6 } = theme.shadows;

  const { actual_value, with_requested, requested_value }: any = value || {};
  const { value: inputValue, unit, step, limit }: any = actual_value || {};

  const [inputChangeInprogress, setInputChangeInProgress] = useState(false);

  const countOfDigitsBeforeDot = Math.max(
    (limit.min + '')
      .split('')
      .filter((el: any) => el !== '-' && el !== '+')
      .join('')
      .split('.')[0].length,
    (limit.max + '')
      .split('')
      .filter((el: any) => el !== '-' && el !== '+')
      .join('')
      .split('.')[0].length,
  );

  const countOfDigitsAfterDot = ((step + '').split('.')[1] || '').length;

  const regexForInput = new RegExp(
    `^[+-]?\\d{0,${countOfDigitsBeforeDot}}(\\.{${!!countOfDigitsAfterDot ? 1 : 0}}\\d{0,${countOfDigitsAfterDot}})?$`,
    'gm',
  );

  const onInputChange = (inputData: string, type: 'start' | 'end') => {
    if (!regexForInput.test(inputData)) return;

    setInputChangeInProgress(true);

    if (type === 'start') {
      setField({ ...value, actual_value: { ...actual_value, value: [inputData, inputValue[1]] } });
    }

    if (type === 'end') {
      setField({ ...value, actual_value: { ...actual_value, value: [inputValue[0], inputData] } });
    }
  };

  const onSliderChange = (sliderValue: any) => {
    setField({
      ...value,
      actual_value: {
        ...actual_value,
        value: sliderValue,
      },
    });
  };

  const onInputBlur = (type: 'start' | 'end') => {
    if (type === 'start') {
      +inputValue[0] < limit.min
        ? setField({ ...value, actual_value: { ...actual_value, value: [limit.min, +inputValue[1]] } })
        : +inputValue[0] > +inputValue[1]
        ? setField({ ...value, actual_value: { ...actual_value, value: [+inputValue[1] - step, +inputValue[1]] } })
        : isNaN(+inputValue[0])
        ? setField({ ...value, actual_value: { ...actual_value, value: [limit.min, +inputValue[1]] } })
        : setField({ ...value, actual_value: { ...actual_value, value: [+inputValue[0], +inputValue[1]] } });
    }

    if (type === 'end') {
      +inputValue[1] > limit.max
        ? setField({ ...value, actual_value: { ...actual_value, value: [+inputValue[0], limit.max] } })
        : +inputValue[1] < +inputValue[0]
        ? setField({ ...value, actual_value: { ...actual_value, value: [+inputValue[0], +inputValue[0] + step] } })
        : isNaN(+inputValue[1])
        ? setField({ ...value, actual_value: { ...actual_value, value: [+inputValue[0], limit.max] } })
        : setField({ ...value, actual_value: { ...actual_value, value: [+inputValue[0], +inputValue[1]] } });
    }

    setInputChangeInProgress(false);
  };

  const [minSizeOfInput, setMinSizeOfInput] = useState<number>(0);

  const generateString: any = (count: number) => {
    return count === 0 ? '' : '6' + generateString(count - 1);
  };

  useEffect(() => {
    const weightiestDigit =
      `${(limit.min + '').split('').findIndex((el: any) => el === '-') !== -1 ? '-' : ''}` +
      `${generateString(countOfDigitsBeforeDot)}` +
      `${countOfDigitsAfterDot ? '.' : ''}` +
      `${generateString(countOfDigitsAfterDot)}`;

    setMinSizeOfInput(Math.ceil(getTextWidth(weightiestDigit, { font: '500 15px Poppins' })));
  }, [limit, step]);

  return (
    <Wrapper>
      {isEditInProcess ? (
        <StyledInputWrapper isEditInProcess={isEditInProcess} {...props}>
          <Container>
            <Box>
              <StyledInput
                value={
                  inputChangeInprogress
                    ? inputValue[0]
                    : countOfDigitsAfterDot
                    ? (+inputValue[0]).toFixed(countOfDigitsAfterDot)
                    : inputValue[0]
                }
                onChange={(e: any) => onInputChange(e.target.value, 'start')}
                onBlur={() => onInputBlur('start')}
                disabled={false}
                width={minSizeOfInput}
              />
              <TextUnit>{unit}</TextUnit>
            </Box>
            <StyledRange
              value={inputValue}
              min={limit.min}
              max={limit.max}
              step={step}
              count={2}
              onChange={(values: number[]) => {
                values[0] >= limit.min && values[1] <= limit.max && onSliderChange(values);
              }}
              trackStyle={[{ backgroundColor: primary }]}
              railStyle={{ backgroundColor: secondary3 }}
              handle={({ index, ...props }: any) => (
                <StyledHandle
                  primary={primary}
                  pureWhite={pureWhite}
                  boxShadow={additionalShadow6}
                  withInput
                  {...props}
                />
              )}
            />
            <Box className="right">
              <StyledInput
                value={
                  inputChangeInprogress
                    ? inputValue[1]
                    : countOfDigitsAfterDot
                    ? (+inputValue[1]).toFixed(countOfDigitsAfterDot)
                    : inputValue[1]
                }
                onChange={(e: any) => onInputChange(e.target.value, 'end')}
                onBlur={() => onInputBlur('end')}
                disabled={false}
                width={minSizeOfInput}
              />
              <TextUnit>{unit}</TextUnit>
            </Box>
          </Container>
        </StyledInputWrapper>
      ) : (
        <TextWrapper highlightValue={highlightValue}>{`${inputValue[0]} - ${inputValue[1]} ${unit}`}</TextWrapper>
      )}
      {!isEditInProcess && with_requested && (
        <RequestBlock
          requested_value={`${requested_value.value[0]} - ${requested_value.value[1]} ${requested_value.unit}`}
        />
      )}
    </Wrapper>
  );
};
