import React from 'react';
import styled from '@cthings.co/styled-components';
import { CardType } from '../../../../../enums/CardType';
import { DocumentsCard } from '../documentsCard/DocumentsCard';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/user';
import { FileNameType } from '../../../../../enums/FileNameType';
import { getDocumentData } from '../../data/getDocumentData';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${media.tablet} {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 32px;
  }
  ${media.phone} {
    padding-bottom: 24px;
  }
`;

const StyledText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;

const StyledDashboardCard = styled(DocumentsCard)`
  cursor: default;

  ${media.tablet} {
    max-width: 280px;
  }

  ${media.semiTablet} {
    max-width: 100%;
  }
`;

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

type DocumentType = {
  title: string;
  type: CardType;
  filename: FileNameType;
};

export const ContentDocuments = () => {
  const languageStrings = useSelector(selectLanguageStrings);

  const documentData = getDocumentData(languageStrings);

  return (
    <Wrapper>
      <StyledText type={TextType.TITLE_H1} margin={'0 0 40px 0'}>
        {languageStrings.navigationDocuments}
      </StyledText>
      <CardsWrapper>
        {documentData.map((document: DocumentType) => {
          const { title, type, filename } = document;

          return (
            <StyledDashboardCard title={title} languageStrings={languageStrings} type={type} filename={filename} />
          );
        })}
      </CardsWrapper>
    </Wrapper>
  );
};
