import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as AddIcon } from '../../../../../../../assets/plus_icon.svg';
import { InputAddImage, LabelAddImage } from '../../../../../../../components/addingAnImage/addingAnImage';
import { SpinnerCircle } from '../../../../../../../features/animations/SpinnerCircle';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { media } from '@cthings.co/styles-utils';
import { DeletePhotoModal } from './components/deleteModal/DeletePhotoModal';
import { PhotoItem } from './components/photoItem/PhotoItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 610px);
  min-width: 288px;
  margin-right: -10px;
  ${media.tablet} {
    max-width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const AddPhotoLabel = styled(LabelAddImage)`
  width: 134px;
  height: 134px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  position: relative;
`;

export const Photos = ({
  addPhoto,
  deletePhoto,
  photos,
  languageStrings,
}: {
  addPhoto: (
    data: string,
    setPhotoList: React.Dispatch<any>,
    setIsLoadingInProcess: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
  deletePhoto: (imageId: string) => void;
  photos?: any[];
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const [isLoadingInProcess, setIsLoadingInProcess] = useState(false);
  const [photosList, setPhotosList] = useState<any>(photos ? photos : []);
  const [imageId, setImageId] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { gray2, primary } = theme.colors;
  const { addNotification } = useNotifierFunctions();

  const resetInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = '';
  };

  const handleAdd = (e: any) => {
    if (e?.target?.files.length > 0) {
      setIsLoadingInProcess(true);
      if (e?.target?.files[0]?.type.includes('image')) {
        addPhoto(e.target.files[0], setPhotosList, setIsLoadingInProcess);
      } else {
        addNotification({
          message: languageStrings.incorrectTypeOfFile,
          type: 'error',
        });
        setIsLoadingInProcess(false);
      }
    }
    resetInputValue(e);
  };

  const handleOpenDeleteModal = (imageId: any) => {
    setImageId(imageId);
    setIsOpenModal(true);
  };

  const handleDelete = () => {
    const preparedList = photosList.filter((item: any) => item.id !== imageId);
    setPhotosList(preparedList);
    deletePhoto(imageId);
    setIsOpenModal(false);
  };

  return (
    <Wrapper>
      <Text
        type={TextType.TEXT_14_BLACK}
        color={gray2}
        weight={'700'}
        fontSize={'16px'}
        margin={'0 0 20px 0'}
        lineHeight={'22px'}
      >
        {languageStrings.photosTitle}
      </Text>
      <InputAddImage onChange={handleAdd} type="file" id="addPictureInput" accept=".heic, image/*" />
      <Container>
        {photosList.map((item: string, index: number) => {
          return (
            <PhotoItem
              deletePhoto={(e: any, imageId: string) => {
                e.stopPropagation();
                handleOpenDeleteModal(imageId);
              }}
              photosList={photosList}
              index={index}
            />
          );
        })}
        <AddPhotoLabel htmlFor="addPictureInput">
          {isLoadingInProcess ? <SpinnerCircle backgroundColor={primary} /> : <AddIcon />}
        </AddPhotoLabel>
      </Container>
      <DeletePhotoModal isOpenModal={isOpenModal} onDelete={handleDelete} setModalClose={setIsOpenModal} />
    </Wrapper>
  );
};
