import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Modal } from '@bit/first-scope.modal';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { InputModal } from '../../../../../components/inputModal/InputModal';
import { EventItem } from './components/eventItem/EventItem';
import { CommentModal } from './components/commentModal/CommentModal';
import { addCommentToIncident } from '../../../../../api/incidents';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { Loader } from '../../../../../components/placeholders/Loader';
import { useBlockBodyScroll } from '../../../../../utils/useBlockBodyScroll';
import { useWindowSize } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { View, getPath } from '../../../../../routes';
import { NavLink } from '../../../../../utils/react-router-dom-abstraction';
import { ReactComponent as LocationIcon } from '../../../../../assets/location_device_details.svg';
import { stringifyUrl } from 'query-string';
import { injectPathParams } from '@cthings.co/utils';
import { LsValueType } from '../../../../../enums/LsValueType';
import ss from '../../../../../utils/ss';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 700px !important;
  align-items: flex-start !important;
  padding: 24px !important;
  ${media.phone} {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    border-radius: 16px 16px 0 0;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  ${media.phone} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

const ScrollableWrapper = styled.div`
  width: calc(100% + 12px);
  box-sizing: border-box;
  height: max-content;
  max-height: 292px;
  overflow-y: auto;
  margin-bottom: 22px;
  padding: 0 12px 0 0;
  position: relative;
  ${media.phone} {
    max-height: 202px;
  }
`;

const EventsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 34px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const BluredWrapper = styled.div`
  width: 100%;
  height: max-content;
  position: relative;
`;

type BluredContainerProps = {
  isBlured: boolean;
};

const BluredContainer = styled.div<BluredContainerProps>`
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 22px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  opacity: ${({ isBlured }) => (isBlured ? '1' : '0')};
  transition: all 0.3s linear;
  &.top {
    transform: none;
    top: 0;
    z-index: var(--modal-second-layer);
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${media.phone} {
    flex-direction: column;
  }
`;

const LeftSideButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  ${media.semiTablet} {
    flex-direction: column;
    gap: 0;
    margin-bottom: 10px;
  }
`;

const Button = styled(CommonButton)`
  width: max-content;
  padding: 0 18px;
  ${media.phone} {
    width: 100%;
    height: 40px;
    &:first-child {
      margin-bottom: 10px;
    }
  }
`;

const LocationButton = styled(CommonButton)`
  height: 32px;
  min-width: 160px;
  width: fit-content;
  padding: 0 6px;
  & > svg {
    margin-right: 2px;
    & > path {
      transition: 0.3s ease;
      stroke: ${({ colors: { main } = {} }) => main};
    }
  }
  &:hover > svg > path {
    stroke: ${({ colors: { mainHover } = {} }) => mainHover};
  }

  ${media.semiTablet} {
    width: 100%;
    height: 40px;
    & > svg {
      margin: 0;
    }
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
`;

export const DetailsModal = ({
  deviceId,
  deviceType,
  isOpenModal,
  closeModal,
  inputsList,
  eventsList,
  incidentId,
  isLoadingInProcess,
  userInfo,
  languageStrings,
}: {
  deviceId: string;
  deviceType: string;
  isOpenModal: boolean;
  closeModal: () => void;
  inputsList: any[];
  eventsList: any[];
  incidentId: string;
  isLoadingInProcess: boolean;
  userInfo: any;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { black1, pureWhite, primary, gray1 } = theme.colors;
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const { addNotification } = useNotifierFunctions();
  const windowWidth = useWindowSize()[0];

  const [isBottomBlured, setIsBottomBlured] = useState(true);
  const [isTopBlured, setIsTopBlured] = useState(false);
  const [isOpenAddComment, setIsOpenAddComment] = useState(false);
  const [localEvents, setLocalEvents] = useState(eventsList);
  useBlockBodyScroll(windowWidth > 430 && isOpenModal);

  const openAddModal = () => {
    setIsOpenAddComment(true);
  };

  const closeAddModal = () => {
    setIsOpenAddComment(false);
  };

  const revertData = () => {
    setLocalEvents(eventsList);
  };

  const toInsightsPage = View.USER_INSIGHTS_DETAILS;

  const href = stringifyUrl({
    url: injectPathParams(getPath(toInsightsPage), {
      id: deviceId,
      type: deviceType,
      offsetEvents: 0,
      offsetMaintenance: 0,
    }),
  });

  const addComment = (comment: string) => {
    const currentDate = getRequiredDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ssZ');
    setLocalEvents([
      ...localEvents,
      {
        desc: 'A comment was added to the incident',
        status: 0,
        ts: currentDate,
        source: userInfo.name,
        note: { text: comment, ts: currentDate, user_id: userInfo.id },
      },
    ]);
    addCommentToIncident(
      incidentId,
      { ts: currentDate, text: comment, user_id: userInfo.id },
      addNotification,
      revertData,
      languageStrings,
    );
  };

  useEffect(() => {
    setLocalEvents(eventsList);
  }, [eventsList]);

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Text type={TextType.SEMITITLE} fontSize={'18px'} weight={'700'} margin={'0 0 22px 0'} color={black1}>
          {languageStrings.problemsButtonDetails}
        </Text>
        <InputWrapper>
          {inputsList.map((item: any, index: number) => {
            const { value, title } = item;
            return <InputModal key={index} value={value} name={title} placeholder={''} onChange={() => {}} disabled />;
          })}
        </InputWrapper>
        <BluredWrapper>
          <BluredContainer isBlured={isTopBlured} className={'top'} />
          <ScrollableWrapper
            onScroll={(e: any) => {
              const maxScrollValue = e.target.scrollHeight - e.target.clientHeight;
              if (maxScrollValue === e.target.scrollTop) {
                setIsBottomBlured(false);
                setIsTopBlured(true);
              } else {
                setIsBottomBlured(true);
                setIsTopBlured(false);
              }
            }}
          >
            <EventsContainer>
              {localEvents && localEvents.length > 0 ? (
                localEvents.map((item: any, index: number) => {
                  return (
                    <EventItem
                      key={index}
                      className={index === localEvents.length - 1 ? 'last' : ''}
                      data={item}
                      index={index}
                      isTopBlured={isTopBlured}
                      lastIndex={localEvents.length - 1}
                      languageStrings={languageStrings}
                    />
                  );
                })
              ) : (
                <PlaceholderWrapper>
                  <Text type={TextType.SEMITITLE} fontSize={'16px'} weight={'400'} color={gray1}>
                    {languageStrings.placeholderNoData}
                  </Text>
                </PlaceholderWrapper>
              )}
            </EventsContainer>
          </ScrollableWrapper>
          {eventsList && eventsList.length > (windowWidth < 430 ? 3 : 4) && (
            <BluredContainer isBlured={isBottomBlured} />
          )}
        </BluredWrapper>
        <ButtonsWrapper>
          <LeftSideButtonsWrapper>
            <Button
              onClick={openAddModal}
              colors={{
                main: black1,
                mainHover: pureWhite,
                background: pureWhite,
                backgroundHover: primary,
                borderHover: primary,
              }}
            >
              {languageStrings.addCommentButton}
            </Button>
            <StyledLink access={[]} to={`${href}?access_token=${token}&expires=${exp}`}>
              <LocationButton
                colors={{
                  main: black1,
                  mainHover: pureWhite,
                  background: pureWhite,
                  backgroundHover: primary,
                  borderHover: primary,
                }}
              >
                <LocationIcon />
                {languageStrings.deviceDetailsGoToInsightDetails}
              </LocationButton>
            </StyledLink>
          </LeftSideButtonsWrapper>
          <Button onClick={closeModal} width={'104px'}>
            {languageStrings.closeButtonValue}
          </Button>
        </ButtonsWrapper>
        <CommentModal
          isOpenModal={isOpenAddComment}
          closeModal={closeAddModal}
          addComment={addComment}
          languageStrings={languageStrings}
        />
        {isLoadingInProcess && <Loader />}
      </StyledModalContainer>
    </StyledModal>
  );
};
