import { IDENTITY_URL } from '../consts';
import { HttpService } from '../utils/http';

export const setLanguageIdentity = (lang: string) => {
  const url = `${IDENTITY_URL}/users/me/lang?lang=${lang}`;
  (async () => {
    try {
      await HttpService.patch(url, {});
    } catch (e) {
      //
    }
  })();
};
