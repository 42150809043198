import React from 'react';

export const ThermometerIcon = ({ primaryColor, secondaryColor }: { primaryColor: string; secondaryColor: string }) => {
  return (
    <svg width="29" height="64" viewBox="0 0 29 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4825 39.3312V9.60088C21.4825 7.85022 20.787 6.17126 19.5491 4.93335C18.3112 3.69545 16.6322 3 14.8816 3C13.1309 3 11.452 3.69545 10.2141 4.93335C8.97615 6.17126 8.2807 7.85022 8.2807 9.60088V39.3312C6.16123 40.7474 4.55338 42.8076 3.6946 45.2076C2.83583 47.6076 2.77166 50.2202 3.51159 52.6595C4.25151 55.0987 5.75629 57.2354 7.80369 58.7539C9.85109 60.2723 12.3325 61.0921 14.8816 61.0921C17.4306 61.0921 19.9121 60.2723 21.9595 58.7539C24.0069 57.2354 25.5116 55.0987 26.2516 52.6595C26.9915 50.2202 26.9273 47.6076 26.0686 45.2076C25.2098 42.8076 23.6019 40.7474 21.4825 39.3312Z"
        stroke={primaryColor}
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
