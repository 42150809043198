import React from 'react';
import styled from '@cthings.co/styled-components';
import { CustomModal } from '../../../../components/customModal/CustomModal';
import { Text, TextType } from '@bit/first-scope.text';
import { InfoButton } from '../../../../components/infoButton/InfoButton';
import { ReactComponent as HelpIcon } from '../../../../assets/help.svg';
import { CommonButton } from '../../../../components/commonButton/CommonButton';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { boldString } from '../../../../utils/boldString';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 12px 0 32px 0;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.semiTablet} {
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column-reverse;
  }
`;

const StyledCancelButton = styled(CommonButton)`
  margin: 0 16px 0 0;
  font-size: 14px;

  ${media.semiTablet} {
    width: 100%;
    margin: 0;
  }
`;

const StyledNextButton = styled(CommonButton)`
  font-size: 14px;
  ${media.semiTablet} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const GpsLocationRequestInfo = ({
  isOpenModal,
  language,
  onCancel,
  onNext,
  link,
}: {
  isOpenModal: boolean;
  language: any;
  onCancel: () => void;
  onNext: () => void;
  link: string;
}) => {
  const theme = useTheme();
  const { black1, gray1, pureWhite } = theme.colors;

  return (
    <CustomModal isOpen={isOpenModal}>
      <TitleWrapper>
        <Text type={TextType.TEXT_14_BLACK} fontSize={'18px'} weight={'600'} color={black1}>
          {language.gpsLocationCheckTitle}
        </Text>
        <InfoButton onClick={() => {}} tooltipText={language.gpsCheckTooltip} customIcon={<HelpIcon />} link={link} />
      </TitleWrapper>
      <Content>
        <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
          {language.gpsCheck1Row}
        </Text>
        <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
          {boldString(language.gpsCheck2Row, gray1)}
        </Text>
        <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
          {language.gpsCheck3Row}
        </Text>
        <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
          {language.gpsCheck4Row}
        </Text>
        <Text type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
          {boldString(language.gpsCheck5Row, gray1)}
        </Text>
      </Content>
      <ButtonRow>
        <StyledCancelButton onClick={onCancel} width={'116px'} color={black1} backgroundColor={pureWhite}>
          {language.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
        </StyledCancelButton>
        <StyledNextButton onClick={onNext} width={'116px'}>
          {language.paginationButtonNext}
        </StyledNextButton>
      </ButtonRow>
    </CustomModal>
  );
};
