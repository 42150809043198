import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectDeviceInfo, setDeviceInfoAddress } from '../../../../../../../../../../../app/state/insight';
import { CloseWhiteRoundButton } from '../../../../../../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { InputModal } from '../../../../../../../../../../../components/inputModal/InputModal';
import { ModalContainer } from '../../../../../../../../../../../components/newModals/ModalContainer';
import { Select } from '../../../../../../../../../../../components/select/Select';
import { SelectAddress } from '../../../../../../../../../../../components/selectAddress/SelectAddress';
import { RowItemType, TableCustomComponentState } from '../../../../../../../../../../../features/universalTable/types';
import { media } from '@cthings.co/styles-utils';
import { useBlockBodyScroll } from '../../../../../../../../../../../utils/useBlockBodyScroll';
import { CustomMobileRow } from './components/customMobileRow/CustomMobileRow';

const StyledModal = styled(Modal)`
  z-index: var(--modal-first-layer);
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
` as any; // @TODO fix type

const StyledModalContainer = styled(ModalContainer)`
  position: fixed;
  bottom: 0;
  ${media.phone} {
    padding: 24px 0px 30px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${media.phone} {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
`;

const StyledRoundCloseButton = styled(CloseWhiteRoundButton)`
  position: relative;
  top: 0;
  right: 0;
`;

const InputsWrapper = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${media.phone} {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
  ${media.phoneXS} {
    height: 280px;
  }
`;

const StyledInput = styled(InputModal)`
  margin: 0 0 12px 0;

  ${media.tablet} {
    width: 100%;
  }
` as any; // @TODO fix type

const StyledSelect = styled(Select)`
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
  }
  ${media.tablet} {
    width: 100%;
  }
` as any; // @TODO fix type

const ButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  margin-top: 20px;
  ${media.phone} {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
`;

export const DetailsModal = ({
  title,
  handleCloseModal,
  inputsList,
  buttonsList,
  isOpenModal,
  languageStrings,
}: {
  title: string;
  handleCloseModal: () => void;
  inputsList: any[];
  buttonsList: any[];
  isOpenModal?: boolean;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const address = useSelector(selectDeviceInfo).address;
  const dispatch = useDispatch();

  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState(false);

  const handleSetAddressValue = (value: any) => {
    dispatch(setDeviceInfoAddress({ value }));
  };

  useBlockBodyScroll(isOpenAddressPopup);

  const renderInfo = (type: RowItemType, item: any, index: number) => {
    const { fieldName, isError, label, onChange, placeholder, selectItems, value, disabled } = item;
    const dataList: any = {
      [RowItemType.INPUT]: (() => (
        <StyledInput
          key={index}
          type={type === RowItemType.INPUT_NUMBER ? 'number' : 'text'}
          name={label}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e: any) => onChange(e.target.value, fieldName)}
          validatingIsStarted={true}
          isError={isError}
          languageStrings={languageStrings}
        />
      ))(),
      [RowItemType.SELECT]: (() => (
        <StyledSelect
          key={index}
          value={value}
          label={label}
          disabled={!value}
          values={selectItems}
          onChange={(value: any) => {
            onChange(value, fieldName);
          }}
          placeholder={placeholder}
          banAutoFocus={true}
          validatingIsStarted={true}
          isError={isError}
          languageStrings={languageStrings}
        />
      ))(),
      [RowItemType.ADDRESS]: (() => <CustomMobileRow value={item} setIsOpenAddressPopup={setIsOpenAddressPopup} />)(),
      [RowItemType.CUSTOM]: (() => <CustomMobileRow value={item} />)(),
    };

    return dataList[type];
  };

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <TitleWrapper>
          <Text type={TextType.SEMITITLE} color={black1}>
            {title}
          </Text>
          <StyledRoundCloseButton onClick={handleCloseModal} />
        </TitleWrapper>
        <InputsWrapper>
          {inputsList.map((item: any, index: number) => {
            return renderInfo(item.type, item, index);
          })}
        </InputsWrapper>
        <ButtonsWrapper>
          {buttonsList.map((item: any, index: number) => {
            const {
              value,
              onClick,
              color,
              colorHover,
              borderColor,
              borderColorHover,
              backgroundColor,
              backgroundColorHover,
            } = item;

            return (
              <CommonButton
                key={index}
                onClick={onClick}
                width={'100%'}
                height={'40px'}
                colors={{
                  main: color,
                  mainHover: colorHover,
                  background: backgroundColor,
                  backgroundHover: backgroundColorHover,
                  border: borderColor,
                  borderHover: borderColorHover,
                }}
              >
                {value}
              </CommonButton>
            );
          })}
        </ButtonsWrapper>
        <SelectAddress
          state={TableCustomComponentState.EDIT}
          address={address}
          titleOfPopup={languageStrings.insightDetailsDeviceConfigurationEditInputsAddressTitle}
          setValue={handleSetAddressValue}
          saveToggle={false}
          externalState={isOpenAddressPopup}
          setExternalState={(isOpen: boolean) => {
            setIsOpenAddressPopup(isOpen);
          }}
          isInUniversalTable={false}
        />
      </StyledModalContainer>
    </StyledModal>
  );
};
