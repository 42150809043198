import React, { useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { InputWrapper } from '../inputWrapper/InputWrapper';
import { DatePicker } from '../datePicker/DatePicker';
import { Text, TextType } from '@bit/first-scope.text';
import { useWindowSize } from '@cthings.co/styles-utils';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type StyledInputWrapperProps = {
  title?: string;
  isEditInProcess?: boolean;
};

const StyledInputWrapper = styled(InputWrapper)<StyledInputWrapperProps>`
  // @TODO fix media types
  ${media.tablet} {
    margin: ${({ title, isEditInProcess }: any) => (title && isEditInProcess ? '0 0 20px 0' : '0')};
  }
  ${media.phone} {
    margin: ${({ isEditInProcess }: any) => (isEditInProcess ? '0 0 20px 0' : '0')};
  }
`;

type WrapperProps = {
  title?: string;
  isEditInProcess?: boolean;
  screenWidth: number;
  wrapperWidth?: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ title }) => (title ? '100%' : 'max-content')};
  display: flex;
  flex-direction: ${({ screenWidth, wrapperWidth, isEditInProcess }) =>
    screenWidth > 480 ? (wrapperWidth && wrapperWidth < 377 && isEditInProcess ? 'column' : 'row') : 'row'};
  align-items: center;

  ${media.phone} {
    width: ${({ isEditInProcess }: any) => (isEditInProcess ? '100%' : 'max-content')};
    flex-direction: ${({ isEditInProcess }: any) => (isEditInProcess ? 'row' : 'column')};
  }
  ${media.phoneXS} {
    width: 100%;
    flex-direction: column;
  }
`;

type StyledDatePickerProps = {
  isEditInProcess?: boolean;
};

const StyledDatePicker = styled(DatePicker)<StyledDatePickerProps>`
  margin: 0;
  & > div {
    width: 100%;
  }

  ${media.phone} {
    width: ${({ isEditInProcess }: any) => (isEditInProcess ? 'calc((100% - 16px) / 2)' : '100%')};
    & > div {
      height: 40px;
      padding: 4px 0;
      justify-content: center;
    }
  }

  ${media.phoneXS} {
    width: 100%;
    & > div {
      max-width: none;
      justify-content: space-between;
      padding: 4px 12px;
      & > div:nth-child(2n) {
        width: 40%;
        justify-content: space-between;
      }
    }
  }
` as any; // @TODO fix type

const Line = styled(Text)``;

export interface DateRangeOptions {
  title?: string;
  validatingIsStarted?: boolean;
}

export const DateRange = ({
  value,
  handleChangeValue,
  options,
  isEditInProcess,
  ...props
}: {
  value: any;
  handleChangeValue: any;
  options: DateRangeOptions;
  isEditInProcess?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const [needToUpdate, setNeedToUpdate] = useState(false);
  const { title, validatingIsStarted } = options;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperWidth = wrapperRef?.current?.clientWidth;
  const screenWidth = useWindowSize()[0];

  const { startDate, endDate } = value;
  const { setStartDate, setEndDate } = handleChangeValue;

  return (
    <StyledInputWrapper title={title} wrapperRef={wrapperRef} isEditInProcess={isEditInProcess} {...props}>
      <Wrapper title={title} wrapperWidth={wrapperWidth} isEditInProcess={isEditInProcess} screenWidth={screenWidth}>
        <StyledDatePicker
          globalValue={startDate}
          handleChangeValue={setStartDate}
          options={{ isEditInProcess, validatingIsStarted, isFocusable: true, needToUpdate }}
          functions={{
            handleOnBlurItem: () => {
              const nextDay = new Date(startDate.setDate(startDate.getDate() + 1));
              if (startDate > endDate) {
                setEndDate(nextDay);
                setNeedToUpdate(true);
              }
            },
            setNeedToUpdate,
          }}
          maxDate={endDate}
        />
        <Line type={TextType.TEXT_14_BLACK} color={gray1} margin={'0 4px'}>
          {'-'}
        </Line>
        <StyledDatePicker
          globalValue={endDate}
          handleChangeValue={setEndDate}
          options={{ isEditInProcess, validatingIsStarted, isFocusable: true, needToUpdate }}
          functions={{
            handleOnBlurItem: () => {
              const prevDay = new Date(endDate.setDate(endDate.getDate() - 1));
              if (endDate < startDate) {
                setStartDate(prevDay);
                setNeedToUpdate(true);
              }
            },
            setNeedToUpdate,
          }}
        />
      </Wrapper>
    </StyledInputWrapper>
  );
};
