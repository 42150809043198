import { WritableDraft } from 'immer/dist/internal';
import { EditAddressModalMobileState } from './types';

export const toggleIsOpenModal = (state: WritableDraft<EditAddressModalMobileState>) => {
  state.isOpenModal = !state.isOpenModal;
};

export const reducers = {
  toggleIsOpenModal,
};
