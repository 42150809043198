import React from 'react';
import styled from '@cthings.co/styled-components';

type SvgType = {
  color: string;
  hoverColor: string;
};

const Svg = styled.svg<SvgType>`
  & > path {
    stroke: ${({ color }) => color};
    transition: all 0.3s linear;
  }
  &:hover {
    & > path {
      stroke: ${({ hoverColor }) => hoverColor};
      transition: all 0.3s linear;
    }
  }
`;

export const DeleteIcon = ({ color, hoverColor }: { color: string; hoverColor: string }) => {
  return (
    <Svg
      color={color}
      hoverColor={hoverColor}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.7002 7.7998H7.1002H18.3002" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9.19863 7.8V6.4C9.19863 6.0287 9.34613 5.6726 9.60868 5.41005C9.87123 5.1475 10.2273 5 10.5986 5H13.3986C13.7699 5 14.126 5.1475 14.3886 5.41005C14.6511 5.6726 14.7986 6.0287 14.7986 6.4V7.8M16.8986 7.8V17.6C16.8986 17.9713 16.7511 18.3274 16.4886 18.5899C16.226 18.8525 15.8699 19 15.4986 19H8.49863C8.12733 19 7.77123 18.8525 7.50868 18.5899C7.24613 18.3274 7.09863 17.9713 7.09863 17.6V7.8H16.8986Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.5996 11.2998V15.4998" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.4004 11.2998V15.4998" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
    </Svg>
  );
};
