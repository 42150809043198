import { useTheme } from '@cthings.co/styled-components';
import { useMediaType } from '@cthings.co/styles-utils';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../app/state/user';
import { CardType, DashboardCard } from '../../../../../../../../components/dashboardCard/DashboardCard';

import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { StatusOfCard } from '../../../../../../../../types/statusOfCards';
import { InfoCard } from '../../../../../../../../components/infoCard/InfoCard';

const GlobalRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CardRow = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

type StyledCardProps = {
  statusOfValue: StatusOfCard;
};

const StyledCard = styled(DashboardCard)<StyledCardProps>`
  & > div > div {
    & > span {
      color: ${({ statusOfValue, theme }) =>
        statusOfValue === StatusOfCard.CRITICAL
          ? colorFetch('red2')({ theme })
          : statusOfValue === StatusOfCard.WARNING
          ? colorFetch('orange2')({ theme })
          : statusOfValue === StatusOfCard.NORMAL
          ? colorFetch('black1')({ theme })
          : colorFetch('black1')({ theme })};
    }
    & > svg > path {
      stroke: ${({ statusOfValue, theme }) =>
        statusOfValue === StatusOfCard.CRITICAL
          ? colorFetch('red2')({ theme })
          : statusOfValue === StatusOfCard.WARNING
          ? colorFetch('orange2')({ theme })
          : statusOfValue === StatusOfCard.NORMAL
          ? colorFetch('primary')({ theme })
          : colorFetch('primary')({ theme })};
    }
  }
  ${media.desktop} {
    width: 100%;
    margin: 0;
  }
` as any; // @TODO fix type

const Space = styled.div`
  flex: 1;
  ${media.desktop} {
    order: -1;
  }
  ${media.phone} {
    display: none;
  }
`;

export const Cards = ({
  humidity,
  temp,
  statusOfHumidity,
  statusOfTemperature,
  infoCardData,
}: {
  humidity: any;
  temp: any;
  statusOfHumidity: any;
  statusOfTemperature: any;
  infoCardData?: any;
}) => {
  const theme = useTheme();

  const { desktop } = useMediaType();

  const languageStrings = useSelector(selectLanguageStrings);

  const { heading, issues } = infoCardData ? infoCardData : { heading: '', issues: '' };

  const countOfTemperature = temp && !isNaN(temp?.measurement) ? `${temp.measurement} ${temp.unit}` : 'N/A';
  const countOfHumidity =
    humidity && !isNaN(humidity?.measurement) ? `${humidity.measurement} ${humidity.unit}` : 'N/A';

  return (
    <GlobalRow>
      <CardRow>
        <StyledCard
          placeholderProps={{
            keyword: 'ManholeDetailsCard',
            style: desktop ? { margin: '0', width: '100%' } : { margin: '0 20px 0 0' },
          }}
          Card={theme}
          languageStrings={languageStrings}
          title={temp?.tile_name || languageStrings.insightDetailsRealTimeCardsTemperature}
          type={CardType.TEMPERATURE}
          count={countOfTemperature}
          statusOfValue={statusOfTemperature}
        />
        <StyledCard
          placeholderProps={{
            keyword: 'ManholeDetailsCard',
            style: desktop ? { width: '100%' } : {},
          }}
          Card={theme}
          languageStrings={languageStrings}
          title={humidity?.tile_name || languageStrings.humidity}
          type={CardType.HUMIDITY}
          count={countOfHumidity}
          statusOfValue={statusOfHumidity}
        />
        {infoCardData && (
          <>
            <Space />
            <InfoCard title={heading} data={issues} />
          </>
        )}
      </CardRow>
    </GlobalRow>
  );
};
