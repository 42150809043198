import React, { FC, ReactNode } from 'react';
import styled from '@cthings.co/styled-components';
import Tooltip, { TooltipPlace, useTooltip } from '../tooltip/Tooltip';
import { ReactComponent as HelpImage } from '../../assets/help.svg';
import { ReactComponent as InfoIcon } from '../../assets/Info.svg';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type ButtonProps = {
  margin?: string;
  color?: string;
  colorHover?: string;
  width?: string;
  height?: string;
};

const Button = styled.a<ButtonProps>`
  display: flex;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  margin: ${({ margin }) => (margin ? margin : '0 0 0 4px')};
  display: flex;
  justify-content: flex-end;
  & svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    > path {
      transition: 0.3s ease;
      stroke: ${({ color, theme }) => (color ? color : colorFetch('gray3')({ theme }))};
    }
  }
  &:hover {
    & svg > path {
      stroke: ${({ colorHover, theme }) => (colorHover ? colorHover : colorFetch('gray2')({ theme }))};
    }
  }
`;

export interface InfoButtonProps {
  onClick: any;
  tooltipText: string;
  color?: string;
  colorHover?: string;
  margin?: string;
  tooltipPlace?: TooltipPlace;
  className?: string;
  link?: string;
  customIcon?: ReactNode;
  isInfoIcon?: boolean;
  width?: string;
  height?: string;
  tooltipWidth?: string;
}

export const InfoButton: FC<InfoButtonProps> = ({
  onClick,
  tooltipPlace,
  color,
  margin,
  colorHover,
  tooltipText,
  link,
  customIcon,
  isInfoIcon,
  width,
  height,
  tooltipWidth,
  ...props
}) => {
  const theme = useTheme();
  const handleClick = () => {
    onClick && onClick();
  };

  const [tooltipIds] = useTooltip();

  return (
    <Button
      href={link}
      target="_blank"
      onClick={handleClick}
      margin={margin}
      color={color}
      colorHover={colorHover}
      theme={theme}
      width={width}
      height={height}
      {...props}
    >
      <Tooltip id={tooltipIds[0]} text={tooltipText} place={tooltipPlace} width={tooltipWidth}>
        {isInfoIcon ? <InfoIcon /> : <HelpImage />}
      </Tooltip>
    </Button>
  );
};
