/**
 * Line area for the Rechart library graph
 */

import React from 'react';
import { Area } from 'recharts';
import { createId } from '../../../../utils/uuid';
import { setGradientArea } from '../gradientArea';

export const setLineArea = (areaRef: any, isRoundedType: boolean, theme: any, color: string) => {
  const { primary } = theme.colors;
  const gradientId = createId('y', 0);

  return (
    <>
      <Area
        ref={areaRef}
        type={isRoundedType ? 'monotoneX' : 'linear'}
        dataKey={'y'}
        stroke={color || primary}
        fillOpacity={0.4}
        fill={`url(#${gradientId})`}
        animationEasing={'ease-in-out'}
      />
      {setGradientArea(gradientId, color || primary)}
    </>
  );
};
