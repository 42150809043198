import { API_URL } from '../../consts';
import { ApiCallType } from '../../utils/http';
import { useApi, generateApi } from '../../features/apiBuilder/useApiBuilder';

export const updateInvoiceDetails = ({
  body,
  handleError,
  handleSuccess,
}: {
  body: { organisation_name: string; vat_number: string; address: any };
  handleError?: (data: any) => void;
  handleSuccess?: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/billing/invoice_details`;
  return generateApi({ url, callType: ApiCallType.PATCH })({ handleError, handleSuccess, body });
};

export const updateDeliveryAddress = ({
  body,
  handleError,
  handleSuccess,
}: {
  body: { name: string; address: any };
  handleError?: (data: any) => void;
  handleSuccess?: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/billing/delivery_address`;
  return generateApi({ url, callType: ApiCallType.PATCH })({ handleError, handleSuccess, body });
};

export const updatePaymentMethod = ({
  id,
  body,
  handleError,
  handleSuccess,
}: {
  id: string;
  body: any;
  handleError?: (data: any) => void;
  handleSuccess?: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/billing/payment_details/${id}`;
  return generateApi({ url, callType: ApiCallType.PATCH })({ handleError, handleSuccess, body });
};

export const updatePaymentDetails = ({
  field_id,
  body,
  handleError,
  handleSuccess,
}: {
  field_id: string;
  body: any;
  handleError?: (data: any) => void;
  handleSuccess?: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/billing/payment_details/${field_id}`;

  return generateApi({ url, callType: ApiCallType.PATCH })({ handleError, handleSuccess, body });
};

export const useGetBillingInfoData = () => {
  const url = `${API_URL}/v2/billing/address_details`;

  const [data] = useApi({
    keywords: ['InvoiceInfo'],
    defaultValue: {},
    callType: ApiCallType.GET,
    url,
    transformResponse: (data) => data.payload,
  });

  return [data];
};

export const useGetPaymentsMethods = () => {
  const url = `${API_URL}/v2/billing/payment_details/payment_methods`;

  const [data] = useApi({
    keywords: ['PaymentsMethods'],
    url,
    transformResponse: (data) => data?.values,
  });
  return [data];
};

export const useGetInvoiceTypes = () => {
  const url = `${API_URL}/v2/billing/payment_details/invoice_types`;

  const [data] = useApi({
    keywords: ['InvoiceTypes'],
    url,
    transformResponse: (data) => data?.values,
  });

  return [data];
};
