import { Address } from '../types/address';

export enum FieldsType {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  CUSTOM = 'CUSTOM',
  ADDRESS = 'ADDRESS',
}

export const NON_STRING_FIELDS = [FieldsType.CUSTOM, FieldsType.ADDRESS];

export const checkFieldValidity = ({
  value,
  validationRule,
  isDisabled,
}: {
  value: string | Record<string, unknown>;
  validationRule?: FieldsType;
  isDisabled?: boolean;
}) =>
  !!isDisabled ||
  (((validationRule && NON_STRING_FIELDS.includes(validationRule)) || value !== '') &&
    (!validationRule || isValidField(value, validationRule)));

export const isStringConsistOfSpaces = (str: string) => {
  const regex = /^\s+$/;
  return regex.test(str);
};

// @NOTE only several FieldsType values allow objects to be passed to value, the typisation can be improved later
export const isValidField = (value: string | Record<string, unknown>, type?: FieldsType, condition?: any) => {
  if (value && type === FieldsType.CUSTOM) {
    if (condition) {
      const valueForTest = typeof value === 'object' ? Object.values(value)[0] : value;
      return condition.test(valueForTest);
    } else {
      return true;
    }
  } else if (value && type === FieldsType.EMAIL) {
    const re = /^\w+([\.\-\+]\w+)*\w*@(\w+[\-\.])+\w+$/gm;
    return re.test(String(value as string).toLowerCase());
  } else if (value && type === FieldsType.PHONE_NUMBER) {
    const re = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/im;
    return re.test(value as string);
  } else if (value && type === FieldsType.NUMBER) {
    const re = /^\d+$/;
    return re.test(value as string) && +(value as string) > 0;
  } else if (type === FieldsType.SELECT) {
    const condition = value && (value as string).length > 0 ? true : false;
    return condition;
  } else if (FieldsType.TEXT) {
    const re = /^\s+$/;
    return value && (value as string).length > 0 && !re.test(value as string);
    // @TODO improve the validation rules for address, for now just availability of line1 is checked
  } else if (FieldsType.ADDRESS) {
    if (value) {
      const { line1 } = value as Address;
      return !!line1;
    } else {
      return false;
    }
  }
};
