export enum GeneralView {
  ADMIN = 'ADMIN',
  USER = 'USER',
  MANAGE = 'MANAGE',
  MANAGE_ANALYTICS = 'MANAGE_ANALYTICS',
  REDIRECT = 'REDIRECT',
  PLACEHOLDER = 'PLACEHOLDER',
  ERROR = 'ERROR',
  DOWNLOAD = 'DOWNLOAD',
  PROVISIONING_LOBBY = 'PROVISIONING_LOBBY',
}

export enum UserView {
  USER_MAIN = 'USER_MAIN',
  USER_INSIGHTS = 'USER_INSIGHTS',
  USER_INSIGHTS_DETAILS = 'USER_INSIGHTS_DETAILS',
  USER_INCIDENTS = 'USER_INCIDENTS',
  USER_DASHBOARD = 'USER_DASHBOARD',
  USER_MANAGE_CLIENTS = 'USER_MANAGE_CLIENTS',
  USER_MANAGE_DEVICES = 'USER_MANAGE_DEVICES',
  USER_MANAGE_USERS = 'USER_MANAGE_USERS',
  USER_PROBLEMS = 'USER_PROBLEMS',
  USER_DOCUMENTS = 'USER_DOCUMENTS',
  USER_REPORTS = 'USER_REPORTS',
  USER_SETTINGS = 'USER_SETTINGS',
  USER_PAIR_DEVICE_DETAILS = 'USER_PAIR_DEVICE_DETAILS',
  USER_PAIR_DEVICE_INSIGHTS = 'USER_PAIR_DEVICE_INSIGHTS',
  USER_MEASUREMENT_DEVICE_INSIGHTS = 'USER_MEASUREMENT_DEVICE_INSIGHTS',
  USER_MEASUREMENT_DEVICE_DETAILS = 'USER_MEASUREMENT_DEVICE_DETAILS',
  USER_MANAGE_DEVICES_DETAILS = 'USER_MANAGE_DEVICES_DETAILS',
  USER_TEST = 'USER_TEST',
}

export enum ManageView {
  MANAGE_ORGANISATIONS = 'MANAGE_ORGANISATIONS',
  MANAGE_ORGANISATIONS_DETAILS = 'MANAGE_ORGANISATIONS_DETAILS',
  MANAGE_DEVICES = 'MANAGE_DEVICES',
  MANAGE_USERS = 'MANAGE_USERS',
  MANAGE_MEASUREMENT = 'MANAGE_MEASUREMENT',
}

export enum ProvisioningView {
  PROVISIONING_ADDITIONAL_DEVICE = 'PROVISIONING_ADDITIONAL_DEVICE',
  PROVISIONING_DEVICE = 'PROVISIONING_DEVICE',
  PROVISIONING = 'PROVISIONING',
  PROVISIONING_SUBSCRIPTION = 'PROVISIONING_SUBSCRIPTION',
  PROVISIONING_COMPLETED = 'PROVISIONING_COMPLETED',
}

export enum BillingView {
  BILLING = 'BILLING',
  BILLING_PLANS = 'BILLING_PLANS',
  BILLING_INVOICES = 'BILLING_INVOICES',
  BILLING_DETAILS = 'BILLING_DETAILS',
}

export enum AdminView {
  ADMIN_MAIN = 'ADMIN_MAIN',
}

export enum ErrorView {
  ERROR_403 = 'ERROR_403',
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
  ERROR_401 = 'ERROR_401',
}

export enum DeviceDetailsType {
  TRACKER = 'TRACKER',
  NODE = 'NODE',
}
