import { GeneralView, UserView, ManageView, AdminView, ErrorView, BillingView, ProvisioningView } from '../enums/View';

export const View = {
  ...GeneralView,
  ...UserView,
  ...AdminView,
  ...ManageView,
  ...ProvisioningView,
  ...BillingView,
  ...ErrorView,
};

export type View = typeof View;

export type CombinedRoutes = {
  [key in keyof View]: any;
};

export type GeneralRoutes = {
  [key in keyof GeneralView]: any;
};

export type AdminRoutes = {
  [key in AdminView]: any;
};

export type UserRoutes = {
  [key in UserView]: any;
};

export type ManageRoutes = {
  [key in ManageView]: any;
};

export type ErrorRoutes = {
  [key in ErrorView]: any;
};
