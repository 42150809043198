import { ModalType } from '../../../enums/ModalType';

export const emptySearch = {
  city: '',
  isOpen: false,
};

export const emptyModal = {
  status: false,
  search: emptySearch,
  type: ModalType.NONE,
  windowOffset: 0,
  scrolled: true,
  redirect: false,
};
