import React from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  display: flex;
  background: ${colorFetch('red0')};
  width: 100%;
  height: 100vh;
`;

export const Main = () => {
  return <Wrapper />;
};
