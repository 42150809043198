import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '../../../../../../../../../components/commonButton/CommonButton';
import { ReactComponent as CloseIcon } from '../../../../../../../../../assets/Close.svg';
import { useStyleContext } from '@cthings.co/styles-utils';

export const StyledModal = styled(Modal)`
  ${media.phone} {
    align-items: flex-end;
    & > div {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  align-items: unset;
  padding: 24px;

  ${media.phone} {
    width: 100%;
    padding: 24px 16px;
    border-radius: 12px 12px 0px 0px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.phone} {
    flex-direction: column-reverse;
  }
`;

interface DeleteModalProps {
  isOpenModal: boolean;
  theme: any;
  languageStrings?: any;
  onDelete: any;
  setModalClose: (props: boolean) => void;
}

export const DeleteModal: FC<DeleteModalProps> = ({ isOpenModal, theme, languageStrings, onDelete, setModalClose }) => {
  const [media] = useStyleContext();
  const { black1, pureWhite, primary, red1, gray1 } = theme.colors;

  const handleCancelClick = () => {
    setModalClose(false);
  };

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer width={'384px'}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text type={TextType.TEXT_14_BLACK} fontSize={'18px'} weight={'600'} color={black1}>
            {languageStrings.emphasizedHeaderTextModalTitle}
          </Text>
          {media.mediaType.phone && <CloseIcon onClick={handleCancelClick} />}
        </div>
        <Text
          type={TextType.TEXT_14_GRAY}
          weight={'400'}
          color={gray1}
          margin={media.mediaType.phone ? '24px 0 24px 0' : '14px 0 32px 0'}
        >
          {languageStrings.deletPairModal}
        </Text>
        <ButtonsRow>
          <CommonButton
            onClick={handleCancelClick}
            width={media.mediaType.phone ? '100%' : '116px'}
            margin={media.mediaType.phone ? '0' : '0 16px 0 0'}
            color={black1}
            borderColor={primary}
            backgroundColor={pureWhite}
            height={media.mediaType.phone ? '40px' : '32px'}
            fontSize={'14px'}
          >
            {languageStrings.noButton}
          </CommonButton>
          <CommonButton
            onClick={onDelete}
            width={media.mediaType.phone ? '100%' : '116px'}
            margin={media.mediaType.phone ? '0 0 10px 0' : '0'}
            borderColor={red1}
            backgroundColor={red1}
            backgroundColorHover={red1}
            height={media.mediaType.phone ? '40px' : '32px'}
            fontSize={'14px'}
          >
            {languageStrings.yesButton}
          </CommonButton>
        </ButtonsRow>
      </StyledModalContainer>
    </StyledModal>
  );
};
