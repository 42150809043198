import React from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';
import { ReactComponent as Download } from '../../../../../assets/download.svg';
import { Modal } from '../../../../newModals/Modal';
import { ModalContainer } from '../../../../newModals/ModalContainer';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { useBlockBodyScroll } from '../../../../../utils/useBlockBodyScroll';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/user';
import { LanguageStrings } from '../../../../../languages/types';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { GraphType } from '../../../types';

const StyledModal = styled(Modal)`
  z-index: var(--modal-first-layer);
`;

const StyledModalContainer = styled(ModalContainer)`
  padding: 24px;
  max-width: 564px;
  align-items: flex-start;
  ${media.tablet} {
    padding: 24px 15px;
  } ;
`;

const Title = styled(Text)`
  font-family: 'Poppins';
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
`;

const StyledText = styled(Text)`
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  margin-top: 15px !important;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
  ${media.tablet} {
    flex-direction: column-reverse;
  } ;
`;

const CancelButton = styled(CommonButton)`
  margin-right: 15px;
  ${media.tablet} {
    margin: 0;
    width: 100%;
    height: 40px;
  } ;
`;

const DownloadButton = styled(CommonButton)`
  min-width: 126px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  &:hover {
    color: ${({ colors: { mainHover } = {} }) => mainHover};
  }
  & > svg {
    margin-right: 7px;
    width: 20px;
    height: 20px;
  }

  & > svg > path {
    stroke: ${({ colors: { main } = {} }) => main};
  }

  &:hover > svg > path {
    stroke: ${({ colors: { mainHover } = {} }) => mainHover};
  }

  ${media.tablet} {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
  }
`;

export const ExportModal = ({
  type,
  isOpenModal,
  setIsOpenModal,
  onClick,
}: {
  type: GraphType;
  isOpenModal: boolean;
  setIsOpenModal: (val: boolean) => void;
  onClick: () => void;
}) => {
  const languageStrings: LanguageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();
  const { black1, gray1, primary, pureWhite, secondary2 } = theme.colors;

  useBlockBodyScroll(isOpenModal);

  const getModalText = (type: GraphType) => {
    const text: any = {
      [GraphType.WATER_LEVEL]: languageStrings.accessDownloadWaterLevel,
      [GraphType.TEMPERATURE]: languageStrings.accessDownloadTemperature,
      [GraphType.EVENTS]: languageStrings.accessDownloadEvents,
    };
    return text[type];
  };

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Title type={TextType.TEXT_14_BLACK} color={black1}>
          {languageStrings.exportExcelFileTitle}
        </Title>
        <StyledText type={TextType.TEXT_14_BLACK} color={gray1}>
          {getModalText(type)}
        </StyledText>
        <ButtonsWrapper>
          <CancelButton
            onClick={() => {
              setIsOpenModal(false);
            }}
            colors={{
              main: black1,
              background: pureWhite,
              backgroundHover: primary,
              border: primary,
              borderHover: primary,
            }}
            width={'116px'}
            height={'32px'}
          >
            {languageStrings.cancelButton}
          </CancelButton>
          <DownloadButton
            onClick={onClick}
            colors={{
              main: pureWhite,
              mainHover: pureWhite,
              background: primary,
              backgroundHover: secondary2,
              border: primary,
            }}
            width={languageStrings.download.length * 12 + 20 + 'px' || '126px'}
            height={'32px'}
          >
            <Download />
            {languageStrings.download}
          </DownloadButton>
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
