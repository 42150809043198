import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { NavLink } from '../../../utils/react-router-dom-abstraction';
import { Text } from '@bit/first-scope.text';
//images
import { ReactComponent as ArrowImage } from '../../../assets/Shape_right.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { SimpleIcon } from '@cthings.co/base';

export const ItemWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-left: 18px;
  margin-bottom: 20px;
  &.hidden {
    display: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${media.tablet} {
    padding-left: 0;
    margin-bottom: 0;
  }
  ${media.phone} {
    margin: 0;
  }
`;

type StyledNavLinkProps = {
  borderRadius: string;
};

export const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-decoration: none;
  & > svg,
  & > svg > path {
    width: 20px;
    height: 20px;
    stroke: ${colorFetch('black1')};
  }
  & > div > div > svg > path {
    width: 20px;
    height: 20px;
    stroke: ${colorFetch('black1')};
  }
  &.active {
    position: relative;
    width: 100%;
    & > svg > path {
      transition: all 0.3s ease;
      stroke: ${({ theme }: any) => theme.colors.primary};
    }
    & > div > div > svg > path {
      transition: all 0.3s ease;
      stroke: ${({ theme }: any) => theme.colors.primary};
    }
    & div span {
      color: ${colorFetch('primary')};
    }
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: -18px;
      bottom: 0;
      width: 2px;
      background-color: ${colorFetch('primary')};
      border-radius: ${({ borderRadius }) => borderRadius};
      ${media.tablet} {
        display: none;
      }
    }
  }
  ${media.tablet} {
    padding: 10px 16px;
    &.active {
      background: ${({ theme }: any) => theme.colors.gray6};
    }
    & > svg,
    & > svg > path {
      width: 20px;
      height: 20px;
      stroke: ${colorFetch('primary')};
    }
    & > div > div > svg > path {
      width: 20px;
      height: 20px;
      stroke: ${colorFetch('primary')};
    }
    padding: 10px 16px;
    &.active {
      background: ${colorFetch('gray6')};
    }
  }

  // color, hovered color for icons
  & > svg,
  & > svg > path {
    width: 20px;
    height: 20px;
    transition: all 0.3s ease;
    stroke: ${({ theme }: any) => theme.colors.black1};
  }
  &:hover > svg > path {
    transition: all 0.3s ease;
    stroke: ${({ theme }: any) => theme.colors.primary};
    ${media.tablet} {
      stroke: ${({ theme }: any) => theme.colors.black1};
    }
  }
  & > div > div > svg > path {
    width: 20px;
    height: 20px;
    stroke: ${({ theme }: any) => theme.colors.black1};
  }
  &:hover > div > div > svg > path {
    transition: all 0.3s ease;
    stroke: ${({ theme }: any) => theme.colors.primary};
    ${media.tablet} {
      stroke: ${({ theme }: any) => theme.colors.black1};
    }
  }
  &:hover > div > span {
    transition: all 0.3s ease;
    color: ${({ theme }: any) => theme.colors.primary};
  }
`;

export const TextWrapper = styled.div`
  box-sizing: border-box;
  flex: 0;
  overflow: hidden;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
  ${media.tablet} {
    display: flex;
    overflow: visible;
  }
`;

export const ManageMobileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapperManage = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1px;
  flex: 0;
  overflow: hidden;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
  ${media.tablet} {
    width: 100%;
    flex: none;
    overflow: visible;
  }
`;

type ManageListProps = {
  height: string;
};

export const ManageList = styled.div<ManageListProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 0;
  width: 100%;
  transition: all 0.3s ease;
  &.open {
    height: ${({ height }) => height};
  }
  ${media.tablet} {
    display: none;
  }
`;

type StyledManageNavlinkProps = {
  hoverColor: string;
};

export const StyledManageNavlink = styled(NavLink)<StyledManageNavlinkProps>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  margin-top: 12px;
  /* margin-left: 30px; */
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1')};
  text-decoration: none;
  transition: all 0.2s linear;
  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    font-weight: 600;
  }
  ${media.tablet} {
    overflow: visible;
  }
`;

type ManageModalProps = {
  width?: string;
};

export const ManageModal = styled.div<ManageModalProps>`
  box-sizing: border-box;
  width: 0;
  padding: 15px 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 20px), -50%);
  background-color: ${colorFetch('white')};
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  &.open {
    width: ${({ width }) => width || '125px'};
    opacity: 1;
    pointer-events: auto;
  }
  ${media.tablet} {
    display: none;
  }
`;

export const ManageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px !important;
  text-decoration: none;
  ${media.tablet} {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    overflow: visible;
    &:hover {
      color: ${colorFetch('gray1')};
    }
  }
`;

export const StyledManageArrowImage = styled(ArrowImage)`
  transform: rotate(90deg);
  transition: all 0.3s ease;
  &.open {
    transform: rotate(-90deg);
  }
  ${media.tablet} {
    display: none;
  }
`;

export const WrapperLanguages = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-left: 18px;
  margin-bottom: 50px;
  ${media.tablet} {
    display: flex;
    align-content: center;
    padding-right: 16px;
  }
`;

export const SelectedLang = styled.div`
  box-sizing: border-box;
  transform: translateY(100%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  &.openNav {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const StyledLangArrowImage = styled(ArrowImage)`
  transition: all 0.3s ease;
  &.open {
    transform: rotate(-180deg);
  }
`;

export const SelectedLangSec = styled.div`
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  &.openNav {
    opacity: 0;
  }
`;

export const ListLanguages = styled.div`
  box-sizing: border-box;
  width: 0;
  padding: 12px 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 20px), calc(-50% + 12px));
  background-color: ${colorFetch('white')};
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
  overflow: hidden;
  &.open {
    width: 110px;
    opacity: 1;
    pointer-events: auto;
  }
  ${media.tablet} {
    display: none;
  }
`;

export const ItemLang = styled.div`
  cursor: pointer;
  color: gray1;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 14px;
  transition: all 0.3s ease;
  &:hover {
    color: ${colorFetch('primary')};
  }
  &.selected {
    font-weight: 600;
  }
  &.last {
    margin: 0;
  }
  ${media.tablet} {
    width: 100%;
    color: ${colorFetch('black')};
    font-size: 16px;
    font-weight: 500;
    padding: 10px 16px;
    margin: 0;
    &.selected {
      color: ${colorFetch('primary')};
      background: ${colorFetch('gray6')};
    }
  }
`;

export const WrapperLogout = styled.div`
  box-sizing: border-box;
  padding-left: 18px;
  ${media.tablet} {
    padding: 10px 16px 20px;
  }
`;

export const UserNameWrapper = styled.div`
  box-sizing: border-box;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  &.open {
    width: 100%;
  }
`;

export const Line = styled.div`
  box-sizing: border-box;
  margin-bottom: 15px;
  height: 1px;
  width: 0;
  background-color: ${colorFetch('gray5')};
  transition: width 0.3s ease;
  &.open {
    width: 100%;
  }
`;

export const LogoutButton = styled.button`
  box-sizing: border-box;
  border: none;
  outline: transparent;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledLogoutText = styled(Text)`
  margin-left: 10px !important;
  ${media.tablet} {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2px;
  }
`;

export const StyledSimpleIcon = styled(SimpleIcon)`
  transition: all 0.3s ease;
  /* transform: ${({ openManageList }: { openManageList: boolean }) => (openManageList ? 'rotate(180deg)' : 'none')}; */
  transform: rotate(180deg);
  ${media.tablet} {
    display: none;
  }
`;
