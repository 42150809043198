import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';
import { ReactComponent as Plus } from '../assets/plus.svg';

const StyledButton = styled(CommonButton)`
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;
export interface ComponentProps {
  // type: PlaceholderType;
}

export const PlaceholderAddButton: FC<ComponentProps> = () => {
  return (
    <StyledButton onClick={() => {}} width="100%">
      <Plus /> Add
    </StyledButton>
  );
};
