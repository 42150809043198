import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as FileIcon } from '../../../../../../../assets/file-text.svg';
import { NoteModal } from '../noteModal/NoteModal';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  isTopBlured?: boolean;
  status: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: ${({ isTopBlured }) => (isTopBlured ? '1000' : '1000000')};
  & > svg > path {
    stroke: ${({ status, theme }) =>
      status === 5 ? colorFetch('pureWhite')({ theme }) : colorFetch('primary')({ theme })};
    transition: all 0.3s linear;
  }
  &:hover {
    transition: all 0.3s linear;
    & > span {
      color: ${({ status, theme }) =>
        status === 5 ? colorFetch('gray4')({ theme }) : colorFetch('secondary2')({ theme })};
    }
    & > svg > path {
      stroke: ${({ status, theme }) =>
        status === 5 ? colorFetch('gray4')({ theme }) : colorFetch('secondary2')({ theme })};
    }
  }
  ${media.phone} {
    justify-self: end;
    align-self: start;
  }
`;

const StyledText = styled(Text)`
  text-decoration: underline;
`;

export const NoteButton = ({
  data,
  status,
  isTopBlured,
  languageStrings,
  ...props
}: {
  data: { ts: string; desc: string; text: string };
  status: number;
  isTopBlured?: boolean;
  languageStrings?: any;
  className?: string;
}) => {
  const theme = useTheme();
  const { pureWhite, primary } = theme.colors;
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <Wrapper onClick={openModal} theme={theme} status={status} isTopBlured={isTopBlured} {...props}>
        <FileIcon />
        <StyledText type={TextType.TEXT_14_GREEN} color={status === 5 ? pureWhite : primary} margin={'0 0 0 2px'}>
          {languageStrings.noteText}
        </StyledText>
      </Wrapper>

      <NoteModal isOpenModal={isOpenModal} data={data} closeNoteModal={closeModal} languageStrings={languageStrings} />
    </>
  );
};
