import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { CopyButton } from '../../copyButton/CopyButton';
import { selectLanguage, selectLanguageStrings } from '../../../app/state/user';
import { useNotifierFunctions } from '../../../features/notifier2';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  width: string;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ width }) => width};
  box-sizing: border-box;
`;

const Title = styled(Text)`
  display: flex;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${colorFetch('gray1_5')};

  ${media.phone} {
    &:nth-child(1) {
      margin-bottom: 16px;
    }
  }
`;

const CopyBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
`;

// @TODO fix DeviceId and StyledCopyButton type

const DeviceId = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('blue2')};
  color: ${colorFetch('blue2')};
  margin-right: 4px;
  cursor: pointer;
` as any;

const StyledCopyButton = styled(CopyButton)`
  cursor: pointer;
  margin-left: 3px;
  &:hover {
    & > span {
      color: ${colorFetch('gray2')};
    }
    & > svg > path {
      stroke: ${colorFetch('gray3')};
    }
  }
` as any;

export interface InfoProps {
  deviceTitle: string;
  deviceName: string;
  className?: string;
  onClick?: () => void;
}

export const Info: React.FC<InfoProps> = ({ deviceTitle, deviceName, onClick, className }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage);
  const { addNotification } = useNotifierFunctions();

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({
      message: languageStrings.copiedMessage,
      type: 'info',
    });
  };

  const width = language.shortName === 'PL' ? '320px' : '295px';

  return (
    <Wrapper width={width}>
      <Title className={className} type={TextType.TEXT_14_BLACK}>
        {deviceTitle}
        <CopyBlock>
          <DeviceId onClick={onClick}>{deviceName}</DeviceId>
          <StyledCopyButton onClick={() => handleCopyText(deviceName)} />
        </CopyBlock>
      </Title>
    </Wrapper>
  );
};
