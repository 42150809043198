import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_URL } from '../../consts';
import { HttpService } from '../../utils/http';
import { selectLanguage } from '../../app/state/user';
import { useLoaderOptions } from '../../features/placeholderComponent/loaderFunctions';

export const useMeasurementDeviceInsights = (id: string, setPlaceholder?: any, stopPlaceholder?: any) => {
  const language = useSelector(selectLanguage);
  const lang = language.id;
  const [data, setData] = useState();

  const trigger = [id, lang] as const;

  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);

  useEffect(() => {
    const url = `${API_URL}/v2/logical-set/${id}/insight?lang=${lang}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload;

        setData(responseData);
      } catch (e) {}
    })();
  }, [...trigger]);

  return data;
};
