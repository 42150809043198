import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { TableInput, TableInputType } from '../../../../../../components/tableInput/TableInput';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { InfoButton } from '../../../../../../components/infoButton/InfoButton';

const Label = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1')};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 4px 6px;
`;

interface DepthProps {
  value: number;
  maxDepth: number;
  minDepth: number;
  setValue: (props: number) => void;
  onClick?: (props: boolean) => void;
}

export const Depth: FC<DepthProps> = ({ value, maxDepth, minDepth, setValue, onClick }) => {
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <>
      <Header>
        <Label type={TextType.TEXT_14_GRAY}>{languageStrings.depth}</Label>
        <InfoButton
          width={'22px'}
          height={'22px'}
          onClick={onClick}
          tooltipText={languageStrings.deploySetDepthModalText}
          isInfoIcon
        />
      </Header>
      <TableInput
        type={TableInputType.SLIDER_FREE_INPUT_WITH_UNIT}
        value={value}
        handleChangeValue={setValue}
        options={{ unit: 'm', step: 0.01, min: minDepth, max: maxDepth }}
        isEditInProcess={true}
      />
    </>
  );
};
