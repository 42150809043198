import { Text, TextType } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import React, { FC, useCallback } from 'react';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { CommonButton } from '../../../commonButton/CommonButton';
import { WavesImage } from '../WavesImage';
import { DeviceType } from '../../../../enums/DeviceType';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const FlowInfoWrapper = styled.div``;

type FlowInfoSeparatorType = {
  borderColor: string;
  backgroundColor: string;
};

const FlowInfoSeparator = styled.div<FlowInfoSeparatorType>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 7px 0;

  & > span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }
`;

const FlowInfoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
`;

type FlowDataProps = {
  isLast: boolean;
};

const FlowData = styled.div<FlowDataProps>`
  padding: 10px 0;
  border-bottom: ${({ isLast }) => (isLast ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray4')};

  &:first-child {
    padding: 0 0 10px 0;
  }
`;

const NameWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;

  & > span {
    font-weight: 600;
  }
`;

const WavesWrapper = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin: 0 4px 0 2px;
  }
`;

type DeviceTypeWrapperProps = {
  withPadding: boolean;
};

const DeviceTypeWrapper = styled.div<DeviceTypeWrapperProps>`
  width: 90%;
  padding-left: ${({ withPadding }) => (withPadding ? '22px' : '0')};

  & > span {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0;
`;

export interface FlowInfoProps {
  //TODO add types
  flowData: any[];
  history: any;
  withFlow: boolean;
  withFlowSeparator: boolean;
  type?: string;
  languageStrings?: any;
}

export const FlowInfo: FC<FlowInfoProps> = ({
  flowData,
  history,
  withFlow,
  withFlowSeparator,
  type,
  languageStrings,
}) => {
  const theme = useTheme();
  const { black1, gray2, gray4, gray6, gray1 } = theme.colors;

  const handleClick = useCallback(
    (path: any) => {
      path && history && history.push(path);
    },
    [history],
  );

  return (
    <>
      <FlowInfoWrapper>
        {withFlowSeparator && (
          <FlowInfoSeparator borderColor={gray4} backgroundColor={gray6}>
            <Text type={TextType.TEXT_12_GRAY} color={gray1}>
              {languageStrings.flowInfo}
            </Text>
          </FlowInfoSeparator>
        )}
        <FlowInfoDataWrapper>
          {(flowData || []).map((item: any, index: number, arr: any) => {
            const { name, measurement, unit, setType, ts, to } = item;
            const date = ts ? getRequiredDateFormat(ts, 'DD.MM.YYYY') : 'N/A';
            const time = ts ? getRequiredDateFormat(ts, 'HH:mm') : 'N/A';

            return (
              <FlowData isLast={index === flowData.length - 1}>
                <NameWrapper>
                  <Text type={TextType.TEXT_14_BLACK} color={black1}>
                    {arr.length > 1 ? name || 'N/A' : ''}
                  </Text>
                </NameWrapper>
                {withFlow && (
                  <WavesWrapper>
                    <WavesImage />
                    <Text type={TextType.TEXT_14_BLACK} color={black1}>
                      {measurement && unit ? `${measurement} ${unit} (${languageStrings.average})` : 'N/A'}
                    </Text>
                  </WavesWrapper>
                )}
                <DeviceTypeWrapper withPadding={withFlow}>
                  <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                    {setType || (type === DeviceType.MANNINGS_PAIR && languageStrings.manningsPair) || 'N/A'}
                  </Text>
                </DeviceTypeWrapper>
                <DateWrapper>
                  <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                    {date || 'N/A'}
                  </Text>
                  <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                    {time || 'N/A'}
                  </Text>
                </DateWrapper>
                <CommonButton onClick={() => handleClick(to)} height="25px" width="100%" noFocus>
                  {languageStrings.commonButtonMore}
                </CommonButton>
              </FlowData>
            );
          })}
        </FlowInfoDataWrapper>
      </FlowInfoWrapper>
    </>
  );
};
