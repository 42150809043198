import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { Select } from '../../../../../../../../../../components/universalSelect/Select';
import { useTableContext, useTableFunctions } from '../../../../../../../../../../features/universalTable/context';
import { TableCustomComponentState } from '../../../../../../../../../../features/universalTable/types';
import { media } from '@cthings.co/styles-utils';

const StyledSelect = styled(Select)`
  width: 230px;
  & > div {
    min-height: 40px;
  }
  & > span:first-child {
    display: none;
  }
  & > div:nth-child(3) {
    top: 52px;
  }
  ${media.tablet} {
    width: 100%;
  }
`;

export const StatusComponent = ({
  globalValue,
  state,
  languageStrings,
}: {
  globalValue: string;

  state?: TableCustomComponentState;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const { setField, getModalItems } = useTableFunctions();
  const [{ openIndex, isEditInProcess, isAddInProcess }] = useTableContext();
  const statusData = getModalItems()[3] ? getModalItems()[3] : {};
  const { value, fieldName, isError, placeholder, validatingIsStarted } = statusData;
  const selectList = [
    { name: 'Done', id: 'Done' },
    { name: 'Planned', id: 'Planned' },
  ];

  const handleOnChangeValue = (value: { name: string; id: string }) => {
    setField(openIndex, value.name, fieldName);
  };

  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
          {globalValue || 'N/A'}
        </Text>
      ) : (
        <StyledSelect
          label={'Status'}
          value={{ name: value, id: value }}
          disabled={!(isEditInProcess || isAddInProcess)}
          values={selectList}
          onChange={(value: any) => handleOnChangeValue(value)}
          placeholder={isEditInProcess || isAddInProcess ? placeholder : 'N/A'}
          startedValidation={validatingIsStarted}
          isError={isError && (isEditInProcess || isAddInProcess)}
          languageStrings={languageStrings}
        />
      )}
    </>
  );
};
