import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as PieImage } from './assets/pieImage.svg';
import { ResponsivePie } from '@nivo/pie';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 126px;
  & > div > div > svg > g > g:first-child {
    transform: translate(70, 63);
  }
  & > div > div > svg > g > g > g > text {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
  }
`;

const ImageWrapper = styled.div`
  margin-left: 20px;
`;

export const Pie = ({ data }: { data: { id: string; label: string; value: number }[] }) => {
  const theme = useTheme();

  const sortingData = (data: any) => {
    const filteredData = data
      ? [...data].sort((a: any, b: any) => b.value - a.value).filter((item: any, index: number) => index < 4)
      : [];

    const correctedLabelLengthData = filteredData.map((item: any) =>
      item?.label?.length > 11 ? { ...item, label: item?.label.substring(0, 11) + '...' } : item,
    );

    const result =
      data.length < 1
        ? [...correctedLabelLengthData]
        : [...correctedLabelLengthData, { id: 'Other', label: 'Other', value: data.length - filteredData.length }];

    return result;
  };

  return (
    <Wrapper>
      {data.length < 1 ? (
        <ImageWrapper>
          <PieImage />
        </ImageWrapper>
      ) : (
        <ResponsivePie
          data={sortingData(data)}
          margin={{ top: 0, right: 100, bottom: 0, left: 0 }}
          colors={{ scheme: 'paired' }}
          innerRadius={0.6}
          cornerRadius={0}
          sliceLabelsSkipAngle={10}
          sliceLabelsTextColor={theme.colors.pureWhite}
          enableRadialLabels={false}
          legends={[
            {
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 10,
              itemWidth: 115,
              itemHeight: 16,
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: 'circle',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
