import { OverlayGradient } from '@bit/first-scope.overlay-gradient';
import styled from '@cthings.co/styled-components';
import React, { FC, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { Map as MapGl, Marker } from 'react-map-gl';
import { MAPBOX_FLAG, MAPBOX_TOKEN } from '../../consts';
import { useMapResizeControl } from '../../features/mapResizeControl/useMapResizeControl';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { ManholeButton, ManholesType } from '../manholeButton/ManholeButton';
import { Placeholder } from '../placeholders/Placeholders';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOverlayGradient = styled(OverlayGradient)`
  height: 140px;
  bottom: -2px;
`;

const SemiWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.additional10};
`;

export interface MapProps {
  zoom: number;
  latitude?: number;
  longitude?: number;
  isGradiented?: boolean;
  staticDisplay?: boolean;
  deviceType?: string;
  className?: string;
  data?: any;
}
const MapPlain: FC<MapProps> = ({
  zoom,
  data,
  isGradiented,
  latitude,
  longitude,
  staticDisplay,
  deviceType,
  ...props
}) => {
  const theme = useTheme();
  const latitudeShifter = (value?: number) => {
    return isGradiented ? (value || 0) - 0.01 / (zoom - 10) : value || 0;
  };

  const [viewport, setViewport] = useState<any>({
    latitude: latitudeShifter(latitude),
    longitude: latitudeShifter(longitude),
    width: '100%',
    height: '100%',
    zoom: zoom || 12,
    transitionDuration: 'auto',
  });

  //resize map
  const mapbox = useMapResizeControl(setViewport);

  const mapReference = useRef();

  useEffect(() => {
    setViewport((currentViewport: any) => ({
      ...currentViewport,
      latitude: latitudeShifter(latitude),
      longitude: latitudeShifter(longitude),
    }));
  }, [longitude, latitude]);

  return (
    <Wrapper {...props}>
      {MAPBOX_FLAG ? (
        <SemiWrapper ref={mapbox} theme={theme}>
          {isGradiented && <StyledOverlayGradient />}
          <MapGl
            {...viewport}
            mapboxAccessToken={MAPBOX_TOKEN}
            ref={mapReference as any}
            onResize={(e: any) => {}}
            onMove={({ viewState }: any) => setViewport(viewState)}
            mapStyle="mapbox://styles/mapbox/light-v11"
            attributionControl={false}
          >
            {data ? (
              data.map((item: any) => {
                const { lat, lng } = item;
                return (
                  <Marker latitude={lat || 0} longitude={lng || 0}>
                    <ManholeButton type={ManholesType.GREEN} deviceType={deviceType} staticDisplay isReq={true} />
                  </Marker>
                );
              })
            ) : (
              <Marker latitude={latitude || 0} longitude={longitude || 0}>
                <ManholeButton type={ManholesType.GREEN} deviceType={deviceType} staticDisplay isReq={true} />
              </Marker>
            )}
          </MapGl>
        </SemiWrapper>
      ) : (
        <Placeholder type={PlaceholderType.GRADIENT_MAP} />
      )}
    </Wrapper>
  );
};

export const Map = withLoader(true, PlaceholderType.GRADIENT_MAP)(MapPlain);

Map.defaultProps = {
  zoom: 12,
  latitude: 52.25603,
  longitude: 20.98765,
  isGradiented: false,
};
