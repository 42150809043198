import { defaultAccess } from '../utils/react-router-dom-interfaces';
import { CombinedRoutes, View } from './routeInterfaces';
import { isEmpty } from 'underscore';
import { PathParams, QueryParams } from './types';
import { stringifyUrl } from 'query-string';

//const

export const MAPPING = Object.freeze({
  [View.ADMIN]: { path: '/admin', access: defaultAccess },
  [View.ADMIN_MAIN]: { path: '/admin/main', access: defaultAccess },
  [View.USER]: { path: '/user', access: defaultAccess },
  [View.USER_MAIN]: { path: '/user/main', access: defaultAccess },
  [View.USER_INSIGHTS_DETAILS]: {
    path: '/user/insights/:id/:type',
    access: ['insight'],
  },
  [View.USER_INSIGHTS]: { path: '/user/insightsmap/:lat/:lon/:zoom', access: ['insight'] },
  [View.USER_INCIDENTS]: { path: '/user/incidents', access: ['incidents'] },
  [View.USER_DASHBOARD]: { path: '/user/dashboard', access: ['dashboard'] },
  [View.USER_REPORTS]: { path: '/user/reports', access: ['reports'] },
  [View.USER_DOCUMENTS]: { path: '/user/documents', access: ['documents'] },
  [View.USER_PROBLEMS]: { path: '/user/problems', access: ['problems'] },
  [View.USER_MANAGE_DEVICES_DETAILS]: {
    path: '/user/manage/devices/details/:id',
    access: defaultAccess,
  },
  [View.USER_SETTINGS]: { path: '/user/settings', access: ['settings'] },
  [View.USER_TEST]: { path: '/user/test', access: [] },
  [View.REDIRECT]: { path: '/', access: [] },
  [View.MANAGE_ANALYTICS]: { path: '/analytics', access: ['analytics'] },
  [View.MANAGE]: { path: '/manage', access: defaultAccess },
  [View.MANAGE_MEASUREMENT]: {
    path: '/analytics/measurement',
    access: ['analytics', 'flow_measurement'],
  },
  [View.USER_PAIR_DEVICE_INSIGHTS]: {
    path: '/analytics/pair/insights/:id',
    access: defaultAccess,
  },
  [View.USER_PAIR_DEVICE_DETAILS]: {
    path: '/analytics/pair/details/:id',
    access: defaultAccess,
  },
  [View.USER_MEASUREMENT_DEVICE_INSIGHTS]: {
    path: '/analytics/measurement/insights/:id',
    access: defaultAccess,
  },
  [View.USER_MEASUREMENT_DEVICE_DETAILS]: {
    path: '/analytics/measurement/details/:id',
    access: defaultAccess,
  },
  [View.MANAGE_ORGANISATIONS]: {
    path: '/manage/organisations',
    access: ['management', 'client', 'view'],
  },
  [View.MANAGE_ORGANISATIONS_DETAILS]: {
    path: '/manage/organisation/details/:id',
    access: ['management', 'client', 'view'],
  },
  [View.MANAGE_DEVICES]: {
    path: '/manage/devices',
    access: ['management', 'devices', 'view'],
  },
  [View.MANAGE_USERS]: { path: '/manage/users', access: ['management', 'user', 'view'] },
  [View.BILLING]: { path: '/billing', access: ['billing'] },
  [View.BILLING_PLANS]: { path: '/billing/plans', access: ['billing', 'plans', 'view'] },
  [View.BILLING_INVOICES]: { path: '/billing/invoices', access: ['billing', 'invoices', 'view'] },
  [View.BILLING_DETAILS]: { path: '/billing/details', access: ['billing', 'details', 'view'] },
  [View.PLACEHOLDER]: { path: '#', access: defaultAccess },
  [View.DOWNLOAD]: { path: '/download/:id', access: defaultAccess },
  [View.PROVISIONING_ADDITIONAL_DEVICE]: { path: '/provisioning/additional/device', access: [] },
  [View.PROVISIONING_DEVICE]: { path: '/provisioning/device', access: [] },
  [View.PROVISIONING_SUBSCRIPTION]: { path: '/provisioning/subscription', access: [] },
  [View.PROVISIONING_COMPLETED]: { path: '/provisioning/completed', access: [] },
  [View.PROVISIONING]: { path: '/provisioning', access: [] },
  [View.PROVISIONING_LOBBY]: { path: '/provisioning-lobby', access: [] },
  [View.ERROR]: { path: '/error', access: [] },
  [View.ERROR_403]: { path: '/error/403', access: [] },
  [View.ERROR_500]: { path: '/error/500', access: [] },
  [View.ERROR_404]: { path: '/error/404', access: [] },
  [View.ERROR_401]: { path: '/error/401', access: [] },
}) as CombinedRoutes;

export const getPath = (view: typeof View[keyof typeof View]) => (MAPPING[view] ? MAPPING[view].path : '');

export const injectPathParams = (route: string, params: any) => {
  let newRoute = route;
  if (params['*']) {
    delete params['*'];
  }
  Object.keys(params).forEach(function (key, index) {
    const regex = new RegExp(`:${key}`, 'g');
    newRoute = newRoute.replace(regex, params[key]);
  });
  return newRoute as any;
};

export const constructPath = ({
  view,
  params,
  queryParams,
}: {
  view: typeof View[keyof typeof View];
  params?: PathParams;
  queryParams?: QueryParams;
}): string => {
  const pathRaw = MAPPING[view] ? MAPPING[view].path : '';

  const pathWithParams = !isEmpty(params) ? injectPathParams(pathRaw, params) : pathRaw;

  const pathWithSearch =
    queryParams && !isEmpty(queryParams) ? stringifyUrl({ url: pathWithParams, query: queryParams }) : pathWithParams;

  return pathWithSearch;
};

export const constructPathFds = (view: typeof View[keyof typeof View]) => {
  return constructPath({ view });
};

export const getAccess = (view: typeof View[keyof typeof View]) =>
  MAPPING[view] ? MAPPING[view].access : defaultAccess;

export const PATHS = Object.keys(View)
  .map((key) => ({
    [key]: getPath((View as any)[key]),
  }))
  .reduce(
    (obj, item) => ({
      ...obj,
      [Object.keys(item)[0]]: item[Object.keys(item)[0]],
    }),
    {},
  ) as CombinedRoutes;

export const ACCESSES = Object.keys(View)
  .map((key) => ({
    [key]: getAccess((View as any)[key]),
  }))
  .reduce(
    (obj, item) => ({
      ...obj,
      [Object.keys(item)[0]]: item[Object.keys(item)[0]],
    }),
    {},
  ) as CombinedRoutes;

export const VIEW_PATH_PAIRS = Object.keys(MAPPING).map((key) => ({
  view: key as typeof View[keyof typeof View],
  path: getPath(key as typeof View[keyof typeof View]) as string,
}));
