import styled from '@cthings.co/styled-components';
import React, { FC } from 'react';
import { ReactComponent as MapImage } from '../assets/placeholder_map_insight_problems.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  overflow: hidden;
  & > svg {
    width: 100%;
    height: 250px;
    height: 250px;
    border-radius: ${colorFetch('primary')};
  }
`;

const LayerGradient = styled.div`
  width: 100%;
  height: 140px;
  background: linear-gradient(
    180deg,
    rgba(242, 242, 245, 0) 0%,
    ${colorFetch('gray5')} 62.5%,
    ${colorFetch('gray5')} 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
`;

export interface PlaceholderMapProps {}

export const PlaceholderGradientMap: FC<PlaceholderMapProps> = () => {
  return (
    <Wrapper>
      <MapImage preserveAspectRatio="xMidYMid slice" />
      <LayerGradient />
    </Wrapper>
  );
};
