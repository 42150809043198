import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { boldString } from '../../../../../../../../utils/boldString';

const StyledModal = styled(Modal)`
  width: 100%;
  z-index: var(--modal-first-layer);
  & > div {
    width: 100%;
  }
  ${media.semiTablet} {
    align-items: flex-end;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  align-items: flex-start !important;
  width: 100% !important;
  max-width: 566px !important;
  box-sizing: border-box;
  padding: 25px !important;
  ${media.semiTablet} {
    border-radius: 12px 12px 0 0 !important;
    height: 100% !important;
  }
`;

const Title = styled(Text)`
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: black !important;
  margin-bottom: 13px !important;
`;

const StyledText = styled(Text)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: ${colorFetch('gray1')} !important;
  margin-bottom: ${({ marginBottom }: any) => marginBottom ?? '16px'} !important;
` as any;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  ${media.semiTablet} {
    flex-direction: column;
  }
`;

const Button = styled(CommonButton)`
  max-width: 116px;

  &.first {
    max-width: max-content;
    padding: 0 15px;
  }

  ${media.semiTablet} {
    max-width: 100%;
    width: 100%;

    &.first {
      max-width: 100%;
      padding: 0 15px;
    }
  }
`;

interface SetOtherParametersModalProps {
  isOpen: boolean;
  handleClose: any;
  handleSetParameters: any;
}

export const SetOtherParametersModal: FC<SetOtherParametersModalProps> = ({
  isOpen = true,
  handleClose,
  handleSetParameters,
}) => {
  const theme = useTheme();
  const { primary } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const buttons = [
    {
      value: languageStrings.setParameters,
      onClick: handleSetParameters,
      backgroundColor: colorFetch('primary'),
      borderColor: colorFetch('primary'),
      color: colorFetch('pureWhite'),
    },
    {
      value: languageStrings.closeButtonValue,
      onClick: handleClose,
      backgroundColor: colorFetch('pureWhite'),
      borderColor: colorFetch('primary'),
      color: colorFetch('pureBlack'),
    },
  ];

  return (
    <StyledModal isOpenModal={isOpen}>
      <StyledModalContainer>
        <Title type={TextType.TEXT_14_GRAY}>{languageStrings.deploymentIsInProgressTitle}</Title>
        <StyledText type={TextType.TEXT_14_GRAY} marginBottom={'5px'}>
          {languageStrings.deploymentIsInProgressTextFirst}
        </StyledText>
        <StyledText type={TextType.TEXT_14_GRAY}>
          {boldString(languageStrings.deploymentIsInProgressTextSecond, primary)}
        </StyledText>
        <Buttons>
          {buttons.map((item: any, index: number) => {
            const { value, onClick, backgroundColor, borderColor, color } = item;
            return (
              <Button
                className={index === 0 ? 'first' : ''}
                onClick={onClick}
                colors={{
                  main: color,
                  background: backgroundColor,
                  border: borderColor,
                }}
              >
                {value}
              </Button>
            );
          })}
        </Buttons>
      </StyledModalContainer>
    </StyledModal>
  );
};
