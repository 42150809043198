import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { AddCompanyCard } from './components/Cards/AddCompanyCard';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { useMediaType } from '@cthings.co/styles-utils';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useDispatch } from 'react-redux';
import { CustomModal } from '../customModal/CustomModal';
import { endCompanyCreation, pushAction } from '../../../../app/state/organisations';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { AddCompanyCardType } from './types';
import { CloseButton } from '../closeButton/CloseButton';

const HeadingBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: ${colorFetch('black1')};
` as any; // @TODO fix type

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.semiTablet} {
    width: 100%;
    flex-direction: column;
  }
`;

const CancelButton = styled(CommonButton)`
  width: 115px;
  margin-top: 40px;
  background: ${colorFetch('white')};
  color: ${colorFetch('black1')};
  ${media.semiTablet} {
    width: 100%;
    margin-top: 10px;
  }
`;

export interface AddCompanyProps {
  isOpen: boolean;
}

export const AddCompany: FC<AddCompanyProps> = ({ isOpen }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(endCompanyCreation());
  };

  const handleFullOperational = () => {
    dispatch(pushAction('fullOperational'));
  };

  const handleCompanyOnly = () => {
    dispatch(pushAction('companyOnly'));
  };

  const arrCards = [
    {
      title: languageStrings.fullyOperationalAccount,
      info: languageStrings.fullyOperationalAccountCardInfo,
      type: AddCompanyCardType.FULLY_OPERATIONAL_CARD,
    },
    {
      title: languageStrings.companyOnly,
      info: languageStrings.companyOnlyCardInfo,
      type: AddCompanyCardType.COMPANY_CARD,
    },
  ];

  const onClicksHandler = (cardType: AddCompanyCardType) => {
    const onClick = {
      [AddCompanyCardType.FULLY_OPERATIONAL_CARD]: handleFullOperational,
      [AddCompanyCardType.COMPANY_CARD]: handleCompanyOnly,
    };

    return onClick[cardType];
  };

  return (
    <CustomModal isOpen={isOpen}>
      <HeadingBlock>
        <Title>{languageStrings.addCompany}</Title>
        <CloseButton />
      </HeadingBlock>
      {arrCards.map((el: any) => {
        return <AddCompanyCard onClick={onClicksHandler} title={el.title} info={el.info} type={el.type} />;
      })}
      <ButtonWrapper>
        <CancelButton onClick={closeModal}>{languageStrings.cancelButton}</CancelButton>
      </ButtonWrapper>
    </CustomModal>
  );
};
