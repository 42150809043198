import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectManagmentSearchValue } from '../../app/state/app';

export const useMobileManagementSearch = (setSearchValue: (value: string) => void) => {
  const managementSearchValue = useSelector(selectManagmentSearchValue);

  useEffect(() => {
    setSearchValue && managementSearchValue && setSearchValue(managementSearchValue);
  }, [managementSearchValue]);
};
