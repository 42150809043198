import { CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from '@cthings.co/router-dom';
import styled from '@cthings.co/styled-components';
import { assignDeviceToClient, useBillingPlan } from '../../../../api/provisioning';
import { selectLanguageStrings } from '../../../../app/state/user';
import { ReactComponent as InfoIcon } from '../../../../assets/SubscriptionInfo.svg';
import { ReactComponent as Tick } from '../../../../assets/Tick.svg';
import { SubscriptionCard } from '../../../../components/subscriptionCard';
import { BooleanSwitcher } from '../../../../components/tableInput/components/booleanSwitcher/BooleanSwitcher';
import { useNotifierFunctions } from '../../../../features/notifier2';
import { View } from '../../../../routes';
import { injectPathParams, PATHS } from '../../../../routes/paths';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useCustomHistory, useHistory } from '../../../../utils/react-router-dom-abstraction';
import { boldString } from '../../../../utils/boldString';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150vh;
  height: 100%;
  background-color: ${colorFetch('gray5')};
  justify-content: center;

  ${media.phone} {
    flex-direction: column;
    min-height: 100vh;
    margin-top: 42px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.desktop} {
    margin: 0 40px 44px 40px;
  }

  ${media.phone} {
    margin: 0;
  }
`;

const Title = styled(Text)`
  font-family: 'Bronova';
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: ${colorFetch('black')};
` as any; // @TODO fix type

const SubTitle = styled(Text)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
  margin-bottom: 40px !important;
` as any; // @TODO fix type

type ColumnProps = {
  additionalShadow11?: string;
};

const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.content {
    padding: 24px;
    background-color: ${colorFetch('white')};
    box-shadow: ${({ additionalShadow11 }) => additionalShadow11};
    border-radius: 12px;

    ${media.phone} {
      padding: 16px;
      margin: 0;
    }
  }
`;

const ContentTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black')};
  margin-bottom: 12px !important;
` as any; // @TODO fix type

const ContentSubTitle = styled(Text)`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  margin-bottom: 20px !important;

  &.info {
    line-height: 20px;
    text-align: start;
    margin: 0;
  }
` as any; // @TODO fix type

const StyledInfoIcon = styled(InfoIcon)`
  margin-right: 12px;
`;

const Row = styled.div`
  display: flex;

  &.info {
    align-items: center;
    padding: 12px;
    background-color: ${colorFetch('gray5')};
    border: 1px solid;
    border-color: ${colorFetch('gray6')};
    border-radius: 6px;
    margin-bottom: 40px;
  }
  &.cards {
    gap: 20px;
    margin-bottom: 40px;

    ${media.tablet} {
      flex-direction: column;
    }
  }
`;

const StyledBooleanSwitcher = styled(BooleanSwitcher)`
  align-items: center;
  margin: 0 0 20px 0;

  & > div > div {
    background-color: ${colorFetch('white')};
    transition: none;

    &.selected {
      background-color: ${colorFetch('white')};
      border: 1px solid;
      border-color: ${colorFetch('primary')};
    }

    > span {
      color: ${colorFetch('gray3')};

      &.selected {
        color: ${colorFetch('primary')};
      }
    }
  }
`;

const NextButton = styled(CommonButton)`
  width: 364px;
  height: 40px;
  & > svg,
  & > div {
    margin-right: 5px;
    & > path {
      transition: 0.3s ease;
    }
  }
  ${media.phone} {
    width: 100%;
  }
`;

const AdditionalSupportFeeWrapper = styled.div`
  display: flex;
  max-width: 985px;
  width: 100%;
  padding: 12px 14px;
  background-color: ${colorFetch('gray6')};
  margin: 20px 0 40px 0;
  gap: 20px;
  border-radius: 6px;

  ${media.tablet} {
    flex-direction: column;
    gap: 10px;
  }
`;

const PriceBlock = styled(Text)`
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 26px !important;
  line-height: 39px !important;
  color: ${colorFetch('gray1')} !important;
  white-space: pre !important;
`;

const ShortDescription = styled(Text)`
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: ${colorFetch('gray2')} !important;
  min-width: 185px !important;
`;

const Description = styled(Text)`
  font-family: 'Poppins';
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: ${colorFetch('gray1')} !important;
`;

const Block = styled.div`
  display: flex;
  align-items: center;

  &.withGap {
    gap: 15px;
  }
`;

interface ProvisioningSubscriptionProps {}

export const ProvisioningSubscription: FC<ProvisioningSubscriptionProps> = ({}) => {
  const theme = useTheme();
  const { secondary2, primary, pureWhite, secondary3, gray2 } = theme.colors;
  const { additionalShadow11 } = theme.shadows;
  const location = useLocation();
  const history = useHistory();
  const customhistory = useCustomHistory();
  const { addNotification } = useNotifierFunctions();
  const { localSecret, transportId, data, device, isUserHasAccount, depth } = location?.state?.state;
  const languageStrings = useSelector(selectLanguageStrings);
  const [billingPlan] = useBillingPlan(device, localSecret);

  const switcherValues = [
    { name: languageStrings.monthlyLabel, value: '1', disabled: !billingPlan?.monthly?.length },
    { name: languageStrings.yearlyLabel, value: '2', disabled: !billingPlan?.yearly?.length },
  ];

  const subtitle =
    billingPlan?.fake_type === 0
      ? languageStrings.smartRadar
      : billingPlan?.fake_type === 1
      ? languageStrings.smartRadarAlarm
      : billingPlan?.fake_type === 2
      ? languageStrings.smartHydrant
      : billingPlan?.fake_type === 3
      ? languageStrings.groundMeter
      : '';

  const [switcherValue, setSwitcherValue] = useState(switcherValues[0].value);
  const [selectedId, setSelectedId] = useState();

  const isMonthly = switcherValue === switcherValues[0].value;
  const isYearly = switcherValue === switcherValues[1].value;

  const handleSwitch = (e: any) => {
    setSwitcherValue(e);
    setSelectedId(undefined);
  };

  const [isDeployedInProgress, setIsDeployedInProgress] = useState(false);

  const redirect = (type: string, id: string) => {
    if (isUserHasAccount) {
      const to = injectPathParams(PATHS.USER_INSIGHTS_DETAILS, {
        id,
        type,
      });
      history.push(to);
    } else {
      const to = View.PROVISIONING_COMPLETED;
      to && customhistory.push(to, { subtitle });
    }
  };

  const handleButtonClick = () => {
    setIsDeployedInProgress(true);
    if (selectedId) {
      const collectedData = { ...data, plan_id: selectedId, depth };

      assignDeviceToClient(
        localSecret,
        transportId,
        addNotification,
        collectedData,
        languageStrings,
        redirect,
        setIsDeployedInProgress,
      );
    }
  };

  return (
    <Wrapper>
      <Content>
        <Column>
          <Title>SMART UTILITIES</Title>
          <SubTitle>{subtitle}</SubTitle>
        </Column>
        <Column additionalShadow11={additionalShadow11} className="content">
          <ContentTitle>{languageStrings.pickSubscriptionPlan}</ContentTitle>
          <ContentSubTitle>
            {languageStrings.pickSubscriptionPlanSub1}
            <br /> {languageStrings.pickSubscriptionPlanSub2}
          </ContentSubTitle>
          <Row className="info">
            <StyledInfoIcon />
            <ContentSubTitle className="info">
              {languageStrings.subscriptionContent1}
              <br /> {languageStrings.subscriptionContent2}
            </ContentSubTitle>
          </Row>
          <StyledBooleanSwitcher
            value={switcherValue}
            values={switcherValues}
            options={{ width: '312px', height: '40px' }}
            onChange={handleSwitch}
            isEditInProcess
          />
          <Row className="cards">
            {billingPlan && isMonthly
              ? billingPlan.monthly.map((item: any) => {
                  return <SubscriptionCard data={item} selectedId={selectedId} onChange={setSelectedId} isPerMonth />;
                })
              : billingPlan &&
                isYearly &&
                !!billingPlan?.yearly?.length &&
                billingPlan.yearly.map((item: any) => {
                  return <SubscriptionCard data={item} selectedId={selectedId} onChange={setSelectedId} />;
                })}
          </Row>
          {billingPlan?.billing_type === 'direct' && billingPlan.fake_type !== 1 && (
            <AdditionalSupportFeeWrapper>
              <Block className="withGap">
                <PriceBlock type={TextType.TEXT_14_GRAY}>+22 €</PriceBlock>
                <ShortDescription type={TextType.TEXT_14_GRAY}>
                  {boldString(languageStrings.shortAdditionalDescription, gray2)}
                </ShortDescription>
              </Block>
              <Block>
                <Description type={TextType.TEXT_14_GRAY}>{languageStrings.fullAdditionalDescription}</Description>
              </Block>
            </AdditionalSupportFeeWrapper>
          )}
          <NextButton
            onClick={handleButtonClick}
            colors={{
              main: pureWhite,
              mainHover: pureWhite,
              background: !selectedId ? secondary3 : primary,
              backgroundHover: !selectedId ? secondary3 : secondary2,
              border: !selectedId ? secondary3 : primary,
              borderHover: !selectedId ? secondary3 : primary,
            }}
            isLoadingInProcess={isDeployedInProgress}
            disabled={!selectedId}
          >
            <Tick />
            {languageStrings.provisioningFinish}
          </NextButton>
        </Column>
      </Content>
    </Wrapper>
  );
};
