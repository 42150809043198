import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { Select } from '../../../universalSelect/Select';
import { InputWrapper } from '../inputWrapper/InputWrapper';
import { useTheme } from '@cthings.co/styled-components';

type StyledDropdownProps = {
  title?: string;
};

const StyledDropdown = styled(Select)<StyledDropdownProps>`
  width: 100%;
  & > div:first-child {
    height: ${({ title }) => (title ? '40px' : '32px')};
  }
  & > div:nth-child(2) {
    top: ${({ title }) => (title ? '46px' : '38px')};
  }
`;

const StyledText = styled(Text)`
  word-break: break-all;
`;

type ValueType = {
  id: string;
  name: string;
};

export interface DropdownOptionsType {
  title?: string;
  isDisabled?: boolean;
  isError?: boolean;
  placeholder?: string;
  validatingIsStarted?: boolean;
}

export const Dropdown = ({
  value,
  valuesList,
  handleChangeValue,
  options,
  isEditInProcess,
  languageStrings,
  ...props
}: {
  value: ValueType;
  valuesList: any[];
  handleChangeValue: (value: { id: string; name: string }) => void;
  options: DropdownOptionsType;
  isEditInProcess?: boolean;
  languageStrings?: any;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const { title, isDisabled, isError, placeholder, validatingIsStarted } = options;

  return (
    <InputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      {isEditInProcess ? (
        <StyledDropdown
          value={value}
          values={valuesList}
          disabled={isDisabled}
          onChange={handleChangeValue}
          placeholder={placeholder}
          startedValidation={validatingIsStarted}
          isError={isError}
          languageStrings={languageStrings}
          title={title}
        />
      ) : (
        <StyledText type={TextType.TEXT_14_GRAY} color={gray1}>
          {value.name ? value.name : 'N/A'}
        </StyledText>
      )}
    </InputWrapper>
  );
};
