import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../app/state/user';
import { API_URL, NEW_ID } from '../../consts';
import { ApiCallType, HttpService } from '../../utils/http';
import { ErrorEventType } from '../../types/error';
import { generateApi } from '../../features/apiBuilder/useApiBuilder';

export const updateDevices = (
  id: string,
  data: any,
  handleNotification: (isSuccess: boolean, message?: string) => void,
  languageStrings?: any,
  setIsError?: (prop: boolean) => void,
  index?: number,
  updateLocalItem?: any,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v2/devices/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem && updateLocalItem(index, responseData.payload);
        handleNotification(true);
      } catch (e) {
        const error = e as ErrorEventType;
        const errorMessage = error?.response?.data?.detail;
        handleNotification(false, errorMessage);
        setIsError && setIsError(true);
      }
    })();
  }
};

export const updateDeviceDetails = ({
  deviceId,
  body,
  handleSuccess,
  handleError,
}: {
  deviceId: string;
  body: any;
  handleSuccess: (data: any) => void;
  handleError: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/devices/${deviceId}/insight`;

  return generateApi({
    url,
    callType: ApiCallType.PATCH,
    transformResponse: (data) => data.payload,
  })({
    handleSuccess,
    handleError,
    body,
  });
};

export const updateDeviceConfig = (id: string, data: any) => {
  const url = `${API_URL}/v2/devices/${id}/config/multi`;
  (async () => {
    try {
      await HttpService.post(url, data);
    } catch (e) {}
  })();
};

export const useCurrentNetwork = (id: string) => {
  const [data, setData] = useState();

  const url = `${API_URL}/v2/devices/${id}/insight/network`;
  useEffect(() => {
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        setData(responseData);
      } catch (e) {
        //
      }
    })();
  }, [id]);

  return data;
};

export const subscribeToDevice = (
  devicsId: string,
  languageStrings: any,
  addNotification: Function,
  setUserDeviceIds: Function,
  setSubscribeError: (value: boolean) => void,
) => {
  const url = `${API_URL}/v2/users/subscribe`;
  const data = {
    device_ids: [devicsId],
  };

  (async () => {
    try {
      let subscribedDevices = (await HttpService.post(url, data)).data.payload.subscribed_devices;
      setUserDeviceIds(subscribedDevices);
      addNotification({
        message: languageStrings.emailNotificationsOn,
        type: 'success',
      });
    } catch (e) {
      setSubscribeError(true);
      addNotification({
        message: languageStrings.emailNotificationsError,
        type: 'error',
      });
    }
  })();
};

export const unsubscribeToDevice = (
  devicsId: string,
  languageStrings: any,
  addNotification: Function,
  setUserDeviceIds: Function,
  setSubscribeError: (value: boolean) => void,
) => {
  const url = `${API_URL}/v2/users/unsubscribe`;
  const data = {
    device_ids: [devicsId],
  };

  (async () => {
    try {
      let subscribedDevices = (await HttpService.post(url, data)).data.payload.subscribed_devices;
      setUserDeviceIds(subscribedDevices);
      addNotification({
        message: languageStrings.emailNotificationsOff,
        type: 'info',
      });
    } catch (e) {
      setSubscribeError(true);
      addNotification({
        message: languageStrings.emailNotificationsError,
        type: 'error',
      });
    }
  })();
};

export const useDeviceQualityTests = (deviceId: string) => {
  const [data, setData] = useState();
  const language = useSelector(selectLanguage);

  const url = `${API_URL}/v2/devices/${deviceId}/qa`;
  useEffect(() => {
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setData(responseData);
      } catch (e) {
        //
      }
    })();
  }, [language]);

  return data;
};

export const updateDeviceConfigurationTable = ({
  id,
  field_id,
  body,
  handleError,
  handleSuccess,
}: {
  id: string;
  field_id: string;
  body: any;
  handleError?: (data: any) => void;
  handleSuccess?: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/devices/${id}/config_params/${field_id}`;

  return generateApi({ url, callType: ApiCallType.PATCH })({ handleError, handleSuccess, body });
};
