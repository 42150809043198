import { CloseButton } from '@bit/first-scope.close-button';
import { CommonButton } from '@cthings.co/buttons';
import { EditButton } from '@bit/first-scope.edit-button';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as AcceptImage } from '../../../../../../../../../../../assets/Accept.svg';
import { InfoButton } from '../../../../../../../../../../../components/infoButton/InfoButton';
import { Select } from '../../../../../../../../../../../components/universalSelect/Select';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
  gridTemplatePhoneXS?: string;
  editInProcess?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: grid;
  height: max-content;
  grid-template-columns: ${({ gridTemplateDesktop }) => gridTemplateDesktop || '1.25fr 1.5fr'};
  grid-gap: 10px;
  padding: ${({ editInProcess }) => (editInProcess ? '6px 22px' : '10px 22px')};
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  color: ${colorFetch('gray1')};
  border-top: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray4')};
  border-radius: ${({ isLast }) => (isLast ? '0 0 8px 8px' : 'none')};
  filter: ${({ editInProcess }) => (editInProcess ? 'drop-shadow(0px 2px 4px rgba(96,97,112,0.3))' : 'none')};
  transition: filter 0.3s linear;

  ${media.tablet} {
    grid-template-columns: ${({ gridTemplateTablet }: any) => gridTemplateTablet || '1.25fr 1.5fr'};
  }

  ${media.phone} {
    height: max-content;
    padding: 12px 16px;
    grid-template-columns: ${({ gridTemplatePhone }: any) => gridTemplatePhone || '1.25fr 1.5fr'};
  }

  ${media.phoneXS} {
    grid-template-columns: ${({ gridTemplatePhoneXS }: any) => gridTemplatePhoneXS || '1.25fr 1.5fr'};
  }
`;

const PropertyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Property = styled(Text)`
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const CloseWrapper = styled.div`
  width: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: end;
`;

const EditButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${media.phone} {
    display: none;
  }
`;

const Value = styled(Text)`
  ${media.phone} {
    word-break: break-word;
  }
`;

const QualityControlButton = styled(CommonButton)`
  width: max-content;
  height: 26px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  justify-self: end;
  justify-self: end;
  ${media.phone} {
    display: none;
  }
`;

export const TableRow = ({
  property,
  value,
  handleSaveOnClick,
  handleCloseOnClick,
  handleChangeValue,
  phisicalValue,
  languageStrings,
  values,
  selectPlaceholder,
  selectFieldName,
  isFirst,
  isLast,
  gridTemplateDesktop,
  gridTemplateTablet,
  gridTemplatePhone,
  gridTemplatePhoneXS,
  isEditable,
  isEdit,
  setIsEdit,
  accessData,
  additionalActionLable,
  additionalActionFunction,
  tooltip,
  ...props
}: {
  property: string;
  value: any;
  handleSaveOnClick: () => void;
  handleCloseOnClick: () => void;
  handleChangeValue?: (name: string, id: string) => void;
  phisicalValue: any;
  languageStrings: any;
  values?: any[];
  selectPlaceholder?: string;
  selectFieldName?: { name: string; id: string };
  isFirst?: boolean;
  isLast?: boolean;
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
  gridTemplatePhoneXS?: string;
  isEditable?: boolean;
  isEdit?: boolean;
  setIsEdit?: (value: boolean) => void;
  className?: string;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  additionalActionLable?: string;
  additionalActionFunction?: () => void;
  tooltip: string;
}) => {
  const theme = useTheme();
  const { secondary2, red1, gray1, gray2, gray3, pureWhite } = theme.colors;

  return (
    <Wrapper
      isFirst={isFirst}
      isLast={isLast}
      gridTemplateDesktop={gridTemplateDesktop}
      gridTemplateTablet={gridTemplateTablet}
      gridTemplatePhone={gridTemplatePhone}
      gridTemplatePhoneXS={gridTemplatePhoneXS}
      editInProcess={isEdit && property === 'Customer'}
      {...props}
    >
      <PropertyWrapper>
        <Property type={TextType.TEXT_14_BLACK} weight={'400'} color={gray1}>
          {property}
        </Property>
        {tooltip && <InfoButton onClick={() => {}} tooltipText={tooltip || ''} />}
      </PropertyWrapper>
      {isEditable ? (
        isEdit ? (
          <Select
            values={values ? values : []}
            placeholder={selectPlaceholder ? selectPlaceholder : ''}
            value={value}
            onChange={(value) => {
              handleChangeValue && handleChangeValue(value.name, value.id);
            }}
            positionTop={'36px'}
            height={'32px'}
            languageStrings={languageStrings}
          />
        ) : (
          <Value type={TextType.TEXT_14_GRAY} weight={'500'} color={gray1}>
            {(value && value.name) || 'N/A'}
          </Value>
        )
      ) : (
        <Value
          type={TextType.TEXT_14_GRAY}
          weight={'500'}
          color={
            property === languageStrings.batteryLevel
              ? typeof phisicalValue === 'boolean'
                ? phisicalValue
                  ? secondary2
                  : red1
                : gray1
              : gray1
          }
        >
          {value || 'N/A'}
        </Value>
      )}
      {accessData?.edit && setIsEdit ? (
        <>
          {isEdit && property !== 'Location' ? (
            <CloseWrapper>
              <CommonButton
                width="32px"
                onClick={() => {
                  handleSaveOnClick();
                  setIsEdit && setIsEdit(false);
                }}
                iconWithoutText
              >
                <AcceptImage />
              </CommonButton>
              <CloseButton
                onClick={() => {
                  handleCloseOnClick();
                  setIsEdit && setIsEdit(false);
                }}
              />
            </CloseWrapper>
          ) : (
            <EditButtonWrapper>
              <EditButton
                tooltipText={`Edit ${value}`}
                onClick={() => {
                  setIsEdit && setIsEdit(true);
                }}
              />
            </EditButtonWrapper>
          )}
        </>
      ) : additionalActionLable && additionalActionFunction ? (
        <QualityControlButton
          colors={{
            main: gray2,
            mainHover: pureWhite,
            background: pureWhite,
            backgroundHover: gray2,
            border: gray3,
            borderHover: gray2,
          }}
          onClick={additionalActionFunction}
        >
          {additionalActionLable}
        </QualityControlButton>
      ) : null}
    </Wrapper>
  );
};
