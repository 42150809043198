import React, { FC, useCallback, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';
import moment from 'moment';
import { CartesianGrid, Cell, Label, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import { CustomYAxisTick } from '../customYAxisTick/CustomYAxisTick';
import { CustomTooltip } from '../customTooltip/CustomTooltip';
import { getRequiredDateFormat } from '@cthings.co/utils';

export enum GraphType {
  WATER_LEVEL = 'WATER_LEVEL',
  TEMPERATURE = 'TEMPERATURE',
  EVENTS = 'EVENTS',
}

export enum DotColorType {
  PICK_UP = 'GREEN',
  CALIBRATION = 'ORANGE',
  GPS_LOCATION = 'BLUE',
  SUBMERGE_IN_WATER = 'PURPLE',
  STANDING_WATER = 'TURQUOISE',
}

const GraphWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export interface EventsGraphProps {
  data: any;
  type: GraphType;
  labels?: { xAxis: string; yAxis: string };
}

const setTickStyle = (fill: string) => ({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.2px',
  fill: fill,
});

const CustomLabel = ({
  value,
  theme,
  x,
  y,
  offset,
}: {
  value: any;
  theme: any;
  x: number;
  y: number;
  offset: number;
}) => {
  const { children } = value;
  return (
    <text
      x={x}
      y={y}
      offset={offset}
      textAnchor={'start'}
      fontFamily={'Poppins, sans-serif'}
      fontSize={'12px'}
      letterSpacing={'0.2'}
      fill={theme.colors.gray2}
    >
      <tspan x={x}>{children}</tspan>
    </text>
  );
};

export const DotsGraph: FC<EventsGraphProps> = ({ data, labels, type, ...props }) => {
  const theme = useTheme();
  const { gray2, primary, blue2, orange2, chartPink3, chartCyan4 } = theme.colors;

  const [node, setNode] = useState<any>(null);
  const graphRef = useCallback((node: any) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const chartWidth = node?.getBoundingClientRect().width - 60 > 0 ? node?.getBoundingClientRect().width - 60 : 0;
  const lineWidth = node?.getBoundingClientRect().width - 40;

  const setYAxisTickText = (value: any) => {
    const { index } = value;
    const data: any = { 4: '00:00', 3: '18:00', 2: '12:00', 1: '06:00', 0: '00:00' };
    return data[index] ? data[index] : '';
  };

  const dateFormatter = (item: any) => {
    return moment(item).format('D.MM');
  };

  const getDotColor = (type: DotColorType) => {
    const color: any = {
      [DotColorType.PICK_UP]: primary,
      [DotColorType.GPS_LOCATION]: blue2,
      [DotColorType.CALIBRATION]: orange2,
      [DotColorType.STANDING_WATER]: chartPink3,
      [DotColorType.SUBMERGE_IN_WATER]: chartCyan4,
    };
    return color[type];
  };

  const scatterData = data.data.map((item: any) => {
    return {
      x: new Date(item.ts).getTime(),
      y: +getRequiredDateFormat(item.ts, 'HH') * 60 + +getRequiredDateFormat(item.ts, 'mm'),
      tooltipTitle: item.tile_name,
      tooltipDate: getRequiredDateFormat(item.ts, 'DD.MM.yyyy, HH:mm'),
      tooltipValue: item.tooltip,
    };
  });

  return (
    <GraphWrapper ref={graphRef}>
      <ScatterChart
        width={chartWidth}
        height={450}
        margin={{
          top: 60,
          bottom: 20,
          left: 20,
          right: 20,
        }}
      >
        <CartesianGrid vertical={false} horizontal={false} />
        <XAxis
          dataKey={'x'}
          axisLine={false}
          tickLine={false}
          tick={setTickStyle(gray2)}
          name={'date'}
          type="number"
          domain={[new Date(data.axis_limits.x.min).getTime(), new Date(data.axis_limits.x.max).getTime()]}
          tickFormatter={(time: any) => dateFormatter(time)}
        >
          <Label
            position="right"
            offset={30}
            content={(value) => CustomLabel({ value, theme, x: 65, y: 436, offset: 0 })}
          >
            {labels?.xAxis}
          </Label>
        </XAxis>
        <YAxis
          dataKey={'y'}
          axisLine={false}
          tickLine={false}
          name={'time'}
          type="number"
          ticks={[0, 360, 720, 1080, 1440]}
          domain={[0, 1440]}
          interval={0}
          minTickGap={0}
          tickCount={24}
          tick={(value) => {
            return <CustomYAxisTick value={value} lineWidth={lineWidth} setTickText={setYAxisTickText} />;
          }}
        >
          <Label
            position="top"
            offset={30}
            content={(value) => CustomLabel({ value, theme, x: 20, y: 28, offset: 30 })}
          >
            {labels?.yAxis}
          </Label>
        </YAxis>
        <ZAxis range={[100, 100]} />
        <Tooltip cursor={false} content={(data) => <CustomTooltip data={data} />} wrapperStyle={{ outline: 'none' }} />
        <Scatter data={scatterData}>
          {data.data.map((entry: any, index: number) => {
            return <Cell key={`cell-${index}`} fill={getDotColor(entry.dot_color)} stroke={'100'} />;
          })}
        </Scatter>
      </ScatterChart>
    </GraphWrapper>
  );
};
