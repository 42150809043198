import { CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { CustomModal } from '../../../../../../components/customModal/CustomModal';
import { Input } from '../../../../../../components/input/Input';
import { SelectAddress } from '../../../../../../components/selectAddress/SelectAddress';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { TableCustomComponentState } from '../../../../../../features/universalTable/types';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { checkFieldValidity, FieldsType } from '../../../../../../utils/validation';

const StyledWrapper = styled(CustomModal)`
  z-index: 10000;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  ${media.phone} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const StyledSelectAddress = styled(SelectAddress)`
  width: 248px;

  ${media.phone} {
    width: 100%;
  }
` as any; // @TODO fix type

const StyledButton = styled(CommonButton)`
  width: 116px;

  ${media.phone} {
    width: 100%;
    height: 40px;
    margin: 0 0 10px 0;
  }

  &.CancelButton {
    margin: 0 16px 0 0;
    color: ${colorFetch('black')};
    background-color: ${colorFetch('white')};

    &:hover {
      color: ${colorFetch('white')};
      background-color: ${colorFetch('secondary2')};
      border-color: ${colorFetch('secondary2')};
    }
    ${media.phone} {
      margin: 0;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  ${media.phone} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledInput = styled(Input)`
  margin: 0;
  & > div {
    width: 248px;
  }
  & > div > input {
    border-color: ${colorFetch('gray3')};
  }

  ${media.phone} {
    & > div {
      width: 100%;
    }
  }
`;

export const AddOrganisationModal = ({
  isOpen,
  onCancelClick,
  setValues,
  values,
  isData,
  ...props
}: {
  isOpen: boolean;
  onCancelClick?: () => void;
  setValues: (props: any) => void;
  values: any;
  isData: boolean;
}) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();
  const { addNotification } = useNotifierFunctions();
  const { black1 } = theme.colors;
  const [startedValidation, setStartedValidation] = useState(false);
  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState(false);
  const [data, setData] = useState<any>(
    values || {
      address: {
        line1: '',
        line2: '',
        city: '',
        region: '',
        country: '',
        zip: '',
        geotag: { lat: '0', lng: '0' },
      },
    },
  );

  const handleChange = (value: any, fieldName: string) => {
    setData((val: any) => ({
      ...val,
      [fieldName]: value,
    }));
  };

  const inputStructure = [
    {
      label: languageStrings.chooseOrganisationNamePlaceholder,
      placeholder: languageStrings.chooseOrganisationNamePlaceholder,
      fieldName: 'name',
      validationRule: FieldsType.TEXT,
    },
    {
      label: languageStrings.chooseOrganisationEmail,
      placeholder: languageStrings.userEmailPlaceholder,
      fieldName: 'email',
      validationRule: FieldsType.EMAIL,
    },
    {
      label: languageStrings.chooseOrganisationPhone,
      placeholder: languageStrings.chooseOrganisationPhonePlaceholder,
      fieldName: 'phone',
      validationRule: FieldsType.PHONE_NUMBER,
    },
  ];

  const isFormValid = inputStructure.every(({ fieldName, validationRule }) =>
    checkFieldValidity({ value: data[fieldName], validationRule }),
  );

  const handleNextButtonClick = () => {
    setStartedValidation(true);
    if (isFormValid && data.address.city) {
      setValues(data);
    } else {
      addNotification({
        message: 'Please, correctly fill in all the fields',
        type: 'error',
      });
    }
  };

  const handleSetAddressValue = (value: any) => {
    const alt = value?.geotag?.alt ? `${value?.geotag?.alt}` : '';
    const lat = value?.geotag?.lat ? `${value?.geotag?.lat}` : '';
    const lng = value?.geotag?.lng ? `${value?.geotag?.lng}` : '';

    setData((prev: any) => ({
      ...prev,
      address: {
        ...value,
        geotag: { ...value?.geotag, lat, lng, alt },
      },
    }));
  };

  return (
    <StyledWrapper isOpen={isOpen}>
      <Text type={TextType.TEXT_14_BLACK} color={black1} weight={'600'} fontSize={'18px'} margin={'0 0 16px 0'}>
        {languageStrings.addOrganisationLabel}
      </Text>
      <Container>
        {inputStructure.map((item: any) => {
          const { label, fieldName, placeholder, validationRule } = item;
          const validationOptions = {
            isError: !checkFieldValidity({ value: data[fieldName], validationRule }),
            startedValidation,
          };

          return (
            <StyledInput
              value={data[fieldName]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e.target.value, fieldName);
              }}
              placeholder={placeholder}
              name={label}
              {...validationOptions}
            />
          );
        })}
        <StyledSelectAddress
          state={TableCustomComponentState.EDIT}
          address={data.address}
          titleOfPopup={languageStrings.provisioningAddress}
          setValue={handleSetAddressValue}
          saveToggle={false}
          setExternalState={(isOpen: boolean) => {
            setIsOpenAddressPopup(isOpen);
          }}
          externalState={isOpenAddressPopup}
          label={languageStrings.organisationAddressLabel}
          isDescriptionModal={true}
          isInstructions={true}
          isFromUniT={true}
          isShowSelect={true}
          isWhiteBackground={true}
          className={'position'}
        />
      </Container>
      <ButtonRow>
        <StyledButton className="CancelButton" onClick={onCancelClick}>
          {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
        </StyledButton>
        <StyledButton onClick={handleNextButtonClick}>
          {isData
            ? languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsSave
            : languageStrings.manageAddButton}
        </StyledButton>
      </ButtonRow>
    </StyledWrapper>
  );
};
