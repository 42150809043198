import { ReactComponent as LocationIcon } from '../../assets/location.svg';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
`;

type InputButtonProps = {
  isDisabled?: boolean;
  isError: boolean;
  isPlaceholder: boolean;
};

export const InputButton = styled.div<InputButtonProps>`
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  box-sizing: border-box;
  background-color: ${({ isDisabled, theme }) =>
    isDisabled ? colorFetch('gray5')({ theme }) : colorFetch('pureWhite')({ theme })};

  color: ${({ isPlaceholder, isDisabled, theme }) =>
    isPlaceholder
      ? isDisabled
        ? colorFetch('gray5')({ theme })
        : colorFetch('gray2')({ theme })
      : colorFetch('black1')({ theme })};

  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid
    ${({ isDisabled, isError, theme }) =>
      isDisabled
        ? colorFetch('gray5')({ theme })
        : isError
        ? colorFetch('red2')({ theme })
        : colorFetch('gray3')({ theme })};

  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 8px 36px 8px 12px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

type StyledLocationIconProps = {
  gray2: string;
};

export const StyledLocationIcon = styled(LocationIcon)<StyledLocationIconProps>`
  width: 25px;
  height: 25px;
  cursor: pointer;
  & > path {
    stroke: ${({ gray2 }) => gray2};
  }
`;
