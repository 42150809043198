/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings search select require
 * Highly customisable.
 *
 */

import React from 'react';
import styled from '@cthings.co/styled-components';
import { CloseButton } from '@bit/first-scope.close-button';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 14px 14px 8px;
  border-bottom: 1px solid !important;
  border-color: ${colorFetch('gray3')} !important;
  border-radius: ${({ theme }) => `${theme.borderRadius.primary} ${theme.borderRadius.primary} 0 0`};
  background-color: ${colorFetch('white')};
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;
const Input = styled.input`
  width: calc(100% - 36px);
  margin-left: 2px;
  border: none;
  padding: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  font-weight: 500;
  color: ${colorFetch('gray1')};
  &::placeholder {
    color: ${colorFetch('gray2')};
  }
`;

export const SearchInputSelect = ({
  value,
  handleOnChange,
  handleClearInput,
  setIsEditing,
  isFocused,
  id,
  banAutoFocus,
}: {
  value: string;
  id?: any;
  handleOnChange: (value: string) => void;
  handleClearInput: () => void;
  setIsEditing?: (e: any) => void;
  isFocused?: boolean;
  banAutoFocus?: boolean;
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper
      onClick={(e: any) => {
        e.stopPropagation();
        setIsEditing && setIsEditing(null);
      }}
      theme={theme}
    >
      <SearchIcon />
      <Input
        id={id}
        value={value}
        // @TODO fix onChange type
        onChange={handleOnChange as any}
        onKeyDown={(e: any) => e.keyCode === 13 && e.preventDefault()}
        placeholder={languageStrings.searchBarPlaceholder}
        autoFocus={banAutoFocus ? false : isFocused}
      />
      {value && <CloseButton onClick={handleClearInput} width={'16px'} height={'16px'} strokeWidth={'2px'} />}
    </Wrapper>
  );
};
