import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  & > :nth-child(1) {
    margin-top: 5px;
  }
  & > :nth-child(2) {
    margin-top: 20px;
  }
`;

const WrapperFirstBlock = styled.div`
  width: 100%;
  margin-top: 5px;
  & > :nth-child(3),
  & > :nth-child(4) {
    margin-top: 12px;
  }
`;

const WrapperTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-top: 5px;
  color: ${colorFetch('gray1')};
`;

const WrapperText = styled(Text)`
  display: flex;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${colorFetch('gray1')};
`;

export const AddressPopupDescription = ({ children, languageStrings }: { children?: any; languageStrings?: any }) => {
  return (
    <Wrapper>
      <WrapperFirstBlock>
        <WrapperTitle>{languageStrings.addresInstructionTitle1}</WrapperTitle>
        <WrapperText type={TextType.TEXT_12_GRAY}>{languageStrings.addresInstructionDesc1}</WrapperText>
        <WrapperText type={TextType.TEXT_12_GRAY}>{languageStrings.addresInstructionDesc2}</WrapperText>
        <WrapperText type={TextType.TEXT_12_GRAY}>{languageStrings.addresInstructionDesc3}</WrapperText>
      </WrapperFirstBlock>
      <WrapperFirstBlock>
        <WrapperTitle>{languageStrings.addresInstructionTitle2}</WrapperTitle>
        <WrapperText type={TextType.TEXT_12_GRAY}>{languageStrings.addresInstructionDesc4}</WrapperText>
        <WrapperText type={TextType.TEXT_12_GRAY}>{languageStrings.addresInstructionDesc5}</WrapperText>
        <WrapperText type={TextType.TEXT_12_GRAY}>{languageStrings.addresInstructionDesc6}</WrapperText>
      </WrapperFirstBlock>
      {children && children}
    </Wrapper>
  );
};
