import { Text, TextType } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import React, { FC, useCallback } from 'react';
import { media } from '@cthings.co/styles-utils';
import { CommonButton } from '../../../commonButton/CommonButton';
import Tooltip, { TooltipPlace, useTooltip } from '../../../tooltip/Tooltip';
import { DistanceToWaterImage } from '../DistanceToWaterImage';
import { FlowInfo } from '../flowInfo/FlowInfo';
import { LocationImage } from '../LocationImage';
import { TemperatureImage } from '../TemperatureImage';
import { WaterLevelImage } from '../WaterLevelImage';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  borderRadius: string;
  backgroundColor: string;
  isOpen: boolean;
  isClicked: boolean;
  boxShadow: string;
};

const Wrapper = styled.div<WrapperProps>`
  min-width: 240px;
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  visibility: ${({ isOpen, isClicked }) => (isOpen || isClicked ? 'visible' : 'hidden')};
  opacity: ${({ isOpen, isClicked }) => (isOpen || isClicked ? '1' : '0')};
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  transition: all 0.3s ease;
  position: absolute;
  top: 60px;
  left: -22px;
  box-shadow: ${({ boxShadow }) => boxShadow};
  &:before {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -8px;
    left: 40px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
    border-radius: 0 0 0 0.25em;
    ${media.phone} {
      left: 108px;
    }
  }
`;
const NameWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 8px 20px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray4')};

  & > span {
    font-weight: 600;
  }
`;

const SemiWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
`;

type TextWrapperProps = {
  margin?: string;
  color?: string;
};

const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};

  & > svg {
    margin-right: 2px;
    & > path {
      stroke: ${({ color }) => color};
    }
  }
`;

const DeviceWrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray4')};

  &:first-child {
    padding: 0 0 10px 0;
  }
`;

const SingleDeviceWrapper = styled.div``;

const SingleDeviceExtraWrapper = styled.div`
  padding: 8px 20px 15px 20px;
`;

const DeviceName = styled(Text)`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
`;

const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin-bottom: 10px; */

  & > span {
    margin: 0 0 0 22px;
  }
`;

export const DeviceDataWrapper = styled.div`
  display: flex;
  margin: 6px 0;
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 350px !important;
  width: max-content !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px;
  padding: 8px;
`;

type DeviceDataProps = {
  margin: string;
  color: string;
  textColor: string;
};

export const DeviceData = styled.div<DeviceDataProps>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};

  & > svg {
    margin-right: 3px;
    & > path {
      stroke: ${({ color }) => color};
    }
  }
  & > span {
    color: ${({ textColor }) => textColor};
  }
`;

const DownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export interface WaterFlowInfoModalProps {
  isClicked: boolean;
  isOpen: boolean;
  waterFlowData: any[];
  flowInfoData: any[];
  history: any;
  className?: string;
  languageStrings?: any;
}

export const WaterFlowInfoModal: FC<WaterFlowInfoModalProps> = ({
  isClicked,
  isOpen,
  waterFlowData,
  flowInfoData,
  history,
  languageStrings,
  ...props
}) => {
  const theme = useTheme();
  const { black1, gray1, gray2, pureWhite } = theme.colors;
  const { primary } = theme.borderRadius;
  const { additionalShadow7 } = theme.shadows;

  const date = waterFlowData[0].date;
  const time = waterFlowData[0].time;

  const isSingleDevice = waterFlowData?.length <= 1;
  const [tooltipId] = useTooltip(3);

  const { waterLevel, distanceToWater, temperature, to, name, address, coordinates } = waterFlowData[0];

  const singleDeviceData = [
    {
      icon: <WaterLevelImage />,
      value: waterLevel?.measurement || 'N/A',
      tooltipText: waterLevel?.tooltipText,
      margin: '0 6px 0 0',
    },
    {
      icon: <DistanceToWaterImage />,
      value: distanceToWater?.measurement || 'N/A',
      tooltipText: distanceToWater?.tooltipText,
      margin: '0 6px 0 0',
    },
    {
      icon: <TemperatureImage />,
      value: temperature?.measurement || 'N/A',
      tooltipText: temperature?.tooltipText,
    },
  ];

  const handleClick = useCallback(() => {
    to && history && history.push(to);
  }, [history, to]);

  return (
    <Wrapper
      isOpen={isOpen}
      isClicked={isClicked}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      backgroundColor={pureWhite}
      borderRadius={primary}
      boxShadow={additionalShadow7}
      {...props}
    >
      {!isSingleDevice ? (
        <>
          <NameWrapper>
            <Text type={TextType.TEXT_14_BLACK} color={black1}>
              {name || 'N/A'}
            </Text>
          </NameWrapper>
          <SemiWrapper>
            {waterFlowData &&
              waterFlowData?.map((item: any, index: number) => {
                const { name, address, coordinates, waterLevel, distanceToWater, temperature } = item;
                const deviceData = [
                  {
                    icon: <WaterLevelImage />,
                    value: waterLevel?.measurement || 'N/A',
                    tooltipText: waterLevel?.tooltipText,
                    margin: '0 6px 0 0',
                  },
                  {
                    icon: <DistanceToWaterImage />,
                    value: distanceToWater?.measurement || 'N/A',
                    tooltipText: distanceToWater?.tooltipText,
                    margin: '0 6px 0 0',
                  },
                  {
                    icon: <TemperatureImage />,
                    value: temperature?.measurement || 'N/A',
                    tooltipText: temperature?.tooltipText,
                  },
                ];

                return (
                  <DeviceWrapper key={index}>
                    <DeviceName type={TextType.TEXT_14_BLACK} color={black1}>
                      {name || 'N/A'}
                    </DeviceName>
                    <WrapperAddress>
                      <TextWrapper color={black1}>
                        <LocationImage />
                        <Text type={TextType.TEXT_14_BLACK} color={black1}>
                          {address || 'N/A'}
                        </Text>
                      </TextWrapper>
                      <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                        {coordinates || 'N/A'}
                      </Text>
                    </WrapperAddress>
                    <DeviceDataWrapper>
                      {deviceData.map((item: any, i: number) => {
                        return (
                          <StyledTooltip key={index} id={tooltipId[i]} text={item.tooltipText} place={TooltipPlace.TOP}>
                            <DeviceData color={gray1} textColor={black1} margin={item.margin ?? '0'}>
                              {item.icon}
                              <Text type={TextType.TEXT_12_BLACK} color={black1}>
                                {item.value}
                              </Text>
                            </DeviceData>
                          </StyledTooltip>
                        );
                      })}
                    </DeviceDataWrapper>
                    <DateWrapper>
                      <TextWrapper margin="0 6px 0 6px">
                        <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                          {item.date || 'N/A'}
                        </Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                          {item.time || 'N/A'}
                        </Text>
                      </TextWrapper>
                    </DateWrapper>
                  </DeviceWrapper>
                );
              })}
          </SemiWrapper>
        </>
      ) : (
        <SingleDeviceWrapper>
          <NameWrapper>
            <DeviceName type={TextType.TEXT_14_BLACK} color={black1}>
              {name || 'N/A'}
            </DeviceName>
          </NameWrapper>
          <SingleDeviceExtraWrapper>
            <WrapperAddress>
              <TextWrapper color={black1}>
                <LocationImage />
                <Text type={TextType.TEXT_14_BLACK} color={black1}>
                  {address || 'N/A'}
                </Text>
              </TextWrapper>
              <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                {coordinates || 'N/A'}
              </Text>
            </WrapperAddress>
            <DeviceDataWrapper>
              {singleDeviceData.map((item: any, index: number) => {
                return (
                  <StyledTooltip key={index} id={tooltipId[index]} text={item.tooltipText} place={TooltipPlace.TOP}>
                    <DeviceData color={gray1} textColor={black1} margin={item.margin ?? '0'}>
                      {item.icon}
                      <Text type={TextType.TEXT_12_BLACK} color={black1}>
                        {item.value}
                      </Text>
                    </DeviceData>
                  </StyledTooltip>
                );
              })}
            </DeviceDataWrapper>
            <DownWrapper>
              <DateWrapper>
                <TextWrapper margin="0 6px 0 6px">
                  <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                    {date || 'N/A'}
                  </Text>
                </TextWrapper>
                <TextWrapper>
                  <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                    {time || 'N/A'}
                  </Text>
                </TextWrapper>
              </DateWrapper>
              <CommonButton onClick={handleClick} height="25px" width="100%" noFocus>
                {languageStrings.commonButtonMore}
              </CommonButton>
            </DownWrapper>
          </SingleDeviceExtraWrapper>
        </SingleDeviceWrapper>
      )}
      <FlowInfo
        flowData={flowInfoData}
        withFlowSeparator={isSingleDevice}
        history={history}
        withFlow
        languageStrings={languageStrings}
      />
    </Wrapper>
  );
};
