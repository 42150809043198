import { API_URL } from '../consts';
import { generateApi, useApi } from '../features/apiBuilder/useApiBuilder';
import { ApiCallType } from '../utils/http';

export const useOrgDet = ({ organisationId, keywords = [] }: { organisationId?: string; keywords?: string[] }) => {
  const url = `${API_URL}/v2/clients/${organisationId}`;

  const [data] = useApi({
    keywords,
    defaultValue: {},
    url,
    transformResponse: (data) => data.payload,
    condition: !!organisationId,
  });

  return [data];
};

export const updateCustomerInfoTable = ({
  id,
  field_id,
  body,
  handleError,
  handleSuccess,
}: {
  id: string;
  field_id: string;
  body: any;
  handleError?: (data: any) => void;
  handleSuccess?: (data: any) => void;
}) => {
  const url = `${API_URL}/v2/clients/${id}/info/${field_id}`;

  return generateApi({ url, callType: ApiCallType.PATCH })({ handleError, handleSuccess, body });
};
