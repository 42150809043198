import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguageStrings } from '../../../../../../../app/state/user';
import { API_URL } from '../../../../../../../consts';
import { DeviceDetailsType } from '../../../../../../../enums/View';
import { withTableContextProvider } from '../../../../../../../features/universalTable/context';
import {
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../../../../../features/universalTable/types';
import { UniversalTable } from '../../../../../../../features/universalTable/UniversalTable';
import { View } from '../../../../../../../routes/routeInterfaces';
import { media } from '@cthings.co/styles-utils';
import { useMediaType } from '@cthings.co/styles-utils';
import { useQueryParams } from '../../../../../../../utils/useQueryParams';
import { EventField } from './components/eventField/EventField';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${media.desktop} {
    max-width: 1024px;
  }
`;
const WrapperText = styled(Text)`
  margin-bottom: 20px;
`;

const NetworkEventTable = ({
  id,
  pathParams,
  type,
  keyWords,
}: {
  id: string;
  pathParams: any;
  type?: DeviceDetailsType;
  keyWords: string[];
}) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const keyword = 'PackagesInfoTable';
  const queryParams = useQueryParams() || {};

  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pageSize = 10;
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const { phone, desktop, tablet } = useMediaType();

  return (
    <Wrapper>
      <WrapperText type={TextType.SEMITITLE}>{languageStrings.insightDetailsNetworkEvent}</WrapperText>
      <UniversalTable
        placeholderProps={{ keyword }}
        updateItems={() => {}}
        keyword={keyword}
        additionalKeywords={keyWords}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        offsetKey={offsetKey}
        offset={+offset}
        pageSize={pageSize}
        queryParams={queryParams}
        pathParams={pathParams}
        tablePath={View.USER_MANAGE_DEVICES_DETAILS}
        mainApiUrl={`${API_URL}/v2/devices/${id}/insight/events/network`}
        inlineActionSet={tablet ? [{ type: InlineActionType.DETAILS }] : []}
        withHeaderInMobile={true}
        withHeaderGridTemplate={phone ? '150px 1fr 24px' : tablet ? '170px 1fr 24px' : '1fr 1fr 3fr'}
        columnStructureTriggers={[tablet, phone]}
        columnStructure={[
          {
            fieldName: 'ts',
            placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
            label: languageStrings.problemsDate,
            defaultValue: '',
            excludedFromView: tablet,
            excludedFromAdd: tablet,
            excludedFromModalView: tablet,
            type: RowItemType.CUSTOM,
            component: (value: any, state) => (
              <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
                {moment(value).format('DD.MM.YYYY') || 'N/A'}
              </Text>
            ),
          },
          {
            fieldName: 'ts',
            placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
            label: languageStrings.insightDetailsEventTime,
            defaultValue: '',
            excludedFromView: tablet,
            excludedFromAdd: tablet,
            excludedFromModalView: tablet,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
                {moment(value).format('HH:mm') || 'N/A'}
              </Text>
            ),
          },
          {
            fieldName: 'ts',
            placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
            label: languageStrings.insightDetalisEventDateAndTime,
            defaultValue: '',
            excludedFromView: !tablet,
            shownInHeader: tablet,
            excludedFromAdd: !tablet,
            excludedFromModalView: !tablet,
            type: RowItemType.CUSTOM,
            component: (value: any, state) => (
              <Text
                type={TextType.TEXT_14_BLACK}
                color={gray1}
                margin={tablet && state === TableCustomComponentState.VIEW_MODAL ? '8px 12px' : '0'}
                weight={tablet && state === TableCustomComponentState.VIEW_MODAL ? '400' : '500'}
              >
                {moment(value).format('DD.MM.YYYY, HH:mm') || 'N/A'}
              </Text>
            ),
          },
          {
            fieldName: 'event',
            placeholder: languageStrings.manageClientsTableRowPlaceholdersEmail,
            label: languageStrings.insightDetailsEvent,
            defaultValue: 'user@example.com',
            shownInHeader: desktop,
            type: RowItemType.CUSTOM,
            component: (value: any, state?: TableCustomComponentState) => <EventField value={value} state={state} />,
          },
        ]}
      />
    </Wrapper>
  );
};
export const NetworkEvent = withTableContextProvider(NetworkEventTable);
