import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: reducers,
});

export const { requestPush, removeRequest } = routesSlice.actions;

export default routesSlice.reducer;
