import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as InfoIcon } from './assets/Info.svg';
import { InputModal } from '../../../../components/inputModal/InputModal';

export const HeadingBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
` as any; // @TODO fix type

export const StepValue = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: right;
` as any; // @TODO fix type

export const Description = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 13px !important;
` as any; // @TODO fix type

export const TypesOfAccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 13px;
`;

export const TypesOfAccountsText = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
` as any; // @TODO fix type

export const DetailsWrapper = styled(Text)`
  display: flex;
` as any; // @TODO fix type

export const Details = styled(Text)`
  font-size: 14px;
  line-height: 24px;
` as any; // @TODO fix type

type HintWrapperProps = {
  backgroundColor: string;
  borderColor: string;
};

export const HintWrapper = styled.div<HintWrapperProps>`
  margin-top: 13px;
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid;
  border-radius: 6px;
  border-color: ${({ borderColor }) => borderColor};
`;

export const HintIcon = styled(InfoIcon)`
  min-width: 20px;
`;

export const HintText = styled(Text)`
  margin-left: 10px !important;

  font-weight: 400;
  font-size: 14px;
` as any; // @TODO fix type

export const InputsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 20px;
  margin-top: 16px;

  ${media.semiTablet} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
`;

export const InputsContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInput = styled(InputModal)`
  margin-bottom: 0;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  ${media.semiTablet} {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const BackButton = styled(CommonButton)`
  width: 115px;
  margin-right: 15px;
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
    margin-right: 0;
  }
`;

export const NextButton = styled(CommonButton)`
  width: 115px;
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
`;
