import { useStyleContext } from '@cthings.co/styles-utils';

export const useDynamicStyles = () => {
  const [{ windowWidth }] = useStyleContext();

  return {
    modalStyles: { isTransformed: windowWidth < 926 },
    descriptionModalContainerStyles: { isHidden: windowWidth < 1143 },
    modalContainerStyles: { width: windowWidth < 926 ? 'calc(100% - 20px)' : '910px' },
  };
};
