import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
  isOpenInvoiceDetailsModal: false,
  isOpenDeliveryAddressModal: false,
};

export const billingDetailsSlice = createSlice({
  name: 'billingDetails',
  initialState,
  reducers: {
    setIsOpenInvoiceDetailsModal: (state, action: PayloadAction<any>) => {
      state.isOpenInvoiceDetailsModal = action.payload;
    },
    setIsOpenDeliveryAddressModal: (state, action: PayloadAction<any>) => {
      state.isOpenDeliveryAddressModal = action.payload;
    },
  },
});

export const { setIsOpenInvoiceDetailsModal, setIsOpenDeliveryAddressModal } = billingDetailsSlice.actions;

export const selectIsOpenInvoiceDetailsModal = (state: RootState) => {
  return state.billingDetails.isOpenInvoiceDetailsModal;
};

export const selectIsOpenDeliveryAddressModal = (state: RootState) => {
  return state.billingDetails.isOpenDeliveryAddressModal;
};

export default billingDetailsSlice.reducer;
