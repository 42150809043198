import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as ThreeDotsIcon } from '../../assets/ThreeDots.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const ExportButtonWrapper = styled.a`
  padding: 0 20px;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
  background-color: ${colorFetch('white')};
  border: 1px solid;
  border-color: ${colorFetch('primary')};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.additional6};
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: ${colorFetch('primary')};
  transition: all 0.3s linear;
  text-decoration: none;

  & > svg > path {
    transition: all 0.3s linear;
  }

  &:hover {
    color: white;
    background-color: ${colorFetch('primary')};
    transition: all 0.3s linear;
    & > svg > path {
      stroke: ${colorFetch('pureWhite')};
      transition: all 0.3s linear;
    }
  }

  ${media.tablet} {
    padding: 0 5px;
  }
`;

export const ExportText = styled.span`
  display: block;

  ${media.tablet} {
    display: none;
  }
`;

export const StyledDotsIcon = styled(ThreeDotsIcon)`
  margin-left: 6px;

  ${media.phone} {
    display: none;
  }
`;
