import { Address } from '../../../types/address';
import { UserRoleId } from '../../../types/userRole';

export type CreationUser = {
  name: string;
  email: string;
  role: UserRoleId;
};

//@TODO add types
export type Organisation = {
  name: string;
  phone: string;
  parentOrganisation: any;
  profile: any;
  email: string;
  address: Address;
};

export type CreationDevice = {
  name: string;
  type: string;
  id: string;
  transport_id: string;
  isSelected: boolean;
  plansets_info: { device_planset: { id: string; name: string }; available_plansets: { id: string; name: string }[] };
};

export type PlansetOfDevice = {
  id: string;
  item: { id: string; name: string };
};

export type PlansetOfTypeOfDevice = {
  type: string;
  item: { id: string; name: string };
};

export enum UserModificationMode {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export type DeviceSelectionMode = 'individual' | 'range' | 'selected';

//@TODO add types
export interface ClientsTableState {
  isEditInProcess: boolean;
  treeHistory: COMPANY_CREATION_STEP[];
  parentOrganisation: Organisation;
  users: (CreationUser & { id: string })[];
  userInEdit: CreationUser & { id: string };
  devices: CreationDevice[];
  failedDevicesCount: number;
  fromDevice: CreationDevice | null;
  toDevice: CreationDevice | null;
  deviceSelectionMode: DeviceSelectionMode;
  uploadedDevices: CreationDevice[];
  isSelectedField: boolean;
  parentOrganisationsList: any;
  profilesList: any;
  isDeviceMode: boolean;
  finaliseTrigger: number;
}

export enum COMPANY_CREATION_STEP {
  ADD_COMPANY = 'ADD_COMPANY',
  FULL_OPERATIONAL_EXPLANATIONS = 'FULL_OPERATIONAL_EXPLANATIONS',
  ADD_NEW_COMPANY = 'ADD_NEW_COMPANY',
  CREATE_FIRST_USER = 'CREATE_FIRST_USER',
  CREATE_MORE_USERS = 'CREATE_MORE_USERS',
  ADD_DEVICES = 'ADD_DEVICES',
  UPLOAD_DEVICES = 'UPLOAD_DEVICES',
  ADD_DEVICES_MANUALLY = 'ADD_DEVICES_MANUALLY',
  SUMMARY = 'SUMMARY',
  SUMMARY_USERS = 'SUMMARY_USERS',
  SUMMARY_DEVICES = 'SUMMARY_DEVICES',
}

type GenericItem = {
  [K in COMPANY_CREATION_STEP]: {};
};

type СreationTreeType<T> = {
  // Сheck if current attribute extends 'name'
  [K in keyof T]: K extends 'name'
    ? T[K] extends Readonly<string>
      ? T[K]
      : Readonly<string>
    : K extends 'actions'
    ? СreationTreeType<T[K]>
    : K extends COMPANY_CREATION_STEP
    ? СreationTreeType<T[K]>
    : // Check if current attribute extends the empty object type
    T[K] extends { [K2 in any]: never }
    ? GenericItem
    : // Check if current attribute extends the object type
    T[K] extends { [K2 in string]: unknown }
    ? // Recursive type with current attribute as a base
      СreationTreeType<T[K]>
    : GenericItem;
};

// @NOTE this function asserts the object that needs to be frozen,
const typedFreeze = <T extends СreationTreeType<T>>(obj: T) => Object.freeze(obj);

const SUMMARY_SUBTREE = {
  [COMPANY_CREATION_STEP.SUMMARY]: {
    name: 'summary',
    actions: {
      [COMPANY_CREATION_STEP.SUMMARY_USERS]: {
        name: 'editUsers',
      },
      [COMPANY_CREATION_STEP.SUMMARY_DEVICES]: {
        name: 'editDevices',
      },
    },
  },
};

const MANUAL_DEVICE_ADD_SUBTREE = {
  [COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY]: {
    name: 'addManually',
    actions: {
      ...SUMMARY_SUBTREE,
    },
  },
};

export const CREATION_TREE = typedFreeze({
  [COMPANY_CREATION_STEP.ADD_COMPANY]: {
    name: 'start',
    actions: {
      [COMPANY_CREATION_STEP.FULL_OPERATIONAL_EXPLANATIONS]: {
        name: 'fullOperational',
        actions: {
          [COMPANY_CREATION_STEP.ADD_NEW_COMPANY]: {
            name: 'getStarted',
            actions: {
              [COMPANY_CREATION_STEP.CREATE_FIRST_USER]: {
                name: 'next',
                actions: {
                  [COMPANY_CREATION_STEP.CREATE_MORE_USERS]: {
                    name: 'next',
                    actions: {
                      [COMPANY_CREATION_STEP.ADD_DEVICES]: {
                        name: 'next',
                        actions: {
                          [COMPANY_CREATION_STEP.UPLOAD_DEVICES]: {
                            name: 'upload',
                            actions: {
                              ...MANUAL_DEVICE_ADD_SUBTREE,
                            },
                          },
                          ...MANUAL_DEVICE_ADD_SUBTREE,
                          ...SUMMARY_SUBTREE,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      [COMPANY_CREATION_STEP.ADD_NEW_COMPANY]: {
        name: 'companyOnly',
      },
    },
  },
} as const);

export type CreationTree = typeof CREATION_TREE;

const DEVICE_SUMMARY_SUBTREE = {
  [COMPANY_CREATION_STEP.SUMMARY]: {
    name: 'summary',
    actions: {
      [COMPANY_CREATION_STEP.SUMMARY_DEVICES]: {
        name: 'editDevices',
      },
    },
  },
};

const REDUCED_MANUAL_DEVICE_ADD_SUBTREE = {
  [COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY]: {
    name: 'addManually',
    actions: {
      ...DEVICE_SUMMARY_SUBTREE,
    },
  },
};

export const DEVICE_EDIT_TREE = typedFreeze({
  [COMPANY_CREATION_STEP.ADD_DEVICES]: {
    name: 'start',
    actions: {
      [COMPANY_CREATION_STEP.UPLOAD_DEVICES]: {
        name: 'upload',
        actions: {
          ...REDUCED_MANUAL_DEVICE_ADD_SUBTREE,
        },
      },
      ...REDUCED_MANUAL_DEVICE_ADD_SUBTREE,
      ...DEVICE_SUMMARY_SUBTREE,
    },
  },
} as const);

export type DeviceEditTree = typeof DEVICE_EDIT_TREE;

// @NOTE allows getting a type for particular subtree inside the CreationTree, based on the path in a tree
/*type Subtree<T, KS extends readonly any[]> = KS extends readonly [infer K, ...infer R]
  ? [K] extends [never]
    ? T
    : [K] extends [keyof T]
    ? { [P in K]: Subtree<T[P], R> }[K]
    : undefined
  : T;*/

// @TODO extend type to support tuples only
const getSubtree = <KS extends readonly any[]>(p: KS, isDeviceMode?: boolean) =>
  p.reduce((subtree, pathElem) => (subtree as any)[pathElem], isDeviceMode ? DEVICE_EDIT_TREE : CREATION_TREE);
type ActionMapping = { name: string; to: COMPANY_CREATION_STEP };

export const getActions = (treeHistory: COMPANY_CREATION_STEP[], isDeviceMode?: boolean) => {
  const subtree =
    getSubtree(
      treeHistory.reduce((path, elem) => [...path, elem, 'actions'], [] as readonly any[]),
      isDeviceMode,
    ) || {};

  const mapping = Object.keys(subtree).map((key) => ({ name: subtree[key].name, to: key })) as ActionMapping[];
  const listOfActions =
    treeHistory.length > 1
      ? ([...mapping, { name: 'back', to: treeHistory[treeHistory.length - 1] }] as ActionMapping[])
      : mapping;

  return listOfActions.reduce(
    (actions, item) => ({ ...actions, [item.name]: item.to }),
    {} as { [K in string]: COMPANY_CREATION_STEP },
  );
};

export type DEVICE_TOOL_STEP = Extract<
  COMPANY_CREATION_STEP,
  | COMPANY_CREATION_STEP.ADD_DEVICES
  | COMPANY_CREATION_STEP.UPLOAD_DEVICES
  | COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY
  | COMPANY_CREATION_STEP.SUMMARY
  | COMPANY_CREATION_STEP.SUMMARY_DEVICES
>;
