import { ButtonIconType, CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { RowItemType, UniversalTable, useTableFunctions, withTableContextProvider } from '@cthings.co/universal-table';
import { HttpService } from '@cthings.co/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import styled from '@cthings.co/styled-components';
import { updateCustomerInfoTable, useOrgDet } from '../../../../api/organisationsDetails';
import { startDeviceEditTool } from '../../../../app/state/organisations';
import { selectAccess, selectIsAccess, selectLanguage, selectLanguageStrings } from '../../../../app/state/user';
import { ReactComponent as Pencil } from '../../../../assets/icons/pencil.svg';
import { ReactComponent as PlusCircle } from '../../../../assets/icons/PlusCircle.svg';
import { EditAction, UniversalTableInput } from '../../../../components/universalTableInput';
import { API_URL } from '../../../../consts';
import { useNotifierFunctions } from '../../../../features/notifier2';
import { OrganisationDevicesTool } from '../../../../features/organisationDevicesTool/OrganisationDevicesTool';
import { useMobileManagementSearch } from '../../../../layouts/managementMobile/useMobileManagement';
import { View } from '../../../../routes/routeInterfaces';
import { useMediaType } from '@cthings.co/styles-utils';
import { useCustomHistory } from '../../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../../utils/useQueryParams';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.tabletL} {
    padding-left: 30px;
    padding-right: 30px;
  }
  ${media.phone} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 32px;
  ${media.phone} {
    padding-left: 16px;
  }
`;

const Title = styled(Text)``;

const TablesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(640px, 1fr) 2fr;
  gap: 30px;
  ${media.tablet} {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const CustomerInfoTable = styled.div`
  width: 100%;
`;

const TableTitle = styled(Text)`
  display: block;

  ${media.phone} {
    padding-left: 16px;
  }
`;

const AcceptAndCloseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StyledPencil = styled(Pencil)`
  /* width: 20px;
  height: 20px; */
  cursor: pointer;
`;

const StyledPlusCircle = styled(PlusCircle)`
  cursor: pointer;
`;

const OrganisationDetailsPlain = () => {
  const theme = useTheme();
  const { blue2, green2, gray1, primary, black1 } = theme.colors;

  const dispatch = useDispatch();
  const keyword = 'OrganisationsDetailsTable';
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const pathParams = useParams();

  const queryParams = useQueryParams() || {};

  const organisationId = pathParams.id;
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const customHistory = useCustomHistory();

  const [data] = useOrgDet({ organisationId, keywords: ['OrganisationsDetailsTable'] });

  const { resetTable, setSearchValue, updateLocalItem, setOpenIndex } = useTableFunctions();

  const languageStrings = useSelector(selectLanguageStrings);
  const { shortName } = useSelector(selectLanguage);

  const [rowInEdit, setRowInEdit] = useState('');
  const [updateCounter, setUpdateCounter] = useState(0);

  const accessData = useSelector(selectAccess).management.client;

  const { ultramonitor, monitor, tablet } = useMediaType();

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useMobileManagementSearch(setSearchValue);

  const handleAdd = () => dispatch(startDeviceEditTool());

  const [savedData, setSavedData] = useState('');

  const handleRevert = (savedData: any, index: number) => updateLocalItem(index, savedData);

  const { addNotification } = useNotifierFunctions();

  const handleStart = (data: any, index: number) => {
    setSavedData(data);
    setRowInEdit(data.id);
    setOpenIndex(index);
  };

  const handleConfirm = (data: any, savedData: any, index?: number) => {
    updateCustomerInfoTable({
      id: organisationId ?? '',
      field_id: data.id,
      body: { value: data.value.actual_value },

      handleError: (data: any) => {
        addNotification({
          message: data?.response?.data?.detail ?? 'Something went wrong!',
          type: 'error',
        });

        handleRevert(savedData, index ?? -1);
        setSavedData('');
        setRowInEdit('');
        setOpenIndex(-1);
      },

      handleSuccess: (data: any) => {
        addNotification({
          message: languageStrings.successSaveMessage,
          type: 'success',
        });

        setSavedData('');
        setRowInEdit('');
        setOpenIndex(-1);
        setUpdateCounter((prev: number) => {
          return prev + 1;
        });
      },
    });
  };

  const handleCancel = (savedData: any, index?: number) => {
    setOpenIndex(-1);
    handleRevert(savedData, index ?? -1);
    setRowInEdit('');
  };

  return (
    <Wrapper>
      <TitleBlock>
        <CommonButton
          width="32px"
          iconType={ButtonIconType.ARROW_LEFT}
          iconWithoutText
          onClick={() => customHistory.goBack()}
        />
        <Title type={TextType.TITLE_H1} color={black1}>
          {data?.name ?? 'N/A'}
        </Title>
      </TitleBlock>
      <TablesWrapper>
        <CustomerInfoTable>
          <TableTitle type={TextType.SEMITITLE} margin={'0 0 12px 0'}>
            {languageStrings.organisationDetailsTitleInfoTable}
          </TableTitle>
          <UniversalTable
            HttpService={HttpService}
            theme={theme}
            language={shortName}
            isPaginationEnabled={false}
            placeholderProps={{
              keyword,
              placeholderOptions: { withoutPagination: true },
              conditionalStyle: { height: '100%', overflow: 'hidden', borderRadius: '8px' },
            }}
            mainApiUrl={`${API_URL}/v2/clients/${organisationId}/info`}
            customRowClick={() => {}}
            apiTriggers={[shortName, updateCounter]}
            keyword={keyword}
            offset={+offset}
            offsetKey={offsetKey}
            inlineActionSet={[{ preset: 'EDIT_ACTION' } as any]}
            // isSearchEnabled={false}
            sortingData={sortingData}
            sortingKeys={sortingKeys}
            pathParams={pathParams}
            queryParams={queryParams}
            tablePath={View.BILLING_INVOICES}
            accessData={accessData}
            presetMapping={{
              component: {
                EDITABLE_INPUT: (
                  value: any,
                  state: any,
                  setValue?: any,
                  saveToggle?: boolean,
                  id?: string,
                  index?: number,
                ) => {
                  return (
                    <UniversalTableInput
                      value={value}
                      state={state}
                      setField={setValue}
                      isEditInProcess={rowInEdit === id}
                      saveToggle={saveToggle}
                      handleCancel={() => handleCancel(savedData, index)}
                      handleConfirm={() => handleConfirm({ id, value }, index)}
                    />
                  );
                },
              },
              inline: {
                EDIT_ACTION: (data: any, index: number) => {
                  const isEditInProcess = rowInEdit === data.id;

                  return (
                    <EditAction
                      data={data}
                      index={index}
                      isEditInProcess={isEditInProcess}
                      handleStart={handleStart}
                      handleConfirm={handleConfirm}
                      handleCancel={handleCancel}
                      handleAdd={handleAdd}
                      savedData={savedData}
                    />
                  );
                },
              },
            }}
            columnStructureTriggers={[ultramonitor, monitor, tablet, rowInEdit]}
            columnStructure={[
              {
                fieldName: 'property',
                placeholder: languageStrings.deviceInfoLabelProperty,
                label: languageStrings.deviceInfoLabelProperty,
                defaultValue: '',
                width: tablet ? '1fr' : monitor ? '1fr' : '1fr',
              },
              {
                fieldName: 'value',
                placeholder: languageStrings.insightDetailsDeviceConfigHeaderValue,
                label: languageStrings.insightDetailsDeviceConfigHeaderValue,
                width: tablet ? '1fr' : '1fr',
                defaultValue: '',
                type: RowItemType.CUSTOM,
                component: { preset: 'EDITABLE_INPUT' },
              },
            ]}
          />
        </CustomerInfoTable>
      </TablesWrapper>
      <OrganisationDevicesTool />
    </Wrapper>
  );
};

export const OrganisationDetails = withTableContextProvider(OrganisationDetailsPlain);
