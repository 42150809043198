import React from 'react';
import styled from '@cthings.co/styled-components';
import { GraphRow } from '../../../common/styledComponents/StyledComponents';
import { WaterLevelGraph } from './components/waterLevelGraph/WaterLevelGraph';

const StyledGraphRow = styled(GraphRow)``;

export const History = ({ defaultFilters }: { defaultFilters: any }) => {
  return (
    <StyledGraphRow>
      <WaterLevelGraph defaultFilters={defaultFilters} />
    </StyledGraphRow>
  );
};
