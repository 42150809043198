import { Text } from '@bit/first-scope.text';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ReactComponent as TypeIcon } from './DeviceTypeIcon.svg';

type WrapperProps = {
  margin?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  ${media.semiTablet} {
    margin-block: 10px;
  }
`;

type TypeNameProps = {
  borderColor?: string;
};

const TypeName = styled(Text)<TypeNameProps>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${colorFetch('gray1_5')};
  margin-left: 6px !important;
  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
  line-height: 18px;
` as any; // @TODO fix type

export interface DeviceTypeProps {
  type: string;
  margin?: string;
  borderColor?: string;
}

export const DeviceType: React.FC<DeviceTypeProps> = ({ type, margin, borderColor }) => {
  return (
    <Wrapper margin={margin}>
      <TypeIcon />
      <TypeName borderColor={borderColor}>{type}</TypeName>
    </Wrapper>
  );
};
