import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Modal } from '@bit/first-scope.modal';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { TableInput, TableInputType } from '../../../../../components/tableInput/TableInput';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { FieldsType, isValidField } from '../../../../../utils/validation';
import { Loader } from '../../../../../components/placeholders/Loader';
import { useBlockBodyScroll } from '../../../../../utils/useBlockBodyScroll';
import { useWindowSize } from '@cthings.co/styles-utils';
import { useGetIncidentParameters } from '../../../../../api/incidents';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 528px !important;
  align-items: flex-start !important;
  padding: 24px !important;
  ${media.phone} {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    border-radius: 16px 16px 0 0;
    padding: 16px !important;
  } ;
`;

const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${media.phone} {
    max-height: 432px;
    overflow-y: scroll;
  }
`;

type InputProps = {
  disabled: boolean;
  severityColor: string;
};

const Input = styled(TableInput)<InputProps>`
  &.tight {
    width: calc(50% - 13px) !important;
  }
  &.last {
    & > div > div > textarea {
      min-height: 130px;
    }
  }
  &.startTime {
    width: calc(50% - 13px);
    flex: none;
    align-items: flex-start;
    & > div {
      margin: 0;
      & > div > input {
        height: 40px;
        text-align: start;
        font-weight: ${({ disabled }) => (disabled ? '400' : '500')};
      }
    }
  }
  &.dropdown {
    & > div > div > span {
      color: ${({ severityColor }) => severityColor};
      -webkit-text-fill-color: ${({ severityColor }) => severityColor};
    }
  }
  margin: 0 0 12px 0;
  ${media.phone} {
    &.tight {
      width: 100% !important;
    }
    &.startTime {
      width: 100% !important;
    }
    &.last {
      margin: 0;
      & > div {
        margin: 0;
      }
    }
    & > div {
      margin: 0;
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;

  ${media.phone} {
    flex-direction: column;
    margin-top: 20px;
    & > button {
      width: 100%;
      height: 40px;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
`;

const CancelButton = styled(CommonButton)`
  margin-right: 16px;
  ${media.phone} {
    margin: 0;
  }
`;

export const ActionModal = ({
  isOpenModal,
  closeModal,
  acceptOnClick,
  data,
  isLoadingInProcess,
  languageStrings,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  acceptOnClick: Function;
  data?: any;
  isLoadingInProcess?: boolean;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { black1, primary, pureWhite, gray1, blue2, orange1, red1 } = theme.colors;

  const [newIncident, setNewIncident] = useState<any>({
    incident_type: { name: '', id: '' },
    device: { name: '', id: '' },
    address: '',
    startDate: new Date(),
    startTime: getRequiredDateFormat(new Date(), 'HHmm'),
    severity: { name: '', id: '', text: 1 },
    comments: '',
  });

  const [incidentParameters] = useGetIncidentParameters();
  const windowWidth = useWindowSize()[0];
  const getSeverityText = (i: number) => (i === 1 ? 'Low' : i === 2 ? 'Medium' : i === 3 ? 'High' : 'Resolved');
  useBlockBodyScroll(isOpenModal && windowWidth > 768);

  const inputsList = [
    {
      value: { name: data.incident_type_name, id: data.incident_type_id },
      type: TableInputType.DROPDOWN,
      valuesList: incidentParameters?.incident_types,
      fieldName: 'incident_type',
      options: {
        textOfErrorMessage: '',
        title: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
        placeholder: languageStrings.chooseTypePlaceholder,
        isError: isValidField(newIncident.incident_type.name, FieldsType.SELECT),
        isDisabled: true,
      },
      onChange: (value: { name: string; id: string }) => {
        setNewIncident({ ...newIncident, incident_type: value });
      },
      className: 'tight',
    },
    {
      value: { name: data.device_transport_id, id: data.device_id },
      type: TableInputType.DROPDOWN,
      valuesList: incidentParameters?.devices,
      options: {
        textOfErrorMessage: '',
        title: languageStrings.deviceLabel,
        placeholder: languageStrings.selectDevicePlaceholder,
        isError: isValidField(newIncident.device.name, FieldsType.SELECT),
        isDisabled: true,
      },
      onChange: (value: { name: string; id: string }) => {
        setNewIncident({ ...newIncident, device: value });
      },
      className: 'tight',
    },
    {
      value: data && data.device_address ? `${data?.device_address?.city}, ${data?.device_address?.line1}` : 'N/A',
      type: TableInputType.TEXTFIELD,
      fieldName: 'address',
      options: {
        textOfErrorMessage: '',
        title: languageStrings.manageClientsTableHeaderItemsAddress,
        placeholder: languageStrings.incidentAddressPlaceholder,
        isError: isValidField(newIncident.address, FieldsType.TEXT),
        isDisabled: true,
      },
    },
    {
      value: data && data.ts_start ? new Date(data.ts_start) : null,
      type: TableInputType.DATE_PICKER_WITH_ICON,
      options: {
        textOfErrorMessage: '',
        title: languageStrings.startDateLabel,
        maxValue: new Date(),
        isDisabled: true,
        handleSaveMobile: () => {},
      },
      onChange: (value: string) => {
        setNewIncident({ ...newIncident, startDate: value });
      },
      className: 'tight',
    },
    {
      value: data && data.ts_start ? getRequiredDateFormat(data.ts_start, 'HHmm') : null,
      type: TableInputType.TIME_PICKER,
      options: {
        width: '100%',
        textOfErrorMessage: '',
        title: languageStrings.startTimeLabel,
        placeholder: languageStrings.startTimePlaceholder,
        isError: isValidField(newIncident.startTime, FieldsType.TEXT),
        isDisabled: true,
      },
      onChange: (value: string) => {
        setNewIncident({ ...newIncident, startTime: value });
      },
      className: 'tight',
    },
    {
      value:
        data && data.severity
          ? {
              name: getSeverityText(data.severity[data.severity.length - 1].text),
              id: data.severity[data.severity.length - 1].text,
            }
          : 'N/A',
      type: TableInputType.DROPDOWN,
      valuesList: incidentParameters?.severities?.map((item: any) => ({
        name: item.name,
        id: item.severity_status,
        text: item.severity_status,
      })),
      options: {
        textOfErrorMessage: '',
        title: languageStrings.severityLabel,
        placeholder: languageStrings.chooseSeverityPlaceholder,
        isError: isValidField(newIncident.severity.name, FieldsType.SELECT),
        isDisabled: true,
      },
      onChange: (value: { name: string; id: string }) => {
        setNewIncident({ ...newIncident, severity: value });
      },
      className: 'tight',
    },
    {
      value: newIncident.comments,
      type: TableInputType.TEXTFIELD,
      options: {
        textOfErrorMessage: '',
        title: languageStrings.insightDetailsCommentsTitle,
        placeholder: languageStrings.insightDetailsCommentsPlaceholder,
        isError: isValidField(newIncident.comments, FieldsType.TEXT),
        rows: 2,
      },
      onChange: (value: string) => {
        setNewIncident({ ...newIncident, comments: value });
      },
    },
  ];

  const cancelOnClick = () => {
    closeModal();
    setNewIncident({
      incident_type: { name: '', id: '' },
      device: { name: '', id: '' },
      address: '',
      startDate: new Date(),
      startTime: getRequiredDateFormat(new Date(), 'HHmm'),
      severity: { name: '', id: '' },
      comments: '',
    });
  };

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Text type={TextType.SEMITITLE} fontSize={'18px'} weight={'700'} margin={'0 0 16px 0'} color={black1}>
          {languageStrings.resolveIncidentTitle}
        </Text>
        <InputsWrapper>
          {inputsList.map((item: any, index: number, arr: any[]) => {
            const { type, value, valuesList, onChange, options, className } = item;

            return (
              <Input
                key={index}
                className={
                  index === 4
                    ? 'startTime'
                    : type === TableInputType.DROPDOWN && index === 5
                    ? 'dropdown'
                    : index === arr.length - 1
                    ? 'last'
                    : className
                }
                type={type}
                value={value}
                valuesList={valuesList}
                handleChangeValue={onChange}
                options={options}
                disabled={index !== arr.length - 1}
                severityColor={
                  index === 5
                    ? value.id === 0
                      ? gray1
                      : value.id === 1
                      ? blue2
                      : value.id === 2
                      ? orange1
                      : value.id === 3
                      ? red1
                      : gray1
                    : ''
                }
                isEditInProcess
              />
            );
          })}
          {isLoadingInProcess && <Loader />}
        </InputsWrapper>
        <ButtonsWrapper>
          <CancelButton
            onClick={cancelOnClick}
            width={'104px'}
            colors={{
              main: black1,
              background: pureWhite,
              backgroundHover: primary,
              borderHover: primary,
            }}
          >
            {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
          </CancelButton>
          <CommonButton onClick={() => acceptOnClick(newIncident.comments)} width={'104px'}>
            {languageStrings.resolveButton}
          </CommonButton>
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
