import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as Info } from '../../../../../../../../../assets/Info2.svg';
import Tooltip, { useTooltip } from '../../../../../../../../../components/tooltip';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    transition: 0.3s ease;
  }
  &:hover > svg > path {
    stroke: ${colorFetch('gray2')};
    transition: 0.3s ease;
  }
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 300px;
  box-sizing: border-box !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px;
  padding: 6px 10px;
`;

export const InfoIcon = ({ tooltip }: { tooltip: string }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipIds] = useTooltip(1);

  const onHover = () => {
    setIsHovered(true);
  };
  const onBlur = () => {
    setIsHovered(false);
  };

  return (
    <StyledTooltip id={isHovered ? tooltipIds[0] : ''} text={tooltip}>
      <IconWrapper onMouseEnter={onHover} onMouseLeave={onBlur}>
        <Info />
      </IconWrapper>
    </StyledTooltip>
  );
};
