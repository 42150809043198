import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const organisationsTableSlice = createSlice({
  name: 'clientsTable',
  initialState,
  reducers,
});

export const {
  startCompanyCreation,
  endCompanyCreation,
  pushLocation,
  pushAction,
  updateParentOrganisation,
  addUsers,
  updateUser,
  deleteUser,
  editUser,
  setEditUser,
  clearEditUser,
  saveEditUser,
  createUser,
  loadDevices,
  setFromDevice,
  setToDevice,
  setDeviceSelectionMode,
  uploadDevices,
  setSelectedField,
  handleDeviceSelection,
  setParentsList,
  setProfilesList,
  startDeviceEditTool,
  setPlansetOfDevice,
  setPlansetForAllDevicesOfThisType,
} = organisationsTableSlice.actions;

export default organisationsTableSlice.reducer;
