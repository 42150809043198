import { CommonButton } from '@cthings.co/buttons';
import styled from '@cthings.co/styled-components';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { Modal } from '@bit/first-scope.modal';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const StyledModal = styled(Modal)`
  ${media.phone} {
    align-items: flex-end;
  }
`;

export const StyledModalContainer = styled(ModalContainer)`
  max-width: 564px !important;
  width: 100% !important;
  padding: 24px !important;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  background: ${colorFetch('white')};
  border-radius: 12px;

  ${media.phone} {
    border-radius: 12px 12px 0 0;
    padding: 24px 16px 26px 16px;
  }
`;

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TextWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Text)`
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
`;

export const StyledInfoText = styled(Text)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  margin-top: 13px !important;

  ${media.phone} {
    margin-top: 16px !important;
  }
`;

export const OrderedList = styled.ol`
  padding: 0 0 0 20px !important;
  margin: 4px 0 0 0 !important;
`;

export const InfoListText = styled.li`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 33px;

  display: flex;
  justify-content: flex-end;

  ${media.tablet} {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
`;

export const ButtonBase = styled(CommonButton)`
  max-width: 116px;
  width: 100%;
  ${media.tablet} {
    max-width: 100%;
    height: 40px;
  }
`;

export const BackButton = styled(ButtonBase)`
  margin-right: 16px;
`;

export const GetStartedButton = styled(ButtonBase)`
  ${media.tablet} {
    margin-bottom: 10px;
  }
`;
