import React from 'react';
import styled, { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ReactComponent as DarkNote } from '../../assets/DarkNote.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '../../components/commonButton/CommonButton';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { View } from '../../routes/routeInterfaces';
import { stringifyUrl } from 'query-string';
import { getPath } from '../../routes/paths';
import { selectLanguageStrings } from '../../app/state/user';
import { useSelector } from 'react-redux';
import { media } from '@cthings.co/styles-utils';

const Banner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin: 25px auto;
  max-width: 835px;
  width: 100%;
  height: 60px;
  background-color: ${colorFetch('pureWhite')};
  color: ${colorFetch('gray1')};
  border: 1px solid;
  border-color: ${colorFetch('orange2')};
  box-sizing: border-box;
  border-radius: 6px;
  gap: 15px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: var(--snackbar-layer);
  box-shadow: 0px 6px 12px 0px rgba(46, 44, 87, 0.06), 0px 6px 30px 0px rgba(46, 44, 87, 0.05);

  ${media.tablet} {
    height: auto;
    padding: 20px;
    max-width: calc(100% - 30px);
  }

  ${media.semiTablet} {
    flex-direction: column;
    gap: 15px;
  } ;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${media.semiTablet} {
    align-items: flex-start;
  }
`;

const NoteIcon = styled(DarkNote)`
  min-width: 30px;
`;

const StyledInfoText = styled(Text)`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${colorFetch('gray1')};

  ${media.tablet} {
    text-align: left;
  }

  ${media.semiTablet} {
    text-align: center;
  }
`;

const StyledButton = styled(CommonButton)`
  color: ${colorFetch('pureWhite')};
  background-color: ${colorFetch('orange2')};
  border: none;
  min-width: 200px;
  width: auto;
  &:hover {
    background-color: ${colorFetch('orange1')};
  }
  ${media.semiTablet} {
    width: 100%;
  }
`;

export const InvalidVATBanner = ({ hideButton }: { hideButton: boolean }) => {
  const theme = useTheme();
  const history = useHistory();

  const { gray1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const onClick = () => {
    history.push(
      stringifyUrl({
        url: getPath(View.BILLING_DETAILS),
      }),
    );
  };

  return (
    <Banner>
      <InfoWrapper>
        <NoteIcon />
        <StyledInfoText type={TextType.TEXT_14_GRAY} color={gray1}>
          {languageStrings.vatNumberErrorText}
        </StyledInfoText>
      </InfoWrapper>
      {!hideButton && <StyledButton onClick={onClick}>{languageStrings.goToBillingDetails}</StyledButton>}
    </Banner>
  );
};
