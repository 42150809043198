import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';
import { DetailsModal } from '../detailsModal/DetailsModal';
import { useTheme } from '@cthings.co/styled-components';

const Button = styled(CommonButton)`
  font-size: 12px;
`;

export const DetailsButton = ({
  device_name,
  deviceId,
  deviceType,
  address,
  incidentData,
  isLoadingInProcess,
  userInfo,
  isOpenModal,
  closeDetailsModal,
  lang,
  languageStrings,
}: {
  device_name: string;
  deviceId: string;
  deviceType: string;
  address: string;
  incidentData: any;
  isLoadingInProcess: boolean;
  userInfo: any;
  isOpenModal: boolean;
  closeDetailsModal: () => void;
  lang: string;
  languageStrings?: any;
}) => {
  const [eventsList, setEventsList] = useState([]);

  const theme = useTheme();
  const { gray2, gray3, pureWhite } = theme.colors;

  const inputsList = [
    { value: device_name, title: languageStrings.deviceLabel },
    { value: address, title: languageStrings.manageClientsTableHeaderItemsAddress },
  ];

  useEffect(() => {
    if (incidentData) {
      setEventsList(incidentData.timeline);
    }
  }, [incidentData]);

  return (
    <>
      <Button
        width={lang === 'DE' ? '100px' : '80px'}
        height={'35px'}
        colors={{
          main: gray2,
          mainHover: pureWhite,
          background: pureWhite,
          backgroundHover: gray3,
          border: gray3,
          borderHover: gray3,
        }}
        onClick={() => {}}
      >
        {languageStrings.problemsButtonDetails}
      </Button>
      <DetailsModal
        deviceId={deviceId}
        deviceType={deviceType}
        isOpenModal={isOpenModal}
        closeModal={closeDetailsModal}
        inputsList={inputsList}
        eventsList={eventsList ? eventsList : []}
        incidentId={incidentData.id}
        isLoadingInProcess={isLoadingInProcess}
        userInfo={userInfo}
        languageStrings={languageStrings}
      />
    </>
  );
};
