import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNotifierFunctions } from '../../features/notifier2';
import { selectLanguageStrings } from '../../app/state/user';
import { Textfield, TextfieldOptionsType } from './components/textfield/Textfield';
import { Dropdown, DropdownOptionsType } from './components/dropdown/Dropdown';
import {
  DropdownMultiChoice,
  DropdownMultiChoiceFunctions,
  DropdownMultiChoiceOptions,
} from './components/dropdownMultiChoice/DropdownMultiChoice';
import { TimePicker } from './components/timePickers/timePicker/TimePicker';
import { TimePickerUnit } from './components/timePickers/timePickerUnit/TimePickerUnit';
import { TimePickerRange } from './components/timePickers/timeRange/TimePickerRange';
import { TimePickerFields } from './components/timePickers/timePickerFields/TimePickerFields';
import { Stepper, StepperOptionsType } from './components/stepper/Stepper';
import { RangeInput, SLiderRangeOptionsType } from './components/range/RangeInput';
import { DatePicker, DatePickerFunctions, DatePickerOptions } from './components/datePicker/DatePicker';
import { BooleanSwitcher, BooleanSwitcherOptions } from './components/booleanSwitcher/BooleanSwitcher';
import { DateRange, DateRangeOptions } from './components/dateRange/DateRange';
import { DatePickerWithIcon, DatePickerWithIconOptions } from './components/datePickerWithIcon/DatePickerWithIcon';
import { DateRangeWithIcon, DateRangeWithIconOptions } from './components/dateRangeWithIcon/DateRangeWithIcon';
import { SpecialInput } from './components/specialInput/SpecialInput';
import { SliderInput, SLiderInputOptionsType } from './components/slider/SliderInput';

export enum TableInputType {
  TEXTFIELD = 'TEXTFIELD',
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_MULTI_CHOICE = 'DROPDOWN_MULTI_CHOICE',
  SLIDER = 'SLIDER',
  SLIDER_RANGE = 'SLIDER_RANGE',
  SLIDER_FREE_INPUT = 'SLIDER_FREE_INPUT',
  SLIDER_FREE_INPUT_WITH_UNIT = 'SLIDER_FREE_INPUT_WITH_UNIT',
  SLIDER_FREE_INPUT_WITH_UNIT_AND_CHECKBOX = 'SLIDER_FREE_INPUT_WITH_UNIT_AND_CHECKBOX',
  SLIDER_RANGE_FREE_INPUT = 'SLIDER_RANGE_FREE_INPUT',
  SLIDER_RANGE_FREE_INPUT_WITH_UNIT = 'SLIDER_RANGE_FREE_INPUT_WITH_UNIT',
  STEPPER = 'STEPPER',
  STEPPER_WITH_UNIT = 'STEPPER_WITH_UNIT',
  TIME_PICKER = 'TIME_PICKER',
  TIME_RANGE = 'TIME_RANGE',
  TIME_PICKER_UNIT_MMSS = 'TIME_PICKER_UNIT_MMSS',
  TIME_PICKER_UNIT_HHMM = 'TIME_PICKER_UNIT_HHMM',
  TIME_PICKER_FIELDS = 'TIME_PICKER_FIELDS',
  TIME_PICKER_FIELDS_HHMM = 'TIME_PICKER_FIELDS_HHMM',
  DATE_PICKER = 'DATE_PICKER',
  DATE_RANGE = 'DATE_RANGE',
  DATE_PICKER_WITH_ICON = 'DATE_PICKER_WITH_ICON',
  DATE_RANGE_WITH_ICON = 'DATE_RANGE_WITH_ICON',
  TOGGLE = 'TOGGLE',
  TOGGLE_BIG = 'TOGGLE_BIG',
  SPECIAL_INTPUT = 'SPECIAL_INPUT',
}

export const TableInput = ({
  type,
  value,
  options,
  additionalFunctions,
  handleChangeValue,
  isEditInProcess,
  valuesList,
  className,
  disabled,
}: {
  type: TableInputType;
  value: any;
  valuesList?: any[];
  options?:
    | TextfieldOptionsType
    | DropdownOptionsType
    | DropdownMultiChoiceOptions
    | DatePickerOptions
    | DateRangeOptions
    | BooleanSwitcherOptions
    | DatePickerWithIconOptions
    | DateRangeWithIconOptions
    | StepperOptionsType
    | SLiderRangeOptionsType
    | SLiderInputOptionsType
    | any;
  additionalFunctions?: DropdownMultiChoiceFunctions | DatePickerFunctions | DatePickerFunctions | any;
  handleChangeValue: any;
  disabled?: boolean;
  isEditInProcess?: boolean;
  className?: string;
}) => {
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);
  const { textOfErrorMessage, isError } = options;

  useEffect(() => {
    if (isError && textOfErrorMessage) {
      addNotification({ type: 'error', message: textOfErrorMessage });
    }
  }, [isError, textOfErrorMessage]);

  const setType = (type: TableInputType) => {
    const stepperInput = () => (
      <Stepper
        type={type}
        value={value}
        handleChangeValue={(value: number) => {
          handleChangeValue(value);
        }}
        isEditInProcess={isEditInProcess}
        options={options}
      />
    );

    const sliderInput = () => (
      <SliderInput
        type={type}
        value={value}
        handleChangeValue={(value: number | string | null) => {
          handleChangeValue(value);
        }}
        options={options}
        isEditInProcess={isEditInProcess}
      />
    );

    const rangeInput = () => (
      <RangeInput
        type={type}
        values={value}
        handleChangeValue={(value: number[]) => {
          handleChangeValue(value);
        }}
        options={options}
        isEditInProcess={isEditInProcess}
      />
    );

    const timePickerWithUnit = () => (
      <TimePickerUnit
        value={value}
        onChange={(value: string, index: number) => {
          handleChangeValue(value, index);
        }}
        unit={
          type === TableInputType.TIME_PICKER_UNIT_HHMM
            ? 'h & min'
            : type === TableInputType.TIME_PICKER_UNIT_MMSS && 'min'
        }
        type={type}
        isEditInProcess={isEditInProcess}
        options={options}
      />
    );

    const data: any = {
      [TableInputType.TEXTFIELD]: (() => (
        <Textfield
          value={value}
          handleChangeValue={handleChangeValue}
          options={options}
          isEditInProcess={isEditInProcess}
          languageStrings={languageStrings}
          className={className}
        />
      ))(),
      [TableInputType.DROPDOWN]: (() => (
        <Dropdown
          value={value}
          valuesList={valuesList ? valuesList : [{ id: '', name: '' }]}
          handleChangeValue={(value: { id: string; name: string }) => {
            handleChangeValue(value);
          }}
          options={options}
          isEditInProcess={isEditInProcess}
          languageStrings={languageStrings}
          className={className}
        />
      ))(),
      [TableInputType.DROPDOWN_MULTI_CHOICE]: (() => (
        <DropdownMultiChoice
          parameters={valuesList ? valuesList : []}
          selectedParameters={value}
          options={options}
          functions={additionalFunctions}
          isEditInProcess={isEditInProcess}
          languageStrings={languageStrings}
          className={className}
        />
      ))(),
      [TableInputType.DATE_PICKER]: (() => (
        <DatePicker
          globalValue={value}
          handleChangeValue={handleChangeValue}
          options={options}
          functions={additionalFunctions}
          isEditInProcess={isEditInProcess}
          className={className}
        />
      ))(),
      [TableInputType.DATE_RANGE]: (() => (
        <DateRange
          value={value}
          handleChangeValue={handleChangeValue}
          options={options}
          isEditInProcess={isEditInProcess}
          className={className}
        />
      ))(),
      [TableInputType.TOGGLE]: (() => (
        <BooleanSwitcher
          values={valuesList ? valuesList : []}
          value={value}
          onChange={handleChangeValue}
          options={options}
          isEditInProcess={isEditInProcess}
          className={className}
        />
      ))(),
      [TableInputType.TOGGLE_BIG]: (() => (
        <BooleanSwitcher
          values={valuesList ? valuesList : []}
          value={value}
          onChange={handleChangeValue}
          options={options}
          isEditInProcess={isEditInProcess}
          className={className}
        />
      ))(),
      [TableInputType.TIME_PICKER]: (() => (
        <TimePicker
          value={value}
          onChange={(value: string) => {
            handleChangeValue(value);
          }}
          options={options}
          isEditInProcess={isEditInProcess}
          className={className}
        />
      ))(),
      [TableInputType.TIME_RANGE]: (() => (
        <TimePickerRange
          value={value}
          onChange={(value: string[]) => {
            handleChangeValue(value);
          }}
          isEditInProcess={isEditInProcess}
        />
      ))(),
      [TableInputType.TIME_PICKER_UNIT_MMSS]: timePickerWithUnit(),
      [TableInputType.TIME_PICKER_UNIT_HHMM]: timePickerWithUnit(),
      [TableInputType.TIME_PICKER_FIELDS]: (() => (
        <TimePickerFields
          value={value}
          onChange={(value: string, index: number) => {
            handleChangeValue(value, index);
          }}
          isEditInProcess={isEditInProcess}
          options={options}
        />
      ))(),
      [TableInputType.TIME_PICKER_FIELDS_HHMM]: (() => (
        <TimePickerFields
          value={value}
          onChange={(value: string, index: number) => {
            handleChangeValue(value, index);
          }}
          isEditInProcess={isEditInProcess}
          options={{ ...options, isHHMM: true }}
        />
      ))(),
      [TableInputType.DATE_PICKER_WITH_ICON]: (() => (
        <DatePickerWithIcon
          globalValue={value}
          handleChangeValue={handleChangeValue}
          isEditInProcess={isEditInProcess}
          options={options}
          className={className}
        />
      ))(),
      [TableInputType.DATE_RANGE_WITH_ICON]: (() => (
        <DateRangeWithIcon
          value={value}
          handleChangeValue={handleChangeValue}
          isEditInProcess={isEditInProcess}
          options={options}
          className={className}
        />
      ))(),
      [TableInputType.SPECIAL_INTPUT]: (() => <SpecialInput value={value} options={options} />)(),
      [TableInputType.STEPPER]: stepperInput(),
      [TableInputType.STEPPER_WITH_UNIT]: stepperInput(),
      [TableInputType.SLIDER]: sliderInput(),
      [TableInputType.SLIDER_FREE_INPUT]: sliderInput(),
      [TableInputType.SLIDER_FREE_INPUT_WITH_UNIT]: sliderInput(),
      [TableInputType.SLIDER_FREE_INPUT_WITH_UNIT_AND_CHECKBOX]: sliderInput(),
      [TableInputType.SLIDER_RANGE]: rangeInput(),
      [TableInputType.SLIDER_RANGE_FREE_INPUT]: rangeInput(),
      [TableInputType.SLIDER_RANGE_FREE_INPUT_WITH_UNIT]: rangeInput(),
    };
    return data[type];
  };

  return <>{setType(type)}</>;
};
