import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import { useStyleContext } from '@cthings.co/styles-utils';

export const EventView = ({ value, isInDevices }: { value: string; isInDevices?: boolean }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const [{ windowWidth }] = useStyleContext();

  const displayingValue = isInDevices
    ? windowWidth < 425
      ? `${value.split(' ')[0].slice(0, 12)}...`
      : windowWidth < 590
      ? `${value.split(';')[0]}...`
      : windowWidth < 769
      ? `${value.split(';')[0]}, ${value.split(';')[1]}...`
      : value
    : windowWidth < 425
    ? `${value.split(' ')[0].slice(0, 12)}...`
    : windowWidth < 590
    ? `${value.split(' ')[0]}...`
    : windowWidth < 769
    ? `${value.split(' ')[0]}, ${value.split(' ')[1]}...`
    : value;

  return (
    <>
      <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
        {displayingValue}
      </Text>
    </>
  );
};
