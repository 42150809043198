import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';

const Wrapper = styled.div``;

interface PlansProps {}

export const Plans: FC<PlansProps> = ({}) => {
  return <Wrapper>Plans</Wrapper>;
};
