import React, { useState, useEffect, useCallback, useRef } from 'react';
// @ts-ignore
import { Marker } from 'react-map-gl';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type MarkerWrapperProps = {
  zIndex: number;
};

const MarkerWrapper = styled.div<MarkerWrapperProps>`
  & > div {
    z-index: ${({ zIndex }) => zIndex};
    & > svg {
      transform: translate(-23px, -8px);
      & > path {
        stroke: ${colorFetch('primary')};
        fill: ${colorFetch('primary')};
      }
    }
  }
`;

export const CustomMarker = ({ children, setCentre, ...props }: any) => {
  const [zIndex, setZIndex] = useState(1);
  const markerRef = useRef(null);
  const theme = useTheme();

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (zIndex === 2 && !(markerRef as any).current.contains(event.target)) {
        setZIndex(1);
      }
    },
    [zIndex],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  return (
    <MarkerWrapper
      zIndex={zIndex}
      onClick={() => {
        setZIndex(2);

        setCentre && props.latitude && props.longitude && setCentre({ lat: props.latitude, lng: props.longitude });
      }}
      ref={markerRef}
      theme={theme}
    >
      <Marker {...props}>{children}</Marker>
    </MarkerWrapper>
  );
};
