import React from 'react';
import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { InfoIcon } from '../../../../../qualityModal/components/infoIcon/InfoIcon';
import { selectLanguageStrings } from '../../../../../../../../../../../app/state/user';
import { useSelector } from 'react-redux';
import { getRequiredDateFormat } from '../../../../../../../../../../../utils/date/date-format';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
` as any; // @TODO fix type

const StyledModalContainer = styled(ModalContainer)`
  width: 527px !important;
  height: max-content;
  padding: 25px 24px !important;
  display: flex;
  align-items: flex-start !important;
  ${media.phone} {
    width: 100% !important;
    position: fixed !important;
    padding: 24px 16px 18px !important;
    bottom: 0 !important;
    border-radius: 16px 16px 0 0 !important;
  } ;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const InfoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(CommonButton)`
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
  }
`;

export const ProvisioningDateModal = ({
  data,
  closeModal,
  isOpenModal,
}: {
  data: any;
  closeModal: () => void;
  isOpenModal?: boolean;
}) => {
  const theme = useTheme();
  const { black1, gray1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const provisionedBy = data?.user_info;
  const provisionedOn = data?.ts_info;

  const infoArr = [
    {
      fieldName: provisionedBy.label,
      tooltipText: provisionedBy.tooltip,
      value: provisionedBy.value,
    },
    {
      fieldName: provisionedOn.label,
      tooltipText: provisionedOn.tooltip,
      value: provisionedOn.value ? getRequiredDateFormat(provisionedOn.value, 'DD.MM.YYYY') : null,
    },
  ];

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <Text type={TextType.SEMITITLE} color={black1} fontSize={'18px'} weight={'700'} margin={'0 0 6px 0'}>
          {languageStrings.provisioningDetails}
        </Text>
        <InfoWrapper>
          {infoArr.map((item: any, index: number) => {
            const { fieldName, tooltipText, value } = item;
            return (
              <InfoRow key={index}>
                <TooltipWrapper>
                  <Text
                    type={TextType.TEXT_14_BLACK}
                    color={gray1}
                    fontSize={'14px'}
                    weight={'400'}
                    margin={'0 5px 0 0'}
                  >
                    {fieldName ? fieldName : 'N/A'}
                  </Text>
                  <InfoIcon tooltip={tooltipText} />
                </TooltipWrapper>
                <Text type={TextType.TEXT_14_BLACK} color={gray1} fontSize={'14px'} weight={'500'}>
                  {value ? value : 'N/A'}
                </Text>
              </InfoRow>
            );
          })}
        </InfoWrapper>
        <ButtonWrapper>
          <StyledButton width={'104px'} height={'32px'} onClick={closeModal}>
            {languageStrings.closeButtonValue}
          </StyledButton>
        </ButtonWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
