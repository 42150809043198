import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { TableRow } from './components/TableRow';
import { PlaceholderBlock } from './components/placeholderBlock';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type TableProps = {
  borderRadiusTable: boolean;
};

const Table = styled.div<TableProps>`
  border-radius: ${({ borderRadiusTable, theme }) => (borderRadiusTable ? theme.borderRadius.primary : '0')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${colorFetch('gray4')};
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  grid-column-gap: 20px;
  background-color: ${colorFetch('gray4')};
  box-sizing: border-box;
  min-height: 56px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
  padding: 0 25px;
  border-radius: ${({ theme }) => `${theme.borderRadius.primary} ${theme.borderRadius.primary} 0 0`};
  & > div {
    align-self: center;
  }
`;

const TableRows = styled.div`
  background-color: ${colorFetch('gray4')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Pagination = styled.div`
  min-height: 50px;
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  grid-column-gap: 20px;
  background-color: ${colorFetch('gray4')};
  box-sizing: border-box;
  padding: 0 25px;
  border-radius: ${({ theme }) => `0 0 ${theme.borderRadius.primary} ${theme.borderRadius.primary}`};
  & > div {
    align-self: center;
  }
  & > div:nth-child(1) {
    grid-area: 1/1/1 / span 2;
  }
`;

export interface ComponentProps {
  countRows?: number;
  withoutPagination?: boolean;
  borderRadiusTable?: boolean;
}

export const PlaceholderCustomTable: FC<ComponentProps> = ({
  countRows = 7,
  withoutPagination = false,
  borderRadiusTable = true,
}) => {
  const theme = useTheme();

  const renderRows = (val: number): any => {
    return (
      <>
        <TableRow />
        {val < countRows ? <>{renderRows(++val)}</> : null}
      </>
    );
  };

  return (
    <Table theme={theme} borderRadiusTable={borderRadiusTable}>
      <TableHeader>
        <PlaceholderBlock width={'85px'} />
        <PlaceholderBlock width={'95px'} />
        <PlaceholderBlock width={'105px'} />
      </TableHeader>
      <TableRows>{renderRows(1)}</TableRows>
      {!withoutPagination && (
        <Pagination>
          <PlaceholderBlock width={'225px'} />
          <PlaceholderBlock width={'225px'} />
        </Pagination>
      )}
    </Table>
  );
};
