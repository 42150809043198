import React from 'react';

export const FreeFlowImage = () => {
  return (
    <svg width="516" height="278" viewBox="0 0 516 278" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="516" height="278" fill="white" />
      <mask
        id="mask0_3967_232571"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="336"
        y="37"
        width="125"
        height="125"
      >
        <circle cx="398.328" cy="99.3281" r="60.1051" fill="#F9F9FA" stroke="#F2F2F5" stroke-width="3.23512" />
      </mask>
      <g mask="url(#mask0_3967_232571)">
        <circle cx="398.328" cy="99.3281" r="60.1051" fill="#F9F9FA" stroke="#F2F2F5" stroke-width="3.23512" />
        <g filter="url(#filter0_i_3967_232571)">
          <path
            d="M336.016 253.593H460.171C460.171 249.222 460.171 119.927 460.171 113.068C409.958 129.688 403.573 110.406 373.94 108.319C356.601 107.098 336.016 113.068 336.016 113.068C336.016 113.068 336.016 250.912 336.016 253.593Z"
            fill="url(#paint0_linear_3967_232571)"
          />
        </g>
      </g>
      <path
        d="M416.421 98.3281L458.988 98.3281"
        stroke="#72748E"
        stroke-width="1.77364"
        stroke-linecap="round"
        stroke-dasharray="3.55 5.91"
      />
      <path
        d="M338.381 98.3281L380.948 98.3281"
        stroke="#72748E"
        stroke-width="1.77364"
        stroke-linecap="round"
        stroke-dasharray="3.55 5.91"
      />
      <path
        d="M393.385 99.0874C393.385 98.1715 393.572 97.3603 393.948 96.654C394.334 95.9477 394.852 95.4014 395.504 95.0152C396.166 94.6179 396.9 94.4192 397.705 94.4192C398.301 94.4192 398.886 94.5517 399.46 94.8165C400.045 95.0704 400.508 95.4125 400.851 95.8429V91.4395H402.754V103.689H400.851V102.315C400.542 102.757 400.111 103.121 399.559 103.408C399.019 103.695 398.395 103.838 397.689 103.838C396.894 103.838 396.166 103.64 395.504 103.242C394.852 102.834 394.334 102.271 393.948 101.554C393.572 100.826 393.385 100.003 393.385 99.0874ZM400.851 99.1206C400.851 98.4915 400.718 97.9452 400.453 97.4817C400.199 97.0182 399.863 96.6651 399.443 96.4223C399.024 96.1795 398.572 96.0581 398.086 96.0581C397.6 96.0581 397.148 96.1795 396.729 96.4223C396.309 96.654 395.967 97.0016 395.702 97.4652C395.448 97.9176 395.322 98.4584 395.322 99.0874C395.322 99.7165 395.448 100.268 395.702 100.743C395.967 101.217 396.309 101.582 396.729 101.835C397.159 102.078 397.611 102.2 398.086 102.2C398.572 102.2 399.024 102.078 399.443 101.835C399.863 101.593 400.199 101.239 400.453 100.776C400.718 100.301 400.851 99.7496 400.851 99.1206Z"
        fill="#555770"
      />
      <path
        d="M343.642 97.6757L338.771 92.8047C338.19 92.2245 337.198 92.6355 337.198 93.456V103.198C337.198 104.018 338.19 104.429 338.771 103.849L343.642 98.9783C344.001 98.6186 344.001 98.0354 343.642 97.6757Z"
        fill="#72748E"
      />
      <path
        d="M453.727 97.6757L458.598 92.8047C459.179 92.2245 460.171 92.6355 460.171 93.456V103.198C460.171 104.018 459.179 104.429 458.598 103.849L453.727 98.9783C453.368 98.6186 453.368 98.0354 453.727 97.6757Z"
        fill="#72748E"
      />
      <mask id="path-9-inside-1_3967_232571" fill="white">
        <path d="M243.988 138.586L243.988 37.2074L24.9174 37.2074L24.9174 138.586L243.988 138.586Z" />
      </mask>
      <path d="M243.988 138.586L243.988 37.2074L24.9174 37.2074L24.9174 138.586L243.988 138.586Z" fill="#F9F9FA" />
      <g filter="url(#filter1_i_3967_232571)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.5661 136.257H25.5L25.5001 111.204L25.5 91.9767C30.3487 91.5464 34.0529 92.4874 39.104 93.7705C48.5912 96.1805 62.8301 99.7975 98.3295 97.8031C135.657 95.7061 157.066 97.828 172.03 101.312C172.524 101.338 173.01 101.363 173.489 101.387C181.525 101.798 188.066 102.038 193.568 102.24C216.522 103.082 221.412 103.261 241.47 112.385C247.361 115.064 253.712 120.531 259.235 125.286C262.233 127.866 264.986 130.237 267.291 131.838C277.089 138.648 283.377 149.293 290.343 161.086C295.507 169.829 301.044 179.203 308.661 188.112C313.187 193.407 314.817 198.657 316.609 204.428C318.336 209.988 320.213 216.033 324.974 223.07H320.313L300.504 214.913C300.504 214.913 296.286 204.894 279.849 183.611C277.404 180.86 275.848 178.165 274.188 175.292C271.275 170.249 268.044 164.655 259.137 157.232C254.41 152.152 247.197 145.515 241.47 141.667C234.12 139.526 226.399 137.737 218.427 136.257L43.5041 136.257C32.8955 137.581 26.6653 138.588 26.6653 138.588L26.5661 136.257Z"
          fill="url(#paint1_linear_3967_232571)"
        />
      </g>
      <g filter="url(#filter2_i_3967_232571)">
        <rect width="101.845" height="6.19924" transform="matrix(0 -1 -1 0 243.988 238.102)" fill="#8F90A6" />
      </g>
      <g filter="url(#filter3_i_3967_232571)">
        <rect width="212.965" height="6.19924" transform="matrix(1 0 0 -1 25.2437 142.502)" fill="#8F90A6" />
      </g>
      <path
        d="M243.988 39.8642L24.9174 39.8642V34.5506L243.988 34.5506V39.8642ZM24.9174 135.929L243.988 135.929V141.243L24.9174 141.243V135.929Z"
        fill="#F2F2F5"
        mask="url(#path-9-inside-1_3967_232571)"
      />
      <g clip-path="url(#clip0_3967_232571)">
        <path
          d="M201.029 279.982C79.3096 266.252 193.869 220.909 201.029 211.59C208.188 202.27 229.668 224.063 243.988 224.063C258.308 224.063 272.628 211.59 286.948 211.59C301.268 211.59 315.588 224.063 329.907 224.063C344.227 224.063 358.547 211.59 372.867 211.59C387.187 211.59 401.507 224.063 415.827 224.063C430.147 224.063 444.466 211.59 458.786 211.59C473.106 211.59 487.426 224.063 501.746 224.063C516.066 224.063 530.386 211.59 544.706 211.59C559.025 211.59 573.345 224.063 587.665 224.063C601.985 224.063 616.305 211.59 630.625 211.59C644.945 211.59 659.265 224.063 673.585 224.063C687.904 224.063 702.224 211.59 716.544 211.59C730.864 211.59 745.184 224.063 759.504 224.063C773.824 224.063 788.144 211.59 802.463 211.59C816.783 211.59 831.103 224.063 845.423 224.063C859.743 224.063 874.063 211.59 888.383 211.59C902.703 211.59 924.182 210.333 931.342 224.063C938.502 237.792 1053.06 270.662 931.342 279.982"
          fill="url(#paint2_linear_3967_232571)"
        />
        <rect x="457.233" y="189.857" width="38.4539" height="55.933" fill="url(#paint3_linear_3967_232571)" />
        <rect
          width="53.6024"
          height="55.933"
          transform="matrix(-1 0 0 1 297.591 189.857)"
          fill="url(#paint4_linear_3967_232571)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_3967_232571"
          x="336.016"
          y="108.154"
          width="124.155"
          height="145.438"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.66146" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3967_232571" />
        </filter>
        <filter
          id="filter1_i_3967_232571"
          x="25.5"
          y="91.8711"
          width="299.474"
          height="131.199"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.64943" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3967_232571" />
        </filter>
        <filter
          id="filter2_i_3967_232571"
          x="237.789"
          y="136.258"
          width="6.19922"
          height="101.844"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.664204" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.23 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3967_232571" />
        </filter>
        <filter
          id="filter3_i_3967_232571"
          x="25.2437"
          y="136.303"
          width="212.965"
          height="6.19922"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.664204" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.23 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3967_232571" />
        </filter>
        <linearGradient
          id="paint0_linear_3967_232571"
          x1="398.093"
          y1="185.101"
          x2="398.093"
          y2="261.507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B3CBFF" />
          <stop offset="1" stop-color="#6790E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3967_232571"
          x1="304.093"
          y1="193.141"
          x2="-159.805"
          y2="95.0576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D4E1FB" />
          <stop offset="0.0770511" stop-color="#B3CBFF" />
          <stop offset="1" stop-color="#6790E5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3967_232571"
          x1="284.773"
          y1="209.669"
          x2="285.636"
          y2="246.239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D4E1FB" />
          <stop stop-color="#B3CBFF" />
          <stop offset="0.524309" stop-color="#C4D7FF" stop-opacity="0.775894" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3967_232571"
          x1="497.383"
          y1="215.493"
          x2="477.213"
          y2="199.283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3967_232571"
          x1="55.9673"
          y1="25.636"
          x2="29.2645"
          y2="8.20317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_3967_232571">
          <rect width="251.698" height="38.4539" fill="white" transform="translate(243.988 207.338)" />
        </clipPath>
      </defs>
    </svg>
  );
};
