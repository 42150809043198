import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { SpinnerCircle } from '../../features/animations/SpinnerCircle';
import { useTheme } from '@cthings.co/styled-components';

const Background = styled.div`
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(2px);
`;

export interface ComponentProps {
  withoutLoader?: boolean;
}

export const Loader: FC<ComponentProps> = ({ withoutLoader = false }) => {
  const theme = useTheme();

  return <Background>{!withoutLoader && <SpinnerCircle backgroundColor={theme.colors.primary} />}</Background>;
};
