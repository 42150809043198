import React, { useState, useCallback, useEffect } from 'react';
import { useProblems } from '../../../../../api/problems';
import styled from '@cthings.co/styled-components';
import { MAPBOX_TOKEN } from '../../../../../consts';
import { media } from '@cthings.co/styles-utils';
import { ErrorListMap, ErrorListMapType } from '../../../../../components/errorListMap/ErrorListMap';
import { useLoaderFunctions } from '../../../../../features/placeholderComponent/loader.context';
import { DraggableTable } from '../DraggableTable/components/DraggableTable/DraggableTable';
import { use100vh } from 'react-div-100vh';
import { useParams } from '@cthings.co/router-dom';
import { useTheme } from '@cthings.co/styled-components';

const MapContainer = styled.div`
  width: 74%;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  ${media.narrowMonitor} {
    width: 326px;
  }
  ${media.desktop} {
    width: 100%;
    height: 640px;
    margin-top: 20px;
  }
  ${media.tablet} {
    height: 100%;
    max-width: initial;
    margin: 0;
  }
`;

const StyledDraggableTable = styled(DraggableTable)`
  width: -moz-available;
  width: -webkit-fill-available;
`;

type ContentProps = {
  height: number | null;
};

// @TODO fix media types
const Content = styled.div<ContentProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  ${media.desktop} {
    flex-wrap: wrap;
  }
  ${media.tablet} {
    height: ${({ height }: any) => (height ? `calc(${height + 'px'} - 146px)` : `calc(100vh - 146px)`)};
  }
`;

export const TableControl = () => {
  interface centreHook {
    lat: number;
    lon: number;
  }

  const theme = useTheme();

  const [centre, setCentre] = useState<centreHook>({ lat: 52.25603, lon: 20.98765 });
  const [selectedDevice, setSelectedDevice] = useState('');
  const pageSize = 10;
  const pathParams = useParams();
  const pageNumber = +(pathParams['offsetProblems'] || '0') / pageSize + 1;
  const [boundingRect, setBoundingRect] = useState(null);
  const mapRef = useCallback((node: any) => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect());
    }
  }, []);
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = ['ProblemsMap'];
  const height = use100vh();

  const [payload] = useProblems(
    boundingRect,
    pageNumber,
    pageSize,
    selectedDevice,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );
  const devices = payload.map;
  const deviceList = devices.geopivoted;
  const centreLat = devices.centre_coord.lat;
  const centreLon = devices.centre_coord.lng;
  const isEmpty = deviceList.length < 1;
  const [viewport, setViewport] = useState({
    latitude: centre.lat,
    longitude: centre.lon,
    width: '100%',
    height: '100%',
    zoom: devices.zoom_level_mapbox || 12,
    transitionDuration: 1000,
  });

  useEffect(() => {
    !isEmpty && setCentre({ lat: parseFloat(centreLat), lon: parseFloat(centreLon) });
  }, [centreLat, centreLon, isEmpty]);

  const handleSetCentre = (centre: any) => {
    setCentre({ lat: parseFloat(centre.lat), lon: parseFloat(centre.lng) });
  };

  return (
    <Content height={height}>
      <StyledDraggableTable
        pageSize={pageSize}
        boundingRect={boundingRect}
        selectedDevice={selectedDevice}
        setViewport={setViewport}
        setSelectedDevice={setSelectedDevice}
      />
      <MapContainer ref={mapRef} theme={theme}>
        {devices.zoom_level_mapbox !== -1 && (
          <ErrorListMap
            placeholderProps={{
              keyword: 'ProblemsMap',
              style: { width: '100%', height: '100%' },
            }}
            type={ErrorListMapType.DASHBOARD}
            zoom={devices.zoom_level_mapbox}
            latitude={centre.lat}
            longitude={centre.lon}
            centerCoords={centre}
            staticDisplay
            deviceList={deviceList}
            setCentre={handleSetCentre}
            mapToken={MAPBOX_TOKEN}
            viewport={viewport}
            setViewport={setViewport}
            setSelectedDevice={setSelectedDevice}
          />
        )}
      </MapContainer>
    </Content>
  );
};
