import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { SelectDevice } from '../../../../../../../../../components/selectDevice/SelectDevice';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  box-shadow: ${({ theme }) => theme.shadows.primary};
`;

type SelectModuleProps = {
  isOpen: any;
  isFirst: boolean;
  isLast: boolean;
};

const SelectModule = styled(SelectDevice)<SelectModuleProps>`
  min-height: 45px;
  & > div {
    min-height: 40px;
    border-radius: ${({ isFirst, isLast, isOpen }) =>
      isFirst ? '8px 8px 0 0 ' : isLast && isOpen ? 0 : isLast ? '0 0 8px 8px' : '0'};
  }
  ${media.tablet} {
    width: 100%;
  }
` as any; // @TODO fix type

const WrapperItems = styled.div``;

type SelectItemProps = {
  isLastOpened: boolean;
};

const SelectItem = styled.div<SelectItemProps>`
  box-sizing: border-box;
  background-color: ${colorFetch('white')};
  z-index: 99;
  top: 45px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: ${({ isLastOpened }) => (isLastOpened ? '0 0 8px 8px' : '0')};
`;

type WrapperProps = {
  isLastOpened: boolean;
  isFirst: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: grid;
  text-overflow: ellipsis;
  height: max-content;
  grid-template-columns: 1.25fr 1.5fr;
  grid-gap: 24px;
  padding: 11px 22px 10px 22px;
  box-sizing: border-box;
  background-color: ${colorFetch('gray6')};
  border-radius: ${({ isLastOpened }) => (isLastOpened ? '0 0 8px 8px' : '0')};
  border-top: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-bottom: none;
  border-color: ${colorFetch('gray4')};
  /* background-color: ${colorFetch('gray5')}; */

  ${media.phone} {
    height: max-content;
    padding: 13px 16px;
  }
`;

const WrapperText = styled(Text)`
  color: ${colorFetch('gray1')};
  word-break: break-all;
`;

export interface ComponentProps {
  newStructureRadioModule: any;
}

const changeStatusPressedModule = (dataRadioModule: any, pressedModule: any) => {
  return dataRadioModule.map((radioModule: any) => {
    if (radioModule.module === pressedModule) {
      return { ...radioModule, isShow: true };
    }
    return { ...radioModule, isShow: false };
  });
};

export const InfoContentTable: FC<ComponentProps> = ({ newStructureRadioModule }) => {
  const [dataRadioModule, setDataRadioModule] = useState(newStructureRadioModule);
  const theme = useTheme();

  useEffect(() => {
    setDataRadioModule(newStructureRadioModule);
  }, [newStructureRadioModule]);

  return (
    <TableWrapper theme={theme}>
      <WrapperItems>
        {dataRadioModule.map((item: any, indexDataRadioModule: any) => {
          const { isShow, module, propertysRadioModule } = item;
          return (
            <>
              <SelectModule
                isOpen={isShow}
                isFirst={indexDataRadioModule === 0 ? true : false}
                isLast={dataRadioModule.length - 1 === indexDataRadioModule ? true : false}
                valueTitle={module}
                label={''}
                disabled={false}
                onChange={(value: any) => {
                  const pressedModule = value.currentTarget.getElementsByTagName('span')[0].innerText;
                  const updatedModuleData = changeStatusPressedModule(dataRadioModule, pressedModule);
                  setDataRadioModule(() => updatedModuleData);
                }}
                isWide={TextTrackCue}
              />
              {isShow && (
                <SelectItem
                  theme={theme}
                  isLastOpened={isShow && dataRadioModule.length - 1 === indexDataRadioModule ? true : false}
                >
                  {propertysRadioModule.length
                    ? propertysRadioModule.map((item: any, index: number) => {
                        const { value, property } = item;
                        return (
                          <Wrapper
                            isFirst={index === 0}
                            isLastOpened={
                              isShow && propertysRadioModule.length - 1 === index && indexDataRadioModule !== 0
                                ? true
                                : false
                            }
                          >
                            <WrapperText type={TextType.TEXT_14_BLACK} weight={'400'}>
                              {property}
                            </WrapperText>
                            <WrapperText type={TextType.TEXT_14_BLACK}>{value || 'N/A'}</WrapperText>
                          </Wrapper>
                        );
                      })
                    : 'data is empty'}
                </SelectItem>
              )}
            </>
          );
        })}
      </WrapperItems>
    </TableWrapper>
  );
};
