import React, { FC, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { Text, TextType } from '@bit/first-scope.text';
import { CommentItem } from './components/CommentItem';
import { media } from '@cthings.co/styles-utils';
import disabledImage from './assets/arrow-up-circle.svg';
import undisabledImage from './assets/arrow-up-circle-colored.svg';
import disabledEditImage from './assets/ok-circle.svg';
import undisabledEditImage from './assets/ok-circle-colored.svg';
import cancelImage from './assets/Light.svg';
import commentPlaceholder from './assets/comment-placeholder.svg';
import { DeletePopup } from '../deletePopup/DeletePopup';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  max-width: 590px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CommentContent = styled.div`
  height: 276px;
  display: flex;
  flex-direction: column;
`;

const CommentHistory = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  flex: 1;
  padding: 16px 4px 16px 24px;
  background-color: ${colorFetch('white')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow2};
  margin-bottom: 12px;
  ${media.tablet} {
    padding: 12px 4px 12px 16px;
  }
`;

const ContentHistory = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 0;
  & > div:first-child {
    padding-top: 0;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
  ${media.tablet} {
    padding: 0 8px 0 0;
  }
`;

const CommentsPlaceholder = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CommentsPlaceholderImage = styled.img`
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  margin-right: 4px;
`;

const CommentsPlaceholderText = styled.span`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${colorFetch('gray2')};
`;

type CommentBlockProps = {
  isEdit: boolean;
};

const CommentBlock = styled.div<CommentBlockProps>`
  display: flex;
  flex-direction: ${({ isEdit }) => (isEdit ? 'column' : 'row')};
  align-items: ${({ isEdit }) => (isEdit ? 'flex-start' : 'center')};
  background-color: ${colorFetch('gray4')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 8px 62px 8px 12px;
  position: relative;
`;

type CommentTextareaProps = {
  isEdit?: boolean;
};

const CommentTextarea = styled(TextareaAutosize)<CommentTextareaProps>`
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: ${({ isEdit }) => (isEdit ? '72' : '112')}px;
  overflow: overlay;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  outline: transparent;
  resize: none;
  box-sizing: border-box;
  color: ${colorFetch('gray1')};
  &::-webkit-input-placeholder {
    color: ${colorFetch('gray2')};
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
    margin: 15px 0;
  }
  ${media.tablet} {
    font-size: 16px;
  }
`;

const SubmitButton = styled.button`
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: pointer;
`;

const SubmitImage = styled.img`
  width: 100%;
  height: 100%;
`;

const CancelButton = styled.button`
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 16px;
  right: 10px;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: pointer;
`;

const CancelImage = styled.img`
  width: 100%;
  height: 100%;
`;

const EditButton = styled.button`
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 18px;
  right: 6px;
  transform: translate(0, 50%);
  background-color: transparent;
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: pointer;
`;

const EditImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Label = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${colorFetch('primary')};
  margin-bottom: 4px;
`;
const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

// @TODO fix media types

const StyledModalContainer = styled(ModalContainer)`
  max-width: 384px;
  ${media.phone} {
    width: 100%;
    max-width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: ${({ theme }: any) => `${theme.borderRadius.additional16} ${theme.borderRadius.additional16} 0 0`};
  }
`;
const Popup = styled(DeletePopup)`
  width: 100%;
  & > ul {
    margin: 0 0 20px 0;
  }
`;

export interface CommentsProps {
  value?: string;
  onSubmit: () => void;
  onChange: (el: any) => void;
  onEdit: (el1: any, el2: any) => any;
  onDelete: (el: any) => void;
  languageStrings?: any;
  className?: string;
  commentsList?: any;
}

export interface EditCommentType {
  valueComment: string;
  editComment: string;
  id: number | '';
}

export const Comments: FC<CommentsProps> = ({
  value = '',
  onSubmit,
  onChange,
  onEdit,
  onDelete,
  languageStrings,
  commentsList,
  ...props
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;
  const [comments, setComments] = useState<any[]>([]);
  const [edit_comment, setEdit_Comment] = useState<EditCommentType>({
    valueComment: '',
    editComment: '',
    id: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [idOfDeletedComment, setIdOfDeletedComment] = useState('');

  const image = value !== '' ? undisabledImage : disabledImage;
  const editImage = edit_comment.valueComment !== edit_comment.editComment ? undisabledEditImage : disabledEditImage;

  const handlerEditComment = () => {
    if (edit_comment.editComment !== edit_comment.valueComment) {
      if (edit_comment.editComment !== '') {
        onEdit(edit_comment.id, edit_comment.editComment);
        setIsEdit(false);
        setEdit_Comment({
          valueComment: '',
          editComment: '',
          id: '',
        });
      } else {
        onDelete(edit_comment.id);
        setIsEdit(false);
        setEdit_Comment({
          valueComment: '',
          editComment: '',
          id: '',
        });
      }
    } else {
      setIsEdit(false);
      setEdit_Comment({
        valueComment: '',
        editComment: '',
        id: '',
      });
    }
  };
  const handlerOnChangeEditComment = (e: any) => {
    const newComment = e.currentTarget.value;
    setEdit_Comment((el: EditCommentType) => ({ ...el, editComment: newComment }));
  };

  useEffect(() => {
    if (commentsList) {
      let newComments: any[] = [...commentsList];
      setComments(newComments);
    }
  }, [commentsList]);

  return (
    <Wrapper {...props}>
      <Text type={TextType.SEMITITLE} margin={'0 0 20px 0'} color={gray2}>
        {languageStrings.insightDetailsCommentsTitle}
      </Text>
      <CommentContent>
        <CommentHistory theme={theme}>
          <ContentHistory>
            {comments.length ? (
              <>
                {comments.map((el: any, index: number) => (
                  <CommentItem
                    key={el._id}
                    id={el._id}
                    textComment={el.text}
                    dateCom={el.created_at}
                    author={el.author_name}
                    updateDate={el.updated_at}
                    permissions={el.permissions}
                    onEdit={setIsEdit}
                    isEdit={isEdit}
                    onDelete={(id: string) => {
                      setIsOpenDeletePopup(true);
                      setIdOfDeletedComment(id);
                    }}
                    edit_comment={edit_comment}
                    setEdit_Comment={setEdit_Comment}
                    className={index === comments.length - 1 ? 'last' : ''}
                  />
                ))}
              </>
            ) : (
              <CommentsPlaceholder>
                <CommentsPlaceholderImage src={commentPlaceholder} alt={'message'} />
                <CommentsPlaceholderText>{languageStrings.commentsPlaceholder}</CommentsPlaceholderText>
              </CommentsPlaceholder>
            )}
          </ContentHistory>
        </CommentHistory>
        <CommentBlock theme={theme} isEdit={isEdit}>
          {isEdit ? (
            <>
              <Label>{languageStrings.commentTableEdit}</Label>
              <CommentTextarea
                isEdit={isEdit}
                placeholder={languageStrings.commentTableEditPlaceholder}
                value={edit_comment.editComment}
                onChange={(e: any) => handlerOnChangeEditComment(e)}
              />
              <CancelButton
                onClick={() => {
                  setIsEdit(false);
                  setEdit_Comment({
                    valueComment: '',
                    editComment: '',
                    id: '',
                  });
                }}
              >
                <CancelImage src={cancelImage} alt="submit" />
              </CancelButton>
              <EditButton
                onClick={handlerEditComment}
                disabled={edit_comment.editComment === edit_comment.valueComment}
              >
                <EditImage src={editImage} alt="submit" />
              </EditButton>
            </>
          ) : (
            <>
              <CommentTextarea
                placeholder={languageStrings.commentTablePlaceholder}
                value={value}
                onChange={(e: any) => onChange(e)}
              />
              <SubmitButton onClick={() => onSubmit()} disabled={value === ''}>
                <SubmitImage src={image} alt="submit" />
              </SubmitButton>
            </>
          )}
        </CommentBlock>
        <StyledModal isOpenModal={isOpenDeletePopup}>
          <StyledModalContainer>
            <Popup
              title={languageStrings.emphasizedHeaderTextModalTitle}
              semiTitle={languageStrings.deletingComment}
              handleOnClose={() => {
                setIsOpenDeletePopup(false);
              }}
              handleOnDelete={() => {
                setIsOpenDeletePopup(false);
                onDelete(idOfDeletedComment);
              }}
              languageStrings={languageStrings}
            />
          </StyledModalContainer>
        </StyledModal>
      </CommentContent>
    </Wrapper>
  );
};

Comments.defaultProps = {
  value: '',
};
