import { isString } from 'underscore';

const encodedAmpersand = encodeURIComponent('&');

export const urlEncodeArray = (params: (string | number | boolean)[]) => {
  try {
    return params.reduce((encodedString, param, index) => {
      if (isString(param) && param.indexOf('&') > -1) {
        throw new Error('Ampersand sign (&) is not allowed in strings that need to be url encoded inside of array');
      }

      return encodedString + encodeURIComponent(param) + (index !== params.length - 1 ? encodedAmpersand : '');
    }, '');
  } catch (e) {
    return '';
  }
};
