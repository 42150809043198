import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as CrossIcon } from '../../assets/Stroke30.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Round = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
  appearance: none;
  background-color: ${colorFetch('gray5')};
  transition: 0.3s;
  &:hover,
  &:focus {
    background-color: ${colorFetch('gray6')};
  }
`;

type CloseWhiteRoundButtonProps = {
  onClick: () => void;
  className?: string;
};

export const CloseWhiteRoundButton: React.FC<CloseWhiteRoundButtonProps> = ({ onClick, ...props }) => {
  return (
    <Round onClick={onClick} {...props}>
      <CrossIcon />
    </Round>
  );
};
