import React, { ChangeEvent, useEffect } from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { InfoButton } from '../../../../../../components/infoButton/InfoButton';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';
import { TooltipPlace } from '../../../../../../components/tooltip/Tooltip';
import { InputModal } from '../../../../../../components/inputModal/InputModal';
import { ReactComponent as SearchIcon } from '../../../../../../assets/Search.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 12px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 36px;
  ${media.tablet} {
    margin-bottom: 12px;
  }
`;

const StyledTitle = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  ${media.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledInput = styled(InputModal)`
  max-width: 536px;
  width: 100%;
  ${media.tablet} {
    margin: 0;
  }
`;

export const Title = ({
  withSmartSearch,
  searchValue,
  setSearchValue,
}: {
  withSmartSearch?: boolean;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const [media] = useStyleContext();
  const { tablet, semiTablet, phone } = media.mediaType;

  const href =
    'https://cthingsco.freshdesk.com/en/support/solutions/articles/103000019825-flow-masurement-pairs-based-on-manning-s-equation ';

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue && setSearchValue(e.currentTarget.value);
  };

  useEffect(() => {
    searchValue && setSearchValue && setSearchValue(searchValue);
  }, []);

  return (
    <Wrapper>
      <TitleWrapper>
        <StyledTitle type={TextType.TITLE_H1}>{languageStrings.measurementTitle}</StyledTitle>
        <InfoButton
          onClick={() => {}}
          color={gray2}
          link={href}
          margin={phone ? '0 0 0 12px' : '0 0 0 8px'}
          tooltipText={languageStrings.pressToLearnMoreTooltip}
          tooltipPlace={semiTablet ? TooltipPlace.RIGHT : TooltipPlace.BOTTOM}
        />
      </TitleWrapper>
      {withSmartSearch && (
        <StyledInput
          inputHeight={tablet ? '32px' : '40px'}
          margin={'0 0 25px'}
          value={searchValue ?? ''}
          onChange={onInputChange}
          placeholder={languageStrings.whatAreYouLookingFor}
          image={<SearchIcon />}
        />
      )}
    </Wrapper>
  );
};
