import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as CloseIcon } from '../../../../../assets/Close.svg';
import { CommonButton } from '../../../../../components/commonButton/CommonButton';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div``;

const StyledCloseIcon = styled(CloseIcon)``;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipMobileModal = ({
  value,
  setModalClose,
  languageStrings,
}: {
  value?: any;
  setModalClose: (props: any) => void;
  languageStrings: any;
}) => {
  const theme = useTheme();
  const { pureWhite, black1, gray1 } = theme.colors;

  const handleClick = () => {
    setModalClose(false);
  };

  const backButtonText = languageStrings.insightDetailsBack;
  const tooltipText = languageStrings.tooltipAddDevicesManually;

  return (
    <Wrapper>
      <TitleRow>
        <Text
          type={TextType.TEXT_14_BLACK}
          color={black1}
          fontSize={'18px'}
          weight={'600'}
          lineHeight={'24px'}
          margin={'0 0 16px 0'}
        >
          {value}
        </Text>
        <StyledCloseIcon onClick={handleClick} />
      </TitleRow>
      <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'} lineHeight={'22px'}>
        {tooltipText}
      </Text>
      <CommonButton
        width={'342px'}
        height={'40px'}
        fontSize={'14px'}
        backgroundColor={pureWhite}
        color={black1}
        margin={'24px 0 0 0'}
        onClick={handleClick}
      >
        {backButtonText}
      </CommonButton>
    </Wrapper>
  );
};
