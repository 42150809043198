import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { InfoButton } from '../../../../components/infoButton/InfoButton';
import { Select } from '../../../../components/universalSelect/Select';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const HeadingBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: ${colorFetch('black')};
` as any; // @TODO fix type

export const StepValue = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: right;
  color: ${colorFetch('gray2')};
` as any; // @TODO fix type

export const Description = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  margin-top: 15px !important;
` as any; // @TODO fix type

export const StyledSelect = styled(Select)`
  margin: 0 0 14px 0;
`;

export const Details = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  margin-top: 5px !important;
` as any; // @TODO fix type

export const InputsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 20px;
  margin-top: 15px;

  & > div:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  ${media.phone} {
    display: flex;
    flex-direction: column;
  }
`;

export const InputsContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;

  ${media.semiTablet} {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const BackButton = styled(CommonButton)`
  width: 115px;
  margin-right: 15px;
  background-color: ${colorFetch('pureWhite')};
  color: ${colorFetch('black1')};
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
    margin-right: 0;
  }
`;

export const NextButton = styled(CommonButton)`
  width: 115px;
  ${media.semiTablet} {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
`;

type TooltipProps = {
  leftOffset: number;
};

export const Tooltip = styled(InfoButton)<TooltipProps>`
  position: absolute;
  & > div > svg {
    width: 20px;
    height: 20px;
  }
  top: -3px;
  left: ${({ leftOffset }) => `calc(${leftOffset}px + 8px)`};
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
`;
