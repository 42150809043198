import { useTheme } from '@cthings.co/styled-components';
import { media, useMediaType } from '@cthings.co/styles-utils';
//@ts-ignore
import styled from '@xstyled/styled-components';
import { RowItemType, UniversalTable, useTableFunctions, withTableContextProvider } from '@cthings.co/universal-table';
import { HttpService } from '@cthings.co/utils';
import React, { useEffect, useState } from 'react';
import { useParams } from '@cthings.co/router-dom';
import { useSelector } from 'react-redux';
import { selectAccess, selectLanguage, selectLanguageStrings } from '../../../../../../../app/state/user';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { API_URL } from '../../../../../../../consts';
import { EditAction, UniversalTableInput } from '../../../../../../../components/universalTableInput';
import { View } from '../../../../../../../routes';
import { Text, TextType } from '@bit/first-scope.text';
import { updateDeviceConfigurationTable } from '../../../../../../../api/manage/manageDevices';
import { PropertyWithTooltip } from './components/propertyWithTooltip/PropertyWithTooltip';
import { useQueryParams } from '../../../../../../../utils/useQueryParams';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleBlock = styled(Text)``;

const DeviceConfigurationUTPlain = () => {
  const theme = useTheme();

  const { orange2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);
  const { shortName } = useSelector(selectLanguage);
  const accessData = useSelector(selectAccess).management.devices;

  const pathParams = useParams();
  const queryParams = useQueryParams() || {};
  const id = pathParams.id;

  const keyword = 'InsightDetailsDeviceConfigurationTable';
  const offsetKey = 'offset';

  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };

  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const { resetTable, updateLocalItem, setOpenIndex } = useTableFunctions();

  const { ultramonitor, monitor, tablet } = useMediaType();

  const [rowInEdit, setRowInEdit] = useState('');
  const [savedData, setSavedData] = useState('');

  const handleRevert = (savedData: any, index: number) => updateLocalItem(index, savedData);

  const { addNotification } = useNotifierFunctions();

  const handleAdd = () => {};

  const handleStart = (data: any, index: number) => {
    setSavedData(data);
    setRowInEdit(data.id);
    setOpenIndex(index);
  };

  const handleConfirm = (data: any, savedData: any, index?: number) => {
    updateDeviceConfigurationTable({
      id: id || '',
      field_id: data.id,
      body: { value: data.value.actual_value.value ?? data.value.actual_value },
      handleError: (data: any) => {
        addNotification({
          message: data?.response?.data?.detail ?? 'Something went wrong!',
          type: 'error',
        });
        handleRevert(savedData, index ?? -1);
        setSavedData('');
        setRowInEdit('');
        setOpenIndex(-1);
      },
      handleSuccess: (data: any) => {
        addNotification({
          message: languageStrings.successSaveMessage,
          type: 'success',
        });
        setSavedData('');
        setRowInEdit('');
        setOpenIndex(-1);
        // setUpdateCounter((prev: number) => {
        //   return prev + 1;
        // });
        updateLocalItem(index, data.payload);
      },
    });
  };

  const handleCancel = (savedData: any, index?: number) => {
    setOpenIndex(-1);
    handleRevert(savedData, index ?? -1);
    setRowInEdit('');
  };

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper>
      <TitleBlock type={TextType.SEMITITLE} margin={'0 0 20px 0'}>
        {languageStrings.insightDetailsDeviceConfigTitle}
      </TitleBlock>
      <UniversalTable
        HttpService={HttpService}
        theme={theme}
        language={shortName}
        isPaginationEnabled={false}
        placeholderProps={{
          keyword,
          placeholderOptions: { withoutPagination: true },
          conditionalStyle: { height: '100%', overflow: 'hidden', borderRadius: '8px' },
        }}
        mainApiUrl={`${API_URL}/v2/devices/${id}/config_params`}
        customRowClick={() => {}}
        apiTriggers={[shortName]}
        keyword={keyword}
        offset={+offset}
        offsetKey={offsetKey}
        inlineActionSet={[{ preset: 'EDIT_ACTION' } as any]}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        pathParams={pathParams}
        queryParams={queryParams}
        tablePath={View.BILLING_INVOICES}
        accessData={accessData}
        actionSectionGridWidth={'66px'}
        presetMapping={{
          component: {
            EDITABLE_INPUT: (
              value: any,
              state: any,
              setValue?: any,
              saveToggle?: boolean,
              id?: string,
              index?: number,
            ) => {
              return (
                <UniversalTableInput
                  value={value}
                  state={state}
                  setField={setValue}
                  isEditInProcess={rowInEdit === id}
                  saveToggle={saveToggle}
                  highlightValue={value.with_requested && orange2}
                />
              );
            },
            TOOLTIP: (value: any) => {
              return <PropertyWithTooltip data={value} />;
            },
          },
          inline: {
            EDIT_ACTION: (data: any, index: number) => {
              const isEditInProcess = rowInEdit === data.id;

              return (
                <EditAction
                  data={data}
                  index={index}
                  isEditInProcess={isEditInProcess}
                  handleStart={handleStart}
                  handleConfirm={handleConfirm}
                  handleCancel={handleCancel}
                  handleAdd={handleAdd}
                  savedData={savedData}
                />
              );
            },
          },
        }}
        columnStructureTriggers={[ultramonitor, monitor, tablet, rowInEdit]}
        columnStructure={[
          {
            fieldName: 'property',
            placeholder: languageStrings.deviceInfoLabelProperty,
            label: languageStrings.deviceInfoLabelProperty,
            defaultValue: '',
            width: tablet ? '1fr' : monitor ? '1fr' : '1fr',
            type: RowItemType.CUSTOM,
            component: { preset: 'TOOLTIP' },
          },
          {
            fieldName: 'value',
            placeholder: languageStrings.insightDetailsDeviceConfigHeaderValue,
            label: languageStrings.insightDetailsDeviceConfigHeaderValue,
            width: tablet ? '1fr' : '1fr',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: { preset: 'EDITABLE_INPUT' },
          },
        ]}
      />
    </Wrapper>
  );
};

export const DeviceConfigurationUT = withTableContextProvider(DeviceConfigurationUTPlain);
