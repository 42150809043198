import { Address } from '../../types/address';

export const checkAddressReadiness = (address: Address, isGeoOnly = false) => {
  const { geotag, country, region, city, line1, line2, zip } = address;

  const geoCondition = parseFloat(geotag.lat) !== 0 || parseFloat(geotag.lng) !== 0;

  const fieldCondition = !!country || !!region || !!city || !!line1 || !!line2 || !!zip;

  return geoCondition && (isGeoOnly || fieldCondition);
};
