/**
 * Custom axis tick for the Axis component from "Recharts" library
 */

import React from 'react';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type TickType = {
  color?: string;
};

export const Tick = styled.div<TickType>`
  height: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${({ color, theme }) => color || colorFetch('gray2')({ theme })};
`;

type LineProps = {
  width: number;
  background?: string;
};

export const Line = styled.div<LineProps>`
  height: 1px;
  width: ${({ width }) => `${width}px`};
  background-color: ${({ background, theme }) => background || colorFetch('gray5')({ theme })};
`;

export enum YAxisTickType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export const CustomYAxisTick = ({
  type = YAxisTickType.DEFAULT,
  value,
  lineWidth,
  setTickText,
  color,
  lineColor,
}: {
  value: any;
  lineWidth: number;
  lineColor?: string;
  color?: string;
  type?: YAxisTickType;
  setTickText?: (value: any) => string | number;
}) => {
  const { y, payload } = value;

  const tickText = setTickText ? setTickText(value) : payload.value;

  return (
    <foreignObject x={20} y={y - 14.5} width={'100%'} height={20}>
      <Tick color={color}>{tickText}</Tick>
      {tickText && <Line background={lineColor} width={lineWidth} />}
    </foreignObject>
  );
};
