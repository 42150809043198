import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton, ButtonIconType } from '@cthings.co/buttons';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { useMediaType } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled(Text)`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const LinkAccountButton = styled(CommonButton)`
  width: max-content;
  padding: 0 10px;
  border-radius: ${({ borderRadius }: any) => borderRadius};
`;

const LinkPaymentButton = styled(LinkAccountButton)``;

interface TitleBlockPropsType {
  isLinkAccountShown: boolean;
  isLinkPaymentShown: boolean;
  linkAccount: () => void;
  linkPaymentMethod: () => void;
}

export const TitleBlock: FC<TitleBlockPropsType> = ({
  isLinkAccountShown,
  isLinkPaymentShown,
  linkAccount,
  linkPaymentMethod,
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;
  const { tablet } = useMediaType();
  const languageStrings = useSelector(selectLanguageStrings);

  const linkAccountButtonText = !tablet ? languageStrings.linkAccount : '';
  const linkPaymentButtonText = !tablet ? languageStrings.linkPaymentMethod : '';

  return (
    <Wrapper>
      <Title type={TextType.TITLE_H1} color={black1}>
        {languageStrings.billingDetails}
      </Title>
      <ButtonsWrapper>
        {isLinkAccountShown && (
          <LinkAccountButton
            height="32px"
            iconType={ButtonIconType.ATTACH}
            onClick={linkAccount}
            iconWithoutText={tablet}
          >
            {linkAccountButtonText}
          </LinkAccountButton>
        )}
        {isLinkPaymentShown && (
          <LinkPaymentButton
            height="32px"
            iconType={ButtonIconType.ATTACH}
            onClick={linkPaymentMethod}
            iconWithoutText={tablet}
          >
            {linkPaymentButtonText}
          </LinkPaymentButton>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};
