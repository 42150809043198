import { parse } from 'query-string';
import { useLocation } from '@cthings.co/router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();

  const queryParams = search ? (parse(search) as { [x: string]: string }) : undefined;

  return queryParams;
};
