/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings tables require.
 * Highly customisable.
 * Background logic is contained in context.tsx
 *
 */

import styled from '@cthings.co/styled-components';
import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../app/state/user';
import { PlaceholderType } from '../../components/placeholders/typePlaceholders/placeholdersType';
import { View } from '../../routes/routeInterfaces';
import { useWindowSize } from '@cthings.co/styles-utils';
import { withLoader } from '../placeholderComponent/loaderFunctions';
import { Head } from './components/head/Head';
import { ManagePagination } from './components/managePagination';
import { ModalSystem } from './components/modalSystem/ModalSystem';
import { NoDataPlaceholder } from './components/noDataPlaceholder/NoDataPlaceholder';
import { Row } from './components/row/Row';
import { useTableContext, useTableFunctions } from './context';
import {
  ActionComponent,
  ActionConfig,
  ColumnStructure,
  InlineActionConfig,
  ModalApi,
  ModalColumnCount,
  RowItemType,
  SortingData,
} from './types';
// @ts-ignore
import _ from 'underscore';
import { useTheme } from '@cthings.co/styled-components';

const MainWrapper = styled.div`
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow2};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
`;

const BackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  z-index: 1;
`;

type ScrollableWrapperProps = {
  isScrollableRows?: boolean;
  height: number;
};

const ScrollableWrapper = styled.div<ScrollableWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: ${({ isScrollableRows, height }) => (isScrollableRows ? `${height}px` : 'max-content')};
  overflow-y: ${({ isScrollableRows }) => (isScrollableRows ? 'auto' : 'visible')};
`;

export const noSelectionParam = 'noselection';

export interface UniversalTableProps {
  updateItems?: any;
  mobileHeadless?: boolean;
  deleteItems?: any;
  fieldNameOfDeletedItems?: string;
  onPageChange?: any;
  pageSize?: number;
  columnStructure: ColumnStructure[];
  allowDelete?: boolean;
  allowSelect?: boolean;
  selectComponent?: (selectedList: any[]) => ReactNode;
  titleActionComponent?: ReactNode;
  actionSet?: ActionConfig[];
  inlineActionSet?: InlineActionConfig[];
  mobileLimit?: number;
  modalColumnCount?: ModalColumnCount;
  customRowClick?: (value: any) => void;
  modalApi?: ModalApi;
  dynamicFlags?: boolean[];
  keyword?: string;
  additionalKeywords?: string[];
  mainApiUrl?: string;
  page?: number;
  eventNum?: any;
  offset?: number;
  tablePath?: typeof View[keyof typeof View];
  pathParams?: any;
  queryParams?: any;
  offsetKey?: any;
  columnStructureTriggers?: any[];
  apiTriggers?: any[];
  idItemForViewModal?: string;
  banAutoFocus?: boolean;
  withHeaderInMobile?: boolean;
  tableAddToggle?: any;
  withHeaderGridTemplate?: string;
  indexOfPayloadItem?: string;
  withoutQuestionMarkBeforeLimit?: boolean;
  isScrollableRows?: boolean;
  rowRef?: any;
  handleRowOnClick?: (e: any) => void;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  sortingData?: SortingData;
  sortingKeys?: SortingData;
  setIsDataLoaded?: (props: boolean) => void;
  keywordAddModalGlobalSearch?: string;
  actionSectionGridWidth?: string;
  isHrefDetailsClick?: boolean;
}

const UniversalTablePlain: FC<UniversalTableProps> = ({
  updateItems,
  mobileHeadless,
  deleteItems,
  fieldNameOfDeletedItems,
  onPageChange,
  pageSize = 12,
  columnStructure,
  allowDelete,
  allowSelect,
  selectComponent,
  titleActionComponent,
  actionSet = [],
  inlineActionSet = [],
  mobileLimit = 4,
  modalColumnCount,
  customRowClick,
  modalApi,
  dynamicFlags,
  keyword,
  additionalKeywords = [],
  mainApiUrl,
  offset,
  tablePath,
  pathParams = {},
  queryParams = {},
  offsetKey,
  columnStructureTriggers = [],
  apiTriggers = [],
  idItemForViewModal,
  banAutoFocus,
  withHeaderInMobile,
  withHeaderGridTemplate,
  tableAddToggle = [],
  indexOfPayloadItem,
  withoutQuestionMarkBeforeLimit,
  isScrollableRows,
  rowRef,
  handleRowOnClick,
  accessData,
  sortingData = { sort_field: '', sort_type: '' },
  sortingKeys = { sort_field: 'sort_field', sort_type: 'sort_type' },
  keywordAddModalGlobalSearch,
  setIsDataLoaded,
  actionSectionGridWidth = '1fr',
  isHrefDetailsClick,
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage).shortName;
  const windowWidth = useWindowSize()[0];

  const [{ highlightedIndex, list, isAddInProcess }] = useTableContext();

  const {
    setGlobalProps,
    setHighlightedIndex,
    setPage,
    triggerSave,
    setSortingData,
    getReadyState,
    startAddProcess,
  } = useTableFunctions();
  const [heightOfScrollableRow, setHeightOfScrollableRow] = useState(0);
  const localRowRef = useRef<HTMLDivElement>(null);
  const heightOfRow = rowRef ? rowRef.current?.clientHeight : localRowRef?.current?.clientHeight;
  const windowHeight = useWindowSize()[1];

  const [isPendingRequestFromModalApi, setIsPendingRequestFromModalApi] = useState(false);
  const [columnStructureTriggerCounter, setColumnStructureTriggerCounter] = useState(1);
  const triggerColumnStructure = () => setColumnStructureTriggerCounter((val) => val + 1);

  const maxHeightOfScrollableWrapper = windowHeight - 252;
  const heightOfScrollableWrapper =
    list.slice(0, pageSize).length * heightOfScrollableRow > maxHeightOfScrollableWrapper
      ? maxHeightOfScrollableWrapper
      : list.slice(0, pageSize).length * heightOfScrollableRow;

  const pathArray = Object.keys(pathParams).reduce((acc: any[], key: any) => [...acc, pathParams[key]], []);
  const queryArray = Object.keys(queryParams).reduce((acc: any[], key: any) => [...acc, queryParams[key]], []);
  const dataReady = getReadyState();

  // @NOTE this condition can be improved later to check if all the dynamic data in
  // column structure is ready
  // For this checks only the select items as they can be missing in some cases
  const columnStructureReady =
    columnStructure.findIndex(
      (column) =>
        (column.type === RowItemType.SELECT || column.type === RowItemType.JOINED_SELECT) &&
        !(column.selectItems || column.pathToSelectItems),
    ) === -1 &&
    (!dynamicFlags || dynamicFlags?.findIndex((flag) => !flag) === -1);

  const selectItemsTrigger = columnStructure.reduce((acc, currentItem) => {
    const hasSelectItems =
      (currentItem.type === RowItemType.SELECT || currentItem.type === RowItemType.JOINED_SELECT) &&
      currentItem.selectItems;
    const increment = hasSelectItems ? currentItem?.selectItems?.length || 0 : 0;
    return acc + increment;
  }, 0);

  useEffect(() => {
    setGlobalProps({ apiTriggers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...apiTriggers]);

  useEffect(() => {
    setGlobalProps({
      allowDelete,
      allowSelect,
      mobileLimit,
      modalApi,
      mainApiUrl,
      keywordList: [keyword, ...additionalKeywords],
      pageSize,
      offsetKey,
      sortingKeys,
      tablePath,
      withHeaderInMobile,
      indexOfPayloadItem,
      withoutQuestionMarkBeforeLimit,
      setIsPendingRequestFromModalApi,
      actionSectionGridWidth,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mainApiUrl,
    allowDelete,
    allowSelect,
    mobileLimit,
    pageSize,
    offsetKey,
    tablePath,
    withHeaderInMobile,
    indexOfPayloadItem,
    withoutQuestionMarkBeforeLimit,
    sortingKeys.sort_field,
    sortingKeys.sort_type,
    actionSectionGridWidth,
  ]);

  useEffect(() => {
    columnStructureReady && setGlobalProps({ columnStructure });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnStructureReady, columnStructureTriggerCounter, selectItemsTrigger, language, ...columnStructureTriggers]);

  useEffect(() => {
    offset !== undefined && offset >= 0 && setPage(Math.floor(offset / pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, pageSize]);

  useEffect(() => {
    sortingData.sort_field && sortingData.sort_type && setSortingData(sortingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingData.sort_field, sortingData.sort_type]);

  useEffect(() => {
    setGlobalProps({ pathParams });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...pathArray]);

  useEffect(() => {
    setGlobalProps({ queryParams });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...queryArray]);

  useEffect(() => {
    if ((rowRef ? rowRef : localRowRef) && typeof heightOfRow === 'number') {
      setHeightOfScrollableRow(heightOfRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightOfRow]);

  useEffect(() => {
    if (windowWidth < 1024 || isAddInProcess) {
      setGlobalProps({ columnStructure });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, windowWidth, isAddInProcess]);

  useEffect(() => {
    if (
      dataReady &&
      !isAddInProcess &&
      startAddProcess &&
      keywordAddModalGlobalSearch &&
      keywordAddModalGlobalSearch === keyword
    ) {
      startAddProcess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReady, keywordAddModalGlobalSearch, startAddProcess]);

  useEffect(() => {
    dataReady && setIsDataLoaded && setIsDataLoaded(true);
  }, [dataReady]);

  const handleUpdate = (...props: any) => {
    triggerSave();
    updateItems(...props);
  };

  const isHighlighted = highlightedIndex !== -1;

  const preparedInlineActionSet = useMemo(() => {
    return inlineActionSet.map((item) => {
      const isFunction = _.isFunction(item.component);
      return { ...item, component: isFunction ? (item.component as ActionComponent) : () => item.component };
    });
  }, [inlineActionSet]);

  return (
    <MainWrapper theme={theme}>
      <Head
        titleActionComponent={titleActionComponent}
        allowDelete={!!allowDelete}
        allowSelect={!!allowSelect}
        selectComponent={selectComponent}
        withHeaderInMobile={withHeaderInMobile}
        withHeaderGridTemplate={withHeaderGridTemplate}
        languageStrings={languageStrings}
        tablePath={tablePath}
      />
      {list.length > 0 ? (
        <ScrollableWrapper isScrollableRows={isScrollableRows} height={heightOfScrollableWrapper}>
          {list.slice(0, pageSize).map((item: any, index: number) => (
            <Row
              data={item}
              index={index}
              setLastListItem={list.length - 1}
              key={index}
              allowDelete={!!allowDelete}
              allowSelect={!!allowSelect}
              inlineActionSet={preparedInlineActionSet as any}
              customRowClick={customRowClick}
              isFirst={index === 0}
              idItemForViewModal={idItemForViewModal}
              languageStrings={languageStrings}
              rounded={index === 0 && !!mobileHeadless}
              withHeaderInMobile={withHeaderInMobile}
              withHeaderGridTemplate={withHeaderGridTemplate}
              rowRef={rowRef ? rowRef : localRowRef}
              handleRowOnClick={
                handleRowOnClick
                  ? () => {
                      handleRowOnClick(item);
                    }
                  : undefined
              }
              isHrefDetailsClick={isHrefDetailsClick}
            />
          ))}
        </ScrollableWrapper>
      ) : (
        <NoDataPlaceholder />
      )}

      <ManagePagination
        onPageChange={
          !mainApiUrl
            ? onPageChange
            : (page: number, isOffset: boolean) => setPage(page, { isOffset, replacePath: true })
        }
        editInProcess={false}
        languageStrings={languageStrings}
      />
      <ModalSystem
        actionSet={actionSet}
        deleteItems={deleteItems}
        allowDelete={!!allowDelete}
        modalColumnCount={modalColumnCount || 2}
        updateItems={handleUpdate}
        languageStrings={languageStrings}
        banAutoFocus={banAutoFocus}
        accessData={accessData}
        fieldNameOfDeletedItems={fieldNameOfDeletedItems}
        isPendingRequestFromModalApi={isPendingRequestFromModalApi}
        triggerColumnStructure={triggerColumnStructure}
      />
      {isHighlighted && <BackgroundBlur theme={theme} onClick={() => setHighlightedIndex(-1)} />}
    </MainWrapper>
  );
};

export const UniversalTable = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(UniversalTablePlain);
