var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from '@xstyled/styled-components';
import { CloseButtonRoundImage } from './components/CloseButtonRoundImage';
const Wrapper = styled.div `
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    stroke: ${(props) => props.theme.colors.primary};
    transition: all 0.3s ease;
  }
  transition: all 0.3 ease;
  &:hover {
    opacity: 0.7;
    & > svg > path {
      stroke: ${(props) => (props.hoveredColor ? props.hoveredColor : props.theme.colors.primary)};
    }
  }
`;
export const CloseButtonRound = (_a) => {
    var { onClick, color, hoveredColor } = _a, props = __rest(_a, ["onClick", "color", "hoveredColor"]);
    const theme = useTheme();
    return (React.createElement(Wrapper, Object.assign({ theme: theme, color: color, hoveredColor: hoveredColor, onClick: onClick }, props),
        React.createElement(CloseButtonRoundImage, null)));
};
