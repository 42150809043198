import { LanguageStrings } from '../../../../languages/types';
import { InputStructure } from './types';
import { FieldsType } from '../../../../utils/validation';

export const getInputStructure = (languageStrings: LanguageStrings) =>
  [
    {
      label: languageStrings.usersName,
      placeholder: languageStrings.nameSurnamePlaceholder,
      fieldName: 'name',
    },
    {
      label: languageStrings.userEmailLabel,
      placeholder: languageStrings.userEmailPlaceholder,
      fieldName: 'email',
      validationRule: FieldsType.EMAIL,
    },
    {
      label: languageStrings.companyNameLabel,
      placeholder: languageStrings.companyNamePlaceholderr,
      fieldName: 'company',
      isDisabled: true,
    },
    {
      label: languageStrings.roleLabel,
      placeholder: languageStrings.roleAdmin,
      fieldName: 'role',
      isDisabled: true,
    },
  ] as InputStructure[];

export const getAccountTypes = (languageStrings: LanguageStrings) => [
  {
    coloredText: languageStrings.roleAdmin,
    remainingText: languageStrings.addNewCompanyModalSecondStepTypesOfAccountsRemainingText1,
  },
  {
    coloredText: languageStrings.roleUser,
    remainingText: languageStrings.addNewCompanyModalSecondStepTypesOfAccountsRemainingText2,
  },
];
