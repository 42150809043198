import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton, ButtonIconType } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { NameOfDevice } from './components/nameOfDevice/NameOfDevice';
import { ReactComponent as BellIcon } from '../../../../../../../assets/Bell.svg';
import { ReactComponent as BellOffIcon } from '../../../../../../../assets/Bell-off.svg';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../../../../app/state/user';
import { useMediaType, useWindowSize } from '@cthings.co/styles-utils';
import { View, getPath } from '../../../../../../../routes';
import { useCustomHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { selectDeviceInfo } from '../../../../../../../app/state/insight';
import { LocationButton } from './components/locationButton/LocationButton';
import { DeleteModal } from './components/deleteModal/DeleteModal';
import { DeviceBlock } from '../../../../../../../components/deviceBlock/DeviceBlock';
import { DevicesSubtitle } from '../devicesSubtitle/DevicesSubtitle';
import { MeasurementDevices } from '../../../types';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { stringifyUrl } from 'query-string';
import { injectPathParams } from '@cthings.co/utils';

const Wrapper = styled.div``;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  ${media.tablet} {
    justify-content: space-between;
  }
  & > span {
    display: none;
    ${media.tablet} {
      display: block;
      font-size: 20px;
    }
  }
  ${media.tablet} {
    margin-bottom: 30px;
  }
  ${media.phone} {
    margin-bottom: 25px;
  }
`;

const SubtitleBlock = styled.div`
  margin: 20px 0 40px 0;

  ${media.phone} {
    margin: 16px 0;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const StyledDeletButton = styled(CommonButton)`
  &:hover > svg > path {
    stroke: ${colorFetch('white')};
  }

  ${media.phone} {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const TitleBlock = ({
  params,
  accessData,
  isLoading,
  isSubscribed,
  setDeviceName,
  onSubscribeBtnPress,
  changeTitleTextarea,
  data,
}: {
  params: any;
  data: any;
  accessData: any;
  isLoading: boolean;
  isSubscribed?: boolean;
  setDeviceName?: (value: string) => void;
  onSubscribeBtnPress?: () => void;
  changeTitleTextarea?: (parameterId: string, value: string) => void;
}) => {
  const customHistory = useCustomHistory();
  const theme = useTheme();

  const deviceInfoData = useSelector(selectDeviceInfo);
  const { type } = deviceInfoData;
  const languageStrings = useSelector(selectLanguageStrings);
  const screenWidth = useWindowSize()[0];
  const windowWidth = screenWidth ? screenWidth : 1440;
  const { phone } = useMediaType();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const language = useSelector(selectLanguage);

  const subscribeBtnIcon = isLoading ? null : isSubscribed ? <BellOffIcon /> : <BellIcon />;

  const subscribeBtnText =
    screenWidth < 1024 ? '' : isSubscribed ? languageStrings.unsubcribeButton : languageStrings.subcribeButton;

  const subscribeBtnText2 = isSubscribed ? languageStrings.unsubcribeButton : languageStrings.subcribeButton;

  const locationBtnText = windowWidth < 1024 ? '' : languageStrings.deviceDetailsGoToInsightDetails;
  const to = View.USER_MEASUREMENT_DEVICE_INSIGHTS;

  const href = stringifyUrl({
    url: injectPathParams(getPath(to), { id: data.id, type, offsetEvents: 0, offsetMaintenance: 0 }),
  });

  return (
    <Wrapper>
      {!phone ? (
        <>
          <Container>
            <CommonButton
              width="32px"
              iconType={ButtonIconType.ARROW_LEFT}
              iconWithoutText
              onClick={() => customHistory.goBack()}
            />
            <NameOfDevice name={data?.name} />
            <LocationButton href={href}>{locationBtnText}</LocationButton>
          </Container>
          <SubtitleBlock>
            {data.type === MeasurementDevices.MANNINGS_PAIR ? (
              <DevicesSubtitle data={data} />
            ) : (
              <DeviceBlock data={data} />
            )}
          </SubtitleBlock>
        </>
      ) : (
        <>
          <Row>
            <CommonButton
              width="32px"
              iconType={ButtonIconType.ARROW_LEFT}
              iconWithoutText
              onClick={() => customHistory.goBack()}
            />
            <NameOfDevice name={data.name} />
            <LocationButton href={href}>{locationBtnText}</LocationButton>
          </Row>
          <SubtitleBlock>
            <DeviceBlock data={data} />
          </SubtitleBlock>
          <DeleteModal
            theme={theme}
            isOpenModal={isOpenDeleteModal}
            setModalClose={() => {
              setIsOpenDeleteModal(false);
            }}
            onDelete={() => {}}
            languageStrings={language}
          />
        </>
      )}
    </Wrapper>
  );
};
