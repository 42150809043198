import React, { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type TextareaElementType = {
  isError?: boolean;
  isPlaceholder: boolean;
};

const TextareaElement = styled(TextareaAutosize)<TextareaElementType>`
  background-color: transparent;
  color: ${({ isError, theme }) => (isError ? colorFetch('red2')({ theme }) : colorFetch('gray1')({ theme }))};
  font-weight: ${({ isError, isPlaceholder }) => (isError || isPlaceholder ? '400' : '500')};
  font-size: 14px;
  outline: none;
  border: none;
  padding: 0;
  margin: 0 4px 0 0;
  resize: none;
  overflow: hidden;
  font-family: inherit;
  width: 100%;
  opacity: 1 !important;
  &::placeholder {
    color: ${({ isError, theme }) => (isError ? colorFetch('red2')({ theme }) : colorFetch('gray1')({ theme }))};
    font-weight: 400;
  }
` as any; // @TODO fix type

export interface TextareaProps {
  textValue?: string | any;
  changeValue: any;
  textareaRef?: any;
  className?: string;
  isDisabled?: boolean;
  isError?: boolean;
  placeholder?: string;
  maxLength?: number;
  onKeyDown?: Function;
  onBlur?: Function;
}

export const Textarea: FC<TextareaProps> = ({
  textValue,
  changeValue,
  textareaRef,
  isDisabled,
  isError,
  placeholder,
  maxLength,
  onKeyDown,
  onBlur,
  ...props
}) => {
  return (
    <TextareaElement
      type="text"
      ref={textareaRef}
      value={textValue}
      onChange={(e: any) => changeValue(e.target.value)}
      onKeyDown={onKeyDown}
      disabled={isDisabled}
      isError={isError}
      placeholder={placeholder ? placeholder : 'Enter info'}
      isPlaceholder={textValue && textValue.length === 0}
      maxLength={maxLength}
      onBlur={onBlur}
      {...props}
    />
  );
};

Textarea.defaultProps = {};
