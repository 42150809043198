import React from 'react';
import styled from '@cthings.co/styled-components';
import { DashboardCard } from '../../../../../../../../components/dashboardCard/DashboardCard';
import { InfoCard } from '../../../../../../../../components/infoCard/InfoCard';
import { media } from '@cthings.co/styles-utils';

const GlobalRow = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

const CardRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 20px;
  ${media.desktop} {
    grid-area: 2/1/2 / span 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  ${media.phone} {
    grid-area: auto;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

const StyledCard = styled(DashboardCard)`
  ${media.desktop} {
    width: 100%;
    margin: 0;
  }
`;

const Space = styled.div`
  display: none;
  ${media.desktop} {
    display: block;
    order: -1;
  }
  ${media.phone} {
    display: none;
  }
`;

export const Cards = ({
  cards,
  languageStrings,
  placeholderCard1Style,
  infoCardData,
}: {
  cards: any;
  languageStrings: any;
  placeholderCard1Style: any;
  infoCardData?: any;
}) => {
  const { heading, issues } = infoCardData ? infoCardData : { heading: '', issues: '' };

  return (
    <GlobalRow>
      <CardRow>
        {cards.map((item: any, index: number) => {
          const { title, count, type, statusOfValue } = item;

          return (
            <StyledCard
              key={index}
              placeholderProps={{
                keyword: 'HydrantDetailsCard',
                style: placeholderCard1Style,
              }}
              languageStrings={languageStrings}
              title={title}
              type={type}
              count={count}
              statusOfValue={statusOfValue}
            />
          );
        })}
      </CardRow>
      {infoCardData && (
        <>
          <Space />
          <InfoCard title={heading} data={issues} />
        </>
      )}
    </GlobalRow>
  );
};
