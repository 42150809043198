import styled from '@cthings.co/styled-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMeasurementDeviceParameters } from '../../../../../api/devices/measurementDevices';
import { useMeasurementDeviceInsights } from '../../../../../api/devices/measurementInsights';
import { useLoggedUserInfo } from '../../../../../api/manage/manageUsers';
import { updateDeviceRow } from '../../../../../api/realTimeInsight';
import { selectAccess, selectIsAccess, selectLanguageStrings } from '../../../../../app/state/user';
import { Loader } from '../../../../../components/placeholders/Loader';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { useLoaderFunctions } from '../../../../../features/placeholderComponent/loader.context';
import { PATHS } from '../../../../../routes/paths';
import { media } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { MeasurementDevices } from '../types';
import { GravitationalCircularImage } from './assets/FreeFlow';
import { FreeFlowImage } from './assets/GravitationalCircular';
import { ManningsPairImage } from './assets/ManningsPair';
import { VWeirImage } from './assets/VWeir';
import { DeviceConfigurationTable } from './components/deviceConfigurationTable/DeviceConfigurationTable';
import { DeploymentDetailsInfoType } from './components/deviceInfo/components/infoContentTable/components/deploymentDetailsModal/DeploymentDetailsModal';
import { DeviceInfo } from './components/deviceInfo/DeviceInfo';
import { TitleBlock } from './components/titleBlock/TitleBlock';
import { useParams } from '@cthings.co/router-dom';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* &.noScroll {
    height: 100vh;
    overflow: hidden;
  } */

  ${media.tablet} {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 32px;
  }

  ${media.phone} {
    padding-bottom: 24px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    margin: 0 0 30px 0;
  }
`;

const ImageWrapper = styled.div`
  background-color: ${colorFetch('pureWhite')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  overflow: hidden;
  margin-top: 20px;
  box-shadow: 0px 2px 8px rgba(96, 97, 112, 0.06);
  & > svg {
    width: 100%;
    height: auto;
  }
  ${media.tablet} {
    margin-top: 30px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-gap: 20px;
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
`;

export const MeasurementDetails = () => {
  const theme = useTheme();
  const customHistory = useCustomHistory();
  const pathParams = useParams();
  const id = pathParams?.id || '';
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = [
    'InsightDetailsMap',
    'InsightDetailsProblemsButton',
    'InsightDetailsCard',
    'InsightDetailsGraph',
    'InsightDetailsDeviceConfigurationTable',
    'InsightDetailsDeviceInfoTable',
  ];

  const data = useMeasurementDeviceInsights(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  ) as any;
  // const dataCurrentNetwork: any = useCurrentNetwork(id);
  const languageStrings = useSelector(selectLanguageStrings);
  const configTimerId = useRef(null as any);
  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState(false);
  // const deviceInfoData = useSelector(selectDeviceInfo);
  const { addNotification } = useNotifierFunctions();
  const accessData = useSelector(selectAccess).management.devices;
  const isDevicesAccessManagementView = accessData.view;
  const isAccess = useSelector(selectIsAccess);
  // const idDevice = data?.info?.id;
  const params = useMeasurementDeviceParameters(id);

  const [deviceName, setDeviceName] = useState('');
  const [userDeviceIds, setUserDeviceIds] = useState<string[]>();
  const [subscribeError, setSubscribeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useLoggedUserInfo();
  // const isSubscribed = userDeviceIds && userDeviceIds.includes(id);
  const [, setIsQualityModalOpen] = useState(false);

  const scrollHandlerClassName = data?.data?.radio_module && params && data?.info?.address ? '' : 'noScroll';
  const deploymentDetailsArr: DeploymentDetailsInfoType[] = [
    {
      fieldName: data?.info?.first_provisioning?.user_info.label,
      tooltipText: data?.info?.first_provisioning?.user_info.tooltip,
      value: data?.info?.first_provisioning?.user_info.value,
    },
    {
      fieldName: data?.info?.first_provisioning?.ts_info.label,
      tooltipText: data?.info?.first_provisioning?.ts_info.tooltip,
      value: data?.info?.first_provisioning?.ts_info.value
        ? getRequiredDateFormat(data?.info?.first_provisioning?.ts_info.value, 'DD.MM.YYYY')
        : null,
    },
    {
      fieldName: data?.info?.first_deployment?.user_info.label,
      tooltipText: data?.info?.first_deployment?.user_info.tooltip,
      value: data?.info?.first_deployment?.user_info.value,
    },
    {
      fieldName: data?.info?.first_deployment?.ts_info.label,
      tooltipText: data?.info?.first_deployment?.ts_info.tooltip,
      value: data?.info?.first_deployment?.ts_info.value
        ? getRequiredDateFormat(data?.info?.first_deployment?.ts_info.value, 'DD.MM.YYYY')
        : null,
    },
    {
      fieldName: data?.info?.first_deployment?.success_ts_info.label,
      tooltipText: data?.info?.first_deployment?.success_ts_info.tooltip,
      value: data?.info?.first_deployment?.success_ts_info.value
        ? getRequiredDateFormat(data?.info?.first_deployment?.success_ts_info.value, 'DD.MM.YYYY')
        : null,
    },
  ];

  // const resetAddress = () => {
  //   dispatch(resetRealTimeAddress());
  // };
  const changeValueTextarea = useCallback(
    (parameter_id: string, val: string | any[], handleRevert?: () => void, setValueByAnswer?: (value: any) => void) => {
      configTimerId.current && clearTimeout(configTimerId.current);
      configTimerId.current = setTimeout(() => {
        updateDeviceRow(id, val, addNotification, languageStrings, handleRevert, setValueByAnswer);
      }, 1500);
    },
    [configTimerId],
  );
  // const changeTitleTextarea = useCallback(
  //   (parameter_id: string, val: string) => {
  //     dispatch(setDeviceInfoName({ name: val }));
  //     configTimerId.current && clearTimeout(configTimerId.current);
  //     configTimerId.current = setTimeout(() => {
  //       updateDeviceRow(id, parameter_id, val, addNotification, languageStrings);
  //     }, 1500);
  //   },
  //   [configTimerId],
  // );
  // const setAddress = (value: any) => {
  //   dispatch(updateRealTimeAddress(value));
  // };
  // const handleUpdateDevice = () => {
  //   updateDevices(idDevice, deviceInfoData);
  // };
  // const onSubscribeBtnPress = () => {
  //   setIsLoading(true);
  //   isSubscribed
  //     ? unsubscribeToDevice(idDevice, languageStrings, addNotification, setUserDeviceIds, setSubscribeError)
  //     : subscribeToDevice(idDevice, languageStrings, addNotification, setUserDeviceIds, setSubscribeError);
  // };
  const handleQualityModal = () => {
    setIsQualityModalOpen((value) => !value);
  };

  const setMeasurementDevicesImage = (type: MeasurementDevices) => {
    const image: any = {
      [MeasurementDevices.FREE_FLOW]: <FreeFlowImage />,
      [MeasurementDevices.GRAVITATIONAL_CIRCULAR]: <GravitationalCircularImage />,
      [MeasurementDevices.MANNINGS_PAIR]: <ManningsPairImage />,
      [MeasurementDevices.V_WEIR]: <VWeirImage />,
    };
    return image[type];
  };

  useEffect(() => {
    if (isAccess && !isDevicesAccessManagementView) {
      customHistory.push(PATHS.ERROR_401);
    }
  }, [isAccess, isDevicesAccessManagementView]);

  useEffect(() => {
    if (params && params[0]) {
      setDeviceName((params[0] as any).value.data);
    }
  }, [params]);

  useEffect(() => {
    if (userInfo && userInfo.subscribed_devices) {
      setUserDeviceIds(userInfo.subscribed_devices);
    }
  }, [userInfo]);

  useEffect(() => {
    subscribeError && setSubscribeError(false);
    isLoading && setIsLoading(false);
  }, [userDeviceIds, subscribeError]);

  return (
    <Wrapper className={scrollHandlerClassName}>
      {data ? (
        <>
          <TitleBlock
            data={data}
            params={params}
            accessData={accessData}
            isLoading={isLoading}
            // isSubscribed={!!isSubscribed}
            setDeviceName={setDeviceName}
            // onSubscribeBtnPress={onSubscribeBtnPress}
            // changeTitleTextarea={changeTitleTextarea}
          />
          <Row>
            <Column>
              <TableWrapper>
                <DeviceInfo
                  data={data}
                  isOpenAddressPopup={isOpenAddressPopup}
                  setIsOpenAddressPopup={(value: boolean) => setIsOpenAddressPopup(value)}
                  accessData={accessData}
                  handleQualityModal={handleQualityModal}
                  deploymentDetailsinfoArr={deploymentDetailsArr}
                  languageStrings={languageStrings}
                />
              </TableWrapper>
              <ImageWrapper theme={theme}>{setMeasurementDevicesImage(data.type)}</ImageWrapper>
            </Column>
            <TableWrapper>
              <DeviceConfigurationTable
                changeValueTextarea={changeValueTextarea}
                values={params}
                isAccessDeviceConfiguration={true}
                languageStrings={languageStrings}
                accessData={accessData}
                deviceName={deviceName}
                setDeviceName={setDeviceName}
              />
              {/* <ManningsEquation /> */}
            </TableWrapper>
          </Row>
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};
