export enum TextType {
  CHIPS_WHITE = 'CHIPS_WHITE',
  CHIPS_BLACK = 'CHIPS_BLACK',
  TEXT_14_BLACK = 'TEXT_14_BLACK',
  TEXT_14_WHITE = 'TEXT_14_WHITE',
  TEXT_14_GRAY = 'TEXT_14_GRAY',
  TEXT_14_RED = 'TEXT_14_RED',
  TEXT_14_GREEN = 'TEXT_14_GREEN',
  PROBLEMS_BUTTON_TEXT = 'PROBLEMS_BUTTON_TEXT',
  HELPER_TEXT_1 = 'HELPER_TEXT_1',
  HELPER_TEXT_2 = 'HELPER_TEXT_2',
  MENU_ITEM_TEXT_PRIMARY = 'MENU_ITEM_TEXT_PRIMARY',
  MENU_ITEM_TEXT_ADDITIONAL = 'MENU_ITEM_TEXT_ADDITIONAL',
  TEXT_12_BLACK = 'TEXT_12_BLACK',
  TEXT_12_GRAY = 'TEXT_12_GRAY',
  TEXT_12_GREEN = 'TEXT_12_GREEN',
  TEXT_10_GRAY = 'TEXT_10_GRAY',
  TEXT_10_WHITE = 'TEXT_10_WHITE',
  TITLE_PRIMARY = 'TITLE_PRIMARY',
  TITLE_SECONDARY = 'TITLE_SECONDARY',
  TITLE_ADDITIONAL = 'TITLE_ADDITIONAL',
  SEMITITLE = 'SEMITITLE',
  TITLE_H1 = 'TITLE_H1',
  TITLE_H1MOBILE = 'TITLE_H1MOBILE',
  TITLE_H3 = 'TITLE_H3',
  TITLE_H3MOBILE = 'TITLE_H3MOBILE',
  TITLE_H4 = 'TITLE_H4',
  TITLE_H4MOBILE = 'TITLE_H4MOBILE',
  TITLE_H5 = 'TITLE_H5',
  TEXT_21_black = 'TEXT_21_black',
}
