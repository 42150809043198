import styled from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';

export const Edit = styled(CommonButton)`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  width: max-content;
`;
