import React, { FC, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { motion } from 'framer-motion';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Container = styled.div`
  width: auto;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled(motion.div)`
  width: 35px;
  height: 20px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: ${({ theme }) => theme.borderRadius.additional10};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerCircle = styled(motion.div)`
  width: 16px;
  height: 16px;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow6};
  background-color: ${colorFetch('white')};
`;

const LabelContainer = styled(motion.div)`
  width: auto;
  height: auto;
  padding: 2px;
  display: flex;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  margin: 0px 8px;
`;

export interface ToggleButtonProps {
  toggle: boolean;
  disabled: boolean;
  labelRight: string;
  labelLeft: string;
  activeBackground: boolean;
  handleToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  handleToggle,
  toggle = false,
  labelRight = '',
  labelLeft = '',
  activeBackground = true,
  disabled = false,
  ...props
}) => {
  const theme = useTheme();

  const [toggled, setToggle] = useState(toggle);
  const [disabledState] = useState(disabled);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      e.preventDefault();
    } else {
      setToggle(!toggled);
      handleToggle(e);
    }
  };

  return (
    <Container>
      <LabelContainer
        animate={{
          fontWeight: toggled ? '400' : '500',
          opacity: disabledState ? 0.3 : 1,
        }}
      >
        {labelLeft}
      </LabelContainer>
      <Button
        // @TODO fix handleClick type
        onClick={handleClick as any}
        animate={{
          backgroundColor: activeBackground
            ? toggled
              ? theme.colors.primary
              : theme.colors.gray4
            : theme.colors.primary,
          opacity: disabledState ? 0.3 : 1,
          justifyContent: toggled ? 'flex-end' : 'flex-start',
        }}
        transition={{ duration: 0.4, ease: 'linear' }}
      >
        <InnerCircle transition={{ duration: 0.4, ease: 'linear' }}></InnerCircle>
      </Button>
      <LabelContainer
        animate={{
          fontWeight: !toggled ? '400' : '500',
          opacity: disabledState ? 0.3 : 1,
        }}
      >
        {labelRight}
      </LabelContainer>
    </Container>
  );
};

function handleToggle() {}

ToggleButton.defaultProps = {
  toggle: false,
  labelRight: 'List View',
  labelLeft: 'Map View',
  disabled: false,
  activeBackground: false,
  handleToggle: handleToggle,
};
