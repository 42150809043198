import { CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as DeployIcon } from '../../../../../../../assets/share.svg';
import { InfoCard } from '../../../../../../../components/infoCard/InfoCard';
import { media } from '@cthings.co/styles-utils';
import { useMediaType } from '@cthings.co/styles-utils';
import { DeviceConfig } from '../deviceConfig/DeviceConfig';

const Wrapper = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 82px 0 30px !important;
  ${media.phone} {
    flex-direction: column-reverse !important;
    margin: 16px 0 16px 0 !important;
  }
`;

type StyledTextProps = {
  isDeployInProgress: boolean;
};

const StyledText = styled(Text)<StyledTextProps>`
  padding: ${({ isDeployInProgress }) => (isDeployInProgress ? '0 200px' : '0')}!important;

  ${media.desktop} {
    margin-left: 56px !important;
  }
  ${media.phone} {
    text-align: center !important;
    margin-left: 0px !important;
  }
`;

const DeployButton = styled(CommonButton)`
  height: 40px;
  margin-left: 39px !important;
  & div > div {
    height: 20px;
    width: 20px;
  }

  ${media.tablet} {
    & div > div {
      margin-right: 8px !important;
    }
  }

  ${media.phone} {
    width: 100%;
    margin: 29px 0 10px !important;
    & div > div {
      margin-right: 8px !important;
    }
  }
`;

const StyledDeployIcon = styled(DeployIcon)`
  margin-right: 8px;
`;

export const DeployBlock = ({
  languageStrings,
  id,
  isLoading,
  deploymentStatus,
  photos,
  onDeployPress,
  infoCardData,
}: {
  languageStrings: any;
  id: string;
  isLoading: boolean;
  deploymentStatus: number | undefined;
  photos: any[];
  onDeployPress: () => void;
  infoCardData: any;
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;
  const { heading, issues } = infoCardData ? infoCardData : { heading: '', issues: '' };
  const { tablet, desktop } = useMediaType();

  const text = deploymentStatus === 0 ? languageStrings.deviceIsntDeployed : languageStrings.deploymentIsInProgress;

  const addPaddings = infoCardData && deploymentStatus !== 0 && !desktop;

  return (
    <>
      <Wrapper>
        <StyledText
          isDeployInProgress={addPaddings}
          type={TextType.TEXT_14_GRAY}
          fontSize={'20px'}
          weight={'400'}
          color={gray2}
        >
          {text}
        </StyledText>
        {deploymentStatus === 0 && (
          <DeployButton disabled={isLoading} onClick={onDeployPress} isLoadingInProcess={isLoading}>
            {!isLoading && <StyledDeployIcon />}
            {languageStrings.deploy}
          </DeployButton>
        )}
        {infoCardData && deploymentStatus !== 0 && <InfoCard title={heading} data={issues} />}
      </Wrapper>
      <DeviceConfig id={id} photos={photos} />
    </>
  );
};
