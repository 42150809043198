import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as UpArrow } from '../../assets/Sorting_arrow_up.svg';
import { ReactComponent as DownArrow } from '../../assets/Sorting_arrow_down.svg';
import { SORT_TYPE } from '../../features/universalTable/types';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
`;

type UpArrowIconProps = {
  color: string;
};

const UpArrowIcon = styled(UpArrow)<UpArrowIconProps>`
  & > path {
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
    transition: all 0.3s ease;
  }
  margin-bottom: 2px;
`;

type DownArrowIconProps = {
  color: string;
};

const DownArrowIcon = styled(DownArrow)<DownArrowIconProps>`
  & > path {
    stroke: ${({ color }) => color};
    fill: ${({ color }) => color};
    transition: all 0.3s ease;
  }
  cursor: pointer;
`;

export const SortingArrows = ({
  sortData,
  fieldName,
  color,
  ...props
}: {
  sortData: { sort_field: string; sort_type: string | number };
  fieldName: string;
  color?: string;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;
  const { sort_field, sort_type } = sortData;

  if (fieldName === sort_field && sort_type === SORT_TYPE.DESC) {
    return (
      <Wrapper {...props}>
        <DownArrowIcon color={color ? color : gray2} />
      </Wrapper>
    );
  } else if (fieldName === sort_field && sort_type === SORT_TYPE.ASC) {
    return (
      <Wrapper {...props}>
        <UpArrowIcon color={color ? color : gray2} />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper {...props}>
        <UpArrowIcon color={color ? color : gray2} />
        <DownArrowIcon color={color ? color : gray2} />
      </Wrapper>
    );
  }
};
