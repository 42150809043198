import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { Text } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const StyledModal = styled(Modal)`
  width: 100%;
  z-index: var(--modal-first-layer);
  & > div {
    width: 100%;
  }
  ${media.semiTablet} {
    align-items: flex-end;
  }
`;

export const StyledModalContainer = styled(ModalContainer)`
  width: 100%;
  max-width: 566px;
  box-sizing: border-box;
  padding: 25px;
  ${media.semiTablet} {
    border-radius: 12px 12px 0 0;
    height: 100%;
    align-items: flex-end;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTitle = styled(Text)`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 15px;
`;

type CheckboxWrapperProps = {
  borderRadius: string;
};

export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid ${colorFetch('gray3')};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: 8px 12px;
`;

export const StyledCheckboxText = styled(Text)`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  gap: 15px;
  ${media.semiTablet} {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const CancelButton = styled(CommonButton)`
  max-width: 115px;
  width: 100%;
  background-color: ${colorFetch('pureWhite')};
  color: ${colorFetch('black1')};
  ${media.semiTablet} {
    max-width: 100%;
    height: 40px;
  }
`;

export const SaveButton = styled(CommonButton)`
  max-width: 115px;
  width: 100%;
  ${media.semiTablet} {
    max-width: 100%;
    height: 40px;
  }
`;
