import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { Text } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as AddFileIcon } from '../../assets/add_file_icon.svg';
import { selectAccess, selectIsAccess, selectLanguage, selectLanguageStrings } from '../../app/state/user';
import { generateReport, useGetRecipients, useReportsList } from '../../api/reports';
import {
  clearPeriod,
  clearRecipients,
  SelectRecipients,
  SelectSelectedParameters,
  toggleIsError,
  toggleIsValidatingStarted,
} from '../../app/state/generateReportModal';
import { useNotifierFunctions } from '../../features/notifier2';
import {
  GenerateReportModal,
  TypeOfParameter,
} from '../../containers/common/reports/components/generateReportModal/GenerateReportModal';
import { PATHS } from '../../routes/paths';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const ExtraWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
`;

// @TODO fix media types

const StyledCommonButton = styled(CommonButton)`
  & > svg {
    margin-right: 6px;
  }
  & > svg > path {
    transition: stroke 0.3s ease;
    stroke: ${({ colors: { main } = {} }) => main};
  }
  &:hover > svg > path {
    stroke: ${colorFetch('white')};
  }
  ${media.tablet} {
    width: 32px;
    height: 32px;
    & > span {
      display: none;
    }
    & > svg {
      margin: 0;
    }
    &:hover > svg > path {
      stroke: ${({ colors: { mainHover } = {} }) => mainHover};
    }
  }
`;

const ButtonText = styled(Text)`
  transition: none;
  ${media.tablet} {
    display: none;
  }
` as any; // @TODO fix type

export const ReportsModal = ({
  deviceId,
  isShowRecipientsBlock,
}: {
  deviceId?: string;
  isShowRecipientsBlock?: boolean;
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoadingInProcess, setIsLoadingInProcess] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { primary, pureWhite, secondary2 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);
  const history = useHistory();
  const isAccess = useSelector(selectIsAccess);
  const accessData = useSelector(selectAccess).management.devices;
  const isReportsAccessManagementView = accessData.view;

  useGetRecipients();

  const recipient = useSelector(SelectRecipients);
  const reportData = useSelector(SelectSelectedParameters);
  const isNotEmptyParameters = !!recipient.selected_parameters.length;
  const language = useSelector(selectLanguage);
  const { addNotification } = useNotifierFunctions();
  useReportsList(setIsEmpty);

  const clearModalData = () => {
    dispatch(
      clearRecipients({
        recipients: recipient.parameters && recipient.parameters.map((item: any) => ({ ...item, isSelected: false })),
      }),
    );
    dispatch(clearPeriod());
  };
  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
    dispatch(toggleIsValidatingStarted({ value: false }));
    clearModalData();
  };
  const handleGenerateReport = () => {
    dispatch(toggleIsValidatingStarted({ value: true }));

    if (isNotEmptyParameters || !isShowRecipientsBlock) {
      setIsLoadingInProcess(true);
      generateReport({ ...reportData, devices: [deviceId] }, addNotification, languageStrings, handleResponse);
      setIsOpenModal(false);
      clearModalData();
      dispatch(toggleIsValidatingStarted({ value: false }));
    }
  };
  const handleResponse = (status: boolean) => {
    setIsLoadingInProcess(false);
  };
  const handleToggleIsError = (selectedItems: any[], type: TypeOfParameter) => {
    selectedItems.length === 0
      ? dispatch(toggleIsError({ type, value: true }))
      : dispatch(toggleIsError({ type, value: false }));
  };
  useEffect(() => {
    handleToggleIsError(recipient.selected_parameters, TypeOfParameter.RECIPIENTS);
  }, [recipient]);

  useEffect(() => {
    if (isAccess && !isReportsAccessManagementView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isReportsAccessManagementView]);

  return (
    <ExtraWrapper>
      <Wrapper>
        {
          <StyledCommonButton
            onClick={openModal}
            width={language.shortName === 'EN' ? '160px' : '190px'}
            isLoadingInProcess={isLoadingInProcess && !isOpenModal}
            colors={{
              main: primary,
              mainHover: pureWhite,
              background: pureWhite,
              backgroundHover: secondary2,
              border: primary,
              borderHover: secondary2,
            }}
          >
            {!isLoadingInProcess && <AddFileIcon />}
            <ButtonText>{languageStrings.generateReports}</ButtonText>
          </StyledCommonButton>
        }
      </Wrapper>
      <GenerateReportModal
        isShowCustomerAndLocationFields={false}
        recipients={recipient}
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        generateOnClick={handleGenerateReport}
        languageStrings={languageStrings}
        isShowRecipientsBlock={isShowRecipientsBlock}
      />
    </ExtraWrapper>
  );
};
