import React, { useEffect } from 'react';
import styled from '@cthings.co/styled-components';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useTableFunctions, withTableContextProvider } from '../../../../features/universalTable/context';
import { UniversalTable } from '../../../../features/universalTable/UniversalTable';
import { InlineActionType, RowItemType } from '../../../../features/universalTable/types';
import { useMediaType } from '@cthings.co/styles-utils';
import { API_URL } from '../../../../consts';
import { View } from '../../../../routes/routeInterfaces';
import { getPath, injectPathParams, PATHS } from '../../../../routes/paths';
import { useMobileManagementSearch } from '../../../../layouts/managementMobile/useMobileManagement';
import { useHistory } from '../../../../utils/react-router-dom-abstraction';
import { Title } from './components/title/Title';
import { LocationButton } from '../../management/devices/components/locationButton/LocationButton';
import { DeviceType } from '../../../../enums/DeviceType';
import { media } from '@cthings.co/styles-utils';
import { DeviceIdLink } from './components/deviceIdLink/DeviceIdLink';
import { useParams } from '@cthings.co/router-dom';
import { useQueryParams } from '../../../../utils/useQueryParams';
import { stringifyUrl } from 'query-string';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${media.desktop} {
    max-width: 1024px;
  }
`;

const FlowMeasurementPlain = () => {
  const { resetTable, setSearchValue } = useTableFunctions();

  const { tablet } = useMediaType();
  const keyword = 'MeasurementsTable';
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pathParams = useParams();

  const languageStrings = useSelector(selectLanguageStrings);
  const history = useHistory();
  const { search_value = '' } = queryParams || {};

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useMobileManagementSearch(setSearchValue);

  return (
    <Wrapper>
      <Title searchValue={search_value} setSearchValue={setSearchValue} withSmartSearch />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !tablet },
        }}
        mainApiUrl={`${API_URL}/v2/logical-set`}
        keyword={keyword}
        offset={+offset}
        offsetKey={offsetKey}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        queryParams={queryParams}
        pathParams={pathParams}
        tablePath={View.MANAGE_MEASUREMENT}
        isHrefDetailsClick={true}
        customRowClick={(value: any) => {
          const { type, id } = value;
          const to =
            type === DeviceType.MANNINGS_PAIR ? View.USER_PAIR_DEVICE_INSIGHTS : View.USER_MEASUREMENT_DEVICE_INSIGHTS;

          const href = to
            ? stringifyUrl({
                url: injectPathParams(getPath(to), { id, offsetEvents: 0, offsetMaintenance: 0 }),
              })
            : undefined;

          return href;
        }}
        inlineActionSet={[
          {
            type: InlineActionType.CUSTOM,
            component: <LocationButton onClick={() => {}} />,
            callback: (data: any) => {
              const { location: address, id } = data;
              const to = injectPathParams(PATHS.USER_INSIGHTS, {
                lat: address?.geotag?.lat,
                lon: address?.geotag?.lng,
                zoom: 20,
              });
              history.push(to, { address, zoom: 20, id });
            },
          },
          { type: InlineActionType.DETAILS },
        ]}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: '',
            label: 'Flow unit',
            defaultValue: '',
            getTextDisplay: (value: string) => (value ? `${value}` : 'N/A'),
          },
          {
            fieldName: 'set_id',
            placeholder: '',
            label: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
            defaultValue: '',
            width: '2fr',
            getTextDisplay: (value: string) => (value ? `${value}` : 'N/A'),
          },
          {
            fieldName: 'devices',
            placeholder: '',
            label: languageStrings.deviceID,
            defaultValue: '',
            width: '2fr',
            type: RowItemType.CUSTOM,
            component: (value: any) => {
              return <DeviceIdLink data={value} />;
            },
          },
        ]}
      />
    </Wrapper>
  );
};

export const FlowMeasurement = withTableContextProvider(FlowMeasurementPlain);
