import { RootState } from '../../../app/store';

export const selectRealTimeData = (state: RootState) => {
  return state.insight.realTimeData;
};

export const selectComments = (state: RootState) => {
  return state.insight.comments;
};

export const selectHistory = (state: RootState) => {
  return state.insight.history;
};

export const selectEventsGraph = (state: RootState) => {
  return state.insight.events_graph;
};

export const selectDevices = (state: RootState) => {
  return state.insight.devices;
};

export const selectEvents = (state: RootState) => {
  return state.insight.events;
};

export const selectDeviceInfo = (state: RootState) => {
  return state.insight.realTimeData.info;
};

export const selectAddress = (state: RootState) => {
  return state.insight.realTimeData.info.address;
};

export const selectDeviceConfig = (state: RootState) => {
  return state.insight.realTimeData.data.config;
};

export const selectWaterLevelFilters = (state: RootState) => {
  return state.insight.history.water_level.filters;
};
export const selectTemperatureFilters = (state: RootState) => {
  return state.insight.history.temperature.filters;
};

export const selectEventsGraphFilters = (state: RootState) => {
  return state.insight.events_graph.filters;
};

export const selectIsWaterLevelLoadingInProcess = (state: RootState) => {
  return state.insight.history.water_level.isLoadingInProcess;
};

export const selectIsTemperatureLoadingInProcess = (state: RootState) => {
  return state.insight.history.temperature.isLoadingInProcess;
};
export const selectIsEventsGraphLoadingInProcess = (state: RootState) => {
  return state.insight.events_graph.isLoadingInProcess;
};
export const selectTemperatureTsFrom = (state: RootState) => {
  return state.insight.history.temperature.ts_from;
};
export const selectTemperatureTsTo = (state: RootState) => {
  return state.insight.history.temperature.ts_to;
};
export const selectWaterLevelTsFrom = (state: RootState) => {
  return state.insight.history.water_level.ts_from;
};
export const selectWaterLevelTsTo = (state: RootState) => {
  return state.insight.history.water_level.ts_to;
};
export const selectEventsGraphTs = (state: RootState) => {
  return state.insight.events_graph.ts;
};
