import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { startCalibration } from '../../../../../../../../../../../api/realTimeInsight';
import { selectLanguageStrings } from '../../../../../../../../../../../app/state/user';
import { useNotifierFunctions } from '../../../../../../../../../../../features/notifier2';
import { useStyleContext } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../../../../../../../utils/date/date-format';
import { CommonModal } from './components/commonModal/CommonModal';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

interface CalibrationDetailsModalProps {
  data: any;
  isOpenModal: boolean;
  id: string;
  setCloseModal: () => void;
}

export const CalibrationDetailsModal: React.FC<CalibrationDetailsModalProps> = ({
  data,
  id,
  isOpenModal,
  setCloseModal,
}) => {
  const [{ mediaType }] = useStyleContext();
  const { phone } = mediaType;
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);

  const [isFirstStep, setIsFirstStep] = useState(isOpenModal);
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [isThirdStep, setIsThirdStep] = useState(false);

  const handleCalibrationStart = (id: string) => {
    startCalibration({
      id,
      handleSuccess: () => {
        addNotification({
          message: languageStrings.success,
          type: 'success',
        });
      },
      handleError: (data: any) => {
        addNotification({
          message: data?.response?.data?.detail ?? languageStrings.somethingWentWrong,
          type: 'error',
        });
      },
    });
  };

  const dataCalibrationDetailsTitle = languageStrings.calibraionLabel;
  const dataCalibrationDetailsTooltip = languageStrings.calibraionTooltip;
  const dataCalibrationTitle = languageStrings.calibraion;
  const dataCalibrationInProgressTitle = languageStrings.calibraionInPropgress;
  const dataCalibrationContent = languageStrings.calibrationDesc;
  const dataCalibrationInProgressContent = languageStrings.calibrationDescInProgress;
  const closeButtonText = languageStrings.closeButtonValue;
  const nextButtonText = languageStrings.paginationButtonNext;
  const backButtonText = languageStrings.backButton;
  const startButtonText = languageStrings.startLabel;

  const dataCalibrationDetailsButtons = [
    {
      onClick: () => {
        setIsFirstStep(false);
        setCloseModal();
      },
      fontSize: '14px',
      color: colorFetch('black1'),
      backgroundColor: colorFetch('pureWhite'),
      width: phone ? '100%' : '116px',
      height: phone ? '40px' : '32px',
      margin: phone ? '0' : '0 16px 0 0',
      text: closeButtonText,
    },
    {
      onClick: () => {
        setIsFirstStep(false);
        setIsSecondStep(true);
      },
      fontSize: '14px',
      width: phone ? '100%' : '116px',
      height: phone ? '40px' : '32px',
      margin: phone ? '0 0 10px 0' : '0',
      text: nextButtonText,
    },
  ];

  const dataCalibrationButtons = [
    {
      onClick: () => {
        setIsSecondStep(false);
        setIsFirstStep(true);
      },
      fontSize: '14px',
      color: colorFetch('black1'),
      backgroundColor: colorFetch('pureWhite'),
      width: phone ? '100%' : '116px',
      height: phone ? '40px' : '32px',
      margin: phone ? '0' : '0 16px 0 0',
      text: backButtonText,
    },
    {
      onClick: () => {
        handleCalibrationStart(id);
        setIsSecondStep(false);
        setIsThirdStep(true);
      },
      fontSize: '14px',
      width: phone ? '100%' : '116px',
      height: phone ? '40px' : '32px',
      margin: phone ? '0 0 10px 0' : '0',
      text: startButtonText,
    },
  ];

  const dataCalibrationDetails = {
    title: {
      styles: { display: 'flex' },
      value: dataCalibrationDetailsTitle,
      tooltipText: dataCalibrationDetailsTooltip,
      tooltipLink: data?.knowledgebase_url,
    },
    content: {
      styles: { margin: phone ? '24px 0 24px 0' : '16px 0 24px 0' },
      items: [
        {
          styles: { display: 'flex', margin: '0 0 10px 0', justifyContent: 'space-between' },
          values: [
            {
              text: data?.user_info?.label ? data?.user_info?.label : 'N/A',
            },
            { text: data?.user_info?.value ? data?.user_info?.value : 'N/A', style: { weight: '500' } },
          ],
        },
        {
          styles: { display: 'flex', margin: '0 0 10px 0', justifyContent: 'space-between' },
          values: [
            {
              text: data?.request_info?.label ? data?.request_info.label : 'N/A',
            },
            {
              text: data?.request_info?.value ? getRequiredDateFormat(data?.request_info?.value, 'DD.MM.YYYY') : 'N/A',
              style: { weight: '500' },
            },
          ],
        },
        {
          styles: { display: 'flex', justifyContent: 'space-between' },
          values: [
            {
              text: data?.success_info?.label ? data?.success_info.label : 'N/A',
            },
            {
              text: data?.success_info?.value ? getRequiredDateFormat(data?.success_info?.value, 'DD.MM.YYYY') : 'N/A',
              style: { weight: '500' },
            },
          ],
        },
      ],
    },
    buttons: {
      styles: { display: 'flex', flexDirection: phone ? 'column' : 'row', justifyContent: 'flex-end' },
      values: phone ? dataCalibrationDetailsButtons.reverse() : dataCalibrationDetailsButtons,
    },
  };

  const dataCalibration = {
    title: {
      styles: { display: 'flex', whiteSpace: 'pre-line' },
      value: dataCalibrationTitle,
      tooltipText: dataCalibrationDetailsTooltip,
      tooltipLink: data?.knowledgebase_url,
    },
    content: {
      styles: { margin: phone ? '24px 0 24px 0' : '12px 0 32px 0' },
      items: [
        {
          values: [
            {
              text: dataCalibrationContent,
            },
          ],
        },
      ],
    },
    buttons: {
      styles: { display: 'flex', flexDirection: phone ? 'column' : 'row', justifyContent: 'flex-end' },
      values: phone ? dataCalibrationButtons.reverse() : dataCalibrationButtons,
    },
  };

  const dataCalibrationInProgress = {
    title: {
      styles: { display: 'flex' },
      value: dataCalibrationInProgressTitle,
      tooltipText: dataCalibrationDetailsTooltip,
      tooltipLink: data?.knowledgebase_url,
    },
    content: {
      styles: { margin: phone ? '24px 0 24px 0' : '12px 0 32px 0' },
      items: [
        {
          values: [
            {
              text: dataCalibrationInProgressContent,
            },
          ],
        },
      ],
    },
    buttons: {
      styles: { display: 'flex', flexDirection: phone ? 'column' : 'row', justifyContent: 'flex-end' },
      values: [
        {
          onClick: () => {
            setIsThirdStep(false);
            setCloseModal();
          },
          fontSize: '14px',
          width: phone ? '100%' : '116px',
          height: phone ? '40px' : '32px',
          text: closeButtonText,
        },
      ],
    },
  };

  return (
    <>
      {data?.in_progress ? (
        <CommonModal isOpenModal={isOpenModal} isCalibration data={dataCalibrationInProgress} />
      ) : (
        <>
          <CommonModal isOpenModal={isFirstStep} data={dataCalibrationDetails} />
          <CommonModal isOpenModal={isSecondStep} data={dataCalibration} />
          <CommonModal isOpenModal={isThirdStep} data={dataCalibrationInProgress} />
        </>
      )}
    </>
  );
};
