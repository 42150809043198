import React, { useEffect } from 'react';
import styled from '@cthings.co/styled-components';
import { useCustomHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../../routes/routeInterfaces';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div``;

const StyledText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
` as any; // @TODO fix typee

export const DeviceIdLink = ({ data }: { data: any[] }) => {
  const theme = useTheme();
  const { blue2 } = theme.colors;
  const customHistory = useCustomHistory();

  return (
    <Wrapper>
      {data.map((item: any, index: number, array: any) => {
        const { transport_id, id } = item;
        return (
          <StyledText
            key={index}
            fontSize={'14px'}
            weight={'500'}
            lineHeight={'24px'}
            color={blue2}
            onClick={() => {
              const to = View.USER_MANAGE_DEVICES_DETAILS;
              customHistory.push(to, {
                pathParams: { id: id, offset: 0 },
              });
            }}
            type={TextType.TEXT_14_BLACK}
          >
            {array.length > 1
              ? array.length - 1 !== index
                ? `${array[index].transport_id}, `
                : `${array[index].transport_id}`
              : transport_id}
          </StyledText>
        );
      })}
    </Wrapper>
  );
};
