/**
 * Custom tooltip for the tooltip component of Recharts library
 */

import React from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  borderRadius: string;
};

export const Wrapper = styled.div<WrapperProps>`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background-color: ${colorFetch('black1')};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: 10px;
`;

type TextType = {
  fontWeight: number;
  color: string;
};

export const Text = styled.span<TextType>`
  font-family: 'Poppins';
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 13px;
  line-height: 16px;
  color: ${({ color }) => color};
  text-align: center;
  white-space: pre-line;
  &.tooltipValue {
    max-width: 175px;
  }
`;

export const CustomTooltip = ({ data }: { data: any }) => {
  const theme = useTheme();
  const { pureWhite, orange2 } = theme.colors;
  const { additional4 } = theme.borderRadius;

  const { active } = data;
  const payload = active && data?.payload[0]?.payload;

  return (
    active &&
    payload && (
      <Wrapper borderRadius={additional4}>
        <Text color={pureWhite} fontWeight={600}>
          {payload.tooltipTitle}
        </Text>
        <Text color={pureWhite} fontWeight={400}>
          {payload.tooltipDate}
        </Text>
        {payload.tooltipValue && (
          <Text color={orange2} fontWeight={400} className={'tooltipValue'}>
            {payload.tooltipValue}
          </Text>
        )}
      </Wrapper>
    )
  );
};
