import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAccess, selectLanguageStrings, selectUserAccessManagement } from '../../../../../../../app/state/user';
import { API_URL } from '../../../../../../../consts';
import { useTableFunctions, withTableContextProvider } from '../../../../../../../features/universalTable/context';
import {
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../../../../../features/universalTable/types';
import { UniversalTable } from '../../../../../../../features/universalTable/UniversalTable';
import { PATHS } from '../../../../../../../routes/paths';
import { View } from '../../../../../../../routes/routeInterfaces';
import { useMediaType } from '@cthings.co/styles-utils';
import { useHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../../../../../utils/useQueryParams';
import { EventField } from './components/eventField/EventField';

const TableControl = ({ pathParams, isInDevices }: { pathParams: any; isInDevices?: boolean }) => {
  const keyword = 'EventsTable';
  const offsetKey = 'offsetEvents';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const id = pathParams.id;

  const theme = useTheme();

  const { gray1 } = theme.colors;

  const { desktop, tablet } = useMediaType();

  const { resetTable } = useTableFunctions();
  const history = useHistory();
  const isUserAccessManagementData = useSelector(selectUserAccessManagement);
  const isAccess = useSelector(selectIsAccess);
  const { view: isUserAccessManagementView } = isUserAccessManagementData;
  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    if (isAccess && !isUserAccessManagementView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isUserAccessManagementView]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <UniversalTable
      placeholderProps={{
        keyword,
        style: { height: 'max-content' },
        conditionalStyle: { overflow: 'hidden' },
        placeholderOptions: { countRows: 6, noScrollPrevention: true },
      }}
      keyword={keyword}
      offset={+offset}
      offsetKey={offsetKey}
      mainApiUrl={`${API_URL}/v2/logical-set/${id}/insight/events`}
      tablePath={View.USER_PAIR_DEVICE_INSIGHTS}
      pathParams={pathParams}
      queryParams={queryParams}
      sortingData={sortingData}
      sortingKeys={sortingKeys}
      withHeaderInMobile={true}
      withHeaderGridTemplate={tablet ? '170px 2fr 24px' : desktop ? '170px 170px 1fr' : '1fr 1fr 2fr 4fr'}
      pageSize={10}
      inlineActionSet={
        tablet
          ? [
              {
                type: InlineActionType.DETAILS,
              },
            ]
          : []
      }
      columnStructureTriggers={[desktop, tablet]}
      columnStructure={[
        {
          fieldName: 'ts',
          placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
          label: languageStrings.problemsDate,
          defaultValue: '',
          excludedFromView: desktop,
          excludedFromAdd: desktop,
          excludedFromModalView: desktop,
          type: RowItemType.CUSTOM,
          component: (value: any, state) => (
            <Text
              type={TextType.TEXT_14_BLACK}
              color={gray1}
              margin={!desktop && state === TableCustomComponentState.VIEW_MODAL ? '8px 12px' : '0'}
              weight={!desktop && state === TableCustomComponentState.VIEW_MODAL ? '400' : '500'}
            >
              {moment(value).format('DD.MM.YYYY') || 'N/A'}
            </Text>
          ),
        },
        {
          fieldName: 'ts',
          placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
          label: languageStrings.insightDetailsEventTime,
          defaultValue: '',
          excludedFromView: desktop,
          excludedFromAdd: desktop,
          excludedFromModalView: desktop,
          type: RowItemType.CUSTOM,
          component: (value: any, state) => (
            <Text
              type={TextType.TEXT_14_BLACK}
              color={gray1}
              margin={!desktop && state === TableCustomComponentState.VIEW_MODAL ? '8px 12px' : '0'}
              weight={!desktop && state === TableCustomComponentState.VIEW_MODAL ? '400' : '500'}
            >
              {moment(value).format('HH:mm') || 'N/A'}
            </Text>
          ),
        },
        {
          fieldName: 'ts',
          placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
          label: languageStrings.insightDetalisEventDateAndTime,
          defaultValue: '',
          excludedFromView: !desktop,
          shownInHeader: desktop,
          excludedFromAdd: !desktop,
          excludedFromModalView: !desktop,
          type: RowItemType.CUSTOM,
          component: (value: any, state) => (
            <Text
              type={TextType.TEXT_14_BLACK}
              color={gray1}
              margin={desktop && state === TableCustomComponentState.VIEW_MODAL ? '8px 12px' : '0'}
              weight={desktop && state === TableCustomComponentState.VIEW_MODAL ? '400' : '500'}
            >
              {moment(value).format('DD.MM.YYYY, HH:mm') || 'N/A'}
            </Text>
          ),
        },
        {
          fieldName: 'type',
          placeholder: '',
          label: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
          defaultValue: '',
          shownInHeader: true,
          type: RowItemType.INPUT,
        },
        {
          fieldName: 'event',
          placeholder: languageStrings.manageClientsTableRowPlaceholdersEmail,
          label: languageStrings.insightDetailsEvent,
          defaultValue: 'user@example.com',
          excludedFromView: tablet,
          type: RowItemType.CUSTOM,
          component: (value: any, state?: TableCustomComponentState) => (
            <EventField value={value} state={state} isInDevices={isInDevices} />
          ),
        },
      ]}
    />
  );
};

export const EventTable = withTableContextProvider(TableControl);
