import React, { FC, useState, useRef, useEffect } from 'react';
// @ts-ignore
import { Map } from 'react-map-gl';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { CustomMarker } from './components/customMarker/CustomMarker';
import { ReactComponent as Location } from '../../assets/location_address.svg';
import { useMapResizeControl } from '../../features/mapResizeControl/useMapResizeControl';
import { PlaceholderMap } from '../placeholders/components/PlaceholderMap';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  z-index: 1;
`;

const SemiWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.additional10};

  & > div {
    height: 100% !important;
  }

  ${media.tablet} {
    & > div {
      width: 100%;
    }
  }
`;

export interface CenterCoordsType {
  lat: number;
  lon: number;
}

export interface AddressMapProps {
  zoom: number;
  latitude?: number;
  longitude?: number;
  centerCoords?: CenterCoordsType;
  className?: string;
  mapToken: string;
  handleSetCentre?: any;
  isGps?: boolean;
}

//@TODO Alex split this component into smaller ones

export const AddressMap: FC<AddressMapProps> = ({
  zoom,
  latitude,
  longitude,
  centerCoords,
  handleSetCentre,
  mapToken,
  isGps,
  ...props
}) => {
  const [viewport, setViewport] = useState({
    latitude: latitude,
    longitude: longitude,
    width: '100%',
    height: '100%',
    zoom: zoom || 12,
  });

  const theme = useTheme();

  const mapReference = useRef();

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    setViewport(() => {
      return {
        ...viewport,
        latitude: centerCoords && centerCoords.lat,
        longitude: centerCoords && centerCoords.lon,
      };
    });
  }, [centerCoords]);

  useEffect(() => {
    setViewport((currentViewport) => ({
      ...currentViewport,
      zoom,
    }));
  }, [zoom]);

  const handleViewportChange = (viewState: any) => {
    setViewport((oldViewPort: any) => {
      handleSetCentre({ lat: viewState.latitude, lng: viewState.longitude });

      return isGps
        ? {
            ...oldViewPort,
            zoom: viewState.zoom,
          }
        : viewState;
    });
  };

  //resize map
  const mapbox = useMapResizeControl(setViewport);

  const mapProps = {
    ...viewport,
    mapboxAccessToken: mapToken,
    ref: mapReference as any,
    onResize: (e: any) => {},
    onMove: ({ viewState }: any) => handleViewportChange(viewState),
    transitionInterruption: 'IGNORE',
    mapStyle: 'mapbox://styles/mapbox/light-v11',
    attributionControl: false,
  };

  return (
    <Wrapper {...props}>
      <SemiWrapper ref={mapbox} theme={theme}>
        <Map {...mapProps} onLoad={() => setIsMapLoaded(true)}>
          <CustomMarker latitude={viewport.latitude} longitude={viewport.longitude} setCentre={null}>
            <Location />
          </CustomMarker>
        </Map>
        {!isMapLoaded && <PlaceholderMap />}
      </SemiWrapper>
    </Wrapper>
  );
};

AddressMap.defaultProps = {
  zoom: 12,
  latitude: 52.25603,
  longitude: 20.98765,
  centerCoords: { lat: 52.25603, lon: 20.98765 },
};
