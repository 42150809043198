import React, { FC, useState } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { ItemsList } from './components/ItemsList';
import { useNotifierFunctions } from '../../../notifier2';
import { checkFieldValidity } from '../../../../utils/validation';
import {
  pushAction,
  selectOrganisationUsers,
  selectParentOrganisation,
  updateParentOrganisation,
  selectSelectedDevices,
  selectParentOrganisationsList,
  selectProfiles,
} from '../../../../app/state/organisations';
import {
  ButtonRow,
  AreaTitle,
  EditButton,
  InputsContainer,
  InputsWrapper,
  SubtitleRow,
  CancelButton,
  SaveButton,
  StyledSelect,
  HeadWrapper,
} from './styled';
import { getInputStructure } from './utils';
import { Address } from '../../../../types/address';
import { InputFieldName } from './types';
import { TableCustomComponentState } from '../../../universalTable/types';
import { AddressInput } from '../../../../components/addressInput/AddressInput';
import { InputModal } from '../../../../components/inputModal/InputModal';
import { CustomModal } from '../customModal/CustomModal';
import { finaliseCreation } from '../../../../api/organisation';
import { getProfiles } from '../../../../api/manage/manageClients';
import { debounce } from '../../../../utils/throttling';
import { useStyleContext } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { CloseButton } from '../closeButton/CloseButton';

interface SummaryProps {
  isOpen: boolean;
}

export const Summary: FC<SummaryProps> = ({ isOpen }) => {
  const devices = useSelector(selectSelectedDevices);
  const theme = useTheme();
  const { black1, gray2, gray1, pureWhite, primary, secondary2 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);
  const profilesList = useSelector(selectProfiles);
  const { addNotification } = useNotifierFunctions();
  const [startedValidation, setStartedValidation] = useState(false);
  const { name, phone, email, address, parentOrganisation, profile } = useSelector(selectParentOrganisation);
  const users = useSelector(selectOrganisationUsers);
  const parentOrganisationsList = useSelector(selectParentOrganisationsList);
  const inputValues = {
    name,
    phone,
    parentOrganisation,
    profile,
    email,
    address: `${address.city ? address.city + ', ' : ''}${address.line1}`,
  };
  const dispatch = useDispatch();
  const [{ mediaType }] = useStyleContext();
  const { phone: phoneStyle } = mediaType;

  const handleBack = () => {
    dispatch(pushAction('back'));
  };

  const inputStructure = getInputStructure(languageStrings);

  const isFormValid = inputStructure.every(({ fieldName, validationRule }) =>
    checkFieldValidity({
      value:
        fieldName === 'parentOrganisation' || fieldName === 'profile'
          ? inputValues[fieldName].name
          : inputValues[fieldName],
      validationRule,
    }),
  );
  const handleInputOnChange = (e: any, fieldName: InputFieldName) => {
    const isParentOrgField = fieldName === 'parentOrganisation';
    const isProfileField = fieldName === 'profile';
    if (isParentOrgField || isProfileField) {
      dispatch(updateParentOrganisation({ [fieldName]: e }));
      if (isParentOrgField) {
        dispatch(updateParentOrganisation({ profile: { name: '', id: '' } }));
        getProfiles(e.id, dispatch, addNotification, languageStrings);
      }
    } else {
      dispatch(updateParentOrganisation({ [fieldName]: e.currentTarget.value }));
    }
  };

  const orgCreationSuccessText = languageStrings.organisationCreationSuccess;
  const validationRequestText = languageStrings.validationFillFieldsRequest;
  const somethingWentWrongtext = languageStrings.somethingWentWrong;

  const handleValidationNotification = () => {
    addNotification({
      message: validationRequestText,
      type: 'error',
    });
  };

  const handleCallbackNotification = (isSuccess: boolean, messageText?: string) => {
    return addNotification({
      message: messageText || (isSuccess ? orgCreationSuccessText : somethingWentWrongtext),
      type: isSuccess ? 'success' : 'error',
    });
  };

  const handleFinalise = debounce(() => {
    setStartedValidation(true);
    if (isFormValid) {
      const usersPrep = users.map(({ name, email, role }) => ({ name, email, role, company: '' }));
      const devicesPrep = devices.map(({ type, transport_id, id, plansets_info }) => ({
        type,
        transport_id,
        id,
        planset_id: plansets_info.device_planset.id,
      }));
      finaliseCreation(
        {
          client: { name, phone, email, address, profile: profile.id, parent: parentOrganisation.id },
          users: usersPrep,
          devices: devicesPrep,
        },
        handleCallbackNotification,
      );
    } else {
      handleValidationNotification();
    }
  }, 700);

  const handleEditUsers = () => {
    dispatch(pushAction('editUsers'));
  };

  const handleEditDevices = () => {
    dispatch(pushAction('editDevices'));
  };

  const setAddress = (address: Address) => {
    dispatch(updateParentOrganisation({ address }));
  };

  const summaryTitle = languageStrings.summaryTitle;
  const summarySubtitle = languageStrings.summarySubtitle;
  const summaryCompanyRowTitle = languageStrings.organisationLabel;
  const summaryUsersRowTitle = languageStrings.summaryUsersRowTitle;
  const insightDetailsDeviceConfigHeaderEdit = languageStrings.insightDetailsDeviceConfigHeaderEdit;
  const summaryDevicesRowTitle = languageStrings.summaryDevicesRowTitle;
  const backButton = languageStrings.backButton;
  const finalizeButton = languageStrings.finalizeButton;

  return (
    <CustomModal isOpen={isOpen}>
      <HeadWrapper>
        <Text type={TextType.TEXT_14_BLACK} color={black1} fontSize={'18px'} weight={'600'}>
          {summaryTitle}
        </Text>
        <CloseButton />
      </HeadWrapper>
      <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'} margin={'12px 0 0 0'}>
        {summarySubtitle}
      </Text>
      <AreaTitle>
        <Text type={TextType.TEXT_14_BLACK} color={gray1}>
          {summaryCompanyRowTitle}
        </Text>
      </AreaTitle>
      <InputsWrapper>
        {inputStructure.map((el) => {
          const isLocationSelect = el.fieldName === 'address';
          const isParentOrganisationSelect = el.fieldName === 'parentOrganisation';
          const isProfileSelect = el.fieldName === 'profile';
          const { fieldName, label, placeholder, validationRule, flex: flexRow } = el;
          const validationOptions = {
            isError: !checkFieldValidity({
              value:
                isProfileSelect || isParentOrganisationSelect ? inputValues[fieldName].name : inputValues[fieldName],
              validationRule,
            }),
            startedValidation,
          };

          const flex = flexRow || '1';

          return (
            <InputsContainer flex={flex}>
              {isLocationSelect ? (
                <AddressInput
                  value={inputValues[fieldName]}
                  title={label}
                  placeholder={placeholder}
                  address={address}
                  setAddress={setAddress}
                  addressState={TableCustomComponentState.EDIT}
                  {...validationOptions}
                />
              ) : isParentOrganisationSelect || isProfileSelect ? (
                <StyledSelect
                  width={phoneStyle ? '100%' : '248px'}
                  value={inputValues[fieldName]}
                  values={isParentOrganisationSelect ? parentOrganisationsList : profilesList}
                  label={label}
                  placeholder={
                    isProfileSelect && !parentOrganisation.name.length ? 'Choose parent org. first' : placeholder
                  }
                  disabled
                  onChange={(e: any) => handleInputOnChange(e, el.fieldName)}
                  languageStrings={languageStrings}
                  {...validationOptions}
                />
              ) : (
                <InputModal
                  value={inputValues[fieldName]}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => handleInputOnChange(e, el.fieldName)}
                  name={label}
                  placeholder={placeholder}
                  {...validationOptions}
                />
              )}
            </InputsContainer>
          );
        })}
      </InputsWrapper>
      <SubtitleRow>
        <Text type={TextType.TEXT_14_BLACK} color={gray1}>
          {summaryUsersRowTitle}: {users.length}
        </Text>
        <EditButton type={TextType.TEXT_14_BLACK} color={gray2} onClick={handleEditUsers}>
          {insightDetailsDeviceConfigHeaderEdit}
        </EditButton>
      </SubtitleRow>
      <ItemsList parameterList={users} languageStrings={languageStrings} />
      <SubtitleRow>
        <Text type={TextType.TEXT_14_BLACK} color={gray1}>
          {summaryDevicesRowTitle}: {devices.length}
        </Text>
        <EditButton type={TextType.TEXT_14_BLACK} color={gray2} onClick={handleEditDevices}>
          {insightDetailsDeviceConfigHeaderEdit}
        </EditButton>
      </SubtitleRow>
      <ItemsList parameterList={devices} languageStrings={languageStrings} />
      <ButtonRow>
        <CancelButton
          colors={{
            main: black1,
            background: pureWhite,
            backgroundHover: primary,
            borderHover: primary,
          }}
          onClick={handleBack}
        >
          {backButton}
        </CancelButton>
        <SaveButton
          colors={{
            main: pureWhite,
            background: primary,
            backgroundHover: secondary2,
            borderHover: primary,
          }}
          onClick={handleFinalise}
        >
          {finalizeButton}
        </SaveButton>
      </ButtonRow>
    </CustomModal>
  );
};
