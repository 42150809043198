import { CommonButton } from '@cthings.co/buttons';
import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as FileIcon } from './assets/FileIcon.svg';
import { ReactComponent as InfoIcon } from './assets/InfoIcon.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/Close.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const HeaderStepNumberWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TitleText = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const StepNumber = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

type LabelProps = {
  isFilePicked: boolean;
  isUploadingFile: boolean;
  isIncorrectTypeOfFile: boolean;
  isError: boolean;
};

export const Label = styled.label<LabelProps>`
  width: 100%;
  height: 112px;
  /* padding: 44px 0; */
  margin-top: 16px;
  ${media.tablet} {
    margin-top: 34px;
    padding: 0;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  background-color: ${({ isFilePicked, isIncorrectTypeOfFile, isError, theme }) =>
    isFilePicked && !isIncorrectTypeOfFile && !isError
      ? colorFetch('gray5')({ theme })
      : colorFetch('pureWhite')({ theme })};

  border: ${({ isFilePicked, isIncorrectTypeOfFile, isError }) =>
    isFilePicked && !isIncorrectTypeOfFile && !isError ? 'none' : '1px dashed'};

  border-color: ${({ isIncorrectTypeOfFile, isError, theme }) =>
    isIncorrectTypeOfFile && isError ? colorFetch('red2')({ theme }) : colorFetch('gray3')({ theme })};

  border-radius: 8px;
  cursor: ${({ isUploadingFile }) => (isUploadingFile ? 'default' : 'pointer')};
  transition: all 0.3s linear;

  &:hover {
    background-color: ${({ isFilePicked, isUploadingFile, isIncorrectTypeOfFile, isError, theme }) =>
      (isFilePicked || !isUploadingFile) && !isIncorrectTypeOfFile && !isError
        ? colorFetch('gray5')({ theme })
        : isUploadingFile
        ? colorFetch('pureWhite')({ theme })
        : colorFetch('gray6')({ theme })};

    border-color: ${({ isIncorrectTypeOfFile, isError, theme }) =>
      isIncorrectTypeOfFile && isError ? colorFetch('red2')({ theme }) : colorFetch('gray3')({ theme })};
  }
` as any;

export const UploadInput = styled.input`
  display: none;
  &::-webkit-file-upload-button {
    display: none;
  }
`;

type UpladTextWrapperProps = {
  margin?: string;
};

export const UpladTextWrapper = styled.div<UpladTextWrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ margin }) => margin};
  user-select: none;
`;

export const StyledUpladText = styled(Text)`
  ${media.tablet} {
    margin-top: 2px;
  }
  &.desktopText {
    ${media.tablet} {
      display: none;
    }
  }
  &.mobileText {
    display: none;
    ${media.tablet} {
      display: block;
    }
  }
`;

export const TryAgainText = styled(Text)`
  text-decoration-line: underline;
  margin-top: 6px;
` as any; // @TODO fix type

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  ${media.tablet} {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
`;

export const ButtonBase = styled(CommonButton)`
  max-width: 116px;
  width: 100%;
  margin-right: 16px;
  ${media.tablet} {
    margin-right: 0px;
    margin-top: 10px;
    max-width: 100%;
    height: 40px;
  }
`;

export const SampleButtonWrapper = styled.a`
  width: 100%;
  text-decoration: none;
  margin-right: 16px;
`;

type SampleButtonProps = {
  colorHover: string;
};

export const SampleButton = styled(ButtonBase)<SampleButtonProps>`
  &:hover > svg > path {
    transition: stroke 0.3s ease;
    stroke: ${({ colorHover }) => colorHover};
  }
  & > svg > path {
    transition: stroke 0.3s ease;
  }
` as any; // @TODO fix type

export const SampleIcon = styled(FileIcon)`
  margin-right: 6px;
`;

export const BackButton = styled(ButtonBase)``;

type SkipButtonProps = {
  isDisabled: boolean;
};

export const SkipButton = styled(ButtonBase)<SkipButtonProps>`
  border-color: ${({ isDisabled, theme }) => (isDisabled ? colorFetch('gray3')({ theme }) : '')};
  color: ${({ isDisabled, theme }) => (isDisabled ? colorFetch('gray3')({ theme }) : '')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

type NextButtonProps = {
  isDisabled: boolean;
};

export const NextButton = styled(ButtonBase)<NextButtonProps>`
  margin-right: 0;
  ${media.tablet} {
    margin-top: 0px;
  }
  background-color: ${({ isDisabled, theme }) => (isDisabled ? colorFetch('pureWhite')({ theme }) : '')};
  border-color: ${({ isDisabled, theme }) => (isDisabled ? colorFetch('gray3')({ theme }) : '')};
  color: ${({ isDisabled, theme }) => (isDisabled ? colorFetch('gray3')({ theme }) : '')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

type InfoButtonProps = {
  $hoverColor: string;
};

export const InfoButton = styled(InfoIcon)<InfoButtonProps>`
  margin-left: 6px;

  &:hover > path {
    transition: stroke 0.3s ease;
    stroke: ${({ $hoverColor }) => $hoverColor};
  }
  & > path {
    transition: stroke 0.3s ease;
  }
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
`;
