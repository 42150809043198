var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from '@xstyled/styled-components';
import { LocationImage } from './components/LocationImage';
import { CloseButton } from '../closeButton/CloseButton';
const Wrapper = styled.div `
  width: 327px;
  height: 40px;
  position: relative;
`;
const Input = styled.input `
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: primary;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  outline: none;
  padding: 6px 32px 6px 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: white;
  ::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: white;
    opacity: 0.6;
    letter-spacing: 0.2px;
  }
`;
const LocationImageWrapper = styled.div `
  display: flex;
  align-items: center;
  position: absolute;
  top: 6px;
  left: 7px;
  & > svg {
    width: 28px;
    height: 28px;
  }
  & > svg > path {
    stroke: ${(props) => props.theme.colors.white};
  }
`;
const CloseButtonWrapper = styled.div `
  position: absolute;
  top: 8px;
  right: 8px;
`;
export const SearchInput = (_a) => {
    var { action, value, onChange, languageStrings } = _a, props = __rest(_a, ["action", "value", "onChange", "languageStrings"]);
    const theme = useTheme();
    const { white } = theme.colors;
    return (React.createElement(Wrapper, Object.assign({}, props),
        React.createElement(LocationImageWrapper, { theme: theme },
            React.createElement(LocationImage, null)),
        React.createElement(Input, { placeholder: languageStrings && languageStrings.insightMobilePlaceholder
                ? languageStrings.insightMobilePlaceholder
                : 'Choose a place...', value: value, onChange: onChange, theme: theme }),
        React.createElement(CloseButtonWrapper, null,
            React.createElement(CloseButton, { onClick: action, color: white, hoverColor: white }))));
};
SearchInput.defaultProps = {};
