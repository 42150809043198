import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { PlaceholderBlock } from './components/placeholderBlock';
import { PlaceholderCircle } from './components/placeholderCircle';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Card = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${colorFetch('gray4')};
  padding: 23px;
  position: absolute;
  z-index: var(--placeholder-layer);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Block = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const LargeCard = styled(Card)``;

const ColumnBlocks = styled.div`
  flex: 1;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;

export interface ComponentProps {
  isLargeCard?: boolean;
}

export const PlaceholderCustomCard: FC<ComponentProps> = ({ isLargeCard = false }) => {
  const theme = useTheme();

  return (
    <>
      {!isLargeCard ? (
        <Card theme={theme}>
          <PlaceholderBlock width={'120px'} />
          <Block>
            <PlaceholderBlock width={'80px'} height={'30px'} />
            <PlaceholderBlock width={'60px'} height={'60px'} />
          </Block>
        </Card>
      ) : (
        <LargeCard theme={theme}>
          <PlaceholderBlock width={'120px'} />
          <Block>
            <PlaceholderCircle width={'140px'} height={'140px'} />
            <ColumnBlocks>
              <PlaceholderBlock width={'65px'} />
              <PlaceholderBlock width={'65px'} />
              <PlaceholderBlock width={'65px'} />
              <PlaceholderBlock width={'65px'} />
              <PlaceholderBlock width={'65px'} />
            </ColumnBlocks>
          </Block>
        </LargeCard>
      )}
    </>
  );
};
