import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { useTooltip } from '../../../../components/tooltip';
import { useBlockBodyScroll } from '../../../../utils/useBlockBodyScroll';
import { ReactComponent as MobileLogo } from '../../assets/SmartUtilities.svg';
import { MenuItem, MenuItemType } from '../../MenuItem';
import { Wrapper } from '../../styled';
import { LanguageId } from '../../../../languages/types';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ArrowButton, ArrowSizeType } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';

const BurgerButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  & div {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: ${colorFetch('black')};
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  & .first {
    transform: translateY(calc(-50% - 6px));
  }
  & .third {
    width: 70%;
    transform: translateY(calc(-50% + 6px));
  }
  &.open {
    & .second {
      opacity: 0;
    }
    & .first {
      transform: rotate(45deg) translate(0, 0);
    }
    & .third {
      width: 18px;
      transform: rotate(-45deg) translate(0, 0);
    }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: ${colorFetch('black')};
    opacity: 0;
    transition: opacity 0.3s;
  }
  &.open {
    pointer-events: auto;
    &:after {
      opacity: 0.4;
    }
  }
`;

const FirstMobileMenu = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 270px;
  background-color: ${colorFetch('white')};
  transform: translateX(-100%);
  transition: transform 0.3s;
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.1);
  &.open {
    transform: translateX(0);
  }
  ${media.semiTablet} {
    width: 100%;
  }
`;

const SecondMobileMenu = styled.div`
  position: absolute;
  top: 0;
  left: 270px;
  bottom: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
  background-color: ${colorFetch('white')};
  transform: translateX(-200%);
  transition: 0.3s;
  z-index: 1;
  &.open {
    transform: translateX(0);
    transition: 0.3s;
  }
  ${media.semiTablet} {
    z-index: 10;
    box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.1);
    width: 100%;
    left: 0;
    transform: translateX(-100%);
  }
`;

const BackButton = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  padding: 10px 16px;
  margin: 0;
  height: 45px;
  display: flex;
  align-items: center;
`;

const BackText = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
  margin-left: 10px;
`;

export const ItemWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-left: 18px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  ${media.tablet} {
    display: flex;
    &.active {
      background-color: ${colorFetch('gray6')};
    }
    padding: 10px 16px;
    margin: 0;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 0.5px;
      background-color: ${colorFetch('gray3')};
    }
  }
`;

export interface SecondaryMenuItem {
  name: string;
  to?: string;
  id?: LanguageId;
  shortName?: string;
}

export const MobileNavigation = ({
  menuMobileItemArray,
  onLogout,
  billingAccess,
  withLanguages,
}: {
  onLogout: any;
  menuMobileItemArray: any[];
  withLanguages?: boolean;
  billingAccess: any;
}) => {
  const theme = useTheme();

  const navRef: any = useRef();
  const burgerRef: any = useRef();

  const languageStrings = useSelector(selectLanguageStrings);
  const [isOpenFirstMenu, setIsOpenFirstMenu] = useState(false);

  const [secondaryMenuItems, setSecondaryMenuItems] = useState<SecondaryMenuItem[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const handleBurgerButton = () => {
    setIsOpenFirstMenu((val) => !val);
    setSecondaryMenuItems([]);
  };

  const handleCloseFirstMenu = () => {
    setIsOpenFirstMenu(false);
  };

  const handleCloseSecondMenu = () => {
    setSecondaryMenuItems([]);
  };

  const outsideHandler = useCallback(
    (e: any) => {
      isOpenFirstMenu &&
        !navRef.current.contains(e.target) &&
        !burgerRef.current.contains(e.target) &&
        secondaryMenuItems.length === 0 &&
        setIsOpenFirstMenu(false);
    },
    [isOpenFirstMenu, secondaryMenuItems],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const [tooltipIds] = useTooltip(menuMobileItemArray.length);

  useBlockBodyScroll(isOpenFirstMenu);

  return (
    <Wrapper>
      <BurgerButton ref={burgerRef} className={isOpenFirstMenu ? 'open' : ''} onClick={handleBurgerButton}>
        <div className="first" />
        <div className="second" />
        <div className="third" />
      </BurgerButton>
      <MobileLogo />
      <Modal className={isOpenFirstMenu ? 'open' : ''}>
        <FirstMobileMenu ref={navRef} className={isOpenFirstMenu ? 'open' : ''}>
          {menuMobileItemArray.map((item, index) => {
            const { value, route, type, disabled, hidden, childRoutes } = item;

            return (
              !(
                (type === MenuItemType.BILLING && billingAccess.size === 1 && billingAccess.has(false)) ||
                (type === MenuItemType.BILLING && !billingAccess.size)
              ) && (
                <MenuItem
                  key={index}
                  type={type}
                  value={value}
                  to={route}
                  disabled={disabled}
                  hidden={hidden}
                  tooltipId={tooltipIds[index]}
                  childRoutes={childRoutes ? childRoutes : null}
                  handleCloseFirstMenu={handleCloseFirstMenu}
                  secondaryMenuItems={secondaryMenuItems}
                  setSecondaryMenuItems={setSecondaryMenuItems}
                  isOpen={false}
                />
              )
            );
          })}
          <div style={{ flex: 1, minHeight: '50px' }}></div>
          {withLanguages && (
            <MenuItem
              type={MenuItemType.LANGUAGES}
              isOpen={isOpen}
              secondaryMenuItems={secondaryMenuItems}
              setSecondaryMenuItems={setSecondaryMenuItems}
            />
          )}
          <MenuItem
            type={MenuItemType.LOGOUT}
            isOpen={true}
            onLogout={onLogout}
            handleCloseFirstMenu={handleCloseFirstMenu}
          />
        </FirstMobileMenu>

        <SecondMobileMenu className={secondaryMenuItems.length ? 'open' : ''}>
          <BackButton onClick={handleCloseSecondMenu}>
            <ArrowButton size={ArrowSizeType.SMALL} />
            <BackText>{languageStrings.backButton}</BackText>
          </BackButton>
          {secondaryMenuItems &&
            secondaryMenuItems.map((item, i) => {
              const { name, to } = item;

              return (
                <MenuItem
                  to={to ? to : ''}
                  isOpen={isOpen}
                  value={name}
                  handleCloseFirstMenu={handleCloseFirstMenu}
                  setSecondaryMenuItems={setSecondaryMenuItems}
                  type={MenuItemType.SECOND_MENU}
                  index={i}
                />
              );
            })}
        </SecondMobileMenu>
      </Modal>
    </Wrapper>
  );
};
