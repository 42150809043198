import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as NoDataImage } from '../../../../assets/No_Data.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorFetch('white')};
`;

export const NoDataPlaceholder = ({ ...props }: { className?: string }) => {
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper {...props}>
      <NoDataImage />
      <Text type={TextType.TEXT_14_GRAY}>{languageStrings.noDataPlaceholderText}</Text>
    </Wrapper>
  );
};
