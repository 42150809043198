import React from 'react';
import { TextareaModal } from '../../../../../../../../../../../components/textareaModal/TextareaModal';

export const EventViewModal = ({ value }: { value: string }) => {
  return (
    <>
      <TextareaModal
        value={value}
        onChange={() => {}}
        onBlur={() => {}}
        onClick={() => {}}
        onFocus={() => {}}
        placeholder={''}
        disabled={true}
      />
    </>
  );
};
