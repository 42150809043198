import React, { FC, FormEvent, useEffect } from 'react';
import {
  ButtonContainer,
  ButtonWrapper,
  CancelButton,
  CheckboxWrapper,
  InputsWrapper,
  SaveButton,
  StyledCheckboxText,
  StyledModal,
  StyledModalContainer,
  StyledTitle,
  Wrapper,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user/selectors';
import { setIsOpenDeliveryAddressModal } from '../../../../../../app/state/billingDetailsSlice';
import { TextType } from '@bit/first-scope.text';
import { InputModal } from '../../../../../../components/inputModal/InputModal';
import { AddressInput } from '../../../../../../components/addressInput/AddressInput';
import { RowItemType, TableCustomComponentState } from '../../../../../../features/universalTable/types';
import { CheckBox } from '@bit/first-scope.controls';
import { updateDeliveryAddress } from '../../../../../../api/manage/manageBilling';
import { useInputStructure } from './utils';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { useTheme } from '@cthings.co/styled-components';

interface DeliveryDetailsModalPropsType {
  getRowEssentials: any;
}

export const DeliveryDetailsModal: FC<DeliveryDetailsModalPropsType> = ({ getRowEssentials }) => {
  const theme = useTheme();
  const { black1, gray1 } = theme.colors;
  const { additional6 } = theme.borderRadius;

  const languageStrings = useSelector(selectLanguageStrings);

  const dispatch = useDispatch();

  const { addNotification } = useNotifierFunctions();

  const [
    {
      inputStructure,
      isFormValid,
      state: { name, address: addressState },
      isChecked,
      isLoaded,
    },
    { setStartedValidation, processRowData, handleRevert, setIsChecked },
  ] = useInputStructure({ getRowEssentials });

  const onClickCancelButton = () => {
    handleRevert();
    dispatch(setIsOpenDeliveryAddressModal(false));
  };

  const onClickSaveButton = () => {
    setStartedValidation(true);
    if (isFormValid) {
      setStartedValidation(false);

      updateDeliveryAddress({
        body: {
          name: name,
          address: addressState,
        },
        handleError: (data: any) => {
          addNotification({
            message: data?.response?.data?.detail ?? languageStrings.somethingWentWrong,
            type: 'error',
          });
        },
        handleSuccess: (data: any) => {
          addNotification({
            message: languageStrings.successSaveMessage,
            type: 'success',
          });
        },
      });
      dispatch(setIsOpenDeliveryAddressModal(false));
    }
  };

  useEffect(() => {
    isFormValid && setStartedValidation(false);
  }, [isFormValid]);

  return (
    <StyledModal isOpenModal={true}>
      <StyledModalContainer>
        <Wrapper>
          <StyledTitle type={TextType.TEXT_14_BLACK} color={black1}>
            {languageStrings.editDeliveryAddressTitle}
          </StyledTitle>

          <InputsWrapper>
            <CheckboxWrapper borderRadius={additional6}>
              <CheckBox
                label=""
                toggle={isChecked}
                handleToggle={() => {
                  setIsChecked((val: boolean) => !val);
                }}
              />
              <StyledCheckboxText type={TextType.TEXT_14_BLACK} color={gray1}>
                {languageStrings.sameAsBillingInfo}
              </StyledCheckboxText>
            </CheckboxWrapper>

            {inputStructure.map((el: any, index) => {
              const { type, value, label, placeholder } = el;

              const [{ readableAddress, validationOptions }, { handleOnChange }] = processRowData(el);

              return (
                <>
                  {type === RowItemType.ADDRESS ? (
                    isLoaded && (
                      <AddressInput
                        title={label}
                        value={readableAddress}
                        placeholder={placeholder}
                        isDisabled={isChecked}
                        addressState={TableCustomComponentState.EDIT}
                        address={addressState}
                        setAddress={handleOnChange}
                        isDescriptionModal={true}
                        {...validationOptions}
                      />
                    )
                  ) : (
                    <InputModal
                      name={label}
                      placeholder={placeholder}
                      value={value}
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        handleOnChange(e.currentTarget.value);
                      }}
                      disabled={isChecked}
                      width={'100%'}
                      margin={'0'}
                      {...validationOptions}
                    />
                  )}
                </>
              );
            })}
          </InputsWrapper>

          <ButtonWrapper>
            <ButtonContainer>
              <CancelButton onClick={onClickCancelButton}>{languageStrings.cancelButton}</CancelButton>
              <SaveButton onClick={onClickSaveButton}>{languageStrings.saveButton}</SaveButton>
            </ButtonContainer>
          </ButtonWrapper>
        </Wrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
