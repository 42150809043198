import React from 'react';
import { MenuItemType } from '../MenuItem';
//images
import { ReactComponent as DashboardImage } from '../../../assets/Dashboard.svg';
import { ReactComponent as InsightImage } from '../../../assets/insight.svg';
import { ReactComponent as IncidentsIcon } from '../../../assets/IncidentsIcon.svg';
import { ReactComponent as SettingsImage } from '../../../assets/Settings.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/reports_icon.svg';
import { ReactComponent as Documents } from '../../../assets/Documents.svg';
import { ReactComponent as LogOutImage } from '../../../assets/Logout.svg';
import { ReactComponent as PieChart } from '../../../assets/pie-chart.svg';
import { ReactComponent as ManageImage } from '../../../assets/Manage.svg';
import { ReactComponent as BillingImage } from '../../../assets/Billing.svg';

export const ItemImage = ({ type }: { type: MenuItemType }) => {
  switch (type) {
    case MenuItemType.DASHBOARD:
      return <DashboardImage />;
    case MenuItemType.INSIGHT:
      return <InsightImage />;
    case MenuItemType.INCIDENTS:
      return <IncidentsIcon />;
    case MenuItemType.SETTINGS:
      return <SettingsImage />;
    case MenuItemType.REPORTS:
      return <ReportsIcon />;
    case MenuItemType.DOCUMENTS:
      return <Documents />;
    case MenuItemType.LOGOUT:
      return <LogOutImage />;
    case MenuItemType.MANAGE:
      return <ManageImage />;
    case MenuItemType.ANALYTICS:
      return <PieChart />;
    case MenuItemType.BILLING:
      return <BillingImage />;
    default:
      return <></>;
  }
};
