import React, { FC, useEffect, useState, useCallback } from 'react';
// @ts-ignore
import { Map, Marker } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import styled from '@cthings.co/styled-components';
import { ManholeButton } from '../../components/manholeButton/ManholeButton';
import { getRequiredDateFormat } from '../../utils/date/date-format';
import { injectPathParams, PATHS } from '../../routes/paths';
import { media } from '@cthings.co/styles-utils';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';
import { useMapResizeControl } from '../../features/mapResizeControl/useMapResizeControl';
import { OverlayGradient } from '../overlayGradient/OverlayGradient';
import { CustomMarker } from './components/customMarker/CustomMarker';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { Text, TextType } from '@bit/first-scope.text';
import { hexToRGBA } from '../../utils/hexToRgb';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

interface CallbackRef {
  current: any;
}

export enum ErrorListMapType {
  DASHBOARD = 'DASHBOARD',
  INSIGHT = 'INSIGHT',
}

export enum ManholesType {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
  NONE = 'NONE',
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  ${media.tablet} {
    border-radius: 0;
  }
`;

const SemiWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.additional10};
  & > div {
    height: 100% !important;
  }
  ${media.tablet} {
    & > div {
      width: 100%;
    }
  }
`;

type ClusterProps = {
  size: number;
  type: ErrorListMapType;
};

const Cluster = styled.div<ClusterProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ type, theme }) =>
    type === ErrorListMapType.DASHBOARD ? colorFetch('red1')({ theme }) : colorFetch('primary')({ theme })};
  color: ${colorFetch('white')};
  font-size: 14px;
  font-weight: 500;
  border-radius: 50%;
  border: 3px solid;
  border-color: ${({ theme }) => hexToRGBA(theme.colors.pureWhite, 0.7)};
  cursor: pointer;
`;

const ClusterText = styled(Text)`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  line-height: 22px;
` as typeof Text;

export interface CenterCoordsType {
  lat: number;
  lon: number;
}

export interface ErrorMapProps {
  type: ErrorListMapType;
  zoom: number;
  latitude?: number;
  longitude?: number;
  centerCoords?: CenterCoordsType;
  isGradiented?: boolean;
  staticDisplay?: boolean;
  deviceList: any[];
  className?: string;
  setCentre?: (el: any) => void;
  mapToken: string;
  viewport: {
    latitude: number | undefined;
    longitude: number | undefined;
    width: string;
    height: string;
    zoom: number;
    transitionInterpolator?: any;
    transitionDuration: number;
  };
  setViewport: any;
  setSelectedDevice?: any;
}

//@TODO Alex split this component into smaller ones

const ErrorListMapPlain: FC<ErrorMapProps> = ({
  type,
  zoom,
  isGradiented,
  latitude,
  longitude,
  centerCoords,
  staticDisplay,
  deviceList,
  mapToken,
  setCentre,
  viewport,
  setViewport,
  setSelectedDevice,
  ...props
}) => {
  const mapbox = useMapResizeControl(setViewport);

  const theme = useTheme();

  const points = deviceList.map((values: any) => {
    return {
      type: 'Feature',
      properties: {
        cluster: false,
        values: values.map((value: any) => ({
          type_event: value.event_type,
          manholeId: value.id,
          name: value.name,
          temperature: !staticDisplay && `${value.rt_data.temp.measurement} ${value.rt_data.temp.unit}`,
          waterLevel: !staticDisplay && `${value.rt_data.water_level.measurement} ${value.rt_data.water_level.unit}`,
          date: !staticDisplay && getRequiredDateFormat(value.rt_data.ts, 'DD.MM.YYYY'),
          time: !staticDisplay && getRequiredDateFormat(value.rt_data.ts, 'HH:mm'),
          address: value.address.line1,
          to: !staticDisplay && injectPathParams(PATHS.USER_INSIGHTS_DETAILS, { id: value.id }),
          coordinates: `${value.address.geotag.lat}, ${value.address.geotag.lng}`,
          device_type: value.type,
        })),
      },
      geometry: {
        type: 'Point',
        coordinates: [values[0].address.geotag.lng, values[0].address.geotag.lat],
      },
      countOfDevices: values.length,
    };
  });
  const [mapnode, setMapnode] = useState<CallbackRef>({ current: undefined });

  const mapReference = useCallback((node: any) => {
    if (node !== null) {
      setMapnode({ current: node });
    }
  }, []);

  const bounds = mapnode.current ? mapnode.current.getMap().getBounds().toArray().flat() : null;

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewport.zoom,
    bounds,
    options: {
      radius: 100,
      map: (props: any) => ({
        count: props.values.length,
      }),
      reduce: (acc: any, props: any) => {
        acc.count += props.count;
        return acc;
      },
    },
  });

  const getExpansionZoom = (clusterId: string) => Math.min(supercluster.getClusterExpansionZoom(clusterId), 20);

  const onSelectMarker = (longitude: number, latitude: number, zoom?: number, duration?: number) => {
    mapnode.current?.easeTo({
      center: [longitude, latitude],
      zoom,
      duration: duration || 400,
    });
  };

  const handleClusterClick = (latitude: number, longitude: number, clusterId: string) => {
    const expansioZoom = getExpansionZoom(clusterId);
    onSelectMarker(longitude, latitude, expansioZoom);
  };

  const handleClick = (id: string, longitude: number, latitude: number) => {
    onSelectMarker(longitude, latitude, viewport.zoom);
    setSelectedDevice(id);
  };

  useEffect(() => {
    setViewport((currentViewport: any) => ({
      ...currentViewport,
      latitude: centerCoords ? centerCoords.lat : 0,
      longitude: centerCoords ? centerCoords.lon : 0,
      zoom,
    }));
  }, [centerCoords, zoom]);

  const history = useHistory();
  const languageStrings = useSelector(selectLanguageStrings);

  const getWarningType = (properties: any) => {
    if (properties) {
      return properties.every((item: any) => item.type_event === 1) ? ManholesType.YELLOW : ManholesType.RED;
    }
  };

  return (
    <Wrapper {...props}>
      <SemiWrapper ref={mapbox} theme={theme}>
        {isGradiented ? <OverlayGradient /> : null}
        <Map
          {...viewport}
          mapboxAccessToken={mapToken}
          ref={mapReference as any}
          onResize={(e: any) => {
            //
          }}
          onMove={({ viewState }: any) => setViewport(viewState)}
          mapStyle="mapbox://styles/mapbox/light-v11"
          attributionControl={false}
        >
          {clusters &&
            clusters.map((clusterObject, index) => {
              const { properties, geometry, id: clusterId } = clusterObject;
              const warningType = getWarningType(properties.values);
              const [longitude, latitude] = geometry.coordinates;
              const { cluster: isCluster, count: pointCount, values } = properties;
              const size = 45 + ((pointCount > 101 ? 100 : pointCount) / points.length) * 10;

              return (
                <>
                  {isCluster ? (
                    <Marker key={clusterId} latitude={latitude} longitude={longitude}>
                      <Cluster
                        type={type}
                        theme={theme}
                        onClick={() => handleClusterClick(latitude, longitude, clusterId)}
                        size={size}
                      >
                        <ClusterText type={TextType.TEXT_20_MAP}>{pointCount}</ClusterText>
                      </Cluster>
                    </Marker>
                  ) : (
                    <CustomMarker
                      key={properties.values[0].manholeId}
                      latitude={parseFloat(latitude)}
                      longitude={parseFloat(longitude)}
                      setCentre={setCentre}
                      staticDisplay={staticDisplay}
                      zoom={viewport.zoom}
                    >
                      <ManholeButton
                        type={type === ErrorListMapType.DASHBOARD ? warningType : ManholesType.GREEN}
                        staticDisplay={staticDisplay}
                        history={history}
                        languageStrings={languageStrings}
                        onClick={() => handleClick(properties.values[0].manholeId, longitude, latitude)}
                        deviceType={values ? values[0]?.device_type : 'smart_manhole'}
                        {...properties}
                      />
                    </CustomMarker>
                  )}
                </>
              );
            })}
        </Map>
      </SemiWrapper>
    </Wrapper>
  );
};

export const ErrorListMap = withLoader(undefined, PlaceholderType.MAP)(ErrorListMapPlain);
