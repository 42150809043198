import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

const ExtraWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: var(--modal-first-layer);
  background-color: rgb(0, 0, 0, 0.6);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${media.ultramonitor} {
    max-width: 1920px;
  }
`;

export interface ModalProps {
  children: any;
  isOpenModal: boolean;

  className?: string;
}
export const Modal: FC<ModalProps> = ({ children, isOpenModal, ...props }) => {
  if (isOpenModal) {
    return createPortal(
      <ExtraWrapper {...props}>
        <Wrapper>{children}</Wrapper>
      </ExtraWrapper>,
      document.getElementById('modal') || document.body,
    );
  }
  return null;
};
