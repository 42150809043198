import React from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { Calendar, CalendarType } from '@bit/first-scope.calendar';
import { CloseButtonRound } from '../closeButtonRound/CloseButtonRound';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  align-items: flex-end;
  & > div {
    width: 100%;
    height: 536px;
    align-items: flex-end;
  }
` as any; // @TODO fix type

const StyledContainer = styled(ModalContainer)`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  border-radius: 24px 24px 0 0;
  padding: 24px 16px 16px 16px;
`;

const StyledCalendar = styled(Calendar)`
  width: 100% !important;
  & > div > div.react-datepicker {
    box-shadow: none;
  }
  .react-datepicker__month-container {
    width: 100% !important;
  }
  .react-datepicker__day {
    margin: 0.5px !important;
  }
  .react-datepicker__header {
    padding: 22px 24px 0px 24px !important;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > button:first-child {
    margin: 0 0 16px 0;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Text)`
  font-size: 18px;
`;

export const ChooseDateMobileModal = ({
  closeModal,
  title,
  languageStrings,
  date,
  setDate,
  maxDate,
  minDate,
  handleSave,
  isOpenModal,
}: {
  closeModal: any;
  title: string;
  languageStrings: any;
  date: Date;
  setDate: any;
  maxDate?: Date;
  minDate?: Date;
  handleSave: (e: any) => void;
  isOpenModal?: boolean;
}) => {
  const theme = useTheme();
  const { pureWhite, black1 } = theme.colors;

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledContainer>
        <TitleWrapper>
          <Title type={TextType.TITLE_H4MOBILE}>
            {title ? `${languageStrings.chooseDateTitle}: ${title}` : `${languageStrings.chooseDateTitle}`}
          </Title>
          <CloseButtonRound closeOnClick={closeModal} />
        </TitleWrapper>
        <StyledCalendar
          type={CalendarType.DATE_SELECTION}
          iconComponent={
            <Text type={TextType.TEXT_12_GRAY}>{languageStrings.generateReportModalPeriodBorderFrom}</Text>
          }
          dateFormat={'DD MMM yy'}
          startDate={date}
          setStartDate={setDate}
          maxDate={maxDate}
          minDate={minDate}
          inline
        />
        <ButtonWrapper>
          <CommonButton onClick={handleSave} width={'100%'} height={'40px'}>
            {languageStrings.saveButton}
          </CommonButton>
          <CommonButton
            onClick={closeModal}
            width={'100%'}
            height={'40px'}
            colors={{ main: black1, background: pureWhite }}
          >
            {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
          </CommonButton>
        </ButtonWrapper>
      </StyledContainer>
    </StyledModal>
  );
};
