import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { COMPANY_CREATION_STEP } from '../../app/state/organisations';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import {
  AddDevicesManually,
  DevicesStepType,
  UploadDevicesModal,
  AddDeviceModal,
} from '../companyCreationWizard/components';
import { useOrganisationCreationDevices } from '../../api/organisation';
import { useTrigger } from '../../utils/useTrigger';
import { selectCurrentLocation } from '../../app/state/organisations';
import { SummaryModal } from './components/summaryModal/SummaryModal';
import { useParams } from '@cthings.co/router-dom';

interface OrganisationDevicesToolProps {}

export const OrganisationDevicesTool: FC<OrganisationDevicesToolProps> = () => {
  const currentLocation = useSelector(selectCurrentLocation);
  const [trigger, toggleTrigger] = useTrigger();

  const { id: client_id } = useParams();

  const triggers = [trigger];

  useBlockBodyScroll(!!currentLocation);

  const { isLoading } = useOrganisationCreationDevices({ triggers, client_id });

  if (!currentLocation) {
    return null;
  }

  const addDevicesMapping = [COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY, COMPANY_CREATION_STEP.SUMMARY_DEVICES];

  return (
    <>
      <AddDeviceModal isOpen={currentLocation === COMPANY_CREATION_STEP.ADD_DEVICES} />
      <UploadDevicesModal isOpen={currentLocation === COMPANY_CREATION_STEP.UPLOAD_DEVICES} />
      <AddDevicesManually
        isOpen={addDevicesMapping.includes(currentLocation)}
        isLoading={isLoading}
        toggleTrigger={toggleTrigger}
        type={
          currentLocation === COMPANY_CREATION_STEP.ADD_DEVICES_MANUALLY ? DevicesStepType.ADD : DevicesStepType.EDIT
        }
      />
      <SummaryModal isOpen={currentLocation === COMPANY_CREATION_STEP.SUMMARY} />
    </>
  );
};
