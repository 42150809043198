import React, { FC, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { Select } from '../../../../../../components/universalSelect/Select';
import { InfoButton } from '../../../../../../components/infoButton/InfoButton';
import { CommonButton } from '../../../../../../components/commonButton/CommonButton';
import { useBlockBodyScroll } from '../../../../../../utils/useBlockBodyScroll';
import { useStyleContext } from '@cthings.co/styles-utils';
import { InputModal } from '../../../../../../components/inputModal/InputModal';
import { TooltipPlace } from '../../../../../../components/tooltip/Tooltip';
import ManholesImage from '../../../../../../assets/ManholesValues.png';
import ManholesImageMobile from '../../../../../../assets/ManholesValuesMobile.png';
import { MobileSelectModal } from './components/MobileSelectModal';
import { FieldsType, isValidField } from '../../../../../../utils/validation';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/user';
import { useTheme } from '@cthings.co/styled-components';

export const StyledModal = styled(Modal)`
  ${media.phone} {
    align-items: flex-start;
    overflow: auto;
    & > div {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  display: flex;
  flex-direction: column;
  align-items: unset;
  padding: 24px;

  ${media.phone} {
    width: 100%;
    padding: 24px 16px;
    border-radius: 12px 12px 0px 0px;
  }
`;

const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 24px 0;

  ${media.phone} {
    margin: 16px 0 24px 0;
  }
`;

const InputsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.phone} {
    flex-direction: column;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.phone} {
    flex-direction: column-reverse;
  }
`;

export interface CalculationProps {
  values: any;
  isOpenModal: boolean;
  onCancel: () => void;
  onSave?: (props: any) => void;
}

export const Calculation: FC<CalculationProps> = ({ values, onCancel, onSave, isOpenModal, ...props }) => {
  const theme = useTheme();
  const { black1, pureWhite } = theme.colors;
  const [media] = useStyleContext();
  const [mobileValue, setMobileValue] = useState({ name: '', id: '' });
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(false);
  const [startedValidation, setStartedValidation] = useState(false);
  const [isFieldsValid, setIsFieldsValid] = useState(false);
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);
  useBlockBodyScroll(isOpenModal);

  const [data, setData] = useState<any>({
    H1: '',
    H2: '',
    msl1: '',
    msl2: '',
    d: '',
    x: '',
    h1: '',
    h2: '',
    pipe: { name: '', id: '' },
  });

  const handleChange = (value: any, fieldName: string) => {
    setData((val: any) => ({
      ...val,
      [fieldName]: value,
    }));
  };

  const handleSaveButton = () => {
    setStartedValidation(true);
    if (isFieldsValid) {
      onSave && onSave(data);
    } else {
      addNotification({
        message: 'Please fill in all fields',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    setData((items: any) => {
      return { ...items, pipe: mobileValue };
    });
    handleChange(mobileValue, 'pipe');
  }, [mobileValue]);

  useEffect(() => {
    const isValid = Object.values(data).every((item: any) => {
      return item && item.name !== '';
    });
    isValid && setIsFieldsValid(true);
  }, [data]);

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer width={'564px'}>
        <Text
          type={TextType.TEXT_14_BLACK}
          color={black1}
          weight={'600'}
          fontSize={'18px'}
          margin={media.mediaType.phone ? '0 0 34px 0' : '0 0 16px 0'}
        >
          Values needed for calculation
        </Text>
        <img src={media.mediaType.phone ? ManholesImageMobile : ManholesImage} alt={'manholes'} />
        <InputsBlock>
          <InputsRow>
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'H1(m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.H1}
              onChange={(e: any) => handleChange(e.target.value, 'H1')}
              isError={!isValidField(data.H1, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'H2 (m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.H2}
              onChange={(e: any) => handleChange(e.target.value, 'H2')}
              isError={!isValidField(data.H2, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
          </InputsRow>
          <InputsRow>
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'MSL1 (m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.msl1}
              onChange={(e: any) => handleChange(e.target.value, 'msl1')}
              isError={!isValidField(data.msl1, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'MSL2 (m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.msl2}
              onChange={(e: any) => handleChange(e.target.value, 'msl2')}
              isError={!isValidField(data.msl2, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
          </InputsRow>
          <InputsRow>
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'d (m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.d}
              onChange={(e: any) => handleChange(e.target.value, 'd')}
              isError={!isValidField(data.d, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'ΔX (m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.x}
              onChange={(e: any) => handleChange(e.target.value, 'x')}
              isError={!isValidField(data.x, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
          </InputsRow>
          <InputsRow>
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'h1 (m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.h1}
              onChange={(e: any) => handleChange(e.target.value, 'h1')}
              isError={!isValidField(data.h1, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
            <InputModal
              width={media.mediaType.phone ? '100%' : '248px'}
              name={'h2 (m)'}
              tooltip={
                <InfoButton
                  onClick={() => {}}
                  tooltipPlace={TooltipPlace.RIGHT}
                  tooltipText={'Height from the bottom of the manhole #1 to the start of the pipe'}
                  margin={'0 0 4px 4px'}
                />
              }
              placeholder={'Enter value'}
              margin={media.mediaType.phone ? '0 0 12px 0' : '0 0 16px 0'}
              value={data.h2}
              onChange={(e: any) => handleChange(e.target.value, 'h2')}
              isError={!isValidField(data.h2, FieldsType.NUMBER)}
              startedValidation={startedValidation}
            />
          </InputsRow>
          <Select
            label={'Pipe material roughness '}
            placeholder={'Choose or enter material’s roughtness'}
            fieldWidth={'100%'}
            value={data.pipe.name}
            values={values}
            positionTop={'unset'}
            positionBottom={'44px'}
            height={'40px'}
            listHeight={'246px'}
            onChange={(e: any) => handleChange(e, 'pipe')}
            triggerCloseModal={!isOpenMobileModal}
            customModal={
              media.mediaType.phone && (
                <MobileSelectModal
                  value={data.pipe}
                  setIsOpenModal={(value: any) => setIsOpenMobileModal(value)}
                  values={values}
                  onChange={(value) => setMobileValue(value)}
                />
              )
            }
            withEnterButtonPlaceholder={!media.mediaType.phone}
            languageStrings={languageStrings}
          />
        </InputsBlock>
        <ButtonsRow>
          <CommonButton
            backgroundColor={pureWhite}
            color={black1}
            height={media.mediaType.phone ? '40px' : '32px'}
            margin={media.mediaType.phone ? '0' : '0 16px 0 0'}
            width={media.mediaType.phone ? '100%' : '116px'}
            onClick={onCancel}
          >
            Back
          </CommonButton>
          <CommonButton
            height={media.mediaType.phone ? '40px' : '32px'}
            margin={media.mediaType.phone ? '0 0 10px 0' : '0'}
            width={media.mediaType.phone ? '100%' : '116px'}
            onClick={handleSaveButton}
          >
            Save
          </CommonButton>
        </ButtonsRow>
      </StyledModalContainer>
    </StyledModal>
  );
};
