import React, { useCallback, useEffect, useState } from 'react';
import { PreparedActionConfig } from '../../../../types';

export const CustomComponent = ({ data, item }: { data: any; item: PreparedActionConfig }) => {
  const [node, setNode] = useState<any>(null);
  const wrapperRef = useCallback((currentNode: any) => {
    if (currentNode !== null) {
      setNode(currentNode);
    }
  }, []);

  useEffect(() => {
    if (node) {
      const pseudoFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
      };
      const clickFunc = (e: any) => {
        if (item && item.callback) {
          e.preventDefault();
          e.stopPropagation();
          item.callback(data);
        }
      };
      node.addEventListener('touchstart', clickFunc, { passive: false });
      node.addEventListener('touchend', pseudoFunc, { passive: false });
      node.addEventListener('click', clickFunc);
      return () => {
        node.removeEventListener('touchstart', clickFunc);
        node.removeEventListener('touchend', pseudoFunc);
        node.removeEventListener('click', clickFunc);
      };
    }
  }, [node, data.id]);
  return <div ref={wrapperRef}>{item.component && item.component(data)}</div>;
};
