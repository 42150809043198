import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { ButtonIconType, CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchBar, SearchBarType } from '@bit/first-scope.search-bar';
import { AddressMap } from '../../../addressMap/AddressMap';
import { MAPBOX_TOKEN } from '../../../../consts';
import { centreHook } from '../../../../types/typeCenterCoords';
import { useAddress } from '../../../../api/objectPosition';
import { DraggableTableDevice } from '../draggableTableDevice/DraggableTableDevice';
import { usePrevious } from '../../../../utils/usePrevious';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CloseWhiteRoundButton } from '../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { AddressPopupDescription } from '../addressPopupDescription/AddressPopupDescription';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { checkAddressReadiness } from '../../utils';

const Wrapper = styled.div`
  display: none;
  ${media.tablet} {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    z-index: var(--modal-second-layer);
  }
`;

const Header = styled.div`
  width: 100%;
  height: 82px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorFetch('gray5')};
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
  padding: 34px 16px 16px 16px;
`;

const Confirm = styled(Text)`
  color: ${colorFetch('primary')};
  &:hover {
    color: ${colorFetch('secondary2')};
  }
` as any; // @TODO fix type

const MapWrapper = styled.div`
  width: 100%;
  height: calc(100% - 240px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & > div {
    border-radius: 0;
    border: none;
  }
`;

const StyledSearchBar = styled(SearchBar)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  width: 400px;
  & > div > div {
    width: 400px;
    & > div > svg > path {
      stroke: ${colorFetch('primary')};
    }
  }
  ${media.phone} {
    width: calc(100% - 20px);
    display: flex;
    justify-content: flex-start;
    & > div {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
  }
`;

const InstructionMobileModal = styled(ModalContainer)`
  width: 100%;
  position: absolute;
  bottom: 0px;
  height: max-content;
  padding: 24px;
  z-index: var(--modal-first-layer);
  border-radius: 16px 16px 0 0;
`;

const StyledCloseWhiteRoundButton = styled(CloseWhiteRoundButton)`
  display: none;
  ${media.tablet} {
    display: flex;
  }
`;

const WrapperTitleModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const MobileEditAddress = ({
  title,
  address,
  inputArr,
  searchCentre,
  localSearch,
  position,
  selectedPos,
  centreLat,
  centreLon,
  zoom,
  locationTimerId,
  setLocalSearch,
  setLocalAddress,
  handleSelectedPos,
  handleLocationChange,
  handleCancel,
  handleSetLocalAddress,
  handleConfirm,
  handleSetAddressFieldValue,
  languageStrings,
  altitude,
  isAllFieldsValid,
  validatingIsStarted,
  setValidatingIsStarted,
  isInstructions,
  handleSaveAsMainLocation,
  isGps,
  isNotMainLocation,
}: {
  inputArr: {
    name: any;
    fieldName: string;
    value: any;
    disabled: boolean;
    placeholder?: unknown;
  }[];

  address: any;
  searchCentre: any;
  localSearch: string;
  position: any[];
  selectedPos: any;
  centreLat: any;
  centreLon: any;
  zoom?: number;
  locationTimerId: any;
  setLocalSearch: any;
  setLocalAddress: any;
  title?: string;
  handleSelectedPos: (position: any) => void;
  handleLocationChange: (e: any) => void;
  handleCancel: () => void;
  handleSetLocalAddress: (field: string, value: any, path?: string[]) => void;
  handleConfirm: () => void;
  handleSetAddressFieldValue: any;
  languageStrings?: any;
  altitude?: any;
  isAllFieldsValid?: boolean;
  validatingIsStarted?: boolean;
  isInstructions?: boolean;
  setValidatingIsStarted?: (val: boolean) => void;
  handleSaveAsMainLocation?: any;
  isGps?: boolean;
  isNotMainLocation?: boolean;
}) => {
  const theme = useTheme();
  const { black1, pureWhite } = theme.colors;

  const searchResultName = selectedPos ? selectedPos.name : '';
  const [centre, setCentre] = useState<centreHook>({ lat: parseFloat(centreLat), lon: parseFloat(centreLon) });
  const [dragInProgress, setDragInProgress] = useState<boolean>(false);
  const [isInstructionModal, setIsInstructionModal] = useState<boolean>(false);
  const [currentCentre, setCurrentCentre] = useState<centreHook>({
    lat: parseFloat(centreLat),
    lon: parseFloat(centreLon),
  });

  const targetAlt = altitude ? parseFloat(altitude) : null;

  const [resAddress1, isInitialLoad1] = useAddress(centre.lat, centre.lon, targetAlt);
  const [resAddress2, isInitialLoad2] = useAddress(searchCentre.lat, searchCentre.lon, targetAlt);

  const handleSetCentre = (centre: any) => {
    setCurrentCentre({ lat: parseFloat(centre.lat), lon: parseFloat(centre.lng) });
  };

  useEffect(() => {
    searchResultName && setLocalSearch(searchResultName);
  }, [searchResultName]);

  useEffect(() => {
    if (
      !isGps &&
      resAddress1 &&
      checkAddressReadiness(resAddress1 as any, !isInitialLoad1) &&
      handleSetAddressFieldValue
    ) {
      handleSetAddressFieldValue(resAddress1);
      setLocalAddress(resAddress1);
    }
  }, [resAddress2, resAddress1.geotag.lng, resAddress1.geotag.lat]);

  useEffect(() => {
    if (
      !isGps &&
      resAddress2 &&
      checkAddressReadiness(resAddress2 as any, !isInitialLoad2) &&
      handleSetAddressFieldValue
    ) {
      handleSetAddressFieldValue(resAddress2);
      setLocalAddress(resAddress2);
    }
  }, [resAddress2, resAddress2.geotag.lng, resAddress2.geotag.lat]);

  useEffect(() => {
    return () => {
      locationTimerId.current && clearTimeout(locationTimerId.current);
    };
  }, []);

  const prevDrag = usePrevious(dragInProgress);

  useEffect(() => {
    if (!dragInProgress && dragInProgress !== prevDrag) {
      setCentre(currentCentre);
    }
  }, [dragInProgress]);

  const handleCloseInstructionModal = () => {
    setIsInstructionModal(false);
  };

  return (
    <>
      <Wrapper
        onMouseDown={() => setDragInProgress && setDragInProgress(true)}
        onMouseUp={() => setDragInProgress && setDragInProgress(false)}
        onTouchStart={() => setDragInProgress && setDragInProgress(true)}
        onTouchEnd={() => setDragInProgress && setDragInProgress(false)}
      >
        <Header>
          <CommonButton
            onClick={handleCancel}
            iconType={ButtonIconType.ARROW_LEFT}
            width={'32px'}
            height={'32px'}
            iconWithoutText
          ></CommonButton>
          <Text type={TextType.TITLE_H3MOBILE} color={black1}>
            {title ? title : languageStrings.insightDetailsDeviceConfigurationEditInputsAddressTitle}
          </Text>
          <Confirm onClick={handleConfirm}>{languageStrings.insightDetailsDeviceConfigAddressButtonsConfirm}</Confirm>
        </Header>
        <MapWrapper>
          {!isGps && (
            <StyledSearchBar
              type={SearchBarType.CLASSIC_LOCATION}
              countResultOfSearching={0}
              defaultValue={''}
              placeholder={languageStrings.manageClientEditAddressSearchBarPlaceholderAddress}
              locations={position}
              search={localSearch}
              setSelectedPos={handleSelectedPos}
              languageStrings={languageStrings}
              handleLocationChange={handleLocationChange}
              theme={theme}
            />
          )}
          <AddressMap
            mapToken={MAPBOX_TOKEN}
            zoom={zoom ? zoom : 8}
            latitude={searchCentre.lat}
            longitude={searchCentre.lon}
            centerCoords={searchCentre}
            handleSetCentre={!isGps ? handleSetCentre : () => {}}
          />
        </MapWrapper>
        <DraggableTableDevice
          inputArr={inputArr}
          handleSetLocalAddress={handleSetLocalAddress}
          isAllFieldsValid={isAllFieldsValid}
          validatingIsStarted={validatingIsStarted}
          setValidatingIsStarted={setValidatingIsStarted}
          isInstructions={isInstructions}
          setIsInstructionModal={setIsInstructionModal}
          isInstructionModal={isInstructionModal}
          isGps={isGps}
          isNotMainLocation={isNotMainLocation}
          handleCancel={handleCancel}
          handleSaveAsMainLocation={handleSaveAsMainLocation}
        />
        {isInstructionModal && (
          <InstructionMobileModal>
            <WrapperTitleModal>
              <StyledCloseWhiteRoundButton onClick={handleCloseInstructionModal} />
              <Text type={TextType.TITLE_H3} color={black1} margin="0 0 14px 0">
                {languageStrings.provisioningInstructions}
              </Text>
            </WrapperTitleModal>
            <AddressPopupDescription languageStrings={languageStrings} />
          </InstructionMobileModal>
        )}
      </Wrapper>
    </>
  );
};
