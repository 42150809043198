import React from 'react';
import { FC } from 'react';
import { StyledText, TextWrapper, StyledInfoText, Button, ButtonsWrapper, HeaderIconDiv } from './styled';
import { TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { CustomModal } from '../customModal/CustomModal';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export interface ChoosingPlansetProps {
  isOpenModal: boolean;
  onThisDeviceButtonClick: () => void;
  onAllButtonClick: () => void;
  onCloseButtonClick: () => void;
}

export const ChoosingPlanset: FC<ChoosingPlansetProps> = ({
  isOpenModal,
  onThisDeviceButtonClick,
  onAllButtonClick,
  onCloseButtonClick,
}) => {
  const theme = useTheme();
  const { black1, gray1 } = theme.colors;
  const languageStrings = useSelector(selectLanguageStrings);

  const buttons = [
    {
      value: languageStrings.cancelButton,
      onClick: onCloseButtonClick,
      backgroundColor: colorFetch('pureWhite'),
      backgroundColorHover: colorFetch('primary'),
      borderColor: colorFetch('primary'),
      borderColorHover: colorFetch('primary'),
      color: colorFetch('black'),
      hoverColor: colorFetch('pureWhite'),
    },
    {
      value: languageStrings.thisDeviceButton,
      onClick: onThisDeviceButtonClick,
      backgroundColor: colorFetch('primary'),
      backgroundColorHover: colorFetch('primary'),
      borderColor: colorFetch('primary'),
      borderColorHover: colorFetch('primary'),
      color: colorFetch('pureWhite'),
      hoverColor: colorFetch('pureWhite'),
    },
    {
      value: languageStrings.allButton,
      onClick: onAllButtonClick,
      backgroundColor: colorFetch('primary'),
      backgroundColorHover: colorFetch('primary'),
      borderColor: colorFetch('primary'),
      borderColorHover: colorFetch('primary'),
      color: colorFetch('pureWhite'),
      hoverColor: colorFetch('pureWhite'),
    },
  ];

  return (
    <CustomModal isOpen={isOpenModal}>
      <TextWrapper>
        <HeaderIconDiv>
          <StyledText type={TextType.TEXT_12_BLACK} color={black1}>
            {languageStrings.choosingPlanset}
          </StyledText>
        </HeaderIconDiv>
        <StyledInfoText type={TextType.TEXT_12_BLACK} color={gray1}>
          {languageStrings.doYouWantToApply}
        </StyledInfoText>
      </TextWrapper>
      <ButtonsWrapper>
        {buttons.map((item: any) => {
          const {
            value,
            onClick,
            backgroundColor,
            backgroundColorHover,
            borderColor,
            borderColorHover,
            color,
            hoverColor,
          } = item;

          return (
            <Button
              onClick={onClick}
              colors={{
                main: color,
                mainHover: hoverColor,
                background: backgroundColor,
                backgroundHover: backgroundColorHover,
                border: borderColor,
                borderHover: borderColorHover,
              }}
            >
              {value}
            </Button>
          );
        })}
      </ButtonsWrapper>
    </CustomModal>
  );
};
