export enum TableInputType {
  TEXTFIELD = 'TEXTFIELD',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  ADDRESS = 'ADDRESS',
  NUMBER_OF_DEVICES = 'NUMBER_OF_DEVICES',
  TEXTFIELD_NUMBER = 'TEXTFIELD_NUMBER',
  SELECT = 'SELECT',
  SLIDER_FREE_INPUT_WITH_UNIT = 'SLIDER_FREE_INPUT_WITH_UNIT',
  SLIDER_RANGE_FREE_INPUT_WITH_UNIT = 'SLIDER_RANGE_FREE_INPUT_WITH_UNIT',
  TIME_PICKER = 'TIME_PICKER',
  TIME_PICKER_UNIT_HHMM = 'TIME_PICKER_UNIT_HHMM',
  TIME_PICKER_UNIT_MMSS = 'TIME_PICKER_UNIT_MMSS',
  TIME_RANGE = 'TIME_RANGE',
  TIME_PICKER_FIELDS_HHMM = 'TIME_PICKER_FIELDS_HHMM',
  TIME_PICKER_FIELDS = 'TIME_PICKER_FIELDS',
}
