import { ThemeType } from '@cthings.co/styled-components';
import { ThresholdColor } from './types';

export const thresholdColorMapping = ({ color, theme }: { color: ThresholdColor; theme: ThemeType }) => {
  const { red2, orange2 } = theme.colors;

  const mapping = {
    [ThresholdColor.ORANGE]: { stroke: orange2 },
    [ThresholdColor.RED]: { stroke: red2 },
  };
  return mapping[color];
};
