import { LsValueType } from '../../../enums/LsValueType';
import { UserType } from '../../../enums/UserType';
import ss from '../../../utils/ss';
import { defaultLang, emptyAccess, languageSet } from './consts';
import { UserState } from './types';

export const initialState: UserState = {
  userType: UserType.USER,
  id: null,
  idpId: null,
  firstName: null,
  lastName: null,
  userSettings: { status: false },
  userSettingsCompleted: false,
  access: emptyAccess,
  language: languageSet.find((el) => el.id === ss.get(LsValueType.locale)) || defaultLang,
  languages: languageSet,
  isExportModalOpen: false,
};
