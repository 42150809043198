import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as LocationIcon } from '../../../../../../../../assets/location-secondary.svg';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { CopyButton } from '../../../../../../../../components/copyButton/CopyButton';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../app/state/user';
import { ReactComponent as HydrantIcon } from '../../assets/hydrant.svg';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Title = styled.div``;

const TitleDevice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;
  ${media.semiTablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const GeoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextDeviceID = styled(Text)`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: ${colorFetch('gray1_5')};
` as any; // @TODO fix type

const TextDevice = styled(Text)`
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colorFetch('gray1_5')};
` as any; // @TODO fix type

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  &:hover {
    & > span {
      color: ${colorFetch('gray2')};
    }
    & > svg > path {
      stroke: ${colorFetch('gray3')};
    }
  }
  ${media.semiTablet} {
    margin-left: 0px;
  }
`;

const DeviceInfoBlock = styled.div`
  display: flex;
`;

const DeviceTypeText = styled(Text)`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${colorFetch('gray1_5')};
  margin-left: 5px;
`;

const Separator = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${colorFetch('gray3')};
  margin: 3px 10px 0;
`;

export const DeviceTitle = ({
  address,
  deviceId,
  handleCopyText,
  deviceType,
}: {
  address: any;
  deviceId: string;
  handleCopyText: (deviceId: string) => void;
  deviceType: string;
}) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <>
      <TitleRow>
        <Title>
          <Text type={TextType.TITLE_PRIMARY}>{address.line1 ? address.line1 : 'N/A'}</Text>{' '}
          <Text type={TextType.TITLE_SECONDARY}>{`, ${address.city ? address.city : 'N/A'}`}</Text>
        </Title>
        <GeoWrapper>
          <LocationIcon />
          <Text type={TextType.HELPER_TEXT_1} color={gray2}>
            {address ? `${address.geotag.lat}, ${address.geotag.lng}` : 'N/A'}
          </Text>
        </GeoWrapper>
      </TitleRow>
      <TitleDevice>
        <DeviceInfoBlock>
          <HydrantIcon />
          <DeviceTypeText type={TextType.TEXT_14_GRAY}>{deviceType ? deviceType : 'N/A'}</DeviceTypeText>
          <Separator />
        </DeviceInfoBlock>
        <DeviceInfoBlock>
          <TextDeviceID>{languageStrings.deviceIdText}</TextDeviceID>
          <CopyWrapper onClick={() => handleCopyText(deviceId)}>
            <TextDevice>{deviceId ? deviceId : 'N/A'}</TextDevice>
            {deviceId && <CopyButton />}
          </CopyWrapper>
        </DeviceInfoBlock>
      </TitleDevice>
    </>
  );
};
