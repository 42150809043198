import React, { Dispatch, FC, SetStateAction, useState, useEffect } from 'react';
import { WrapperLanguages, SelectedLang, SelectedLangSec, ListLanguages, ItemLang } from './styled';
import { Text, TextType } from '@bit/first-scope.text';
import { useStyleContext } from '@cthings.co/styles-utils';
import { SecondaryMenuItem } from './mobileNavigation/MobileNavigation';
import { useTheme } from '@cthings.co/styled-components';
import { ArrowButton, ArrowDirectionType, ArrowSizeType } from '@cthings.co/buttons';

export interface LanguageItemTypes {
  languagePickerRef: any;
  languages: any;
  languageIndex: number;
  isOpen: boolean;
  openLangList: boolean;
  handleSetLanguage: (language: string) => void;
  setOpenLangList: any;
  isArrowButtonOpen?: boolean;
  setIsArrowButtonOpen?: Dispatch<SetStateAction<boolean>> | undefined;
  secondaryMenuItems?: SecondaryMenuItem[];
  setSecondaryMenuItems?: Dispatch<SetStateAction<SecondaryMenuItem[]>> | undefined;
}

export const LanguageItem: FC<LanguageItemTypes> = ({
  languagePickerRef,
  languages,
  languageIndex,
  isOpen,
  openLangList,
  handleSetLanguage,
  setOpenLangList,
  secondaryMenuItems,
  setSecondaryMenuItems,
}) => {
  const [isLanguageArrowOpen, setIsLanguageArrowOpen] = useState(false);
  const [media] = useStyleContext();
  const theme = useTheme();

  const { black1 } = theme.colors;

  const onLanguageItemClickHandler = () => {
    setSecondaryMenuItems && setSecondaryMenuItems(languages);
    setIsLanguageArrowOpen && setIsLanguageArrowOpen(true);
    setOpenLangList((el: boolean) => !el);
  };

  useEffect(() => {
    secondaryMenuItems && !secondaryMenuItems.length && setIsLanguageArrowOpen(false);
  }, [secondaryMenuItems]);

  return (
    <WrapperLanguages ref={languagePickerRef}>
      {!media.mediaType.tablet && (
        <>
          <SelectedLang className={isOpen ? 'openNav' : ''} onClick={() => setOpenLangList((el: boolean) => !el)}>
            <Text color={black1} type={TextType.TEXT_14_BLACK}>
              {languages[languageIndex].name}
            </Text>
            {/* <StyledLangArrowImage className={openLangList ? 'open' : ''} /> */}
            <ArrowButton isReveresed={openLangList} size={ArrowSizeType.SMALL} direction={ArrowDirectionType.RIGHT} />
          </SelectedLang>

          <SelectedLangSec className={isOpen ? 'openNav' : ''} onClick={() => setOpenLangList((el: boolean) => !el)}>
            <Text color={black1} type={TextType.TEXT_14_BLACK}>
              {languages[languageIndex].shortName}
            </Text>
          </SelectedLangSec>
        </>
      )}
      {media.mediaType.tablet && (
        <>
          <SelectedLangSec className={isOpen ? 'openNav' : ''} onClick={onLanguageItemClickHandler}>
            <Text color={black1} fontSize={'16px'} type={TextType.TEXT_14_BLACK}>
              {languages[languageIndex].name}
            </Text>
          </SelectedLangSec>
          <ArrowButton
            isReveresed={isLanguageArrowOpen}
            size={ArrowSizeType.SMALL}
            direction={ArrowDirectionType.RIGHT}
          />
        </>
      )}
      <ListLanguages className={openLangList ? 'open' : ''}>
        {languages.map((item: any, index: number) => {
          return (
            <ItemLang
              key={index}
              className={
                index === languageIndex && index === languages.length - 1
                  ? 'selected last'
                  : index === languageIndex
                  ? 'selected'
                  : index === languages.length - 1
                  ? 'last'
                  : ''
              }
              onClick={() => handleSetLanguage(languages[index].id)}
            >
              {item.name}
            </ItemLang>
          );
        })}
      </ListLanguages>
    </WrapperLanguages>
  );
};
