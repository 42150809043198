import React from 'react';
import styled from '@cthings.co/styled-components';
import { CommonButton } from '@cthings.co/buttons';
import { media } from '@cthings.co/styles-utils';
import { useContentForErrorPages } from '../useContentForErrorPages';

export enum TypeOfErrorFourth {
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
}

type WrapperProps = {
  type: TypeOfErrorFourth;
};

// @TODO fix media types
const Wrapper = styled.div<WrapperProps>`
  width: 300px;
  display: flex;
  justify-content: ${({ type }) => (type === TypeOfErrorFourth.ERROR_404 ? 'center' : 'space-between')};
  position: relative;
  top: 195px;

  ${media.tablet} {
    top: ${({ type }: any) => (type === TypeOfErrorFourth.ERROR_500 ? '135px' : '')};
    left: ${({ type }: any) => (type === TypeOfErrorFourth.ERROR_500 ? '12px' : '')};
  }

  ${media.phone} {
    top: ${({ type }: any) => (type === TypeOfErrorFourth.ERROR_500 ? '117px' : '')};
    left: ${({ type }: any) => (type === TypeOfErrorFourth.ERROR_500 ? '0' : '')};
    right: ${({ type }: any) => (type === TypeOfErrorFourth.ERROR_500 ? '1px' : '')};
  }
`;

const StyledButton = styled(CommonButton)`
  min-width: 127px;
  width: auto;
`;

export const FourthVersionErrorPage = ({ type }: { type: TypeOfErrorFourth }) => {
  const { fourthVersionErrorPage500, fourthVersionErrorPage404 } = useContentForErrorPages();

  const getErrorPageValue = (type: TypeOfErrorFourth) => {
    const value = {
      [TypeOfErrorFourth.ERROR_500]: fourthVersionErrorPage500(),
      [TypeOfErrorFourth.ERROR_404]: fourthVersionErrorPage404(),
    };
    return value[type];
  };

  const selectedValue: any = getErrorPageValue(type);

  return (
    <Wrapper type={type}>
      {selectedValue.buttons.map((item: any, index: number) => {
        const { onClick, backgroundColor, backgroundColorHover, color, value } = item;
        return (
          <StyledButton
            key={index}
            onClick={onClick}
            height={'40px'}
            colors={{
              main: color,
              background: backgroundColor,
              backgroundHover: backgroundColorHover,
            }}
          >
            {value}
          </StyledButton>
        );
      })}
    </Wrapper>
  );
};
