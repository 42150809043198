import moment from 'moment';
import { API_URL, NEW_ID } from '../consts';
import { ErrorEventType } from '../types/error';
import { HttpService } from '../utils/http';

export const updateMaintenanceEvents = (
  id: string,
  deviceId: string,
  data: any,
  index: number,
  updateLocalItem: any,
  toggleUpdaiting: any,
  toggleAdd: any,
  toggleEdit: any,
  handleAddNotification: Function,
  setIsSaveButtonDisabled: (status: boolean) => void,
  languageStrings?: any,
) => {
  if (id !== NEW_ID) {
    setIsSaveButtonDisabled(true);
    const url = `${API_URL}/v2/maintenance/${deviceId}/${id}`;
    const preparedDate = moment.utc(data.ts).format('YYYY-MM-DD');
    const preparedTime = moment.utc(data.ts).format('HH:mm');

    const preparedData = { ...data, device_id: deviceId, id, date: preparedDate, time: preparedTime };
    (async () => {
      try {
        await HttpService.put(url, preparedData);
        toggleEdit();
        handleAddNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
        setIsSaveButtonDisabled(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
        setIsSaveButtonDisabled(false);
      }
      toggleUpdaiting(false);
    })();
  } else {
    const url = `${API_URL}/v2/maintenance/${deviceId}`;
    const preparedData = { ...data, device_id: deviceId };
    setIsSaveButtonDisabled(true);
    (async () => {
      try {
        await HttpService.post(url, preparedData);
        toggleAdd();
        handleAddNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
        setIsSaveButtonDisabled(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
        setIsSaveButtonDisabled(false);
      }
      toggleUpdaiting(false);
    })();
  }
};

export const deleteMaintanenceGlobal = (
  id: string[],
  deleteMaintanence: any,
  deleteCheckedMaintanences: any,
  deviceId: string,
  handleAddNotification: Function,
  languageStrings?: any,
) => {
  const data = { maintenance_ids: id };
  if (id.length === 1) {
    const url = `${API_URL}/v2/maintenance/${deviceId}/${id}`;
    const deleteOneMaintanence = () => deleteMaintanence(id[0]);
    (async () => {
      try {
        await HttpService.delete(url, data);
        handleAddNotification({
          message: languageStrings.messageAboutSuccessfullyDeleted,
          type: 'success',
        });
      } catch (e) {
        const error = e as ErrorEventType;
        handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
      }
    })();
    deleteOneMaintanence();
  } else {
    const url = `${API_URL}/v2/maintenance/${deviceId}/delete`;
    (async () => {
      try {
        await HttpService.post(url, data);
      } catch (e) {
        //
      }
    })();
    deleteCheckedMaintanences();
  }
};
