import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { TableInput, TableInputType } from '../tableInput/TableInput';
import { getRequiredDateFormat } from '../../utils/date/date-format';
import { TableCustomComponentState } from '../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export enum DateFiledType {
  REGISTRATION = 'REGISTRATION',
}

const StyledTableInput = styled(TableInput)`
  margin: 0;
  & > span {
    display: none;
  }
  & > div {
    width: 100%;
    & > div > div > div {
      height: 40px !important;
    }
  }
`;
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type StyledTextProps = {
  padding: string;
};

const StyledText = styled(Text)<StyledTextProps>`
  padding: ${({ padding }) => padding};
  color: ${colorFetch('gray1')};
`;

const prepareDate = (date: string | Date) => {
  const value = getRequiredDateFormat(date, 'YYYY-MM-DDTHH:mm:ssZ');

  return value;
};

export const DateField = ({
  value,
  setField,
  isAddInProcess,
  state,
  languageStrings,
  disabled,
  withTime = true,
  saveToggle,
  type,
}: {
  value: string;
  setField: Function;
  isAddInProcess?: boolean;
  state?: TableCustomComponentState;
  languageStrings?: any;
  disabled?: boolean;
  withTime?: boolean;
  type?: DateFiledType;
  saveToggle?: boolean;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const isModal = state === TableCustomComponentState.EDIT || state === TableCustomComponentState.VIEW_MODAL;
  const [savedDate, setSavedDate] = useState(value);

  useEffect(() => {
    if (isAddInProcess && setField) {
      setField(prepareDate(new Date()));
    }
  }, [isAddInProcess]);

  useEffect(() => {
    setSavedDate(value);
  }, [!!saveToggle]);

  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <ItemWrapper>
          <StyledText
            theme={theme}
            padding={isModal ? '8px 12px' : '8px 0'}
            type={TextType.TEXT_14_BLACK}
            color={gray1}
            weight={'400'}
          >
            {value ? getRequiredDateFormat(value, 'DD.MM.YYYY') : 'N/A'}
          </StyledText>
          {value && withTime && (
            <StyledText
              theme={theme}
              padding={isModal ? '8px 12px' : '8px 0'}
              type={TextType.TEXT_14_BLACK}
              color={gray1}
              weight={'400'}
            >
              {getRequiredDateFormat(value, 'HH:mm')}
            </StyledText>
          )}
        </ItemWrapper>
      ) : (
        <StyledTableInput
          type={TableInputType.DATE_PICKER_WITH_ICON}
          value={value ? new Date(value) : ''}
          isEditInProcess
          options={{
            textOfErrorMessage: '',
            title: languageStrings.startDateLabel,
            maxValue: new Date(),
            handleSaveMobile: () => {},
            onChange: (value: any) => {
              setField(prepareDate(value));
            },
            onCloseMobile: () => {
              setField(prepareDate(savedDate));
            },
            isDisabled: disabled,
          }}
          handleChangeValue={(value: any) => {
            setField(prepareDate(value));
          }}
        />
      )}
    </>
  );
};
