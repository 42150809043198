import React from 'react';
import { ReactComponent as CrossButton } from '../../../../assets/Close.svg';
import { useDispatch } from 'react-redux';
import { endCompanyCreation } from '../../../../app/state/organisations';

export const CloseButton = () => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(endCompanyCreation());
  };

  return (
    <CrossButton width={'26'} height={'26'} onClick={closeModal} style={{ cursor: 'pointer', marginLeft: '15px' }} />
  );
};
