import React, { FC, useState } from 'react';
import { Text } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { ItemRow } from './itemRow/ItemRow';
import { LanguageStrings } from '../../../../../languages/types';
import { useSelector } from 'react-redux';
import { selectOrganisationUsers, setEditUser } from '../../../../../app/state/organisations';
import { AddUser } from '../../addUser';
import { useDispatch } from 'react-redux';
import { useUserRole } from '../../../../../utils/userRole';
import { getTextWidth } from '../../../../../utils/textWidth';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const ComponentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  transition: all 0.2s linear;

  ${media.tablet} {
    max-width: 100%;
    width: 100%;
  }

  ${media.phone} {
    height: 100%;
  }

  &.disabled {
    background-color: ${colorFetch('gray5')};
  }
`;

type WrapperTitleComponentProps = {
  textWidth: number;
};

const WrapperTitleComponent = styled.div<WrapperTitleComponentProps>`
  display: grid;
  grid-template-columns: ${({ textWidth }) => `1.5fr 2fr ${textWidth}px 20px`};
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Poppins';
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
  color: ${colorFetch('gray1')};
  padding: 0 22px;
  ${media.semiTablet} {
    grid-template-columns: 1.5fr 1fr 20px;
  }
`;

const UserNameTitle = styled(Text)`
  color: ${colorFetch('gray1')};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 10px 5px 10px 0;
` as any; // @TODO fix type

const UserEmailTitle = styled(UserNameTitle)`
  ${media.semiTablet} {
    display: none;
  }
`;

const UserRoleTitle = styled(UserNameTitle)``;

const ComponentHistory = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  flex: 1;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

type ComponentContentProps = {
  height: string;
};

const ComponentContent = styled.div<ComponentContentProps>`
  height: ${({ height }) => `${height}px`};
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 219px;
  transition: all 0.3s linear;
`;

type SubmitButtonProps = {
  isActive: boolean;
};

const SubmitButton = styled.button<SubmitButtonProps>`
  width: 100%;
  height: 45px;
  padding: 0;
  margin: 0;
  background-color: ${colorFetch('gray5')};
  color: ${colorFetch('gray2')};
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'auto')};
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: Poppins;
  border-top: 1px solid;
  border-color: ${colorFetch('gray3')};
  transition: all 0.3s linear;
  border-radius: 0 0 8px 8px;
  &:hover {
    background-color: ${({ isActive, theme }) =>
      isActive ? colorFetch('gray4')({ theme }) : colorFetch('gray5')({ theme })};
  }
  &:active {
    background-color: ${({ isActive, theme }) =>
      isActive ? colorFetch('gray4')({ theme }) : colorFetch('gray5')({ theme })};
  }
  &.disabled {
    background-color: ${colorFetch('gray4')};
    cursor: initial;
  }
`;

const WrapperComponentContainer = styled.div`
  width: 100%;
`;

interface AddUsersViewProps {
  languageStrings: LanguageStrings;
}

export const AddUsersView: FC<AddUsersViewProps> = ({ languageStrings }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const users = useSelector(selectOrganisationUsers);

  const [isEditOpen, setIsEditOpen] = useState(false);

  const [, { getUserByRole }] = useUserRole();

  const roleNamesArray = users.map((el: any) => {
    return getUserByRole(el.role).name;
  });

  const longestLabel = roleNamesArray.reduce((longest: string, currentWord: string) => {
    return currentWord.length > longest.length ? currentWord : longest;
  }, '');

  const textWidth = Math.ceil(getTextWidth(longestLabel, { font: '14px Poppins' }));
  const roleColumnWidth = textWidth < 60 ? 60 : textWidth;

  const handleAddRow = () => {
    setIsEditOpen(true);
  };

  const handleEditRow = (id: string) => {
    dispatch(setEditUser(id));
    setIsEditOpen(true);
  };

  const nameTitleText = languageStrings.usersName;
  const emailTitleText = languageStrings.emailAddress;
  const roleTitleText = languageStrings.manageUsersAddUserModalInputsRoleTitle;
  const addUserButtonText = languageStrings.addUser;

  return (
    <WrapperComponentContainer>
      <ComponentContainer>
        <WrapperTitleComponent textWidth={roleColumnWidth}>
          <UserNameTitle>{nameTitleText}</UserNameTitle>
          <UserEmailTitle>{emailTitleText}</UserEmailTitle>
          <UserRoleTitle>{roleTitleText}</UserRoleTitle>
        </WrapperTitleComponent>
        <ComponentHistory theme={theme}>
          <ComponentContent height={'180'}>
            {users.map((el, index: number) => (
              <ItemRow
                key={el.id}
                data={el}
                handleEditRow={() => handleEditRow(el.id)}
                isDisabled={index === 0}
                textWidth={roleColumnWidth}
              />
            ))}
          </ComponentContent>
        </ComponentHistory>

        <SubmitButton isActive onClick={handleAddRow} theme={theme}>
          {addUserButtonText}
        </SubmitButton>
      </ComponentContainer>
      {isEditOpen && <AddUser onClose={() => setIsEditOpen(false)} />}
    </WrapperComponentContainer>
  );
};
