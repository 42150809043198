import React, { FC } from 'react';
import { ButtonsWrapper, FiltersButton, Title, TitleWrapper } from '../../styles';
import { Export } from '../exportModal/Export';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/user';
import { TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import { useMediaType } from '@cthings.co/styles-utils';
import { ReactComponent as FiltersIcon } from '../../../../assets/sliders.svg';

interface TitleBlockProps {
  data: any;
  header: string;
  applyingStatus: any;
  type: any;
  filters: any;
  handleOpenFilterModal: () => void;
  hideExportButton?: boolean;
}

export const TitleBlock: FC<TitleBlockProps> = ({
  type,
  data,
  header,
  applyingStatus,
  filters,
  handleOpenFilterModal,
  hideExportButton = false,
}) => {
  const theme = useTheme();
  const { gray2, primary, pureWhite } = theme.colors;

  const mediaType = useMediaType();

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <TitleWrapper>
      <Title type={TextType.TITLE_H4} color={gray2}>
        {data.header || header}
      </Title>
      <ButtonsWrapper>
        <FiltersButton
          onClick={handleOpenFilterModal}
          colors={{
            main: primary,
            background: pureWhite,
          }}
          width={'120px'}
          height={'32px'}
        >
          <FiltersIcon />
          {languageStrings.filtersButton}
        </FiltersButton>
        {!hideExportButton && mediaType.desktop && (
          <Export
            isExportButtonActive={!!data.data.length}
            applyingStatus={applyingStatus}
            type={type}
            data={data}
            filters={filters}
            ts_from={filters.ts_from}
            ts_to={filters.ts_to}
          />
        )}
      </ButtonsWrapper>
    </TitleWrapper>
  );
};
