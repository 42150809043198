import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type BlockProps = {
  width: string;
};

const Block = styled.div<BlockProps>`
  height: 16px;
  max-width: ${({ width }) => width || '100px'};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  background-color: ${colorFetch('gray5')};
`;

export interface ComponentProps {
  width: string;
}

export const PlaceholderBlock: FC<ComponentProps> = ({ width }) => {
  const theme = useTheme();

  return <Block theme={theme} width={width} />;
};
