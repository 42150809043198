/**
 * @description
 * The context contains all the background logic that is
 * required for maps to run correctly.
 * This includes custom grid layout generation, api interactions
 * and access to table state.
 *
 */

import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  ComponentType,
  FC,
} from 'react';
import { NEW_ID } from '../../consts';
import { ColumnStructure, MetadataItem, ModalApi, RowItemType, SortingData, SORT_TYPE } from './types';
import { TableData, TableContextProviderType, TableFunctions, Pagination } from './types';
// @ts-ignore
import _, { isEmpty } from 'underscore';
import { HttpService } from '../../utils/http';
import { usePrevious } from '../../utils/usePrevious';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { FieldsType, isValidField } from '../../utils/validation';
import { useApi } from '../apiBuilder/useApiBuilder';
import { noSelectionParam } from './UniversalTable';
import { stringifyUrl } from 'query-string';
import { getPath } from '../../routes';
import { injectPathParams } from '../../routes/paths';
import { useQueryParams } from '../../utils/useQueryParams';

/* @TODO Alex reuse this natively later
_.isFunction = function (obj: any) {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}; */

const DEFAULT_OPEN_INDEX = -1;
const DEFAULT_HIGHLIGHTED_INDEX = -1;
const DEFAULT_DELETE_INDEX = -1;
const DEFAULT_OPEN_ID = null;
export const EXTRA_KEY = 'extraKey_';

export const DEFAULT_TABLE_DATA = {
  list: [],
  pagination: {
    total_items: 0,
    total_pages: 1,
    page_number: 1,
    limit: 0,
    offset: 0,
  },
};

const appendFieldName = (fieldName: string, isExternal: boolean) =>
  isExternal ? `${EXTRA_KEY}${fieldName}` : fieldName;

const deconstructFieldName = (fieldName: string, isExternal: boolean) =>
  isExternal ? fieldName.slice(EXTRA_KEY.length) : fieldName;
const isColumnOfSingularValue = (type: RowItemType | undefined) =>
  !type ||
  type === RowItemType.INPUT_NUMBER ||
  type === RowItemType.INPUT ||
  type === RowItemType.JOINED_SELECT ||
  type === RowItemType.INPUT_EMAIL ||
  type === RowItemType.INPUT_PHONE_NUMBER ||
  type === RowItemType.CUSTOM;

const prepareSortableKey = (sortableKey: string, type: RowItemType | undefined) => {
  const stringArray = sortableKey.split('.');
  const result = isColumnOfSingularValue(type)
    ? stringArray[0]
    : stringArray.reduce((result, item, index) => (index < 2 ? result + (index === 1 ? '_' : '') + item : result), '');
  return result;
};

const useData = (
  url: string | undefined,
  page: number,
  pageSize: number,
  setItems: any,
  keywordList: string[],
  searchField: string,
  sortingData: SortingData,
  // @TODO check if this can be removed
  didRender: boolean,
  apiTriggers: any[],
  setReadyState: any,
  indexOfItemInPayload?: string,
  withoutQuestionMarkBeforeLimit?: boolean,
) => {
  const { sort_field, sort_type } = sortingData;
  const offset = pageSize * (page - 1);
  const triggers = [url, pageSize, offset, searchField, sort_field, sort_type, ...apiTriggers];

  const finalUrl = `${url}${withoutQuestionMarkBeforeLimit ? '' : '?'}limit=${pageSize}&offset=${offset}${
    sort_field && sort_field !== noSelectionParam && sort_type && sort_type !== SORT_TYPE.NONE
      ? `&sort_type=${sort_type}&sort_field=${sort_field}`
      : ''
  }${searchField ? `&search_field=${searchField}` : ''}`;

  const [, , isLoading] = useApi({
    url: finalUrl,
    keywords: keywordList,
    triggers,
    condition: !!url,
    handleSuccess: (data: any) => {
      const { pagination, payload, metadata } = data;
      const arr = payload && Object.values(payload);
      const preparedPayload = indexOfItemInPayload ? arr[+indexOfItemInPayload] : payload;

      setItems &&
        preparedPayload &&
        setItems(
          preparedPayload.map((item: any) => ({ ...item, isChecked: false, isEdit: false })),
          pagination,
          metadata,
        );
      setReadyState(true);
    },
  });

  return { isLoading };
};

//@TODO Alex add selector for local delete name

const DEFAULT_STATE = {
  ...DEFAULT_TABLE_DATA,
  isSaveButtonDisabled: false,
  isSelectAll: false,
  isEditInProcess: false,
  isViewInProcess: false,
  isAddInProcess: false,
  isUpdaitingInProcess: false,
  isDeleteInProcess: false,
  saveToggle: false,
  openIndex: DEFAULT_OPEN_INDEX,
  highlightedIndex: DEFAULT_HIGHLIGHTED_INDEX,
  localDeleteIndex: DEFAULT_DELETE_INDEX,
  page: 0,
  mobileSelectMode: false,
  openId: DEFAULT_OPEN_ID,
  currentModalApiId: DEFAULT_OPEN_ID,
  sorting: { sort_field: '', sort_type: '' },
  searchValue: { actual: '', slow: '' },
  globalProps: {
    columnStructure: [],
    allowDelete: false,
    allowSelect: false,
    mobileLimit: 4,
    keywordList: [],
    pageSize: 12,
    pathParams: {},
    queryParams: {},
    offsetKey: 'offset',
    sortingKeys: { sort_field: 'sort_field', sort_type: 'sort_type' },
    apiTriggers: [],
    keyword: '',
  },
} as TableData;

const TableContext = createContext([DEFAULT_STATE, null as any, null as any] as TableContextProviderType);

const useTableContextCreator = (): TableContextProviderType => {
  const [tableState, setTableState] = useState(DEFAULT_STATE);
  const [mainApiReady, setMainApiReady] = useState(false);
  const [modalApiReady, setModalApiReady] = useState(false);

  const {
    mobileSelectMode,
    globalProps,
    list,
    openId,
    openIndex,
    isAddInProcess,
    metadata,
    sorting,
    searchValue,
    page,
    isSaveButtonDisabled,
    isSelectAll,
  } = tableState;
  const {
    allowDelete,
    allowSelect,
    mobileLimit,
    columnStructure,
    modalApi,
    mainApiUrl,
    keywordList,
    pageSize,
    tablePath,
    pathParams,
    offsetKey,
    queryParams: globalQueryParams,
    sortingKeys,
    apiTriggers,
    withHeaderInMobile,
    setIsPendingRequestFromModalApi,
    indexOfPayloadItem,
    withoutQuestionMarkBeforeLimit,
    actionSectionGridWidth,
  } = globalProps;

  const localQueryParams = useQueryParams() || {};

  const queryParams = !isEmpty(globalQueryParams) ? globalQueryParams : localQueryParams;

  const useModalData = (
    id: string | null,
    modalApi: ModalApi | undefined,
    enrichModalData: any,
    setCurrentId: (id: string) => void,
    setReadyState: any,
  ) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    //const trigger = [id] as const;
    const prevId = usePrevious(id);
    const loaderOptions = /*useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);*/ {};

    useEffect(() => {
      if (id && modalApi && id !== prevId) {
        setIsPendingRequestFromModalApi && setIsPendingRequestFromModalApi(true);
        const url = modalApi.url(id);

        (async () => {
          try {
            let responseData = (await HttpService.get(url, loaderOptions)).data;
            const { payload } = responseData;
            enrichModalData(payload);
            setCurrentId(id);
            setReadyState(true);
            setIsPendingRequestFromModalApi && setIsPendingRequestFromModalApi(false);
          } catch (e) {
            //
          }
        })();
      }
    }, [id /*...trigger , loaderOptions*/]);
  };
  useEffect(() => {
    if (!modalApi) {
      setModalApiReady(true);
    }
  }, [mainApiUrl]);

  const timerId = useRef(null as any);
  const history = useHistory();

  const handleRedirect = ({ view, pathParams, queryParams }: any) => {
    view &&
      history &&
      history.replace(stringifyUrl({ url: injectPathParams(getPath(view), pathParams), query: queryParams }));
  };

  const setField = (index: number, newValue: any, fieldName?: string) => {
    const isFunction = _.isFunction(newValue);
    setTableState((value: TableData) => {
      const rowCopy = JSON.parse(JSON.stringify(value.list[index]));
      let resultRow = rowCopy;
      if (isFunction) {
        resultRow = newValue(rowCopy);
      } else if (fieldName) {
        resultRow[fieldName] = newValue;
      }

      return { ...value, list: [...value.list.slice(0, index), resultRow, ...value.list.slice(index + 1)] };
    });
  };

  const triggerSave = () => {
    setTableState((value: TableData) => ({
      ...value,
      saveToggle: !value.saveToggle,
    }));
  };

  const enrichModalData = useCallback(
    (payload: any) => {
      const addition = Object.keys(payload)
        .map((key: any) => ({
          [`${EXTRA_KEY}${key}`]: payload[key],
        }))
        .reduce(
          (acc, newVal) => ({
            ...acc,
            ...newVal,
          }),
          {},
        );

      setField(openIndex, (value: any) => ({
        ...value,
        ...addition,
      }));
    },
    [setField, openIndex],
  );

  const setCurrentModalApiId = (id: string) => {
    setTableState((value: TableData) => ({
      ...value,
      currentModalApiId: id,
    }));
  };

  const setTableData = (list: any[], pagination: Pagination, metadata?: MetadataItem[]) => {
    setTableState((value: TableData) => {
      const { list: currentList, isAddInProcess } = value;
      const listRemainder = isAddInProcess ? currentList.filter((el) => el.id === NEW_ID) : [];
      return { ...value, list: [...list, ...listRemainder], pagination, metadata };
    });
  };

  useData(
    mainApiUrl,
    page + 1,
    pageSize,
    setTableData,
    keywordList,
    searchValue.slow,
    sorting,
    !!mainApiUrl && keywordList.length > 0 && !!pageSize && columnStructure.length > 0,
    apiTriggers,
    setMainApiReady,
    indexOfPayloadItem,
    withoutQuestionMarkBeforeLimit,
  );

  useModalData(openId, modalApi, enrichModalData, setCurrentModalApiId, setModalApiReady);

  const deleteItem = (id: string, fieldNameOfId?: string) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        list: value.list.filter((elem: any) => (fieldNameOfId ? elem[fieldNameOfId] !== id : elem.id !== id)),
        pagination: { ...value.pagination, total_items: value.pagination.total_items - 1 },
        mobileSelectMode: false,
      };
    });
  };
  const deleteCheckedItems = () => {
    setTableState((value: TableData) => {
      const prevListLength = value.list.length;
      const newList = value.list.filter((item: any) => !item.isChecked);
      const listLength = newList.length;
      const difference = prevListLength - listLength;
      const { total_items } = value.pagination;
      const newPagination = { ...value.pagination, total_items: total_items - difference };
      return { ...value, list: newList, pagination: newPagination, mobileSelectMode: false };
    });
  };

  const setIsSaveButtonDisabled = (status: boolean) => {
    setTableState((tableData: TableData) => ({
      ...tableData,
      isSaveButtonDisabled: status,
    }));
  };

  const toggleIsChecked = (index: number) => {
    setTableState((value: TableData) => {
      const rowCopy = JSON.parse(JSON.stringify(value.list[index]));
      rowCopy.isChecked = !rowCopy.isChecked;
      return { ...value, list: [...value.list.slice(0, index), rowCopy, ...value.list.slice(index + 1)] };
    });
  };
  const toggleIsCheckedAll = () => {
    setTableState((value: TableData) => {
      const newList = value.list.map((item: any, index: number) => {
        const { isChecked } = item;
        if (!isChecked && !value.isSelectAll) {
          return { ...item, isChecked: true };
        } else if (isChecked && value.isSelectAll) {
          return { ...item, isChecked: false };
        } else {
          return item;
        }
      });
      return { ...value, isSelectAll: !value.isSelectAll, list: newList };
    });
  };

  const uncheckAll = () => {
    setTableState((value: TableData) => {
      const newList = value.list.map((item: any, index: number) => ({ ...item, isChecked: false }));
      return { ...value, isSelectAll: false, list: newList };
    });
  };

  const toggleIsEditInProcess = () => {
    setTableState((value: TableData) => {
      return { ...value, isEditInProcess: !value.isEditInProcess };
    });
  };

  const putItem = (item: any) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        list: [
          {
            ...item,
            isChecked: false,
            isEdit: true,
          },
          ...value.list,
        ],
      };
    });
  };

  const addEmptyItem = () => {
    setTableState((value: TableData) => {
      const defaultValue = value.globalProps.columnStructure.reduce(
        (prevItem: any, item: any) =>
          item.type === RowItemType.SELECT
            ? {
                ...prevItem,
                [item.fieldName.id]: item.defaultValue,
              }
            : {
                ...prevItem,
                [item.fieldName]: item.defaultValue,
              },
        {},
      );

      return {
        ...value,
        list: [
          {
            id: NEW_ID,
            ...defaultValue,
            isChecked: false,
            isEdit: true,
          },
          ...value.list,
        ],
      };
    });
  };

  const createItem = (id: string, data: any) => {
    setTableState((value: TableData) => {
      const { list } = value;
      const { length } = list;
      return {
        ...value,
        list: [
          {
            ...list[length],
            id,
            ...data,
            isChecked: false,
            isEdit: false,
          },
          ...list.slice(0, length - 1),
        ],
      };
    });
  };

  const increaseItemCount = () => {
    setTableState((value: TableData) => {
      return { ...value, pagination: { ...value.pagination, total_items: value.pagination.total_items + 1 } };
    });
  };

  const updateLocalItem = (index: number, newValue: any) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        list: [...value.list.slice(0, index), { ...value.list[index], ...newValue }, ...value.list.slice(index + 1)],
      };
    });
  };

  const setGlobalProps = (props: any) => {
    setTableState((value: TableData) => {
      return { ...value, globalProps: { ...value.globalProps, ...props } };
    });
  };

  const toggleView = (index: number | null, id: string | null) => {
    setTableState((value: TableData) => {
      return {
        ...value,
        openIndex: !value.isViewInProcess && (index || index === 0) ? index : DEFAULT_OPEN_INDEX,
        openId: !value.isViewInProcess && id ? id : DEFAULT_OPEN_ID,
        isViewInProcess: !value.isViewInProcess,
        currentModalApiId: !value.isViewInProcess ? value.currentModalApiId : DEFAULT_OPEN_ID,
      };
    });
  };

  const toggleEdit = () => {
    setTableState((value: TableData) => ({
      ...value,
      isEditInProcess: !value.isEditInProcess,
    }));
  };

  const toggleUpdaiting = (value: boolean) => {
    setTableState((current: TableData) => ({
      ...current,
      isUpdaitingInProcess: value,
    }));
  };

  const toggleAdd = () => {
    setTableState((value: TableData) => {
      const { isAddInProcess } = value;
      const addOptions = isAddInProcess
        ? {
            openIndex: DEFAULT_OPEN_INDEX,
            openId: DEFAULT_OPEN_ID,
            currentModalApiId: DEFAULT_OPEN_ID,
          }
        : {};
      return {
        ...value,
        isAddInProcess: !isAddInProcess,
        ...addOptions,
      };
    });
  };

  const toggleDelete = () => {
    setTableState((value: TableData) => ({
      ...value,
      isDeleteInProcess: !value.isDeleteInProcess,
    }));
  };

  const setHighlightedIndex = (index: number = -1) => {
    setTableState((value: TableData) => ({
      ...value,
      highlightedIndex: index,
    }));
  };

  const setLocalDeleteIndex = (index: number = -1) => {
    setTableState((value: TableData) => ({
      ...value,
      localDeleteIndex: index,
    }));
  };

  const setSortingData = useCallback(
    (sorting: SortingData) => {
      setTableState((value: TableData) => {
        if (tablePath && sortingKeys.sort_field && sortingKeys.sort_type) {
          const query = {
            ...queryParams,
            [sortingKeys.sort_field]: sorting.sort_field,
            [sortingKeys.sort_type]: sorting.sort_type,
          };

          handleRedirect({ view: tablePath, queryParams: query, pathParams });
          //history && history.replace(stringifyUrl({ url: getPath(tablePath), query: params }));
        }
        return {
          ...value,
          sorting,
          page: 0,
        };
      });
    },
    [history, sortingKeys, tablePath, pathParams, queryParams],
  );

  const toggleMobileSelectMode = () => {
    setTableState((value: TableData) => ({
      ...value,
      mobileSelectMode: !value.mobileSelectMode,
    }));
  };

  const startAddProcess = () => {
    setTableState((value: TableData) => {
      const defaultValue = value.globalProps.columnStructure.reduce(
        (prevItem: any, item: any) =>
          item.type === RowItemType.SELECT
            ? {
                ...prevItem,
                [item.fieldName.id]: item.defaultValue,
              }
            : {
                ...prevItem,
                [item.fieldName]: item.defaultValue,
              },
        {},
      );

      const { list } = value;
      const openIndex = list.length;
      const openId = NEW_ID;
      const isAddInProcess = true;
      const newList = [
        ...list,
        {
          id: NEW_ID,
          ...defaultValue,
          isChecked: false,
          isEdit: true,
        },
      ];
      // tablePath && history && history.replace(tablePath, { pathParams }); это сбивает offset, когда начинаешь добавление
      return {
        ...value,
        openIndex,
        openId,
        isAddInProcess,
        list: newList,
        pagination: { ...value.pagination, total_items: value.pagination.total_items + 1 },
      };
    });
  };

  const setPage = useCallback(
    (page: number, options: { isOffset?: boolean; replacePath?: boolean } = {}) => {
      const { isOffset, replacePath } = options;
      setTableState((value: TableData) => {
        const resultPage = !!isOffset ? page + value.page : page;
        // if (replacePath && offsetKey && tablePath) {
        //   const offset = pageSize * resultPage;
        //   const query = { ...queryParams, [offsetKey]: offset };

        //   handleRedirect({ view: tablePath, queryParams: query, pathParams });
        //   //history && history.replace(stringifyUrl({ url: getPath(tablePath), query }));
        // }

        return { ...value, page: resultPage };
      });
    },
    [history, tablePath, pathParams, queryParams, offsetKey, pageSize],
  );

  const setSearchValue = useCallback(
    (searchValue: string) => {
      setTableState((value: TableData) => ({
        ...value,
        searchValue: { ...value.searchValue, actual: searchValue },
      }));
      const query = { ...queryParams, search_value: searchValue, [offsetKey]: 0 };
      //tablePath && history && history.replace(stringifyUrl({ url: getPath(tablePath), query }));
      handleRedirect({ view: tablePath, queryParams: query, pathParams });
      timerId.current && clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        if (searchValue.length > 2 || searchValue === '') {
          setTableState((value: TableData) => ({
            ...value,
            searchValue: { ...value.searchValue, slow: searchValue },
          }));
          isSelectAll && toggleIsCheckedAll();
          setPage(0);
        }
      }, 700);
    },
    [timerId, tablePath, pathParams, queryParams, offsetKey, isSelectAll],
  );

  const getReadyState = useCallback(() => {
    return mainApiReady && modalApiReady;
  }, [mainApiReady, modalApiReady]);

  const getSearchValue = useCallback(() => {
    return searchValue;
  }, [searchValue]);

  const getSelectedItems = () => {
    return tableState.list.filter((elem: any) => elem.isChecked);
  };

  const getLocalDeleteName = (fieldName?: string) => {
    const localItem = tableState.localDeleteIndex !== -1 ? tableState.list[tableState.localDeleteIndex] : null;
    return localItem ? (fieldName ? localItem[fieldName] : localItem.name ? localItem.name : localItem.id) : null;
  };

  const getLocalDeleteId = () => {
    const localItem = tableState.localDeleteIndex !== -1 ? tableState.list[tableState.localDeleteIndex] : null;
    return localItem ? localItem.id : null;
  };

  const getViewedData = useCallback(() => {
    const viewedData = list[openIndex];
    let fieldNameOptions = {};
    if (viewedData && viewedData.viewFieldName) {
      fieldNameOptions = { fieldName: viewedData.viewFieldName };
    }
    return viewedData ? { ...viewedData, ...fieldNameOptions } : undefined;
  }, [list, openIndex]);

  const getPresentedItems = useCallback(
    (index: number, windowWidth: any) => {
      const data = list[index];

      const presentedItems =
        columnStructure.length > 0
          ? columnStructure
              .filter((config: ColumnStructure) => {
                if (windowWidth.tablet && withHeaderInMobile) {
                  return config.shownInHeader;
                } else {
                  return !config.excludedFromView;
                }
              })
              .map((item: any) => ({
                ...item,
                value:
                  item.type === RowItemType.SELECT
                    ? { name: data[item.fieldName.name], id: data[item.fieldName.id] }
                    : data[item.fieldName],
              }))
          : [];

      return presentedItems;
    },
    [columnStructure, list],
  );

  const getModalItems = useCallback(() => {
    const data = getViewedData();

    const modalItems =
      columnStructure.length > 0 && data
        ? columnStructure.map((item: any) => {
            const { fieldName, type, isFromRequest, conditionForValidationInCustom } = item;
            const fieldType =
              type === RowItemType.INPUT_EMAIL
                ? FieldsType.EMAIL
                : type === RowItemType.INPUT_PHONE_NUMBER
                ? FieldsType.PHONE_NUMBER
                : type === RowItemType.INPUT_NUMBER
                ? FieldsType.NUMBER
                : type === RowItemType.SELECT || type === RowItemType.JOINED_SELECT
                ? FieldsType.SELECT
                : type === RowItemType.INPUT_CUSTOM || type === RowItemType.CUSTOM
                ? FieldsType.CUSTOM
                : FieldsType.TEXT;

            const value =
              type === RowItemType.SELECT
                ? {
                    name: data[appendFieldName(fieldName.name, isFromRequest)],
                    id: data[appendFieldName(fieldName.id, isFromRequest)],
                  }
                : data[appendFieldName(fieldName, isFromRequest)];
            const valueForValidation =
              (type === RowItemType.SELECT || type === RowItemType.JOINED_SELECT) && value && typeof value !== 'string'
                ? value.name
                : value;

            return {
              ...item,
              fieldName:
                type === RowItemType.SELECT
                  ? {
                      name: appendFieldName(fieldName.name, isFromRequest),
                      id: appendFieldName(fieldName.id, isFromRequest),
                    }
                  : appendFieldName(fieldName, isFromRequest),
              value: value,
              isError: !isValidField(valueForValidation, fieldType, conditionForValidationInCustom),
            };
          })
        : [];
    return modalItems;
  }, [columnStructure, list, getViewedData]);

  const setValidatingIsStarted = (value?: boolean) => {
    const structure = columnStructure.map((item: any) => {
      return { ...item, validatingIsStarted: value };
    });
    setGlobalProps({ columnStructure: structure });
  };

  const getRequiredData = useCallback(() => {
    const data = getModalItems();
    const requiredData = data
      .filter((arrEl) => !arrEl.disabled && !(isAddInProcess && arrEl.excludedFromAdd))
      .reduce((prevItem: any, item: any) => {
        const { fieldName, type, value, isFromRequest } = item;
        return type === RowItemType.SELECT
          ? {
              ...prevItem,
              [deconstructFieldName(fieldName.id, isFromRequest)]: value.id,
            }
          : {
              ...prevItem,
              [deconstructFieldName(fieldName, isFromRequest)]: value,
            };
      }, {});
    return requiredData;
  }, [getModalItems]);

  const getGridStructure = useCallback(
    (index: number, windowWidth: any) => {
      const presentedItems = index !== -1 ? getPresentedItems(index, windowWidth) : columnStructure;
      const preparationFunction = (array: any[], allowSelect: boolean) => {
        const gridLimit = mobileLimit + (mobileLimit % 2);
        const length = array.length;
        const combinedData = array
          .filter((item) => {
            if (withHeaderInMobile) {
              return item.shownInHeader;
            } else {
              return !item.excludedFromView;
            }
          })
          .reduce(
            (prevItem, item: any, index) => {
              return {
                gridTemplate: `${prevItem.gridTemplate} ${item.width ? item.width : '1fr'}`,
                mobileGridTemplate: `${prevItem.mobileGridTemplate} ${index < 2 ? '1fr' : ''}`,
                mobileGridArea: `${prevItem.mobileGridArea}${
                  index < gridLimit
                    ? ` ${index % 2 === 0 ? (allowSelect && mobileSelectMode ? `'select ` : `'`) : ''}item${index}${
                        index % 2 !== 0 ? ` action'` : ''
                      }`
                    : ''
                }`,
                headGridTemplate: `${prevItem.headGridTemplate} ${item.width ? item.width : '1fr'}`,
              };
            },
            { gridTemplate: '', mobileGridTemplate: '', mobileGridArea: '', headGridTemplate: '' },
          );
        const { mobileGridArea, gridTemplate, mobileGridTemplate, headGridTemplate } = combinedData;
        return {
          mobileGridArea: `${mobileGridArea}${length % 2 !== 0 && length < gridLimit ? ` item${length} action'` : ''}`,
          gridTemplate: `${allowSelect ? '30px' : ''} ${gridTemplate} ${
            allowSelect ? '200px' : actionSectionGridWidth
          }`,
          mobileGridTemplate: `${allowSelect && mobileSelectMode ? '42px' : ''} ${mobileGridTemplate}`,
          headGridTemplate: `${allowSelect ? '30px' : ''} ${headGridTemplate} ${
            allowSelect ? '200px' : actionSectionGridWidth
          }`,
        };
      };
      // return preparationFunction(presentedItems, allowDelete || allowSelect);
      return preparationFunction(
        windowWidth.narrowMonitor ? presentedItems.slice(0, 3) : presentedItems,
        allowDelete || allowSelect,
      );
    },
    [allowDelete, allowSelect, mobileSelectMode, mobileLimit, getPresentedItems, actionSectionGridWidth],
  );

  const getHeaderData = useCallback(
    (windowWidth: any) => {
      return columnStructure
        .filter((item) => {
          if (windowWidth.tablet && withHeaderInMobile) {
            return item.shownInHeader;
          } else {
            return !item.excludedFromView;
          }
        })
        .map((item, currentIndex) => {
          let sort_field = '';
          let sortable = false;

          const fieldName = (isColumnOfSingularValue(item.type)
            ? item.fieldName
            : (item.fieldName as { id: string; name: string }).name || null) as string;

          const index =
            metadata && fieldName ? metadata.findIndex((meta) => meta.object_key.includes(fieldName)) : currentIndex;

          const finalIndex = index !== -1 ? index : currentIndex;

          const field_name = metadata && metadata[finalIndex]?.field_name;

          const preparedIndex = metadata
            ? metadata.findIndex((el) => prepareSortableKey(el.sortable_key, item.type) === fieldName)
            : -1;
          if (preparedIndex !== -1 && metadata) {
            const sortable_key = fieldName;
            if (sortable_key) {
              sort_field = metadata[preparedIndex]?.sortable_key;
              sortable = metadata[preparedIndex]?.sortable;
            }
          }
          return { ...item, sortable, sort_field, field_name };
        });
    },
    [columnStructure, metadata],
  );

  const getSortingData = useCallback(() => {
    return sorting;
  }, [sorting]);

  const setOpenIndex = useCallback((index: number) => {
    setTableState((value: TableData) => ({ ...value, openIndex: index }));
  }, []);

  const resetTable = () => {
    setTableState(DEFAULT_STATE);
  };

  const getRawData = useCallback(() => {
    return list;
  }, [list]);

  //@TODO Alex work on this object
  const functions = {
    setIsSaveButtonDisabled,
    setTableData,
    setSearchValue,
    deleteItem,
    deleteCheckedItems,
    toggleIsChecked,
    toggleIsCheckedAll,
    toggleIsEditInProcess,
    addEmptyItem,
    increaseItemCount,
    setField,
    updateLocalItem,
    setGlobalProps,
    putItem,
    getReadyState,
    getSelectedItems,
    getViewedData,
    getLocalDeleteName,
    getLocalDeleteId,
    createItem,
    toggleView,
    toggleEdit,
    toggleUpdaiting,
    toggleAdd,
    toggleDelete,
    setHighlightedIndex,
    setLocalDeleteIndex,
    setSortingData,
    toggleMobileSelectMode,
    getPresentedItems,
    getModalItems,
    getGridStructure,
    startAddProcess,
    uncheckAll,
    enrichModalData,
    getRequiredData,
    getHeaderData,
    getSortingData,
    setOpenIndex,
    getSearchValue,
    setPage,
    setValidatingIsStarted,
    resetTable,
    triggerSave,
    getRawData,
  };

  return [tableState, setTableState, functions];
};

export const TableContextProvider = ({ children }: { children: ReactNode }) => {
  const provider = useTableContextCreator();
  //const [tableState, setTableState] = provider;

  return <TableContext.Provider value={provider}>{children}</TableContext.Provider>;
};

export const useTableContext = (): TableContextProviderType => {
  const service = useContext(TableContext);

  if (!service) {
    throw new Error('Table Context is unavailable');
  }

  return service;
};

export const useTableFunctions = (): TableFunctions => {
  const service = useContext(TableContext);

  if (!service) {
    throw new Error('Table Context is unavailable');
  }

  return service[2];
};

export const withTableContext = (Component: any) => {
  return function WithTableContext(props: any) {
    const service = useTableContext();
    return <Component {...props} tableContext={service} />;
  };
};

export const withTableContextProvider = <P extends object>(Component: ComponentType<P>): FC<P> => ({ ...props }) => {
  const provider = useTableContextCreator();
  return (
    <TableContext.Provider value={provider}>
      {/* @ts-ignore */}
      <Component {...(props as P)} />
    </TableContext.Provider>
  );
};
