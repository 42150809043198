import React, { FC, SyntheticEvent, useState } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { ButtonWrapper, IconWrapper, InputButton, StyledLocationIcon, Wrapper } from './styled';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';
import { SelectAddress } from '../selectAddress/SelectAddress';
import { TableCustomComponentState } from '../../features/universalTable/types';
import { Address } from '../../types/address';
import { useTheme } from '@cthings.co/styled-components';

export interface AddressInputProps {
  title?: string;
  placeholder: string;
  value: string;
  isDescriptionModal?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  startedValidation?: boolean;
  addressState: TableCustomComponentState;
  address: Address;
  setAddress: (param: Address) => void;
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
  className?: string;
  onConfirm?: any;
  onCancel?: any;
}

export const AddressInput: FC<AddressInputProps> = ({
  title,
  value,
  placeholder,
  isDescriptionModal,
  isDisabled,
  isError,
  startedValidation,
  addressState,
  address,
  setAddress,
  onConfirm,
  onCancel,
  ...rest
}) => {
  const theme = useTheme();
  const {
    colors: { gray1, gray2, red2 },
  } = theme;

  const languageStrings = useSelector(selectLanguageStrings);
  const [saveToggle, setSaveToggle] = useState(false);
  const toggleSave = () => setSaveToggle((val) => !val);

  const handleConfirm = (props: any) => {
    onConfirm && onConfirm(props);
    toggleSave();
  };

  const handleCancel = (props: any) => {
    onCancel && onCancel(props);
  };

  const isErrorShown = !!isError && !!startedValidation;

  const [isOpenAddressPopup, setIsOpenAddressPopup] = useState(false);

  return (
    <Wrapper {...rest}>
      {title && (
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin={'0 0 2px 6px'}>
          {title}
        </Text>
      )}
      <ButtonWrapper onClick={() => setIsOpenAddressPopup(true)}>
        <InputButton isDisabled={isDisabled} isError={isErrorShown} isPlaceholder={!value} theme={theme}>
          {value || placeholder}
        </InputButton>
        <IconWrapper>
          <StyledLocationIcon gray2={gray2} />
        </IconWrapper>
      </ButtonWrapper>
      {isErrorShown && (
        <Text type={TextType.TEXT_12_GRAY} color={red2} margin={'4px 0 0 12px'}>
          {`${title} ${languageStrings.validationMessage}`}
        </Text>
      )}
      <SelectAddress
        state={addressState}
        address={address}
        titleOfPopup={languageStrings.organisationAddress}
        setValue={setAddress}
        saveToggle={!!saveToggle}
        handleUpdateDevice={() => {}}
        setExternalState={(isOpen: boolean) => setIsOpenAddressPopup(isOpen)}
        externalState={isOpenAddressPopup}
        isInUniversalTable={false}
        isDescriptionModal={isDescriptionModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </Wrapper>
  );
};
