import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: reducers,
});

export const {
  setIsAuthenticated,
  setNfcFlag,
  setInitialFlag,
  setManagmentSearchValue,
  setProvisioningData,
  setNewOrgProvisioningData,
  clearProvisioningData,
} = appSlice.actions;

export default appSlice.reducer;
