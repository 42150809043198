import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as TickIcon } from '../../../../../../../assets/Tick.svg';
import { CloseWhiteRoundButton } from '../../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../../../utils/date/date-format';
import { useBlockBodyScroll } from '../../../../../../../utils/useBlockBodyScroll';
import { InfoIcon } from './components/infoIcon/InfoIcon';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 527px !important;
  height: max-content;
  padding: 25px 24px !important;
  display: flex;
  align-items: flex-start !important;
  ${media.phone} {
    width: 100% !important;
    position: fixed !important;
    padding: 24px 16px 18px !important;
    bottom: 0 !important;
    border-radius: 16px 16px 0 0 !important;
  }
`;

const Title = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black')};
  margin-bottom: 16px !important;
` as any; // @TODO fix type

const StyledRoundCloseButton = styled(CloseWhiteRoundButton)`
  display: none;
  ${media.phone} {
    display: flex;
  }
`;

type RowProps = {
  marginBottom?: string;
};

const Row = styled.div<RowProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

type BlockProps = {
  margin?: string;
};

const Block = styled.div<BlockProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin: ${({ margin }) => margin};
`;

const StyledTickIcon = styled(TickIcon)`
  margin-right: 4px;
  & > path {
    stroke: ${colorFetch('secondary2')};
  }
`;

const Line = styled.div`
  justify-content: flex-start;
  width: 100%;
  height: 1px;
  background-color: ${colorFetch('gray4')};
  margin: 7px 0 14px;
`;

type AdaptiveBlockProps = {
  alignItems: string;
};

// @TODO fix media types
const AdaptiveBlock = styled(Block)<AdaptiveBlockProps>`
  ${media.phone} {
    flex-direction: column;
    align-items: ${({ alignItems }: any) => alignItems};
  }
`;

const StyledCommonButton = styled(CommonButton)`
  width: 104px;
  align-self: flex-end;
  margin-top: 19px;
  ${media.desktop} {
    height: 40px;
  }
  ${media.phone} {
    width: 100%;
  }
`;

const Comma = styled(Text)`
  ${media.phone} {
    display: none;
  }
`;

export const QualityModal = ({
  isOpen,
  languageStrings,
  deviceTests,
  closeModal,
}: {
  isOpen: boolean;
  languageStrings: any;
  deviceTests: any;
  closeModal: () => void;
}) => {
  const theme = useTheme();
  const { primary, gray1 } = theme.colors;
  const { tests, qc_personnel, created_at } = deviceTests;

  useBlockBodyScroll(isOpen);

  return (
    <StyledModal isOpenModal={isOpen}>
      <StyledModalContainer>
        <Title>{languageStrings.qualityControl}</Title>
        <StyledRoundCloseButton onClick={closeModal} />
        {tests.length ? (
          tests.map((test: any) => (
            <Row marginBottom={'10px'}>
              <Block>
                <Text margin={'0 4px 0 0'} type={TextType.TEXT_14_BLACK} color={gray1}>
                  {test.name}
                </Text>
                <InfoIcon tooltip={test.tooltip} />
              </Block>
              <Block margin={'0 0 0 25px'}>
                <StyledTickIcon />
                <Text type={TextType.TEXT_14_GREEN} weight={'400'} color={primary}>
                  {languageStrings.passed}
                </Text>
              </Block>
            </Row>
          ))
        ) : (
          <Text type={TextType.TEXT_14_GRAY} weight={'400'} margin={'2px 0 11px 0'}>
            {languageStrings.noTestInformation}
          </Text>
        )}
        <Line />
        <Row>
          <AdaptiveBlock alignItems={'flex-start'}>
            <Text type={TextType.TEXT_14_GRAY} weight={'400'} margin={'0 4px 0 0'}>
              {`${languageStrings.personnelLabel}:`}
            </Text>
            <Text type={TextType.TEXT_14_GRAY}>{tests.length ? qc_personnel : 'N/A'}</Text>
          </AdaptiveBlock>
          <AdaptiveBlock alignItems={'flex-end'}>
            {tests.length ? (
              <>
                <Text type={TextType.TEXT_14_GRAY} weight={'400'}>
                  {getRequiredDateFormat(created_at, 'DD.MM.YYYY')}
                </Text>
                <Comma type={TextType.TEXT_14_GRAY} weight={'400'}>
                  ,
                </Comma>
                <Text type={TextType.TEXT_14_GRAY} weight={'400'} margin={'0 0 0 4px'}>
                  {getRequiredDateFormat(created_at, 'HH:mm')}
                </Text>
              </>
            ) : null}
          </AdaptiveBlock>
        </Row>
        <StyledCommonButton onClick={closeModal}>{languageStrings.closeButtonValue}</StyledCommonButton>
      </StyledModalContainer>
    </StyledModal>
  );
};
