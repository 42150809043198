import React from 'react';
import styled from '@cthings.co/styled-components';
import { useDashboardData } from '../../../api/dashboard';
import { media } from '@cthings.co/styles-utils';
import { Text, TextType } from '@bit/first-scope.text';
import { CardType } from '@bit/first-scope.dashboard-card';
import { useSelector } from 'react-redux';
import { selectAccess, selectLanguageStrings } from '../../../app/state/user';
import { Pie } from '../../../components/pie/Pie';
import { DashboardCard } from '../../../components/dashboardCard/DashboardCard';
import { useWindowSize } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${media.tablet} {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

const StyledText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;

const CardsWrapper = styled.div`
  max-width: 880px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${media.semiTablet} {
    max-width: 100%;
  }
`;

const StyledDashboardCard = styled(DashboardCard)`
  cursor: default;

  ${media.semiTablet} {
    max-width: 100%;
  }
  &.piechart {
    padding: 20px 15px 30px 0;
  }
` as any; // @TODO fix type

export const Dashboard = () => {
  const keywordList = ['DashboardCard'];

  const [data] = useDashboardData(keywordList);
  const userAccess = useSelector(selectAccess);

  const languageStrings = useSelector(selectLanguageStrings);

  const windowSize = useWindowSize();

  const placeholderCard1Style =
    windowSize[0] > 1024
      ? { width: 'max-content', height: 'max-content', margin: '0 20px 20px 0' }
      : windowSize[0] > 550
      ? { width: 'max-content', height: 'max-content', margin: '0 20px 20px 0' }
      : { width: '100%', height: 'max-content', margin: '0 0 20px 0' };

  const placeholderCard2Style =
    windowSize[0] > 1024
      ? { width: 'max-content', height: 'max-content', margin: '0 20px 20px 0' }
      : windowSize[0] > 550
      ? { width: 'max-content', height: 'max-content', margin: '0 0 20px 0' }
      : { width: '100%', height: 'max-content', margin: '0 0 20px 0' };

  const placeholderCard3Style =
    windowSize[0] > 1024
      ? { width: 'max-content', height: 'max-content', margin: '0 0 20px 0' }
      : windowSize[0] > 550
      ? { width: 'max-content', height: 'max-content', margin: '0 20px 20px 0' }
      : { width: '100%', height: 'max-content', margin: '0 0 20px 0' };

  return (
    <Wrapper>
      <StyledText type={TextType.TITLE_H1} margin={'0 0 40px 0'}>
        {languageStrings.dashboardTitle}
      </StyledText>
      <CardsWrapper>
        <StyledDashboardCard
          placeholderProps={{
            keyword: 'DashboardCard',
            style: placeholderCard1Style,
          }}
          languageStrings={languageStrings}
          type={CardType.DEVICES}
          count={data.total_devices}
        />
        <StyledDashboardCard
          placeholderProps={{
            keyword: 'DashboardCard',
            style: placeholderCard2Style,
          }}
          languageStrings={languageStrings}
          type={CardType.LOCATIONS}
          count={data.total_locations}
        />
        {userAccess.role_name === 'Admin' && (
          <StyledDashboardCard
            className="piechart"
            placeholderProps={{
              keyword: 'DashboardCard',
              style: placeholderCard3Style,
              placeholderOptions: { isLargeCard: true },
            }}
            languageStrings={languageStrings}
            type={CardType.CITIES}
            data={data.locations_piechart}
            titleClassName={'title'}
          >
            <Pie data={data.locations_piechart} />
          </StyledDashboardCard>
        )}
      </CardsWrapper>
    </Wrapper>
  );
};
