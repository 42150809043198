import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 137px;

  background: linear-gradient(
    180deg,
    rgba(242, 242, 245, 0) 0%,
    ${({ theme }) => theme.colors.gray5} 62.5%,
    ${({ theme }) => theme.colors.gray5} 100%
  );

  position: absolute;
  bottom: 0px;
  z-index: calc(var(--navigation-layer) - 1);
  pointer-events: none;
`;

export interface OverlayGradientProps {
  className?: string;
}

export const OverlayGradient: FC<OverlayGradientProps> = ({ ...props }) => {
  const theme = useTheme();

  return <Wrapper theme={theme} {...props}></Wrapper>;
};
