import { CommonButton } from '@cthings.co/buttons';
import { useTheme } from '@cthings.co/styled-components';
import React, { ReactNode } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { ReactComponent as LocationIcon } from '../.././../../../../../../../assets/location_device_details.svg';
import { NavLink } from '../../../../../../../../../utils/react-router-dom-abstraction';
import ss from '../../../../../../../../../utils/ss';
import { LsValueType } from '../../../../../../../../../enums/LsValueType';

// @TODO fix media types
const StyledButton = styled(CommonButton)`
  height: 32px;
  width: max-content;
  padding: 0 34px;
  margin-left: 10px;
  & > svg {
    margin-right: 2px;
    & > path {
      transition: 0.3s ease;
    }
  }
  &:hover > svg > path {
    stroke: ${({ colors: { mainHover } = {} }) => mainHover};
  }

  ${media.desktop} {
    width: 70px;
    padding: 0;
    &:hover > svg > path {
      stroke: ${({ colors: { mainHover } = {} }) => mainHover};
    }
  }

  ${media.tablet} {
    margin-left: 10px;
    & > svg {
      margin: 0;
    }
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
`;

export const LocationButton = ({ children, href }: { children: ReactNode; href: string }) => {
  const theme = useTheme();
  const { primary, pureWhite } = theme.colors;
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  return (
    <StyledLink access={[]} to={`${href}?access_token=${token}&expires=${exp}`}>
      <StyledButton
        colors={{
          main: primary,
          mainHover: pureWhite,
          background: pureWhite,
        }}
      >
        <LocationIcon />
        {children}
      </StyledButton>
    </StyledLink>
  );
};
