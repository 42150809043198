import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text } from '@bit/first-scope.text';
import { InputModal } from '../../../../components/inputModal/InputModal';
import { Select } from '../../../../components/universalSelect/Select';
import { CommonButton } from '../../../../components/commonButton/CommonButton';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { ReactComponent as CloseIconRaw } from '../../../../assets/Close.svg';
import { CustomModal } from '../customModal/CustomModal';
import { ThemeType } from '@cthings.co/styled-components';

export const StyledCustomModal = styled(CustomModal)`
  & > div > div > div {
    overflow: visible;
    ${media.phone} {
      overflow: auto;
    }
  }
`;

export const StyledModal = styled(Modal)`
  z-index: 10000000;

  ${media.phone} {
    align-items: flex-end;
    & > div {
      width: 100%;
    }
  }
`;

export const StyledModalContainer = styled(ModalContainer)`
  align-items: unset;
  padding: 24px;
  width: 100%;

  ${media.phone} {
    padding: 24px 16px;
    border-radius: 12px 12px 0px 0px;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  margin: 0 0 16px 0;

  ${media.phone} {
    justify-content: space-between;
  }
`;

type TextType = typeof Text & { theme: ThemeType };

export const StyledText = styled(Text).attrs(({ theme: { colors: { black1 } }, ...rest }: TextType) => ({
  ...rest,
  fontSize: '18px',
  weight: '600',
  color: black1,
}))``;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  ${media.phone} {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  margin: 8px 0 0 0;
  justify-content: ${({ justifyContent }: { justifyContent: string }) => justifyContent};
  ${media.phone} {
    flex-direction: column-reverse;
    margin: 0;
  }
`;

export const StyledInput = styled(InputModal)`
  width: 248px;
  margin: 0 0 14px 0;
  ${media.phone} {
    width: 100%;
    margin: 0 0 16px 0;
  }
` as typeof InputModal;

export const StyledSelect = styled(Select)`
  width: 248px;
  ${media.phone} {
    width: 100%;
  }
` as typeof Select;

export const ButtonSection = styled.div`
  display: flex;
  gap: 16px;
  ${media.phone} {
    flex-direction: column-reverse;
    gap: 0;
  }
`;

export const CloseIcon = styled(CloseIconRaw)`
  display: none;
  ${media.phone} {
    display: block;
  }
`;

export const StyledCommonButton = styled(CommonButton)`
  height: 32px;
  width: 116px;

  ${media.phone} {
    height: 40px;
    width: 100%;
  }
`;

type ButtonType = typeof CommonButton & { theme: ThemeType };

export const DeleteButton = styled(StyledCommonButton).attrs(
  ({
    theme: {
      colors: { black1, red1, pureWhite },
    },
    ...rest
  }: ButtonType) => ({
    ...rest,
    fontSize: '14px',
    color: black1,
    borderColor: red1,
    backgroundColor: pureWhite,
    backgroundColorHover: red1,
  }),
)`
  margin: 0;

  ${media.phone} {
    margin: 10px 0 0 0;
  }
`;

export const CancelButton = styled(StyledCommonButton).attrs(
  ({
    theme: {
      colors: { primary: primaryColor, pureWhite, black1 },
    },
    ...rest
  }: ButtonType) => ({
    ...rest,
    fontSize: '14px',
    backgroundColor: pureWhite,
    borderColor: primaryColor,
    color: black1,
  }),
)`
  margin: 0;

  ${media.phone} {
    margin: 0 16px 0 0;
  }
`;

export const SaveButton = styled(StyledCommonButton).attrs((props: ButtonType) => ({
  ...props,
  fontSize: '14px',
}))`
  margin: 0;

  ${media.phone} {
    margin: 24px 0 10px 0;
  }
`;
