import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

export const ExtraWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${media.tablet} {
    padding: 0 16px 32px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;
