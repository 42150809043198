import { GenerateReportModalState } from './types';

export const initialState: GenerateReportModalState = {
  ts_from: '',
  ts_to: '',
  locations: {
    parameters: [
      {
        id: '',
        line1: '',
        line2: '',
        zip: '',
        city: '',
        region: '',
        country: '',
        geotag: {
          lat: '',
          lng: '',
        },
        isSelected: false,
      },
    ],
    selected_parameters: [],
    isOpenPopup: false,
    isSelectAll: false,
    isError: false,
    isUploadingInProcess: false,
  },
  customers: {
    parameters: [{ id: '', name: '', isSelected: false }],
    selected_parameters: [],
    isOpenPopup: false,
    isSelectAll: false,
    isError: false,
    isUploadingInProcess: false,
  },
  recipients: {
    parameters: [{ id: '', name: '', secondary_name: '', isSelected: false }],
    selected_parameters: [],
    isOpenPopup: false,
    isSelectAll: false,
    isError: false,
    isUploadingInProcess: false,
  },
  isOpenMobileDateModal: false,
  validatingIsStarted: false,
};
