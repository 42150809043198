import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RowItemType } from '../../../../../../features/universalTable/types';
import { selectLanguageStrings } from '../../../../../../app/state/user';

import { checkFieldValidity } from '../../../../../../utils/validation';
import { inputStructureData } from './data';
import { DEFAULT_ADDRESS, EMPTY_ADDRESS } from '../../../../../../components/selectAddress/data';

export const useInputStructure = ({ getRowEssentials }: { getRowEssentials: Function }) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const [startedValidation, setStartedValidation] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [localState, setLocalState] = useState({ orgName: '', vat: '', address: DEFAULT_ADDRESS });

  const [savedData, setSavedData] = useState({ orgName: null, vat: null, address: EMPTY_ADDRESS });

  const handleInputOnChange = (fieldName: keyof typeof localState, value: any) => {
    setLocalState((currentVal) => ({ ...currentVal, [fieldName]: value }));
  };

  const processRowData = ({
    type,
    id,
    ids,
    value,
    isRequired,
    validationRule,
    fieldName,
    onChange,
  }: {
    type: any;
    id: string;
    ids: string[];
    value: any;
    isRequired: boolean;
    validationRule: any;
    fieldName: any;
    onChange: any;
  }) => {
    let data: any;
    if (type === RowItemType.ADDRESS) {
      data = ids.reduce(
        (acc: any, currentItem: any) => {
          const singleData = getRowEssentials(currentItem);
          const { rawData, setItem } = singleData;
          const finalValue = { ...acc.value, [currentItem]: rawData?.value?.actual_value };
          const finalSetItem = (value: any) => {
            acc.setItem && acc.setItem(value);
            setItem('value', { ...finalValue[currentItem], actual_value: value[currentItem] });
          };
          return { value: finalValue, setItem: finalSetItem };
        },
        { value: {}, setItem: null as any },
      );
    } else {
      const { rawData, setItem } = getRowEssentials(id);
      data = { value: rawData, setItem: (value: any) => setItem('value', value) };
    }

    const handleOnChange = (value: any) => {
      onChange(value);
      fieldName === 'address'
        ? data.setItem({
            country: value.country,
            region: value.region,
            city: value.city,
            address: value,
            postcode: value.zip,
          })
        : data.setItem((oldData: any) => {
            return {
              ...oldData,
              value: {
                ...oldData.value,
                actual_value: value,
              },
            };
          });
    };

    const validationOptions = {
      isError: isRequired
        ? !checkFieldValidity({ value: fieldName === 'address' ? value?.line1 : value, validationRule })
        : false,
      startedValidation,
    };

    const { value: tableValue } = data || {};

    const defaultValue =
      fieldName === 'address'
        ? {
            country: tableValue?.country,
            region: tableValue?.region,
            city: tableValue?.city,
            line1: tableValue?.address?.line1,
            line2: tableValue?.address?.line2,
            zip: tableValue?.postcode,
            geotag: tableValue?.address?.geotag || value?.geotag,
          }
        : data.value?.value?.actual_value;

    const readableAddress = `${defaultValue?.city ? defaultValue?.city + ', ' : ''}${defaultValue?.line1 || ''}`;

    return [{ data, validationOptions, defaultValue, readableAddress }, { handleOnChange }] as const;
  };

  const inputStructure = inputStructureData.map(({ fieldName, languageStringsKeys, ...rest }) => ({
    ...rest,
    fieldName,
    label: languageStrings[languageStringsKeys.label],
    placeholder: languageStrings[languageStringsKeys.placeholder],
    onChange: (value: any) => handleInputOnChange(fieldName, value),
    value: localState[fieldName],
  }));

  const isFormValid = inputStructure.every(({ value, fieldName, isRequired, validationRule }) => {
    return isRequired
      ? checkFieldValidity({
          value: fieldName === 'address' ? (value as any)?.line1 : value,
          validationRule,
        })
      : true;
  });

  useEffect(() => {
    if (!isLoaded) {
      inputStructure.forEach((el: any) => {
        const [{ data, defaultValue }, { handleOnChange }] = processRowData(el);
        const { fieldName } = el;
        const { value } = data;
        setIsLoaded(true);
        if (fieldName === 'address' ? !!value?.address && !!value?.country : !!value?.value?.actual_value) {
          setSavedData((currentSaved) => ({ ...currentSaved, [fieldName]: defaultValue }));
          handleOnChange(defaultValue);
        }
        //
      });
    }
  }, [inputStructure]);

  const handleRevert = () => {
    inputStructure.forEach((el: any) => {
      const [, { handleOnChange }] = processRowData(el);
      const { fieldName } = el;

      handleOnChange(savedData[fieldName as keyof typeof savedData]);
    });
  };

  /*const handleRevertField = (fieldName: keyof typeof savedData) => {
    const index = inputStructure.findIndex((el) => el.fieldName === fieldName);

    if (index !== -1) {
      const el = inputStructure[index];
      const [, { handleOnChange }] = processRowData(el as any);

      handleOnChange(savedData[fieldName]);
    }
  };*/

  return [
    {
      inputStructure,
      isFormValid,
      state: localState,
      isLoaded,
    },
    {
      handleInputOnChange,
      setStartedValidation,
      processRowData,
      handleRevert,
      setSavedData,
    },
  ] as const;
};
