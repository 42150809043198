import React from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { TableItemState } from '../../../../../features/universalTable/types';
import { useTheme } from '@cthings.co/styled-components';

export const Severity = ({
  status,
  isResolved,
  state,
  languageStrings,
}: {
  status: number;
  isResolved: boolean;
  state: any;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { gray1, orange2, red2, blue2 } = theme.colors;

  const value =
    status === 1
      ? { name: languageStrings.severityLow, severity_status: 1 }
      : status === 2
      ? { name: languageStrings.severityMedium, severity_status: 2 }
      : status === 3
      ? { name: languageStrings.severityHigh, severity_status: 3 }
      : { name: 'N/A', severity_status: 4 };

  const textColor = isResolved
    ? gray1
    : status === 0
    ? gray1
    : status === 1
    ? blue2
    : status === 2
    ? orange2
    : status === 3
    ? red2
    : gray1;

  return (
    <>
      {state === TableItemState.VIEW ? (
        <Text type={TextType.TEXT_14_BLACK} color={textColor}>
          {value.name}
        </Text>
      ) : (
        <></>
      )}
    </>
  );
};
