import React from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../app/state/user';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .title {
    font-weight: 700;
    margin-bottom: 10px;
  }
`;

const List = styled.ul`
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0 0 40px 0;
  & > span {
    font-weight: 400;
  }
`;

const ListItem = styled.li`
  color: ${colorFetch('gray1')};
  font-size: 14px;
  line-height: 24px;
  margin-left: 18px;
  &.semiTitle {
    list-style: none;
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${media.phone} {
    flex-direction: column-reverse;
  }

  & > .cancel {
    margin-right: 16px;
    ${media.phone} {
      margin: 10px 0 0 0;
    }
  }
`;

type ButtonProps = {
  lang: string;
  isBlocked?: boolean;
};

// @TODO fix media type

const Button = styled(CommonButton)<ButtonProps>`
  width: ${({ lang }) => (lang === 'DE' ? '138px' : '104px')};
  ${media.tablet} {
    width: 100%;
    height: 40px;
    pointer-events: ${({ isBlocked }: any) => isBlocked && 'none'};
  }
  ${media.phone} {
    width: 100%;
  }
`;

export const DeletePopup = ({
  title,
  semiTitle,
  deletedElementsList,
  handleOnClose,
  handleOnDelete,
  languageStrings,
  ...props
}: {
  title: string;
  semiTitle: string;
  deletedElementsList?: string[];
  handleOnClose: (value: React.SetStateAction<boolean>) => void;
  handleOnDelete: (() => void) | undefined;
  className?: string;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { black1, gray1, red2, red1, pureWhite } = theme.colors;
  const lang = useSelector(selectLanguage).shortName;

  return (
    <Wrapper {...props}>
      <Text className="title" color={black1} type={TextType.TEXT_14_BLACK}>
        {title}
      </Text>
      <List>
        <ListItem className="semiTitle" color={gray1}>
          {semiTitle}
        </ListItem>
        {deletedElementsList &&
          deletedElementsList.map((item: any, index: number) => {
            return <ListItem key={index}>{item}</ListItem>;
          })}
      </List>
      <ButtonWrapper>
        <Button
          className="cancel"
          colors={{
            main: black1,
            background: pureWhite,
          }}
          onClick={handleOnClose as any}
          lang={lang}
        >
          {languageStrings.manageClientsMultipleDeleteModalButtonsNo}
        </Button>
        <Button
          colors={{
            background: red2,
            backgroundHover: red1,
            border: 'none',
            borderHover: red1,
          }}
          onClick={handleOnDelete}
          lang={lang}
        >
          {languageStrings.manageClientsMultipleDeleteModalButtonsYes}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
