import { useEffect, useState } from 'react';
import { API_URL, CLIENT_ID, NEW_ID } from '../../consts';
import { LsValueType } from '../../enums/LsValueType';
import { ErrorAddCustomerEventType, ErrorEventType } from '../../types/error';
import { HttpService } from '../../utils/http';
import ss from '../../utils/ss';
import { urlEncodeArray } from '../../utils/urlEncode';

export const updateUsers = (
  id: string,
  data: { name: string; email: string; phone: string; client_id: string; role: number },
  index: number,
  updateLocalItem: any,
  toggleUpdaiting: any,
  handleAddNotification: Function,
  setIsSaveButtonDisabled: (status: boolean) => void,
  languageStrings?: any,
) => {
  const mockData = { ...data, phone: '' };
  if (id !== NEW_ID) {
    setIsSaveButtonDisabled(true);
    const url = `${API_URL}/v2/users/${id}/?client_id=${CLIENT_ID}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, mockData)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
        setIsSaveButtonDisabled(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
        setIsSaveButtonDisabled(false);
      }
      toggleUpdaiting(false);
    })();
  } else {
    setIsSaveButtonDisabled(true);
    const url = `${API_URL}/v2/users?client_id=${CLIENT_ID}`;
    (async () => {
      try {
        let responseData = (await HttpService.post(url, mockData)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: languageStrings.successfullSavingMessage,
          type: 'success',
        });
        setIsSaveButtonDisabled(false);
      } catch (e) {
        const error = e as ErrorAddCustomerEventType;
        const errorEvent = e as ErrorEventType;
        const errorMessage = error?.response?.data?.detail[0]?.msg ?? errorEvent?.response?.data?.detail;
        handleAddNotification({ message: errorMessage, type: 'error' });
        setIsSaveButtonDisabled(false);
      }
      toggleUpdaiting(false);
    })();
  }
};

export const deleteUserGlobal = (
  id: string[],
  deleteUser: any,
  deleteCheckedUsers: any,
  handleAddNotification: Function,
  languageStrings?: any,
) => {
  const data = { user_ids: id };
  const url = `${API_URL}/v2/users/delete`;
  const deleteOneUser = () => deleteUser(id[0]);

  (async () => {
    try {
      await HttpService.post(url, data);
      if (id.length === 1) {
        deleteOneUser();
      } else {
        deleteCheckedUsers();
      }
      handleAddNotification({
        message: languageStrings.messageAboutSuccessfullyDeleted,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleAddNotification({ message: `${error?.response?.data?.detail}`, type: 'error' });
    }
  })();
};

export const useLoggedUserInfo = () => {
  const [userInfo, setUserInfo] = useState<any>();
  useEffect(() => {
    const url = `${API_URL}/v2/users/one`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setUserInfo(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);
  return userInfo;
};

type UserExportParams = {
  client_ids: string[];
};

export const generateUserDetailsExportUrl = (selectedParams: UserExportParams, lang: string) => {
  const encodedClientIds = urlEncodeArray(selectedParams.client_ids);

  return `${API_URL}/v2/users/export/generate?access_token=${ss.get(
    LsValueType.token,
  )}&client_ids=${encodedClientIds}&lang=${lang}`;
};
