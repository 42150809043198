/**
 * @description
 * This is a section with table pagination links generation
 * according to defined behavior.
 *
 */

import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { ArrowButton } from '@bit/first-scope.arrow-button';
import { Text, TextType } from '@bit/first-scope.text';
import { ArrowButtonType } from '../../../../../components/arrowButton/ArrowButton';
import { media } from '@cthings.co/styles-utils';
import { useMediaType } from '@cthings.co/styles-utils';
import { GoToPagination } from '../goToPagination/GoToPagination';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';
import { stringifyUrl } from 'query-string';
import { injectPathParams } from '@cthings.co/utils';
import { View, getPath } from '../../../../../routes';
import { NavLink } from '../../../../../utils/react-router-dom-abstraction';
import { useTableContext } from '../../../../../features/universalTable/context';
import ss from '../../../../../utils/ss';
import { LsValueType } from '../../../../../enums/LsValueType';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.phone} {
    width: 63%;
    justify-content: space-between;
  }

  ${media.phoneXS} {
    width: 100%;
    justify-content: space-between;
  }
`;

// @TODO fix media types
const StyledArrowButton = styled(ArrowButton)`
  margin: ${({ type }) => (type === ArrowButtonType.LEFT ? '0 18px 0 0' : '0 0 0 18px')};
  ${media.phone} {
    margin: ${({ type }: any) => (type === ArrowButtonType.LEFT ? '0 8px 0 0' : '0 0 0 8px')};
  }
` as any; // @TODO fix type

type NumberingWrapperProps = {
  numberOfPages: number;
};

// @TODO fix media types
const NumberingWrapper = styled.div<NumberingWrapperProps>`
  display: flex;
  ${media.phone} {
    width: 100%;
    justify-content: ${({ numberOfPages }: any) => (numberOfPages > 3 ? 'space-around' : 'center')};
  }
`;

type NumberWrapperProps = {
  isBigNumber?: boolean;
  editInProcess?: boolean;
  numberOfPages?: number;
};

// @TODO fix media types
const NumberWrapper = styled.div<NumberWrapperProps>`
  width: 24px;
  height: 24px;
  padding: ${({ isBigNumber }) => (isBigNumber ? '0 7px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  margin: 0 11px;

  cursor: ${({ editInProcess }) => (editInProcess ? 'auto' : 'pointer')};
  user-select: none;

  &.bigNumber {
    padding: 0 7px;
    background-color: ${colorFetch('secondary3')};
    border-radius: 15px;
    & > span {
      color: ${colorFetch('primary')};
    }
  }

  &:hover {
    background-color: ${colorFetch('green5')};
    border-radius: ${({ isBigNumber, theme }) => (isBigNumber ? '15px' : theme.borderRadius.round)};
  }

  &:hover > span {
    color: ${colorFetch('primary')};
  }

  &.selected {
    background-color: ${colorFetch('green5')};
  }
  &.selected > span {
    color: ${colorFetch('primary')};
  }
  ${media.phone} {
    margin: ${({ numberOfPages }: any) => (numberOfPages <= 3 ? '0 14px' : '0 4px')};
  }
`;

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 11px;
  ${media.phone} {
    margin: 0 6px;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

export interface PaginationNumberingProps {
  pagesNum: number;
  currentPage: number;
  onPageChange: any;
  editInProcess?: boolean;
  languageStrings?: any;
  className?: string;
}

export const PaginationNumbering: FC<PaginationNumberingProps> = ({
  pagesNum,
  onPageChange,
  currentPage,
  editInProcess,
  languageStrings,
  ...props
}) => {
  // @NOTE middleArraySize has to be ALWAYS an odd number for logic of pagination to work
  const { semiTablet } = useMediaType();
  const token = ss.get(LsValueType.token);
  const exp = ss.get(LsValueType.exp);

  const middleArraySize = semiTablet ? 1 : 5;
  const distanceToArrayCenter = (middleArraySize - 1) / 2;
  const endDelta = pagesNum - currentPage - 1;
  const cutNegative = (num: number) => (num >= 0 ? num : 0);
  const size = pagesNum >= middleArraySize ? middleArraySize : pagesNum;
  const backOffset = cutNegative(distanceToArrayCenter - endDelta);
  const offset = 1 + cutNegative(currentPage - backOffset - distanceToArrayCenter);
  const middleArray = [...(Array(size) as any).keys()].map((key) => key + offset);
  const theme = useTheme();
  const arrOfNum = Array.from(Array(pagesNum).keys());

  const [{ globalProps }] = useTableContext();

  const handleLeftArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(-1, true);
  };
  const handleRightArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(1, true);
  };

  const handleItemClick = (value: number) => {
    onPageChange(value);
  };

  const ifStartNumber = currentPage > distanceToArrayCenter;
  const ifStartDots = currentPage > distanceToArrayCenter + 1 && arrOfNum.length >= 5;
  const ifEndNumber = endDelta > distanceToArrayCenter;
  const ifEndDots = endDelta > distanceToArrayCenter + 1 && arrOfNum.length >= 3;

  const getLength = (number: number) => {
    return number.toString().length;
  };
  const { queryParams, tablePath, pathParams, pageSize, offsetKey } = globalProps;

  const startNumberCurrentOffset = 0;
  const startNumberQuery = { ...queryParams, [offsetKey]: startNumberCurrentOffset, access_token: token, expires: exp };

  const startNumberHref = stringifyUrl({
    url: injectPathParams(getPath(tablePath as typeof View[keyof typeof View]), pathParams),
    query: startNumberQuery,
  });

  const endNumberCurrentOffset = pageSize * (pagesNum - 1);
  const endNumberQuery = { ...queryParams, [offsetKey]: endNumberCurrentOffset, access_token: token, expires: exp };

  const endNumberHref = stringifyUrl({
    url: injectPathParams(getPath(tablePath as typeof View[keyof typeof View]), pathParams),
    query: endNumberQuery,
  });

  return (
    <Wrapper {...props}>
      {currentPage > 0 && (
        <StyledArrowButton
          onClick={handleLeftArrowClick}
          color={theme.colors.primary}
          colorHover={theme.colors.secondary3}
          type={ArrowButtonType.LEFT}
        />
      )}
      <NumberingWrapper numberOfPages={pagesNum}>
        {ifStartNumber && arrOfNum.length > 5 && (
          <StyledNavLink access={[]} to={startNumberHref}>
            <NumberWrapper editInProcess={editInProcess} theme={theme} className={currentPage === 0 ? 'selected' : ''}>
              <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
                {'1'}
              </Text>
            </NumberWrapper>
          </StyledNavLink>
        )}
        {ifStartDots && arrOfNum.length > 5 && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {middleArray.map((item) => {
          const lengthNumber = getLength(item);
          const index = item - 1;

          const currentOffset = pageSize * index;
          const query = { ...queryParams, [offsetKey]: currentOffset, access_token: token, expires: exp };

          const href = stringifyUrl({
            url: injectPathParams(getPath(tablePath as typeof View[keyof typeof View]), pathParams),
            query,
          });

          return (
            <StyledNavLink access={[]} to={href}>
              <NumberWrapper
                numberOfPages={pagesNum}
                editInProcess={editInProcess}
                key={index}
                className={
                  lengthNumber > 3 && currentPage === index ? 'bigNumber' : currentPage === index ? 'selected' : ''
                }
                isBigNumber={lengthNumber > 3}
              >
                <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
                  {item}
                </Text>
              </NumberWrapper>
            </StyledNavLink>
          );
        })}
        {ifEndDots && arrOfNum.length > 5 && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {ifEndNumber && arrOfNum.length > 5 && (
          <StyledNavLink access={[]} to={endNumberHref}>
            <NumberWrapper
              editInProcess={editInProcess}
              className={
                getLength(pagesNum) > 3 && currentPage === pagesNum - 1
                  ? 'bigNumber'
                  : currentPage === pagesNum - 1
                  ? 'selected'
                  : ''
              }
              isBigNumber={getLength(pagesNum) > 3}
            >
              <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
                {pagesNum}
              </Text>
            </NumberWrapper>
          </StyledNavLink>
        )}
      </NumberingWrapper>

      {endDelta > 0 && (
        <StyledArrowButton
          onClick={handleRightArrowClick}
          color={theme.colors.primary}
          colorHover={theme.colors.secondary3}
          type={ArrowButtonType.RIGHT}
        />
      )}
      <GoToPagination handleGoToPage={handleItemClick} pagesCount={pagesNum} languageStrings={languageStrings} />
    </Wrapper>
  );
};
