import React from 'react';

export const OutletIcon = ({ primaryColor, secondaryColor }: { primaryColor: string; secondaryColor: string }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8274 30.2036H11.8516C11.2993 30.2036 10.8516 30.6513 10.8516 31.2036V43.1552C10.8516 43.7075 11.2993 44.1552 11.8516 44.1552H17.8274"
        stroke={primaryColor}
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.0371 44.1552H52.0011C52.5534 44.1552 53.0011 43.7075 53.0011 43.1552V31.2036C53.0011 30.6513 52.5534 30.2036 52.0011 30.2036H46.0371"
        stroke={primaryColor}
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.0371 18.3695C46.0371 11.4407 40.6965 6.48828 31.8738 6.48828C23.0511 6.48828 17.8281 11.4878 17.8281 18.3695"
        stroke={secondaryColor}
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.0371 18.3696C46.0371 35.8385 46.0371 56.0013 46.0371 56.0013"
        stroke={secondaryColor}
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8281 56.0013V18.3696"
        stroke={secondaryColor}
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2285 56.001H49.5192"
        stroke={primaryColor}
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.5192 18.3696H14.2285"
        stroke={primaryColor}
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
