import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { TableCustomComponentState } from '../../../../../../../../../features/universalTable/types';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 12px;
`;

export const Problem = ({ value, state }: { value: any; state: TableCustomComponentState }) => {
  const theme = useTheme();
  const { gray1, orange2, red2 } = theme.colors;
  const { event_type, event_message } = value;

  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <Text
          type={TextType.TEXT_14_BLACK}
          color={event_type === 1 ? orange2 : event_type === 2 ? red2 : gray1}
          weight={'400'}
        >
          {value ? `${event_message}` : 'N/A'}
        </Text>
      ) : (
        <Wrapper>
          <Text
            type={TextType.TEXT_14_BLACK}
            color={event_type === 1 ? orange2 : event_type === 2 ? red2 : gray1}
            weight={'400'}
          >
            {value ? `${event_message}` : 'N/A'}
          </Text>
        </Wrapper>
      )}
    </>
  );
};
