import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import Hyphenated from 'react-hyphen';
// @ts-ignore
import en from 'hyphenated-en-gb';
import styled from '@cthings.co/styled-components';
import { InfoButton } from '../../../../../../../../../components/infoButton/InfoButton';
import { TableInput, TableInputType } from '../../../../../../../../../components/tableInput/TableInput';
import { media } from '@cthings.co/styles-utils';
import { useMediaType, useWindowSize } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { DeviceConfigurationRowType } from '../../DeviceConfigurationTable';

type WrapperProps = {
  isLastRow?: boolean;
  isEdit?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: max-content;
  min-height: 45px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 70px 3fr 4fr;
  gap: 40px;
  align-items: center;
  padding: 6px 22px;
  border-bottom: ${({ isLastRow }) => (!isLastRow ? '1px solid' : 'none')};
  border-color: ${colorFetch('gray4')};
  box-shadow: ${({ isEdit, theme }) => (isEdit ? theme.shadows.additionalShadow10 : 'none')};
  transition: all 0.3s linear;
  position: relative;
  ${media.phone} {
    grid-template-columns: 1fr 1fr;
    padding: 13px 16px;
  }
  &.header {
    height: 50px;
    border-bottom: 2px solid;
    border-color: ${colorFetch('gray4')};
    ${media.phone} {
      padding: 13px 16px;
    }
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  &.edit {
    width: 100%;
    flex-wrap: nowrap;
  }
`;

const EditWrapper = styled.div`
  display: flex;
`;

const RequestBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  margin-right: 22px;
  ${media.phone} {
    display: none;
  }
`;

const RequestText = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 0 10px;
  color: ${colorFetch('primary')};
`;

const EditButton = styled.button`
  display: none;
  ${media.phone} {
    display: flex;
    justify-self: end;
    width: max-content;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin: 0;
    position: absolute;
    top: 13px;
    right: 16px;
  }
`;

interface DeviceConfigurationRowProps {
  type: DeviceConfigurationRowType;
  isEdit?: boolean;
  setSelectedIndex?: React.Dispatch<React.SetStateAction<number>>;
  data?: any;
  inputType?: TableInputType;
  parameter?: string;
  value?: string | null;
  changeValue?: (data: any, handleRevert?: () => void) => void;
  infoTooltip?: string;
  isLastRow?: boolean;
  className?: string;
  isAccessDeviceConfiguration?: boolean;
  handleEditInHeaderOnClick?: () => void;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  index?: number;
  isFieldValid?: boolean;
  setDeviceName?: Function;
  globalSetSavedData?: () => void;
  description?: string;
  languageStrings: any;
}

export const DeviceConfigurationRow: FC<DeviceConfigurationRowProps> = ({
  type,
  isEdit,
  setSelectedIndex,
  data,
  inputType,
  changeValue,
  parameter,
  value,
  infoTooltip,
  isLastRow,
  isAccessDeviceConfiguration,
  handleEditInHeaderOnClick,
  accessData,
  index,
  isFieldValid,
  setDeviceName,
  globalSetSavedData,
  description,
  languageStrings,
  ...props
}) => {
  const { options, requested_value, requestStatus } = data || {};
  const theme = useTheme();
  const { black1, primary, secondary2 } = theme.colors;
  const [textareaValue, setTextareaValue] = useState<any>('');
  const [displayedValue, setDisplayedValue] = useState<any>('');
  const [savedString, setSavedString] = useState<any>('');
  const [validatingIsStarted, setValidatingIsStarted] = useState(false);
  const { phone } = useMediaType();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const windowWidth = useWindowSize()[0];
  const maxCountOfSymbolsInNameOfDevice =
    windowWidth > 768 ? (isEdit ? 58 : 105) : windowWidth < 769 && windowWidth > 425 ? 107 : 55;

  const handleChangeTextareaValue = (value: any, ind?: number) => {
    if (typeof ind === 'number') {
      const preparedArr = textareaValue.map((item: number, i: number) => (i === ind ? value : item));
      setTextareaValue(preparedArr);
    } else {
      setTextareaValue(value);
      index === 0 && setDeviceName && setDeviceName(value);
    }
  };
  const handleRevertValue = useCallback(() => {
    index === 0 && setDeviceName && setDeviceName(savedString);
    setTextareaValue(savedString);
    setValidatingIsStarted(false);
  }, [setTextareaValue, savedString]);

  const handleEditOnClick = () => {
    setSelectedIndex && typeof index === 'number' && setSelectedIndex(index);
    globalSetSavedData && globalSetSavedData();
    setSavedString(textareaValue);
  };

  const handleSaveOnClick = () => {
    if (isFieldValid && `${textareaValue}`.length > 0) {
      changeValue && changeValue(textareaValue, handleRevertValue);
      setSelectedIndex && setSelectedIndex(-1);
      setValidatingIsStarted(false);
    } else {
      setValidatingIsStarted(true);
    }
  };

  const handleCloseOnClick = () => {
    setSelectedIndex && setSelectedIndex(-1);
    handleRevertValue();
    setValidatingIsStarted(false);
  };

  useEffect(() => {
    value !== undefined && setTextareaValue(requested_value ? requested_value : value);
    if (isEdit && textareaRef && textareaRef.current) {
      textareaRef.current.focus();
      setSelectedIndex && typeof index === 'number' && setSelectedIndex(index);
    }
  }, [value, requested_value, isEdit, index]);

  useEffect(() => {
    if (textareaValue && typeof textareaValue === 'string' && textareaValue.length > maxCountOfSymbolsInNameOfDevice) {
      setDisplayedValue(`${textareaValue.slice(0, 56)}...`);
    } else {
      setDisplayedValue(textareaValue);
    }
  }, [textareaValue, maxCountOfSymbolsInNameOfDevice]);

  return (
    <>
      {type === DeviceConfigurationRowType.HEADER_ROW && (
        <Wrapper {...props} className={'header'}>
          {!phone && (
            <Text type={TextType.TEXT_14_BLACK} color={black1}>
              {languageStrings.symbolLabel}
            </Text>
          )}
          <Text type={TextType.TEXT_14_BLACK} color={black1}>
            {languageStrings.meaningLabel}
          </Text>
          <Text type={TextType.TEXT_14_BLACK} color={black1}>
            {languageStrings.insightDetailsDeviceConfigHeaderValue}
          </Text>
          {accessData?.edit && (
            <EditButton onClick={handleEditInHeaderOnClick ? handleEditInHeaderOnClick : () => {}}>
              <Text type={TextType.TEXT_14_BLACK} color={primary} hoverColor={secondary2}>
                {languageStrings.insightDetailsDeviceConfigHeaderEdit}
              </Text>
            </EditButton>
          )}
        </Wrapper>
      )}
      {type === DeviceConfigurationRowType.PRIMARY_ROW && (
        <Wrapper isLastRow={isLastRow} isEdit={isEdit} {...props}>
          {!phone && (
            <TextWrapper>
              <Hyphenated language={en}>
                <Text type={TextType.HELPER_TEXT_1} margin="0 2px 0 0">
                  {parameter}
                </Text>
              </Hyphenated>
            </TextWrapper>
          )}
          <TextWrapper>
            <Text type={TextType.HELPER_TEXT_1} margin="0 2px 0 0">
              {description ? description : 'N/A'}
            </Text>
            {infoTooltip && <InfoButton onClick={() => {}} tooltipText={infoTooltip || ''} />}
          </TextWrapper>
          <TextWrapper className={isEdit ? 'edit' : ''}>
            <TableInput
              type={inputType || TableInputType.TEXTFIELD}
              value={
                isEdit ? textareaValue : requestStatus === 1 ? requested_value : textareaValue ? displayedValue : 'N/A'
              }
              handleChangeValue={handleChangeTextareaValue}
              options={{
                textareaRef,
                isDisabled: !!isEdit,
                isError: validatingIsStarted && !isFieldValid,
                validatingIsStarted,
                textOfErrorMessage: languageStrings.tableInputErrorMessage,
                ...options,
              }}
              isEditInProcess={isEdit}
            />

            {/* <TableInput
              type={inputType || TableInputType.TEXTFIELD}
              value={description ? description : 'N/A'}
              handleChangeValue={() => {}}
              options={{
                textareaRef,
                isDisabled: !!isEdit,
                isError: validatingIsStarted && !isFieldValid,
                validatingIsStarted,
                textOfErrorMessage: languageStrings
                  ? languageStrings.tableInputErrorMessage
                  : `Please check value. It's not valid`,
                ...options,
              }}
              isEditInProcess={isEdit}
            /> */}
            {/* <EditWrapper>
              {requestStatus === 1 && !isEdit && (
                <RequestBlock>
                  <RequestText>
                    {languageStrings.insightDetailsDeviceConfigStatusChangesStagesRequested || 'Requested'}
                  </RequestText>
                  <InfoButton
                    onClick={() => {}}
                    color={theme.colors.gray3}
                    colorHover={theme.colors.primary}
                    tooltipText={`${languageStrings.insightDetailsStatusChangesStagesCurrent || 'Current: '} ${
                      inputType === TableInputType.TIME_PICKER_FIELDS_HHMM
                        ? value
                          ? `${value[1]} ${languageStrings ? languageStrings.hoursUnit : 'hours'}, ${value[2]} ${
                              languageStrings ? languageStrings.minsUnit : 'mins'
                            }`
                          : 'N/A'
                        : inputType === TableInputType.TIME_PICKER_FIELDS
                        ? `${
                            value
                              ? `${value[0]} ${languageStrings ? languageStrings.daysUnit : 'days'}, ${value[1]} ${
                                  languageStrings ? languageStrings.hoursUnit : 'hours'
                                }`
                              : 'N/A'
                          }`
                        : value
                    }`}
                  />
                </RequestBlock>
              )}
              {accessData?.edit && (
                <EditSection
                  onEditClick={handleEditOnClick}
                  saveOnClick={handleSaveOnClick}
                  closeOnClick={handleCloseOnClick}
                  isEdit={isEdit}
                  isAccessDeviceConfiguration={isAccessDeviceConfiguration}
                />
              )}
            </EditWrapper> */}
          </TextWrapper>
        </Wrapper>
      )}
    </>
  );
};

DeviceConfigurationRow.defaultProps = {};
