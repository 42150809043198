// @NOTE PLEASE DON'T EDIT THIS FILE DURING DEVELOPMENT

export const ENABLE_NID_BRANDING = (window as any)._env_
  ? (window as any)._env_.ENABLE_NID_BRANDING === 'true'
  : process.env.ENABLE_NID_BRANDING === 'true';

export const isMpwik = window.location.href.includes('mpwik.intelliventory.ai');
export const isNid = ENABLE_NID_BRANDING && window.location.href.includes('nid');

export const API_URL = (window as any)._env_
  ? (window as any)._env_.API_URL
  : process.env.API_URL || 'https://api.nid.intelliventory.ai';
export const AUTH_URL = (window as any)._env_
  ? (window as any)._env_.AUTH_URL
  : process.env.AUTH_URL || 'https://identity.nid.intelliventory.ai/auth';
export const SIGNUP_URL = (window as any)._env_
  ? (window as any)._env_.SIGNUP_URL
  : process.env.SIGNUP_URL || 'https://identity.nid.intelliventory.ai/signup';
export const IDENTITY_URL = (window as any)._env_
  ? (window as any)._env_.IDENTITY_URL
  : process.env.IDENTITY_URL || 'https://identity.nid.intelliventory.ai';
export const UNIQUE_PREFIX = (window as any)._env_
  ? (window as any)._env_.UNIQUE_PREFIX
  : process.env.UNIQUE_PREFIX || 'CthingsCo.1.';
export const MAPBOX_API = (window as any)._env_
  ? (window as any)._env_.MAPBOX_API
  : process.env.MAPBOX_API || 'https://api.mapbox.com/geocoding/v5';
export const MAPBOX_TOKEN = (window as any)._env_
  ? (window as any)._env_.MAPBOX_TOKEN
  : process.env.MAPBOX_TOKEN ||
    'pk.eyJ1IjoicHJhdGlrLWd1cnVkYXR0IiwiYSI6ImNrZjU3dTh6dzBrN3kyc25vZ3ZqMjd1eXAifQ.CQXH7MSvem6BjKNCcGDmvA';

const clientIdMapping = {
  isMpwik: isMpwik && 'mpwikwroc',
  isNid: isNid && 'nid',
  enableNidBranding: !ENABLE_NID_BRANDING && 'sup',
  envClientId: (window as any)._env_?.CLIENT_ID,
  processClientId: process.env.CLIENT_ID,
};

export const CLIENT_ID =
  clientIdMapping.isMpwik ||
  clientIdMapping.isNid ||
  clientIdMapping.enableNidBranding ||
  clientIdMapping.envClientId ||
  clientIdMapping.processClientId ||
  'cthings';

export const NEW_ID = (window as any)._env_ ? (window as any)._env_.NEW_ID : process.env.NEW_ID || 'NEW_ID';
export const MAPBOX_FLAG = (window as any)._env_ ? (window as any)._env_.MAPBOX_FLAG : process.env.MAPBOX_FLAG || true;
export const isSafari =
  /^((?!chrome|android).)*Safari/i.test(navigator.userAgent) &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const LOCAL_CLIENT_ID = 'sup';
