import React from 'react';
import { Value, Wrapper } from './styled';
import { useTheme } from '@cthings.co/styled-components';
import { InfoButton } from '../../../../../../components/infoButton/InfoButton';

enum VATStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
}

export const ValueView = ({
  value,
  vat_status,
  tooltipText,
}: {
  value: string;
  vat_status?: VATStatus;
  tooltipText?: string;
}) => {
  const theme = useTheme();

  const { red2, orange2, gray1 } = theme.colors;

  const setVatColor = (type: VATStatus) => {
    const textColor = {
      [VATStatus.VALID]: gray1,
      [VATStatus.INVALID]: red2,
      [VATStatus.PENDING]: orange2,
    };
    return textColor[type];
  };

  return (
    <Wrapper>
      <Value vatColor={vat_status ? setVatColor(vat_status) : gray1} theme={theme}>
        {value}
      </Value>
      {tooltipText && <InfoButton onClick={() => {}} tooltipText={tooltipText} isInfoIcon tooltipWidth={'350px'} />}
    </Wrapper>
  );
};
