import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as Pen } from '../../../../../../assets/Pen.svg';
import { media } from '@cthings.co/styles-utils';
import { useUserRole } from '../../../../../../utils/userRole';
import { UserRoleId } from '../../../../../../types/userRole';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type ContainerProps = {
  textWidth: number;
  isDisabled: boolean;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${({ textWidth }) => `1.5fr 2fr ${textWidth}px 20px`};
  /* grid-template-columns: 1fr 1.5fr 50px 20px; */
  align-items: center;
  padding: 0 22px;
  position: relative;
  background-color: ${({ isDisabled, theme }) => (isDisabled ? colorFetch('gray5')({ theme }) : '')};
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 95%;
    height: 1px;
    background-color: ${colorFetch('gray3')};
    left: 13px;
    ${media.phone} {
      left: 8px;
    }
  }

  ${media.semiTablet} {
    grid-template-columns: 1.5fr 1fr 20px;
  }
`;

const UserName = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 0;
  margin: 0;
  word-break: break-word;
`;

const UserEmail = styled(UserName)`
  ${media.semiTablet} {
    display: none;
  }
`;

const UserRole = styled(UserName)``;

type PenIconProps = {
  isDisabled: boolean;
};

const PenIcon = styled(Pen)<PenIconProps>`
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'flex')};
  cursor: pointer;
  padding: 10px;
` as any; // @TODO fix type

export interface ItemComponentProps {
  data: { id: string; name: string; email: string; role: UserRoleId };
  isDisabled: boolean;
  handleEditRow: (id: string) => void;
  textWidth: number;
}

export const ItemRow: FC<ItemComponentProps> = ({ data, handleEditRow, isDisabled, textWidth }) => {
  const { email, name, role } = data;

  const [, { getUserByRole }] = useUserRole();

  return (
    <Container isDisabled={isDisabled} textWidth={textWidth}>
      <UserName>{name}</UserName>
      <UserEmail>{email}</UserEmail>
      <UserRole>{getUserByRole(role).name}</UserRole>
      <PenIcon isDisabled={isDisabled} onClick={handleEditRow} />
    </Container>
  );
};
