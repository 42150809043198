import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { Calendar, CalendarType } from '@bit/first-scope.calendar';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { media } from '@cthings.co/styles-utils';
import { DateButton, DateButtonType } from '../dateButton/DateButton';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 18px 0;
`;

const SemiWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

type PeriodItemProps = {
  isSelected: boolean;
};

const PeriodItem = styled.div<PeriodItemProps>`
  width: 112px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? colorFetch('primary')({ theme }) : colorFetch('gray3')({ theme })};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    border-color: ${colorFetch('primary')};
    & > span {
      color: ${colorFetch('primary')};
    }
  }
  ${media.semiTablet} {
    width: calc(25% - 8px);
  }
`;

const PeriodText = styled(Text)`
  line-height: 16px;
  text-align: center;
  ${media.tablet} {
    font-size: 12px;
  }
`;

const ChoosePeriodWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const StyledCalendar = styled(Calendar)`
  width: 234px;
  & > div > div > input {
    height: 32px;
    padding: 6px 30px 6px 56px;
    font-size: 12px;
    box-shadow: none;
  }
  &.date_to > div > div > input {
    padding: 6px 42px;
  }
  &.from > div > div.react-datepicker__input-container > div {
    padding: 8px 36px 8px 53px;
  }
  .react-datepicker__input-container {
    & > div {
      box-shadow: none;
      & > span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  ${media.semiTablet} {
    display: none;
  }
` as any; // @TODO fix type

export const ChoosePeriod = ({
  languageStrings,
  handleSetDates,
  selectedIndex,
  setSelectedIndex,
  setIsScrollableModal,
}: {
  languageStrings: any;
  handleSetDates: (startDate: string, endDate: string) => void;
  selectedIndex: number;
  setSelectedIndex: any;
  setIsScrollableModal: any;
}) => {
  const getFormattedDate = (date: any, hr: number, min: number, sec: number, ms: number) => {
    const d = new Date(date);
    d.setHours(hr, min, sec, ms);
    return d;
  };
  const [startDate, setStartDate] = useState(getFormattedDate(new Date(), 0, 0, 0, 0));
  const [endDate, setEndDate] = useState(getFormattedDate(new Date(), 23, 59, 59, 999));
  const [previousDate, setPreviousDate] = useState(getFormattedDate(new Date(), 0, 0, 0, 0));
  const today = new Date();
  const theme = useTheme();
  const { primary, gray2 } = theme.colors;
  const refreshDate = () => {
    setStartDate(getFormattedDate(new Date(), 0, 0, 0, 0));
    setEndDate(getFormattedDate(new Date(), 23, 59, 59, 999));
  };

  const periodsArr = [
    {
      value: languageStrings.generateReportModalDay,
      onClick: (index: number) => {
        setSelectedIndex(index);
        const d = new Date();
        const d2 = new Date();
        setStartDate(getFormattedDate(d.setDate(d.getDate() - 1), 0, 0, 0, 0));
        setEndDate(getFormattedDate(d2.setDate(d2.getDate() - 1), 23, 59, 59, 999));
      },
    },
    {
      value: languageStrings.generateReportModalWeek,
      onClick: (index: number) => {
        setSelectedIndex(index);
        const d = new Date();
        const d2 = new Date();
        setStartDate(getFormattedDate(d.setDate(d.getDate() - 7), 0, 0, 0, 0));
        setEndDate(getFormattedDate(d2.setDate(d2.getDate() - 1), 23, 59, 59, 999));
      },
    },
    {
      value: languageStrings.generateReportModalMonth,
      onClick: (index: number) => {
        setSelectedIndex(index);
        const d = new Date();
        const d2 = new Date();
        setStartDate(getFormattedDate(d.setMonth(d.getMonth() - 1), 0, 0, 0, 0));
        setEndDate(getFormattedDate(d2.setDate(d2.getDate() - 1), 23, 59, 59, 999));
      },
    },
    {
      value: languageStrings.generateReportModalPeriod,
      onClick: (index: number) => {
        setSelectedIndex(index);
        setIsScrollableModal(false);
        refreshDate();
      },
    },
  ];

  useEffect(() => {
    handleSetDates(
      getRequiredDateFormat(startDate, 'YYYY-MM-DDTHH:mm:ssZ'),
      getRequiredDateFormat(endDate, 'YYYY-MM-DDTHH:mm:ssZ'),
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedIndex === 3 && endDate < startDate) {
      setStartDate(getFormattedDate(endDate, 0, 0, 0, 0));
    }
  }, [endDate]);

  return (
    <Wrapper>
      <SemiWrapper>
        {periodsArr.map((item: any, index: number) => {
          const { value, onClick } = item;
          return (
            <PeriodItem
              onClick={() => {
                if (index === selectedIndex) {
                  return null;
                } else {
                  onClick(index);
                }
              }}
              key={index}
              theme={theme}
              isSelected={index === selectedIndex}
            >
              <PeriodText type={TextType.TEXT_14_GRAY} color={selectedIndex === index ? primary : gray2}>
                {value}
              </PeriodText>
            </PeriodItem>
          );
        })}
      </SemiWrapper>
      {selectedIndex === 3 && (
        <ChoosePeriodWrapper>
          <StyledCalendar
            className={'from'}
            type={CalendarType.DATE_SELECTION}
            iconComponent={
              <Text type={TextType.TEXT_12_GRAY}>{languageStrings.generateReportModalPeriodBorderFrom}</Text>
            }
            dateFormat={'DD MMM yy'}
            startDate={startDate}
            setStartDate={setStartDate}
            maxDate={today}
            onClick={() => {
              setIsScrollableModal(false);
            }}
          />
          <StyledCalendar
            className={'date_to'}
            type={CalendarType.DATE_SELECTION}
            iconComponent={
              <Text type={TextType.TEXT_12_GRAY}>{languageStrings.generateReportModalPeriodBorderTo} </Text>
            }
            dateFormat={'DD MMM yy'}
            startDate={endDate}
            setStartDate={setEndDate}
            maxDate={today}
            onClick={() => {
              setIsScrollableModal(false);
            }}
          />
          <DateButton
            type={DateButtonType.FROM}
            date={startDate}
            setDate={setStartDate}
            languageStrings={languageStrings}
            maxDate={today}
            previousDate={previousDate}
            setPreviousDate={setPreviousDate}
          />
          <DateButton
            type={DateButtonType.TO}
            date={endDate}
            setDate={setEndDate}
            languageStrings={languageStrings}
            maxDate={today}
            previousDate={previousDate}
            setPreviousDate={setPreviousDate}
          />
        </ChoosePeriodWrapper>
      )}
    </Wrapper>
  );
};
