import React from 'react';
import styled from '@cthings.co/styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CommonButton } from '@cthings.co/buttons';
import { AddressPopupDescription } from '../../../addressPopupDescription/AddressPopupDescription';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  z-index: var(--modal-first-layer);
`;

const StyledModalContainer = styled(ModalContainer)`
  padding: 24px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled(CommonButton)`
  margin: 22px 0 0 16px;
`;

export const InstructionsModal = ({
  isOpenModal,
  closeModal,
  languageStrings,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  languageStrings?: any;
}) => {
  const theme = useTheme();

  const { pureWhite, black1 } = theme.colors;

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <AddressPopupDescription languageStrings={languageStrings}>
          <ButtonWrapper>
            <CloseButton
              width="104px"
              colors={{
                main: black1,
                background: pureWhite,
              }}
              onClick={closeModal}
            >
              {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
            </CloseButton>
          </ButtonWrapper>
        </AddressPopupDescription>
      </StyledModalContainer>
    </StyledModal>
  );
};
