import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './data';
import { reducers } from './reducers';

export const generateReportModalSlice = createSlice({
  name: 'generateReportModal',
  initialState,
  reducers: reducers,
});

export const {
  setParameters,
  clearLocation,
  clearCustomer,
  clearRecipients,
  clearPeriod,
  toggleIsSelectedParameter,
  toggleIsSelectedAllParameter,
  toggleIsOpenPopup,
  setDates,
  toggleIsOpenMobileDateModal,
  toggleIsError,
  toggleIsValidatingStarted,
  toggleIsUploadingInProcess,
} = generateReportModalSlice.actions;

export default generateReportModalSlice.reducer;
