/**
 * Universal line graph with differrent types @LineGraphType
 */

import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { AreaChart, XAxis, YAxis, Tooltip, Label } from 'recharts';
import { useStyleContext } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../utils/date/date-format';
import { GraphTitle } from './components/graphTitle/GraphTitle';
import { CustomYAxisTick } from './components/customYAxisTick';
import { setLineArea } from './components/lineArea/LineArea';
import { setMidValueLine } from './components/midValueLine/MidValueLine';
import { CustomCalibrationTooltip } from './components/customCalibrationTooltip/CustomCalibrationTooltip';
import { useTheme } from '@cthings.co/styled-components';
import { CustomReferenceArea } from './components/referenceAreaForCalibration/ReferenceAreaForCalibration';
import { GraphType } from '../graph/types';

import { useTooltip } from '../../components/tooltip';
import { threshlodLinesMapping } from './components/threshlodMapping';
import { ThreshlodIconTooltipMapping } from './components/threshlodMapping/threshlodIconTooltipMapping';
import { ThresholdColor, ThresholdData } from './components/threshlodMapping/types';

// @NOTE An example of thresholdData
// const thresholdData = [
//   { threshold: 25, color: ThresholdColor.ORANGE, tooltipText: 'Critical state' },
//   { threshold: 21.55, color: ThresholdColor.RED, tooltipText: 'Warning state' },
//   { threshold: 18.102487, color: ThresholdColor.RED, tooltipText: 'Warning state' },
//   { threshold: 15.440401, color: ThresholdColor.ORANGE, tooltipText: 'Warning state' },
// ];

export enum LineGraphType {
  SHARP = 'SHARP',
  ROUNDED = 'ROUNDED',
}

type WrapperProps = {
  height: number;
  margin?: string;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: ${({ height }) => `${height}px`};
  /* box-shadow: ${({ theme }) => theme.shadows.additionalShadow11}; */
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  margin: ${({ margin }) => margin};
  box-sizing: border-box;
  padding: 0 20px;
`;

const setTickStyle = ({ fill }: { fill: string }) => ({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.2px',
  fill: fill,
});

const CustomLabel = ({
  value,
  theme,
  x,
  y,
  offset,
}: {
  value: any;
  theme: any;
  x: number;
  y: number;
  offset: number;
}) => {
  const { children } = value;
  return (
    <text
      x={x}
      y={y}
      offset={offset}
      textAnchor={'start'}
      fontFamily={'Poppins, sans-serif'}
      fontSize={'12px'}
      letterSpacing={'0.2'}
      fill={theme.colors.gray2}
    >
      <tspan x={x}>{children}</tspan>
    </text>
  );
};

export type CartessianViewBox = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};

export type DataType = {
  x: string;
  y: number;
  average?: number;
  interval?: number[];
  tooltipTitle?: string;
  tooltipValue?: string;
};

type LineGraphProps = {
  type?: LineGraphType;
  graphType?: GraphType;
  data: DataType[];
  calibrationData?: DataType[];
  thresholdData: ThresholdData[];
  height?: number;
  labels?: { xAxis: string; yAxis: string };
  unit: string;
  title?: string | ReactNode;
  statisticTitle?: string | ReactNode;
  statisticValue?: number | string;
  margin?: string;
  midValue?: number;
  minLimitX?: number;
  maxLimitX?: number;
  maxLimitY?: number;
  minThreshold?: number;
  maxThreshold?: number;
  isSelects?: boolean;
  color?: string;
  invertAxis?: boolean;
  limits?: any;
  id?: string;
};

export const LineGraph: React.FC<LineGraphProps> = ({
  type = LineGraphType.SHARP,
  graphType,
  data,
  calibrationData,
  thresholdData,
  height = 350,
  title,
  labels,
  unit,
  statisticTitle,
  statisticValue,
  margin,
  midValue,
  minLimitX,
  maxLimitX,
  maxLimitY,
  minThreshold,
  maxThreshold,
  isSelects,
  color,
  invertAxis,
  limits,
  id,
}) => {
  const theme = useTheme();
  const { gray2, blue3 } = theme.colors;

  const [{ windowWidth }] = useStyleContext();
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [areaSize, setAreaSize] = useState({ height: 0, width: 0 });

  const isRoundedType = type === LineGraphType.ROUNDED;

  const graphContainerHeight = height - (title || statisticTitle ? 90 : 14);
  const graphWidth = wrapperWidth - 5;
  const areaWidth = graphWidth - 40;
  const lineWidth = areaWidth - 40;

  const wrapperRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        setWrapperWidth(node.getBoundingClientRect().width);
      }
    },
    [windowWidth],
  );

  const areaRef = useCallback(
    (node: any) => {
      if (node !== null) {
        const { height, width } = node.props;
        setAreaSize({ height, width });
      }
    },
    [windowWidth],
  );

  const midLineOffsetY = useMemo(() => {
    let offsetY = 0;
    if (maxLimitY && midValue) {
      const averagePercentValue = midValue / (maxLimitY / 100);
      // 20px is margin top of area and offsetY is beginning from area height + margin top
      offsetY = areaSize.height + 20 - (areaSize.height / 100) * averagePercentValue;
    }
    return offsetY;
  }, [areaSize.height, midValue, maxLimitY]);

  const preparedData = data.map((item) => ({
    ...item,
    y: !!invertAxis ? limits.y.min - item.y : +item.y,
    x: new Date(item.x).getTime(),
  }));

  const preparedCalibrationData = calibrationData?.map((item: any) => ({
    x1: item.start_ts,
    x2: item.end_ts === null ? null : item.end_ts,
  }));

  const hasThresholdData = !!thresholdData?.length;

  const [tooltipIds] = useTooltip(thresholdData?.length);
  return (
    <Wrapper ref={wrapperRef} height={height} margin={margin} theme={theme}>
      {(title || statisticTitle) && (
        <GraphTitle
          theme={theme}
          title={title}
          parameterName={statisticTitle}
          parameterValue={statisticValue}
          isSelects={isSelects}
        />
      )}
      <AreaChart
        height={graphContainerHeight}
        width={areaWidth}
        data={preparedData}
        margin={{ top: 60, right: 20, bottom: 0, left: -10 }}
      >
        <YAxis
          axisLine={{ stroke: theme.colors.gray4 }}
          tickLine={false}
          domain={!!invertAxis ? [limits.y.max, limits.y.min] : [limits.y.min, limits.y.max]}
          tick={(value) => <CustomYAxisTick value={value} lineWidth={lineWidth} theme={theme} />}
        >
          <Label
            position="top"
            offset={30}
            content={(value) => CustomLabel({ value, theme, x: 20, y: 28, offset: 30 })}
          >
            {labels?.yAxis}
          </Label>
        </YAxis>
        {hasThresholdData && threshlodLinesMapping({ thresholdData, theme })}
        <XAxis
          type="number"
          angle={-45}
          tickMargin={36}
          height={30}
          minTickGap={24}
          dataKey={'x'}
          axisLine={false}
          tickLine={false}
          domain={[new Date(limits?.x?.min).getTime(), new Date(limits?.x?.max).getTime()]}
          tick={setTickStyle({ fill: gray2 })}
          tickFormatter={(value) => getRequiredDateFormat(value, 'DD.MM.YYYY HH:mm')}
        >
          <Label
            position="right"
            offset={30}
            content={(value) => CustomLabel({ value, theme, x: 50, y: 330, offset: 0 })}
          >
            {labels?.xAxis}
          </Label>
        </XAxis>

        {setLineArea(areaRef, isRoundedType, theme, color || blue3)}

        {midValue && setMidValueLine(midLineOffsetY, lineWidth, theme)}

        <Tooltip
          cursor={false}
          animationDuration={300}
          content={(tooltipData) => (
            <CustomCalibrationTooltip
              tooltipData={tooltipData}
              data={preparedData}
              unit={unit}
              calibrationData={preparedCalibrationData}
              theme={theme}
              id={id}
              limits={limits}
            />
          )}
        />
        {graphType === GraphType.WATER_LEVEL &&
          CustomReferenceArea({
            theme,
            data: preparedData,
            calibrationData: preparedCalibrationData,
            widthOfGraph: graphWidth,
            // setTooltipId: setTooltipId,
            // tooltipId: tooltipId,
          })}
      </AreaChart>
      {hasThresholdData && (
        <ThreshlodIconTooltipMapping
          thresholdData={thresholdData}
          areaSize={areaSize}
          limits={limits}
          tooltipIds={tooltipIds}
        />
      )}
    </Wrapper>
  );
};
