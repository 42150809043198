import { CommonButton } from '@cthings.co/buttons';
import { Text, TextType } from '@bit/first-scope.text';
import styled from '@cthings.co/styled-components';
import React, { FC, useCallback } from 'react';
import { DeviceType } from '../../enums/DeviceType';
import { CoverImage } from './components/CoverImage';
import { LocationImage } from './components/LocationImage';
import { TemperatureImage } from './components/TemperatureImage';
import { WaterLevelImage } from './components/WaterLevelImage';
import { OutletImage } from './components/OutletImage';
import { media } from '@cthings.co/styles-utils';
import { FlowInfo } from './components/flowInfo/FlowInfo';
import { DistanceToWaterImage } from './components/DistanceToWaterImage';
import Tooltip, { TooltipPlace, useTooltip } from '../../components/tooltip';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type WrapperProps = {
  isOpen: boolean;
  isClicked: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  min-width: 240px;
  /* height: 158px; */
  box-sizing: border-box;
  border-radius: ${colorFetch('primary')};
  background-color: ${colorFetch('pureWhite')};
  visibility: ${({ isOpen, isClicked }) => (isOpen || isClicked ? 'visible' : 'hidden')};
  opacity: ${({ isOpen, isClicked }) => (isOpen || isClicked ? '1' : '0')};
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  transition: all 0.3s ease;
  position: absolute;
  top: 60px;
  left: -22px;
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow7};
  &:before {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -8px;
    left: 40px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
    border-radius: 0 0 0 0.25em;
    ${media.phone} {
      left: 108px;
    }
  }
`;
const NameWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 8px 18px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray4')};
  & > span {
    font-weight: 600;
  }
`;

const SemiWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 8px 20px 15px 20px;
`;

type TextWrapperProps = {
  margin?: string;
  color?: string;
};

const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  & > svg {
    margin-right: 2px;
    & > path {
      stroke: ${({ color }) => color};
    }
  }
`;

const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 6px;
  & > span {
    margin: 0 0 0 22px;
  }
`;

type WrapperLevelDataProps = {
  margin: string;
};

const WrapperLevelData = styled.div<WrapperLevelDataProps>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  & > svg {
    margin-right: 3px;
    & > path {
      stroke: ${colorFetch('gray1')};
    }
  }
  & > span {
    color: ${colorFetch('black1')};
  }
`;

const HydrantInfoWrapper = styled.div`
  display: flex;
`;

type WrapperCoverProps = {
  color: string;
};

const WrapperCover = styled.div<WrapperCoverProps>`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 4px;
    & > circle {
      stroke: ${({ color }) => color};
    }
  }
  & > span {
    color: ${({ color }) => color};
  }
`;

type WrapperOutletProps = {
  color: string;
};

const WrapperOutlet = styled.div<WrapperOutletProps>`
  display: flex;
  align-items: center;
  & > svg {
    margin: 0 4px 0 8px;
    & > path {
      stroke: ${({ color }) => color};
    }
  }
  & > span {
    color: ${({ color }) => color};
  }
`;

type WrapperWaterLevelProps = {
  isHydrant: boolean;
  color: string;
};

const WrapperWaterLevel = styled.div<WrapperWaterLevelProps>`
  display: flex;
  align-items: center;
  margin-right: 8px;
  & > svg {
    margin: ${({ isHydrant }) => (isHydrant ? '0 4px 0 8px' : '0 4px 0 2px')};
    & > path {
      stroke: ${({ color }) => color};
    }
  }
  & > span {
    color: ${({ color }) => color};
  }
  &.hydrant {
    margin: 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 350px !important;
  width: max-content !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px;
  padding: 8px;
`;

interface LevelType {
  measurement: string;
  tooltipText: string;
}

export interface ManholeInfoProps {
  isClicked: boolean;
  isOpen: boolean;
  name: string;
  address: string;
  coordinates: string;
  temperature: LevelType;
  distanceToWater: LevelType;
  deviceType?: string;
  waterLevel: LevelType;
  flowInfoData: any[];
  waterLevelHydrant: string;
  cover: boolean;
  outlet: boolean;
  date: string;
  time: string;
  history: any;
  className?: string;
  bottom?: string;
  to?: any;
  languageStrings?: any;
  deployment_date: string | null;
  deployment_status: number | null;
}

export const ManholeInfo: FC<ManholeInfoProps> = ({
  isClicked,
  isOpen,
  name,
  history,
  address,
  coordinates,
  temperature,
  deviceType,
  distanceToWater,
  waterLevel,
  flowInfoData,
  waterLevelHydrant,
  cover,
  outlet,
  date,
  time,
  bottom,
  to,
  languageStrings,
  deployment_date,
  deployment_status,
  ...props
}) => {
  const theme = useTheme();
  const { black1, gray2, red2 } = theme.colors;
  const [tooltipId] = useTooltip(3);
  const handleClick = useCallback(() => {
    to && history && history.push(to);
  }, [to, history]);

  const deviceData = [
    {
      icon: <WaterLevelImage />,
      value: waterLevel?.measurement || 'N/A',
      tooltipText: waterLevel?.tooltipText,
      margin: '0 6px 0 0',
    },
    {
      icon: <DistanceToWaterImage />,
      value: distanceToWater?.measurement || 'N/A',
      tooltipText: distanceToWater?.tooltipText,
      margin: '0 6px 0 0',
    },
    {
      icon: <TemperatureImage />,
      value: temperature?.measurement || 'N/A',
      tooltipText: temperature?.tooltipText,
    },
  ];

  return (
    <Wrapper
      theme={theme}
      isOpen={isOpen}
      isClicked={isClicked}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      {...props}
    >
      <NameWrapper>
        <Text type={TextType.TEXT_14_BLACK} color={black1}>
          {name || 'N/A'}
        </Text>
      </NameWrapper>
      <SemiWrapper>
        <WrapperAddress>
          <TextWrapper color={black1}>
            <LocationImage />
            <Text type={TextType.TEXT_14_BLACK} color={black1}>
              {address || 'N/A'}
            </Text>
          </TextWrapper>
          <Text type={TextType.TEXT_12_GRAY} color={gray2}>
            {coordinates || 'N/A'}
          </Text>
        </WrapperAddress>
        {deployment_date
          ? deviceType !== DeviceType.HYDRANT && (
              <div style={{ display: 'flex' }}>
                {deviceData.map((item: any, index: number) => {
                  return (
                    <StyledTooltip id={tooltipId[index]} text={item.tooltipText} place={TooltipPlace.TOP}>
                      <WrapperLevelData margin={item.margin ?? '0'}>
                        {item.icon}
                        <Text type={TextType.TEXT_12_BLACK} color={black1}>
                          {item.value}
                        </Text>
                      </WrapperLevelData>
                    </StyledTooltip>
                  );
                })}
              </div>
            )
          : null}
        {deviceType === DeviceType.HYDRANT && (
          <HydrantInfoWrapper>
            <WrapperCover color={cover && waterLevelHydrant && outlet ? black1 : red2}>
              <CoverImage />
              <Text type={TextType.TEXT_14_RED} color={black1}>
                {cover ? languageStrings.closedText : languageStrings.openText}
              </Text>
            </WrapperCover>
            <WrapperWaterLevel
              className={'hydrant'}
              color={cover && waterLevelHydrant && outlet ? black1 : red2}
              isHydrant
            >
              <WaterLevelImage />
              <Text type={TextType.TEXT_14_BLACK}>{waterLevelHydrant ? 'Ok' : languageStrings.hightText}</Text>
            </WrapperWaterLevel>
            <WrapperOutlet color={cover && waterLevelHydrant && outlet ? black1 : red2}>
              <OutletImage />
              <Text type={TextType.TEXT_14_BLACK}>{outlet ? 'Ok' : 'Pres.'}</Text>
            </WrapperOutlet>
          </HydrantInfoWrapper>
        )}
        <DownWrapper>
          <DateWrapper>
            {deployment_status === 2 ? (
              <>
                <TextWrapper margin="0 6px 0 6px">
                  <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                    {date || 'N/A'}
                  </Text>
                </TextWrapper>

                <TextWrapper>
                  <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                    {time || 'N/A'}
                  </Text>
                </TextWrapper>
              </>
            ) : (
              <Text type={TextType.TEXT_12_GRAY} color={gray2}>
                {deployment_status === 1
                  ? languageStrings.deploying
                  : deployment_status === 0
                  ? languageStrings.notDeployed
                  : ''}
              </Text>
            )}
          </DateWrapper>
          <CommonButton onClick={handleClick} height="25px" width="100%" noFocus>
            {languageStrings.commonButtonMore}
          </CommonButton>
        </DownWrapper>
      </SemiWrapper>
      {flowInfoData && (
        <FlowInfo
          flowData={flowInfoData}
          withFlowSeparator
          history={history}
          withFlow
          type={flowInfoData[0]?.set_type}
          languageStrings={languageStrings}
        />
      )}
    </Wrapper>
  );
};

ManholeInfo.defaultProps = {
  isOpen: true,
  address: 'Brest, Belarus',
  coordinates: '5551.4444 7895.5854',
  temperature: { measurement: '54', tooltipText: 'tooltip' },
  waterLevel: { measurement: '5', tooltipText: 'tooltip' },
  distanceToWater: { measurement: '4', tooltipText: 'tooltip' },
  date: '21.01.2020',
  time: '9:54',
};
