import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';

const StyledText = styled(Text)`
  ${media.tablet} {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const EventView = ({ value }: { value: string }) => {
  const theme = useTheme();

  const { gray1 } = theme.colors;

  return (
    <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
      {value}
    </StyledText>
  );
};
