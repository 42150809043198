import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { Text } from '@bit/first-scope.text';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export const ExtraWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${media.tablet} {
    padding: 0 16px 32px;
  }
  ${media.phone} {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &.placeholder {
    height: calc(100% - 121px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  ${media.desktop} {
    max-width: 1024px;
  }
  margin-top: 32px;
  ${media.tablet} {
    margin-top: 16px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.phone} {
    justify-content: center;
  }
`;

export const StyledTitle = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;

export const StyledLink = styled.a`
  text-decoration: underline ${colorFetch('primary')} 1px;
`;
