import React, { FC, useState, useEffect } from 'react';
import Slider, { Handle } from 'rc-slider';
import AutosizeInput from 'react-input-autosize';
import 'rc-slider/assets/index.css';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { TableInputType } from '../../../types';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { InputWrapper } from '../../common/inputWrapper/InputWrapper';
import { RequestBlock } from '../../requestedBlock/RequestedBlock';

const Wrapper = styled.div`
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledInputWrapper = styled(InputWrapper)`
  flex-direction: row;
  align-items: center;
  ${media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  ${media.phone} {
    height: 40px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 100%;
  background-color: ${colorFetch('gray6')};
  border-right: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 8px 0 0 8px;
  ${media.phone} {
    width: 100%;
    border-color: transparent;
    border-radius: 8px;
  }
`;

type StyledInputProps = {
  disabled: boolean;
};

const StyledInput = styled(AutosizeInput)<StyledInputProps>`
  display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')} !important;
  background-color: ${colorFetch('gray6')};
  border-radius: 8px 0 0 8px;
  & > input {
    outline: transparent;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${colorFetch('gray1')};
  }
`;

// @TODO fix any
const StyledSlider = styled(Slider as any)`
  width: calc(100% - 71px);
  margin: 0 14px;
  background: none;
  ${media.phone} {
    display: none;
  }
`;

type StyledHandleProps = {
  withInput?: boolean;
  pureWhite: string;
  primary: string;
  boxShadow: string;
};

const StyledHandle = styled(Handle as any)<StyledHandleProps>`
  width: 14px;
  height: 14px;
  background-color: ${colorFetch('pureWhite')};
  border: 2px solid;
  border-color: ${colorFetch('primary')};
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  transform: translate(-50%, -5px) !important;
  box-shadow: ${({ boxShadow }) => boxShadow} !important;
  border-radius: 50%;
  &:hover {
    border-color: ${colorFetch('primary')};
  }
  &:focus,
  &:active {
    border-color: ${colorFetch('primary')};
    box-shadow: ${({ boxShadow }) => boxShadow};
  }
`;

const TextUnit = styled.span`
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

type TextWrapperType = {
  highlightValue: string | any;
};

const TextWrapper = styled.span<TextWrapperType>`
  word-break: break-all;
  width: 100%;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ highlightValue }) => highlightValue || colorFetch('gray1')};
`;

interface SliderFreeInputWithUnitProps {
  value: {
    type: TableInputType;
    actual_value: { value: number | string; unit: string; limit: { min: number; max: number }; step: number } | null;
    requested_value: { value: number | string; unit: string; limit: { min: number; max: number }; step: number } | null;
    with_requested: boolean;
    is_editable: boolean;
  };
  state: any;
  setField: any;
  isEditInProcess: boolean | undefined;
  highlightValue?: string | any;
}

export const SliderFreeInputWithUnit: FC<SliderFreeInputWithUnitProps> = ({
  value,
  state,
  setField,
  isEditInProcess = false,
  highlightValue,
  ...props
}) => {
  const theme = useTheme();
  const { primary, secondary3 } = theme.colors;
  const { additionalShadow6 } = theme.shadows;

  const { actual_value, with_requested, requested_value }: any = value || {};
  const { value: inputValue, unit, step, limit }: any = actual_value || {};

  const regexForInput = /^\d{0,3}?[.]?\d{0,2}?$/gm;

  const onInputChange = (e: any) => {
    regexForInput.test(e.target.value) &&
      setField({ ...value, actual_value: { ...actual_value, value: e.target.value } });
  };

  const onSliderChange = (sliderValue: any) => {
    setField({ ...value, actual_value: { ...actual_value, value: sliderValue } });
  };

  const onInputBlur = () => {
    +inputValue <= limit.min
      ? setField({ ...value, actual_value: { ...actual_value, value: limit.min } })
      : +inputValue >= limit.max
      ? setField({ ...value, actual_value: { ...actual_value, value: limit.max } })
      : isNaN(+inputValue)
      ? setField({ ...value, actual_value: { ...actual_value, value: limit.min } })
      : setField({ ...value, actual_value: { ...actual_value, value: +inputValue } });
  };

  return (
    <Wrapper>
      {isEditInProcess ? (
        <StyledInputWrapper isEditInProcess={isEditInProcess} {...props}>
          <Container>
            <Box>
              <StyledInput value={inputValue} onChange={onInputChange} onBlur={onInputBlur} disabled={false} />
              <TextUnit>{unit}</TextUnit>
            </Box>
            <StyledSlider
              value={inputValue}
              min={limit.min}
              max={limit.max}
              step={step}
              onChange={onSliderChange}
              trackStyle={{ backgroundColor: primary }}
              railStyle={{ backgroundColor: secondary3 }}
              handle={(props: any) => <StyledHandle boxShadow={additionalShadow6} {...props} />}
            />
          </Container>
        </StyledInputWrapper>
      ) : (
        <TextWrapper highlightValue={highlightValue}>{`${inputValue} ${unit}`}</TextWrapper>
      )}
      {!isEditInProcess && with_requested && (
        <RequestBlock requested_value={`${requested_value.value} ${requested_value.unit}`} />
      )}
    </Wrapper>
  );
};
