import { Calendar, CalendarType } from '@bit/first-scope.calendar';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import moment from 'moment';
import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useTableContext, useTableFunctions } from '../../../../../../../../../../features/universalTable/context';
import { TableCustomComponentState } from '../../../../../../../../../../features/universalTable/types';
import { media } from '@cthings.co/styles-utils';
import { useWindowSize } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../../../../../../../../../utils/date/date-format';
import { ChooseDateMobileModal } from '../../../../../../../../reports/components/chooseDateMobileModal/ChooseDateMobileModal';

type StyledCalendarProps = {
  withBord: boolean;
};

const StyledCalendar = styled(Calendar)<StyledCalendarProps>`
  width: 234px !important;
  & > div > div > input {
    height: 32px;
    padding: 6px 30px 6px 12px !important;
    font-size: 12px;
    box-shadow: none;
  }
  .react-datepicker__input-container {
    & > div {
      box-shadow: none;
      padding: 6px 30px 6px 12px !important;
      border-color: ${({ withBord, theme }) =>
        withBord ? colorFetch('gray3')({ theme }) : colorFetch('gray5')({ theme })};
      & > span {
        font-size: 14px !important;
        line-height: 22px !important;
        font-weight: 400 !important;
        color: ${colorFetch('gray1')} !important;
      }
      & > div {
        display: ${({ withBord }) => (withBord ? 'flex' : 'none')} !important;
      }
    }
  }
  ${media.tablet} {
    width: 100% !important;
  }
` as any; // @TODO fix type

export const DateField = ({
  globalValue,
  state,
  languageStrings,
}: {
  globalValue: string;
  state?: TableCustomComponentState;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const { setField, getModalItems } = useTableFunctions();
  const [{ openIndex }] = useTableContext();
  const [isOpenModal, setIsOpenModal] = useState<boolean>();
  const [previousDate, setPreviousDate] = useState(new Date());
  const dateData = getModalItems()[0] ? getModalItems()[0] : {};
  const { fieldName, value } = dateData;
  const width = useWindowSize()[0];

  const handleSetDate = (date: Date) => {
    const preparedDate = moment(date).toISOString();
    setField(openIndex, preparedDate, fieldName);
  };
  const openModal = () => {
    setIsOpenModal(true);
    setPreviousDate(new Date(value));
  };
  const closeModal = (e: any) => {
    const preparedDate = moment(previousDate).toISOString();
    e.preventDefault();
    e.stopPropagation();
    setIsOpenModal(false);
    setField(openIndex, preparedDate, fieldName);
  };
  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenModal(false);
  };

  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
          {getRequiredDateFormat(globalValue, 'DD.MM.YYYY') || 'N/A'}
        </Text>
      ) : (
        <>
          <StyledCalendar
            type={CalendarType.DATE_SELECTION}
            iconComponent={<></>}
            dateFormat={'DD.MM.YYYY'}
            startDate={new Date(value)}
            setStartDate={handleSetDate}
            withBord={state === TableCustomComponentState.EDIT}
            languageStrings={languageStrings}
            onClick={width < 425 && openModal}
          />
          <ChooseDateMobileModal
            isOpenModal={isOpenModal}
            closeModal={closeModal}
            title={''}
            languageStrings={languageStrings}
            date={new Date(value)}
            setDate={handleSetDate}
            handleSave={handleSave}
          />
        </>
      )}
    </>
  );
};
