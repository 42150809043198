import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { CardType } from '../../../../../enums/CardType';
import { ReactComponent as File } from '../../../../../assets/file.svg';
import { DownloadLink } from '../../../../../components/donwloadLink/DonwloadLink';
import { getDocumentsDownloadUrl } from '../../../../../api/documents';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

type ExtraWrapperProps = {
  margin?: string;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  width: 280px;
  height: 120px;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow2};
  background-color: ${colorFetch('white')};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.additional16};
  margin: ${({ margin }) => margin};
  padding: 16px 24px 20px;
  ${media.tablet} {
    width: 100%;
  }
`;

const WrapperTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.2px;
  overflow-wrap: break-word;
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type WrapperProps = {
  type?: CardType;
};

const Wrapper = styled.div<WrapperProps>`
  height: ${({ type }) => (type === CardType.CITIES ? 'max-content' : '64px')};
  display: ${({ type }) => (type === CardType.CITIES ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: flex-end;
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

export interface DashboardCardProps {
  languageStrings: any;
  type?: CardType;
  title: string;
  data?: any[];
  margin?: string;
  count?: string | number;
  filename: string;
}

export const DocumentsCard: FC<DashboardCardProps> = ({
  languageStrings,
  type,
  count,
  title,
  margin,
  data,
  filename,
  ...props
}) => {
  const theme = useTheme();
  const { black1, gray2, gray1 } = theme.colors;
  const color = black1;

  return (
    <ExtraWrapper margin={margin} {...props} theme={theme}>
      <Button>
        <WrapperTitle color={color}>{title}</WrapperTitle>
        <Wrapper type={type} theme={theme}>
          <DownloadLink
            downloadUrl={getDocumentsDownloadUrl(filename)}
            colors={{ main: gray2, mainHover: gray1, background: 'none', backgroundHover: 'none', border: 'none' }}
          >
            {languageStrings.download}
          </DownloadLink>
          <File />
        </Wrapper>
      </Button>
    </ExtraWrapper>
  );
};
DocumentsCard.defaultProps = {};
