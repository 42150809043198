import React, { FC, useCallback, useEffect, useRef, useState, ReactNode } from 'react';
import styled from '@cthings.co/styled-components';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { CheckBox } from '@bit/first-scope.controls';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

const SelectBlock = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.position {
    position: static;
  }
`;

type SelectItemProps = {
  disabled: boolean;
  isWhiteBackground?: boolean;
  isFocused: boolean;
  isError?: boolean;
  selectIcon: boolean;
};

const SelectItem = styled.div<SelectItemProps>`
  padding: ${({ disabled }) => (disabled ? '8px 12px' : '6px 4px 6px 12px')};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ isWhiteBackground, disabled, theme }) =>
    isWhiteBackground ? colorFetch('pureWhite')({ theme }) : disabled ? colorFetch('gray5')({ theme }) : 'transparent'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  width: 100%;
  border: 1px solid
    ${({ disabled, isFocused, isError, theme }) =>
      disabled
        ? colorFetch('gray5')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : isError
        ? colorFetch('red2')({ theme })
        : colorFetch('gray3')({ theme })};
  display: flex;
  outline: none;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s linear;
  & > svg {
    width: ${({ selectIcon }) => (selectIcon ? '26px' : 'max-content')};
    height: ${({ selectIcon }) => (selectIcon ? '26px' : 'max-content')};
    & > path {
      stroke: ${({ disabled, isFocused, isError, theme }) =>
        disabled
          ? colorFetch('gray5')({ theme })
          : isFocused
          ? colorFetch('primary')({ theme })
          : isError
          ? colorFetch('red2')({ theme })
          : colorFetch('gray2')({ theme })};
    }
  }
`;

type TextItemProps = {
  color: string;
};

const TextItem = styled.span<TextItemProps>`
  display: block;
  font-size: 14px;
  line-height: 22px;
  font-family: Poppins, sans-serif;
  color: ${({ color }) => color};
  word-break: break-word;
  text-overflow: ellipsis;
  user-select: none;
`;

type ArrowButtonProps = {
  isOpen: boolean;
};

const ArrowButton = styled.button<ArrowButtonProps>`
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & svg {
    transition: all 0.3s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : '')};
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 3px;
  position: absolute;
  z-index: var(--modal-first-layer);
  bottom: -4px;
  left: 0;
  box-shadow: ${({ theme }) => theme.shadows.primary};
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  transform: translate(0, calc(100%));
  background-color: ${colorFetch('white')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
`;

const SelectModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 125px;
  height: max-content;
  box-sizing: border-box;
  padding: 7px 9px;
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${({ theme }) => theme.borderRadius.primary};
  }
`;

const SelectModalItem = styled.div`
  width: 100%;
  color: ${colorFetch('gray1')};
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 6px;
  &.selected {
    color: ${colorFetch('primary')};
    font-weight: 500;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: ${colorFetch('primary')};
    font-weight: 500;
  }
`;

type MessageProps = {
  isShowMessage?: boolean;
};

const Message = styled(Text)<MessageProps>`
  width: 100%;
  display: ${({ isShowMessage }) => (isShowMessage ? 'flex' : 'none')};
`;

const WrapperCheckBox = styled(CheckBox)`
  margin: 5px;
`;

interface valueType {
  id: any;
  name: string;
}

export interface ComponentProps {
  value: valueType;
  values: any[];
  disabled?: boolean;
  onChange: (id: any) => any;
  className?: any;
  label?: any;
  selectIcon?: () => ReactNode;
  onClick?: () => void;
  startedValidation?: boolean;
  isError?: boolean;
  fromAttach?: boolean;
  languageStrings: any;
  isCheckBox?: boolean;
  isWhiteBackground?: boolean;
}

export const Select: FC<ComponentProps> = ({
  value,
  values,
  disabled = false,
  onChange,
  label,
  selectIcon,
  onClick,
  startedValidation,
  isError,
  fromAttach,
  languageStrings,
  isCheckBox,
  className,
  isWhiteBackground,
  ...props
}) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(values?.findIndex((item: any) => item.id === value.id));
  const isShowMessage = fromAttach ? !!isError : startedValidation && !!isError && !disabled;
  const { gray1, gray2, red2 } = theme.colors;
  useEffect(() => {
    setSelectedIndex(values?.findIndex((item: any) => item.id === value.id));
  }, [value, values]);

  const buttonRef = useRef<any>(null);

  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpen && !buttonRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const handleChange = (index: any, value: valueType) => {
    setSelectedIndex(index);
    onChange(value);
  };

  return (
    <SelectBlock {...props} className={className}>
      {label && (
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 4px 6px">
          {label}
        </Text>
      )}
      <SelectItem
        className="selectedItem"
        disabled={disabled}
        theme={theme}
        onClick={onClick ? onClick : () => setIsOpen(!isOpen)}
        selectIcon={!!selectIcon}
        isError={isError}
        isFocused={isOpen}
        isWhiteBackground={isWhiteBackground}
      >
        <>{isCheckBox && <WrapperCheckBox editInProcess={true} label="" handleToggle={() => {}} toggle={true} />}</>
        <TextItem color={value.name && value.name.length > 0 ? gray1 : gray2}>
          {values[selectedIndex] && values[selectedIndex].name}
        </TextItem>
        {!disabled &&
          (selectIcon ? (
            selectIcon()
          ) : (
            <ArrowButton isOpen={isOpen} ref={buttonRef}>
              <Arrow />
            </ArrowButton>
          ))}
      </SelectItem>
      {isOpen && (
        <SelectContainer className="modalOfSelect">
          <SelectModal theme={theme}>
            {values.length
              ? values.map((el: any, i: number) => (
                  <SelectModalItem
                    onClick={() => handleChange(i, el)}
                    className={i === selectedIndex ? 'selected' : ''}
                  >
                    {el.name}
                  </SelectModalItem>
                ))
              : 'data is empty'}
          </SelectModal>
        </SelectContainer>
      )}
      <Message type={TextType.TEXT_12_GRAY} color={red2} margin={'4px 0 0 12px'} isShowMessage={isShowMessage}>
        {`Address ${languageStrings.validationMessage}`}
      </Message>
    </SelectBlock>
  );
};
