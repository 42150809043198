import React, { FC } from 'react';
import styled from '@cthings.co/styled-components';
import { useTheme } from '@cthings.co/styled-components';
import { ReactComponent as ArrowRightIcon } from '../../assets/Arrow_right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/Arrow_left.svg';
import { ReactComponent as AddIcon } from '../../assets/Add.svg';
import { ReactComponent as MassageIcon } from '../../assets/Massage.svg';
import { ReactComponent as AttachIcon } from '../../assets/Attach.svg';
import { ReactComponent as DettachIcon } from '../../assets/Dettach.svg';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export enum ButtonIconType {
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ADD = 'ADD',
  MASSAGE = 'MASSAGE',
  ATTACH = 'ATTACH',
  DETTACH = 'DETTACH',
}

type ButtonType = {
  width?: string;
  height?: string;
  margin?: string;
  iconType?: ButtonIconType;
  iconPositionRight?: boolean;
  backgroundColor?: string;
  fontSize?: string;
  borderColor?: string;
  backgroundColorHover?: string;
  hoverColor?: string;
  noFocus?: boolean;
};

const Button = styled.button<ButtonType>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin || '0'};
  display: flex;
  justify-content: ${({ iconType }) =>
    iconType === ButtonIconType.ADD || iconType === ButtonIconType.ARROW_LEFT || iconType === ButtonIconType.ARROW_RIGHT
      ? 'space-around'
      : 'center'};
  align-items: center;
  flex-direction: ${({ iconPositionRight }) => (iconPositionRight ? 'row-reverse' : 'row')};

  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : colorFetch('primary')({ theme })};

  color: ${({ color, theme }) => (color ? color : colorFetch('pureWhite')({ theme }))};

  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  line-height: 16px;

  letter-spacing: 0.2px;

  border: 1px solid;
  border-color: ${({ borderColor, theme }) => (borderColor ? borderColor : colorFetch('primary')({ theme }))};
  border-radius: ${({ theme }) => theme.borderRadius.primary};

  outline: none;
  cursor: pointer;

  transition: all 0.3s ease;

  & > svg > path {
    stroke: ${({ color }) => color};
  }

  &:hover {
    background-color: ${({ backgroundColorHover, theme }) =>
      backgroundColorHover ? backgroundColorHover : colorFetch('secondary2')({ theme })};

    color: ${({ hoverColor, theme }) => (hoverColor ? hoverColor : colorFetch('pureWhite')({ theme }))};
  }
`;

export interface CommonButtonProps {
  onClick: any;
  fontSize?: string;
  children?: any;
  height?: string;
  width?: string;
  margin?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  color?: string;
  hoverColor?: string;
  borderColor?: string;
  noFocus?: boolean;
  className?: string;
  iconType?: ButtonIconType;
  iconPositionRight?: boolean;
}

export const CommonButton: FC<CommonButtonProps> = ({
  children,
  onClick,
  noFocus,
  height,
  width,
  margin,
  backgroundColor,
  backgroundColorHover,
  color,
  hoverColor,
  borderColor,
  iconType,
  iconPositionRight,
  fontSize,
  ...props
}) => {
  const theme = useTheme();
  const handleMouseDown = (e: any) => {
    noFocus && e.preventDefault();
  };
  return (
    <Button
      width={width}
      height={height}
      margin={margin}
      backgroundColor={backgroundColor}
      backgroundColorHover={backgroundColorHover}
      color={color}
      hoverColor={hoverColor}
      borderColor={borderColor}
      onClick={onClick}
      noFocus={noFocus}
      onMouseDown={handleMouseDown}
      iconType={iconType}
      iconPositionRight={iconPositionRight}
      fontSize={fontSize}
      theme={theme}
      {...props}
    >
      {iconType === ButtonIconType.ARROW_LEFT && <ArrowLeftIcon />}
      {iconType === ButtonIconType.ARROW_RIGHT && <ArrowRightIcon />}
      {iconType === ButtonIconType.ADD && <AddIcon />}
      {iconType === ButtonIconType.MASSAGE && <MassageIcon />}
      {iconType === ButtonIconType.ATTACH && <AttachIcon />}
      {iconType === ButtonIconType.DETTACH && <DettachIcon />}
      {children}
    </Button>
  );
};

CommonButton.defaultProps = {
  width: '230px',
  height: '32px',
};
