export enum ThresholdColor {
  RED = 'RED',
  ORANGE = 'ORANGE',
}

export type ThresholdData = {
  threshold: number;
  color: ThresholdColor;
  tooltipText?: string;
  style?: {
    stroke?: string;
    strokeDasharray?: string;
    opacity?: number;
    strokeWidth?: number;
  };
};

export type TooltipWrapperProps = {
  top?: number;
  right?: number;
};

export type WrapperProps = {
  width: number;
};
