import React from 'react';
import styled from '@cthings.co/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { DeviceInfo } from '../../../../../../../components/deviceInfo/DeviceInfo';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';
import { MeasurementDevices } from '../../../types';
import { DeviceType } from '../deviceType/DeviceType';
import { DeviceLocation } from '../deviceLocation/DeviceLocation';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
  }
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeviceTitle = ({ data, languageStrings }: { languageStrings: any; data: any }) => {
  const [{ mediaType }] = useStyleContext();
  const { desktop, semiTablet } = mediaType || {};
  const isManningsPair = data?.type === MeasurementDevices.MANNINGS_PAIR;

  const deviceLocationData = isManningsPair
    ? {
        deviceName_1: data?.devices[0]?.transport_id,
        deviceName_2: data?.devices[1]?.transport_id,
        longitude_1: data?.devices[0]?.address?.geotag?.lng,
        longitude_2: data?.devices[1]?.address?.geotag?.lng,
        latitude_1: data?.devices[0]?.address?.geotag?.lat,
        latitude_2: data?.devices[1]?.address?.geotag?.lat,
        location_1: data?.devices[0]?.address?.line1,
        location_2: data?.devices[1]?.address?.line1,
      }
    : {
        deviceName: data?.devices[0]?.transport_id,
        longitude: data?.devices[0]?.address?.geotag?.lng,
        latitude: data?.devices[0]?.address?.geotag?.lat,
        location: data?.devices[0]?.address?.line1,
      };

  return (
    <TitleRow>
      <TitleColumn>
        <Text
          type={TextType.TITLE_PRIMARY}
          fontSize={desktop ? '20px' : '30px'}
          margin={semiTablet ? '0 0 10px 0' : '0'}
        >
          {data?.location?.line1 ? data?.location?.line1 : 'N/A'}
        </Text>
        {isManningsPair && (
          <DeviceType margin={desktop ? '4px 0 0 0' : '28px 0 0 0'} type={languageStrings.pariedManholeModalName} />
        )}
      </TitleColumn>
      {isManningsPair ? (
        <DeviceLocation data={deviceLocationData} deviceIds={data?.devices} />
      ) : (
        <DeviceInfo data={deviceLocationData} deviceId={data?.device_ids[0]} />
      )}
    </TitleRow>
  );
};
