import React from 'react';

export const CoverImage = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="8" r="5.59375" stroke="#555770" />
      <circle cx="7.5" cy="8" r="3.71875" stroke="#555770" />
    </svg>
  );
};
