import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useSwipeable } from 'react-swipeable';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type ContainerProps = {
  withoutNav?: boolean;
  mobileTable: boolean;
  isDraggable: boolean;
};

const Container = styled.div<ContainerProps>`
  width: calc(100% - 520px);
  transition: all 0.2s ease-in-out;
  ${media.narrowMonitor} {
    width: calc(100% - 346px);
  }
  ${media.desktop} {
    width: 100%;
  }
  // @TODO fix media types
  ${media.tablet} {
    position: fixed;
    z-index: var(--modal-first-layer);
    bottom: ${({ withoutNav }: any) => (withoutNav ? '0' : '62px')};
    left: 0;
    right: 0;
    transform: ${({ mobileTable, isDraggable, withoutNav }: any) =>
      mobileTable && isDraggable ? 'translateY(0)' : withoutNav ? 'translateY(51%)' : 'translateY(70%)'};
    background-color: transparent;
    border-radius: ${({ theme }: any) => `${theme.borderRadius.additional24} ${theme.borderRadius.additional24} 0 0`};
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 4px;
      background-color: ${colorFetch('black')};
      opacity: 0.2;
      border-radius: 2px;
    }
  }
  ${media.phone} {
    transform: ${({ mobileTable, isDraggable, withoutNav }: any) =>
      mobileTable && isDraggable && withoutNav ? 'translateY(0%)' : 'translateY(60%)'};
    & > div > div {
      overflow: ${({ mobileTable }: any) => (mobileTable ? 'auto' : 'hidden')};
    }
  }
`;

type BackgroundProps = {
  mobileTable: boolean;
};

const Background = styled.div<BackgroundProps>`
  position: fixed;
  z-index: var(--background-layer);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${colorFetch('black')};
  opacity: 0.6;
  display: ${({ mobileTable }) => (mobileTable ? 'block' : 'none')};
`;

export const withMobileDraggableDeviceContainer = (isDraggable: boolean, withoutNav?: boolean) => {
  const enhance = (Component: any) => ({ ...props }) => {
    const [style] = useStyleContext();
    const { mediaType } = style;
    const theme = useTheme();
    const [mobileTable, setMobileTable] = useState(false);

    const handlers = useSwipeable({
      onSwipedUp: () => setMobileTable(true),
      onSwipedDown: () => setMobileTable(false),
      preventDefaultTouchmoveEvent: true,
    });

    useEffect(() => {
      if (props?.isInstructionModal) {
        setMobileTable(false);
      }
    }, [props?.isInstructionModal]);

    return (
      <>
        {mediaType.tablet ? (
          <>
            <Container
              mobileTable={mobileTable}
              isDraggable={isDraggable}
              withoutNav={withoutNav}
              {...handlers}
              theme={theme}
              className={'draggable-table'}
            >
              <Component {...props} />
            </Container>
            <Background mobileTable={mobileTable} />
          </>
        ) : (
          <Component {...props} />
        )}
      </>
    );
  };
  return enhance;
};
