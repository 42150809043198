import React from 'react';
import { createPortal } from 'react-dom';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  zIndex?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: none;
  // @TODO fix media types
  ${media.tablet} {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 62px;
    box-sizing: border-box;
    background-color: ${colorFetch('white')};
    box-shadow: ${({ theme }: any) => theme.shadows.additionalShadow9};
    padding: 5px 20px;
    position: fixed;
    bottom: -1;
    z-index: ${({ zIndex }: any) => (zIndex ? zIndex : 'var(--modal-second-layer)')};
    border-top: 1px solid;
    border-color: ${colorFetch('gray3')};
  }
`;

export const MobilePortalContainer = ({
  children,
  zIndex,
  ...props
}: {
  children: any;
  zIndex?: string;
  className?: string;
}) => {
  const theme = useTheme();

  return createPortal(
    <Wrapper theme={theme} zIndex={zIndex} {...props}>
      {children}
    </Wrapper>,
    document.getElementById('mobile_container') || document.body,
  );
};
