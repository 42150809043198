import { LanguageStrings } from '../../../../languages/types';

export const getUploadWays = (languageStrings: LanguageStrings) => [
  {
    name: languageStrings.addManuallyAddDeviceModal,
    info: languageStrings.addManuallyDescAddDeviceModal,
    tooltip: languageStrings.addManuallyTooltipAddDeviceModal,
    id: '1',
  },
  {
    name: languageStrings.uploadFileAddDeviceModal,
    info: languageStrings.uploadFileDescAddDeviceModal,
    tooltip: languageStrings.uploadFileTooltipAddDeviceModal,
    id: '2',
  },
];
