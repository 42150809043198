import { InputType, InputStructure } from './types';
import { LanguageStrings } from '../../../../languages/types';
import { FieldsType } from '../../../../utils/validation';

export const getInputStructure = (languageStrings: LanguageStrings) => {
  const mapping = [
    {
      fieldType: InputType.INPUT,
      fieldName: 'name',
      placeholder: languageStrings.nameSurnamePlaceholder,
      label: languageStrings.userNameLabel,
    },
    {
      fieldType: InputType.INPUT,
      fieldName: 'email',
      placeholder: languageStrings.userEmailPlaceholder,
      label: languageStrings.userEmailLabel,
      validationRule: FieldsType.EMAIL,
    },
    {
      fieldType: InputType.INPUT,
      fieldName: 'company',
      placeholder: '',
      label: languageStrings.userCompanyLabel,
      isDisabled: true,
    },
    {
      fieldType: InputType.SELECT,
      fieldName: 'role',
      placeholder: languageStrings.mobileEditPopupChooseRole,
      label: languageStrings.manageUsersAddUserModalInputsRoleTitle,
    },
  ];

  return mapping as InputStructure[];
};
