import React, { useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { TableInput, TableInputType } from '../../../../../components/tableInput/TableInput';
import { FieldsType, isValidField } from '../../../../../utils/validation';

const StyledInput = styled(TableInput)`
  & > div {
    margin: 0;
    & > div:first-child {
      display: none;
    }
    & > div > textarea {
      min-height: 130px;
    }
  }
`;

export const CommentsField = ({ setField, languageStrings }: { setField: Function; languageStrings?: any }) => {
  const [comment, setComment] = useState('');

  const onChange = (value: string) => {
    setComment(value);
  };

  useEffect(() => {
    setField(comment);
  }, [comment]);

  return (
    <StyledInput
      type={TableInputType.TEXTFIELD}
      value={comment}
      handleChangeValue={onChange}
      options={{
        textOfErrorMessage: '',
        title: 'Comments',
        placeholder: languageStrings.insightDetailsCommentsPlaceholder,
        rows: 2,
        isError: isValidField(comment, FieldsType.TEXT),
      }}
      isEditInProcess
    />
  );
};
