import React, { useState } from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { CloseButtonRound } from '../../../reports/components/closeButtonRound/CloseButtonRound';
import { useBlockBodyScroll } from '../../../../../utils/useBlockBodyScroll';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { ResolveButton } from '../resolveButton/ResolveButton';
import { Loader } from '../../../../../components/placeholders/Loader';
import { DetailsModal } from '../detailsModal/DetailsModal';
import { ActionModal } from '../actionModal/ActionModal';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { useTheme } from '@cthings.co/styled-components';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  width: 700px;
  align-items: flex-start;
  padding: 16px 0 30px;
  ${media.phone} {
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: 16px 16px 0 0;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
  ${media.desktop} {
    padding: 0px 16px;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 370px;
  overflow-y: scroll;
  ${media.desktop} {
    padding: 0px 16px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const FakeInput = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('gray5')};
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  padding: 8px 12px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;

  & > button:last-child {
    width: 100%;
    height: 40px;
    margin: 18px 0 0 0;
    font-size: 14px;
  }
  & > div:last-child {
    width: 100%;
    margin: 0;
    flex-direction: revert;
    justify-content: center;
    margin-top: 18px;
    & > span {
      color: ${colorFetch('green2')};
      font-weight: 500;
      &:last-child {
        margin-left: 3px;
        width: max-content;
      }
    }
  }
  ${media.desktop} {
    padding: 0px 16px;
  }
`;

export const MobileDetails = ({
  isOpenModal,
  closeModal,
  data,
  isLoadingInProcess,
  incidentId,
  userInfo,
  handleResolveIncident,
  languageStrings,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  data: any;
  isLoadingInProcess: boolean;
  incidentId: string;
  userInfo: any;
  handleResolveIncident: (comment: string) => void;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { black1, gray1, pureWhite } = theme.colors;

  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [isOpenResolveModal, setIsOpenResolveModal] = useState(false);

  useBlockBodyScroll(isOpenModal);

  const inputsList = [
    {
      label: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
      value: data && data.incident_type_name ? data.incident_type_name : 'N/A',
    },
    {
      label: languageStrings.deviceLabel,
      value: data && data.device_transport_id ? data.device_transport_id : 'N/A',
    },
    {
      label: languageStrings.provisioningAddress,
      value: `${data && data.device_address ? `${data.device_address?.city}, ${data.device_address?.line1}` : 'N/A'}`,
    },
    {
      label: languageStrings.sourceLabel,
      value: `${data && data.source?.name ? data.source?.name : 'N/A'}`,
    },
    {
      label: languageStrings.startLabel,
      value: `${data && data.ts_start ? getRequiredDateFormat(data.ts_start, 'DD.MM.YYYY, HH:mm') : 'N/A'}`,
    },
    {
      label: languageStrings.lastEventLabel,
      value: `${data && data.updated_at ? getRequiredDateFormat(data.updated_at, 'DD.MM.YYYY, HH:mm') : 'N/A'}`,
    },
    {
      label: languageStrings.severityLabel,
      value:
        data && data.severity
          ? data?.severity[data.severity.length - 1].text === 1
            ? languageStrings.severityLow
            : data?.severity[data.severity.length - 1].text === 2
            ? languageStrings.severityMedium
            : data?.severity[data.severity.length - 1].text === 3
            ? languageStrings.severityHigh
            : 'N/A'
          : 'N/A',
    },
  ];

  const openDetailsModal = () => {
    setIsOpenDetailsModal(true);
  };

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer>
        <TitleWrapper>
          <Text type={TextType.SEMITITLE} fontSize={'18px'} weight={'700'} color={black1}>
            {languageStrings.problemsButtonDetails}
          </Text>
          <CloseButtonRound closeOnClick={closeModal} />
        </TitleWrapper>
        <InputWrapper>
          {inputsList.map((item: any) => {
            const { label, value } = item;
            return (
              <Wrapper>
                <Text type={TextType.SEMITITLE} fontSize={'12px'} weight={'500'} color={gray1} margin={'0 0 2px 12px'}>
                  {label}
                </Text>
                <FakeInput theme={theme}>
                  <Text type={TextType.SEMITITLE} fontSize={'14px'} weight={'400'} color={gray1}>
                    {value}
                  </Text>
                </FakeInput>
              </Wrapper>
            );
          })}
        </InputWrapper>
        <ButtonsWrapper>
          <CommonButton
            onClick={openDetailsModal}
            width={'100%'}
            height={'40px'}
            colors={{
              main: black1,
              background: pureWhite,
            }}
          >
            {languageStrings.moreDetailsButton}
          </CommonButton>
          <ResolveButton
            date={data && data.resolved ? getRequiredDateFormat(data.resolved.ts, 'DD.MM.YYYY, HH:mm') : ''}
            openResolveModal={() => {
              if (data) {
                setIsOpenResolveModal(true);
              }
            }}
            languageStrings={languageStrings}
          />
        </ButtonsWrapper>
        {isLoadingInProcess && <Loader />}
        <DetailsModal
          deviceId={data?.device_id}
          deviceType={data?.device_type}
          incidentId={incidentId}
          isOpenModal={isOpenDetailsModal}
          closeModal={() => {
            setIsOpenDetailsModal(false);
          }}
          inputsList={
            data
              ? [
                  {
                    value: data.device_transport_id,
                    title: languageStrings.deviceLabel,
                  },
                  {
                    value: `${data.device_address?.city}, ${data.device_address?.line1}`,
                    title: languageStrings.manageClientsTableHeaderItemsAddress,
                  },
                ]
              : []
          }
          eventsList={data ? data?.timeline : []}
          isLoadingInProcess={isLoadingInProcess}
          userInfo={userInfo}
          languageStrings={languageStrings}
        />
        <ActionModal
          isOpenModal={isOpenResolveModal}
          closeModal={() => {
            setIsOpenResolveModal(false);
          }}
          acceptOnClick={(comment: string) => {
            setIsOpenResolveModal(false);
            handleResolveIncident(comment);
          }}
          data={data}
          isLoadingInProcess={isLoadingInProcess}
          languageStrings={languageStrings}
        />
      </StyledModalContainer>
    </StyledModal>
  );
};
