import React, { useState, useEffect, useCallback, useRef } from 'react';
// @ts-ignore
import { Marker } from 'react-map-gl';
import styled from '@cthings.co/styled-components';

const MarkerWrapper = styled.div``;

export const CustomMarker = ({ children, setCentre, zoom, handleClick, staticDisplay, ...props }: any) => {
  const [zIndex, setZIndex] = useState(1);
  const markerRef = useRef(null);

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (zIndex === 2 && !(markerRef as any).current.contains(event.target)) {
        setZIndex(1);
      }
    },
    [zIndex],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  const handleLocalClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setZIndex(2);
    handleClick && handleClick();
    const calcMisplacedLat = (zoom: number, latitude: number) => {
      const y =
        (512 / (2 * Math.PI)) *
          Math.pow(2, zoom) *
          (Math.PI - Math.log(Math.tan((Math.PI / 4) * (1 + latitude / 90)))) +
        100;
      const newLat =
        ((Math.atan(Math.exp(Math.PI - ((y / 512) * 2 * Math.PI) / Math.pow(2, zoom))) / Math.PI) * 4 - 1) * 90;

      return newLat;
    };

    setCentre &&
      !staticDisplay &&
      props.latitude &&
      props.longitude &&
      setCentre({ lat: calcMisplacedLat(zoom, props.latitude), lng: props.longitude });
  };

  return (
    <MarkerWrapper onClick={handleLocalClick} ref={markerRef}>
      <Marker {...props} style={{ zIndex }}>
        {children}
      </Marker>
    </MarkerWrapper>
  );
};
