import { CommonButton } from '@cthings.co/buttons';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React from 'react';
import styled from '@cthings.co/styled-components';
import { CloseWhiteRoundButton } from '../../../../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { TableInput } from '../../../../../../../../../components/tableInput/TableInput';
import { media } from '@cthings.co/styles-utils';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone} {
      width: 100%;
    }
  }
` as any; // @TODO fix type

const StyledModalContainer = styled(ModalContainer)`
  width: 100%;
  position: fixed;
  bottom: 0;
  border-radius: ${({ theme }) => `${theme.borderRadius.additional16} ${theme.borderRadius.additional16} 0 0`};
  ${media.phone} {
    padding: 24px 0px 30px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${media.phone} {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
`;

const StyledRoundCloseButton = styled(CloseWhiteRoundButton)`
  position: relative;
  top: 0;
  right: 0;
`;

const InputsWrapper = styled.div`
  width: 100%;
  height: 406px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${media.phone} {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
  ${media.phoneXS} {
    height: 280px;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  margin-top: 20px;
  ${media.phone} {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
`;

export const MobileEditModal = ({
  isOpenModal,
  handleCloseModal,
  inputsList,
  buttonsList,
  languageStrings,
}: {
  handleCloseModal: () => void;
  inputsList: any[];
  buttonsList: any[];
  isOpenModal?: boolean;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  return (
    <StyledModal isOpenModal={isOpenModal}>
      <StyledModalContainer theme={theme}>
        <TitleWrapper>
          <Text type={TextType.SEMITITLE} color={black1}>
            {languageStrings.insightDetailsDeviceConfigHeaderEdit}
          </Text>
          <StyledRoundCloseButton onClick={handleCloseModal} />
        </TitleWrapper>
        <InputsWrapper>
          {inputsList.map((item: any, index: number) => {
            const {
              isError,
              label,
              handleChangeValue,
              placeholder,
              value,
              validatingIsStarted,
              inputType,
              options,
            } = item;

            return (
              <TableInput
                key={index}
                type={inputType}
                value={value}
                options={{
                  placeholder,
                  title: label,
                  validatingIsStarted,
                  isError,
                  ...options,
                }}
                handleChangeValue={(val: any, i?: number) => {
                  handleChangeValue(val, index, i);
                }}
                isEditInProcess
              />
            );
          })}
        </InputsWrapper>
        <ButtonsWrapper>
          {buttonsList.map((item: any, index: number) => {
            const {
              value,
              onClick,
              color,
              colorHover,
              borderColor,
              borderColorHover,
              backgroundColor,
              backgroundColorHover,
            } = item;

            return (
              <CommonButton
                key={index}
                onClick={onClick}
                width={'100%'}
                height={'40px'}
                colors={{
                  main: color,
                  mainHover: colorHover,
                  background: backgroundColor,
                  backgroundHover: backgroundColorHover,
                  border: borderColor,
                  borderHover: borderColorHover,
                }}
              >
                {value}
              </CommonButton>
            );
          })}
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
